import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import Error from 'components/Error'
import Comment from './Comment'
import moment from 'moment'

class TicketCommentsDisplay extends Component {
  styles = {
    container: {
      height: '100%',
    },
    secondayContainer: {
      height: '100%',
      minHeight: '64px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    empty: {
      color: '#ABABAB',
    },
  }

  getUser = comment => {
    const createdBy = comment.get('createdBy')
    return this.props.users.get(createdBy) || Map()
  }

  render() {
    if (this.props.ticketComments.get('error')) {
      return (
        <div style={this.styles.secondayContainer}>
          <Error description={'Something went wrong...'} />
        </div>
      )
    }

    if (this.props.ticketComments.get('loading')) {
      return (
        <div style={this.styles.secondayContainer}>
          <SquareLoadingAnimation />
        </div>
      )
    }

    const comments = this.props.ticketComments.get('data') || List()
    if (comments.isEmpty()) {
      return (
        <div style={this.styles.secondayContainer}>
          <span style={this.styles.empty}> This ticket has no comments.</span>
        </div>
      )
    }

    return (
      <div style={this.styles.container}>
        {comments
          .sort(
            (a, b) =>
              moment(b.get('createdDate')) - moment(a.get('createdDate'))
          ) // sort by most recent
          .map((comment, index) => {
            const user = this.getUser(comment)
            return (
              <Comment
                key={index}
                comment={comment}
                user={user}
                isFirst={index === 0}
                isLast={
                  this.props.ticketComments.get('data').size - 1 === index
                }
              />
            )
          })}
      </div>
    )
  }
}

TicketCommentsDisplay.propTypes = {
  users: ImmutablePropTypes.iterable.isRequired,
  ticketComments: ImmutablePropTypes.iterable.isRequired,
}

export default TicketCommentsDisplay
