import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { Map } from 'immutable'
import CloudBadge from 'components/CloudBadge'
import { selectBotsMap } from 'containers/SonraiData/selectors'
import TextLink from 'components/TextLink'
import qs from 'query-string'
import { stripTags } from 'utils/sonraiUtils'

class BotWidget extends React.Component {
  styles = {
    container: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      alignItems: 'center',
    },
  }

  getPushLink = srn => {
    return {
      pathname: '/App/BotManagement/BotDetails',
      search: qs.stringify({
        botId: srn,
      }),
    }
  }

  render() {
    if (!this.props.srn || typeof this.props.srn !== 'string') {
      return '-'
    }

    const bot = this.props.bots.get(this.props.srn) || Map()
    if (bot.isEmpty()) {
      return <span />
    }

    return (
      <div style={this.styles.container}>
        <CloudBadge type={(bot.get('cloud') || '').toLowerCase()} />
        <div
          style={{
            fontSize: this.props.table ? '0.9rem' : '1.2rem',
            marginLeft: '0.3em',
          }}
        >
          <TextLink to={this.getPushLink(bot.get('srn'))}>
            {stripTags(bot.get('title'))}
          </TextLink>
        </div>
      </div>
    )
  }
}

BotWidget.propTypes = {
  bots: ImmutablePropTypes.map,
  srn: PropTypes.string,
  table: PropTypes.bool,
}
const mapStateToProps = createStructuredSelector({
  bots: selectBotsMap,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(BotWidget)
