const CUSTOM_CLASSIFIER_PROPS = `
params {
  regexes
  aliases
  proximity
  where
  disallowed_aliases
}
srn
type
atomName
`

export const FETCH_CUSTOM_CLASSIFIERS_QUERY = `
query getCustomClassifiers($srnList: [String!]!) {
  GetCustomClassifiers(srnList: $srnList) {
    items {
      ${CUSTOM_CLASSIFIER_PROPS}
    }
  }
}
`

export const CREATE_CUSTOM_CLASSIFIERS_MUTATION = `
mutation createCustomClassifier($input: [CustomClassifierInput!]!) {
  CreateCustomClassifiers(input: $input) {
    items {
      ${CUSTOM_CLASSIFIER_PROPS}
    }
  }
}
`

export const DELETE_CUSTOM_CLASSIFIERSS_MUTATION = `
mutation deleteCustomClassifiers($srnList: [String!]!) {
  DeleteCustomClassifiers(srnList: $srnList) {
    items {
      srn
    }
  }
}
`

export const UPDATE_CUSTOM_CLASSIFIERSS_MUTATION = `
mutation updateCustomClassifiers(
  $srnList: [String!]!
  $input: CustomClassifierInput!
) {
  UpdateCustomClassifiers(srnList: $srnList, input: $input) {
    items {
      ${CUSTOM_CLASSIFIER_PROPS}
    }
  }
}
`
