const PREFIX = 'app/containers/NodeViewHeader/'

export const GET_NODE_DATA = `${PREFIX}GET_NODE_DATA`
export const SET_NODE_DATA = `${PREFIX}SET_NODE_DATA`
export const ERR_NODE_DATA = `${PREFIX}ERR_NODE_DATA`

export const SET_CRM_DATA = `${PREFIX}SET_CRM_DATA`
export const ERR_CRM_DATA = `${PREFIX}ERR_CRM_DATA`

export const SET_SWIMLANES = `${PREFIX}SET_SWIMLANES`
export const ERR_SWIMLANES = `${PREFIX}ERR_SWIMLANES`

export const GET_KEYVAULT_DATA = `${PREFIX}GET_KEYVAULT_DATA`
export const SET_KEYVAULT_DATA = `${PREFIX}SET_KEYVAULT_DATA`
export const ERR_KEYVAULT_DATA = `${PREFIX}ERR_KEYVAULT_DATA`

export const GET_RESOURCE_GROUP = `${PREFIX}GET_RESOURCE_GROUP`
export const SET_RESOURCE_GROUP = `${PREFIX}SET_RESOURCE_GROUP`
export const ERR_RESOURCE_GROUP = `${PREFIX}ERR_RESOURCE_GROUP`

export const ADD_KEYVAULT_CREDS = `${PREFIX}ADD_KEYVAULT_CREDS`
export const ADD_KEYVAULT_CREDS_SUCCESS = `${PREFIX}ADD_KEYVAULT_CREDS_SUCCESS`

export const ADD_RESOURCE_TO_SWIMLANE = `${PREFIX}ADD_RESOURCE_TO_SWIMLANE`
export const ADD_RESOURCE_TO_SWIMLANE_SUCCESS = `${PREFIX}ADD_RESOURCE_TO_SWIMLANE_SUCCESS`

export const ADD_TAG = `${PREFIX}ADD_TAG`
export const ADD_TAG_SUCCESS = `${PREFIX}ADD_TAG_SUCCESS`

export const REMOVE_TAG = `${PREFIX}REMOVE_TAG`
export const REMOVE_TAG_SUCCESS = `${PREFIX}REMOVE_TAG_SUCCESS`

export const REMOVE_KEYVAULT_CREDS = `${PREFIX}REMOVE_KEYVAULT_CREDS`
export const REMOVE_KEYVAULT_CREDS_SUCCESS = `${PREFIX}REMOVE_KEYVAULT_CREDS_SUCCESS`

export const UPDATE_KEYVAULT_CREDS = `${PREFIX}UPDATE_KEYVAULT_CREDS`
export const UPDATE_KEYVAULT_CREDS_SUCCESS = `${UPDATE_KEYVAULT_CREDS}UPDATE_KEYVAULT_CREDS_SUCCESS`

export const ADD_CHANGE_DETECTION_PROPERTIES = `${PREFIX}ADD_CHANGE_DETECTION_PROPERTIES`
export const ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS = `${PREFIX}ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS`

export const REMOVE_CHANGE_DETECTION_PROPERTIES = `${PREFIX}REMOVE_CHANGE_DETECTION_PROPERTIES`
export const REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS = `${PREFIX}REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS`

export const TOGGLE_RESOURCE_MONITORING = `${PREFIX}TOGGLE_RESOURCE_MONITORING`
export const TOGGLE_RESOURCE_MONITORING_SUCCESS = `${PREFIX}TOGGLE_RESOURCE_MONITORING_SUCCESS`

export const UPDATE_CHANGE_DETECTION_PROPERTY = `${PREFIX}UPDATE_CHANGE_DETECTION_PROPERTY`
export const UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS = `${PREFIX}UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS`

export const UPDATE_FRIENDLY_NAME = `${PREFIX}UPDATE_FRIENDLY_NAME`
export const UPDATE_FRIENDLY_NAME_SUCCESS = `${PREFIX}UPDATE_FRIENDLY_NAME_SUCCESS`

export const UPDATE_IMPORTANCE = `${PREFIX}UPDATE_IMPORTANCE`
export const UPDATE_IMPORTANCE_SUCCESS = `${PREFIX}UPDATE_IMPORTANCE_SUCCESS`
