import { createSelector } from 'reselect'
import { Map } from 'immutable'
import qs from 'query-string'
import { selectControlFrameworksForUser } from 'containers/ControlFrameworkData/selectors'

const selectPolicyViewDomain = state => state.get('policyEdit') || Map()

export const selectPolicy = createSelector(
  selectPolicyViewDomain,
  state => state.get('policy') || Map()
)

export const selectSaving = createSelector(
  selectPolicyViewDomain,
  state => state.get('saving')
)

export const selectDeleting = createSelector(
  selectPolicyViewDomain,
  state => state.get('deleting')
)

export const selectPolicyId = (state, props) =>
  props.location ? qs.parse(props.location.search).policyId : props.policyId

export const selectUserFrameworkIds = createSelector(
  selectControlFrameworksForUser,
  frameworks => frameworks.map(cf => cf.get('srn'))
)
