import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import {
  SET_USER_PROFILE,
  TOGGLE_SWIMLANE_MANAGER,
  SET_SONRAI_USER_SRN,
  SET_USER_PROFILE_ERROR,
} from './constants'

const initialState = fromJS({
  userProfile: {
    loading: true,
  },
  sonraiUserSrn: null,
  swimlaneManagerOpen: false,
  error: null,
})

const userProfileDataReducer = handleActions(
  {
    [SET_USER_PROFILE]: (state, { payload }) => {
      return state.set('userProfile', fromJS(payload))
    },
    [SET_USER_PROFILE_ERROR]: (state, { payload }) => {
      return state.set('error', payload)
    },
    [TOGGLE_SWIMLANE_MANAGER]: (state, { payload }) =>
      state.set('swimlaneManagerOpen', payload),
    [SET_SONRAI_USER_SRN]: (state, { payload }) =>
      state.set('sonraiUserSrn', payload),
  },
  initialState
)

export default userProfileDataReducer
