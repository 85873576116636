/*
 *
 * SonraiData actions
 *
 */

import {
  SET_RELATIONS,
  SET_TYPES,
  RUN_INSPECTION_QUERY,
  FETCH_SAVED_SEARCHES,
  FETCH_SAVED_SEARCHES_DETAILS,
  SET_SAVED_SEARCHES,
  SET_ALLOWED_ROOT_QUERIES,
  SET_SAVED_SEARCH,
  FETCH_SONRAI_SEARCHES,
  SET_SONRAI_SEARCHES,
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  LOAD_TAG,
  SET_PIVOT,
  FETCH_DATA_CONTAINERS,
  FETCH_DATA_CONTAINERS_SUCCESS,
  START_UPDATE_DATA_POLL,
  STOP_UPDATE_DATA_POLL,
  SET_QUERY_NAMES,
  SET_GRAPHQL_SCHEMA,
  FETCH_GRAPHQL_SCHEMA,
  REMOVE_SAVED_SEARCH,
  CHECK_IF_HAS_COLLECTORS,
  SET_HAS_COLLECTORS,
  GET_MONITORED_RESOURCES_COUNT,
  SET_MONITORED_RESOURCES_COUNT,
  FETCH_SWIMLANES,
  FETCH_SWIMLANES_SUCCESS,
  ADD_SWIMLANE,
  REMOVE_SWIMLANE,
  REMOVE_SWIMLANE_SUCCESS,
  UPDATE_SWIMLANE,
  SET_GLOBAL_ERROR,
  FETCH_SONRAI_USERS,
  FETCH_SONRAI_USERS_SUCCESS,
  UPDATE_SONRAI_USER,
  UPDATE_SONRAI_USER_SUCCESS,
  GET_ALL_ROLES,
  SET_ALL_ROLES,
  ERR_ALL_ROLES,
  UPDATE_USER_ROLES,
  UPDATE_USER_ROLES_SUCCESS,
  GET_BOTS,
  SET_BOTS,
  CREATE_BOT,
  CREATE_BOT_SUCCESS,
  VALIDATE_SOURCE_URL,
  VALIDATE_SOURCE_URL_SUCCESS,
  CLEAR_SOURCE_URL_VALIDATION,
  HANDLE_DELETE_BOTS,
  HANDLE_DELETE_BOTS_SUCCESS,
  UPDATE_BOT_ASSIGNMENT,
  UPDATE_BOT_ASSIGNMENT_SUCCESS,
  GET_EXEMPTED_IDENTITIES,
  SET_EXEMPTED_IDENTITIES,
  SET_EXEMPTED_IDENTITIES_ERROR,
  ADD_EXEMPTED_IDENTITIES,
  ADD_EXEMPTED_IDENTITIES_SUCCESS,
  DELETE_EXEMPTED_IDENTITIES,
  DELETE_EXEMPTED_IDENTITIES_SUCCESS,
  SET_OBJECTIVES,
  FETCH_OBJECTIVES,
  FETCH_OBJECTIVES_ERROR,
  ENABLE_OBJECTIVE,
  ENABLE_OBJECTIVE_SUCCESS,
  ENABLE_OBJECTIVE_ERROR,
  ENABLE_ALL_OBJECTIVES,
  ENABLE_ALL_OBJECTIVES_SUCCESS,
  ENABLE_ALL_OBJECTIVES_ERROR,
  ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE,
  ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_SUCCESS,
  ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_ERROR,
  FETCH_SONRAI_CONFIG,
  SET_SONRAI_CONFIG,
} from './constants'
import { createAction } from 'redux-actions'

export const setPivot = createAction(SET_PIVOT)
export const setTypes = createAction(SET_TYPES)
export const setRelations = createAction(SET_RELATIONS)
export const runInspectionQuery = createAction(RUN_INSPECTION_QUERY)
export const fetchSavedSearches = createAction(FETCH_SAVED_SEARCHES)
export const fetchSavedSearchesDetails = createAction(
  FETCH_SAVED_SEARCHES_DETAILS
)
export const setSavedSearches = createAction(SET_SAVED_SEARCHES)
export const setAllowedRootQueries = createAction(SET_ALLOWED_ROOT_QUERIES)
export const setSavedSearch = createAction(SET_SAVED_SEARCH)
export const setGraphQLSchema = createAction(SET_GRAPHQL_SCHEMA)
export const fetchGraphQLSchema = createAction(FETCH_GRAPHQL_SCHEMA)

export const setSonraiSearches = createAction(SET_SONRAI_SEARCHES)
export const fetchSonraiSearches = createAction(FETCH_SONRAI_SEARCHES)
export const fetchAccounts = createAction(FETCH_ACCOUNTS)
export const fetchAccountsSuccess = createAction(FETCH_ACCOUNTS_SUCCESS)
export const fetchSubscriptions = createAction(FETCH_SUBSCRIPTIONS)
export const fetchSubscriptionsSuccess = createAction(
  FETCH_SUBSCRIPTIONS_SUCCESS
)
export const fetchTags = createAction(FETCH_TAGS)
export const fetchTagsSuccess = createAction(FETCH_TAGS_SUCCESS)
export const loadTag = createAction(LOAD_TAG)
export const fetchDataContainers = createAction(FETCH_DATA_CONTAINERS)
export const fetchDataContainersSuccess = createAction(
  FETCH_DATA_CONTAINERS_SUCCESS
)

export const startUpdateDataPoll = createAction(START_UPDATE_DATA_POLL)
export const stopUpdateDataPoll = createAction(STOP_UPDATE_DATA_POLL)
export const setQueryNames = createAction(SET_QUERY_NAMES)
export const removeSavedSearch = createAction(REMOVE_SAVED_SEARCH)
export const checkIfHasCollectors = createAction(CHECK_IF_HAS_COLLECTORS)
export const setHasCollectors = createAction(SET_HAS_COLLECTORS)
export const getMonitoredResourcesCount = createAction(
  GET_MONITORED_RESOURCES_COUNT
)
export const setMonitoredResourcesCount = createAction(
  SET_MONITORED_RESOURCES_COUNT
)

export const fetchSwimlanes = createAction(FETCH_SWIMLANES)
export const fetchSwimlanesSuccess = createAction(FETCH_SWIMLANES_SUCCESS)
export const addSwimlane = createAction(ADD_SWIMLANE)
export const removeSwimlane = createAction(REMOVE_SWIMLANE)
export const removeSwimlaneSuccess = createAction(REMOVE_SWIMLANE_SUCCESS)
export const updateSwimlane = createAction(UPDATE_SWIMLANE)
export const setGlobalError = createAction(SET_GLOBAL_ERROR)
export const fetchSonraiUsers = createAction(FETCH_SONRAI_USERS)
export const fetchSonraiUsersSuccess = createAction(FETCH_SONRAI_USERS_SUCCESS)
export const updateSonraiUser = createAction(UPDATE_SONRAI_USER)
export const updateSonraiUserSuccess = createAction(UPDATE_SONRAI_USER_SUCCESS)
export const getAllRoles = createAction(GET_ALL_ROLES)
export const setAllRoles = createAction(SET_ALL_ROLES)
export const errAllRoles = createAction(ERR_ALL_ROLES)
export const updateUserRoles = createAction(UPDATE_USER_ROLES)
export const updateUserRolesSuccess = createAction(UPDATE_USER_ROLES_SUCCESS)

export const getBots = createAction(GET_BOTS)
export const setBots = createAction(SET_BOTS)
export const updateBotAssignments = createAction(UPDATE_BOT_ASSIGNMENT)
export const updateBotAssignmentsSuccess = createAction(
  UPDATE_BOT_ASSIGNMENT_SUCCESS
)
export const createBot = createAction(CREATE_BOT)
export const createBotSuccess = createAction(CREATE_BOT_SUCCESS)
export const getExemptedIdentities = createAction(GET_EXEMPTED_IDENTITIES)
export const setExemptedIdentities = createAction(SET_EXEMPTED_IDENTITIES)
export const setExemptedIdentitiesError = createAction(
  SET_EXEMPTED_IDENTITIES_ERROR
)

export const addExemptedIdentities = createAction(ADD_EXEMPTED_IDENTITIES)
export const addExemptedIdentitiesSuccess = createAction(
  ADD_EXEMPTED_IDENTITIES_SUCCESS
)

export const deleteExemptedIdentities = createAction(DELETE_EXEMPTED_IDENTITIES)
export const deleteExemptedIdentitiesSuccess = createAction(
  DELETE_EXEMPTED_IDENTITIES_SUCCESS
)

export const validateSourceUrl = createAction(VALIDATE_SOURCE_URL)
export const validateSourceUrlSuccess = createAction(
  VALIDATE_SOURCE_URL_SUCCESS
)
export const clearSourceUrlValidation = createAction(
  CLEAR_SOURCE_URL_VALIDATION
)
export const handleDeleteBots = createAction(HANDLE_DELETE_BOTS)
export const handleDeleteBotsSuccess = createAction(HANDLE_DELETE_BOTS_SUCCESS)

export const fetchObjectives = createAction(FETCH_OBJECTIVES)
export const fetchObjectivesError = createAction(FETCH_OBJECTIVES_ERROR)
export const setObjectives = createAction(SET_OBJECTIVES)
export const enableObjective = createAction(ENABLE_OBJECTIVE)
export const enableAllObjectives = createAction(ENABLE_ALL_OBJECTIVES)
export const enableAllObjectivesSuccess = createAction(
  ENABLE_ALL_OBJECTIVES_SUCCESS
)
export const enableAllObjectivesError = createAction(
  ENABLE_ALL_OBJECTIVES_ERROR
)
export const enableAllObjectivesForSwimlane = createAction(
  ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE
)
export const enableAllObjectivesForSwimlaneError = createAction(
  ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_ERROR
)
export const enableAllObjectivesForSwimlaneSuccess = createAction(
  ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_SUCCESS
)
export const enableObjectiveSuccess = createAction(ENABLE_OBJECTIVE_SUCCESS)
export const enableObjectiveError = createAction(ENABLE_OBJECTIVE_ERROR)

export const fetchSonraiConfig = createAction(FETCH_SONRAI_CONFIG)
export const setSonraiConfig = createAction(SET_SONRAI_CONFIG)
