import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getColorByTimePassed } from 'utils/sonraiUtils'

class NodeViewDetailAccessKeys extends React.PureComponent {
  styles = {
    container: {},
    title: {
      fontSize: '0.8em',
      fontWeight: '400',
      marginTop: '2px',
    },
    value: {},
    group: {
      display: 'grid',
      gridTemplateColumns: '65px auto',
    },
  }

  render() {
    if (this.props.value && Array.isArray(this.props.value)) {
      return (
        <div style={this.styles.container}>
          {this.props.value.map((value, index) => {
            const created = value.createdDate
              ? moment(value.createdDate).format('LLL')
              : '-'
            const lastUsed = value.lastUsed
              ? moment(value.lastUsed).format('LLL')
              : '-'

            return (
              <div key={`key${index}`} style={this.styles.group}>
                <div style={this.styles.title}>Name:</div>
                <div style={this.styles.value}>{value.name}</div>
                <div style={this.styles.title}>Created:</div>
                <div style={this.styles.value}>{created}</div>
                <div style={this.styles.title}>Last Used:</div>
                {lastUsed === '-' ? (
                  <div style={this.styles.value}>{lastUsed}</div>
                ) : (
                  <div
                    style={{
                      ...this.styles.value,
                      ...{ color: getColorByTimePassed(lastUsed) },
                    }}
                  >
                    {lastUsed}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    } else {
      return <div>-</div>
    }
  }
}

NodeViewDetailAccessKeys.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
}

export default NodeViewDetailAccessKeys
