import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import { SET_IDENTITY_CONTENT, GET_IDENTITY_CONTENT } from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  identityContent: {},
  isLoading: false,
})

const identityNodeViewCardLayoutReducer = handleActions(
  {
    [GET_IDENTITY_CONTENT]: state => state.set('isLoading', true),
    [SET_IDENTITY_CONTENT]: (state, { payload }) => {
      return state
        .set('identityContent', fromJS(payload))
        .set('isLoading', false)
    },
    [CLEAR_DATA]: state => state.set('identityContent', Map()),
  },
  initialState
)

export default identityNodeViewCardLayoutReducer
