import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import Select from 'react-select'

import { getLabel, getValue } from '../utils'

/**
 * get text for the placeholder in the select
 */
function getPlaceholdertext(props) {
  if (props.fieldState.getIn(['select', 'loading'])) {
    return 'Loading ...'
  }
  if (props.fieldState.getIn(['select', 'error'])) {
    return 'Error loading values'
  }
  return `Select ${props.fieldDefinition.name}...`
}

/**
 * get the options we'll pass to the select
 */
function getOptions(props) {
  const data = props.fieldState.getIn(['select', 'data']) ?? List()
  const options = data.toJS().map(item => ({
    label: getLabel(item, props.fieldDefinition),
    value: getValue(item, props.fieldDefinition),
  }))
  return _.sortBy(options, ['label', 'value'])
}

export default function TemplatefieldSelect(props) {
  const options = getOptions(props)
  const [selectedValue, setSelectedValue] = useState()

  useEffect(() => {
    // fetch values that will populate select options
    props.fetchTicketTemplateFieldSelect({
      field: props.fieldDefinition,
    })
  }, [])

  useEffect(() => {
    if (!props.fieldState) {
      return
    }
    const value = props.fieldState.get('value')

    // if no value selected, just set to null or whatever
    if (!value) {
      setSelectedValue(value)
    }
    // if it's multi, need to choose an option for each selected thing
    else if (props.fieldDefinition.multi) {
      try {
        // for multi, is serizalied json
        let optionValues = JSON.parse(value)
        const selectedOptions = options.filter(opt =>
          optionValues.includes(opt.value)
        )
        setSelectedValue(selectedOptions)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Illegal value aws set ${value}`)
        setSelectedValue(value)
      }
    }
    // otherwise find the selected option
    else {
      const selectedOption = options.filter(opt => value === opt.value)
      setSelectedValue(selectedOption)
    }
  }, [props.fieldState])

  return (
    <Select
      placeholder={getPlaceholdertext(props)}
      onChange={val => {
        let value
        if (props.fieldDefinition.multi) {
          // if it's multiselect, make a stringified JSON array of the values
          if (val != null) {
            value = JSON.stringify(val.map(({ value }) => value))
          } else {
            value = null // unelss they unselected everything in the JSON array
          }
        } else {
          // for normal single valued select, just take value
          value = val.value
        }
        props.setTicketTemplateFieldValue({
          field: props.fieldDefinition,
          value,
        })
      }}
      isMulti={props.fieldDefinition.multi}
      value={selectedValue}
      options={options}
      isDisabled={
        props.fieldState.getIn(['select', 'loading']) ||
        props.fieldState.getIn(['select', 'error'])
      }
    />
  )
}

TemplatefieldSelect.propTypes = {
  fetchTicketTemplateFieldSelect: PropTypes.func.isRequired,
  setTicketTemplateFieldValue: PropTypes.func.isRequired,
  fieldDefinition: PropTypes.shape({
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    multi: PropTypes.bool,
    type: PropTypes.string.isRequired,
    select: PropTypes.shape({
      labelFields: PropTypes.arrayOf(PropTypes.string),
      valueField: PropTypes.string,
      search: PropTypes.string,
      path: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  fieldState: ImmutablePropTypes.contains({
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    select: ImmutablePropTypes.contains({
      loading: PropTypes.bool,
      error: PropTypes.bool,
      data: ImmutablePropTypes.listOf(PropTypes.map),
    }),
  }),
}
