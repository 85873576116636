import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown as RSDropdown } from 'reactstrap'

export class Dropdown extends React.Component {
  state = {
    dropdownExpanded: false,
  }

  styles = {
    container: {
      display: 'inline',
    },
  }

  toggleDropdown = e => {
    this.setState(currentState => ({
      dropdownExpanded: !currentState.dropdownExpanded,
    }))
    if (e) {
      e.stopPropagation()
    }
  }

  render() {
    return (
      <RSDropdown
        style={this.styles.container}
        isOpen={this.state.dropdownExpanded}
        toggle={this.toggleDropdown}
        disabled={this.props.disabled}
      >
        {React.Children.map(this.props.children, child =>
          React.cloneElement(child, {
            dropdownExpanded: this.state.dropdownExpanded,
            toggle: this.toggleDropdown,
          })
        )}
      </RSDropdown>
    )
  }
}

Dropdown.defaultProps = {
  onClick: () => {},
}

Dropdown.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

export default Dropdown
export DropdownMenu from './DropdownMenu'
export DropdownItem from './DropdownItem'
export DropdownAnchor from './DropdownAnchor'
