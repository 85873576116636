/**
 *
 * NodeViewDetailDate
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class NodeViewDetailDate extends React.PureComponent {
  getValue = () => {
    return this.props.value ? moment(this.props.value).format('lll') : '-'
  }

  render() {
    return <div>{this.getValue()}</div>
  }
}

NodeViewDetailDate.propTypes = {
  value: PropTypes.string,
}

export default NodeViewDetailDate
