/*
 * NodeSolutionCenter Messages
 *
 * This contains all the text for the NodeSolutionCenter component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  identity: {
    id: 'app.container.NodeSolutionCenter.header',
    defaultMessage: 'Identity',
  },
  infrastructure: {
    id: 'app.container.NodeSolutionCenter.infrastructure',
    defaultMessage: 'Infrastructure',
  },
  data: {
    id: 'app.container.NodeSolutionCenter.data',
    defaultMessage: 'Data',
  },
  cloud: {
    id: 'app.container.NodeSolutionCenter.cloud',
    defaultMessage: 'Cloud',
  },
  header: {
    id: 'app.containers.NodeSolutionCenter.header',
    defaultMessage: 'This is NodeSolutionCenter container !',
  },
  saving: {
    id: 'app.components.NodeView.saving',
    defaultMessage: 'Saving...',
  },
  editName: {
    id: 'app.components.NodeView.editName',
    defaultMessage: 'Edit Display Name',
  },
  typeLabel: {
    id: 'app.components.NodeView.typeLabel',
    defaultMessage: 'Type',
  },
  accountLabel: {
    id: 'app.components.NodeView.accountLabel',
    defaultMessage: 'Account',
  },
  subscription: {
    id: 'app.components.NodeView.subscription',
    defaultMessage: 'Subscription',
  },
  toggle: {
    id: 'app.components.Tag.toggle',
    defaultMessage: 'Toggle add tag',
  },
  tags: {
    id: 'app.components.Tag.tags',
    defaultMessage: 'Tags',
  },
  error: {
    id: 'app.components.Tag.error',
    defaultMessage: 'Both fields are required',
  },
  addButtonLabel: {
    id: 'app.components.Tag.addButtonLabel',
    defaultMessage: 'Add Tag',
  },
  keyPlaceholder: {
    id: 'app.components.Tag.keyPlaceholder',
    defaultMessage: 'Tag Key',
  },
  valuePlaceholder: {
    id: 'app.components.Tag.valuePlaceholder',
    defaultMessage: 'Tag Value',
  },
  enableChangeDetectionMessage: {
    id: 'app.components.ManageDriftDetectionModal.enableChangeDetectionMessage',
    defaultMessage:
      'To enable change detection please select one or many of the monitored properties below to enable change detection on. Once they are enabled you can manange them by clicking the "Manage Change Detection" button on the upper right corner of a monitored resource.',
  },
})
