import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import styled from 'styled-components'
import BarLoadingAnimation from 'components/BarLoadingAnimation'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import DataTable from 'components/DataTable'
import TextLink from 'components/TextLink'

import messages from './messages'
import { getSuggestedBots } from './utils'
import { getCloudFromSrn } from 'utils/sonraiUtils'

const DataTableContainer = styled.div`
  height: 250px;
`

const MessageContainer = styled.div`
  padding: 20px 0px;
  text-align: center;
`

function formatTableData(data, props) {
  if (!data) {
    return []
  }

  let cloudType = null
  if (props.ticket.resource && props.ticket.resource.cloudType) {
    cloudType = props.ticket.resource.cloudType
  } else if (props.ticket.account) {
    cloudType = getCloudFromSrn(props.ticket.account)
  }

  if (!cloudType) {
    // eslint-disable-next-line no-console
    console.error('could not determine cloud type for ticket', props.ticket)
    return []
  }

  return data
    .toJS()
    .filter(bot => bot.cloud)
    .filter(bot => bot.cloud.toLowerCase() === cloudType)
    .filter(bot => {
      return bot.type === 'Remediation'
    })
    .map(bot => {
      return {
        type: bot.type,
        srn: bot.srn,
        title: bot.title,
        description: bot.description,
        blob: bot.blob,
      }
    })
}

export default function Step1(props) {
  const [showAllBots, setShowAllBots] = useState(false)

  const bots = showAllBots
    ? props.bots.get('data')
    : getSuggestedBots(props.ticket, props.bots, props.allEscallations)
  const formattedData = formatTableData(bots, props)

  let body
  let extraInstructions
  if (formattedData.length === 0) {
    if (showAllBots) {
      body = (
        <MessageContainer>
          <DynamicFormattedMessage {...messages.noBots} />
        </MessageContainer>
      )
    } else {
      body = (
        <MessageContainer>
          <DynamicFormattedMessage {...messages.noSuggestedBots} />
          <br />
          <TextLink color="primary" onClick={() => setShowAllBots(true)}>
            <DynamicFormattedMessage {...messages.chooseBotPrompt} />
          </TextLink>
        </MessageContainer>
      )
    }
  } else {
    body = (
      <DataTableContainer>
        <DataTable
          autosize={false}
          data={formattedData}
          hasCheckBoxes
          customColumnConfig={{
            blob: { hide: true },
            srn: { hide: true },
            description: { flex: 1 },
          }}
          customGridProps={{ rowSelection: 'single' }}
          rowSelected={({ data }) => {
            props.selectBot(data)
          }}
          supressSelectAll
        />
      </DataTableContainer>
    )
    if (!showAllBots) {
      extraInstructions = (
        <Fragment>
          <DynamicFormattedMessage {...messages.showingSuggestedBots} />
          <TextLink color="primary" onClick={() => setShowAllBots(true)}>
            {' '}
            (<DynamicFormattedMessage {...messages.showAll} />)
          </TextLink>
        </Fragment>
      )
    } else {
      extraInstructions = (
        <Fragment>
          <DynamicFormattedMessage {...messages.showingAllBots} />
          <TextLink color="primary" onClick={() => setShowAllBots(false)}>
            {' '}
            (<DynamicFormattedMessage {...messages.showSuggested} />)
          </TextLink>
        </Fragment>
      )
    }
  }

  if (props.bots.get('loading')) {
    return <BarLoadingAnimation />
  }

  return (
    <div>
      <p>
        <DynamicFormattedMessage {...messages.step1Instructions} />
        <p style={{ fontSize: '0.9rem' }}>{extraInstructions}</p>
      </p>
      {body}
    </div>
  )
}

Step1.propTypes = {
  allEscallations: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  }),
  bots: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    data: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  }),
  selectBot: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  ticket: ImmutablePropTypes.map,
}
