import { defineMessages } from 'react-intl'

export default defineMessages({
  loading: {
    id: 'app.components.LoadingAnim.loading',
    defaultMessage: 'Loading...',
  },
  error: {
    id: 'app.components.LoadingAnim.error',
    defaultMessage: "Ooops...that wasn't supposed to happen!",
  },
  contact: {
    id: 'app.components.LoadingAnim.contact',
    defaultMessage:
      "We'd love to hear about it, though! You can contact support at",
  },
})
