import React from 'react'
import PropTypes from 'prop-types'
import SizeMe from 'components/SizeMe'
import Masonry from 'react-masonry-component'
import _ from 'lodash'

import { getBreakpoint, getLayoutForBreakpoint } from './utils'

export class ColumnLayout extends React.Component {
  styles = {}
  masonry = {}

  getBreakpoint = () => {
    return getBreakpoint({
      width: this.props.size.width,
      breakpoints: this.props.breakpoints,
    })
  }

  getLayoutForBreakpoint = () => {
    return getLayoutForBreakpoint({
      width: this.props.size.width,
      breakpoints: this.props.breakpoints,
      layout: this.props.columns,
    })
  }

  recalculateGrid = index => {
    const masonry = _.get(this.masonry, `col${index}`)
    if (masonry) {
      masonry.layout()
    }
  }

  render() {
    const columnLayout = this.getLayoutForBreakpoint()

    return (
      <div style={{ width: '100%', display: 'flex' }}>
        {columnLayout.map((col, index) => {
          const colWidth = this.props.size.width * (col.widthPercent * 0.01)
          return (
            <div
              key={index}
              style={{ display: 'inline-block', width: `${colWidth}px` }}
            >
              <Masonry
                key={this.props.size.width}
                ref={c => {
                  this.masonry[`col${index}`] = c ? c.masonry : null
                }}
                options={{
                  gutter: 0,
                  columnWidth:
                    this.props.size.width * (col.widthPercent * 0.01),
                  fitWidth: true,
                }}
              >
                {React.Children.map(this.props.children, child => {
                  return col.items.includes(child.key)
                    ? React.cloneElement(child, {
                        childkey: child.key,
                      })
                    : null
                })
                  .filter(child => {
                    return !!child
                  })
                  .map(child => {
                    const childHeight = _.get(col, [
                      'heights',
                      child.props.childkey,
                    ])

                    return (
                      <div
                        key={child.props.childkey}
                        style={{
                          width: '100%',
                          padding: '0.5em',
                          height: childHeight
                            ? `${childHeight * this.props.rowHeight}px`
                            : 'auto',
                        }}
                      >
                        {childHeight
                          ? React.cloneElement(child, {
                              style: { ...child.props.style, height: '100%' },
                              recalculateGrid: () =>
                                this.recalculateGrid(index),
                            })
                          : React.cloneElement(child, {
                              recalculateGrid: () =>
                                this.recalculateGrid(index),
                            })}
                      </div>
                    )
                  })}
              </Masonry>
            </div>
          )
        })}
      </div>
    )
  }
}

ColumnLayout.defaultProps = {
  breakpoints: { lg: 1390, md: 1153, sm: 916, xs: 679 },
  rowHeight: 180,
}

const ColumnShape = PropTypes.shape({
  widthPercent: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  height: PropTypes.objectOf(PropTypes.number),
})

ColumnLayout.propTypes = {
  columns: PropTypes.shape({
    lg: PropTypes.arrayOf(ColumnShape),
    md: PropTypes.arrayOf(ColumnShape),
    sm: PropTypes.arrayOf(ColumnShape),
    xs: PropTypes.arrayOf(ColumnShape),
  }),
  breakpoints: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
  }),
  children: PropTypes.node,
  rowHeight: PropTypes.number,
  size: PropTypes.shape({
    width: PropTypes.number,
  }).isRequired,
}

export default SizeMe(ColumnLayout)
