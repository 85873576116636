import React from 'react'
import PropTypes from 'prop-types'
import { getPrincipalType } from 'utils/sonraiUtils'

class ServicePrincipal extends React.PureComponent {
  styles = {}

  render() {
    const principalString = this.props.value
      .map((principal, index) => {
        if (index < this.props.value.length - 1) {
          return `${getPrincipalType(principal.srn)} | `
        } else {
          return `${getPrincipalType(principal.srn)}`
        }
      })
      .join('')

    return <div>{principalString}</div>
  }
}

ServicePrincipal.propTypes = {
  value: PropTypes.array,
}

export default ServicePrincipal
