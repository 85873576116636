import React, { Component, Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import _ from 'lodash'

import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

import ChangeDetectionPopup from './ChangeDetectionPopup'

class WidgetPopup extends Component {
  render() {
    const keyName = this.props.data.keyName
    const showLinkToTickets = !this.props.crmTickets
      .filter(ticket => ticket.get('ticketKey') === keyName)
      .isEmpty()

    return (
      <Fragment>
        <ChangeDetectionPopup
          title={this.props.title}
          content={this.props.content}
          lockedOn={this.props.lockedOn}
          isLocked={this.props.isLocked}
          color={this.props.color}
          data={this.props.data}
          resourceSrn={this.props.resourceSrn}
        />
        {showLinkToTickets && (
          <BorderlessButton
            title={`View open "${_.startCase(keyName)}" tickets (for all time)`}
            to={{
              pathname: '/App/Tickets',
              state: {
                resourceSRN: this.props.resourceSrn,
                ticketKey: [keyName],
              },
            }}
          >
            <Icon
              fa
              name="notification"
              color={this.props.theme.fail}
              style={{ fontSize: '1rem' }}
            />
          </BorderlessButton>
        )}
      </Fragment>
    )
  }
}

WidgetPopup.propTypes = {
  crmTickets: ImmutablePropTypes.list.isRequired,
  data: PropTypes.shape({
    keyName: PropTypes.string,
    keyType: PropTypes.string,
  }),
  lockedOn: PropTypes.string,
  isLocked: PropTypes.bool,
  resourceSrn: PropTypes.string,
  color: PropTypes.string,
  theme: themeShape,
  title: PropTypes.string,
  content: PropTypes.object,
}

export default themeable(WidgetPopup)
