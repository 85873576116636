import { defineMessages } from 'react-intl'

export default defineMessages({
  assignEscalationError: {
    id: 'app.containers.CreateUpdateTicketModal.createError',
    defaultMessage: 'There was an assigning ticket escalations.',
  },
  createError: {
    id: 'app.containers.CreateUpdateTicketModal.createError',
    defaultMessage: 'There was an error saving the ticket',
  },
  'error.required': {
    id: 'app.containers.CreateUpdateTicketModal.error.required',
    defaultMessage: "The field '{field}' is required",
  },
  invalidTemplate: {
    id: 'app.containers.CreateUpdateTicketModal.invalidTemplate',
    defaultMessage: 'Selected Template is Invalid.',
  },
  validationErrors: {
    id: 'app.containers.CreateUpdateTicketModal.validationErrors',
    defaultMessage: 'There are some form validation errors.',
  },
})
