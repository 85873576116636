import { defineMessages } from 'react-intl'

export default {
  pluralFields: {
    ...defineMessages({
      Account: {
        id: 'app.field.plural.Account',
        defaultMessage: 'Accounts',
      },
      Accesskey: {
        id: 'app.field.plural.Accesskey',
        defaultMessage: 'Access Keys',
      },
      Action: {
        id: 'app.field.plural.Action',
        defaultMessage: 'Actions',
      },
      Actionclassification: {
        id: 'app.field.plural.Actionclassification',
        defaultMessage: 'Action Classifications',
      },
      Actiontype: {
        id: 'app.field.plural.Actiontype',
        defaultMessage: 'Action Types',
      },
      Alert: {
        id: 'app.field.plural.Alert',
        defaultMessage: 'Alerts',
      },
      Audit: {
        id: 'app.field.plural.Audit',
        defaultMessage: 'Audits',
      },
      Alias: {
        id: 'app.field.plural.Alias',
        defaultMessage: 'Aliases',
      },
      Attribute: {
        id: 'app.field.plural.Attribute',
        defaultMessage: 'Attributes',
      },
      Availabilityzone: {
        id: 'app.field.plural.Availabilityzone',
        defaultMessage: 'Availability Zones',
      },
      Blockstorage: {
        id: 'app.field.plural.Blockstorage',
        defaultMessage: 'Block Storages',
      },
      Cert: {
        id: 'app.field.plural.Cert',
        defaultMessage: 'Certs',
      },
      Cidr: {
        id: 'app.field.plural.Cidr',
        defaultMessage: 'CIDRs',
      },
      CIDR: {
        id: 'app.field.plural.CIDR',
        defaultMessage: 'CIDRs',
      },
      City: {
        id: 'app.field.plural.City',
        defaultMessage: 'Cities',
      },
      Cloud: {
        id: 'app.field.plural.Cloud',
        defaultMessage: 'Clouds',
      },
      Cluster: {
        id: 'app.field.plural.Cluster',
        defaultMessage: 'Clusters',
      },
      Clusterparametergroup: {
        id: 'app.field.plural.Clusterparametergroup',
        defaultMessage: 'Cluster Parameter Groups',
      },
      Compute: {
        id: 'app.field.plural.Compute',
        defaultMessage: 'Computes',
      },
      Data: {
        id: 'app.field.plural.Data',
        defaultMessage: 'Data',
      },
      Dataclassification: {
        id: 'app.field.plural.Dataclassification',
        defaultMessage: 'Data Classifications',
      },
      Datacontainer: {
        id: 'app.field.plural.Datacontainer',
        defaultMessage: 'Data Containers',
      },
      Dataobject: {
        id: 'app.field.plural.Dataobject',
        defaultMessage: 'Data Objects',
      },
      Datastore: {
        id: 'app.field.plural.Datastores',
        defaultMessage: 'Data Stores',
      },
      Datastoreclassification: {
        id: 'app.field.plural.Datastoreclassification',
        defaultMessage: 'Data Store Classifications',
      },
      Datastoreversion: {
        id: 'app.field.plural.Datastoreversions',
        defaultMessage: 'Data Store Versions',
      },
      Datastoretype: {
        id: 'app.field.plural.Datastoretype',
        defaultMessage: 'Data Store Types',
      },
      Encryptionkey: {
        id: 'app.field.plural.Encryptionkey',
        defaultMessage: 'Encryption Keys',
      },
      Entity: {
        id: 'app.field.plural.Entity',
        defaultMessage: 'Entities',
      },
      Eventstream: {
        id: 'app.field.plural.Eventstream',
        defaultMessage: 'Event Streams',
      },
      msg: {
        id: 'app.field.plural.msg',
        defaultMessage: 'Message',
      },
      Group: {
        id: 'app.field.plural.Group',
        defaultMessage: 'Groups',
      },
      Identity: {
        id: 'app.field.plural.Identity',
        defaultMessage: 'Identities',
      },
      Identityprovider: {
        id: 'app.field.plural.Identityprovider',
        defaultMessage: 'Identity Providers',
      },
      Image: {
        id: 'app.field.plural.Image',
        defaultMessage: 'Images',
      },
      Infrastructure: {
        id: 'app.field.plural.Infrastructure',
        defaultMessage: 'Infrastructure',
      },
      Keystore: {
        id: 'app.field.plural.Keystore',
        defaultMessage: 'Key Stores',
      },
      Location: {
        id: 'app.field.plural.Location',
        defaultMessage: 'Locations',
      },
      Mfadevice: {
        id: 'app.field.plural.Mfadevice',
        defaultMessage: 'MFA Devices',
      },
      Network: {
        id: 'app.field.plural.Network',
        defaultMessage: 'Networks',
      },
      Networkinterface: {
        id: 'app.field.plural.Networkinterface',
        defaultMessage: 'Network Interfaces',
      },
      Networkpermission: {
        id: 'app.field.plural.Networkpermission',
        defaultMessage: 'Network Permissions',
      },
      Networkpolicy: {
        id: 'app.field.plural.Networkpolicy',
        defaultMessage: 'Network Policies',
      },
      Networkservice: {
        id: 'app.field.plural.Networkservice',
        defaultMessage: 'Network Services',
      },
      Networksubnet: {
        id: 'app.field.plural.Networksubnet',
        defaultMessage: 'Network Subnets',
      },
      Networkcomponent: {
        id: 'app.field.plural.Networkcomponent',
        defaultMessage: 'Network Components',
      },
      Networkgroup: {
        id: 'app.field.plural.Networkgroup',
        defaultMessage: 'Network Groups',
      },
      Networklistener: {
        id: 'app.field.plural.Networklistener',
        defaultMessage: 'Network Listeners',
      },
      Notification: {
        id: 'app.field.plural.Notification',
        defaultMessage: 'Notifications',
      },
      Option: {
        id: 'app.field.plural.Option',
        defaultMessage: 'Options',
      },
      Optiongroup: {
        id: 'app.field.plural.Optiongroup',
        defaultMessage: 'Option Groups',
      },
      Organization: {
        id: 'app.field.plural.Organization',
        defaultMessage: 'Organizations',
      },
      Organizationalunit: {
        id: 'app.field.plural.Organizationalunit',
        defaultMessage: 'Organizational Units',
      },
      Passwordpolicy: {
        id: 'app.field.plural.Passwordpolicy',
        defaultMessage: 'Password Policies',
      },
      Parameter: {
        id: 'app.field.plural.Parameters',
        defaultMessage: 'Parameters',
      },
      Parametergroup: {
        id: 'app.field.plural.Parametergroup',
        defaultMessage: 'Parameter Groups',
      },
      Permission: {
        id: 'app.field.plural.Permission',
        defaultMessage: 'Permissions',
      },
      Permissionlist: {
        id: 'app.field.plural.Permissionlist',
        defaultMessage: 'Permission Lists',
      },
      Policy: {
        id: 'app.field.plural.Policy',
        defaultMessage: 'Policies',
      },
      Policyentry: {
        id: 'app.field.plural.Policyentry',
        defaultMessage: 'Policy Entries',
      },
      Policyversion: {
        id: 'app.field.plural.Policyversion',
        defaultMessage: 'Policy Versions',
      },
      Portrange: {
        id: 'app.field.plural.Portrange',
        defaultMessage: 'Port Ranges',
      },
      Protection: {
        id: 'app.field.plural.Protection',
        defaultMessage: 'Protections',
      },
      Protocol: {
        id: 'app.field.plural.Protocol',
        defaultMessage: 'Protocols',
      },
      Publickey: {
        id: 'app.field.plural.Publickey',
        defaultMessage: 'Public Keys',
      },
      Region: {
        id: 'app.field.plural.Region',
        defaultMessage: 'Regions',
      },
      Resource: {
        id: 'app.field.plural.Resource',
        defaultMessage: 'Resources',
      },
      Resourcegroup: {
        id: 'app.field.plural.ResourceGroup',
        defaultMessage: 'Resource Groups',
      },
      Role: {
        id: 'app.field.plural.Role',
        defaultMessage: 'Roles',
      },
      Service: {
        id: 'app.field.plural.Service',
        defaultMessage: 'Services',
      },
      Serviceclassification: {
        id: 'app.field.plural.Serviceclassification',
        defaultMessage: 'Service Classifications',
      },
      Servicetag: {
        id: 'app.field.plural.Servicetag',
        defaultMessage: 'Service Tags',
      },
      Servicetype: {
        id: 'app.field.plural.Servicetype',
        defaultMessage: 'Service Types',
      },
      ServiceType: {
        id: 'app.field.plural.ServiceType',
        defaultMessage: 'Service Types',
      },
      Servercertificate: {
        id: 'app.field.plural.Servercertificate',
        defaultMessage: 'Server Certificates',
      },
      Signingcertificate: {
        id: 'app.field.plural.Signingcertificate',
        defaultMessage: 'Signing Certificates',
      },
      Sharedaccesspolicy: {
        id: 'app.field.plural.Sharedaccesspolicy',
        defaultMessage: 'Shared Access Policies',
      },
      Snapshot: {
        id: 'app.field.plural.Snapshot',
        defaultMessage: 'Snapshots',
      },
      Storageaccount: {
        id: 'app.field.plural.Storageaccount',
        defaultMessage: 'Storage Accounts',
      },
      Subdivision: {
        id: 'app.field.plural.Subdivision',
        defaultMessage: 'Subdivisions',
      },
      Subnetgroup: {
        id: 'app.field.plural.Subnetgroup',
        defaultMessage: 'Subnet Groups',
      },
      Subscription: {
        id: 'app.field.plural.Subscription',
        defaultMessage: 'Subscriptions',
      },
      Tag: {
        id: 'app.field.plural.Tag',
        defaultMessage: 'Tags',
      },
      User: {
        id: 'app.field.plural.User',
        defaultMessage: 'Users',
      },
    }),
  },
  fields: {
    ...defineMessages({
      // plural ones
      identities: {
        id: 'app.field.Identities',
        defaultMessage: 'Identities',
      },
      swimlaneSRNs: {
        id: 'app.field.swimlaneSRNs',
        defaultMessage: 'Swimlane',
      },
      Identities: {
        id: 'app.field.Identities',
        defaultMessage: 'Identities',
      },
      attributes: {
        id: 'app.field.Attributes',
        defaultMessage: 'Attributes',
      },
      Attributes: {
        id: 'app.field.Attributes',
        defaultMessage: 'Attributes',
      },
      actionTypes: {
        id: 'app.field.ActionTypes',
        defaultMessage: 'Action Types',
      },
      ActionTypes: {
        id: 'app.field.Attributes',
        defaultMessage: 'Action Types',
      },
      permissions: {
        id: 'app.field.Permissions',
        defaultMessage: 'Permissions',
      },
      Permissions: {
        id: 'app.field.Permissions',
        defaultMessage: 'Permissions',
      },
      services: {
        id: 'app.field.Services',
        defaultMessage: 'Services',
      },
      Services: {
        id: 'app.field.Services',
        defaultMessage: 'Services',
      },
      serviceTypes: {
        id: 'app.field.ServiceTypes',
        defaultMessage: 'Service Types',
      },
      ServiceTypes: {
        id: 'app.field.ServiceTypes',
        defaultMessage: 'Service Types',
      },
      alerts: {
        id: 'app.field.Alerts',
        defaultMessage: 'Alerts',
      },
      Alerts: {
        id: 'app.field.Alerts',
        defaultMessage: 'Alerts',
      },
      actions: {
        id: 'app.field.Actions',
        defaultMessage: 'Actions',
      },
      Actions: {
        id: 'app.field.Actions',
        defaultMessage: 'Actions',
      },
      Protections: {
        id: 'app.field.Protections',
        defaultMessage: 'Protections',
      },
      identity: {
        id: 'app.field.Identity',
        defaultMessage: 'Identity',
      },
      protection: {
        id: 'app.field.Protection',
        defaultMessage: 'Protection',
      },
      aliasSet: {
        id: 'app.field.aliasSet',
        defaultMessage: 'Alias Set',
      },
      availabilityZone: {
        id: 'app.field.availabilityZone',
        defaultMessage: 'Availability Zone',
      },
      Action: {
        id: 'app.field.Action',
        defaultMessage: 'Action',
      },
      ActionType: {
        id: 'app.field.ActionType',
        defaultMessage: 'ActionType',
      },
      Attribute: {
        id: 'app.field.Attribute',
        defaultMessage: 'Attribute',
      },
      Data: {
        id: 'app.field.Data',
        defaultMessage: 'Data',
      },
      Entity: {
        id: 'app.field.Entity',
        defaultMessage: 'Entity',
      },
      Identity: {
        id: 'app.field.Identity',
        defaultMessage: 'Identity',
      },
      Infrastructure: {
        id: 'app.field.Infrastructure',
        defaultMessage: 'Infrastructure',
      },
      Permission: {
        id: 'app.field.Permission',
        defaultMessage: 'Permission',
      },
      Protection: {
        id: 'app.field.Protection',
        defaultMessage: 'Protection',
      },
      Resource: {
        id: 'app.field.Resource',
        defaultMessage: 'Resource',
      },
      Service: {
        id: 'app.field.Service',
        defaultMessage: 'Service',
      },
      ServiceType: {
        id: 'app.field.ServiceType',
        defaultMessage: 'ServiceType',
      },
      isAttachedTo: {
        id: 'app.field.isAttachedto',
        defaultMessage: 'Is Attached To',
      },
      attachedEntities: {
        id: 'app.field.attachedEntities',
        defaultMessage: 'Attached Entitites',
      },
      isMemberOf: {
        id: 'app.field.isMemberOf',
        defaultMessage: 'Is Member Of',
      },
      members: {
        id: 'app.field.members',
        defaultMessage: 'Members',
      },
      removed: {
        id: 'app.field.removed',
        defaultMessage: 'Removed',
      },
      removedBy: {
        id: 'app.field.removedBy',
        defaultMessage: 'Removed By',
      },
      created: {
        id: 'app.field.created',
        defaultMessage: 'Created',
      },
      createdBy: {
        id: 'app.field.createdBy',
        defaultMessage: 'Created By',
      },
      isOwnerOf: {
        id: 'app.field.isOwnerOf',
        defaultMessage: 'Is Owner Of',
      },
      isOwnedBy: {
        id: 'app.field.isOwnedBy',
        defaultMessage: 'Is Owned By',
      },
      createdDate: {
        id: 'app.field.createdDate',
        defaultMessage: 'Created Date',
      },
      observedDate: {
        id: 'app.field.observedDate',
        defaultMessage: 'Observed Date',
      },
      active: {
        id: 'app.field.name',
        defaultMessage: 'Active',
      },
      account: {
        id: 'app.field.name',
        defaultMessage: 'Account',
      },
      label: {
        id: 'app.field.name',
        defaultMessage: 'Label',
      },
      __typename: {
        id: 'app.field.name',
        defaultMessage: 'Type',
      },
      name: {
        id: 'app.field.name',
        defaultMessage: 'Name',
      },
      resourceid: {
        id: 'app.field.resourceid',
        defaultMessage: 'Resource ID',
      },
      type: {
        id: 'app.field.type',
        defaultMessage: 'Type',
      },
      sid: {
        id: 'app.field.sid',
        defaultMessage: 'SID',
      },
      hasPolicyEntry: {
        id: 'app.field.hasPolicyEntry',
        defaultMessage: 'Has Policy Entry',
      },
      isPartOfPolicy: {
        id: 'app.field.isPartOfPolicy',
        defaultMessage: 'Is Part of Policy',
      },
      hasPolicyVersion: {
        id: 'app.field.hasPolicyVersion',
        defaultMessage: 'Has Policy Version',
      },
      isPartOfPolicyVersion: {
        id: 'app.field.isPartOfPolicyVersion',
        defaultMessage: 'Is Part of Policy Version',
      },
      hasMember: {
        id: 'app.field.hasMember',
        defaultMessage: 'Has Member',
      },
      hasAttached: {
        id: 'app.field.hasAttached',
        defaultMessage: 'Has Attached',
      },
      contains: {
        id: 'app.field.contains',
        defaultMessage: 'Contains',
      },
      isIn: {
        id: 'app.field.isIn',
        defaultMessage: 'Is In',
      },
      hasProperty: {
        id: 'app.field.hasProperty',
        defaultMessage: 'Has Attribute',
      },
      attributesTo: {
        id: 'app.field.attributesTo',
        defaultMessage: 'Attributes To',
      },
      performedOn: {
        id: 'app.field.performedOn',
        defaultMessage: 'Performed On',
      },
      isPartOfAction: {
        id: 'app.field.sid',
        defaultMessage: 'Is Part Of Action',
      },
      performed: {
        id: 'app.field.performed',
        defaultMessage: 'Performed',
      },
      performedBy: {
        id: 'app.field.performedBy',
        defaultMessage: 'Performed By',
      },
      allows: {
        id: 'app.field.allows',
        defaultMessage: 'Allows',
      },
      isAllowedBy: {
        id: 'app.field.isAllowedBy',
        defaultMessage: 'Is Allowed By',
      },
      denies: {
        id: 'app.field.denies',
        defaultMessage: 'Denies',
      },
      isDeniedBy: {
        id: 'app.field.isDeniedBy',
        defaultMessage: 'Is Denied By',
      },
      hasActionType: {
        id: 'app.field.hasActionType',
        defaultMessage: 'Has Action Type',
      },
      hasActionClassificationValue: {
        id: 'app.field.hasActionClassificationValue',
        defaultMessage: 'Has Action Classification Value',
      },
      isPartOfPermission: {
        id: 'app.field.isPartOfPermission',
        defaultMessage: 'Is Part Of Permission',
      },
      hasServiceType: {
        id: 'app.field.hasServiceType',
        defaultMessage: 'Has Service Type',
      },
      sonraiConfig: {
        id: 'app.field.sonraiConfig',
        defaultMessage: 'Sonrai Config',
      },
      appliesToPermission: {
        id: 'app.field.appliesToPermission',
        defaultMessage: 'Applies To Permission',
      },
      appliesToResource: {
        id: 'app.field.appliesToResource',
        defaultMessage: 'Applies to Resource',
      },
      appliesTo: {
        id: 'app.field.appliesTo',
        defaultMessage: 'Applies To',
      },
      isPartOfIdentity: {
        id: 'app.field.isPartOfIdentity',
        defaultMessage: 'Is Part Of Identity',
      },
      performedAtCityValue: {
        id: 'app.field.performedAtCityValue',
        defaultMessage: 'Performed At City Value',
      },
      performedAtCityValues: {
        id: 'app.field.performedAtCityValues',
        defaultMessage: 'Performed At City Values',
      },
      performedAtCountryValue: {
        id: 'app.field.performedAtCountryValue',
        defaultMessage: 'Performed At Country Value',
      },
      performedAtCountryValues: {
        id: 'app.field.performedAtCountryValues',
        defaultMessage: 'Performed At Country Values',
      },
      performedByValue: {
        id: 'app.field.performedByValue',
        defaultMessage: 'Performed By Value',
      },
      performedByLabel: {
        id: 'app.field.performedByLabel',
        defaultMessage: 'Performed By Label',
      },
      performedOnLabel: {
        id: 'app.field.performedByLabel',
        defaultMessage: 'Performed On Label',
      },
      performedOnValue: {
        id: 'app.field.performedOnValue',
        defaultMessage: 'Performed On Value',
      },
      srcId: {
        id: 'app.field.srcId',
        defaultMessage: 'Source ID',
      },
      srcIds: {
        id: 'app.field.srcIds',
        defaultMessage: 'Source IDs',
      },
      srcIP: {
        id: 'app.field.srcIP',
        defaultMessage: 'Source IP',
      },
      srcIPs: {
        id: 'app.field.srcIPs',
        defaultMessage: 'Source IPs',
      },
      region: {
        id: 'app.field.region',
        defaultMessage: 'Region',
      },
      regions: {
        id: 'app.field.regions',
        defaultMessage: 'Regions',
      },
      country: {
        id: 'app.field.country',
        defaultMessage: 'Country',
      },
      countries: {
        id: 'app.field.countries',
        defaultMessage: 'Countries',
      },
      coalescedCount: {
        id: 'app.field.coalescedCount',
        defaultMessage: 'Coalesced Count',
      },
      userAgent: {
        id: 'app.field.userAgent',
        defaultMessage: 'User Agent',
      },
      userAgents: {
        id: 'app.field.userAgents',
        defaultMessage: 'User Agents',
      },
      cookieName: {
        id: 'app.field.cookieName',
        defaultMessage: 'Cookie Name',
      },
      isConsoleEnabled: {
        id: 'app.field.isConsoleEnabled',
        defaultMessage: 'Is Console Enabled',
      },
      cookieExpiration: {
        id: 'app.field.cookieExpiration',
        defaultMessage: 'Cookie Expiration',
      },
      dnsName: {
        id: 'app.field.dnsName',
        defaultMessage: 'DNS',
      },
      disabledSettings: {
        id: 'app.field.disabledSettings',
        defaultMessage: 'Disabled Settings',
      },
      enabledSettings: {
        id: 'app.field.enabledSettings',
        defaultMessage: 'Enabled Settings',
      },
      instanceSet: {
        id: 'app.field.instanceSet',
        defaultMessage: 'Instance Set',
      },
      ipSet: {
        id: 'app.field.ipSet',
        defaultMessage: 'IPs',
      },
      isGlobal: {
        id: 'app.field.isGlobal',
        defaultMessage: 'Is Global',
      },
      isOrganizational: {
        id: 'app.field.isOrganizational',
        defaultMessage: 'Is Organizational',
      },
      port: {
        id: 'app.field.port',
        defaultMessage: 'port',
      },
      prefix: {
        id: 'app.field.prefix',
        defaultMessage: 'Prefix',
      },
      protocol: {
        id: 'app.field.protocol',
        defaultMessage: 'Protocol',
      },
      privateIPSet: {
        id: 'app.field.privateIPSet',
        defaultMessage: 'Private IP',
      },
      privateDnsName: {
        id: 'app.field.privateDnsName',
        defaultMessage: 'Private DNS',
      },
      publicIPSet: {
        id: 'app.field.publicIPSet',
        defaultMessage: 'Public IP Set',
      },
      serverlessSet: {
        id: 'app.field.serverlessSet',
        defaultMessage: 'Serverless Set',
      },
      serviceType: {
        id: 'app.field.serviceType',
        defaultMessage: 'Service Type',
      },
      snsTopic: {
        id: 'app.field.snsTopic',
        defaultMessage: 'SNS Topic',
      },
      targetPrefix: {
        id: 'app.field.targetPrefix',
        defaultMessage: 'Target Prefix',
      },
      Entities: {
        id: 'app.components.QueryBlocksStart.Entities',
        defaultMessage: 'Entities',
      },
      Resources: {
        id: 'app.components.QueryBlocksStart.Resources',
        defaultMessage: 'Resources',
      },
      Accounts: {
        id: 'app.field.Accounts',
        defaultMessage: 'Accounts',
      },
      AccessKeys: {
        id: 'app.field.AccessKeys',
        defaultMessage: 'Access Keys',
      },
      Alias: {
        id: 'app.field.Alias',
        defaultMessage: 'Alias',
      },
      Aliases: {
        id: 'app.field.Aliases',
        defaultMessage: 'Aliases',
      },
      Audit: {
        id: 'app.field.Audit',
        defaultMessage: 'Audit',
      },
      Audits: {
        id: 'app.field.Audits',
        defaultMessage: 'Audits',
      },
      Alert: {
        id: 'app.field.Alert',
        defaultMessage: 'Alert',
      },
      BlockStorage: {
        id: 'app.field.BlockStorage',
        defaultMessage: 'Block Storage',
      },
      BlockStorages: {
        id: 'app.field.BlockStorages',
        defaultMessage: 'Block Storages',
      },
      Cert: {
        id: 'app.field.Cert',
        defaultMessage: 'Cert',
      },
      Certs: {
        id: 'app.field.Certs',
        defaultMessage: 'Certs',
      },
      CIDR: {
        id: 'app.field.CIDR',
        defaultMessage: 'CIDR',
      },
      CIDRs: {
        id: 'app.field.CIDRs',
        defaultMessage: 'CIDRs',
      },
      Cloud: {
        id: 'app.field.Cloud',
        defaultMessage: 'Cloud',
      },
      Clouds: {
        id: 'app.field.Clouds',
        defaultMessage: 'Clouds',
      },
      Cluster: {
        id: 'app.field.Cluster',
        defaultMessage: 'Cluster',
      },
      Clusters: {
        id: 'app.field.Clusters',
        defaultMessage: 'Clusters',
      },
      ClusterParameterGroup: {
        id: 'app.field.ClusterParameterGroup',
        defaultMessage: 'Cluster Parameter Group',
      },
      ClusterParameterGroups: {
        id: 'app.field.ClusterParameterGroups',
        defaultMessage: 'Cluster Parameter Groups',
      },
      Compute: {
        id: 'app.field.Compute',
        defaultMessage: 'Compute',
      },
      Computes: {
        id: 'app.field.Computes',
        defaultMessage: 'Computes',
      },
      DataContainer: {
        id: 'app.field.DataContainer',
        defaultMessage: 'Data Container',
      },
      DataContainers: {
        id: 'app.field.DataContainers',
        defaultMessage: 'Data Containers',
      },
      DataObject: {
        id: 'app.field.DataObject',
        defaultMessage: 'Data Object',
      },
      DataObjects: {
        id: 'app.field.DataObjects',
        defaultMessage: 'Data Objects',
      },
      DataStore: {
        id: 'app.field.DataStore',
        defaultMessage: 'Data Store',
      },
      DataStores: {
        id: 'app.field.DataStores',
        defaultMessage: 'Data Stores',
      },
      EncryptionKey: {
        id: 'app.field.EncryptionKey',
        defaultMessage: 'Encryption Key',
      },
      EncryptionKeys: {
        id: 'app.field.EncryptionKeys',
        defaultMessage: 'Encryption Keys',
      },
      EventStream: {
        id: 'app.field.EventStream',
        defaultMessage: 'Event Stream',
      },
      EventStreams: {
        id: 'app.field.EventStreams',
        defaultMessage: 'Event Streams',
      },
      Group: {
        id: 'app.field.Group',
        defaultMessage: 'Group',
      },
      Groups: {
        id: 'app.field.Groups',
        defaultMessage: 'Groups',
      },
      Image: {
        id: 'app.field.Image',
        defaultMessage: 'Image',
      },
      Images: {
        id: 'app.field.Images',
        defaultMessage: 'Images',
      },
      KeyStore: {
        id: 'app.field.KeyStore',
        defaultMessage: 'Key Store',
      },
      KeyStores: {
        id: 'app.field.KeyStores',
        defaultMessage: 'Key Stores',
      },
      MFADevice: {
        id: 'app.field.MFADevice',
        defaultMessage: 'MFA Device',
      },
      MFADevices: {
        id: 'app.field.MFADevices',
        defaultMessage: 'MFA Devices',
      },
      Network: {
        id: 'app.field.Network',
        defaultMessage: 'Network',
      },
      Networks: {
        id: 'app.field.Networks',
        defaultMessage: 'Networks',
      },
      NetworkInterface: {
        id: 'app.field.NetworkInterface',
        defaultMessage: 'Network Interface',
      },
      NetworkInterfaces: {
        id: 'app.field.NetworkInterfaces',
        defaultMessage: 'Network Interfaces',
      },
      NetworkPermission: {
        id: 'app.field.NetworkPermission',
        defaultMessage: 'Network Permission',
      },
      NetworkPermissions: {
        id: 'app.field.NetworkPermissions',
        defaultMessage: 'Network Permissions',
      },
      NetworkPolicy: {
        id: 'app.field.NetworkPolicy',
        defaultMessage: 'Network Policy',
      },
      NetworkPolicies: {
        id: 'app.field.NetworkPolicies',
        defaultMessage: 'Network Policies',
      },
      NetworkService: {
        id: 'app.field.NetworkService',
        defaultMessage: 'Network Service',
      },
      NetworkServices: {
        id: 'app.field.NetworkServices',
        defaultMessage: 'Network Services',
      },
      NetworkSubnet: {
        id: 'app.field.NetworkSubnet',
        defaultMessage: 'Network Subnet',
      },
      NetworkSubnets: {
        id: 'app.field.NetworkSubnets',
        defaultMessage: 'Network Subnets',
      },
      NetworkComponents: {
        id: 'app.field.NetworkComponents',
        defaultMessage: 'Network Components',
      },
      NetworkListeners: {
        id: 'app.field.NetworkListeners',
        defaultMessage: 'Network Listeners',
      },
      NetworkGroups: {
        id: 'app.field.NetworkGroups',
        defaultMessage: 'Network Groups',
      },
      Networkcomponent: {
        id: 'app.field.Networkcomponent',
        defaultMessage: 'Network Component',
      },
      Networklistener: {
        id: 'app.field.Networklistener',
        defaultMessage: 'Network Listener',
      },
      Networkgroup: {
        id: 'app.field.Networkgroup',
        defaultMessage: 'Network Group',
      },
      Option: {
        id: 'app.field.Option',
        defaultMessage: 'Option',
      },
      Options: {
        id: 'app.field.Options',
        defaultMessage: 'Options',
      },
      OptionGroup: {
        id: 'app.field.OptionGroup',
        defaultMessage: 'Option Group',
      },
      OptionGroups: {
        id: 'app.field.OptionGroups',
        defaultMessage: 'Option Groups',
      },
      Organization: {
        id: 'app.field.Organization',
        defaultMessage: 'Organization',
      },
      Organizations: {
        id: 'app.field.Organizations',
        defaultMessage: 'Organizations',
      },
      PasswordPolicy: {
        id: 'app.field.PasswordPolicy',
        defaultMessage: 'Password Policy',
      },
      PasswordPolicies: {
        id: 'app.field.PasswordPolicies',
        defaultMessage: 'Password Policies',
      },
      Parameter: {
        id: 'app.field.plural.Parameter',
        defaultMessage: 'Parameter',
      },
      Parameters: {
        id: 'app.field.Parameters',
        defaultMessage: 'Parameters',
      },
      ParameterGroup: {
        id: 'app.field.ParameterGroup',
        defaultMessage: 'Parameter Group',
      },
      ParameterGroups: {
        id: 'app.field.ParameterGroups',
        defaultMessage: 'Parameter Groups',
      },
      PermissionList: {
        id: 'app.field.PermissionList',
        defaultMessage: 'Permission List',
      },
      PermissionLists: {
        id: 'app.field.PermissionLists',
        defaultMessage: 'Permission Lists',
      },
      Policy: {
        id: 'app.field.Policy',
        defaultMessage: 'Policy',
      },
      Policies: {
        id: 'app.field.Policies',
        defaultMessage: 'Policies',
      },
      PolicyEntry: {
        id: 'app.field.PolicyEntry',
        defaultMessage: 'Policy Entry',
      },
      PolicyEntries: {
        id: 'app.field.PolicyEntries',
        defaultMessage: 'Policy Entries',
      },
      PolicyVersion: {
        id: 'app.field.PolicyVersion',
        defaultMessage: 'Policy Version',
      },
      PolicyVersions: {
        id: 'app.field.PolicyVersions',
        defaultMessage: 'Policy Versions',
      },
      PortRange: {
        id: 'app.field.PortRange',
        defaultMessage: 'Port Range',
      },
      PortRanges: {
        id: 'app.field.PortRanges',
        defaultMessage: 'Port Ranges',
      },
      Protocol: {
        id: 'app.field.Protocol',
        defaultMessage: 'Protocol',
      },
      Protocols: {
        id: 'app.field.Protocols',
        defaultMessage: 'Protocols',
      },
      PublicKey: {
        id: 'app.field.PublicKey',
        defaultMessage: 'Public Key',
      },
      PublicKeys: {
        id: 'app.field.PublicKeys',
        defaultMessage: 'Public Keys',
      },
      ResourceGroup: {
        id: 'app.field.ResourceGroup',
        defaultMessage: 'Resource Group',
      },
      ResourceGroups: {
        id: 'app.field.ResourceGroups',
        defaultMessage: 'Resource Groups',
      },
      Role: {
        id: 'app.field.Role',
        defaultMessage: 'Role',
      },
      Roles: {
        id: 'app.field.Roles',
        defaultMessage: 'Roles',
      },
      ServiceTag: {
        id: 'app.field.ServiceTag',
        defaultMessage: 'Service Tag',
      },
      ServiceTags: {
        id: 'app.field.ServiceTags',
        defaultMessage: 'Service Tags',
      },
      ServerCertificate: {
        id: 'app.field.ServerCertificate',
        defaultMessage: 'Server Certificate',
      },
      ServerCertificates: {
        id: 'app.field.ServerCertificates',
        defaultMessage: 'Server Certificates',
      },
      SigningCertificate: {
        id: 'app.field.SigningCertificate',
        defaultMessage: 'Signing Certificate',
      },
      SigningCertificates: {
        id: 'app.field.SigningCertificates',
        defaultMessage: 'Signing Certificates',
      },
      SharedAccessPolicy: {
        id: 'app.field.SharedAccessPolicy',
        defaultMessage: 'Shared Access Policy',
      },
      SharedAccessPolicies: {
        id: 'app.field.SharedAccessPolicies',
        defaultMessage: 'Shared Access Policies',
      },
      Snapshot: {
        id: 'app.field.Snapshot',
        defaultMessage: 'Snapshot',
      },
      Snapshots: {
        id: 'app.field.Snapshots',
        defaultMessage: 'Snapshots',
      },
      SubnetGroup: {
        id: 'app.field.SubnetGroup',
        defaultMessage: 'Subnet Group',
      },
      SubnetGroups: {
        id: 'app.field.SubnetGroups',
        defaultMessage: 'Subnet Groups',
      },
      actionClassification: {
        id: 'app.global.field.actionClassification',
        defaultMessage: 'Action Classification',
      },
      activityCount: {
        id: 'app.global.field.activityCount',
        defaultMessage: 'Count',
      },
      allowUsersToChangePassword: {
        id: 'app.global.field.allowUsersToChangePassword',
        defaultMessage: 'Allow Users To Change Password',
      },
      Subscription: {
        id: 'app.field.Subscription',
        defaultMessage: 'Subscription',
      },
      encryption: {
        id: 'app.global.field.encryption',
        defaultMessage: 'Encryption Type',
      },
      Subscriptions: {
        id: 'app.field.Subscriptions',
        defaultMessage: 'Subscriptions',
      },
      cidrBlock: {
        id: 'app.global.field.cidrBlock',
        defaultMessage: 'CIDR',
      },
      User: {
        id: 'app.field.User',
        defaultMessage: 'User',
      },
      Users: {
        id: 'app.field.Users',
        defaultMessage: 'Users',
      },
      auditsTo: {
        id: 'app.global.relations.auditsTo',
        defaultMessage: 'Audits To',
      },
      notAuditsTo: {
        id: 'app.global.relations.notAuditsTo',
        defaultMessage: 'Does Not Audit To',
      },
      receivesAuditFrom: {
        id: 'app.global.relations.receivesAuditFrom',
        defaultMessage: 'Receives Audit From',
      },
      notReceivesAuditFrom: {
        id: 'app.global.relations.notReceivesAuditFrom',
        defaultMessage: 'Does Not Receive Audit From',
      },
      accessedBy: {
        id: 'app.global.relations.accessedBy',
        defaultMessage: 'Accessed By',
      },
      notAccessedBy: {
        id: 'app.global.relations.notAccessedBy',
        defaultMessage: 'Not Accessed By',
      },
      isRegisteredWith: {
        id: 'app.global.relations.isRegisteredWith',
        defaultMessage: 'Is Registered With',
      },
      notIsRegisteredWith: {
        id: 'app.global.relations.notIsRegisteredWith',
        defaultMessage: 'Is Not Registered With',
      },
      hasRegisteredNetworkGroup: {
        id: 'app.global.relations.hasRegisteredNetworkGroup',
        defaultMessage: 'Has Registered Network Group',
      },
      notHasRegisteredNetworkGroup: {
        id: 'app.global.relations.notHasRegisteredNetworkGroup',
        defaultMessage: 'Does Not Have Registered Network Group',
      },
      serves: {
        id: 'app.global.relations.serves',
        defaultMessage: 'Serves',
      },
      notServes: {
        id: 'app.global.relations.notServes',
        defaultMessage: 'Does Not Serve',
      },
      isServedBy: {
        id: 'app.global.relations.isServedBy',
        defaultMessage: 'Is Served By',
      },
      notIsServedBy: {
        id: 'app.global.relations.notIsServedBy',
        defaultMessage: 'Is Not Served By',
      },

      canBeAccessedBy: {
        id: 'app.global.relations.canBeAccessedBy',
        defaultMessage: 'Can Be Accessed By',
      },
      notCanBeAccessedBy: {
        id: 'app.global.relations.notCanBeAccessedBy',
        defaultMessage: 'Can Not Be Accessed By',
      },
      manages: {
        id: 'app.global.relations.manages',
        defaultMessage: 'Manages',
      },
      hasTag: {
        id: 'app.global.relations.hasTag',
        defaultMessage: 'Has Tag',
      },
      hasAlias: {
        id: 'app.global.relations.hasAlias',
        defaultMessage: 'Has Alias',
      },
      notHasAlias: {
        id: 'app.global.relations.notHasAlias',
        defaultMessage: 'Does Not Have Alias',
      },
      isAliasOf: {
        id: 'app.global.relations.isAliasOf',
        defaultMessage: 'Is Alias Of',
      },
      notIsAliasOf: {
        id: 'app.global.relations.notIsAliasOf',
        defaultMessage: 'Is Not Alias Of',
      },
      hasAudit: {
        id: 'app.global.relations.hasAudit',
        defaultMessage: 'Has Audit',
      },
      notHasAudit: {
        id: 'app.global.relations.notHasAudit',
        defaultMessage: 'Does Not Have Audit',
      },
      isClassificationOfAudit: {
        id: 'app.global.relations.isClassificationOfAudit',
        defaultMessage: 'Is Classification Of Audit',
      },
      notIsClassificationOfAudit: {
        id: 'app.global.relations.notIsClassificationOfAudit',
        defaultMessage: 'Is Not Classification Of Audit',
      },
      audits: {
        id: 'app.global.relations.audits',
        defaultMessage: 'Audits',
      },
      notAudits: {
        id: 'app.global.relations.notAudits',
        defaultMessage: 'Does Not Audit',
      },
      isAttachedToSubnet: {
        id: 'app.global.relations.isAttachedToSubnet',
        defaultMessage: 'Is Attached To Subnet',
      },
      isAttachedToInterface: {
        id: 'app.global.relations.isAttachedToInterface',
        defaultMessage: 'Is Attached To Interface',
      },
      includedInPolicy: {
        id: 'app.global.relations.includedInPolicy',
        defaultMessage: 'Included In Policy',
      },
      references: {
        id: 'app.global.relations.references',
        defaultMessage: 'References',
      },
      hasSnapshot: {
        id: 'app.global.relations.hasSnapshot',
        defaultMessage: 'Has Snapshot',
      },
      isAttachedToBlockStorage: {
        id: 'app.global.relations.isAttachedToBlockStorage',
        defaultMessage: 'Is Attached To Block Storage',
      },
      basedOn: {
        id: 'app.global.relations.basedOn',
        defaultMessage: 'Based On',
      },
      isDefinedBy: {
        id: 'app.global.relations.isDefinedBy',
        defaultMessage: 'Is Defined By',
      },
      hasClassification: {
        id: 'app.global.relations.hasClassification',
        defaultMessage: 'Has Classification',
      },
      isAttachedToNetwork: {
        id: 'app.global.relations.isAttachedToNetwork',
        defaultMessage: 'Is Attached To Network',
      },
      includedInNetwork: {
        id: 'app.global.relations.includedInNetwork',
        defaultMessage: 'Included In Network',
      },
      isPartOf: {
        id: 'app.global.relations.isPartOf',
        defaultMessage: 'Is Part Of',
      },
      isPartOfPolicyEntry: {
        id: 'app.field.isPartOfPolicyEntry',
        defaultMessage: 'Is Part of Policy Entry',
      },
      hasPermission: {
        id: 'app.global.relations.hasPermission',
        defaultMessage: 'Has Permission',
      },
      hasAccessToData: {
        id: 'app.global.relations.hasAccessToData',
        defaultMessage: 'Has Access To Data',
      },
      hasAttachedNetworkComponent: {
        id: 'app.global.relations.hasAttachedNetworkComponent',
        defaultMessage: 'Has Attached Network Component',
      },
      notHasAttachedNetworkComponent: {
        id: 'app.global.relations.notHasAttachedNetworkComponent',
        defaultMessage: 'Does Not Have Attached Network Component',
      },
      isAttachedToNetworkComponent: {
        id: 'app.global.relations.isAttachedToNetworkComponent',
        defaultMessage: 'Is Attached To Network Component',
      },
      notIsAttachedToNetworkComponent: {
        id: 'app.global.relations.notIsAttachedToNetworkComponent',
        defaultMessage: 'Is Not Attached To Network Component',
      },
      hasAttachedNetworkPolicy: {
        id: 'app.global.relations.hasAttachedNetworkPolicy',
        defaultMessage: 'Has Attached Network Policy',
      },
      notHasAttachedNetworkPolicy: {
        id: 'app.global.relations.notHasAttachedNetworkPolicy',
        defaultMessage: 'Does Not Have Attached Network Policy',
      },
      hasAttachedNetworkGroup: {
        id: 'app.global.relations.hasAttachedNetworkGroup',
        defaultMessage: 'Has Attached Network Group',
      },
      notHasAttachedNetworkGroup: {
        id: 'app.global.relations.notHasAttachedNetworkGroup',
        defaultMessage: 'Does Not Have Attached Network Group',
      },
      canBeAssumedBy: {
        id: 'app.global.relations.canBeAssumedBy',
        defaultMessage: 'Can Be Assumed By',
      },
      notCanBeAssumedBy: {
        id: 'app.global.relations.notCanBeAssumedBy',
        defaultMessage: 'Can Not Be Assumed By',
      },
      isAllowedByDestination: {
        id: 'app.global.relations.isAllowedByDestination',
        defaultMessage: 'Is Allowed By Destination',
      },
      notIsAllowedByDestination: {
        id: 'app.global.relations.notIsAllowedByDestination',
        defaultMessage: 'Is Not Allowed By Destination',
      },
      isAllowedBySource: {
        id: 'app.global.relations.isAllowedBySource',
        defaultMessage: 'Is Allowed By Source',
      },
      notIsAllowedBySource: {
        id: 'app.global.relations.notIsAllowedBySource',
        defaultMessage: 'Is Not Allowed By Source',
      },
      isDeniedBySource: {
        id: 'app.global.relations.isDeniedBySource',
        defaultMessage: 'Is Denied By Source',
      },
      notIsDeniedBySource: {
        id: 'app.global.relations.notIsDeniedBySource',
        defaultMessage: 'Is Not Denied By Source',
      },
      isDeniedByDestination: {
        id: 'app.global.relations.isDeniedByDestination',
        defaultMessage: 'Is Denied By Destination',
      },
      notIsDeniedByDestination: {
        id: 'app.global.relations.notIsDeniedByDestination',
        defaultMessage: 'Is Not Denied By Destination',
      },
      isSnapshotOfBlockStorage: {
        id: 'app.global.relations.isSnapshotOfBlockStorage',
        defaultMessage: 'Is Snapshot Of Block Storage',
      },
      notIsSnapshotOfBlockStorage: {
        id: 'app.global.relations.notIsSnapshotOfBlockStorage',
        defaultMessage: 'Is Not Snapshot Of Block Storage',
      },
      isSnapshotOfCluster: {
        id: 'app.global.relations.isSnapshotOfCluster',
        defaultMessage: 'Is Snapshot Of Cluster',
      },
      notIsSnapshotOfCluster: {
        id: 'app.global.relations.notIsSnapshotOfCluster',
        defaultMessage: 'Is Not Snapshot Of Cluster',
      },
      isSnapshotOfCompute: {
        id: 'app.global.relations.isSnapshotOfCompute',
        defaultMessage: 'Is Snapshot Of Compute',
      },
      notIsSnapshotOfCompute: {
        id: 'app.global.relations.notIsSnapshotOfCompute',
        defaultMessage: 'Is Not Snapshot Of Compute',
      },
      serviceIsIn: {
        id: 'app.global.relations.serviceIsIn',
        defaultMessage: 'Service Is In',
      },
      notServiceIsIn: {
        id: 'app.global.relations.notServiceIsIn',
        defaultMessage: 'Service Is Not In',
      },
      isUsedBy: {
        id: 'app.global.relations.isUsedBy',
        defaultMessage: 'Is Used By',
      },
      notIsUsedBy: {
        id: 'app.global.relations.notIsUsedBy',
        defaultMessage: 'Is Not Used By',
      },
      hasBlockStorage: {
        id: 'app.global.relations.hasBlockStorage',
        defaultMessage: 'Has Block Storage',
      },
      notHasBlockStorage: {
        id: 'app.global.relations.notHasBlockStorage',
        defaultMessage: 'Does Not Have Block Storage',
      },
      hasAttachedClusterParameterGroup: {
        id: 'app.global.relations.hasAttachedClusterParameterGroup',
        defaultMessage: 'Has Attached Cluster Parameter Group',
      },
      notHasAttachedClusterParameterGroup: {
        id: 'app.global.relations.notHasAttachedClusterParameterGroup',
        defaultMessage: 'Does Not Have Attached Cluster Parameter Group',
      },
      containsDataStore: {
        id: 'app.global.relations.containsDataStore',
        defaultMessage: 'Contains Data Store',
      },
      notContainsDataStore: {
        id: 'app.global.relations.notContainsDataStore',
        defaultMessage: 'Does Not Contain Data Store',
      },
      hasAttachedParameterGroup: {
        id: 'app.global.relations.hasAttachedParameterGroup',
        defaultMessage: 'Has Attached Parameter Group',
      },
      notHasAttachedParameterGroup: {
        id: 'app.global.relations.notHasAttachedParameterGroup',
        defaultMessage: 'Does Not Have Attached Parameter Group',
      },
      hasAttachedSubnetGroup: {
        id: 'app.global.relations.hasAttachedSubnetroup',
        defaultMessage: 'Has Attached Subnet Group',
      },
      notHasAttachedSubnetGroup: {
        id: 'app.global.relations.notHasAttachedSubnetGroup',
        defaultMessage: 'Does Not Have Attached Subnet Group',
      },
      hasAttachedOptionGroup: {
        id: 'app.global.relations.hasAttachedOptionGroup',
        defaultMessage: 'Has Attached Option Group',
      },
      notHasAttachedOptionGroup: {
        id: 'app.global.relations.notHasAttachedOptionGroup',
        defaultMessage: 'Does Not Have Attached Option Group',
      },
      assumedBy: {
        id: 'app.global.relations.assumedBy',
        defaultMessage: 'Assumed By',
      },
      notAssumedBy: {
        id: 'app.global.relations.notAssumedBy',
        defaultMessage: 'Not Assumed By',
      },
      hasAttachedPasswordPolicy: {
        id: 'app.global.relations.hasAttachedPasswordPolicy',
        defaultMessage: 'Has Attached Password Policy',
      },
      notHasAttachedPasswordPolicy: {
        id: 'app.global.relations.notHasAttachedPasswordPolicy',
        defaultMessage: 'Does Not Have Attached Password Policy',
      },
      hasAttachedAccessPolicy: {
        id: 'app.global.relations.hasAttachedAccessPolicy',
        defaultMessage: 'Has Attached Access Policy',
      },
      notHasAttachedAccessPolicy: {
        id: 'app.global.relations.notHasAttachedAccessPolicy',
        defaultMessage: 'Does Not Have Attached Access Policy',
      },
      hasAttachedSharedAccessPolicy: {
        id: 'app.global.relations.hasAttachedSharedAccessPolicy',
        defaultMessage: 'Has Attached Shared Access Policy',
      },
      notHasAttachedSharedAccessPolicy: {
        id: 'app.global.relations.notHasAttachedSharedAccessPolicy',
        defaultMessage: 'Does Not Have Attached Shared Access Policy',
      },
      isProtectedBy: {
        id: 'app.global.relations.isProtectedBy',
        defaultMessage: 'Is Protected By',
      },
      notIsProtectedBy: {
        id: 'app.global.relations.notIsProtectedBy',
        defaultMessage: 'Is Not Protected By',
      },
      baseOf: {
        id: 'app.global.relations.baseOf',
        defaultMessage: 'Base Of',
      },
      notBaseOf: {
        id: 'app.global.relations.notBaseOf',
        defaultMessage: 'Not Base Of',
      },
      hasAttachedCompute: {
        id: 'app.global.relations.hasAttachedCompute',
        defaultMessage: 'Has Attached Compute',
      },
      notHasAttachedCompute: {
        id: 'app.global.relations.notHasAttachedCompute',
        defaultMessage: 'Does Not Have Attached Compute',
      },
      hasRouteTable: {
        id: 'app.global.relations.hasRouteTable',
        defaultMessage: 'Has Route Table',
      },
      notHasRouteTable: {
        id: 'app.global.relations.notHasRouteTable',
        defaultMessage: 'Does Not Have Route Table',
      },
      includes: {
        id: 'app.global.relations.includes',
        defaultMessage: 'Includes',
      },
      notIncludes: {
        id: 'app.global.relations.notIncludes',
        defaultMessage: 'Does Not Include',
      },
      hasSubnet: {
        id: 'app.global.relations.hasSubnet',
        defaultMessage: 'Has Subnet',
      },
      notHasSubnet: {
        id: 'app.global.relations.notHasSubnet',
        defaultMessage: 'Does Not Have Subnet',
      },
      includesCompute: {
        id: 'app.global.relations.includesCompute',
        defaultMessage: 'Includes Compute',
      },
      notIncludesCompute: {
        id: 'app.global.relations.notIncludesCompute',
        defaultMessage: 'Does Not Include Compute',
      },
      includesInterface: {
        id: 'app.global.relations.includesInterface',
        defaultMessage: 'Includes Interface',
      },
      hasAttachedInterface: {
        id: 'app.global.relations.hasAttachedInterface',
        defaultMessage: 'Has Attached Interface',
      },
      notHasAttachedInterface: {
        id: 'app.global.relations.notHasAttachedInterface',
        defaultMessage: 'Does Not Have Attached Interface',
      },
      hasAttachedStorage: {
        id: 'app.global.relations.hasAttachedStorage',
        defaultMessage: 'Has Attached Storage',
      },
      notHasAttachedStorage: {
        id: 'app.global.relations.notHasAttachedStorage',
        defaultMessage: 'Does Not Have Attached Storage',
      },
      isPeeredWith: {
        id: 'app.global.relations.isPeeredWith',
        defaultMessage: 'Is Peered With',
      },
      notIsPeeredWith: {
        id: 'app.global.relations.notIsPeeredWith',
        defaultMessage: 'Is Not Peered With',
      },
      notIncludesInterface: {
        id: 'app.global.relations.notIncludesInterface',
        defaultMessage: 'Does Not Include Interface',
      },
      egressProtectedBy: {
        id: 'app.global.relations.egressProtectedBy',
        defaultMessage: 'Egress Protected By',
      },
      notEgressProtectedBy: {
        id: 'app.global.relations.notEgressProtectedBy',
        defaultMessage: 'Egress Not Protected By',
      },
      ingressProtectedBy: {
        id: 'app.global.relations.ingressProtectedBy',
        defaultMessage: 'Ingess Protected By',
      },
      notIngressProtectedBy: {
        id: 'app.global.relations.notIngressProtectedBy',
        defaultMessage: 'Ingress Not Protected By',
      },
      organizes: {
        id: 'app.global.relations.organizes',
        defaultMessage: 'Organizes',
      },
      actionIsIn: {
        id: 'app.global.relations.actionIsIn',
        defaultMessage: 'Action Is In',
      },
      notActionIsIn: {
        id: 'app.global.relations.notActionIsIn',
        defaultMessage: 'Action Is Not In',
      },
      organizedBy: {
        id: 'app.global.relations.organizedBy',
        defaultMessage: 'Organized By',
      },
      accessedProtection: {
        id: 'app.global.relations.accessedProtection',
        defaultMessage: 'Accessed Protection',
      },
      hasAccessToNetworkPolicy: {
        id: 'app.global.relations.hasAccessToNetworkPolicy',
        defaultMessage: 'Has Access To Network Policy',
      },
      hasAccessToCompute: {
        id: 'app.global.relations.hasAccessToCompute',
        defaultMessage: 'Has Access To Compute',
      },
      accessedCompute: {
        id: 'app.global.relations.accessedCompute',
        defaultMessage: 'Accessed Compute',
      },
      accessedIdentity: {
        id: 'app.global.relations.accessedIdentity',
        defaultMessage: 'Accessed Identity',
      },
      hasAccessToIdentity: {
        id: 'app.global.relations.hasAccessToIdentity',
        defaultMessage: 'Has Access To Identity',
      },
      accessedNetworkPolicy: {
        id: 'app.global.relations.accessedNetworkPolicy',
        defaultMessage: 'Accessed Network Policy',
      },
      hasPrincipal: {
        id: 'app.global.relations.hasPrincipal',
        defaultMessage: 'Has Principal',
      },
      hasAccessToProtection: {
        id: 'app.global.relations.hasAccessToProtection',
        defaultMessage: 'Has Access To Protection',
      },
      accessedData: {
        id: 'app.global.relations.accessedData',
        defaultMessage: 'Accessed Data',
      },
      usesPortrange: {
        id: 'app.global.relations.usesPortrange',
        defaultMessage: 'Uses Portrange',
      },

      usesProtocol: {
        id: 'app.global.relations.usesProtocol',
        defaultMessage: 'Uses Protocol',
      },
      allowsServiceTagDestination: {
        id: 'app.global.relations.allowsServiceTagDestination',
        defaultMessage: 'Allows Service Tag Destination',
      },
      deniesServiceTagSource: {
        id: 'app.global.relations.deniesServiceTagSource',
        defaultMessage: 'Denies Service Tag Source',
      },
      allowsPortRangeSource: {
        id: 'app.global.relations.allowsPortRangeSource',
        defaultMessage: 'Allows Port Range Source',
      },
      deniesServiceTagDestination: {
        id: 'app.global.relations.deniesServiceTagDestination',
        defaultMessage: 'Denies Service Tag Destination',
      },
      deniesPortRangeDestination: {
        id: 'app.global.relations.deniesPortRangeDestination',
        defaultMessage: 'Denies Port Range Destination',
      },
      allowsPolicyDestination: {
        id: 'app.global.relations.allowsPolicyDestination',
        defaultMessage: 'Allows Policy Destination',
      },
      allowsServiceTagSource: {
        id: 'app.global.relations.allowsServiceTagSource',
        defaultMessage: 'Allows Service Tag Source',
      },
      deniesPolicySource: {
        id: 'app.global.relations.deniesPolicySource',
        defaultMessage: 'Denies Policy Source',
      },
      deniesPortRangeSource: {
        id: 'app.global.relations.deniesPortRangeSource',
        defaultMessage: 'Denies Port Range Source',
      },
      protectsIngress: {
        id: 'app.global.relations.protectsIngress',
        defaultMessage: 'Protects Ingress',
      },
      allowsPolicySource: {
        id: 'app.global.relations.allowsPolicySource',
        defaultMessage: 'Allows Policy Source',
      },
      allowsPortRangeDestination: {
        id: 'app.global.relations.allowsPortRangeDestination',
        defaultMessage: 'Allows Port Range Destination',
      },
      deniesCIDRDestination: {
        id: 'app.global.relations.deniesCIDRDestination',
        defaultMessage: 'Denies CIDR Destination',
      },
      deniesProtocol: {
        id: 'app.global.relations.deniesProtocol',
        defaultMessage: 'Denies Protocol',
      },
      deniesCIDRSource: {
        id: 'app.global.relations.deniesCIDRSource',
        defaultMessage: 'Denies CIDR Source',
      },
      allowsProtocol: {
        id: 'app.global.relations.allowsProtocol',
        defaultMessage: 'Allows Protocol',
      },
      allowsCIDRDestination: {
        id: 'app.global.relations.allowsCIDRDestination',
        defaultMessage: 'Allows CIDR Destination',
      },
      protectsEgress: {
        id: 'app.global.relations.protectsEgress',
        defaultMessage: 'Protects Egress',
      },
      allowsCIDRSource: {
        id: 'app.global.relations.allowsCIDRSource',
        defaultMessage: 'Allows CIDR Source',
      },
      deniesPolicyDestination: {
        id: 'app.global.relations.deniesPolicyDestination',
        defaultMessage: 'Denies Policy Destination',
      },
      has: {
        id: 'app.global.relations.has',
        defaultMessage: 'Has',
      },
      hasDataStoreVersion: {
        id: 'app.global.relations.hasDataStoreVersion',
        defaultMessage: 'Has Data Store Version',
      },
      isInCountry: {
        id: 'app.global.relations.isInCountry',
        defaultMessage: 'Is In Country',
      },
      performedIn: {
        id: 'app.global.relations.performedIn',
        defaultMessage: 'Performed In',
      },
      containsCloudAccount: {
        id: 'app.global.relations.containsCloudAccount',
        defaultMessage: 'Contains Cloud Account',
      },
      scansAccount: {
        id: 'app.global.relations.scansAccount',
        defaultMessage: 'Scans Account',
      },
      containsAccount: {
        id: 'app.global.relations.containsAccount',
        defaultMessage: 'Contains Account',
      },
      variables: {
        id: 'app.global.relations.variables',
        defaultMessage: 'Variables',
      },
      isInAvailabilityZone: {
        id: 'app.global.relations.isInAvailabilityZone',
        defaultMessage: 'Is In Availability Zone',
      },
      isInRegion: {
        id: 'app.global.relations.isInRegion',
        defaultMessage: 'Is In Region',
      },
      isRegionOfMultiRegion: {
        id: 'app.global.relations.isRegionOfMultiRegion',
        defaultMessage: 'Is Region of Multi Region',
      },
      notIsRegionOfMultiRegion: {
        id: 'app.global.relations.notIsRegionOfMultiRegion',
        defaultMessage: 'Is Not Region of Multi Region',
      },
      hasDataType: {
        id: 'app.global.relations.hasDataType',
        defaultMessage: 'Has Data Type',
      },
      hasDataStoreType: {
        id: 'app.global.relations.hasDataStoreType',
        defaultMessage: 'Has Data Store Type',
      },
      isInSubdivision: {
        id: 'app.global.relations.isInSubdivision',
        defaultMessage: 'Is In Subdivision',
      },
      containsIdentity: {
        id: 'app.global.relations.containsIdentity',
        defaultMessage: 'Contains Identity',
      },
      ownsContent: {
        id: 'app.global.relations.ownsContent',
        defaultMessage: 'Owns Content',
      },
      accessedWith: {
        id: 'app.global.relations.accessedWith',
        defaultMessage: 'Accessed With',
      },
      hasPermissionList: {
        id: 'app.global.relations.hasPermissionList',
        defaultMessage: 'Has Permission List',
      },
      protects: {
        id: 'app.global.relations.protects',
        defaultMessage: 'Protects',
      },
      assumedRole: {
        id: 'app.global.relations.assumedRole',
        defaultMessage: 'Assumed Role',
      },
      isInCity: {
        id: 'app.global.relations.isInCity',
        defaultMessage: 'Is In City',
      },
      canAssume: {
        id: 'app.global.relations.canAssume',
        defaultMessage: 'Can Assume',
      },
      runsOn: {
        id: 'app.global.relations.runsOn',
        defaultMessage: 'Runs On',
      },
      cloneOf: {
        id: 'app.global.relations.cloneOf',
        defaultMessage: 'Clone Of',
      },
      isAttachedToAccessPolicy: {
        id: 'app.global.relations.isAttachedToAccessPolicy',
        defaultMessage: 'Is Attached To Access Policy',
      },
      performedAt: {
        id: 'app.global.relations.performedAt',
        defaultMessage: 'Performed At',
      },
      hasMFADevice: {
        id: 'app.global.relations.hasMFADevice',
        defaultMessage: 'Has MFA Device',
      },
      containsServiceType: {
        id: 'app.global.relations.containsServiceType',
        defaultMessage: 'Contains Service Type',
      },
      containsActionType: {
        id: 'app.global.relations.containsActionType',
        defaultMessage: 'Contains Action Type',
      },
      notIsAttachedTo: {
        id: 'app.global.relations.notIsAttachedTo',
        defaultMessage: 'Is Not Attached To',
      },
      notManages: {
        id: 'app.global.relations.notManages',
        defaultMessage: 'Does Not Manage',
      },
      notHasTag: {
        id: 'app.global.relations.notHasTag',
        defaultMessage: 'Does Not Have Tag',
      },
      notIsAttachedToSubnet: {
        id: 'app.global.relations.notIsAttachedToSubnet',
        defaultMessage: 'Is Not Attached To Subnet',
      },
      notIsAttachedToInterface: {
        id: 'app.global.relations.notIsAttachedToInterface',
        defaultMessage: 'Is Not Attached To Interface',
      },
      notIncludedInPolicy: {
        id: 'app.global.relations.notIncludedInPolicy',
        defaultMessage: 'Not Included In Policy',
      },
      notContains: {
        id: 'app.global.relations.notContains',
        defaultMessage: 'Does Not Contain',
      },
      notReferences: {
        id: 'app.global.relations.notReferences',
        defaultMessage: 'Does Not Reference',
      },
      notHasSnapshot: {
        id: 'app.global.relations.notHasSnapshot',
        defaultMessage: 'Does Not Have Snapshot',
      },
      notIsAttachedToBlockStorage: {
        id: 'app.global.relations.notIsAttachedToBlockStorage',
        defaultMessage: 'Is Not Attached To Block Storage',
      },
      notBasedOn: {
        id: 'app.global.relations.notBasedOn',
        defaultMessage: 'Not Based On',
      },
      notIsDefinedBy: {
        id: 'app.global.relations.notIsDefinedBy',
        defaultMessage: 'Is Not Defined By',
      },
      notHasClassification: {
        id: 'app.global.relations.notHasClassification',
        defaultMessage: 'Does Not Have Classification',
      },
      notIsAttachedToNetwork: {
        id: 'app.global.relations.notIsAttachedToNetwork',
        defaultMessage: 'Is Not Attached To Network',
      },
      notIncludedInNetwork: {
        id: 'app.global.relations.notIncludedInNetwork',
        defaultMessage: 'Not Included In Network',
      },
      notIsPartOf: {
        id: 'app.global.relations.notIsPartOf',
        defaultMessage: 'Is Not Part Of',
      },
      notIsPartOfPolicyEntry: {
        id: 'app.global.notIsPartOfPolicyEntry',
        defaultMessage: 'Is Not Part of Policy Entry',
      },
      notIsPartOfPolicyVersion: {
        id: 'app.global.notIsPartOfPolicyVersion',
        defaultMessage: 'Is Not Part of Policy Version',
      },
      notHasServiceType: {
        id: 'app.global.relations.notHasServiceType',
        defaultMessage: 'Does Not Have Service Type',
      },
      notHasPermission: {
        id: 'app.global.relations.notHasPermission',
        defaultMessage: 'Does Not Have Permission',
      },
      notDenies: {
        id: 'app.global.relations.notDenies',
        defaultMessage: 'Does Not Deny',
      },
      notHasActionType: {
        id: 'app.global.relations.notHasActionType',
        defaultMessage: 'Does Not Have Action Type',
      },
      notAllows: {
        id: 'app.global.relations.notAllows',
        defaultMessage: 'Does Not Allow',
      },
      notHasAccessToData: {
        id: 'app.global.relations.notHasAccessToData',
        defaultMessage: 'Does Not Have Access To Data',
      },
      notOrganizes: {
        id: 'app.global.relations.notOrganizes',
        defaultMessage: 'Does Not Organize',
      },
      notOrganizedBy: {
        id: 'app.global.relations.notOrganizedBy',
        defaultMessage: 'Not Organized By',
      },
      notAccessedProtection: {
        id: 'app.global.relations.notAccessedProtection',
        defaultMessage: 'Has Not Accessed Protection',
      },
      notHasAccessToNetworkPolicy: {
        id: 'app.global.relations.notHasAccessToNetworkPolicy',
        defaultMessage: 'Does Not Have Access To Network Policy',
      },
      notAppliesTo: {
        id: 'app.global.relations.notAppliesTo',
        defaultMessage: 'Does Not Apply To',
      },
      appliesToData: {
        id: 'app.global.relations.appliesToData',
        defaultMessage: 'Applies To Data',
      },
      notAppliesToData: {
        id: 'app.global.relations.notAppliesToData',
        defaultMessage: 'Does Not Apply To Data',
      },
      appliesToIdentity: {
        id: 'app.global.relations.appliesToIdentity',
        defaultMessage: 'Applies To Identity',
      },
      notAppliesToIdentity: {
        id: 'app.global.relations.notAppliesToIdentity',
        defaultMessage: 'Does Not Apply To Identity',
      },
      isAssignedTo: {
        id: 'app.global.relations.isAssignedTo',
        defaultMessage: 'Is Assigned To',
      },
      notIsAssignedTo: {
        id: 'app.global.relations.notIsAssignedTo',
        defaultMessage: 'Is Not Assigned To',
      },
      usedToAccess: {
        id: 'app.global.relations.usedToAccess',
        defaultMessage: 'Used To Access',
      },
      notUsedToAccess: {
        id: 'app.global.relations.notUsedToAccess',
        defaultMessage: 'Not Used To Access',
      },
      isClassificationOfServiceType: {
        id: 'app.global.relations.isClassificationOfServiceType',
        defaultMessage: 'Is Classification Of Service Type',
      },
      notIsClassificationOfServiceType: {
        id: 'app.global.relations.notIsClassificationOfServiceType',
        defaultMessage: 'Is Not Classification Of Service Type',
      },
      isAvailabilityZoneOf: {
        id: 'app.global.relations.isAvailabilityZoneOf',
        defaultMessage: 'Is Availability Zone Of',
      },
      notIsAvailabilityZoneOf: {
        id: 'app.global.relations.notIsAvailabilityZoneOf',
        defaultMessage: 'Is Not Availability Zone Of',
      },
      isCityOfLocation: {
        id: 'app.global.relations.isCityOfLocation',
        defaultMessage: 'Is City Of Location',
      },
      notIsCityOfLocation: {
        id: 'app.global.relations.notIsCityOfLocation',
        defaultMessage: 'Is Not City Of Location',
      },
      countryOfAction: {
        id: 'app.global.relations.countryOfAction',
        defaultMessage: 'Country Of Action',
      },
      notCountryOfAction: {
        id: 'app.global.relations.notCountryOfAction',
        defaultMessage: 'Not Country of Action',
      },
      isCountryOfRegion: {
        id: 'app.global.relations.isCountryOfRegion',
        defaultMessage: 'Is Country Of Region',
      },
      notIsCountryOfRegion: {
        id: 'app.global.relations.notIsCountryOfRegion',
        defaultMessage: 'Is Not Country of Region',
      },
      isCountryOfSubdivision: {
        id: 'app.global.relations.isCountryOfSubdivision',
        defaultMessage: 'Is Country Of Subdivision',
      },
      notIsCountryOfSubdivision: {
        id: 'app.global.relations.notIsCountryOfSubdivision',
        defaultMessage: 'Is Not Country of Subdivision',
      },
      isClassificationOfDataStore: {
        id: 'app.global.relations.isClassificationOfDataStore',
        defaultMessage: 'Is Classification Of Data Store',
      },
      notIsClassificationOfDataStore: {
        id: 'app.global.relations.notIsClassificationOfDataStore',
        defaultMessage: 'Is Not Classification Of Data Store',
      },
      isClassificationOfDataStoreType: {
        id: 'app.global.relations.isClassificationOfDataStoreType',
        defaultMessage: 'Is Classification Of Data Store Type',
      },
      notIsClassificationOfDataStoreType: {
        id: 'app.global.relations.notIsClassificationOfDataStoreType',
        defaultMessage: 'Is Not Classification Of Data Store Type',
      },
      appliesToDataStore: {
        id: 'app.global.relations.appliesToDataStore',
        defaultMessage: 'Applies To Data Store',
      },
      notAppliesToDataStore: {
        id: 'app.global.relations.notAppliesToDataStore',
        defaultMessage: 'Does Not Apply To Data Store',
      },
      managedBy: {
        id: 'app.global.relations.managedBy',
        defaultMessage: 'Managed By',
      },
      notManagedBy: {
        id: 'app.global.relations.notManagedBy',
        defaultMessage: 'Not Managed By',
      },
      isRegionOfAvailabilityZone: {
        id: 'app.global.relations.isRegionOfAvailabilityZone',
        defaultMessage: 'Is Region Of Availability Zone',
      },
      notIsRegionOfAvailabilityZone: {
        id: 'app.global.relations.notIsRegionOfAvailabilityZone',
        defaultMessage: 'Is Not Region Of Availability Zone',
      },
      isRegionOfResource: {
        id: 'app.global.relations.isRegionOfResource',
        defaultMessage: 'Is Region Of Resource',
      },
      notIsRegionOfResource: {
        id: 'app.global.relations.notIsRegionOfResource',
        defaultMessage: 'Is Not Region Of Resource',
      },
      isVersionOfDataStore: {
        id: 'app.global.relations.isVersionOfDataStore',
        defaultMessage: 'Is Version Of Data Store',
      },
      notIsVersionOfDataStore: {
        id: 'app.global.relations.notIsVersionOfDataStore',
        defaultMessage: 'Is Not Version Of Data Store',
      },
      isVersionOfDataStoreType: {
        id: 'app.global.relations.isVersionOfDataStoreType',
        defaultMessage: 'Is Version Of Data Store Type',
      },
      notIsVersionOfDataStoreType: {
        id: 'app.global.relations.notIsVersionOfDataStoreType',
        defaultMessage: 'Is Not Version Of Data Store Type',
      },
      locationOfAction: {
        id: 'app.global.relations.locationOfAction',
        defaultMessage: 'Location Of Action',
      },
      notLocationOfAction: {
        id: 'app.global.relations.notLocationOfAction',
        defaultMessage: 'Not Location Of Action',
      },
      isSubdivisionOfCity: {
        id: 'app.global.relations.isSubdivisionOfCity',
        defaultMessage: 'Is Subdivision Of City',
      },
      notIsSubdivisionOfCity: {
        id: 'app.global.relations.notIsSubdivisionOfCity',
        defaultMessage: 'Is Not Subdivision Of City',
      },
      tagsEntity: {
        id: 'app.global.relations.tagsEntity',
        defaultMessage: 'Tags Entity',
      },
      notTagsEntity: {
        id: 'app.global.relations.notTagsEntity',
        defaultMessage: 'Does Not Tag Entity',
      },
      notHasAccessToCompute: {
        id: 'app.global.relations.notHasAccessToCompute',
        defaultMessage: 'Has No Access To Compute',
      },
      notAccessedCompute: {
        id: 'app.global.relations.notAccessedCompute',
        defaultMessage: 'Has Not Accessed Compute',
      },
      notPerformed: {
        id: 'app.global.relations.notPerformed',
        defaultMessage: 'Has Not Performed',
      },
      notAccessedIdentity: {
        id: 'app.global.relations.notAccessedIdentity',
        defaultMessage: 'Did Not Access Identity',
      },
      notHasAccessToIdentity: {
        id: 'app.global.relations.notHasAccessToIdentity',
        defaultMessage: 'Has No Access To Identity',
      },
      notAccessedNetworkPolicy: {
        id: 'app.global.relations.notAccessedNetworkPolicy',
        defaultMessage: 'Has Not Accessed Network Policy',
      },
      notHasPrincipal: {
        id: 'app.global.relations.notHasPrincipal',
        defaultMessage: 'Does Not Have Principal',
      },
      notIsOwnerOf: {
        id: 'app.global.relations.notIsOwnerOf',
        defaultMessage: 'Is Not Owner Of',
      },
      notHasAccessToProtection: {
        id: 'app.global.relations.notHasAccessToProtection',
        defaultMessage: 'Has No Access To Protection',
      },
      notIsMemberOf: {
        id: 'app.global.relations.notIsMemberOf',
        defaultMessage: 'Is Not Member Of',
      },
      notAccessedData: {
        id: 'app.global.relations.notAccessedData',
        defaultMessage: 'Has Not Accessed Data',
      },
      notUsesPortrange: {
        id: 'app.global.relations.notUsesPortrange',
        defaultMessage: 'Does Not Use Portrange',
      },
      notUsesProtocol: {
        id: 'app.global.relations.notUsesProtocol',
        defaultMessage: 'Does Not Use Protocol',
      },
      notAllowsServiceTagDestination: {
        id: 'app.global.relations.notAllowsServiceTagDestination',
        defaultMessage: 'Does Not Allow Service Tag Destination',
      },
      notDeniesServiceTagSource: {
        id: 'app.global.relations.notDeniesServiceTagSource',
        defaultMessage: 'Does Not Denie Service Tag Source',
      },
      notAllowsPortRangeSource: {
        id: 'app.global.relations.notAllowsPortRangeSource',
        defaultMessage: 'Does Not Allow Port Range Source',
      },
      notDeniesServiceTagDestination: {
        id: 'app.global.relations.notDeniesServiceTagDestination',
        defaultMessage: 'Does Not Deny Service Tag Destination',
      },
      notDeniesPortRangeDestination: {
        id: 'app.global.relations.notDeniesPortRangeDestination',
        defaultMessage: 'Does Not Deny Port Range Destination',
      },
      notAllowsPolicyDestination: {
        id: 'app.global.relations.notAllowsPolicyDestination',
        defaultMessage: 'Does Not Allow Policy Destination',
      },
      notAllowsServiceTagSource: {
        id: 'app.global.relations.notAllowsServiceTagSource',
        defaultMessage: 'Does Not Allow Service Tag Source',
      },
      notDeniesPolicySource: {
        id: 'app.global.relations.notDeniesPolicySource',
        defaultMessage: 'Does Not Deny Policy Source',
      },
      notDeniesPortRangeSource: {
        id: 'app.global.relations.notDeniesPortRangeSource',
        defaultMessage: 'Does Not Deny Port Range Source',
      },
      notProtectsIngress: {
        id: 'app.global.relations.notProtectsIngress',
        defaultMessage: 'Does Not Protect Ingress',
      },
      notAllowsPolicySource: {
        id: 'app.global.relations.notAllowsPolicySource',
        defaultMessage: 'Does Not Allow Policy Source',
      },
      notAllowsPortRangeDestination: {
        id: 'app.global.relations.notAllowsPortRangeDestination',
        defaultMessage: 'Does Not AllowsPortRangeDestination',
      },
      notDeniesCIDRDestination: {
        id: 'app.global.relations.notDeniesCIDRDestination',
        defaultMessage: 'Does Not Deny CIDR Destination',
      },
      notDeniesProtocol: {
        id: 'app.global.relations.notDeniesProtocol',
        defaultMessage: 'Does Not Deny Protocol',
      },
      notDeniesCIDRSource: {
        id: 'app.global.relations.notDeniesCIDRSource',
        defaultMessage: 'Does Not Deny CIDR Source',
      },
      notAllowsProtocol: {
        id: 'app.global.relations.notAllowsProtocol',
        defaultMessage: 'Does Not Allow Protocol',
      },
      notAllowsCIDRDestination: {
        id: 'app.global.relations.notAllowsCIDRDestination',
        defaultMessage: 'Does Not Allow CIDR Destination',
      },
      notProtectsEgress: {
        id: 'app.global.relations.notProtectsEgress',
        defaultMessage: 'Does Not Protect Egress',
      },
      notAllowsCIDRSource: {
        id: 'app.global.relations.notAllowsCIDRSource',
        defaultMessage: 'Does Not Allow CIDR Source',
      },
      notDeniesPolicyDestination: {
        id: 'app.global.relations.notDeniesPolicyDestination',
        defaultMessage: 'Does Not Deny Policy Destination',
      },
      notHasPolicyVersion: {
        id: 'app.global.relations.notHasPolicyVersion',
        defaultMessage: 'Does Not Have Policy Version',
      },
      notHasPolicyEntry: {
        id: 'app.global.relations.notHasPolicyEntry',
        defaultMessage: 'Does Not Have Policy Entry',
      },
      notHas: {
        id: 'app.global.relations.notHas',
        defaultMessage: 'Does Not Have',
      },
      notHasDataStoreVersion: {
        id: 'app.global.relations.notHasDataStoreVersion',
        defaultMessage: 'Does Not Have Data Store Version',
      },
      notIsInCountry: {
        id: 'app.global.relations.notIsInCountry',
        defaultMessage: 'Is Not In Country',
      },
      notPerformedIn: {
        id: 'app.global.relations.notPerformedIn',
        defaultMessage: 'Not Performed In',
      },
      notContainsCloudAccount: {
        id: 'app.global.relations.notContainsCloudAccount',
        defaultMessage: 'Does Not Contain Cloud Account',
      },
      notScansAccount: {
        id: 'app.global.relations.notScansAccount',
        defaultMessage: 'Does Not Scan Account',
      },
      notContainsAccount: {
        id: 'app.global.relations.notContainsAccount',
        defaultMessage: 'Does Not Contain Account',
      },
      notVariables: {
        id: 'app.global.relations.notVariables',
        defaultMessage: 'Does Not Have Variables',
      },
      notIsInAvailabilityZone: {
        id: 'app.global.relations.notIsInAvailabilityZone',
        defaultMessage: 'Is Not In Availability Zone',
      },
      notIsInRegion: {
        id: 'app.global.relations.notIsInRegion',
        defaultMessage: 'Is Not In Region',
      },
      notHasDataType: {
        id: 'app.global.relations.notHasDataType',
        defaultMessage: 'Does Not Have Data Type',
      },
      notHasDataStoreType: {
        id: 'app.global.relations.notHasDataStoreType',
        defaultMessage: 'Does Not Have Data Store Type',
      },
      notIsInSubdivision: {
        id: 'app.global.relations.notIsInSubdivision',
        defaultMessage: 'Is Not In Subdivision',
      },
      notContainsIdentity: {
        id: 'app.global.relations.notContainsIdentity',
        defaultMessage: 'Does Not Contain Identity',
      },
      notOwnsContent: {
        id: 'app.global.relations.notOwnsContent',
        defaultMessage: 'Does Not Own Content',
      },
      notAccessedWith: {
        id: 'app.global.relations.notAccessedWith',
        defaultMessage: 'Not Accessed With',
      },
      notHasPermissionList: {
        id: 'app.global.relations.notHasPermissionList',
        defaultMessage: 'Does Not Have Permission List',
      },
      notProtects: {
        id: 'app.global.relations.notProtects',
        defaultMessage: 'Does Not Protect',
      },
      notPerformedOn: {
        id: 'app.global.relations.notPerformedOn',
        defaultMessage: 'Did Not Perform On',
      },
      notAssumedRole: {
        id: 'app.global.relations.notAssumedRole',
        defaultMessage: 'Did Not Assume Role',
      },
      notIsInCity: {
        id: 'app.global.relations.notIsInCity',
        defaultMessage: 'Is Not In City',
      },
      notCanAssume: {
        id: 'app.global.relations.notCanAssume',
        defaultMessage: 'Can Not Assume',
      },
      notRunsOn: {
        id: 'app.global.relations.notRunsOn',
        defaultMessage: 'Does Not Run On',
      },
      notCloneOf: {
        id: 'app.global.relations.notCloneOf',
        defaultMessage: 'Not Clone Of',
      },
      notIsAttachedToAccessPolicy: {
        id: 'app.global.relations.notIsAttachedToAccessPolicy',
        defaultMessage: 'Is Not Attached To Access Policy',
      },
      notPerformedAt: {
        id: 'app.global.relations.notPerformedAt',
        defaultMessage: 'Not Performed At',
      },
      notHasMFADevice: {
        id: 'app.global.relations.notHasMFADevice',
        defaultMessage: 'Does Not Have MFA Device',
      },
      notContainsServiceType: {
        id: 'app.global.relations.notContainsServiceType',
        defaultMessage: 'Does Not Contain Service Type',
      },
      notContainsActionType: {
        id: 'app.global.relations.notContainsActionType',
        defaultMessage: 'Does Not Contain Action Type',
      },
    }),
  },
  operations: {
    ...defineMessages({
      EQ: {
        id: 'app.operation.EQ',
        defaultMessage: 'Equals',
      },
      NEQ: {
        id: 'app.operation.NEQ',
        defaultMessage: 'Not Equals',
      },
      IS_NULL: {
        id: 'app.operation.IS_NULL',
        defaultMessage: 'Is Null',
      },
      IS_NOT_NULL: {
        id: 'app.operation.IS_NOT_NULL',
        defaultMessage: 'Is Not Null',
      },
      STARTS_WITH: {
        id: 'app.operation.STARTS_WITH',
        defaultMessage: 'Starts With',
      },
      ENDS_WITH: {
        id: 'app.operation.ENDS_WITH',
        defaultMessage: 'Ends With',
      },
      IN_LIST: {
        id: 'app.operation.IN_LIST',
        defaultMessage: 'In List',
      },
      NOT_IN_LIST: {
        id: 'app.operation.NOT_IN_LIST',
        defaultMessage: 'Not In List',
      },
      BETWEEN: {
        id: 'app.operation.BETWEEN',
        defaultMessage: 'Between',
      },
      NOT_BETWEEN: {
        id: 'app.operation.NOT_BETWEEN',
        defaultMessage: 'Not Between',
      },
      CONTAINS: {
        id: 'app.operation.CONTAINS',
        defaultMessage: 'Contains',
      },
      NOT_CONTAINS: {
        id: 'app.operation.NOT_CONTAINS',
        defaultMessage: 'Does Not Contain',
      },
      REGEX: {
        id: 'app.operation.REGEX',
        defaultMessage: 'Matches Regex',
      },
      NOT_REGEX: {
        id: 'app.operation.NOT_REGEX',
        defaultMessage: 'Does Not Match Regex',
      },
      GT: {
        id: 'app.operations.GT',
        defaultMessage: 'Greater Than',
      },
      GTE: {
        id: 'app.operations.GTE',
        defaultMessage: 'Greater Than or Equal To',
      },
      LT: {
        id: 'app.operation.LT',
        defaultMessage: 'Less Than',
      },
      LTE: {
        id: 'app.operation.LTE',
        defaultMessage: 'Less Than or Equal To',
      },
      TEXT_CONTAINS: {
        id: 'app.operation.TEXT_CONTAINS',
        defaultMessage: 'Text Contains',
      },
      TEXT_CONTAINS_REGEX: {
        id: 'app.operation.TEXT_CONTAINS_REGEX',
        defaultMessage: 'Text Contains Regex',
      },
      TEXT_CONTAINS_PREFIX: {
        id: 'app.operation.TEXT_CONTAINS_PREFIX',
        defaultMessage: 'Text Contains Prefix',
      },
      TEXT_FUZZY: {
        id: 'app.operation.TEXT_FUZZY',
        defaultMessage: 'Text Fuzzy',
      },
    }),
  },
  enumValues: {
    ...defineMessages({
      Action: {
        id: 'app.enumValues.Action',
        defaultMessage: 'Action',
      },
      Any: {
        id: 'app.enumValues.Any',
        defaultMessage: 'Any',
      },
      ACCESSKEY: {
        id: 'app.enumValues.ACCESSKEY',
        defaultMessage: 'Access Key',
      },
      ADMIN: {
        id: 'app.enumValues.ADMIN',
        defaultMessage: 'Admin',
      },
      ASSUMEDROLE: {
        id: 'app.enumValues.ASSUMEDROLE',
        defaultMessage: 'Assumed Role',
      },
      CONTAINER: {
        id: 'app.enumValues.CONTAINER',
        defaultMessage: 'Container',
      },
      DATASTORE: {
        id: 'app.enumValues.DATASTORE',
        defaultMessage: 'Data Store',
      },
      Data: {
        id: 'app.enumValues.Data',
        defaultMessage: 'Data',
      },
      GROUP: {
        id: 'app.enumValues.GROUP',
        defaultMessage: 'Group',
      },
      KEYSTORE: {
        id: 'app.enumValues.KEYSTORE',
        defaultMessage: 'Key Store',
      },
      Identity: {
        id: 'app.enumValues.Identity',
        defaultMessage: 'Identity',
      },
      Infrastructure: {
        id: 'app.enumValues.Infrastructure',
        defaultMessage: 'Infrastructure',
      },
      Object: {
        id: 'app.enumValues.OBJECT',
        defaultMessage: 'Object',
      },
      POLICY: {
        id: 'app.enumValues.POLICY',
        defaultMessage: 'Policy',
      },
      Protection: {
        id: 'app.enumValues.Protection',
        defaultMessage: 'Protection',
      },
      ROLE: {
        id: 'app.enumValues.ROLE',
        defaultMessage: 'Role',
      },
      USER: {
        id: 'app.enumValues.USER',
        defaultMessage: 'User',
      },
      VIRTUAL: {
        id: 'app.enumValues.VIRTUAL',
        defaultMessage: 'Virtual',
      },
      PHYSICAL: {
        id: 'app.enumValues.PHYSICAL',
        defaultMessage: 'Physical',
      },
      ZONE: {
        id: 'app.enumValues.ZONE',
        defaultMessage: 'Zone',
      },
      PLATFORM: {
        id: 'app.enumValues.PLATFORM',
        defaultMessage: 'Platform',
      },
      CLOUD: {
        id: 'app.enumValues.CLOUD',
        defaultMessage: 'Cloud',
      },
      POLICYENTRY: {
        id: 'app.enumValues.POLICYENTRY',
        defaultMessage: 'Policy Entry',
      },
      POLICYVERSION: {
        id: 'app.enumValues.POLICYVERSION',
        defaultMessage: 'Policy Version',
      },
      FEDERATEDUSER: {
        id: 'app.enumValues.FEDERATEDUSER',
        defaultMessage: 'Federated User',
      },
      ACCOUNT: {
        id: 'app.enumValues.ACCOUNT',
        defaultMessage: 'Account',
      },
      ORGANIZATION: {
        id: 'app.enumValues.ORGANIZATION',
        defaultMessage: 'Organization',
      },
      OBJECT: {
        id: 'app.enumValues.OBJECT',
        defaultMessage: 'Object',
      },
      SRCIP: {
        id: 'app.enumValues.SRCIP',
        defaultMessage: 'Source IP',
      },
      USERAGENT: {
        id: 'app.enumValues.USERAGENT',
        defaultMessage: 'User Agent',
      },
      REGION: {
        id: 'app.enumValues.REGION',
        defaultMessage: 'Region',
      },
      USERNAME: {
        id: 'app.enumValues.USERNAME',
        defaultMessage: 'Username',
      },
      OPERATION: {
        id: 'app.enumValues.OPERATION',
        defaultMessage: 'Operation',
      },
      PERMISSION: {
        id: 'app.enumValues.PERMISSION',
        defaultMessage: 'Permission',
      },
      ACTIONTYPE: {
        id: 'app.enumValues.ACCESSKEY',
        defaultMessage: 'Action Type',
      },
      SERVICE: {
        id: 'app.enumValues.ACCESSKEY',
        defaultMessage: 'Service',
      },
      AWSEVENT: {
        id: 'app.enumValues.AWSEVENT',
        defaultMessage: 'AWS Event',
      },
      AZUREEVENT: {
        id: 'app.enumValues.AZUREEVENT',
        defaultMessage: 'Azure Event',
      },
      GCPEVENT: {
        id: 'app.enumValues.GCPEVENT',
        defaultMessage: 'GCP Event',
      },
    }),
  },
  tickets: {
    ...defineMessages({
      ticketStatusNEW: {
        id: 'app.containers.Tickets.ticketStatusNEW',
        defaultMessage: 'New',
      },
      ticketStatusSNOOZED: {
        id: 'app.containers.Tickets.ticketStatusSNOOZED',
        defaultMessage: 'Snoozed',
      },
      ticketStatusRISK_ACCEPTED: {
        id: 'app.containers.Tickets.ticketStatusRISK_ACCEPTED',
        defaultMessage: 'Accepted Risk',
      },
      ticketStatusCLOSED: {
        id: 'app.containers.Tickets.ticketStatusCLOSED',
        defaultMessage: 'Closed',
      },
    }),
  },
}
