export default {
  container: {
    display: 'grid',
    gridTemplateAreas: '"nav header" "nav globalerror" "nav content"',
    gridTemplateColumns: '64px 1fr',
    gridTemplateRows: '60px auto 1fr',
    gridGap: '0px',
    height: '100vh',
    width: '100%',
  },
  headerContainer: {
    gridArea: 'header',
    zIndex: 9,
  },
  globalerrorContainer: {
    gridArea: 'globalerror',
  },
  navContainer: {
    gridArea: 'nav',
  },
  contentContainer: {
    gridArea: 'content',
    overflow: 'hidden',
  },
}
