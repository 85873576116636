/*
 *
 * DataClassifierkDataReducer reducer
 *
 */
import { fromJS, Map } from 'immutable'

import {
  FETCH_CUSTOM_CLASSIFIERS,
  SET_CUSTOM_CLASSIFIERS,
  SET_CUSTOM_CLASSIFIERS_ERROR,
  CREATE_CUSTOM_CLASSIFIER,
  CREATE_CUSTOM_CLASSIFIER_SUCCESS,
  CREATE_CUSTOM_CLASSIFIER_ERROR,
  UPDATE_CUSTOM_CLASSIFIERS,
  UPDATE_CUSTOM_CLASSIFIERS_SUCCESS,
  UPDATE_CUSTOM_CLASSIFIERS_ERROR,
  DELETE_CUSTOM_CLASSIFIERS,
  DELETE_CUSTOM_CLASSIFIERS_SUCCESS,
  DELETE_CUSTOM_CLASSIFIERS_ERROR,
} from './constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  fetchingClassifiers: false,
  customClassifiers: null,
  fetchingClassifiersError: null,
  creatingClassifier: false,
  creatingClassifierError: null,
  deletingCustomClassifiers: Map(),
  deletingCustomClassifiersError: null,
  updatingCustomClassifiers: Map(),
  updatingCustomClassifiersError: null,
})

const dataClassifierkDataReducer = handleActions(
  {
    [FETCH_CUSTOM_CLASSIFIERS]: state =>
      state
        .set('fetchingClassifiers', true)
        .set('fetchingClassifiersError', null),
    [SET_CUSTOM_CLASSIFIERS]: (state, { payload }) =>
      state
        .set('fetchingClassifiers', false)
        .set('fetchingClassifiersError', null)
        .set('customClassifiers', fromJS(payload.classifiers)),
    [SET_CUSTOM_CLASSIFIERS_ERROR]: (state, { payload }) =>
      state
        .set('fetchingClassifiers', false)
        .set('fetchingClassifiersError', payload.error),
    [CREATE_CUSTOM_CLASSIFIER]: state =>
      state
        .set('creatingClassifier', true)
        .set('creatingClassifierError', null),
    [CREATE_CUSTOM_CLASSIFIER_SUCCESS]: (state, { payload }) =>
      state
        .update('customClassifiers', cc => cc.push(fromJS(payload.classifier)))
        .set('creatingClassifier', false)
        .set('creatingClassifierError', null),
    [CREATE_CUSTOM_CLASSIFIER_ERROR]: (state, { payload }) =>
      state
        .set('creatingClassifier', false)
        .set('creatingClassifierError', payload.error),
    [DELETE_CUSTOM_CLASSIFIERS]: (state, { payload }) => {
      let deleting = state.get('deletingCustomClassifiers')
      payload.srnList.forEach(srn => (deleting = deleting.set(srn, true)))
      return state
        .set('deletingCustomClassifiers', deleting)
        .set('deletingCustomClassifiersError', null)
    },
    [DELETE_CUSTOM_CLASSIFIERS_SUCCESS]: (state, { payload }) => {
      let deleting = state.get('deletingCustomClassifiers')
      payload.delete.forEach(srn => (deleting = deleting.delete(srn)))
      return state
        .update('customClassifiers', ccs =>
          ccs.filter(cc => !payload.delete.includes(cc.get('srn')))
        )
        .set('deletingCustomClassifiers', deleting)
        .set('deletingCustomClassifiersError', null)
    },
    [DELETE_CUSTOM_CLASSIFIERS_ERROR]: (state, { payload }) => {
      let deleting = state.get('deletingCustomClassifiers')
      payload.srnList.forEach(srn => (deleting = deleting.delete(srn)))
      return state
        .set('deletingCustomClassifiers', deleting)
        .set('deletingCustomClassifiersError', payload.error)
    },
    [UPDATE_CUSTOM_CLASSIFIERS]: (state, { payload }) => {
      let updating = state.get('updatingCustomClassifiers')
      payload.srnList.forEach(srn => (updating = updating.set(srn, true)))
      return state
        .set('updatingCustomClassifiers', updating)
        .set('updatingCustomClassifiersError', null)
    },
    [UPDATE_CUSTOM_CLASSIFIERS_SUCCESS]: (state, { payload }) => {
      let updating = state.get('updatingCustomClassifiers')
      let customClassifiers = state
        .get('customClassifiers')
        .map(cc =>
          cc.get('srn') === payload.updated.srn ? fromJS(payload.updated) : cc
        )
      payload.srnList.forEach(srn => (updating = updating.delete(srn)))
      return state
        .set('customClassifiers', customClassifiers)
        .set('updatingCustomClassifiers', updating)
        .set('updatingCustomClassifiersError', null)
    },
    [UPDATE_CUSTOM_CLASSIFIERS_ERROR]: (state, { payload }) => {
      let updating = state.get('updatingCustomClassifiers')
      payload.srnList.forEach(srn => (updating = updating.delete(srn)))
      return state
        .set('updatingCustomClassifiers', updating)
        .set('updatingCustomClassifiersError', payload.error)
    },
  },
  initialState
)

export default dataClassifierkDataReducer
