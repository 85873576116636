export const getBreakpoint = ({ width, breakpoints }) => {
  if (width <= breakpoints.xs) {
    return 'xs'
  } else if (width <= breakpoints.sm) {
    return 'sm'
  } else if (width <= breakpoints.md) {
    return 'md'
  }

  return 'lg'
}

const breakpointOrder = ['xs', 'sm', 'md', 'lg']

export const getLayoutForChild = ({ child, width, breakpoints, layouts }) => {
  const breakpoint = getBreakpoint({ width, breakpoints })
  const breakpointIndex = breakpointOrder.indexOf(breakpoint)

  const layout = layouts[child.key]

  if (layout[breakpoint]) {
    return layout[breakpoint]
  } else {
    for (let i = breakpointIndex; i >= 0; i--) {
      const lowerBreakPoint = breakpointOrder[i]
      if (layout[lowerBreakPoint]) {
        return layout[lowerBreakPoint]
      }
    }
  }

  return {
    w: 1,
  }
}

export const getLayoutForBreakpoint = ({ width, breakpoints, layout }) => {
  const breakpoint = getBreakpoint({ width, breakpoints })
  const breakpointIndex = breakpointOrder.indexOf(breakpoint)

  if (layout[breakpoint]) {
    return layout[breakpoint]
  } else {
    for (let i = breakpointIndex; i >= 0; i--) {
      const lowerBreakPoint = breakpointOrder[i]
      if (layout[lowerBreakPoint]) {
        return layout[lowerBreakPoint]
      }
    }
  }

  return []
}
