import React from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export const EmptyText = ({ theme, children }) => {
  return (
    <span style={{ fontStyle: 'italic', color: theme.neutralDark }}>
      {children}
    </span>
  )
}

EmptyText.propTypes = {
  theme: themeShape,
  children: PropTypes.node,
}

export default themeable(EmptyText)
