import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CodeMirror from 'codemirror'
import 'codemirror/addon/runmode/runmode'
import 'codemirror/mode/javascript/javascript'
import CopyToClipBoard from 'components/CopyToClipboard'

const styles = {
  displayModeButton: {
    background: 'none',
    cursor: 'pointer',
    border: 'none',
    color: '#9a9a9a',
  },
  jsonDisplayContainer: {
    border: '1px solid #eee',
    overflow: 'auto',
    padding: '10px',
    marginTop: '-25px',
  },

  jsonDisplayControlsContainer: {
    padding: '0px 0px 8px 0px',
    textAlign: 'right',
  },
  jsonDisplayText: {
    fontFamily: '"Lucida Console", Monaco, monospace',
    fontSize: '11.5px',
  },
  jsonDisplayStyles: {
    property: {
      color: 'black',
    },
    string: {
      color: '#007ad9',
    },
  },
}

class JSONDisplay extends Component {
  render() {
    const json = JSON.stringify(this.props.content, null, 2)
    const elements = []
    let index = 0
    const pushElement = (token, classNames) => {
      const tokenStyles = (classNames || '')
        .split(' ')
        .map(className => {
          return styles.jsonDisplayStyles[className.trim()]
        })
        .filter(style => style)
        .reduce((allStyles, style) => {
          return {
            ...allStyles,
            ...style,
          }
        }, {})
      elements.push(
        <span className={classNames} style={tokenStyles} key={++index}>
          {token}
        </span>
      )
    }

    CodeMirror.runMode(
      json,
      {
        name: 'text/javascript',
        json: 'true',
      },
      (token, style) => {
        pushElement(token, style)
      }
    )

    return (
      <div style={{ overflow: 'auto' }}>
        <div
          className="json-display-control-container"
          style={styles.jsonDisplayControlsContainer}
        >
          <div
            style={{
              position: 'relative',
              top: '25px',
              right: '8px',
            }}
          >
            <CopyToClipBoard
              value={json}
              style={{
                ...styles.displayModeButton,
                marginTop: 'none',
              }}
            />
          </div>
        </div>
        <div
          className="json-display-content"
          style={styles.jsonDisplayContainer}
        >
          <pre style={styles.jsonDisplayText}>{elements}</pre>
        </div>
      </div>
    )
  }
}

JSONDisplay.propTypes = {
  content: PropTypes.object,
}

export default JSONDisplay
