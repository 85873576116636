/**
 *
 * QuickSearch
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import LoadingAnim from 'components/LoadingAnim'
import { List } from 'immutable'
import Error from 'components/Error'
import { push } from 'connected-react-router'

import Icon from 'components/Icon'
import TextLink from 'components/TextLink'
import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import TableLoader from 'components/TableLoader'
import NodeSolutionCenter from 'containers/NodeSolutionCenter'
import SlideoutPanel from 'components/SlideoutPanel'
import EmptySection from 'components/EmptySection'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getNodeViewPushParams } from 'utils/sonraiUtils'

import reducer from './reducer'
import sagas from './sagas'
import {
  selectResultLimitExceeded,
  selectQuickSearchResults,
  selectQuickSearchLoading,
  selectQuery,
  selectError,
} from './selectors'
import {
  fetchQuickSearch,
  clearQuickSearchResults,
  quickSearchLoading,
} from './actions'

export class QuickSearch extends React.Component {
  constructor(props) {
    super(props)

    this.props.fetchQuickSearch(props.query)
    this.state = {
      showPanel: false,
      type: null,
      nodeId: null,
    }

    this.styles = {
      container: {
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateAreas: "'results'",
        overflow: 'hidden',
        height: '100%',
      },
      containerWithPanel: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridTemplateAreas: "'results details'",
        overflow: 'hidden',
        height: '100%',
      },
      resultsColumn: {
        gridArea: 'results',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridRowGap: '0.5em',
        overflow: 'hidden',
      },
      panelColumn: {
        gridArea: 'details',
        paddingLeft: '1em',
      },
      header: {
        padding: '1em',
        borderBottom: '1px solid #eee',
        fontSize: '1.3em',
      },
      queryText: {
        fontWeight: 400,
      },
      resultLimitExceeded: {
        color: '#555',
      },
      content: {
        overflow: 'auto',
        padding: '0 1em',
      },
      resultsCard: {
        height: '45%',
        minHeight: '500px',
        margin: '0.5em 0',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflowX: 'auto',
      },
      cardTitle: {
        fontSize: '22px',
        fontWeight: '300',
      },
      mainCard: {
        margin: '1em',
      },
    }
  }

  componentWillUnmount() {
    this.props.clearQuickSearchResults()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.query !== this.props.query) {
      this.props.fetchQuickSearch(this.props.query)
      this.setState({ showPanel: false })
    }
  }

  onClickNodeView = (nodeId, type) => {
    this.setState({ showPanel: true, type, nodeId })
  }

  getHasNoResults = () => {
    return (
      this.props.quicksearch.isEmpty() ||
      (this.props.quicksearch
        .getIn(['Identities', 'items'], List())
        .isEmpty() &&
        this.props.quicksearch.getIn(['Data', 'items'], List()).isEmpty() &&
        this.props.quicksearch
          .getIn(['Infrastructure', 'items'], List())
          .isEmpty() &&
        this.props.quicksearch.getIn(['Protection', 'items'], List()).isEmpty())
    )
  }

  getTables = () => {
    const identities = this.props.quicksearch.getIn(
      ['Identities', 'items'],
      List()
    )
    const data = this.props.quicksearch.getIn(['Data', 'items'], List())
    const infrastructures = this.props.quicksearch.getIn(
      ['Infrastructure', 'items'],
      List()
    )
    const protection = this.props.quicksearch.getIn(
      ['Protection', 'items'],
      List()
    )

    const hiddenCols = [
      'resourceId',
      'loadId',
      'sid',
      'srn',
      'observedDate',
      'modifiedDate',
      'createdBy',
    ]

    return (
      <div style={this.styles.resultsColumn}>
        <div style={this.styles.header}>
          Displaying Search Results for:{' '}
          <span style={this.styles.queryText}> {this.props.query} </span>
          {this.props.resultLimitExceeded && (
            <p style={this.styles.resultLimitExceeded}>
              This search has 500 or more results
            </p>
          )}
        </div>

        <div style={this.styles.content}>
          {!identities.isEmpty() && (
            <BorderedCard style={this.styles.resultsCard}>
              <div style={this.styles.cardTitle}>
                <Icon fa name="user" />
                &nbsp;{' '}
                {`Identities (${
                  identities.size >= 500 ? `500 or more` : identities.size
                })`}
              </div>
              <DataTable
                onSingleClickNodeView={this.onClickNodeView}
                data={identities.toJS()}
                hiddenColumns={hiddenCols}
              />
            </BorderedCard>
          )}

          {identities.isEmpty() && this.props.isLoading && (
            <BorderedCard style={this.styles.resultsCard}>
              <div style={this.styles.cardTitle}>
                <Icon fa name="user" />
                &nbsp;Identities
              </div>
              <TableLoader />
            </BorderedCard>
          )}

          {!data.isEmpty() && (
            <BorderedCard style={this.styles.resultsCard}>
              <div style={this.styles.cardTitle}>
                <Icon fa name="data" />
                &nbsp;{' '}
                {`Data (${data.size >= 500 ? `500 or more` : data.size})`}
              </div>
              <DataTable
                onSingleClickNodeView={this.onClickNodeView}
                data={data.toJS()}
                hiddenColumns={hiddenCols}
              />
            </BorderedCard>
          )}

          {data.isEmpty() && this.props.isLoading && (
            <BorderedCard style={this.styles.resultsCard}>
              <div style={this.styles.cardTitle}>
                <Icon fa name="data" />
                &nbsp; Data
              </div>
              <TableLoader />
            </BorderedCard>
          )}

          {!infrastructures.isEmpty() && (
            <BorderedCard style={this.styles.resultsCard}>
              <div style={this.styles.cardTitle}>
                <Icon fa name="network" />
                &nbsp;
                {`Infrastructure (${
                  infrastructures.size >= 500
                    ? `500 or more`
                    : infrastructures.size
                })`}
              </div>
              <DataTable
                onSingleClickNodeView={this.onClickNodeView}
                data={infrastructures.toJS()}
                hiddenColumns={hiddenCols}
              />
            </BorderedCard>
          )}

          {infrastructures.isEmpty() && this.props.isLoading && (
            <BorderedCard style={this.styles.resultsCard}>
              <div style={this.styles.cardTitle}>
                <Icon fa name="network" />
                &nbsp; Infrastructure
              </div>
              <TableLoader />
            </BorderedCard>
          )}

          {!protection.isEmpty() && (
            <BorderedCard style={this.styles.resultsCard}>
              <div style={this.styles.cardTitle}>
                <Icon fa name="network" />
                &nbsp;
                {`Protection (${
                  protection.size >= 500 ? `500 or more` : protection.size
                })`}
              </div>
              <DataTable
                onSingleClickNodeView={this.onClickNodeView}
                data={protection.toJS()}
                hiddenColumns={hiddenCols}
              />
            </BorderedCard>
          )}

          {protection.isEmpty() && this.props.isLoading && (
            <BorderedCard style={this.styles.resultsCard}>
              <div style={this.styles.cardTitle}>
                <Icon fa name="key" />
                &nbsp; Protection
              </div>
              <TableLoader />
            </BorderedCard>
          )}
        </div>
      </div>
    )
  }

  goToNodeView = () => {
    const nav = getNodeViewPushParams(this.state.nodeId, this.state.nodeType)
    return nav
  }

  render() {
    if (this.props.error) {
      return (
        <BorderedCard style={this.styles.mainCard}>
          <Error
            errorMessage={this.props.error}
            description="There was an error loading the data"
          />
        </BorderedCard>
      )
    }

    if (this.props.isLoading && this.getHasNoResults()) {
      return (
        <div style={this.styles.container}>
          <TableLoader />
        </div>
      )
    }

    if (this.getHasNoResults()) {
      return (
        <div style={this.styles.container}>
          <EmptySection>No results for query: {this.props.query}</EmptySection>
        </div>
      )
    }

    if (
      this.props.quicksearch === undefined ||
      this.props.quicksearch.isEmpty()
    ) {
      return <LoadingAnim />
    }

    return (
      <div
        style={
          this.state.showPanel
            ? this.styles.containerWithPanel
            : this.styles.container
        }
      >
        {this.getTables()}

        <SlideoutPanel
          styles={this.styles.panelColumn}
          visible={this.state.showPanel}
          toggleVisible={() => this.setState({ showPanel: false })}
          actions={
            <TextLink color="primary" to={this.goToNodeView} newTab>
              Go to full node view
            </TextLink>
          }
        >
          {this.state.showPanel && (
            <NodeSolutionCenter
              type={this.state.type}
              nodeId={this.state.nodeId}
              horizontal
            />
          )}
        </SlideoutPanel>
      </div>
    )
  }
}

QuickSearch.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  quicksearch: ImmutablePropTypes.map,
  fetchQuickSearch: PropTypes.func,
  resultLimitExceeded: PropTypes.bool,
  clearQuickSearchResults: PropTypes.func,
  query: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  error: selectError,
  isLoading: selectQuickSearchLoading,
  quicksearch: selectQuickSearchResults,
  query: selectQuery,
  resultLimitExceeded: selectResultLimitExceeded,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchQuickSearch,
      quickSearchLoading,
      clearQuickSearchResults,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({
  key: 'quicksearch',
  reducer: reducer,
})
const withSaga = injectSaga({ key: 'quicksearch', saga: sagas })

export default compose(withConnect, withReducer, withSaga)(QuickSearch)
