import React from 'react'
import PropTypes from 'prop-types'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import TextLink from 'components/TextLink'
import Hoverable from 'components/Hoverable'
import { TitleShimmer } from 'components/ShimmerLoader'
import CopyToClipboard from 'components/CopyToClipboard'
import ReactTooltip from 'react-tooltip'
import uuid from 'uuid/v4'

class HeaderLabelLink extends React.PureComponent {
  constructor(props) {
    super(props)
    this.tooltipId = uuid()
  }

  styles = {
    clipboardButton: {
      marginBottom: '5px',
      width: '32px',
    },
    textStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '30px',
      marginTop: '-3px',
    },
    stayInHereYouPieceOfTrash: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }

  getTooltop = srn => {
    return (
      <ReactTooltip
        id={this.tooltipId}
        place="bottom"
        effect="solid"
        clickable={true}
      >
        <span>{srn}</span>
      </ReactTooltip>
    )
  }

  render() {
    let name = ''
    if (!this.props.loading) {
      name = this.props.data.friendlyName
        ? this.props.data.friendlyName
        : this.props.data.name
    }

    return (
      <div style={this.styles.stayInHereYouPieceOfTrash}>
        {this.props.loading ? (
          <TitleShimmer />
        ) : (
          <div>
            {name ? (
              <Hoverable
                hoverStyle={{ cursor: 'default' }}
                renderContent={({ hovered }) => (
                  <div style={this.styles.textStyle}>
                    <div data-tip data-for={this.tooltipId}>
                      {this.props.noLink ? (
                        name
                      ) : (
                        <TextLink
                          to={getNodeViewPushParams(
                            this.props.data.srn,
                            getTypeFromSrn(this.props.data.srn)
                          )}
                        >
                          {name}
                        </TextLink>
                      )}
                    </div>
                    {hovered && this.props.copy ? (
                      <div style={this.styles.clipboardButton}>
                        <CopyToClipboard value={this.props.data.srn} />
                      </div>
                    ) : (
                      <div style={this.styles.clipboardButton} />
                    )}
                  </div>
                )}
              />
            ) : (
              `-`
            )}
            {this.props.tooltip && this.getTooltop(this.props.tooltip)}
          </div>
        )}
      </div>
    )
  }
}

HeaderLabelLink.propTypes = {
  tooltip: PropTypes.string,
  data: PropTypes.any,
  copy: PropTypes.bool,
  noLink: PropTypes.bool,
  loading: PropTypes.bool,
}

export default HeaderLabelLink
