export default {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  detail: {},
  key: {
    fontSize: '0.95rem',
    color: '#485460',
    fontWeight: 400,
  },
  code: {
    fontFamily: 'monospace',
    fontSize: '1.1rem',
  },
  codeBlock: {
    backgroundColor: '#fafafa',
    fontFamily: 'monospace',
    fontSize: '1.1rem',
    marginBottom: '0.5em',
  },
  emptyCard: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
