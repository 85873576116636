import { defineMessages } from 'react-intl'

export default defineMessages({
  classificationLabel: {
    id:
      'app.container.NodeViewLayouts.ActionTypeNodeViewCardLayout.classificationLabel',
    defaultMessage: 'Action Classification',
  },
  cloudTypeLabel: {
    id:
      'app.container.NodeViewLayouts.ActionTypeNodeViewCardLayout.cloudTypeLabel',
    defaultMessage: 'Cloud Type',
  },
  nodeViewType: {
    id: 'app.container.NodeViewLayouts.ActionTypeNodeViewCardLayout.actiontype',
    defaultMessage: 'Actiontype',
  },
  permissionIdsTitle: {
    id:
      'app.container.NodeViewLayouts.ActionTypeNodeViewCardLayout.permissionIdsTitle',
    defaultMessage: 'Identites With Permissions',
  },
  executedIdsTitle: {
    id:
      'app.container.NodeViewLayouts.ActionTypeNodeViewCardLayout.executedIdsTitle',
    defaultMessage: 'Identities Executed (Last 7 Days)',
  },
})
