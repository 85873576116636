import poster from 'assets/poster.png'

export default {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr 60px',
    gridTemplateRows: '100px 1fr 60px',
    height: '100vh',
    width: '100vw',
    background: `url(${poster})`,
    repeatStyle: 'no-repeat',
    backgroundSize: 'cover',
  },
  header: {
    gridColumn: '1 / -1',
    clear: 'both',
    width: '100%',
    zIndex: '2',
  },
  content: {
    gridColumn: '2',
    minHeight: '450px',
    width: '100%',
    zIndex: '2',
  },
  gradient: {
    zIndex: '1',
    opacity: '0.75',
    backgroundImage:
      'linear-gradient(180deg, #537895 0%, #32516F 44%, #09203F 100%)',
  },
  center: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto',
    height: 'auto',
    transform: 'translateX(-50%) translateY(-50%)',
    backgroundSize: 'cover',
    background: `url(${poster})`,
    repeatStyle: 'no-repeat',
  },
  logo: {
    width: '195px',
    height: '58px',
    float: 'left',
    margin: '1em',
  },
  footer: {
    gridColumn: '1 / -1',
    clear: 'both',
    width: '100%',
    zIndex: '2',
  },
}
