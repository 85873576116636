import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { createStructuredSelector } from 'reselect'
import { Map } from 'immutable'

import { NodeViewTabs } from 'components/NodeView'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import DetailsTab from 'containers/TicketDetails/components/DetailsTab'
import TicketCommentsTab from 'containers/TicketDetails/components/TicketCommentsTab'
import TicketHistoryTab from 'containers/TicketDetails/components/TicketHistoryTab'
import { selectChangeDetectionOptions } from 'containers/Tickets/selectors'
import SimilarTicketsTab from './components/SimilarTicketsTab'

import {
  fetchPolicyEvidenceLegacy,
  fetchPolicyPath,
} from 'containers/TicketDetailsData/actions'

import {
  selectPolicies,
  selectControlGroups,
} from 'containers/ControlFrameworkData/selectors'
import {
  selectSwimlanes,
  selectSwimlanesLoading,
  selectSavedSearches,
  selectSonraiSearches,
} from 'containers/SonraiData/selectors'
import {
  selectPolicyEvidenceLegacy,
  selectTicketComments,
  selectPolicyPath,
} from 'containers/TicketDetailsData/selectors'

export class TicketDetailsTabs extends Component {
  constructor(props, context) {
    super(props, context)
    if (this.requiresLegacyPolicyEvidence()) {
      props.fetchPolicyEvidenceLegacy({ ticket: props.ticket.toJS() })
    }
    if (!props.policies.isEmpty()) {
      const policy = props.policies.get(props.ticket.get('ticketKey')) || Map()
      props.fetchPolicyPath({
        resourceSrn: props.ticket.get('resourceSRN'),
        searchId: policy.getIn(['contains', 'items', 0, 'sid']),
      })
    }
  }

  style = {
    container: {
      minHeight: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
    },
    tabContainer: {
      height: '100%',
      padding: '1rem',
      backgroundColor: this.props.theme.light,
      borderTop: 0,
      overflow: 'auto',
      borderBottom: `1px solid ${this.props.theme.neutralMedium}`,
      borderLeft: `1px solid ${this.props.theme.neutralMedium}`,
      borderRight: `1px solid ${this.props.theme.neutralMedium}`,
    },
  }

  requiresLegacyPolicyEvidence() {
    if (
      'Policy' === this.props.ticket.get('ticketType') &&
      null === this.props.ticket.getIn(['evidence', 'policyEvidence'], null)
    ) {
      return true
    }
    return false
  }

  render() {
    return (
      <NodeViewTabs style={this.style.container}>
        <div label="Details">
          <div style={this.style.tabContainer}>
            <DetailsTab
              ticket={this.props.ticket}
              policies={this.props.policies}
              controlGroups={this.props.controlGroups}
              advancedSearches={this.props.advancedSearches}
              searches={this.props.searches}
              policyEvidenceLegacy={this.props.policyEvidenceLegacy}
              useLegacyPolicyEvidence={this.requiresLegacyPolicyEvidence()}
              policyPath={this.props.policyPath}
              showRemediationModal={this.props.showRemediationModal}
            />
          </div>
        </div>
        <div label={'Comments'}>
          <div style={this.style.tabContainer}>
            <TicketCommentsTab ticket={this.props.ticket} />
          </div>
        </div>
        <div label="Similar Tickets">
          <div style={this.style.tabContainer}>
            <SimilarTicketsTab
              changeDetectionOptions={this.props.changeDetectionOptions}
              ticket={this.props.ticket}
              policies={this.props.policies}
              swimlanes={this.props.swimlanes}
              swimlanesLoading={this.props.swimlanesLoading}
            />
          </div>
        </div>

        <div label="History">
          <div style={this.style.tabContainer}>
            <TicketHistoryTab ticket={this.props.ticket} />
          </div>
        </div>
      </NodeViewTabs>
    )
  }
}

TicketDetailsTabs.propTypes = {
  ticket: ImmutablePropTypes.iterable.isRequired,
  theme: themeShape,
  policies: ImmutablePropTypes.iterable.isRequired,
  searches: ImmutablePropTypes.map,
  policyPath: ImmutablePropTypes.map,
  advancedSearches: ImmutablePropTypes.iterable,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  swimlanesLoading: PropTypes.bool,
  controlGroups: ImmutablePropTypes.iterable.isRequired,
  changeDetectionOptions: ImmutablePropTypes.iterable.isRequired,
  fetchPolicyEvidenceLegacy: PropTypes.func.isRequired,
  fetchPolicyPath: PropTypes.func,
  policyEvidenceLegacy: ImmutablePropTypes.map,
  showRemediationModal: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  ticketComments: selectTicketComments,
  policies: selectPolicies,
  controlGroups: selectControlGroups,
  searches: selectSavedSearches,
  advancedSearches: selectSonraiSearches,
  swimlanes: selectSwimlanes,
  swimlanesLoading: selectSwimlanesLoading,
  changeDetectionOptions: selectChangeDetectionOptions,
  policyEvidenceLegacy: selectPolicyEvidenceLegacy,
  policyPath: selectPolicyPath,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push,
      fetchPolicyEvidenceLegacy,
      fetchPolicyPath,
    },
    dispatch
  )

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(themeable(TicketDetailsTabs))
