import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DataTable from 'components/DataTable'
import messages from 'containers/NodeSolutionCenter/messages'
import { FormattedMessage } from 'react-intl'
import { getFriendlyKeyName } from 'utils/widgetUtils'

class EnableChangeDetection extends Component {
  style = {
    table: {
      height: '50vh',
      width: '500px',
      margin: '0 auto',
    },
  }

  render() {
    return (
      <div>
        <p>
          <FormattedMessage {...messages.enableChangeDetectionMessage} />
        </p>
        <div style={this.style.table}>
          <DataTable
            data={this.props.data}
            customColumnConfig={{
              keyName: {
                suppressMenu: true,
                width: 500,
                valueFormatter: params => {
                  return getFriendlyKeyName(params.value)
                },
              },
            }}
            multiRowSelect
            hasCheckBoxes
            selectionChanged={this.props.selectionChanged}
            autosize={false}
          />
        </div>
      </div>
    )
  }
}

EnableChangeDetection.propTypes = {
  selectionChanged: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
}

export default EnableChangeDetection
