import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

const selectRoleNodeViewCardLayoutDomain = state =>
  state.get('roleNodeViewCardLayout', Map())

export const selectActionsPerformed = createSelector(
  selectRoleNodeViewCardLayoutDomain,
  state => state.get('actionsPerformed') || List()
)

export const selectIsLoadingActions = createSelector(
  selectRoleNodeViewCardLayoutDomain,
  state => state.get('loadingActions')
)

export const selectRoleContent = createSelector(
  selectRoleNodeViewCardLayoutDomain,
  state => state.get('roleContent') || Map()
)

export const selectIsLoading = createSelector(
  selectRoleNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
