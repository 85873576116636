import { all, put, takeLatest, call } from 'redux-saga/effects'
import { fromJS, List, Map } from 'immutable'
import _ from 'lodash'
import {
  FETCH_ALL_TYPES_RELATIONS_QUERY,
  FETCH_ACTIONS_RELATIONS_QUERY,
  MULTI_FETCH_ALL_TYPES_RELATIONS_QUERY,
  FETCH_ACCOUNT_SOURCE_NODES_QUERY,
  GET_RELATIONS_QUERY,
  DEFAULT_EXPLORER_LIMIT,
} from 'static-queries'

import {
  GET_SOURCE_NODE,
  GET_RELATIONS,
  GET_NODE_PREVIEW,
  GET_NODE_PREVIEW_RELATIONS,
  HANDLE_EXPAND,
  GET_MULTI_SOURCE_NODES,
} from './constants'
import { getClient } from 'apolloClient'
import {
  setSourceNodes,
  setRelations,
  setNodePreview,
  setNodePreviewRelations,
} from './actions'
import gql from 'graphql-tag'
import { exists } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils.js'

const uniqByLabel = relations => {
  let uniqByLabel = {}
  relations.forEach(result => {
    if (uniqByLabel[result.label]) {
      uniqByLabel[result.label] = [
        ...uniqByLabel[result.label],
        result.relation,
      ]
    } else {
      uniqByLabel[result.label] = [result.relation]
    }
  })
  return uniqByLabel
}

function* getRelationsOnType(action) {
  const arrOfExpandableNodeTypes = action.payload
  const listOfSrns = [].concat(
    ...arrOfExpandableNodeTypes.map(x => x.listOfSrnsToExpand)
  )

  const results = yield all(
    listOfSrns.map(node => call(fetchRelationsOnSourceNodes, node, true))
  )

  const resultsToDisplay = results.map(result => ({
    relations: uniqByLabel(result.relations),
    srn: result.srn,
  }))

  yield all(
    resultsToDisplay.map(result =>
      _.keys(result.relations).map(key =>
        put(
          setRelations({
            srn: result.srn,
            relations: result.relations[key],
            label: key,
          })
        )
      )
    )
  )
}

function* fetchRelationsOnSourceNodes(node, uniqByLabel) {
  const client = getClient()
  const results = yield client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query fetchAllRelations {
        Entities(where: { srn: { value: "${node}" } }) {
          items {
            label
            srn
            ... on Resource { 
                name
            }
            relations(limit: ${DEFAULT_EXPLORER_LIMIT}) {
              item {
                srn
                label
                ... on Resource {
                  name
                  friendlyName
                }
              }
              relation {
                direction
                name
              }
            }
          }
        }
      }
    `,
  })

  const entity = _.get(results, ['data', 'Entities', 'items', 0], {})
  const entityRelations = _.get(entity, ['relations'], [])

  const relations = uniqByLabel
    ? entityRelations.map(rel => ({
        label: rel.item.label,
        parent: node,
        relation: rel,
      }))
    : entityRelations

  return {
    srn: node,
    label: entity.__typename,
    relations,
  }
}

function* getRelationsOnNodePreview(action) {
  try {
    const client = getClient()
    const query =
      'Action' === getTypeFromSrn(action.payload)
        ? FETCH_ACTIONS_RELATIONS_QUERY
        : FETCH_ALL_TYPES_RELATIONS_QUERY
    const results = yield client.query({
      fetchPolicy: 'no-cache',
      query: gql`
        ${query}
      `,
      variables: {
        sourceNodeId: action.payload,
      },
    })

    if (results.data.Entities.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setNodePreviewRelations(results.data.Entities.items[0].relations))
  } catch (e) {
    console.error(`${e}`) //eslint-disable-line no-console
  }
}

function* getMultiSourceNodes({ payload }) {
  const client = getClient()
  try {
    const result = yield client.query({
      query: gql`
        ${MULTI_FETCH_ALL_TYPES_RELATIONS_QUERY}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        sourceNodeIds: payload.ids,
      },
    })
    const nodes = _.get(result, ['data', 'Entities', 'items'])

    yield put(setSourceNodes({ nodes: nodes, search: false }))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`${e}`)
  }
}

function* getSourceNode(action) {
  const client = getClient()

  let result
  if (exists(action.payload.id)) {
    const query =
      'Action' === getTypeFromSrn(action.payload.id)
        ? FETCH_ACTIONS_RELATIONS_QUERY
        : FETCH_ALL_TYPES_RELATIONS_QUERY
    result = yield client.query({
      query: gql`
        ${query}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        sourceNodeId: action.payload.id,
      },
    })
  } else {
    result = yield client.query({
      query: gql`
        ${FETCH_ACCOUNT_SOURCE_NODES_QUERY}
      `,
      fetchPolicy: 'no-cache',
    })
  }

  if (result.data[_.keys(result.data)[0]].items.length > 0) {
    const node = result.data[_.keys(result.data)[0]].items[0]
    yield put(setSourceNodes({ nodes: [node], search: false }))

    let groupedRelations = Map()
    node.relations.forEach(relation => {
      const typename = relation.item.__typename
      const newRelation = fromJS({ parent: node.srn, ...relation })

      if (!groupedRelations.get(typename)) {
        groupedRelations = groupedRelations.set(typename, List([newRelation]))
      } else {
        groupedRelations = groupedRelations.update(typename, typeRelations =>
          typeRelations.push(newRelation)
        )
      }
    })

    yield all(
      groupedRelations
        .map((typedRelations, label) =>
          put(
            setRelations({
              srn: node.srn,
              relations: typedRelations,
              label: label,
            })
          )
        )
        .toJS()
    )
  }
}

function* getRelations({ payload }) {
  const { srn, itemFilter } = payload
  const query = gql`
    ${GET_RELATIONS_QUERY}
  `

  const client = getClient()

  try {
    const result = yield client.query({
      query,
      variables: { srn, itemFilter, limit: DEFAULT_EXPLORER_LIMIT },
      fetchPolicy: 'no-cache',
    })

    if (result.data.Entities.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const relations = result.data.Entities.items[0].relations
    yield put(setRelations({ srn, relations, label: itemFilter.label.value }))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting relationships for node', e.message)
    yield put(
      setRelations({ srn, relations: [], label: itemFilter.label.value })
    )
  }
}

function* getNodePreview(action) {
  const query = action.payload.query
  const variables = action.payload.variables

  const client = getClient()
  const result = yield client.query({
    query,
    variables,
    fetchPolicy: 'no-cache',
  })

  yield put(setNodePreview(result.data.Resources.items[0]))
}

function* explorerSaga() {
  yield all([
    takeLatest(GET_RELATIONS, getRelations),
    takeLatest(GET_SOURCE_NODE, getSourceNode),
    takeLatest(GET_MULTI_SOURCE_NODES, getMultiSourceNodes),
    takeLatest(GET_NODE_PREVIEW, getNodePreview),
    takeLatest(GET_NODE_PREVIEW_RELATIONS, getRelationsOnNodePreview),
    takeLatest(HANDLE_EXPAND, getRelationsOnType),
  ])
}

export default explorerSaga
