import React from 'react'
import PropTypes from 'prop-types'
import TooltipTrigger from 'react-popper-tooltip'

import PopoverBody from './PopoverBody'
import PopoverAnchor from './PopoverAnchor'

export class Popover extends React.Component {
  render() {
    return (
      <TooltipTrigger
        onVisibilityChange={this.props.onToggle}
        tooltipShown={this.props.isOpen}
        placement={this.props.position}
        trigger={this.props.trigger}
        tooltip={({
          arrowRef,
          tooltipRef,
          getArrowProps,
          getTooltipProps,
          placement,
        }) => (
          <div
            {...getTooltipProps({
              ref: tooltipRef,
              className: this.props.borderless
                ? 'tooltip-container-noborder'
                : 'tooltip-container',
            })}
          >
            {!this.props.borderless && (
              <div
                {...getArrowProps({
                  ref: arrowRef,
                  className: 'tooltip-arrow',
                  'data-placement': placement,
                })}
              />
            )}
            {this.props.children.find(({ type }) => type === PopoverBody)}
          </div>
        )}
      >
        {({ getTriggerProps, triggerRef }) => (
          <div
            {...getTriggerProps({
              ref: triggerRef,
              className: 'tooltip-trigger',
            })}
            style={this.props.style}
          >
            {this.props.children.find(({ type }) => type === PopoverAnchor)}
          </div>
        )}
      </TooltipTrigger>
    )
  }
}

Popover.defaultProps = {
  position: 'auto',
  trigger: 'click',
}

Popover.propTypes = {
  borderless: PropTypes.bool,
  children: PropTypes.node,
  onToggle: PropTypes.func,
  position: PropTypes.oneOf([
    'auto',
    'left',
    'right',
    'bottom',
    'top',
    'right-start',
    'right-end',
    'left-start',
    'left-end',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
  ]),
  style: PropTypes.object,
  trigger: PropTypes.oneOf(['click', 'hover', 'right-click', 'none']),
  isOpen: PropTypes.bool,
}

export default Popover
export { default as PopoverAnchor } from './PopoverAnchor'
export { default as PopoverBody } from './PopoverBody'
