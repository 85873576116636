import {
  GET_PERFORMED_ACTIONS,
  SET_PERFORMED_ACTIONS,
  SET_ACTIVITY_DATE,
  SET_PERFORMED_ACTION_FIELDS,
  TOGGLE_PERFORMED_ACTION_FIELDS,
  CLEAR_ACTIVITY,
  GET_ROLES_ASSUMED,
  SET_ROLES_ASSUMED,
  GET_ROLES_ASSUMED_BY,
  SET_ROLES_ASSUMED_BY,
  GET_ACTIVE_FROM,
  SET_ACTIVE_FROM,
  GET_ACCESSED_USING,
  SET_ACCESSED_USING,
  GET_SELECTED_ROW_ACTIONS,
  SET_SELECTED_ROW_ACTIONS,
  TOGGLE_ACTIONS_MODAL,
} from './constants'
import { createAction } from 'redux-actions'

export const getPerformedActions = createAction(GET_PERFORMED_ACTIONS)
export const setPerformedActions = createAction(SET_PERFORMED_ACTIONS)
export const setActivityDate = createAction(SET_ACTIVITY_DATE)
export const togglePerformedActionFields = createAction(
  TOGGLE_PERFORMED_ACTION_FIELDS
)
export const setPerformedActionFields = createAction(
  SET_PERFORMED_ACTION_FIELDS
)
export const clearActivity = createAction(CLEAR_ACTIVITY)
export const getRolesAssumed = createAction(GET_ROLES_ASSUMED)
export const setRolesAssumed = createAction(SET_ROLES_ASSUMED)
export const getRolesAssumedBy = createAction(GET_ROLES_ASSUMED_BY)
export const setRolesAssumedBy = createAction(SET_ROLES_ASSUMED_BY)
export const getActiveFrom = createAction(GET_ACTIVE_FROM)
export const setActiveFrom = createAction(SET_ACTIVE_FROM)
export const getAccessedUsing = createAction(GET_ACCESSED_USING)
export const setAccessedUsing = createAction(SET_ACCESSED_USING)
export const getSelectedRowActions = createAction(GET_SELECTED_ROW_ACTIONS)
export const setSelectedRowActions = createAction(SET_SELECTED_ROW_ACTIONS)
export const toggleActionsModal = createAction(TOGGLE_ACTIONS_MODAL)
