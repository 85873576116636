/*
 *
 * SolutionCenter reducer
 *
 */

import { fromJS, List, Map } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  GET_DATA_SUCCESS,
  SET_SWIMLANE_DATA_LOADING,
  GET_SWIMLANE_DATA_SUCCESS,
  GET_DATA,
  CLEAR_DATA,
  GET_CRM_DATA_SUCCESS,
  SET_RESOURCE_MONITORING,
  SET_TICKET_COUNT,
  FETCH_RESOURCE_GROUPS,
  FETCH_RESOURCE_GROUPS_SUCCESS,
  SET_SHOULD_UPDATE_NODE_SWIMLANES,
  FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR,
  FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR_SUCCESS,
  FETCH_UBER_POLICY,
  FETCH_UBER_POLICY_ERROR,
  FETCH_UBER_POLICY_SUCCESS,
  SET_CRM_TICKETS,
} from './constants'

const initialState = fromJS({
  detailsLoading: true,
  isLoading: false,
  data: undefined,
  isMonitored: undefined,
  fetchingKeyVaultCreds: Map(),
  deletingKeyVaultCreds: Map(),
  updatingKeyVaultCreds: Map(),
  keyVaultCreds: Map(),
  keyVaultError: null,
  resourceGroups: null,
  fetchingResourceGroup: false,
  shouldUpdateNodeSwimlanes: false,
  fetchingEffectivePermissions: false,
  fetchingUsedEffectivePermissions: false,
  effectivePermissions: null,
  usedEffectivePermissions: null,
  uberPolicy: {
    data: null,
    loading: false,
    error: null,
  },
  crmTickets: [],
  ticketCount: null,
})

const nodeSolutionCenter = handleActions(
  {
    [GET_DATA]: state => state.set('detailsLoading', true),
    [GET_DATA_SUCCESS]: (state, { payload }) => {
      return state.set('detailsLoading', false).set('data', payload.data)
    },
    [SET_SWIMLANE_DATA_LOADING]: (state, { payload }) => {
      return state.set('swimlaneDetailsLoading', payload.loading)
    },
    [GET_SWIMLANE_DATA_SUCCESS]: (state, { payload }) => {
      let highestImportance = -1
      if (!state.get('importance') && payload.data) {
        payload.data.forEach(swimmy => {
          if (swimmy.defaultImportance > highestImportance) {
            highestImportance = swimmy.defaultImportance
          }
        })
      }

      const newdude =
        highestImportance <= 0
          ? state.set('importance', 5)
          : state.set('importance', highestImportance)

      if (newdude.hasIn(['data', 'Resources', 'items', 0])) {
        return newdude
          .setIn(
            ['data', 'Resources', 'items', 0, 'swimlane', 'items'],
            fromJS(payload.data)
          )
          .set('swimlaneDetailsFailure', false)
          .set('swimlaneDetailsLoading', false)
      } else {
        return newdude
          .set('swimlaneDetailsFailure', true)
          .set('swimlaneDetailsLoading', false)
      }
    },
    [GET_CRM_DATA_SUCCESS]: (state, { payload }) => {
      return state
        .set('isMonitored', payload.isMonitored)
        .set('importance', payload.importance)
    },
    [SET_RESOURCE_MONITORING]: (state, { payload }) => {
      if (!payload) {
        // removed this t\because it broke stuff,
        // actual fix is to refetch data on enable
        //.set('monitoredProperties', [])
        //.set('changeDetectionOptions', [])
        return state.set('isMonitored', payload)
      }
      return state.set('isMonitored', payload)
    },
    [CLEAR_DATA]: state => {
      return state
        .set('data', undefined)
        .set('tags', List())
        .set('isMonitored', undefined)
        .set('monitoredProperties', [])
        .set('changeDetectionOptions', [])
        .set('alertLogsCount', 0)
        .set(
          'uberPolicy',
          fromJS({
            loading: false,
            error: null,
            data: null,
          })
        )
    },
    [SET_TICKET_COUNT]: (state, { payload }) =>
      state.set('ticketCount', payload),
    [FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR]: (state, { payload }) =>
      state.set(
        payload.usedPermissions
          ? 'fetchingEffectivePermissions'
          : 'fetchingUsedEffectivePermissions',
        true
      ),
    [FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR_SUCCESS]: (state, { payload }) =>
      state
        .set(
          payload.usedPermissions
            ? 'fetchingUsedEffectivePermissions'
            : 'fetchingEffectivePermissions',
          false
        )
        .set(
          payload.usedPermissions
            ? 'usedEffectivePermissions'
            : 'effectivePermissions',
          fromJS(payload.effectivePermissions)
        ),
    [FETCH_RESOURCE_GROUPS]: state => state.set('fetchingResourceGroups', true),
    [FETCH_RESOURCE_GROUPS_SUCCESS]: (state, { payload }) =>
      state
        .set('resourceGroups', fromJS(payload.groups))
        .set('fetchingResourceGroups', false),
    [FETCH_UBER_POLICY]: state =>
      state.set(
        'uberPolicy',
        fromJS({
          loading: true,
          data: null,
          error: null,
        })
      ),
    [FETCH_UBER_POLICY_ERROR]: (state, { payload }) =>
      state.set(
        'uberPolicy',
        fromJS({
          loading: false,
          data: null,
          error: payload,
        })
      ),
    [FETCH_UBER_POLICY_SUCCESS]: (state, { payload }) =>
      state.set(
        'uberPolicy',
        fromJS({
          loading: false,
          data: payload,
          error: null,
        })
      ),
    [SET_SHOULD_UPDATE_NODE_SWIMLANES]: (state, { payload }) =>
      state.set('shouldUpdateNodeSwimlanes', payload),
    [SET_CRM_TICKETS]: (state, { payload }) =>
      state.set('crmTickets', fromJS(payload)),
  },
  initialState
)

export default nodeSolutionCenter
