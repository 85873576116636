/**
 *
 * Icon
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import {
  faAngleRight,
  faAngleLeft,
  faBell,
  faCheckCircle as faCheckCircleSolid,
  faCircle as faCircleSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faFilter as faFilterSolid,
  faLock,
  faLockOpen,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faQuestion,
  faSync,
  faUser,
  faLevelUpAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  faAddressBook,
  faAngleDoubleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrows,
  faArrowUp,
  faArrowToTop,
  faArrowToBottom,
  faBan,
  faBars,
  faBell as faBellLight,
  faBinoculars,
  faBracketsCurly,
  faBroadcastTower,
  faBox,
  faBrowser,
  faBug,
  faBuilding,
  faBullseye,
  faCalendarTimes,
  faCameraRetro,
  faCity,
  faChartBar,
  faChartLine,
  faChartNetwork,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClipboard,
  faClipboardList,
  faClipboardListCheck,
  faClock,
  faClone,
  faCloud,
  faCloudUpload,
  faCode,
  faCodeMerge,
  faCog,
  faCogs,
  faColumns,
  faCompress,
  faCompressWide,
  faContainerStorage,
  faCopy,
  faCube,
  faCubes,
  faDatabase,
  faDharmachakra,
  faDiamond,
  faDivide,
  faDoorOpen,
  faEllipsisV,
  faExchange,
  faExclamationCircle,
  faExclamationSquare,
  faExclamationTriangle,
  faExpand,
  faExpandArrowsAlt,
  faExpandWide,
  faExternalLinkAlt,
  faLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileCertificate,
  faFileInvoice,
  faFileSignature,
  faFilter,
  faFolder,
  faFolders,
  faFrown,
  faGlasses,
  faGlobe,
  faGlobeAmericas,
  faGripHorizontal,
  faHashtag,
  faHdd,
  faHeartRate,
  faHeartbeat,
  faIdBadge,
  faIdCard,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faLightbulb,
  faList,
  faListAlt,
  faListOl,
  faLocationArrow,
  faLock as hollowLock,
  faLockAlt,
  faMapMarker,
  faMapMarkerAlt,
  faMapSigns,
  faMask,
  faMicrochip,
  faMinus,
  faMinusCircle,
  faMousePointer,
  faNetworkWired,
  faObjectGroup,
  faObjectUngroup,
  faPencilAlt,
  faPlay,
  faPlug,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faQrcode,
  faQuestionCircle,
  faReply,
  faRocket,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faServer,
  faShield,
  faSignIn,
  faSitemap,
  faSpinner,
  faSquare,
  faStamp,
  faStar,
  faStepBackward,
  faSyncAlt,
  faTable,
  faTachometer,
  faTag,
  faTerminal,
  faThumbsUp,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTools,
  faTrashAlt,
  faUndo,
  faUniversity,
  faRobot,
  faUpload,
  faUser as faUserLight,
  faUserCircle,
  faUserHeadset,
  faUsers,
  faUsersCog,
  faUserSecret,
  faDigging,
  faUserTag,
  faTags,
  faShieldCheck,
  faSortAmountDown,
  faWarehouseAlt,
  faWindowClose,
  faFileContract,
  faCommentsAlt,
  faCommentAltPlus,
  faChartPieAlt,
  faCompactDisc,
  faScroll,
  faLaptopCode,
  faTasks,
} from '@fortawesome/pro-light-svg-icons'
import {
  faThumbtack as faThumbtackSolid,
  faCaretDown,
  faExclamation,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faInfo,
  faCog as faCogSolid,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faAws,
  faWindows,
  faGoogle,
  faSearchengin,
  faSlack,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'assets/pixeden-stroke-7-icons/pe-icon-7-stroke/css/pe-icon-7-stroke.css'
import 'assets/pixeden-stroke-7-icons/pe-icon-7-stroke/css/helper.css'
import sonrailogo from 'assets/sonrailogo.png'
import gcplogo from 'assets/gcplogo.png'
import gcplogowhite from 'assets/gcpwhite.png'
import hashicorpvaultlogo from 'assets/hashicorpvaultlogo.png'
import hashicorplogo from 'assets/hashicorplogo.png'
import kuberneteslogo from 'assets/kuberneteslogo.png'
import servicenowlogo from 'assets/servicenowsmall.png'
import slacklogo from 'assets/slackicon.png'
import jiralogo from 'assets/jiraicon.png'

class Icon extends React.PureComponent {
  styles = {
    icon: {
      fontSize: '28px',
    },
  }

  getIconClassName = () => {
    switch (this.props.name) {
      case 'explorer':
        return 'pe-7s-helm'
      case 'pieChart':
        return 'pe-7s-graph'
      case 'search':
        return 'pe-7s-search'
      case 'report':
        return 'pe-7s-news-paper'
      case 'alert':
        return 'pe-7s-attention'
      case 'config':
        return 'pe-7s-config'
      case 'notification':
        return 'pe-7s-bell'
      case 'explore':
        return 'pe-7s-helm'
      case 'logout':
        return 'pe-7s-power'
      case 'securityCenterLink':
        return 'pe-7s-gleam'
      case 'users':
        return 'pe-7s-users'
      case 'opsCenter':
        return 'pe-7s-global'
      case 'roleManagement':
        return 'pe-7s-key'
      case 'bot':
        return 'pe-7s-disk'
      case 'cloud':
        return 'pe-7s-cloud'
      case 'server':
        return 'pe-7s-server'
      case 'settings':
        return 'pe-7s-settings'
      case 'cog-solid':
        return 'pe-7s-cog-solid'
      case 'columns':
        return 'pe-7s-columns'
      case 'monitoredResources':
        return 'pe-7s-help2'
      case 'escalation':
        return 'pe-7s-levelUpAlt'
    }
  }

  buildClassName = () => {
    if (this.props.fixed) {
      return `${this.getIconClassName()} ${this.props.className} pe-fw`
    } else {
      return `${this.getIconClassName()} ${this.props.className}`
    }
  }

  getFaIconClass = name => {
    if (name.includes('crm')) {
      switch (name) {
        case 'accessed-crm':
        case 'accessedBy-crm':
          return faDoorOpen
        case 'activeFrom-crm':
        case 'activeUsing-crm':
          return faMapMarker
        case 'actionableBy-crm':
        case 'hasPermissionTo-crm':
          return faKey
        case 'accessedFrom-crm':
        case 'countryAccessedFrom-crm':
        case 'regionsInteractedWith-crm':
        case 'region-crm':
          return faGlobeAmericas
        case 'active-crm':
          return faHeartRate
        case 'isConsoleEnabled-crm':
          return faTerminal
        case 'mfaEnabled-crm':
        case 'encryptionEnabled-crm':
          return faKey
        case 'userAgentList-crm':
        case 'accessedUsing-crm':
          return faUserSecret
        case 'auditEnabled-crm':
          return faClipboardListCheck
        case 'versioningEnabled-crm':
        case 'dataStoreVersion-crm':
          return faLayerGroup
        case 'writeOnceEnabled-crm':
          return faFolder
        case 'publicRead-crm':
          return faUsers
        case 'publicWrite-crm':
          return faPencilAlt
        case 'isPublic-crm':
          return faGlobe
        default:
          return null
      }
    }
    switch (name) {
      case 'address-book':
        return faAddressBook
      case 'angle-double-right':
        return faAngleDoubleRight
      case 'angle-right':
        return faAngleRight
      case 'angle-left':
        return faAngleLeft
      case 'arrow-to-top':
        return faArrowToTop
      case 'arrow-to-bottom':
        return faArrowToBottom
      case 'binoculars':
        return faBinoculars
      case 'brackets-curly':
        return faBracketsCurly
      case 'browser':
        return faBrowser
      case 'building':
        return faBuilding
      case 'bug':
        return faBug
      case 'bullseye':
        return faBullseye
      case 'broadcast-tower':
        return faBroadcastTower
      case 'camera-retro':
        return faCameraRetro
      case 'city':
        return faCity
      case 'run':
        return faPlay
      case 'arrow-right':
        return faArrowRight
      case 'help':
        return faQuestionCircle
      case 'heartbeat':
        return faHeartbeat
      case 'bot':
        return faRobot
      case 'escalation':
        return faLevelUpAlt
      case 'server':
        return faServer
      case 'star':
        return faStar
      case 'archive':
        return faArchive
      case 'arrows':
        return faArrows
      case 'ban':
        return faBan
      case 'box':
        return faBox
      case 'clock':
        return faClock
      case 'upload':
        return faUpload
      case 'terminal':
        return faTerminal
      case 'notification':
        return faBell
      case 'search':
        return faSearch
      case 'sign-in':
        return faSignIn
      case 'identity':
        return faUser
      case 'infrastucture':
        return faInfo
      case 'data':
      case 'cube':
        return faCube
      case 'cubes':
        return faCubes
      case 'hdd':
        return faHdd
      case 'shield':
        return faShield
      case 'digging':
        return faDigging
      case 'network':
        return faNetworkWired
      case 'securityCenterLink':
        return faShieldCheck
      case 'compress':
        return faCompressWide
      case 'expand':
        return faExpandWide
      case 'expand-explorer':
        return faExpand
      case 'pie-alt':
        return faChartPieAlt
      case 'sunburst':
        return faCompactDisc
      case 'caret-down':
        return faCaretDown
      case 'cog-solid':
        return faCogSolid
      case 'database':
        return faDatabase
      case 'exchange':
        return faExchange
      case 'folders':
        return faFolders
      case 'caret-left':
        return faCaretLeft
      case 'caret-right':
        return faCaretRight
      case 'advancedSearch':
        return faSearchengin
      case 'slack':
        return faSlack
      case 'caret-up':
        return faCaretUp
      case 'back':
        return faStepBackward
      case 'id-badge':
        return faIdBadge
      case 'explorer':
        return faDharmachakra
      case 'chart-pie':
        return faChartPie
      case 'chart-bar':
        return faChartBar
      case 'chart-network':
        return faChartNetwork
      case 'clipboard':
        return faClipboard
      case 'clipboard-list':
        return faClipboardList
      case 'clipboard-list-check':
        return faClipboardListCheck
      case 'cloud':
        return faCloud
      case 'idCard':
        return faIdCard
      case 'alert':
        return faExclamationTriangle
      case 'spark':
        return faChartLine
      case 'calendar':
        return faCalendarTimes
      case 'compliance':
        return faClipboardListCheck
      case 'cloud-upload':
        return faCloudUpload
      case 'code':
        return faCode
      case 'arrow-down':
      case 'expandy-down':
        return faArrowDown
      case 'arrow-left':
        return faArrowLeft
      case 'exclamation-triangle-solid':
        return faExclamationTriangleSolid
      case 'collapsy':
      case 'arrow-up':
        return faArrowUp
      case 'sort':
        return faSortAmountDown
      case 'collapse':
        return faCompress
      case 'circle':
        return faCircle
      case 'tasks':
        return faTasks
      case 'close':
        return faWindowClose
      case 'divide':
        return faDivide
      case 'expandy':
        return faExpandArrowsAlt
      case 'circle-solid':
        return faCircleSolid
      case 'code-merge':
        return faCodeMerge
      case 'cogs':
        return faCogs
      case 'container-storage':
        return faContainerStorage
      case 'copy':
        return faCopy
      case 'object-group':
        return faObjectGroup
      case 'object-ungroup':
        return faObjectUngroup
      case 'times':
        return faTimes
      case 'times-circle':
        return faTimesCircle
      case 'diamond':
        return faDiamond
      case 'ellipsis-v':
        return faEllipsisV
      case 'exclamation-square':
        return faExclamationSquare
      case 'frown':
        return faFrown
      case 'globe':
        return faGlobe
      case 'globe-americas':
        return faGlobeAmericas
      case 'grip-horizontal':
        return faGripHorizontal
      case 'hashtag':
        return faHashtag
      case 'lightbulb':
        return faLightbulb
      case 'exclamation':
        return faExclamation
      case 'map-signs':
        return faMapSigns
      case 'map-marker-alt':
        return faMapMarkerAlt
      case 'mask':
        return faMask
      case 'minus':
        return faMinus
      case 'minus-circle':
        return faMinusCircle
      case 'microchip':
        return faMicrochip
      case 'mouse-pointer':
        return faMousePointer
      case 'eye':
        return faEye
      case 'eye-slash':
        return faEyeSlash
      case 'file-alt':
        return faFileAlt
      case 'filter':
        return faFilter
      case 'file':
        return faFile
      case 'file-invoice':
        return faFileInvoice
      case 'file-signature':
        return faFileSignature
      case 'file-certificate':
        return faFileCertificate
      case 'filter-solid':
        return faFilterSolid
      case 'key':
        return faKey
      case 'active':
        return faPlug
      case 'list-ol':
        return faListOl
      case 'list':
        return faList
      case 'list-alt':
        return faListAlt
      case 'location-arrow':
        return faLocationArrow
      case 'lock':
        return faLock
      case 'lock-alt':
        return faLockAlt
      case 'hollowLock':
        return hollowLock
      case 'lock-open':
        return faLockOpen
      case 'plus':
        return faPlus
      case 'pencil-alt':
        return faPencilAlt
      case 'power-off':
        return faPowerOff
      case 'question-solid':
        return faQuestion
      case 'qrcode':
        return faQrcode
      case 'search-minus':
        return faSearchMinus
      case 'search-plus':
        return faSearchPlus
      case 'reply':
        return faReply
      case 'rocket':
        return faRocket
      case 'scroll':
        return faScroll
      case 'save':
        return faSave
      case 'sitemap':
        return faSitemap
      case 'square':
        return faSquare
      case 'tachometer':
        return faTachometer
      case 'table':
        return faTable
      case 'copy-icon':
        return faClone
      case 'thumbs-up':
        return faThumbsUp
      case 'thumbtack-solid':
        return faThumbtackSolid
      case 'trash-alt':
        return faTrashAlt
      case 'tools':
        return faTools
      case 'check':
        return faCheck
      case 'check-circle':
        return faCheckCircle
      case 'check-circle-solid':
        return faCheckCircleSolid
      case 'user':
        return faUserLight
      case 'role':
        return faUserTag
      case 'users':
        return faUsers
      case 'users-cog':
        return faUsersCog
      case 'user-circle':
        return faUserCircle
      case 'user-headset':
        return faUserHeadset
      case 'windows':
      case 'azure':
        return faWindows
      case 'google':
        return faGoogle
      case 'amazon':
      case 'aws':
        return faAws
      case 'exclamation-circle':
        return faExclamationCircleSolid
      case 'exclamation-circle-light':
        return faExclamationCircle
      case 'info-circle':
        return faInfoCircle
      case 'trash':
        return faTrashAlt
      case 'config':
        return faCog
      case 'newWindow':
        return faExternalLinkAlt
      case 'pin':
        return faThumbtack
      case 'refresh':
        return faSyncAlt
      case 'sync':
        return faSyncAlt
      case 'reset':
        return faSync
      case 'spinner':
        return faSpinner
      case 'bell':
        return faBellLight
      case 'groupBy':
        return faSitemap
      case 'circlePlus':
        return faPlusCircle
      case 'undo':
        return faUndo
      case 'university':
        return faUniversity
      case 'column':
        return faColumns
      case 'link':
        return faLink
      case 'menu':
        return faBars
      case 'arrowRight':
        return faChevronRight
      case 'arrowLeft':
        return faChevronLeft
      case 'active-crm':
        return faHeartRate
      case 'console-access':
        return faTerminal
      case 'versioning-enabled':
        return faLayerGroup
      case 'public-read':
        return faGlasses
      case 'stamp':
        return faStamp
      case 'tag':
        return faTag
      case 'tags':
        return faTags
      case 'laptop-code':
        return faLaptopCode
      case 'long-arrow-alt-up':
        return faLongArrowAltUp
      case 'long-arrow-alt-down':
        return faLongArrowAltDown
      case 'warehouse-alt':
        return faWarehouseAlt
      case 'license':
        return faFileContract
      case 'add-comment':
        return faCommentAltPlus
      case 'comments':
        return faCommentsAlt
      default:
        return null
    }
  }

  getIconPng = type => {
    switch (type) {
      case 'sonrailogo':
        return sonrailogo
      case 'gcplogo':
        return gcplogo
      case 'gcplogowhite':
        return gcplogowhite
      case 'hashicorpvaultlogo':
        return hashicorpvaultlogo
      case 'hashicorplogo':
        return hashicorplogo
      case 'kuberneteslogo':
        return kuberneteslogo
      case 'servicenowlogo':
        return servicenowlogo
      case 'slacklogo':
        return slacklogo
      case 'jiralogo':
        return jiralogo
    }
  }

  getFAIcon() {
    return (
      <FontAwesomeIcon
        className={this.props.className}
        transform={this.props.transform}
        icon={this.getFaIconClass(this.props.name)}
        color={this.props.color}
        size={this.props.size}
        spin={this.props.spin}
        pulse={this.props.pulse}
        style={this.props.style}
      />
    )
  }

  render() {
    if (this.props.png) {
      return (
        <img
          src={this.getIconPng(this.props.type)}
          style={{ width: '24px', height: '24px', ...this.props.style }}
        />
      )
    }
    if (this.props.fa) {
      return this.getFAIcon()
    } else {
      return (
        <i
          style={Object.assign({}, this.styles.icon, this.props.style)}
          className={this.buildClassName()}
        />
      )
    }
  }
}

Icon.defaultProps = {
  size: 'lg',
  style: {},
}

Icon.propTypes = {
  png: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  fa: PropTypes.bool,
  fixed: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  transform: PropTypes.string,
  spin: PropTypes.bool,
  pulse: PropTypes.bool,
}

Icon = Radium(Icon) // eslint-disable-line

export default Icon
