import {
  SET_CHANGE_DETECTION_DATA,
  ADD_CHANGE_DETECTION_PROPERTIES,
  ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS,
  REMOVE_CHANGE_DETECTION_PROPERTIES,
  REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS,
  UPDATE_CHANGE_DETECTION_PROPERTY,
  UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS,
} from './constants'

import { createAction } from 'redux-actions'

export const setChangeDetectionData = createAction(SET_CHANGE_DETECTION_DATA)

export const addChangeDetectionProperties = createAction(
  ADD_CHANGE_DETECTION_PROPERTIES
)
export const addChangeDetectionPropertiesSuccess = createAction(
  ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS
)

export const removeChangeDetectionProperties = createAction(
  REMOVE_CHANGE_DETECTION_PROPERTIES
)

export const removeChangeDetectionPropertiesSuccess = createAction(
  REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS
)

export const updateChangeDetectionProperty = createAction(
  UPDATE_CHANGE_DETECTION_PROPERTY
)

export const updateChangeDetectionPropertySuccess = createAction(
  UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS
)
