/**
 *
 * PageLoader
 *
 */

import React from 'react'
import BarLoadingAnimation from 'components/BarLoadingAnimation'

const styles = {
  centerContent: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

class PageLoader extends React.PureComponent {
  render() {
    return (
      <div style={styles.centerContent}>
        <BarLoadingAnimation />
      </div>
    )
  }
}

export default PageLoader
