import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectAccountNodeViewCardLayoutDomain = state =>
  state.get('accountNodeViewCardLayout', Map())

export const selectAccountContent = createSelector(
  selectAccountNodeViewCardLayoutDomain,
  state => state.get('accountContent') || Map()
)

export const selectIsLoading = createSelector(
  selectAccountNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
