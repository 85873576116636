import React from 'react'
import PropTypes from 'prop-types'
import hoistNonReactStatics from 'hoist-non-react-statics'

import getInjectors from './sagaInjectors'

/**
 * Dynamically injects more than one saga
 *
 * @param {array} sagas array of objects that have same form as injectSagas hoc
 *
 */
export default ({ sagas }) => WrappedComponent => {
  class InjectMultipleSagas extends React.Component {
    static WrappedComponent = WrappedComponent
    static contextTypes = {
      store: PropTypes.object.isRequired,
    }
    static displayName = `withMultipleSagas(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'})`

    constructor(props, context) {
      super(props, context)
      this.injectors = getInjectors(this.context.store)
      const { injectSaga } = this.injectors
      sagas.forEach(({ key, saga, mode }) => {
        injectSaga(key, { saga, mode }, this.props)
      })
    }
    componentWillUnmount() {
      const { ejectSaga } = this.injectors
      sagas.forEach(({ key }) => {
        ejectSaga(key)
      })
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return hoistNonReactStatics(InjectMultipleSagas, WrappedComponent)
}
