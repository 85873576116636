import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import color from 'color'

const HEIGHT = 30

const Breadcrumb = styled.div`
  list-style-type: none;
  background-color: ${props =>
    color(props.color)
      .lighten(0.1)
      .hex()};
  display: inline-block;
  line-height: ${HEIGHT}px;
  position: relative;
  height: ${HEIGHT}px;
  vertical-align: middle;
  padding-left: ${HEIGHT}px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: ${props => (color(props.color).isLight() ? '#000' : '#fff')}
  font-weight: 400;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')}
  transition: background-color 0.3s ease;
  &:first-child {
    padding-left: 0.5em;
  }
  &:hover {
    background-color: ${props => props.color};
  }
  ::after {
    /* arrow */
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: ${HEIGHT / 2}px solid transparent;
    border-bottom: ${HEIGHT / 2}px solid transparent;
    border-left: ${HEIGHT / 2}px solid ${props =>
  color(props.color)
    .lighten(0.1)
    .hex()};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    z-index: 1;
    transition: border-left 0.3s ease;
  }
  &:hover:after {
    border-left: ${HEIGHT / 2}px solid ${props => props.color}
  }
  ::before {
    /*small line between arrows */
    content: '';
    width: ${Math.sqrt((HEIGHT * HEIGHT) / 2) + 5}px;
    height: ${Math.sqrt((HEIGHT * HEIGHT) / 2) + 5}px;
    background: transparent;
    position: absolute;
    transform: rotate(45deg);
    transform-origin: top right;
    top: ${HEIGHT / 2}px;
    margin: 0;
    right: -${HEIGHT / 2 + 2}px;
    border-right: #fff solid 3px;
    border-top: #fff solid 3px;
    z-index: 2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const SunburstBreadcrumb = ({ children, color, onClick }) => {
  return (
    <Breadcrumb color={color} onClick={onClick}>
      {children}
      <div style={{ width: '0.5em', display: 'inline-block' }} />
    </Breadcrumb>
  )
}

SunburstBreadcrumb.defaultProps = {
  color: '#eee',
}

SunburstBreadcrumb.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  onClick: PropTypes.func,
}

export default SunburstBreadcrumb
