import {
  SET_TICKET_LIST_FILTER_PANEL,
  CLEAR_TICKET_LIST_FILTER,
} from './constants'
import { createAction } from 'redux-actions'

export const setTicketListFilterPanel = createAction(
  SET_TICKET_LIST_FILTER_PANEL
)
export const clearTicketListFilter = createAction(CLEAR_TICKET_LIST_FILTER)
