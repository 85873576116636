import _ from 'lodash'
import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { isImmutable } from 'immutable'
import { getStore } from 'configureStore'
import { selectSwimlanes } from 'containers/SonraiData/selectors'
import { getClient } from 'apolloClient'

const toResoucreIdMap = {}
const toSwimlaneMap = {}

const alreadyFetched = new Set() // eslint-disable-line no-restricted-globals

/**
 * refresh swimlanes from store
 */
const refreshSwimlanes = callback => {
  const store = getStore()
  const state = store.getState()
  const swimlanes = selectSwimlanes(state)
  if (swimlanes) {
    createResourceMap(swimlanes)
    if (callback) {
      callback()
    }
  }
}

/**
 * fetch the swimlan by resourceID
 */
const fetchSwimlaneByResouceId = async (resourceId, callback) => {
  const query = gql`
    query getswimmy($resourceId: String) {
      Swimlanes(where: { resourceId: { value: $resourceId } }) {
        items {
          srn
          resourceId
        }
      }
    }
  `
  return getSwimlaneAndUpdate(
    async () => (await getClient()).query({ query, variables: { resourceId } }),
    callback
  )
}

/**
 * fetch the swimlane by SRN
 */
const fetchSwimlaneBySrn = async (srn, callback) => {
  const query = gql`
    query getswimmy($srn: String) {
      Swimlanes(where: { srn: { value: $srn } }) {
        items {
          srn
          resourceId
        }
      }
    }
  `
  return getSwimlaneAndUpdate(
    async () => (await getClient()).query({ query, variables: { srn } }),
    callback
  )
}

/**
 * Go get the swimlane using the function to fetch it, then update the swimlane
 * maps we have then call the callback
 */
const getSwimlaneAndUpdate = async (queryFetch, callback) => {
  try {
    const result = await queryFetch()
    const swimlane = _.get(result, ['data', 'Swimlanes', 'items', 0], null)
    if (swimlane != null) {
      updateResourceMaps(swimlane)
    }
  } catch (e) {
    callback(e)
    return
  }
  callback()
}

/**
 * check if some string is an SRN
 */
const isSrn = val => {
  return typeof val === 'string' && val.startsWith('srn')
}

/**
 * create a function that will get the resource group id
 */
const createGetResourceId = ({ initialized, setLoading }) => srn => {
  if (toResoucreIdMap.hasOwnProperty(srn)) {
    return toResoucreIdMap[srn]
  }

  // if we're initialized our swimlane maps, but the swimlane isn't present,
  // maybe its a new swimlane so try going to get it
  if (initialized) {
    // try synchronously first from store
    refreshSwimlanes()

    // if we fount it, return it
    if (toResoucreIdMap.hasOwnProperty(srn)) {
      return toResoucreIdMap[srn]
    }

    // otherwise load it
    if (!alreadyFetched.has(srn)) {
      alreadyFetched.add(srn)
      setLoading(true)
      fetchSwimlaneBySrn(srn, () => setLoading(false))
    }
  }
}

const createGetSwimalneSRN = ({ initialized, setLoading }) => resourceId => {
  if (toSwimlaneMap.hasOwnProperty(resourceId)) {
    return toSwimlaneMap[resourceId]
  }

  // see comments above in createGetResourceId about this steps
  if (initialized) {
    refreshSwimlanes()
    if (toSwimlaneMap.hasOwnProperty(resourceId)) {
      return toSwimlaneMap[resourceId]
    }

    if (!alreadyFetched.has(resourceId)) {
      alreadyFetched.add(resourceId)
      setLoading(true)
      fetchSwimlaneByResouceId(resourceId, () => setLoading(false))
    }
  }
}

/**
 * Add the swimlane to the maps we keep
 */
function updateResourceMaps({ resourceId, srn }) {
  if (srn) {
    toResoucreIdMap[srn] = resourceId
  }
  if (resourceId) {
    toSwimlaneMap[resourceId] = srn
  }
}

/**
 * Add all swimlanes to the maps we keep
 */
function createResourceMap(swimlanes) {
  if (isImmutable(swimlanes)) {
    return createResourceMap(swimlanes.toJS())
  }
  for (const swimlane of Object.values(swimlanes)) {
    updateResourceMaps(swimlane)
  }
}

/**
 * Sometimes it is more convenient for users to check if the user has
 * permission to something based on the swimlane's SRN instead of the
 * resourceId, but this hook will return some methods that lets it do both
 */
export default function useSwimlanesForPermissionCheck() {
  const [initialized, setInitialized] = useState(false)
  const [, setLoading] = useState(false)

  useEffect(() => {
    if (!initialized) {
      refreshSwimlanes(() => setInitialized(true))
    }
  }, [])

  return {
    isSrn,
    getResourceId: createGetResourceId({ initialized, setLoading }),
    getSwimlaneSRN: createGetSwimalneSRN({ initialized, setLoading }),
  }
}
