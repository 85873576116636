/*
 *
 * UserNodeViewCardLayout reducer
 *
 */

import { fromJS, Map } from 'immutable'
import { handleActions } from 'redux-actions'

import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

import {
  SET_ACTIONS_PERFORMED,
  GET_ACTIONS_PERFORMED,
  GET_USER_CONTENT,
  SET_USER_CONTENT,
} from './constants'

const initialState = fromJS({
  actionsPerformed: [],
  isLoading: false,
  loadingUserContent: true,
  userContent: {},
})

const userNodeViewCardLayoutReducer = handleActions(
  {
    [GET_ACTIONS_PERFORMED]: state => state.set('isLoading', true),
    [SET_ACTIONS_PERFORMED]: (state, { payload }) => {
      return state
        .set('actionsPerformed', fromJS(payload))
        .set('isLoading', false)
    },
    [GET_USER_CONTENT]: state => state.set('loadingUserContent', true),
    [SET_USER_CONTENT]: (state, { payload }) => {
      return state
        .set('userContent', fromJS(payload))
        .set('loadingUserContent', false)
    },
    [CLEAR_DATA]: state =>
      state.set('userContent', Map()).set('actionsPerformed', Map()),
  },
  initialState
)

export default userNodeViewCardLayoutReducer
