/**
 *
 * BounceLoadingAnimation
 *
 */

import React from 'react'

class BounceLoadingAnimation extends React.PureComponent {
  render() {
    return (
      <div className="spinner">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    )
  }
}

BounceLoadingAnimation.propTypes = {}

export default BounceLoadingAnimation
