import { createSelector } from 'reselect'
import { List, Map } from 'immutable'
import { VIEWS } from './constants'
import qs from 'query-string'

const selectSecurityCenterDomain = state => state.get('securityCenter') || Map()

export const selectResources = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('resources') || Map()
)

export const selectBulkDisablingMonitoring = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('bulkDisablingMonitoring')
)

export const selectSelectedResourceId = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('selectedResourceId')
)

export const selectSelectedResource = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('selectedResource')
)

export const selectSelectedSwimlaneName = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('selectedSwimlaneName')
)

export const selectStatus = createSelector(selectSecurityCenterDomain, sc =>
  sc.get('status')
)

export const selectSelectedCategory = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('selectedCategory')
)

export const selectSelectedSeverity = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('selectedSeverity')
)

export const selectView = (state, props) =>
  qs.parse(props.location.search).view || VIEWS.VIS

export const selectLaneFilters = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('filters')
)

export const selectLoadingRollupData = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('loadingRollupData')
)

export const selectCategoryRollupData = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('categoryRollupData') || List()
)

export const selectCategorySeverityRollupData = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('categorySeverityRollupData') || List()
)

export const selectTimelineRollupData = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('timelineRollupData') || List()
)

export const selectVisFilters = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('visFilters') || Map()
)

export const selectCategorySeriesTotals = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('categorySeriesTotals') || Map()
)

export const selectSCInfoModal = createSelector(
  selectSecurityCenterDomain,
  sc => sc.get('scInfoModal')
)
