import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

const selectUserNodeViewCardLayoutDomain = state =>
  state.get('userNodeViewCardLayout', Map())

export const selectActionsPerformed = createSelector(
  selectUserNodeViewCardLayoutDomain,
  state => state.get('actionsPerformed') || List()
)

export const selectIsLoadingActions = createSelector(
  selectUserNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)

export const selectUserContent = createSelector(
  selectUserNodeViewCardLayoutDomain,
  state => state.get('userContent') || Map()
)

export const selectLoadingUserContent = createSelector(
  selectUserNodeViewCardLayoutDomain,
  state => state.get('loadingUserContent')
)
