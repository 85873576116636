import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

import Autosuggest from 'react-autosuggest'
import { Input } from 'reactstrap'
import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import FormLabel from 'components/FormLabel'
import Icon from 'components/Icon'
import Popover, { PopoverAnchor, PopoverBody } from 'components/Popover'
import TextLink from 'components/TextLink'

import { addTag } from './actions'

import { selectTags as selectTagOptions } from 'containers/SonraiData/selectors'
import { makeSelectTags } from './selectors'

import messages from './messages'

export class AddTagForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      open: false,
      suggestions: [],
      autoValue: '',
      errorKey: false,
    }

    this.styles = {
      keyInput: {},
      addTagButton: {
        padding: '0rem 0.7rem',
        borderRadius: '1rem',
        border: `1px dashed ${props.theme.neutralMedium}`,
        backgroundColor: 'transparent',
        height: '20px',
        lineHeight: '20px',
        fontSize: '0.9em',
        margin: '0.25rem 0.5rem',
      },
      inputWrapper: {
        width: '400px',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: 'auto auto',
        gridColumnGap: '0.5em',
        gridRowGap: '0.5em',
        padding: '1em 0.5em',
      },
      inputLabel: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
      },
      actionButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      addIcon: { fontSize: '17px', top: '-2px', position: 'relative' },
    }
  }

  getSuggestions = value => {
    const inputValue =
      typeof value === 'string' ? value.trim().toLowerCase() : value

    if (inputValue.length === 0) {
      return []
    }

    return this.props.tagOptions
      .map(tagObj => decodeURIComponent(tagObj.get('key')) || '')
      .filter(tag => tag.toLowerCase().includes(inputValue))
      .toJS()
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  getSuggestionValue = suggestion => suggestion

  renderSuggestion = suggestion => <div>{suggestion}</div>

  toggleAddTag = () => {
    if (this.state.errorKey) {
      this.setState({ errorKey: false })
    }
    this.setState(oldState => ({
      open: !oldState.open,
    }))
  }

  onChange = (event, { newValue }) => {
    if (this.state.errorKey) {
      this.setState({ errorKey: false })
    }
    this.setState({
      value: newValue,
    })
  }

  handleAddTag = () => {
    if (this.state.value === '' || this.state.autoValue === '') {
      this.setState({
        errorKey: true,
      })
    } else {
      this.props.addTag({
        srn: this.props.nodeId,
        tag: {
          key: encodeURIComponent(this.state.value),
          value: this.state.autoValue,
        },
      })
    }
  }

  handleKeyPress = e => {
    const { value, autoValue } = this.state
    if (value.trim() !== '' && autoValue.trim() !== '') {
      if (e.key === 'Enter') {
        this.handleAddTag()
      }
    }
  }

  handleVal = event => {
    this.setState({
      autoValue: event.target.value,
    })
  }

  render() {
    return (
      <Popover isOpen={this.state.open}>
        <PopoverAnchor>
          <Button style={this.styles.addTagButton} onClick={this.toggleAddTag}>
            <Icon fa name="plus" style={this.styles.addIcon} />{' '}
            <DynamicFormattedMessage {...messages.addTag} />
          </Button>
        </PopoverAnchor>
        <PopoverBody>
          <div style={this.styles.inputWrapper}>
            <FormLabel required style={this.styles.inputLabel}>
              <DynamicFormattedMessage {...messages.key} />
            </FormLabel>
            <Autosuggest
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              inputProps={{
                value: this.state.value,
                onChange: this.onChange,
                onKeyPress: this.handleKeyPress,
                disabled: this.props.tags.saving,
              }}
            />
            <FormLabel required style={this.styles.inputLabel}>
              <DynamicFormattedMessage {...messages.value} />
            </FormLabel>
            <Input
              onKeyPress={this.handleKeyPress}
              style={this.styles.keyInput}
              onChange={this.handleVal}
              value={this.state.autoValue}
              disabled={this.props.tags.saving}
            />
          </div>
          <div style={this.styles.actionButtons}>
            <TextLink onClick={this.toggleAddTag} color="secondary">
              <DynamicFormattedMessage {...messages.cancel} />
            </TextLink>
            <Button
              style={{ marginLeft: '0.5em' }}
              onClick={this.handleAddTag}
              color="primary"
              disabled={
                this.state.value.trim() === '' ||
                this.state.autoValue.trim() === '' ||
                this.props.tags.saving
              }
            >
              {this.props.tags.saving ? (
                <Icon style={this.styles.addIcon} fa name="sync" spin />
              ) : (
                'Add'
              )}
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    )
  }
}

AddTagForm.propTypes = {
  theme: themeShape,

  // ~ passed props ~
  nodeId: PropTypes.string,

  // ~ props from redux store ~
  tags: PropTypes.shape({
    saving: PropTypes.bool,
  }),
  tagOptions: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.contains({
      key: PropTypes.string,
    })
  ),

  // ~ bound action creators ~
  addTag: PropTypes.func.isRequired,
}

const makeMapStateToProps = (state, props) => {
  const selectTags = makeSelectTags(state, props)
  return createStructuredSelector({
    tags: selectTags,
    tagOptions: selectTagOptions,
  })
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addTag }, dispatch)
}

const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  themeable
)(AddTagForm)
