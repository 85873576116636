import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import TextLink from 'components/TextLink'
import Button from 'components/Button'
import Select from 'components/Select'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'

import messages from '../messages'

const SNOOZE_UNTIL_OPTIONS = {
  DAYS_7: {
    label: '7 Days',
    value: [7, 'days'],
  },
  DAYS_14: {
    label: '14 Days',
    value: [14, 'days'],
  },
  DAYS_30: {
    label: '30 Days',
    value: [30, 'days'],
  },
  DAYS_90: {
    label: '90 days',
    value: [90, 'days'],
  },
  DAYS_180: {
    label: '180 days',
    value: [180, 'days'],
  },
  DAYS_1YEAR: {
    label: 'One year',
    value: [1, 'year'],
  },
}

function formatOptionValue({ value }) {
  return moment()
    .add(...value)
    .format('YYYY-MM-DD')
}

export default function SnoozeTicketModal(props) {
  const [snoozeUntil, setSnoozeUntil] = useState(null)

  const clearAndClose = () => {
    setSnoozeUntil(null)
    props.close()
  }

  const onSubmit = () => {
    props.snoozeTicket({
      srn: props.ticket.srn,
      snoozedUntil: formatOptionValue(snoozeUntil),
    })
    clearAndClose()
  }

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>
        <DynamicFormattedMessage {...messages.snoozeModalHeaderText} />
      </ModalHeader>
      <ModalBody>
        <DynamicFormattedMessage {...messages.snoozeModalHeaderInstructions} />
        <Select
          options={Object.values(SNOOZE_UNTIL_OPTIONS)}
          onChange={val => setSnoozeUntil(val)}
        />
      </ModalBody>
      <ModalFooter>
        <TextLink color="secondary" onClick={clearAndClose}>
          <DynamicFormattedMessage {...messages.snoozeModalCancelText} />
        </TextLink>
        <Button
          color="primary"
          disabled={snoozeUntil == null}
          onClick={onSubmit}
        >
          <DynamicFormattedMessage {...messages.snoozeModalOKText} />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

SnoozeTicketModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  snoozeTicket: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    srn: PropTypes.string.isRequired,
  }),
}
