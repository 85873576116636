import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

/**
 * Direct selector to the Change Detection Manager state domain
 */
const selectChangeDetectionManagerDomain = state =>
  state.get('changeDetectionManager')

/**
 * Other specific selectors
 */

/**
 * Default selector used by Change Detection Manager
 */

export const selectChangeDetectionManager = createSelector(
  selectChangeDetectionManagerDomain,
  state => state || Map()
)

export const selectIsLoading = createSelector(
  selectChangeDetectionManagerDomain,
  changeDetectionManager => {
    return changeDetectionManager.get('isLoading')
  }
)

export const selectChangeDetectionOptions = createSelector(
  selectChangeDetectionManagerDomain,
  changeDetectionManager => {
    return changeDetectionManager.get('changeDetectionOptions') || List()
  }
)

export const selectChangeDetectionProperties = createSelector(
  selectChangeDetectionManagerDomain,
  changeDetectionManager => {
    return changeDetectionManager.get('changeDetectionProperties') || List()
  }
)

export default selectChangeDetectionManager
export { selectChangeDetectionManagerDomain }
