import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles'

class SonraiPageContent extends React.PureComponent {
  render() {
    return (
      <div id="content" style={styles.content}>
        {this.props.children}
      </div>
    )
  }
}

SonraiPageContent.propTypes = {
  children: PropTypes.node,
}

export default SonraiPageContent
