import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import TextLink from 'components/TextLink'
import injectSaga from 'utils/injectSaga'
import moment from 'moment'
import injectReducer from 'utils/injectReducer'
import reducer from './reducers'
import saga from './sagas'
import {
  getPerformedActions,
  setActivityDate,
  setPerformedActionFields,
  togglePerformedActionFields,
  clearActivity,
  getRolesAssumed,
  getRolesAssumedBy,
  getActiveFrom,
  getAccessedUsing,
  getSelectedRowActions,
  toggleActionsModal,
} from './actions'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import {
  selectPerformedActions,
  selectActivityDate,
  selectPerformedActionFields,
  selectRolesAssumed,
  selectRolesAssumedBy,
  selectActiveFrom,
  selectAccessedUsing,
  selectSelectedRowActions,
  selectSelectedRowActionsLoading,
  selectActionsModal,
} from './selectors'
import { selectQueryTypes } from 'containers/SonraiData/selectors'
import { Responsive } from 'react-grid-layout'
import SizeMe from 'components/SizeMe'
import CustomDateRange from 'components/CustomDateRange'
import { TopTitle, CardBody } from 'components/Card'
import BorderedCard from 'components/BorderedCard'
import PerformedActions from './PerformedActions'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import { selectIsMonitored } from 'containers/NodeSolutionCenter/selectors'
import { toggleResourceMonitoring } from 'containers/NodeSolutionCenter/actions'
import { srnIsCRMActionable } from 'utils/widgetUtils'
import _ from 'lodash'

import { selectChangeDetectionProperties } from 'containers/ChangeDetectionManager/selectors'
import ActivityFromRegions from 'components/ActivityFromRegions'
import UnderlyingActionsModal from 'components/UnderlyingActionsModal'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import AccessedUsingWidget from 'components/AccessedUsingWidget'
import DataTable from 'components/DataTable'

const ResponsiveGridLayout = SizeMe(Responsive)

const getData = props => {
  const date = props.date
  const fromDate = date.fromDate
  const toDate = date.toDate
  const srn = props.nodeId
  const type = getTypeFromSrn(srn)
  const actionableByMode = srnIsCRMActionable(props.nodeId)

  props.getPerformedActions({
    srn,
    fromDate,
    toDate,
  })

  if (type === 'User') {
    props.getRolesAssumed({
      srn,
      fromDate,
      toDate,
    })
  } else if (type === 'Role') {
    props.getRolesAssumedBy({
      srn,
      fromDate,
      toDate,
    })
  }

  props.getActiveFrom({
    srn,
    fromDate,
    toDate,
    actionableByMode,
  })

  props.getAccessedUsing({
    srn,
    fromDate,
    toDate,
    actionableByMode,
  })
}

export class Activity extends React.Component {
  constructor(props) {
    super(props)

    // choose which types of actions to fetch by default based on the type of
    // the resource being displayed on screen: is it an Identity?
    try {
      const lowercaseNodeType = getTypeFromSrn(props.nodeId).toLowerCase()
      const identity = props.queryTypes.get('Identity')
      const identityType = identity
        .get('possibleTypes')
        .find(type => type.get('name').toLowerCase() === lowercaseNodeType)
      if (identityType) {
        props.setPerformedActionFields(['performedByValue'])
      } else {
        props.setPerformedActionFields(['performedOnValue'])
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        'Activity continaer coudlnt determine if nodeId was Identity: ' +
          props.nodeId,
        e
      )
    }

    getData(props)

    this.styles = {
      title: {
        fontSize: '18px',
        fontWeight: '300',
        borderBottom: '1px solid #eee',
        marginRight: '0px',
      },
      card: {
        height: '100%',
      },
      body: {
        alignItems: 'start',
        paddingTop: '5px',
        height: 'calc(100% - 5px)',
        background: 'none',
      },
      list: {
        fontSize: '12px',
        width: '100%',
      },
      listItem: {
        cursor: 'pointer',
        paddingBottom: '1px',
      },
      empty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      },
      crmOverlay: {
        position: 'absolute',
        background: 'rgba(200,200,200,0.48)',
        opacity: '0.7',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0.25rem',
        height: '100%',
      },
    }
  }

  componentWillUnmount() {
    this.props.clearActivity()
  }

  componentDidUpdate(oldProps) {
    const date = this.props.date
    const fromDate = date.fromDate
    const toDate = date.toDate
    const srn = this.props.nodeId
    if (
      oldProps.date !== this.props.date ||
      oldProps.isMonitored !== this.props.isMonitored
    ) {
      getData(this.props)
    }
    if (oldProps.performedActionFields !== this.props.performedActionFields) {
      this.props.getPerformedActions({
        srn,
        fromDate,
        toDate,
      })
    }
  }

  handleDatesChange = params => {
    const { startDate, endDate, value } = params
    if (startDate && endDate) {
      this.props.setActivityDate({
        fromDate: startDate,
        toDate: endDate,
      })
    }
    if (!startDate && !endDate && value) {
      this.props.setActivityDate({
        fromDate: moment().subtract(value, 'days'),
        toDate: moment(),
      })
    }
  }

  getRolesAssumed = () => {
    if (this.props.rolesAssumed.get('isLoading')) {
      return <SquareLoadingAnimation />
    }
    if (
      this.props.rolesAssumed.get('data') &&
      !this.props.rolesAssumed.get('data').isEmpty()
    ) {
      const data = this.props.rolesAssumed.toJS().data.map(action => {
        const role = _.get(action, ['peformedOn', 'items', 0, 'name'])
          ? _.get(action, ['peformedOn', 'items', 0, 'name'])
          : _.get(action, ['performedOnValue', 0], null)

        return {
          role: role,
          assumedOn: action.createdDate,
        }
      })
      return <DataTable data={data} />
    }
    return (
      <div style={this.styles.empty}>
        <span>No Roles Assumed</span>
      </div>
    )
  }

  getRolesAssumedBy = () => {
    if (this.props.rolesAssumedBy.get('isLoading')) {
      return <SquareLoadingAnimation />
    }
    if (
      this.props.rolesAssumedBy.get('data') &&
      !this.props.rolesAssumedBy.get('data').isEmpty()
    ) {
      const data = this.props.rolesAssumedBy.toJS().data.map(action => {
        const actor = _.get(action, ['performedBy', 'items', 0, 'name'])
          ? _.get(action, ['performedBy', 'items', 0, 'name'])
          : _.get(action, ['performedByValue', 0], null)

        return {
          assumedBy: actor,
          assumedOn: action.createdDate,
        }
      })
      return <DataTable data={data} />
    }
    return (
      <div style={this.styles.empty}>
        <span>Role Not Assumed</span>
      </div>
    )
  }

  getCRMOverlay = () => {
    return (
      <div style={this.styles.crmOverlay}>
        <div
          style={{
            textAlign: 'center',
            fontSize: '0.9rem',
            marginBottom: '0.5rem',
          }}
        >
          Results from this widget are only available if the resource is
          monitored.
        </div>
        <TextLink
          color="primary"
          onClick={() =>
            this.props.toggleResourceMonitoring({
              srn: this.props.nodeId,
              isMonitored: this.props.isMonitored,
            })
          }
        >
          Enable Monitoring
        </TextLink>
      </div>
    )
  }

  navigateToNodeView = srn => {
    const nodeType = getTypeFromSrn(srn)
    this.props.push(getNodeViewPushParams(srn, nodeType))
  }

  render() {
    const {
      date: { fromDate, toDate },
    } = this.props
    const actionableByMode = srnIsCRMActionable(this.props.nodeId)
    const type = getTypeFromSrn(this.props.nodeId)

    const rowHeight = 64
    const layout2Col = [
      { x: 0, y: 0, w: 4, h: 6, i: 'activityExplorer' },
      { x: 0, y: 5, w: 4, h: 6, i: 'rolesAssumed' },
      { x: 0, y: 10, w: 2, h: 5, i: 'countries' },
      { x: 2, y: 10, w: 2, h: 5, i: 'userAgentStrings' },
    ]
    const layout1Col = [
      { x: 0, y: 0, w: 2, h: 5, i: 'activityExplorer' },
      { x: 0, y: 5, w: 2, h: 5, i: 'rolesAssumed' },
      { x: 0, y: 10, w: 2, h: 5, i: 'countries' },
      { x: 0, y: 15, w: 2, h: 5, i: 'userAgentStrings' },
    ]
    return (
      <div>
        <CustomDateRange
          noBorder
          startDate={fromDate}
          endDate={toDate}
          onDatesChange={this.handleDatesChange}
          displayFormat={'MMMM D YYYY'}
        />
        <UnderlyingActionsModal
          toggle={this.props.toggleActionsModal}
          isOpen={this.props.actionsModal}
          selectedRowActions={this.props.selectedRowActions.toJS()}
          selectedRowActionsLoading={this.props.selectedRowActionsLoading}
          onClickNodeView={this.navigateToNodeView}
          title={'Actions'}
        />
        <ResponsiveGridLayout
          className="layout"
          breakpoints={{ lg: 1390, md: 1250, sm: 916, xs: 679 }}
          cols={{ lg: 4, md: 4, sm: 4, xs: 2 }}
          margin={[15, 15]}
          rowHeight={rowHeight}
          measureBeforeMount={true}
          useCSSTransforms={false}
          layouts={{
            lg: layout2Col,
            md: layout2Col,
            sm: layout2Col,
            xs: layout1Col,
          }}
          isDraggable={false}
          isResizable={false}
        >
          <div key="activityExplorer">
            <PerformedActions
              onClickNodeView={this.navigateToNodeView}
              togglePerformedActionFields={
                this.props.togglePerformedActionFields
              }
              performedActionFields={this.props.performedActionFields}
              isLoading={this.props.performedActions.get('isLoading')}
              data={
                !this.props.performedActionFields.isEmpty()
                  ? this.props.performedActions.get('data')
                    ? this.props.performedActions.get('data').toJS()
                    : []
                  : []
              }
            />
          </div>
          <div key="rolesAssumed">
            {type === 'Role' ? (
              <BorderedCard style={this.styles.card}>
                <TopTitle style={this.styles.title}>Role Assumed By</TopTitle>
                <CardBody style={this.styles.body}>
                  {this.getRolesAssumedBy()}
                </CardBody>
              </BorderedCard>
            ) : (
              <BorderedCard style={this.styles.card}>
                <TopTitle style={this.styles.title}>Roles Assumed</TopTitle>
                <CardBody style={this.styles.body}>
                  {this.getRolesAssumed()}
                </CardBody>
              </BorderedCard>
            )}
          </div>
          <div key="countries">
            <ActivityFromRegions
              crmTickets={this.props.crmTickets}
              renderCRMOverlay={() => this.getCRMOverlay()}
              isMonitored={this.props.isMonitored}
              getSelectedRowActions={this.props.getSelectedRowActions}
              isLocked={
                this.props.changeDetectionProperties
                  .map(x => x.get('keyName'))
                  .includes('activeFrom') ||
                this.props.changeDetectionProperties
                  .map(x => x.get('keyName'))
                  .includes('accessedFrom')
              }
              title={actionableByMode ? 'Accessed From' : 'Active From'}
              srn={this.props.nodeId}
              activityWidget={this.props.activeFrom.toJS()}
              isLoading={this.props.activeFrom.get('isLoading')}
              actionableByMode={actionableByMode}
              path={actionableByMode ? 'accessedFrom' : 'activeFrom'}
            />
          </div>
          <div key="userAgentStrings">
            <AccessedUsingWidget
              crmTickets={this.props.crmTickets}
              renderCRMOverlay={() => this.getCRMOverlay()}
              isMonitored={this.props.isMonitored}
              srn={this.props.nodeId}
              isLocked={this.props.changeDetectionProperties
                .map(x => x.get('keyName'))
                .includes('accessedUsing')}
              accessedUsingData={this.props.accessedUsing.toJS()}
              isLoading={this.props.accessedUsing.get('isLoading')}
              title={actionableByMode ? 'Was Accessed Using' : 'Accessed Using'}
              getSelectedRowActions={this.props.getSelectedRowActions}
              path={actionableByMode ? 'accessedUsing' : 'activeUsing'}
            />
          </div>
        </ResponsiveGridLayout>
      </div>
    )
  }
}

Activity.propTypes = {
  crmTickets: ImmutablePropTypes.list.isRequired,
  push: PropTypes.func,
  performedActions: ImmutablePropTypes.iterable,
  getPerformedActions: PropTypes.func,
  setActivityDate: PropTypes.func,
  date: PropTypes.string,
  performedActionFields: ImmutablePropTypes.iterable,
  setPerformedActionFields: PropTypes.func,
  togglePerformedActionFields: PropTypes.func,
  nodeId: PropTypes.string,
  clearActivity: PropTypes.func,
  rolesAssumed: ImmutablePropTypes.iterable,
  rolesAssumedBy: ImmutablePropTypes.iterable,
  isMonitored: PropTypes.bool,
  toggleResourceMonitoring: PropTypes.func,
  activeFrom: ImmutablePropTypes.iterable,
  accessedUsing: ImmutablePropTypes.iterable,
  changeDetectionProperties: ImmutablePropTypes.iterable,
  getSelectedRowActions: PropTypes.func,
  selectedRowActionsLoading: PropTypes.bool,
  selectedRowActions: ImmutablePropTypes.iterable,
  toggleActionsModal: PropTypes.func,
  actionsModal: PropTypes.bool,
  queryTypes: ImmutablePropTypes.map,
}

const mapStateToProps = createStructuredSelector({
  performedActions: selectPerformedActions,
  date: selectActivityDate,
  performedActionFields: selectPerformedActionFields,
  rolesAssumed: selectRolesAssumed,
  rolesAssumedBy: selectRolesAssumedBy,
  activeFrom: selectActiveFrom,
  accessedUsing: selectAccessedUsing,
  isMonitored: selectIsMonitored,
  changeDetectionProperties: selectChangeDetectionProperties,
  selectedRowActionsLoading: selectSelectedRowActionsLoading,
  selectedRowActions: selectSelectedRowActions,
  actionsModal: selectActionsModal,
  queryTypes: selectQueryTypes,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      clearActivity,
      getPerformedActions,
      setActivityDate,
      setPerformedActionFields,
      togglePerformedActionFields,
      getRolesAssumed,
      getRolesAssumedBy,
      toggleResourceMonitoring,
      getActiveFrom,
      getAccessedUsing,
      getSelectedRowActions,
      toggleActionsModal,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'activity', reducer })
const withSaga = injectSaga({ key: 'activity', saga: saga })

export default compose(withReducer, withSaga, withConnect)(Activity)
