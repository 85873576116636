import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { push } from 'connected-react-router'
import qs from 'query-string'
import { injectIntl } from 'react-intl'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Popover, { PopoverAnchor, PopoverBody } from 'components/Popover'
import ProfileIcon from 'components/ProfileIcon'
import { selectSonraiUserSrn } from 'containers/UserProfileData/selectors'
import { useAuth0 } from 'react-auth0-wrapper'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import { getCurrentOrg } from 'auth/current-org'

import { clearCurrentOrg, canSetDefaultOrg } from 'auth/current-org'

export const UserMenu = props => {
  const [state, setState] = useState({
    open: false,
  })
  const { user, logout } = useAuth0()

  const styles = {
    popover: {
      boxShadow: '-2px 6px 5px 0px rgba(136,136,136,0.41)',
    },
    popoverBody: {
      padding: '0.5em',
    },
    username: {
      color: props.theme.neutralDark,
      padding: '0.5em 0',
    },
    divider: {
      borderBottom: `1px solid ${props.theme.neutralLight}`,
      margin: '0.5em',
    },
    menuOption: {
      display: 'flex',
      padding: '0.4em 0',
      cursor: 'pointer',
    },
    icon: {
      marginRight: '0.5em',
      width: '30px',
    },
  }

  const onDropdownToggle = newVisibility => {
    setState({
      open: newVisibility,
    })
  }

  const getPhoto = () => user.picture

  const getName = () => user.name

  const goToUserProfile = () => {
    props.push({
      pathname: '/App/UserManagement/User',
      search: qs.stringify({
        srn: props.sonraiUserSrn,
      }),
    })

    setState({
      open: false,
    })
  }

  const gotoKnowledgeBase = () => {
    props.push({
      pathname: '/App/HelpCenter',
    })

    setState({
      open: false,
    })
  }

  return (
    <Fragment>
      <Popover
        position="bottom-end"
        onToggle={onDropdownToggle}
        isOpen={state.open}
      >
        <PopoverAnchor>
          <ProfileIcon img={getPhoto()} />
        </PopoverAnchor>
        <PopoverBody style={styles.popover}>
          <div style={styles.popoverBody}>
            <div style={styles.username}>
              {getName()}
              <br />
              Organization: {getCurrentOrg()}
            </div>
            <div style={styles.divider} />
            <BorderlessButton
              color="primary"
              style={styles.menuOption}
              onClick={goToUserProfile}
            >
              <Icon fa name="user" style={styles.icon} />
              <span>User Profile</span>
            </BorderlessButton>
            {!canSetDefaultOrg() && (
              // only allow users to change their org if we were not able to
              // set one by default for them
              <BorderlessButton
                color="primary"
                style={styles.menuOption}
                onClick={() => clearCurrentOrg()}
              >
                <Icon fa name="exchange" style={styles.icon} />
                <span>Change Organizations</span>
              </BorderlessButton>
            )}
            <BorderlessButton
              color="primary"
              style={styles.menuOption}
              onClick={gotoKnowledgeBase}
              to={{
                pathname: '/App/HelpCenter',
              }}
            >
              <Icon fa name="lightbulb" style={styles.icon} />
              <span>Knowledge Base</span>
            </BorderlessButton>
            <BorderlessButton
              onClick={undefined /* "toggleLicenseDropdown" is this defined? */}
              style={{
                padding: '6px 0px',
              }}
            >
              <Icon fa name="license" style={styles.icon} />
              <a
                style={{
                  color: props.theme.primary,
                  fontWeight: '400',
                }}
                target="_blank"
                rel="noopener noreferrer"
                href={window.config.userAgreementUrl}
              >
                License Agreement
              </a>
            </BorderlessButton>
            <div style={styles.divider} />
            <BorderlessButton style={styles.menuOption} onClick={logout}>
              <Icon
                transform="shrink-2 down-1"
                fa
                name="power-off"
                style={styles.icon}
              />
              <span>Logout</span>
            </BorderlessButton>
          </div>
        </PopoverBody>
      </Popover>
    </Fragment>
  )
}

UserMenu.propTypes = {
  theme: themeShape,
  push: PropTypes.func.isRequired,
  sonraiUserSrn: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  sonraiUserSrn: selectSonraiUserSrn,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)
export default compose(
  withConnect,
  injectIntl,
  themeable
)(UserMenu)
