/*
 *
 * LanguageProvider reducer
 *
 */

import { fromJS } from 'immutable'
import { CHANGE_LOCALE } from './constants'
import { DEFAULT_LOCALE } from '../App/constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  locale: DEFAULT_LOCALE,
})

const languageProviderReducer = handleActions(
  {
    [CHANGE_LOCALE]: (state, { payload }) =>
      state.set('locale', payload.locale),
  },
  initialState
)

export default languageProviderReducer
