import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import BorderedCard from 'components/BorderedCard'
import WidgetPopup from 'components/WidgetPopup'
import { getMappedBoolLabel } from 'utils/widgetUtils'
import { exists } from 'utils/sonraiUtils'
class PropertiesWidget extends Component {
  styles = {
    header: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: '22px',
      marginRight: '0.5rem',
      display: 'inline-flex',
    },
    loading: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    propertiesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0.5rem 0.25rem',
      position: 'relative',
      alignItems: 'flex-start',
      height: 'calc(100% - 55px)',
    },
    key: {
      fontSize: '0.95rem',
      fontWeight: '400',
      color: '#FFF',
    },
    code: {
      fontFamily: 'monospace',
      fontSize: '1.1rem',
    },
  }

  componentDidUpdate() {
    if (this.props.recalculateGrid) {
      setTimeout(this.props.recalculateGrid, 100)
    }
  }

  getProperties = () => {
    if (!this.props.propertyWidget.isEmpty()) {
      if (
        exists(this.props.propertyWidget.get('data')) &&
        !this.props.propertyWidget.isEmpty()
      ) {
        return this.props.propertyWidget
          .get('data')
          .sort((a, b) => a.get('keyName').localeCompare(b.get('keyName')))
          .map((property, i) => {
            const label = getMappedBoolLabel(
              property.get('keyName'),
              property.get('currentValue')
            )
            if (exists(label)) {
              return (
                <div key={i}>
                  <WidgetPopup
                    crmTickets={this.props.crmTickets}
                    resourceSrn={this.props.srn}
                    data={{
                      content: <div style={this.styles.key}>{label}</div>,
                      keyName: property.get('keyName'),
                      currentValue: property.get('currentValue'),
                    }}
                    color="#FFF"
                    isLocked={this.props.changeDetectionProperties
                      .map(x => x.get('keyName'))
                      .includes(property.get('keyName'))}
                  />
                </div>
              )
            }
          })
      }
    }
  }

  render() {
    const properties = this.getProperties()
    return (
      <BorderedCard
        style={{
          height: '100%',
        }}
        loading={this.props.propertyWidget.get('isLoading')}
      >
        <div style={this.styles.header}>
          <div style={this.styles.title}>Attributes</div>
        </div>
        <div style={this.styles.propertiesContainer}>{properties}</div>
      </BorderedCard>
    )
  }
}

PropertiesWidget.propTypes = {
  changeDetectionProperties: PropTypes.object,
  crmTickets: ImmutablePropTypes.list.isRequired,
  srn: PropTypes.string,
  recalculateGrid: PropTypes.func,
  propertyWidget: ImmutablePropTypes.iterable.isRequired,
}

export default PropertiesWidget
