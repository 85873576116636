import { defineMessages } from 'react-intl'

export default defineMessages({
  nodata: {
    id: 'app.containers.AccessActivity.canAssumeRoles',
    defaultMessage: 'None',
  },
  error: {
    id: 'app.containers.AccessActivity.error',
    defaultMessage: 'An error ocurred fetching data',
  },
})
