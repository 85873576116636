import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { TopTitle, CardBody } from 'components/Card'
import Card from 'components/Card'
import WidgetPopup from 'components/WidgetPopup'
import moment from 'moment'
import DataTable from 'components/DataTable'
import { exists } from 'utils/sonraiUtils'
import TextLink from 'components/TextLink'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

class ActivityFromRegions extends Component {
  styles = {
    title: {
      fontSize: '22px',
      fontWeight: '300',
    },
  }

  getData = activityData => {
    if (
      exists(activityData) &&
      exists(activityData.data) &&
      !_.isEmpty(activityData.data)
    ) {
      const rows = activityData.data.map(resource => {
        const row = {
          timestamp: resource.timestamp,
          from: resource.regionSet,
          showActions: '',
        }
        return row
      })
      return rows
    }
    return []
  }

  handleActionsModal = ({ from, timestamp }) => {
    const { srn, path } = this.props
    this.props.getSelectedRowActions({
      data: {
        regionSet: from,
        criticalResourceSRN: srn,
      },
      path,
      date: {
        fromDate: moment(timestamp).utc().startOf('day'),
        toDate: moment(timestamp).utc().endOf('day'),
      },
    })
  }

  getHeader = () => {
    const { isLocked, title } = this.props
    const keyName = this.props.actionableByMode ? 'accessedFrom' : 'activeFrom'

    //TODO: support resourceSrn as a filter in tickets page
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={this.styles.title}>{title}</span>
          {this.props.isMonitored && (
            <WidgetPopup
              data={{
                keyName,
                keyType: 'PATH',
              }}
              title={this.props.title}
              resourceSrn={this.props.srn}
              isLocked={isLocked}
              style={{ marginBottom: '0.35rem' }}
              crmTickets={this.props.crmTickets}
            />
          )}
        </div>
      </div>
    )
  }

  getContent = () => {
    const activityData = this.getData(this.props.activityWidget)
    const { isLoading } = this.props
    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    }

    if (!this.props.isMonitored) {
      return this.props.renderCRMOverlay()
    } else {
      return (
        <DataTable
          data={activityData}
          onRowCountChange={this.props.recalculateGrid}
          style={{
            height: activityData.length > 0 ? '100%' : '60px',
            width: '100%',
          }}
          sortColumns
          sortColumnByType="ActivityFromRegions"
          autosize={false}
          customColumnConfig={{
            resource: {
              hide: true,
            },
            timestamp: {
              headerName: 'Day',
              valueFormatter: ({ value }) => moment(value).format('ll'),
            },
            from: {
              flex: 1,
              minWidth: 200,
            },
            showActions: {
              cellStyle: { padding: '0 7px' },
              width: 110,
              minWidth: 110,
              aggFunc: null,
              pinned: 'right',
              headerName: '',
              menuTabs: [],
              suppressMenu: true,
              cellRendererFramework: params => {
                if (!params.data) {
                  return null
                }
                return (
                  <TextLink
                    color="primary"
                    style={{ color: this.props.theme.primary }}
                    onClick={() => this.handleActionsModal(params.data)}
                  >
                    Show Actions
                  </TextLink>
                )
              },
            },
          }}
        />
      )
    }
  }

  render() {
    return (
      <Card>
        <TopTitle style={this.styles.title}>{this.getHeader()}</TopTitle>
        <CardBody
          style={{
            display: 'grid',
            gridTemplateRows: '1fr',
            height: 'auto',
            overflow: 'hidden',
            justifyContent: 'inherit',
            alignItems: 'inherit',
          }}
        >
          {this.getContent()}
        </CardBody>
      </Card>
    )
  }
}

ActivityFromRegions.defaultProps = {
  // eslint-disable-next-line react/display-name
  renderCRMOverlay: () => {
    return (
      <div>
        <div
          style={{
            textAlign: 'center',
            fontSize: '0.9rem',
            marginBottom: '0.5rem',
          }}
        >
          Results from this widget are only available if the resource is
          monitored.
        </div>
      </div>
    )
  },
}

ActivityFromRegions.propTypes = {
  crmTickets: ImmutablePropTypes.list.isRequired,
  theme: themeShape,
  actionableByMode: PropTypes.bool,
  title: PropTypes.string,
  srn: PropTypes.string,
  isLocked: PropTypes.bool,
  recalculateGrid: PropTypes.func,
  activityWidget: PropTypes.object,
  isLoading: PropTypes.bool,
  getSelectedRowActions: PropTypes.func,
  path: PropTypes.string,
  isMonitored: PropTypes.bool,
  renderCRMOverlay: PropTypes.func,
}

export default themeable(ActivityFromRegions)
