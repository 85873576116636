/**
 *
 * FormLabel
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'reactstrap'

const styles = {
  inputLabel: {
    margin: '1em 0 0 0',
  },
  requiredIndicator: {
    paddingLeft: '0.3em',
    color: 'red',
  },
}

class FormLabel extends React.PureComponent {
  render() {
    const { label, style, required, children, ...rest } = this.props
    return (
      <Label style={{ ...styles.inputLabel, ...style }} {...rest}>
        {label || children}{' '}
        {required ? <span style={styles.requiredIndicator}>*</span> : null}
      </Label>
    )
  }
}

FormLabel.defaultProps = {
  style: {},
}

FormLabel.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
  required: PropTypes.bool,
  style: PropTypes.object,
}

export default FormLabel
