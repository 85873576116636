import { createSelector } from 'reselect'
import { Map, List } from 'immutable'
import qs from 'query-string'
import { getFieldsForType } from 'query-builder'
import _ from 'lodash'

import { selectTags as selectSonraiTags } from 'containers/SonraiData/selectors'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { selectQueryTypes } from 'containers/SonraiData/selectors'
import { SKIP_PROPERTIES } from './constants'

export const nodeSolutionCenterDomain = state =>
  state.get('nodeSolutionCenter') || Map()

export const selectIsLoading = createSelector(
  nodeSolutionCenterDomain,
  nodeSolutionCenter => {
    return nodeSolutionCenter.get('isLoading')
  }
)

export const selectData = createSelector(
  nodeSolutionCenterDomain,
  nodeSolutionCenter => {
    return nodeSolutionCenter.get('data') || Map()
  }
)

export const selectTagOptions = createSelector(selectSonraiTags, tags => tags)

export const selectSavingTag = createSelector(
  nodeSolutionCenterDomain,
  nodeSolutionCenter => nodeSolutionCenter.get('savingTag')
)

export const selectDetailsLoading = createSelector(
  nodeSolutionCenterDomain,
  nodeSolutionCenter => nodeSolutionCenter.get('detailsLoading')
)

export const selectSwimlaneDetailsFailure = createSelector(
  nodeSolutionCenterDomain,
  nodeSolutionCenter => nodeSolutionCenter.get('swimlaneDetailsFailure')
)

export const selectNodeId = (state, props) => {
  return (
    props.nodeId || //NodeID can be passed from quicksearch or other areas where its in a slideout panel
    _.get(props, ['nodeData', 'srn']) || //When the selector is used in a subcomponent of NodeSolutionCenter
    qs.parse(_.get(props, ['location', 'search'], '')).nodeId ||
    qs.parse(location.search).nodeId ||
    ''
  )
}

export const selectFields = createSelector(
  [selectNodeId, selectQueryTypes],
  (nodeId, types) => {
    const type = getTypeFromSrn(nodeId).toLowerCase()
    const toSkip = [...SKIP_PROPERTIES]
    if (type !== 'image') {
      toSkip.push('sid')
    }
    const fields = getFieldsForType(type, types).filter(
      field => !toSkip.includes(field.get('name'))
    )

    return fields
  }
)

export const selectNodeData = createSelector(selectData, data =>
  data ? _.get(data, [Object.keys(data)[0], 'items', 0], {}) : {}
)

export const selectSwimlaneDetailsLoading = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('swimlaneDetailsLoading')
)

export const selectIsMonitored = createSelector(
  nodeSolutionCenterDomain,
  nodeSolutionCenter => {
    return nodeSolutionCenter.get('isMonitored')
  }
)

export const selectMonitoredProperties = createSelector(
  nodeSolutionCenterDomain,
  nodeSolutionCenter => {
    return nodeSolutionCenter.get('monitoredProperties') || List()
  }
)

export const selectLoadingAccessPaths = createSelector(
  nodeSolutionCenterDomain,
  nodeSolutionCenter => nodeSolutionCenter.get('loadingAccessPaths')
)

export const selectKeyVaultCreds = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('keyVaultCreds') || Map()
)

export const selectFetchingKeyVaultCreds = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('fetchingKeyVaultCreds') || Map()
)

export const selectDeletingKeyVaultCreds = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('deletingKeyVaultCreds') || Map()
)

export const selectAddingKeyVaultCreds = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('addingAzureKeyVault') || Map()
)

export const selectUpdatingKeyVaultCreds = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('updatingKeyVaultCreds') || Map()
)

export const selectKeyVaultError = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('keyVaultError')
)

export const selectShouldUpdateNodeSwimlanes = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('shouldUpdateNodeSwimlanes')
)

export const selectEffectivePermissions = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('effectivePermissions')
)

export const selectUsedEffectivePermissions = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('usedEffectivePermissions')
)

export const selectEffectivePermissionsLoading = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('fetchingEffectivePermissions')
)

export const selectUsedEffectivePermissionsLoading = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('fetchingUsedEffectivePermissions')
)

export const selectUberPolicy = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('uberPolicy') || Map()
)

export const selectTicketCount = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('ticketCount')
)

export const selectCrmTickets = createSelector(
  nodeSolutionCenterDomain,
  state => state.get('crmTickets') || List()
)
