import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { push } from 'connected-react-router'

import { fetchActivityTicketActions } from 'containers/TicketDetailsData/actions'
import { selectActivityTicketActions } from 'containers/TicketDetailsData/selectors'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

import CRMAlertDetails from './CRMAlertDetails'
export class TicketCRMEvidence extends React.Component {
  constructor(props, context) {
    super(props, context)
    props.fetchActivityTicketActions({ ticket: props.ticket.toJS() })
  }

  render() {
    if (this.props.activityTicketActions.get('loading')) {
      return <SquareLoadingAnimation />
    }

    if (this.props.activityTicketActions.get('error')) {
      return <span>error</span>
    }

    return (
      <CRMAlertDetails
        activeAlertActions={(
          this.props.activityTicketActions.get('data') || List()
        ).toJS()}
        push={this.props.push}
        alert={this.props.ticket}
        showHeader={false}
        hiddenColumns={['srn']}
      />
    )
  }
}

TicketCRMEvidence.propTypes = {
  // ~ passed props ~
  ticket: ImmutablePropTypes.map.isRequired,
  // ~ bound action creators ~
  fetchActivityTicketActions: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,

  // ~ from redux ~
  activityTicketActions: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  }),
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchActivityTicketActions, push }, dispatch)
}

const mapStateToProps = createStructuredSelector({
  activityTicketActions: selectActivityTicketActions,
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(TicketCRMEvidence)
