import { createAction } from 'redux-actions'
import {
  GET_DATA_STORE_CONTENT,
  SET_DATA_STORE_CONTENT,
  ERR_DATA_STORE_CONTENT,
} from './constants'

export const getDataStoreContent = createAction(GET_DATA_STORE_CONTENT)
export const setDataStoreContent = createAction(SET_DATA_STORE_CONTENT)
export const errDataStoreContent = createAction(ERR_DATA_STORE_CONTENT)
