import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import {
  getFieldsOfInterestForCard,
  getRelationsOfInterestForCard,
  getEffectivePermissionsOfInterestForCard,
} from 'utils/propsOfInterestUtils'
import { getIconMapping } from 'utils/sonraiUtils'
import Icon from 'components/Icon'
import { getAliasForField } from 'query-builder'
import Expandable from 'components/Expandable'
import { getTypeFromDefinition } from 'utils/graphDataUtils'
export class PolicySearchConditions extends React.Component {
  allCardRelations = {}
  allCardConditions = {}
  allCardEffectivePermissions = {}

  renderTriggerConditions = (searchCard, fields, results) => {
    const cardConditions = getFieldsOfInterestForCard({
      rootSearchField: searchCard,
      results: results,
      alertTime: this.props.alertTime,
    })
    const cardRelations = getRelationsOfInterestForCard({
      rootSearchField: searchCard,
      results: results,
    })

    const cardEffectivePermissions = getEffectivePermissionsOfInterestForCard({
      rootSearchField: searchCard,
      results: results.getIn(['hasEffectivePermissions', 'items'], List()),
      alertTime: this.props.alertTime,
      allTriggerProps: true,
    })

    this.allCardRelations[searchCard.get('id')] = cardRelations
    this.allCardConditions[searchCard.get('id')] = cardConditions
    this.allCardEffectivePermissions[
      searchCard.get('id')
    ] = cardEffectivePermissions

    const nonGte0Relations = cardRelations.filter(
      relation => relation.message && !relation.message.includes('at least one')
    )

    const type = getTypeFromDefinition(searchCard)

    const nearestParentWithContent = this.getNearestParentWithContent(
      searchCard,
      fields
    )
    const nearestParentNameWithContent = this.getDisplayNameFromCard(
      nearestParentWithContent
    )

    const showContentForCard =
      nonGte0Relations.length > 0 ||
      cardEffectivePermissions.length > 0 ||
      cardConditions.length > 0 ||
      !searchCard.get('parentId')

    return (
      <Fragment key={searchCard.get('id')}>
        {showContentForCard && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '28px 150px 1fr',
              gridTemplateRows: 'auto auto',
              gridTemplateAreas:
                '"icon label conditions" "arrow label relation"',
              marginTop: '1em',
              fontSize: '0.9em',
              gridColumnGap: '0.5em',
            }}
          >
            <div style={{ gridArea: 'icon' }}>
              <Icon fa name={getIconMapping(type).faIcon} />
            </div>
            <div style={{ gridArea: 'label' }}>
              <div>{searchCard.get('name')}</div>

              {type.toLowerCase() !==
              searchCard.get('name', '').toLowerCase() ? (
                <div style={{ fontStyle: 'italic' }}>({type})</div>
              ) : (
                ''
              )}
            </div>
            <div style={{ gridArea: 'conditions' }}>
              {searchCard.get('parentId') &&
                nearestParentWithContent &&
                (nonGte0Relations.length > 0 || cardConditions.length > 0) && (
                  <p
                    style={{
                      gridArea: 'text',
                      fontStyle: 'italic',
                      marginBottom: 0,
                    }}
                  >
                    {nearestParentWithContent.get('id') ===
                    searchCard.get('parentId')
                      ? `Has a ${searchCard.getIn([
                          'definition',
                          'name',
                        ])} relation from ${nearestParentNameWithContent}`
                      : `Has a relationship chain from ${nearestParentNameWithContent}`}
                  </p>
                )}
              {cardConditions.map(cond => (
                <p style={{ marginBottom: 0 }} key={cond.field}>
                  {cond.field === 'metadata' ? (
                    cond.triggerCondition
                  ) : (
                    <Expandable height={'53px'}>{cond.message}</Expandable>
                  )}
                </p>
              ))}
              {!searchCard.get('parentId') &&
                cardConditions.length === 0 &&
                nonGte0Relations.length === 0 &&
                cardEffectivePermissions.length === 0 && (
                  <p
                    style={{
                      gridArea: 'text',
                      fontStyle: 'italic',
                      marginBottom: 0,
                    }}
                  >
                    No relevant filters on the root card
                  </p>
                )}

              {cardEffectivePermissions.length > 0 && (
                <div>
                  <div style={{ fontSize: '1.1em', fontStyle: 'italic' }}>
                    Has effective permissions matching:{' '}
                  </div>

                  <div style={{ marginLeft: '1em' }}>
                    {cardEffectivePermissions.map((epGroup, index) => {
                      return (
                        <Fragment key={index}>
                          {index !== 0 && <div>AND</div>}
                          <div
                            style={{
                              margin: '1em 0',
                              borderLeft: '3px solid #eee',
                              paddingLeft: '1em',
                            }}
                          >
                            {epGroup.map(cond => (
                              <Expandable key={cond.propName} height={'53px'}>
                                <p style={{ marginBottom: '0.3em' }}>
                                  {cond.triggerCondition}
                                </p>
                              </Expandable>
                            ))}
                          </div>
                        </Fragment>
                      )
                    })}
                  </div>
                </div>
              )}

              {searchCard
                .get('selectionSet', List())
                .toJS()
                .map(cardId => {
                  const childCard = fields.get(cardId)
                  const childCardRelationName = childCard.getIn([
                    'definition',
                    'name',
                  ])
                  const childCardRelation =
                    cardRelations.find(
                      rel => rel.field === childCardRelationName
                    ) || {}

                  if (
                    childCardRelation.message &&
                    childCardRelation.message.includes('at least one')
                  ) {
                    return null
                  }

                  return (
                    <p key={childCardRelation.field}>
                      {childCardRelation.message}
                    </p>
                  )
                })}
            </div>
          </div>
        )}
        {searchCard
          .get('selectionSet', List())
          .toJS()
          .map(cardId => {
            const childCard = fields.get(cardId)
            let childCardRelationName = childCard.getIn(['definition', 'name'])

            if (!results.get(childCardRelationName)) {
              childCardRelationName = getAliasForField(
                cardId,
                childCardRelationName
              )
            }

            return this.renderTriggerConditions(
              childCard,
              fields,
              results.getIn([childCardRelationName, 'items', 0], Map())
            )
          })}
      </Fragment>
    )
  }

  getNearestParentWithContent = (searchCard, fields) => {
    let nearestParentWithContent
    let curr = searchCard

    while (!nearestParentWithContent) {
      if (!curr.get('parentId')) {
        nearestParentWithContent = curr
        continue
      }

      const parentConditions =
        this.allCardConditions[curr.get('parentId')] || []
      const parentRelations = this.allCardRelations[curr.get('parentId')] || []
      const parentEffectivePermissions =
        this.allCardEffectivePermissions[curr.get('parentId')] || []

      const parentHasContent =
        parentConditions.length > 0 ||
        parentEffectivePermissions.length > 0 ||
        parentRelations.filter(
          relation =>
            relation.message && !relation.message.includes('at least one')
        ).length > 0

      const parent = fields.get(curr.get('parentId'), Map())

      if (parentHasContent) {
        nearestParentWithContent = parent
      } else {
        curr = parent
      }
    }

    return nearestParentWithContent
  }

  getDisplayNameFromCard = card => {
    if (!card) {
      return
    }

    const typename = getTypeFromDefinition(card)
    const cardName = card.get('name') || ''

    return typename.toLowerCase() === cardName.toLowerCase()
      ? cardName
      : `${cardName} (${typename})`
  }

  render() {
    if (
      (this.props.results || List()).isEmpty() ||
      this.props.search.isEmpty()
    ) {
      return null
    }

    const fields = this.props.search.getIn(['query', 'fields'], Map())

    const rootSearchField = fields.find(
      field => !field.get('parentId'),
      null,
      Map()
    )

    return (
      <div>
        {this.renderTriggerConditions(
          rootSearchField,
          fields,
          this.props.results
        )}
      </div>
    )
  }
}

PolicySearchConditions.defaultProps = {
  results: Map(),
  allTriggerProps: false,
}

PolicySearchConditions.propTypes = {
  alertTime: PropTypes.number,
  results: ImmutablePropTypes.map.isRequired,
  search: ImmutablePropTypes.map.isRequired,
}

export default PolicySearchConditions
