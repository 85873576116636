import { all, put, takeLatest, takeEvery, call } from 'redux-saga/effects'
import {
  getDataSuccess,
  setSwimlaneDataLoading,
  getSwimlaneDataSucces,
  setSwimlaneDataFailure,
  getCrmDataSuccess,
  setResourceMonitoring,
  fetchEffectivePermissionsForActorSuccess,
  fetchUberPolicySuccess,
  fetchUberPolicyError,
  setTicketCount,
  setCrmTickets,
} from './actions'
import { updateRapsheetResourceMonitor } from 'containers/RapSheet/actions'
import { setChangeDetectionData } from 'containers/ChangeDetectionManager/actions'
import {
  FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR,
  GET_DATA,
  TOGGLE_RESOURCE_MONITORING,
  RELOAD_SWIMLANES,
  FETCH_UBER_POLICY,
  GET_TICKET_COUNT,
  GET_CRM_TICKETS,
} from './constants'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'
import _ from 'lodash'
import { isMonitored } from 'utils/sonraiUtils'
import { TYPES_WITH_CRM, DATA_TYPES_FOR_CRM } from 'appConstants'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { formatChangeDetectionProperties } from 'utils/sonraiUtils'

function* getNameInfoOnSrn(srn) {
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
      query getNameInfoOnEntity {
        Resources(
          where: { srn: { value: "${srn}"}}
        ) {
          items {
            name
            friendlyName
            srn
          }
        }
      }
      `,
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })
    if (
      response.data &&
      response.data.Resources.items &&
      !_.isEmpty(response.data.Resources.items)
    ) {
      return _.first(response.data.Resources.items)
    }
    return {}
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error(`${err}`)
  }
}

function* getInfoOnField(field, listOfSrns) {
  const extendedInfo = yield all(
    listOfSrns.map(srn => call(getNameInfoOnSrn, srn))
  )

  return {
    [field]: extendedInfo,
  }
}

function* getData(action) {
  try {
    const client = getClient()

    const response = yield client.query({
      query: action.payload.query,
      variables: action.payload.variables,
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })

    const items = _.first(response.data.Resources.items)

    const fieldsWithSrns = _.keys(items).filter(key => {
      if (Array.isArray(items[key]) && 'swimlaneSRNs' !== key) {
        if (
          items[key].find(
            item => typeof item === 'string' && item.startsWith('srn:')
          )
        ) {
          return key
        }
      }
    })
    const arrOfFieldsWithSrnsInfo = yield all(
      fieldsWithSrns.map(field => call(getInfoOnField, field, items[field]))
    )

    if (items && !_.isEmpty(fieldsWithSrns)) {
      fieldsWithSrns.forEach((field, index) => {
        response.data.Resources.items[0][field] =
          arrOfFieldsWithSrnsInfo[index][field]
      })
    }

    yield put(
      getDataSuccess({
        data: response.data,
      })
    )
    const resourceSrn = action.payload.variables.srn

    const type = getTypeFromSrn(resourceSrn).toLowerCase()

    const field = TYPES_WITH_CRM.includes(type)
      ? response.data[_.keys(response.data)[0]].items[0] &&
        response.data[_.keys(response.data)[0]].items[0].sonraiConfig
      : undefined

    const properties = yield call(getChangeDetectionProperties, resourceSrn)
    const changeDetectionProperties = formatChangeDetectionProperties(
      properties
    )

    const changeDetectionOptions = yield call(getChangeDetectionOptions, type)
    changeDetectionOptions.forEach(option => {
      if (
        option.keyType !== 'PATH' ||
        option.keyName === 'activeFrom' ||
        option.keyName === 'accessedUsing' ||
        option.keyName === 'accessedFrom'
      ) {
        option.actionClassification = []
      }
    })
    // Get's rid of the Activity action classification as this is not a valid action classification type (should do this server side)
    changeDetectionOptions.forEach(option => {
      if (!_.isEmpty(option.actionClassification)) {
        option.actionClassification = [...option.actionClassification].filter(
          x => x !== 'Activity'
        )
      }
    })

    yield put(
      setChangeDetectionData({
        changeDetectionOptions,
        changeDetectionProperties,
      })
    )

    yield put(
      getCrmDataSuccess({
        isMonitored: isMonitored(field),
        importance: items !== undefined ? items.importance : null,
      })
    )

    if (action.payload.swimlaneQuery) {
      yield put(setSwimlaneDataLoading({ loading: true }))
      yield getSwimlaneData({
        payload: {
          query: action.payload.swimlaneQuery,
          variables: action.payload.variables,
        },
      })
    } else {
      yield put(setSwimlaneDataLoading({ loading: false }))
      yield put(setSwimlaneDataFailure(false))
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error fetching data', e)
  }
}

function* getSwimlaneData(action) {
  try {
    const client = getClient()

    const response = yield client.query({
      query: action.payload.query,
      variables: action.payload.variables,
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })

    if (response.errors) {
      throw response.errors
    }

    const items = _.get(response, ['data', 'Resources', 'items'])
    if (items == null || items.length === 0) {
      throw 'could not get resource from :' + JSON.stringify(response)
    }

    const resource = items[0]
    const swimlanes = _.get(resource, ['swimlane', 'items'])
    if (swimlanes == null || swimlanes.length === 0) {
      throw 'could not get swimlanes from :' + JSON.stringify(resource)
    }

    yield put(
      getSwimlaneDataSucces({
        data: swimlanes,
      })
    )
  } catch (e) {
    yield put(setSwimlaneDataFailure(true))
    //eslint-disable-next-line no-console
    console.error('Error fetching data', e)
  }
}

function* getTicketCount(action) {
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
      query getTicketCountForResource {
        TicketRollups(
          where: {
            resourceSRN: {
              op: EQ
              value: "${action.payload}"
            }
            ticketType: {
              value: "ALL"
            }
            swimlaneSrns: { value: "ALL" }
            ticketKey: { op: EQ, value: "ALL" }
            status: { op: IN_LIST, values: ["NEW", "IN_PROGRESS"] }
            severityCategory: { op: EQ, value: "ALL" }
            actionClassification: { value: "ALL" }
            resourceType: { op: EQ, value: "ALL" }
            resourceLabel: { op: EQ, value: "ALL" }
            orgName: { value: "ALL" }
            account: { value: "ALL" }
          }
        ) {
          items {
            count
          }
        }
      }
      `,
    })

    if (response.errors) {
      throw new Error(response.errors)
    }

    const count = _.get(response, [
      'data',
      'TicketRollups',
      'items',
      0,
      'count',
    ])

    if (count === undefined) {
      yield put(setTicketCount(null))
    } else {
      yield put(setTicketCount(count))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`${e} there was an error getting ticketCount`)
  }
}

function* getChangeDetectionProperties(resourceSrn) {
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
        query {
          CDConfigs(where: { resourceSrn: { value: "${resourceSrn}" },  active: { value: true }, }) {
            items {
              keyType
              keyName
              alertLevel
              actionClassification
            }
          }
        }
      `,
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })
    return response.data.CDConfigs.items
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting change detection properties', e)
  }
}

function* getChangeDetectionOptions(resourceType = '') {
  const type = DATA_TYPES_FOR_CRM.includes(resourceType.toLowerCase())
    ? 'Data'
    : 'Identity'

  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
        query getOptions {
          getChangeDetectionOptions(resourceType: ${type}) {
            keyType
            keyName
            actionClassification
          }
        }
      `,
    })
    return response.data.getChangeDetectionOptions
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting changeDetectionOptions', e)
  }
}

function* getCRMTickets(action) {
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
      query getCRMTicketsForResource {
        Tickets(
          where: {
            resourceSRN: {
              op: EQ
              value: "${action.payload}"
            }
            ticketType: {
              op: IN_LIST
              values: ["Access", "Activity", "Property"]
            }
            status: { op: IN_LIST, values: ["NEW", "IN_PROGRESS"] }
          }
        ) {
          items(
            limit: 1000,
          ) {
            srn
            resourceId
            createdDate
            createdBy
            orgName
            assignedTo
            transitionedBy
            transitionDate
            isSnoozed
            snoozedUntil
            ticketType
            ticketKey
            description
            title
            status
            account
            firstSeen
            lastSeen
            flag
            evidence {
              conditions
              path
              count
              fieldType
              boolValue
              intValue
              longValue
              value
              prevBoolValue
              prevIntValue
              prevLongValue
              prevValue
              resourceSet
              conditions
              path
              regionSet
              userAgentSet
            }
            resourceSRN
            severityNumeric
            severityCategory
            swimlaneSRNs
            actionClassification
            resourceLabel
            resourceType
          }
        }
      }
      `,
    })

    if (response.errors) {
      throw new Error(response.errors)
    }

    const tickets = _.get(response, ['data', 'Tickets', 'items'])

    if (!tickets) {
      throw new Error('Received null items')
    } else {
      yield put(setCrmTickets(tickets))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`${e} there was an error getting CRM tickets`)
  }
}

function* toggleResourceMonitoring(action) {
  const { isMonitored, srn } = action.payload
  const change = !isMonitored
  //optimiticly update change
  yield put(setResourceMonitoring(change))
  yield put(updateRapsheetResourceMonitor({ srn: srn, isMonitored: change }))
  // actually make change
  const client = getClient()
  const response = yield client.mutate({
    mutation: gql`
        mutation toggleResourceMonitoring {
          setMonitor(monitorStatusBySrn: {srn: "${srn}", monitor: ${change} }){
            srn
            monitor
          }
        }
      `,
  })

  // compare result of change to our assumption, if they are the same do nothing, if not, correct the data
  const update = response.data.setMonitor[0].monitor

  if (update !== change) {
    yield put(updateRapsheetResourceMonitor({ srn: srn, isMonitored: update }))
    yield put(setResourceMonitoring(update))
  }
}

export function* fetchEffectivePermissionsForActor(action) {
  const client = yield getClient()

  const epfilter = {
    permissionActivity: {
      op: action.payload.usedPermissions ? 'LT' : 'GT',
      value: 30,
    },
  }

  try {
    const results = yield client.query({
      query: gql`
        query effectivePermission${
          action.payload.usedPermissions ? 'Used' : ''
        }(
          $epfilter: EffectivePermissionFilterType
          $srn: String!
        ) {
          Actors(where: { srn: { value: $srn } }) {
            items(limit: 1) {
              hasEffectivePermissions(
                distinct: [
                  identityChain,
                  permission, 
                  permissionSrn, 
                  policySrn,
                  policyEntryConditions, 
                  policyEntryResourceFilter, 
                  resourceId
                ]
                where: { includes: [$epfilter] 
              }) {
                items {
                  identityChain,
                  permission,
                  permissionSrn,
                  policySrn,
                  policyEntryConditions
                  policyEntryResourceFilter
                  resourceId
                }
              }
            }
          }
        }
      `,
      variables: {
        srn: action.payload.srn,
        epfilter,
      },
    })

    if (results.data) {
      const permissions = _.get(
        results.data,
        ['Actors', 'items', '0', 'hasEffectivePermissions', 'items'],
        []
      )

      yield put(
        fetchEffectivePermissionsForActorSuccess({
          usedPermissions: action.payload.usedPermissions,
          effectivePermissions: permissions,
        })
      )
    } else {
      throw new Error('Error fetching effective permissions')
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

export function* fetchUberPolicy(action) {
  const client = yield getClient()

  try {
    const results = yield client.query({
      query: gql`
        query fetchUberPolicyForNode($srn: String!) {
          AnalyticsResults(
            where: {
              type: { value: AWS_UBER_POLICY }
              resourceSrn: { value: $srn }
            }
          ) {
            items {
              data
            }
          }
        }
      `,
      variables: {
        srn: action.payload,
      },
    })

    if (results.errors) {
      throw new Error(results.errors)
    }

    const policy = _.get(results, [
      'data',
      'AnalyticsResults',
      'items',
      0,
      'data',
      0,
      'policy',
    ])

    if (!policy) {
      throw new Error('No results returned')
    }

    yield put(fetchUberPolicySuccess(policy))
  } catch (e) {
    yield put(fetchUberPolicyError(e.message))
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* nodeSolutionCenterSaga() {
  yield all([
    takeLatest(GET_DATA, getData),
    takeLatest(GET_TICKET_COUNT, getTicketCount),
    takeLatest(GET_CRM_TICKETS, getCRMTickets),
    takeLatest(TOGGLE_RESOURCE_MONITORING, toggleResourceMonitoring),
    takeLatest(RELOAD_SWIMLANES, getSwimlaneData),
    takeEvery(
      FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR,
      fetchEffectivePermissionsForActor
    ),
    takeLatest(FETCH_UBER_POLICY, fetchUberPolicy),
  ])
}

export default nodeSolutionCenterSaga
