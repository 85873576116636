export const GET_INTEGRATIONS = 'app/PlatformSettingsData/GET_INTEGRATIONS'
export const SET_INTEGRATIONS = 'app/PlatformSettingsData/SET_INTEGRATIONS'
export const SET_INTEGRATIONS_ERROR =
  'app/PlatformSettingsData/SET_INTEGRATIONS_ERROR'

export const CREATE_INTEGRATION = 'app/PlatformSettingsData/CREATE_INTEGRATION'
export const CREATE_INTEGRATION_SUCCESS =
  'app/PlatformSettingsData/CREATE_INTEGRATION_SUCCESS'
export const CREATE_INTEGRATION_ERROR =
  'app/PlatformSettingsData/CREATE_INTEGRATION_ERROR'

export const DELETE_INTEGRATION = 'app/PlatformSettingsData/DELETE_INTEGRATION'
export const DELETE_INTEGRATION_SUCCESS =
  'app/PlatformSettingsData/DELETE_INTEGRATION_SUCCESS'
export const DELETE_INTEGRATION_ERROR =
  'app/PlatformSettingsData/DELETE_INTEGRATION_ERROR'

export const UPDATE_INTEGRATION = 'app/PlatformSettingsData/UPDATE_INTEGRATION'
export const UPDATE_INTEGRATION_SUCCESS =
  'app/PlatformSettingsData/UPDATE_INTEGRATION_SUCCESS'
export const UPDATE_INTEGRATION_ERROR =
  'app/PlatformSettingsData/UPDATE_INTEGRATION_ERROR'

export const UPDATE_INTEGRATION_CONFIG =
  'app/PlatformSettingsData/UPDATE_INTEGRATION_CONFIG'
export const UPDATE_INTEGRATION_CONFIG_SUCCESS =
  'app/PlatformSettingsData/UPDATE_INTEGRATION_CONFIG_SUCCESS'
export const UPDATE_INTEGRATION_CONFIG_ERROR =
  'app/PlatformSettingsData/UPDATE_INTEGRATION_CONFIG_ERROR'

export const ASSIGN_INTEGRATION = 'app/PlatformSettingsData/ASSIGN_INTEGRATION'
export const ASSIGN_INTEGRATION_SUCCESS =
  'app/PlatformSettingsData/ASSIGN_INTEGRATION_SUCCESS'
export const ASSIGN_INTEGRATION_ERROR =
  'app/PlatformSettingsData/ASSIGN_INTEGRATION_ERROR'

export const DELETE_INTEGRATION_ASSIGNMENT =
  'app/PlatformSettingsData/DELETE_INTEGRATION_ASSIGNMENT'
export const DELETE_INTEGRATION_ASSIGNMENT_SUCCESS =
  'app/PlatformSettingsData/DELETE_INTEGRATION_ASSIGNMENT_SUCCESS'
export const DELETE_INTEGRATION_ASSIGNMENT_ERROR =
  'app/PlatformSettingsData/DELETE_INTEGRATION_ASSIGNMENT_ERROR'

export const INTEGRATION_TYPES = {
  SLACK: 'slack',
  SERVICE_NOW: 'service_now',
  JIRA: 'jira',
}

export const slackActionTypeKey = {
  TICKET_OPENED: 'Ticket Opens',
  TICKET_CLOSED: 'Ticket Closes',
  TICKET_REOPENED: 'Ticket Reopens',
  TICKET_SNOOZED: 'Ticket Snoozed',
  TICKET_RISK_ACCEPTED: 'Ticket Risk Accepted',
  TICKET_ASSIGNED: 'Ticket Assigned',
  TICKET_COMMENT_ADDED: 'Ticket Comment Added',
  TICKET_ESCALATION_EXECUTED: 'Ticket Escalation Executed',
}

export const slackActionTypes = Object.keys(slackActionTypeKey).map(type => ({
  value: type,
  label: slackActionTypeKey[type],
}))
