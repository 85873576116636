// src/react-auth0-wrapper.js
import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import jwt from 'jsonwebtoken'
import PropTypes from 'prop-types'
import createAuth0Client from '@auth0/auth0-spa-js'
import AuthDB, { TokenFetchType, LogoutEventType } from 'auth/auth-db'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

let auth0ClientExport
export const getAuth0Client = () => {
  return auth0ClientExport
}

/**
 * here is our DB to keep track of session telemetry .. aids in debugging
 * session problems
 */
const WINDOW_AUTHDB_KEY = '$$_AUTHDB'

export const getAuthDB = () => {
  return window[WINDOW_AUTHDB_KEY]
}
const setAuthDB = authDB => {
  window[WINDOW_AUTHDB_KEY] = authDB
}

// things can wait on the initial authorization call to auth0 using this promise
let resolveTokenIsSet
let rejectTokenIsSet
const initialTokenLoad = new Promise((resolve, reject) => {
  resolveTokenIsSet = resolve
  rejectTokenIsSet = reject
})

/**
 * There are some functions in this module that interact with one another to
 * manage the user's session. The basic goal of managing the session is that the
 * user should be logged out of the app if they are idle in all tabs for the
 * some duration (30mins).
 *
 * During the time the session is active, the user should have a valid jwt
 * token, which we store on the window object as window.aat.
 *
 * Technically the session idle duration is the lifespan where the jwt token
 * remains valid. 30mins is what we have it configured to at this time.
 *
 * When a user interacts with the system by clicking or pressing a key, we run
 * some code to decide if we should get a new token. There are two cases:
 *
 * ~~ Case 1 ~~
 * If the token's tll is partially over, we will fetch a new token
 * immediately. We don't wait for the token's ttl to be fully over because
 * once that happens the session would also be expired and we couldn't get a
 * new token.
 *
 * The percentage of the token's TTL we wait before we refetch is set by the
 * variable TOKEN_GOODFOR_LIFE_PCT
 *
 * So for example, if the token TTL was 30 mins, and the user interacted with
 * the app 10 minutes after the token's iat, we would get a new token.
 *
 * ~~ Case 2 ~~
 * If the token's ttl has not passed this threshold, we don't refetch the token
 * but we do set an interval to refetch the token in a little bit.
 *
 * This handles the case where, say for example, a user interacted with the app
 * after 8 mins, but didn't interact with the app again until after the 30 min
 * mark, their token would be expired.
 *
 * We fetch the token either at the minimum time we would in case 1, but if
 * that time is too far in the future, we do it after some max aount of
 * time. That amount of time is set by MAX_REFETCH_AFTER_INTERACTION
 *
 * The reason we wait some max time is, say we didn't and the user interacted
 * with the app 1 minute afer their tokenw as expired. We would refetch at 10
 * minute mark, and then if they never interafed with the app again, we would
 * log them out 30 mins after that, but really they have been idle for 39
 * minutes.
 *
 * If the user is active in a different tab, we also need to fetch a token to
 * use on this tab. To do that, we keep track of the last page interaction
 * across all tabs in localStorage. There is a function that runs on an
 * interval which checks if an interaction has recently ocurrred, and it if has
 * the function will get a new token (checkLastPageInteraction).
 */
const TOKEN_GOODFOR_LIFE_PCT = 0.33
const MAX_REFETCH_AFTER_INTERACTION = 60 * 1000

/**
 * Force fetch a new token, even if the current token isn't expired
 */
const forceFetchNewToken = async () => {
  const auth0Client = await getAuth0Client()
  clearTokenCache(auth0Client)
  return await auth0Client.getTokenSilently({
    audience: window.config.idpGraphQLAudience,
    scope: 'openid profile read:data read:platform read:token',
  })
}

/**
 * When we get a new token, we set a timeout function to run when the token
 * expires to logout the user immediately. This is to handle the case where
 * a user might sit down at the computer, their token might already be expired
 * but they haven't been kicked out of the app yet (say by the code that
 * handles 401 from API). They could sit there for a few mins before getting
 * kicked out, so we log them out immediately
 */
let logoutTimeout
const setLogoutTimeout = token => {
  // make sure we log out right when the token expiress
  const { exp, iat } = jwt.decode(token)
  const tokenTTL = (exp - iat) * 1000
  logoutTimeout = setTimeout(async () => {
    getAuthDB().logoutEvent(LogoutEventType.EXPIRE_TIMEOUT)
    logout(await getAuth0Client())
  }, tokenTTL)
}

/**
 * Get a new token and set it as window.aat
 */
const setNewTokenOnWindow = async () => {
  try {
    window.aat = await forceFetchNewToken()
    if (logoutTimeout) {
      clearTimeout(logoutTimeout)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error happen refetching token', e)
  }
  setLogoutTimeout(window.aat)
  getAuthDB().tokenFetchEvent(window.aat, TokenFetchType.REFETCH)
}

/**
 * This is a bit of a hack, auth0Client isn't supposed to let you force it to manually
 * refresh the token, but it stores the curent token in this cache object, so if you
 * delete everything out of it, it will refetch
 */
const clearTokenCache = auth0Client => {
  const cache = auth0Client.cache.cache
  const keys = Object.keys(cache)
  for (let key of keys) {
    delete cache[key]
  }
}

/**
 * We keep track of the last time the user interacted with a page of the
 * application in any open tab using local storage - this will be returned
 * in *epoch seconds*
 */
export const LAST_PAGE_INTERACTION_LS_KEY =
  '__sonrai__LAST_PAGE_INTERACTION_LS_KEY'

export const getLastPageInteraction = () => {
  const rawValue = localStorage.getItem(LAST_PAGE_INTERACTION_LS_KEY)
  const asNumber = Number(rawValue)
  let asEpochSeconds = null
  if (!asNumber) {
    // eslint-disable-next-line no-console
    console.warn(
      `invalid local storage value ${LAST_PAGE_INTERACTION_LS_KEY}:${rawValue}`
    )
    asEpochSeconds = Math.floor(new Date().getTime() / 1000)
  } else {
    asEpochSeconds = asNumber
  }
  return asEpochSeconds
}

export const setLastPageInteraction = (
  epochSecondsNow = new Date().getTime() / 1000
) => {
  if (typeof epochSecondsNow !== 'number') {
    throw new Error(
      'IllegalArgumentError: must be number, received: ' +
        typeof epochSecondsNow
    )
  }
  localStorage.setItem(LAST_PAGE_INTERACTION_LS_KEY, epochSecondsNow)
}

export const UIEXP_CLAIM = 'https://sonraisecurity.com/uiexp'
export const getEffectiveTokenExpiration = decodedToken => {
  // if the caller didn't pass decoded token, recall with decoded
  if (typeof token === 'string') {
    // token is encoded - decode and call
    return getEffectiveTokenExpiration(jwt.decode(decodedToken))
  }
  const { exp, [UIEXP_CLAIM]: uiexp } = decodedToken
  if (uiexp && exp) {
    return Math.min(uiexp, exp)
  }
  if (exp) {
    return exp
  }
  if (uiexp) {
    return uiexp
  }
}

/**
 * when user interacts with page, we check if they need a new token
 * and possibly go get one - see big comment above explains what is
 * happening in this function.
 */
let fetchTokenInFutureTimeout
const handlePageInteraction = () => {
  const token = window.aat
  const decodedToken = jwt.decode(token)
  if (!decodedToken) {
    return
  }

  const { iat } = decodedToken
  const exp = getEffectiveTokenExpiration(decodedToken)

  // calcualte the time in the future we should fetch new token
  const tokenLife = exp - iat
  const fetchAfter = iat + tokenLife * TOKEN_GOODFOR_LIFE_PCT

  // clear the futue token refetch due to new page interaction
  if (fetchTokenInFutureTimeout) {
    clearTimeout(fetchTokenInFutureTimeout)
  }

  // if it's passed the time we should have got the new token, get it now
  const now = new Date().getTime() / 1000
  if (fetchAfter < now) {
    return setNewTokenOnWindow()
  }

  // otherwise get the new token a short time in the future
  const millisUntilGetNewToken = Math.min(
    (fetchAfter - now) * 1000,
    MAX_REFETCH_AFTER_INTERACTION
  )
  fetchTokenInFutureTimeout = setTimeout(
    () => setNewTokenOnWindow(),
    millisUntilGetNewToken
  )
}

// we don't run this method every time user does something
const throttledHandlePageInteraction = _.throttle(
  () => {
    setLastPageInteraction() // defaults to now
    handlePageInteraction()
  },
  5000, // only run it every 5 secs
  {
    leading: true,
    trailing: false,
  }
)
document.addEventListener('click', throttledHandlePageInteraction)
document.addEventListener('keydown', throttledHandlePageInteraction)

/**
 * If the user is active in a different tab, we want to make sure their token
 * doesn't expire due to idleness on this tab, so use the local stored value
 *
 * it's OK if the tab the user last ineracted with is the current tab
 */
const otherTabInteractionCheckInterval = 15 // seconds
const checkLastPageInteraction = () => {
  const lastPageInteraction = getLastPageInteraction()
  const nowEpochSeconds = new Date().getTime() / 1000

  const intervalSinceLastInteraction = nowEpochSeconds - lastPageInteraction
  if (intervalSinceLastInteraction < otherTabInteractionCheckInterval) {
    handlePageInteraction()
  }
}
setInterval(checkLastPageInteraction, otherTabInteractionCheckInterval * 1000)

/**
 * either get the cucrent token, or if it is expired, check if the user
 * has recently interacted with the page. If they have, refresh the token
 * otherise, return the expired token
 */
export const getCurrentToken = async () => {
  return window.aat
}

/**
 * helper function to make sure we logout with a callback to the right path
 */
export const logout = (auth0Client, ...p) => {
  auth0Client.logout({ returnTo: `${window.location.origin}/Login`, ...p })
}

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)
      auth0ClientExport = auth0FromHook

      if (
        window.location.search.includes('code=') &&
        !window.location.search.includes('code=success') &&
        window.location.pathname != '/App/DoSlackAuth'
      ) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback()
          onRedirectCallback(appState)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('error handling redirct callback', e)
          if (
            e.message ===
            "The code was generated for a user who doesn't exist anymore."
          ) {
            auth0FromHook.loginWithRedirect({
              audience: window.config.idpGraphQLAudience,
              scope: 'openid profile read:data read:platform read:token',
            })
          }
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()

        auth0FromHook
          .getTokenSilently({
            audience: window.config.idpGraphQLAudience,
            scope: 'openid profile read:data read:platform read:token',
          })
          .then(token => {
            window.aat = token
            setIsAuthenticated(isAuthenticated)
            setUser(user)
            setAuthDB(new AuthDB(user))
            getAuthDB().tokenFetchEvent(token, TokenFetchType.INITIAL)
            setLogoutTimeout(window.aat)
            resolveTokenIsSet(token)
          })
          .catch(e => {
            rejectTokenIsSet(e)
            auth0FromHook
              .loginWithRedirect({
                audience: window.config.idpGraphQLAudience,
                scope: 'openid profile read:data read:platform read:token',
              })
              .then(token => {
                window.aat = token
                setLogoutTimeout(window.aat)
              })
          })
      }

      setLoading(false)
    }
    initAuth0()
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    if (auth0Client) {
      try {
        await auth0Client.loginWithPopup(params)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        setPopupOpen(false)
      }
      const user = await auth0Client.getUser()
      setUser(user)
      setIsAuthenticated(true)
    }
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        initialTokenLoad,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => logout(auth0Client, ...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

Auth0Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  onRedirectCallback: PropTypes.func,
}
