import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import _ from 'lodash'
import { List } from 'immutable'
import MultiSelect from 'react-select'
import makeAnimated from 'react-select/animated'
import { Dropdown, DropdownToggle } from 'reactstrap'

import TimeoutLoader from 'components/TimeoutLoader'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { getNodeInfoQuery } from 'utils/graphDataUtils'
import { getFieldsForType, getFullType } from 'query-builder'
import Icon from 'components/Icon'
import {
  selectQueryNames,
  selectQueryTypes,
  selectQueryRelations,
} from 'containers/SonraiData/selectors'
import { computeAvailableFilters } from 'utils/sonraiUtils'
import BorderlessButton from 'components/BorderlessButton'
import TextLink from 'components/TextLink'
import CenterContent from 'components/CenterContent'
import { PreviewCard } from 'components/NodeView'
import DropdownMenu from 'components/StyledReactstrapDropdownMenu'

import {
  selectAvailableFilters,
  selectNodes,
  selectSelectedNode,
  selectIsLoadingNodePreview,
  selectNodePreview,
  selectNodePreviewRelations,
} from './selectors'
import {
  getRelations,
  removeRelations,
  setFilter,
  removeFilter,
  selectNode,
  clearFilters,
  loadAllChildren,
  getNodePreview,
  setAvailableFilters,
  removeNode,
  getNodePreviewRelations,
} from './actions'

const skipValues = [
  'srn',
  'sid',
  'observedDate',
  'loadId',
  'resourceId',
  'createdBy',
  'content',
]

class NodePreview extends Component {
  styles = {
    container: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
      gridTemplateAreas: '"header" "body" "children"',
      maxHeight: '80%',
    },
    header: {
      minWidth: '100%',
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gridArea: 'header',
    },
    body: {
      gridArea: 'body',
      maxHeight: '500px',
    },
    closeButton: {
      alignSelf: 'start',
      margin: '0.05rem 0rem 0rem 0.5rem',
    },
    removeNode: {
      display: 'block',
      width: '100%',
      marginBottom: '0.5em',
      paddingTop: '0.25em',
      paddingBottom: '0.25em',
      paddingLeft: '1em',
      paddingRight: '1em',
      textAlign: 'left',
    },
    filterTitle: {
      margin: '0.5rem 0rem',
      fontWeight: '400',
    },
    disabledSelectAll: {
      cursor: 'default',
      color: '#c8c8c8',
    },
    selectHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    moreButton: {
      padding: '0 0.3em',
      border: 'none',
      display: 'inline',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    iconMenu: {
      display: 'flex',
      alignSelf: 'flex-start',
      alignItems: 'center',
    },
  }

  constructor(props) {
    super(props)

    this.state = {
      dropdownExpanded: false,
    }
    if (props.selectedNode) {
      const query = this.getGenericQuery(props.selectedNode)
      const variables = { srn: props.selectedNode }
      props.getNodePreview({ query, variables })

      const nodeConfig = props.nodes.get(props.selectedNode)

      const nodeType = getFullType(nodeConfig.get('__typename'))

      const itemType = props.queryTypes
        .getIn([nodeType, 'fields'], List())
        .find(i => i.get('name') === 'items')
        .getIn(['type', 'ofType', 'name'])

      if (!this.props.availableFilters.get(itemType)) {
        const availableRelations = computeAvailableFilters(
          itemType,
          props.queryRelations,
          props.queryTypes
        )

        props.setAvailableFilters({
          type: itemType,
          filters: availableRelations,
        })
      }
    }
  }

  componentDidUpdate(oldProps) {
    const { nodePreview } = this.props
    const srn = nodePreview.get('srn')
    if (oldProps.nodePreview.get('srn') !== srn) {
      if (srn) {
        this.props.getNodePreviewRelations(srn)
      }
    }
  }

  getGenericQuery = nodeId => {
    return getNodeInfoQuery(
      nodeId,
      this.props.queryNames,
      this.props.queryTypes
    )
  }

  isActive = label => {
    const filterForLabel = this.props.nodes
      .get(this.props.selectedNode)
      .get('filters')
      .get(label)
    if (filterForLabel && filterForLabel.get('selected')) {
      return true
    }
    return false
  }

  handleSelectAll = () => {
    if (this.props.nodePreviewRelations.isEmpty()) {
      return
    }

    const node = this.props.nodePreview
    const sourceNodes = this.props.sourceNodes.toJS()
    const parentNodes = this.props.nodes
      .get(this.props.selectedNode)
      .get('parents')
      ? this.props.nodes.get(this.props.selectedNode).get('parents').toJS()
      : []

    let relations = {}
    const srn = node.get('srn')

    this.props.nodePreviewRelations.forEach(relation => {
      const typename = relation.getIn(['item', '__typename'])
      const newRelation = { parent: srn, ...relation.toJS() }
      if (!relations[typename]) {
        relations[typename] = [newRelation]
      } else {
        relations[typename].push(newRelation)
      }
    })

    _.keys(relations).forEach(type => {
      const typeArr = relations[type]
        .filter(x => !sourceNodes.includes(x.item.srn))
        .filter(y => !parentNodes.includes(y.item.srn))
      if (!_.isEmpty(typeArr)) {
        this.props.setFilter(type)
      }
    })

    this.props.loadAllChildren({
      srn,
      relations,
    })
  }

  handleChildFilter = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
        this.props.setFilter(actionMeta.option.value)
        this.props.getRelations({
          srn: this.props.selectedNode,
          itemFilter: {
            label: { value: actionMeta.option.value, caseSensitive: false },
          },
        })
        break
      case 'clear':
        this.props.clearFilters({
          node: this.props.selectedNode,
          sourceNodes: this.props.sourceNodes,
        })
        break
      default:
        this.props.removeFilter(actionMeta.removedValue.value)
        this.props.removeRelations({
          srn: this.props.selectedNode,
          label: actionMeta.removedValue.value,
          sourceNodes: this.props.sourceNodes,
        })
    }
  }

  getPreviewFields = () => {
    const type = this.props.nodePreview.get('__typename').toLowerCase()
    const fields = getFieldsForType(type, this.props.queryTypes).filter(
      field => !skipValues.includes(field.get('name'))
    )

    return fields.toJS()
  }

  getOptions = nodeId => {
    if (nodeId && this.props.nodes) {
      const selectedNode = this.props.nodes.get(nodeId)
      if (selectedNode) {
        const selectedType = selectedNode.get('__typename')
        return _.keys(this.props.availableFilters.get(selectedType))
          .filter(filter => filter !== 'Action')
          .map(filter => ({
            label: filter,
            value: filter,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      }
    }
  }

  toggleDropdown = () => {
    this.setState(oldState => ({
      dropdownExpanded: !oldState.dropdownExpanded,
    }))
  }

  getValues = options => {
    return options.filter(option => this.isActive(option.value))
  }

  render() {
    if (this.props.nodePreview.isEmpty() || this.props.isLoadingNodePreview) {
      return (
        <div style={this.styles.container}>
          <div style={this.styles.header}>
            <div />
            <div style={this.styles.iconMenu}>
              <BorderlessButton
                style={this.styles.closeButton}
                title="Close"
                onClick={() => this.props.selectNode({ node: null })}
              >
                <Icon fa name="times" />
              </BorderlessButton>
            </div>
          </div>
          <div style={this.styles.body}>
            <CenterContent>
              <TimeoutLoader
                firstMessage="Working on your query..."
                finalMessage="Took too long to load this node's details. Please try again."
              />
            </CenterContent>
          </div>
        </div>
      )
    }

    const node = this.props.nodePreview
    const selectOptions = this.getOptions(this.props.selectedNode)
    const value = this.getValues(selectOptions)

    return (
      <PreviewCard
        nodeData={node.toJS()}
        fields={this.getPreviewFields()}
        actions={
          <Fragment>
            {!this.props.sourceNodes.includes(node.get('srn')) && (
              <Dropdown
                style={{ alignSelf: 'flex-start' }}
                isOpen={this.state.dropdownExpanded}
                toggle={this.toggleDropdown}
              >
                <DropdownToggle style={this.styles.moreButton}>
                  <Icon fa name="ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu
                  style={{ border: 'none' }}
                  onClick={this.toggleDropdown}
                >
                  <BorderlessButton
                    style={this.styles.removeNode}
                    title="Remove Node"
                    onClick={() => this.props.removeNode(node)}
                  >
                    Remove Node
                  </BorderlessButton>
                </DropdownMenu>
              </Dropdown>
            )}
            <BorderlessButton
              style={this.styles.closeButton}
              title="Close"
              onClick={() => this.props.selectNode({ node: null })}
            >
              <Icon fa name="times" />
            </BorderlessButton>
          </Fragment>
        }
      >
        <div style={this.styles.selectHeader}>
          <span style={this.styles.filterTitle}>Show Relations:</span>
          <TextLink
            onClick={this.handleSelectAll}
            style={{
              borderBottom:
                !this.props.nodePreviewRelations.isEmpty() &&
                `1px solid ${this.props.theme.primary}`,
            }}
            color="primary"
            disabled={this.props.nodePreviewRelations.isEmpty()}
          >
            {this.props.nodePreviewRelations.isEmpty() && (
              <Icon fa spin name="sync" size="sm" />
            )}{' '}
            Show All
          </TextLink>
        </div>

        <MultiSelect
          isMulti
          isLoading={_.isEmpty(selectOptions)}
          components={makeAnimated()}
          value={value}
          onChange={this.handleChildFilter}
          options={selectOptions}
          styles={this.props.colourStyles}
        />
      </PreviewCard>
    )
  }
}

NodePreview.propTypes = {
  availableFilters: ImmutablePropTypes.iterable.isRequired,
  clearFilters: PropTypes.func.isRequired,
  colourStyles: PropTypes.object.isRequired,
  isLoadingNodePreview: PropTypes.bool,
  getNodePreview: PropTypes.func.isRequired,
  getRelations: PropTypes.func.isRequired,
  loadAllChildren: PropTypes.func.isRequired,
  nodePreview: ImmutablePropTypes.map.isRequired,
  nodes: ImmutablePropTypes.iterable.isRequired,
  queryNames: ImmutablePropTypes.iterable.isRequired,
  queryRelations: ImmutablePropTypes.iterable.isRequired,
  queryTypes: ImmutablePropTypes.iterable.isRequired,
  removeFilter: PropTypes.func.isRequired,
  removeRelations: PropTypes.func.isRequired,
  selectedNode: PropTypes.string,
  selectNode: PropTypes.func.isRequired,
  setAvailableFilters: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  theme: themeShape,
  sourceNodes: PropTypes.object,
  removeNode: PropTypes.func,
  getNodePreviewRelations: PropTypes.func,
  nodePreviewRelations: ImmutablePropTypes.map,
}

const mapStateToProps = createStructuredSelector({
  queryNames: selectQueryNames,
  queryRelations: selectQueryRelations,
  queryTypes: selectQueryTypes,
  availableFilters: selectAvailableFilters,
  nodes: selectNodes,
  selectedNode: selectSelectedNode,
  isLoadingNodePreview: selectIsLoadingNodePreview,
  nodePreview: selectNodePreview,
  nodePreviewRelations: selectNodePreviewRelations,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearFilters,
      getNodePreview,
      loadAllChildren,
      getRelations,
      removeRelations,
      setAvailableFilters,
      setFilter,
      removeFilter,
      selectNode,
      removeNode,
      getNodePreviewRelations,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, themeable)(NodePreview)
