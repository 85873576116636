/**
 *
 * ContextMenu
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import BorderlessButton from 'components/BorderlessButton'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class ContextMenu extends React.Component {
  styles = {
    container: {
      zIndex: 100,
      position: 'absolute',
      top: `${this.props.coords[0]}px`,
      left: `${this.props.coords[1]}px`,
      boxShadow: `rgba(176, 176, 176, 0.5) 0px 0px 5px 2.5px`,
      padding: '0.5rem',
      backgroundColor: this.props.theme.light,
      borderRadius: '0.2rem',
    },
    button: {
      padding: '0 0.3em',
      border: 'none',
      fontSize: '0.9rem',
      display: 'inline',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  }

  getActions = actions =>
    actions.map(box => (
      <BorderlessButton
        key={actions.indexOf(box)}
        style={this.styles.button}
        onClick={box.action}
      >
        {box.text}
      </BorderlessButton>
    ))

  render() {
    return (
      <div style={this.styles.container}>
        {this.getActions(this.props.actions)}
      </div>
    )
  }
}

ContextMenu.propTypes = {
  actions: PropTypes.array,
  coords: PropTypes.array,
  theme: themeShape,
}

export default themeable(ContextMenu)
