import { styled as stylesIn } from 'react-tabtab'
import styled from 'styled-components'

export const getTheme = (theme, small) => {
  let { TabListStyle, ActionButtonStyle, TabStyle, PanelStyle } = stylesIn

  TabStyle = styled(TabStyle)`
    color: ${theme.dark};
    font-weight: 300;
    padding: 0;
    margin: 0 5px;
    transition: color 0.28s ease;
    border: none;
    border-bottom: 2px solid #fff;
    height: ${small ? '37px' : '47px'};
    &:hover {
      border-bottom: 2px solid ${theme.primary};
    }
    ${props => {
      return props.active
        ? `
        color: ${theme.primary};
      `
        : null
    }}
    ${props =>
      props.active && !props.vertical
        ? `
        border-bottom: 2px solid ${theme.primary};
      `
        : null};
  `

  ActionButtonStyle = styled(ActionButtonStyle)`
    background-color: transparent;
    border: none;
    padding: 0px;
    margin-top: -0.1em;
    border-radius: 0;
    &:hover {
      color: #1b75bc;
    }
  `

  PanelStyle = styled(PanelStyle)`
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 30px 30px;
    transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
    border-radius: 2px;
  `

  return {
    TabList: TabListStyle,
    ActionButton: ActionButtonStyle,
    Tab: TabStyle,
    Panel: PanelStyle,
  }
}
