import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { getNameFromSrn } from 'utils/sonraiUtils'
import CloudAccount from 'components/CloudAccount'
import NodeViewDetailMetadataBody, {
  SEARCH_INLINE,
} from 'components/NodeView/NodeViewDetailMetadataBody'
import TextLink from 'components/TextLink'
import JSONDisplay from 'components/JSONDisplay'
import { FlatPathWidget } from 'components/PathWidget'
import { selectCanAccessPathsLoading } from './selectors'
import Button from 'components/Button'

class ShowPathModal extends Component {
  styles = {
    titleName: {
      fontWeight: 500,
    },
    titleDetailsContainer: {
      paddingTop: '8px',
    },
    titleDetailsFieldKey: {
      fontWeight: 500,
      paddingRight: '5px',
    },
    titleDetailsFieldValue: {
      fontWeight: 300,
    },
    titleDetailsRow: {
      fontSize: '14px',
      paddingTop: '5px',
    },
    titleDetailsMetadataRow: {
      borderTop: '1px solid #eee',
      fontSize: '12px',
    },
    titleDetialsMetadata: {
      marginTop: '5px',
      width: '100%',
    },
    titleDetailsMetadataKey: {
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingRight: '8px',
    },
    titleDetailsMetadataValue: {
      paddingTop: '5px',
      paddingBottom: '5px',
    },
  }

  state = {
    selectedTab: 0,
  }

  renderPolicyJSONView = () => {
    const content = JSON.parse(this.props.pathEndItem.json)
    return <JSONDisplay content={content} />
  }

  renderMetadataView = () => {
    return (
      <div
        className="show-path-modal-metadata-container"
        style={{
          maxHeight: '350px',
          overflow: 'auto',
        }}
      >
        <NodeViewDetailMetadataBody
          value={this.props.pathEndItem.metadata}
          search={SEARCH_INLINE}
        />
      </div>
    )
  }

  renderJSONViews = () => {
    if (!this.props.pathEndItem.json) {
      return (
        <div style={{ margin: '0.25rem 0rem 0rem 0.1rem' }}>
          <span
            style={{
              ...this.styles.titleDetailsFieldKey,
              ...{ fontSize: '14px' },
            }}
          >
            Metadata
          </span>

          {this.renderMetadataView()}
        </div>
      )
    }
    return (
      <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '-0.25rem 0rem -0.5rem 0rem',
          }}
        >
          <Button
            color={this.state.selectedTab === 0 && 'primary'}
            style={{
              borderRadius: '10px',
              fontSize: '0.9rem',
              margin: '1rem 0.5rem 1rem 0rem',
            }}
            onClick={() => this.setState({ selectedTab: 0 })}
          >
            Policy JSON
          </Button>
          <Button
            color={this.state.selectedTab === 1 && 'primary'}
            style={{
              borderRadius: '10px',
              fontSize: '0.9rem',
              margin: '1rem 0rem',
            }}
            onClick={() => this.setState({ selectedTab: 1 })}
          >
            Metadata
          </Button>
        </div>
        {this.state.selectedTab === 0
          ? this.renderPolicyJSONView()
          : this.renderMetadataView()}
      </div>
    )
  }

  getTitleDetailsKeyName = key => {
    return key
      .replace('zzmetadata_', '')
      .split(/(?=[A-Z\\.])/)
      .map(segment => segment.replace('.', ''))
      .map(_.capitalize)
      .join(' ')
  }

  getFieldValueText = ({ key, value, item }) => {
    if (key === 'account') {
      const cloudType = item.cloudType
      const accountNumber = item.account
      const accountSRN = `srn:${cloudType}:account::${accountNumber}/Account/${accountNumber}`
      return <CloudAccount accountSrn={accountSRN} />
    } else {
      return <span>{value}</span>
    }
  }

  getTitleDetails = () => {
    if (!this.props.pathEndItem) {
      return <span />
    }

    const fields = Object.keys(this.props.pathEndItem)
      .filter(key => {
        if ('conditions' === key || 'path' === key || '__typename' === key) {
          return false
        }

        const value = this.props.pathEndItem[key]
        if (
          _.isArray(value) ||
          _.isObject(value) ||
          _.isFunction(value) ||
          _.isEmpty(value)
        ) {
          return false
        }
        return true
      })
      .reduce((fields, key) => {
        return Object.assign({ [key]: this.props.pathEndItem[key] }, fields)
      }, {})

    if (this.props.pathEndItem.rbac) {
      let name = this.props.pathEndItem.isRoot
        ? `(Root) ${this.props.pathEndItem.rbac.resourcePath}`
        : this.props.pathEndItem.rbac.resourcePath
      fields[
        this.props.pathEndItem.rbac.resourceType === 'directResource'
          ? this.props.pathEndItem.rbac.resourceType
          : this.props.pathEndItem.rbac.resourceType.slice(
              0,
              this.props.pathEndItem.rbac.resourceType.length - 1
            )
      ] = name
    }

    return (
      <div style={this.styles.titleDetailsContainer}>
        {Object.keys(fields)
          .sort()
          .map((key, index) => {
            const value = fields[key]
            return (
              <div
                key={`showpathmodal-titledetails-row-${index}`}
                style={this.styles.titleDetailsRow}
              >
                <span style={this.styles.titleDetailsFieldKey}>
                  {this.getTitleDetailsKeyName(key)}
                </span>
                <span style={this.styles.titleDetailsFieldValue}>
                  {this.getFieldValueText({
                    key,
                    value,
                    item: this.props.pathEndItem,
                  })}
                </span>
              </div>
            )
          })}
        {this.renderJSONViews()}
      </div>
    )
  }

  getPath = () => {
    if (this.props.leaveItBeFFS) {
      return this.props.paths
    } else {
      return this.props.paths.map(path => {
        return path.push(
          this.props.actionableByMode
            ? this.props.sourceId
            : this.props.resourceId
        )
      })
    }
  }

  render() {
    const { isOpen, toggle } = this.props

    // give a little mote room if don't need to show title details
    let modalBodyHeight = '600px'
    if (this.props.pathEndItem) {
      if (this.props.pathEndItem.metadata) {
        modalBodyHeight = '300px' // leave extra vertical height for metadata
      } else {
        modalBodyHeight = '450px'
      }
    }

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={{ width: '80vw', minWidth: '700px', maxWidth: '80vw' }}
        size="lg"
      >
        <ModalHeader
          toggle={toggle}
          tag="div"
          cssModule={{ 'modal-title': 'w-100' }}
          style={{ width: '100%' }}
        >
          <div style={{ wordBreak: 'break-all' }}>
            {this.props.actionableByMode ? (
              <span>
                <span style={this.styles.titleName}>
                  {this.props.classification}
                </span>{' '}
                Access Paths from{' '}
                <span style={this.styles.titleName}>
                  {getNameFromSrn(this.props.resourceId)}
                </span>{' '}
                to{' '}
                <span style={this.styles.titleName}>
                  {this.props.sourceName}
                </span>
              </span>
            ) : (
              <span>
                <span style={this.styles.titleName}>
                  {this.props.classification}
                </span>{' '}
                Access Paths from{' '}
                <span style={this.styles.titleName}>
                  {this.props.sourceName}
                </span>{' '}
                to{' '}
                <span style={this.styles.titleName}>
                  {getNameFromSrn(this.props.resourceId)}
                </span>
              </span>
            )}
          </div>
          {this.getTitleDetails()}
        </ModalHeader>
        <ModalBody style={{ height: modalBodyHeight, overflow: 'hidden' }}>
          {
            <FlatPathWidget
              data={this.getPath()}
              loading={
                !this.props.skipLoading && this.props.canAccessPathsLoading
              }
              error={this.props.canAccessPathsError}
              onDoubleClick={this.props.onDoubleClick}
              emphasisNodes={[this.props.resourceId, this.props.sourceId]}
            />
          }
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={toggle}>
            Close
          </TextLink>
        </ModalFooter>
      </Modal>
    )
  }
}

ShowPathModal.propTypes = {
  actionableByMode: PropTypes.bool,
  leaveItBeFFS: PropTypes.bool,
  classification: PropTypes.string,
  paths: ImmutablePropTypes.list.isRequired,
  canAccessPathsLoading: PropTypes.bool,
  canAccessPathsError: PropTypes.bool,
  resourceId: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  pathEndItem: PropTypes.object,
  sourceName: PropTypes.string.isRequired,
  sourceId: PropTypes.string.isRequired,
  skipLoading: PropTypes.bool,
  onDoubleClick: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  canAccessPathsLoading: selectCanAccessPathsLoading,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(ShowPathModal)
