/**
 *
 * DynamicFormattedMessage
 * Provides a default ID and message for translation messages
 * that may be missing their values due to being dynamically created
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

class DynamicFormattedMessage extends React.PureComponent {
  render() {
    return (
      <FormattedMessage
        id={this.props.id || 'undefined.translation'}
        defaultMessage={
          this.props.defaultMessage || '[UNDEFINED TRANSLATION MESSAGE]'
        }
        values={this.props.values}
      />
    )
  }
}

DynamicFormattedMessage.propTypes = {
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  values: PropTypes.object,
}

export default DynamicFormattedMessage
