import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'

import messages from '../messages'

const StyledErrorContainer = styled.div`
  color: red;
`

/**
 * this component is to show error message on CreateUpdateTicketModal
 */
export default function FormErrorMessage(props) {
  if (!props.error) {
    return null
  }

  return (
    <StyledErrorContainer>
      <DynamicFormattedMessage
        {...messages[props.error.key]}
        values={props.error.values}
      />
    </StyledErrorContainer>
  )
}

FormErrorMessage.propTypes = {
  error: PropTypes.shape({
    key: PropTypes.string.isRequired,
    values: PropTypes.object,
  }),
}
