import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles'

class TopTitle extends React.PureComponent {
  render() {
    return (
      <div
        style={{ ...styles.topTitle, ...this.props.style }}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </div>
    )
  }
}

TopTitle.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default TopTitle
