import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import BorderlessButton from 'components/BorderlessButton'
import TextLink from 'components/TextLink'
import ChartLegendEntry from 'components/ChartLegendEntry'

export class SunburstLegend extends React.Component {
  onClickLegend = legendEntry => {
    //Switch filters to only allow one at a time for now
    //Because we were getting weird behaviour in the list of alerts on solution center when there were multiple filters selected
    //TODO: Fix using multiple filters, especially on the same property
    let filters = this.props.legendFilters
    if (
      filters.find(
        filter =>
          filter.keyName === legendEntry.keyName &&
          filter.keyValue === legendEntry.keyValue
      )
    ) {
      //Clicked an already-selected filter so we want to remove it from the list
      filters = filters.filter(
        filter =>
          !(
            filter.keyName === legendEntry.keyName &&
            filter.keyValue === legendEntry.keyValue
          )
      )
    } else {
      //Add the filter to the filter list
      filters = [
        {
          keyName: legendEntry.keyName,
          keyValue: legendEntry.keyValue,
        },
      ]
    }

    this.props.onClickLegend(filters)
  }

  onReset = () => {
    this.props.onClickLegend([])
  }

  getLegendItems = () => {
    const getLegend = (node, items, depth) => {
      const key = node.keyName ? `${node.keyName}${node.keyValue}` : node.name
      if (items[key]) {
        items[key].count += node.value
      } else if (node.name) {
        items[key] = {
          title: node.name,
          color: node.hex,
          count: node.value,
          keyName: node.keyName,
          keyValue: node.keyValue,
          depth: depth,
        }
      }

      if (node.children) {
        node.children.forEach(child => getLegend(child, items, depth + 1))
      }

      return items
    }

    const uniqueItems = _.sortBy(
      Object.values(
        getLegend({ children: this.props.formattedData.children }, {}, 0)
      ), //Skip the first root node
      item => item.depth
    )

    const legendItems = uniqueItems.map((item, index) => {
      const previousVal = index !== 0 ? uniqueItems[index - 1] : {}
      const hasNewDepth = index !== 0 && previousVal.depth !== item.depth
      const { isSunburstViewMulti } = this.props

      const wrapperStyle = {
        display: 'block',
      }

      if (hasNewDepth) {
        wrapperStyle.borderTop = '1px solid #ccc'
        wrapperStyle.paddingTop = '10px'
        wrapperStyle.marginTop = '5px'
      }

      if (this.props.legendFilters.length > 0) {
        const matchesFilter = this.props.legendFilters.find(
          filter =>
            item.keyName === filter.keyName && item.keyValue === filter.keyValue
        )

        if (matchesFilter) {
          wrapperStyle.color = '#222'
        } else {
          wrapperStyle.color = '#aaa'
        }
      }

      const key = item.keyName ? `${item.keyName}${item.keyValue}` : item.title

      const styledTitle = (
        <BorderlessButton
          key={key}
          style={wrapperStyle}
          onClick={() => this.onClickLegend(item)}
        >
          <ChartLegendEntry
            title={`${item.title} (${item.count})`}
            color={item.color}
          />
        </BorderlessButton>
      )

      return styledTitle
    })

    return legendItems
  }

  render() {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          overflow: 'hidden',
        }}
      >
        {this.props.legendFilters.length === 0 ? (
          <span style={{ fontSize: '0.9em' }}>&nbsp;</span>
        ) : (
          <TextLink
            color="secondary"
            onClick={this.onReset}
            style={{
              fontSize: '0.9em',
              textAlign: 'right',
              marginRight: '1em',
            }}
          >
            Clear Selection
          </TextLink>
        )}
        <div
          style={{
            overflow: 'auto',
            padding: '0.5em',
            marginRight: '1em',
          }}
        >
          {this.getLegendItems()}
        </div>
      </div>
    )
  }
}
SunburstLegend.propTypes = {
  formattedData: PropTypes.object,
  onClickLegend: PropTypes.func,
  legendFilters: PropTypes.array.isRequired,
  isSunburstViewMulti: PropTypes.bool,
}

export default SunburstLegend
