/*
 * AppLayout Messages
 *
 * This contains all the text for the AppLayout component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  solutionCenter: {
    id: 'app.containers.AppLayout.solutionCenter',
    defaultMessage: 'Solution Center',
  },
  graphExplorer: {
    id: 'app.containers.AppLayout.graphExplorer',
    defaultMessage: 'Search',
  },
  explorer: {
    id: 'app.containers.AppLayout.explorer',
    defaultMessage: 'Explorer',
  },
  search: {
    id: 'app.containers.AppLayout.search',
    defaultMessage: 'Search',
  },
  reports: {
    id: 'app.containers.AppLayout.reports',
    defaultMessage: 'Reports',
  },
  swimlaneCheckup: {
    id: 'app.containers.AppLayout.swimlaneCheckup',
    defaultMessage: 'Swimlane Checkup',
  },
  swimlaneCheckupDetails: {
    id: 'app.containers.AppLayout.swimlaneCheckupDetails',
    defaultMessage: 'Swimlane Checkup Details',
  },
  alerts: {
    id: 'app.containers.AppLayout.alerts',
    defaultMessage: 'Alerts',
  },
  setup: {
    id: 'app.containers.AppLayout.setup',
    defaultMessage: 'Setup',
  },
  config: {
    id: 'app.containers.AppLayout.config',
    defaultMessage: 'Config',
  },
  quickSearch: {
    id: 'app.containers.AppLayout.quickSearch',
    defaultMessage: 'Quick Search',
  },
  widgetResults: {
    id: 'app.containers.AppLayout.widgetResults',
    defaultMessage: 'Widget Results',
  },
  appTitle: {
    id: 'app.containers.AppLayout.appTitle',
    defaultMessage: 'Sonrai',
  },
  nodeView: {
    id: 'app.containers.AppLayout.nodeView',
    defaultMessage: 'Details',
  },
  controlCenter: {
    id: 'app.containers.AppLayout.controlCenter',
    defaultMessage: 'Manage Control Frameworks',
  },
  ticketDetails: {
    id: 'app.containers.AppLayout.ticketDetails',
    defaultMessage: 'Ticket Details',
  },
  tickets: {
    id: 'app.containers.AppLayout.tickets',
    defaultMessage: 'Tickets',
  },
  alert: {
    id: 'app.containers.AppLayout.alert',
    defaultMessage: 'Alert',
  },
  policy: {
    id: 'app.containers.AppLayout.policy',
    defaultMessage: 'Policy',
  },
  deploy: {
    id: 'app.containers.AppLayout.deploy',
    defaultMessage: 'Deploy',
  },
  helpCenter: {
    id: 'app.containers.AppLayout.helpCenter',
    defaultMessage: 'Knowledge Base',
  },
  securityCenter: {
    id: 'app.constainers.AppLayout.securityCenter',
    defaultMessage: 'Security Center',
  },
  escalation: {
    id: 'app.constainers.AppLayout.escalation',
    defaultMessage: 'Escalations',
  },
  botManagement: {
    id: 'app.constainers.AppLayout.botManagement',
    defaultMessage: 'Bot Management',
  },
  monitoredResources: {
    id: 'app.constainers.AppLayout.monitoredResources',
    defaultMessage: 'Monitored Resources',
  },
  botDetails: {
    id: 'app.constainers.AppLayout.botDetails',
    defaultMessage: 'Bot Details',
  },
  roleManagement: {
    id: 'app.constainers.AppLayout.roleManagement',
    defaultMessage: 'Role Management',
  },
  roleDetails: {
    id: 'app.constainers.AppLayout.roleDetails',
    defaultMessage: 'Role Details',
  },
  userManagement: {
    id: 'app.constainers.AppLayout.userManagement',
    defaultMessage: 'User Management',
  },
  userDetails: {
    id: 'app.constainers.AppLayout.userDetails',
    defaultMessage: 'User Details',
  },
  swimlanes: {
    id: 'app.constainers.AppLayout.swimlanes',
    defaultMessage: 'Swimlane Management',
  },
  platformSettings: {
    id: 'app.constainers.AppLayout.platformSettings',
    defaultMessage: 'Settings',
  },
  opsCenter: {
    id: 'app.constainers.AppLayout.opsCenter',
    defaultMessage: 'Ops Center',
  },
  objectivesManager: {
    id: 'app.constainers.AppLayout.objectivesManager',
    defaultMessage: 'Objectives',
  },
})
