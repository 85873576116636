import React from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import ActiveToolbar from './ActiveToolbar'
import InactiveToolbar from './InactiveToolbar'

export const BulkActionToolbar = props => {
  const isActive = props.selectedItems.length > 0

  if (isActive) {
    return <ActiveToolbar {...props} />
  } else {
    return <InactiveToolbar {...props} />
  }
}

BulkActionToolbar.defaultProps = {
  style: {},
  selectedItems: [],
  totalItems: [],
  showCheckbox: true,
}

BulkActionToolbar.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  theme: themeShape,
  working: PropTypes.bool,
  selectedItems: PropTypes.array,
  totalItems: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  toggleSelectAll: PropTypes.func,
  showCheckbox: PropTypes.bool,
}
export default themeable(BulkActionToolbar)
export { default as ToolbarItem } from './ToolbarItem'
export { default as ToolbarDivider } from './ToolbarDivider'
