import React from 'react'
import styled from 'styled-components'

import { Input } from 'reactstrap'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import messages from '../messages'
import SubmitFormBaseComponent, {
  RequiredSpan,
  DefaultFieldControlContainer,
} from './SubmitFormBaseComponent'
import {
  AUTH_MODE_USERNAME_KV,
  AUTH_MODE_USERNAME_SECRETMGR,
} from '../constants'

const FormFieldsContainer = styled.div`
  display: grid;
  grid-row-gap: 8px;
  grid-template-columns: 200px auto;
`

const Label = styled.label`
  padding-top: 8px;
  padding-right: 8px;
`

const authModeMessages = {
  [AUTH_MODE_USERNAME_KV]: {
    name: messages.submitFormAuthenticationKVName,
    path: messages.submitFormAuthenticationKVPath,
    nameInstructions: messages.submitFormAuthenticationKVNameInstructions,
    pathInstructions: messages.submitFormAuthenticationKVPathInstructions,
  },
  [AUTH_MODE_USERNAME_SECRETMGR]: {
    name: messages.submitFormAuthenticationSecretName,
    path: messages.submitFormAuthenticationSecretPath,
    nameInstructions:
      messages.submitFormAuthenticationSecretsManagerArnInstructions,
    pathInstructions:
      messages.submitFormAuthenticationSecretsManagerPathInstructions,
  },
}

export default class SubmitFormAuthenticationSection extends SubmitFormBaseComponent {
  renderTitleText = () => {
    return (
      <DynamicFormattedMessage {...messages.submitFormAuthenticationLabel} />
    )
  }

  renderHelpContent = () => {
    return (
      <div>
        <DynamicFormattedMessage
          {...messages.submitFormAuthenticationInstructions}
        />
        <ul
          style={{
            listStyleType: 'disc',
            margin: '20px',
            marginTop: '12px',
            padding: 'none',
          }}
        >
          <li>
            <DynamicFormattedMessage
              {...messages.submitFormAuthenticationUsernameInstructions}
            />
          </li>
          <li>
            <DynamicFormattedMessage
              {...authModeMessages[this.props.authenticationMode]
                .nameInstructions}
            />
          </li>
          <li>
            <DynamicFormattedMessage
              {...authModeMessages[this.props.authenticationMode]
                .pathInstructions}
            />
          </li>
        </ul>
      </div>
    )
  }

  changeUsername = event => {
    const { value } = event.target
    this.props.setFormFields({
      [this.props.fieldKey]: {
        ...this.props.formValues[this.props.fieldKey],
        username: value,
      },
    })
  }

  changeKVName = event => {
    const { value } = event.target
    this.props.setFormFields({
      [this.props.fieldKey]: {
        ...this.props.formValues[this.props.fieldKey],
        kvName: value,
      },
    })
  }

  changeKVPath = event => {
    const { value } = event.target
    this.props.setFormFields({
      [this.props.fieldKey]: {
        ...this.props.formValues[this.props.fieldKey],
        kvPath: value,
      },
    })
  }

  validateUsername = ({ target: { value } }) => {
    if (!value) {
      this.props.setFormErrors({
        [this.props.fieldKey]: 'username is required',
      })
    }
  }

  validateKVName = ({ target: { value } }) => {
    if (!value) {
      this.props.setFormErrors({
        [this.props.fieldKey]: 'name is required',
      })
    }
  }

  validateKVPath = ({ target: { value } }) => {
    if (!value) {
      this.props.setFormErrors({
        [this.props.fieldKey]: 'path is required',
      })
    }
  }

  renderFieldControls = () => {
    return (
      <DefaultFieldControlContainer>
        <FormFieldsContainer>
          <Label>
            <DynamicFormattedMessage
              {...messages.submitFormAuthenticationUsername}
            />
            <RequiredSpan />
          </Label>
          <Input
            onChange={this.changeUsername}
            onBlur={this.validateUsername}
            value={this.props.formValues[this.props.fieldKey].username}
          />
          <Label>
            <DynamicFormattedMessage
              {...authModeMessages[this.props.authenticationMode].name}
            />
            <RequiredSpan />
          </Label>
          <Input
            onChange={this.changeKVName}
            onBlur={this.validateKVName}
            value={this.props.formValues[this.props.fieldKey].kvName}
          />
          <Label>
            <DynamicFormattedMessage
              {...authModeMessages[this.props.authenticationMode].path}
            />
            <RequiredSpan />
          </Label>
          <Input
            onChange={this.changeKVPath}
            onBlur={this.validateKVPath}
            value={this.props.formValues[this.props.fieldKey].kvPath}
          />
        </FormFieldsContainer>
      </DefaultFieldControlContainer>
    )
  }
}
