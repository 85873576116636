import React from 'react'
import PropTypes from 'prop-types'
import colorLib from 'color'

export const ChartTooltip = ({
  title,
  color,
  label,
  value,
  onHover,
  onEndHover,
  colorShadow,
}) => {
  const styles = {
    container: {
      border: '1px solid #eee',
      borderRadius: '4px',
      backgroundColor: '#fff',
      fontSize: '0.8em',
    },
    header: {
      backgroundColor: '#fafafa',
      borderBottom: '1px solid #eee',
      padding: '0.5em',
    },
    body: {
      padding: '0.5em 1em',
    },
    color: {
      display: 'inline-block',
      borderRadius: '50%',
      backgroundColor: color,
      height: '1em',
      width: '1em',
      marginRight: '1em',
    },
    value: {
      fontWeight: 'bold',
      marginLeft: '0.5em',
    },
  }

  if (!color || colorLib(color).hex() === '#FFFFFF') {
    styles.color.border = '1px solid #eee'
  }

  if (colorShadow) {
    styles.container.boxShadow = `${colorLib(color)
      .alpha(0.12)
      .rgb()
      .string()} 0px 1px 3px, ${colorLib(color)
      .alpha(0.24)
      .rgb()
      .string()} 0px 1px 4px`
  }

  return (
    <div
      style={styles.container}
      onMouseEnter={onHover}
      onMouseLeave={onEndHover}
    >
      <div style={styles.header}>{title}</div>
      <div style={styles.body}>
        <span style={styles.color} />
        {label}: <span style={styles.value}>{value}</span>
      </div>
    </div>
  )
}

ChartTooltip.propTypes = {
  color: PropTypes.string,
  title: PropTypes.node,
  label: PropTypes.node,
  value: PropTypes.node,
  onEndHover: PropTypes.func,
  onHover: PropTypes.func,
  colorShadow: PropTypes.bool,
}

export default ChartTooltip
