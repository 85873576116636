import { put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import {
  DATA_CONTAINER_NODE_VIEW_QUERY,
  DATA_CONTAINER_PERFORMED_ON_ACTIONS_QUERY,
} from 'static-queries'
import { GET_DATA_CONTAINER_CONTENT, GET_ACTIONS_PERFORMED } from './constants'
import { setDataContainerContent, setActionsPerformed } from './actions'

function* getActions(action) {
  const { srn, range } = action.payload

  try {
    const client = getClient()
    const results = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        ${DATA_CONTAINER_PERFORMED_ON_ACTIONS_QUERY}
      `,
      variables: {
        srn,
        range,
      },
    })

    const actionsPerformed = results.data.Actions.items
    if (actionsPerformed === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setActionsPerformed(actionsPerformed))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching data container actions', e)
  }
}

function* getDataContainerContent(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${DATA_CONTAINER_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })
    const content = results.data.DataContainers.items
    if (content === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setDataContainerContent(_.get(content, 0)))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching data container node view content', e)
  }
}

function* dataContainerNodeSaga() {
  yield takeLatest(GET_ACTIONS_PERFORMED, getActions)
  yield takeLatest(GET_DATA_CONTAINER_CONTENT, getDataContainerContent)
}

export default dataContainerNodeSaga
