export const ASSIGN_TO_ME = `app/containers/TicketDetails/ASSIGN_TO_ME`
export const ASSIGN_TO_ME_SUCCESS = `app/containers/TicketDetails/ASSIGN_TO_ME_SUCCESS`
export const ASSIGN_TO_ME_ERROR = `app/containers/TicketDetails/ASSIGN_TO_ME_ERROR`

export const FETCH_TICKET_DETAILS = `app/containers/TicketDetails/FETCH_TICKET_DETAILS`
export const FETCH_TICKET_DETAILS_SUCCESS = `app/containers/TicketDetails/FETCH_TICKET_DETAILS_SUCCESS`
export const FETCH_TICKET_DETAILS_ERROR = `app/containers/TicketDetails/FETCH_TICKET_DETAILS_ERROR`

export const FETCH_POLICY_PATH = `app/containers/TicketDetails/FETCH_POLICY_PATH`
export const FETCH_POLICY_PATH_SUCCESS = `app/containers/TicketDetails/FETCH_POLICY_PATH_SUCCESS`
export const FETCH_POLICY_PATH_ERROR = `app/containers/TicketDetails/FETCH_POLICY_PATH_ERROR`

export const CLOSE_TICKET = `app/containers/TicketDetails/CLOSE_TICKET`
export const CLOSE_TICKET_SUCCESS = `app/containers/TicketDetails/CLOSE_TICKET_SUCCESS`
export const CLOSE_TICKET_ERROR = `app/containers/TicketDetails/CLOSE_TICKET_ERROR`

export const REOPEN_TICKET = `app/containers/TicketDetails/REOPEN_TICKET`
export const REOPEN_TICKET_SUCCESS = `app/containers/TicketDetails/REOPEN_TICKET_SUCCESS`
export const REOPEN_TICKET_ERROR = `app/containers/TicketDetails/REOPEN_TICKET_ERROR`

export const SNOOZE_TICKET = `app/containers/TicketDetails/SNOOZE_TICKET`
export const SNOOZE_TICKET_SUCCESS = `app/containers/TicketDetails/SNOOZE_TICKET_SUCCESS`
export const SNOOZE_TICKET_ERROR = `app/containers/TicketDetails/SNOOZE_TICKET_ERROR`

export const ACCEPT_RISK_TICKET = `app/containers/TicketDetails/ACCEPT_RISK_TICKET`
export const ACCEPT_RISK_TICKET_SUCCESS = `app/containers/TicketDetails/ACCEPT_RISK_TICKET_SUCCESS`
export const ACCEPT_RISK_TICKET_ERROR = `app/containers/TicketDetails/ACCEPT_RISK_TICKET_ERROR`

export const REASSIGN_TO_USER = `app/containers/TicketDetails/REASSIGN_TO_USER`
export const REASSIGN_TO_USER_SUCCESS = `app/containers/TicketDetails/REASSIGN_TO_USER_SUCCESS`
export const REASSIGN_TO_USER_ERROR = `app/containers/TicketDetails/REASSIGN_TO_USER_ERROR`

export const FETCH_TICKET_COMMENTS =
  'app/containers/TicketDetails/FETCH_TICKET_COMMENTS'
export const FETCH_TICKET_COMMENTS_SUCCESS =
  'app/containers/TicketDetails/FETCH_TICKET_COMMENTS_SUCCESS'
export const FETCH_TICKET_COMMENTS_ERROR =
  'app/containers/TicketDetails/FETCH_TICKET_COMMENTS_ERROR'

export const ADD_COMMENT = 'app/containers/TicketDetails/ADD_COMMENT'
export const ADD_COMMENT_SUCCESS =
  'app/containers/TicketDetails/ADD_COMMENT_SUCCESS'
export const ADD_COMMENT_ERROR =
  'app/containers/TicketDetails/ADD_COMMENT_ERROR'

export const FETCH_SIMILAR_TICKETS = `app/containers/TicketDetails/FETCH_SIMILAR_TICKETS`
export const FETCH_SIMILAR_TICKETS_ERROR = `app/containers/TicketDetails/FETCH_SIMILAR_TICKETS_ERROR`
export const FETCH_SIMILAR_TICKETS_SUCCESS = `app/containers/TicketDetails/FETCH_SIMILAR_TICKETS_SUCCESS`
export const CHANGE_SIMILAR_TICKETS_FILTERS = `app/containers/TicketDetails/CHANGE_SIMILAR_TICKETS_FILTERS`

export const FETCH_TICKET_HISTORY =
  'app/containers/TicketDetails/FETCH_TICKET_HISTORY'
export const FETCH_TICKET_HISTORY_SUCCESS =
  'app/containers/TicketDetails/FETCH_TICKET_HISTORY_SUCCESS'
export const FETCH_TICKET_HISTORY_ERROR =
  'app/containers/TicketDetails/FETCH_TICKET_HISTORY_ERROR'

export const FETCH_ACTIVITY_TICKET_ACTIONS = `app/containers/TicketDetails/FETCH_ACTIVITY_TICKET_ACTIONS`
export const FETCH_ACTIVITY_TICKET_ACTIONS_SUCCESS = `app/containers/TicketDetails/FETCH_ACTIVITY_TICKET_ACTIONS_SUCCESS`
export const FETCH_ACTIVITY_TICKET_ACTIONS_ERROR = `app/containers/TicketDetails/FETCH_ACTIVITY_TICKET_ACTIONS_ERROR`

export const FETCH_POLICY_EVIDENCE_LEGACY = `app/containers/TicketDetail/FETCH_POLICY_EVIDENCE_LEGACY`
export const FETCH_POLICY_EVIDENCE_LEGACY_SUCCESS = `app/containers/TicketDetail/FETCH_POLICY_EVIDENCE_LEGACY_SUCCESS`
export const FETCH_POLICY_EVIDENCE_LEGACY_ERROR = `app/containers/TicketDetail/FETCH_POLICY_EVIDENCE_LEGACY_ERROR`

export const TICKET_STATUS_CHANGE_SUCCESS =
  'app/constainers/TicketDetailsData/TICKET_STATUS_CHANGE_SUCCESS'

export const CLOSE_TICKETS = 'app/containers/TicketDetailsData/CLOSE_TICKETS'
export const CLOSE_TICKETS_SUCCESS =
  'app/containers/TicketDetailsData/CLOSE_TICKETS_SUCCESS'

export const BULK_ACTION_START =
  'app/containers/TicketDetailsData/BULK_ACTION_START'
export const BULK_ACTION_COMPLETE =
  'app/containers/TicketDetailsData/BULK_ACTION_COMPLETE'

export const ASSIGN_TICKETS = 'app/containers/TicketDetailsData/ASSIGN_TICKETS'
export const ACCEPT_RISK_TICKETS =
  'app/containers/TicketDetailsData/ACCEPT_RISK_TICKETS'
export const SNOOZE_TICKETS = 'app/containers/TicketDetailsData/SNOOZE_TICKETS'
export const BULK_COMMENT = 'app/containers/TicketDetailsData/BULK_COMMENT'
export const BULK_TAG = 'app/containers/TicketDetailsData/BULK_TAG'
