import React from 'react'
import styled from 'styled-components'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'

import messages from '../messages'

const ContentContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  text-align: center;
  padding-top: 90px;
`

const MessageContainer = styled.h3`
  margin-top: 10px;
  margin-bottom: 10px;
`

export default function TicketNotFound() {
  return (
    <ContentContainer>
      <MessageContainer>
        <DynamicFormattedMessage {...messages.notFoundMessage} />
      </MessageContainer>
    </ContentContainer>
  )
}
