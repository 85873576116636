import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectDataNodeViewCardLayoutDomain = state =>
  state.get('dataNodeViewCardLayout', Map())

export const selectDataContent = createSelector(
  selectDataNodeViewCardLayoutDomain,
  state => state.get('dataContent') || Map()
)

export const selectIsLoading = createSelector(
  selectDataNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
