import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import _ from 'lodash'
import gql from 'graphql-tag'
import { parse, print } from 'graphql'
import { fromJS } from 'immutable'
import { RESOURCE_GROUP_FOR_NODE } from 'static-queries'

import { getClient } from 'apolloClient'
import { DATA_TYPES_FOR_CRM, TYPES_WITH_CRM } from 'appConstants'

import {
  dudeWheresMyType,
  getNodeInfoQuery,
  getTypeFromSrn,
} from 'utils/graphDataUtils'
import { isMonitored, formatChangeDetectionProperties } from 'utils/sonraiUtils'

import {
  ADD_CHANGE_DETECTION_PROPERTIES,
  ADD_KEYVAULT_CREDS,
  ADD_RESOURCE_TO_SWIMLANE,
  ADD_TAG,
  GET_NODE_DATA,
  GET_KEYVAULT_DATA,
  GET_RESOURCE_GROUP,
  REMOVE_CHANGE_DETECTION_PROPERTIES,
  TOGGLE_RESOURCE_MONITORING,
  REMOVE_TAG,
  REMOVE_KEYVAULT_CREDS,
  UPDATE_CHANGE_DETECTION_PROPERTY,
  UPDATE_FRIENDLY_NAME,
  UPDATE_IMPORTANCE,
  UPDATE_KEYVAULT_CREDS,
} from './constants'

import {
  addChangeDetectionPropertiesSuccess as changeManagerAddChangeDetectionPropertiesSuccess,
  removeChangeDetectionPropertiesSuccess as changeManagerRemoveChangeDetectionPropertiesSuccess,
  updateChangeDetectionPropertySuccess as changeManagerUpdateChangeDetectionPropertySuccess,
} from 'containers/ChangeDetectionManager/actions'
import { setResourceMonitoring as nodeSolutionCenterSetResourceMonitor } from 'containers/NodeSolutionCenter/actions'
import { updateRapsheetResourceMonitor } from 'containers/RapSheet/actions'
import { loadTag as sonraiDataLoadTag } from 'containers/SonraiData/actions'
import {
  addChangeDetectionPropertiesSuccess,
  addKeyVaultCredsSuccess,
  addResourceToSwimlaneSuccess,
  addTagSuccess,
  setCrmData,
  errCrmData,
  setNodeData,
  errNodeData,
  setKeyVaultData,
  errKeyVaultData,
  setResourceGroup,
  errResourceGroup,
  setSwimlanes,
  errSwimlanes,
  removeChangeDetectionPropertiesSuccess,
  removeKeyVaultCredsSuccess,
  removeTagSuccess,
  toggleResourceMonitoringSuccess,
  updateChangeDetectionPropertySucces,
  updateFriendlyNameSuccess,
  updateImportanceSuccess,
  updateKeyVaultCredsSuccess,
} from './actions'

import { addProperty } from 'containers/ChangeDetectionManager/sagas'
import { removeProperty } from '../ChangeDetectionManager/sagas'

import { selectTags } from 'containers/SonraiData/selectors'

// these are selectors to pull the respective fields from the state
export const getQueryNames = state => state.getIn(['sonraiData', 'queryNames'])
export const getQueryTypes = state => state.getIn(['sonraiData', 'queryTypes'])

/**
 * Add CD some properties to the thing in the header
 */
function* addChangeDetectionProperties(action) {
  const { srn, properties, onNodeView } = action.payload
  try {
    // be optimistic
    const actions = [
      put(addChangeDetectionPropertiesSuccess({ srn, properties })),
    ]
    if (onNodeView) {
      actions.push(
        put(changeManagerAddChangeDetectionPropertiesSuccess(properties))
      )
    }
    yield all(actions)

    for (let property of properties.toJS()) {
      yield call(addProperty, property, srn)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error adding change detection properties', e)
  }
}

function* addKeyVaultCreds(action) {
  const { srn } = action.payload
  try {
    const client = yield getClient()
    const results = yield client.mutate({
      mutation: gql`
        mutation addKeyVaultCreds($config: CollectorConfigurationInput!) {
          CreateCollectorConfiguration(input: $config) {
            id
            srn
            targetSrn
            enabled
            type
            blob
          }
        }
      `,
      variables: {
        config: {
          targetSrn: srn,
          enabled: true,
          type: 'AzureTokenCredentials',
          blob: JSON.stringify({
            location: 'keyvault',
            containerName: action.payload.name,
            containerPrefix: action.payload.path,
          }),
        },
      },
    })

    const path = ['data', 'CreateCollectorConfiguration']
    const newCreds = _.get(results, path)
    if (!newCreds) {
      throw `result did not return ${path.join('.')}`
    }

    const actions = [put(addKeyVaultCredsSuccess({ srn, newCreds }))]
    yield all(actions)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened adding the KeyVault creds', e)
    yield put(errKeyVaultData({ srn }))
  }
}

function* removeKeyVaultCreds(action) {
  try {
    const { srn, id } = action.payload
    const client = yield getClient()
    const results = yield client.mutate({
      variables: { id },
      mutation: gql`
        mutation deleteKeyVault($id: String!) {
          DeleteCollectorConfiguration(id: $id)
        }
      `,
    })

    const path = ['data', 'DeleteCollectorConfiguration']
    if (!_.get(results, path)) {
      throw `result did not return ${path.join}`
    }
    const actions = [put(removeKeyVaultCredsSuccess({ srn, id }))]
    yield all(actions)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error removing adding the KeyVault creds', e)
  }
}

function* updateKeyVaultCreds(action) {
  try {
    const { srn, id } = action.payload
    const client = yield getClient()
    const results = yield client.mutate({
      mutation: gql`
        mutation updateCollectorConfiguration(
          $config: CollectorConfigurationInput!
          $id: String!
        ) {
          UpdateCollectorConfiguration(id: $id, input: $config) {
            id
            srn
            targetSrn
            enabled
            type
            blob
          }
        }
      `,
      variables: {
        config: {
          targetSrn: srn,
          enabled: true,
          type: 'AzureTokenCredentials',
          blob: JSON.stringify({
            location: 'keyvault',
            containerName: action.payload.containerName,
            containerPrefix: action.payload.containerPrefix,
          }),
        },
        id: id,
      },
    })

    const path = ['data', 'UpdateCollectorConfiguration']
    const updateCreds = _.get(results, path)
    // nodeSolutionCenterUpdateAzureKeyVaultCredsSuccess
    if (!updateCreds) {
      throw `result did not return ${path.join('.')}`
    }

    const actions = [put(updateKeyVaultCredsSuccess({ srn, id, updateCreds }))]
    yield all(actions)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened updating KeyVault creds', e)
  }
}

function* addResourceToSwimlane(action) {
  const client = yield getClient()
  try {
    const results = yield client.mutate({
      mutation: gql`
        mutation addResourceToSwimlane($srn: ID!, $resourceId: String!) {
          UpdateSwimlane(
            srn: $srn
            value: { resourceIds: { add: [$resourceId] } }
          ) {
            srn
            title
          }
        }
      `,
      variables: {
        resourceId: action.payload.resourceId,
        srn: action.payload.swimlaneSrn,
      },
    })

    if (results) {
      yield put(
        addResourceToSwimlaneSuccess({
          srn: action.payload.srn,
          swimlane: results.data.UpdateSwimlane,
        })
      )
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('An error happened adding resource to swimlane', e)
  }
}

function* addTag(action) {
  const {
    tag: { key, value },
    srn,
  } = action.payload
  try {
    const client = yield getClient()
    const response = yield client.mutate({
      variables: { key, value, srn },
      mutation: gql`
        mutation addTagsWithNoDuplicates(
          $key: String
          $value: String
          $srn: ID
        ) {
          AddTag(
            value: { key: $key, value: $value, tagsEntity: { add: [$srn] } }
          ) {
            srn
            key
            value
          }
        }
      `,
    })
    const newTag = _.get(response, ['data', 'AddTag'])
    if (!newTag) {
      throw 'the response did not return a new tag'
    }

    const actions = [put(addTagSuccess({ srn, newTag }))]

    // if we created a new tag, save it for dropdown population
    const existingTags = yield select(selectTags)
    const existedBefore = existingTags.find(t => t.get('key') === newTag.key)
    if (!existedBefore) {
      actions.push(put(sonraiDataLoadTag(fromJS(newTag))))
    }

    yield all(actions)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened in nodeViewHeader adding tag', e)
  }
}

function* removeTag(action) {
  try {
    const client = yield getClient()
    const { srn, nodeId } = action.payload
    const response = yield client.mutate({
      mutation: gql`
        mutation removeTag($resourceSrn: ID!, $value: ResourceUpdater!) {
          UpdateResource(srn: $resourceSrn, value: $value) {
            resourceId
          }
        }
      `,
      variables: {
        resourceSrn: nodeId,
        value: {
          hasTag: {
            remove: [srn],
          },
        },
      },
    })
    const { data } = response
    if (!data) {
      throw 'the response did not return data'
    }
    yield put(
      removeTagSuccess({
        srn: nodeId,
        tagSrn: srn,
      })
    )
  } catch (e) {
    // eslint-disable-next-line
    console.error('Error removing tag', e)
  }
}

/**
 * Remove some properties from the thing in the header
 */
function* removeChangeDetectionProperties(action) {
  const { srn, properties, onNodeView } = action.payload
  try {
    const actions = [
      put(removeChangeDetectionPropertiesSuccess({ srn, properties })),
    ]
    if (onNodeView) {
      actions.push(
        put(changeManagerRemoveChangeDetectionPropertiesSuccess(properties))
      )
    }
    yield all(actions)

    for (let property of properties.toJS()) {
      yield call(removeProperty, property, srn)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error removing change detection properties', e)
  }
}

function* updateChangeDetectionProperty(action) {
  const { srn, toRemove, newProperty, onNodeView, index } = action.payload
  try {
    // do API calls
    if (toRemove) {
      yield call(removeProperty, toRemove, srn)
    }
    yield call(addProperty, newProperty, srn)

    // put changes
    const actions = [
      put(updateChangeDetectionPropertySucces({ srn, property: newProperty })),
    ]
    if (onNodeView) {
      actions.push(
        changeManagerUpdateChangeDetectionPropertySuccess({
          newProperty,
          index,
        })
      )
    }
    yield all(actions)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating CD Property in header', e)
  }
}

/**
 * modifies the passed query to get the CDConfigs field in the query operation
 */
const addFetchChangeDetectionPropertiesFields = query => {
  const cdQuery = gql`
    query {
      CDConfigs(
        where: { resourceSrn: { value: $srn }, active: { value: true } }
      ) {
        items {
          keyType
          keyName
          alertLevel
          actionClassification
        }
      }
    }
  `
  const querySelections = query.definitions[0].selectionSet.selections
  const cdSelections = cdQuery.definitions[0].selectionSet.selections
  cdSelections.forEach(selection => querySelections.push(selection))
}

/**
 * modifies the passed query (parsed document) to include the
 * getChangeDetection field in the query operation
 */
export const addFetchChangeDetectionOptionsFields = query => {
  const cdQuery = gql`
    query getOptions($type: ResourceType) {
      getChangeDetectionOptions(resourceType: $type) {
        keyType
        keyName
        actionClassification
      }
    }
  `
  const queryOpDef = query.definitions[0]
  const cdOpDef = cdQuery.definitions[0]

  const queryVarDefs = queryOpDef.variableDefinitions
  const cdVarDefs = cdOpDef.variableDefinitions
  cdVarDefs.forEach(varDef => queryVarDefs.push(varDef))

  const querySelections = queryOpDef.selectionSet.selections
  const cdSelections = cdOpDef.selectionSet.selections
  cdSelections.forEach(selection => querySelections.push(selection))
}

/**
 * Add fields to query for the swimlane for the resource
 */
const SWIMLANE_ALIAS = 'resource_swimlane'
export const addSwimlaneOptionsFields = query => {
  const swQuery = gql`
    query resource_swimlane($srn: String) {
      ${SWIMLANE_ALIAS}: Resources(where: { srn: { value: $srn } }) {
        items(limit: 1) {
          swimlane {
            items {
              description
              label
              title
              srn
              defaultImportance
              createdBy
              sid
              lastModified
              createdDate
              name
            }
          }
        }
      }
    }
  `

  const querySelections = query.definitions[0].selectionSet.selections
  const swimlaneSelections = swQuery.definitions[0].selectionSet.selections
  swimlaneSelections.forEach(selection => querySelections.push(selection))
}

/**
 * Fetches node data and also the CRM data
 */
function* getNodeData(action) {
  const { srn } = action.payload
  let cdConfigs
  let cdOptions
  let swimlanes
  let item

  // this part just fetches everything, but only puts actions for fetching node data
  try {
    const queryNames = yield select(getQueryNames)
    const queryTypes = yield select(getQueryTypes)

    // in this saga we get the prebuild node info query, and then add other stuff to it
    // so we make fewer requests to graphql server to get all the data
    //
    // we do parse(print()) b/c if we don't and this saga runs twice we get the original
    // unmodified query passed into client.query({}) below don't know why
    const query = parse(print(getNodeInfoQuery(srn, queryNames, queryTypes)))
    addFetchChangeDetectionPropertiesFields(query)
    addFetchChangeDetectionOptionsFields(query)
    addSwimlaneOptionsFields(query, queryTypes)
    const type = DATA_TYPES_FOR_CRM.includes(getTypeFromSrn(srn).toLowerCase())
      ? 'Data'
      : 'Identity'

    const client = yield getClient()
    const result = yield client.query({
      variables: { srn, type },
      query: gql`
        ${print(query)}
      `,
    })

    const { data } = result
    if (!data || data == null) {
      throw 'the result had no data'
    }

    const { items } = Object.values(data)[0]
    if (!items || items == null) {
      throw 'the result had no items'
    }

    if (items.length == 0) {
      throw 'the result had no items'
    }

    item = items[0]
    yield put(setNodeData({ srn, data: item }))

    cdConfigs = _.get(data, ['CDConfigs', 'items'])
    cdOptions = _.get(data, ['getChangeDetectionOptions'])
    swimlanes = _.get(data, [SWIMLANE_ALIAS, 'items', 0, 'swimlane', 'items'])
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened fetching nodeViewHeader nodeData ', e)
    yield put(errNodeData({ srn }))
  }

  // this part uses the response from the first part to formulate the CRM data
  try {
    if (!cdConfigs || cdConfigs == null) {
      throw 'the result had no cdConfigs'
    }

    if (!cdOptions || cdOptions == null) {
      throw 'the result had no cdOptions'
    }

    const cdProperties = formatChangeDetectionProperties(cdConfigs)

    cdOptions.forEach(option => {
      if (
        option.keyType !== 'PATH' ||
        option.keyName === 'activeFrom' ||
        option.keyName === 'accessedUsing' ||
        option.keyName === 'accessedFrom'
      ) {
        option.actionClassification = []
      }
    })
    // Get's rid of the Activity action classification as this is not a valid action classification type (should do this server side)
    cdOptions.forEach(option => {
      if (!_.isEmpty(option.actionClassification)) {
        option.actionClassification = [...option.actionClassification].filter(
          x => x !== 'Activity'
        )
      }
    })

    const type = getTypeFromSrn(srn).toLowerCase()
    const monitoredField = TYPES_WITH_CRM.includes(type)
      ? item.sonraiConfig
      : undefined

    yield put(
      setCrmData({
        srn,
        monitored: isMonitored(monitoredField),
        importance: item.importance,
        changeDetectionProperties: cdProperties,
        changeDetectionOptions: cdOptions,
      })
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened fetching nodeViewHeader CRM data', e)
    yield put(errCrmData({ srn }))
  }

  // this part uses the response from the first part to formulate the swimlane data
  try {
    if (!swimlanes) {
      const path = [SWIMLANE_ALIAS, 'items', 0, 'swimlane', 'items']
      throw `response did not have results ${path.join('.')}`
    }

    yield put(setSwimlanes({ srn, swimlanes }))
  } catch (e) {
    // eslint-disable-next-line no-console
    yield put(errSwimlanes({ srn }))
  }
}

function* getKeyVaultData(action) {
  const { srn } = action.payload
  try {
    const client = yield getClient()
    const results = yield client.query({
      variables: { srn },
      query: gql`
        query getKeyVaultData($srn: String) {
          CollectorConfigurations(where: { targetSrn: $srn }) {
            items {
              id
              srn
              targetSrn
              enabled
              type
              blob
            }
          }
        }
      `,
    })

    const path = ['data', 'CollectorConfigurations', 'items']
    const data = _.get(results, path)
    if (!data) {
      throw `the response did not return ${path.join('.')}`
    }
    const actions = [put(setKeyVaultData({ srn, data }))]
    yield all(actions)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened fetching nodeViewHeader KeyVault data', e)
    yield put(errKeyVaultData({ srn }))
  }
}

function* getResourceGroup(action) {
  const { srn } = action.payload
  try {
    const client = yield getClient()
    const results = yield client.query({
      variables: { srn },
      query: gql`
        ${RESOURCE_GROUP_FOR_NODE}
      `,
    })

    const path = ['data', 'Resources', 'items']
    if (_.get(results, path, false)) {
      const resourceGroup = dudeWheresMyType(
        results.data.Resources,
        'isIn',
        'ResourceGroup'
      )
      const storageAccount = dudeWheresMyType(
        results.data.Resources,
        'isIn',
        'DataStore'
      )

      yield put(
        setResourceGroup({
          srn,
          group: resourceGroup,
          storageAccount: storageAccount,
        })
      )
    } else {
      throw `the result did not return ${path.join('.')}`
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error happened nodeViewHeader fetching resource groups', e)
    yield put(errResourceGroup({ srn }))
  }
}

/**
 * multiple places read the isMonitored state, change them all for good measure
 */
const allMonitorChangeActions = ({ change, srn, onNodeView }) => {
  const actions = [
    toggleResourceMonitoringSuccess({ srn, isMonitored: change }),
    updateRapsheetResourceMonitor({ srn, isMonitored: change }),
  ]

  if (onNodeView) {
    actions.push(nodeSolutionCenterSetResourceMonitor(change))
  }
  return actions.map(action => put(action))
}

/**
 * Toggle monitoring for the resource
 */
function* toggleResourceMonitoring(action) {
  try {
    const { isMonitored, onNodeView, srn } = action.payload
    const change = !isMonitored

    //optimiticly update change in many places that may want the change
    yield all(allMonitorChangeActions({ srn, change, onNodeView }))

    const client = yield getClient()
    const response = yield client.mutate({
      variables: { srn, change },
      mutation: gql`
        mutation toggleResourceMonitoring($srn: String!, $change: Boolean!) {
          setMonitor(monitorStatusBySrn: [{ srn: $srn, monitor: $change }]) {
            srn
            monitor
          }
        }
      `,
    })

    const update = _.get(response, ['data', 'setMonitor', 0, 'monitor'])
    if (!update && false !== update) {
      throw 'The response did not return data.setMonitor.0.monitor'
    }

    // compare result of change to our assumption, if they are the same do
    // nothing, if not, correct the data
    if (update !== change) {
      yield all(allMonitorChangeActions({ srn, change: update }))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened toggling resource monitoring: ', e)
  }
}

function* updateFriendlyName(action) {
  try {
    const client = getClient()
    const { srn, value } = action.payload
    const response = yield client.mutate({
      variables: { srn, value },
      mutation: gql`
        mutation update_resource($srn: ID!, $value: String) {
          UpdateResource(srn: $srn, value: { friendlyName: $value }) {
            srn
            friendlyName
          }
        }
      `,
    })
    yield put(
      updateFriendlyNameSuccess({
        srn: srn,
        value: response.data.UpdateResource.friendlyName,
      })
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error saving friendly name', e)
  }
}

/**
 * change the 'importance' value of the resource
 */
function* updateImportance(action) {
  const { srn, importance } = action.payload
  try {
    const client = getClient()
    const result = yield client.mutate({
      variables: { srn, importance },
      mutation: gql`
        mutation setImportance($srn: String!, $importance: Long!) {
          setImportance(srn: $srn, importance: $importance) {
            srn
            importance
          }
        }
      `,
    })

    const path = ['data', 'setImportance', 'importance']
    const updatedImportance = _.get(result, path)
    if (!updatedImportance && !_.isNumber(updatedImportance)) {
      throw `result did not return ${path.join('.')}`
    }
    yield put(updateImportanceSuccess({ srn, importance: updatedImportance }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating resource importance', e)
  }
}

function* nodeViewHeaderSaga() {
  yield all([
    takeLatest(ADD_CHANGE_DETECTION_PROPERTIES, addChangeDetectionProperties),
    takeLatest(ADD_KEYVAULT_CREDS, addKeyVaultCreds),
    takeLatest(ADD_RESOURCE_TO_SWIMLANE, addResourceToSwimlane),
    takeLatest(ADD_TAG, addTag),
    takeLatest(GET_NODE_DATA, getNodeData),
    takeLatest(GET_KEYVAULT_DATA, getKeyVaultData),
    takeLatest(GET_RESOURCE_GROUP, getResourceGroup),
    takeLatest(
      REMOVE_CHANGE_DETECTION_PROPERTIES,
      removeChangeDetectionProperties
    ),
    takeLatest(REMOVE_KEYVAULT_CREDS, removeKeyVaultCreds),
    takeLatest(REMOVE_TAG, removeTag),
    takeLatest(TOGGLE_RESOURCE_MONITORING, toggleResourceMonitoring),
    takeLatest(UPDATE_CHANGE_DETECTION_PROPERTY, updateChangeDetectionProperty),
    takeLatest(UPDATE_FRIENDLY_NAME, updateFriendlyName),
    takeLatest(UPDATE_IMPORTANCE, updateImportance),
    takeLatest(UPDATE_KEYVAULT_CREDS, updateKeyVaultCreds),
  ])
}

export default nodeViewHeaderSaga
