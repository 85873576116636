/**
 *
 * EscalationData
 *
 */

import React from 'react'
import { compose } from 'redux'

import { getDisplayName } from 'utils/hocHelpers'
import escalationDataReducers from './reducers'
import escalationDataSagas from './sagas'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'

const escalationData = WrappedComponent => {
  return class extends React.Component {
    displayName = `escalationData(${getDisplayName(WrappedComponent)})`
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const withReducer = injectReducer({
  key: 'escalationData',
  reducer: escalationDataReducers,
})
const withSaga = injectSaga({
  key: 'escalationData',
  saga: escalationDataSagas,
})

export default compose(withReducer, withSaga, escalationData)
