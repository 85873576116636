/*
 *
 * Explorer reducer
 *
 */

import { fromJS, Map, List } from 'immutable'
import _ from 'lodash'
import { handleActions } from 'redux-actions'
import {
  SET_RELATIONS,
  REMOVE_RELATIONS,
  GET_SOURCE_NODE,
  GET_MULTI_SOURCE_NODES,
  SET_SOURCE_NODES,
  SET_FILTER,
  REMOVE_FILTER,
  SELECT_NODE,
  SET_AVAILABLE_FILTERS,
  CLEAR_GRAPH,
  GET_NODE_PREVIEW,
  SET_NODE_PREVIEW,
  LOAD_ALL_CHILDREN,
  CLEAR_FILTERS,
  REMOVE_NODE,
  SET_NODE_PREVIEW_RELATIONS,
  HANDLE_EXPAND,
  HANDLE_HIDE,
  HANDLE_SHOW,
  LOAD_NODES,
  HANDLE_UPDATE,
} from './constants'
import { EXPLORER_VIEW_TYPES } from 'appConstants'

const initialState = fromJS({
  sourceNodes: [],
  isLoading: false,
  nodes: {},
  selectedNode: null,
  availableFilters: {},
  isLoadingNodePreview: false,
  nodePreview: {},
  nodePreviewRelations: {},
  hiddenNodes: [],
  expandedNodeTypes: [],
  isLoadingNodes: false,
})

const explorerReducer = handleActions(
  {
    [REMOVE_NODE]: (state, { payload }) => {
      const node = payload.get('srn')
      const allNodes = state.get('nodes')

      let children = List()
      allNodes.forEach(n => {
        const parents = n.get('parents', List())
        if (parents.includes(node)) {
          children = children.push(n)
        }
      })

      const childNodeIds = children.map(child => child.get('srn'))
      const newNodes = allNodes.filter(
        x => !childNodeIds.includes(x.get('srn')) && x.get('srn') !== node
      )

      return state.set('selectedNode', null).set('nodes', newNodes)
    },
    [CLEAR_GRAPH]: state =>
      state
        .set('selectedNode', null)
        .set('sourceNodes', fromJS([]))
        .set('nodes', fromJS({}))
        .set('availableFilters', fromJS({}))
        .set('tableData', fromJS([]))
        .set('viewType', EXPLORER_VIEW_TYPES.EXPLORER)
        .set('nodePreview', fromJS({}))
        .set('nodePreviewRelations', fromJS({}))
        .set('hiddenNodes', fromJS([]))
        .set('expandedNodeTypes', fromJS([])),
    [LOAD_ALL_CHILDREN]: (state, { payload }) => {
      let nodes = {}
      _.keys(payload.relations).map(a => {
        nodes[a] = []
        payload.relations[a].map(b => {
          let srns = nodes[a].map(y => y.item.srn)
          let sourceNodes = state.get('sourceNodes')
          if (!srns.includes(b.item.srn)) {
            if (!sourceNodes.includes(b.item.srn)) {
              nodes[a].push(b)
            }
          }
        })
      })
      const relations = fromJS(nodes)
      let newState = state.setIn(['nodes', payload.srn, 'relations'], relations)
      relations.forEach(relationsForType => {
        relationsForType.forEach(relation => {
          const item = relation.get('item')
          if (!newState.get('nodes').has(item.get('srn'))) {
            newState = newState.setIn(
              ['nodes', item.get('srn')],
              item
                .set('filters', Map())
                .set('isSourceNode', false)
                .set('parents', fromJS([payload.srn]))
            )
          }
        })
      })
      return newState
    },
    [CLEAR_FILTERS]: (state, { payload }) => {
      let currentRelations = state
        .getIn(['nodes', payload.node, 'relations'])
        .toJS()

      let newState = state.deleteIn(['nodes', payload.node, 'relations'])
      const sourceNodes = payload.sourceNodes.toJS()
      let clearRelations = {}
      for (let i = 0; i < _.keys(currentRelations).length; i++) {
        clearRelations[_.keys(currentRelations)[i]] = _.uniqBy(
          currentRelations[_.keys(currentRelations)[i]].filter(
            x => !sourceNodes.includes(x.item.srn)
          ),
          x => x.item.srn
        )
      }

      fromJS(clearRelations).forEach(type => {
        type.forEach(rel => {
          newState = removeDeselectedNodes(newState, rel.get('item').get('srn'))
        })
      })
      return newState.setIn(
        ['nodes', state.get('selectedNode'), 'filters'],
        Map()
      )
    },
    [SET_RELATIONS]: (state, { payload }) => {
      const sourceNodes = state.get('sourceNodes')
      const parentNodes = state.getIn(['nodes', payload.srn, 'parents'])

      let relations = payload.relations.filter(
        x => !sourceNodes.includes(x.item.srn)
      )
      if (parentNodes && !parentNodes.isEmpty()) {
        relations = relations.filter(y => !parentNodes.includes(y.item.srn))
      }

      let newState = state
        .setIn(
          ['nodes', payload.srn, 'relations', payload.label],
          fromJS(relations.map(rel => ({ parent: payload.srn, ...rel })))
        )
        .setIn(
          ['nodes', payload.srn, 'filters', payload.label],
          fromJS({ selected: true })
        )

      for (let relation of relations) {
        if (!newState.get('nodes').has(relation.item.srn)) {
          newState = newState.setIn(
            ['nodes', relation.item.srn],
            fromJS({
              ...relation.item,
              filters: {},
              isSourceNode: false,
              parents: [payload.srn],
            })
          )
        } else {
          let parents = newState.getIn(['nodes', relation.item.srn, 'parents'])

          let currentParents = parents.filter(
            x => !state.get('sourceNodes').includes(x)
          )

          if (!currentParents.isEmpty()) {
            if (!currentParents.contains(payload.srn)) {
              newState = newState.setIn(
                ['nodes', relation.item.srn, 'parents'],
                currentParents.add(payload.srn)
              )
            }
          }
        }
      }
      return newState.set('isLoading', false).set('isLoadingNodes', false)
    },
    [REMOVE_RELATIONS]: (state, { payload }) => {
      let currentRelations = fromJS(
        _.uniqBy(
          state
            .getIn(['nodes', payload.srn, 'relations', payload.label])
            .toJS(),
          x => x.item.srn
        )
      )

      const sourceNodes = payload.sourceNodes.toJS()
      currentRelations = currentRelations.filter(
        rel => !sourceNodes.includes(rel.getIn(['item', 'srn']))
      )

      let newState = state.deleteIn([
        'nodes',
        payload.srn,
        'relations',
        payload.label,
      ])

      currentRelations.forEach(rel => {
        newState = removeDeselectedNodes(newState, rel.get('item').get('srn'))
      })
      return newState
    },
    [GET_SOURCE_NODE]: state => state.set('isLoading', true),
    [GET_MULTI_SOURCE_NODES]: state => state.set('isLoading', true),
    [SET_SOURCE_NODES]: (state, { payload }) => {
      let nodes = payload.nodes
      let sourceNodes = []
      let stateNodes = {}
      nodes.forEach(node => {
        sourceNodes.push(node.srn)
        stateNodes[node.srn] = {
          ...node,
          filters: {},
          isSourceNode: true,
          name: node.friendlyName || node.name,
          type: node.__typename,
          relations: {},
        }
      })
      let newState = state
        .set('isLoading', false)
        .set('nodes', fromJS(stateNodes))
        .set('sourceNodes', fromJS(sourceNodes))
      return newState
    },
    [LOAD_NODES]: (state, { payload }) => {
      let nodes = {}
      payload.forEach(node => {
        nodes[node.get('srn')] = { ...node.toJS() }
      })

      const sourceNodes = payload
        .filter(node => node.get('isSourceNode'))
        .map(node => node.get('srn'))

      return state.set('nodes', fromJS(nodes)).set('sourceNodes', sourceNodes)
    },
    [SET_FILTER]: (state, { payload }) => {
      return state.setIn(
        ['nodes', state.get('selectedNode'), 'filters', payload],
        fromJS({ selected: true })
      )
    },
    [REMOVE_FILTER]: (state, { payload }) => {
      return state.setIn(
        ['nodes', state.get('selectedNode'), 'filters', payload],
        fromJS({ selected: false })
      )
    },
    [SELECT_NODE]: (state, { payload }) => {
      return state.set('selectedNode', payload.node)
    },
    [SET_AVAILABLE_FILTERS]: (state, { payload }) => {
      return state.setIn(['availableFilters', payload.type], payload.filters)
    },
    [HANDLE_HIDE]: (state, { payload }) => {
      const sourceNodes = state.get('sourceNodes')
      const nodesToHide = payload.listOfSrnsToHide
        .filter(node => {
          if (!sourceNodes.includes(node)) {
            return node
          }
        })
        .map(node =>
          fromJS({
            srn: node,
            __typename: payload.typename,
          })
        )
      const hiddenNodes = state.get('hiddenNodes').concat(nodesToHide)

      return state.set('hiddenNodes', hiddenNodes)
    },
    [HANDLE_SHOW]: (state, { payload }) => {
      const hiddenNodes = state.get('hiddenNodes')
      const newHiddenNodes = hiddenNodes.filter(
        node => node.get('__typename') !== payload
      )
      return state.set('hiddenNodes', newHiddenNodes)
    },
    [GET_NODE_PREVIEW]: state => state.set('isLoadingNodePreview', true),
    [SET_NODE_PREVIEW]: (state, { payload }) => {
      return state
        .set('nodePreview', fromJS(payload))
        .set('isLoadingNodePreview', false)
    },
    [SET_NODE_PREVIEW_RELATIONS]: (state, { payload }) => {
      return state.set('nodePreviewRelations', fromJS(payload))
    },
    [HANDLE_EXPAND]: (state, { payload }) => {
      const types = payload.map(x => x.typename)
      const expandedNodeTypes = state.get('expandedNodeTypes').toJS()
      const updatedExpandedNodeTypes = fromJS(
        [].concat(types, expandedNodeTypes)
      )
      return state
        .set('isLoadingNodes', true)
        .set('expandedNodeTypes', updatedExpandedNodeTypes)
    },
    [HANDLE_UPDATE]: (state, { payload }) => {
      return state
        .set('nodes', fromJS(payload))
        .set('hiddenNodes', List())
        .set('expandedNodeTypes', List())
    },
  },
  initialState
)

const removeDeselectedNodes = (state, srn) => {
  let newState = state
  const currentNode = newState.getIn(['nodes', srn])

  if (currentNode && currentNode.get('parents')) {
    if (currentNode.get('parents').size <= 1) {
      newState = newState.deleteIn(['nodes', srn])
    }
  }

  const currentRelations = newState.getIn([
    'nodes',
    currentNode.get('srn'),
    'relations',
  ])
  if (currentRelations) {
    currentRelations.forEach(relationsForType => {
      relationsForType.forEach(item => {
        newState = removeDeselectedNodes(newState, item.get('srn'))
      })
    })
  }

  newState.get('nodes').forEach(node => {
    if (node.get('parents')) {
      if (
        node
          .get('parents')
          .toJS()
          .includes(currentNode.get('srn'))
      ) {
        newState = removeDeselectedNodes(newState, node.get('srn'))
      }
    }
  })

  return newState
}

export default explorerReducer
