import { defineMessages } from 'react-intl'

export default defineMessages({
  actionErrorAcceptRiskTicket: {
    id: 'app.containers.TicketDetails.actionErrorCloseTicket',
    defaultMessage: 'Error ocurred accepting ticket risk.',
  },
  actionErrorCloseTicket: {
    id: 'app.containers.TicketDetails.actionErrorCloseTicket',
    defaultMessage: 'Error ocurred closing ticket.',
  },
  actionErrorReopenTicket: {
    id: 'app.containers.TicketDetails.actionErrorCloseTicket',
    defaultMessage: 'Error ocurred reopening ticket.',
  },
  actionErrorSnoozeTicket: {
    id: 'app.containers.TicketDetails.actionErrorSnoozeTicket',
    defaultMessage: 'Error ocurred snoozing ticket.',
  },
  actionErrorReassign: {
    id: 'app.containers.TicketDetails.actionErrorReassign',
    defaultMessage: 'Error ocurred reassigning ticket.',
  },
  assignToMe: {
    id: 'app.containers.TicketDetails.assignToMe',
    defaultMessage: 'Assign To Me',
  },
  assignToMeError: {
    id: 'app.containers.TicketDetails.assignToMeError',
    defaultMessage: 'There was an error assigning ticket to me.',
  },
  customFields: {
    id: 'app.containers.TicketDetails.customFields',
    defaultMessage: 'Custom Ticket Fields:',
  },
  description: {
    id: 'app.containers.TicketDetails.description',
    defaultMessage: 'Description',
  },
  na: {
    id: 'app.containers.TicketDetails.na',
    defaultMessage: 'N/A',
  },
  fetchSimilarTicketsError: {
    id: 'app.containers.TicketDetails.fetchSimilarTicketsError',
    defaultMessage: 'Error ocurred loading Similar Tickets.',
  },
  notFoundMessage: {
    id: 'app.containers.TicketDetails.notFoundMessage',
    defaultMessage: 'The ticket could not be found',
  },
  errorHeaderText: {
    id: 'app.containers.TicketDetails.errorHeaderText',
    defaultMessage: 'Ticket Error Occurred',
  },
  poilcyEvidenceLegacyLoadError: {
    id: 'app.containers.TicketDetails.poilcyEvidenceLegacyLoadError',
    defaultMessage: 'Error occurred loading policy evidence',
  },
  snoozeModalHeaderText: {
    id: 'app.containers.TicketDetails.snoozeModalHeaderText',
    defaultMessage: 'Snooze Ticket',
  },
  reassignModalHeaderText: {
    id: 'app.containers.TicketDetails.reassignModalHeaderText',
    defaultMessage: 'Reassign To User',
  },
  reassignModalOKText: {
    id: 'app.containers.TicketDetails.snoozeModalOKText',
    defaultMessage: 'Reassign',
  },
  snoozeModalHeaderInstructions: {
    id: 'app.containers.TicketDetails.snoozeModalHeaderInstructions',
    defaultMessage: 'Select period of snooze',
  },
  snoozeModalOKText: {
    id: 'app.containers.TicketDetails.snoozeModalOKText',
    defaultMessage: 'Snooze',
  },
  snoozeModalCancelText: {
    id: 'app.containers.TicketDetails.snoozeModalHeaderText',
    defaultMessage: 'Cancel',
  },
  unassigned: {
    id: 'app.containers.TicketDetails.unassigned',
    defaultMessage: 'Unassigned',
  },
})
