import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import Color from 'color'
import { Table } from 'reactstrap'
import qs from 'query-string'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Tooltip from 'components/Tooltip'
import Icon from 'components/Icon'
import SectionHeader from 'components/SectionHeader'
import NodeViewDetailString from './NodeViewDetailString'
import NodeViewDetailDate from './NodeViewDetailDate'
import NodeViewDetailBool from './NodeViewDetailBool'
import NodeViewDetailAccessKeys from './NodeViewDetailAccessKeys'
import NodeViewDetailAccount from './NodeViewDetailAccount'
import NodeViewDetailSrn from './NodeViewDetailSrn'
import NodeViewDetailCode from './NodeViewDetailCode'
import NodeViewDetailClassification from './NodeViewDetailsClassification'
import NodeViewSrn from './NodeViewSrn'
import NodeViewDetailInt from './NodeViewDetailInt'
import IconDivider from 'components/IconDivider'
import BorderlessButton from 'components/BorderlessButton'
import messages from 'globalTranslations'
import NodeViewDetailLabel from './NodeViewDetailLabel'
import NodeViewDetailMetadata from './NodeViewDetailMetadata'
import NodeViewDetailBlockStorage from './NodeViewDetailBlockStorage'
import NodeViewDetailPublicKey from './NodeViewDetailPublicKey'
import SwimlaneDisplay from 'components/SwimlaneDisplay'
import TextLink from 'components/TextLink'
import MFADevice from 'components/MFADevice'
import ServicePrincipal from 'components/ServicePrincipal'
import ConsoleEnabled from 'components/ConsoleEnabled'
import { exists } from 'utils/sonraiUtils'
import { getMappedBoolLabel } from 'utils/widgetUtils'
import { HAS_COPY } from 'appConstants'

class NodeViewDetailsSection extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      highlight: {
        backgroundColor: Color(props.theme.emphasis)
          .lighten(0.4)
          .rotate(10)
          .hex(),
      },
      detail: {
        padding: '0.5em 0',
      },
      label: {
        display: 'flex',
      },
      detailCard: {
        margin: '2.5em 0 0 0 ',
      },
      expandButton: {
        fontSize: '0.8rem',
      },
    }
    this.state = {
      expand: false,
    }
  }

  getFieldCount = (type, value) => {
    if (type === 'LIST') {
      if (Array.isArray(value)) {
        return `(${value.length || '-'})`
      }
    }
    return
  }

  getLabel = (fieldName, label, count) => {
    if (fieldName === 'sid') {
      label = 'Image ID'
    }

    if (fieldName === 'swimlaneSRNs') {
      label = 'Swimlanes'
    }

    if (count) {
      if (label !== 'Classification Set') {
        label = `${label} ${count}`
      }
    }
    const propOfInterest = this.getPropOfInterestMatches(fieldName)

    if (propOfInterest.length > 0) {
      return (
        <div style={this.styles.label}>
          {label}&nbsp;
          <Tooltip
            anchor={
              <Icon
                fa
                name="exclamation-circle-light"
                color={this.props.theme.primary}
              />
            }
            tooltipContent={propOfInterest.map((prop, index) => (
              <div key={index}>{prop.message}</div>
            ))}
          />
        </div>
      )
    }

    return label
  }

  toggleExpand = () => {
    this.setState(oldState => ({
      expand: !oldState.expand,
    }))
  }

  renderDetail = field => {
    const node = this.props.nodeData
    const propOfInterest = this.getPropOfInterestMatches(field.name)
    const value = node[field.name]
    const mappedLabel = getMappedBoolLabel(field.name, value)
    const translatedLabel = messages.fields[field.name]
      ? this.props.intl.formatMessage(messages.fields[field.name])
      : _.startCase(field.name)
    const finalLabel = mappedLabel || translatedLabel
    const type = field.type.name || field.type.kind
    const count = this.getFieldCount(type, value)
    const label = this.getLabel(field.name, finalLabel, count)

    return (
      <div
        key={field.name}
        style={{
          ...this.styles.detail,
          ...this.props.detailStyle,
          ...(propOfInterest.length > 0 ? this.styles.highlight : {}),
        }}
      >
        <NodeViewDetailLabel>{label}</NodeViewDetailLabel>
        {mappedLabel ? null : this.renderHeaderField(field, node)}
      </div>
    )
  }

  renderHeaderField = (field, profile) => {
    const value = profile[field.name] || field.value
    const type = field.type.name || field.type.kind

    return this.renderField(field.name, value, type)
  }

  renderField = (fieldName, value, type) => {
    if (!exists(value) && fieldName != 'classificationSet') {
      return '-'
    }

    const props = {
      key: `content-${fieldName}`,
      value: value,
      fieldName: fieldName,
    }

    if (fieldName === 'classificationSet') {
      return (
        <NodeViewDetailClassification
          {...props}
          nodeData={this.props.nodeData}
        />
      )
    }

    if (fieldName === 'account') {
      return (
        <NodeViewDetailAccount
          {...props}
          accountId={value}
          nodeData={this.props.nodeData}
        />
      )
    }

    if (fieldName === 'isOwnedBy') {
      const ownedBy = _.get(value, ['items', 0])
      if (ownedBy) {
        return (
          <TextLink
            to={{
              pathname: `/App/SolutionCenter/Node/user/`,
              search: qs.stringify({
                nodeId: ownedBy.srn,
              }),
            }}
          >
            {ownedBy.name}
          </TextLink>
        )
      } else {
        return <div>-</div>
      }
    }

    if (fieldName === 'swimlaneSRNs') {
      if (type === 'LIST') {
        return (
          <Table bordered>
            <tbody>
              {value.map((val, index) => (
                <tr key={index}>
                  <td style={{ padding: '0.3em' }}>
                    {this.renderField(fieldName, val, 'String')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )
      }
      return <SwimlaneDisplay srn={value} hasLink hasCopy />
    }

    if (fieldName === 'mfaDevice') {
      return <MFADevice {...props} />
    }

    if (fieldName === 'consoleEnabled') {
      return <ConsoleEnabled {...props} />
    }

    if (fieldName === 'metadata') {
      return (
        <NodeViewDetailMetadata {...props} nodeData={this.props.nodeData} />
      )
    }

    if (fieldName === 'srn' || fieldName === 'resourceId') {
      return <NodeViewDetailSrn {...props} />
    }

    if (fieldName === 'blockStorage') {
      return <NodeViewDetailBlockStorage {...props} />
    }

    if (fieldName === 'publicKeys') {
      return <NodeViewDetailPublicKey {...props} />
    }

    if (fieldName.startsWith('srcIP')) {
      if (Array.isArray(value)) {
        return <NodeViewDetailCode value={value.join(', ')} {...props} />
      } else {
        return <NodeViewDetailCode {...props} />
      }
    }

    if (fieldName === 'accessKeys') {
      return <NodeViewDetailAccessKeys {...props} />
    }

    if (fieldName === 'servicePrincipals') {
      return <ServicePrincipal {...props} />
    }

    if (Array.isArray(value) && !HAS_COPY.includes(fieldName)) {
      if (value.length <= 3) {
        return value.map((val, index) => (
          <div
            key={index}
            style={{ display: 'inline-flex', marginRight: '0.3em' }}
          >
            {this.renderField(fieldName, val, 'String')}
            {value.length > 1 && index < value.length - 1 && ', '}
          </div>
        ))
      } else {
        return (
          <Table bordered>
            <tbody>
              {value.map((val, index) => (
                <tr key={index}>
                  <td style={{ padding: '0.3em' }}>
                    {this.renderField(fieldName, val, 'String')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )
      }
    } else if (Array.isArray(value) && HAS_COPY.includes(fieldName)) {
      if (value.length >= 4) {
        const hide = value.slice(0, 4)
        return (
          <div>
            {!this.state.expand
              ? hide.map((item, i) => <NodeViewSrn key={i} value={item} />)
              : value.map((item, i) => <NodeViewSrn key={i} value={item} />)}
            <IconDivider color="#eee">
              <BorderlessButton
                onClick={this.toggleExpand}
                style={this.styles.expandButton}
              >
                {this.state.expand ? (
                  <div>
                    <Icon name="caret-up" fa /> Hide
                  </div>
                ) : (
                  <div>
                    <Icon name="caret-down" fa /> Show All (
                    {value.length >= 100 ? '100+' : value.length})
                  </div>
                )}
              </BorderlessButton>
            </IconDivider>
          </div>
        )
      }
      return value.map((item = '', i) =>
        item.srn ? (
          <NodeViewSrn key={i} value={item} />
        ) : (
          <NodeViewDetailString key={i} value={item} fieldName={fieldName} />
        )
      )
    }

    switch (type) {
      case 'String':
        if (_.isObject(value)) {
          const stringVal = value.friendlyName || value.name || value.srn || ''
          return (
            <NodeViewDetailString value={stringVal} fieldName={fieldName} />
          )
        }
        if (value && value.startsWith('srn')) {
          return <NodeViewSrn {...props} />
        }

        if (value && fieldName.startsWith('userAgent')) {
          return <NodeViewDetailCode {...props} />
        }

        return <NodeViewDetailString {...props} />
      case 'Boolean':
        return <NodeViewDetailBool {...props} />
      case 'DateTime':
        return <NodeViewDetailDate {...props} />
      case 'Int':
        return <NodeViewDetailInt {...props} />
      default:
        return null
    }
  }

  getPropOfInterestMatches = fieldName => {
    return this.props.propsOfInterest.filter(prop => prop.field === fieldName)
  }

  render() {
    return (
      <div style={{ ...this.styles.detailCard, ...this.props.style }}>
        <div>
          {this.props.title && (
            <SectionHeader small>{this.props.title}</SectionHeader>
          )}

          {this.props.cloudLink}
          {this.props.titleActions}
        </div>
        {this.props.fields.map(this.renderDetail)}
      </div>
    )
  }
}

NodeViewDetailsSection.defaultProps = {
  detailStyle: {},
  propsOfInterest: [],
  style: {},
}

NodeViewDetailsSection.propTypes = {
  fields: PropTypes.array.isRequired,
  nodeData: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  theme: themeShape,
  detailStyle: PropTypes.object,
  style: PropTypes.object,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
  title: PropTypes.node,
  titleActions: PropTypes.node,
  cloudLink: PropTypes.string,
}

export default injectIntl(themeable(NodeViewDetailsSection))
