import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { isImmutable, fromJS, Map, List } from 'immutable'
import _ from 'lodash'
import uuid from 'uuid/v4'

import { getTypeFromSrn } from 'utils/graphDataUtils'
import { getNameFromSrn } from 'utils/sonraiUtils'
import PathWidget from './PathWidget'

const EDGE_PER_NODE_LIMIT = 10

class NestedPathWidget extends React.Component {
  rollupNodes = {}

  processNode = (item, parentId, key) => {
    let nodes = List()
    let edges = List()
    let props = Map()

    const fallbackId = uuid()

    if (
      key === 'hasEffectivePermissions' &&
      item.get('identityChain') &&
      item.get('policySrn')
    ) {
      const chain = item.get('identityChain').split(',')
      chain.shift() //Remove the first value because it will always be the resource ahead and we dont want to duplicate it
      chain.push(item.get('policySrn'))

      chain.forEach((srn, index) => {
        edges = edges.push(
          fromJS({
            item: {
              name: getNameFromSrn(srn),
              srn: srn,
              __typename: getTypeFromSrn(srn),
            },
            parent: index === 0 ? parentId : chain[index - 1],
            relation: {
              direction: 'OUT',
              name: '',
            },
          })
        )

        nodes = nodes.push(
          fromJS({
            item: {
              name: getNameFromSrn(srn),
              srn: srn,
              __typename: getTypeFromSrn(srn),
            },
          })
        )
      })

      return {
        nodes,
        edges,
      }
    } else {
      item.forEach((value, propkey) => {
        if (isImmutable(value)) {
          if (value.get('items')) {
            if (
              value.get('items').size > EDGE_PER_NODE_LIMIT &&
              propkey !== 'hasEffectivePermissions'
            ) {
              const childType =
                value.get('items').first().get('__typename') || 'nodes'
              this.rollupNodes[childType] =
                this.rollupNodes[childType] || uuid()
              const placeholderNodeId = this.rollupNodes[childType]

              edges = edges.push(
                fromJS({
                  item: {
                    name: `(Multiple ${childType})`,
                    srn: placeholderNodeId,
                    __typename: 'Rollup',
                  },
                  parent: item.get('srn') || fallbackId,
                  relation: {
                    direction: 'OUT',
                    name: '',
                  },
                })
              )

              nodes = nodes.push(
                fromJS({
                  item: {
                    name: `(Multiple ${childType})`,
                    srn: placeholderNodeId,
                    __typename: 'Rollup',
                  },
                })
              )
            } else {
              let items = value.get('items', List())
              const aggregation = value
                .get('aggregation', Map())
                .delete('__typename')

              if (!aggregation.isEmpty()) {
                items = items.map(item => item.merge(aggregation))
              }

              items.forEach(childItem => {
                const processed = this.processNode(
                  childItem,
                  item.get('srn') || fallbackId,
                  propkey
                )

                nodes = nodes.concat(processed.nodes)
                edges = edges.concat(processed.edges)
              })
            }
          } else if (value.get('aggregation')) {
            const agValue = value.get('aggregation').delete('__typename')
            const processed = this.processNode(
              value.delete('aggregation').merge(agValue),
              item.get('srn') || fallbackId,
              propkey
            )

            nodes = nodes.concat(processed.nodes)
            edges = edges.concat(processed.edges)
          } else {
            props = props.set(propkey, value)
          }
        } else {
          props = props.set(propkey, value)
        }
      })
    }

    if (!props.has('name') && !props.has('srn')) {
      //Rather than falling back to showing the UUID as the name,
      //we'll grab the "first" property and just assume that it is interesting.
      let stringyBoi = ''
      props.forEach(prop => {
        if (typeof prop === 'string' && !stringyBoi) {
          stringyBoi = prop
        }
      })
      if (stringyBoi) {
        props = props.set('name', stringyBoi)
      }
    }

    if (!props.has('srn')) {
      props = props.set('srn', fallbackId)
    }

    nodes = nodes.push(fromJS({ item: props }))

    if (key) {
      edges = edges.push(
        fromJS({
          item: props,
          parent: parentId,
          relation: {
            direction: 'OUT',
            name: key,
          },
        })
      )
    }

    nodes = nodes.reverse()

    return {
      nodes,
      edges,
    }
  }

  processNodes = () => {
    let nodes = List()
    let edges = List()

    const processed = this.processNode(this.props.data, null, null)
    nodes = nodes.concat(processed.nodes)
    edges = edges.concat(processed.edges)

    return {
      nodes: _.uniqBy(nodes.toJS(), value => value.item.srn),
      edges: edges.toJS(),
    }
  }

  render() {
    const processed = this.processNodes()

    if (processed.edges.length > 200) {
      //eslint-disable-next-line no-console
      console.warn('Path is too large to render', processed)
      return (
        <p style={{ color: '#777', fontStyle: 'italic', margin: '0.5em 0' }}>
          The path is too large to display
        </p>
      )
    }

    return (
      <PathWidget
        loading={this.props.loading}
        nodes={processed.nodes}
        edges={processed.edges}
        onDoubleClick={this.props.onDoubleClick}
        style={this.props.style}
      />
    )
  }
}

NestedPathWidget.propTypes = {
  data: ImmutablePropTypes.map.isRequired,
  loading: PropTypes.bool,
  onDoubleClick: PropTypes.func,
  style: PropTypes.object,
}

export default NestedPathWidget
