/*
 *
 * RapSheet actions
 *
 */

import {
  SET_SELECTED_RESOURCE_ALERT_HISTORY,
  FETCH_CATEGORY_ROLLUP_DATA,
  SET_CATEGORY_ROLLUP_DATA,
  SET_RESOURCES,
  SET_RESOURCES_LOADING,
  FETCH_FILTERED_ALERTS,
  TOGGLE_SUNBURST_VIEW,
  UPDATE_RAPSHEET_RESOURCE_MONITOR,
} from './constants'
import { createAction } from 'redux-actions'

export const setSelectedResourceAlertHistory = createAction(
  SET_SELECTED_RESOURCE_ALERT_HISTORY
)

export const fetchCategoryRollupData = createAction(FETCH_CATEGORY_ROLLUP_DATA)
export const setCategoryRollupData = createAction(SET_CATEGORY_ROLLUP_DATA)
export const fetchFilteredAlerts = createAction(FETCH_FILTERED_ALERTS)
export const toggleSunburstView = createAction(TOGGLE_SUNBURST_VIEW)
export const setResources = createAction(SET_RESOURCES)
export const setResourcesLoading = createAction(SET_RESOURCES_LOADING)
export const updateRapsheetResourceMonitor = createAction(
  UPDATE_RAPSHEET_RESOURCE_MONITOR
)
