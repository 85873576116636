import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Map } from 'immutable'
import FontFaceObserver from 'fontfaceobserver'
import BounceLoadingAnimation from 'components/BounceLoadingAnimation'
import uuid from 'uuid/v4'
import { getAccountSrnFromId, getCloudFromSrn } from 'utils/sonraiUtils'
import CenterContent from 'components/CenterContent'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import {
  truncateMiddle,
  getNameFromSrn,
  exists,
  getAccountFromSrn,
} from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { getColor, getIcon } from './nodeIcons'
import VisNetwork from './VisNetwork'
const MAX_SPRING_LENGTH = 800
const MIN_SPRING_LENGTH = 50
const DEFAULT_OUT_SPRING_LENGTH = 100
const DEFAULT_IN_SPRING_LENGTH = 250

class GraphVis extends React.Component {
  constructor(props) {
    super(props)

    this.uuid = uuid()

    this.state = {
      customSpringLengths: {},
      network: null,
      iconsLoaded: false,
      zoomView: false,
    }

    const fontA = new FontFaceObserver('Font Awesome 5 Pro')
    const fontB = new FontFaceObserver('Font Awesome 5 Pro', {
      weight: 400,
    })

    Promise.all([fontA.load(), fontB.load()]).then(() => {
      if (this.mounted) {
        this.setState({
          iconsLoaded: true,
        })
      }
    })

    this.events = {
      dragEnd: this.handleEndDrag,
      oncontext: this.noop,
      click: this.props.onClick,
      hoverNode: this.onHoverNode,
      blurNode: this.onBlurNode,
      doubleClick: this.props.onDoubleClick,
      hoverEdge: this.onHoverEdge,
      blurEdge: this.onBlurEdge,
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)

    this.mounted = true
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)

    this.mounted = false
  }

  shouldComponentUpdate(newProps, newState) {
    if (newState !== this.state) {
      return true
    }

    return (
      !_.isEqual(newProps.nodes, this.props.nodes) ||
      !_.isEqual(newProps.edges, this.props.edges) ||
      !_.isEqual(newProps.terms, this.props.terms) ||
      !_.isEqual(newProps.selectedNode, this.props.selectedNode)
    )
  }

  noop = visInfo => {
    visInfo.event.preventDefault()
    if (this.props.onContext) {
      const { x, y } = visInfo.pointer.DOM
      const node = this.state.network.getNodeAt({
        x,
        y,
      })
      this.props.onContext(node, { x, y })
    }
  }

  handleClick = ({ target }) => {
    const canvas = target.localName === 'canvas'
    if (canvas) {
      if (!this.state.zoomView) {
        this.setState({
          zoomView: true,
        })
      }
    } else {
      if (this.state.zoomView) {
        this.setState({
          zoomView: false,
        })
      }
    }
  }

  getLabel = (relation, displayName) => {
    const type = relation.item.__typename || getTypeFromSrn(relation.item.srn)
    const account =
      relation.item.account || getAccountFromSrn(relation.item.srn)
    if (account && account !== '-' && this.props.showAccountInLabel) {
      const srn = getAccountSrnFromId(account, this.props.accounts)
      const cloudType = _.toUpper(getCloudFromSrn(srn))
      const obj =
        this.props.accounts.find(account => account.get('srn') === srn) || Map()
      const name =
        obj.get('friendlyName') || obj.get('name') || obj.get('account')
      const displayAccount = `${cloudType} - ${name}`
      return `${_.startCase(type)}:\n ${displayName}\n ${displayAccount}`
    }
    return `${_.startCase(type)}:\n ${displayName}`
  }

  getHoverTitle = relation => {
    return (
      relation.item.friendlyName ||
      relation.item.name ||
      getNameFromSrn(relation.item.srn) ||
      ''
    )
  }

  isMatch = label => {
    const { terms } = this.props
    if (!_.isEmpty(terms)) {
      const searches = terms.map(term => term.label.toLowerCase())

      const values = searches.map(search =>
        label.toLowerCase().includes(search)
      )
      if (!values.includes(true)) {
        return true
      }
    }
  }

  handleLabelColor = (label, relation) => {
    if (this.isMatch(label)) {
      return '#F1F1F1'
    }

    if (relation.item.active === false) {
      return this.props.theme.neutralDark
    }
  }

  handleIconColor = label => {
    if (this.isMatch(label)) {
      return '#F1F1F1'
    }
    return getColor(
      label.split(':')[0],
      this.props.theme,
      this.props.typeColors
    )
  }

  handleEdgeColor = (relation, isMatch, parentNode) => {
    if (isMatch) {
      return '#F1F1F1'
    }

    if (
      relation.item.active === false ||
      (parentNode && parentNode.item.active === false)
    ) {
      return this.props.theme.neutralDark
    }

    if (
      relation.parent
        ? relation.relation.direction === 'IN'
        : relation.parent == relation.item.srn
    ) {
      return this.props.theme.explorerIncomingEdge
    } else {
      return this.props.theme.darkBlue
    }
  }

  getLabelColor = isMatch => {
    if (isMatch) {
      return '#F1F1F1'
    }

    return '#000000'
  }

  handleEndDrag = e => {
    if (e.nodes.length < 1 || !this.state.network) {
      return
    }

    const newCoords = e.pointer.canvas
    const nodeConfig = this.props.edges.find(
      relation => relation.item.srn === e.nodes[0]
    )

    if (!nodeConfig) {
      return
    }

    let parentId = e.nodes[0]

    if (nodeConfig.parent) {
      parentId = nodeConfig.parent
    }

    const parentCoords = this.state.network.getPositions([parentId])[parentId]

    const dx = newCoords.x - parentCoords.x
    const dy = newCoords.y - parentCoords.y

    const newOffset = Math.sqrt(dx * dx + dy * dy)

    //Fudge the numbers a little to make it looks right.
    //The "starting" spring length is 100
    let newSpringLength = newOffset - 100
    if (newSpringLength < MIN_SPRING_LENGTH) {
      newSpringLength = MIN_SPRING_LENGTH
    }

    if (newSpringLength > MAX_SPRING_LENGTH) {
      newSpringLength = MAX_SPRING_LENGTH
    }

    this.setState(oldState => {
      const springLengths = oldState.customSpringLengths
      springLengths[e.nodes[0]] = newSpringLength

      return {
        customSpringLengths: springLengths,
      }
    })
  }

  getNodes = () => {
    let nodes = _.uniqBy(this.props.nodes, node => node.item.srn).map(node => {
      const isSourceNode =
        node.item.isSourceNode ||
        (this.props.sourceNodes &&
          this.props.sourceNodes.includes(node.item.srn))

      const fullName = `${this.getHoverTitle(node)}`
      const shortName = truncateMiddle(fullName)
      const fullLabel = this.getLabel(node, fullName)
      const type = node.item.__typename || getTypeFromSrn(node.item.srn)
      const iconColor = isSourceNode
        ? this.props.theme.emphasis
        : this.handleIconColor(fullLabel)

      return {
        id: node.item.srn,
        label: this.getLabel(node, shortName),
        title: fullName,
        font: {
          color: this.handleLabelColor(fullLabel, node),
        },
        icon: {
          face: '"Font Awesome 5 Pro"',
          size: 50,
          code: getIcon(type),
          color:
            node.item.active === false
              ? this.props.theme.neutralMedium
              : iconColor,
        },
      }
    })

    return nodes
  }

  getEdges = () => {
    return this.props.edges.map(relation => {
      return this.getCoreNodeConfig(relation)
    })
  }

  getCoreNodeConfig = relation => {
    const isMatch = this.isMatch(
      this.getLabel(relation, this.getHoverTitle(relation))
    )

    const parentNode = this.props.nodes.find(
      node => node.item.srn === relation.parent
    )

    return {
      from:
        relation.relation.direction === 'IN'
          ? relation.item.srn
          : this.props.sourceNodeID || relation.parent,
      to:
        relation.relation.direction === 'IN'
          ? this.props.sourceNodeID || relation.parent
          : relation.item.srn,
      color: {
        color: this.handleEdgeColor(relation, isMatch, parentNode),
        highlight: this.props.theme.emphasis,
      },
      dashes:
        relation.item.active === false ||
        (parentNode && parentNode.item.active === false),
      font: {
        color: this.getLabelColor(isMatch),
        align: 'middle',
        size: 0,
      },
      label: relation.relation.name,
      length:
        this.state.customSpringLengths[relation.item.srn] ||
        (relation.relation.direction === 'IN'
          ? DEFAULT_IN_SPRING_LENGTH
          : DEFAULT_OUT_SPRING_LENGTH),
    }
  }

  onHoverNode = () => {
    const network = this.state.network
    network.canvas.body.container.style.cursor = 'pointer'
  }

  onBlurNode = () => {
    const network = this.state.network
    network.canvas.body.container.style.cursor = 'default'
  }

  onHoverEdge = e => {
    this.state.network.body.data.edges.update({
      id: e.edge,
      font: { size: 14 },
    })
  }

  onBlurEdge = e => {
    this.state.network.body.data.edges.update({ id: e.edge, font: { size: 0 } })
  }

  render() {
    if (!this.state.iconsLoaded) {
      return (
        <CenterContent>
          <BounceLoadingAnimation />
        </CenterContent>
      )
    }

    const graph = {
      nodes: this.getNodes(),
      edges: this.getEdges(),
    }

    const options = _.merge(
      {
        edges: {
          color: this.props.theme.darkBlue,
          arrowStrikethrough: false,
          shadow: {
            enabled: false,
          },
          smooth: { enabled: true },
          width: 2,
        },
        nodes: {
          font: {
            vadjust: -7,
          },
          shadow: {
            enabled: false,
          },
          shape: 'dot',
          icon: {
            face: '"Font Awesome 5 Pro"',
            color: '#aa00ff',
            size: 50,
          },
          color: {
            border: '#ffffff',
            background: '#fafafa',
          },
        },
        physics: {
          barnesHut: {
            damping: 0.2,
            gravitationalConstant: -4000,
          },
        },
        interaction: {
          zoomView: this.state.zoomView,
          hover: true,
          hoverConnectedEdges: false,
          selectConnectedEdges: false,
        },
      },
      this.props.options
    )

    if (this.state.network && exists(this.props.selectedNode)) {
      this.state.network.selectNodes([this.props.selectedNode])
    }

    return (
      <VisNetwork
        key={this.uuid}
        graph={graph}
        options={options}
        events={this.events}
        getNetwork={network => this.setState({ network })}
        height={this.props.height}
        width={this.props.width}
      />
    )
  }
}

GraphVis.defaultProps = {
  height: '100%',
  width: '100%',
  onClick: () => {},
  options: {},
}

GraphVis.propTypes = {
  options: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        srn: PropTypes.string,
        __typename: PropTypes.string,
        friendlyName: PropTypes.string,
        name: PropTypes.string,
        active: PropTypes.bool,
      }),
    })
  ).isRequired,
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        srn: PropTypes.string,
        __typename: PropTypes.string,
        friendlyName: PropTypes.string,
        name: PropTypes.string,
        active: PropTypes.bool,
      }),
      parent: PropTypes.string,
      relation: PropTypes.shape({
        direction: PropTypes.oneOf(['IN', 'OUT']),
        name: PropTypes.string,
      }),
    })
  ).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  theme: themeShape,
  terms: PropTypes.array.isRequired,
  sourceNodes: PropTypes.object,
  sourceNodeID: PropTypes.string,
  selectedNode: PropTypes.string,
  onClick: PropTypes.func,
  onContext: PropTypes.func,
  onDoubleClick: PropTypes.func,
  typeColors: PropTypes.object,
  showAccountInLabel: PropTypes.bool,
  accounts: ImmutablePropTypes.iterable,
}

export default themeable(GraphVis)
