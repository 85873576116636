import React from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class MFADevice extends React.PureComponent {
  getColor = () => {
    if (this.props.value === 'Virtual' || this.props.value === 'Hardware') {
      return { color: this.props.theme.success }
    } else {
      return { color: this.props.theme.fail }
    }
  }

  render() {
    if (this.props.value === 'Loading...') {
      return <div>{this.props.value}</div>
    } else {
      return <div style={this.getColor()}>{this.props.value}</div>
    }
  }
}

MFADevice.propTypes = {
  value: PropTypes.string,
  theme: themeShape,
}

export default themeable(MFADevice)
