import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles'

class TopTitle extends React.PureComponent {
  render() {
    return (
      <div style={{ ...styles.bottomTitle, ...this.props.style }}>
        {this.props.children}
      </div>
    )
  }
}

TopTitle.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

export default TopTitle
