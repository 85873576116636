/**
 *
 * TableWidget
 *
 */

import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import _ from 'lodash'
import gql from 'graphql-tag'
import TextLink from 'components/TextLink'
import DataTable from 'components/DataTable'
import { getSearchIdForSonraiSearches } from 'utils/sonraiUtils'
import { queryHasPivotFilter } from 'query-builder'
import Card, { TopTitle, CardBody } from 'components/Card'
import WidgetCard from 'components/WidgetCard'
import {
  getSonraiSearchQuery,
  getSearchCard,
  hasSonraiSearch,
  getWidgetResultsExplorerSavedSearchQuery,
  getTableSavedSearchQuery,
} from 'query-builder'
import SonraiCountDisplay from 'components/SonraiCountDisplay'
import { Map } from 'immutable'

import TableWidgetQueryResult from './TableWidgetQueryResult'

export class TableWidget extends React.Component {
  styles = {
    title: {
      fontSize: '22px',
      fontWeight: '300',
      cursor: 'pointer',
      marginRight: '0px',
    },
    subtitle: {
      fontSize: '14px',
      fontWeight: '300',
      color: '#888888',
    },
    card: {
      minHeight: 0, //Required to allow tables to autosize without overflowing
    },
  }

  shouldFlatten = () => {
    return (
      (this.props.options && this.props.options.sonraiSearches.table) ||
      !getSearchCard(this.props.resultLayout, 'table')
    )
  }

  getQuery = () => {
    if (hasSonraiSearch(this.props.options, 'table')) {
      return getSonraiSearchQuery(this.props.options.sonraiSearches.table)
    } else {
      return getTableSavedSearchQuery(
        this.props.getQueryBuilder,
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.table,
        {},
        'tableWidgetQuery',
        this.shouldFlatten()
      )
    }
  }

  getExploreResultsQuery = () => {
    if (hasSonraiSearch(this.props.options, 'table')) {
      return getSonraiSearchQuery(this.props.options.sonraiSearches.table)
    } else {
      return getWidgetResultsExplorerSavedSearchQuery(
        this.props.getQueryBuilder,
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.table
      )
    }
  }

  getSearchName = () => {
    if (!this.props.savedSearches) {
      return
    }

    return hasSonraiSearch(this.props.options, 'table')
      ? this.props.options.sonraiSearches.table
      : this.props.savedSearches.getIn([
          this.props.resultLayout.indexedSearches.table,
          'name',
        ])
  }

  onClickSearch = () => {
    this.props.onClickSearch({
      savedSearchId: this.props.resultLayout.indexedSearches.table,
      sonraiSearchName: this.props.options.sonraiSearches.table,
      searchTitle: this.props.title,
    })
  }

  getSearchId = () => {
    const { options, sonraiSearches, resultLayout } = this.props
    let searchObj = Map({ uiSearch: null, advancedSearch: null })
    if (hasSonraiSearch(options, 'table')) {
      const searches = getSearchIdForSonraiSearches(options, sonraiSearches)
      searchObj = searchObj.set('advancedSearch', searches)
    } else {
      searchObj = searchObj.set('uiSearch', resultLayout.indexedSearches.table)
    }
    return searchObj
  }

  renderHeader = length => {
    const title = length ? (
      <span>
        {this.props.title} (<SonraiCountDisplay count={length} />)
      </span>
    ) : (
      this.props.title
    )

    return (
      <div style={this.styles.header}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.onClickSearch ? (
            <TextLink
              title={this.getSearchName()}
              style={this.styles.title}
              onClick={this.onClickSearch}
            >
              {title}
            </TextLink>
          ) : (
            <span style={this.styles.title}>{title}</span>
          )}
        </div>

        <div key-="subtitle" style={this.styles.subtitle}>
          {this.props.subtitle}
        </div>
      </div>
    )
  }

  render() {
    if (this.props.data === undefined) {
      const searchId = this.getSearchId()
      const queryConfig = this.getQuery()
      if (!queryConfig.gqlStatement) {
        return (
          <WidgetCard
            searchId={searchId}
            loading={true}
            title={this.props.title}
            allowDelete={this.props.allowDelete}
            allowUpdate={this.props.allowUpdate}
            onRemove={this.props.onRemove}
            onEdit={this.props.onEdit}
          />
        )
      }

      const filtered = queryHasPivotFilter(queryConfig.gqlStatement)

      return (
        <Query
          query={gql`
            ${queryConfig.gqlStatement}
          `}
          variables={queryConfig.variables}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          key={JSON.stringify(queryConfig)} //TODO: investigate refetching data w/ new query and variables with same Query component
          context={{
            queryName: `tableWidget_${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ error, data, refetch, networkStatus }) => {
            return (
              <TableWidgetQueryResult
                data={data}
                error={error}
                networkStatus={networkStatus}
                refetch={refetch}
                filtered={filtered}
                searchId={searchId}
                renderHeader={this.renderHeader}
                shouldFlatten={this.shouldFlatten}
                {...this.props}
              />
            )
          }}
        </Query>
      )
    } else {
      if (this.props.loading) {
        return (
          <WidgetCard
            loading={true}
            title={this.props.title}
            disableToolbar={this.props.disableToolbar}
          />
        )
      }

      return (
        <Card loading={this.props.loading}>
          <TopTitle>{this.renderHeader(this.props.data.length)}</TopTitle>
          <CardBody>
            <DataTable
              customColumnConfig={this.props.customColumnConfig}
              selectNode={this.props.selectNode}
              selectedNode={this.props.selectedNode}
              id={this.props.nodeViewType}
              userProfile={this.props.userProfile}
              // setUserProfileTables={this.props.setUserProfileTables}
              set="NodeView"
              data={this.props.data}
              onClickNodeView={this.props.onClickNodeView}
              onSingleClickNodeView={this.props.onSingleClickNodeView}
              flatten={this.props.flatten}
              exportFileName={this.props.title}
            />
          </CardBody>
        </Card>
      )
    }
  }
}

TableWidget.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  data: PropTypes.array,
  flatten: PropTypes.bool,
  nodeViewType: PropTypes.string,
  getQueryBuilder: PropTypes.func,
  onRemove: PropTypes.func,
  options: PropTypes.object,
  resultLayout: PropTypes.shape({
    indexedSearches: PropTypes.objectOf(PropTypes.string),
  }),
  savedSearches: ImmutablePropTypes.map,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.string,
  toggleStatic: PropTypes.func,
  static: PropTypes.bool,
  setWidgetOptions: PropTypes.func,
  onClickNodeView: PropTypes.func,
  onClickSearch: PropTypes.func,
  onEdit: PropTypes.func,
  onSingleClickNodeView: PropTypes.func,
  pivot: PropTypes.object,
  userProfile: PropTypes.object,
  srn: PropTypes.string,
  selectedNode: PropTypes.string,
  widget: PropTypes.object,
  loading: PropTypes.bool,
  selectNode: PropTypes.func,
  sonraiSearches: ImmutablePropTypes.iterable,
  customColumnConfig: PropTypes.object,
}

export default TableWidget
