import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import { SET_PUBLICKEY_CONTENT, GET_PUBLICKEY_CONTENT } from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  publickeyContent: {},
  isLoading: false,
})

const publickeyNodeViewCardLayoutReducer = handleActions(
  {
    [GET_PUBLICKEY_CONTENT]: state => state.set('isLoading', true),
    [SET_PUBLICKEY_CONTENT]: (state, { payload }) => {
      return state
        .set('publickeyContent', fromJS(payload))
        .set('isLoading', false)
    },
    [CLEAR_DATA]: state => state.set('publickeyContent', Map()),
  },
  initialState
)

export default publickeyNodeViewCardLayoutReducer
