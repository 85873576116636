import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import TextLink from 'components/TextLink'
import { getNodeViewPushParams, getNameFromSrn } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import qs from 'query-string'
import SavedSearchDisplay from 'components/SavedSearchDisplay'
import MarkdownDisplay from 'components/MarkdownDisplay'
import { hadResourceDeleted } from 'utils/sonraiUtils'

import messages from '../messages'

import BorderlessButton from 'components/BorderlessButton'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Expandable from 'components/Expandable'

import Pill from 'components/Badge/Pill'

import SectionHeader from 'components/SectionHeader'
import NodeViewHeader from 'containers/NodeViewHeader'
import Button from 'components/Button'

import TicketEvidence from './TicketEvidence'
class DetailsTab extends Component {
  styles = {
    resourceTop: {
      backgroundColor: this.props.theme.neutralLight,
      display: 'flex',
      alignItems: 'center',
      padding: '0.5rem',
    },
    property: {
      display: 'flex',
      margin: '0rem 0.25rem',
      padding: '0.25rem',
    },
    propertyLabel: {
      fontSize: '1rem',
      fontWeight: '400',
      margin: '0rem 0.5rem 0rem 0rem',
    },
    cfBadges: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '-0.25rem',
    },
    props: {
      display: 'grid',
      gridTemplateColumns: '200px 1fr',
      padding: '0.5rem',
    },
    propLabel: {
      fontSize: '1rem',
      marginLeft: '0.5em',
      fontWeight: '400',
    },
    tooltipLabel: {
      margin: '0.25rem 0rem',
      fontWeight: '400',
    },
    li: {
      listStyleType: 'disc',
      marginLeft: '1rem',
    },
  }

  getPolicy = () => {
    const policy =
      this.props.policies.get(this.props.ticket.get('ticketKey')) || Map()

    return policy
  }

  getPolicyInfo = () => {
    const policy = this.getPolicy()
    if (!policy || policy.isEmpty()) {
      return null
    }
    const search = policy.getIn(['contains', 'items', 0]) || Map()

    const policyNavLink = {
      pathname: '/App/ControlCenter/Policy',
      search: qs.stringify({
        policyId: policy.get('srn'),
      }),
    }

    let searchLocationParams

    if (search.get('__typename') === 'Savedquery') {
      searchLocationParams = {
        pathname: '/App/GraphExplorer',
        state: {
          searchName: search.get('name'),
        },
      }
    } else if (search.get('__typename') === 'Search') {
      searchLocationParams = {
        pathname: '/App/Search',
        search: qs.stringify({
          searchId: search.get('sid'),
        }),
      }
    }

    const controlGroupIds = policy
      .getIn(['containedByControlFramework', 'items'], List())
      .map(cf => cf.get('srn'))
    const controlGroups = controlGroupIds.map(
      id => this.props.controlGroups.get(id) || Map()
    )

    return (
      <div
        style={{
          border: `2px solid ${this.props.theme.neutralLight}`,
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        <div style={this.styles.resourceTop}>
          <BorderlessButton color="primary" to={policyNavLink}>
            {policy.get('title')}
          </BorderlessButton>
        </div>

        <div style={this.styles.props}>
          <span style={this.styles.propLabel}>Alerting Level: </span>
          <div>{policy.get('alertingLevelNumeric')}</div>
          <span style={this.styles.propLabel}>Control Frameworks: </span>
          <div style={this.styles.cfBadges}>
            {controlGroups.map(cf => (
              <div key={cf.get('sid')}>
                <BorderlessButton
                  to={{
                    pathname: '/App/ControlCenter/ControlGroup',
                    search: qs.stringify({
                      controlGroupId: cf.get('srn'),
                    }),
                  }}
                >
                  <Pill style={{ margin: '0rem 0.25rem' }}>
                    {cf.get('title')}
                  </Pill>
                </BorderlessButton>
              </div>
            ))}
          </div>
          <span style={this.styles.propLabel}>Search: </span>
          <div>
            <SavedSearchDisplay
              search={search.toJS()}
              to={searchLocationParams}
            />
          </div>
          <span style={this.styles.propLabel}>Description:</span>
          <div>
            <Expandable>
              <MarkdownDisplay content={policy.get('description')} />
            </Expandable>
          </div>
        </div>
      </div>
    )
  }

  getEvidenceData = (ticket, fieldName) => {
    return ticket.getIn(['evidence', fieldName], List())
  }

  renderTicketEvidence = ticket => {
    const resourceSet = this.getEvidenceData(ticket, 'resourceSet')
    const regionSet = this.getEvidenceData(ticket, 'regionSet')
    const userAgentSet = this.getEvidenceData(ticket, 'userAgentSet')
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0rem 0rem 0rem 0.25rem',
        }}
      >
        <div style={this.styles.tooltipLabel}> Resource </div>
        <div style={{ margin: '0.25rem' }}>
          {!resourceSet.isEmpty() ? (
            <div>
              {getTypeFromSrn(resourceSet.first())} -{' '}
              <TextLink
                newTab
                color="primary"
                to={getNodeViewPushParams(
                  resourceSet.first(),
                  getTypeFromSrn(resourceSet.first())
                )}
              >
                {getNameFromSrn(resourceSet.first())}
              </TextLink>
            </div>
          ) : (
            '-'
          )}
        </div>
        <div style={this.styles.tooltipLabel}> Region(s): </div>
        {!regionSet.isEmpty() ? (
          <ul style={{ margin: '0.25rem' }}>
            {regionSet.map((item, index) => (
              <li style={this.styles.li} key={index}>
                {item}
              </li>
            ))}
          </ul>
        ) : (
          '-'
        )}
        <div style={this.styles.tooltipLabel}> User Agent(s): </div>
        {!userAgentSet.isEmpty() ? (
          <ul style={{ margin: '0.25rem' }}>
            {userAgentSet.map((item, index) => (
              <li style={this.styles.li} key={index}>
                {item}
              </li>
            ))}
          </ul>
        ) : (
          '-'
        )}
      </div>
    )
  }

  render() {
    const policy = this.getPolicy()
    const hasPolicy = !!policy && !policy.isEmpty()
    const nodeId =
      this.props.ticket.getIn(['resource', 'srn']) ||
      this.props.ticket.get('resourceSRN')

    const hasNoResource = hadResourceDeleted(this.props.ticket.toJS())

    return (
      <div>
        {this.props.ticket.get('ticketType') === 'Custom' && (
          <Fragment>
            <SectionHeader small>
              <DynamicFormattedMessage {...messages.description} />
            </SectionHeader>
            <div>
              {this.props.ticket.get('description') ? (
                <MarkdownDisplay
                  content={this.props.ticket.get('description')}
                />
              ) : (
                <DynamicFormattedMessage {...messages.na} />
              )}
            </div>
            <br />
            <br />
          </Fragment>
        )}
        {nodeId && (
          <Fragment>
            <SectionHeader small>
              Resource Details{' '}
              <TextLink
                to={getNodeViewPushParams(nodeId, getTypeFromSrn(nodeId))}
              >
                {!hasNoResource && '(Go to Resource Node View)'}
              </TextLink>
            </SectionHeader>

            {!hasNoResource ? (
              <NodeViewHeader
                horizontal
                styles={{ margin: '0rem' }}
                nodeId={nodeId}
              />
            ) : (
              <div
                style={{
                  color: this.props.theme.warn,
                  margin: '1rem 0rem',
                }}
              >
                This resource is no longer available as it was removed from the
                Sonrai platform.
              </div>
            )}
          </Fragment>
        )}

        {this.props.showRemediationModal && (
          <Fragment>
            <SectionHeader small style={{ marginTop: '2em' }}>
              Remediation
            </SectionHeader>
            <div style={{ margin: '0.5em 0' }}>
              <Button color="primary" onClick={this.props.showRemediationModal}>
                Show Remediation Steps
              </Button>
            </div>
          </Fragment>
        )}

        {hasPolicy && (
          <SectionHeader small style={{ marginTop: '2em' }}>
            {' '}
            Policy Details{' '}
          </SectionHeader>
        )}

        {this.props.ticket.get('ticketType') === 'Activity' && (
          <div>
            <SectionHeader small style={{ marginTop: '1.5em' }}>
              Ticket Evidence
            </SectionHeader>
            {this.renderTicketEvidence(this.props.ticket)}
          </div>
        )}

        {this.props.ticket.get('ticketType') === 'Activity' && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SectionHeader small style={{ marginTop: '1.5em' }}>
              {' '}
              Actions for this Ticket{' '}
            </SectionHeader>
          </div>
        )}

        {hasPolicy && this.getPolicyInfo()}
        {this.props.ticket.get('ticketType') !== 'Custom' && (
          <div
            style={{
              border: `2px solid ${this.props.theme.neutralLight}`,
              padding: '1rem',
            }}
          >
            <TicketEvidence
              ticket={this.props.ticket}
              policy={policy}
              searches={this.props.searches}
              policyEvidenceLegacy={this.props.policyEvidenceLegacy}
              useLegacyPolicyEvidence={this.props.useLegacyPolicyEvidence}
              policyPath={this.props.policyPath}
              advancedSearches={this.props.advancedSearches}
            />
          </div>
        )}
      </div>
    )
  }
}

DetailsTab.propTypes = {
  ticket: ImmutablePropTypes.iterable.isRequired,
  theme: themeShape,
  advancedSearches: ImmutablePropTypes.list,
  policies: ImmutablePropTypes.iterable.isRequired,
  searches: ImmutablePropTypes.map.isRequired,
  showRemediationModal: PropTypes.func,
  controlGroups: ImmutablePropTypes.iterable.isRequired,
  policyEvidenceLegacy: ImmutablePropTypes.map,
  policyPath: ImmutablePropTypes.map,
  useLegacyPolicyEvidence: PropTypes.bool,
}

export default themeable(DetailsTab)
