import { defineMessages } from 'react-intl'

export default defineMessages({
  canAssumeRoles: {
    id: 'app.containers.AccessActivity.canAssumeRoles',
    defaultMessage: 'Can Assume Roles',
  },
  canBeAssumedBy: {
    id: 'app.containers.AccessActivity.canBeAssumedBy',
    defaultMessage: 'Resources that can Access/Assume this Role',
  },
  groupMemberships: {
    id: 'app.containers.AccessActivity.groupMemberships',
    defaultMessage: 'Group Memberships',
  },
  directAttachedPolicies: {
    id: 'app.containers.AccessActivity.directAttachedPolicies',
    defaultMessage: 'RBAC or Direct Attached',
  },
  scps: {
    id: 'app.containers.AccessActivity.scps',
    defaultMessage: 'Global Policies',
  },
  instanceProfiles: {
    id: 'app.containers.Access.instanceProfiles',
    defaultMessage: 'Instance Profiles',
  },
  servicePrincipals: {
    id: 'app.containers.Access.servicePrincipals',
    defaultMessage: 'Service Principals',
  },
})
