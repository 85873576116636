import {
  GET_ESCALATIONS,
  SET_ESCALATIONS,
  SET_ESCALATIONS_ERROR,
  CREATE_ESCALATION,
  CREATE_ESCALATION_SUCCESS,
  CREATE_ESCALATION_ERROR,
  DELETE_ESCALATION,
  DELETE_ESCALATION_SUCCESS,
  DELETE_ESCALATION_ERROR,
  FETCH_ESCALATION_FILTER_OPTIONS,
  FETCH_ESCALATION_FILTER_OPTIONS_SUCCESS,
  FETCH_ESCALATION_FILTER_OPTIONS_ERROR,
  UPDATE_ESCALATION,
  UPDATE_ESCALATION_SUCCESS,
  UPDATE_ESCALATION_ERROR,
  UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR,
  CLEAR_UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR,
} from './constants'
import { createAction } from 'redux-actions'

export const getEscalations = createAction(GET_ESCALATIONS)
export const setEscalations = createAction(SET_ESCALATIONS)
export const setEscalationsError = createAction(SET_ESCALATIONS_ERROR)

export const createEscalation = createAction(CREATE_ESCALATION)
export const createEscalationSuccess = createAction(CREATE_ESCALATION_SUCCESS)
export const createEscalationError = createAction(CREATE_ESCALATION_ERROR)

export const deleteEscalation = createAction(DELETE_ESCALATION)
export const deleteEscalationSuccess = createAction(DELETE_ESCALATION_SUCCESS)
export const deleteEscalationError = createAction(DELETE_ESCALATION_ERROR)

export const fetchEscalationOptions = createAction(
  FETCH_ESCALATION_FILTER_OPTIONS
)
export const fetchEscalationOptionsSuccess = createAction(
  FETCH_ESCALATION_FILTER_OPTIONS_SUCCESS
)
export const fetchEscalationOptionsError = createAction(
  FETCH_ESCALATION_FILTER_OPTIONS_ERROR
)

export const updateEscalation = createAction(UPDATE_ESCALATION)
export const updateEscalationSuccess = createAction(UPDATE_ESCALATION_SUCCESS)
export const updateEscalationError = createAction(UPDATE_ESCALATION_ERROR)
export const updateEscalationSwimlaneCustomTicketError = createAction(
  UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR
)
export const clearUpdateEscalationSwimlaneEditCustomTicketError = createAction(
  CLEAR_UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR
)
