import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import _ from 'lodash'

import permissionChecker from 'containers/PermissionChecker'
import {
  selectSwimlanesBySrn,
  selectSwimlanesLoading,
  selectSonraiUsers,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'
import { selectPolicies } from 'containers/ControlFrameworkData/selectors'
import DataTable from 'components/DataTable'
import { ToolbarItem } from 'components/BulkActionToolbar'
import { closeTickets } from 'containers/TicketDetailsData/actions'
import { selectBulkActionWorking } from 'containers/TicketDetailsData/selectors'
import { usePrevious } from 'utils/hookUtils'

import {
  connectProps,
  AssigneeColumn,
  CreatedByColumn,
  ResourceColumn,
  StatusColumn,
  SwimlanesColumn,
  getDisplayValue,
} from './TicketListColumns'
import TicketDetails from './TicketDetails'
import { formatTicketTitle, formatTicketType } from './utils'
import AssignToUserModal from './AssignToUserModal'
import AcceptRiskModal from './AcceptRiskModal'
import { getPoliciesFromObjectives } from 'utils/sonraiUtils'
import SnoozeModal from './SnoozeModal'
import CommentModal from './CommentModal'
import AddTagsModal from './AddTagsModal'
function getObjective(ticket, objectives) {
  const { ticketType, ticketKey } = ticket
  if (!ticket || ticketType !== 'Policy' || !objectives || !ticketKey) {
    return
  }
  const mappedObjectives = getPoliciesFromObjectives(objectives)
  return _.keys(mappedObjectives).find(key =>
    mappedObjectives[key].includes(ticketKey)
  )
}

function formatRow(policies, objectives, row) {
  return {
    highestAlertSeverity: row.severityNumeric,
    resourceName: getDisplayValue(row.resource),
    resource: row.resource,
    title: formatTicketTitle(policies, row),
    swimlanes: row.swimlaneSRNs,
    status: row.status,
    assignee: row.assignedTo,
    objective: getObjective(row, objectives),
    createdDate: row.createdDate,
    lastModified: row.lastModified,
    firstSeen: row.firstSeen,
    lastSeen: row.lastSeen,
    createdBy: row.createdBy,
    type: formatTicketType(row),
    resourceId: row.resourceId,
    srn: row.srn,
    id: row.id,
    ticket: row,
  }
}

/**
 * Format data for proper display
 */
function formatData(policies, tickets, objectives) {
  const rows = tickets
  return rows.map(row => formatRow(policies, objectives, row))
}

const customColumnConfig = props => {
  const defaultColumnConfig = {
    assignee: {
      cellRendererFramework: connectProps(AssigneeColumn, props),
      getQuickFilterText: params =>
        AssigneeColumn.getQuickFilterText(params, props),
    },
    createdBy: {
      cellRendererFramework: connectProps(CreatedByColumn, props),
    },
    resourceName: {
      cellRendererFramework: connectProps(ResourceColumn, props),
      minWidth: 250,
    },
    resource: {
      hide: true,
    },
    status: {
      minWidth: 150,
      cellStyle: {
        padding: '0 0.4em',
      },
      cellRendererFramework: connectProps(StatusColumn, props),
    },
    swimlanes: {
      cellStyle: {
        padding: '0 0.4em',
      },
      cellRendererFramework: connectProps(SwimlanesColumn, props),
      getQuickFilterText: params =>
        SwimlanesColumn.getQuickFilterText(params, props),
    },
    title: { width: 300 },
    resourceId: {
      hide: true,
    },
    srn: {
      hide: true,
    },
    id: {
      hide: true,
    },
    ticket: {
      hide: true,
    },
  }
  if (props.columnConfig) {
    return {
      ...defaultColumnConfig,
      ...props.columnConfig,
    }
  }
  return defaultColumnConfig
}

function TicketTable(props) {
  const [activeTicketSrn, setActiveTicketSrn] = useState(null)
  const data = formatData(props.policies, props.tickets, props.objectives)
  const [selectedTickets, setSelectedTickets] = useState([])
  const [gridAPI, setGridAPI] = useState(null)
  const [showReassignModal, setShowReassignModal] = useState(false)
  const [showAcceptRiskModal, setShowAcceptRiskModal] = useState(false)
  const [showSnoozeModal, setShowSnoozeModal] = useState(false)
  const [showCommentModal, setShowCommentModal] = useState(false)
  const [showTagModal, setShowTagModal] = useState(false)

  const previousBulkActionWorking = usePrevious(props.bulkActionWorking)

  useEffect(() => {
    if (previousBulkActionWorking && !props.bulkActionWorking) {
      if (gridAPI) {
        gridAPI.deselectAll()
      }

      if (props.refreshData) {
        props.refreshData()
      }
    }
  }, [props.bulkActionWorking])

  const onSingleClickRow = srn => {
    setActiveTicketSrn(srn)
  }

  const getBulkActions = () => {
    const options = [
      <ToolbarItem
        key="Close"
        onClick={() => props.closeTickets(selectedTickets)}
        iconName="check"
        disabled={selectedTickets.length === 0}
      >
        {selectedTickets.length > 0
          ? `Resolve (${selectedTickets.length})`
          : 'Resolve'}
      </ToolbarItem>,
      <ToolbarItem
        key="Assign"
        onClick={() => setShowReassignModal(true)}
        iconName="user"
        disabled={selectedTickets.length === 0}
      >
        {selectedTickets.length > 0
          ? `Reassign (${selectedTickets.length})`
          : 'Reassign'}
      </ToolbarItem>,
      <ToolbarItem
        key="Snooze"
        onClick={() => setShowSnoozeModal(true)}
        iconName="clock"
        disabled={selectedTickets.length === 0}
      >
        {selectedTickets.length > 0
          ? `Snooze (${selectedTickets.length})`
          : 'Snooze'}
      </ToolbarItem>,
      <ToolbarItem
        key="Comment"
        onClick={() => setShowCommentModal(true)}
        iconName="comments"
        disabled={selectedTickets.length === 0}
      >
        {selectedTickets.length > 0
          ? `Comment (${selectedTickets.length})`
          : 'Comment'}
      </ToolbarItem>,
      <ToolbarItem
        key="Tag"
        onClick={() => setShowTagModal(true)}
        iconName="tag"
        disabled={selectedTickets.length === 0}
      >
        {selectedTickets.length > 0
          ? `Tag Resource (${selectedTickets.length})`
          : 'Tag Resource'}
      </ToolbarItem>,
    ]

    if (
      props.userHasPermission({
        permissionName: 'acceptrisk.tickets',
      })
    ) {
      options.push(
        <ToolbarItem
          key="Accept"
          onClick={() => setShowAcceptRiskModal(true)}
          iconName="stamp"
          disabled={selectedTickets.length === 0}
        >
          {selectedTickets.length > 0
            ? `Accept Risk (${selectedTickets.length})`
            : 'Accept Risk'}
        </ToolbarItem>
      )
    }

    return options
  }

  const checkboxRowTest = params => {
    if (!params.data || params.column.rowGroupActive) {
      //Row is grouped
      return false
    }

    const swimlaneSRNs = _.get(params, [
      'data',
      'alertObj',
      'resource',
      'swimlaneSRNs',
    ])

    if (!swimlaneSRNs || swimlaneSRNs.length === 0) {
      return false
    }

    return props.userHasPermission({
      permissionName: 'transition.tickets',
      swimlanes: swimlaneSRNs,
    })
  }

  const onLoad = ({ api }) => {
    setGridAPI(api)
  }

  const handleCheckBoxSelection = event => {
    const rows = event.api.getSelectedRows() || []

    const tickets = rows.map(row => row.srn)

    setSelectedTickets(tickets)
  }

  return (
    <Fragment>
      <DataTable
        data={data}
        crm
        customColumnConfig={customColumnConfig(props)}
        onDoubleClickRow={({ srn }) => {
          props.push({
            pathname: `/App/TicketDetails`,
            search: `srn=${srn}`,
          })
        }}
        onSingleClickNodeView={onSingleClickRow}
        bulkActions={getBulkActions()}
        bulkActionWorking={props.bulkActionWorking}
        checkboxRowTest={checkboxRowTest}
        checkboxSelectedRows={selectedTickets}
        supressSelectAll
        hasCheckBoxes
        selectionChanged={handleCheckBoxSelection}
        onLoad={onLoad}
        {...props}
      />
      {!props.noModal && (
        <TicketDetails
          allTickets={data}
          ticketSrn={activeTicketSrn}
          closeDetails={() => setActiveTicketSrn(null)}
          setActiveTicketSrn={setActiveTicketSrn}
        />
      )}
      {showReassignModal && (
        <AssignToUserModal
          ticketSrns={selectedTickets}
          close={() => setShowReassignModal(false)}
        />
      )}
      {showAcceptRiskModal && (
        <AcceptRiskModal
          ticketSrns={selectedTickets}
          close={() => setShowAcceptRiskModal(false)}
        />
      )}
      {showSnoozeModal && (
        <SnoozeModal
          ticketSrns={selectedTickets}
          close={() => setShowSnoozeModal(false)}
        />
      )}
      {showCommentModal && (
        <CommentModal
          ticketSrns={selectedTickets}
          close={() => setShowCommentModal(false)}
        />
      )}
      {showTagModal && (
        <AddTagsModal
          resourceSrns={data
            .filter(row => selectedTickets.includes(row.srn))
            .map(row => _.get(row, ['resource', 'srn']))}
          close={() => setShowCommentModal(false)}
        />
      )}
    </Fragment>
  )
}

TicketTable.propTypes = {
  bulkActionWorking: PropTypes.bool,
  closeTickets: PropTypes.func.isRequired,
  noModal: PropTypes.bool,
  policies: ImmutablePropTypes.map.isRequired,
  push: PropTypes.func.isRequired,
  refreshData: PropTypes.func,
  swimlanes: ImmutablePropTypes.map.isRequired,
  objectives: ImmutablePropTypes.map.isRequired,
  swimlanesLoading: PropTypes.bool,
  tickets: PropTypes.array,
  users: ImmutablePropTypes.map.isRequired,
  userHasPermission: PropTypes.func.isRequired,
  usersLoading: PropTypes.bool,
  customColumnConfig: PropTypes.object,
  columnConfig: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  bulkActionWorking: selectBulkActionWorking,
  policies: selectPolicies,
  swimlanes: selectSwimlanesBySrn,
  swimlanesLoading: selectSwimlanesLoading,
  users: selectSonraiUsers,
  usersLoading: selectSonraiUsersLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      closeTickets,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, permissionChecker)(TicketTable)
