import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import { exists } from 'utils/sonraiUtils'
import CenterContent from 'components/CenterContent'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { GRAPHQL_TIMEOUT } from 'appConstants'
export class TimeoutLoader extends React.Component {
  state = {
    loadingMessage: null,
    hideLoader: false,
  }

  constructor(props) {
    super(props)

    this.timer = _.delay(this.setFirstMessage, 8000)

    this.styles = {
      loading: {
        margin: '1rem',
        padding: '0rem 25%',
      },
      loadingContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      loadingMessageContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        color: props.theme.neutralDark,
      },
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  setFirstMessage = () => {
    this.setState({
      loadingMessage: this.props.firstMessage,
    })

    this.timer = _.delay(this.setFinalMessage, GRAPHQL_TIMEOUT - 8000)
  }

  setFinalMessage = () => {
    this.setState({
      loadingMessage: this.props.finalMessage,
      hideLoader: true,
    })
  }

  render() {
    const { loadingMessage } = this.state

    if (!exists(loadingMessage)) {
      return (
        <CenterContent style={this.styles.loadingContainer}>
          <SquareLoadingAnimation />
        </CenterContent>
      )
    } else {
      return (
        <CenterContent style={this.styles.loadingContainer}>
          {!this.state.hideLoader && <SquareLoadingAnimation />}
          <div style={this.styles.loading}>
            <div style={this.styles.loadingMessageContainer}>
              {this.state.loadingMessage}
            </div>
          </div>
        </CenterContent>
      )
    }
  }
}

TimeoutLoader.propTypes = {
  firstMessage: PropTypes.node,
  finalMessage: PropTypes.node,
  theme: themeShape,
}

export default themeable(TimeoutLoader)
