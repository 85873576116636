export const CRM_TABLE_MAX_SIZE = 500

export const SET_CRITICAL_RESOURCE_MONITOR_DATE =
  'app/AccessActivity/SET_CRITICAL_RESOURCE_MONITOR_DATE'

export const GET_PROPERTY_WIDGET_DATA =
  'app/AccessActivity/GET_PROPERTY_WIDGET_DATA'

export const SET_PROPERTY_WIDGET_DATA =
  'app/AccessActivity/SET_PROPERTY_WIDGET_DATA'

export const GET_DID_ACCESS_DATA = 'app/AccessActivity/GET_DID_ACCESS_DATA'

export const SET_DID_ACCESS_DATA = 'app/AccessActivity/SET_DID_ACCESS_DATA'

export const GET_CAN_ACCESS_DATA = 'app/AccessActivity/GET_CAN_ACCESS_DATA'

export const SET_CAN_ACCESS_DATA = 'app/AccessActivity/SET_CAN_ACCESS_DATA'

export const GET_ACCESSED_USING_DATA =
  'app/AccessActivity/GET_ACCESSED_USING_DATA'

export const SET_ACCESSED_USING_DATA =
  'app/AccessActivity/SET_ACCESSED_USING_DATA'

export const GET_ACTIVITY_WIDGET_DATA =
  'app/AccessActivity/GET_ACTIVITY_WIDGET_DATA'

export const SET_ACTIVITY_WIDGET_DATA =
  'app/AccessActivity/SET_ACTIVITY_WIDGET_DATA'

export const GET_SELECTED_ROW_ACTIONS =
  'app/AccessActivity/GET_SELECTED_ROW_ACTIONS'
export const SET_SELECTED_ROW_ACTIONS =
  'app/AccessActivity/SET_SELECTED_ROW_ACTIONS'
export const TOGGLE_ACTIONS_MODAL = 'app/AccessActivity/TOGGLE_ACTIONS_MODAL'

export const GET_CAN_ACCESS_PATH = 'app/AccessActivity/GET_CAN_ACCESS_PATH'
export const SET_CAN_ACCESS_PATH = 'app/AccessActivity/SET_CAN_ACCESS_PATH'

export const GET_EP_PATHS = 'app/Access/GET_EP_PATHS'
export const SET_EP_PATHS = 'app/Access/SET_EP_PATHS'
export const ERR_EP_PATHS = 'app/Access/ERR_EP_PATHS'
export const SET_LAST_CAN_ACCESS_JOB_TIMESTAMP =
  'app/Access/SET_LAST_CAN_ACCESS_JOB_TIMESTAMP'
