/*
 *
 * PlatformSettingsData actions
 *
 */

import {
  GET_INTEGRATIONS,
  SET_INTEGRATIONS,
  SET_INTEGRATIONS_ERROR,
  CREATE_INTEGRATION,
  CREATE_INTEGRATION_SUCCESS,
  CREATE_INTEGRATION_ERROR,
  DELETE_INTEGRATION,
  DELETE_INTEGRATION_SUCCESS,
  DELETE_INTEGRATION_ERROR,
  UPDATE_INTEGRATION,
  UPDATE_INTEGRATION_SUCCESS,
  UPDATE_INTEGRATION_ERROR,
  UPDATE_INTEGRATION_CONFIG,
  UPDATE_INTEGRATION_CONFIG_SUCCESS,
  UPDATE_INTEGRATION_CONFIG_ERROR,
  ASSIGN_INTEGRATION,
  ASSIGN_INTEGRATION_SUCCESS,
  ASSIGN_INTEGRATION_ERROR,
  DELETE_INTEGRATION_ASSIGNMENT,
  DELETE_INTEGRATION_ASSIGNMENT_SUCCESS,
  DELETE_INTEGRATION_ASSIGNMENT_ERROR,
} from './constants'
import { createAction } from 'redux-actions'

export const getIntegrations = createAction(GET_INTEGRATIONS)
export const setIntegrations = createAction(SET_INTEGRATIONS)
export const setIntegrationsError = createAction(SET_INTEGRATIONS_ERROR)

export const createIntegration = createAction(CREATE_INTEGRATION)
export const createIntegrationSuccess = createAction(CREATE_INTEGRATION_SUCCESS)
export const createIntegrationError = createAction(CREATE_INTEGRATION_ERROR)

export const deleteIntegration = createAction(DELETE_INTEGRATION)
export const deleteIntegrationSuccess = createAction(DELETE_INTEGRATION_SUCCESS)
export const deleteIntegrationError = createAction(DELETE_INTEGRATION_ERROR)

export const updateIntegration = createAction(UPDATE_INTEGRATION)
export const updateIntegrationSuccess = createAction(UPDATE_INTEGRATION_SUCCESS)
export const updateIntegrationError = createAction(UPDATE_INTEGRATION_ERROR)

export const updateIntegrationConfig = createAction(UPDATE_INTEGRATION_CONFIG)
export const updateIntegrationConfigSuccess = createAction(
  UPDATE_INTEGRATION_CONFIG_SUCCESS
)
export const updateIntegrationConfigError = createAction(
  UPDATE_INTEGRATION_CONFIG_ERROR
)

export const assignIntegration = createAction(ASSIGN_INTEGRATION)
export const assignIntegrationSuccess = createAction(ASSIGN_INTEGRATION_SUCCESS)
export const assignIntegrationError = createAction(ASSIGN_INTEGRATION_ERROR)

export const deleteIntegrationAssignment = createAction(
  DELETE_INTEGRATION_ASSIGNMENT
)
export const deleteIntegrationAssignmentSuccess = createAction(
  DELETE_INTEGRATION_ASSIGNMENT_SUCCESS
)
export const deleteIntegrationAssignmentError = createAction(
  DELETE_INTEGRATION_ASSIGNMENT_ERROR
)
