import { createSelector } from 'reselect'
import { fromJS, Map } from 'immutable'
import _ from 'lodash'
import qs from 'query-string'
import { initialState } from './reducer'

export const selectQueryStringTicketSrn = (state, props) => {
  return qs.parse(_.get(props, ['location', 'search'], '')).srn
}

const selectDomain = state => state.get('ticketDetails') ?? initialState

export const selectAssignToMe = createSelector(selectDomain, state =>
  state.get('assignToMe')
)

export const selectTicketDetails = createSelector(selectDomain, state =>
  state.get('ticketDetails')
)

export const selectTicketComments = createSelector(
  selectDomain,
  state => state.get('ticketComments') || Map()
)

export const selectAddCommentError = createSelector(selectDomain, state =>
  state.get('addCommentError')
)

export const selectSimilarTickets = createSelector(
  selectDomain,
  state => state.get('similarTickets') || Map()
)

export const selectTicketHistory = createSelector(
  selectDomain,
  state => state.get('ticketHistory') || Map()
)

export const selectActivityTicketActions = createSelector(selectDomain, state =>
  state.get('activityTicketActions')
)

export const selectAcceptRiskTicketStatus = createSelector(
  selectDomain,
  state => state.get('acceptRiskTicketStatus')
)

export const selectCloseTicketStatus = createSelector(selectDomain, state =>
  state.get('closeTicketStatus')
)

export const selectSnoozeTicketStatus = createSelector(selectDomain, state =>
  state.get('snoozeTicketStatus')
)

export const selectReassignToUserStatus = createSelector(selectDomain, state =>
  state.get('reassignToUserStatus')
)

export const selectReopenTicketStatus = createSelector(selectDomain, state =>
  state.get('reopenTicketStatus')
)

export const selectPolicyEvidenceLegacy = createSelector(selectDomain, state =>
  state.get('policyEvidenceLegacy')
)

export const selectPolicyPath = createSelector(selectDomain, state =>
  state.get('policyPath')
)

export const selectBulkActionWorking = createSelector(selectDomain, state =>
  state.get('bulkActionWorking')
)

/**
 * get whether or not any action is currnetly loading/error
 */
export const selectActionStatus = createSelector(
  [
    selectAssignToMe,
    selectAcceptRiskTicketStatus,
    selectCloseTicketStatus,
    selectSnoozeTicketStatus,
    selectReopenTicketStatus,
    selectReassignToUserStatus,
  ],
  (...actions) =>
    fromJS({
      loading: actions.reduce(
        (loading, action) => loading || action.get('loading'),
        false
      ),
      error: actions.reduce(
        (error, action) => error || action.get('error'),
        false
      ),
    })
)
