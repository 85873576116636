/**
 *
 * MissingDataNode
 *
 */

import React from 'react'
import crash from 'assets/iamghost.png'
import { FormattedMessage } from 'react-intl'

import { SUPPORT_EMAIL } from 'appConstants'

import messages from './messages'

const styles = {
  failContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90vh',
  },
  crashImg: {
    width: '30%',
    maxWidth: '90px',
    opacity: 0.3,
  },
  crashText: {
    fontSize: 20,
    fontWeight: '400',
    color: '#676767',
    marginTop: '30px',
  },
}

export class MissingDataNode extends React.Component {
  render() {
    return (
      <div style={styles.failContainer}>
        <img style={styles.crashImg} src={crash} />
        <p style={styles.crashText}>
          <FormattedMessage {...messages.error} />
        </p>
        <p>
          <FormattedMessage {...messages.contact} />{' '}
          <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </p>
      </div>
    )
  }
}
export default MissingDataNode
