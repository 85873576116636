/**
 *
 * NodeSolutionCenter
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import _ from 'lodash'
import { getNameForHelmet } from 'utils/sonraiUtils'
import { Helmet } from 'react-helmet'
import MissingDataNode from 'components/MissingDataNode'
import { NODE_VIEW_TYPES } from 'appConstants'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import AccountNodeViewCardLayout from 'containers/NodeViewLayouts/AccountNodeViewCardLayout'
import UserNodeViewCardLayout from 'containers/NodeViewLayouts/UserNodeViewCardLayout'
import DataNodeViewCardLayout from 'containers/NodeViewLayouts/DataNodeViewCardLayout'
import DataContainerNodeViewCardLayout from 'containers/NodeViewLayouts/DataContainerNodeViewCardLayout'
import DataStoreNodeViewCardLayout from 'containers/NodeViewLayouts/DataStoreNodeViewCardLayout'
import PublicKeyNodeViewCardLayout from 'containers/NodeViewLayouts/PublicKeyNodeViewCardLayout'
import RoleNodeViewCardLayout from 'containers/NodeViewLayouts/RoleNodeViewCardLayout'
import PolicyNodeViewCardLayout from 'containers/NodeViewLayouts/PolicyNodeViewCardLayout'
import ImageNodeViewCardLayout from 'containers/NodeViewLayouts/ImageNodeViewCardLayout'
import ComputeNodeViewCardLayout from 'containers/NodeViewLayouts/ComputeNodeViewCardLayout'
import GenericNodeViewCardLayout from 'containers/NodeViewLayouts/GenericNodeViewCardLayout'
import ActionTypeNodeViewCardLayout from 'containers/NodeViewLayouts/ActionTypeNodeViewCardLayout'
import ActionNodeViewCardLayout from 'containers/NodeViewLayouts/ActionNodeViewCardLayout'
import IdentityNodeViewCardLayout from 'containers/NodeViewLayouts/IdentityNodeViewCardLayout'
import GroupNodeViewCardLayout from 'containers/NodeViewLayouts/GroupNodeViewCardLayout'
import AuditNodeViewCardLayout from 'containers/NodeViewLayouts/AuditNodeViewCardLayout'
import SecretStoreNodeViewCardLayout from 'containers/NodeViewLayouts/SecretStoreNodeViewCardLayout'
import SecretNodeViewCardLayout from 'containers/NodeViewLayouts/SecretNodeViewCardLayout'
import EncryptionKeyNodeViewCardLayout from 'containers/NodeViewLayouts/EncryptionKeyNodeViewCardLayout'

import K8sClusterNodeViewCardLayout from 'containers/NodeViewLayouts/K8sClusterNodeViewCardLayout/loadable'
import K8sNamespaceNodeViewCardLayout from 'containers/NodeViewLayouts/K8sNamespaceNodeViewCardLayout/loadable'
import K8sWorkloadNodeViewCardLayout from 'containers/NodeViewLayouts/K8sWorkloadNodeViewCardLayout/loadable'

import LoadingAnim from 'components/LoadingAnim'
import { exists } from 'utils/sonraiUtils'
import { getNodeInfoQuery, getTypeFromSrn } from 'utils/graphDataUtils'
import {
  selectQueryNames,
  selectQueryTypes,
} from 'containers/SonraiData/selectors'

import { fetchTags } from 'containers/SonraiData/actions'

import {
  selectData,
  selectTagOptions,
  selectNodeId,
  selectNodeData,
  selectDetailsLoading,
  selectIsMonitored,
  selectShouldUpdateNodeSwimlanes,
} from './selectors'
import {
  getData,
  setShouldUpdateNodeSwimlanes,
  getTicketCount,
  getCrmTickets,
} from './actions'
import reducer from './reducers'
import sagas from './sagas'
import { TYPES_WITH_CRM } from 'appConstants'
import { fetchPolicies } from 'containers/ControlFrameworkData/actions'
import { selectPolicies } from 'containers/ControlFrameworkData/selectors'

export class NodeSolutionCenter extends React.Component {
  constructor(props) {
    super(props)

    if (this.props.tagOptions.isEmpty()) {
      this.props.fetchTags()
    }

    if (this.props.policies.isEmpty()) {
      this.props.fetchPolicies()
    }

    this.fetchData()

    this.styles = {
      wrapper: {
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        gridTemplateAreas: '"header""tabs""body"',
        overflow: 'auto',
      },
    }
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.nodeId !== this.props.nodeId ||
      (oldProps.queryNames.isEmpty() && !this.props.queryNames.isEmpty())
    ) {
      this.fetchData()
    }
  }

  fetchData = () => {
    if (!this.props.queryNames.isEmpty()) {
      const query = getNodeInfoQuery(
        this.props.nodeId,
        this.props.queryNames,
        this.props.types
      )

      this.props.getTicketCount(this.props.nodeId)
      this.props.getCrmTickets(this.props.nodeId)

      this.props.getData({
        query: query,
        variables: {
          srn: this.props.nodeId,
        },
      })
    }
  }

  getNodeType = () => {
    return this.props.type || getTypeFromSrn(this.props.nodeId)
  }

  getNodeCardClass = () => {
    const type = this.getNodeType().toLowerCase()
    switch (type) {
      case NODE_VIEW_TYPES.ACCOUNT:
        if ('K8SCluster' === this.props.nodeData.type) {
          return K8sClusterNodeViewCardLayout
        }
        return AccountNodeViewCardLayout
      case NODE_VIEW_TYPES.ACTION:
        return ActionNodeViewCardLayout
      case NODE_VIEW_TYPES.USER:
        return UserNodeViewCardLayout
      case NODE_VIEW_TYPES.DATA:
      case NODE_VIEW_TYPES.DATA_OBJECT:
        return DataNodeViewCardLayout
      case NODE_VIEW_TYPES.DATA_STORE:
        return DataStoreNodeViewCardLayout
      case NODE_VIEW_TYPES.CONTAINER:
        return DataContainerNodeViewCardLayout
      case NODE_VIEW_TYPES.PUBLIC_KEY:
        return PublicKeyNodeViewCardLayout
      case NODE_VIEW_TYPES.POLICY:
        return PolicyNodeViewCardLayout
      case NODE_VIEW_TYPES.IMAGE:
        return ImageNodeViewCardLayout
      case NODE_VIEW_TYPES.COMPUTE:
        return ComputeNodeViewCardLayout
      case NODE_VIEW_TYPES.ACTION_TYPE:
        return ActionTypeNodeViewCardLayout
      case NODE_VIEW_TYPES.IDENTITY:
        return IdentityNodeViewCardLayout
      case NODE_VIEW_TYPES.GROUP:
        return GroupNodeViewCardLayout
      case NODE_VIEW_TYPES.AUDIT:
        return AuditNodeViewCardLayout
      case NODE_VIEW_TYPES.RESOURCE_GROUP:
        if ('kubernetes' === this.props.nodeData.cloudType) {
          return K8sNamespaceNodeViewCardLayout
        }
        return GenericNodeViewCardLayout
      case NODE_VIEW_TYPES.ROLE:
        return RoleNodeViewCardLayout
      case NODE_VIEW_TYPES.SECRET_STORE:
        return SecretStoreNodeViewCardLayout
      case NODE_VIEW_TYPES.SECRET:
        return SecretNodeViewCardLayout
      case NODE_VIEW_TYPES.ENCRYPTION_KEY:
        return EncryptionKeyNodeViewCardLayout
      case NODE_VIEW_TYPES.WORKLOAD:
        if ('kubernetes' === this.props.nodeData.cloudType) {
          return K8sWorkloadNodeViewCardLayout
        }
        return GenericNodeViewCardLayout
      default:
        return GenericNodeViewCardLayout
    }
  }

  render() {
    if (this.props.shouldReloadSwimlanes) {
      this.props.setShouldUpdateNodeSwimlanes(false)
    }
    const hasCRM = TYPES_WITH_CRM.includes(
      getTypeFromSrn(this.props.nodeId).toLowerCase()
    )

    if (
      this.props.detailsLoading ||
      (hasCRM && !exists(this.props.isMonitored))
    ) {
      return <LoadingAnim />
    }

    if (_.isEmpty(this.props.nodeData)) {
      return <MissingDataNode />
    }

    const Component = this.getNodeCardClass()

    const name = getNameForHelmet(this.props.nodeData)
    return (
      <div key={this.props.nodeId} style={this.styles.wrapper}>
        <Component
          nodeId={this.props.nodeId}
          nodeData={this.props.nodeData}
          location={this.props.location}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
        />
        <Helmet title={`Sonrai - ${name}`} />
      </div>
    )
  }
}

NodeSolutionCenter.propTypes = {
  detailsLoading: PropTypes.bool,
  fetchTags: PropTypes.func,
  getData: PropTypes.func,
  horizontal: PropTypes.bool,
  //Used in the selectors!!
  //eslint-disable-next-line react/no-unused-prop-types
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  nodeId: PropTypes.string,
  nodeData: PropTypes.object,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
  queryNames: ImmutablePropTypes.iterable.isRequired,
  type: PropTypes.string,
  types: ImmutablePropTypes.map,
  tagOptions: ImmutablePropTypes.iterable.isRequired,
  isMonitored: PropTypes.bool,
  shouldReloadSwimlanes: PropTypes.bool,
  setShouldUpdateNodeSwimlanes: PropTypes.func.isRequired,
  policies: ImmutablePropTypes.iterable,
  fetchPolicies: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  data: selectData,
  detailsLoading: selectDetailsLoading,
  queryNames: selectQueryNames,
  types: selectQueryTypes,
  tagOptions: selectTagOptions,
  nodeId: selectNodeId,
  nodeData: selectNodeData,
  isMonitored: selectIsMonitored,
  shouldReloadSwimlanes: selectShouldUpdateNodeSwimlanes,
  policies: selectPolicies,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getData,
      fetchTags,
      fetchPolicies,
      setShouldUpdateNodeSwimlanes,
      getTicketCount,
      getCrmTickets,
    },
    dispatch
  )
}

const withReducer = injectReducer({ key: 'nodeSolutionCenter', reducer })

const withSaga = injectSaga({
  key: 'nodeSolutionCenter',
  saga: sagas,
  mode: DAEMON,
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, withSaga, withReducer)(NodeSolutionCenter)
