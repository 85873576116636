import { createSelector } from 'reselect'
import { Map, List } from 'immutable'
import qs from 'query-string'
import { selectSonraiSearches } from 'containers/SonraiData/selectors'
import {
  selectControlGroups,
  selectPolicies,
} from 'containers/ControlFrameworkData/selectors'

const selectControlGroupDomain = state => state.get('controlGroup') || Map()
const selectContentLibraryDomain = state =>
  state.get('cfContentLibrary') || Map()

export const selectSaving = createSelector(selectControlGroupDomain, state =>
  state.get('saving')
)

export const selectCloning = createSelector(selectControlGroupDomain, state =>
  state.get('cloning')
)

export const selectControlGroupId = (state, props) => {
  if (props && props.location && props.location.search) {
    return qs.parse(props.location.search).controlGroupId
  } else {
    return null
  }
}

export const selectActiveFramework = createSelector(
  selectContentLibraryDomain,
  state => state.get('activeFramework')
)

export const selectControlGroup = createSelector(
  [selectControlGroups, selectControlGroupId, selectActiveFramework],
  (controlGroups, id, activeFramework) => {
    if (activeFramework) {
      return controlGroups.get(activeFramework, Map())
    } else {
      return controlGroups.get(id, Map())
    }
  }
)

export const selectIndexedSonraiSearches = createSelector(
  selectSonraiSearches,
  sonraiSearchList => {
    return Map(sonraiSearchList.map(search => [search.get('name'), search]))
  }
)

export const selectPoliciesForControlGroup = createSelector(
  [selectControlGroup, selectPolicies],
  (controlGroup, allPolicies) => {
    const policyList = controlGroup
      .getIn(['contains', 'items'], List())
      .map(policy => allPolicies.get(policy.get('srn'), Map()))
      .filterNot(policy => policy.isEmpty())

    return Map(policyList.map(policy => [policy.get('srn'), policy]))
  }
)
