import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import TextLink from 'components/TextLink'
import DataTable from 'components/DataTable'
import { TopTitle, CardBody } from 'components/Card'
import Card from 'components/Card'
import WidgetPopup from 'components/WidgetPopup'
import { getAccountFromSrn, getNodeViewPushParams } from 'utils/sonraiUtils'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { exists } from 'utils/sonraiUtils'
import { CRM_TABLE_MAX_SIZE } from 'containers/AccessActivity/constants'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import { getTypeFromSrn } from 'utils/graphDataUtils'

class CanAccessWidget extends Component {
  styles = {
    title: {
      fontSize: '22px',
      fontWeight: '300',
      marginRight: '0px',
    },
    card: {
      minHeight: 0,
      display: 'grid',
      gridTemplateRows: '1fr',
      height: 'auto',
      overflow: 'hidden',
      justifyContent: 'inherit',
      alignItems: 'inherit',
    },
  }

  state = {
    showPathId: null,
    showPathClassification: null,
  }

  resourceOfResource = alertLog => {
    const { resourceSetFull } = alertLog
    if (!resourceSetFull) {
      // eslint-disable-next-line no-console
      console.warn(
        `alertLog ${JSON.stringify(alertLog)} has no resourceSetFull}`
      )
      return undefined
    }

    if (resourceSetFull.length == 0) {
      // eslint-disable-next-line no-console
      console.warn(
        `alertLog ${JSON.stringify(alertLog)} has empty resourceSetFull}`
      )
      return undefined
    }

    const resource = resourceSetFull[0]
    return resource
  }

  importanceOfResource = alertLog => {
    const resource = this.resourceOfResource(alertLog)
    if (!resource) {
      return ''
    }

    if (resource.importance) {
      return resource.importance
    }

    const swimlanes = _.get(resource, ['swimlane', 'items'])
    if (!swimlanes) {
      return ''
    }

    const maxImportance = swimlanes.reduce((max, current) => {
      return Math.max(current.defaultImportance, max)
    }, -1)
    return maxImportance > 0 ? maxImportance : ''
  }

  tagsOfResource = alertLog => {
    const resource = this.resourceOfResource(alertLog)
    if (!resource) {
      return ''
    }

    if (resource.tagSet) {
      return resource.tagSet.join(', ')
    } else {
      return ''
    }
  }

  swimlanesOfResource = alertLog => {
    const resource = this.resourceOfResource(alertLog)
    const swimlanes = _.get(resource, ['swimlane', 'items'])
    if (!swimlanes) {
      return ''
    }

    return swimlanes.map(swimlane => swimlane.title).join(', ')
  }

  getData = canAccessData => {
    if (
      exists(canAccessData) &&
      exists(canAccessData.data) &&
      !_.isEmpty(canAccessData.data)
    ) {
      if (true === _.get(canAccessData, ['data', 0, 'isSpecialAzureData'])) {
        return canAccessData.data.map(row => {
          return _.pick(row, [
            'isSpecialAzureData',
            'who',
            'How',
            'account',
            'Service',
            'Conditions',
            'Swimlanes',
            'Importance',
            'Tags',
            'action',
            'srn',
          ])
        })
      }
      const resources = canAccessData.data.map(resource => ({
        ...resource,
        ...resource.resource,
      }))
      let rows = resources.map(resource => {
        if (resource) {
          const row = {
            [this.props.actionableByMode
              ? 'who'
              : 'what']: resource.resourceSetFull
              ? resource.resourceSetFull
                  .map(
                    resource =>
                      resource.friendlyName ||
                      resource.name ||
                      _.last(resource.srn.split('/'))
                  )
                  .join(', ')
              : resource.resourceSet &&
                resource.resourceSet
                  .map(resource => _.last(resource.split('/')))
                  .join(', '),
            srn: resource.resourceSetFull
              ? resource.resourceSetFull
                  .map(resource => resource.srn)
                  .join(', ')
              : resource.resourceSet &&
                resource.resourceSet.map(resource => resource.srn).join(', '),
            how: resource.actionClassification,
            action: resource.resourceSet,
            highestAlertSeverity: resource.highestAlertSeverity,
            account: resource.resourceSetFull
              ? resource.resourceSetFull.map(y => y.account).join(', ')
              : resource.resourceSet &&
                resource.resourceSet.map(y => getAccountFromSrn(y)).join(', '),
            service:
              resource.resourceSet &&
              resource.resourceSet.map(x => x.split(':')[2]).join(', '),
            conditions: resource.conditions,
            swimlanes: this.swimlanesOfResource(resource),
            importance: this.importanceOfResource(resource),
            tags: this.tagsOfResource(resource),
          }
          return row
        }
      })

      rows = _.uniqBy(rows, row => {
        return `${this.props.actionableByMode ? row.who : row.what}${row.how}${
          row.account
        }${row.service}${row.conditions}`
      })

      rows = _.sortBy(rows, row =>
        this.props.actionableByMode ? row.who : row.what
      )

      return rows
    }

    return []
  }

  showPath = (resourceIds, actionClassification, conditions) => {
    //For now, assume only one resourceID per line
    this.props.onShowPath(resourceIds[0], actionClassification, conditions)
  }

  onShowPathFromList = (...args) => {
    this.props.onShowPathFromList(...args)
  }

  getHeader = count => {
    const { isLocked, title } = this.props

    const keyName = this.props.actionableByMode
      ? 'actionableBy'
      : 'hasPermissionTo'

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={this.styles.title}>
            {title}{' '}
            {count !== 0 && (
              <span
                style={{
                  color: '#888',
                }}
              >
                (
                {count >= CRM_TABLE_MAX_SIZE ? `${CRM_TABLE_MAX_SIZE}+` : count}
                )
              </span>
            )}
          </span>
          <WidgetPopup
            isLocked={isLocked}
            data={{
              keyName,
              keyType: 'PATH',
            }}
            title={this.props.title}
            resourceSrn={this.props.srn}
            crmTickets={this.props.crmTickets}
            keyName={keyName}
          />
        </div>
      </div>
    )
  }

  getContent = () => {
    const canAccessData = this.getData(this.props.canAccessData)

    const { isLoading, isMonitored } = this.props
    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    }

    if (!isMonitored && !this.props.usesEP) {
      return this.props.renderCRMOverlay()
    } else {
      return (
        <DataTable
          onRowCountChange={this.props.recalculateGrid}
          style={{
            height: canAccessData.length > 0 ? '100%' : '60px',
          }}
          onDoubleClickRow={this.handleRowDoubleClick}
          sortColumns={true}
          sortColumnByType="CanAccess"
          crm
          data={canAccessData}
          customColumnConfig={{
            isSpecialAzureData: {
              hide: true,
            },
            srn: {
              hide: true,
            },
            resourceSet: {
              valueFormatter: params => {
                return params.value.join(', ')
              },
              comparator: (valueA, valueB) => {
                //Assume only one result in resourceSet
                const first = _.get(valueA, 0) || ''
                const second = _.get(valueB, 0) || ''
                return first.localeCompare(second)
              },
            },
            action: {
              width: 120,
              minWidth: 120,
              pinned: 'right',
              headerName: '',
              menuTabs: [],
              suppressMenu: true,
              cellRendererFramework: params => {
                if (!params.data) {
                  return '' //It's a grouped row
                }

                return (
                  <TextLink
                    color="primary"
                    style={{ color: this.props.theme.primary }}
                    onClick={() => {
                      if (params.data.isSpecialAzureData) {
                        this.onShowPathFromList({
                          item: {
                            srn: params.data.srn,
                            path: JSON.stringify(params.value),
                            conditions: params.data.conditions,
                          },
                          showPathClassification: params.data.how,
                        })
                      } else {
                        this.showPath(
                          params.value,
                          params.data.how,
                          params.data.conditions
                        )
                      }
                    }}
                  >
                    Show Path
                  </TextLink>
                )
              },
            },
          }}
        />
      )
    }
  }

  handleRowDoubleClick = params => {
    let nav = getNodeViewPushParams(params.srn, getTypeFromSrn(params.srn))
    this.props.push(nav)
  }

  render() {
    const canAccessData = this.getData(this.props.canAccessData)

    return (
      <Card title={this.props.title}>
        <TopTitle>{this.getHeader(canAccessData.length)}</TopTitle>
        <CardBody style={this.styles.card}>{this.getContent()}</CardBody>
      </Card>
    )
  }
}

CanAccessWidget.defaultProps = {
  // eslint-disable-next-line react/display-name
  renderCRMOverlay: () => {
    return (
      <div>
        <div
          style={{
            textAlign: 'center',
            fontSize: '0.9rem',
            marginBottom: '0.5rem',
          }}
        >
          Results from this widget are only available if the resource is
          monitored.
        </div>
      </div>
    )
  },
}

CanAccessWidget.propTypes = {
  actionableByMode: PropTypes.bool,
  canAccessData: PropTypes.object,
  crmTickets: ImmutablePropTypes.list.isRequired,
  isLoading: PropTypes.bool,
  isLocked: PropTypes.bool,
  isMonitored: PropTypes.bool,
  onShowPath: PropTypes.func.isRequired,
  onShowPathFromList: PropTypes.func,
  push: PropTypes.func,
  recalculateGrid: PropTypes.func,
  renderCRMOverlay: PropTypes.func,
  srn: PropTypes.string,
  theme: themeShape,
  title: PropTypes.string,
  usesEP: PropTypes.bool,
}

export default themeable(CanAccessWidget)
