/*
 *
 * ThemeManager reducer
 *
 */

import { fromJS } from 'immutable'
import { LOAD_THEME, SET_TYPE_COLORS } from './constants'
import { handleActions } from 'redux-actions'
import defaultTheme from 'assets/base-theme'

const initialState = fromJS({
  theme: defaultTheme,
  typeColors: {},
})

const themeManagerReducer = handleActions(
  {
    [LOAD_THEME]: (state, { payload }) => state.set('theme', fromJS(payload)),
    [SET_TYPE_COLORS]: (state, { payload }) =>
      state.set('typeColors', fromJS(payload)),
  },
  initialState
)

export default themeManagerReducer
