/**
 *
 * Logo
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import SmallLogo from 'assets/SmallIconWhite.png'

class Logo extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  styles = {
    logo: {
      width: '50px',
      height: '50px',
      display: 'inline',
      position: 'absolute',
    },
  }
  render() {
    const { style, ...rest } = this.props
    return (
      <img
        className="slide-fwd-center"
        style={style || this.styles.logo}
        src={SmallLogo}
        alt="Sonrai Security"
        {...rest}
      />
    )
  }
}

Logo.propTypes = {
  style: PropTypes.object,
}

export default Logo
