import {
  GET_SECRET_STORE_CONTENT,
  SET_SECRET_STORE_CONTENT,
  GET_VAULT_STORE_CONTENT,
} from './constants'
import { createAction } from 'redux-actions'

export const getSecretStoreContent = createAction(GET_SECRET_STORE_CONTENT)
export const setSecretStoreContent = createAction(SET_SECRET_STORE_CONTENT)
export const getVaultStoreContent = createAction(GET_VAULT_STORE_CONTENT)
