import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import qs from 'querystring'
import { push } from 'connected-react-router'
import { selectEscalations } from 'containers/EscalationData/selectors'
import { getEscalations } from 'containers/EscalationData/actions'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import injectReducer from 'utils/injectReducer'
import {
  selectSonraiUsers,
  selectSwimlanes,
} from 'containers/SonraiData/selectors'
import { selectSonraiUserSrn } from 'containers/UserProfileData/selectors'
import { List, Map } from 'immutable'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer, { fieldSelectInitialState } from './reducers'
import sagas from './sagas'

import { fetchTicketDetails } from 'containers/TicketDetailsData/actions'
import {
  fetchTicketTemplates,
  setTicketType,
  setSelectedTicketTemplate,
  clearState,
  fetchTicketTemplateFieldSelect,
  setTicketTemplateFieldValue,
  saveCustomTicket,
  updateCustomTicket,
} from './actions'

import { selectAccounts } from 'containers/SonraiData/selectors'
import {
  selectTicketType,
  selectSelectedTicketTemplate,
  selectTicketTemplates,
  selectTicketTemplateFields,
  selectCreateTicketStatus,
} from './selectors'

import Select from 'react-select'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import permissionChecker from 'containers/PermissionChecker'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import DefaultFields from './DefaultFields'
import TicketTemplateForm from './TicketTemplateForm'

import messages from './messages'

function SelectTicketType(props) {
  const ticketTemplates = (props.ticketTemplates.get('data') ?? List())
    .toJS()
    .map(ticketTemplate => {
      return {
        value: ticketTemplate,
        label: ticketTemplate.title,
      }
    })
  const styles = {
    container: {
      display: 'grid',
      gridTemplateRows: '1fr 1fr',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0rem 0rem 0.5rem 0rem',
    },
  }
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <TextLink color="primary" to="/App/TicketTemplates">
          Manage Ticket Templates
        </TextLink>
        <TextLink
          color="primary"
          onClick={() => handleTicketType(props, 'CUSTOM')}
        >
          {' '}
          No Template{' '}
        </TextLink>
      </div>
      <Select
        placeholder={'Select Template...'}
        options={ticketTemplates}
        onChange={props.handleTicketTemplateChange}
        value={props.ticketTemplate}
      />
    </div>
  )
}

SelectTicketType.propTypes = {
  handleTicketTemplateChange: PropTypes.func.isRequired,
  ticketTemplate: PropTypes.object,
  ticketTemplates: ImmutablePropTypes.contains({
    error: PropTypes.bool,
    loading: PropTypes.bool,
    data: ImmutablePropTypes.iterable,
  }),
}

function handleTicketType(props, type, template) {
  if (template) {
    props.setSelectedTicketTemplate(template.value)
  }
  props.setTicketType(type)
}

function TicketBody(props) {
  switch (props.ticketType) {
    case 'CUSTOM':
      return <DefaultFields {...props} />
    case 'TEMPLATE':
      return <TicketTemplateForm {...props} />
    default:
      return <SelectTicketType {...props} />
  }
}

TicketBody.propTypes = {
  ticketType: PropTypes.string,
}

function getModalTitle(props) {
  const update = isUpdateMode(props)
  if (!update && props.resource) {
    const {
      resource: { name, friendlyName, srn },
    } = props
    const display = friendlyName || name || srn
    return `Open a ticket for ${display}`
  }
  return update ? 'Edit Ticket' : 'Create Ticket'
}

function isUpdateMode(props) {
  return props.ticket !== undefined
}

function handleSelfAssign(props, assign) {
  const self = {
    label: props.users.getIn([props.currentUser, 'name']),
    value: props.currentUser,
  }
  assign(self)
}

function getInitialSwimlaneState(props) {
  if (props.resource) {
    const { swimlanes } = props
    return (props.resource.swimlaneSRNs ?? [])
      .filter(swimlaneSRN => {
        return props.userHasPermission({
          permissionName: 'edit.tickets',
          swimlanes: [swimlaneSRN],
        })
      })
      .map(item => {
        const swimlane =
          swimlanes.find(swimlane => swimlane.get('srn') === item) || Map()
        return {
          label: swimlane.get('title') || swimlane.get('srn'),
          value: swimlane.get('srn'),
        }
      })
  } else {
    return null
  }
}
export function CreateUpdateTicketModal(props) {
  const styles = {
    title: {
      fontWeight: '400',
      fontSize: '1.1rem',
      maxWidth: '50vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }
  const update = isUpdateMode(props)
  const modalTitle = getModalTitle(props)

  // selectTicketType
  const [ticketTemplate, handleTicketTemplateChange] = useState()

  // default fields
  const [title, handleTitleChange] = useState()
  const [description, handleDescriptionChange] = useState()
  const [selectedSwimlanes, handleSwimlaneChange] = useState(
    getInitialSwimlaneState(props)
  )
  const [severity, handleSeverityChange] = useState()
  const [assignedTo, handleAssignedToChange] = useState()
  const [escalation, handleEscalationChange] = useState()
  const [account, handleAccountChange] = useState()

  const [formErrors, setFormErrors] = useState({})

  function cleanup() {
    props.clearState()
    // reset initial form state
    handleTitleChange(undefined)
    handleDescriptionChange(undefined)
    handleSwimlaneChange(getInitialSwimlaneState(props))
    handleSeverityChange(undefined)
    handleAssignedToChange(undefined)
    handleEscalationChange(undefined)
    setFormErrors({})
    handleTicketType(props, null)
  }

  function handleClose(props) {
    props.toggle()
    cleanup()
  }

  useEffect(() => {
    if (props.escalations.isEmpty()) {
      props.getEscalations()
    }
    props.fetchTicketTemplates()
  }, [])

  // this hook sets all the default form values if in update mode
  useEffect(() => {
    // if it has props.ticket, assume we're updating an existing ticket
    if (props.ticket) {
      handleTitleChange(props.ticket.title)
      handleDescriptionChange(props.ticket.description)
      handleSwimlaneChange(
        (props.ticket.swimlaneSRNs ?? []).map(swimlaneSRN => {
          const { swimlanes } = props
          const swimlane =
            swimlanes.find(swimlane => swimlane.get('srn') === swimlaneSRN) ||
            Map()
          return {
            label: swimlane.get('title') || swimlane.get('srn'),
            value: swimlane.get('srn'),
          }
        })
      )
      handleSeverityChange({
        label: _.capitalize(
          (props.ticket.severityCategory ?? '').toLowerCase()
        ),
        value: props.ticket.severityCategory,
      })
      if (props.ticket.assignedTo) {
        handleAssignedToChange({
          value: props.ticket.assignedTo,
        })
      }

      if (!props.escalations.isEmpty()) {
        const escalationScheme = props.escalations
          .valueSeq()
          .find(escalation => {
            return (
              undefined !==
              (escalation.get('filters') ?? List()).find(filter => {
                return filter.get('ticketKey') === props.ticket.srn
              })
            )
          })
        if (escalationScheme !== undefined) {
          handleEscalationChange({
            label: escalationScheme.get('title'),
            value: escalationScheme.get('srn'),
          })
        }
      }

      for (let field of props.ticket.customFields ?? []) {
        let value = field.value
        if (field.type === 'CHECKBOX') {
          if (value === 'true') value = true
          if (value === 'fase') value = false
        }
        props.setTicketTemplateFieldValue({ field, value })
      }

      if (!props.accounts.isEmpty()) {
        const account = props.accounts.find(account => {
          return account.get('srn') === props.ticket.account
        })
        if (account) {
          if (account) {
            handleAccountChange({
              label:
                account.get('friendlyName') ||
                account.get('name') ||
                account.get('account'),
              value: account.get('srn'),
            })
          }
        } else {
          // eslint-disable-next-line
          console.warn('could not set account for ticket');
        }
      }
    }
    return () => {
      cleanup()
    }
  }, [props.isOpen, props.accounts, props.escalations])

  // this hook sets the ticket template if in update mode
  useEffect(() => {
    if (
      props.selectedTicketTemplate === null &&
      props.ticket &&
      props.ticketTemplates.get('data')
    ) {
      if (props.ticket.templateSRN) {
        const selectedTemplate = props.ticketTemplates
          .get('data')
          .find(template => template.get('srn') === props.ticket.templateSRN)
        if (selectedTemplate) {
          props.setSelectedTicketTemplate(selectedTemplate.toJS())
        }
        handleTicketType(props, 'TEMPLATE')
      } else {
        handleTicketType(props, 'CUSTOM')
      }
    }
  }, [props.ticketTemplates, props.isOpen])

  // this function handle when the user click save
  const onClickCreate = () => {
    const formErrors = {}

    // validate required default fields
    if (!title) {
      formErrors['title'] = {
        key: 'error.required',
        values: { field: 'Title' },
      }
    }
    if (!selectedSwimlanes) {
      formErrors['swimlanes'] = {
        key: 'error.required',
        values: { field: 'Swimlanes' },
      }
    }
    if (!severity) {
      formErrors['severity'] = {
        key: 'error.required',
        values: { field: 'Severity' },
      }
    }

    if (!props.resource) {
      if (!account) {
        formErrors['account'] = {
          key: 'error.required',
          values: { field: 'Account' },
        }
      }
    }

    // validate template fields
    if (props.selectedTicketTemplate !== null) {
      ;(props.selectedTicketTemplate.fields || [])
        .filter(field => field.required)
        .forEach(field => {
          const value = (
            props.ticketTemplateFields.get(field.name) ||
            fieldSelectInitialState
          ).get('value')
          switch (field.type) {
            case 'checkbox':
              if (true !== value && false !== value) {
                formErrors[field.name] = {
                  key: 'error.required',
                  values: { field: field.name },
                }
              }
              break
            case 'text':
            case 'select':
              if (_.isEmpty(value) || _.isNull(value) || _.isUndefined(value)) {
                formErrors[field.name] = {
                  key: 'error.required',
                  values: { field: field.name },
                }
              }
          }
        })
    }

    setFormErrors(formErrors)

    // reduce field definitions + values to format expected in action paylaod
    const customFields = (
      (props.selectedTicketTemplate || {}).fields || []
    ).reduce((customFields, fieldDefinition) => {
      let value = (
        props.ticketTemplateFields.get(fieldDefinition.name) ||
        fieldSelectInitialState
      ).get('value')

      // the graphql endpoint expects a string
      if (_.isBoolean(value)) {
        value = `${value}`
      }

      const customField = {
        value,
        type: fieldDefinition.type.toUpperCase(),
        isMulti: fieldDefinition.multi,
        name: fieldDefinition.name,
        isRequired: fieldDefinition.required,
      }
      return [...customFields, customField]
    }, [])

    if (Object.keys(formErrors).length === 0) {
      if (update) {
        props.updateCustomTicket({
          // change dropdown options to their values:
          escalationSchemeSrn: (escalation ?? {}).value,
          ticket: {
            title,
            ticketSrn: props.ticket.srn,
            description,
            assignedTo: (assignedTo ?? {}).value,
            severityCategory: (severity ?? {}).value,
            account: (account ?? {}).value,
            swimlaneSRNs: (selectedSwimlanes ?? []).map(({ value }) => value),
            customFields,
          },
        })
      } else {
        props.saveCustomTicket({
          escalationSchemeSrn: (escalation ?? {}).value,
          ticket: {
            title,
            description,
            resourceSRN: (props.resource ?? {}).srn,
            assignedTo: (assignedTo ?? {}).value,
            severityCategory: (severity ?? {}).value,
            account: (account ?? {}).value,
            swimlaneSRNs: (selectedSwimlanes ?? []).map(({ value }) => value),
            templateSRN: (props.selectedTicketTemplate ?? {}).srn,
            customFields,
          },
        })
      }
    }
  }

  // This is the code that will redirect to ticket details the modal after the
  // user saves if there are no errors.
  const prevMutateStatusRef = useRef()
  useEffect(() => {
    if (prevMutateStatusRef.current) {
      const wasLoading = prevMutateStatusRef.current.get('loading')
      const isLoading = props.createTicketStatus.get('loading')
      const justFinishedLoading = wasLoading && !isLoading
      if (
        justFinishedLoading &&
        !props.createTicketStatus.get('errors') &&
        !props.createTicketStatus.get('error')
      ) {
        if (update) {
          props.fetchTicketDetails({ srn: props.ticket.srn })
          handleClose(props)
        } else {
          props.push({
            pathname: '/App/TicketDetails',
            search: qs.stringify({
              srn: props.createTicketStatus.getIn(['data', 'srn']),
            }),
          })
          handleClose(props)
        }
      }
    }
    prevMutateStatusRef.current = props.createTicketStatus
  }, [props.createTicketStatus])

  return (
    <Modal size="lg" isOpen={props.isOpen}>
      <ModalHeader toggle={() => handleClose(props)}>
        <div title={modalTitle} style={styles.title}>
          {modalTitle}
        </div>
      </ModalHeader>
      <ModalBody>
        <TicketBody
          {...props}
          formErrors={formErrors}
          ticketTemplate={ticketTemplate}
          handleTicketTemplateChange={handleTicketTemplateChange}
          //for default fields
          title={title}
          handleTitleChange={handleTitleChange}
          description={description}
          handleDescriptionChange={handleDescriptionChange}
          selectedSwimlanes={selectedSwimlanes}
          handleSwimlaneChange={handleSwimlaneChange}
          severity={severity}
          handleSeverityChange={handleSeverityChange}
          assignedTo={assignedTo}
          handleSelfAssign={() =>
            handleSelfAssign(props, handleAssignedToChange)
          }
          handleAssignedToChange={handleAssignedToChange}
          escalation={escalation}
          handleEscalationChange={handleEscalationChange}
          account={account}
          handleAccountChange={handleAccountChange}
          fetchTicketTemplateFieldSelect={props.fetchTicketTemplateFieldSelect}
          setTicketTemplateFieldValue={props.setTicketTemplateFieldValue}
          ticketTemplateFields={props.ticketTemplateFields}
        />
      </ModalBody>
      <ModalFooter>
        {Object.keys(formErrors).length > 0 && (
          <span style={{ color: 'red', position: 'absolute', left: '20px' }}>
            <DynamicFormattedMessage {...messages.validationErrors} />
          </span>
        )}
        {props.createTicketStatus.get('error') > 0 && (
          <span style={{ color: 'red', position: 'absolute', left: '20px' }}>
            <DynamicFormattedMessage {...messages.createError} />
          </span>
        )}
        {props.createTicketStatus.get('assignEscalationError') && (
          <span style={{ color: 'red', position: 'absolute', left: '20px' }}>
            <DynamicFormattedMessage {...messages.assignEscalationError} />
          </span>
        )}
        <TextLink
          color="secondary"
          onClick={() => handleClose(props)}
          style={{ margin: '0rem 0.5rem 0rem 0rem' }}
        >
          Cancel
        </TextLink>
        {!props.ticketType && (
          <Button
            color="primary"
            disabled={!ticketTemplate}
            onClick={() => handleTicketType(props, 'TEMPLATE', ticketTemplate)}
          >
            Continue
          </Button>
        )}
        {props.ticketType && (
          <Button
            color="primary"
            onClick={onClickCreate}
            disabled={props.createTicketStatus.get('loading')}
          >
            {update ? 'Save' : 'Create'}
            {props.createTicketStatus.get('loading') && (
              <Icon fa name="sync" spin />
            )}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

CreateUpdateTicketModal.propTypes = {
  //from parent
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  resource: PropTypes.object,
  ticket: PropTypes.shape({
    account: PropTypes.string,
    assignedTo: PropTypes.string,
    customFields: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.string,
    srn: PropTypes.string.isRequired,
    severityCategory: PropTypes.string.isRequired,
    swimlaneSRNs: PropTypes.arrayOf(PropTypes.string),
    templateSRN: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),

  //actions
  push: PropTypes.func.isRequired,
  fetchTicketDetails: PropTypes.func.isRequired,
  fetchTicketTemplates: PropTypes.func.isRequired,
  getEscalations: PropTypes.func.isRequired,
  setTicketType: PropTypes.func.isRequired,
  setSelectedTicketTemplate: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
  fetchTicketTemplateFieldSelect: PropTypes.func.isRequired,
  setTicketTemplateFieldValue: PropTypes.func.isRequired,
  saveCustomTicket: PropTypes.func.isRequired,
  updateCustomTicket: PropTypes.func.isRequired,

  //redux
  accounts: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      srn: PropTypes.string.isRequired,
      account: PropTypes.string.isRequired,
    })
  ),
  createTicketStatus: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: ImmutablePropTypes.contains({ srn: PropTypes.string }),
  }),
  escalations: ImmutablePropTypes.iterable.isRequired,
  ticketType: PropTypes.string,
  selectedTicketTemplate: PropTypes.string,
  ticketTemplateFields: ImmutablePropTypes.map,
  ticketTemplates: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  swimlanes: ImmutablePropTypes.iterable,
}

const mapStateToProps = createStructuredSelector({
  accounts: selectAccounts,
  createTicketStatus: selectCreateTicketStatus,
  swimlanes: selectSwimlanes,
  currentUser: selectSonraiUserSrn,
  users: selectSonraiUsers,
  escalations: selectEscalations,
  selectedTicketTemplate: selectSelectedTicketTemplate,
  ticketType: selectTicketType,
  ticketTemplates: selectTicketTemplates,
  ticketTemplateFields: selectTicketTemplateFields,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      fetchTicketTemplates,
      fetchTicketDetails,
      getEscalations,
      setTicketType,
      setSelectedTicketTemplate,
      clearState,
      fetchTicketTemplateFieldSelect,
      setTicketTemplateFieldValue,
      saveCustomTicket,
      updateCustomTicket,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'createTicketModal', reducer })
const withSaga = injectSaga({
  key: 'createTicketModal',
  saga: sagas,
  mode: DAEMON,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  permissionChecker
)(CreateUpdateTicketModal)
