import { all, put, takeLatest } from 'redux-saga/effects'

import { getClient } from 'apolloClient'
import { getNodeInfoQuery } from 'utils/graphDataUtils'

import { GET_DATA } from './constants'
import { setData, errData } from './actions'

function* getData(action) {
  const { srn, queryNames, types } = action.payload
  const query = getNodeInfoQuery(srn, queryNames, types)
  const client = yield getClient()
  try {
    const result = yield client.query({
      query,
      variables: { srn },
    })

    const { data } = result
    if (!data || data == null) {
      throw 'the result had no data'
    }

    const { items } = Object.values(data)[0]
    if (!items || items == null) {
      throw 'the result had no items'
    }

    yield put(setData({ data: items[0] }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`There was an error fetching data: {}`, e)
    yield put(errData())
  }
}

function* nodeViewMiniPopoutSaga() {
  yield all([takeLatest(GET_DATA, getData)])
}

export default nodeViewMiniPopoutSaga
