import { put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import {
  USER_NODE_VIEW_ACTIONS_PERFORMED_QUERY,
  ROLE_NODE_VIEW_QUERY,
} from 'static-queries' // do we need a role specific actions performed query ?
import { GET_ROLE_CONTENT, GET_ACTIONS_PERFORMED } from './constants'
import { setRoleContent, setActionsPerformed } from './actions'

function* getActions(action) {
  const { srn, range } = action.payload

  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${USER_NODE_VIEW_ACTIONS_PERFORMED_QUERY}
      `,
      variables: {
        srn,
        range,
      },
    })
    const actionsPerformed = results.data.Actions.items
    if (actionsPerformed === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setActionsPerformed(actionsPerformed))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getRoleContent(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${ROLE_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Roles.items
    if (content === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setRoleContent(_.get(content, 0)))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* roleNodeSaga() {
  yield takeLatest(GET_ACTIONS_PERFORMED, getActions)
  yield takeLatest(GET_ROLE_CONTENT, getRoleContent)
}

export default roleNodeSaga
