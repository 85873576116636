/**
 *
 * PolicyEntryInfo
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import BorderlessButton from 'components/BorderlessButton'
import { Table } from 'reactstrap'

const styles = {
  active: {
    backgroundColor: '#fff',
  },
  activeSubkey: {
    fontWeight: 'bold',
    backgroundColor: 'rgb(249, 251, 255)',
    borderLeft: '1px solid #eee',
  },
  activeSubkeyButton: {
    backgroundColor: 'transparent',
  },
  wrapper: {
    borderTop: '1px solid #eee',
    borderBottom: '1px solid #eee',
    padding: '1em',
    paddingRight: '0',
  },
  properties: {
    padding: '1em 0 0 1em',
  },
  subkeys: {
    paddingLeft: '1em',
  },
  title: {
    textAlign: 'left',
  },
  code: {
    fontFamily: 'monospace',
    fontSize: '1.2em',
    lineHeight: '1.5em',
    marginBottom: '1em',
    paddingRight: '1em',
    wordBreak: 'break-word',
    wordWrap: 'break-word', //Needed for Firefox
  },
  label: {
    paddingRight: '1em',
  },
}

class PolicyEntryInfo extends React.Component {
  renderPolicySubButton = (type, label) => {
    return (
      <tr>
        <td style={this.props.activeSubkey === type ? styles.activeSubkey : {}}>
          <BorderlessButton
            onClick={this.props.setContent.bind(null, this.props.index, type)}
            style={{
              ...styles.subkey,
              ...(this.props.activeSubkey === type
                ? styles.activeSubkeyButton
                : {}),
            }}
          >
            {label}
          </BorderlessButton>
        </td>
      </tr>
    )
  }

  render() {
    return (
      <div
        style={{
          ...styles.wrapper,
          ...(this.props.active ? styles.active : {}),
        }}
      >
        <BorderlessButton
          style={styles.title}
          onClick={this.props.setContent.bind(null, this.props.index, 'allows')}
        >
          {this.props.policyEntry.name}
        </BorderlessButton>
        {this.props.active && (
          <div style={styles.properties}>
            <div>
              <span style={styles.label}>Conditions:</span>
              <div style={styles.code}>{this.props.policyEntry.conditions}</div>
            </div>
            <div>
              <span style={styles.label}>Resource Filter:</span>

              <div style={styles.code}>
                {this.props.policyEntry.resourceFilter}
              </div>
            </div>
            <div style={styles.subkeys}>
              <Table hover>
                <tbody>
                  {this.renderPolicySubButton('allows', this.props.allowsLabel)}
                  {this.renderPolicySubButton('denies', this.props.deniesLabel)}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    )
  }
}

PolicyEntryInfo.propTypes = {
  policyEntry: PropTypes.shape({
    name: PropTypes.string,
    conditions: PropTypes.string,
    resourceFilter: PropTypes.string,
  }),
  index: PropTypes.number,
  setContent: PropTypes.func,
  active: PropTypes.bool,
  activeSubkey: PropTypes.string,
  allowsLabel: PropTypes.node,
  deniesLabel: PropTypes.node,
}

export default PolicyEntryInfo
