import { defineMessages } from 'react-intl'

export default defineMessages({
  autoremediate: {
    id: 'app.containers.TicketAutoRemediateWizard.autoremediate',
    defaultMessage: 'Auto Remediate',
  },
  back: {
    id: 'app.containers.TicketAutoRemediateWizard.back',
    defaultMessage: 'Back',
  },
  modalTitle: {
    id: 'app.containers.TicketAutoRemediateWizard.modalTitle',
    defaultMessage: 'Auto-Remediate Ticket',
  },
  next: {
    id: 'app.containers.TicketAutoRemediateWizard.next',
    defaultMessage: 'Next',
  },
  botDescriptionLabel: {
    id: 'app.containers.TicketAutoRemediateWizard.b',
    defaultMessage: 'Bot Description',
  },
  botTitleLabel: {
    id: 'app.containers.TicketAutoRemediateWizard.botTitleLabel',
    defaultMessage: 'Bot Title',
  },
  botTypeLabel: {
    id: 'app.containers.TicketAutoRemediateWizard.botTitleLabel',
    defaultMessage: 'Bot Type',
  },
  chooseBotPrompt: {
    id: 'app.containers.TicketAutoRemediateWizard.chooseBotPrompt',
    defaultMessage: 'Choose Bot Manually',
  },
  chooseDifferentBot: {
    id: 'app.containers.TicketAutoRemediateWizard.chooseDifferentBot',
    defaultMessage: 'Try choosing a different bot',
  },
  ok: {
    id: 'app.containers.TicketAutoRemediateWizard.ok',
    defaultMessage: 'OK',
  },
  noBots: {
    id: 'app.containers.TicketAutoRemediateWizard.noBots',
    defaultMessage: 'No bots available for auto remediation',
  },
  noSuggestedBots: {
    id: 'app.containers.TicketAutoRemediateWizard.noSuggestedBots',
    defaultMessage: 'Could not suggest bot for auto remediation',
  },
  showingAllBots: {
    id: 'app.containers.TicketAutoRemediateWizard.showingAllBots',
    defaultMessage: 'Displaying all bots',
  },
  showAll: {
    id: 'app.containers.TicketAutoRemediateWizard.showAll',
    defaultMessage: 'show all',
  },
  showingSuggestedBots: {
    id: 'app.containers.TicketAutoRemediateWizard.showingSuggestedBots',
    defaultMessage: 'Displaying suggested bots',
  },
  showSuggested: {
    id: 'app.containers.TicketAutoRemediateWizard.showingSuggestedBots',
    defaultMessage: 'show suggested',
  },
  step1Instructions: {
    id: 'app.containers.TicketAutoRemediateWizard.step1Instructions',
    defaultMessage: 'Select a bot to auto-remediate the ticket',
  },
  step2Instructions: {
    id: 'app.containers.TicketAutoRemediateWizard.step2Instructions',
    defaultMessage: 'Confirm auto remediation',
  },
  step2PolicyLabel: {
    id: 'app.containers.TicketAutoRemediateWizard.step2PolicyLabel',
    defaultMessage: 'The following Policy will be applied',
  },
  submitErrorMessage: {
    id: 'app.containers.TicketAutoRemediateWizard.submitErrorMessage',
    defaultMessage:
      'There was an error submitting request to auto-remediate ticket',
  },
  submitNotAcceptedMessage: {
    id: 'app.containers.TicketAutoRemediateWizard.submitNotAcceptedMessage',
    defaultMessage: 'The request to remediate the ticket was not accepted.',
  },
  submitNoCollectorMatchedMessage: {
    id: 'app.containers.TicketAutoRemediateWizard.submitNotAcceptedMessage',
    defaultMessage:
      'No collector is configured with capability to auto remediate the ticket.',
  },
  submitSuccessMessage: {
    id: 'app.containers.TicketAutoRemediateWizard.submitSuccessMessage',
    defaultMessage: 'Request to auto-remediate ticket has been submitted.',
  },
})
