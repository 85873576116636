import React, { Component, Fragment } from 'react'
// import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { List } from 'immutable'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { createStructuredSelector } from 'reselect'

import Escalation from 'containers/TicketDetails/components/Escalation'
import { selectBots } from 'containers/SonraiData/selectors'

import TicketStatusIcon from 'components/TicketStatusIcon'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import BorderedCard from 'components/BorderedCard'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import CreatedByBadge from 'components/CreatedByBadge'
import SectionHeader from 'components/SectionHeader'
import TextLink from 'components/TextLink'
import UserWidget from 'components/UserWidget'

import messages from './messages'

export class TicketDetailsEscalation extends Component {
  style = {
    container: {
      marginTop: '34px',
      display: 'flex',
      flexDirection: 'column',
    },
    propertiesContainer: {
      padding: '0.5rem 0rem',
    },
    property: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '2rem',
    },
    label: {
      fontSize: '1rem',
      fontWeight: '400',
      margin: '0rem 0rem 0.5rem 0rem',
    },
    value: {
      fontSize: '0.95rem',
      maxWidth: '360px',
      overflow: 'hidden',
      wordWrap: 'break-word',
    },
  }

  getCreatedBy = () => {
    const createdBy = this.props.ticket.get('createdBy')
    return <CreatedByBadge table createdBy={createdBy} />
  }

  hasEscalationWidget = () => {
    const rules = this.props.ticket.getIn(['nextEscalation', 'rule']) || List()
    if (!this.props.bots.get('isLoading') && !rules.isEmpty()) {
      return true
    }
    return false
  }

  /**
   * Get display value for a custom field from ticket template
   */
  getCustomFieldValue = customField => {
    const type = customField.get('type')
    const value = customField.get('value')

    // if it's a muti select, render a thingy for each of the values as
    // if it was only a single value by making recursive call
    if (type === 'SELECT' && customField.get('isMulti') && value) {
      let values
      // for mutli, the values will be stringified JSON
      try {
        values = JSON.parse(value)
      } catch (e) {
        // eslint-disable-next-line
        console.error(`error occured displaying value ${value}`, e)
        return <span>Error occurred processing value {value}</span>
      }
      if (_.isArray(values)) {
        return values.map((value, index) => (
          // pass custom field with one of the values and render non-multi mode
          <div key={`customFieldValue_${index}`}>
            {this.getCustomFieldValue(
              customField.set('value', value).set('isMulti', false)
            )}
          </div>
        ))
      }
    }

    if (type === 'CHECKBOX') {
      // for checkbox, always false unless explicitly true (e.g. null = false)
      return `${value == 'true'}`
    }

    if (value === null) {
      return <DynamicFormattedMessage {...messages.na} />
    }

    // if it's an SRN, make link to srn
    if (`${value}`.startsWith('srn:')) {
      return (
        <TextLink
          style={{ fontSize: '0.9rem' }}
          color="primary"
          to={getNodeViewPushParams(value, getTypeFromSrn(value))}
          newTab
        >
          {value}
        </TextLink>
      )
    }

    // by default, just return the value text
    return customField.get('value')
  }

  render() {
    const hasEscalationError = _.isNull(this.props.ticket.get('nextEscalation'))
    const hasEscalationWidget = this.hasEscalationWidget() || hasEscalationError
    return (
      <BorderedCard style={this.style.container}>
        {hasEscalationWidget && (
          <Escalation
            bots={this.props.bots}
            ticket={this.props.ticket}
            hasError={hasEscalationError}
          />
        )}

        <div style={this.style.propertiesContainer}>
          <div style={this.style.property}>
            <div style={this.style.label}> Status </div>
            <div style={this.style.value}>
              <TicketStatusIcon value={this.props.ticket.get('status')} />
            </div>
          </div>
          <div style={this.style.property}>
            <div style={this.style.label}> Assigned To </div>
            <div style={this.style.value}>
              {this.props.ticket.get('assignedTo') ? (
                <UserWidget srn={this.props.ticket.get('assignedTo')} table />
              ) : (
                <Fragment>
                  <DynamicFormattedMessage {...messages.unassigned} />
                </Fragment>
              )}
            </div>
          </div>
          <div style={this.style.property}>
            <div style={this.style.label}> Date Created </div>
            <div style={this.style.value}>
              {this.props.ticket.get('createdDate')
                ? moment(this.props.ticket.get('createdDate')).format(
                    'MMM Do YYYY h:mm a'
                  )
                : '-'}
            </div>
          </div>
          <div style={this.style.property}>
            <div style={this.style.label}> Created By</div>
            <div style={this.style.value}>{this.getCreatedBy()}</div>
          </div>
          {this.props.ticket.get('ticketType') === 'Custom' &&
            this.props.ticket.get('customFields') != null &&
            this.props.ticket.get('customFields').size > 0 && (
              // render display of custom fields from custom ticket template:
              <Fragment>
                <hr />
                <SectionHeader small>
                  <DynamicFormattedMessage {...messages.customFields} />
                </SectionHeader>
                {this.props.ticket.get('customFields').map(customField => {
                  return (
                    <div
                      key={`cf-${customField.get('name')}`}
                      style={this.style.property}
                    >
                      <div style={this.style.label}>
                        {customField.get('name')}
                      </div>
                      <div style={this.style.value}>
                        {this.getCustomFieldValue(customField)}
                      </div>
                    </div>
                  )
                })}
              </Fragment>
            )}
        </div>
      </BorderedCard>
    )
  }
}

TicketDetailsEscalation.propTypes = {
  theme: themeShape,
  ticket: ImmutablePropTypes.iterable.isRequired,
  bots: ImmutablePropTypes.iterable.isRequired,
}

const mapStateToProps = createStructuredSelector({
  bots: selectBots,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push,
    },
    dispatch
  )

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(themeable(TicketDetailsEscalation))
