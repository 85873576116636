import React from 'react'
import PropTypes from 'prop-types'
import { DropdownMenu as RSDropdownMenu } from 'reactstrap'

export class DropdownMenu extends React.Component {
  onClick = value => {
    this.props.onClick(value)
    this.props.toggle()
  }

  render() {
    return (
      <RSDropdownMenu>
        {React.Children.map(this.props.children, child =>
          React.cloneElement(child, {
            onClick: this.onClick,
          })
        )}
      </RSDropdownMenu>
    )
  }
}

DropdownMenu.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  toggle: PropTypes.func,
}

export default DropdownMenu
