import React from 'react'
import PropTypes from 'prop-types'
import { Button as ReactstrapButton } from 'reactstrap'
import Color from 'color'
import { Link } from 'react-router-dom'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class Button extends React.PureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      base: {
        boxShadow: 'none',
        cursor: 'pointer',
      },
    }
  }

  getStyle = () => {
    let style = {
      color: this.props.theme.neutralMedium,
      backgroundColor: '#ffffff',
      borderColor: this.props.theme.neutralMedium,
      borderWidth: '1px',
    }

    if (this.props.color === 'primary' || this.props.themeColor === 'primary') {
      style = {
        color: this.props.outline ? this.props.theme.primary : '#ffffff',
        backgroundColor: this.props.outline ? '#fff' : this.props.theme.primary,
        borderColor: this.props.theme.primary,
      }
    }

    if (this.props.color === 'success' || this.props.themeColor === 'success') {
      style = {
        color: this.props.outline ? this.props.theme.success : '#ffffff',
        backgroundColor: this.props.outline ? '#fff' : this.props.theme.success,
        borderColor: this.props.theme.success,
      }
    }

    if (
      this.props.color === 'secondary' ||
      this.props.themeColor === 'secondary'
    ) {
      style = {
        color: this.props.outline ? this.props.theme.secondary : '#ffffff',
        backgroundColor: this.props.outline
          ? '#fff'
          : this.props.theme.secondary,
        borderColor: this.props.theme.secondary,
      }
    }

    if (this.props.color === 'danger' || this.props.themeColor === 'danger') {
      style = {
        color: this.props.outline ? this.props.theme.fail : '#ffffff',
        backgroundColor: this.props.outline ? '#fff' : this.props.theme.fail,
        borderColor: this.props.theme.fail,
      }
    }

    return style
  }

  getDisabledStyle = inStyle => {
    let style = { ...inStyle }
    if (this.props.disabled) {
      style.backgroundColor = Color(style.backgroundColor)
        .desaturate(0.5)
        .darken(0.01)
        .hex()
      style.color = Color(style.color).desaturate(0.2).darken(0.3).hex()
      style.borderColor = Color(style.borderColor).darken(0.25)
      style.boxShadow = `inset 0px 0px 2px 0px ${style.borderColor}`
      return style
    }
  }

  render() {
    const {
      children,
      style,
      theme, //eslint-disable-line no-unused-vars
      typeColors, //eslint-disable-line no-unused-vars
      to,
      newTab,
      onClick,
      ...pprops
    } = this.props

    const finalStyle = {
      ...this.styles.base,
      ...this.getStyle(),
      ...style,
      ...this.getDisabledStyle(this.getStyle()),
    }

    const finalOnClick = this.props.disabled ? undefined : onClick

    if (to) {
      return (
        <Link
          to={to}
          target={newTab ? '_blank' : undefined}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
        >
          <ReactstrapButton
            style={finalStyle}
            {...pprops}
            onClick={finalOnClick}
          >
            {children}
          </ReactstrapButton>
        </Link>
      )
    }

    return (
      <ReactstrapButton
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        style={finalStyle}
        onClick={finalOnClick}
        {...pprops}
      >
        {children}
      </ReactstrapButton>
    )
  }
}

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  theme: themeShape,
  typeColors: PropTypes.object,
  newTab: PropTypes.bool,
  to: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.any,
  }),
  //When using Button as the tag for reactstrap DropdownAnchor, reactstrap is stripping the "color" prop. Added this alternative color prop so it gets passed through
  themeColor: PropTypes.string,
  style: PropTypes.object,
}

export default themeable(Button)
