export default {
  closeButton: {
    marginRight: '0.3em',
  },
  toolbarButton: {
    fontSize: '16px',
    cursor: 'pointer',
    margin: '0.25em',
  },
  filterIcon: {
    fontSize: '16px',
    margin: '0.25em 0 0.25em 0.25em',
  },
  hidden: {
    height: 0,
    width: 0,
    display: 'none',
  },
  link: {
    display: 'block',
    width: '100%',
    marginBottom: '0.5em',
    paddingTop: '0.25em',
    paddingBottom: '0.25em',
    paddingLeft: '1em',
    paddingRight: '1em',
    textAlign: 'left',
  },
  error: {
    textAlign: 'center',
    color: '#555',
    fontSize: '0.95em',
    padding: '0.5em',
  },
  errorImg: {
    opacity: '0.4',
    width: '40px',
    marginTop: '2em',
  },
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gridArea: 'widget-content',
    textAlign: 'center',
  },
}
