import {
  GET_ACTIONS_PERFORMED,
  SET_ACTIONS_PERFORMED,
  GET_USER_CONTENT,
  SET_USER_CONTENT,
} from './constants'
import { createAction } from 'redux-actions'

export const getActionsPerformed = createAction(GET_ACTIONS_PERFORMED)
export const setActionsPerformed = createAction(SET_ACTIONS_PERFORMED)
export const getUserContent = createAction(GET_USER_CONTENT)
export const setUserContent = createAction(SET_USER_CONTENT)
