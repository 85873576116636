/*
 * IHelp Messages
 *
 * This contains all the text for the IHelp component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  quicksearch: {
    id: 'app.containers.IHelp.quicksearch',
    defaultMessage:
      'Quick search looks across all of your Identities, Data, and Infrastructure simultaneously allowing you to quickly find what you are looking for',
  },
  filterPanel: {
    id: 'app.containers.IHelp.filterPanel',
    defaultMessage:
      'Filter all the displayed Solution Center content. If an active filter has been successfully applied to a widget, it will be indicated on the widget by the filter icon.',
  },
  searchEntityTypes: {
    id: 'app.containers.IHelp.searchEntityTypes',
    defaultMessage:
      'Choose a top level data type to begin a new search. You will then be able to add more filters and relations to explore your cloud data',
  },
  savedSearchNameField: {
    id: 'app.containers.IHelp.savedSearchNameField',
    defaultMessage:
      'Saving the search will allow you to revisit this search in the future, or use its results in a widget on the Solution Center',
  },
  solutionCenterToolbarGeneral: {
    id: 'app.containers.IHelp.solutionCenterToolbarGeneral',
    defaultMessage:
      'Solution Cards show you interesting metrics at a glance. Click the plus icon in the tab bar to create your own custom Solution Card. Add widgets to your new card using the button just under the tab bar',
  },
  editWidgetModal: {
    id: 'app.containers.IHelp.editWidgetModal',
    defaultMessage:
      'Widgets require at least one search to display data. They may require several searches, or other configuration, depending on the type. Widgets can be edited after creation.',
  },
  multiFilterValue: {
    id: 'app.containers.IHelp.multiFilterValue',
    defaultMessage:
      "To filter by multiple values (that are or'd together), please add them separated by either a comma or on separate lines, but not both. It is also possible to upload a CSV file using the button below. If you would like to search for a value containing a comma, contain it within quotes",
  },
  explorer: {
    id: 'app.containers.IHelp.explorer',
    defaultMessage: 'Use Search to highlight existing nodes of interest.',
  },
  relationOperator: {
    id: 'app.containers.IHelp.relationOperator',
    defaultMessage:
      'Select OR to find results that match at least one on the connected relations. Select AND to find only the results that match every connected relation',
  },
  filterPanelTagSet: {
    id: 'app.containers.IHelp.filterPanelTagSet',
    defaultMessage: 'Match on both Tag Keys and Tag Values',
  },
  ignorePermissions: {
    id: 'app.containers.IHelp.ignorePermissions',
    defaultMessage:
      'Permissions that match these filters are excluded from results',
  },
  includePermissions: {
    id: 'app.containers.IHelp.includePermissions',
    defaultMessage:
      'Permissions that match these filters are included in search results',
  },
  notSearchFilter: {
    id: 'app.containers.IHelp.notSearchFilter',
    defaultMessage:
      'Check this to invert this filter by adding a NOT statement',
  },
  proximityMessage: {
    id: 'app.containers.IHelp.proximityMessage',
    defaultMessage:
      'Distance in characters between the regex pattern and a keyword. If a keyword is fully within the proximity then there is a pattern match.',
  },
  whereMessage: {
    id: 'app.containers.IHelp.whereMessage',
    defaultMessage: 'The direction to check for keywords.',
  },
  customDataClassifierDescription: {
    id: 'app.containers.IHelp.customDataClassifierDescription',
    defaultMessage:
      'The use case for this model is when data patterns can be described by the set of regexes and the set of keywords in proximity to the regexes. For example, if the pattern is "Customer ID: 12345678", then the regex is the one for 8 digits, and the key word is "Customer ID"; the proximity can be set to 20. In addition, one can provide the list of disallowed keywords and configure directions to check for keywords.',
  },
})
