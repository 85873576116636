import _ from 'lodash'

/**
 * get the object containing the entities that would populate the objects
 *
 * @param {object} resultData response from gql query
 * @returns {object[]}
 */
export function getSelectOptionsObjects(result, field) {
  // if there's no data, no point in trying to get result
  if (!result.data) {
    return null
  }

  let path
  if (!field.select.path) {
    path = ['data', Object.keys(result.data)[0], 'items']
  } else {
    path = [...field.select.path]
  }

  return _.get(result, path, null)
}

/**
 * get the value that would go in an option in select
 */
export function getValue(item, field) {
  if (field.select.valueField) {
    return _.get(item, field.select.valueField)
  } else {
    return item.srn
  }
}

/**
 * get the label that would go in an option in select
 */
export function getLabel(item, field) {
  if (field.select.labelFields) {
    let label = field.select.labelFields.reduce((currentValue, nextKey) => {
      if (currentValue) {
        return currentValue
      }
      return item[nextKey]
    }, null)
    if (label) {
      return label
    }
  }
  return item.srn
}
