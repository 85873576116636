import { all, put, takeLatest, select } from 'redux-saga/effects'
import { fromJS, Map } from 'immutable'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'
import {
  QueryBuilder,
  getFields,
  getExploreSonraiSearchQueryString,
} from 'query-builder'
import _ from 'lodash'

import {
  selectSavedSearches,
  selectQueryTypes,
} from 'containers/SonraiData/selectors'

import { setWidgetResults, setWidgetResultsError } from './actions'
import {
  FETCH_SAVED_SEARCH_WIDGET_RESULTS,
  FETCH_ADVANCED_SEARCH_WIDGET_RESULTS,
  FETCH_CUSTOM_SEARCH_WIDGET_RESULTS,
} from './constants'

function* getSavedSearchResults({ payload }) {
  const savedSearches = yield select(selectSavedSearches)

  let fields = getFields(savedSearches, payload.searchId)

  if (!fields.isEmpty()) {
    const rootField = fields.find(
      statement => !statement.get('parentId'),
      null,
      Map()
    )

    if (payload.filter && !_.isEmpty(payload.filter)) {
      //Add the custom filter arg to the query
      const searchCardId = payload.filterCardId || rootField.get('id')
      fields = fields.updateIn([searchCardId, 'arguments', 'and'], andArgs =>
        andArgs.push(
          fromJS({
            [payload.filter.label]: {
              caseSensitive: false,
              dateOffset: null,
              op: 'EQ',
              value: payload.filter.value,
            },
          })
        )
      )
    }

    const flatten = fields.size > 1

    const queryTypes = yield select(selectQueryTypes)

    const queryBuilder = new QueryBuilder({
      query: fields,
      types: queryTypes,
    })

    queryBuilder.addDefaultResultViews()
    queryBuilder.skipCounts()

    if (flatten) {
      queryBuilder.enableFlattenMode()
    }

    const query = queryBuilder.buildPivotableSource(rootField.get('id'))

    try {
      const client = getClient()
      const result = yield client.query({
        query: gql`
          ${query.gqlStatement}
        `,
        variables: query.variables,
      })

      yield put(setWidgetResults(result.data))
    } catch (e) {
      //eslint-disable-next-line no-console
      console.error('Failed to fetch results for widget explorer', e)
      yield put(setWidgetResultsError(e.message))
    }
  }
}

function* getAdvancedSearchResults({ payload }) {
  const query = getExploreSonraiSearchQueryString(payload.searchName)

  try {
    const client = getClient()
    const result = yield client.query({
      query: gql`
        ${query}
      `,
    })

    yield put(setWidgetResults(result.data))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Failed to fetch results for widget explorer', e)
    yield put(setWidgetResultsError(e.message))
  }
}

function* getCustomSearchResults({ payload }) {
  try {
    const client = getClient()
    const result = yield client.query({
      query: gql`
        ${payload.query}
      `,
    })

    yield put(setWidgetResults(result.data))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Failed to fetch results for widget explorer', e)
    yield put(setWidgetResultsError(e.message))
  }
}

function* widgetResultsSaga() {
  yield all([
    takeLatest(FETCH_SAVED_SEARCH_WIDGET_RESULTS, getSavedSearchResults),
    takeLatest(FETCH_ADVANCED_SEARCH_WIDGET_RESULTS, getAdvancedSearchResults),
    takeLatest(FETCH_CUSTOM_SEARCH_WIDGET_RESULTS, getCustomSearchResults),
  ])
}

export default widgetResultsSaga
