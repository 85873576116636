import {
  GET_ROLE_CONTENT,
  SET_ROLE_CONTENT,
  GET_ACTIONS_PERFORMED,
  SET_ACTIONS_PERFORMED,
} from './constants'

import { createAction } from 'redux-actions'

export const getRoleContent = createAction(GET_ROLE_CONTENT)
export const setRoleContent = createAction(SET_ROLE_CONTENT)
export const getActionsPerformed = createAction(GET_ACTIONS_PERFORMED)
export const setActionsPerformed = createAction(SET_ACTIONS_PERFORMED)
