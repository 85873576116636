import { put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import { ACTION_TYPE_NODE_VIEW_QUERY } from 'static-queries'

import { GET_ACTIONTYPE_CONTENT } from './constants'
import { setActiontypeContent } from './actions'

function* getActiontypeContent(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${ACTION_TYPE_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.ActionTypes.items
    if (content === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setActiontypeContent(_.get(content, 0)))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* actiontypeNodeSaga() {
  yield takeLatest(GET_ACTIONTYPE_CONTENT, getActiontypeContent)
}

export default actiontypeNodeSaga
