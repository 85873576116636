import React from 'react'
import PropTypes from 'prop-types'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Icon from 'components/Icon'
import { PlainCheckbox } from 'components/Checkbox'
import ToolbarDivider from './ToolbarDivider'

export const ActiveToolbar = ({
  children,
  theme,
  working,
  style,
  selectedItems,
  totalItems,
  showCheckbox,
  toggleSelectAll,
}) => {
  const styles = {
    toolbar: {
      display: 'flex',
      backgroundColor: theme.secondary,
      color: theme.light,
      fontWeight: 400,
      height: '35px',
      padding: '0 0.5em',
      alignItems: 'center',
    },
    spinnerContainer: {
      flex: 1,
      textAlign: 'right',
      color: theme.light,
    },
  }

  return (
    <div className="flip-in-hor-bottom" style={{ ...styles.toolbar, ...style }}>
      {showCheckbox && toggleSelectAll && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PlainCheckbox
            checked={selectedItems.length > 0}
            semiChecked={
              selectedItems.length > 0 &&
              selectedItems.length !== totalItems.length
            }
            onChange={toggleSelectAll}
          />
          &nbsp;Select All
          <ToolbarDivider active={true} />
        </div>
      )}

      {React.Children.map(children, child => {
        if (!child) {
          return null
        }

        return React.cloneElement(child, {
          disabled: working || child.props.disabled,
          active: true,
        })
      })}

      {working && (
        <div style={styles.spinnerContainer}>
          <Icon fa name="sync" spin />
        </div>
      )}
    </div>
  )
}

ActiveToolbar.defaultProps = {
  style: {},
  selectedItems: [],
  totalItems: [],
  showCheckbox: true,
}

ActiveToolbar.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  theme: themeShape,
  working: PropTypes.bool,
  selectedItems: PropTypes.array,
  totalItems: PropTypes.array,
  toggleSelectAll: PropTypes.func,
  showCheckbox: PropTypes.bool,
}
export default themeable(ActiveToolbar)
