import { fromJS, List } from 'immutable'
export const modifyFieldsForSRN = (srn, rootIndex, fields) => {
  // some older searches annoyingly don't have an and[] field in the arg by
  // default so need to insert one here otherwise null pointer exception
  if (!fields.getIn([rootIndex, 'arguments']).has('and')) {
    fields = fields.setIn([rootIndex, 'arguments', 'and'], List())
  }

  const rootNodeType = fields
    .get(rootIndex)
    .getIn(['definition', 'type', 'name'])

  if (rootNodeType === 'ActionEdgeRelation') {
    fields = fields.updateIn([rootIndex, 'arguments', 'or'], List(), args =>
      args
        .push(
          fromJS({
            performedByValue: { value: srn, op: 'EQ', caseSensitive: true },
          })
        )
        .push(
          fromJS({
            performedOnValue: { value: srn, op: 'EQ', caseSensitive: true },
          })
        )
    )
  } else {
    fields = fields.updateIn([rootIndex, 'arguments', 'and'], List(), args =>
      args.push(
        fromJS({
          srn: { value: srn, op: 'EQ', caseSensitive: true },
        })
      )
    )
  }
  return fields
}
