/*
 *
 * Change Detection Manager reducer
 *
 */

import { fromJS } from 'immutable'
import {
  ADD_CHANGE_DETECTION_PROPERTIES,
  ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS,
  REMOVE_CHANGE_DETECTION_PROPERTIES,
  REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS,
  UPDATE_CHANGE_DETECTION_PROPERTY,
  UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS,
  SET_CHANGE_DETECTION_DATA,
} from './constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  changeDetectionOptions: [],
  changeDetectionProperties: [],
  isLoading: false,
})

const changeDetectionManagerReducer = handleActions(
  {
    [SET_CHANGE_DETECTION_DATA]: (state, { payload }) => {
      const { changeDetectionOptions, changeDetectionProperties } = payload
      return state
        .set('changeDetectionOptions', fromJS(changeDetectionOptions))
        .set('changeDetectionProperties', fromJS(changeDetectionProperties))
    },
    [ADD_CHANGE_DETECTION_PROPERTIES]: state => state.set('isLoading', true),
    [ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS]: (state, { payload }) => {
      const currentState = state.get('changeDetectionProperties')
      const indexByKeyName = currentState.findIndex(
        property => property.get('keyName') === payload.getIn(['0', 'keyName'])
      )
      if (indexByKeyName !== -1) {
        const haveSameAlertLevel =
          currentState.getIn([indexByKeyName, 'alertLevel']) ===
          payload.getIn(['0', 'alertLevel'])
        if (haveSameAlertLevel) {
          const updatedState = currentState.updateIn(
            [indexByKeyName, 'actionClassification'],
            arr => arr.push(payload.getIn(['0', 'actionClassification']))
          )
          return state
            .set('changeDetectionProperties', updatedState)
            .set('isLoading', false)
        } else {
          const indexByAlertLevel = currentState.findIndex(
            item =>
              item.get('keyName') === payload.getIn(['0', 'keyName']) &&
              item.get('alertLevel') === payload.getIn(['0', 'alertLevel'])
          )
          if (indexByAlertLevel !== -1) {
            const updatedState = currentState.updateIn(
              [indexByAlertLevel, 'actionClassification'],
              arr => arr.push(payload.getIn(['0', 'actionClassification']))
            )
            return state
              .set('changeDetectionProperties', updatedState)
              .set('isLoading', false)
          } else {
            return state
              .set('changeDetectionProperties', currentState.merge(payload))
              .set('isLoading', false)
          }
        }
      } else {
        return state
          .set('changeDetectionProperties', currentState.merge(payload))
          .set('isLoading', false)
      }
    },
    [REMOVE_CHANGE_DETECTION_PROPERTIES]: state => state.set('isLoading', true),
    [REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS]: (state, { payload }) => {
      let currentState = state.get('changeDetectionProperties').toJS()
      payload.forEach(property => {
        let index
        if (property.get('alertLevel')) {
          index = currentState.findIndex(
            prop =>
              prop.keyName === property.get('keyName') &&
              prop.alertLevel === property.get('alertLevel')
          )
        } else {
          index = currentState.findIndex(
            prop => prop.keyName === property.get('keyName')
          )
        }

        currentState.splice(index, 1)
      })

      return state
        .set('changeDetectionProperties', fromJS(currentState))
        .set('isLoading', false)
    },
    [UPDATE_CHANGE_DETECTION_PROPERTY]: state => state.set('isLoading', true),
    [UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS]: (state, { payload }) => {
      const { newProperty, index } = payload
      return state
        .setIn(['changeDetectionProperties', index], fromJS(newProperty))
        .set('isLoading', false)
    },
  },
  initialState
)

export default changeDetectionManagerReducer
