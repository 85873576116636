/**
 *
 * AccountNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { push } from 'connected-react-router'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { List } from 'immutable'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import { NodeViewDetailString, NodeViewDetailLabel } from 'components/NodeView'

import messages from './messages'
import reducer from './reducer'
import sagas from './sagas'
import { getAccountContent } from './actions'
import { selectAccountContent, selectIsLoading } from './selectors'

export class AccountNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getAccountContent(props.nodeData.srn)
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getServices = () => {
    return this.props.accountContent
      .getIn(['contains', 'items'], List())
      .toJS()
      .map(service => service.ServiceName)
  }

  getLayouts = () => {
    return {
      graph: {
        lg: { w: 8, h: 3 },
        md: { w: 7, h: 3 },
        sm: { w: 6, h: 3 },
        xs: { w: 4, h: 3 },
      },
    }
  }

  getHeaderDetails = () => {
    return [
      <Fragment key="content-services">
        <NodeViewDetailLabel>
          {this.props.intl.formatMessage(messages.servicesTitle)}
        </NodeViewDetailLabel>
        <NodeViewDetailString value={this.getServices().join(', ')} />
      </Fragment>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />
        <NodeView
          {...this.props}
          layouts={this.getLayouts()}
          gridContent={[]}
          nodeData={this.props.nodeData}
          additionalHeaderDetails={this.getHeaderDetails()}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
          renderDetailsTab={false}
        />
      </Fragment>
    )
  }
}

AccountNodeViewCardLayout.propTypes = {
  accountContent: ImmutablePropTypes.map.isRequired,
  getAccountContent: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  userProfile: selectUserProfile,
  accountContent: selectAccountContent,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAccountContent,
      setUserProfileTables,
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({ key: 'accountNodeViewCardLayout', reducer })

const withSaga = injectSaga({
  key: 'accountNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(AccountNodeViewCardLayout)
