import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'

import messages from './messages'

class NoClassificationsView extends Component {
  render() {
    return (
      <div
        style={{
          width: '400px',
          fontSize: '16px',
          fontWeight: '300',
          margin: '0 auto',
          padding: '24px 0px',
          textAlign: 'center',
        }}
      >
        <span>
          <DynamicFormattedMessage {...messages.noClassificationsInfo} />
        </span>
        <br />
        {this.props.canSubmitJob && (
          <TextLink color="primary" onClick={this.props.openFormView}>
            <DynamicFormattedMessage
              {...messages.noClassificationsCreateText}
            />
          </TextLink>
        )}
      </div>
    )
  }
}

NoClassificationsView.propTypes = {
  canSubmitJob: PropTypes.func.isRequired,
  openFormView: PropTypes.func.isRequired,
}

export default NoClassificationsView
