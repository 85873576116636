import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import CenterContent from 'components/CenterContent'

const styles = {
  errorIcon: {
    color: 'bf2b2b',
    filter: 'drop-shadow( 2px 2px 3px #AAA )',
    fontSize: '50px',
    marginBottom: '0.5em',
  },
  codeStyle: {
    fontFamily: 'monospace',
  },
  wrapper: {
    height: '100%',
  },
}

export class Error extends React.PureComponent {
  render() {
    const { errorMessage, description, title } = this.props

    return (
      <CenterContent style={styles.wrapper}>
        <div>
          <Icon name="exclamation-circle" fa style={styles.errorIcon} />
          {title && <h6> {title}</h6>}
          <p className="error-description">{description}</p>
          <p style={styles.codeStyle} className="error-message">
            {errorMessage}
          </p>
        </div>
      </CenterContent>
    )
  }
}

Error.propTypes = {
  description: PropTypes.node,
  errorMessage: PropTypes.node,
  title: PropTypes.node,
}

export default Error
