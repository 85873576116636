export default {
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  borderedCard: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    gridTemplateAreas: '"top-title" "widget-content" "bottom-title"',
  },
  leftHeader: {  
    position: 'absolute',
    padding: '0.25em',
    top: '1px',
    left: '1px' 
  },
  rightHeader: {
    display: 'flex',
    position: 'absolute',
    padding: '0.25em',
    top: '1px',
    right: '1px'
  },
  topTitle: {
    gridArea: 'top-title',
  },
  bottomTitle: {
    gridArea: 'bottom-title',
    textAlign: 'center',
  },
  body: {
    gridArea: 'widget-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    marginRight: '0.3em',
  },
  toolbarButton: {
    fontSize: '16px',
    cursor: 'pointer',
    margin: '0.25em',
  },
  filterIcon: {
    fontSize: '16px',
    margin: '0.25em 0 0.25em 0.25em',
  },
  hidden: {
    height: 0,
    width: 0,
    display: 'none',
  },
  error: {
    textAlign: 'center',
    gridArea: 'widget-content',
  },
  moreButton: {
    padding: '0 0.3em',
    border: 'none',
    display: 'inline',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  link: {
    display: 'block',
    width: '100%',
    marginBottom: '0.5em',
    paddingTop: '0.25em',
    paddingBottom: '0.25em',
    paddingLeft: '1em',
    paddingRight: '1em',
    textAlign: 'left',
  },
  loading: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gridArea: 'widget-content',
    textAlign: 'center',
  },
  loadingTitle: {
    paddingTop: '0.5em',
  },
}
