import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectAccessDomain = state => state.get('access')

export const selectAccess = createSelector(
  selectAccessDomain,
  state => state || Map()
)

export const selectCanAssumeRoles = createSelector(
  selectAccess,
  state => {
    return state.get('canAssumeRoles')
  }
)

export const selectCanBeAssumedBy = createSelector(
  selectAccess,
  state => {
    return state.get('canBeAssumedBy')
  }
)

export const selectGroupMemberships = createSelector(
  selectAccess,
  state => {
    return state.get('groupMemberships')
  }
)

export const selectDirectAttachedPolicies = createSelector(
  selectAccess,
  state => {
    return state.get('directAttachedPolicies')
  }
)

export const selectSCPs = createSelector(
  selectAccess,
  state => {
    return state.get('scps')
  }
)

export const selectInstanceProfiles = createSelector(
  selectAccess,
  state => {
    return state.get('instanceProfiles')
  }
)

export const selectPermissions = createSelector(
  selectAccess,
  state => {
    return state.get('permissions') || Map()
  }
)

export default selectAccess
export { selectAccessDomain }
