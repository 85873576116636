import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Hoverable from 'components/Hoverable'
import uuid from 'uuid/v4'
import CopyToClipboard from 'components/CopyToClipboard'
import { truncateMiddle } from 'utils/sonraiUtils'

class NodeViewDetailSrn extends React.PureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '30px',
      },
      textStyle: {
        width: '370px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      clipboardButton: { margin: '0px 0px 5px 5px', width: '32px' },
    }

    this.uuid = uuid()
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Hoverable
          hoverStyle={{ cursor: 'default' }}
          renderContent={({ hovered }) => (
            <div style={this.styles.container}>
              <div style={this.styles.textStyle} data-tip data-for={this.uuid}>
                {truncateMiddle(this.props.value, 40, 19)}
              </div>
              {hovered ? (
                <div style={this.styles.clipboardButton}>
                  <CopyToClipboard value={this.props.value} />
                </div>
              ) : (
                <div style={this.styles.clipboardButton} />
              )}
            </div>
          )}
        />
        <ReactTooltip id={this.uuid} place="top" effect="solid">
          {this.props.value}
        </ReactTooltip>
      </div>
    )
  }
}

NodeViewDetailSrn.propTypes = {
  value: PropTypes.string,
}

export default NodeViewDetailSrn
