export const CREATE_POLICY = 'app/ControlFrameworkData/CREATE_POLICY'
export const CREATE_POLICY_SUCCESS =
  'app/ControlFrameworkData/CREATE_POLICY_SUCCESS'
export const UPDATE_CONTROL_GROUP_SUCCESS =
  'app/ControlFrameworkData/UPDATE_CONTROL_GROUP_SUCCESS'
export const UPDATE_CONTROL_GROUP =
  'app/ControlFrameworkData/UPDATE_CONTROL_GROUP'
export const FETCH_POLICIES = 'app/ControlFrameworkData/FETCH_POLICIES'
export const SET_POLICIES = 'app/ControlFrameworkData/SET_POLICIES'
export const FETCH_CONTROL_GROUPS =
  'app/ControlFrameworkData/FETCH_CONTROL_GROUPS'
export const SET_CONTROL_GROUPS = 'app/ControlFrameworkData/SET_CONTROL_GROUPS'
export const CREATE_CONTROL_GROUP =
  'app/ControlFrameworkData/CREATE_CONTROL_GROUP'
export const CREATE_CONTROL_GROUP_SUCCESS =
  'app/ControlFrameworkData/CREATE_CONTROL_GROUP_SUCCESS'
export const DELETE_CONTROL_GROUP =
  'app/ControlFrameworkData/DELETE_CONTROL_GROUP'
export const DELETE_CONTROL_GROUP_SUCCESS =
  'app/ControlFrameworkData/DELETE_CONTROL_GROUP_SUCCESS'
export const DELETE_POLICY = 'app/ControlFrameworkData/DELETE_POLICY'
export const DELETE_POLICY_SUCCESS =
  'app/ControlFrameworkData/DELETE_POLICY_SUCCESS'
export const UPDATE_POLICY = 'app/ControlFrameworkData/UPDATE_POLICY'
export const UPDATE_POLICY_SUCCESS =
  'app/ControlFrameworkData/UPDATE_POLICY_SUCCESS'
export const ADD_POLICY = 'app/ControlFrameworkData/ADD_POLICY'
export const ADD_POLICY_SUCCESS = 'app/ControlFrameworkData/ADD_POLICY_SUCCESS'
export const SET_POLICY_ERROR = 'app/ControlFrameworkData/SET_POLICY_ERROR'
export const REMOVE_POLICY = 'app/ControlFrameworkData/REMOVE_POLICY'
export const REMOVE_POLICY_SUCCESS =
  'app/ControlFrameworkData/REMOVE_POLICY_SUCCESS'
export const CLONE_FRAMEWORK = 'app/ControlFrameworkData/CLONE_FRAMEWORK'
export const CLONE_FRAMEWORK_SUCCESS =
  'app/ControlFrameworkData/CLONE_FRAMEWORK_SUCCESS'
export const SET_FRAMEWORK_ENABLED =
  'app/ControlFrameworkData/SET_FRAMEWORK_ENABLED'
export const SET_FRAMEWORK_ENABLED_SUCCESS =
  'app/ControlFrameworkData/SET_FRAMEWORK_ENABLED_SUCCESS'

export const TOGGLE_ENABLED_ON_SWIMLANES =
  'app/ControlFrameworkData/TOGGLE_ENABLED_ON_SWIMLANES'
export const TOGGLE_ENABLED_ON_SWIMLANES_SUCCESS =
  'app/ControlFrameworkData/TOGGLE_ENABLED_ON_SWIMLANES_SUCCESS'
export const TOGGLE_SWIMLANE_ON_CFS =
  'app/ControlFrameworkData/TOGGLE_SWIMLANE_ON_CFS'
export const TOGGLE_SWIMLANE_ON_CFS_SUCCESS =
  'app/ControlFrameworkData/TOGGLE_SWIMLANE_ON_CFS_SUCCESS'
export const TOGGLE_SWIMLANE_ON_CFS_ERROR =
  'app/ControlFrameworkData/TOGGLE_SWIMLANE_ON_CFS_ERROR'
