/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import languageProviderReducer from 'containers/LanguageProvider/reducer'

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers, history) {
  return combineReducers({
    router: connectRouter(history),
    language: languageProviderReducer,
    ...injectedReducers,
  })
}
