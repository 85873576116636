import React from 'react'

import { Input } from 'reactstrap'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import { PlainCheckbox } from 'components/Checkbox'

import SubmitFormBaseComponent, {
  DefaultFieldControlContainer,
} from './SubmitFormBaseComponent'

import messages from '../messages'
import { isValidPEM } from '../utils'

export default class SubmitFormEncryptionSection extends SubmitFormBaseComponent {
  constructor(props) {
    super(props)
    this.styles = {
      ...this.styles,
      publicKeyInputHide: {
        maxHeight: '0px',
        overflow: 'hidden',
        transition: 'max-height 0.5s',
      },
      publicKeyInputShow: {
        maxHeight: '800px',
        transition: 'max-height 0.5s',
      },
    }
  }

  renderTitleText = () => {
    return (
      <DynamicFormattedMessage {...messages.submitFormIncludeEncryptionLabel} />
    )
  }

  renderHelpContent = () => {
    return (
      <span>
        <DynamicFormattedMessage
          {...messages.submitFormIncludeEncryptionInstructions}
        />
      </span>
    )
  }

  setEncryptionEnabled = newVal => {
    this.props.setFormFields({ encryptionEnabled: newVal })
  }

  setPublicKey = event => {
    this.props.setFormFields({ publicKey: (event.target.value || '').trim() })
  }

  validatePublicKey = ({ target: { value } }) => {
    if (this.props.formValues.encryptionEnabled) {
      if (!this.props.formValues.publicKey) {
        this.props.setFormErrors({
          encryptionEnabled:
            'Public key must be present if encyrption is enabeld',
        })
      } else if (!isValidPEM(value)) {
        this.props.setFormErrors({
          encryptionEnabled: 'Public key is invalid format must be pem format',
          publicKey: 'Public key is invalid format must be pem format',
        })
      }
    }
  }

  renderFieldControls = () => {
    return (
      <DefaultFieldControlContainer>
        <div>
          <PlainCheckbox
            checked={this.props.formValues.encryptionEnabled}
            onChange={this.setEncryptionEnabled}
          />
          <DynamicFormattedMessage
            {...messages.submitFormIncludeEncryptSamplesLabel}
          />{' '}
        </div>
        <div
          style={{
            ...this.styles.publicKeyInput,
            ...(this.props.formValues.encryptionEnabled
              ? this.styles.publicKeyInputShow
              : this.styles.publicKeyInputHide),
          }}
        >
          <div
            style={{
              marginTop: '8px',
              ...(this.props.formErrors.encryptionEnabled &&
                this.styles.formHeadingErrors),
            }}
          >
            <label>
              <DynamicFormattedMessage
                {...messages.submitFormIncludePublicKeyLabel}
              />
              <this.RequiredSpan />
              {this.props.formErrors.publicKey && (
                <span>
                  <b>
                    {' '}
                    <DynamicFormattedMessage
                      {...messages.submitFormIncludeValidPemWarning}
                    />
                  </b>
                </span>
              )}
            </label>
          </div>
          <div>
            <Input
              type="textarea"
              name="publicKey"
              value={this.props.formValues.publicKey}
              onChange={this.setPublicKey}
              onBlur={this.validatePublicKey}
              style={{
                width: '100%',
                fontFamily: 'monospace',
                fontSize: '12px',
                height: '180px',
              }}
            />
          </div>
        </div>
      </DefaultFieldControlContainer>
    )
  }
}
