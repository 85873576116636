export const LAYERS = {
  BASE: 1,
  SECONDARY_NAV: 400,
  NAV: 500,
  TOOLBAR: 600,
  ACTIONS_TOOLBAR: 700,
  DROPDOWN: 800,
  PANEL: 900,
  GLOBAL_NAV: 950,
  MODAL: 1000,
}
