/**
 *
 * NodeViewDetailBool
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles'

class NodeViewDetailBool extends React.PureComponent {
  getValue = () => {
    return this.props.value !== undefined && this.props.value !== null
      ? this.props.value.toString()
      : '-'
  }

  render() {
    const value = this.getValue()
    return <div style={value === '-' ? {} : styles.code}>{value}</div>
  }
}

NodeViewDetailBool.propTypes = {
  value: PropTypes.bool,
}

export default NodeViewDetailBool
