/*
 *
 * RapSheet reducer
 *
 */

import { fromJS } from 'immutable'
import {
  SET_SELECTED_RESOURCE_ALERT_HISTORY,
  SET_RESOURCES_LOADING,
  SET_RESOURCES,
  SET_CATEGORY_ROLLUP_DATA,
  FETCH_FILTERED_ALERTS,
  FETCH_CATEGORY_ROLLUP_DATA,
  TOGGLE_SUNBURST_VIEW,
  UPDATE_RAPSHEET_RESOURCE_MONITOR,
} from './constants'
import { handleActions } from 'redux-actions'
import { List } from 'immutable'

const initialState = fromJS({
  resources: {},
  resourcesLoading: false,
  selectedResourceAlerts: [],
  categoryRollupData: [],
  categoryRollupLoading: false,
  alertListLoading: false,
  isSunburstViewMulti: false,
})

const rapSheetReducer = handleActions(
  {
    [FETCH_FILTERED_ALERTS]: state => state.set('alertListLoading', true),
    [FETCH_CATEGORY_ROLLUP_DATA]: state =>
      state.set('categoryRollupLoading', true),
    [SET_CATEGORY_ROLLUP_DATA]: (state, { payload }) =>
      state
        .set('categoryRollupData', fromJS(payload))
        .set('categoryRollupLoading', false),
    [SET_RESOURCES]: (state, { payload }) =>
      state.set('resources', fromJS(payload)).set('resourcesLoading', false),
    [SET_RESOURCES_LOADING]: (state, { payload }) =>
      state.set('resourcesLoading', payload),
    [SET_SELECTED_RESOURCE_ALERT_HISTORY]: (state, { payload }) =>
      state
        .set('selectedResourceAlerts', fromJS(payload))
        .set('expandedAlert', undefined)
        .set('alertListLoading', false),
    [TOGGLE_SUNBURST_VIEW]: state =>
      state.set('isSunburstViewMulti', !state.get('isSunburstViewMulti')),
    [UPDATE_RAPSHEET_RESOURCE_MONITOR]: (state, { payload }) => {
      if (!state.getIn(['resources', payload.srn])) {
        return state
      }

      return state.updateIn(['resources', payload.srn], resource => {
        let newResource = resource

        if (!newResource.get('sonraiConfig')) {
          newResource = newResource.set('sonraiConfig', List())
        }

        if (
          newResource.get('sonraiConfig').includes('MONITORED') &&
          !payload.isMonitored
        ) {
          newResource = newResource.update('sonraiConfig', oldConfig =>
            oldConfig.filter(val => val !== 'MONITORED')
          )
        } else if (
          !newResource.get('sonraiConfig').includes('MONITORED') &&
          payload.isMonitored
        ) {
          newResource = newResource.update('sonraiConfig', oldConfig =>
            oldConfig.push('MONITORED')
          )
        }

        return newResource
      })
    },
  },
  initialState
)

export default rapSheetReducer
