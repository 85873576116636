import { put, takeLatest, all } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'

import { GET_SECRET_STORE_CONTENT, GET_VAULT_STORE_CONTENT } from './constants'
import { setSecretStoreContent } from './actions'

function* getSecretStoreContent(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        query secretStoreNodeViewQuery($srn: String!) {
          SecretStores(where: { srn: { value: $srn } }) {
            items {
              contains(
                where: {
                  label: { op: EQ, value: "Secret" }
                  active: { op: EQ, value: true }
                }
              ) {
                count
                items {
                  srn
                  name
                  friendlyName
                  ... on Secret {
                    metadata
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        srn: action.payload,
      },
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })
    const content = results.data.SecretStores.items
    if (content === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setSecretStoreContent(_.get(content, 0)))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getVaultStoreContent(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        query secretStoreNodeViewQuery($srn: String!) {
          SecretStores(where: { srn: { value: $srn } }) {
            items {
              contains(
                where: {
                  label: { op: EQ, value: "SecretStore" }
                  active: { op: EQ, value: true }
                }
              ) {
                count
                items {
                  srn
                  name
                  friendlyName
                  ... on Secretstore {
                    type
                    metadata
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        srn: action.payload,
      },
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })
    const content = results.data.SecretStores.items
    if (content === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setSecretStoreContent(_.get(content, 0)))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* secretStoreNodeSaga() {
  yield all([
    yield takeLatest(GET_SECRET_STORE_CONTENT, getSecretStoreContent),
    yield takeLatest(GET_VAULT_STORE_CONTENT, getVaultStoreContent),
  ])
}

export default secretStoreNodeSaga
