import BackgroundImage from 'assets/bkgr_img_bar.png'

export default {
  container: {
    width: '64px',
    height: '100%',
    minHeight: '250px',
    textAlign: 'center',
    margin: 'auto',
    position: 'relative',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.50)',
    zIndex: 10,
  },
  background: {
    height: '100vh',
    width: '64px',
    position: 'absolute',
    top: '0px',
    opacity: '0.8',
  },
  clouds: {
    height: '100vh',
    minHeight: '220px',
    width: '64px',
    top: '0px',
    position: 'absolute',
    backgroundImage: `url(${BackgroundImage})`,
  },
  logo: {
    width: '46.82px',
    height: '32.57px',
    top: '12.78px',
    display: 'inline',
    position: 'absolute',
    left: '8.65px',
  },
  lineTop: {
    height: '1px',
    width: '64px',
    borderBottom: '1px solid #FFFFFF',
    opacity: '0.2',
    position: 'absolute',
    top: '60px',
  },
  topLinks: {
    position: 'absolute',
    top: '60px',
    width: '64px',
    textAlign: 'center',
  },
  bottomLinks: {
    position: 'absolute',
    bottom: '15px',
    width: '64px',
    textAlign: 'center',
  },
}
