/**
 *
 * FeatureFlags
 *
 */

import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import createFlags from 'flag'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectFeatureFlagConfig } from './selectors'
import _ from 'lodash'
import permissionChecker from 'containers/PermissionChecker'
import { getDisplayName } from 'utils/hocHelpers'

const { FlagsProvider, Flag, useFlags } = createFlags()

const featureFlags = WrappedComponent => {
  return class extends React.Component {
    displayName = `FeatureFlags(${getDisplayName(WrappedComponent)})`
    static propTypes = {
      featureFlagConfig: ImmutablePropTypes.map.isRequired,
    }

    render() {
      const flags = {
        ...window.config.feature_flags,
        ...this.props.featureFlagConfig.toJS(),
      }
      _.keys(flags).forEach(flag => {
        flags[flag] = flags[flag] === 'true'
      })
      return (
        <FlagsProvider flags={flags}>
          <WrappedComponent {...this.props} />
        </FlagsProvider>
      )
    }
  }
}

const mapStateToProps = createStructuredSelector({
  featureFlagConfig: selectFeatureFlagConfig,
})

const withConnect = connect(mapStateToProps)

export default compose(permissionChecker, withConnect, featureFlags)

// To use a feature flag, import the <Flag> component and set the "name" and "render" props:
// <Flag
//     name=["path", "to", "name"]
//     render={() => (
//       <Button>Click Me!</Button>
//     )}
//   />
// It will render the content in the "render" prop if the current user has the value of "name" in their "feature_flags" user data

export { Flag, useFlags }
