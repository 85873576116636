/**
 *
 * UserNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { List } from 'immutable'
import { exists } from 'utils/sonraiUtils'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import { DAEMON } from 'utils/constants'
import BigStatWidget from 'components/BigStatWidget'
import TableWidget from 'components/TableWidget'
import Dropdown, {
  DropdownMenu,
  InlineDropdownAnchor,
  DropdownItem,
} from 'components/Dropdown'

import messages from './messages'
import {
  selectActionsPerformed,
  selectIsLoadingActions,
  selectUserContent,
  selectLoadingUserContent,
} from './selectors'
import reducer from './reducer'
import { getActionsPerformed, getUserContent } from './actions'
import sagas from './sagas'

const styles = {
  title: {
    fontSize: '22px',
    fontWeight: '300',
  },
}

export class UserNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: 1,
    }

    props.getActionsPerformed({
      srn: this.props.nodeData.srn,
      range: this.state.filter,
    })

    props.getUserContent(this.props.nodeData.srn)
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getMFACount = () =>
    this.props.userContent.getIn(['hasMFADevice', 'items'], List()).size

  getKeysOwned = () => {
    let rows = []
    this.props.userContent
      .getIn(['isOwnerOf', 'group'], List())
      .toJS()
      .forEach(item => {
        const key = item.key.label || '-'
        item.items.forEach(groupName => {
          rows.push({
            type: key,
            name: groupName.name || '-',
            srn: groupName.srn,
            createdDate: groupName.createdDate || null,
            lastUsed: groupName.lastUsed || null,
          })
        })
      })
    return rows
  }

  getMemberOf = () => {
    let rows = []
    this.props.userContent
      .getIn(['isMemberOf', 'items'], List())
      .toJS()
      .forEach(group => {
        const { hasAttached } = group
        const groupActive = group.active
        const groupName = group.name
        hasAttached.items.forEach(policy => {
          const { active, name, srn } = policy
          rows.push({
            groupName,
            policyActive: active,
            policyName: name,
            srn,
            groupActive,
          })
        })
      })

    this.props.userContent
      .getIn(['hasAttached', 'items'], List())
      .toJS()
      .forEach(policy => {
        const { name, active, srn } = policy
        rows.push({
          groupName: 'Direct Attached',
          policyActive: active,
          policyName: name,
          srn,
          groupActive: '-',
        })
      })

    return rows
  }

  getPerformed = () => {
    const { actionsPerformed } = this.props
    if (!actionsPerformed.isEmpty()) {
      const data = actionsPerformed.map(action => {
        return {
          eventName: action.get('eventName') || '-',
          cityPerformedAt:
            action.get('performedAtCityValue') ||
            (exists(action.get('performedAtCityValues')) &&
              action.get('performedAtCityValues').join(', ')) ||
            '-',
          srn: action.get('srn'),
        }
      })
      return data.toJS()
    } else {
      return []
    }
  }

  handleRangeChange = val => {
    this.setState({
      filter: val.value,
    })
    this.props.getActionsPerformed({
      srn: this.props.nodeData.srn,
      range: val.value,
    })
  }

  getLayouts = () => {
    const numGroups = this.getMemberOf().length
    const numOwned = this.getKeysOwned().length
    const numPerformed = this.getPerformed().length

    return {
      groups: {
        md: { w: 4, h: numGroups > 0 ? 2 : 1 },
        sm: { w: 6, h: numGroups > 0 ? 2 : 1 },
        xs: { w: 4, h: numGroups > 0 ? 2 : 1 },
      },
      mfa: {
        xs: { w: 1, h: 1 },
      },
      owns: {
        md: { w: 3, h: numOwned > 0 ? 2 : 1 },
        sm: { w: 5, h: numOwned > 0 ? 2 : 1 },
        xs: { w: 3, h: numOwned > 0 ? 2 : 1 },
      },
      performed: {
        lg: { w: 8, h: numPerformed > 0 ? 2 : 1 },
        md: { w: 7, h: numPerformed > 0 ? 2 : 1 },
        sm: { w: 6, h: numPerformed > 0 ? 2 : 1 },
        xs: { w: 4, h: numPerformed > 0 ? 2 : 1 },
      },
      graph: {
        lg: { w: 4, h: 3 },
        md: { w: 7, h: 3 },
        sm: { w: 6, h: 3 },
        xs: { w: 4, h: 3 },
      },
    }
  }

  getGridContent = () => {
    return [
      <div key={'performed'}>
        <TableWidget
          nodeViewType={'User'}
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
          data={this.getPerformed()}
          title={
            <div style={{ display: 'inline-block' }}>
              <span style={styles.title}>
                <FormattedMessage {...messages.performed} />{' '}
              </span>

              <Dropdown
                selectedValue={this.state.filter}
                onClick={filter => this.handleRangeChange(filter)}
              >
                <InlineDropdownAnchor />
                <DropdownMenu>
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays1)}
                    value={1}
                  />
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays7)}
                    value={7}
                  />
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays30)}
                    value={30}
                  />
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays90)}
                    value={90}
                  />
                </DropdownMenu>
              </Dropdown>
            </div>
          }
          onClickNodeView={this.onClickNodeView}
          loading={this.props.loading}
        />
      </div>,
      <div key={'groups'}>
        <TableWidget
          nodeViewType={'User'}
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
          data={this.getMemberOf()}
          title={this.props.intl.formatMessage(messages.groupsPolicies)}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.loadingUserContent}
        />
      </div>,
      <div key={'owns'}>
        <TableWidget
          nodeViewType={'User'}
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
          data={this.getKeysOwned()}
          title={this.props.intl.formatMessage(messages.owns)}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.loadingUserContent}
        />
      </div>,
      <div key={'mfa'}>
        <BigStatWidget
          data={this.getMFACount() || '-'}
          title={this.props.intl.formatMessage(messages.MFADevices)}
          loading={this.props.loadingUserContent}
        />
      </div>,
    ]
  }

  render() {
    let nodeData = { ...this.props.nodeData }
    nodeData.ownedBy = this.getKeysOwned()
    if (this.props.nodeData.type === 'User') {
      nodeData.user = {}
      nodeData.user.content = this.props.userContent
      nodeData.user.loading = this.props.loadingUserContent
    }

    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          layouts={this.getLayouts()}
          gridContent={this.getGridContent()}
          nodeData={nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
          renderDetailsTab={false}
        />
      </Fragment>
    )
  }
}

UserNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  setUserProfileTables: PropTypes.func,
  userContent: ImmutablePropTypes.map.isRequired,
  getUserContent: PropTypes.func.isRequired,
  loadingUserContent: PropTypes.bool,
  toggleResourceMonitoring: PropTypes.func,
  userProfile: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  getActionsPerformed: PropTypes.func,
  loading: PropTypes.bool,
  actionsPerformed: PropTypes.object,
  nodeId: PropTypes.string,
  nodeData: PropTypes.object,
  push: PropTypes.func,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  actionsPerformed: selectActionsPerformed,
  loading: selectIsLoadingActions,
  userProfile: selectUserProfile,
  userContent: selectUserContent,
  loadingUserContent: selectLoadingUserContent,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getActionsPerformed,
      setUserProfileTables,
      push,
      getUserContent,
    },
    dispatch
  )
}

const withReducer = injectReducer({ key: 'userNodeViewCardLayout', reducer })

const withSaga = injectSaga({
  key: 'userNodeViewCardLayout',
  saga: sagas,
  mode: DAEMON,
})

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  injectIntl,
  withSaga,
  withReducer
)(UserNodeViewCardLayout)
