/**
 *
 * BarLoadingAnimation
 *
 */

import React from 'react'

class BarLoadingAnimation extends React.PureComponent {
  render() {
    return (
      <div className="spinner2">
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
      </div>
    )
  }
}

BarLoadingAnimation.propTypes = {}

export default BarLoadingAnimation
