import React from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class ConsoleEnabled extends React.PureComponent {
  render() {
    if (this.props.value === 'Loading...') {
      return <div>{this.props.value}</div>
    } else {
      return (
        <div
          style={{
            color: this.props.value
              ? this.props.theme.success
              : this.props.theme.fail,
          }}
        >
          {this.props.value ? `True` : `False`}
        </div>
      )
    }
  }
}

ConsoleEnabled.propTypes = {
  value: PropTypes.bool,
  theme: themeShape,
}

export default themeable(ConsoleEnabled)
