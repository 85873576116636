import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import {
  GET_DATA_STORE_CONTENT,
  ERR_DATA_STORE_CONTENT,
  SET_DATA_STORE_CONTENT,
} from './constants'

const initialState = fromJS({
  dataStoreContent: {
    loading: false,
    error: false,
    data: {},
  },
})

const dataStoreNodeViewCardLayoutReducer = handleActions(
  {
    [GET_DATA_STORE_CONTENT]: state =>
      state
        .setIn(['dataStoreContent', 'loading'], true)
        .setIn(['dataStoreContent', 'error'], false),
    [ERR_DATA_STORE_CONTENT]: state =>
      state
        .setIn(['dataStoreContent', 'loading'], false)
        .setIn(['dataStoreContent', 'error'], true),
    [SET_DATA_STORE_CONTENT]: (state, { payload }) =>
      state
        .setIn(['dataStoreContent', 'loading'], false)
        .setIn(['dataStoreContent', 'error'], false)
        .setIn(['dataStoreContent', 'data'], fromJS(payload.content)),
  },
  initialState
)

export default dataStoreNodeViewCardLayoutReducer
