import React from 'react'

import DefaultFields from './DefaultFields'
import TemplateFields from './components/TemplateFields'

export default function TicketTemplateForm(props) {
  return (
    <div>
      <DefaultFields {...props} />
      <TemplateFields {...props} />
    </div>
  )
}
