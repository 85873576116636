import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import {
  NEXT_STEP,
  PREV_STEP,
  RESET_STATE,
  SELECT_BOT,
  FETCH_ALL_ESCALATIONS,
  FETCH_ALL_ESCALLATIONS_ERROR,
  FETCH_ALL_ESCALLATIONS_SUCCESS,
  SUBMIT_AUTO_REMEDIATE,
  SUBMIT_AUTO_REMEDIATE_ERROR,
  SUBMIT_AUTO_REMEDIATE_NOT_ACCEPTED,
  SUBMIT_AUTO_REMEDIATE_NO_COLLECTOR_MATCHED,
  SUBMIT_AUTO_REMEDIATE_SUCCESS,
} from './constants'
export const initialState = fromJS({
  allEscalations: {
    loading: false,
    error: false,
    data: null,
  },
  step: 1, // which step the wizard at
  selectedBot: null,
  submitAutoRemediateStatus: {
    loading: false,
    notAccepted: false,
    noCollectorMatched: false,
    error: false,
  },
})

const ticketAutoRemediateWizard = handleActions(
  {
    [NEXT_STEP]: state => state.set('step', state.get('step') + 1),
    [PREV_STEP]: state => state.set('step', state.get('step') - 1),
    [RESET_STATE]: state =>
      initialState.merge(
        fromJS({ allEscalations: state.get('allEscalations') })
      ),
    [SELECT_BOT]: (state, { payload }) => state.set('selectedBot', payload),

    [FETCH_ALL_ESCALATIONS]: state =>
      state
        .setIn(['allEscalations', 'loading'], true)
        .setIn(['allEscalations', 'error'], false),
    [FETCH_ALL_ESCALLATIONS_ERROR]: state =>
      state
        .setIn(['allEscalations', 'loading'], false)
        .setIn(['allEscalations', 'error'], true),
    [FETCH_ALL_ESCALLATIONS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['allEscalations', 'loading'], false)
        .setIn(['allEscalations', 'error'], false)
        .setIn(['allEscalations', 'data'], fromJS(payload.data)),
    [SUBMIT_AUTO_REMEDIATE]: state =>
      state
        .setIn(['submitAutoRemediateStatus', 'loading'], true)
        .setIn(['submitAutoRemediateStatus', 'error'], false)
        .setIn(['submitAutoRemediateStatus', 'notAccepted'], false)
        .setIn(['submitAutoRemediateStatus', 'noCollectorMatched'], false),
    [SUBMIT_AUTO_REMEDIATE_ERROR]: state =>
      state
        .setIn(['submitAutoRemediateStatus', 'loading'], false)
        .setIn(['submitAutoRemediateStatus', 'error'], true)
        .setIn(['submitAutoRemediateStatus', 'notAccepted'], false)
        .setIn(['submitAutoRemediateStatus', 'noCollectorMatched'], false),
    [SUBMIT_AUTO_REMEDIATE_NOT_ACCEPTED]: state =>
      state
        .setIn(['submitAutoRemediateStatus', 'loading'], false)
        .setIn(['submitAutoRemediateStatus', 'error'], false)
        .setIn(['submitAutoRemediateStatus', 'notAccepted'], true)
        .setIn(['submitAutoRemediateStatus', 'noCollectorMatched'], false),
    [SUBMIT_AUTO_REMEDIATE_SUCCESS]: state =>
      state
        .setIn(['submitAutoRemediateStatus', 'loading'], false)
        .setIn(['submitAutoRemediateStatus', 'error'], false)
        .setIn(['submitAutoRemediateStatus', 'notAccepted'], false)
        .setIn(['submitAutoRemediateStatus', 'noCollectorMatched'], false),
    [SUBMIT_AUTO_REMEDIATE_NO_COLLECTOR_MATCHED]: state =>
      state
        .setIn(['submitAutoRemediateStatus', 'loading'], false)
        .setIn(['submitAutoRemediateStatus', 'error'], false)
        .setIn(['submitAutoRemediateStatus', 'notAccepted'], false)
        .setIn(['submitAutoRemediateStatus', 'noCollectorMatched'], true),
  },
  initialState
)

export default ticketAutoRemediateWizard
