import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import FormLabel from 'components/FormLabel'
import FormErrorMessage from './FormErrorMessage'
import TemplateField from './TemplateField'

import { fieldSelectInitialState } from '../reducers'
import messages from '../messages'

export default function TemplateFields(props) {
  const fields = props.selectedTicketTemplate.fields

  // if there are no fields defined, display error message
  if (!fields) {
    return (
      <div style={{ color: 'red' }}>
        <DynamicFormattedMessage {...messages} />
      </div>
    )
  }

  // render an input for each field from template
  return fields.map((field, i) => {
    // don't show the required star for checkboxes that are required cause
    // it might look like something the user has to click like the "I agree"
    // checkbox on some installers.
    const required = field.type != 'checkbox' && field.required

    return (
      <div key={`template_field_${i}`}>
        <br />
        <FormLabel style={{ margin: '0rem' }} required={required}>
          {field.name}
        </FormLabel>
        <TemplateField
          fieldDefinition={field}
          fieldState={props.ticketTemplateFields.get(
            field.name,
            fieldSelectInitialState
          )}
          fetchTicketTemplateFieldSelect={props.fetchTicketTemplateFieldSelect}
          setTicketTemplateFieldValue={props.setTicketTemplateFieldValue}
        />
        {props.formErrors[field.name] && (
          <FormErrorMessage error={props.formErrors[field.name]} />
        )}
      </div>
    )
  })
}

TemplateFields.propTypes = {
  fetchTicketTemplateFieldSelect: PropTypes.func.isRequired,
  setTicketTemplateFieldValue: PropTypes.func.isRequired,
  ticketTemplateFields: ImmutablePropTypes.map,
  selectedTicketTemplate: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        required: PropTypes.bool,
        type: PropTypes.string.isRequired,
        select: PropTypes.shape({
          labelFields: PropTypes.arrayOf(PropTypes.string),
          valueField: PropTypes.string,
          search: PropTypes.string,
          path: PropTypes.arrayOf(PropTypes.string),
        }),
      })
    ),
  }),
}
