import { put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import { GROUP_NODE_VIEW_QUERY } from 'static-queries'

import { GET_GROUP_CONTENT } from './constants'
import { setGroupContent } from './actions'

function* getGroupContent(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${GROUP_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Groups.items
    if (content === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setGroupContent(_.get(content, 0)))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* groupNodeSaga() {
  yield takeLatest(GET_GROUP_CONTENT, getGroupContent)
}

export default groupNodeSaga
