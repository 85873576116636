import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'

import { getNodeViewPushParams, truncateMiddle } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import TextLink from 'components/TextLink'
import NodeViewDetails from './NodeViewDetails'

class PreviewCard extends Component {
  styles = {
    container: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
      gridTemplateAreas: '"header" "body" "children"',
      height: '100%',
    },
    header: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gridArea: 'header',
    },
    body: {
      gridArea: 'body',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    children: {
      gridArea: 'children',
    },
    nameContainer: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ':hover': {
        textDecoration: 'underline',
      },
    },
    name: {
      fontSize: '28px',
      fontWeight: '200',
    },
    headerContent: {
      marginTop: '1.25rem',
    },
    iconMenu: {
      display: 'flex',
      alignSelf: 'flex-start',
      alignItems: 'center',
    },
  }

  render() {
    const selectedSrn = this.props.nodeData.srn
    return (
      <div style={this.styles.container}>
        <div style={this.styles.header}>
          <div style={this.styles.nameContainer}>
            <TextLink
              style={this.styles.name}
              title="Click to navigate to node details"
              to={getNodeViewPushParams(
                selectedSrn,
                getTypeFromSrn(selectedSrn)
              )}
            >
              {truncateMiddle(
                this.props.nodeData.friendlyName ||
                  this.props.nodeData.name ||
                  this.props.nodeData.srn
              )}
            </TextLink>
          </div>

          <div style={this.styles.iconMenu}>{this.props.actions}</div>
        </div>
        <div style={this.styles.body}>
          <NodeViewDetails
            nodeData={this.props.nodeData}
            fields={this.props.fields}
            style={this.styles.headerContent}
            detailStyle={{ padding: 0, marginBottom: '0.5em' }}
          />
        </div>
        <div style={this.styles.children}>{this.props.children}</div>
      </div>
    )
  }
}

PreviewCard.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  nodeData: PropTypes.object,
  fields: PropTypes.array,
}

export default Radium(PreviewCard)
