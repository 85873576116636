import Color from 'color'
import _ from 'lodash'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { fromJS, Map, List } from 'immutable'
import { getFields } from 'query-builder'
import { getNameFromSrn, exists } from 'utils/sonraiUtils'
import { DATA_TYPES_FOR_CRM, SEVERITY_CATEGORIES } from 'appConstants'

export const getCoordConf = (value, theme) => {
  let coordinates = null
  let type = ''
  let label = ''

  switch (value) {
    // case 'us-east1':
    //   coordinates = [4.5, 50.5]
    //   label = 'US East 1'
    //   type = 'GCP'
    //   break
    //// googles

    case 'us-asia1':
      coordinates = [120.5, 24]
      label = 'Asia East 1'
      type = 'GCP'
      break
    case 'us-asia2':
      coordinates = [114, 22.2]
      label = 'Asia East 2'
      type = 'GCP'
      break
    case 'asia-northeast1':
      coordinates = [139.2, 35.3]
      label = 'Asia North East 1'
      type = 'GCP'
      break
    case 'asia-northeast2':
      coordinates = [135.3, 34.6]
      label = 'Asia North East 2'
      type = 'GCP'
      break
    case 'asia-south1':
      coordinates = [73.3, 19.5]
      label = 'Asia South 1'
      type = 'GCP'
      break
    case 'asia-southeast1':
      coordinates = [104.2, 1.2]
      label = 'Asia South East 1'
      type = 'GCP'
      break
    case 'australia-southeast1':
      coordinates = [150.9, -34]
      label = 'Australia South East East 1'
      type = 'GCP'
      break
    case 'europe-north1':
      coordinates = [27, 60.5]
      label = 'Europe North 1'
      type = 'GCP'
      break
    case 'europe-west1':
      coordinates = [15, 50]
      label = 'Europe West 1'
      type = 'GCP'
      break
    case 'europe-west2':
      coordinates = [0.5, 51.5]
      label = 'Europe West 2'
      type = 'GCP'
      break
    case 'europe-west3':
      coordinates = [8.5, 50.2]
      label = 'Europe West 3'
      type = 'GCP'
      break
    case 'europe-west4':
      coordinates = [7.2, 53.6]
      label = 'Europe West 4'
      type = 'GCP'
      break
    case 'europe-west6':
      coordinates = [8.6, 47]
      label = 'Europe West 6'
      type = 'GCP'
      break
    case 'northamerica-northeast1':
      coordinates = [-73.56, 45.5]
      label = 'North America North East 1'
      type = 'GCP'
      break
    case 'southamerica-east1':
      coordinates = [-73.56, 45.5]
      label = 'South America East 1'
      type = 'GCP'
      break
    case 'us-central1':
      coordinates = [-93, 39.96]
      label = 'US Central 1'
      type = 'GCP'
      break
    case 'us-east1':
      coordinates = [-81.2, 34]
      label = 'US East 1'
      type = 'GCP'
      break
    case 'us-east4':
      coordinates = [-78.45, 38.9]
      label = 'US East 4'
      type = 'GCP'
      break
    case 'us-west1':
      coordinates = [-120, 45.5]
      label = 'US West 1'
      type = 'GCP'
      break
    case 'us-west2':
      coordinates = [-118, 33.8]
      label = 'US West 2'
      type = 'GCP'
      break
    ///////////////////////////////////////////////////////////////
    case 'us-east-1':
      coordinates = [-78.45, 38.13]
      label = 'US East 1'
      type = 'AWS'
      break
    case 'us-east-2':
      coordinates = [-83.0, 39.96]
      label = 'US East 2'
      type = 'AWS'
      break
    case 'us-west-1':
      coordinates = [-121.96, 37.35]
      label = 'US West 1'
      type = 'AWS'
      break
    case 'us-west-2':
      coordinates = [-123.88, 46.15]
      label = 'US West 2'
      type = 'AWS'
      break
    case 'ap-northeast-1':
      coordinates = [137.42, 35.41]
      label = 'Asia Pacific NorthEast 1'
      type = 'AWS'
      break
    case 'ap-northeast-2':
      coordinates = [126.98, 37.56]
      label = 'Asia Pacific NorthEast 2'
      type = 'AWS'
      break
    case 'ap-northeast-3':
      coordinates = [125.5, 34.69]
      label = 'Asia Pacific NorthEast 3'
      type = 'AWS'
      break
    case 'ap-south-1':
      coordinates = [72.88, 19.08]
      label = 'Asia Pacific South 1'
      type = 'AWS'
      break
    case 'ap-southeast-1':
      coordinates = [103.8, 1.37]
      label = 'Asia Pacific SouthEast 1'
      type = 'AWS'
      break
    case 'ap-southeast-2':
      coordinates = [151.2, -33.86]
      label = 'Asia Pacific SouthEast 2'
      type = 'AWS'
      break
    case 'ca-central-1':
      coordinates = [-73.56, 45.5]
      label = 'Canada Central 1'
      type = 'AWS'
      break
    case 'cn-north-1':
      coordinates = [116.68, 39.54]
      label = 'China North 1'
      type = 'AWS'
      break
    case 'cn-northwest-1':
      coordinates = [106.15, 37.19]
      label = 'China NorthWest 1'
      type = 'AWS'
      break
    case 'eu-central-1':
      coordinates = [8.6833, 50.1167]
      label = 'Europe Central 1'
      type = 'AWS'
      break
    case 'eu-west-1':
      coordinates = [-7.69, 53.14]
      label = 'Europe West 1'
      type = 'AWS'
      break
    case 'eu-west-2':
      coordinates = [-0.12, 51.5]
      label = 'Europe West 2'
      type = 'AWS'
      break
    case 'eu-west-3':
      coordinates = [2.35, 48.85]
      label = 'Europe West 3'
      type = 'AWS'
      break
    case 'sa-east-1':
      coordinates = [-46.38, -23.34]
      label = 'South America'
      type = 'AWS'
      break
    case 'eastus':
      coordinates = [-79.8164, 37.3719]
      label = 'East US'
      type = 'Azure'
      break
    case 'eastus2':
      coordinates = [-78.3889, 36.6681]
      label = 'East US 2'
      type = 'Azure'
      break
    case 'usgovvirginia':
      coordinates = [-81.66, 37.98]
      label = 'US Gov Virginia'
      type = 'Azure'
      break
    case 'usdodeast':
      coordinates = [-81.66, 37.98]
      label = 'US DoD East'
      type = 'Azure'
      break
    case 'centralus':
      coordinates = [-95.63, 41.91]
      label = 'Central US'
      type = 'Azure'
      break
    case 'usgoviowa':
      coordinates = [-93.6208, 41.5908]
      label = 'US Gov Iowa'
      type = 'Azure'
      break
    case 'usdodcentral':
      coordinates = [-95.63, 41.91]
      label = 'US DoD Central'
      type = 'Azure'
      break
    case 'northcentralus':
      coordinates = [-87.6278, 41.8819]
      label = 'North Central US'
      type = 'Azure'
      break
    case 'southcentralus':
      coordinates = [-98.5, 29.4167]
      label = 'South Central US'
      type = 'Azure'
      break
    case 'usgovtexas':
      coordinates = [-104.56, 31.09]
      label = 'US Gov Texas'
      type = 'Azure'
      break
    case 'westcentralus':
      coordinates = [-110.234, 40.89]
      label = 'West Central US'
      type = 'Azure'
      break
    case 'westus':
      coordinates = [-122.417, 37.783]
      label = 'West US'
      type = 'Azure'
      break
    case 'westus2':
      coordinates = [-119.852, 47.233]
      label = 'West US 2'
      type = 'Azure'
      break
    case 'usgovarizona':
      coordinates = [-114.17, 34.14]
      label = 'US Gov Arizona'
      type = 'Azure'
      break
    case 'canadaeast':
      coordinates = [-71.217, 46.817]
      label = 'Canada East'
      type = 'Azure'
      break
    case 'canadacentral':
      coordinates = [-79.383, 43.653]
      label = 'Canada Central'
      type = 'Azure'
      break
    case 'brazilsouth':
      coordinates = [-46.633, -23.55]
      label = 'Brazil South'
      type = 'Azure'
      break
    case 'northeurope':
      coordinates = [-6.2597, 53.3478]
      label = 'North Europe'
      type = 'Azure'
      break
    case 'westeurope':
      coordinates = [4.9, 52.3667]
      label = 'West Europe'
      type = 'Azure'
      break
    case 'francecentral':
      coordinates = [2.373, 46.3772]
      label = 'France Central'
      type = 'Azure'
      break
    case 'francesouth':
      coordinates = [2.1972, 43.8345]
      label = 'France South'
      type = 'Azure'
      break
    case 'ukwest':
      coordinates = [-3.084, 53.427]
      label = 'UK West'
      type = 'Azure'
      break
    case 'uksouth':
      coordinates = [-0.799, 50.941]
      label = 'UK South'
      type = 'Azure'
      break
    case 'germanycentral':
      coordinates = [8.49, 50.12]
      label = 'Germany Central'
      type = 'Azure'
      break
    case 'germanynortheast':
      coordinates = [11.5, 52.12]
      label = 'Germany NorthEast'
      type = 'Azure'
      break
    case 'germanynorth':
      coordinates = [10.35, 53.088346]
      label = 'Germany North'
      type = 'Azure'
      break
    case 'germanywestcentral':
      coordinates = [10.88, 50.85]
      label = 'Germany West Central'
      type = 'Azure'
      break
    case 'switzerlandnorth':
      coordinates = [8.46, 47.37]
      label = 'Switzerland North'
      type = 'Azure'
      break
    case 'switzerlandwest':
      coordinates = [6.1, 46.2]
      label = 'Switzerland West'
      type = 'Azure'
      break
    case 'norwayeast':
      coordinates = [10.64, 59.89]
      label = 'Norway East'
      type = 'Azure'
      break
    case 'norwaywest':
      coordinates = [5.14, 60.36]
      label = 'Norway West'
      type = 'Azure'
      break
    case 'southeastasia':
      coordinates = [103.833, 1.283]
      label = 'SouthEast Asia'
      type = 'Azure'
      break
    case 'eastasia':
      coordinates = [114.188, 22.267]
      label = 'East Asia'
      type = 'Azure'
      break
    case 'australiaeast':
      coordinates = [151.2094, -33.86]
      label = 'Australia East'
      type = 'Azure'
      break
    case 'australiasoutheast':
      coordinates = [144.9631, -37.8136]
      label = 'Australia SouthEast'
      type = 'Azure'
      break
    case 'australiacentral':
      coordinates = [149.1244, -35.3075]
      label = 'Australia Central'
      type = 'Azure'
      break
    case 'australiacentral2':
      coordinates = [149.1244, -35.3075]
      label = 'Australia Central 2'
      type = 'Azure'
      break
    case 'chinaeast':
      coordinates = [121.19, 31.22]
      label = 'China East'
      type = 'Azure'
      break
    case 'chinaeast2':
      coordinates = [121.19, 31.22]
      label = 'China East 2'
      type = 'Azure'
      break
    case 'chinanorth':
      coordinates = [116.11, 39.93]
      label = 'China North'
      type = 'Azure'
      break
    case 'chinanorth2':
      coordinates = [116.11, 39.93]
      label = 'China North 2'
      type = 'Azure'
      break
    case 'centralindia':
      coordinates = [73.9197, 18.5822]
      label = 'Central India'
      type = 'Azure'
      break
    case 'westindia':
      coordinates = [72.868, 19.088]
      label = 'West India'
      type = 'Azure'
      break
    case 'southindia':
      coordinates = [80.1636, 12.9822]
      label = 'South India'
      type = 'Azure'
      break
    case 'japaneast':
      coordinates = [139.77, 35.68]
      label = 'Japan East'
      type = 'Azure'
      break
    case 'japanwest':
      coordinates = [135.5022, 34.6939]
      label = 'Japan West'
      type = 'Azure'
      break
    case 'koreacentral':
      coordinates = [126.978, 37.5665]
      label = 'Korea Central'
      type = 'Azure'
      break
    case 'koreasouth':
      coordinates = [129.0756, 35.1796]
      label = 'Korea South'
      type = 'Azure'
      break
    case 'southafricawest':
      coordinates = [18.37, -33.9]
      label = 'South Africa West'
      type = 'Azure'
      break
    case 'southafricanorth':
      coordinates = [28.0, -26.17]
      label = 'South Africa North'
      type = 'Azure'
      break
    case 'uaecentral':
      coordinates = [54.41, 24.38]
      label = 'UAE Central'
      type = 'Azure'
      break
    case 'uaenorth':
      coordinates = [55.08, 25.07]
      label = 'UAE North'
      type = 'Azure'
      break
  }

  return {
    coordinates,
    color:
      theme !== undefined
        ? type === 'AWS'
          ? theme.primaryAws
          : type === 'Azure'
          ? theme.primaryAzure
          : type === 'GCP'
          ? theme.primaryGcp
          : '#000000'
        : '#000000',
    type,
    label,
  }
}

//Value //Precision - to which decimal place.
//Ex: round(23.13323, 2) => 23.13
export const round = (value, precision) =>
  value !== 0
    ? Math.round(value * Math.pow(10, precision || 0)) /
      Math.pow(10, precision || 0)
    : 0

const getRGB = (level, theme) => {
  switch (level) {
    case 1:
      return Color.rgb(theme.info)
    case 2:
      return Color.rgb(theme.warn)
    case 3:
      return Color.rgb(theme.fail)
    default:
      return Color('#fff')
  }
}

export const getAlertColors = (level, theme) => {
  let color = getRGB(level, theme)

  return {
    color: color.darken(0.4).hex(),
    backgroundColor: color.lighten(0.25).hex(),
  }
}

export const isEven = num => num % 2 !== 1

export const getTimes = () => {
  let times = []
  for (let i = 0; i < 2; i++) {
    let am = true
    if (i === 1) {
      am = false
    }
    for (let j = 0; j < 12; j++) {
      let hour
      if (j === 0) {
        hour = 12
      } else {
        hour = j
      }
      for (let x = 0; x < 4; x++) {
        let min
        if (x === 0) {
          min = null
        } else if (x === 1) {
          min = 15
        } else if (x === 2) {
          min = 30
        } else if (x === 3) {
          min = 45
        }

        times.push({ hour, min, am })
      }
    }
  }
  return times
}

const CRUD = [
  'DataCreate',
  'DataRead',
  'DataUpdate',
  'DataDelete',
  'ProtectionCreate',
  'ProtectionRead',
  'ProtectionUpdate',
  'ProtectionDelete',
]

const getCRUDActionMap = (action, type, resourceType = '') => {
  if (exists(type)) {
    if (type === 'hasPermissionTo') {
      switch (action) {
        case 'DataCreate':
          return 'Create Data'
        case 'DataRead':
          return 'Read Data'
        case 'DataUpdate':
          return 'Update Data'
        case 'DataDelete':
          return 'Delete Data'
        case 'ProtectionCreate':
          return 'Create Protection'
        case 'ProtectionRead':
          return 'Read Protection'
        case 'ProtectionUpdate':
          return 'Update Protection'
        case 'ProtectionDelete':
          return 'Delete Protection'
        default:
          return action
      }
    } else {
      if (DATA_TYPES_FOR_CRM.includes(resourceType.toLowerCase())) {
        switch (action) {
          case 'DataCreate':
            return 'Data created by'
          case 'DataRead':
            return 'Data read by'
          case 'DataUpdate':
            return 'Data updated by'
          case 'DataDelete':
            return 'Data deleted by'
          case 'ProtectionCreate':
            return 'Protection created by'
          case 'ProtectionRead':
            return 'Protection read by'
          case 'ProtectionUpdate':
            return 'Protection updated by'
          case 'ProtectionDelete':
            return 'Protection deleted by'
          default:
            return action
        }
      } else {
        switch (action) {
          case 'DataCreate':
            return 'Created Data'
          case 'DataRead':
            return 'Read Data'
          case 'DataUpdate':
            return 'Updated Data'
          case 'DataDelete':
            return 'Deleted Data'
          case 'ProtectionCreate':
            return 'Created Protection'
          case 'ProtectionRead':
            return 'Read Protection'
          case 'ProtectionUpdate':
            return 'Updated Protection'
          case 'ProtectionDelete':
            return 'Deleted Protection'
          default:
            return action
        }
      }
    }
  }
  return action
}

export const getFriendlyActionName = (action, type, resourceType = '') => {
  if (!action) {
    return '-'
  }

  if (CRUD.includes(action)) {
    return getCRUDActionMap(action, type, resourceType)
  }

  if (type !== 'hasPermissionTo') {
    if (DATA_TYPES_FOR_CRM.includes(resourceType.toLowerCase())) {
      switch (action) {
        case 'Resume':
          return 'Resumed by'
        case 'Activity':
        case 'Action':
          return 'Interacted with by'
        case 'Delete':
          return 'Deleted by'
        case 'Stop':
          return 'Stopped by'
        case 'Accept':
          return 'Accepted by'
        case 'Analytic':
          return 'Performed Analytic by'
        case 'Configure':
          return 'Configured by'
        case 'Message':
          return 'Messaged by'
        case 'Create':
          return 'Created by'
        case 'Start':
          return 'Started by'
        case 'Archive':
          return 'Archived by'
        case 'Restore':
          return 'Restored by'
        case 'Update':
          return 'Updated by'
        case 'Read':
          return 'Read by'
        case 'Login':
          return 'Logged in to by'
        case 'Failure':
          return 'Failed'
        case 'Authentication':
          return 'Authenticated by'
        case 'Logout':
          return 'Logged out by'
        case 'Audit':
          return 'Audited by'
        default:
          return action
      }
    } else {
      switch (action) {
        case 'Resume':
          return 'Resumed'
        case 'Activity':
        case 'Action':
          return 'Interacted with'
        case 'Delete':
          return 'Deleted'
        case 'Stop':
          return 'Stopped'
        case 'Accept':
          return 'Accepted'
        case 'Analytic':
          return 'Performed Analytic'
        case 'Configure':
          return 'Configured'
        case 'Message':
          return 'Messaged'
        case 'Create':
          return 'Created'
        case 'Start':
          return 'Started'
        case 'Archive':
          return 'Archived'
        case 'Restore':
          return 'Restored'
        case 'Update':
          return 'Updated'
        case 'Read':
          return 'Read'
        case 'Login':
          return 'Logged in to'
        case 'Failure':
          return 'Failed'
        case 'Authentication':
          return 'Authenticated'
        case 'Logout':
          return 'Logged out'
        case 'Audit':
          return 'Audited'
        default:
          return action
      }
    }
  } else {
    switch (action) {
      case 'Activity':
      case 'Action':
        return 'Interact with'
      case 'Analytic':
        return 'Perform Analytic'
      case 'Failure':
        return 'Fail'
      case 'Authentication':
        return 'Authenticate'
      default:
        return action
    }
  }
}

export const getCrmPropertySubtitle = (srn, keyName) => {
  if (srnIsCRMActionable(srn)) {
    switch (keyName) {
      case 'active':
        return 'Detect if data is deleted or re-added'
      case 'auditEnabled':
        return 'Detect change in audit configuration settings'
      case 'encryptionEnabled':
        return 'Detect changes in encryption settings'
      case 'isPublic':
        return 'Detect change in the public ip settings of the data store'
      case 'publicRead':
        return 'Detect changes in public exposure of the data'
      case 'publicWrite':
        return 'Detect changes in the public write configuration of the data'
      case 'versioningEnabled':
        return 'Detect changes in versioning configuration of the data'
      case 'writeOnce':
        return 'Detect changes in write once configuration of the data'
      case 'version':
        return 'Detect a version change of the data store software'
      case 'actionableBy':
        return 'Detect a new identity which can configure or access the data outside of the learned baseline'
      case 'accessed':
        return 'Detect new identities configuring or accessing the data objects outside the learned baseline'
      case 'accessedFrom':
        return 'Detect access to data from countries outside the learned baseline'
      case 'accessedUsing':
        return 'Detect access to data from user agents outside the learned baseline'
    }
  } else {
    switch (keyName) {
      case 'isConsoleEnabled':
        return 'Detect change in ability to access the cloud console'
      case 'active':
        return 'Detect if identity is deleted or re-enabled'
      case 'hasPermissionTo':
      case 'actionableBy':
        return 'Detect a change in permissions giving additional right to data or datastore configuration'
      case 'accessed':
        return 'Detect access, modification or configuration changes to data and data stores outside of the learned baseline'
      case 'accessedUsing':
        return 'Detect a new useragent outside the learned baseline'
      case 'accessedFrom':
      case 'activeFrom':
        return 'Detect access from countries outside the baseline'
    }
  }
}

export const getMappedBoolLabel = (key, valueIn) => {
  const value = valueIn === 'true' || valueIn === true

  switch (key) {
    case 'active':
      return value ? 'Active' : 'Deleted'
    case 'auditEnabled':
      return value ? 'Audit Enabled' : 'Audit Disabled'
    case 'encryptionEnabled':
      return value ? 'Encrypted' : 'Encryption Disabled'
    case 'versioningEnabled':
      return value ? 'Versioned' : 'Not Versioned'
    case 'isConsoleEnabled':
      return value ? 'Console Enabled' : 'Console Disabled'
    case 'writeOnce':
      return value ? 'Write Once' : 'Write Disabled'
    case 'publicRead':
      return value ? 'Public Readable' : 'Not Public Readable'
    case 'publicWrite':
      return value ? 'Public Writable' : 'Not Public Writable'
    case 'isPublic':
      return value ? 'Can be Public' : 'Private'
    case 'isDefault':
      return value ? 'Default' : 'Custom'
    case 'isGlobal':
      return value ? 'Global' : 'Local'
    case 'isModifiable':
      return value ? 'Modifiable' : 'Not Modifiable'
    case 'isOrganizational':
      return value ? 'Organizational' : 'Not Organizational'
    case 'success':
    case 'succeeded':
      return value ? 'Succeeded' : 'Failed'
    case 'enabled':
      return value ? 'Enabled' : 'Disabled'
    case 'websiteEnabled':
      return value ? 'Website Enabled' : 'Website Disabled'
    default:
      return
  }
}

export const getFriendlyKeyName = keyName => {
  switch (keyName) {
    case 'actionableBy':
      return 'Who and What Can Access'
    case 'accessedBy':
      return 'Who and What Did Access'
    case 'accessedFrom':
      return 'Accessed From Geography'
    case 'accessedUsing':
      return 'Accessed Using User-Agent'
    case 'activeFrom':
      return 'Active From Geography'
    case 'hasPermissionTo':
      return 'Has Permission to Access Data'
    case 'accessed':
      return 'Accessed Data'
    default:
      return (
        _.lowerCase(keyName)
          .split(' ')
          .map(x => _.upperFirst(x))
          .join(' ') || ''
      )
  }
}

export const getPropertyName = params => {
  switch (params.get('keyName')) {
    case 'active':
      if (params.get('boolValue')) {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } was activated`
      } else {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } was deleted`
      }
    case 'isConsoleEnabled':
      if (params.get('boolValue')) {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } can now login via console`
      } else {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } can no longer login via console`
      }
    case 'isMFA':
      if (params.get('boolValue')) {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } now has MFA`
      } else {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } no longer has MFA`
      }
    case 'encryptionEnabled':
      if (params.get('boolValue')) {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } is now encrypted`
      } else {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } is no longer encrypted`
      }
    case 'isPublic':
      if (params.get('boolValue')) {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } now has ${params.getIn(['resource', 'resourceId'])}` // Should be public ip but couldnt figure out how to get that field from resource
      } else {
        return `${params.getIn([
          'resource',
          'name',
        ])} no longer has ${params.getIn(['resource', 'resourceId'])}`
      }
    case 'publicRead':
      if (params.get('boolValue')) {
        return `${params.getIn([
          'resource',
          'name',
        ])} is now publicly accessible`
      } else {
        return `${params.getIn([
          'resource',
          'name',
        ])} is no longer publicly accessible`
      }
    case 'publicWrite':
      if (params.get('boolValue')) {
        return `${params.getIn([
          'resource',
          'name',
        ])} can be written to by anyone`
      } else {
        return `${params.getIn([
          'resource',
          'name',
        ])} can no longer be written to by anyone`
      }
    case 'writeOnce':
      if (params.get('boolValue')) {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } is now set to write once`
      } else {
        return `${params.getIn([
          'resource',
          'name',
        ])} is no longer set to write once`
      }
    case 'version':
    case 'versioningEnabled':
      if (params.get('boolValue')) {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } is now versioned`
      } else {
        return `${
          params.getIn(['resource', 'friendlyName']) ||
          params.getIn(['resource', 'name']) ||
          getNameFromSrn(params.getIn(['resource', 'srn']))
        } is no longer versioned`
      }
  }
}

export const getAlertLevelColor = numericLevel => {
  switch (numericLevel) {
    case 10:
      return '#da1e28'
    case 9:
      return '#da1e28'
    case 8:
      return '#da1e28'
    case 7:
      return '#fc7b1e'
    case 6:
      return '#fc7b1e'
    case 5:
      return '#fc7b1e'
    case 4:
      return '#fdd13a'
    case 3:
      return '#fdd13a'
    case 2:
      return '#fdd13a'
    case 1:
      return '#fdd13a'
    default:
      return '#24a148'
  }
}

export const mapAlertSeverityToHML = numericLevel => {
  if (numericLevel >= 80) {
    return SEVERITY_CATEGORIES.HIGH
  } else if (numericLevel >= 50) {
    return SEVERITY_CATEGORIES.MEDIUM
  } else {
    return SEVERITY_CATEGORIES.LOW
  }
}

export const getAlertLevelColorHML = level => {
  if (level === SEVERITY_CATEGORIES.HIGH) {
    return '#da1e28'
  } else if (level === SEVERITY_CATEGORIES.MEDIUM) {
    return '#fc7b1e'
  } else {
    return '#fdd13a'
  }
}

export const mapEnglishAlertLevelToNumber = level => {
  if (level === 'Critical') {
    return 10
  } else if (level === 'Warn') {
    return 5
  } else {
    return 0
  }
}

export const srnIsCRMActionable = (srn = '') => {
  const type = getTypeFromSrn(srn).toLowerCase()
  return DATA_TYPES_FOR_CRM.includes(type)
}

export const widgetSearchUsesFirstCard = (
  searchId,
  savedSearches,
  searchCardId
) => {
  const fields = getFields(savedSearches, searchId)

  if (fields.isEmpty()) {
    return false
  }

  const rootFieldId = fields
    .find(statement => !statement.get('parentId'), null, Map())
    .get('id')

  return rootFieldId === searchCardId
}

//========================================================//
//                                                        //
//              GET FRIENDLY CRM TICKET NAME              //
//                                                        //
//========================================================//
// TODO: move to formatted strings                        //

const dictDataBeing = {
  Activity: 'was interacted with',
  ProtectionCreate: 'was interacted with',
  Archive: 'was archived',
  ProtectionDelete: 'had protection deleted',
  Create: 'was created',
  Analytic: 'had analytic setup',
  Read: 'was read',
  Stop: 'was stopped',
  Message: 'had message setup',
  Action: 'was interacted with',
  Update: 'was updated',
  Restore: 'was restored',
  Login: 'was authenticated to',
  DataCreate: 'had data created',
  Authentication: 'was authenticated to',
  DataDelete: 'had data deleted',
  DataUpdate: 'had data updated',
  Logout: 'was logged out of',
  Resume: 'was resumed',
  Configure: 'was configured',
  DataRead: 'had data read',
  Accept: 'was accepted',
  Failure: 'was failed',
  Start: 'was started',
  ProtectionUpdate: 'had protection updated',
  Delete: 'was deleted',
  Audit: 'was audited',
}
const dictDataCan = {
  Activity: 'be interacted with',
  ProtectionCreate: 'have protection created',
  Archive: 'be archived',
  ProtectionDelete: 'be archived',
  Create: 'be created',
  Analytic: 'have analytics performed',
  Read: 'be read',
  Stop: 'be stopped',
  Message: 'have message created',
  Action: 'be interacted with',
  Update: 'be updated',
  Restore: 'be updated',
  Login: 'authenticate into',
  DataCreate: 'have new data created',
  Authentication: 'authenticate into',
  DataDelete: 'have data deleted',
  DataUpdate: 'have data updated',
  Logout: 'be logged out of',
  Resume: 'be resumed',
  Configure: 'be configured',
  DataRead: 'have data read',
  Accept: 'be accepted',
  Failure: 'be failed',
  Start: 'be started',
  ProtectionUpdate: 'have protection updated',
  Delete: 'be deleted',
  Audit: 'be audited',
}
const dictIdentityDoing = {
  Activity: ' interacted with',
  ProtectionCreate: 'created protection keys for',
  Archive: 'archived',
  ProtectionDelete: 'deleted protection keys for',
  Create: 'created new data in',
  Analytic: 'created analytics in',
  Read: 'read configuration about',
  Stop: 'stopped service hosting',
  Message: 'created message in',
  Action: 'interacted with',
  Update: 'updated configuration of',
  Restore: 'restored configuration of ',
  Login: 'authenticated to',
  DataCreate: 'created data in',
  Authentication: 'authenticated to',
  DataDelete: 'deleted data from',
  DataUpdate: 'updated data from',
  Logout: 'logged out of',
  Resume: 'resumed service hosting',
  Configure: 'configured service hosting',
  DataRead: 'read data from',
  Accept: 'accepted',
  Failure: 'failed',
  Start: 'started service hosting',
  ProtectionUpdate: 'updated protection keys for',
  Delete: 'deleted',
  Audit: 'audited',
}
const dictIdentityCan = {
  Activity: 'interact with',
  ProtectionCreate: 'create protection keys for',
  Archive: 'archive',
  ProtectionDelete: 'delete protection keys for',
  Create: 'create new data in',
  Analytic: 'create analytics in',
  Read: 'read configuration about',
  Stop: 'stop service hosting',
  Message: 'create message in',
  Action: 'interact with',
  Update: 'update configuration of',
  Restore: 'restore configuration of',
  Login: 'authenticate to',
  DataCreate: 'create data in',
  Authentication: 'authenticate to',
  DataDelete: 'delete data from',
  DataUpdate: 'update data from',
  Logout: 'logout',
  Resume: 'resume service hosting',
  Configure: 'configure service hosting',
  DataRead: 'read data from',
  Accept: 'accept',
  Failure: 'accept',
  Start: 'start service hosting',
  ProtectionUpdate: 'update protection keys for',
  Delete: 'delete',
  Audit: 'audit',
}

const getFriendlyResourceName = params =>
  params.getIn(['resource', 'friendlyName']) ||
  params.getIn(['resource', 'name']) ||
  getNameFromSrn(params.getIn(['resource', 'srn'])) ||
  _.last(params.getIn(['resourceSRN']).split('/')) ||
  '-'

const niceActionName = ({ type, kind, ac }) => {
  if (type === 'data' && kind === 'being') {
    return dictDataBeing[ac]
  } else if (type === 'data' && kind === 'can') {
    return dictDataCan[ac]
  } else if (type === 'identity' && kind === 'doing') {
    return dictIdentityDoing[ac]
  } else if (type === 'identity' && kind === 'can') {
    return dictIdentityCan[ac]
  }
}
const getFriendlyEvidenceField = (evidence, field) => {
  const items = evidence && evidence.get(field) ? evidence.get(field) : List()
  return !items.isEmpty()
    ? items
        .toJS()
        .map(item => getNameFromSrn(item))
        .join(', ')
    : '-'
}

const buildDataTicketName = params => {
  if (
    params.get('ticketKey') === 'accessedBy' ||
    params.get('ticketKey') === 'accessedUsing'
  ) {
    // ACTIONED
    const name = getFriendlyResourceName(params)
    const action = niceActionName({
      type: 'data',
      kind: 'being',
      ac: params.get('actionClassification'),
    })
    const evidence = getFriendlyEvidenceField(
      params.get('evidence'),
      'resourceSet'
    )

    return `${name} ${action} by ${evidence}`
  } else if (
    params.get('ticketKey') === 'activeFrom' ||
    params.get('ticketKey') === 'accessedFrom'
  ) {
    // ACCESSED FROM
    const name = getFriendlyResourceName(params)
    const action = 'was accessed from'
    const evidence = getFriendlyEvidenceField(
      params.get('evidence'),
      'regionSet'
    )

    return `${name} ${action} ${evidence}`
  } else if (
    params.get('ticketKey') === 'hasPermissionTo' ||
    params.get('ticketKey') === 'actionableBy'
  ) {
    // CAN ACTION
    const name = getFriendlyResourceName(params)
    const action = niceActionName({
      type: 'data',
      kind: 'can',
      ac: params.get('actionClassification'),
    })
    const evidence = getFriendlyEvidenceField(
      params.get('evidence'),
      'resourceSet'
    )

    return `${name} has permission to ${action} by ${evidence}`
  } else {
    // CAN BE ACTIONED
    const name = getFriendlyResourceName(params)
    const action = niceActionName({
      type: 'data',
      kind: 'can',
      ac: params.get('actionClassification'),
    })
    const evidence = getFriendlyEvidenceField(
      params.get('evidence'),
      'resourceSet'
    )

    return `${name} can ${action} by ${evidence}`
  }
}

const buildIdentityTicketName = params => {
  if (params.get('ticketKey') === 'accessed') {
    // ACTIONING
    const name = getFriendlyResourceName(params)
    const action = niceActionName({
      type: 'identity',
      kind: 'doing',
      ac: params.get('actionClassification'),
    })
    const evidence = getFriendlyEvidenceField(
      params.get('evidence'),
      'resourceSet'
    )

    return `${name} ${action} ${evidence}`
  } else if (
    params.get('ticketKey') === 'activeFrom' ||
    params.get('ticketKey') === 'accessedFrom'
  ) {
    // ACCESSED FROM
    const name = getFriendlyResourceName(params)
    const action = 'was accessed from'
    const evidence = getFriendlyEvidenceField(
      params.get('evidence'),
      'regionSet'
    )
    return `${name} ${action} ${evidence}`
  } else if (params.get('ticketKey') === 'accessedUsing') {
    const name = getFriendlyResourceName(params)
    const evidenceOne = getFriendlyEvidenceField(
      params.get('evidence'),
      'resourceSet'
    )
    const evidenceTwo = getFriendlyEvidenceField(
      params.get('evidence'),
      'userAgentSet'
    )

    return `${name} accessed ${evidenceOne} using ${evidenceTwo}`
  } else {
    // CAN ACTION
    const name = getFriendlyResourceName(params)
    const action = niceActionName({
      type: 'identity',
      kind: 'can',
      ac: params.get('actionClassification'),
    })
    const evidence = getFriendlyEvidenceField(
      params.get('evidence'),
      'resourceSet'
    )

    return `${name} has permission to ${action} ${evidence}`
  }
}

const getFriendlyCRMTicketName = params => {
  // Switch between identity and data streams using the type of the critical resource
  if (
    params.getIn(['resource', '__typename']) &&
    DATA_TYPES_FOR_CRM.includes(
      params.getIn(['resource', '__typename']).toLowerCase()
    )
  ) {
    return buildDataTicketName(params)
  } else {
    return buildIdentityTicketName(params)
  }
}

const getFriendlyPropertyTicketName = ticket => {
  const evidence = ticket.get('evidence') || Map()
  let newValue
  switch (evidence.get('fieldType')) {
    case 'Boolean':
      newValue = evidence.get('boolValue') || 'false'
      break
    case 'String':
      newValue = evidence.get('value') || ''
      break
    case 'Integer':
      newValue = evidence.get('intValue') || 0
      break
    case 'Long':
      newValue = evidence.get('longValue') || 0
      break
    default:
      // eslint-disable-next-line
        console.error('could not create property ticket name from evidence', evidence)
      return ''
  }

  return `Property ${ticket.get('ticketKey')} changed to ${newValue}`
}

export const getFriendlyTicketName = (ticket, policies) => {
  const ticketKey = ticket.get('ticketKey')
  const ticketType = ticket.get('ticketType')
  if (ticketType === 'Policy') {
    if (ticketKey.includes('ControlPolicy')) {
      const policy = policies.get(ticketKey) || Map()
      return policy.get('title') || ticketKey
    }
  } else if (ticketType === 'Property') {
    return getFriendlyPropertyTicketName(ticket)
  } else if (ticketType === 'Access' || ticketType === 'Activity') {
    return getFriendlyCRMTicketName(ticket)
  } else {
    return ticket.get('title') || '-'
  }
}
