import React, { Fragment } from 'react'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SubmitFormBaseComponent, {
  DefaultFieldControlContainer,
  RadioInstructionMessage,
} from './SubmitFormBaseComponent'
import messages from '../messages'

export default class ScanModeSection extends SubmitFormBaseComponent {
  renderTitleText = () => {
    return (
      <DynamicFormattedMessage {...messages.submitFormScanModeNumberLabel} />
    )
  }

  renderHelpContent = () => {
    return (
      <Fragment>
        <span>
          <DynamicFormattedMessage
            {...messages.submitFormScanModeInstructions}
          />
        </span>
      </Fragment>
    )
  }

  setScanMode = event => {
    this.props.setFormFields({ scanMode: event.target.value })
  }

  renderFieldControls = () => {
    return (
      <DefaultFieldControlContainer>
        <div>
          <label style={{ paddingLeft: '8px' }}>
            {' '}
            <input
              type="radio"
              name="scanMode"
              value="FULL_SCAN"
              checked={
                this.props.formValues[this.props.fieldKey] === 'FULL_SCAN'
              }
              onClick={this.setScanMode}
              style={{ marginRight: '8px' }}
            />
            <DynamicFormattedMessage
              {...messages.submitFormScanModeFullLabel}
            />{' '}
            -{' '}
            <RadioInstructionMessage>
              <DynamicFormattedMessage
                {...messages.submitFormScanModeFullDescription}
              />
            </RadioInstructionMessage>
          </label>
        </div>
        <div>
          <label style={{ paddingLeft: '8px' }}>
            {' '}
            <input
              type="radio"
              name="scanMode"
              value="PARTIAL_SCAN"
              checked={
                this.props.formValues[this.props.fieldKey] === 'PARTIAL_SCAN'
              }
              onClick={this.setScanMode}
              style={{ marginRight: '8px' }}
            />
            <DynamicFormattedMessage
              {...messages.submitFormScanModePartialLabel}
            />{' '}
            -{' '}
            <RadioInstructionMessage>
              <DynamicFormattedMessage
                {...messages.submitFormScanModePartialDescription}
              />
            </RadioInstructionMessage>
          </label>
        </div>
        <div>
          <label style={{ paddingLeft: '8px' }}>
            {' '}
            <input
              type="radio"
              name="scanMode"
              value="QUICK_SCAN"
              checked={
                this.props.formValues[this.props.fieldKey] === 'QUICK_SCAN'
              }
              onClick={this.setScanMode}
              style={{ marginRight: '8px' }}
            />
            <DynamicFormattedMessage
              {...messages.submitFormScanModeQuickLabel}
            />{' '}
            -{' '}
            <RadioInstructionMessage>
              <DynamicFormattedMessage
                {...messages.submitFormScanModeQuickDescription}
              />
            </RadioInstructionMessage>
          </label>
        </div>
      </DefaultFieldControlContainer>
    )
  }
}
