import _ from 'lodash'
import { fromJS, List } from 'immutable'

/**
 * get the bots that could apply to a ticket by analysing the escalations and
 * looking if they could apply to ticket, then getting the bots off the
 * escalations
 */
export const getSuggestedBots = (ticket, bots, allEscalations) => {
  if (!allEscalations.get('data')) {
    return List()
  }
  const escalations = allEscalations
    .get('data')
    .filter(escalation => escalationIsForTicket(escalation, ticket))
  return getEscalationsBots(escalations, bots)
}

/**
 * determine if escalation applies to ticket
 */
export const escalationIsForTicket = (escalation, ticket) => {
  return (
    undefined !==
    (escalation.get('filters') ?? List()).find(
      escalation => escalation.get('ticketKey') === ticket.ticketKey
    )
  )
}

/**
 * get thee bots off the escalations
 */
export const getEscalationsBots = (escalations, bots) => {
  const botsBySrn = _.keyBy(bots.get('data').toJS(), 'srn')
  const botsInrules = escalations
    .flatMap(escalation => escalation.get('rules'))
    .filter(rule => 'ESCALATE_TO_BOT' === rule.get('actionType'))
    .map(rule => {
      return botsBySrn[rule.getIn(['escalateToBot', 'botSrn'])]
    })
    .filter(bot => bot != undefined)
  return fromJS(_.uniqBy(botsInrules.toJS(), ({ srn }) => srn))
}
