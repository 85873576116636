import { createAction } from 'redux-actions'

import {
  ASSIGN_TO_ME,
  ASSIGN_TO_ME_ERROR,
  ASSIGN_TO_ME_SUCCESS,
  FETCH_TICKET_DETAILS,
  FETCH_TICKET_DETAILS_SUCCESS,
  FETCH_TICKET_DETAILS_ERROR,
  ACCEPT_RISK_TICKET,
  ACCEPT_RISK_TICKET_ERROR,
  ACCEPT_RISK_TICKET_SUCCESS,
  CLOSE_TICKET,
  CLOSE_TICKET_ERROR,
  CLOSE_TICKET_SUCCESS,
  REOPEN_TICKET,
  REOPEN_TICKET_ERROR,
  REOPEN_TICKET_SUCCESS,
  SNOOZE_TICKET,
  SNOOZE_TICKET_ERROR,
  SNOOZE_TICKET_SUCCESS,
  FETCH_TICKET_COMMENTS,
  FETCH_TICKET_COMMENTS_SUCCESS,
  FETCH_TICKET_COMMENTS_ERROR,
  ADD_COMMENT,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_ERROR,
  FETCH_SIMILAR_TICKETS,
  FETCH_SIMILAR_TICKETS_ERROR,
  FETCH_SIMILAR_TICKETS_SUCCESS,
  CHANGE_SIMILAR_TICKETS_FILTERS,
  REASSIGN_TO_USER,
  REASSIGN_TO_USER_SUCCESS,
  REASSIGN_TO_USER_ERROR,
  FETCH_TICKET_HISTORY,
  FETCH_TICKET_HISTORY_SUCCESS,
  FETCH_TICKET_HISTORY_ERROR,
  FETCH_ACTIVITY_TICKET_ACTIONS,
  FETCH_ACTIVITY_TICKET_ACTIONS_SUCCESS,
  FETCH_ACTIVITY_TICKET_ACTIONS_ERROR,
  FETCH_POLICY_EVIDENCE_LEGACY,
  FETCH_POLICY_EVIDENCE_LEGACY_ERROR,
  FETCH_POLICY_EVIDENCE_LEGACY_SUCCESS,
  FETCH_POLICY_PATH,
  FETCH_POLICY_PATH_SUCCESS,
  FETCH_POLICY_PATH_ERROR,
  TICKET_STATUS_CHANGE_SUCCESS,
  CLOSE_TICKETS,
  CLOSE_TICKETS_SUCCESS,
  BULK_ACTION_START,
  BULK_ACTION_COMPLETE,
  ASSIGN_TICKETS,
  ACCEPT_RISK_TICKETS,
  SNOOZE_TICKETS,
  BULK_COMMENT,
  BULK_TAG,
} from './constants'

export const assignToMe = createAction(ASSIGN_TO_ME)
export const assignToMeError = createAction(ASSIGN_TO_ME_ERROR)
export const assignToMeSuccess = createAction(ASSIGN_TO_ME_SUCCESS)

export const fetchPolicyPath = createAction(FETCH_POLICY_PATH)
export const fetchPolicyPathSuccess = createAction(FETCH_POLICY_PATH_SUCCESS)
export const fetchPolicyPathError = createAction(FETCH_POLICY_PATH_ERROR)

export const fetchTicketDetails = createAction(FETCH_TICKET_DETAILS)
export const fetchTicketDetailsSuccess = createAction(
  FETCH_TICKET_DETAILS_SUCCESS
)
export const fetchTicketDetailsError = createAction(FETCH_TICKET_DETAILS_ERROR)

export const acceptRiskTicket = createAction(ACCEPT_RISK_TICKET)
export const acceptRiskTicketSuccess = createAction(ACCEPT_RISK_TICKET_SUCCESS)
export const acceptRiskTicketError = createAction(ACCEPT_RISK_TICKET_ERROR)

export const closeTicket = createAction(CLOSE_TICKET)
export const closeTicketSuccess = createAction(CLOSE_TICKET_SUCCESS)
export const closeTicketError = createAction(CLOSE_TICKET_ERROR)

export const reopenTicket = createAction(REOPEN_TICKET)
export const reopenTicketError = createAction(REOPEN_TICKET_ERROR)
export const reopenTicketSucces = createAction(REOPEN_TICKET_SUCCESS)

export const snoozeTicket = createAction(SNOOZE_TICKET)
export const snoozeTicketSuccess = createAction(SNOOZE_TICKET_SUCCESS)
export const snoozeTicketError = createAction(SNOOZE_TICKET_ERROR)

export const fetchTicketComments = createAction(FETCH_TICKET_COMMENTS)
export const fetchTicketCommentsSuccess = createAction(
  FETCH_TICKET_COMMENTS_SUCCESS
)
export const fetchTicketCommentsError = createAction(
  FETCH_TICKET_COMMENTS_ERROR
)

export const addComment = createAction(ADD_COMMENT)
export const addCommentSuccess = createAction(ADD_COMMENT_SUCCESS)
export const addCommentError = createAction(ADD_COMMENT_ERROR)

export const fetchSimilarTickets = createAction(FETCH_SIMILAR_TICKETS)
export const fetchSimilarTicketsError = createAction(
  FETCH_SIMILAR_TICKETS_ERROR
)
export const fetchSimilarTicketsSuccess = createAction(
  FETCH_SIMILAR_TICKETS_SUCCESS
)

export const changeSimilarTicketsFilters = createAction(
  CHANGE_SIMILAR_TICKETS_FILTERS
)

export const reassignToUser = createAction(REASSIGN_TO_USER)
export const reassignToUserSuccess = createAction(REASSIGN_TO_USER_SUCCESS)
export const reassignToUserError = createAction(REASSIGN_TO_USER_ERROR)

export const fetchTicketHistory = createAction(FETCH_TICKET_HISTORY)
export const fetchTicketHistorySuccess = createAction(
  FETCH_TICKET_HISTORY_SUCCESS
)
export const fetchTicketHistoryError = createAction(FETCH_TICKET_HISTORY_ERROR)

export const fetchActivityTicketActions = createAction(
  FETCH_ACTIVITY_TICKET_ACTIONS
)
export const fetchActivityTicketActionsSuccess = createAction(
  FETCH_ACTIVITY_TICKET_ACTIONS_SUCCESS
)
export const fetchActivityTicketActionsError = createAction(
  FETCH_ACTIVITY_TICKET_ACTIONS_ERROR
)

export const fetchPolicyEvidenceLegacy = createAction(
  FETCH_POLICY_EVIDENCE_LEGACY
)
export const fetchPolicyEvidenceLegacyError = createAction(
  FETCH_POLICY_EVIDENCE_LEGACY_ERROR
)
export const fetchPolicyEvidenceLegacySuccess = createAction(
  FETCH_POLICY_EVIDENCE_LEGACY_SUCCESS
)

export const ticketStatusChangeSuccess = createAction(
  TICKET_STATUS_CHANGE_SUCCESS
)

export const closeTickets = createAction(CLOSE_TICKETS)
export const closeTicketsSuccess = createAction(CLOSE_TICKETS_SUCCESS)
export const bulkActionStart = createAction(BULK_ACTION_START)
export const bulkActionComplete = createAction(BULK_ACTION_COMPLETE)
export const assignTickets = createAction(ASSIGN_TICKETS)
export const acceptRiskTickets = createAction(ACCEPT_RISK_TICKETS)
export const snoozeTickets = createAction(SNOOZE_TICKETS)
export const bulkComment = createAction(BULK_COMMENT)
export const bulkTag = createAction(BULK_TAG)
