import { defineMessages } from 'react-intl'

export default defineMessages({
  addPrivateKeyModalTitle: {
    id: 'app.containers.DataClassification.addPrivateKeyModalTitle',
    defaultMessage: 'Add Decryption Key',
  },
  addPrivateKeyBoldInstructionsPart1Bold: {
    id: 'app.containers.DataClassification.addPrivateKeyBoldPart1',
    defaultMessage:
      'The classification result samples returned to Sonrai have been encrypted and cannot be displayed without the appropriate encryption key.',
  },
  addPrivateKeyInstructionsPart2NotBold: {
    id: 'app.containers.DataClassification.addPrivateKeyPart2NotBold',
    defaultMessage:
      'To view your results, please enter the matching key that was used when submitting the classification job.',
  },
  addPrivateKeyInstructionsPart3Bold: {
    id: 'app.containers.DataClassification.addPrivateKeyInstructionsPart3Bold',
    defaultMessage:
      'Your private key is used only in this browser session for decryption and is not transmitted or stored in any way.',
  },
  addPrivateKeyInstructionsPart4NotBold: {
    id: 'app.containers.DataClassification.addPrivateKeyInstructionsPart3Bold',
    defaultMessage:
      'Incorrect keys will not return any results.  If you misplace your private key, you cannot retrieve these encrypted results, however you can choose to rerun the classification job again with a new public key to retrieve a new set of results.',
  },
  submitFormCustomClassifierLabel: {
    id: 'app.containers.DataClassification.submitFormCustomClassifierLabel',
    defaultMessage: 'Custom Classifiers',
  },
  dataClassificationTableSectionHeading: {
    id:
      'app.containers.DataClassification.dataClassificationTableSectionHeading',
    defaultMessage: 'Data Classification Results',
  },
  dataClassificationTableHeadingText: {
    id: 'app.containers.DataClassification.dataClassificationTableHeadingText',
    defaultMessage:
      'The data has been found to contain this information by  the classification job.',
  },
  dataClassificationNoSamplesText: {
    id: 'app.containers.DataClassification.dataClassificationNoSamplesText',
    defaultMessage:
      'This classification job was configured not to return samples. Only counts of classified data were found. If you would like to have samples of the classified data, please run classification job with included samples.',
  },
  dataTableClassifierName_ADDRESS: {
    id: 'app.containers.DataClassification.dataTableClassifierName_ADDRESS',
    defaultMessage: 'Address',
  },
  dataTableClassifierName_CREDITCARD: {
    id: 'app.containers.DataClassification.dataTableClassifierName_CREDITCARD',
    defaultMessage: 'Credit Card',
  },
  dataTableClassifierName_DATE: {
    id: 'app.containers.DataClassification.dataTableClassifierName_DATE',
    defaultMessage: 'Date',
  },
  dataTableClassifierName_DOB: {
    id: 'app.containers.DataClassification.dataTableClassifierName_DOB',
    defaultMessage: 'Date of Birth',
  },
  dataTableClassifierName_EMAIL: {
    id: 'app.containers.DataClassification.dataTableClassifierName_EMAIL',
    defaultMessage: 'Email',
  },
  dataTableClassifierName_FULLNAME: {
    id: 'app.containers.DataClassification.dataTableClassifierName_FULLNAME',
    defaultMessage: 'Full Name',
  },
  dataTableClassifierName_PASSPORTNUMBER: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_PASSPORTNUMBER',
    defaultMessage: 'Passport Number',
  },
  dataTableClassifierName_BANKACCOUNTNUMBER: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_BANKACCOUNTNUMBER',
    defaultMessage: 'Bank Account Number',
  },
  dataTableClassifierName_PHONENUMBER: {
    id: 'app.containers.DataClassification.dataTableClassifierName_PHONENUMBER',
    defaultMessage: 'Phone Number',
  },
  dataTableClassifierName_PHONENUMBER_CANADA: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_PHONENUMBER_CANADA',
    defaultMessage: 'Phone Number',
  },
  dataTableClassifierName_PHONENUMBER_USA: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_PHONENUMBER_USA',
    defaultMessage: 'Phone Number',
  },
  dataTableClassifierName_PHONENUMBER_UK: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_PHONENUMBER_UK',
    defaultMessage: 'Phone Number',
  },
  dataTableClassifierName_POSTCODE: {
    id: 'app.containers.DataClassification.dataTableClassifierName_POSTCODE',
    defaultMessage: 'Postal Code',
  },
  dataTableClassifierName_SIN: {
    id: 'app.containers.DataClassification.dataTableClassifierName_SIN',
    defaultMessage: 'Social Insurance Number',
  },
  dataTableClassifierName_SSN: {
    id: 'app.containers.DataClassification.dataTableClassifierName_SSN',
    defaultMessage: 'Social Security Number',
  },
  dataTableClassifierName_TAXNUMBER: {
    id: 'app.containers.DataClassification.dataTableClassifierName_TAXNUMBER',
    defaultMessage: 'Tax Number',
  },
  dataTableClassifierName_ZIPCODE: {
    id: 'app.containers.DataClassification.dataTableClassifierName_SSN',
    defaultMessage: 'ZIP Code',
  },
  dataTableClassifierName_AWSACCESSKEY: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_AWSACCESSKEY',
    defaultMessage: 'AWS Access Key',
  },
  dataTableClassifierName_AWSSECRETKEY: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_AWSSECRETKEY',
    defaultMessage: 'AWS Secret Key',
  },
  dataTableClassifierName_AZURESTORAGEACCOUNTKEY: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_AZURESTORAGEACCOUNTKEY',
    defaultMessage: 'Azure Storage Account Key',
  },
  dataTableClassifierName_AZURECLIENTSECRET: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_AZURECLIENTSECRET',
    defaultMessage: 'Azure Client Secret',
  },
  dataTableClassifierName_AZURESAS: {
    id: 'app.containers.DataClassification.dataTableClassifierName_AZURESAS',
    defaultMessage: 'Azure Access Signature',
  },
  dataTableClassifierName_DRIVERSLICENSE: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_DRIVERSLICENSE',
    defaultMessage: 'Drivers License',
  },
  dataTableClassifierName_ENCRYPTIONKEY: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_ENCRYPTIONKEY',
    defaultMessage: 'Encryption Key',
  },
  dataTableClassifierName_GCPAPIKEY: {
    id: 'app.containers.DataClassification.dataTableClassifierName_GCPAPIKEY',
    defaultMessage: 'GCP API Key',
  },
  dataTableClassifierName_GCPSERVICEACCOUNTKEY: {
    id:
      'app.containers.DataClassification.dataTableClassifierName_GCPSERVICEACCOUNTKEY',
    defaultMessage: 'GCP Service Account Key',
  },
  noClassificationsInfo: {
    id: 'app.containers.DataClassification.noClassificationsInfo',
    defaultMessage: 'There are no classifications.',
  },
  noClassificationsCreateText: {
    id: 'app.containers.DataClassification.noClassificationsCreateText',
    defaultMessage: 'Click here to create classifications job',
  },
  manageCustomDataClassifiers: {
    id: 'app.containers.DataClassification.manageCustomDataClassifiers',
    defaultMessage: '(Manage Custom Data Classifiers)',
  },
  suggestUnencryptionLinkMessage: {
    id: 'app.containers.DataClassification.suggestUnencryptionLinkMessage',
    defaultMessage: 'Add decryption key',
  },
  scheduleAnotherClassification: {
    id: 'app.containers.DataClassification.scheduleAnotherClassification',
    defaultMessage: 'Run another classification job',
  },
  submitFormHeader: {
    id: 'app.containers.DataClassification.submitFormHeader',
    defaultMessage: 'Create New Classification Job',
  },
  submitFormHeaderText: {
    id: 'app.containers.DataClassification.submitFormHeaderText',
    defaultMessage:
      'Please enter the values below to configure a new job to classify this data.',
  },
  submitFormClassifiersLabel: {
    id: 'app.containers.DataClassification.submitFormClassifiersLabel',
    defaultMessage: 'Classifiers',
  },
  submitFormClassifiersInstruction: {
    id: 'app.containers.DataClassification.submitFormClassifiersInstruction',
    defaultMessage:
      'Classifiers allow for different classes of sensitive data to be identified, such as names, email addresses or credit card numbers.  Choose one or more of the classifier options below to use when scanning your resource. ',
  },
  submitFormPhoneNumberLabel: {
    id: 'app.containers.DataClassification.submitFormPhoneNumberLabel',
    defaultMessage: 'Phone Numbers',
  },
  submitFormPhoneNumberAllCountries: {
    id: 'app.containers.DataClassification.submitFormPhoneNumberAllCountries',
    defaultMessage: 'All Countries',
  },
  submitFormScanModeNumberLabel: {
    id: 'app.containers.DataClassification.submitFormScanModeNumberLabel',
    defaultMessage: 'Scan Mode',
  },
  submitFormScanModeInstructions: {
    id: 'app.containers.DataClassification.submitFormScanModeInstructions',
    defaultMessage: 'Choose from one of the available scan modes',
  },
  submitFormScanModeFullLabel: {
    id: 'app.containers.DataClassification.submitFormScanModeFullLabel',
    defaultMessage: 'Full Scan',
  },
  submitFormScanModeFullDescription: {
    id: 'app.containers.DataClassification.submitFormScanModeFullLabel',
    defaultMessage:
      'Scan all files in the storage resource. A maximum of 1000 items are included in the report, with up to 50 samples per classifier.',
  },
  submitFormScanModePartialLabel: {
    id: 'app.containers.DataClassification.submitFormScanModePartialLabel',
    defaultMessage: 'Partial Scan',
  },
  submitFormScanModePartialDescription: {
    id: 'app.containers.DataClassification.submitFormScanModeFullLabel',
    defaultMessage:
      'Scans 25% of the files in the storage resource.  A maximum of 1000 items are included in the report, with up to 20 samples per classifier.',
  },
  submitFormScanModeQuickLabel: {
    id: 'app.containers.DataClassification.submitFormScanModeQuickLabel',
    defaultMessage: 'Quick Scan',
  },
  submitFormScanModeQuickDescription: {
    id: 'app.containers.DataClassification.submitFormScanModeQuickLabel',
    defaultMessage:
      'Scans 5% of the files in the storage resource. A maximum of 10 objects are included in the report, with up to 10 samples per classifier.',
  },
  submitFormAuthenticationLabel: {
    id: 'app.containers.DataClassification.submitFormAuthenticationLabel',
    defaultMessage: 'Authentication',
  },
  submitFormAuthenticationInstructions: {
    id:
      'app.containers.DataClassification.submitFormAuthenticationInstructions',
    defaultMessage:
      'Please provide the following information to allow the classification job to acces the database.',
  },
  submitFormAuthenticationUsernameInstructions: {
    id: 'app.containers.DataClassification.submitFormAuthenticationUsername',
    defaultMessage:
      'Database Username - Username for which to access the database',
  },
  submitFormAuthenticationSecretsManagerArnInstructions: {
    id:
      'app.containers.DataClassification.submitFormAuthenticationSecretsManagerArnInstructions',
    defaultMessage:
      'Secrets Manager Secret ARN - ARN of a secret in AWS Secrets Manager containing the password to access the database',
  },
  submitFormAuthenticationSecretsManagerPathInstructions: {
    id:
      'app.containers.DataClassification.submitFormAuthenticationSecretsManagerPathInstructions',
    defaultMessage:
      'Secrets Manager Secret Path - Key in Secret JSON Document for which the value is the database password',
  },
  submitFormAuthenticationKVNameInstructions: {
    id: 'app.containers.DataClassification.submitFormAuthenticationKVPath',
    defaultMessage:
      'Key Vault Name - Name of the Key Vault that holds the database password',
  },
  submitFormAuthenticationKVPathInstructions: {
    id: 'app.containers.DataClassification.submitFormAuthenticationKVPath',
    defaultMessage:
      'Key Vault Path - Key in Secret JSON Document for which the value is the database password',
  },
  submitFormAuthenticationUsername: {
    id: 'app.containers.DataClassification.submitFormAuthenticationUsername',
    defaultMessage: 'Database Username',
  },
  submitFormAuthenticationKVName: {
    id: 'app.containers.DataClassification.submitFormAuthenticationKVName',
    defaultMessage: 'Key Vault Name',
  },
  submitFormAuthenticationKVPath: {
    id: 'app.containers.DataClassification.submitFormAuthenticationKVPath',
    defaultMessage: 'Key Vault Path',
  },
  submitFormAuthenticationSecretName: {
    id: 'app.containers.DataClassification.submitFormAuthenticationSecretName',
    defaultMessage: 'Secrets Manager Secret ARN',
  },
  submitFormAuthenticationSecretPath: {
    id: 'app.containers.DataClassification.submitFormAuthenticationSecretPath',
    defaultMessage: 'Secrets Manager Secret Path',
  },
  submitFormMaxSamplesPerClassifierLabel: {
    id:
      'app.containers.DataClassification.submitFormMaxSamplesPerClassifierLabel',
    defaultMessage: 'Max Samples Per Classifier',
  },
  submitFormMaxSamplesPerClassifierInstructions: {
    id:
      'app.containers.DataClassification.submitFormMaxSamplesPerClassifierInstructions',
    defaultMessage:
      'Maximum number of data samples reported per classifier. Minimum = 1, Maximum = 100',
  },
  submitFormOutputLabel: {
    id: 'app.containers.DataClassification.submitFormOutputLabel',
    defaultMessage: 'Output Mode',
  },
  submitFormOutputInstructions: {
    id: 'app.containers.DataClassification.submitFormOutputInstructions',
    defaultMessage: 'Choose from one of the available output modes',
  },
  submitFormOutputLimitLabel: {
    id: 'app.containers.DataClassification.submitFormOutputLimitLabel',
    defaultMessage: 'Output Limit',
  },
  submitFormOutputLimitInstructions: {
    id: 'app.containers.DataClassification.submitFormOutputLimitInstructions',
    defaultMessage:
      'Maximum number of output records generated during scanning. Minimum = 1, Maximum = 1000',
  },
  submitFormColumnSampleSizeLabel: {
    id: 'app.containers.DataClassification.submitFormColumnSampleSizeLabel',
    defaultMessage: 'Column Sample Size',
  },
  submitFormColumnSampleSizeInstructions: {
    id:
      'app.containers.DataClassification.submitFormColumnSampleSizeInstructions',
    defaultMessage:
      'Number of values in each column that will be scanned for data to classify. Minimum = 300, Maximum = 3000',
  },
  submitFormOutputRecommend: {
    id: 'app.containers.DataClassification.submitFormOutputRecommend',
    defaultMessage: 'Recommended',
  },
  submitFormOutputFingerLabel: {
    id: 'app.containers.DataClassification.submitFormOutputFingerLabel',
    defaultMessage: 'Finger Print',
  },
  submitFormOutputFingerInstructions: {
    id: 'app.containers.DataClassification.submitFormOutputFingerInstructions',
    defaultMessage: 'in concise form with outputs per Fingerprints',
  },
  submitFormOutputDOLabel: {
    id: 'app.containers.DataClassification.submitFormOutputDOLabel',
    defaultMessage: 'Data Object',
  },
  submitFormOutputDOInstructions: {
    id: 'app.containers.DataClassification.submitFormOutputDOInstructions',
    defaultMessage:
      'Outputs can be produced in detailed form with counts and samples of data found per DataObject',
  },
  submitFormIncludeSamplesLabel: {
    id: 'app.containers.DataClassification.submitFormIncludeSamplesLabel',
    defaultMessage: 'Include Samples',
  },
  submitFormIncludeSamplesInstructions: {
    id:
      'app.containers.DataClassification.submitFormIncludeSamplesInstructions',
    defaultMessage:
      'Choose whether to include samples of the data found by the classification job in results. If not selected, only counts of the classifications will be returned',
  },
  submitFormIncludeEncryptionLabel: {
    id: 'app.containers.DataClassification.submitFormIncludeEncryptionLabel',
    defaultMessage: 'Encryption',
  },
  submitFormIncludeEncryptionInstructions: {
    id:
      'app.containers.DataClassification.submitFormIncludeEncryptionInstructions',
    defaultMessage:
      'Choose whether the samples returned from from the job will be encrypted. You will be prompted ot enter a public key to use for encryption. When the samples are found and loaded, you can use your private key to decrypt them and view them in the browser.',
  },
  submitFormIncludeEncryptSamplesLabel: {
    id:
      'app.containers.DataClassification.submitFormIncludeEncryptSamplesLabel',
    defaultMessage: 'Encrypted Samples',
  },
  submitFormIncludePublicKeyLabel: {
    id: 'app.containers.DataClassification.submitFormIncludePublicKeyLabel',
    defaultMessage: 'Public Key',
  },
  submitFormIncludeValidPemWarning: {
    id: 'app.containers.DataClassification.submitFormIncludeValidPemWarning',
    defaultMessage: 'Must be valid PEM format',
  },
  submitFormSubmit: {
    id: 'app.containers.DataClassification.submitFormSubmit',
    defaultMessage: 'Submit',
  },
  submitFormCancel: {
    id: 'app.containers.DataClassification.submitFormCancel',
    defaultMessage: 'Cancel',
  },
})
