import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import moment from 'moment'
import { List } from 'immutable'
import { DateRangePicker } from 'react-dates'
import { Responsive } from 'react-grid-layout'
import SizeMe from 'components/SizeMe'
const ResponsiveGridLayout = SizeMe(Responsive)

import DidAccessWidget from 'components/DidAccessWidget'
import AccessedUsingWidget from 'components/AccessedUsingWidget'
import CanAccessWidget from 'components/CanAccessWidget'
import ActivityFromRegions from 'components/ActivityFromRegions'
import PropertiesWidget from 'components/PropertiesWidget'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import injectSaga from 'utils/injectSaga'
import injectReducer from 'utils/injectReducer'
import { srnIsCRMActionable } from 'utils/widgetUtils'
import reducer from './reducers'
import saga from './sagas'
import {
  getNodeViewPushParams,
  exists,
  getNameFromSrn,
} from 'utils/sonraiUtils'
import {
  setCriticalResourceMonitorDate,
  getPropertyWidgetData,
  getDidAccessData,
  getCanAccessData,
  getAccessedUsingData,
  getActivityWidgetData,
  getSelectedRowActions,
  toggleActionsModal,
  getCanAccessPath,
  setCanAccessPath,
} from './actions'
import _ from 'lodash'
import {
  selectCriticalResourceMonitorDate,
  selectPropertyWidget,
  selectDidAccessData,
  selectCanAccessData,
  selectAccessedUsingData,
  selectActivityWidget,
  selectSelectedRowActions,
  selectActionsModal,
  selectSelectedRowActionsLoading,
  selectAccessPaths,
  selectCanAccessPaths,
} from './selectors'

import { selectChangeDetectionProperties } from 'containers/ChangeDetectionManager/selectors'

import { initialState } from './reducers'
import ShowPathModal from './ShowPathModal'
import UnderlyingActionsModal from './UnderlyingActionsModal'

import 'react-dates/lib/css/_datepicker.css'
import './styles.css'

const style = {}

const getAllData = props => {
  const date = exists(props.date)
    ? props.date
    : exists(props.alertTime)
    ? {
        fromDate: moment.utc(parseInt(props.alertTime)).subtract(1, 'days'),
        toDate: moment.utc(parseInt(props.alertTime)),
      }
    : {
        fromDate: moment().subtract(1, 'days'),
        toDate: moment(),
      }

  const fromDate = date.fromDate.format('X')
  const toDate = date.toDate.format('X')

  const srn = props.nodeId

  props.getPropertyWidgetData(srn)
  props.getDidAccessData({
    srn,
    fromDate,
    toDate,
    keyName: srnIsCRMActionable(srn) ? 'accessedBy' : 'accessed',
  })
  props.getCanAccessData({
    srn,
    fromDate,
    toDate,
    keyName: srnIsCRMActionable(srn) ? 'actionableBy' : 'hasPermissionTo',
  })
  props.getAccessedUsingData({
    srn,
    fromDate,
    toDate,
  })
  props.getActivityWidgetData({
    srn,
    fromDate,
    toDate,
    keyName: srnIsCRMActionable(srn) ? 'accessedFrom' : 'activeFrom',
  })
}

class AccessActivity extends React.Component {
  constructor(props) {
    super(props)

    getAllData(props)

    this.state = {
      showPathId: null,
      showPathClassification: null,
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.date !== this.props.date) {
      getAllData(this.props)
    }
  }

  componentWillUnmount() {
    this.props.setCriticalResourceMonitorDate(initialState.get('date'))
  }

  navigateToNodeView = srn => {
    const nodeType = getTypeFromSrn(srn)
    this.props.push(getNodeViewPushParams(srn, nodeType))
  }

  onShowPath = (resourceId, classification, conditions) => {
    let fromDate
    let toDate

    if (exists(this.props.date)) {
      fromDate = this.props.date.fromDate.format('YYYY-MM-DD')
      toDate = this.props.date.toDate.format('YYYY-MM-DD')
    } else if (exists(this.props.alertTime)) {
      fromDate = moment
        .utc(parseInt(this.props.alertTime))
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
      toDate = moment.utc(parseInt(this.props.alertTime)).format('YYYY-MM-DD')
    } else {
      fromDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
      toDate = moment().format('YYYY-MM-DD')
    }

    if (
      !this.props.canAccessPaths.getIn([
        resourceId,
        classification,
        fromDate,
        conditions,
      ])
    ) {
      const actionableByMode = srnIsCRMActionable(this.props.nodeId)

      this.props.getCanAccessPath({
        resourceId: resourceId,
        actionClassification: classification,
        srn: this.props.nodeId,
        conditions: conditions,
        fromDate: fromDate,
        toDate: toDate,
        keyName: actionableByMode ? 'actionableBy' : 'hasPermissionTo',
      })
    }

    this.setState({
      showPathId: resourceId,
      showPathClassification: classification,
      showPathConditions: conditions,
    })
  }

  closeShowPath = () => {
    this.setState({
      showPathId: null,
      showPathClassification: null,
      showPathConditions: null,
    })
  }

  onDoubleClickPathNode = ({ nodes }) => {
    if (!_.isEmpty(nodes)) {
      const srn = _.first(nodes)
      if (srn && srn.startsWith('srn:')) {
        const { origin } = window.location
        const { pathname, search } = getNodeViewPushParams(
          srn,
          getTypeFromSrn(srn)
        )
        if (pathname && search) {
          const url = `${origin}${pathname}?${search}`
          window.open(url, '_blank')
        }
      }
    }
  }

  getModalTitle = () => {
    return 'Actions'
  }

  handleDatesChange = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      this.props.setCriticalResourceMonitorDate({
        fromDate: startDate,
        toDate: endDate,
      })
    }
  }

  render() {
    const type = getTypeFromSrn(this.props.nodeId)
    const date = exists(this.props.date)
      ? this.props.date
      : exists(this.props.alertTime)
      ? {
          fromDate: moment
            .utc(parseInt(this.props.alertTime))
            .subtract(1, 'days'),
          toDate: moment.utc(parseInt(this.props.alertTime)),
        }
      : {
          fromDate: moment().subtract(1, 'days'),
          toDate: moment(),
        }

    const { fromDate, toDate } = date

    const actionableByMode = srnIsCRMActionable(this.props.nodeId)

    const changeDetectionProperties = this.props.changeDetectionProperties
    const hasDidAccess =
      this.props.didAccessData &&
      exists(this.props.didAccessData.get('data')) &&
      this.props.didAccessData.get('data').size > 0
    const hasActivityFromRegions =
      this.props.activityWidget &&
      exists(this.props.activityWidget.get('data')) &&
      this.props.activityWidget.get('data').size > 0
    const hasCanAccess =
      this.props.canAccessData &&
      exists(this.props.canAccessData.get('data')) &&
      this.props.canAccessData.get('data').size > 0
    const accessedUsing =
      this.props.accessedUsingData &&
      exists(this.props.accessedUsingData.get('data')) &&
      this.props.accessedUsingData.get('data').size > 0

    const rowHeight = 64
    const smallRowHeight = 2
    const propertiesHeight = type === 'User' ? 2 : 3
    const largeRowHeight = 5

    const layout2Col = [
      { x: 0, y: 0, w: 1, h: propertiesHeight, i: 'properties' },
      {
        x: 0,
        y: 1,
        w: 1,
        h: hasDidAccess ? largeRowHeight : smallRowHeight,
        i: 'didAccess',
      },
      {
        x: 0,
        y: 4,
        w: 1,
        h: hasActivityFromRegions ? largeRowHeight : smallRowHeight,
        i: 'activityFromRegions',
      },
      {
        x: 0,
        y: 7,
        w: 1,
        h: hasCanAccess ? largeRowHeight : smallRowHeight,
        i: 'canAccess',
      },
      {
        x: 0,
        y: 10,
        w: 1,
        h: accessedUsing ? largeRowHeight : smallRowHeight,
        i: 'accessedUsing',
      },
    ]

    const layout1Col = [
      { x: 0, y: 0, w: 1, h: propertiesHeight, i: 'properties' },
      {
        x: 0,
        y: 1,
        w: 1,
        h: hasDidAccess ? largeRowHeight : smallRowHeight,
        i: 'didAccess',
      },
      {
        x: 0,
        y: 3,
        w: 1,
        h: hasActivityFromRegions ? largeRowHeight : smallRowHeight,
        i: 'activityFromRegions',
      },
      {
        x: 0,
        y: 5,
        w: 1,
        h: hasCanAccess ? largeRowHeight : smallRowHeight,
        i: 'canAccess',
      },
      {
        x: 0,
        y: 7,
        w: 1,
        h: accessedUsing ? largeRowHeight : smallRowHeight,
        i: 'accessedUsing',
      },
    ]

    return (
      <div style={style.accessActivityContainer}>
        <div
          style={{
            padding: '0.75rem 0.75rem 0.25rem 0.75rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <DateRangePicker
            noBorder
            startDate={fromDate}
            startDateId="your_unique_start_date_id"
            endDate={toDate}
            endDateId="your_unique_end_date_id"
            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => this.setState({ focusedInput })}
            onDatesChange={this.handleDatesChange}
            isOutsideRange={date => moment(date).isAfter(moment())}
            displayFormat={'MMMM D YYYY'}
          />
        </div>
        <ShowPathModal
          toggle={this.closeShowPath}
          isOpen={!!this.state.showPathId}
          resourceId={this.state.showPathId}
          sourceName={getNameFromSrn(this.props.nodeId)}
          onDoubleClick={this.onDoubleClickPathNode}
          sourceId={this.props.nodeId}
          classification={this.state.showPathClassification}
          actionableByMode={actionableByMode}
          paths={this.props.canAccessPaths.getIn(
            [
              this.state.showPathId,
              this.state.showPathClassification,
              fromDate.format('YYYY-MM-DD'),
              this.state.showPathConditions,
            ],
            List()
          )}
        />
        <UnderlyingActionsModal
          toggle={this.props.toggleActionsModal}
          isOpen={this.props.actionsModal}
          selectedRowActions={this.props.selectedRowActions.toJS()}
          selectedRowActionsLoading={this.props.selectedRowActionsLoading}
          onClickNodeView={this.navigateToNodeView}
          title={this.getModalTitle()}
        />
        <ResponsiveGridLayout
          className="layout"
          breakpoints={{ lg: 1390, md: 1250, sm: 916, xs: 679 }}
          cols={{ lg: 2, md: 2, sm: 1, xs: 1 }}
          margin={[15, 15]}
          rowHeight={rowHeight}
          measureBeforeMount={true}
          useCSSTransforms={false}
          layouts={{
            lg: layout2Col,
            md: layout2Col,
            sm: layout1Col,
            xs: layout1Col,
          }}
          isDraggable={false}
          isResizable={false}
        >
          <div key="properties">
            <PropertiesWidget
              crmTickets={this.props.crmTickets}
              key="properties"
              type={actionableByMode ? 'Data' : 'Identity'}
              changeDetectionProperties={changeDetectionProperties}
              srn={this.props.nodeId}
              propertyWidget={this.props.propertyWidget}
            />
          </div>
          <div key="didAccess">
            <DidAccessWidget
              crmTickets={this.props.crmTickets}
              push={this.props.push}
              getSelectedRowActions={this.props.getSelectedRowActions}
              srn={this.props.nodeId}
              isLocked={
                changeDetectionProperties
                  .map(x => x.get('keyName'))
                  .includes('accessed') ||
                changeDetectionProperties
                  .map(x => x.get('keyName'))
                  .includes('accessedBy')
              }
              date={date}
              type={type}
              didAccessData={this.props.didAccessData.toJS()}
              isLoading={this.props.didAccessData.get('isLoading')}
              title={actionableByMode ? 'Who & What Did Access' : 'Did Access'}
              actionableByMode={actionableByMode}
              path={actionableByMode ? 'accessedBy' : 'accessed'}
            />
          </div>
          <div key="activityFromRegions">
            <ActivityFromRegions
              crmTickets={this.props.crmTickets}
              getSelectedRowActions={this.props.getSelectedRowActions}
              isLocked={
                changeDetectionProperties
                  .map(x => x.get('keyName'))
                  .includes('activeFrom') ||
                changeDetectionProperties
                  .map(x => x.get('keyName'))
                  .includes('accessedFrom')
              }
              title={
                actionableByMode ? 'Accessed From Region' : 'Active From Region'
              }
              srn={this.props.nodeId}
              activityWidget={this.props.activityWidget.toJS()}
              isLoading={this.props.activityWidget.get('isLoading')}
              actionableByMode={actionableByMode}
              path={actionableByMode ? 'accessedFrom' : 'activeFrom'}
            />
          </div>
          <div key="canAccess">
            <CanAccessWidget
              crmTickets={this.props.crmTickets}
              push={this.props.push}
              srn={this.props.nodeId}
              isLocked={
                changeDetectionProperties
                  .map(x => x.get('keyName'))
                  .includes('hasPermissionTo') ||
                changeDetectionProperties
                  .map(x => x.get('keyName'))
                  .includes('actionableBy')
              }
              title={actionableByMode ? 'Who & What Can Access' : 'Can Access'}
              canAccessData={this.props.canAccessData.toJS()}
              isLoading={this.props.canAccessData.get('isLoading')}
              onShowPath={this.onShowPath}
              actionableByMode={actionableByMode}
            />
          </div>
          <div key="accessedUsing">
            <AccessedUsingWidget
              crmTickets={this.props.crmTickets}
              srn={this.props.nodeId}
              isLocked={changeDetectionProperties
                .map(x => x.get('keyName'))
                .includes('accessedUsing')}
              type={type}
              accessedUsingData={this.props.accessedUsingData.toJS()}
              isLoading={this.props.accessedUsingData.get('isLoading')}
              title={actionableByMode ? 'Was Accessed Using' : 'Accessed Using'}
              getSelectedRowActions={this.props.getSelectedRowActions}
              path={actionableByMode ? 'wasAccessedUsing' : 'accessedUsing'}
            />
          </div>
        </ResponsiveGridLayout>
      </div>
    )
  }
}

AccessActivity.propTypes = {
  accessedUsingData: ImmutablePropTypes.iterable.isRequired,
  actionsModal: PropTypes.bool,
  activityWidget: ImmutablePropTypes.iterable.isRequired,
  alertTime: PropTypes.number,
  canAccessData: ImmutablePropTypes.iterable.isRequired,
  canAccessPaths: ImmutablePropTypes.map.isRequired,
  changeDetectionProperties: ImmutablePropTypes.iterable,
  crmTickets: ImmutablePropTypes.list.isRequired,
  date: PropTypes.string,
  didAccessData: ImmutablePropTypes.iterable.isRequired,
  getCanAccessPath: PropTypes.func.isRequired,
  getSelectedRowActions: PropTypes.func,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }),
  nodeId: PropTypes.string.isRequired,
  propertyWidget: ImmutablePropTypes.iterable.isRequired,
  push: PropTypes.func.isRequired,
  selectedRowActions: PropTypes.array,
  selectedRowActionsLoading: PropTypes.bool,
  setCriticalResourceMonitorDate: PropTypes.func.isRequired,
  toggleActionsModal: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  date: selectCriticalResourceMonitorDate,
  canAccessPaths: selectCanAccessPaths,
  changeDetectionProperties: selectChangeDetectionProperties,
  accessPaths: selectAccessPaths,
  propertyWidget: selectPropertyWidget,
  didAccessData: selectDidAccessData,
  canAccessData: selectCanAccessData,
  accessedUsingData: selectAccessedUsingData,
  activityWidget: selectActivityWidget,
  selectedRowActions: selectSelectedRowActions,
  actionsModal: selectActionsModal,
  selectedRowActionsLoading: selectSelectedRowActionsLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      setCriticalResourceMonitorDate,
      getPropertyWidgetData,
      getDidAccessData,
      getCanAccessData,
      getAccessedUsingData,
      getActivityWidgetData,
      getSelectedRowActions,
      toggleActionsModal,
      getCanAccessPath,
      setCanAccessPath,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'accessActivity', reducer })
const withSaga = injectSaga({ key: 'accessActivity', saga: saga })

export default compose(withReducer, withSaga, withConnect)(AccessActivity)
