/**
 *
 * ActionNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { List, Map, isImmutable } from 'immutable'

import Badge from 'components/Badge'
import { TopTitle } from 'components/Card'
import CloudAccount from 'components/CloudAccount'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import BorderedCard from 'components/BorderedCard'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'
import Tooltip from 'components/Tooltip'
import NodeViewMiniPopout from 'containers/NodeViewMiniPopout'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import TableWidget from 'components/TableWidget'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import NodeViewDetailMetadataBody, {
  DISPJSON,
} from 'components/NodeView/NodeViewDetailMetadataBody'
import { getTypeFromSrn } from 'utils/graphDataUtils'

import messages from './messages'

import reducer from './reducer'
import sagas from './sagas'
import { getActionContent } from './actions'
import { selectActionContent, selectIsLoading } from './selectors'
import { SEARCH_INLINE } from '../../../components/NodeView/NodeViewDetailMetadataBody'

const styles = {
  title: {
    fontSize: '22px',
    fontWeight: '300',
    marginRight: '0px',
  },
  performedBy: {
    table: {
      tableLayout: 'fixed',
      width: '100%',
    },
    tableCell: {
      padding: '4px',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    tableCellLabel: {
      width: '128px',
    },
    tableCellValue: {},
    tableCellTextRow: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    tableCellTextRowNoEllip: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    tableRow: {
      borderTop: '1px solid #eee',
    },
  },
}

export class ActionNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getActionContent(props.nodeData.srn)
    this.state = {
      miniNodeViewOpen: false,
      miniNodeViewNodeId: '',
    }
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getPerformedOn = () => {
    return this.props.actionContent
      .getIn(['performedOn', 'items'], List())
      .toJS()
      .map(resource => {
        const value = {
          ..._.pick(resource, ['name', 'type', 'active', 'region']),
          swimlanes: _.get(resource, ['swimlane', 'items'], [])
            .map(({ title }) => title)
            .join(', '),
          ..._.pick(resource, ['tagSet', 'createdDate', 'modifiedDate', 'srn']),
        }
        return value
      })
  }

  getNameFromResource = resource => {
    if (isImmutable(resource)) {
      return this.getNameFromResource(resource.toJS())
    }
    const friendlyName = resource.friendlyName
    if (friendlyName) return friendlyName

    const name = resource.name
    if (name) return name

    const srn = resource.srn
    if (srn) return srn

    return ''
  }

  closeMiniNodeView = () => {
    this.setState({
      miniNodeViewNodeId: '',
      miniNodeViewOpen: false,
    })
  }

  openMiniNodeView = nodeId => {
    this.setState({
      miniNodeViewNodeId: nodeId,
      miniNodeViewOpen: true,
    })
  }

  getPerformedByWidget = () => {
    if (this.props.isLoading) {
      return (
        <BorderedCard style={{ height: '100%' }}>
          <SquareLoadingAnimation />
        </BorderedCard>
      )
    }

    const performedByLabel = (
      this.props.actionContent.get('performedByLabel') || List()
    ).toJS()

    let user = this.props.actionContent
      .getIn(['performedBy', 'items'], List())
      .filter(it => {
        const srn = it.get('srn')
        const type = getTypeFromSrn(srn)
        return (
          type !== 'Role' && type !== 'AccessKey' && !srn.includes('Account')
        )
      })
      .get(0, null)

    let account = this.props.actionContent
      .getIn(['performedBy', 'items'], List())
      .filter(it => {
        const srn = it.get('srn')
        const type = getTypeFromSrn(srn)
        return (
          type !== 'Role' && type !== 'AccessKey' && srn.includes('Account')
        )
      })
      .get(0, null)
    // if action was performed by account user, but not some original user, treat the
    // account user like the original user
    if (user == null && account != null) {
      user = account
      account = null
    }

    const role = performedByLabel.includes('Role')
      ? this.props.actionContent
          .getIn(['performedBy', 'items'], List())
          .filter(it => {
            const srn = it.get('srn')
            const type = getTypeFromSrn(srn)
            return type === 'Role'
          })
          .get(0, Map())
      : null

    const accessKey = performedByLabel.includes('AccessKey')
      ? this.props.actionContent
          .getIn(['performedBy', 'items'], List())
          .filter(it => {
            const srn = it.get('srn')
            const type = getTypeFromSrn(srn)
            return type === 'AccessKey'
          })
          .get(0, Map())
      : null

    let typeFromSRN = ''
    if (user != null) {
      typeFromSRN = user.get('srn')
    } else if (role != null) {
      typeFromSRN = role.get('srn')
    } else if (account != null) {
      typeFromSRN = account.get('srn')
    }
    const originalIdentityType = getTypeFromSrn(typeFromSRN) || ''

    return (
      <BorderedCard style={{ height: '100%' }}>
        <TopTitle style={styles.title}>Performed By</TopTitle>
        {/*
         * show the user assumed role only if the action has a role and a user
         */}
        {role === null || (user === null && role !== null) ? (
          <p>
            <DynamicFormattedMessage
              values={{ type: originalIdentityType }}
              {...messages.performedByUser}
            />
          </p>
        ) : (
          <p>
            <DynamicFormattedMessage
              values={{ type: originalIdentityType }}
              {...messages.performedByUserAndRole}
            />
          </p>
        )}
        <table style={styles.performedBy.table}>
          <tbody>
            {user != null && (
              <tr style={styles.performedBy.tableRow}>
                <td
                  style={{
                    ...styles.performedBy.tableCell,
                    ...styles.performedBy.tableCellLabel,
                  }}
                >
                  <DynamicFormattedMessage
                    {...messages.originalIdentityLabel}
                  />
                </td>
                <td
                  style={{
                    ...styles.performedBy.tableCell,
                    ...styles.performedBy.tableCellValue,
                  }}
                >
                  <div style={styles.performedBy.tableCellTextRow}>
                    <TextLink
                      color="primary"
                      onClick={() => this.openMiniNodeView(user.get('srn'))}
                    >
                      {this.getNameFromResource(user)}
                    </TextLink>
                  </div>
                  <div style={styles.performedBy.tableCellTextRowNoEllip}>
                    <CloudAccount accountId={user.get('account')} />
                  </div>
                </td>
              </tr>
            )}
            {account != null && (
              <tr style={styles.performedBy.tableRow}>
                <td
                  style={{
                    ...styles.performedBy.tableCell,
                    ...styles.performedBy.tableCellLabel,
                  }}
                >
                  <DynamicFormattedMessage {...messages.accountUserLabel} />
                </td>
                <td
                  style={{
                    ...styles.performedBy.tableCell,
                    ...styles.performedBy.tableCellValue,
                  }}
                >
                  <div style={styles.performedBy.tableCellTextRowNoEllip}>
                    <CloudAccount hasLink accountId={account.get('account')} />
                  </div>
                </td>
              </tr>
            )}
            {role != null && (
              <tr style={styles.performedBy.tableRow}>
                <td
                  style={{
                    ...styles.performedBy.tableCell,
                    ...styles.performedBy.tableCellValue,
                  }}
                >
                  <DynamicFormattedMessage {...messages.roleLabel} />
                </td>
                <td
                  style={{
                    ...styles.performedBy.tableCell,
                    ...styles.performedBy.tableCellValue,
                  }}
                >
                  <div style={styles.performedBy.tableCellTextRow}>
                    <TextLink
                      color="primary"
                      onClick={() => this.openMiniNodeView(role.get('srn'))}
                    >
                      {this.getNameFromResource(role)}
                    </TextLink>
                  </div>
                  <div style={styles.performedBy.tableCellTextRowNoEllip}>
                    <CloudAccount accountId={role.get('account')} />
                  </div>
                </td>
              </tr>
            )}
            {accessKey != null && (
              <tr style={styles.performedBy.tableRow}>
                <td
                  style={{
                    ...styles.performedBy.tableCell,
                    ...styles.performedBy.tableCellLabel,
                  }}
                >
                  <DynamicFormattedMessage {...messages.accessKeyLabel} />
                </td>
                <td
                  style={{
                    ...styles.performedBy.tableCell,
                    ...styles.performedBy.tableCellValue,
                  }}
                >
                  <div style={styles.performedBy.tableCellTextRow}>
                    <TextLink
                      color="primary"
                      onClick={() =>
                        this.openMiniNodeView(accessKey.get('srn'))
                      }
                    >
                      {this.getNameFromResource(accessKey)}
                    </TextLink>
                  </div>
                  <div style={styles.performedBy.tableCellTextRowNoEllip}>
                    <CloudAccount accountId={accessKey.get('account')} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <NodeViewMiniPopout
          handleClose={this.closeMiniNodeView}
          open={this.state.miniNodeViewOpen}
          nodeId={this.state.miniNodeViewNodeId}
        />
      </BorderedCard>
    )
  }

  getEventDetails = () => {
    if (this.props.isLoading) {
      return <SquareLoadingAnimation />
    }

    return (
      <div>
        <NodeViewDetailMetadataBody
          displayMode={DISPJSON}
          search={SEARCH_INLINE}
          value={(this.props.actionContent.get('metadata') || Map()).toJS()}
        />
      </div>
    )
  }

  getGridContent = () => {
    return [
      <div key="performedby">{this.getPerformedByWidget()}</div>,
      <div key="performedon">
        <TableWidget
          nodeViewType="Action"
          setUserProfileTables={this.props.setUserProfileTables}
          userProfile={this.props.userProfile}
          data={this.getPerformedOn()}
          title={this.props.intl.formatMessage(messages.performedOnTitle)}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.isLoading}
        />
      </div>,
      <div key="eventdetails">
        <BorderedCard>
          <TopTitle style={{ zIndex: 2, position: 'relative' }}>
            {!this.props.isLoading &&
            this.props.actionContent.get('coalescedCount') > 1 ? (
              <Tooltip
                anchor={
                  <span style={styles.title}>
                    <DynamicFormattedMessage
                      {...messages.firstEventDetailsTitle}
                    />
                  </span>
                }
                position="right"
                tooltipContent={
                  <div>
                    {
                      <DynamicFormattedMessage
                        {...messages.firstEventDetailsTooltip}
                      />
                    }
                  </div>
                }
              />
            ) : (
              <span style={styles.title}>
                <DynamicFormattedMessage {...messages.eventDetailsTitle} />
              </span>
            )}
          </TopTitle>
          {this.getEventDetails()}
        </BorderedCard>
      </div>,
    ]
  }

  getLayouts = () => {
    return {
      performedby: {
        lg: { w: 3, h: 2 },
        md: { w: 3, h: 2 },
        sm: { w: 3, h: 2 },
        xs: { w: 3, h: 2 },
      },
      performedon: {
        lg: { w: 5, h: 2 },
        md: { w: 5, h: 2 },
        sm: { w: 5, h: 2 },
        xs: { w: 5, h: 2 },
      },
      eventdetails: {
        sm: { w: 8 },
        xs: { w: 1 },
      },
    }
  }

  getCols = () => {
    return {
      lg: 8,
      md: 8,
      sm: 8,
      xs: 1,
    }
  }

  getHeaderBadge = () => {
    if (
      this.props.nodeData.coalescedCount &&
      this.props.nodeData.coalescedCount > 1
    ) {
      return (
        <Tooltip
          tooltipContent={
            <span>
              <DynamicFormattedMessage {...messages.firstEventDetailsTooltip} />
            </span>
          }
          anchor={
            <Badge>
              <Icon fa name="info-circle" />
              &nbsp;Coalesced
            </Badge>
          }
        />
      )
    }
  }

  render() {
    const nodeData = {
      ...this.props.nodeData,
      performedOnValue: this.getPerformedOn(),
    }

    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />
        <NodeView
          {...this.props}
          layouts={this.getLayouts()}
          cols={this.getCols()}
          gridContent={this.getGridContent()}
          nodeData={nodeData}
          propsOfInterest={this.props.propsOfInterest}
          detailsTag={this.getHeaderBadge()}
          horizontal={this.props.horizontal}
        />
      </Fragment>
    )
  }
}

ActionNodeViewCardLayout.propTypes = {
  actionContent: ImmutablePropTypes.map.isRequired,
  getActionContent: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  setUserProfileTables: PropTypes.func.isRequired,
  userProfile: ImmutablePropTypes.map.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  actionContent: selectActionContent,
  isLoading: selectIsLoading,
  userProfile: selectUserProfile,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getActionContent,
      setUserProfileTables,
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({ key: 'actionNodeViewCardLayout', reducer })

const withSaga = injectSaga({
  key: 'actionNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(ActionNodeViewCardLayout)
