import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

const selectActivityDomain = state => state.get('activity')

export const selectActivity = createSelector(
  selectActivityDomain,
  state => state || Map()
)

export const selectPerformedActions = createSelector(
  selectActivity,
  state => state.get('performedActions')
)

export const selectActivityDate = createSelector(
  selectActivity,
  state => state.get('date')
)

export const selectPerformedActionFields = createSelector(
  selectActivity,
  state => state.get('performedActionFields')
)

export const selectRolesAssumed = createSelector(
  selectActivity,
  state => state.get('rolesAssumed')
)

export const selectRolesAssumedBy = createSelector(
  selectActivity,
  state => state.get('rolesAssumedBy')
)

export const selectActiveFrom = createSelector(
  selectActivity,
  state => state.get('activeFrom')
)

export const selectAccessedUsing = createSelector(
  selectActivity,
  state => state.get('accessedUsing')
)

export const selectSelectedRowActions = createSelector(
  selectActivity,
  state => {
    return state.get('selectedRowActions') || List()
  }
)

export const selectSelectedRowActionsLoading = createSelector(
  selectActivity,
  state => {
    return state.get('selectedRowActionsLoading')
  }
)

export const selectActionsModal = createSelector(
  selectActivity,
  state => {
    return state.get('actionsModal')
  }
)

export default selectActivity
export { selectActivityDomain }
