/**
 *
 * NodeView
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import qs from 'querystring'
import { injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { withRouter } from 'react-router'
import moment from 'moment'
import { Map } from 'immutable'

import BorderedCard from 'components/BorderedCard'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { NodeViewDetails, NodeViewTabs } from 'components/NodeView'

import sizeMe from 'components/SizeMe'
import {
  selectFields,
  selectTicketCount,
  selectIsMonitored,
  selectCrmTickets,
} from './selectors'
import { TYPES_WITH_CRM } from 'appConstants'
import LegacyAccessActivity from 'containers/AccessActivity'
import Activity from 'containers/Activity'
import Access from 'containers/Access'
import DataClassification from 'containers/DataClassification/Loadable'
import DynamicHeightGrid, { ColumnLayout } from 'components/DynamicHeightGrid'
import RapSheet from 'containers/RapSheet'
import { DEFAULT_RAPSHEET_ALERTS_DAYS } from './constants'
import { FILTER_DATE_FORMAT } from 'appConstants'
import { hasClassifications } from 'containers/DataClassification/utils'

export const TYPES_WITH_NEW_ACCESS_ACTIVITY_VIEW = [
  'user',
  'role',
  'compute',
  'datacontainer',
  'dataobject',
  'datastore',
  'secretstore',
  'secret',
  'encryptionkey',
]

class NodeView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rapsheetFromDate: moment()
        .subtract(DEFAULT_RAPSHEET_ALERTS_DAYS, 'days')
        .startOf('day')
        .toISOString(),
      rapsheetToDate: moment().endOf('day').toISOString(),
    }
    this.styles = {
      details: {
        borderTop: 0,
        borderRadius: 0,
        overflow: 'auto',
        padding: '0',
      },
      gridBody: {
        overflow: 'auto',
      },
      nodeContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        margin: '0 12px 12px 12px',
      },
    }
  }

  hasAccessActivityTabParam = () => {
    const { alert } = qs.parse(window.location.search.substring(1))
    if (alert) {
      return true
    }

    const urlShowAccessActivity = qs.parse(
      _.get(this.props, ['location', 'search'], '')
    ).showAccessActivity

    const locationAccessActivity = _.get(this.props, [
      'location',
      'state',
      'AccessActivity',
    ])

    if (locationAccessActivity || urlShowAccessActivity) {
      return true
    }

    return false
  }

  navigateToNodeView = srn => {
    const nodeType = getTypeFromSrn(srn)
    this.props.push(getNodeViewPushParams(srn, nodeType))
  }

  setRapsheetDateRange = dateRange => {
    const rapsheetFromDate = dateRange.startDate
    const rapsheetToDate = dateRange.endDate

    this.setState({ rapsheetFromDate, rapsheetToDate })
  }

  setRapsheetDateFilter = ({ fromDate, toDate }) => {
    this.setState({
      rapsheetFromDate: moment(fromDate).format(FILTER_DATE_FORMAT),
      rapsheetToDate: moment(toDate).format(FILTER_DATE_FORMAT),
    })
  }

  renderGridBody = () => {
    if (this.props.columns) {
      return (
        <div style={{ width: '100%' }}>
          <ColumnLayout columns={this.props.columns}>
            {this.props.gridContent}
          </ColumnLayout>
        </div>
      )
    }

    if (this.props.horizontal) {
      return (
        <div style={{ height: '100%' }}>
          <DynamicHeightGrid
            layouts={this.props.layouts}
            columns={this.props.columns}
            cols={this.props.cols}
          >
            {this.props.gridContent}
          </DynamicHeightGrid>
        </div>
      )
    }

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <DynamicHeightGrid
          layouts={this.props.layouts}
          columns={this.props.columns}
          cols={this.props.cols}
        >
          {this.props.gridContent}
        </DynamicHeightGrid>
      </div>
    )
  }

  renderOverview = () => {
    const horizontal = this.props.width < 900 || this.props.horizontal

    const type = getTypeFromSrn(this.props.nodeData.srn).toLowerCase()

    const cardStyle =
      TYPES_WITH_CRM.includes(type) && this.props.isMonitored
        ? { overflow: 'auto' }
        : {}

    const content = [
      <div style={cardStyle} key="body">
        {this.renderGridBody()}
      </div>,
    ]

    if (horizontal) {
      return (
        <div style={{ overflow: 'auto' }} label="Overview">
          {content}
        </div>
      )
    }

    return (
      <div style={{ overflow: 'hidden' }} label="Overview">
        {content}
      </div>
    )
  }

  renderSidebar = () => {
    const horizontal = this.props.width < 900 || this.props.horizontal
    const style = {
      maxWidth: !horizontal ? '325px' : undefined,
    }

    return (
      <BorderedCard style={{ overflow: 'auto' }} key="details">
        <NodeViewDetails
          nodeData={this.props.nodeData}
          fields={this.props.fields.toJS()}
          additionalHeaderDetails={this.props.additionalHeaderDetails}
          propsOfInterest={this.props.propsOfInterest}
          detailsTag={this.props.detailsTag}
          horizontal={horizontal}
          style={style}
        />
      </BorderedCard>
    )
  }

  render() {
    const { alertTime } = qs.parse(window.location.search.substring(1))
    const {
      nodeData: { srn },
      changeHistory,
    } = this.props

    const type = getTypeFromSrn(srn).toLowerCase()
    const hasActivity = this.hasAccessActivityTabParam()

    const supportsClassification = hasClassifications(this.props.nodeData)

    const sonbabies = []

    if (this.props.horizontal) {
      sonbabies.push(<div label="Overview">{this.renderSidebar()}</div>)
    }
    if (this.props.renderDetailsTab) {
      sonbabies.push(<div label="Details">{this.renderOverview()}</div>)
    }

    if (
      TYPES_WITH_CRM.includes(type) &&
      !TYPES_WITH_NEW_ACCESS_ACTIVITY_VIEW.includes(type) &&
      this.props.isMonitored
    ) {
      sonbabies.push(
        <div label="Access & Activity">
          <LegacyAccessActivity
            nodeId={srn}
            crmTickets={this.props.crmTickets}
          />
        </div>
      )
    }

    if (TYPES_WITH_NEW_ACCESS_ACTIVITY_VIEW.includes(type)) {
      sonbabies.push(
        <div label="Access">
          <Access
            nodeId={srn}
            nodeData={this.props.nodeData}
            items={changeHistory}
            alertTime={alertTime}
            crmTickets={this.props.crmTickets}
          />
        </div>
      )
    }

    if (TYPES_WITH_NEW_ACCESS_ACTIVITY_VIEW.includes(type)) {
      sonbabies.push(
        <div label="Activity">
          <Activity
            nodeId={srn}
            items={changeHistory}
            alertTime={alertTime}
            crmTickets={this.props.crmTickets}
          />
        </div>
      )
    }

    if (supportsClassification) {
      sonbabies.push(
        <div label="Data Classification">
          <DataClassification
            nodeId={srn}
            dataContainerContent={this.props.dataContainerContent}
            nodeData={this.props.nodeData}
          />
        </div>
      )
    }

    if (this.props.tabs) {
      sonbabies.push(...this.props.tabs)
    }

    sonbabies.push(
      <div
        label="Risks and Policy Findings"
        notification={this.props.ticketCount}
      >
        <BorderedCard
          style={{
            height: '100%',
            minHeight: 'calc(100vh - 256px)',
            display: 'grid',
            gridTemplateRows: '1fr',
          }}
        >
          <RapSheet
            resourceId={this.props.nodeData.srn}
            setDateFilter={this.setRapsheetDateFilter}
            preFilters={Map({
              fromDate: this.state.rapsheetFromDate,
              toDate: this.state.rapsheetToDate,
            })}
          />
        </BorderedCard>
      </div>
    )

    return (
      <div
        style={{
          ...this.styles.nodeContainer,
          display: this.props.horizontal ? 'block' : 'grid',
        }}
      >
        {!this.props.horizontal && this.renderSidebar()}
        <NodeViewTabs activeTabId={hasActivity ? 1 : 0}>
          {sonbabies}
        </NodeViewTabs>
      </div>
    )
  }
}

const ColumnShape = PropTypes.shape({
  widthPercent: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  height: PropTypes.objectOf(PropTypes.number),
})

NodeView.defaultProps = {
  renderDetailsTab: true,
  tabs: [],
}

NodeView.propTypes = {
  changeHistory: ImmutablePropTypes.list,
  columns: PropTypes.shape({
    lg: PropTypes.arrayOf(ColumnShape),
    md: PropTypes.arrayOf(ColumnShape),
    sm: PropTypes.arrayOf(ColumnShape),
    xs: PropTypes.arrayOf(ColumnShape),
  }),
  cols: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
  }),
  crmTickets: ImmutablePropTypes.list.isRequired,
  dataContainerContent: PropTypes.object,
  detailsTag: PropTypes.node,
  fields: ImmutablePropTypes.list,
  gridContent: PropTypes.node,
  horizontal: PropTypes.bool,
  additionalHeaderDetails: PropTypes.arrayOf(PropTypes.node),
  layouts: PropTypes.object,
  push: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  nodeData: PropTypes.object.isRequired,
  isMonitored: PropTypes.bool,
  location: PropTypes.object, //eslint-disable-line
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
  renderDetailsTab: PropTypes.bool,
  width: PropTypes.number,
  tabs: PropTypes.array,
  ticketCount: PropTypes.number,
}

const mapStateToProps = createStructuredSelector({
  crmTickets: selectCrmTickets,
  fields: selectFields,
  isMonitored: selectIsMonitored,
  ticketCount: selectTicketCount,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withRouter, withConnect, injectIntl, sizeMe)(NodeView)
