import { defineMessages } from 'react-intl'

export default defineMessages({
  account: {
    id: 'app.containers.NodeViewHeader.account',
    defaultMessage: 'Account',
  },
  add: {
    id: 'app.containers.NodeViewHeader.add',
    defaultMessage: 'Add',
  },
  addTag: {
    id: 'app.containers.NodeViewHeader.addTag',
    defaultMessage: 'Add Tag',
  },
  addToSwimlane: {
    id: 'app.containers.NodeViewHeader.addToSwimlane',
    defaultMessage: 'Add To Swimlane',
  },
  aksDiscoveryInstructions: {
    id: 'app.containers.NodeViewHeader.aksDiscoveryInstructions',
    defaultMessage:
      'Some extra steps are required to discover Kubernetes Resources. If you have not already done so please complete the steps in the Help Center Document {docLink}',
  },
  cancel: {
    id: 'app.containers.NodeViewHeader.cancel',
    defaultMessage: 'Cancel',
  },
  close: {
    id: 'app.containers.NodeViewHeader.enableMonitoring',
    defaultMessage: 'Close',
  },
  disableMonitoring: {
    id: 'app.containers.NodeViewHeader.enableMonitoring',
    defaultMessage: 'Disable Monitoring',
  },
  enableMonitoring: {
    id: 'app.containers.NodeViewHeader.enableMonitoring',
    defaultMessage: 'Enable Monitoring',
  },
  key: {
    id: 'app.containers.NodeViewHeader.key',
    defaultMessage: 'Key',
  },
  manageKeyVaultCredentials: {
    id: 'app.containers.NodeViewHeader.manageKeyVaultCredentials',
    defaultMessage: 'Manage KeyVault Credentials',
  },
  showAll: {
    id: 'app.containers.NodeViewHeader.showMore',
    defaultMessage: 'Show All',
  },
  showLess: {
    id: 'app.containers.NodeViewHeader.showMore',
    defaultMessage: 'Show Less',
  },
  swimlanes: {
    id: 'app.containers.NodeViewHeader.swimlanes',
    defaultMessage: 'Swimlanes',
  },
  tags: {
    id: 'app.containers.NodeViewHeader.tags',
    defaultMessage: 'Tags',
  },
  tenant: {
    id: 'app.containers.NodeViewHeader.tenant',
    defaultMessage: 'Tenant',
  },
  value: {
    id: 'app.containers.NodeViewHeader.value',
    defaultMessage: 'Value',
  },
})
