import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import {
  ASSIGN_TICKET_ESCALTION_ERROR,
  FETCH_TICKET_TEMPLATES,
  FETCH_TICKET_TEMPLATES_ERROR,
  FETCH_TICKET_TEMPLATES_SUCCESS,
  FETCH_TICKET_TEMPLATE_FIELD_SELECT,
  FETCH_TICKET_TEMPLATE_FIELD_SELECT_ERROR,
  FETCH_TICKET_TEMPLATE_FIELD_SELECT_SUCCESS,
  SAVE_CUSTOM_TICKET,
  SAVE_CUSTOM_TICKET_ERROR,
  SAVE_CUSTOM_TICKET_SUCCES,
  UPDATE_CUSTOM_TICKET,
  UPDATE_CUSTOM_TICKET_ERROR,
  UPDATE_CUSTOM_TICKET_SUCCES,
  SET_TICKET_TYPE,
  SET_SELECTED_TICKET_TEMPLATE,
  CLEAR_STATE,
  SET_TICKET_TEMPLATE_FIELD_VALUE,
} from './constants'

export const fieldSelectInitialState = fromJS({
  select: {
    loading: false,
    error: false,
    data: null,
  },
  value: null,
})

export const initialState = fromJS({
  ticketType: null,
  selectedTicketTemplate: null,
  createTicketStatus: {
    loading: false,
    error: false,
    assignEscalationError: false,
  },
  ticketTemplates: {
    loading: false,
    error: false,
    data: null,
  },
  ticketTemplateFields: {},
})

function setFieldInitialStateIfUnset(state, field) {
  if (!state.getIn(['ticketTemplateFields', field.name])) {
    return state.setIn(
      ['ticketTemplateFields', field.name],
      fieldSelectInitialState
    )
  } else {
    return state
  }
}

const createTicketModalReducer = handleActions(
  {
    [FETCH_TICKET_TEMPLATES]: state =>
      state
        .setIn(['ticketTemplates', 'loading'], true)
        .setIn(['ticketTemplates', 'error'], false),
    [FETCH_TICKET_TEMPLATES_ERROR]: state =>
      state
        .setIn(['ticketTemplates', 'loading'], false)
        .setIn(['ticketTemplates', 'error'], true),
    [FETCH_TICKET_TEMPLATES_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['ticketTemplates', 'loading'], false)
        .setIn(['ticketTemplates', 'error'], false)
        .setIn(['ticketTemplates', 'data'], fromJS(payload.data)),
    [SET_TICKET_TYPE]: (state, { payload }) => state.set('ticketType', payload),
    [SET_SELECTED_TICKET_TEMPLATE]: (state, { payload }) =>
      state.set('selectedTicketTemplate', payload),
    [CLEAR_STATE]: state =>
      initialState
        // we want to keep ticket templates otherwise we need to refech them
        .set('ticketTemplates', state.get('ticketTemplates')),

    [SET_TICKET_TEMPLATE_FIELD_VALUE]: (state, { payload: { field, value } }) =>
      setFieldInitialStateIfUnset(state, field).setIn(
        ['ticketTemplateFields', field.name, 'value'],
        value
      ),
    [FETCH_TICKET_TEMPLATE_FIELD_SELECT]: (state, { payload: { field } }) =>
      setFieldInitialStateIfUnset(state, field)
        .setIn(['ticketTemplateFields', field.name, 'select', 'loading'], true)
        .setIn(['ticketTemplateFields', field.name, 'select', 'error'], false),
    [FETCH_TICKET_TEMPLATE_FIELD_SELECT_ERROR]: (
      state,
      { payload: { field } }
    ) =>
      state
        .setIn(['ticketTemplateFields', field.name, 'select', 'loading'], false)
        .setIn(['ticketTemplateFields', field.name, 'select', 'error'], true),
    [FETCH_TICKET_TEMPLATE_FIELD_SELECT_SUCCESS]: (
      state,
      { payload: { field, data } }
    ) =>
      state
        .setIn(['ticketTemplateFields', field.name, 'select', 'loading'], false)
        .setIn(['ticketTemplateFields', field.name, 'select', 'error'], false)
        .setIn(
          ['ticketTemplateFields', field.name, 'select', 'data'],
          fromJS(data)
        ),
    [SAVE_CUSTOM_TICKET]: state =>
      state
        .setIn(['createTicketStatus', 'error'], false)
        .setIn(['createTicketStatus', 'assignEscalationError'], false)
        .setIn(['createTicketStatus', 'loading'], true),
    [SAVE_CUSTOM_TICKET_ERROR]: state =>
      state
        .setIn(['createTicketStatus', 'error'], true)
        .setIn(['createTicketStatus', 'loading'], false),
    [SAVE_CUSTOM_TICKET_SUCCES]: (state, { payload }) =>
      state
        .setIn(['createTicketStatus', 'error'], false)
        .setIn(['createTicketStatus', 'loading'], false)
        .setIn(['createTicketStatus', 'data'], fromJS(payload.data)),
    [UPDATE_CUSTOM_TICKET]: state =>
      state
        .setIn(['createTicketStatus', 'error'], false)
        .setIn(['createTicketStatus', 'assignEscalationError'], false)
        .setIn(['createTicketStatus', 'loading'], true),
    [UPDATE_CUSTOM_TICKET_ERROR]: state =>
      state
        .setIn(['createTicketStatus', 'error'], true)
        .setIn(['createTicketStatus', 'loading'], false),
    [UPDATE_CUSTOM_TICKET_SUCCES]: (state, { payload }) =>
      state
        .setIn(['createTicketStatus', 'error'], false)
        .setIn(['createTicketStatus', 'loading'], false)
        .setIn(['createTicketStatus', 'data'], fromJS(payload.data)),
    [ASSIGN_TICKET_ESCALTION_ERROR]: state =>
      state
        .setIn(['createTicketStatus', 'error'], true)
        .setIn(['createTicketStatus', 'assignEscalationError'], true),
  },
  initialState
)

export default createTicketModalReducer
