import { fromJS, Map } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  GET_INTEGRATIONS,
  SET_INTEGRATIONS,
  SET_INTEGRATIONS_ERROR,
  CREATE_INTEGRATION,
  CREATE_INTEGRATION_SUCCESS,
  CREATE_INTEGRATION_ERROR,
  DELETE_INTEGRATION,
  DELETE_INTEGRATION_SUCCESS,
  DELETE_INTEGRATION_ERROR,
  UPDATE_INTEGRATION,
  UPDATE_INTEGRATION_SUCCESS,
  UPDATE_INTEGRATION_ERROR,
  ASSIGN_INTEGRATION,
  ASSIGN_INTEGRATION_SUCCESS,
  ASSIGN_INTEGRATION_ERROR,
  DELETE_INTEGRATION_ASSIGNMENT,
  DELETE_INTEGRATION_ASSIGNMENT_SUCCESS,
  DELETE_INTEGRATION_ASSIGNMENT_ERROR,
  UPDATE_INTEGRATION_CONFIG,
  UPDATE_INTEGRATION_CONFIG_SUCCESS,
  UPDATE_INTEGRATION_CONFIG_ERROR,
} from './constants'

const initialState = fromJS({
  loadingIntegrations: false,
  integrations: null,
  getIntegrationsError: null,
  creatingIntegration: false,
  creatingError: null,
  deletingIntegrations: Map(),
  deletingError: null,
  updatingIntegrations: Map(),
  assigningIntegrations: Map(),
  updatingIntegrationError: Map(),
  assigningIntegrationError: null,
  deletingIntegrationAssignments: Map(),
  deletingIntegrationAssignmentsError: Map(),
  updatingIntegrationConfigs: Map(),
  updatingIntegrationConfigsError: Map(),
})

const platformSettingsReducers = handleActions(
  {
    [GET_INTEGRATIONS]: state => state.set('loadingIntegrations', true),
    [SET_INTEGRATIONS]: (state, { payload }) =>
      state
        .set('loadingIntegrations', false)
        .set('integrations', fromJS(payload))
        .set('getIntegrationsError', null),
    [SET_INTEGRATIONS_ERROR]: (state, { payload }) =>
      state
        .set('loadingIntegrations', false)
        .set('integrations', null)
        .set('getIntegrationsError', payload),
    [CREATE_INTEGRATION]: state => state.set('creatingIntegration', true),
    [CREATE_INTEGRATION_SUCCESS]: (state, { payload }) =>
      state
        .set('creatingIntegration', false)
        .update('integrations', integrations =>
          integrations.push(fromJS(payload))
        )
        .set('creatingError', null),
    [CREATE_INTEGRATION_ERROR]: (state, { payload }) =>
      state.set('creatingError', payload).set('creatingIntegration', false),
    [DELETE_INTEGRATION]: (state, { payload }) =>
      state.setIn(['deletingIntegrations', payload.srn], true),
    [DELETE_INTEGRATION_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['deletingIntegrations', payload.srn])
        .set('deletingError', null)
        .update('integrations', integrations =>
          integrations.filter(int => int.get('srn') !== payload.srn)
        ),
    [DELETE_INTEGRATION_ERROR]: (state, { payload }) =>
      state
        .deleteIn(['deletingIntegrations', payload.srn])
        .set('deletingError', payload.error),
    [UPDATE_INTEGRATION]: (state, { payload }) =>
      state.setIn(['updatingIntegrations', payload.srn], true),
    [UPDATE_INTEGRATION_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['updatingIntegrations', payload.srn])
        .deleteIn(['updatingIntegrationError', payload.srn])
        .update('integrations', integrations =>
          integrations.map(integration =>
            integration.get('srn') === payload.srn
              ? integration
                  .set('title', payload.updated.title)
                  .set('description', payload.updated.description)
              : integration.set()
          )
        ),
    [UPDATE_INTEGRATION_ERROR]: (state, { payload }) =>
      state
        .deleteIn(['updatingIntegrations', payload.srn])
        .setIn(['updatingIntegrationError', payload.srn], payload.error),
    [UPDATE_INTEGRATION_CONFIG]: (state, { payload }) =>
      state.setIn(['updatingIntegrationConfigs', payload.srn], true),
    [UPDATE_INTEGRATION_CONFIG_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['updatingIntegrationConfigs', payload.configSrn])
        .deleteIn(['updatingIntegrationConfigsError', payload.configSrn])
        .update('integrations', integrations =>
          integrations.map(integration =>
            integration.update('configs', configs => {
              if (configs) {
                return configs.map(config =>
                  config.get('srn') == payload.configSrn
                    ? config.set(
                        payload.type.toLowerCase(),
                        fromJS(payload.config)
                      )
                    : config.set()
                )
              } else {
                return null
              }
            })
          )
        ),
    [UPDATE_INTEGRATION_CONFIG_ERROR]: (state, { payload }) =>
      state
        .deleteIn(['updatingIntegrationConfigs', payload.srn])
        .setIn(['updatingIntegrationConfigsError', payload.srn], payload.error),
    [ASSIGN_INTEGRATION]: (state, { payload }) =>
      state.setIn(['assigningIntegrations', payload.swimlaneSrn], true),
    [ASSIGN_INTEGRATION_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['assigningIntegrations', payload.swimlaneSrn])
        .set('assigningIntegrationError', null),
    [ASSIGN_INTEGRATION_ERROR]: (state, { payload }) =>
      state
        .set('assigningIntegrationError', payload.error)
        .deleteIn(['assigningIntegrations', payload.swimlaneSrn]),
    [DELETE_INTEGRATION_ASSIGNMENT]: (state, { payload }) =>
      state.setIn(
        ['deletingIntegrationAssignments', payload.assignmentSrn],
        true
      ),
    [DELETE_INTEGRATION_ASSIGNMENT_ERROR]: (state, { payload }) =>
      state
        .deleteIn(['deletingIntegrationAssignments', payload.assignmentSrn])
        .setIn(
          ['deletingIntegrationAssignments', payload.assignmentSrn],
          payload.error
        ),
    [DELETE_INTEGRATION_ASSIGNMENT_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['deletingIntegrationAssignments', payload.assignmentSrn])
        .update('integrations', integrations =>
          integrations.map(integration =>
            integration.update('configs', configs => {
              if (configs) {
                return configs.filter(
                  config => config.get('srn') !== payload.assignmentSrn
                )
              } else {
                return null
              }
            })
          )
        ),
  },
  initialState
)

export default platformSettingsReducers
