export const NEXT_STEP = `app/containers/TicketAutoRemediateWizard/NEXT_STEP`
export const PREV_STEP = `app/containers/TicketAutoRemediateWizard/PREV_STEP`
export const RESET_STATE = `app/containers/TicketAutoRemediateWizard/RESET_STATE`
export const SELECT_BOT = `app/containers/TicketAutoRemediateWizard/SELEC_BOT`

export const FETCH_ALL_ESCALATIONS = `app/containers/TicketAutoRemediateWizard/FETCH_ALL_ESCALATIONS`
export const FETCH_ALL_ESCALLATIONS_SUCCESS = `app/containers/TicketAutoRemediateWizard/FETCH_ALL_ESCALLATIONS_SUCCESS`
export const FETCH_ALL_ESCALLATIONS_ERROR = `app/containers/TicketAutoRemediateWizard/FETCH_ALL_ESCALLATIONS_ERROR`

export const SUBMIT_AUTO_REMEDIATE = `app/containers/TicketAutoRemediateWizard/SUBMIT_AUTO_REMEDIATE`
export const SUBMIT_AUTO_REMEDIATE_SUCCESS = `app/containers/TicketAutoRemediateWizard/SUBMIT_AUTO_REMEDIATE_SUCCESS`
export const SUBMIT_AUTO_REMEDIATE_ERROR = `app/containers/TicketAutoRemediateWizard/SUBMIT_AUTO_REMEDIATE_ERROR`
export const SUBMIT_AUTO_REMEDIATE_NOT_ACCEPTED = `app/containers/TicketAutoRemediateWizard/SUBMIT_AUTO_REMEDIATE_NOT_ACCEPTED`
export const SUBMIT_AUTO_REMEDIATE_NO_COLLECTOR_MATCHED = `app/containers/TicketAutoRemediateWizard/SUBMIT_AUTO_REMEDIATE_NO_COLLECTOR_MATCHED`
