import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectAuditNodeViewCardLayoutDomain = state =>
  state.get('auditNodeViewCardLayout', Map())

export const selectAuditContent = createSelector(
  selectAuditNodeViewCardLayoutDomain,
  state => state.get('auditContent') || Map()
)

export const selectIsLoading = createSelector(
  selectAuditNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
