/**
 *
 * GlobalNavBar
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import Color from 'color'

import featureFlags from 'containers/FeatureFlags'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Logo from 'components/Logo'
import NavBarLink from 'components/NavBarLink'
import { resetEditor } from 'containers/Search/actions'
import { resetQuery } from 'containers/SearchQuery/actions'

import messages from './messages'
import styles from './styles'
import { selectPathname, selectMatchPath } from './selectors'

export class GlobalNavBar extends React.Component {
  shouldComponentUpdate(newProps) {
    return newProps.pathname !== this.props.pathname
  }

  /*
    Feature flag example, do not remove
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    <Flag
      name="secretFeature"
      render={() => (
        <NavBarLink
          name="secretFeatureLink"
          to={`${this.props.match.path}/secretFeature`}
          fullname={<FormattedMessage {...messages.secretFeature} />}
          fixed
          active={
            this.props.pathname === `${this.props.matchPath}/secretFeature`
          }
        />
      )}
    />
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */

  resetSearch = () => {
    this.props.resetEditor()
    this.props.resetQuery()
  }

  render() {
    return (
      <div
        id="GNB-Container"
        className="slide-in-left"
        style={styles.container}
      >
        <div id="GNB-Boilerplate" style={{ position: 'relative' }}>
          <div id="GNB-Clouds" style={styles.clouds} />
          <div
            id="GNB-Gradient"
            style={{
              ...styles.background,
              ...{
                backgroundImage: `linear-gradient(180deg, ${
                  this.props.theme.primary
                } 0%,
                ${Color(this.props.theme.primary).blacken(0.5).hex()} 44%,
                ${Color(this.props.theme.primary).blacken(0.9).hex()} 100%)`,
              },
            }}
          />
          <div
            style={{ cursor: 'pointer' }}
            onClick={this.props.navigateToOverview}
          >
            <Logo id="GNB-SonraiLogo" style={styles.logo} />
          </div>
          <div id="GNB-Line" style={styles.lineTop} />
        </div>
        <div id="GNB-TopLinks" style={styles.topLinks}>
          <ul>
            <NavBarLink
              name="securityCenterLink"
              to={`${this.props.match.path}/SecurityCenter?view=vis`}
              fullname={<FormattedMessage {...messages.sc} />}
              fixed
              active={
                this.props.pathname === `${this.props.matchPath}/SecurityCenter`
              }
            />
            <NavBarLink
              name="search"
              to={`${this.props.matchPath}/Search`}
              fullname={<FormattedMessage {...messages.search} />}
              fixed
              active={this.props.pathname === `${this.props.matchPath}/Search`}
              onClick={this.resetSearch}
            />
            <NavBarLink
              name="pieChart"
              to={`${this.props.matchPath}/SolutionCenter`}
              fullname={<FormattedMessage {...messages.solutionCenter} />}
              fixed
              active={
                this.props.pathname === `${this.props.matchPath}/SolutionCenter`
              }
            />
            <NavBarLink
              name="list-alt"
              fa
              style={{
                fontSize: '20px',
                height: '20px',
                width: '20px',
                color: 'rgb(255, 255, 255, 0.75)',
              }}
              to={`${this.props.match.path}/Tickets`}
              fullname={<FormattedMessage {...messages.tickets} />}
              fixed
              active={this.props.pathname === `${this.props.matchPath}/Tikcets`}
            />

            <NavBarLink
              name="monitoredResources"
              to={`${this.props.matchPath}/MonitoredResources`}
              fullname={<FormattedMessage {...messages.monitoredResources} />}
              fixed
              active={
                this.props.pathname ===
                `${this.props.matchPath}/MonitoredResources`
              }
            />
            <NavBarLink
              name="heartbeat"
              style={{
                fontSize: '18px',
                height: '18px',
                width: '18px',
                color: 'rgb(255, 255, 255, 0.75)',
              }}
              to={`${this.props.match.path}/SwimlaneCheckup`}
              fullname={<FormattedMessage {...messages.swimlaneCheckup} />}
              fixed
              fa
              active={
                this.props.pathname ===
                `${this.props.matchPath}/SwimlaneCheckup`
              }
            />
          </ul>
        </div>
        <div id="GNB-BottonLinks" style={styles.bottomLinks}>
          <ul>
            <WithPermission
              multiPermissions={[
                { permissionName: 'edit.swimlanes' },
                { permissionName: 'edit.roleassignments' },
                { permissionName: 'assign.bots' },
              ]}
            >
              <NavBarLink
                name="column"
                to={`${this.props.matchPath}/SwimlaneManagement`}
                fullname={<FormattedMessage {...messages.swimlanes} />}
                fixed
                style={{
                  fontSize: '18px',
                  height: '18px',
                  width: '18px',
                  color: 'rgb(255, 255, 255, 0.75)',
                }}
                active={
                  this.props.pathname ===
                  `${this.props.matchPath}/SwimlaneManagement`
                }
                fa
              />
            </WithPermission>
            <NavBarLink
              name="alert"
              to={`${this.props.matchPath}/ControlCenter`}
              fullname={<FormattedMessage {...messages.controlCenter} />}
              fixed
              active={
                this.props.pathname === `${this.props.matchPath}/ControlCenter`
              }
            />
            <WithPermission
              multiPermissions={[
                { permissionName: 'edit.users' },
                { permissionName: 'edit.invites' },
                { permissionName: 'edit.roleassignments' },
              ]}
            >
              <NavBarLink
                name="users"
                to={`${this.props.match.path}/UserManagement`}
                fullname={<FormattedMessage {...messages.userManagement} />}
                fixed
                active={
                  this.props.pathname ===
                  `${this.props.matchPath}/UserManagement`
                }
              />
            </WithPermission>
            <NavBarLink
              name="key"
              style={{
                fontSize: '18px',
                height: '18px',
                width: '18px',
                color: 'rgb(255, 255, 255, 0.75)',
              }}
              to={`${this.props.match.path}/RoleManagement`}
              fullname={<FormattedMessage {...messages.roleManagement} />}
              fixed
              fa
              active={
                this.props.pathname === `${this.props.matchPath}/RoleManagement`
              }
            />
            <WithPermission permissionName="edit.bots">
              <NavBarLink
                name="bot"
                style={{
                  fontSize: '22px',
                  height: '22px',
                  width: '22px',
                  color: 'rgb(255, 255, 255, 0.75)',
                }}
                fa
                to={`${this.props.match.path}/BotManagement`}
                fullname={<FormattedMessage {...messages.bots} />}
                active={
                  this.props.pathname ===
                  `${this.props.matchPath}/BotManagement`
                }
              />
            </WithPermission>
            <NavBarLink
              name="escalation"
              style={{
                fontSize: '22px',
                height: '22px',
                width: '22px',
                color: 'rgb(255, 255, 255, 0.75)',
              }}
              fa
              to={`${this.props.match.path}/Escalation`}
              fullname={<FormattedMessage {...messages.escalation} />}
              active={
                this.props.pathname === `${this.props.matchPath}/Escalation`
              }
            />
            <NavBarLink
              name="tasks"
              style={{
                fontSize: '22px',
                height: '22px',
                width: '22px',
                color: 'rgb(255, 255, 255, 0.75)',
              }}
              fa
              to={`${this.props.match.path}/ObjectivesManager`}
              fullname={<FormattedMessage {...messages.objectiveManager} />}
              active={
                this.props.pathname ===
                `${this.props.matchPath}/ObjectivesManager`
              }
            />
            <WithPermission permissionName="edit.collectors">
              <NavBarLink
                name="cloud"
                to={`${this.props.match.path}/Deploy`}
                fullname={<FormattedMessage {...messages.collectors} />}
                active={
                  this.props.pathname === `${this.props.matchPath}/Deploy`
                }
              />
            </WithPermission>
            <NavBarLink
              name="server"
              to={`${this.props.match.path}/DataClassifierManagement`}
              fullname={
                <FormattedMessage {...messages.customDataClassifiers} />
              }
              active={
                this.props.pathname ===
                `${this.props.matchPath}/DataClassifierManagement`
              }
            />
            <WithPermission permissionName="edit.integrations">
              <NavBarLink
                name="settings"
                to={`${this.props.match.path}/PlatformSettings`}
                fullname={<FormattedMessage {...messages.platformSettings} />}
                active={
                  this.props.pathname ===
                  `${this.props.matchPath}/PlatformSettings`
                }
              />
            </WithPermission>
          </ul>
        </div>
      </div>
    )
  }
}

GlobalNavBar.propTypes = {
  pathname: PropTypes.string,
  matchPath: PropTypes.string.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  navigateToOverview: PropTypes.func,
  resetEditor: PropTypes.func.isRequired,
  resetQuery: PropTypes.func.isRequired,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  pathname: selectPathname,
  matchPath: selectMatchPath,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetEditor,
      resetQuery,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, themeable, featureFlags)(GlobalNavBar)
