import React from 'react'
import PropTypes from 'prop-types'
import hoistNonReactStatics from 'hoist-non-react-statics'

import getInjectors from './reducerInjectors'

/**
 * Dynamically can inject more than one reducer
 * @param {array} reducers a list of reducers having same arguments as for injectReducer hoc
 */
export default ({ reducers }) => WrappedComponent => {
  class MultiReducerInjector extends React.Component {
    static WrappedComponent = WrappedComponent
    static contextTypes = {
      store: PropTypes.object.isRequired,
    }
    static displayName = `withMultipleReducers(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'})`

    constructor(props, context) {
      super(props, context)
      const injectors = getInjectors(this.context.store)
      const { injectReducer } = injectors
      reducers.forEach(({ key, reducer }) => injectReducer(key, reducer))
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return hoistNonReactStatics(MultiReducerInjector, WrappedComponent)
}
