import { put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import { GET_ENCRYPTION_KEY_CONTENT } from './constants'
import { setEncryptionKeyContent } from './actions'
import { ENCRYPTION_KEY_NODE_VIEW_QUERY } from 'static-queries'

function* getEncryptionKeyContent(action) {
  const { srn } = action.payload
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${ENCRYPTION_KEY_NODE_VIEW_QUERY}
      `,
      variables: {
        srn,
      },
    })

    const content = results.data.EncryptionKeys.items
    if (content === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setEncryptionKeyContent(_.get(content, 0)))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* EncryptionKeyNodeSaga() {
  yield takeLatest(GET_ENCRYPTION_KEY_CONTENT, getEncryptionKeyContent)
}

export default EncryptionKeyNodeSaga
