import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import './styles.css'

const LinkRenderer = props => {
  return (
    <a href={props.href} target="_blank" rel="nofollow noopener noreferrer">
      {props.children}
    </a>
  )
}

LinkRenderer.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
}

export class MarkdownDisplay extends React.Component {
  render() {
    return (
      <div className="markdown-display">
        <ReactMarkdown
          source={this.props.content}
          linkTarget="_blank"
          renderers={{ link: LinkRenderer }}
        />
      </div>
    )
  }
}

MarkdownDisplay.propTypes = {
  content: PropTypes.string,
}

export default MarkdownDisplay
