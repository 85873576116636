import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectEncryptionKeyNodeViewCardLayoutDomain = state =>
  state.get('encryptionKeyNodeViewCardLayout', Map())

export const selectEncryptionKeyContent = createSelector(
  selectEncryptionKeyNodeViewCardLayoutDomain,
  state => state.get('encryptionKeyContent') || Map()
)

export const selectIsLoading = createSelector(
  selectEncryptionKeyNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
