export const ASSIGN_TICKET_ESCALTION_ERROR = `app/containers/CreateUpdateTicketModal/ASSIGN_TICKET_ESCALTION_ERROR`

export const FETCH_TICKET_TEMPLATES = `app/containers/CreateUpdateTicketModal/FETCH_TICKET_TEMPLATES`
export const FETCH_TICKET_TEMPLATES_ERROR = `app/containers/CreateUpdateTicketModal/FETCH_TICKET_TEMPLATES_ERROR`
export const FETCH_TICKET_TEMPLATES_SUCCESS = `app/containers/CreateUpdateTicketModal/FETCH_TICKET_TEMPLATES_SUCCESS`

export const FETCH_TICKET_TEMPLATE_FIELD_SELECT = `app/containers/CreateUpdateTicketModal/FETCH_TICKET_TEMPLATE_FIELD_SELECT`
export const FETCH_TICKET_TEMPLATE_FIELD_SELECT_ERROR = `app/containers/CreateUpdateTicketModal/FETCH_TICKET_TEMPLATE_FIELD_SELECT_ERROR`
export const FETCH_TICKET_TEMPLATE_FIELD_SELECT_SUCCESS = `app/containers/CreateUpdateTicketModal/FETCH_TICKET_TEMPLATE_FIELD_SELECT_SUCCESS`

export const SAVE_CUSTOM_TICKET = `app/containers/CreateUpdateTicketModal/SAVE_CUSTOM_TICKET`
export const SAVE_CUSTOM_TICKET_ERROR = `app/containers/CreateUpdateTicketModal/SAVE_CUSTOM_TICKET_ERROR`
export const SAVE_CUSTOM_TICKET_SUCCES = `app/containers/CreateUpdateTicketModal/SAVE_CUST_TICKET_SUCCES`

export const UPDATE_CUSTOM_TICKET = `app/containers/CreateUpdateTicketModal/UPDATE_CUSTOM_TICKET`
export const UPDATE_CUSTOM_TICKET_ERROR = `app/containers/CreateUpdateTicketModal/UPDATE_CUSTOM_TICKET_ERROR`
export const UPDATE_CUSTOM_TICKET_SUCCES = `app/containers/CreateUpdateTicketModal/UPDATE_CUSTOM_TICKET_SUCCES`

export const SET_TICKET_TYPE = `app/CreateUpdateTicketModal/SET_TICKET_TYPE`
export const SET_SELECTED_TICKET_TEMPLATE = `app/CreateUpdateTicketModal/SET_SELECTED_TICKET_TEMPLATE`
export const CLEAR_STATE = `app/CreateUpdateTicketModal/CLEAR_STATE`
export const SET_TICKET_TEMPLATE_FIELD_VALUE = `app/containers/CreateUpdateTicketModal/SET_TICKET_TEMPLATE_FIELD_VALUE`
