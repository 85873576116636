import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import { GET_DATA, SET_DATA, ERR_DATA } from './constants'

const initialState = fromJS({
  data: {
    data: null,
    error: false,
    loading: true,
  },
})

const nodeViewMiniPopout = handleActions(
  {
    [GET_DATA]: state => {
      return state
        .setIn(['data', 'error'], false)
        .setIn(['data', 'loading'], true)
    },
    [SET_DATA]: (state, { payload }) =>
      state
        .setIn(['data', 'error'], false)
        .setIn(['data', 'loading'], false)
        .setIn(['data', 'data'], fromJS(payload.data)),
    [ERR_DATA]: state =>
      state.setIn(['data', 'error'], true).setIn(['data', 'loading'], false),
  },
  initialState
)

export default nodeViewMiniPopout
