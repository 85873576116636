import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import PropTypes from 'prop-types'
import MarkdownEditor from 'components/MarkdownEditor'
import Button from 'components/Button'

class AddTicketComment extends Component {
  state = {
    comment: '',
  }

  styles = {
    container: {
      height: '100%',
      margin: '0.25rem 0rem 0.5rem 0rem',
    },
    disabled: {
      height: '100%',
      backgroundColor: this.props.theme.neutralLight,
      pointerEvents: 'none',
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
    },
    error: {
      fontSize: '0.9rem',
      color: this.props.theme.fail,
      margin: '0rem 0rem 0rem 1rem',
    },
  }

  handleChange = comment => this.setState({ comment })

  handleBlur = comment => this.setState({ comment })

  handleAddComment = () => {
    this.props.addComment({
      ticketSrn: this.props.ticketSrn,
      body: this.state.comment,
      createdBy: this.props.currentUser,
    })
    this.setState({ comment: null })
  }

  render() {
    const isDisabled =
      this.props.ticketComments.get('loading') ||
      this.props.ticketComments.get('error') ||
      !this.state.comment
    const isEditDisabled =
      this.props.ticketComments.get('loading') ||
      this.props.ticketComments.get('error')
    return (
      <div style={this.styles.container}>
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'auto auto',
            gridGap: '0.5rem',
          }}
        >
          {isEditDisabled ? (
            <div style={this.styles.disabled}>
              <MarkdownEditor
                value={this.state.comment}
                onChange={this.handleBlur}
                onKeyStroke={this.handleChange}
                disabled={
                  this.props.ticketComments.get('loading') ||
                  this.props.ticketComments.get('error')
                }
              />
            </div>
          ) : (
            <div>
              <MarkdownEditor
                value={this.state.comment}
                onChange={this.handleBlur}
                onKeyStroke={this.handleChange}
                disabled={
                  this.props.ticketComments.get('loading') ||
                  this.props.ticketComments.get('error')
                }
              />
            </div>
          )}
          <div style={this.styles.footer}>
            <Button
              color="primary"
              onClick={this.handleAddComment}
              disabled={isDisabled}
              style={isDisabled && { pointerEvents: 'none' }}
            >
              Add Comment
            </Button>
            {this.props.addCommentError && (
              <div style={this.styles.error}> Failed to add comment </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

AddTicketComment.propTypes = {
  ticketComments: ImmutablePropTypes.iterable.isRequired,
  addComment: PropTypes.func.isRequired,
  ticketSrn: PropTypes.string.isRequired,
  currentUser: PropTypes.string.isRequired,
  addCommentError: PropTypes.bool.isRequired,
  theme: themeShape,
}

export default themeable(AddTicketComment)
