import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import SizeMe from 'components/SizeMe'
import TextLink from 'components/TextLink'
export class BigStat extends React.Component {
  styles = {
    number: {
      textAlign: 'center',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
    },
    searchLink: {
      fontWeight: '300',
      cursor: 'pointer',
      transition: 'color 0.1s ease-out',
    },
  }

  shouldComponentUpdate = newProps => {
    return (
      newProps.height !== this.props.height ||
      newProps.width !== this.props.width ||
      newProps.data !== this.props.data
    )
  }

  getTextSize = (text, fontSize) => {
    var canvas = this.canvas || (this.canvas = document.createElement('canvas'))
    var context = canvas.getContext('2d')
    context.font = `300 ${fontSize}px 'Roboto', 'Helvetica Neue', Arial, sans-serif`
    var metrics = context.measureText(text)
    return metrics.width
  }

  getFontSize = () => {
    const formatted = `${numeral(this.props.data).format('0.[00]a')}`

    let fontSize = 60

    let textSize = this.getTextSize(formatted, fontSize)

    while (textSize > this.props.width && fontSize > 20) {
      fontSize = fontSize - 5
      textSize = this.getTextSize(formatted, fontSize)
    }

    return fontSize
  }

  render() {
    return (
      <div className="big-stat-value" style={this.styles.number}>
        <TextLink
          title={this.props.title}
          className="big-stat-link"
          style={{
            ...this.styles.searchLink,
            fontSize: `${this.getFontSize()}px`,
            lineHeight: `${this.props.height}px`,
          }}
          onClick={this.props.onClick}
        >
          {numeral(this.props.data).format('0.[00]a')}
        </TextLink>
      </div>
    )
  }
}

BigStat.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default SizeMe(BigStat)
