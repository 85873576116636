/**
 *
 * CustomDateRange
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import TextLink from 'components/TextLink'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import SelectBar from 'components/SelectBar'

const defaultValue = { label: 'Within the past 24 hours', value: 1 }

class CustomDateRange extends React.Component {
  state = {
    isAdvanced: false,
  }

  styles = {
    container: {
      display: 'grid',
      gridTemplateColumns: '375px 150px',
      padding: '0.75rem 0.75rem 0.25rem 0.75rem',
    },
    textContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  }

  toggleDateRange = () => {
    const { isAdvanced } = this.state
    if (isAdvanced) {
      this.props.onDatesChange(defaultValue)
    }
    this.setState(oldState => ({ isAdvanced: !oldState.isAdvanced }))
  }

  render() {
    const options = [
      { label: 'Within the past 24 hours', value: 1 },
      { label: 'Within the past 7 days', value: 7 },
      { label: 'Within the past 30 days', value: 30 },
      { label: 'Within the past 60 days', value: 60 },
      { label: 'Within the past 90 days', value: 90 },
    ]
    return (
      <div style={this.styles.container}>
        <div>
          {this.state.isAdvanced ? (
            <DateRangePicker
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              isOutsideRange={date => moment(date).isAfter(moment())}
              {...this.props}
            />
          ) : (
            <SelectBar
              autoSort={false}
              options={options}
              defaultValue={defaultValue}
              isClearable={false}
              onChange={this.props.onDatesChange}
            />
          )}
        </div>
        <div style={this.styles.textContainer}>
          <TextLink
            color="primary"
            style={{ margin: '0rem 0rem 0rem 0.5rem' }}
            onClick={this.toggleDateRange}
          >
            {this.state.isAdvanced ? 'Relative Date' : 'Custom Date Range'}
          </TextLink>
        </div>
      </div>
    )
  }
}

CustomDateRange.defaultProps = {
  displayFormat: 'MMMM D YYYY',
}

CustomDateRange.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  displayFormat: PropTypes.string,
  onDatesChange: PropTypes.func.isRequired,
}

export default CustomDateRange
