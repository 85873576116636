import axios from 'axios'

const configureAxios = authToken => {
  window.axios = axios.create({
    headers: {
      common: {
        Authorization: `Bearer ${authToken}`,
        'Access-Control-Allow-Origin': '*',
      },
    },
  })
}

export { configureAxios }
export default configureAxios
