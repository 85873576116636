/**
 *
 * InfoCard
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import Card, { TopTitle, CardBody } from 'components/Card'
import TextLink from 'components/TextLink'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class InfoCard extends React.Component {
  constructor(props) {
    super(props)
    this.styles = {
      header: {
        fontSize: '1.2em',
        padding: '1em',
      },
      detail: {
        padding: '0.5em',
        display: 'grid',
        gridTemplateColumns: '30% 1fr',
        gridColumnGap: '1em',
      },
      overrideCard: {
        backgroundColor: props.theme.highlight,
        padding: '0',
        overflow: 'hidden',
      },
      overrideHeader: {
        paddingTop: '0',
        paddingBottom: '0',
        paddingLeft: '0.4em',
        paddingRight: '0.4em',
      },
      titleLink: {
        overflow: 'hidden',
        whiteSpace: 'no-wrap',
        display: 'block',
        textOverflow: 'ellipsis',
      },
      body: {
        backgroundColor: '#fff',
        alignItems: 'start',
        justifyContent: 'start',
        padding: '1em',
        overflow: 'auto',
        height: 'auto',
      },
    }
  }

  render() {
    return (
      <Card
        containerStyle={this.styles.overrideCard}
        headerStyle={this.styles.overrideHeader}
      >
        <TopTitle>
          <div style={this.styles.header}>
            <TextLink onClick={this.props.onClick}>{this.props.title}</TextLink>
          </div>
        </TopTitle>
        <CardBody style={this.styles.body}>
          <div style={{ width: '100%', overflow: 'auto' }}>
            {React.Children.map(this.props.children, child => (
              <div style={this.styles.detail}>{child}</div>
            ))}
          </div>
        </CardBody>
      </Card>
    )
  }
}

InfoCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  onClick: PropTypes.func,
  theme: themeShape,
}

export default themeable(InfoCard)
export Property from './Property'
export DateProperty from './DateProperty'
export BoolProperty from './BoolProperty'
