/**
 *
 * BoolProperty
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const styles = {
  code: {
    fontFamily: 'monospace',
    fontSize: '1.1rem',
  },
  true: {
    color: '#2d3e50',
    fontWeight: '500',
  },
  false: {
    color: '#666',
  },
}

class BoolProperty extends React.Component {
  getValue = () => {
    return this.props.value !== undefined && this.props.value !== null
      ? this.props.value.toString()
      : '-'
  }

  getStyle = value => {
    if (value === 'true') {
      return { ...styles.code, ...styles.true }
    }

    if (value === 'false') {
      return { ...styles.code, ...styles.false }
    }

    return {}
  }

  render() {
    const value = this.getValue()
    return (
      <Fragment>
        <div>{this.props.label}</div>
        <div style={this.getStyle(value)}>{value}</div>
      </Fragment>
    )
  }
}

BoolProperty.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default BoolProperty
