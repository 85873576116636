import { put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import {
  USER_NODE_VIEW_ACTIONS_PERFORMED_QUERY,
  USER_NODE_VIEW_QUERY,
} from 'static-queries'

import { GET_ACTIONS_PERFORMED, GET_USER_CONTENT } from './constants'
import { setActionsPerformed, setUserContent } from './actions'

function* getActions(action) {
  const { srn, range } = action.payload

  try {
    const client = getClient()
    const results = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        ${USER_NODE_VIEW_ACTIONS_PERFORMED_QUERY}
      `,
      variables: {
        srn,
        range,
      },
    })
    const actionsPerformed = results.data.Actions.items
    if (actionsPerformed === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setActionsPerformed(actionsPerformed))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getUserContent(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${USER_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Users.items

    if (content === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setUserContent(_.get(content, 0)))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* userNodeSaga() {
  yield takeLatest(GET_ACTIONS_PERFORMED, getActions)
  yield takeLatest(GET_USER_CONTENT, getUserContent)
}

export default userNodeSaga
