import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import BarLoadingAnimation from 'components/BarLoadingAnimation'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'

import messages from './messages'

export default function Step3(props) {
  if (props.submitAutoRemediateStatus.get('loading')) {
    return <BarLoadingAnimation />
  }

  if (props.submitAutoRemediateStatus.get('error')) {
    return <DynamicFormattedMessage {...messages.submitErrorMessage} />
  }

  if (props.submitAutoRemediateStatus.get('notAccepted')) {
    return (
      <Fragment>
        <DynamicFormattedMessage {...messages.submitNotAcceptedMessage} />
        <TextLink color="primary" onClick={props.resetState}>
          <DynamicFormattedMessage {...messages.chooseDifferentBot} />
        </TextLink>
      </Fragment>
    )
  }

  if (props.submitAutoRemediateStatus.get('noCollectorMatched')) {
    return (
      <Fragment>
        <DynamicFormattedMessage
          {...messages.submitNoCollectorMatchedMessage}
        />
      </Fragment>
    )
  }

  return <DynamicFormattedMessage {...messages.submitSuccessMessage} />
}

Step3.propTypes = {
  resetState: PropTypes.func,
  submitAutoRemediateStatus: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }).isRequired,
}
