import { isImmutable } from 'immutable'

const convertToWildcardRegex = permissionName => {
  return new RegExp(
    '^' +
      permissionName
        .replace(/[.+?^${}()|[\]\\]/g, '\\$&')
        .replace(/\*/g, '.*') +
      '$'
  )
}

const scopesMatchTarget = ({ target, scopes }) => {
  const scopeValues = Object.keys(scopes)
  const matchingScopeValue = scopeValues.find(scopeValue =>
    target.match(convertToWildcardRegex(scopeValue))
  )
  return matchingScopeValue != undefined
}

const scopesMatchSwimlanes = ({ swimlanes, scopes }) => {
  for (let swimlane of swimlanes) {
    const matchFound = scopesMatchTarget({ target: swimlane, scopes })
    if (matchFound) {
      return true
    }
  }
  return false
}

/**
 * used to check whether the user has a particular paermission
 *
 * @param {String} permissionName name of permission to check
 * @param {Object} permissions permissions the user might have in format
 * returned by the sonrai-users-permissions hook i.e.
 * {
 *   loading: false,
 *   error: false,
 *   data: {
 *     [permissionName]: { scopes: {...} }
 *   }
 * }
 * @param {String} resourceId the resourceId we're asking if we have permission
 * for it. Optional - if not it will just return whether the user has this
 * permission for any resource
 * @param {String} swimlanes the swimlanes to check if the thing has permission
 * for it. Opptional - if not passed it will just return whether the user has
 * this permission for any resource
 */
export const userHasPermission = ({
  permissionName,
  permissions,
  resourceId,
  swimlanes,
}) => {
  // handle case where we accidently pass immutable permissions
  if (isImmutable(permissions)) {
    return userHasPermission({
      permissionName,
      resourceId,
      permissions: permissions.toJS(),
      swimlanes,
    })
  }

  const permissionData = permissions.data || {}

  const permission = Object.keys(permissionData)
    .filter(name => permissionName.match(convertToWildcardRegex(name)))
    .map(name => permissionData[name])[0]

  if (!permission) {
    return false
  }

  const { scopes } = permission

  if (resourceId) {
    return scopesMatchTarget({ target: resourceId, scopes })
  }

  if (swimlanes) {
    return scopesMatchSwimlanes({ swimlanes, scopes })
  }

  return true
}
