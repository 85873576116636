import React from 'react'
import PropTypes from 'prop-types'
import { DropdownToggle } from 'reactstrap'
import Button from 'components/Button'
import BorderlessButton from 'components/BorderlessButton'

class DropdownAnchor extends React.Component {
  render() {
    const { children, borderless, ...pprops } = this.props
    return (
      <DropdownToggle
        tag={borderless ? BorderlessButton : Button}
        data-toggle="dropdown"
        {...pprops}
      >
        {children}
      </DropdownToggle>
    )
  }
}

DropdownAnchor.propTypes = {
  borderless: PropTypes.bool,
  children: PropTypes.node,
}

export default DropdownAnchor
