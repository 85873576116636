/*
 * DataContainerNodeViewCardLayout Messages
 *
 * This contains all the text for the DataContainerNodeViewCardLayout component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.containers.DataContainerNodeViewCardLayout.title',
    defaultMessage: 'Data Container',
  },
  Actions: {
    id: 'app.containers.DataContainerNodeViewCardLayout.Actions',
    defaultMessage: 'Actions (Last 7 Days)',
  },
  performed: {
    id: 'app.containers.DataContainerNodeViewCardLayout.performed',
    defaultMessage: 'Activity',
  },
  Contents: {
    id: 'app.containers.DataContainerNodeViewCardLayout.Contents',
    defaultMessage: 'Contents',
  },
  DataClassifications: {
    id: 'app.containers.DataContainerNodeViewCardLayout.DataClassifications',
    defaultMessage: 'Database Anatomy',
  },
  FingerPrints: {
    id: 'app.containers.DataContainerNodeViewCardLayout.FingerPrints',
    defaultMessage: 'FingerPrints',
  },
  Datacontainer: {
    id: 'app.containers.DataContainerNodeViewCardLayout.Datacontainer',
    defaultMessage: 'Datacontainer',
  },
  activeLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.activeLabel',
    defaultMessage: 'Active',
  },
  typeLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.typeLabel',
    defaultMessage: 'Type',
  },
  accountLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  lastModifiedLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.lastModifiedLabel',
    defaultMessage: 'Last Modified',
  },
  createdLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.createdLabel',
    defaultMessage: 'Created',
  },
  srnLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.srnLabel',
    defaultMessage: 'srn',
  },
  encryptionEnabledLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.encryptionEnabledLabel',
    defaultMessage: 'Encryption',
  },
  auditEnabledLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.auditEnabledLabel',
    defaultMessage: 'Audit',
  },
  versioningEnabledLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.versioningEnabledLabel',
    defaultMessage: 'Versioning',
  },
  publicReadLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.publicReadLabel',
    defaultMessage: 'Public Read',
  },
  publicWriteLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.publicWriteLabel',
    defaultMessage: 'Public Write',
  },
  websiteEnabledLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.websiteEnabledLabel',
    defaultMessage: 'Website',
  },
  writeOnceLabel: {
    id: 'app.containers.DataContainerNodeViewCardLayout.writeOnceLabel',
    defaultMessage: 'Write Once',
  },
  statusDays1: {
    id: 'app.containers.DataContainerNodeViewCardLayout.statusDays1',
    defaultMessage: 'within the past 24 hours',
  },
  statusDays7: {
    id: 'app.containers.DataContainerNodeViewCardLayout.statusDays7',
    defaultMessage: 'within the past 7 days',
  },
  statusDays30: {
    id: 'app.containers.DataContainerNodeViewCardLayout.statusDays30',
    defaultMessage: 'within the past 30 days',
  },
  statusDays90: {
    id: 'app.containers.DataContainerNodeViewCardLayout.statusDays90',
    defaultMessage: 'within the past 90 days',
  },
})
