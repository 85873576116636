/**
 *
 * PublicKeyNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'

import Card, { CardBody, TopTitle } from 'components/Card'
import DataTable from 'components/DataTable'
import { injectIntl } from 'react-intl'

import messages from './messages'
import reducer from './reducer'
import sagas from './sagas'
import { getPublickeyContent } from './actions'
import { selectPublickeyContent, selectIsLoading } from './selectors'

const styles = {
  title: {
    color: '#C8C8C8',
    fontWeight: '500',
    fontSize: '1.2rem',
    marginRight: '0.25rem',
  },
}
export class PublicKeyNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getPublickeyContent(props.nodeData.srn)
  }

  getUsedToAccess = () =>
    this.props.publickeyContent.getIn(['usedToAccess', 'items'], List()).toJS()

  getGridContent = usedToAccess => {
    const usedToAccesses = usedToAccess.map(accessed => {
      return (
        <div key={accessed.ComputeName}>
          <Card loading={this.props.isLoading}>
            <TopTitle>
              <span
                style={{
                  color: '#C8C8C8',
                  fontWeight: '500',
                  fontSize: '1.2rem',
                  margin: '0rem 0.25rem 1rem 0rem ',
                }}
              >
                {this.props.intl.formatMessage(messages.topTitleText)}
              </span>
              {accessed.ComputeName || '-'}
            </TopTitle>

            <CardBody
              style={{
                alignItems: 'start',
                justifyContent: 'start',
                width: '100%',
                height: '100%',
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
                gridTemplateColumns: '1fr',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ margin: '0.5rem' }}>
                  <span style={styles.title}>
                    {this.props.intl.formatMessage(messages.lastLaunched)}
                  </span>
                  {accessed.launchTime
                    ? moment(accessed.launchTime).format('lll')
                    : '-'}
                </div>
                <div style={{ margin: '0rem 0.5rem 0.5rem 0.5rem' }}>
                  <span style={styles.title}>
                    {this.props.intl.formatMessage(messages.architecture)}
                  </span>
                  {accessed.architecture ? accessed.architecture : '-'}
                </div>
              </div>
              <DataTable
                userProfile={this.props.userProfile}
                // setUserProfileTables={this.props.setUserProfileTables}
                data={accessed.isPartOfAction.items.map(item => {
                  return {
                    eventName: item.eventName,
                    createdDate: item.createdDate,
                    performedBy: _.get(item, [
                      'performedBy',
                      'items',
                      0,
                      'name',
                    ]),
                  }
                })}
              />
            </CardBody>
          </Card>
        </div>
      )
    })

    return usedToAccesses
  }

  getColumns = items => {
    return {
      md: [
        {
          widthPercent: 50,
          items: ['graph'],
          heights: {
            graph: 3,
          },
        },
        {
          widthPercent: 50,
          items: items.map(item => item.ComputeName),
          heights: items.reduce((heights, item) => {
            heights[item.ComputeName] = 2
            return heights
          }, {}),
        },
      ],
      xs: [
        {
          widthPercent: 100,
          items: [...items.map(item => item.ComputeName), 'graph'],
          heights: {
            ...items.reduce((heights, item) => {
              heights[item.ComputeName] = 2
              return heights
            }, {}),
            graph: 3,
          },
        },
      ],
    }
  }

  render() {
    const usedToAccess = this.getUsedToAccess()
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          columns={this.getColumns(usedToAccess)}
          gridContent={this.getGridContent(usedToAccess)}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
          renderDetailsTab={false}
        />
      </Fragment>
    )
  }
}
PublicKeyNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  publickeyContent: ImmutablePropTypes.map.isRequired,
  getPublickeyContent: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  userProfile: ImmutablePropTypes.map.isRequired,
  setUserProfileTables: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  userProfile: selectUserProfile,
  publickeyContent: selectPublickeyContent,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPublickeyContent,
      setUserProfileTables,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({
  key: 'publickeyNodeViewCardLayout',
  reducer,
})

const withSaga = injectSaga({
  key: 'publickeyNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(PublicKeyNodeViewCardLayout)
