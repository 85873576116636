import { all, put, call, takeLatest } from 'redux-saga/effects'
import {
  updateChangeDetectionPropertySuccess,
  addChangeDetectionPropertiesSuccess,
  removeChangeDetectionPropertiesSuccess,
} from './actions'
import {
  UPDATE_CHANGE_DETECTION_PROPERTY,
  ADD_CHANGE_DETECTION_PROPERTIES,
  REMOVE_CHANGE_DETECTION_PROPERTIES,
} from './constants'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'
import { exists } from 'utils/sonraiUtils'
import _ from 'lodash'

export function* addProperty(property, resourceSrn) {
  const { keyName, keyType, actionClassification, alertLevel } = property

  try {
    const client = getClient()
    if (!exists(actionClassification) || _.isEmpty(actionClassification)) {
      yield client.mutate({
        mutation: gql`
          mutation setChangeDetectionProperties {
            setChangeDetectionProperties(resourceSrn: "${resourceSrn}", keyType: ${keyType}, keyName: "${keyName}", alertLevel: ${alertLevel ||
          5}) {
              resourceSrn
            }
          }
        `,
      })
    } else {
      if (Array.isArray(actionClassification)) {
        for (let i = 0; i < actionClassification.length; i++) {
          yield client.mutate({
            mutation: gql`
              mutation setChangeDetectionProperties {
                setChangeDetectionProperties(resourceSrn: "${resourceSrn}", keyType: ${keyType}, keyName: "${keyName}", actionClassification: ${
              actionClassification[i]
            }, alertLevel: ${alertLevel || 5}) {
                  resourceSrn
                }
              }
            `,
          })
        }
      } else {
        yield client.mutate({
          mutation: gql`
            mutation setChangeDetectionProperties {
              setChangeDetectionProperties(resourceSrn: "${resourceSrn}", keyType: ${keyType}, keyName: "${keyName}", actionClassification: ${actionClassification}, alertLevel: ${alertLevel ||
            5}) {
                resourceSrn
              }
            }
          `,
        })
      }
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error adding change detection property', e)
  }
}

export function* removeProperty(property, resourceSrn) {
  const { keyName, actionClassification } = property

  try {
    const client = getClient()
    if (!exists(actionClassification) || _.isEmpty(actionClassification)) {
      yield client.mutate({
        mutation: gql`
        mutation deleteChangeDetectionProperty {
          removeChangeDetectionProperties(
            resourceSrn: "${resourceSrn}"
            keyName: "${keyName}"
          )
        }`,
      })
    } else {
      if (Array.isArray(actionClassification)) {
        for (let i = 0; i < actionClassification.length; i++) {
          yield client.mutate({
            mutation: gql`
              mutation deleteChangeDetectionProperty {
                removeChangeDetectionProperties(resourceSrn: "${resourceSrn}", keyName: "${keyName}",  actionClassification: ${
              actionClassification[i]
            },)
                }
              `,
          })
        }
      } else {
        yield client.mutate({
          mutation: gql`
            mutation deleteChangeDetectionProperty {
              removeChangeDetectionProperties(resourceSrn: "${resourceSrn}", keyName: "${keyName}", actionClassification: ${actionClassification}) {
                  resourceSrn
                }
              }
            `,
        })
      }
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error removing change detection property', e)
  }
}

function* addChangeDetectionProperties(action) {
  const { properties, resourceSrn } = action.payload

  try {
    yield put(addChangeDetectionPropertiesSuccess(properties))
    yield all(
      properties
        .toJS()
        .map(property => call(addProperty, property, resourceSrn))
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error adding change detection properties', e)
  }
}
function* removeChangeDetectionProperties(action) {
  const { properties, resourceSrn } = action.payload

  try {
    yield put(removeChangeDetectionPropertiesSuccess(properties))
    yield all(
      properties
        .toJS()
        .map(property => call(removeProperty, property, resourceSrn))
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Error removing change detection properties ${e}`)
  }
}

function* updateChangeDetectionProperty(action) {
  const { toRemove, resourceSrn, newProperty, index } = action.payload
  try {
    if (toRemove) {
      yield all(
        [toRemove].map(property => call(removeProperty, property, resourceSrn))
      )
    }

    yield all(
      [newProperty].map(property => call(addProperty, property, resourceSrn))
    )

    yield put(updateChangeDetectionPropertySuccess({ newProperty, index }))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error updating CD Property', e)
  }
}

function* changeDetectionManagerSaga() {
  yield all([
    takeLatest(UPDATE_CHANGE_DETECTION_PROPERTY, updateChangeDetectionProperty),
    takeLatest(ADD_CHANGE_DETECTION_PROPERTIES, addChangeDetectionProperties),
    takeLatest(
      REMOVE_CHANGE_DETECTION_PROPERTIES,
      removeChangeDetectionProperties
    ),
  ])
}

export default changeDetectionManagerSaga
