import { createAction } from 'redux-actions'
import {
  LOAD_USER,
  FETCH_USER_PROFILE,
  SET_USER_PROFILE,
  SAVE_USER_PROFILE,
  SET_USER_PROFILE_TABLES,
  HIDE_SOLUTION_CARD,
  UNHIDE_SOLUTION_CARDS,
  ACCEPT_LICENSE,
  SET_SECURITY_CENTER_WELCOME_MODAL,
  TOGGLE_SWIMLANE_MANAGER,
  SET_SONRAI_USER_SRN,
  SET_USER_PROFILE_ERROR,
} from './constants'

export const loadUser = createAction(LOAD_USER)
export const setUserProfile = createAction(SET_USER_PROFILE)
export const fetchUserProfile = createAction(FETCH_USER_PROFILE)
export const saveUserProfile = createAction(SAVE_USER_PROFILE)
export const hideSolutionCard = createAction(HIDE_SOLUTION_CARD)
export const unhideSolutionCards = createAction(UNHIDE_SOLUTION_CARDS)
export const acceptLicense = createAction(ACCEPT_LICENSE)
export const toggleSwimlaneManager = createAction(TOGGLE_SWIMLANE_MANAGER)
export const setSonraiUserSrn = createAction(SET_SONRAI_USER_SRN)

export const setUserProfileTables = createAction(
  SET_USER_PROFILE_TABLES,
  (grid, set, id) => ({
    grid,
    set,
    id,
  })
)

export const setSecurityCenterWelcomeModal = createAction(
  SET_SECURITY_CENTER_WELCOME_MODAL
)

export const setUserProfileError = createAction(SET_USER_PROFILE_ERROR)
