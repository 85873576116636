import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

const TextLineShimmer = ({
  baseLength = 175,
  containerWidth = 300,
  randomize = false,
  ...props
}) => {
  const random = Math.random() * (1 - 0.7) + 0.7

  return (
    <span
      style={{ display: 'block', width: `${containerWidth}px`, height: '18px' }}
    >
      <ContentLoader
        height={14}
        width={containerWidth}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect
          x="0"
          y="0"
          rx="3"
          ry="3"
          width={randomize ? baseLength * random : baseLength}
          height="14"
        />
      </ContentLoader>
    </span>
  )
}

TextLineShimmer.propTypes = {
  baseLength: PropTypes.number,
  containerWidth: PropTypes.number,
  randomize: PropTypes.bool,
}

export default TextLineShimmer
