import React from 'react'
import PropTypes from 'prop-types'
import { exists } from 'utils/sonraiUtils'

class NodeViewDetailInt extends React.PureComponent {
  getValue = () => {
    return exists(this.props.value) ? this.props.value.toString() : '-'
  }

  render() {
    return <div>{this.getValue()}</div>
  }
}

NodeViewDetailInt.propTypes = {
  value: PropTypes.bool,
}

export default NodeViewDetailInt
