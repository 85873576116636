/**
 *
 * RapSheet
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import injectSaga from 'utils/injectSaga'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Map, fromJS } from 'immutable'
import moment from 'moment'
import Scrollable from 'components/Scrollable'
import SunburstVis from 'components/SunburstVis'
import themeable from 'containers/ThemeManager/Themeable'
import {
  selectPolicies,
  selectControlGroups,
} from 'containers/ControlFrameworkData/selectors'
import {
  selectLoadedControlGroups,
  selectLoadedPolicies,
} from 'containers/ControlFrameworkData/selectors'

import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import injectReducer from 'utils/injectReducer'
import CenterContent from 'components/CenterContent'
import {
  selectSwimlanes,
  selectSwimlanesBySrn,
  selectObjectives,
  selectObjectivesLoading,
} from 'containers/SonraiData/selectors'
import { fetchObjectives } from 'containers/SonraiData/actions'
import { BlockShimmer } from 'components/ShimmerLoader'
import _ from 'lodash'
import {
  selectSelectedResourceAlerts,
  selectCategoryRollupData,
  selectCategoryRollupLoading,
  selectIsSunburstViewMulti,
  selectResources,
  selectAlertListLoading,
  selectQueryStringTicketSrn,
} from './selectors'
import reducer from './reducer'
import rsSaga from './sagas'
import { sliceBySeverity } from './processData'
import {
  fetchCategoryRollupData,
  fetchFilteredAlerts,
  toggleSunburstView,
} from './actions'
import RapSheetAlerts from './RapSheetAlerts'
export class RapSheet extends React.Component {
  styles = {
    wrapper: {
      height: '100%',
    },
    vis: {
      display: 'grid',
      height: '50%',
      gridTemplateRows: '1fr',
      overflow: 'hidden',
    },
  }

  constructor(props) {
    super(props)

    if (props.objectives.isEmpty()) {
      props.fetchObjectives()
    }

    this.state = {
      sunburstlegendFilters: [],
      pieVariables: {},
      ticketSrn: props.ticketSrn,
    }

    this.updateAllData(props.preFilters)
  }

  componentDidUpdate(oldProps) {
    const frameworksLoaded =
      this.props.loadedControlPolicies && this.props.loadedControlGroups

    const datesChanged =
      this.props.preFilters.get('fromDate') !==
        oldProps.preFilters.get('fromDate') ||
      this.props.preFilters.get('toDate') !== oldProps.preFilters.get('toDate')

    if (
      frameworksLoaded &&
      (!oldProps.loadedControlGroups ||
        !oldProps.loadedControlPolicies ||
        datesChanged)
    ) {
      this.updateAllData(this.props.preFilters)
    }
  }

  updateAllData = (overrideValues = Map()) => {
    this.props.fetchCategoryRollupData({
      resourceId: this.props.resourceId,
      swimlaneSrn: this.props.swimlanes.getIn([
        this.props.selectedSwimlaneName,
        'srn',
      ]),
      category: this.props.selectedCategory,
      visFilters: overrideValues,
    })

    this.updateAlerts(overrideValues)
  }

  updateAlerts = (overrideValues = Map()) => {
    this.props.fetchFilteredAlerts({
      category: this.props.selectedCategory,
      swimlaneSrn: this.props.swimlanes
        ? this.props.swimlanes.getIn([this.props.selectedSwimlaneName, 'srn'])
        : undefined,
      fetchResources: !this.props.resourceId || this.props.resources.isEmpty(),
      ticketSrn: this.state.ticketSrn,
      status: this.props.preFilters.get('status'),
      ...overrideValues.toJS(),
      resourceSrn: overrideValues.get('resourceSrn') || this.props.resourceId,
    })
  }

  onClickPie = clickedSliceFilters => {
    if (_.isEmpty(clickedSliceFilters)) {
      //we cleared all the filters and should just reset
      this.setState({
        pieVariables: {},
      })

      if (!this.props.resourceId && this.props.onFilterChange) {
        this.props.onFilterChange({})
      }

      this.updateAlerts(fromJS({ fetchResources: true }))

      return
    }

    let { key, label, value } = clickedSliceFilters

    //PieChart works with the swimlane title to show the friendly name in the breadcrumbs
    //So if we're filtering by swimlane we need to convert the name to the srn
    if (key === 'swimlane') {
      key = 'swimlaneSrn'
    }

    this.setState(currState => {
      const pieVariables = {
        ...currState.pieVariables,
      }

      if (pieVariables[key] === value) {
        //If slice was already selected, we're DEselecting it!
        delete pieVariables[key]
      } else {
        pieVariables[key] = label
      }

      let filterVariables = {
        resourceSrn: this.props.resourceId,
        ...pieVariables,
      }

      if (!this.props.resourceId && this.props.onFilterChange) {
        this.props.onFilterChange(filterVariables)
      }

      this.updateAlerts(fromJS(filterVariables))

      return {
        pieVariables: pieVariables,
      }
    })
  }

  onClickSunburst = ({ path }) => {
    let filterVariables = path.reduce((variables, slice) => {
      if (!slice.keyName) {
        return variables
      }

      variables[slice.keyName] = slice.keyValue
      return variables
    }, {})

    filterVariables.resourceSrn = this.props.resourceId

    this.setState({
      variables: filterVariables,
    })

    if (filterVariables.controlFrameworkId) {
      if (!filterVariables.ticketKey) {
        filterVariables.ticketKey = filterVariables.controlFrameworkId
        filterVariables.ticketType = 'Framework'
      } else {
        filterVariables.ticketType = 'Policy'
      }
    }

    if (!this.props.resourceId && this.props.onFilterChange) {
      this.props.onFilterChange({ ...filterVariables })
    }

    this.updateAlerts(fromJS({ ...filterVariables }))
  }

  onChangeLegendFilter = filters => {
    const filterVariables = filters.reduce((variables, filter) => {
      variables[filter.keyName] = filter.keyValue
      return variables
    }, this.state.variables || {})

    filterVariables.resourceSrn = this.props.resourceId

    if (filterVariables.controlFrameworkId) {
      if (!filterVariables.keyName) {
        filterVariables.keyName = filterVariables.controlFrameworkId
        filterVariables.keyType = 'Framework'
      } else {
        filterVariables.keyType = 'Policy'
      }
    }

    if (!this.props.resourceId && this.props.onFilterChange) {
      this.props.onFilterChange(filterVariables)
    }

    this.updateAlerts(
      fromJS({
        ...filterVariables,
        fetchResources: true,
      })
    )
  }

  formatSunburstData = data => {
    let formatted = sliceBySeverity(data, {
      colorBySeverity: false,
      typeColors: this.props.typeColors,
      includeLabel: !this.props.resourceId,
      swimlanes: this.props.swimlanesBySrn,
      controlGroups: this.props.controlGroups,
      controlPolicies: this.props.policies,
    })

    return { children: formatted }
  }

  renderVis = () => {
    return (
      <div style={this.styles.vis}>
        <SunburstVis
          data={this.props.categoryRollupData.toJS()}
          dataFormatter={this.formatSunburstData}
          onClickSlice={this.onClickSunburst}
          onFilterChange={this.props.onFilterChange}
          onClickPie={this.onClickPie}
          loading={this.props.categoryRollupLoading}
          preFilters={this.props.preFilters}
          onChangeLegendFilter={this.onChangeLegendFilter}
          toggleSunburstView={this.props.toggleSunburstView}
          isSunburstViewMulti={this.props.isSunburstViewMulti}
          variables={this.state.variables}
          swimlanes={this.props.swimlanesBySrn}
          policies={this.props.policies}
          controlFrameworks={this.props.controlGroups}
        />
      </div>
    )
  }

  renderAlerts = () => {
    if (this.props.alertListLoading) {
      return (
        <div style={{ padding: '1em', height: '100%' }}>
          <BlockShimmer />
        </div>
      )
    }

    return (
      <Fragment>
        <RapSheetAlerts
          data={this.props.alerts.toJS()}
          selectedResourceId={this.props.resourceId}
          setAlertCounts={this.props.setAlertCounts}
          refreshData={this.updateAlerts}
          objectives={this.props.objectives}
        />
      </Fragment>
    )
  }

  render() {
    if (
      !this.props.loadedControlPolicies ||
      !this.props.loadedControlGroups ||
      this.props.objectivesLoading
    ) {
      return (
        <CenterContent style={{ height: '100%' }}>
          <SquareLoadingAnimation />
        </CenterContent>
      )
    }

    return (
      <Scrollable style={this.styles.wrapper}>
        {this.renderVis()}
        {this.renderAlerts()}
      </Scrollable>
    )
  }
}

RapSheet.defaultProps = {
  preFilters: Map({
    fromDate: moment().subtract(7, 'days').toISOString(),
    toDate: moment().toISOString(),
  }),
}

RapSheet.propTypes = {
  alerts: ImmutablePropTypes.list.isRequired,
  alertListLoading: PropTypes.bool,
  categoryRollupData: ImmutablePropTypes.iterable.isRequired,
  categoryRollupLoading: PropTypes.bool,
  controlGroups: ImmutablePropTypes.map.isRequired,
  policies: ImmutablePropTypes.iterable.isRequired,
  fetchCategoryRollupData: PropTypes.func.isRequired,
  fetchFilteredAlerts: PropTypes.func.isRequired,
  loadedControlGroups: PropTypes.bool,
  loadedControlPolicies: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  preFilters: ImmutablePropTypes.map.isRequired,
  resourceId: PropTypes.string,
  resources: ImmutablePropTypes.map.isRequired,
  selectedSwimlaneName: PropTypes.string,
  selectedCategory: PropTypes.string,
  setAlertCounts: PropTypes.func,
  swimlanes: ImmutablePropTypes.map.isRequired,
  swimlanesBySrn: ImmutablePropTypes.map.isRequired,
  typeColors: PropTypes.object,
  isSunburstViewMulti: PropTypes.bool,
  toggleSunburstView: PropTypes.func,
  ticketSrn: PropTypes.string.isRequired,
  objectives: ImmutablePropTypes.iterable,
  objectivesLoading: PropTypes.bool,
  fetchObjectives: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  alerts: selectSelectedResourceAlerts,
  alertListLoading: selectAlertListLoading,
  categoryRollupData: selectCategoryRollupData,
  categoryRollupLoading: selectCategoryRollupLoading,
  controlGroups: selectControlGroups,
  policies: selectPolicies,
  loadedControlPolicies: selectLoadedPolicies,
  loadedControlGroups: selectLoadedControlGroups,
  resources: selectResources,
  swimlanes: selectSwimlanes,
  swimlanesBySrn: selectSwimlanesBySrn,
  isSunburstViewMulti: selectIsSunburstViewMulti,
  ticketSrn: selectQueryStringTicketSrn,
  objectives: selectObjectives,
  objectivesLoading: selectObjectivesLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCategoryRollupData,
      fetchFilteredAlerts,
      toggleSunburstView,
      fetchObjectives,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'rapSheet', reducer })
const withSaga = injectSaga({ key: 'rs', saga: rsSaga })

export default compose(withReducer, withSaga, withConnect, themeable)(RapSheet)
