/* eslint-disable no-console */
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import uuid from 'uuid/v4'

import {
  retryOnShutdownLink,
  shouldRetryOnShutdown,
  makeRetryOnFailedLink,
  RETRY_ON_SHUTDOWN,
  RETRY_ON_NETWORK_FAILURE,
} from 'utils/apollo/apollo-retry-links'
import { getStore } from 'configureStore'
import { exists } from 'utils/sonraiUtils'
import { getGraphEndpoint } from 'utils/graphDataUtils'
import { setGlobalError } from 'containers/SonraiData/actions'
import {
  getCurrentToken,
  getAuth0Client,
  getAuthDB,
  logout,
} from 'react-auth0-wrapper'
import { LogoutEventType } from 'auth/auth-db'
import { getCurrentOrg } from 'auth/current-org'

const cache = new InMemoryCache()

// This disables the http cache and sets the cache-control header sent to the backend.
// Enabling it disables all of our backend caching and can have adverse effects on performance
// THIS SHOULD *NOT* BE SET TO TRUE IN PROD
const DISABLE_CACHE = false

const createClient = () => {
  const httpLink = createHttpLink({
    uri: `${getGraphEndpoint()}graphql`,
    fetchOptions: { mode: 'cors' },
  })

  const authLink = setContext(async (_, { headers }) => {
    const token = await getCurrentToken()
    const authorization = token ? `Bearer ${token}` : ''

    const currentOrg = getCurrentOrg()
    if (!currentOrg) {
      console.warn('about to make graphql request w current org not set')
    }

    return {
      headers: {
        ...headers,
        authorization,
        'sonraisecurity-com-org': currentOrg,
      },
    }
  })

  const cacheLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'Cache-Control': 'no-cache',
      },
    }
  })

  const queryNameLink = setContext((operation, previousContext) => {
    let { headers, queryName } = previousContext
    if (!queryName) {
      if (!operation.operationName) {
        return previousContext
      }

      queryName = operation.operationName
    }

    return {
      ...previousContext,
      headers: {
        ...headers,
        'query-name': queryName,
      },
    }
  })

  const requestIDLink = setContext((operation, previousContext) => {
    let { headers, queryName } = previousContext
    if (!queryName) {
      if (operation.operationName) {
        queryName = operation.operationName
      }
    }

    return {
      ...previousContext,
      headers: {
        ...headers,
        'x-request-id': uuid(),
      },
    }
  })

  const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: performance.now() })
    return forward(operation)
  })

  const logTimeLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(data => {
      if (window.config.logSlowQueriesTime) {
        const context = operation.getContext()
        const time = performance.now() - context.start
        if (time >= window.config.logSlowQueriesTime)
          console.warn(
            `Slow Query Alert: query ${context.headers['query-name']} took ${time}ms to complete. Request ID ${context.headers['x-request-id']}`
          )
      }

      return data
    })
  })

  let willRetryOnShutdown = false
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const headers = operation.getContext().headers || {}
    if (graphQLErrors) {
      const store = getStore()
      graphQLErrors.map(({ message, locations, path }) => {
        store.dispatch(setGlobalError(message))
        console.error(
          `[GraphQL Error]: Message: ${message}, Location: ${locations}, Path: ${path}, QueryName: ${headers['query-name']}, RequestID: ${headers['x-request-id']}`
        )
      })
    }

    if (networkError) {
      if (!(willRetryOnShutdown && shouldRetryOnShutdown(networkError))) {
        const store = getStore()
        store.dispatch(
          setGlobalError(`[Network Error]: ${networkError.message}`)
        )
        console.error(
          `[Network Error]: ${networkError.message}, QueryName: ${headers['query-name']}, RequestID: ${headers['x-request-id']}`
        )
      }

      if (networkError.statusCode === 504) {
        const store = getStore()
        store.dispatch(
          setGlobalError(
            '[Network Error]: Unfortunately, your search has timed out. Please try again later.'
          )
        )
        console.error(
          '[Network Error]: Unfortunately, your search has timed out. Please try again later.'
        )
      }

      if (
        networkError.statusCode === 401 ||
        networkError.statusCode === 403 ||
        networkError.statusCode === 404
      ) {
        if (
          networkError.statusCode === 401 ||
          networkError.statusCode === 403
        ) {
          console.error(
            '[Network Error]: Received 401 or 403 from graphql, likely due to invalid token, logging you out'
          )
        }

        if (networkError.statusCode === 404) {
          console.error(
            '[Network Error]: Received a 404 while attempting a graphql call, likely due to invalid org or token, logging you out'
          )
        }

        const auth0Client = getAuth0Client()
        if (auth0Client) {
          getAuthDB().logoutEvent(LogoutEventType.UNAUTH_RESP)
          logout(auth0Client)
        } else {
          throw 'Cannot get auth0 client to logout'
        }
      }
    }
  })

  let link = DISABLE_CACHE ? cacheLink.concat(errorLink) : errorLink

  /**
   * optionally add retry handlers: by default we will retry if the
   * graphql-server shuts down while the request is in progress, but we will
   * not retry on additional network failures
   */
  if (window.config[RETRY_ON_SHUTDOWN] != false) {
    link = link.concat(retryOnShutdownLink)
    willRetryOnShutdown = true
  }
  if (window.config[RETRY_ON_NETWORK_FAILURE]) {
    const retryOnFailedLink = makeRetryOnFailedLink()
    link = link.concat(retryOnFailedLink)
  }

  return new ApolloClient({
    link: link
      .concat(authLink)
      .concat(queryNameLink)
      .concat(requestIDLink)
      .concat(timeStartLink)
      .concat(logTimeLink)
      .concat(httpLink),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        forceFetch: true,
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}

const getClient = () => {
  if (window.client !== undefined) {
    return window.client
  } else {
    window.client = createClient()
    return window.client
  }
}

const refreshEndpoint = () => {
  window.client = createClient()
}

export { getClient, refreshEndpoint }
