/*
 *
 * ControlGroup reducer
 *
 */

import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  CREATE_POLICY,
  CREATE_POLICY_SUCCESS,
  UPDATE_CONTROL_GROUP,
  UPDATE_CONTROL_GROUP_SUCCESS,
  ADD_POLICY,
  ADD_POLICY_SUCCESS,
  SET_POLICY_ERROR,
} from 'containers/ControlFrameworkData/constants'
import { SET_CLONING } from './constants'

const initialState = fromJS({
  saving: false,
  cloning: null,
})

const controlGroupReducer = handleActions(
  {
    [ADD_POLICY]: state => state.set('saving', true),
    [SET_POLICY_ERROR]: state =>
      state.set('saving', false).set('cloning', null),
    [ADD_POLICY_SUCCESS]: state => state.set('saving', false),
    [CREATE_POLICY]: state => state.set('saving', true),
    [CREATE_POLICY_SUCCESS]: state =>
      state.set('saving', false).set('cloning', null),
    [SET_CLONING]: (state, { payload }) => state.set('cloning', payload),
    [UPDATE_CONTROL_GROUP]: state => state.set('saving', true),
    [UPDATE_CONTROL_GROUP_SUCCESS]: state => state.set('saving', false),
  },
  initialState
)

export default controlGroupReducer
