import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectThemeManagerDomain = state => state.get('theme', Map())

export const selectTheme = createSelector(
  selectThemeManagerDomain,
  state => state.get('theme', Map())
)

export const selectTypeColors = createSelector(
  selectThemeManagerDomain,
  state => state.get('typeColors', Map())
)
