/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux'
import { fromJS } from 'immutable'
import { routerMiddleware } from 'connected-react-router/immutable'
import createReducer from './reducers'
import createSagaMiddleware from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()
let store

export default function configureStore(
  initialState = { authed: false },
  history
) {
  // Create the store with two middlewares
  // 1. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)]

  const enhancers = [applyMiddleware(...middlewares)]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
          stateSanitizer: state => {
            return state
              .set('userProfileData', 'SKIP_DEVTOOL_SERIALIZE')
              .setIn(['sonraiData', 'queryTypes'], 'SKIP_DEVTOOL_SERIALIZE')
              .setIn(['sonraiData', 'schema'], 'SKIP_DEVTOOL_SERIALIZE')
              .setIn(['sonraiData', 'queryNames'], 'SKIP_DEVTOOL_SERIALIZE')
              .setIn(['sonraiData', 'queryRelations'], 'SKIP_DEVTOOL_SERIALIZE')
              .setIn(['sonraiData', 'savedSearches'], 'SKIP_DEVTOOL_SERIALIZE')
              .setIn(['sonraiData', 'sonraiSearches'], 'SKIP_DEVTOOL_SERIALIZE')
              .setIn(['theme', 'typeColors'], 'SKIP_DEVTOOL_SERIALIZE')
          },
        })
      : compose
  /* eslint-enable */

  store = createStore(
    createReducer({}, history),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  )

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {} // Reducer registry
  store.history = history //Save the history for when we rebuild the reducer when injecting a new one
  store.injectedSagas = {}

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers, history))
    })
  }

  return store
}

export const getStore = () => store
