import {
  GET_AUDIT_CONTENT,
  SET_AUDIT_CONTENT,
  TOGGLE_SECONDARY_AUDIT,
  SET_HAS_SECONDARY_AUDIT,
} from './constants'
import { createAction } from 'redux-actions'

export const getAuditContent = createAction(GET_AUDIT_CONTENT)
export const setAuditContent = createAction(SET_AUDIT_CONTENT)
