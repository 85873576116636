import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import color from 'color'

import KeyVaultConfig from 'containers/Deploy/KeyVaultConfig'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import BorderlessButton from 'components/BorderlessButton'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'

import {
  addKeyVaultCreds,
  getKeyVaultData,
  errKeyVaultData,
  removeKeyVaultCreds,
  updateKeyVaultCreds,
} from './actions'

import { makeSelectKeyVault } from './selectors'

import messages from './messages'
import { bindActionCreators } from 'redux'

const AKS_HELP_ARTICLE_NAME = 'Enable AKS Discovery'

const HelpCenterTextContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 8px;
`

function getAKSHelpCenterHREF() {
  const protocol = window.origin.startsWith('http:') ? 'http://' : 'https://'
  return (
    protocol +
    window.location.host +
    '/App/HelpCenter?preloadSearch=' +
    AKS_HELP_ARTICLE_NAME
  )
}

function AKSHelpCenterLink() {
  return (
    <HelpCenterTextContainer>
      <FormattedMessage
        values={{
          docLink: (
            <TextLink color="primary">
              <a href={getAKSHelpCenterHREF()} target="_">
                {AKS_HELP_ARTICLE_NAME}
              </a>
            </TextLink>
          ),
        }}
        {...messages.aksDiscoveryInstructions}
      />
    </HelpCenterTextContainer>
  )
}

export class KeyVaultActions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      keyVaultModal: false,
      addingNew: false,
    }

    this.styles = {
      container: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
      },
      button: {
        height: '1.85rem',
        fontSize: '0.85rem',
        border: `1px solid ${this.props.theme.primary}`,
        padding: '0.25rem 0.5rem',
        margin: '4px 5px 0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: color(this.props.theme.neutralDark).darken(0.6),
      },
      toggle: {
        marginTop: '1rem',
      },
    }
  }

  componentDidMount() {
    this.props.getKeyVaultData({
      srn: this.props.nodeId,
      onNodeView: this.props.onNodeView,
    })
  }

  openModal = () => {
    this.setState({
      keyVaultModal: true,
    })
  }

  closeModal = () => {
    this.setState({
      keyVaultModal: false,
    })
  }

  handleAddKeyVaultCreds = payload => {
    this.props.addKeyVaultCreds({
      ...payload,
      onNodeView: this.props.onNodeView,
    })
  }

  handleRemoveKeyVaultCreds = payload => {
    this.props.removeKeyVaultCreds({
      ...payload,
      onNodeView: this.props.onNodeView,
    })
  }

  handleUpdateKeyVaultCreds = payload => {
    this.props.updateKeyVaultCreds({
      ...payload,
      onNodeView: this.props.onNodeView,
    })
  }

  render() {
    return (
      <div style={this.styles.container}>
        <BorderlessButton onClick={this.openModal} style={this.styles.button}>
          <div style={{ paddingRight: '0.5rem' }}>
            <Icon
              fa
              name="key"
              style={{
                fontSize: '0.95rem',
                color: this.props.theme.primary,
              }}
            />
          </div>
          <DynamicFormattedMessage {...messages.manageKeyVaultCredentials} />
        </BorderlessButton>
        <Modal isOpen={this.state.keyVaultModal} style={{ minWidth: '715px' }}>
          <ModalHeader style={{ width: '100%' }} toggle={this.closeModal}>
            <DynamicFormattedMessage {...messages.manageKeyVaultCredentials} />
          </ModalHeader>
          <ModalBody>
            {this.props.keyVault.get('loading') ? (
              <SquareLoadingAnimation />
            ) : (
              <Fragment>
                {'AzureKubernetesCluster' === this.props.nodeType && (
                  <AKSHelpCenterLink />
                )}
                <KeyVaultConfig
                  keyVaultCreds={this.props.keyVault.get('data')}
                  isDeleting={this.props.keyVault.get('deleting')}
                  isUpdating={this.props.keyVault.get('updating')}
                  isAdding={this.props.keyVault.get('adding')}
                  srn={this.props.nodeId}
                  addAzureKeyVaultCreds={this.handleAddKeyVaultCreds}
                  removeAzureKeyVaultCreds={this.handleRemoveKeyVaultCreds}
                  updateAzureKeyVaultCreds={this.handleUpdateKeyVaultCreds}
                  setAzureKeyVaultError={this.props.errKeyVaultData}
                  error={this.props.keyVault.get('error')}
                />
              </Fragment>
            )}
          </ModalBody>
          <ModalFooter>
            <TextLink color="primary" onClick={this.closeModal}>
              <DynamicFormattedMessage {...messages.close} />
            </TextLink>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

KeyVaultActions.propTypes = {
  theme: themeShape,

  // ~ passed props ~
  nodeId: PropTypes.string.isRequired,
  onNodeView: PropTypes.bool.isRequired,
  nodeType: PropTypes.string,

  // ~ props from redux store ~
  keyVault: PropTypes.object.isRequired,

  // ~ bound action creators ~
  addKeyVaultCreds: PropTypes.func.isRequired,
  errKeyVaultData: PropTypes.func.isRequired,
  getKeyVaultData: PropTypes.func.isRequired,
  removeKeyVaultCreds: PropTypes.func.isRequired,
  updateKeyVaultCreds: PropTypes.func.isRequired,
}

const makeMapStateToProps = () => {
  const selectKeyVault = makeSelectKeyVault()
  return createStructuredSelector({
    keyVault: selectKeyVault,
  })
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addKeyVaultCreds,
      getKeyVaultData,
      errKeyVaultData,
      removeKeyVaultCreds,
      updateKeyVaultCreds,
    },
    dispatch
  )
}

const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  themeable
)(KeyVaultActions)
