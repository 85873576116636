import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import CodeMirror from 'codemirror'
import 'codemirror/addon/runmode/runmode'
import 'codemirror/mode/javascript/javascript'

const styles = {
  displayStyles: {
    filterMatch: {
      fontWeight: 'bold',
      backgroundColor: '#ffc1075e',
    },
    property: {
      color: 'black',
    },
    string: {
      color: '#007ad9',
    },
  },
}

export const JsonView = props => {
  const [stringifiedText, setStringifiedText] = useState('')
  useEffect(() => {
    if (stringifiedText) {
      props.onStringify(stringifiedText)
    }
  }, [stringifiedText])

  let obj
  if (_.isObject(props.value)) {
    obj = props.value
  } else {
    try {
      obj = JSON.parse(props.value)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('could not parse props.value as JSON: ' + props.value, e)
    }
  }

  const jsonText = JSON.stringify(obj, null, 2)
  if (stringifiedText != jsonText) {
    setStringifiedText(jsonText)
  }

  const tokens = []
  CodeMirror.runMode(
    jsonText,
    {
      name: 'text/javascript',
      json: 'true',
    },
    (token, style) => {
      const tokenStyles = (style || '')
        .split(' ')
        .map(className => {
          return styles.displayStyles[className.trim()]
        })
        .filter(style => style)
        .reduce((allStyles, style) => {
          return {
            ...allStyles,
            ...style,
          }
        }, {})

      // if the text matches the search text, add an additional class
      if (props.highlightText) {
        let highlightText = props.highlightText.toLowerCase()
        if (
          token &&
          highlightText !== '' &&
          token.toLowerCase().includes(highlightText)
        ) {
          style += ' filter-match'
          Object.assign(tokenStyles, styles.displayStyles.filterMatch)
        }
      }
      tokens.push(
        <span className={style} style={tokenStyles} key={tokens.length}>
          {token}
        </span>
      )
    }
  )

  return (
    <div>
      <pre>{tokens}</pre>
    </div>
  )
}

JsonView.defaultProps = {
  onStringify: () => {},
}

JsonView.propTypes = {
  highlightText: PropTypes.string,
  onStringify: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

export default JsonView
