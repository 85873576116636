import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import { updateControlGroup } from 'containers/ControlFrameworkData/actions'
import MarkdownEditor from 'components/MarkdownEditor'

import messages from './messages'
import { selectSaving } from './selectors'

export class EditControlGroupModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.controlGroup.get('title'),
      description: props.controlGroup.get('description'),
      friendlyName: props.controlGroup.get('shortDescription'),
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.saving && !this.props.saving) {
      this.props.toggle()
    }
  }

  setDescription = value => {
    this.setState({
      description: value,
    })
  }

  setTitle = e => {
    this.setState({
      title: e.target.value,
    })
  }

  setFriendlyName = e => {
    this.setState({
      friendlyName: e.target.value,
    })
  }

  handleSave = () => {
    this.props.updateControlGroup({
      srn: this.props.controlGroup.get('srn'),
      title: this.state.title,
      description: this.state.description,
      friendlyName: this.state.friendlyName,
    })
  }

  render() {
    return (
      <Modal isOpen={true} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          <FormattedMessage {...messages.editCGHeader} />
        </ModalHeader>
        <ModalBody>
          <FormLabel
            for="cgTitle"
            required
            label={<FormattedMessage {...messages.cGTitleLabel} />}
          />
          <Input
            type="text"
            name="cgTitle"
            id="cgTitle"
            value={this.state.title}
            onChange={this.setTitle}
          />

          <FormLabel
            for="cgFriendlyName"
            label={<FormattedMessage {...messages.cGFriendlyNameLabel} />}
          />
          <Input
            type="text"
            name="cgFriendlyName"
            id="cgFriendlyName"
            value={this.state.friendlyName}
            onChange={this.setFriendlyName}
          />

          <FormLabel
            for="cgDescription"
            label={<FormattedMessage {...messages.cGDescriptionLabel} />}
          />
          <MarkdownEditor
            name="cgDescription"
            id="cgDescription"
            value={this.state.description}
            onChange={this.setDescription}
          />
        </ModalBody>
        <ModalFooter>
          <TextLink color="secondary" onClick={this.props.toggle}>
            <FormattedMessage {...messages.cancelButton} />
          </TextLink>
          <Button
            color="primary"
            onClick={this.handleSave}
            disabled={!this.state.title || this.props.saving}
          >
            {this.props.saving ? (
              <Icon fa spin name="sync" />
            ) : (
              <FormattedMessage {...messages.saveEditButton} />
            )}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

EditControlGroupModal.propTypes = {
  controlGroup: ImmutablePropTypes.map.isRequired,
  saving: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  updateControlGroup: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  saving: selectSaving,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateControlGroup,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(withConnect)(EditControlGroupModal)
