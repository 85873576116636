import React, { Component } from 'react'
import PropTypes from 'prop-types'

class NodeViewTab extends Component {
  styles = {
    tab: {
      display: 'inline-block',
      marginBottom: '-1px',
      border: '1px solid #ccc',
      padding: '0.5rem 1.5rem',
      marginRight: '0.5rem',
      borderTopRightRadius: '2.5px',
      borderTopLeftRadius: '2.5px',
      cursor: 'pointer',
      position: 'relative',
    },
    activeTab: {
      backgroundColor: '#fff',
      display: 'inline-block',
      marginBottom: '-1px',
      borderTop: '1px solid #ccc',
      borderRight: '1px solid #ccc',
      borderLeft: '1px solid #ccc',
      borderBottom: '1px solid #fff',
      padding: '0.5rem 1.5rem',
      marginRight: '0.5rem',
      borderTopRightRadius: '2.5px',
      borderTopLeftRadius: '2.5px',
      position: 'relative',
    },
  }

  onClick = () => {
    const { label, onClick } = this.props
    onClick(label)
  }

  isActive = (activeTab, label) => {
    if (activeTab == label) {
      return this.styles.activeTab
    } else {
      return this.styles.tab
    }
  }

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this

    return (
      <div style={this.isActive(activeTab, label)} onClick={onClick}>
        {label}
        {this.props.children}
      </div>
    )
  }
}

NodeViewTab.propTypes = {
  children: PropTypes.node,
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default NodeViewTab
