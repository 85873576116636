import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import { SET_AUDIT_CONTENT, GET_AUDIT_CONTENT } from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  auditContent: {},
  isLoading: false,
})

const auditNodeViewCardLayoutReducer = handleActions(
  {
    [GET_AUDIT_CONTENT]: state => state.set('isLoading', true),
    [SET_AUDIT_CONTENT]: (state, { payload }) => {
      return state.set('auditContent', fromJS(payload)).set('isLoading', false)
    },
    [CLEAR_DATA]: state => state.set('auditContent', Map()),
  },
  initialState
)

export default auditNodeViewCardLayoutReducer
