/*
 *
 *  All app constants that are global should be set and consumed from here
 *  These should be used to prevent the use of static strings as states
 *
 */

export const LOADING = 'Loading...'
export const ERROR = 'ERROR'
export const EMPTY = 'EMPTY'

export const DISCOVERY_TYPES = {
  USER: 'User',
  NETWORK: 'Network',
  ACTION: 'Action',
  REGION: 'Region',
  ACCOUNT: 'Account',
  ACTION_TYPE: 'ActionType',
  ROLE: 'Role',
  POLICY_ENTRY: 'PolicyEntry',
  PERMISSION: 'Permission',
  PERMISSION_LIST: 'PermissionList',
  DATA_OBJECT: 'DataObject',
  POLICY: 'Policy',
  POLICY_VERSION: 'PolicyVersion',
  DATA_CONTAINER: 'DataContainer',
}

export const PLATFORM_ACCOUNT_TYPES = {
  AWS: 'aws',
  AZURE: 'azure',
  GCP: 'gcp',
}

export const CLOUD_TYPES = {
  AWS: 'aws',
  AZURE: 'azure',
  SONRAI: 'sonrai',
  GCP: 'gcp',
  HASHICORP: 'hashicorp',
  KUBERNETES: 'kubernetes',
  GSUITE: 'gsuite',
}

export const FILTER_DATE_FORMAT = 'YYYY-MM-DD'
export const WIDGET_TYPES = {
  RATIO: 'ratio',
  BIG_COUNT: 'bigCount',
  TABLE: 'table',
  GAUGE: 'gauge',
  MAP: 'map',
  LIST: 'list',
  REGIONS: 'regions',
  PIE_CHART: 'pieChart',
  BAR_CHART: 'barChart',
  ADVMAP: 'advMap',
  ALERT: 'alert',
  COMPLIANCE: 'compliance',
  SPARK: 'spark',
  TEXT: 'text',
}

export const BOUNDARY_OPTIONS = {
  GREATER: 'Greater than...',
  LESS: 'Less than...',
  EQUAL: 'Equal to...',
}

export const NODE_TYPES = {
  IDENTITY: 'Identity',
  USERS: 'Users',
  DATA: 'Data',
  ACCOUNT: 'Accounts',
  ACTION: 'Actions',
  INFRASTRUCTURE: 'Infrastructure',
  PERMISSION: 'Permissions',
  PROTECTION: 'Protection',
}

export const EXPLORER_VIEW_TYPES = {
  EXPLORER: 'Explorer',
  TABLE: 'Table',
}

export const ARC_MAP_SEARCH_NAME = 'arcmap'
export const REGION_MAP_SEARCH_NAME = 'regionmap'

export const NODE_VIEW_TYPES = {
  PUBLIC_KEY: 'publickey',
  ACCOUNT: 'account',
  DATA: 'data',
  DATA_OBJECT: 'dataobject',
  DATA_STORE: 'datastore',
  USER: 'user',
  CONTAINER: 'datacontainer',
  POLICY: 'policy',
  COMPUTE: 'compute',
  IMAGE: 'image',
  GENERIC: 'generic',
  ACTION_TYPE: 'actiontype',
  ACTION: 'action',
  IDENTITY: 'identity',
  GROUP: 'group',
  AUDIT: 'audit',
  ROLE: 'role',
  RESOURCE_GROUP: 'resourcegroup',
  SECRET: 'secret',
  SECRET_STORE: 'secretstore',
  ENCRYPTION_KEY: 'encryptionkey',
  WORKLOAD: 'workload',
}

export const SUPPORT_EMAIL = 'support@sonraisecurity.com'

export const START_DATE = 'startDate'

export const ALERT_LEVELS = {
  ERROR: 'Critical',
  WARN: 'Warn',
  INFO: 'Info',
}

export const CONTROL_FRAMEWORKS = {
  ALL_CONTROL_FRAMEWORKS: 'All Control Frameworks',
}

export const POLL_FREQUENCY = 300000 //5 MINUTES

export const SONRAI_ORG_NAME = 'supersonrai'

export const SONRAI_MAX_COUNT = 500
export const SONRAI_DEFAULT_LIMIT = 100

export const DEFAULT_SEARCH_LIMIT = 500

export const QUERY_FILTER_OP = {
  IN_LIST: 'IN_LIST',
  NOT_IN_LIST: 'NOT_IN_LIST',
  BETWEEN: 'BETWEEN',
  NOT_BETWEEN: 'NOT_BETWEEN',
  IS_NULL: 'IS_NULL',
  IS_NOT_NULL: 'IS_NOT_NULL',
  REGEX: 'REGEX',
  NOT_REGEX: 'NOT_REGEX',
  EQUALS: 'EQ',
  NOT_EQUALS: 'NEQ',
}

export const GRAPHQL_TIMEOUT = 120000

export const TYPES_WITH_CRM = [
  'user',
  'datastore',
  'datacontainer',
  'role',
  'dataobject',
  'secretstore',
  'secret',
  'identityreference',
  'compute',
  'identity',
  'encryptionkey',
]

export const DATA_TYPES_FOR_CRM = [
  'datastore',
  'datacontainer',
  'dataobject',
  'secret',
  'secretstore',
  'encryptionkey',
]
export const IDENTITY_TYPES_FOR_CRM = [
  'user',
  'role',
  'identityreference',
  'identity',
]

export const DEFAULT_ACTION_CLASSIFICATIONS_TO_ENABLE = [
  'DataCreate',
  'DataDelete',
  'DataUpdate',
  'DataRead',
  'ProtectionCreate',
  'ProtectionDelete',
  'ProtectionUpdate',
  'ProtectionRead',
  'Configure',
  'Delete',
  'Update',
]

export const SEARCH_SAVE_STATE = {
  ALLOW: 0,
  SAVING_CONCURRENT: 1,
  SAVING: 2,
}

export const HAS_COPY = [
  'cidrBlock',
  'id',
  'privateDnsName',
  'sid',
  'srn',
  'vpcID',
  'account',
  'email',
  'friendlyName',
  'name',
  'accessKey',
  'eventName',
  'grantedAccessKey',
  'grantedSessionPrincipal',
  'performedByValue',
  'performedOnValue',
  'sessionPrincipal',
  'srcID',
  'srcIP',
  'srcIPs',
  'userAgent',
  'fingerprint',
  'userName',
  'privateIPSet',
  'publicIPSet',
  'srnList',
  'userAgentSet',
  'ipSet',
]

export const SEVERITY_CATEGORIES = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
}

export const GLOBAL_SWIMLANE_NAME = 'Global'

export const NODE_TYPES_WITH_NAME_EDITING_DISABLED = ['action', 'actiontype']

export const SWIMLANE_SCOPED_PERMISSIONS = [
  'view.data',
  'edit.alerts',
  'edit.resource',
  'edit.resources',
  'edit.crmsettings',
  'edit.ticket',
  'edit.dataclassificationconfig',
  'assign.bots',
  'edit.roleassignments',
  'assign.integrations',
  'edit.tickets',
  'transition.tickets',
  'snooze.tickets',
  'acceptrisk.tickets',
  'assign.escalations',
  'execute.bots',
]

export const ACTION_TYPES = {
  ESCALATE_TO_BOT: 'Escalate to Bot',
  ASSIGN_USER: 'Assign to User',
  ASSIGN_ROLE: 'Assign to Role',
}

export const REMEDIATION_TYPES = {
  UPDATE_POLICY: 'UPDATE_POLICY',
  DELETE_IDENTITY: 'DELETE_IDENTITY',
  DELETE_ACCESS_KEY: 'DELETE_ACCESS_KEY',
}

export const TICKET_STATUS = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS', //Note: Not actually supported CRC-2641 needs backend work
  CLOSED: 'CLOSED',
  SNOOZED: 'SNOOZED',
  RISK_ACCEPTED: 'RISK_ACCEPTED',
}
