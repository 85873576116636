import gql from 'graphql-tag'

export const ACCEPT_RISK_MUTATION = gql`
  mutation acceptRiskTicket($srn: String) {
    AcceptRiskTickets(input: { srns: [$srn] }) {
      successCount
      failureCount
    }
  }
`

export const CLOSE_TICKET_MUTATION = gql`
  mutation closeTicket($srn: String) {
    CloseTickets(input: { srns: [$srn] }) {
      successCount
      failureCount
    }
  }
`

export const REOPEN_TICKET_MUTATION = gql`
  mutation reopenTicket($srn: String) {
    ReopenTickets(input: { srns: [$srn] }) {
      successCount
      failureCount
    }
  }
`

export const SNOOZE_TICKET_MUTATION = gql`
  mutation snoozeTicket($srn: String, $snoozedUntil: DateTime) {
    SnoozeTickets(snoozedUntil: $snoozedUntil, input: { srns: [$srn] }) {
      successCount
      failureCount
    }
  }
`

export const FETCH_TICKET_DETAILS_QUERY = gql`
  query fetchTicketDetails($srn: String!) {
    Tickets(where: { srn: { value: $srn } }) {
      items(limit: 1) {
        assignedTo
        account
        actionClassification
        createdBy
        createdDate
        description
        firstSeen
        flag
        isSnoozed
        lastSeen
        orgName
        resourceId
        resourceLabel
        resourceSRN
        resourceType
        severityCategory
        severityNumberic
        snoozedUntil
        srn
        status
        swimlaneSRNs
        ticketKey
        ticketType
        title
        transitionDate
        transitionedBy
        templateSRN
        customFields {
          name
          type
          value
          isMulti
        }
        evidence {
          policyEvidence
          count
          conditions
          fieldType
          boolValue
          intValue
          longValue
          value
          prevBoolValue
          prevIntValue
          prevLongValue
          prevValue
          resourceSet
          path
          policyEvidence
          regionSet
          userAgentSet
        }
        resourceSRN
        severityNumeric
        severityCategory
        actionClassification
        resourceLabel
        resourceType
        swimlaneSRNs
        resource {
          __typename
          account
          active
          cloudType
          country
          createdDate
          friendlyName
          importance
          label
          name
          region
          resourceId
          srn
          swimlaneSRNs
          tagSet
        }
      }
    }
  }
`

export const FETCH_TICKET_COMMENTS_QUERY = gql`
  query fetchComments($ticketSrn: String!) {
    TicketComments(where: { ticketSrn: { value: $ticketSrn } }) {
      items(limit: 1000) {
        srn
        resourceId
        body
        createdDate
        createdBy
      }
    }
  }
`

export const ADD_TICKET_COMMENT_MUTATION = gql`
  mutation addTicketComment(
    $ticketSrn: String!
    $body: String!
    $createdBy: String!
  ) {
    CreateTicketComment(
      input: { body: $body, ticketSrn: $ticketSrn, createdBy: $createdBy }
    ) {
      resourceId
      srn
      createdBy
      createdDate
      body
    }
  }
`

export const FETCH_SIMILAR_TICKETS_QUERY = gql`
  query fetchSimilarTicket($ticketFilter: TicketsFilter) {
    Tickets(where: $ticketFilter) {
      items(limit: 200) {
        srn
        resourceId
        createdDate
        createdBy
        orgName
        assignedTo
        transitionedBy
        transitionDate
        isSnoozed
        snoozedUntil
        ticketType
        ticketKey
        description
        title
        status
        account
        firstSeen
        lastSeen
        flag
        evidence {
          policyEvidence
          count
          fieldType
          boolValue
          intValue
          longValue
          value
          prevBoolValue
          prevIntValue
          prevLongValue
          prevValue
          resourceSet
          conditions
          path
          regionSet
          userAgentSet
        }
        resourceSRN
        resource {
          name
          srn
          friendlyName
        }
        severityNumeric
        severityCategory
        actionClassification
        resourceLabel
        resourceType
        swimlaneSRNs
      }
    }
  }
`

export const REASSIGN_TICKET_MUTATION = gql`
  mutation reassignToUser($ticketSrn: String!, $userSrn: String!) {
    AssignTicket(input: { ticketSrn: $ticketSrn, userSrn: $userSrn }) {
      srn
      resourceId
      createdDate
      createdBy
      orgName
      assignedTo
      transitionedBy
      transitionDate
      isSnoozed
      snoozedUntil
      ticketType
      ticketKey
      description
      title
      status
      account
      firstSeen
      lastSeen
      flag
      evidence {
        policyEvidence
        count
        fieldType
        boolValue
        intValue
        longValue
        value
        prevBoolValue
        prevIntValue
        prevLongValue
        prevValue
        resourceSet
        conditions
        path
        regionSet
        userAgentSet
      }
      resourceSRN
      resource {
        name
        srn
        friendlyName
      }
      severityNumeric
      severityCategory
      actionClassification
      resourceLabel
      resourceType
      swimlaneSRNs
    }
  }
`

export const FETCH_TICKET_HISTORY_QUERY = gql`
  query getTicketHistory($ticketSrn: String!) {
    TicketHistoryLogs(where: { ticketSrn: { value: $ticketSrn } }) {
      items(limit: 1000) {
        srn
        resourceId
        createdDate
        actorSrn
        actionType
        message
        artifactSrn
      }
    }
  }
`

export const ACTIONS_QUERY = gql`
  query ticketActionsQuery($actionFilter: ActionFilter, $limit: Int = 1000) {
    Actions(where: $actionFilter) {
      count
      items(limit: $limit) {
        srn
        eventName
        service
        createdDate
        timestamp
      }
    }
  }
`

export const TICKET_BOT_ACTIONS_QUERY = gql`
  query ticketBotActions($metadataFilter: String) {
    Actions(where: { metadata: { op: EQ, value: $metadataFilter } }) {
      count
      items {
        succeeded
        eventName
        createdDate
        srn
        metadata
        resourceId
      }
    }
  }
`
