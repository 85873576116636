import React, { Component, Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import color from 'color'
import { fromJS, List } from 'immutable'
import _ from 'lodash'
import qs from 'query-string'

import HeaderLabelLink from 'containers/NodeSolutionCenter/HeaderLabelLink'
import permissionChecker from 'containers/PermissionChecker'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import WithoutPermission from 'containers/PermissionChecker/WithoutPermission'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

import { Dropdown, DropdownToggle } from 'reactstrap'

import Pill from 'components/Badge/Pill'
import BorderlessButton from 'components/BorderlessButton'
import Button from 'components/Button'
import CloudBadge from 'components/CloudBadge'
import CopyToClipboard from 'components/CopyToClipboard'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import InlineEditableText from 'components/InlineEditableText'
import ImportanceWidget from 'components/ImportanceWidget'
import Hoverable from 'components/Hoverable'
import NodeViewDetailAccount from 'components/NodeView/NodeViewDetailAccount'
import { TitleShimmer } from 'components/ShimmerLoader'

import { BlockShimmer } from 'components/ShimmerLoader'
import SmolExpand from 'components/SmolExpand'
import Subscription from 'components/Subscription'
import DropdownMenu from 'components/StyledReactstrapDropdownMenu'
import Tag from 'components/Tag'
import TextLink from 'components/TextLink'

import AddSwimlaneForm from './AddSwimlaneForm'
import AddTagForm from './AddTagForm'
import ChangeDetectionActions from './ChangeDetectionActions'
import KeyVaultActions from './KeyVaultActions'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer, { REDUX_KEY } from './reducers'
import sagas from './sagas'

import {
  getNodeData,
  getResourceGroup,
  removeTag,
  updateFriendlyName,
  updateImportance,
} from './actions'

import { selectQueryTypes } from 'containers/SonraiData/selectors'
import {
  makeSelectCrmData,
  makeSelectNodeDataIsLoading,
  makeSelectNodeData,
  makeSelectNodeDataError,
  makeSelectResourceGroup,
  makeSelectSwimlanes,
  makeSelectTags,
  makeSelectUpdateFriendlyName,
} from './selectors'

import {
  selectSwimlanes,
  selectSwimlanesLoading,
} from 'containers/SonraiData/selectors'

import messages from './messages'

import { TYPES_WITH_CRM } from 'appConstants'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import {
  buildAzureResourceLink,
  buildAwsResourceLink,
  getCloudFromSrn,
  getIconMapping,
  getSubscriptionFromSrn,
} from 'utils/sonraiUtils'
import CreateUpdateTicketModal from 'containers/CreateUpdateTicketModal'

export class NodeViewHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      actionDropdownExpanded: false,
      tagOverflow: true,
      swimmyOverflow: true,
      tagExpanded: false,
      expandSwimmy: true,
      createTicketModal: false,
    }

    this.styles = {
      accountBar: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      actionDropdownButton: {
        width: '100%',
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '4px',
      },
      property: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: '0.3em',
        alignItems: 'center',
        padding: '0 0.5em',
      },
      fieldLabel: {
        fontSize: '0.8em',
        fontWeight: '400',
        color: '#485460',
      },
      actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      actionsContainer: {
        overflow: 'hidden',
        display: 'flex',
      },
      button: {
        height: '1.85rem',
        fontSize: '0.85rem',
        border: `1px solid ${this.props.theme.primary}`,
        padding: '0 0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: '5px',
        color: color(this.props.theme.neutralDark).darken(0.6),
      },
      container: {
        margin: '0px 12px 12px 12px',
        boxShadow: `rgba(0, 0, 0, 0.1) 1px 4px 3px -1px`,
        borderRadius: '0.25em',
        border: '1px solid #e7e7e9',
        ...props.styles,
      },
      headerItem: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
      },
      labelType: {
        marginTop: '2px',
        marginLeft: '12px',
        color: '#aaaaaa',
        fontVariant: 'small-caps',
        fontSize: '0.9em',
        fontWeight: '400',
      },
      linkGuy: {
        fontSize: '0.9em',
        gridArea: 'link',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: '1.5em',
        marginRight: '1.1em',
      },
      name: {
        fontSize: '1.3em',
        width: '100%',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: '550px',
      },
      noFriendlyName: {
        color: 'black',
        display: 'grid',
        height: '40px',
        gridTemplateColumns: '80% 20%',
        fontSize: '18px',
        padding: '5px',
      },
      noFriendlyNameCopy: {
        position: 'relative',
        top: '-16px',
        height: '35px',
        textAlign: 'right',
      },
      noFriendlyNameHover: {
        backgroundColor: props.theme.neutralLight,
      },
      swimlanes: {
        fontSize: '0.9em',
        fontWeight: '300',
        color: this.props.theme.dark,
        marginRight: '0.5em',
      },
      top: {
        backgroundColor: this.props.theme.background,
        display: 'grid',
        gridTemplateColumns: 'auto',
        borderTopRightRadius: '0.25rem',
        borderTopLeftRadius: '0.25rem',
        padding: '0.25rem 0.5rem 0 0.5rem',
      },
      topNameStuff: {
        display: 'grid',
        gridTemplateColumns: '30px minmax(200px, 550px) 1fr',
        gridColumnGap: '5px',
        alignItems: 'flex-start',
      },
      mySides: {
        display: 'grid',
        gridTemplateColumns: 'auto 300px',
      },
      title: {
        minWidth: '240px',
      },
    }
  }

  componentDidMount() {
    this.props.getNodeData({ srn: this.props.nodeId })
    this.props.getResourceGroup({ srn: this.props.nodeId })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nodeId !== this.props.nodeId) {
      this.props.getNodeData({ srn: this.props.nodeId })
      this.props.getResourceGroup({ srn: this.props.nodeId })
    }
  }

  setTagOverflow = overflow => {
    this.setState({ tagOverflow: overflow })
  }

  setSwimmyOverflow = overflow => {
    this.setState({ swimmyOverflow: overflow })
  }

  toggleSwimmyExpand = () => {
    this.setState(oldState => ({
      expandSwimmy: !oldState.expandSwimmy,
    }))
  }

  toggleActionMenu = () => {
    this.setState(oldState => ({
      actionDropdownExpanded: !oldState.actionDropdownExpanded,
    }))
  }

  getActions = () => {
    const actions = [this.props.actions]

    const canEditResource = this.props.userHasPermission({
      permissionName: 'edit.resources',
      swimlanes: this.props.nodeData.swimlaneSRNs,
    })
    const canEditCrm = this.props.userHasPermission({
      permissionName: 'edit.crmsettings',
      swimlanes: this.props.nodeData.swimlaneSRNs,
    })

    actions.push(
      <div style={{ marginTop: '4px', marginLeft: '4px', marginBottom: '4px' }}>
        <Button
          title="Open Relation Explorer"
          onClick={this.navigateToExplorer}
          style={this.styles.button}
          key={`nodeViewHeader-actions-${this.props.nodeData.srn}-openRelationExplorer`}
        >
          <div style={{ paddingRight: '0.5rem' }}>
            <Icon fa name="chart-network" />
          </div>
          Relations
        </Button>
      </div>
    )

    const hasCRM = TYPES_WITH_CRM.includes(
      getTypeFromSrn(this.props.nodeData.srn).toLowerCase()
    )

    if (hasCRM && canEditCrm) {
      actions.push(
        <ChangeDetectionActions
          key={`nodeViewHeader-actions-${this.props.nodeData.srn}-CDMODAL`}
          nodeId={this.props.nodeData.srn}
          nodeData={this.props.nodeData}
          onNodeView={this.props.onNodeView}
          direction={`horizontal`}
        />
      )
    }

    if (
      (canEditResource &&
        this.props.nodeData.type === 'AzureDatabricksAccount') ||
      this.props.nodeData.type === 'AzureKubernetesCluster'
    ) {
      actions.push(
        <KeyVaultActions
          nodeId={this.props.nodeData.srn}
          nodeType={this.props.nodeData.type}
          onNodeView={this.props.onNodeView}
        />
      )
    }

    if (
      this.props.userHasPermission({
        permissionName: 'edit.tickets',
        swimlanes: this.props.nodeData.swimlaneSRNs,
      })
    ) {
      actions.push(
        <Button
          title="Open a ticket"
          onClick={() => this.setState({ createTicketModal: true })}
          style={{ ...this.styles.button, ...{ margin: '4px 0px 0px 0px' } }}
          key={`nodeViewHeader-actions-${this.props.nodeData.srn}-createTicketModal`}
        >
          <div style={{ paddingRight: '0.5rem' }}>
            <Icon fa name="list-alt" />
          </div>
          Open Ticket
        </Button>
      )
    }

    return actions
  }

  getAccountTitle = () => {
    if (this.props.nodeData.cloudType === 'azure') {
      return <DynamicFormattedMessage {...messages.tenant} />
    } else {
      return <DynamicFormattedMessage {...messages.account} />
    }
  }

  getContainerBackgroundColor = () => {
    return this.props.nodeData && this.props.nodeData.active !== false
      ? this.props.theme.light
      : this.props.theme.neutralLight || this.props.theme.light
  }

  getDisplayName = () => {
    const baseName = this.props.nodeData.name || this.props.nodeData.srn
    const friendlyName = this.props.nodeData.friendlyName
    return friendlyName || baseName
  }

  getHeaderIcon = () => {
    const type = this.props.nodeData.__typename
    if (typeof type !== 'string') {
      return 'data'
    }
    return getIconMapping(type).faIcon
  }

  getImportance = swimlanes => {
    // if importance is set on the resource, return that
    if (this.props.crmData.importance) {
      return this.props.crmData.importance
    }

    // otherwise default importance is the max of all the swimlanes
    if (!swimlanes) {
      // eslint-disable-next-line no-console
      console.warn("could not get 'data' from swimlanes properties")
    }

    const importance = swimlanes.reduce((maxImportance, swimlane) => {
      const { defaultImportance, srn } = swimlane
      if (!_.isNumber(defaultImportance)) {
        // eslint-disable-next-line no-console
        console.warn(`swimlane ${srn} does not have 'defaultImportance'`)
      }
      return Math.max(maxImportance, defaultImportance)
    }, -1)

    return importance > 0 ? importance : 5
  }

  getLinkToResource = () => {
    const noneOfTheseGuys = [
      'PolicyVersion',
      'PolicyEntry',
      'PermissionList',
      'Action',
    ]
    if (
      !this.props.nodeData.active &&
      !noneOfTheseGuys.includes(this.props.nodeData.label)
    ) {
      return null
    }
    let url = null
    const type = getCloudFromSrn(this.props.nodeData.srn)
    if (type === 'azure') {
      url = buildAzureResourceLink(
        this.props.nodeData.srn,
        this.props.nodeData.resourceId,
        this.props.nodeData.type,
        this.props.nodeData.label,
        this.props.nodeData.metadata
      )
    } else if (type === 'aws') {
      url = buildAwsResourceLink(this.props.nodeData)
    } else {
      return null
    }

    return url ? (
      <div style={this.styles.linkGuy}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <CloudBadge
            type={type}
            style={{ marginRight: '0.3em', fontSize: '1.2em' }}
          />

          <TextLink>
            Link to {type === 'azure' ? 'Azure Portal' : 'AWS Console'}
          </TextLink>
        </a>
      </div>
    ) : (
      <div style={this.styles.disabledCloud} />
    )
  }

  getSupportsFriendlyName = () => {
    const fields = this.props.queryTypes.getIn(
      [this.props.nodeData.__typename, 'fields'],
      []
    )
    const friendlyNameField = fields.find(
      field => 'friendlyName' === field.get('name')
    )
    return friendlyNameField !== undefined
  }

  getSupportsTags = () => {
    const fields = this.props.queryTypes.getIn(
      [this.props.nodeData.__typename, 'fields'],
      []
    )
    const hasTag = fields.find(field => 'hasTag' === field.get('name'))
    return hasTag !== undefined
  }

  handleUpdateFriendlyName = newValue => {
    const value = newValue || ''
    this.props.updateFriendlyName({ srn: this.props.nodeId, value })
  }

  navigateToExplorer = () => {
    const id = this.props.nodeId
    const type = getTypeFromSrn(this.props.nodeId)

    this.props.push({
      pathname: '/App/Explorer',
      search: qs.stringify({
        id: id,
        type: type,
      }),
    })
  }

  hasImportance = node =>
    this.props.queryTypes
      .getIn([node.__typename, 'implements'], List())
      .includes('Resource')

  renderAccountBar = swimlanes => {
    const subscriptionId = getSubscriptionFromSrn(this.props.nodeData.srn)

    let resourceGroup = this.props.resourceGroup.group
    let storageAccount = this.props.resourceGroup.storageAccount
    if (!this.props.resourceGroup.loading) {
      if (_.isEmpty(resourceGroup)) {
        resourceGroup = null
      }
      if (_.isEmpty(storageAccount)) {
        storageAccount = null
      }
    }
    const importance = this.getImportance(swimlanes)
    const swimlanesSrns = this.props.nodeData.swimlaneSRNs
    const hasImportance = this.hasImportance(this.props.nodeData)
    return (
      <div style={this.styles.mySides}>
        <div style={this.styles.accountBar}>
          <div style={{ ...this.styles.property, gridArea: 'account' }}>
            <div style={this.styles.fieldLabel}>{this.getAccountTitle()}:</div>
            <div>
              <NodeViewDetailAccount
                accountId={this.props.nodeData.account}
                nodeData={this.props.nodeData}
              />
            </div>
          </div>
          {!!subscriptionId && this.props.nodeData.label !== 'Subscription' && (
            <div style={{ ...this.styles.property, gridArea: 'subscription' }}>
              <div style={this.styles.fieldLabel}>Subscription:</div>
              <Subscription subscriptionId={subscriptionId} />
            </div>
          )}
          {this.props.nodeData.cloudType === 'azure' &&
            resourceGroup &&
            this.props.nodeData.label !== 'ResourceGroup' && (
              <div
                style={{ ...this.styles.property, gridArea: 'resourceGroup' }}
              >
                <div style={this.styles.fieldLabel}>Resource Group:</div>
                <HeaderLabelLink loading={false} data={resourceGroup} />
              </div>
            )}
          {this.props.nodeData.cloudType === 'azure' &&
            storageAccount &&
            this.props.nodeData.label !== 'DataStore' && (
              <div
                style={{ ...this.styles.property, gridArea: 'storageAccount' }}
              >
                <div style={this.styles.fieldLabel}>Storage Account:</div>
                <HeaderLabelLink loading={false} data={storageAccount} />
              </div>
            )}
          {this.props.nodeData.region && (
            <div style={{ ...this.styles.property, gridArea: 'region' }}>
              <div style={this.styles.fieldLabel}>Region:</div>
              <div style={{ fontSize: '0.9em' }}>
                {this.props.nodeData.region}
              </div>
            </div>
          )}
        </div>
        <div>
          {hasImportance && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gridArea: 'importance',
              }}
            >
              <div
                style={{
                  margin: '-0.5rem 1rem -1.5rem 0rem',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <WithPermission
                  permissionName="edit.resources"
                  swimlanes={swimlanesSrns}
                >
                  <div
                    style={{
                      color: '#888',
                      marginBottom: '0.25rem',
                      marginRight: '0.5rem',
                    }}
                  >
                    {' '}
                    Importance:{' '}
                  </div>
                  {!importance ? (
                    <TitleShimmer width={168} />
                  ) : (
                    <ImportanceWidget
                      theme={this.props.theme}
                      nodeId={this.props.nodeData.srn}
                      setImportance={this.props.updateImportance}
                      importance={importance}
                    />
                  )}
                </WithPermission>
              </div>
            </div>
          )}
          {this.getLinkToResource()}
        </div>
      </div>
    )
  }

  renderLoading = () => {
    return (
      <div style={{ height: '150px', padding: '12px' }}>
        <div style={{ height: '100%' }}>
          <BlockShimmer />
        </div>
      </div>
    )
  }

  renderName = () => {
    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.resources',
      swimlanes: this.props.nodeData.swimlaneSRNs,
    })
    const displayName = this.getDisplayName()
    if (!this.getSupportsFriendlyName() || !canEdit) {
      return (
        <div>
          <Hoverable
            hoverStyle={this.styles.noFriendlyNameHover}
            renderContent={({ hovered }) => (
              <div style={this.styles.noFriendlyName}>
                <div>{displayName}</div>
                <div style={this.styles.noFriendlyNameCopy}>
                  {hovered && (
                    <CopyToClipboard value={this.props.nodeData.name} />
                  )}
                  {hovered && (
                    <CopyToClipboard value={this.props.nodeData.srn} />
                  )}
                </div>
              </div>
            )}
          />
        </div>
      )
    }

    return (
      <InlineEditableText
        activeNode={this.props.nodeData.active}
        value={displayName}
        saving={this.props.updateFriendlyNameStatus.loading}
        onSubmit={this.handleUpdateFriendlyName}
        style={this.styles.name}
      />
    )
  }

  renderSwimlanes = swimlanes => {
    if (this.props.swimlanesLoading) {
      return (
        <div style={{ marginLeft: '0.25rem' }}>
          <TitleShimmer width={120} />
        </div>
      )
    }

    if (swimlanes.length === 0) {
      return null
    }

    return (
      <div style={this.styles.headerItem}>
        <SmolExpand
          setContentOverflow={this.setSwimmyOverflow}
          expanded={this.state.expandSwimmy}
          autoUpdate={true}
          height={'55px'}
        >
          <WithPermission permissionName="edit.swimlanes">
            <AddSwimlaneForm
              savingSL={this.props.swimlaneInfo.addingResourceToSL}
              currentSwimlanes={swimlanes}
              resourceId={this.props.nodeData.resourceId}
              srn={this.props.nodeData.srn}
            />
          </WithPermission>
          <WithoutPermission permissionName="edit.swimlanes">
            <div style={{ paddingLeft: '8px', display: 'inline-block' }}>
              <DynamicFormattedMessage {...messages.swimlanes} />:
            </div>
          </WithoutPermission>
          {swimlanes.map((swimlane, index) => {
            return (
              <Pill
                key={`swombo-${index}`}
                style={{
                  ...this.styles.swimlanes,
                  backgroundColor: this.props.nodeData.active
                    ? this.props.theme.neutralLight
                    : this.props.theme.light,
                }}
              >
                {swimlane.title}
              </Pill>
            )
          })}
        </SmolExpand>
        <div style={{ marginRight: '1em' }}>
          {this.state.swimmyOverflow && (
            <BorderlessButton onClick={this.toggleTagExpand}>
              {this.state.expandSwimmy ? (
                <Fragment>
                  <DynamicFormattedMessage {...messages.showLess} />
                  <Icon name="caret-up" fa />
                </Fragment>
              ) : (
                <Fragment>
                  <DynamicFormattedMessage {...messages.showAll} />
                  <Icon name="caret-down" fa />
                </Fragment>
              )}
            </BorderlessButton>
          )}
        </div>
      </div>
    )
  }

  renderTags = () => {
    if (!this.getSupportsTags()) {
      return null
    }

    if (this.props.tags.loading || !this.props.tags.data) {
      return (
        <div style={{ marginLeft: '0.5rem' }}>
          <TitleShimmer width={120} />
        </div>
      )
    }

    const swimlanesSrns = this.props.nodeData.swimlaneSRNs
    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.resources',
      swimlanes: swimlanesSrns,
    })

    return (
      <div style={this.styles.headerItem}>
        <SmolExpand
          setContentOverflow={this.setTagOverflow}
          expanded={this.state.tagExpanded}
          autoUpdate={true}
          height={'55px'}
          scale={true}
        >
          <WithPermission
            permissionName="edit.resources"
            swimlanes={swimlanesSrns}
          >
            <AddTagForm nodeId={this.props.nodeId} />
          </WithPermission>
          <WithoutPermission
            permissionName="edit.resources"
            swimlanes={swimlanesSrns}
          >
            <div style={{ paddingLeft: '8px', display: 'inline-block' }}>
              <DynamicFormattedMessage {...messages.tags} />:
            </div>
          </WithoutPermission>
          {this.props.tags.data
            .sort(tag =>
              decodeURIComponent(
                typeof tag.key === 'string' && tag.key.toLowerCase()
              )
            )
            .map((tag, index) => {
              let removeTag = canEdit
                ? () =>
                    this.props.removeTag({
                      srn: tag.srn,
                      nodeId: this.props.nodeId,
                    })
                : null

              return (
                <Tag
                  style={{ margin: '0.25rem' }}
                  key={`${tag.srn}-${index}`}
                  tag={fromJS(tag)}
                  tagHeight="20px"
                  removeTag={removeTag}
                />
              )
            })}
        </SmolExpand>
        <div style={{ marginRight: '1em' }}>
          {this.state.tagOverflow && (
            <BorderlessButton onClick={this.toggleTagExpand}>
              {this.state.tagExpanded ? (
                <Fragment>
                  Show Less <Icon name="caret-up" fa />
                </Fragment>
              ) : (
                <Fragment>
                  Show All <Icon name="caret-down" fa />
                </Fragment>
              )}
            </BorderlessButton>
          )}
        </div>
      </div>
    )
  }

  render() {
    if (this.props.isLoading) {
      return this.renderLoading()
    }
    const nodeLabel = this.props.nodeData.label.toUpperCase()
    const nodeType = this.props.nodeData.type
      ? ` - ${this.props.nodeData.type.toUpperCase()}`
      : ''

    const nodeDataSwombos = new Set(this.props.nodeData.swimlaneSRNs || []) // eslint-disable-line no-restricted-globals
    const swimlanes = this.props.swimlanes.filter(swombo =>
      nodeDataSwombos.has(swombo.get('srn'))
    )

    return (
      <div>
        <div style={this.styles.labelType}>{`${nodeLabel}${nodeType}`}</div>
        <div
          style={{
            ...this.styles.container,
            backgroundColor: this.getContainerBackgroundColor(),
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              padding: '0.25rem 0.5rem 0 0.5rem',
              background: this.props.theme.background,
              height: '62px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontSize: '1.5em',
                  margin: '3px 5px 0px 5px',
                }}
              >
                <Icon
                  fa
                  name={this.getHeaderIcon()}
                  color={this.props.theme.neutralDark}
                />
              </div>
              <div style={this.styles.title}>{this.renderName()}</div>
            </div>
            {!this.props.horizontal ? (
              <div key="actions" style={this.styles.actions}>
                <div style={this.styles.actionsContainer}>
                  {this.getActions()}
                </div>
              </div>
            ) : (
              <div style={this.styles.actions}>
                <div
                  style={{
                    overflow: this.state.actionDropdownExpanded
                      ? undefined
                      : 'hidden',
                  }}
                >
                  <Dropdown
                    isOpen={this.state.actionDropdownExpanded}
                    toggle={this.toggleActionMenu}
                  >
                    <DropdownToggle
                      style={{ border: 'none', color: '#333', padding: '4px' }}
                    >
                      <Icon fa name="config" />
                    </DropdownToggle>
                    <DropdownMenu>{this.getActions()}</DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
          <div style={this.styles.bottom}>
            {this.renderAccountBar(swimlanes)}
            {this.renderTags(swimlanes)}
            {this.renderSwimlanes(swimlanes.toList().toJS())}
          </div>
        </div>
        <CreateUpdateTicketModal
          isOpen={this.state.createTicketModal}
          toggle={() =>
            this.setState(oldState => ({
              createTicketModal: !oldState.createTicketModal,
            }))
          }
          resource={this.props.nodeData}
        />
      </div>
    )
  }
}

NodeViewHeader.defaultProps = {
  onNodeView: false,
  styles: {},
}

NodeViewHeader.propTypes = {
  theme: themeShape,

  // passed from permissionChecker
  userHasPermission: PropTypes.func.isRequired,

  // ~ passed props ~
  actions: PropTypes.node, // any custom actions components (eg buttons, etc.)
  nodeId: PropTypes.string.isRequired,
  // set true if rendered on node view so we can set CRM data and other stuff used
  // on actual node view in sagas
  onNodeView: PropTypes.bool,

  // ~ props from redux store ~
  crmData: PropTypes.shape({
    error: PropTypes.bool,
    monitored: PropTypes.bool,
    importance: PropTypes.number,
    changeDetectionProperties: PropTypes.arrayOf(PropTypes.object),
    changeDetectionOptions: PropTypes.arrayOf(PropTypes.object),
  }),
  isLoading: PropTypes.bool,
  nodeData: PropTypes.shape({
    account: PropTypes.string,
    active: PropTypes.bool,
    cloudType: PropTypes.string,
    friendlyName: PropTypes.string,
    hasTags: PropTypes.object,
    label: PropTypes.string,
    metadata: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    region: PropTypes.string,
    resourceId: PropTypes.string,
    swimlaneSRNs: PropTypes.array,
    srn: PropTypes.string.isRequired,
    type: PropTypes.string,
    __typename: PropTypes.string.isRequired,
  }),
  swimlanes: ImmutablePropTypes.map,
  queryTypes: ImmutablePropTypes.map.isRequired,
  resourceGroup: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    group: PropTypes.object,
    storageAccount: PropTypes.object,
  }),
  swimlaneInfo: PropTypes.shape({
    addingResourceToSL: PropTypes.bool,
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  tags: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  updateFriendlyNameStatus: PropTypes.shape({
    loading: PropTypes.bool,
  }),
  styles: PropTypes.object,

  // ~ bound action creators ~
  getNodeData: PropTypes.func.isRequired,
  getResourceGroup: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  updateFriendlyName: PropTypes.func.isRequired,
  updateImportance: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  swimlanesLoading: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNodeData,
      getResourceGroup,
      push,
      removeTag,
      updateFriendlyName,
      updateImportance,
    },
    dispatch
  )
}

const makeMapStateToProps = () => {
  const selectCrmData = makeSelectCrmData()
  const selectNodeDataIsLoading = makeSelectNodeDataIsLoading()
  const selectNodeData = makeSelectNodeData()
  const selectNodeDataError = makeSelectNodeDataError()
  const selectResouceGroup = makeSelectResourceGroup()
  const selectTags = makeSelectTags()
  const selectSwimlaneInfo = makeSelectSwimlanes()
  const selectUpdateFriendlyName = makeSelectUpdateFriendlyName()
  return createStructuredSelector({
    crmData: selectCrmData,
    isLoading: selectNodeDataIsLoading,
    nodeData: selectNodeData,
    nodeDataError: selectNodeDataError,
    queryTypes: selectQueryTypes,
    resourceGroup: selectResouceGroup,
    swimlanes: selectSwimlanes,
    swimlaneInfo: selectSwimlaneInfo,
    swimlanesLoading: selectSwimlanesLoading,
    tags: selectTags,
    updateFriendlyNameStatus: selectUpdateFriendlyName,
  })
}

const withConnect = connect(makeMapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: REDUX_KEY, reducer })
const withSaga = injectSaga({
  key: REDUX_KEY,
  saga: sagas,
  mode: DAEMON,
})

export default compose(
  withConnect,
  permissionChecker,
  themeable,
  withSaga,
  withReducer,
  withRouter
)(NodeViewHeader)
