import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import { SET_ACTIONTYPE_CONTENT, GET_ACTIONTYPE_CONTENT } from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  actiontypeContent: {},
  isLoading: false,
})

const actiontypeNodeViewCardLayoutReducer = handleActions(
  {
    [GET_ACTIONTYPE_CONTENT]: state => state.set('isLoading', true),
    [SET_ACTIONTYPE_CONTENT]: (state, { payload }) => {
      return state
        .set('actiontypeContent', fromJS(payload))
        .set('isLoading', false)
    },
    [CLEAR_DATA]: state => state.set('actiontypeContent', Map()),
  },
  initialState
)

export default actiontypeNodeViewCardLayoutReducer
