/**
 *
 * NodeViewDetails
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Color from 'color'
import _ from 'lodash'

import {
  groupNodeViewDetails,
  exists,
  getCloudFromSrn,
  buildAzureResourceLink,
  metadataToObj,
  metdataNestedObject,
} from 'utils/sonraiUtils'
import { isClassificationSupported } from 'containers/DataClassification/utils'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Icon from 'components/Icon'
import IconDivider from 'components/IconDivider'
import BorderlessButton from 'components/BorderlessButton'
import NodeViewDetailsSection from './NodeViewDetailsSection'
import TextLink from 'components/TextLink'
import CloudBadge from '../CloudBadge'

const SKIP_FIELDS = [
  'cloudType',
  'friendlyName',
  'highestAlertSeverity',
  'importance',
  'lastAlertDate',
  'sonraiConfig',
  'tagCount',
  'tagSet',
  'srcId',
  'srcIds',
  'swimlaneCount',
  'policyJSON',
]

class NodeViewDetails extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      highlight: {
        backgroundColor: Color(props.theme.emphasis)
          .lighten(0.4)
          .rotate(10)
          .hex(),
      },
      expandButton: {
        fontSize: '0.8em',
      },
      horizontalWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
    }

    this.state = {
      expand: false,
    }
  }

  toggleExpand = () => {
    this.setState(oldState => ({
      expand: !oldState.expand,
    }))
  }

  getLinkToResource = () => {
    const noneOfTheseGuys = [
      'PolicyVersion',
      'PolicyEntry',
      'PermissionList',
      'Action',
    ]
    if (
      getCloudFromSrn(this.props.nodeData.srn) === 'azure' &&
      this.props.nodeData.active &&
      !noneOfTheseGuys.includes(this.props.nodeData.label)
    ) {
      return (
        <a
          href={buildAzureResourceLink(
            this.props.nodeData.srn,
            this.props.nodeData.resourceId,
            this.props.nodeData.type,
            this.props.nodeData.label,
            this.props.nodeData.metadata
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CloudBadge
            type={'azure'}
            style={{ marginRight: '0.3em', fontSize: '1.2em' }}
          />
          <TextLink>Azure Portal</TextLink>
        </a>
      )
    } else {
      return null
    }
  }

  getAccessKeys = () => {
    const keys = []

    const ownedBy = this.props.nodeData.ownedBy || []
    ownedBy.forEach(item => {
      if (item.type === 'AccessKey') {
        keys.push(item)
      }
    })
    return keys
  }

  getPerformedOnRegions = () => {
    const regions = []
    if (
      this.props.nodeData.performedOnValue &&
      Array.isArray(this.props.nodeData.performedOnValue)
    ) {
      this.props.nodeData.performedOnValue.forEach(value => {
        if (!regions.includes(value.region)) {
          regions.push(value.region)
        }
      })
    }
    return regions
  }

  getActionDetails = () => {
    const performedOnRegions = this.getPerformedOnRegions()

    const details = []
    details.push({
      name: 'srn',
      value: this.props.nodeData.srn,
      type: {
        name: 'String',
      },
    })

    details.push({
      name: 'resourceId',
      value: this.props.nodeData.resourceId,
      type: {
        name: 'String',
      },
    })

    details.push({
      name: 'type',
      value: this.props.nodeData.type,
      type: {
        name: 'String',
      },
    })
    details.push({
      name: 'createdDate',
      value: this.props.nodeData.createdDate,
      label: 'Time of Event',
      type: {
        name: 'DateTime',
      },
    })
    details.push({
      name: 'eventName',
      value: this.props.nodeData.eventName,
      type: {
        name: 'String',
      },
    })
    details.push({
      name: 'hasActionClassificationValue',
      value: this.props.nodeData.hasActionClassificationValue,
      type: {
        name: 'Array',
      },
    })
    details.push({
      name: 'hasServiceClassificationValue',
      value: this.props.nodeData.hasServiceClassificationValue,
      type: {
        name: 'Array',
      },
    })
    details.push({
      name: 'coalescedCount',
      value: this.props.nodeData.coalescedCount,
      type: {
        name: 'Int',
      },
    })
    details.push({
      name: 'userAgent',
      value: this.props.nodeData.userAgent,
      type: {
        name: 'Int',
      },
    })
    details.push({
      name: 'srcIPs',
      value: this.props.nodeData.srcIPs,
      type: {
        name: 'Array',
      },
    })
    details.push({
      name: 'performedAtCityValues',
      value: this.props.nodeData.performedAtCityValues,
      type: {
        name: 'Array',
      },
    })
    details.push({
      name: 'performedAtCountryValues',
      value: this.props.nodeData.performedAtCountryValues,
      type: {
        name: 'Array',
      },
    })
    details.push({
      name: 'performedOnRegions',
      value: performedOnRegions,
      type: {
        name: 'Array',
      },
    })
    details.push({
      name: 'timestamp',
      value: this.props.nodeData.timestamp,
      type: {
        name: 'String',
      },
    })

    return details
  }

  getComputeDetails = () => {
    const details = []
    const metadataObject = metadataToObj(this.props.nodeData.metadata)
    const metadataNestedObject = metdataNestedObject(
      this.props.nodeData.metadata
    )

    details.push({
      name: 'active',
      value: this.props.nodeData.active,
      type: {
        name: 'Boolean',
      },
    })

    details.push({
      name: 'srn',
      value: this.props.nodeData.srn,
      type: {
        name: 'String',
      },
    })

    details.push({
      name: 'resourceId',
      value: this.props.nodeData.resourceId,
      type: {
        name: 'String',
      },
    })

    details.push({
      name: 'runState',
      value: this.props.nodeData.runState,
      type: {
        name: 'String',
      },
    })

    details.push({
      name: 'launchTime',
      value: this.props.nodeData.launchTime,
      type: {
        name: 'String',
      },
    })

    const basedOn = _.get(this.props.nodeData, [
      'basedOn',
      'items',
      0,
      'ImageName',
    ])
    details.push({
      name: 'basedOn ', // don't delete this space after basedOn very important
      value: basedOn,
      type: {
        name: 'String',
      },
    })

    details.push({
      name: 'instanceType',
      value: metadataObject['instance.instanceType'],
      type: {
        name: 'String',
      },
    })

    const networkInterfaces = _.get(
      metadataNestedObject,
      ['instance', 'networkInterfaces'],
      []
    )

    for (let i = 0; i < networkInterfaces.length; i++) {
      const networkInterface = networkInterfaces[i]
      details.push({
        name: 'publicDns' + i,
        value: _.get(networkInterface, ['association', 'publicDnsName']),
        type: {
          name: 'String',
        },
      })
      details.push({
        name: 'publicIp' + i,
        value: _.get(networkInterface, ['association', 'publicIp']),
        type: {
          name: 'String',
        },
      })
    }

    for (let i = 0; i < networkInterfaces.length; i++) {
      const networkInterface = networkInterfaces[i]
      details.push({
        name: 'privateDns' + i,
        value: _.get(networkInterface, ['privateDnsName']),
        type: {
          name: 'String',
        },
      })
      details.push({
        name: 'privateIp' + i,
        value: _.get(networkInterface, ['privateIpAddress']),
        type: {
          name: 'String',
        },
      })
    }

    details.push({
      name: 'architecture',
      value: this.props.nodeData.architecture,
      type: {
        name: 'String',
      },
    })

    details.push({
      name: 'publicKeys',
      value: this.props.nodeData.accessedWith.items,
      type: {},
    })

    details.push({
      name: 'blockStorage',
      value: this.props.nodeData.isAttachedToBlockStorage.items,
      type: {},
    })

    details.push({
      name: 'metadata',
      value: this.props.nodeData.metadata,
      type: {},
    })
    details.push({
      name: 'timestamp',
      value: this.props.nodeData.timestamp,
      type: {
        name: 'String',
      },
    })
    details.push({
      name: 'createDate',
      value: this.props.nodeData.createDate,
      type: {
        name: 'String',
      },
    })
    return details
  }

  getNewDetails = () => {
    const details = []
    details.push({
      name: 'active',
      value: this.props.nodeData.active,
      type: {
        name: 'Boolean',
      },
    })
    if (this.props.nodeData.user) {
      const mfa = this.props.nodeData.user.content.toJS()
      if (this.props.nodeData.user.loading) {
        details.push({
          name: 'mfaDevice',
          value: 'Loading...',
          type: {
            name: 'String',
          },
        })
      } else {
        if (mfa.hasMFADevice.items.length > 0) {
          details.push({
            name: 'mfaDevice',
            value: mfa.hasMFADevice.items[0].type,
            type: {
              name: 'String',
            },
          })
        } else {
          details.push({
            name: 'mfaDevice',
            value: 'None',
            type: {
              name: 'String',
            },
          })
        }
      }
    }
    if (this.props.nodeData.label === 'User') {
      details.push({
        name: 'consoleEnabled',
        value: this.props.nodeData.isConsoleEnabled,
        type: {
          name: 'Boolean',
        },
      })
      details.push({
        name: 'passwordLastUsed',
        value: this.props.nodeData.passwordLastUsed,
        type: {
          name: 'DateTime',
        },
      })
      details.push({
        name: 'accessKeys',
        value: this.getAccessKeys(),
        type: {
          name: 'String',
        },
      })
    } else if (this.props.nodeData.label === 'Role') {
      details.push({
        name: 'servicePrincipals',
        value: this.props.nodeData.servicePrincipals,
        type: {
          name: 'String',
        },
      })

      details.push({
        name: 'modifiedDate',
        value: this.props.nodeData.modifiedDate,
        type: {
          name: 'String',
        },
      })
    }

    details.push({
      name: 'srn',
      value: this.props.nodeData.srn,
      type: {
        name: 'String',
      },
    })
    details.push({
      name: 'resourceId',
      value: this.props.nodeData.resourceId,
      type: {
        name: 'String',
      },
    })
    details.push({
      name: 'timestamp',
      value: this.props.nodeData.timestamp,
      type: {
        name: 'String',
      },
    })
    details.push({
      name: 'createDate',
      value: this.props.nodeData.createDate,
      type: {
        name: 'String',
      },
    })
    details.push({
      name: 'metadata',
      value: this.props.nodeData.metadata,
      type: {
        name: 'String',
      },
    })

    return details
  }

  getHeaderContent = () => {
    const displayFields = this.props.fields.filter(
      field => !SKIP_FIELDS.includes(field.name)
    )

    const nonNullFields = displayFields.filter(field =>
      exists(this.props.nodeData[field.name])
    )
    const nullFields = displayFields.filter(
      field => !exists(this.props.nodeData[field.name])
    )

    const noCoalesce =
      !this.props.nodeData.coalescedCount ||
      this.props.nodeData.coalescedCount === 1
    const detailGroups = groupNodeViewDetails(nonNullFields, noCoalesce)
    if (this.props.nodeData.label === 'AccessKey') {
      detailGroups.general.push({
        args: [],
        deprecationReason: null,
        description: null,
        isDeprecated: false,
        name: 'isOwnedBy',
        type: { kind: 'SCALAR', name: 'String', ofType: null },
      })
    }

    if (this.props.nodeData.label === 'PolicyVersion') {
      const versionIndex = detailGroups.other.findIndex(
        item => item.name === 'version'
      )
      detailGroups.other.splice(versionIndex, 1)
    }

    let commonProps = {
      propsOfInterest: this.props.propsOfInterest,
      nodeData: this.props.nodeData,
      detailStyle: this.props.detailStyle,
      style: this.props.horizontal
        ? { marginTop: 0, marginRight: '2em', minWidth: '150px' }
        : undefined,
    }

    let details = []

    if (
      this.props.nodeData.label === 'User' ||
      this.props.nodeData.label === 'Role'
    ) {
      details = this.getNewDetails()
    } else if (this.props.nodeData.label === 'Action') {
      details = this.getActionDetails()
    } else if (this.props.nodeData.label === 'Compute') {
      details = this.getComputeDetails()
    }

    const newNodeView = details.length > 0

    return (
      <div>
        {newNodeView
          ? this.getSingleDetailGroup(details, commonProps)
          : this.getAllGroups(detailGroups, commonProps)}
        {nullFields.length > 0 && !newNodeView && (
          <div>
            <IconDivider color="#eee">
              <BorderlessButton
                onClick={this.toggleExpand}
                style={this.styles.expandButton}
              >
                {this.state.expand ? (
                  <Fragment>
                    <Icon name="caret-up" fa /> Hide Empty Properties
                  </Fragment>
                ) : (
                  <Fragment>
                    <Icon name="caret-down" fa /> Show Empty Properties
                  </Fragment>
                )}
              </BorderlessButton>
            </IconDivider>

            {this.state.expand && (
              <NodeViewDetailsSection
                {...commonProps}
                fields={nullFields}
                style={
                  this.props.horizontal
                    ? { display: 'flex', flexDirection: 'row' }
                    : undefined
                }
                detailStyle={
                  this.props.horizontal ? { margin: '0 1em' } : undefined
                }
              />
            )}
          </div>
        )}
      </div>
    )
  }

  getSingleDetailGroup = (details, commonProps) => {
    return (
      <div
        style={
          this.props.horizontal ? this.styles.horizontalWrapper : undefined
        }
      >
        <div>
          <NodeViewDetailsSection
            {...commonProps}
            fields={details}
            style={{ marginTop: 0 }}
            titleActions={this.props.detailsTag}
          />

          {this.props.additionalHeaderDetails && (
            <div>{this.props.additionalHeaderDetails}</div>
          )}
        </div>
      </div>
    )
  }

  getAllGroups = (detailGroups, commonProps) => {
    const dataDetails = detailGroups.data
    if (
      isClassificationSupported(this.props.nodeData) ||
      this.props.nodeData.__typename === 'Dataobject'
    ) {
      if (!dataDetails.find(detail => detail.name === 'classificationSet')) {
        if (
          this.props.nodeData.cloudType === 'aws' &&
          this.props.nodeData.type === 'Bucket'
        ) {
          dataDetails.push({
            name: 'classificationSet',
            type: { name: 'String' },
          })
        }
        if (
          this.props.nodeData.cloudType === 'azure' &&
          this.props.nodeData.type === 'Container'
        ) {
          dataDetails.push({
            name: 'classificationSet',
            type: { name: 'String' },
          })
        }
      }
    }

    return (
      <div
        style={
          this.props.horizontal ? this.styles.horizontalWrapper : undefined
        }
      >
        <div>
          <NodeViewDetailsSection
            {...commonProps}
            title="General Properties"
            fields={detailGroups.general}
            style={{ marginTop: 0 }}
            titleActions={this.props.detailsTag}
          />

          {this.props.additionalHeaderDetails && (
            <div>{this.props.additionalHeaderDetails}</div>
          )}
        </div>
        {detailGroups.location.length > 0 && (
          <NodeViewDetailsSection
            {...commonProps}
            title="Location"
            fields={detailGroups.location}
          />
        )}
        {detailGroups.action.length > 0 && (
          <NodeViewDetailsSection
            {...commonProps}
            title="Action Details"
            fields={detailGroups.action}
          />
        )}
        {detailGroups.compute.length > 0 && (
          <NodeViewDetailsSection
            {...commonProps}
            title="Compute Details"
            fields={detailGroups.compute}
          />
        )}
        {detailGroups.coalesced.length > 0 && (
          <NodeViewDetailsSection
            {...commonProps}
            title="Coalesced Details"
            fields={detailGroups.coalesced}
          />
        )}
        {detailGroups.data.length > 0 && (
          <NodeViewDetailsSection
            {...commonProps}
            title="Data Details"
            fields={detailGroups.data}
          />
        )}
        {detailGroups.other.length > 0 && (
          <NodeViewDetailsSection
            {...commonProps}
            title="Other Properties"
            fields={detailGroups.other}
          />
        )}
      </div>
    )
  }

  render() {
    return (
      <div style={{ ...this.styles.container, ...this.props.style }}>
        {this.getHeaderContent()}
      </div>
    )
  }
}

NodeViewDetails.defaultProps = {
  style: {},
  detailStyle: {},
  propsOfInterest: [],
}

NodeViewDetails.propTypes = {
  detailsTag: PropTypes.node,
  horizontal: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  nodeData: PropTypes.object.isRequired,
  additionalHeaderDetails: PropTypes.arrayOf(PropTypes.node),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  style: PropTypes.object,
  theme: themeShape,
  detailStyle: PropTypes.object,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

export default injectIntl(themeable(NodeViewDetails))
