export const GET_CAN_ASSUME_ROLES = 'app/Access/GET_CAN_ASSUME_ROLES'
export const ERR_CAN_ASSUME_ROLES = 'app/Access/ERR_CAN_ASSUME_ROLES'
export const SET_CAN_ASSUME_ROLES = 'app/Access/SET_CAN_ASSUME_ROLES'

export const GET_CAN_BE_ASSUMED_BY = 'app/Access/GET_CAN_BE_ASSUMED_BY'
export const ERR_CAN_BE_ASSUMED_BY = 'app/Access/ERR_CAN_BE_ASSUMED_BY'
export const SET_CAN_BE_ASSUMED_BY = 'app/Access/SET_CAN_BE_ASSUMED_BY'

export const GET_GROUP_MEMBERSHIPS = 'app/Access/GET_GROUP_MEMBERSHIPS'
export const ERR_GROUP_MEMBERSHIPS = 'app/Access/ERR_GROUP_MEMBERSHIPS'
export const SET_GROUP_MEMBERSHIPS = 'app/Access/SET_GROUP_MEMBERSHIPS'

export const GET_DIRECT_ATTACHED_POLICIES =
  'app/Access/GET_DIRECT_ATTACHED_POLICIES'
export const ERR_DIRECT_ATTACHED_POLICIES =
  'app/Access/ERR_DIRECT_ATTACHED_POLICIES'
export const SET_DIRECT_ATTACHED_POLICIES =
  'app/Access/SET_DIRECT_ATTACHED_POLICIES'

export const GET_SCPS = 'app/Access/GET_SCPS'
export const ERR_SCPS = 'app/Access/ERR_SCPS'
export const SET_SCPS = 'app/Access/SET_SCPS'

export const GET_INSTANCE_PROFILES = 'app/Access/GET_INSTANCE_PROFILES'
export const SET_INSTANCE_PROFILES = 'app/Access/SET_INSTANCE_PROFILES'
export const ERR_INSTANCE_PROFILES = 'app/Access/ERR_INSTANCE_PROFILES'

export const GET_PERMISSION_INFO = 'app/Access/GET_PERMISSION_INFO'
export const SET_PERMISSION_INFO = 'app/Access/SET_PERMISSION_INFO'
