/*
 *
 * EscalationData constants
 *
 */

export const GET_ESCALATIONS = 'app/EscalationData/GET_ESCALATIONS'
export const SET_ESCALATIONS = 'app/EscalationData/SET_ESCALATIONS'
export const SET_ESCALATIONS_ERROR = 'app/EscalationData/SET_ESCALATIONS_ERROR'

export const CREATE_ESCALATION = 'app/EscalationData/CREATE_ESCALATION'
export const CREATE_ESCALATION_SUCCESS =
  'app/EscalationData/CREATE_ESCALATION_SUCCESS'
export const CREATE_ESCALATION_ERROR =
  'app/EscalationData/CREATE_ESCALATION_ERROR'

export const DELETE_ESCALATION = 'app/EscalationData/DELETE_ESCALATION'
export const DELETE_ESCALATION_SUCCESS =
  'app/EscalationData/DELETE_ESCALATION_SUCCESS'
export const DELETE_ESCALATION_ERROR =
  'app/EscalationData/DELETE_ESCALATION_ERROR'

export const FETCH_ESCALATION_FILTER_OPTIONS =
  'app/EscalationData/FETCH_ESCALATION_FILTER_OPTIONS'
export const FETCH_ESCALATION_FILTER_OPTIONS_SUCCESS =
  'app/EscalationData/FETCH_ESCALATION_FILTER_OPTIONS_SUCCESS'
export const FETCH_ESCALATION_FILTER_OPTIONS_ERROR =
  'app/EscalationData/FETCH_ESCALATION_FILTER_OPTIONS_ERROR'

export const UPDATE_ESCALATION = 'app/EscalationData/UPDATE_ESCALATION'
export const UPDATE_ESCALATION_SUCCESS =
  'app/EscalationData/UPDATE_ESCALATION_SUCCESS'
export const UPDATE_ESCALATION_ERROR =
  'app/EscalationData/UPDATE_ESCALATION_ERROR'
export const UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR =
  'app/EscalationData/UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR'
export const CLEAR_UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR =
  'app/EscalationData/CLEAR_UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR'
