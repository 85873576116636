import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators } from 'redux'
import { List } from 'immutable'

import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import Card, { CardBody, TopTitle } from 'components/Card'

import FilterAndPath from './FilterAndPath'

export class AccessWidget extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      content: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
        height: '100%',
        gridColumnGap: '3em',
      },

      columnHeader: {
        display: 'grid',
        gridTemplateColumns: '220px 1fr',
        fontWeight: 400,
      },
    }
  }

  onDoubleClick = ({ nodes }) => {
    if (nodes.length > 0 && nodes[0].includes('srn:')) {
      const srn = nodes[0]
      this.props.push(getNodeViewPushParams(srn, getTypeFromSrn(srn)))
    }
  }

  getExposedToPublic = () => {
    return (
      this.props.publicFilters.get('items', List()).size > 0 &&
      this.props.publicPath.get('items', List()).size > 0
    )
  }

  onToggle = () => {
    if (this.props.recalculateGrid) {
      setTimeout(this.props.recalculateGrid, 100)
    }
  }

  getExposedToPrivate = () => {
    return (
      this.props.privateFilters.get('items', List()).size > 0 &&
      this.props.privatePath.get('items', List()).size > 0
    )
  }

  render() {
    return (
      <Card>
        <TopTitle style={{ fontSize: '22px', fontWeight: '300' }}>
          {this.props.title}
        </TopTitle>
        <CardBody style={{ overflow: 'auto' }}>
          <div style={this.styles.content}>
            <div>
              <div style={this.styles.columnHeader}>
                <div>Exposed to Public Access:</div>
                {this.getExposedToPublic() ? 'Yes' : 'No'}
              </div>
              <FilterAndPath
                filterLabel="Public Network Filters"
                filters={this.props.publicFilters}
                pathLabel="Public Path"
                path={this.props.publicPath}
                loadingFilters={this.props.loadingPublicFilters}
                loadingPath={this.props.loadingPublicPath}
                onDoubleClick={this.onDoubleClick}
                onToggle={this.onToggle}
              />
            </div>

            <div>
              <div style={this.styles.columnHeader}>
                <div>Exposed to Private Access:</div>
                {this.getExposedToPrivate() ? 'Yes' : 'No'}
              </div>
              <FilterAndPath
                filterLabel="Private Network Filters"
                filters={this.props.privateFilters}
                pathLabel="Private Path"
                path={this.props.privatePath}
                loadingFilters={this.props.loadingPrivateFilters}
                loadingPath={this.props.loadingPrivatePath}
                onDoubleClick={this.onDoubleClick}
                onToggle={this.onToggle}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

AccessWidget.propTypes = {
  publicFilters: ImmutablePropTypes.map.isRequired,
  publicPath: ImmutablePropTypes.map.isRequired,
  privateFilters: ImmutablePropTypes.map.isRequired,
  privatePath: ImmutablePropTypes.map.isRequired,
  title: PropTypes.node,
  loadingPublicFilters: PropTypes.bool,
  loadingPrivateFilters: PropTypes.bool,
  loadingPublicPath: PropTypes.bool,
  loadingPrivatePath: PropTypes.bool,
  push: PropTypes.func,
  recalculateGrid: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessWidget)
