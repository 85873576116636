export const DOMAIN = 'tickets'

export const FETCH_TICKET_LIST = `app/containers/Tickets/FETCH_TICKET_LIST`
export const FETCH_TICKET_LIST_SUCCESS = `app/containers/Tickets/FETCH_TICKET_LIST_SUCCESS`
export const FETCH_TICKET_LIST_ERROR = `app/containers/Tickets/FETCH_TICKET_LIST_ERROR`
export const SET_TICKET_LIST_FILTER = `app/containers/Tickets/SET_TICKET_FILTER`

// fetch change detection options
export const FETCH_CD_OPTIONS = `app/containers/Tickets/FETCH_CD_OPTIONS`
export const FETCH_CD_OPTIONS_SUCCESS = `app/containers/Tickets/FETCH_CD_OPTIONS_SUCCESS`
export const FETCH_CD_OPTIONS_ERROR = `app/containers/Tickets/FETCH_CD_OPTIONS_ERROR`

export const CLEAR_TICKET_LIST_FILTER = `app/containers/Tickets/CLEAR_TICKET_LIST_FILTER`
