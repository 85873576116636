export const RUN_QUERY = 'app/Search/RUN_QUERY'
export const QUERY_ERROR_HAPPEN = 'app/Search/QUERY_ERROR_HAPPEN'
export const CLEAR_QUERY_ERROR = 'app/Search/CLEAR_QUERY_ERROR'
export const START_QUERY = 'app/Search/START_QUERY'
export const SET_QUERY_RESULTS = 'app/Search/SET_QUERY_RESULTS'
export const SAVE_SEARCH_WITH_CONCURRENT_CHECK =
  'app/Search/SAVE_SEARCH_WITH_CONCURRENT_CHECK'
export const SET_SEARCH_MODIFIED_CONCURRENTLY =
  'app/Search/SET_SEARCH_MODIFIED_CONCURRENTLY'
export const SAVE_SEARCH = 'app/Search/SAVE_SEARCH'
export const SAVE_SEARCH_SUCCESS = 'app/Search/SAVE_SEARCH_SUCCESS'
export const SAVE_SEARCH_ERROR = 'app/Search/SAVE_SEARCH_ERROR'
export const CLEAR_SAVE_SEARCH_ERROR = 'app/Search/CLEAR_SAVE_SEARCH_ERROR'
export const SET_SEARCH_NAME = 'app/Search/SET_SEARCH_NAME'
export const LOAD_SEARCH_IN_ADVANCED = 'app/Search/LOAD_SEARCH_IN_ADVANCED'

export const SEARCH_VIEWS = {
  BUILD: 'build',
  RESULTS: 'results',
}

export const SET_MONITORING_ON_RESOURCES =
  'app/Search/SET_MONITORING_ON_RESOURCES'
export const SET_MONITORING_ON_RESOURCES_SUCCESS =
  'app/Search/SET_MONITORING_ON_RESOURCES_SUCCESS'
export const SET_MONITORING_ON_RESOURCES_ERROR =
  'app/Search/SET_MONITORING_ON_RESOURCES_ERROR'
export const SET_MONITORING_RESOURCES_EXCEEDED = `app/Search/SET_MONITORING_RESOURCES_EXCEEDED`
export const SET_IMPORTANCE_MULTI = 'app/Search/SET_IMPORTANCE_MULTI'
export const SET_IMPORTANCE_MULTI_SUCCESS =
  'app/Search/SET_IMPORTANCE_MULTI_SUCCESS'
export const SET_IMPORTANCE_MULTI_MODAL =
  'app/Search/SET_IMPORTANCE_MULTI_MODAL'

export const ADD_TAGS_ON_SELECTED_RESOURCES =
  'app/Search/ADD_TAGS_ON_SELECTED_RESOURCES'
export const ADD_TAGS_ON_SELECTED_RESOURCES_SUCCESS =
  'app/Search/ADD_TAGS_ON_SELECTED_RESOURCES_SUCCESS'
export const TOGGLE_COMMENTS = 'app/Search/TOGGLE_COMMENTS'
export const RESET_EDITOR = 'app/Search/RESET_EDITOR'
export const SAVE_SEARCH_DESCRIPTION = 'app/Search/SAVE_SEARCH_DESCRIPTION'
export const SAVE_SEARCH_DESCRIPTION_SUCCESS =
  'app/Search/SAVE_SEARCH_DESCRIPTION_SUCCESS'
export const GET_NODETYPE_COUNTS = 'app/Search/GET_NODETYPE_COUNTS'
export const SET_NODETYPE_COUNTS = 'app/Search/SET_NODETYPE_COUNTS'
