import { createSelector } from 'reselect'
import { Map, List } from 'immutable'
import { getCurrentOrg } from 'auth/current-org'

export const selectControlFrameworkDataDomain = state =>
  state.get('controlFrameworkData', Map())

export const selectLoadedControlGroups = createSelector(
  selectControlFrameworkDataDomain,
  state => state.get('loadedControlGroups')
)

export const selectControlGroups = createSelector(
  selectControlFrameworkDataDomain,
  state => state.get('controlGroups') || Map()
)

export const selectPolicies = createSelector(
  selectControlFrameworkDataDomain,
  state => state.get('policies') || Map()
)

export const selectFetchingPolicies = createSelector(
  selectControlFrameworkDataDomain,
  state => state.get('fetchingPolicies')
)

export const selectFetchingControlGroups = createSelector(
  selectControlFrameworkDataDomain,
  state => state.get('fetchingControlGroups')
)

export const selectCreatingPolicy = createSelector(
  selectControlFrameworkDataDomain,
  state => state.get('creatingPolicy')
)

export const selectDeletingCFs = createSelector(
  selectControlFrameworkDataDomain,
  state => state.get('deletingCFs')
)

export const selectLoadedPolicies = createSelector(
  selectControlFrameworkDataDomain,
  state => state.get('loadedControlPolicies')
)

export const selectPolicyError = createSelector(
  selectControlFrameworkDataDomain,
  sonraiData => sonraiData.get('policyError')
)

export const selectUpdatingPolicies = createSelector(
  selectControlFrameworkDataDomain,
  sonraiData => sonraiData.get('updatingPolicies') || Map()
)

export const selectTogglingSwimlaneOnCfs = createSelector(
  selectControlFrameworkDataDomain,
  sonraiData => sonraiData.get('togglingSwimlaneOnCfs') || Map()
)

export const selectTogglingSwimlaneError = createSelector(
  selectControlFrameworkDataDomain,
  sonraiData => sonraiData.get('togglingSwimlaneError') || Map()
)

export const selectControlFrameworksForUser = createSelector(
  [selectControlGroups],
  controlGroups =>
    controlGroups.filter(cf => {
      const thisOrgPrefix = `/org/${getCurrentOrg()}/`
      return cf.get('resourceId', '').startsWith(thisOrgPrefix)
    })
)

export const selectPoliciesForUser = createSelector(
  [selectPolicies, selectControlFrameworksForUser],
  (policies, userFrameworks) => {
    const frameworkIds = userFrameworks.toList().map(cf => cf.get('srn'))

    return policies.filterNot(policy =>
      policy
        .getIn(['containedByControlFramework', 'items'], List())
        .filter(parentFramework =>
          frameworkIds.includes(parentFramework.get('srn'))
        )
        .isEmpty()
    )
  }
)

export const selectUpdatingCFs = createSelector(
  selectControlFrameworkDataDomain,
  state => state.get('updatingCFs')
)
