import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb as RSBreadcrumb, BreadcrumbItem } from 'reactstrap'

export const Breadcrumb = ({ children }) => {
  return <RSBreadcrumb style={{ fontSize: '0.9em' }}>{children}</RSBreadcrumb>
}

Breadcrumb.propTypes = {
  children: PropTypes.node,
}

export default Breadcrumb
export { BreadcrumbItem }
