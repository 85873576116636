import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import DataTable from 'components/DataTable'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { srnIsCRMActionable } from 'utils/widgetUtils'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import SectionHeader from 'components/SectionHeader'

import ShowPath from './ShowPath'

class CRMAlertDetails extends React.Component {
  styles = {
    base: {
      zIndex: '0',
      padding: '0em 1em 1em 1em',
      overflow: 'hidden',
    },
    tableHeightWrapper: {
      height: '350px',
    },
    pathHeightWrapper: {
      height: '350px',
      marginTop: '1em',
    },
  }

  shouldComponentUpdate(nextProps) {
    return this.props.activeAlertActions !== nextProps.activeAlertActions
  }

  onDoubleClickPathNode = ({ nodes }) => {
    if (nodes.length > 0) {
      const srn = nodes[0]

      if (srn.startsWith('srn:')) {
        this.props.push(getNodeViewPushParams(srn, getTypeFromSrn(srn)))
      }
    }
  }

  handleActionsNav = (srn, type) => {
    let nav = getNodeViewPushParams(srn, type)
    nav.search = `${nav.search}`

    this.props.push(nav)
  }

  renderAccessAlert = () => {
    const evidence = this.props.alert.get('evidence')

    let path = evidence.get('path') || List()
    if (typeof path === 'string') {
      path = JSON.parse(path)
    } else {
      path = path.toJS()
    }

    const resourceId = evidence.get('resourceSet', List()).first()

    return (
      <div className="fade-in" style={this.styles.pathHeightWrapper}>
        <SectionHeader small>Access Path</SectionHeader>
        <ShowPath
          key={resourceId}
          accessPaths={path}
          resourceId={resourceId}
          onDoubleClick={this.onDoubleClickPathNode}
          sourceId={this.props.alert.get('resourceId')}
          classification={this.props.alert.get('actionClassification')}
          actionableByMode={srnIsCRMActionable(
            this.props.alert.get('resourceId')
          )}
        />
      </div>
    )
  }

  renderActivityAlert = () => {
    if (this.props.activeAlertActionsLoading) {
      return <SquareLoadingAnimation />
    }

    return (
      this.props.activeAlertActions && (
        <div>
          {this.props.showHeader && (
            <SectionHeader small>Actions for this Ticket</SectionHeader>
          )}
          <div style={this.styles.tableHeightWrapper}>
            <DataTable
              style={{ height: '300px' }}
              data={this.props.activeAlertActions}
              autosize={false}
              hiddenColumns={this.props.hiddenColumns}
              onClickNodeView={this.handleActionsNav}
            />
          </div>
        </div>
      )
    )
  }

  renderPropertyAlert = () => {
    const property = this.props.alert.get('ticketKey')
    const evidence = this.props.alert.get('evidence', Map())

    const hasBoolEvidence = evidence.get('fieldType') === 'Boolean'
    const hasStringEvidence = evidence.get('fieldType') === 'String'
    const hasIntEvidence = evidence.get('fieldType') === 'Int'
    const hasLongEvidence = evidence.get('fieldType') === 'Long'

    let prevVal
    let currVal

    if (hasBoolEvidence) {
      prevVal = evidence.get('prevBoolValue')
      currVal = evidence.get('boolValue')
    } else if (hasStringEvidence) {
      prevVal = evidence.get('prevValue')
      currVal = evidence.get('value')
    } else if (hasIntEvidence) {
      prevVal = evidence.get('prevIntValue')
      currVal = evidence.get('intValue')
    } else if (hasLongEvidence) {
      prevVal = evidence.get('prevLongValue')
      currVal = evidence.get('longValue')
    }

    prevVal = String(prevVal)
    currVal = String(currVal)

    return (
      <div>
        {this.props.showHeader && (
          <SectionHeader small>Information</SectionHeader>
        )}
        Property <span style={{ fontWeight: 400 }}>{property}</span> changed
        from{' '}
        <span style={{ backgroundColor: '#d4d4fc', padding: '0 0.5em' }}>
          {prevVal}
        </span>{' '}
        to{' '}
        <span style={{ backgroundColor: '#f7f2cf', padding: '0 0.5em' }}>
          {currVal}
        </span>
      </div>
    )
  }

  render() {
    return (
      <div style={this.styles.base}>
        {this.props.alert.get('ticketType') === 'Access'
          ? this.renderAccessAlert()
          : this.props.alert.get('ticketType') === 'Activity'
          ? this.renderActivityAlert()
          : this.renderPropertyAlert()}
      </div>
    )
  }
}

CRMAlertDetails.defaultProps = {
  showHeader: true,
}

CRMAlertDetails.propTypes = {
  activeAlertActions: PropTypes.array,
  activeAlertActionsLoading: PropTypes.bool,
  push: PropTypes.func,
  alert: ImmutablePropTypes.map.isRequired,
  showHeader: PropTypes.bool,
  hiddenColumns: PropTypes.array,
}

export default CRMAlertDetails
