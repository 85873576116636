import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import SelectBar from 'components/SelectBar'
import { List } from 'immutable'

import Icon from 'components/Icon'
import TabBar from 'components/TabBar'
import FormLabel from 'components/FormLabel'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import {
  createPolicy,
  addPolicy,
} from 'containers/ControlFrameworkData/actions'
import {
  selectSonraiSearches,
  selectSavedSearches,
} from 'containers/SonraiData/selectors'
import { selectPolicies } from 'containers/ControlFrameworkData/selectors'

import {
  POLICY_EVAL_TYPES,
  POLICY_OP_TYPES,
} from 'containers/PolicyEdit/constants'
import messages from './messages'
import { selectSaving } from './selectors'
import PolicyEditor from 'containers/PolicyEdit/PolicyEditor'

export class AddPolicyModal extends React.Component {
  componentDidUpdate(oldProps) {
    if (oldProps.saving && !this.props.saving) {
      this.props.toggle()
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      title: '',
      description: '',
      selectedSearchValue: null,
      selectedSearchSrn: null,
      selectedPolicySrn: null,
      policyEvalType: POLICY_EVAL_TYPES.COUNT,
      policyEvalTarget: 0,
      policyEvalOp: POLICY_OP_TYPES.GT,
      policyRemediationType: null,
      activeTabIndex: 0,
      alertingLevelNumeric: 5,
    }

    this.styles = {
      tabbar: {
        paddingBottom: 0,
        fontWeight: '300',
      },
      triggerConfig: {
        display: 'grid',
        gridTemplateColumns: '30% 1fr 30%',
        gridColumnGap: '1em',
      },
    }
  }
  setPolicyDescription = value => {
    this.setState({
      description: value,
    })
  }

  setPolicyTitle = e => {
    this.setState({
      title: e.target.value,
    })
  }

  setPolicyEvalOp = e => {
    this.setState({
      policyEvalOp: e.target.value,
    })
  }

  setPolicyRemediationType = item =>
    this.setState({ policyRemediationType: item ? item.value : item })

  setPolicyEvalTarget = e => {
    const parsed = parseInt(e.target.value)
    this.setState({
      policyEvalTarget: isNaN(parsed) ? e.target.value : parsed,
    })
  }

  setPolicyEvalType = e => {
    this.setState({
      policyEvalType: e.target.value,
    })
  }

  handleCreate = () => {
    const evalCriteria = {
      [this.state.policyEvalOp]: [
        this.state.policyEvalType,
        this.state.policyEvalTarget,
      ],
    }

    this.props.createPolicy({
      title: this.state.title,
      description: this.state.description,
      searchId: this.state.selectedSearchSrn,
      controlGroupId: this.props.controlGroup.get('srn'),
      alertingLevelNumeric: this.state.alertingLevelNumeric,
      remediationType: this.state.policyRemediationType,
      evalCriteria: evalCriteria,
    })
  }

  handleAdd = () => {
    this.props.addPolicy({
      controlGroupId: this.props.controlGroup.get('srn'),
      policyId: this.state.selectedPolicySrn,
    })
  }

  setSelectedSearch = value => {
    if (!value) {
      this.setState({
        selectedSearchValue: null,
        selectedSearchSrn: null,
      })
    } else {
      this.setState({
        selectedSearchValue: value.value,
        selectedSearchSrn: value.srn,
      })
    }
  }

  setSelectedPolicy = value => {
    if (!value) {
      this.setState({
        selectedPolicySrn: null,
      })
    } else {
      this.setState({
        selectedPolicySrn: value.value,
      })
    }
  }

  changeTab = tab => {
    this.setState({
      activeTabIndex: tab,
    })
  }

  getFilteredPolicies = () => {
    const currentPolicyIds = this.props.controlGroup
      .getIn(['contains', 'items'], List())
      .map(policy => policy.get('srn'))

    return this.props.policies
      .toList()
      .filterNot(policy => currentPolicyIds.includes(policy.get('srn')))
  }

  triggerIsValid = () => {
    return typeof this.state.policyEvalTarget === 'number'
  }

  renderSubmitButton = () => {
    if (this.props.saving) {
      return (
        <Button color="primary" disabled>
          <Icon fa spin name="sync" />
        </Button>
      )
    }

    if (this.state.activeTabIndex === 0) {
      return (
        <Button
          color="primary"
          onClick={this.handleCreate}
          disabled={
            !this.state.title ||
            !this.state.selectedSearchSrn ||
            !this.triggerIsValid()
          }
        >
          <FormattedMessage {...messages.createButton} />
        </Button>
      )
    } else {
      return (
        <Button
          color="primary"
          onClick={this.handleAdd}
          disabled={!this.state.selectedPolicySrn}
        >
          <FormattedMessage {...messages.addButton} />
        </Button>
      )
    }
  }

  renderCreateContent = () => {
    return (
      <PolicyEditor
        onAlertingLevelNumericChange={alertingLevelNumeric =>
          this.setState({ alertingLevelNumeric })
        }
        alertingLevelNumeric={this.state.alertingLevelNumeric}
        setPolicyEvalTarget={this.setPolicyEvalTarget}
        setPolicyTitle={this.setPolicyTitle}
        setPolicyDescription={this.setPolicyDescription}
        setPolicyEvalType={this.setPolicyEvalType}
        setPolicyEvalOp={this.setPolicyEvalOp}
        setSelectedSearch={this.setSelectedSearch}
        policyEvalTarget={this.state.policyEvalTarget}
        policyEvalOp={this.state.policyEvalOp}
        policyEvalType={this.state.policyEvalType}
        policyRemediationType={this.state.policyRemediationType}
        setPolicyRemediationType={this.setPolicyRemediationType}
        description={this.state.description}
        title={this.state.title}
        sonraiSearches={this.props.sonraiSearches}
        selectedSearchValue={this.state.selectedSearchValue}
        savedSearches={this.props.savedSearches}
      />
    )
  }

  renderAddContent = () => {
    return (
      <div>
        <FormLabel
          for="policy"
          label={<FormattedMessage {...messages.policyLabel} />}
          required={true}
        />

        <SelectBar
          isClearable
          name="policy"
          value={this.state.selectedPolicySrn}
          options={this.getFilteredPolicies()
            .toJS()
            .map(field => ({
              value: field.srn,
              label: field.title,
            }))}
          onChange={this.setSelectedPolicy}
        />
      </div>
    )
  }

  render() {
    return (
      <Modal isOpen={true} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle} style={this.styles.tabbar}>
          <TabBar
            changeTab={this.changeTab}
            activeTab={this.state.activeTabIndex}
            tabs={[
              {
                id: 'createNew',
                text: this.props.intl.formatMessage(messages.newPolicyTabLabel),
              },
              {
                id: 'addExisting',
                text: this.props.intl.formatMessage(messages.addPolicyTabLabel),
              },
            ]}
          />
        </ModalHeader>
        <ModalBody>
          {this.state.activeTabIndex === 0
            ? this.renderCreateContent()
            : this.renderAddContent()}
        </ModalBody>
        <ModalFooter>
          <TextLink color="secondary" onClick={this.props.toggle}>
            <FormattedMessage {...messages.cancelButton} />
          </TextLink>
          {this.renderSubmitButton()}
        </ModalFooter>
      </Modal>
    )
  }
}

AddPolicyModal.propTypes = {
  addPolicy: PropTypes.func.isRequired,
  createPolicy: PropTypes.func.isRequired,
  controlGroup: ImmutablePropTypes.map.isRequired,
  intl: intlShape,
  policies: ImmutablePropTypes.map.isRequired,
  saving: PropTypes.bool,
  savedSearches: ImmutablePropTypes.iterable.isRequired,
  sonraiSearches: ImmutablePropTypes.iterable.isRequired,
  toggle: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  saving: selectSaving,
  savedSearches: selectSavedSearches,
  sonraiSearches: selectSonraiSearches,
  policies: selectPolicies,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPolicy,
      addPolicy,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, injectIntl)(AddPolicyModal)
