import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { fromJS } from 'immutable'
import _ from 'lodash'

import InfoCard, { Property } from 'components/InfoCard'
import { metadataToObj } from 'utils/sonraiUtils'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import CodeBlock from 'components/CodeBlock'

export class AwsVaultSecret extends React.Component {
  getLayouts = () => {
    return {
      contents: {
        lg: { w: 8 },
        md: { w: 7 },
        sm: { w: 6 },
        xs: { w: 4 },
      },
      graph: {
        lg: { w: 8, h: 2 },
        md: { w: 7, h: 2 },
        sm: { w: 6, h: 2 },
        xs: { w: 4, h: 2 },
      },
    }
  }

  getLabelFromMetadata = key => {
    const parts = key.split('.')

    const label = parts[parts.length - 1]
    return _.startCase(label)
  }

  getGridContent = () => {
    const name = this.props.nodeData.name || ''
    const type = _.get(name.split('/'), 1)

    if (type === 'roles') {
      const metadata = fromJS(metadataToObj(this.props.nodeData.metadata))
        .delete('role.default_sts_ttl')
        .delete('role.max_sts_ttl')
        .map((value, key) => {
          if (key === 'role.role_arns' || key === 'role.policy_arns') {
            try {
              value = JSON.parse(value).map(val => <div key={val}>{val}</div>)
            } catch (e) {
              //eslint-disable-next-line no-console
              console.warn('could not parse json from metadata', e)
            }
          }

          if (key === 'role.policy_document') {
            try {
              const parsedValue = JSON.stringify(JSON.parse(value), null, 4)
              value = <CodeBlock>{parsedValue}</CodeBlock>
            } catch (e) {
              //eslint-disable-next-line no-console
              console.warn('could not parse json from metadata', e)
            }
          }

          return (
            <Property
              key={key}
              value={value || '-'}
              label={this.getLabelFromMetadata(key)}
            />
          )
        })
        .toList()
        .toJS()

      return [
        <div key="contents">
          <InfoCard title="Secret Properties">{metadata}</InfoCard>
        </div>,
      ]
    }

    return []
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          layouts={this.getLayouts()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
        />
      </Fragment>
    )
  }
}

AwsVaultSecret.propTypes = {
  horizontal: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

export default AwsVaultSecret
