/**
 *
 * LoadingAnim
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ProgressBar from 'progressbar.js'

import fail from 'assets/sadcloud.png'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { SUPPORT_EMAIL } from 'appConstants'
import messages from './messages'
export class LoadingAnim extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    const start = this.props.inverse ? 1 : 0
    const end = this.props.inverse ? 0 : 1

    var path = new ProgressBar.Path('#loading_logo', {
      duration: 10000,
      from: {
        color: this.props.theme.secondary,
        width: 2,
      },
      to: {
        color: this.props.theme.primary,
        width: 8,
      },
      strokeWidth: 4,
      easing: 'easeInOut',
      step: (state, shape) => {
        shape.path.setAttribute('stroke', state.color)
        shape.path.setAttribute('stroke-width', state.width)
      },
    })
    path.set(start)
    path.animate(end)
    this.interval = setInterval(() => {
      path.set(end)
      path.animate(start)
    }, 10500)
    this.interval2 = setInterval(() => {
      path.set(start)
      path.animate(end)
    }, 21000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.interval2)
  }

  render() {
    if (this.props.error) {
      return (
        <div style={{ textAlign: 'center' }}>
          <div>
            <img id="graphqlerror" src={fail} style={{ width: '40%' }} />
          </div>
          <div
            style={{
              color: this.props.theme.primary,
              fontWeight: '400',
              fontSize: '40px',
            }}
          >
            <p>
              <FormattedMessage {...messages.error} />
            </p>
            <p>
              <FormattedMessage {...messages.contact} />{' '}
              <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
            </p>
          </div>
        </div>
      )
    }

    return (
      <div className="fade-in" style={{ textAlign: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <Fragment>
            <div>
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="550.000000pt"
                height="500.000000pt"
                viewBox="0 0 500.000000 550.000000"
              >
                <path
                  transform="matrix(1.33333 0 0 -1.33333 0 524.215)"
                  id="loading_logo"
                  d={`M299.86 229.32a79.962 79.962 0 0 1-25.383-4.119 80.136 80.136 0 0 1 2.216 18.752c0 44.245-35.868 80.113-80.112 80.113-32.487 0-62.088-24.282-73.166-47.414-2.387-4.983-.531-10.948 4.397-13.447 3.189-1.617 6.83-1.354 9.685.363 1.555.935 2.734 2.378 3.76 4.034 11.45 18.44 30.873 36.505 55.324 36.505 33.22 0 60.153-26.932 60.153-60.154 0-14.824-4.543-29.148-14.262-38.867-7.895-7.895-14.69-13.084-21.655-16.154l-21.113 19.336c-3.867 3.541-9.872 3.278-13.413-.589l-.535-.584c-3.541-3.866-3.277-9.87.589-13.412l64.017-58.629c3.866-3.54 9.87-3.277 13.412.59l.535.584a9.491 9.491 0 0 1-.59 13.412l-20.77 19.021c2.47 7.221 8.57 17.17 14.124 22.816 10.879 11.064 26.044 17.884 42.787 17.884 33.222 0 60.153-26.932 60.153-60.154 0-33.22-26.931-60.153-60.153-60.153-15.726 0-29.176 7.064-40.754 15.921-3.461 2.647-79.441 70.552-103.741 94.852-16.423 16.423-37.026 29.492-62.064 29.492-44.245 0-80.112-35.868-80.112-80.112 0-44.245 35.867-80.112 80.112-80.112 32.5 0 60.47 19.361 73.034 47.173L186.61 97.7c3.867-3.54 9.872-3.277 13.412.59 3.54 3.865 3.277 9.87-.59 13.411l-63.391 58.058c-3.866 3.541-9.871 3.276-13.412-.59-3.541-3.866-3.277-9.87.589-13.412l27.332-25.03c-7.8-24.178-30.477-41.672-57.25-41.672-33.221 0-60.152 26.932-60.152 60.153 0 33.222 26.93 60.154 60.153 60.154 16.339 0 30.532-7.194 41.987-17.097.214-.185 86.97-78.138 102.524-93.692 16.418-16.418 37.017-29.477 62.048-29.477 44.245 0 80.112 35.867 80.112 80.112 0 44.244-35.867 80.112-80.112 80.112`}
                  fill="none"
                  stroke="blue"
                  strokeWidth="5"
                />
              </svg>
            </div>
            <div
              style={{
                color: this.props.theme.primary,
                fontWeight: '400',
                fontSize: '40px',
              }}
            >
              <FormattedMessage {...messages.loading} />
            </div>
          </Fragment>
        </div>
      </div>
    )
  }
}

LoadingAnim.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  inverse: PropTypes.bool,
  theme: themeShape,
}

export default themeable(LoadingAnim)
