import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import { SET_ACTION_CONTENT, GET_ACTION_CONTENT } from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  actionContent: {},
  isLoading: false,
})

const actionNodeViewCardLayoutReducer = handleActions(
  {
    [GET_ACTION_CONTENT]: state => state.set('isLoading', true),
    [SET_ACTION_CONTENT]: (state, { payload }) => {
      return state.set('actionContent', fromJS(payload)).set('isLoading', false)
    },
    [CLEAR_DATA]: state => state.set('actionContent', Map()),
  },
  initialState
)

export default actionNodeViewCardLayoutReducer
