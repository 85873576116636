/*
 *
 * SwimlaneDetails actions
 *
 */

import {
  GET_PREVIEW_RESULTS,
  SET_PREVIEW_RESULTS,
  CREATE_SWIMLANE,
  CREATE_SWIMLANE_SUCCESS,
  DELETE_SWIMLANE,
  DELETE_SWIMLANE_SUCCESS,
  SET_EDITING_SWIMLANE_SRN,
  UPDATE_SWIMLANE,
  UPDATE_SWIMLANE_SUCCESS,
  SET_UPDATE_SWIMLANE_ERROR,
  GET_TAG_VALUES,
  SET_TAG_VALUES,
  GET_CONTROL_FRAMEWORKS_FOR_SWIMLANE,
  SET_CONTROL_FRAMEWORKS_FOR_SWIMLANE,
  GET_BOT_VALUES,
  SET_BOT_VALUES,
  UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK,
  UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK_SUCCESS,
  UPDATE_USER_IN_SWIMLANE,
  UPDATE_USER_IN_SWIMLANE_SUCCESS,
  SET_SWIMLANE_BOT_ERROR,
} from './constants'
import { createAction } from 'redux-actions'

export const getPreviewResults = createAction(GET_PREVIEW_RESULTS)
export const setPreviewResults = createAction(SET_PREVIEW_RESULTS)
export const createSwimlane = createAction(CREATE_SWIMLANE)
export const createSwimlaneSuccess = createAction(CREATE_SWIMLANE_SUCCESS)
export const deleteSwimlane = createAction(DELETE_SWIMLANE)
export const deleteSwimlaneSuccess = createAction(DELETE_SWIMLANE_SUCCESS)
export const setEditingSwimlaneSrn = createAction(SET_EDITING_SWIMLANE_SRN)
export const updateSwimlane = createAction(UPDATE_SWIMLANE)
export const updateSwimlaneSuccess = createAction(UPDATE_SWIMLANE_SUCCESS)
export const setUpdateSwimlaneError = createAction(SET_UPDATE_SWIMLANE_ERROR)
export const getTagValues = createAction(GET_TAG_VALUES)
export const setTagValues = createAction(SET_TAG_VALUES)
export const getControlFrameworksForSwimlane = createAction(
  GET_CONTROL_FRAMEWORKS_FOR_SWIMLANE
)
export const setControlFrameworksForSwimlane = createAction(
  SET_CONTROL_FRAMEWORKS_FOR_SWIMLANE
)
export const getBotValues = createAction(GET_BOT_VALUES)
export const setBotValues = createAction(SET_BOT_VALUES)
export const updateSwimlanesInControlFramework = createAction(
  UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK
)
export const updateSwimlanesInControlFrameworkSuccess = createAction(
  UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK_SUCCESS
)
export const updateUserInSwimlane = createAction(UPDATE_USER_IN_SWIMLANE)
export const updateUserInSwimlaneSuccess = createAction(
  UPDATE_USER_IN_SWIMLANE_SUCCESS
)
export const setSwimlaneBotError = createAction(SET_SWIMLANE_BOT_ERROR)
