/*
 *
 * Activity reducer
 *
 */

import { fromJS } from 'immutable'
import moment from 'moment'
import {
  GET_PERFORMED_ACTIONS,
  SET_PERFORMED_ACTIONS,
  SET_ACTIVITY_DATE,
  TOGGLE_PERFORMED_ACTION_FIELDS,
  CLEAR_ACTIVITY,
  GET_ROLES_ASSUMED,
  SET_ROLES_ASSUMED,
  GET_ROLES_ASSUMED_BY,
  SET_ROLES_ASSUMED_BY,
  GET_ACTIVE_FROM,
  SET_ACTIVE_FROM,
  GET_ACCESSED_USING,
  SET_ACCESSED_USING,
  GET_SELECTED_ROW_ACTIONS,
  SET_SELECTED_ROW_ACTIONS,
  TOGGLE_ACTIONS_MODAL,
  SET_PERFORMED_ACTION_FIELDS,
} from './constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  performedActions: {
    data: null,
    isLoading: false,
  },
  rolesAssumed: {
    data: null,
    isLoading: false,
  },
  rolesAssumedBy: {
    data: null,
    isLoading: false,
  },
  activeFrom: {
    data: null,
    isLoading: false,
  },
  accessedUsing: {
    data: null,
    isLoading: false,
  },
  selectedRowActions: null,
  selectedRowActionsLoading: false,
  actionsModal: false,
})
  .set('date', {
    fromDate: moment().subtract(1, 'days'),
    toDate: moment(),
  })
  .set('performedActionFields', fromJS(['performedByValue']))

const activityReducer = handleActions(
  {
    [GET_PERFORMED_ACTIONS]: state =>
      state
        .setIn(['performedActions', 'isLoading'], true)
        .setIn(['performedActions', 'data'], null),
    [SET_PERFORMED_ACTIONS]: (state, { payload }) =>
      state
        .setIn(['performedActions', 'data'], fromJS(payload))
        .setIn(['performedActions', 'isLoading'], false),
    [GET_ROLES_ASSUMED]: state =>
      state
        .setIn(['rolesAssumed', 'isLoading'], true)
        .setIn(['rolesAssumed', 'data'], null),
    [SET_ROLES_ASSUMED]: (state, { payload }) =>
      state
        .setIn(['rolesAssumed', 'data'], fromJS(payload))
        .setIn(['rolesAssumed', 'isLoading'], false),
    [GET_ROLES_ASSUMED_BY]: state =>
      state
        .setIn(['rolesAssumedBy', 'isLoading'], true)
        .setIn(['rolesAssumedBy', 'data'], null),
    [SET_ROLES_ASSUMED_BY]: (state, { payload }) =>
      state
        .setIn(['rolesAssumedBy', 'data'], fromJS(payload))
        .setIn(['rolesAssumedBy', 'isLoading'], false),
    [SET_ACTIVITY_DATE]: (state, { payload }) => state.set('date', payload),
    [SET_PERFORMED_ACTION_FIELDS]: (state, { payload }) =>
      state.set('performedActionFields', fromJS(payload)),
    [TOGGLE_PERFORMED_ACTION_FIELDS]: (state, { payload }) => {
      const index = state.get('performedActionFields').indexOf(payload)
      if (index === -1) {
        return state.set(
          'performedActionFields',
          state.get('performedActionFields').push(payload)
        )
      } else {
        return state.set(
          'performedActionFields',
          state.get('performedActionFields').splice(index, 1)
        )
      }
    },
    [GET_ACTIVE_FROM]: state =>
      state
        .setIn(['activeFrom', 'isLoading'], true)
        .setIn(['activeFrom', 'data'], null),
    [SET_ACTIVE_FROM]: (state, { payload }) =>
      state
        .setIn(['activeFrom', 'data'], fromJS(payload))
        .setIn(['activeFrom', 'isLoading'], false),
    [GET_ACCESSED_USING]: state =>
      state
        .setIn(['accessedUsing', 'isLoading'], true)
        .setIn(['accessedUsing', 'data'], null),
    [SET_ACCESSED_USING]: (state, { payload }) =>
      state
        .setIn(['accessedUsing', 'data'], fromJS(payload))
        .setIn(['accessedUsing', 'isLoading'], false),
    [TOGGLE_ACTIONS_MODAL]: state =>
      state.set('actionsModal', !state.get('actionsModal')),
    [GET_SELECTED_ROW_ACTIONS]: state =>
      state.set('actionsModal', true).set('selectedRowActionsLoading', true),
    [SET_SELECTED_ROW_ACTIONS]: (state, { payload }) => {
      return state
        .set('selectedRowActions', fromJS(payload))
        .set('selectedRowActionsLoading', false)
    },
    [CLEAR_ACTIVITY]: () => {
      return initialState
    },
  },
  initialState
)

export default activityReducer
