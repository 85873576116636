import React from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Icon from 'components/Icon'

export const ActionToolbar = ({ hidden, children, theme, working, style }) => {
  const styles = {
    toolbar: {
      padding: '0.5em 1em',
      backgroundColor: theme.highlight,
      display: 'flex',
    },
    spinnerContainer: {
      flex: 1,
      textAlign: 'right',
      color: theme.neutralMedium,
    },
  }

  if (hidden) {
    return null
  }

  return (
    <div className="flip-in-hor-bottom" style={{ ...styles.toolbar, ...style }}>
      {React.Children.map(children, child => {
        if (!child) {
          return null
        }

        return React.cloneElement(child, {
          disabled: working || child.props.disabled,
        })
      })}
      {working && (
        <div style={styles.spinnerContainer}>
          <Icon fa name="sync" spin />
        </div>
      )}
    </div>
  )
}

ActionToolbar.defaultProps = {
  style: {},
}

ActionToolbar.propTypes = {
  children: PropTypes.node,
  hidden: PropTypes.bool,
  style: PropTypes.object,
  theme: themeShape,
  working: PropTypes.bool,
}
export default themeable(ActionToolbar)
export { default as ToolbarItem } from './ToolbarItem'
export { default as ToolbarDivider } from './ToolbarDivider'
