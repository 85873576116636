/*
 * PolicyNodeViewCardLayout Messages
 *
 * This contains all the text for the PolicyNodeViewCardLayout component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.containers.PolicyNodeViewCardLayout.title',
    defaultMessage: 'Data Details',
  },
  activeLabel: {
    id: 'app.containers.PolicyNodeViewCardLayout.activeLabel',
    defaultMessage: 'Active',
  },
  typeLabel: {
    id: 'app.containers.PolicyNodeViewCardLayout.typeLabel',
    defaultMessage: 'Type',
  },
  accountLabel: {
    id: 'app.containers.PolicyNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id: 'app.containers.PolicyNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  Description: {
    id: 'app.containers.PolicyNodeViewCardLayout.description',
    defaultMessage: 'Description',
  },
  group: {
    id: 'app.containers.PolicyNodeViewCardLayout.group',
    defaultMessage: 'Group',
  },
  lastModifiedLabel: {
    id: 'app.containers.PolicyNodeViewCardLayout.lastModifiedLabel',
    defaultMessage: 'Last Modified',
  },
  createdLabel: {
    id: 'app.containers.PolicyNodeViewCardLayout.createdLabel',
    defaultMessage: 'Created',
  },
  srnLabel: {
    id: 'app.containers.PolicyNodeViewCardLayout.srnLabel',
    defaultMessage: 'srn',
  },
  service: {
    id: 'app.containers.PolicyNodeViewCardLayout.service',
    defaultMessage: 'Service',
  },
  descriptionLabel: {
    id: 'app.containers.PolicyNodeViewCardLayout.description',
    defaultMessage: 'Description',
  },
  sizeLabel: {
    id: 'app.containers.PolicyNodeViewCardLayout.size',
    defaultMessage: 'Size',
  },
  bytes: {
    id: 'app.containers.PolicyNodeViewCardLayout.bytes',
    defaultMessage: 'Bytes',
  },
  publicRead: {
    id: 'app.containers.PolicyNodeViewCardLayout.publicRead',
    defaultMessage: 'Public Read',
  },
  publicWrite: {
    id: 'app.containers.PolicyNodeViewCardLayout.publicWrite',
    defaultMessage: 'Public Write',
  },
  webisteEnabled: {
    id: 'app.containers.PolicyNodeViewCardLayout.webisteEnabled',
    defaultMessage: 'Website Enabled',
  },
  auditEnabled: {
    id: 'app.containers.PolicyNodeViewCardLayout.auditEnabled',
    defaultMessage: 'Audit Enabled',
  },
  containerLabel: {
    id: 'app.containers.PolicyNodeViewCardLayout.containerLabel',
    defaultMessage: 'Container',
  },
  none: {
    id: 'app.containers.PolicyNodeViewCardLayout.none',
    defaultMessage: 'None',
  },
  dataItem: {
    id: 'app.containers.PolicyNodeViewCardLayout.dataItem',
    defaultMessage: 'This Data item is not part of a container',
  },
  record: {
    id: 'app.containers.PolicyNodeViewCardLayout.record',
    defaultMessage: 'Record',
  },
  performed: {
    id: 'app.containers.PolicyNodeViewCardLayout.performed',
    defaultMessage: 'Performed',
  },
  members: {
    id: 'app.containers.PolicyNodeViewCardLayout.performed',
    defaultMessage: 'Members',
  },
  groupsPolicies: {
    id: 'app.containers.PolicyNodeViewCardLayout.groupsPolicies',
    defaultMessage: 'Groups & Policies',
  },
  label: {
    id: 'app.containers.PolicyNodeViewCardLayout.label',
    defaultMessage: 'Label',
  },
  Policy: {
    id: 'app.containers.PolicyNodeViewCardLayout.Policy',
    defaultMessage: 'Policy',
  },
  public: {
    id: 'app.containers.PolicyNodeViewCardLayout.public',
    defaultMessage: 'Public',
  },
  usedIn: {
    id: 'app.containers.PolicyNodeViewCardLayout.usedIn',
    defaultMessage: 'Used In:',
  },
  Version: {
    id: 'app.containers.PolicyNodeViewCardLayout.version',
    defaultMessage: 'Version',
  },
  noPolicies: {
    id: 'app.containers.PolicyNodeViewCardLayout.noPolicies',
    defaultMessage: 'This policy has no versions',
  },
  name: {
    id: 'app.containers.PolicyNodeViewCardLayout.name',
    defaultMessage: 'Name',
  },
  subscription: {
    id: 'app.containers.PolicyNodeViewCardLayout.subscription',
    defaultMessage: 'Subscription',
  },
})
