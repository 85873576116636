import { createSelector } from 'reselect'
import { Map } from 'immutable'
import qs from 'query-string'

export const selectQuickSearchDomain = state => state.get('quicksearch', Map())

export const selectQuickSearchResults = createSelector(
  selectQuickSearchDomain,
  state => state.get('quickSearchResults') || Map()
)

export const selectQuickSearchLoading = createSelector(
  selectQuickSearchDomain,
  state => state.get('quickSearchLoading') || false
)

export const selectResultLimitExceeded = createSelector(
  selectQuickSearchDomain,
  state => state.get('resultLimitExceeded')
)

export const selectError = createSelector(
  selectQuickSearchDomain,
  state => state.get('error')
)

export const selectQuery = () => {
  return qs.parse(location.search).query
}
