import { createAction } from 'redux-actions'

import {
  GET_NODE_DATA,
  SET_NODE_DATA,
  ERR_NODE_DATA,
  SET_CRM_DATA,
  ERR_CRM_DATA,
  GET_KEYVAULT_DATA,
  SET_KEYVAULT_DATA,
  ERR_KEYVAULT_DATA,
  GET_RESOURCE_GROUP,
  SET_RESOURCE_GROUP,
  ERR_RESOURCE_GROUP,
  SET_SWIMLANES,
  ERR_SWIMLANES,
  ADD_CHANGE_DETECTION_PROPERTIES,
  ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS,
  ADD_KEYVAULT_CREDS,
  ADD_KEYVAULT_CREDS_SUCCESS,
  ADD_RESOURCE_TO_SWIMLANE,
  ADD_RESOURCE_TO_SWIMLANE_SUCCESS,
  ADD_TAG,
  ADD_TAG_SUCCESS,
  REMOVE_CHANGE_DETECTION_PROPERTIES,
  REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS,
  REMOVE_KEYVAULT_CREDS,
  REMOVE_KEYVAULT_CREDS_SUCCESS,
  REMOVE_TAG,
  REMOVE_TAG_SUCCESS,
  UPDATE_CHANGE_DETECTION_PROPERTY,
  UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS,
  TOGGLE_RESOURCE_MONITORING,
  TOGGLE_RESOURCE_MONITORING_SUCCESS,
  UPDATE_FRIENDLY_NAME,
  UPDATE_FRIENDLY_NAME_SUCCESS,
  UPDATE_IMPORTANCE,
  UPDATE_IMPORTANCE_SUCCESS,
  UPDATE_KEYVAULT_CREDS,
  UPDATE_KEYVAULT_CREDS_SUCCESS,
} from './constants'

export const getNodeData = createAction(GET_NODE_DATA)
export const setNodeData = createAction(SET_NODE_DATA)
export const errNodeData = createAction(ERR_NODE_DATA)

export const setCrmData = createAction(SET_CRM_DATA)
export const errCrmData = createAction(ERR_CRM_DATA)

export const setSwimlanes = createAction(SET_SWIMLANES)
export const errSwimlanes = createAction(ERR_SWIMLANES)

export const getResourceGroup = createAction(GET_RESOURCE_GROUP)
export const setResourceGroup = createAction(SET_RESOURCE_GROUP)
export const errResourceGroup = createAction(ERR_RESOURCE_GROUP)

export const getKeyVaultData = createAction(GET_KEYVAULT_DATA)
export const setKeyVaultData = createAction(SET_KEYVAULT_DATA)
export const errKeyVaultData = createAction(ERR_KEYVAULT_DATA)

export const addChangeDetectionProperties = createAction(
  ADD_CHANGE_DETECTION_PROPERTIES
)
export const addChangeDetectionPropertiesSuccess = createAction(
  ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS
)

export const addKeyVaultCreds = createAction(ADD_KEYVAULT_CREDS)
export const addKeyVaultCredsSuccess = createAction(ADD_KEYVAULT_CREDS_SUCCESS)

export const addResourceToSwimlane = createAction(ADD_RESOURCE_TO_SWIMLANE)
export const addResourceToSwimlaneSuccess = createAction(
  ADD_RESOURCE_TO_SWIMLANE_SUCCESS
)

export const addTag = createAction(ADD_TAG)
export const addTagSuccess = createAction(ADD_TAG_SUCCESS)

export const removeChangeDetectionProperties = createAction(
  REMOVE_CHANGE_DETECTION_PROPERTIES
)
export const removeChangeDetectionPropertiesSuccess = createAction(
  REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS
)

export const removeKeyVaultCreds = createAction(REMOVE_KEYVAULT_CREDS)
export const removeKeyVaultCredsSuccess = createAction(
  REMOVE_KEYVAULT_CREDS_SUCCESS
)

export const removeTag = createAction(REMOVE_TAG)
export const removeTagSuccess = createAction(REMOVE_TAG_SUCCESS)

export const updateChangeDetectionProperty = createAction(
  UPDATE_CHANGE_DETECTION_PROPERTY
)
export const updateChangeDetectionPropertySucces = createAction(
  UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS
)

export const toggleResourceMonitoring = createAction(TOGGLE_RESOURCE_MONITORING)
export const toggleResourceMonitoringSuccess = createAction(
  TOGGLE_RESOURCE_MONITORING_SUCCESS
)

export const updateFriendlyName = createAction(UPDATE_FRIENDLY_NAME)
export const updateFriendlyNameSuccess = createAction(
  UPDATE_FRIENDLY_NAME_SUCCESS
)

export const updateImportance = createAction(UPDATE_IMPORTANCE)
export const updateImportanceSuccess = createAction(UPDATE_IMPORTANCE_SUCCESS)

export const updateKeyVaultCreds = createAction(UPDATE_KEYVAULT_CREDS)
export const updateKeyVaultCredsSuccess = createAction(
  UPDATE_KEYVAULT_CREDS_SUCCESS
)
