import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectPolicyNodeViewCardLayoutDomain = state =>
  state.get('policyNodeViewCardLayout', Map())

export const selectPolicyContent = createSelector(
  selectPolicyNodeViewCardLayoutDomain,
  state => state.get('policyContent') || Map()
)

export const selectIsLoading = createSelector(
  selectPolicyNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
