/**
 *
 * Login
 *
 */

import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import ChooseOrgModal from 'components/ChooseOrgModal'
import SonraiPage, {
  SonraiPageContent,
  SonraiPageFooter,
} from 'components/SonraiPage'
import { useAuth0 } from 'react-auth0-wrapper'

import OrgSelector from 'auth/current-org'

import styles from './styles'
export const Login = props => {
  const { loginWithRedirect } = useAuth0()
  useEffect(() => {
    // just go right to login screen, don't give user the chance to click
    if (!props.authenticated) {
      loginWithRedirect()
    }
  })

  let mustChooseOrg = false
  let chooseOrgModal = null
  if (props.authenticated && window.aat) {
    mustChooseOrg = !OrgSelector.getCurrentOrg()
    if (mustChooseOrg) {
      chooseOrgModal = (
        <ChooseOrgModal
          allowedOrgs={OrgSelector.getAllowedOrgs()}
          onConfirm={org => {
            OrgSelector.setCurrentOrg(org)
          }}
        />
      )
    }
  }

  // if you wanna clikc to login, remove the use effect above
  if (!props.authenticated || mustChooseOrg) {
    if (window.location.hash === '') {
      return (
        <SonraiPage videoBackground>
          <SonraiPageContent>
            {chooseOrgModal}
            {
              // also uncomment this to show the button!
              // it's commented now because we don't want it to flash on the screen
              /*
            <div id="login-container" style={styles.loginContainer}>
              <button
                type="button"
                onClick={() => loginWithRedirect()}
                style={{ background: 'rgba(255,255,255,0.6)', color: 'blue' }}
              >
                Login
              </button>
            </div>
            */
            }
          </SonraiPageContent>
          <SonraiPageFooter>
            <span style={styles.copyright}>
              © {new Date().getFullYear()} Sonraí Security
            </span>
          </SonraiPageFooter>
        </SonraiPage>
      )
    } else {
      return <div style={{ background: '#c0c0c0' }} />
    }
  } else if (localStorage.getItem('redirectOnLoginUrl')) {
    const url = localStorage.getItem('redirectOnLoginUrl')
    localStorage.removeItem('redirectOnLoginUrl')
    return <Redirect to={{ pathname: url }} />
  } else {
    return <Redirect to={'/App/SecurityCenter'} />
  }
}

export default Login
