import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import FormLabel from 'components/FormLabel'

export const RequiredSpan = () => <span style={{ color: 'red' }}> *</span>

export function DefaultFieldControlContainer(props) {
  return (
    <div className="field-control">
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </div>
  )
}

export default class SubmitFormBaseComponent extends Component {
  constructor(props) {
    super(props)
    this.RequiredSpan = RequiredSpan // allow access to children if they dont wanna import

    this.DefaultFieldControlContainer = DefaultFieldControlContainer

    this.styles = {
      fieldHelpContent: {
        marginTop: '6px',
        marginBottom: '6px',
        fontSize: '13px',
        fontWeight: 300,
        overflow: 'hidden',
      },
      fieldHelpContentClosed: {
        maxHeight: '0px',
        transition: 'max-height 0.2s ease-out',
      },
      fieldHelpContentOpen: {
        maxHeight: '500px',
        transition: 'max-height 0.5s',
      },
      formHeadingErrors: {
        color: 'red',
      },
    }

    /**
     * Here are three methods that you must subclass this class and implement
     *
     * if you implement the subclass like this ...
     * renderHelpContent() { }
     *
     * ... not using an arrow function make sure you bind the method in the
     * constructor
     */
    this.renderTitleText = () => {
      throw new Error(
        `${this.displayName} (${
          this.props.fieldKey
        }) must impement method renderTitleText`
      )
    }
    this.renderHelpContent = () => {
      throw new Error(
        `${this.displayName} (${
          this.props.fieldKey
        }) must impement method renderHelpContent`
      )
    }
    this.renderFieldControls = () => {
      throw new Error(
        `${this.displayName} (${
          this.props.fieldKey
        }) must impement method renderFieldControls`
      )
    }
  }

  render() {
    return (
      <div className="form-field-section">
        <div className="field-heading">
          <FormLabel
            className="field-heading-text"
            style={
              this.props.formErrors[this.props.fieldKey]
                ? this.styles.formHeadingErrors
                : {}
            }
            required={this.props.required}
          >
            {this.renderTitleText()}
          </FormLabel>
        </div>
        <div
          className="field-heading-helpContent"
          style={{
            ...this.styles.fieldHelpContent,
            ...this.styles.fieldHelpContentOpen,
          }}
        >
          {this.renderHelpContent()}
        </div>
        {this.renderFieldControls()}
      </div>
    )
  }
}

SubmitFormBaseComponent.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  formErrors: PropTypes.object.isRequired,
  required: PropTypes.bool,

  // some props aren't used by the base class, but children can expect them to be passed:
  authenticationType: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  formValues: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  setFormFields: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
}

export const RadioInstructionMessage = styled.span`
  font-size: 12px;
`
