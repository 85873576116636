import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { List, isImmutable } from 'immutable'
import _ from 'lodash'
import Select from 'react-select'
import 'react-gh-like-diff/dist/css/diff2html.min.css'
import { ReactGhLikeDiff } from 'react-gh-like-diff'

import { getNameFromSrn } from 'utils/sonraiUtils'
import CodeBlock from 'components/CodeBlock'
import { REMEDIATION_TYPES } from 'appConstants'
import CopyToClipBoard from 'components/CopyToClipboard'
import FormLabel from 'components/FormLabel'
import { ButtonGroup } from 'reactstrap'
import Button from 'components/Button'

const RemediationModal = ({ toggle, ticket, policy }) => {
  const evidence = ticket.getIn(['evidence', 'policyEvidence'], '')
  const policyEvidence = isImmutable(evidence) ? evidence.toJS() : evidence
  const data = _.get(policyEvidence, ['data']) || []
  const policySrn = _.get(data, ['0', 'policySrn'], null)
  const initialPolicy = policySrn
    ? { label: getNameFromSrn(policySrn), value: policySrn }
    : null
  const [selectedPolicy, setPolicy] = useState(initialPolicy)
  const [tabIndex, setTabIndex] = useState(0)
  const [hasMonitoredActionsPolicy, setHasMonitoredActionsPolicy] = useState(
    false
  )
  const fullPolicy = selectedPolicy
    ? data.find(item => item.policySrn === selectedPolicy.value) || {}
    : {}

  const styles = {
    policyDiffContainer: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      gridGap: '1rem',
      gridTemplateAreas: '"select" "view"',
    },
    selectContainer: {
      gridArea: 'select',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      alignItems: 'flex-end',
      gridColumnGap: '2.5rem',
    },
    policyDiffHeader: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '1em',
      margin: '0rem 0rem 0.5rem 0rem',
    },
    policyDiffTitleContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      alignItems: 'center',
    },
    modalBody: {
      maxHeight: '80vh',
      minHeight: '40vh',
      overflow: 'auto',
      padding: '0rem 1rem 1rem 1rem',
    },
  }

  useEffect(() => {
    if (fullPolicy) {
      if (fullPolicy.monitoredActionsPolicy) {
        setHasMonitoredActionsPolicy(true)
      } else {
        setHasMonitoredActionsPolicy(false)
      }
    }
  }, [selectedPolicy])

  const renderPolicyDiffView = () => {
    let currentPol = fullPolicy.currentPolicy
    const suggestedPol =
      tabIndex !== 0 && hasMonitoredActionsPolicy
        ? fullPolicy.monitoredActionsPolicy
        : fullPolicy.policy

    if (!currentPol || !suggestedPol) {
      return <div>No suggested policy for this resource</div>
    }

    const oldCode = JSON.stringify(currentPol, null, 2)
    const newCode = JSON.stringify(suggestedPol, null, 2)

    return (
      <div>
        <div style={styles.policyDiffHeader}>
          <div style={styles.policyDiffTitleContainer}>
            <span style={{ fontWeight: 400 }}>Original Policy</span>
            <CopyToClipBoard value={oldCode} />
          </div>
          <div style={styles.policyDiffTitleContainer}>
            <span style={{ fontWeight: 400 }}>
              {tabIndex === 0
                ? 'Recommended Policy'
                : 'Monitored Actions Policy'}
            </span>

            <CopyToClipBoard value={newCode} style={{ marginTop: '0rem' }} />
          </div>
        </div>
        <ReactGhLikeDiff
          past={oldCode}
          current={newCode}
          options={{ drawFileList: false }}
        />
      </div>
    )
  }

  const renderPolicyDiff = () => {
    const options = data.map(({ policySrn }) => ({
      label: getNameFromSrn(policySrn),
      value: policySrn,
    }))

    return (
      <div style={styles.policyDiffContainer}>
        {selectedPolicy && (
          <div style={styles.selectContainer}>
            <div>
              <FormLabel style={{ fontSize: '1rem', fontWeight: '400' }}>
                Select Policy to Compare{' '}
              </FormLabel>
              <Select
                value={selectedPolicy}
                onChange={setPolicy}
                options={options}
              />
            </div>
            <div>
              <ButtonGroup style={{ height: '38.5px' }}>
                <Button
                  outline={tabIndex !== 0}
                  color="primary"
                  style={{ padding: '0.25rem 0.5rem' }}
                  onClick={() => setTabIndex(0)}
                >
                  Recommended Policy
                </Button>
                <Button
                  outline={tabIndex !== 1}
                  color="primary"
                  disabled={!hasMonitoredActionsPolicy}
                  style={{ padding: '0.25rem 0.5rem' }}
                  onClick={() => setTabIndex(1)}
                >
                  Monitored Actions Policy
                </Button>
              </ButtonGroup>
            </div>
          </div>
        )}
        <div style={{ gridArea: 'view' }}>
          {selectedPolicy ? (
            renderPolicyDiffView()
          ) : (
            <div>No suggested policy for this resource</div>
          )}
        </div>
      </div>
    )
  }

  const renderManualRemediationSteps = () => {
    //TODO: replace this with the remediation steps on the ticket itself (a new field)

    return (
      <Fragment>
        {policy
          .getIn(['hasManualRemediations', 'items'], List())
          .map((remediation, index) => (
            <CodeBlock key={index}>
              {remediation.get('remediationSteps')}
            </CodeBlock>
          ))}
      </Fragment>
    )
  }

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      size="lg"
      style={{
        width: '80vw',
        minWidth: '80vw',
      }}
    >
      <ModalHeader toggle={toggle}>Remediation Steps</ModalHeader>
      <ModalBody style={styles.modalBody}>
        {policy &&
        policy.get('remediationType') === REMEDIATION_TYPES.UPDATE_POLICY
          ? renderPolicyDiff()
          : renderManualRemediationSteps()}
      </ModalBody>
    </Modal>
  )
}

RemediationModal.propTypes = {
  policy: ImmutablePropTypes.map,
  ticket: ImmutablePropTypes.map.isRequired,
  toggle: PropTypes.func,
}

export default RemediationModal
