/**
 *
 * DataClassifierData
 *
 */

import React from 'react'
import { compose } from 'redux'

import { getDisplayName } from 'utils/hocHelpers'
import sonraiDatareducer from './reducer'
import sagas from './sagas'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'

const dataClassifierData = WrappedComponent => {
  return class extends React.Component {
    displayName = `DataClassifierData(${getDisplayName(WrappedComponent)})`
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const withReducer = injectReducer({
  key: 'dataClassifierData',
  reducer: sonraiDatareducer,
})
const withSaga = injectSaga({ key: 'dataClassifierData', saga: sagas })

export default compose(withReducer, withSaga, dataClassifierData)
