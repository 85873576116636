/*
 *
 * SwimlaneDetails constants
 *
 */

export const GET_PREVIEW_RESULTS = 'app/SwimlaneDetails/GET_PREVIEW_RESULTS'
export const SET_PREVIEW_RESULTS = 'app/SwimlaneDetails/SET_PREVIEW_RESULTS'
export const CREATE_SWIMLANE = 'app/SwimlaneDetails/CREATE_SWIMLANE'
export const CREATE_SWIMLANE_SUCCESS =
  'app/SwimlaneDetails/CREATE_SWIMLANE_SUCCESS'
export const DELETE_SWIMLANE = 'app/SwimlaneDetails/DELETE_SWIMLANE'
export const DELETE_SWIMLANE_SUCCESS =
  'app/SwimlaneDetails/DELETE_SWIMLANE_SUCCESS'
export const SET_EDITING_SWIMLANE_SRN =
  'app/SwimlaneDetails/SET_EDITING_SWIMLANE_SRN'
export const UPDATE_SWIMLANE = 'app/SwimlaneDetails/UPDATE_SWIMLANE'
export const UPDATE_SWIMLANE_SUCCESS =
  'app/SwimlaneDetails/UPDATE_SWIMLANE_SUCCESS'
export const SET_UPDATE_SWIMLANE_ERROR =
  'app/SwimlaneDetails/SET_UPDATE_SWIMLANE_ERROR'

export const GET_TAG_VALUES = 'app/SwimlaneDetails/GET_TAG_VALUES'
export const SET_TAG_VALUES = 'app/SwimlaneDetails/SET_TAG_VALUES'
export const GET_CONTROL_FRAMEWORKS_FOR_SWIMLANE =
  'app/SwimlaneDetails/GET_CONTROL_FRAMEWORKS_FOR_SWIMLANE'
export const SET_CONTROL_FRAMEWORKS_FOR_SWIMLANE =
  'app/SwimlaneDetails/SET_CONTROL_FRAMEWORKS_FOR_SWIMLANE'
export const UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK =
  'app/SwimlaneDetails/UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK'
export const UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK_SUCCESS =
  'app/SwimlaneDetails/UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK_SUCCESS'

export const GET_BOT_VALUES = 'app/SwimlaneDetails/GET_BOT_VALUES'
export const SET_BOT_VALUES = 'app/SwimlaneDetails/SET_BOT_VALUES'

export const UPDATE_USER_IN_SWIMLANE =
  'app/SwimlaneDetails/UPDATE_USER_IN_SWIMLANE'
export const UPDATE_USER_IN_SWIMLANE_SUCCESS =
  'app/SwimlaneDetails/UPDATE_USER_IN_SWIMLANE_SUCCESS'
export const SET_SWIMLANE_BOT_ERROR =
  'app/SwimlaneDetails/SET_SWIMLANE_BOT_ERROR'
