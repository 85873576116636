import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { fromJS } from 'immutable'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import ContainerDimensions from 'react-container-dimensions'
import CenterContent from 'components/CenterContent'
import BorderlessButton from 'components/BorderlessButton'
import { selectAccounts } from 'containers/SonraiData/selectors'
import Button from 'components/Button'
import BounceLoadingAnimation from 'components/BounceLoadingAnimation'
import GraphVis from 'components/GraphVis'
import Icon from 'components/Icon'
import _ from 'lodash'
import color from 'color'
import qs from 'query-string'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class PathWidget extends React.Component {
  state = {
    treeMode: true,
  }

  toggleDisplayMode = () => {
    this.setState(currentState => ({
      treeMode: !currentState.treeMode,
    }))
  }

  getEdges = () => {
    const { edges } = this.props
    return edges.map(edge => {
      const {
        relation: { name, direction },
        item,
        parent,
      } = edge
      if (name) {
        if (name.startsWith('qf')) {
          return {
            item,
            parent,
            relation: { direction, name: _.last(name.split('_')) },
          }
        }
      }
      return edge
    })
  }

  getOptions = () => {
    if (this.state.treeMode) {
      return {
        layout: {
          hierarchical: {
            enabled: true,
            direction: 'LR',
            sortMethod: 'directed',
            levelSeparation: 200,
          },
        },
        physics: {
          enabled: false,
        },
        edges: {
          font: {
            vadjust: -10,
            size: 12,
          },
          arrowStrikethrough: false,
          shadow: {
            enabled: false,
          },
          smooth: {
            enabled: true,
            type: 'cubicBezier',
            roundness: 0.8,
            forceDirection: 'horizontal',
          },
          width: 2,
        },
        nodes: {
          widthConstraint: {
            maximum: 300,
          },
          margin: 10,
        },
        interaction: {
          hover: true,
          hoverConnectedEdges: false,
          selectConnectedEdges: true,
          dragNodes: false,
        },
      }
    }

    return {
      nodes: {
        widthConstraint: {
          maximum: 300,
        },
        margin: 10,
      },
      interaction: {
        hover: true,
        hoverConnectedEdges: false,
        selectConnectedEdges: true,
      },
    }
  }

  navigateToExplorer = () => {
    this.props.push({
      pathname: '/App/Explorer',
      search: qs.stringify({
        id: this.props.nodes[0].item.srn,
      }),
      state: {
        nodes: this.props.nodes,
        edges: this.getEdges(),
      },
    })
  }

  render() {
    if (this.props.loading) {
      return (
        <CenterContent style={{ height: '100%' }}>
          <BounceLoadingAnimation />
        </CenterContent>
      )
    }

    if (this.props.error) {
      return (
        <CenterContent style={{ height: '100%' }}>
          <div>There was an error loading the data</div>
        </CenterContent>
      )
    }

    if (
      !this.props.nodes ||
      this.props.nodes.length === 0 ||
      !this.props.edges
    ) {
      return <div />
    }

    return (
      <div
        style={{
          height: '100%',
          position: 'relative',
          width: '100%',
          ...this.props.style,
        }}
      >
        <ContainerDimensions>
          <GraphVis
            key={`${this.state.treeMode.toString()}-${this.props.resourceId}`}
            onDoubleClick={this.props.onDoubleClick}
            terms={[]}
            options={this.getOptions()}
            nodes={this.props.nodes}
            edges={this.getEdges()}
            sourceNodes={
              this.props.emphasisNodes
                ? fromJS(this.props.emphasisNodes)
                : undefined
            }
            showAccountInLabel
            accounts={this.props.accounts}
          />
        </ContainerDimensions>
        <BorderlessButton
          style={{ position: 'absolute', top: '5px', right: '5px' }}
          onClick={this.toggleDisplayMode}
        >
          {this.state.treeMode ? (
            <span>
              <Icon fa name="chart-network" />
              &nbsp;Show as constellation
            </span>
          ) : (
            <span>
              <Icon fa name="sitemap" transform="rotate--90" />
              &nbsp;Show as tree
            </span>
          )}
        </BorderlessButton>
        <Button
          style={{
            position: 'absolute',
            top: '5px',
            left: '10px',
            height: '1.85rem',
            fontSize: '0.85rem',
            border: `1px solid ${this.props.theme.primary}`,
            padding: '0 0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: '5px',
            color: color(this.props.theme.neutralDark).darken(0.6),
          }}
          onClick={this.navigateToExplorer}
        >
          <span>
            <Icon fa name="chart-network" />
            &nbsp;View in Explorer
          </span>
        </Button>
      </div>
    )
  }
}

PathWidget.defaultProps = {
  style: {},
}

PathWidget.propTypes = {
  accounts: ImmutablePropTypes.iterable.isRequired,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        srn: PropTypes.string,
        __typename: PropTypes.string,
        friendlyName: PropTypes.string,
        name: PropTypes.string,
        active: PropTypes.bool,
      }),
    })
  ).isRequired,
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        srn: PropTypes.string,
        __typename: PropTypes.string,
        friendlyName: PropTypes.string,
        name: PropTypes.string,
        active: PropTypes.bool,
      }),
      parent: PropTypes.string,
      relation: PropTypes.shape({
        direction: PropTypes.oneOf(['IN', 'OUT']),
        name: PropTypes.string,
      }),
    })
  ).isRequired,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  onDoubleClick: PropTypes.func,
  push: PropTypes.func,
  emphasisNodes: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
  resourceId: PropTypes.string,
  theme: themeShape,
}
const mapStateToProps = createStructuredSelector({
  accounts: selectAccounts,
})
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, themeable)(PathWidget)
