import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import DataTable from 'components/DataTable'
import { TopTitle, CardBody } from 'components/Card'
import Card from 'components/Card'
import { getAccountFromSrn, getNodeViewPushParams } from 'utils/sonraiUtils'
import WidgetPopup from 'components/WidgetPopup'
import moment from 'moment'
import { exists } from 'utils/sonraiUtils'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import TextLink from 'components/TextLink'
import { CRM_TABLE_MAX_SIZE } from 'containers/AccessActivity/constants'

class DidAccessWidget extends Component {
  styles = {
    title: {
      fontSize: '22px',
      fontWeight: '300',
      marginRight: '0px',
    },
    card: {
      minHeight: 0,
    },
  }

  getData = didAccessData => {
    if (
      exists(didAccessData) &&
      exists(didAccessData.data) &&
      !_.isEmpty(didAccessData.data)
    ) {
      if (this.props.actionableByMode) {
        const rows = didAccessData.data.map(x => {
          const row = {
            who:
              x.resourceSetFull &&
              x.resourceSetFull
                .map(
                  resource =>
                    resource.friendlyName ||
                    resource.name ||
                    _.last(resource.srn.split('/'))
                )
                .join(', '),
            how: x.actionClassification,
            highestAlertSeverity: x.highestAlertSeverity,
            importance: x.importance,
            when: moment.utc(x.timestamp).format('LLL'),
            where: x.resourceSetFull
              ? x.resourceSetFull.map(y => y.account).join(', ')
              : x.resourceSet &&
                x.resourceSet.map(y => getAccountFromSrn(y)).join(', '),
            service:
              x.resourceSet &&
              x.resourceSet.map(y => y.split(':')[2]).join(', '),
            __typename: x.__typename,
            resourceSet: x.resourceSet,
            showActions: '',
          }
          return row
        })
        return rows || []
      } else {
        const rows = didAccessData.data.map(x => {
          const row = {
            what: x.resourceSetFull
              .map(
                resource =>
                  resource.friendlyName ||
                  resource.name ||
                  _.last(resource.srn.split('/'))
              )
              .join(', '),
            how: x.actionClassification,
            highestAlertSeverity: x.highestAlertSeverity,
            importance: x.importance,
            when: moment.utc(x.timestamp).format('LLL'),
            where: x.resourceSetFull
              ? x.resourceSetFull.map(y => y.account).join(', ')
              : x.resourceSet &&
                x.resourceSet.map(y => getAccountFromSrn(y)).join(', '),
            service: x.resource.srn.split('/')[2],
            __typename: x.__typename,
            resourceSet: x.resourceSet,
            showActions: '',
          }
          return row
        })
        return rows || []
      }
    }
    return []
  }

  getHeader = count => {
    const { isLocked, title } = this.props
    const keyName = this.props.actionableByMode ? 'accessedBy' : 'accessed'

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={this.styles.title}>
            {title}
            {count !== 0 && (
              <span
                style={{
                  color: '#888',
                }}
              >
                (
                {count >= CRM_TABLE_MAX_SIZE ? `${CRM_TABLE_MAX_SIZE}+` : count}
                )
              </span>
            )}
          </span>
          <WidgetPopup
            data={{
              keyName,
              keyType: 'PATH',
            }}
            title={this.props.title}
            resourceSrn={this.props.srn}
            isLocked={isLocked}
            crmTickets={this.props.crmTickets}
          />
        </div>
      </div>
    )
  }

  handleRowDoubleClick = (srn, type) => {
    let nav = getNodeViewPushParams(srn, type)
    this.props.push(nav)
  }

  handleActionsModal = ({ resourceSet, how }) => {
    const { srn, path, date } = this.props
    this.props.getSelectedRowActions({
      data: {
        actionClassification: how,
        resourceSet,
        criticalResourceSRN: srn,
      },
      path,
      date,
    })
  }

  render() {
    const { title, isLoading } = this.props
    const didAccessData = this.getData(this.props.didAccessData)
    return (
      <Card loading={isLoading} title={title}>
        <TopTitle>{this.getHeader(didAccessData.length)}</TopTitle>
        <CardBody style={this.styles.card}>
          <DataTable
            groupBy={!_.isEmpty(didAccessData) ? 'when' : undefined}
            sortColumns
            onRowCountChange={this.props.recalculateGrid}
            style={{
              height: didAccessData.length > 0 ? '300px' : '60px',
            }}
            sortColumnByType={
              this.props.actionableByMode ? 'DidAccessData' : 'DidAccessUser'
            }
            crm
            data={didAccessData}
            customColumnConfig={{
              showActions: {
                width: 120,
                minWidth: 120,
                aggFunc: null,
                pinned: 'right',
                headerName: '',
                menuTabs: [],
                suppressMenu: true,
                cellRendererFramework: params => {
                  if (!params.data) {
                    return null
                  }

                  return (
                    <TextLink
                      color="primary"
                      style={{ color: this.props.theme.primary }}
                      onClick={() => this.handleActionsModal(params.data)}
                    >
                      Show Actions
                    </TextLink>
                  )
                },
              },
            }}
          />
        </CardBody>
      </Card>
    )
  }
}

DidAccessWidget.propTypes = {
  theme: themeShape,
  actionableByMode: PropTypes.bool,
  crmTickets: ImmutablePropTypes.list.isRequired,
  title: PropTypes.string,
  isLocked: PropTypes.bool,
  recalculateGrid: PropTypes.func,
  srn: PropTypes.string,
  date: PropTypes.string,
  didAccessData: PropTypes.object,
  isLoading: PropTypes.bool,
  push: PropTypes.func,
  getSelectedRowActions: PropTypes.func,
  path: PropTypes.string,
}

export default themeable(DidAccessWidget)
