/**
 *
 * DataContainerNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { List } from 'immutable'
import { FormattedMessage } from 'react-intl'
import Dropdown, {
  DropdownMenu,
  InlineDropdownAnchor,
  DropdownItem,
} from 'components/Dropdown'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import TableWidget from 'components/TableWidget'
import { isClassificationSupported } from 'containers/DataClassification/utils'
import messages from './messages'
import TextLink from 'components/TextLink'
import reducer from './reducer'
import sagas from './sagas'
import { getDataContainerContent, getActionsPerformed } from './actions'
import {
  selectDataContainerContent,
  selectIsLoading,
  selectIsLoadingActions,
  selectActionsPerformed,
} from './selectors'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { formatDataForDataAnatomy } from 'containers/DataClassification/utils'
const styles = {
  title: {
    fontSize: '22px',
    fontWeight: '300',
    marginRight: '7px',
  },
}

export class DataContainerNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: 1,
    }

    props.getActionsPerformed({
      srn: props.nodeData.srn,
      range: 1,
    })

    props.getDataContainerContent(props.nodeData.srn)
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getActions = () => {
    const { actionsPerformed, loadingActions } = this.props
    if (!actionsPerformed.isEmpty() && !loadingActions) {
      return actionsPerformed.toJS().map(action => {
        return {
          eventName: action.eventName,
          createdDate: action.createdDate,
          type: action.type,
          srn: action.srn,
          performedBy: _.get(action, ['performedBy', 'items', 0, 'name']),
          actionType: _.get(action, [
            'hasActionType',
            'group',
            0,
            'key',
            'name',
          ]),
        }
      })
    } else {
      return []
    }
  }

  getDataAnatomyCustomColumnCofig = () => {
    const navigateToThing = srn => () => {
      const type = getTypeFromSrn(srn)
      const nav = getNodeViewPushParams(srn, type)
      this.props.push(nav)
    }
    return {
      flex: 1,
      cellRendererFramework: params => {
        if (typeof params.value === 'string') {
          return params.value
        } else {
          return (
            <TextLink
              color="primary"
              onClick={navigateToThing(params.value.srn)}
            >
              {params.value.name || params.value.srn || ''}
            </TextLink>
          )
        }
      },
    }
  }

  getContents = () => {
    const contents = this.props.dataContainerContent
      .getIn(['contains', 'items'], List())
      .toJS()
      .filter(item =>
        item.type
          ? !item.type.toLowerCase().includes('fingerprint')
          : !item.srn.toLowerCase().includes('fingerprint')
      )
      .map(item => {
        return {
          name: item.name,
          active: item.active,
          createdDate: item.createdDate,
          type: item.type,
          srn: item.srn,
        }
      })

    return contents
  }

  getFingerPrints = () => {
    const fingerprints = this.props.dataContainerContent
      .getIn(['contains', 'items'], List())
      .toJS()
      .filter(item =>
        item.type
          ? item.type.toLowerCase().includes('fingerprint')
          : item.srn.toLowerCase().includes('fingerprint')
      )

    return fingerprints.map(item => ({
      Type: item.type || '-',
      CoalescedCount: item.coalescedCount || '-',
      Encryption: (item.name && item.name.split('/')[2]) || '-',
      FileExtension: (item.name && _.last(item.name.split('/'))) || '-',
    }))
  }

  getLayouts = () => {
    const hasActions = this.getActions().length > 0
    const hasContents = this.getContents().length > 0
    const hasFingerPrints = this.getFingerPrints().length > 0

    return {
      actions: {
        lg: { w: 8, h: hasActions ? 3 : 1 },
        md: { w: 7, h: hasActions ? 3 : 1 },
        sm: { w: 6, h: hasActions ? 3 : 1 },
        xs: { w: 4, h: hasActions ? 3 : 1 },
      },
      fingerprints: {
        lg: { w: 8, h: hasFingerPrints ? 3 : 1 },
        md: { w: 7, h: hasFingerPrints ? 3 : 1 },
        sm: { w: 6, h: hasFingerPrints ? 3 : 1 },
        xs: { w: 4, h: hasFingerPrints ? 3 : 1 },
      },
      contents: {
        lg: { w: 8, h: hasContents ? 3 : 1 },
        md: { w: 7, h: hasContents ? 3 : 1 },
        sm: { w: 6, h: hasContents ? 3 : 1 },
        xs: { w: 4, h: hasContents ? 3 : 1 },
      },
      graph: {
        lg: { w: 5, h: 3 },
        md: { w: 4, h: 3 },
        sm: { w: 6, h: 3 },
        xs: { w: 4, h: 3 },
      },
    }
  }

  handleFilterChange = ({ value }) => {
    this.setState({
      filter: value,
    })
    this.props.getActionsPerformed({
      srn: this.props.nodeData.srn,
      range: value,
    })
  }

  getContentsTable = () => {
    const supportsDataClassifications = isClassificationSupported(
      this.props.nodeData
    )

    if (
      supportsDataClassifications &&
      !_.isEmpty(
        formatDataForDataAnatomy(this.props.dataContainerContent).toJS()
      )
    ) {
      return (
        <TableWidget
          nodeViewType="Datacontainer"
          loading={this.props.isLoading}
          title={this.props.intl.formatMessage(messages.DataClassifications)}
          autosize={false}
          data={formatDataForDataAnatomy(
            this.props.dataContainerContent
          ).toJS()}
          customColumnConfig={{
            schema: this.getDataAnatomyCustomColumnCofig(),
            table: this.getDataAnatomyCustomColumnCofig(),
            column: this.getDataAnatomyCustomColumnCofig(),
          }}
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
        />
      )
    }
    return (
      <TableWidget
        nodeViewType="Datacontainer"
        userProfile={this.props.userProfile}
        setUserProfileTables={this.props.setUserProfileTables}
        data={this.getContents()}
        title={this.props.intl.formatMessage(messages.Contents)}
        subtitle={`${this.getContents().length} ${
          this.getContents().length === 1 ? 'item' : 'items'
        }`}
        onClickNodeView={this.onClickNodeView}
        loading={this.props.isLoading}
      />
    )
  }

  getGridContent = () => {
    return [
      <div key="actions">
        <TableWidget
          nodeViewType="Datacontainer"
          onClickNodeView={this.onClickNodeView}
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
          data={this.getActions()}
          title={
            <div style={{ display: 'inline-block' }}>
              <span style={styles.title}>
                <FormattedMessage {...messages.performed} />
              </span>

              <Dropdown
                selectedValue={this.state.filter}
                onClick={this.handleFilterChange}
              >
                <InlineDropdownAnchor />
                <DropdownMenu>
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays1)}
                    value={1}
                  />
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays7)}
                    value={7}
                  />
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays30)}
                    value={30}
                  />
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays90)}
                    value={90}
                  />
                </DropdownMenu>
              </Dropdown>
            </div>
          }
          loading={this.props.loadingActions}
        />
      </div>,
      <div key="contents">{this.getContentsTable()}</div>,
      <div key="fingerprints">
        <TableWidget
          nodeViewType="Datacontainer"
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
          data={this.getFingerPrints()}
          title={this.props.intl.formatMessage(messages.FingerPrints)}
          subtitle={`${this.getFingerPrints().length} ${
            this.getFingerPrints().length === 1 ? 'item' : 'items'
          }`}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.isLoading}
        />
      </div>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          layouts={this.getLayouts()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
        />
      </Fragment>
    )
  }
}

DataContainerNodeViewCardLayout.propTypes = {
  getDataContainerContent: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataContainerContent: ImmutablePropTypes.map.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  setUserProfileTables: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
  getActionsPerformed: PropTypes.func,
  actionsPerformed: ImmutablePropTypes.iterable,
  loadingActions: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile,
  dataContainerContent: selectDataContainerContent,
  isLoading: selectIsLoading,
  loadingActions: selectIsLoadingActions,
  actionsPerformed: selectActionsPerformed,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserProfileTables,
      push,
      getDataContainerContent,
      getActionsPerformed,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({
  key: 'dataContainerNodeViewCardLayout',
  reducer,
})

const withSaga = injectSaga({
  key: 'dataContainerNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(DataContainerNodeViewCardLayout)
