import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Color from 'color'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class TextLink extends React.PureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      base: {
        fontWeight: 'inherit',
        cursor: props.disabled ? 'default' : 'pointer',
        transition: 'color 0.1s ease-out',
      },
    }

    this.state = {
      hovered: false,
    }
  }

  endHover = () => {
    this.setState({
      hovered: false,
    })
  }

  startHover = () => {
    this.setState({
      hovered: true,
    })
  }

  getInvertedColor = () => {
    if (this.props.disabled) {
      return this.props.theme.neutralLight
    }

    if (this.state.hovered) {
      return this.props.theme.primary
    }

    return this.props.theme.light
  }

  getColor = () => {
    if (this.props.disabled) {
      return this.props.theme.neutralMedium
    }

    if (this.state.hovered) {
      if (this.props.color === 'primary') {
        return Color(this.props.theme.primary).darken(0.6)
      }

      if (this.props.color === 'secondary') {
        return Color(this.props.theme.secondary).darken(0.6)
      }
      if (this.props.color === 'warning') {
        return Color(this.props.theme.red).darken(0.6)
      }
    }

    if (this.props.color === 'primary') {
      return this.props.theme.primary
    }

    if (this.props.color === 'secondary') {
      return this.props.theme.secondary
    }
    if (this.props.color === 'warning') {
      return this.props.theme.red
    }
  }

  render() {
    if (this.props.to) {
      return (
        <Link
          to={this.props.to}
          target={this.props.newTab ? '_blank' : undefined}
          onMouseEnter={this.startHover}
          onMouseLeave={this.endHover}
          style={{
            ...this.styles.base,
            color: this.props.invert
              ? this.getInvertedColor()
              : this.getColor(),
            ...this.props.style,
          }}
        >
          {this.props.children}
        </Link>
      )
    }

    const {
      children,
      style,
      theme, //eslint-disable-line no-unused-vars
      typeColors, //eslint-disable-line no-unused-vars
      onClick,
      disabled,
      invert,
      ...pprops
    } = this.props

    return (
      <a
        onMouseEnter={this.startHover}
        onMouseLeave={this.endHover}
        style={{
          ...this.styles.base,
          color: invert ? this.getInvertedColor() : this.getColor(),
          ...style,
        }}
        onClick={disabled ? undefined : onClick}
        {...pprops}
      >
        {children}
      </a>
    )
  }
}

TextLink.defaultProps = {
  theme: {},
  style: {},
}

TextLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  invert: PropTypes.bool,
  theme: themeShape,
  typeColors: PropTypes.object,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.any,
  }),
  style: PropTypes.object,
}

export default themeable(TextLink)
