import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectComputeNodeViewCardLayoutDomain = state =>
  state.get('computeNodeViewCardLayout') || Map()

export const selectComputeContent = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('computeContent') || Map()
)

export const selectIsLoading = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)

export const selectInboundPublicFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('inboundPublicFilters', Map())
)

export const selectLoadingInboundPublicFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingInboundPublicFilters')
)

export const selectInboundPrivateFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('inboundPrivateFilters') || Map()
)

export const selectLoadingInboundPrivateFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingInboundPrivateFilters')
)

export const selectInboundPublicPath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('inboundPublicPath') || Map()
)

export const selectLoadingInboundPublicPath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingInboundPublicPath')
)

export const selectInboundPrivatePath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('inboundPrivatePath') || Map()
)

export const selectLoadingInboundPrivatePath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingInboundPrivatePath')
)

export const selectOutboundPublicFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('outboundPublicFilters') || Map()
)

export const selectLoadingOutboundPublicFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingOutboundPublicFilters')
)

export const selectOutboundPrivateFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('outboundPrivateFilters') || Map()
)

export const selectLoadingOutboundPrivateFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingOutboundPrivateFilters')
)

export const selectOutboundPublicPath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('outboundPublicPath') || Map()
)

export const selectLoadingOutboundPublicPath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingOutboundPublicPath')
)

export const selectOutboundPrivatePath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('outboundPrivatePath') || Map()
)

export const selectLoadingOutboundPrivatePath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingOutboundPrivatePath')
)

export const selectIndirectInboundPublicFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('indirectInboundPublicFilters') || Map()
)

export const selectLoadingIndirectInboundPublicFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingIndirectInboundPublicFilters')
)

export const selectIndirectInboundPrivateFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('indirectInboundPrivateFilters') || Map()
)

export const selectLoadingIndirectInboundPrivateFilters = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingIndirectInboundPrivateFilters')
)

export const selectIndirectInboundPublicPath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('indirectInboundPublicPath') || Map()
)

export const selectLoadingIndirectInboundPublicPath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingIndirectInboundPublicPath')
)

export const selectIndirectInboundPrivatePath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('indirectInboundPrivatePath') || Map()
)

export const selectLoadingIndirectInboundPrivatePath = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingIndirectInboundPrivatePath')
)

export const selectSecurityGroups = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('securityGroups') || Map()
)

export const selectLoadingSecurityGroups = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('loadingSecurityGroups')
)

export const selectErrorSecurityGroups = createSelector(
  selectComputeNodeViewCardLayoutDomain,
  state => state.get('errorSecurityGroups')
)
