import { defineMessages } from 'react-intl'

export default defineMessages({
  accessKeyLabel: {
    id: 'app.containers.ActionNodeViewCardLayout.roleLabel',
    defaultMessage: 'AccessKey',
  },
  accountUserLabel: {
    id: 'app.containers.ActionNodeViewCardLayout.roleLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id: 'app.container.NodeViewLayouts.ActionNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  nodeViewType: {
    id: 'app.container.NodeViewLayouts.ActionNodeViewCardLayout.action',
    defaultMessage: 'Action',
  },
  regionLabel: {
    id: 'app.container.NodeViewLayouts.ActionNodeViewCardLayout.regionLabel',
    defaultMessage: 'Region',
  },
  sourceIPLabel: {
    id: 'app.container.NodeViewLayouts.ActionNodeViewCardLayout.sourceIPLabel',
    defaultMessage: 'Source IP',
  },
  typeLabel: {
    id: 'app.container.NodeViewLayouts.ActionNodeViewCardLayout.typeLabel',
    defaultMessage: 'Type',
  },
  succeededLabel: {
    id: 'app.container.NodeViewLayouts.ActionNodeViewCardLayout.succeededLabel',
    defaultMessage: 'Succeeded',
  },
  userAgentLabel: {
    id: 'app.container.NodeViewLayouts.ActionNodeViewCardLayout.userAgentLabel',
    defaultMessage: 'User Agent',
  },
  eventNameLabel: {
    id: 'app.container.NodeViewLayouts.ActionNodeViewCardLayout.eventNameLabel',
    defaultMessage: 'Event Name',
  },
  performedByTitle: {
    id:
      'app.container.NodeViewLayouts.ActionNodeViewCardLayout.performedByTitle',
    defaultMessage: 'Performed By',
  },
  performedOnTitle: {
    id:
      'app.container.NodeViewLayouts.ActionNodeViewCardLayout.performedOnTitle',
    defaultMessage: 'Performed On',
  },
  actionTypesTitle: {
    id:
      'app.container.NodeViewLayouts.ActionNodeViewCardLayout.actionTypesTitle',
    defaultMessage: 'Action Types',
  },
  accountLabel: {
    id: 'app.containers.ActionNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  eventDetailsTitle: {
    id: 'app.containers.ActionNodeViewCardLayout.eventDetailsTitle',
    defaultMessage: 'Event Details',
  },
  firstEventDetailsTitle: {
    id: 'app.containers.ActionNodeViewCardLayout.firstEventDetailsTitle',
    defaultMessage: 'First Event Details',
  },
  firstEventDetailsTooltip: {
    id: 'app.containers.ActionNodeViewCardLayout.firstEventDetailsTooltip',
    defaultMessage:
      'First event in this series of coalesced events. Sonrai coalesces events based on: performed by, access key, event name, granted access key, user agent, succeeded',
  },
  originalIdentityLabel: {
    id: 'app.containers.ActionNodeViewCardLayout.originalIdentityLabel',
    defaultMessage: 'Original Identity',
  },
  performedByUser: {
    id: 'app.containers.ActionNodeViewCardLayout.performedByUser',
    defaultMessage: 'This action was performed by {type}',
  },
  performedByUserAndRole: {
    id: 'app.containers.ActionNodeViewCardLayout.performedByUserAndRole',
    defaultMessage: 'This action was performed by {type} that assumed Role',
  },
  roleLabel: {
    id: 'app.containers.ActionNodeViewCardLayout.roleLabel',
    defaultMessage: 'Role',
  },
})
