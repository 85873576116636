import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NodeViewTab from './NodeViewTab'
import IconCount from 'components/IconCount'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class NodeViewTabs extends Component {
  constructor(props) {
    super(props)

    const child = this.findChild((child, index) => index === props.activeTabId)

    this.state = {
      activeTab:
        child && child.props
          ? child.props.label
          : (this.findChild(ele => ele && ele.props && ele.props.label) || {})
              .label,
    }

    this.styles = {
      tabContainer: {
        borderBottom: '1px solid #ccc',
        padding: '0rem 1.4rem',
      },
    }
  }

  findChild = predicate => {
    let match = undefined
    React.Children.forEach(this.props.children, (child, index) => {
      if (!match && predicate(child, index)) {
        match = child
      }
    })

    return match
  }

  onSelectTab = tab => {
    this.setState({ activeTab: tab })
  }

  render() {
    const {
      onSelectTab,
      props: { children },
      state: { activeTab },
    } = this

    return (
      <div style={this.props.style}>
        <div>
          <div style={this.styles.tabContainer}>
            {React.Children.map(children, child => {
              if (!child) {
                return null
              }

              const { label, notification, notificationTitle } = child.props
              if (!label) {
                return null
              }
              return (
                <NodeViewTab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={onSelectTab}
                >
                  {notification ? (
                    <IconCount
                      title={notificationTitle}
                      color={this.props.theme.fail}
                      text={notification}
                    />
                  ) : null}
                </NodeViewTab>
              )
            }).filter(child => child)}
          </div>
        </div>
        <div style={this.props.tabStyle}>
          {React.Children.map(children, child => {
            if (!child) {
              return null
            }

            if (child.props.label !== activeTab) return undefined
            return child.props.children
          }).filter(child => child)}
        </div>
      </div>
    )
  }
}

NodeViewTabs.defaultProps = {
  activeTabId: 0,
  tabStyle: { overflow: 'auto' },
}

NodeViewTabs.propTypes = {
  children: PropTypes.array,
  activeTabId: PropTypes.number,
  style: PropTypes.object,
  tabStyle: PropTypes.object,
  theme: themeShape,
}

export default themeable(NodeViewTabs)
