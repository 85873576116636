import { createAction } from 'redux-actions'
import {
  GET_DATA,
  GET_DATA_SUCCESS,
  SET_SWIMLANE_DATA_LOADING,
  GET_SWIMLANE_DATA_SUCCESS,
  SET_SWIMLANE_DATA_FAILURE,
  GET_CRM_DATA_SUCCESS,
  REMAINING_TAG_SEARCH,
  CLEAR_DATA,
  GET_TAG_OPTIONS,
  GET_TAG_OPTIONS_SUCCESS,
  SET_RESOURCE_MONITORING,
  TOGGLE_RESOURCE_MONITORING,
  GET_TICKET_COUNT,
  SET_TICKET_COUNT,
  SET_SHOULD_UPDATE_NODE_SWIMLANES,
  RELOAD_SWIMLANES,
  FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR,
  FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR_SUCCESS,
  FETCH_UBER_POLICY,
  FETCH_UBER_POLICY_ERROR,
  FETCH_UBER_POLICY_SUCCESS,
  GET_CRM_TICKETS,
  SET_CRM_TICKETS,
} from './constants'

export const getData = createAction(GET_DATA)

export const reloadSwimlanes = createAction(RELOAD_SWIMLANES)

export const getDataSuccess = createAction(GET_DATA_SUCCESS)

export const setSwimlaneDataLoading = createAction(SET_SWIMLANE_DATA_LOADING)

export const getSwimlaneDataSucces = createAction(GET_SWIMLANE_DATA_SUCCESS)

export const setSwimlaneDataFailure = createAction(SET_SWIMLANE_DATA_FAILURE)

export const getCrmDataSuccess = createAction(GET_CRM_DATA_SUCCESS)

export const remainingTagSearch = createAction(REMAINING_TAG_SEARCH)

export const clearData = createAction(CLEAR_DATA)

export const getTagOptions = createAction(GET_TAG_OPTIONS)

export const getTagOptionsSuccess = createAction(GET_TAG_OPTIONS_SUCCESS)

export const setResourceMonitoring = createAction(SET_RESOURCE_MONITORING)
export const toggleResourceMonitoring = createAction(TOGGLE_RESOURCE_MONITORING)

export const getTicketCount = createAction(GET_TICKET_COUNT)
export const setTicketCount = createAction(SET_TICKET_COUNT)
export const setShouldUpdateNodeSwimlanes = createAction(
  SET_SHOULD_UPDATE_NODE_SWIMLANES
)

export const fetchEffectivePermissionsForActor = createAction(
  FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR
)
export const fetchEffectivePermissionsForActorSuccess = createAction(
  FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR_SUCCESS
)

export const fetchUberPolicy = createAction(FETCH_UBER_POLICY)
export const fetchUberPolicyError = createAction(FETCH_UBER_POLICY_ERROR)
export const fetchUberPolicySuccess = createAction(FETCH_UBER_POLICY_SUCCESS)

export const getCrmTickets = createAction(GET_CRM_TICKETS)
export const setCrmTickets = createAction(SET_CRM_TICKETS)
