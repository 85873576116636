import React from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from './ThemeContext'
import { getDisplayName } from 'utils/hocHelpers'

export default WrappedComponent => {
  return class extends React.PureComponent {
    displayName = `Themeable(${getDisplayName(WrappedComponent)})`

    render() {
      return (
        <ThemeContext.Consumer>
          {({ theme, typeColors }) => (
            <WrappedComponent
              {...this.props}
              theme={theme}
              typeColors={typeColors}
            />
          )}
        </ThemeContext.Consumer>
      )
    }
  }
}

export const themeShape = PropTypes.shape({
  primary: PropTypes.string,
  secondary: PropTypes.string,
  emphasis: PropTypes.string,
  light: PropTypes.string,
  dark: PropTypes.string,
  highlight: PropTypes.string,
  neutralLight: PropTypes.string,
  neutralMedium: PropTypes.string,
  neutralDark: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  success: PropTypes.string,
  fail: PropTypes.string,
  bodyText: PropTypes.string,
  background: PropTypes.string,
})
