import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Input } from 'reactstrap'
import { Map, List } from 'immutable'
import _ from 'lodash'
import Select from 'react-select'

import permissionChecker from 'containers/PermissionChecker'
import CloudAccount from 'components/CloudAccount'
import FormLabel from 'components/FormLabel'
import MarkdownEditor from 'components/MarkdownEditor'
import TextLink from 'components/TextLink'
import UserWidget from 'components/UserWidget'
import FormErrorMessage from './components/FormErrorMessage'

function getEscalationOptions(props) {
  const selected = (props.selectedSwimlanes || []).map(item => item.value)
  const options = props.escalations
    .filter(escalation => {
      const assignmentSRNs = (
        escalation.get('assignments') || List()
      ).map(item => item.get('swimlaneSRN'))
      return !!assignmentSRNs.find(srn => selected.includes(srn))
    })
    .toList()
    .toJS()
    .map(item => ({ label: item.title || item.srn, value: item.srn }))
  return _.sortBy(options, option => (option.label ?? '').toLowerCase())
}

export function DefaultFields(props) {
  const styles = {
    assignToContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0.5rem 0rem 0rem 0rem',
    },
  }

  return (
    <div>
      <div>
        <FormLabel style={{ margin: '0rem' }} required>
          Title
        </FormLabel>
        <Input
          placeholder="Add Title..."
          name="ticketTitle"
          value={props.title}
          onChange={({ target: { value } }) => props.handleTitleChange(value)}
        />
        {props.formErrors.title && (
          <FormErrorMessage error={props.formErrors.title} />
        )}
      </div>
      <div>
        <FormLabel>Description</FormLabel>
        <MarkdownEditor
          onChange={value => props.handleDescriptionChange(value)}
          value={props.description}
        />
      </div>
      <div>
        <FormLabel required>Swimlanes</FormLabel>
        <Select
          isClearable
          placeholder={'Select Swimlane(s)...'}
          options={_.sortBy(
            (props.swimlanes || Map())
              .toList()
              .toJS()
              .filter(swimlane =>
                props.userHasPermission({
                  permissionName: 'edit.tickets',
                  swimlanes: [swimlane.srn],
                })
              )
              .map(swimlane => ({
                label: swimlane.title || swimlane.srn,
                value: swimlane.srn,
              })),
            option => (option.label ?? '').toLowerCase()
          )}
          value={
            props.selectedSwimlanes &&
            !_.isEmpty(props.selectedSwimlanes) &&
            props.selectedSwimlanes
          }
          onChange={props.handleSwimlaneChange}
          isMulti
          isDisabled={!!props.resource}
        />
        {props.formErrors.swimlanes && (
          <FormErrorMessage error={props.formErrors.swimlanes} />
        )}
      </div>
      {!props.resource && (
        <div>
          <FormLabel required>Account</FormLabel>
          <Select
            onChange={props.handleAccountChange}
            options={_.sortBy(
              (props.accounts ?? List())
                .map(account => ({
                  label:
                    account.get('friendlyName') ||
                    account.get('name') ||
                    account.get('account'),
                  value: account.get('srn'),
                }))
                .toJS(),
              option => (option.label ?? '').toLowerCase()
            )}
            value={props.account}
            formatOptionLabel={option => {
              return <CloudAccount accountSrn={option.value} />
            }}
          />
          {props.formErrors.account && (
            <FormErrorMessage error={props.formErrors.account} />
          )}
        </div>
      )}
      <div>
        <FormLabel required>Severity</FormLabel>
        <Select
          placeholder="Select Severity..."
          isClearable
          options={[
            { label: 'Low', value: 'LOW' },
            { label: 'Medium', value: 'MEDIUM' },
            { label: 'High', value: 'HIGH' },
          ]}
          value={props.severity}
          onChange={props.handleSeverityChange}
        />
        {props.formErrors.severity && (
          <FormErrorMessage error={props.formErrors.severity} />
        )}
      </div>
      <div>
        <div style={styles.assignToContainer}>
          <FormLabel>Assign To</FormLabel>
          <TextLink
            style={{ padding: '0.5rem 0rem 0rem 0rem' }}
            color="primary"
            onClick={props.handleSelfAssign}
            disabled={
              props.assignedTo && props.assignedTo.value === props.currentUser
            }
          >
            {' '}
            Assign to me{' '}
          </TextLink>
        </div>
        <Select
          placeholder="Assign To..."
          isClearable
          options={_.sortBy(
            props.users
              .toList()
              .toJS()
              .map(item => ({ label: item.name || item.srn, value: item.srn })),
            option => (option.label ?? '').toLowerCase()
          )}
          value={props.assignedTo}
          formatOptionLabel={option => (
            <UserWidget srn={option.value} noLink table />
          )}
          onChange={props.handleAssignedToChange}
        />
      </div>
      <div>
        <FormLabel>Escalation Scheme</FormLabel>
        <Select
          isClearable
          placeholder="Select Escalation Scheme..."
          options={getEscalationOptions(props)}
          isLoading={props.escalationsLoading}
          isDisabled={!props.selectedSwimlanes}
          value={props.escalation}
          onChange={props.handleEscalationChange}
        />
      </div>
      {props.resource && (
        <div>
          <FormLabel>Resource</FormLabel>
          <Select
            isDisabled
            value={{
              label:
                props.resource.friendlyName ||
                props.resource.name ||
                props.resource.srn,
              value: props.resource.srn,
            }}
          />
        </div>
      )}
    </div>
  )
}

DefaultFields.propTypes = {
  account: PropTypes.object,
  accounts: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      srn: PropTypes.string.isRequired,
      account: PropTypes.string.isRequired,
    })
  ),
  handleAccountChange: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  resource: PropTypes.object,
  escalationsLoading: PropTypes.bool,
  escalation: PropTypes.object,
  handleEscalationChange: PropTypes.func.isRequired,
  handleAssignedToChange: PropTypes.func.isRequired,
  assignedTo: PropTypes.object,
  users: ImmutablePropTypes.iterable.isRequired,
  selectedSwimlanes: PropTypes.array,
  currentUser: PropTypes.string,
  handleSelfAssign: PropTypes.func.isRequired,
  handleSeverityChange: PropTypes.func.isRequired,
  severity: PropTypes.object,
  handleSwimlaneChange: PropTypes.func.isRequired,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  handleDescriptionChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  handleTitleChange: PropTypes.func.isRequired,
  userHasPermission: PropTypes.func.isRequired,
}

export default permissionChecker(DefaultFields)
