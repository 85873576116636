import React from 'react'
import PropTypes from 'prop-types'
import SizeMe from 'components/SizeMe'
import Masonry from 'react-masonry-component'

import { getBreakpoint, getLayoutForChild } from './utils'
export class AutoLayout extends React.Component {
  styles = {}
  masonry = null

  constructor(props) {
    super(props)
  }

  getNumberOfCols = () => {
    const breakpoint = this.getBreakpoint()
    return this.props.cols[breakpoint]
  }

  getBreakpoint = () => {
    return getBreakpoint({
      width: this.props.size.width,
      breakpoints: this.props.breakpoints,
    })
  }

  getLayoutForChild = child => {
    return getLayoutForChild({
      width: this.props.size.width,
      breakpoints: this.props.breakpoints,
      child,
      layouts: this.props.layouts,
    })
  }

  recalculateGrid = () => {
    if (this.masonry) {
      this.masonry.layout()
    }
  }

  render() {
    const colWidth = this.props.size.width / this.getNumberOfCols()

    return (
      <div style={{ width: '100%' }}>
        <Masonry
          key={this.props.size.width}
          ref={c => {
            this.masonry = c ? c.masonry : null
          }}
          options={{
            gutter: 0,
            columnWidth: colWidth,
            fitWidth: true,
          }}
        >
          {React.Children.map(this.props.children, child => {
            const childLayout = this.getLayoutForChild(child)

            const width = childLayout.w * colWidth
            if (childLayout.h) {
              const height = childLayout.h * this.props.rowHeight

              return (
                <div
                  style={{
                    width: `${width}px`,
                    display: 'inline-block',
                    height: `${height}px`,
                    padding: '0.5em',
                  }}
                >
                  {React.cloneElement(child, {
                    style: { ...child.props.style, height: '100%' },
                    recalculateGrid: this.recalculateGrid,
                  })}
                </div>
              )
            } else {
              return (
                <div
                  style={{
                    width: `${width}px`,
                    display: 'inline-block',
                    padding: '0.5em',
                  }}
                >
                  {React.cloneElement(child, {
                    recalculateGrid: this.recalculateGrid,
                  })}
                </div>
              )
            }
          })}
        </Masonry>
      </div>
    )
  }
}

const layoutShape = PropTypes.shape({
  w: PropTypes.number.isRequired,
  h: PropTypes.number,
})

AutoLayout.defaultProps = {
  breakpoints: { lg: 1390, md: 1153, sm: 916, xs: 679 },
  cols: { lg: 8, md: 7, sm: 6, xs: 4 },
  rowHeight: 180,
}

AutoLayout.propTypes = {
  breakpoints: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
  }),
  children: PropTypes.node,
  layouts: PropTypes.objectOf(
    PropTypes.shape({
      lg: layoutShape,
      md: layoutShape,
      sm: layoutShape,
      xs: layoutShape,
    })
  ),
  cols: PropTypes.object,
  rowHeight: PropTypes.number,
  size: PropTypes.shape({
    width: PropTypes.number,
  }).isRequired,
}

export default SizeMe(AutoLayout)
