import React, { Fragment } from 'react'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SubmitFormBaseComponent, {
  DefaultFieldControlContainer,
  RadioInstructionMessage,
} from './SubmitFormBaseComponent'

import messages from '../messages'

export default class SubmitFormOutputMode extends SubmitFormBaseComponent {
  renderTitleText = () => {
    return <DynamicFormattedMessage {...messages.submitFormOutputLabel} />
  }

  renderHelpContent = () => {
    return (
      <Fragment>
        <span>
          <DynamicFormattedMessage {...messages.submitFormOutputInstructions} />
        </span>
      </Fragment>
    )
  }

  setOutputMode = event => {
    this.props.setFormFields({ outputMode: event.target.value })
  }

  renderFieldControls = () => {
    return (
      <DefaultFieldControlContainer>
        <div>
          <label style={{ paddingLeft: '8px' }}>
            {' '}
            <input
              type="radio"
              name="outputMode"
              value="FINGERPRINT"
              checked={this.props.formValues.outputMode === 'FINGERPRINT'}
              onClick={this.setOutputMode}
              style={{ marginRight: '8px' }}
            />
            <DynamicFormattedMessage
              {...messages.submitFormOutputFingerLabel}
            />{' '}
            (
            <DynamicFormattedMessage {...messages.submitFormOutputRecommend} />)
            {' - '}
            <RadioInstructionMessage>
              <DynamicFormattedMessage
                {...messages.submitFormOutputFingerInstructions}
              />
            </RadioInstructionMessage>
          </label>
        </div>
        <div>
          <label style={{ paddingLeft: '8px' }}>
            {' '}
            <input
              type="radio"
              name="outputMode"
              value="OBJECT"
              checked={this.props.formValues.outputMode === 'OBJECT'}
              onClick={this.setOutputMode}
              style={{ marginRight: '8px' }}
            />
            <DynamicFormattedMessage {...messages.submitFormOutputDOLabel} />
            {' - '}
            <RadioInstructionMessage>
              <DynamicFormattedMessage
                {...messages.submitFormOutputDOInstructions}
              />
            </RadioInstructionMessage>
          </label>
        </div>
      </DefaultFieldControlContainer>
    )
  }
}
