import isIp from 'is-ip'
import moment from 'moment'
import qs from 'query-string'
import _ from 'lodash'
import pluralizeLib from 'pluralize'
import numeral from 'numeral'
import Immutable, { Map, List, isImmutable, fromJS } from 'immutable'
import Papa from 'papaparse'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import color from 'color'

import {
  NODE_VIEW_TYPES,
  SONRAI_MAX_COUNT,
  TYPES_WITH_CRM,
  SWIMLANE_SCOPED_PERMISSIONS,
  DEFAULT_SEARCH_LIMIT,
} from 'appConstants'

export const mapPoliciesToControlFrameworks = (
  alerts,
  policies,
  controlGroups
) => {
  const policiesDuplicatedByCf = []

  const policyAlertsByCF = alerts.map(alert => {
    if (alert.ticketType === 'Framework') {
      alert.controlFrameworkName = controlGroups.getIn([
        alert.ticketKey,
        'title',
      ])
      alert.controlFrameworkId = alert.ticketKey

      return alert
    }

    if (alert.ticketType !== 'Policy') {
      return alert
    }

    const policyId = alert.ticketKey
    const policy = policies.get(policyId, Map())
    const controlFrameworks = policy
      .getIn(['containedByControlFramework', 'items'], List())
      .map(cf => controlGroups.get(cf.get('srn'), Map()))
      .filterNot(cf => cf.isEmpty())

    alert.policyName = policy.get('title')
    alert.controlFrameworkName = controlFrameworks.first(Map()).get('title')
    alert.controlFrameworkId = controlFrameworks.first(Map()).get('srn')

    if (controlFrameworks.size > 1) {
      controlFrameworks.shift().forEach(cf => {
        const duplicateAlert = { ...alert }
        duplicateAlert.controlFrameworkName = cf.get('title')
        duplicateAlert.controlFrameworkId = cf.get('srn')
        policiesDuplicatedByCf.push(duplicateAlert)
      })
    }

    return alert
  })

  return policyAlertsByCF.concat(policiesDuplicatedByCf)
}

export const getMultiValuesFromString = inputValue => {
  const parsed = Papa.parse(inputValue, {
    quoteChar: '"',
    escapeChar: '"',
    delimitersToGuess: [',', '\t', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
  }).data

  if (inputValue.match('\r\n|\n|\r')) {
    return [].concat.apply([], parsed)
  }

  if (Array.isArray(parsed) && parsed.length > 0 && parsed[0].length > 1) {
    return parsed[0]
  } else {
    return [inputValue]
  }
}

export const sortCards = cards => {
  let kards = [...cards]

  let newCardOrder = [
    _.remove(kards, function (card) {
      return card.name === 'Overview' || card.text === 'Overview'
    })[0],
    _.remove(kards, function (card) {
      return card.name === 'Identity' || card.text === 'Identity'
    })[0],
    _.remove(kards, function (card) {
      return card.name === 'Data' || card.text === 'Data'
    })[0],
    _.remove(kards, function (card) {
      return card.name === 'Activity' || card.text === 'Activity'
    })[0],
    _.remove(kards, function (card) {
      return card.name === 'Review' || card.text === 'Review'
    })[0],
    _.remove(kards, function (card) {
      return (
        card.name === '__sonrai-alerts0' || card.text === '__sonrai-alerts0'
      )
    })[0],
    ...kards.sort((a, b) => {
      const aDate = a.createdDate ? moment(a.createdDate) : moment(a.date)
      const bDate = b.createdDate ? moment(b.createdDate) : moment(b.date)
      return aDate.isAfter(bDate)
    }),
  ]
  return _.compact(newCardOrder)
}

export const exists = value => {
  if (value === null) {
    return false
  }

  if (value === undefined) {
    return false
  }

  if (value === '') {
    return false
  }

  return true
}

export const isPrimitiveValue = value => {
  return (
    value === null ||
    value === undefined ||
    typeof value === 'string' ||
    typeof value === 'boolean' ||
    typeof value === 'number'
  )
}

export const processPrimitiveCellValue = cellValue => {
  if (typeof cellValue === 'boolean') {
    return `${cellValue}`
  } else if (cellValue === null) {
    return '-'
  } else if (typeof cellValue === 'string') {
    return cellValue
  }

  return !isNaN(parseInt(cellValue)) && !isIp(cellValue)
    ? parseInt(cellValue)
    : cellValue
}

export const addSubItems = (value, key = '', noTypePrepend) => {
  if (key && Array.isArray(value) && !key.includes('items')) {
    value = value.join(', ')
  }

  if (isPrimitiveValue(value)) {
    const obj = {}
    obj[key] = processPrimitiveCellValue(value)
    return [obj]
  }

  if (Array.isArray(value)) {
    const uniqueTypes = new Set() //eslint-disable-line no-restricted-globals
    for (var i = 0; i < value.length; i++) {
      const val = value[i]
      if (key.includes('EdgeRelation_items')) {
        val.__typename = key.replace('EdgeRelation_items', '')
      }
      uniqueTypes.add(val.__typename)
      if (uniqueTypes.size > 1) {
        // only iterate until we find some unique types
        break
      }
    }

    let newRows = []
    const items = value
    items.forEach(item => {
      const processedResult = addSubItems(item, key, uniqueTypes.size > 1)
      processedResult.forEach(r => newRows.push(r))
    })

    return newRows
  }

  //Value is an Object

  let masterRow = {}
  let newRows = []
  const fieldNames = Object.keys(value)

  fieldNames.forEach(fieldName => {
    const columnName =
      value.__typename && !noTypePrepend
        ? `${value.__typename}_${fieldName}`
        : fieldName

    const processedResult = addSubItems(value[fieldName], columnName)

    //the results was a property query with only one element, which is treated as a new column
    //   E.G. "[{ RegionName: 'ca-central-1' }]"
    if (processedResult.length === 1) {
      Object.assign(masterRow, processedResult[0])
      newRows.forEach(rowVals => Object.assign(rowVals, processedResult[0]))
    } else if (newRows.length === 0) {
      newRows = processedResult.map(result =>
        Object.assign({}, masterRow, result)
      )
    } else {
      //Processsing of a list of results, in which case we need to create new, semi-duplicated rows
      //   E.G. [
      //          {RepositoryTagKey: 'aws:cloudformation:stack-id',},
      //          {RepositoryTagKey: 'aws:cloudformation:stack-name',},
      //          {RepositoryTagKey: 'aws:cloudformation:logical-id',},
      //        ]

      const initialRowData = _.cloneDeep(newRows)
      newRows = []

      processedResult.forEach(result => {
        initialRowData.forEach(rowVals => Object.assign(rowVals, result))
        newRows = newRows.concat(initialRowData)
      })
    }
  })

  if (newRows.length === 0) {
    //No new rows were generated - data was always just plain properties
    newRows.push(masterRow)
  }

  return newRows
}

export const flattenData = data => {
  const flattenedData = addSubItems(data)

  let template = new Set() //eslint-disable-line no-restricted-globals
  flattenedData.forEach(row => {
    Object.keys(row).forEach(key => template.add(key))
  })

  return flattenedData.map(row => {
    let newRow = {}
    Array.from(template.values()).forEach(prop => {
      //add default values for any missing properties
      if (exists(row[prop])) {
        newRow[prop] = row[prop]
      } else {
        newRow[prop] = '-'
      }
    })
    return newRow
  })
}

export const possibleIdentities = [
  'mfadevice',
  'organization',
  'samlprovider',
  'identityprovider',
  'activedirectoryapplication',
]

export const getIdentityPath = type => {
  if (possibleIdentities.includes(type)) {
    return 'identity'
  } else {
    return 'generic'
  }
}

export const getNodeViewPushParams = (nodeId, type = '') => {
  let typeLow = type.toLowerCase()

  let pathpart = ''
  if (typeLow === NODE_VIEW_TYPES.USER) {
    pathpart = 'user'
  } else if (typeLow === NODE_VIEW_TYPES.DATA_OBJECT) {
    pathpart = 'data'
  } else if (typeLow === NODE_VIEW_TYPES.CONTAINER) {
    pathpart = 'datacontainer'
  } else if (typeLow === NODE_VIEW_TYPES.ACCOUNT) {
    pathpart = 'account'
  } else if (typeLow === NODE_VIEW_TYPES.PUBLIC_KEY) {
    pathpart = 'publickey'
  } else if (typeLow === NODE_VIEW_TYPES.POLICY) {
    pathpart = 'policy'
  } else if (typeLow === NODE_VIEW_TYPES.IMAGE) {
    pathpart = 'image'
  } else if (typeLow === NODE_VIEW_TYPES.COMPUTE) {
    pathpart = 'compute'
  } else if (typeLow === NODE_VIEW_TYPES.ACTION_TYPE) {
    pathpart = 'actiontype'
  } else if (typeLow === NODE_VIEW_TYPES.ACTION) {
    pathpart = 'action'
  } else if (typeLow === NODE_VIEW_TYPES.GROUP) {
    pathpart = 'group'
  } else if (typeLow === NODE_VIEW_TYPES.AUDIT) {
    pathpart = 'audit'
  } else if (typeLow === NODE_VIEW_TYPES.ROLE) {
    pathpart = 'role'
  } else if (typeLow === NODE_VIEW_TYPES.SECRET_STORE) {
    pathpart = 'secretstore'
  } else if (typeLow === NODE_VIEW_TYPES.SECRET) {
    pathpart = 'secret'
  } else {
    pathpart = getIdentityPath(typeLow)
  }

  return {
    pathname: `/App/SolutionCenter/Node/${pathpart}/`,
    search: qs.stringify({
      nodeId: nodeId,
    }),
  }
}

export const groupNodeViewDetails = (fields, noCoalesce) => {
  const generalDetails = [
    'active',
    'name',
    'type',
    'label',
    'account',
    'aliasSet',
    'createdDate',
    'srn',
    'resourceId',
    'timestamp',
    'modifiedDate',
    'isOwnedBy',
    'sid',
  ]
  const locationDetails = [
    'region',
    'latitude',
    'longitude',
    'country',
    'performedAtCityValues',
    'performedAtCountryValues',
    'availabilityZone',
  ]
  const actionProperties = [
    'sessionDate',
    'accessKey',
    'grantedActionKey',
    'sessionPrincipal',
    'grantedSessionPrincipal',
    'grantedSessionDate',
    'sharedEventId',
    'hasServiceClassificationValue',
    'eventName',
    'performedByLabel',
    'service',
    'performedByValue',
    'succeeded',
    'grantedAccessKey',
    'performedOnValue',
    'performedOnLabel',
    'srcIPs',
    'userAgent',
  ]
  const coalescedProperties = [
    'srcIPs',
    'userAgents',
    'regions',
    'coalescedCount',
    'performedAtCountryValues',
    'performedAtCityValues',
  ]

  const computeProperties = [
    'architecture',
    'ebsOptimized',
    'launchTime',
    'runState',
    'latestRestorableTime',
  ]

  const dataProperties = [
    'encryptionEnabled',
    'publicRead',
    'publicWrite',
    'versioningEnabled',
    'classificationSet',
  ]

  const groups = {
    action: [],
    coalesced: [],
    compute: [],
    general: [],
    location: [],
    other: [],
    data: [],
    hidden: [],
  }

  fields.forEach(field => {
    if (generalDetails.includes(field.name)) {
      groups.general.push(field)
    } else if (locationDetails.includes(field.name)) {
      groups.location.push(field)
    } else if (actionProperties.includes(field.name)) {
      groups.action.push(field)
    } else if (computeProperties.includes(field.name)) {
      groups.compute.push(field)
    } else if (coalescedProperties.includes(field.name)) {
      if (noCoalesce) {
        groups.action.push(field)
      } else {
        groups.coalesced.push(field)
      }
    } else if (dataProperties.includes(field.name)) {
      groups.data.push(field)
    } else {
      groups.other.push(field)
    }
  })

  return groups
}

export const buildAzureResourceLink = (srn, resourceId, type, label, meta) => {
  // this method is a bit clunk but gets the job done
  // do not make fun of the += strings either i know u gon say something i AINT EVEN GOTTA LOOK
  // ?v=0qZwwyHvCbo

  const isStorage = [
    'BlockBlob',
    'Blob',
    'File',
    'BlockStorage',
    'DataContainer',
    'DataObject',
    'FileShare',
    'KeyStore',
    'Secret',
    'SecretStore',
  ]

  let sub = getSubscriptionFromSrn(resourceId)
  if (!sub) {
    sub = getSubscriptionFromSrn(srn)
  }

  let resource = getResourceGroupFromSrn(resourceId)
  if (!resource) {
    resource = getResourceGroupFromSrn(srn)
  }

  let providers = getProvidersFromSrn(resourceId)
  if (!providers) {
    providers = getProvidersFromSrn(srn)
  }

  let link = 'https://portal.azure.com/#'

  if (label === 'ResourceGroup') {
    link += `@${getAccountFromSrn(
      srn
    )}/resource/subscriptions/${sub}/resourceGroups/${resource}/providers/Microsoft.ContainerService/managedClusters/${resource}/overview`
  } else if (label === 'User') {
    let appId = ''
    if (meta) {
      meta.forEach(dude => {
        if (
          dude.substring(dude.indexOf('.') + 1, dude.indexOf(':')) === 'appId'
        ) {
          appId = dude.substr(dude.indexOf(':') + 1)
        }
      })
    }
    if (type === 'ServicePrincipal' && appId) {
      link += `blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/appId/${appId}/isMSAApp/`
    } else {
      link += `blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Profile/userId/${getWhateverFromSrn(
        '/User/',
        srn
      )}/adminUnitObjectId/`
    }
  } else if (label === 'Group') {
    link += `blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Overview/groupId/${getWhateverFromSrn(
      '/Group/',
      srn
    )}`
  } else if (srn.search('/Subscription/') !== -1) {
    link += `@${getAccountFromSrn(srn)}/resource/subscriptions/${sub}/overview`
  } else if (isStorage.includes(type)) {
    link += `#blade/Microsoft_Azure_Storage/FileShareMenuBlade/overview/storageAccountId/%2Fsubscriptions%2F${sub}%2FresourceGroups%2F${resource}`
  } else {
    link += `@${getAccountFromSrn(
      srn
    )}/resource/subscriptions/${sub}/resourceGroups/${resource}/providers/${providers}/overview`
  }
  if (type === 'AzureAppGatewayListener') {
    link = `${link.substr(0, link.indexOf('/listener/'))}/overview`
  }
  return link
}
// https://console.aws.amazon.com/iam/home?region=us-east-1#/users/brad.peters
export const buildAwsResourceLink = ({
  srn,
  resourceId,
  type,
  label,
  region,
}) => {
  const baseUrl = `https://console.aws.amazon.com`

  if (label === 'User' && type === 'User') {
    return `${baseUrl}/iam/home#/users/${getNameFromSrn(srn)}`
  } else if (label === 'Group' && type === 'Group') {
    return `${baseUrl}/iam/home#/groups/${getNameFromSrn(srn)}`
  } else if (label === 'Role' && type === 'Role') {
    return `${baseUrl}/iam/home#/roles/${getNameFromSrn(srn)}`
  } else if (label === 'Compute') {
    if (type === 'EC2') {
      return `${baseUrl}/ec2/home?region=${region}#Home:`
    } else if (type === 'AWSLambda') {
      return `${baseUrl}/lambda/home?region=${region}#/functions/${getNameFromSrn(
        srn
      )}`
    } else {
      return null
    }
  } else if (label === 'DataContainer') {
    if (type === 'S3Bucket') {
      return `${baseUrl}/s3/buckets/${getNameFromSrn(srn)}/?region=${region}`
    }
  } else if (label === 'MFADevice') {
    if (type === 'Virtual') {
      return `${baseUrl}/iam/home?#/users/${getNameFromSrn(
        srn
      )}?section=security_credentials`
    }
  } else if (label === 'Policy') {
    if (type === 'ManagedPolicy') {
      return `${baseUrl}/iam/home?#/policies/${resourceId}`
    }
  } else if (label === 'IdentityProvider') {
    if (type === 'SAMLProvider') {
      return `${baseUrl}/iam/home?#/providers/${resourceId}`
    }
  } else if (label === 'Group') {
    if (type === 'Group') {
      return `${baseUrl}/iam/home#/groups/${getNameFromSrn(srn)}`
    }
  } else if (label === 'Snapshot') {
    if (type === 'BlockStorage') {
      return `${baseUrl}/ec2/v2/home?region=${region}#Snapshots:search=${getNameFromSrn(
        srn
      )}`
    } else if (type === 'Instance') {
      // return `${baseUrl}/ec2/home#/groups/${getNameFromSrn(srn)}`
    }
  } else if (label === 'Image') {
    if (type === 'AMI') {
      // this one is fudged because it cant tell if  ami is public or private
      // return `${baseUrl}/ec2/v2/home?region=${region}#Images:search=${getNameFromSrn(
      //   srn
      // )}`
    }
  }
  return null
}

export const getProvidersFromSrn = srn => {
  if (!srn) {
    return ''
  }
  const tag = '/providers/'
  let providers = ''
  if (srn.search(tag) !== -1) {
    providers = srn.substring(srn.indexOf(tag) + tag.length)
  }

  if (providers.search('/dnsrecords/') !== -1) {
    providers = providers.substr(
      0,
      providers
        .substr(
          0,
          providers.substr(0, providers.lastIndexOf('/')).lastIndexOf('/')
        )
        .lastIndexOf('/')
    )
  }
  return providers
}

export const getWhateverFromSrn = (whatever, srn) => {
  if (!srn) {
    return ''
  }
  let userId = ''
  if (srn.toLowerCase().search(whatever.toLowerCase()) !== -1) {
    userId = srn
      .toLowerCase()
      .substring(
        srn.toLowerCase().indexOf(whatever.toLowerCase()) + whatever.length
      )
    if (userId.search('/') !== -1) {
      userId = userId.substring(0, userId.indexOf('/'))
    }
  }
  return userId
}

export const getResourceGroupFromSrn = srn => {
  if (!srn) {
    return ''
  }
  const tag = '/resourcegroups/'
  const camelTag = '/resourceGroups/'
  let resourceGroups = ''
  if (resourceGroups === '' && srn.search(camelTag) !== -1) {
    resourceGroups = srn.substring(srn.indexOf(camelTag) + camelTag.length)
    resourceGroups = resourceGroups.substring(0, resourceGroups.indexOf('/'))
  } else if (srn.search(tag) !== -1) {
    resourceGroups = srn.substring(srn.indexOf(tag) + tag.length)
    if (resourceGroups.search('/') !== -1) {
      resourceGroups = resourceGroups.substring(0, resourceGroups.indexOf('/'))
    }
  }
  return resourceGroups
}

export const isCrmType = srn => {
  return TYPES_WITH_CRM.includes(getTypeFromSrn(srn).toLowerCase())
}

export const getCloudFromSrn = (srn = '') => {
  if (!srn) {
    return '-'
  }

  return srn.split(':')[1]
}

export const getAccountFromSrn = (srn = '') => {
  if (!srn || !srn.startsWith('srn')) {
    return '-'
  }

  if (srn.includes('srn:sonrai::')) {
    return '-'
  }

  return srn.split(':')[4].split('/')[0]
}

export const getAccountSrnFromId = (id, accounts) => {
  const account = accounts.find(
    account => account.get('srn').includes(id),
    null,
    Map()
  )
  return account.get('srn')
}

export const getNameFromSrn = (srn = '') => {
  if (!srn) {
    return ''
  }

  if (!srn.startsWith('srn')) {
    return srn
  }

  if (srn.startsWith('srn:sonrai::')) {
    const typeandname = srn.replace('srn:sonrai::', '').split('/')
    typeandname.splice(0, 1)
    return typeandname.join('/')
  }

  const allParts = srn.split(':')

  let accounttypeandname
  if (allParts.length < 5) {
    accounttypeandname = allParts[allParts.length - 1].split('/')
  } else {
    accounttypeandname = allParts[4].split('/')
  }

  if (accounttypeandname.length >= 4) {
    if (accounttypeandname[1] === 'User') {
      accounttypeandname.splice(0, 3)
    } else if (accounttypeandname[2] === 'AWSLambda') {
      accounttypeandname.splice(0, 4)
    } else {
      accounttypeandname.splice(0, 2)
    }
    return accounttypeandname.join('/')
  } else {
    return accounttypeandname[accounttypeandname.length - 1]
  }
}

export const getAccountIdFromAccountSrn = (srn = '') => {
  const str = srn.substring(srn.lastIndexOf('/') + 1)
  if (str.includes('k8')) {
    return str.replace('k8scluster', 'K8SCluster')
  }
  return str
}

export const getSubscriptionFromSrn = srn => {
  if (!srn) {
    return ''
  }
  const bigSub = '/Subscription/'
  const lilSub = '/subscriptions/'
  let subscriptionId = ''
  if (srn.search(bigSub) !== -1) {
    subscriptionId = srn.substring(srn.indexOf(bigSub) + bigSub.length)
    if (subscriptionId.search('/') !== -1) {
      subscriptionId = subscriptionId.substring(0, subscriptionId.indexOf('/'))
    }
  }
  if (srn.search(lilSub) !== -1) {
    subscriptionId = srn.substring(srn.indexOf(lilSub) + lilSub.length)
    subscriptionId = subscriptionId.substring(0, subscriptionId.indexOf('/'))
  }

  return subscriptionId
}

export const resultsLimitExceeded = data => {
  return _.get(data, 'items', []).length >= 500
}

export const pluralize = (string = '', count) => {
  if (exists(count) && count === 1) {
    return string
  }

  return pluralizeLib(string)
}

export const guidGen = () =>
  S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()

//Util for guidGen
const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)

//"The Bert Rocker Algorithm"
export const computeAvailableFilters = (type, queryRelations, queryTypes) => {
  const relations = queryRelations
  const graphQLTypes = queryTypes

  const typeRelationsExpanded = {}

  const putRelation = (otherType, name) => {
    if (typeRelationsExpanded[otherType] == undefined) {
      typeRelationsExpanded[otherType] = []
    }
    typeRelationsExpanded[otherType].push(name)
  }

  const getImplementingTypes = interfaceTypeName => {
    let implementingTypes = []
    for (let possibleType of graphQLTypes
      .get(interfaceTypeName)
      .get('possibleTypes')
      .toJS()) {
      if (possibleType.kind === 'INTERFACE') {
        implementingTypes.push.apply(
          implementingTypes,
          getImplementingTypes(possibleType.name)
        )
      } else {
        implementingTypes.push(possibleType.name)
      }
    }
    return implementingTypes
  }

  const putAllRelationsForType = (type, relations) => {
    for (let relation of relations) {
      let otherType = relation.to === type ? relation.from : relation.to
      let name = relation.name

      if (graphQLTypes.get(otherType).get('kind') === 'INTERFACE') {
        const implementingTypes = getImplementingTypes(otherType)
        for (let implementingType of implementingTypes) {
          putRelation(implementingType, name)
        }
      } else {
        putRelation(otherType, name)
      }
    }
  }

  const typeRelationsRaw = relations
    .find(rel => rel.get('type') === type, null, Map())
    .toJS()

  if (typeRelationsRaw.relations) {
    putAllRelationsForType(type, typeRelationsRaw.relations)
  }

  if (typeRelationsRaw.interfaces) {
    for (let interfaze of typeRelationsRaw.interfaces) {
      putAllRelationsForType(interfaze.type, interfaze.relations)
    }
  }

  return typeRelationsExpanded
}

export const sonraiCountDisplay = (count, hasSearchLimit) => {
  const formatNumber = number => numeral(number).format('0.[00]a')
  if (exists(count)) {
    if (count >= SONRAI_MAX_COUNT && !hasSearchLimit) {
      return `${formatNumber(SONRAI_MAX_COUNT)}+`
    } else {
      return formatNumber(count)
    }
  } else {
    return '_'
  }
}

export const truncateMiddle = (value = '', n = 25, charCount = 8) => {
  if (value.length <= n) {
    return value
  }

  return `${value.substr(0, charCount - 1)}\u2026${value.substr(
    value.length - charCount,
    value.length - 1
  )}`
}

export const getSearchIdForSonraiSearches = (options, sonraiSearches) => {
  const searches = {}
  if (options && sonraiSearches) {
    Object.keys(options.sonraiSearches).forEach(key => {
      searches[options.sonraiSearches[key]] = ''
    })
    const searchesJS = isImmutable(sonraiSearches)
      ? List.isList(sonraiSearches)
        ? sonraiSearches.toJS()
        : sonraiSearches.toList().toJS()
      : sonraiSearches
    if (!_.isEmpty(searchesJS)) {
      for (let searchGuy of searchesJS) {
        if (searchGuy.name in searches) {
          searches[searchGuy.name] = searchGuy.query
        }
      }
    }
  }
  return searches
}

export const monthsToDays = numMonths => {
  return Math.abs(
    Number.parseInt(
      moment
        .duration(moment().diff(moment().add(numMonths, 'M').calendar()))
        .asDays()
    )
  )
}

export const isMonitored = field => {
  if (exists(field) && !_.isEmpty(field)) {
    return field.includes('MONITORED')
  }
  return false
}

export const formatChangeDetectionProperties = properties => {
  let changeDetectionProperties = []
  if (properties.length >= 1) {
    properties.forEach(property => {
      if (!_.isEmpty(changeDetectionProperties)) {
        const indexByKeyName = changeDetectionProperties
          .map(x => x.keyName)
          .indexOf(property.keyName)
        if (indexByKeyName !== -1) {
          const haveSameAlertLevel =
            changeDetectionProperties[indexByKeyName].alertLevel ===
            property.alertLevel
          if (haveSameAlertLevel) {
            if (
              exists(
                changeDetectionProperties[indexByKeyName].actionClassification
              )
            ) {
              changeDetectionProperties[indexByKeyName].actionClassification = [
                ...changeDetectionProperties[indexByKeyName]
                  .actionClassification,
                property.actionClassification[0],
              ]
            }
          } else {
            const indexByAlertLevel = changeDetectionProperties.findIndex(
              item => {
                return (
                  item.keyName === property.keyName &&
                  item.alertLevel === property.alertLevel
                )
              }
            )

            if (indexByAlertLevel !== -1) {
              if (
                exists(
                  changeDetectionProperties[indexByAlertLevel]
                    .actionClassification
                )
              ) {
                changeDetectionProperties[
                  indexByAlertLevel
                ].actionClassification = [
                  ...changeDetectionProperties[indexByAlertLevel]
                    .actionClassification,
                  property.actionClassification &&
                    property.actionClassification[0],
                ]
              }
            } else {
              changeDetectionProperties.push(property)
            }
          }
        } else {
          changeDetectionProperties.push(property)
        }
      } else {
        changeDetectionProperties.push(property)
      }
    })
  }
  if (!_.isEmpty(changeDetectionProperties)) {
    changeDetectionProperties.forEach(item => {
      if (
        exists(item.actionClassification) &&
        Array.isArray(item.actionClassification)
      ) {
        item.actionClassification = _.uniq(item.actionClassification)
      }
    })
  }
  return changeDetectionProperties
}

export const metadataToObj = metadata => {
  const mappedVals = _.reduce(
    metadata,
    (finalMap, val) => {
      // the key is everything on the LHS of first unescaped colon so we split
      // on all unescaped ':'
      const split = val.match(/(\\.|[^:])+/g)

      // do replacement here ot unescape colons
      let key = split[0].replace(/\\:/g, ':')
      const value = val.substring(split[0].length + 1).replace(/\\:/g, ':')

      return {
        ...finalMap,
        [key]: value,
      }
    },
    {}
  )
  return mappedVals
}

export const metdataNestedObject = metadata => {
  const metadataObject = metadataToObj(metadata)
  return _.reduce(
    metadataObject,
    (result, value, key) => {
      const jsonPath = key.split('.').map(segment => {
        const number = Number(segment)
        return _.isNaN(number) ? segment : number
      })
      _.set(result, jsonPath, value)
      return result
    },
    {}
  )
}

const ESCAPED_WHITESPACE_PATTERN = '\\\\[nrt]'

/**
 * If a metadata field is a string, it can contain stringified JSON. This
 * method tries to identify if that is the case and then parse the string.
 *
 * Sometimes the strings have excaped whitespace characters them so it will
 * try to remove those too before it parses it.
 */
export const parseJSONString = value => {
  // First try to figure out if the string might contain JSON before actually
  // trying to parse it. Trying to save come CPU cycles by doing this...

  // remove both escaped and non-escaped whitesapce from start of string
  const startUnescapped = value
    .replace(new RegExp(ESCAPED_WHITESPACE_PATTERN), '')
    .trim()

  // it could be stringified, non-literal (object or array) json if the first
  // character is '[' or '{'
  const couldBeNonLiteral =
    startUnescapped.charAt(0) === '[' || startUnescapped.charAt(0) === '{'

  // here we think it's probably not stringified JSON, or if it is, it's not
  // an object or an array, so no sense parsing it.
  if (!couldBeNonLiteral) {
    return value
  }

  // remove all the escaped whitespace
  const allWhitespaceUnescaped = value.replace(
    new RegExp(ESCAPED_WHITESPACE_PATTERN, 'g'),
    ''
  )

  // try to return the parsed value
  try {
    return JSON.parse(allWhitespaceUnescaped)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug('Could not escape metadata value: ', allWhitespaceUnescaped)
  }

  // maybe the string wasn't JSON afterall, so we just return original value
  return value
}

/**
 * Sometimes metadata gets reported with values that are stringified json. This
 * method iterates over the metadata object and will find fields that are
 * stringified JSON and parse them.
 */
export const unescapeJSONObject = arg => {
  // this method gets called recursively, so it's possible a child in the
  // document got passed that isn't an object...
  if (_.isArray(arg)) {
    return arg.map(value => unescapeJSONObject(value))
  }

  // if it's a string, try parse it as JSON
  if (_.isString(arg)) {
    const parsedString = parseJSONString(arg)
    if (parsedString !== arg) {
      // the string must have been JSON, so escape the nested object
      return unescapeJSONObject(parsedString)
    }
  }

  if (!_.isObject(arg)) {
    return arg // the child passed must be a literal
  }

  // create a copy of the object w/ all it's fields unescaped
  const doc = { ...arg }
  for (let key of Object.keys(doc)) {
    const value = arg[key]
    doc[key] = unescapeJSONObject(value)
  }

  return doc // return our copy
}

export const getIconMapping = (type = '') => {
  switch (type.toLowerCase()) {
    case 'actor':
      return { unicode: '\uf85e', faIcon: 'digging' }
    case 'audit':
      return { unicode: '\uf70e', faIcon: 'scroll' }
    case 'policy':
    case 'networkpolicy':
    case 'policyentry':
    case 'platformpolicyentry':
    case 'platformpolicy':
    case 'sharedaccesspolicy':
    case 'passwordpolicy':
      return { unicode: '\uf15b', faIcon: 'file' }
    case 'cert':
    case 'servercertificate':
    case 'signingcertificate':
      return { unicode: '\uf5f3', faIcon: 'file-certificate' }
    case 'effectivepermission':
      return { unicode: '\uf0c1', faIcon: 'link' }
    case 'entity':
    case 'resource':
    case 'service':
      return { unicode: '\uf1b2', faIcon: 'cube' }
    case 'account':
    case 'platformaccount':
      return { unicode: '\uf2bd', faIcon: 'user-circle' }
    case 'platformorganization':
      return { unicode: '\uf1ad', faIcon: 'building' }
    case 'networkinterface':
      return { unicode: '\uf090', faIcon: 'sign-in' }
    case 'publickey':
    case 'accesskey':
    case 'encryptionkey':
      return { unicode: '\uf084', faIcon: 'key' }
    case 'mfadevice':
      return { unicode: '\uf029', faIcon: 'qrcode' }
    case 'blockstorage':
    case 'datacontainer':
    case 'datastore':
    case 'cidr':
    case 'data':
      return { unicode: '\uf1c0', faIcon: 'database' }
    case 'image':
      return { unicode: '\uf0a0', faIcon: 'hdd' }
    case 'dataobject':
    case 'resourcegroup':
      return { unicode: '\uf1b3', faIcon: 'cubes' }
    case 'network':
    case 'networksubnet':
    case 'networkcomponent':
    case 'infrastructure':
      return { unicode: '\uf0e8', faIcon: 'sitemap' }
    case 'storageaccount':
    case 'organizationalunit':
      return { unicode: '\uf660', faIcon: 'folders' }
    case 'networkpermission':
    case 'permission':
    case 'platformpermission':
      return { unicode: '\uf30d', faIcon: 'lock-alt' }
    case 'list':
    case 'permissionlist':
      return { unicode: '\uf03a', faIcon: 'list' }
    case 'identity':
    case 'platformidentity':
      return { unicode: '\uf2c1', faIcon: 'id-badge' }
    case 'identityprovider':
      return { unicode: '\uf19c', faIcon: 'university' }
    case 'protection':
      return { unicode: '\uf023', faIcon: 'hollowLock' }
    case 'systemconfig':
      return { unicode: '\uf085', faIcon: 'cogs' }
    case 'networkservice':
      return { unicode: '\uf519', faIcon: 'broadcast-tower' }
    case 'platformgroup':
    case 'subnetgroup':
    case 'optiongroup':
    case 'rollup':
      return { unicode: '\uf247', faIcon: 'object-group' }
    case 'subscription':
      return { unicode: '\uf2b9', faIcon: 'address-book' }
    case 'city':
    case 'subdivision':
      return { unicode: '\uf64f', faIcon: 'city' }
    case 'policyversion':
    case 'datastoreversion':
      return { unicode: '\uf292', faIcon: 'hashtag' }
    case 'platformbusinessunit':
      return { unicode: '\uf1ad', faIcon: 'building' }
    case 'role':
    case 'group':
      return { unicode: '\uf0c0', faIcon: 'users' }
    case 'snapshot':
      return { unicode: '\uf083', faIcon: 'camera-retro' }
    case 'content':
      return { unicode: '\uf573', faIcon: 'file-signature' }
    case 'country':
      return { unicode: '\uf57d', faIcon: 'globe-americas' }
    case 'region':
    case 'availabilityzone':
      return { unicode: '\uf0ac', faIcon: 'globe' }
    case 'action':
    case 'platformaction':
      return { unicode: '\uf245', faIcon: 'mouse-pointer' }
    case 'user':
      return { unicode: '\uf007', faIcon: 'user' }
    case 'servicetype':
    case 'platformactiontype':
    case 'actiontype':
    case 'servicetag':
    case 'datastoretype':
    case 'platformdatatype':
    case 'serviceclassification':
    case 'actionclassification':
    case 'dataclassification':
    case 'datastoreclassification':
      return { unicode: '\uf02c', faIcon: 'tags' }
    case 'portrange':
      return { unicode: '\uf0ec', faIcon: 'exchange' }
    case 'protocol':
      return { unicode: '\uf101', faIcon: 'angle-double-right' }
    case 'report':
      return { unicode: '\uf570', faIcon: 'file-invoice' }
    case 'cluster':
      return { unicode: '\uf58d', faIcon: 'grip-horizontal' }
    case 'compute':
      return { unicode: '\uf2db', faIcon: 'microchip' }
    case 'cloud':
      return { unicode: '\uf0c2', faIcon: 'cloud' }
    case 'tag':
      return { unicode: '\uf02b', faIcon: 'tag' }
    case 'parameter':
    case 'property':
    case 'clusterparametergroup':
    case 'parametergroup':
      return { unicode: '\uf121', faIcon: 'code' }
    case 'platformuser':
      return { unicode: '\uf509', faIcon: 'users-cog' }
    case 'location':
      return { unicode: '\uf3c5', faIcon: 'map-marker-alt' }
    case 'secretstore':
      return { unicode: '\uf495', faIcon: 'warehouse-alt' }
    case 'secret':
      return { unicode: '\uf6fa', faIcon: 'mask' }
    case 'workload':
      return { unicode: '\uf5fc', faIcon: 'laptop-code' }
    default:
      return { unicode: '\uf1b2', faIcon: 'cube' }
  }
}

export const getBaseEntityColor = (type = '', theme = {}) => {
  switch (type.toLowerCase()) {
    case 'publickey':
    case 'encryptionkey':
    case 'cert':
    case 'servercertificate':
    case 'signingcertificate':
      return theme.protection
    case 'blockstorage':
    case 'datacontainer':
    case 'datastore':
    case 'data':
    case 'dataobject':
    case 'keystore':
    case 'secret':
    case 'secretstore':
      return theme.data
    case 'identity':
    case 'role':
    case 'group':
    case 'user':
    case 'platformuser':
    case 'account':
    case 'mfadevice':
    case 'organization':
    case 'organizationalunit':
    case 'identityprovider':
    case 'identityreference':
    case 'accesskey':
      return theme.identity
    case 'networkpolicy':
    case 'audit':
    case 'cidr':
    case 'cloud':
    case 'eventStream':
    case 'compute':
    case 'dnszone':
    case 'dnsrecord':
    case 'networkcomponent':
    case 'networklisteners':
    case 'networkgroups':
    case 'image':
    case 'network':
    case 'networkinterface':
    case 'networkpermission':
    case 'networkroutetable':
    case 'networkroute':
    case 'networksubnet':
    case 'option':
    case 'optiongroup':
    case 'parameter':
    case 'paramatergroup':
    case 'clusterparamatergroup':
    case 'servicetag':
    case 'cluster':
    case 'snapshot':
    case 'subnetgroup':
    case 'trigger':
      return theme.infrastructure
    case 'city':
    case 'subdivision':
    case 'country':
    case 'region':
    case 'availabilityzone':
    case 'location':
      return theme.emphasis
    default:
      return theme.secondary
  }
}

export const hashCode = (str = '') => {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export const colorForEntityString = (val = '', typeColors) => {
  const value = typeof val !== 'string' ? String(val) : val.toLowerCase()

  if (typeColors) {
    return typeColors[value] || colorForString(value)
  }

  return colorForString(value)
}

export const colorForString = (val = '') => {
  const num = hashCode(val)
  var c = (num & 0x00ffffff).toString(16).toUpperCase()

  const hex = '00000'.substring(0, 6 - c.length) + c
  return '#' + hex
}

export const shiftColorToMatch = (colorToShift, baseColor) => {
  const baseColorLib = color(baseColor)
  if (
    baseColorLib.red() === baseColorLib.green() &&
    baseColorLib.red() === baseColorLib.blue()
  ) {
    //target color is grey, so desaturate the given color otherwise it will turn out red
    return color(colorToShift).desaturate(1).hex()
  }

  const baseHue = baseColorLib.hue()

  return color(colorToShift).hue(baseHue).hex()
}

export const createNestedOrderMap = excuseMeSirIMustRemainOrdered => {
  // very important that i remain ordered
  return typeof excuseMeSirIMustRemainOrdered !== 'object' ||
    excuseMeSirIMustRemainOrdered === null
    ? excuseMeSirIMustRemainOrdered
    : Array.isArray(excuseMeSirIMustRemainOrdered)
    ? Immutable.Seq(excuseMeSirIMustRemainOrdered)
        .map(createNestedOrderMap)
        .toList()
    : Immutable.Seq(excuseMeSirIMustRemainOrdered)
        .map(createNestedOrderMap)
        .toOrderedMap()
}

export const getValidAliasName = alias => {
  // wow so cool! fancy pants over heremaking it in 5 lines instead of 35
  return alias
    .slice(/[a-zA-Z]|_/.exec(alias).index) // slice off everything before first char
    .replace(/([^a-zA-Z_0-9 ]+)/g, '') // replace all the invsalid characters with nothing
    .split(/\s/) // get an array of the words
    .map((val, index) => (index > 0 ? _.capitalize(val) : val)) // capitalize each one
    .join('') // make one string
}

export const shiftDateArg = (arg, alertTime) => {
  const originalAlertTime = alertTime.toISOString()

  if (arg.get('dateOffset')) {
    if (isImmutable(arg.get('dateOffset'))) {
      const offsetType = arg.getIn(['dateOffset', 'type'])
      const tense = arg.getIn(['dateOffset', 'tense'])
      const number = arg.getIn(['dateOffset', 'number'])
      const measure = arg.getIn(['dateOffset', 'measure'])

      if (offsetType === 'LT' && tense === 'past') {
        // "More than 30 days ago"
        return arg
          .set('op', 'LT')
          .set('value', alertTime.subtract(number, measure).toISOString())
          .delete('dateOffset')
      } else if (offsetType === 'LT' && tense === 'future') {
        // "Within the next 90 days"
        return arg
          .set('op', 'BETWEEN')
          .set(
            'values',
            fromJS([
              originalAlertTime,
              alertTime.add(number, measure).toISOString(),
            ])
          )
          .delete('dateOffset')
      } else if (offsetType === 'GT' && tense === 'past') {
        // "Within the past 24 hours"
        return arg
          .set('op', 'BETWEEN')
          .set(
            'values',
            fromJS([
              alertTime.subtract(number, measure).toISOString(),
              originalAlertTime,
            ])
          )
          .delete('dateOffset')
      } else if (offsetType === 'GT' && tense === 'future') {
        // "More than 90 days from now"
        return arg
          .set('op', 'GT')
          .set('value', alertTime.add(number, measure).toISOString())
          .delete('dateOffset')
      }
    } else {
      if (arg.get('dateOffset') < 0) {
        //Value is Older than X days ago
        return arg
          .set('op', 'LTE')
          .set(
            'value',
            alertTime.add(arg.get('dateOffset'), 'days').toISOString()
          )
          .delete('dateOffset')
      } else {
        //Value is newer than X days ago
        return arg
          .set('op', 'BETWEEN')
          .set(
            'values',
            fromJS([
              alertTime
                .subtract(arg.get('dateOffset'), 'days')
                .subtract(1, 'hour')
                .toISOString(),
              originalAlertTime,
            ])
          )
          .delete('dateOffset')
      }
    }
  }

  return arg
}

export const shiftDateArgs = (keyedArgs, timestamp) => {
  const alertTime =
    typeof timestamp === 'string' ? moment(timestamp) : moment.unix(timestamp)
  return keyedArgs.map(keyedArg => {
    const firstKey = keyedArg.keySeq().first()
    const arg = shiftDateArg(keyedArg.get(firstKey), alertTime)

    return fromJS({
      [firstKey]: arg,
    })
  })
}

export const getColorByTimePassed = date => {
  const days = moment().diff(moment(date), 'days')
  if (days < 7) {
    return '#11871f'
  } else if (days < 30) {
    return '#fccf49'
  } else if (days < 90) {
    return '#ff802b'
  } else {
    return '#bc1818'
  }
}

export const getPrincipalType = srn => {
  if (srn.includes('/User/Root/') || srn.includes('/Account/')) {
    return 'Account'
  }

  if (srn.includes('/User/')) {
    return 'User'
  }

  if (srn.includes('/IdentityProvider/')) {
    return 'Identity Provider'
  }

  if (srn.includes('/Service/')) {
    return 'AWS Service'
  }

  return '-'
}

export const getDefaultChangeDetectionPropertiesByType = type => {
  switch (type && type.toLowerCase()) {
    case 'user':
      return ['accessed', 'activefrom', 'accessedusing']
    default:
      return []
  }
}

export const stripExtraChars = (text = '') => {
  return text
    .toLowerCase()
    .replace(/[^\w\s]|_/g, '')
    .replace(/\s+/g, ' ')
    .trim()
}

export const keywordMatch = (a, b) => {
  const first = (a || '').toLowerCase()
  const second = (b || '').toLowerCase()

  return first.includes(second) || second.includes(first)
}

export const getNameForHelmet = data => {
  const keys = ['friendlyName', 'name', 'title', 'type', '__typename'] // fields to use in order
  const key = keys.find(key => data[key]) // first field thats not null
  const charLimit = 20
  let name = _.truncate(data[key], {
    length: charLimit,
    separator: ' ',
  })
  return name || ''
}

export const getSwimlaneNameFromSrn = (srn, swimlanes) => {
  const swimlane =
    swimlanes.find(swimlane => swimlane.get('srn') === srn) || Map()
  return swimlane.get('title') || swimlane.get('name') || srn
}

export const getSwimlaneSrnFromResourceId = (resourceId, swimlanes) => {
  const swimlane =
    swimlanes.find(swimlane => swimlane.get('resourceId') === resourceId) ||
    Map()
  return swimlane.get('srn')
}

export const getAcceptableCloudAccountTypesForBots = (
  swimlaneAccounts,
  allAccounts
) => {
  if (swimlaneAccounts.isEmpty()) {
    return []
  }
  const accountsByCloudType = swimlaneAccounts
    .map(accountId => getAccountSrnFromId(accountId, allAccounts))
    .filter(srn => srn) // if it doesn't exist the account was likely deleted
    .map(srn => srn && srn.split(':')[1]) // grab the cloud type of the srn
    .map(type => type && type.toLowerCase())
  return !accountsByCloudType.isEmpty() ? accountsByCloudType.toJS() : []
}

export const isPlatformRole = role => {
  const isntSwimmyPerm = perm => !SWIMLANE_SCOPED_PERMISSIONS.includes(perm)
  const perms = role.get('expandedPermissions', List())
  return undefined !== perms.find(isntSwimmyPerm)
}

export const isValidURL = url => {
  if (url && typeof url === 'string' && url.length >= 1) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator
    return !!pattern.test(url)
  }
  return false
}
export const getNodeTypeMap = (
  filteredMappings,
  nodeTypeCounts,
  queryTypes
) => {
  const getCountFromGroupedQuery = nodeType => {
    const node = nodeTypeCounts.find(
      node => node.get('value').toLowerCase() === nodeType.toLowerCase()
    )
    if (node) {
      return node.get('count')
    }
    return null
  }
  let map = {}
  for (let i = 0; i < filteredMappings.length; i++) {
    const type = filteredMappings[i]
    const { nodeType } = type
    let count = getCountFromGroupedQuery(nodeType)
    if (!count) {
      const isInterface = queryTypes.getIn([nodeType, 'kind']) === 'INTERFACE'
      if (!isInterface) {
        map[nodeType] = { count: 0 }
      } else {
        const obj = queryTypes.get(nodeType, Map())
        const possibleTypes = obj.get('possibleTypes', List())
        const accumCountForInterfaceNodeType = possibleTypes.reduce(
          (acc, cur) => {
            const count = getCountFromGroupedQuery(cur.get('name'))
            if (count) {
              return acc + count
            }
            return acc
          },
          0
        )
        map[nodeType] = { count: accumCountForInterfaceNodeType }
      }
    } else {
      map[nodeType] = { count }
    }
  }
  return fromJS(map)
}

export const stripTags = things => {
  if (typeof things === 'string') {
    return things.replace(/</g, '&lt;').replace(/>/g, '	&gt;')
  }
  return things.map(cat => cat.replace(/</g, '&lt;').replace(/>/g, '	&gt;'))
}

export const getTicketId = ticketResourceId => {
  return _.last(_.split(ticketResourceId, '/'))
}

export const getPoliciesFromObjectives = objectives => {
  const policies = []
  objectives.forEach(objective => {
    objective
      .getIn(['appliedControlFrameworks', 'items'], List())
      .forEach(item => {
        item.getIn(['contains', 'items'], List()).forEach(item =>
          policies.push({
            srn: item.get('srn'),
            name: objective.get('name'),
          })
        )
      })
  })
  const grouped = _.groupBy(
    _.uniqBy(policies, item => item.srn),
    'name'
  )
  _.keys(grouped).forEach(key => {
    grouped[key] = grouped[key].map(item => item.srn)
  })
  return grouped
}

export const orderObjectiveViolationData = (tickets, objectives, policies) => {
  const sortedByCFOrder = _.sortBy(tickets, ticket => {
    const objective =
      objectives.find(
        objective => objective.get('srn') === ticket.objectiveSrn
      ) || Map()

    const policy =
      policies.find(item => item.get('srn') === ticket.ticketKey) || Map()

    const controlFrameworksInPolicy = policy.getIn(
      ['containedByControlFramework', 'items'],
      List()
    )
    const controlFrameworksInObjective =
      objective.get('definedControlFrameworks') || List()

    const controlFrameWorkToOrderBy = controlFrameworksInObjective.find(cf => {
      const match = controlFrameworksInPolicy.find(
        item => item.get('srn') === cf.get('controlFrameworkSrn')
      )
      return match
    })

    return controlFrameWorkToOrderBy.get('controlFrameworkOrder')
  })

  const sortedByPolicy = sortedByCFOrder.sort((a, b) => {
    const policyA =
      policies.find(item => item.get('srn') === a.ticketKey) || Map()
    const policyB =
      policies.find(item => item.get('srn') === b.ticketKey) || Map()

    if (a.objectiveSrn === b.objectiveSrn) {
      const controlFrameworksInPolicyA = policyA.getIn(
        ['containedByControlFramework', 'items'],
        List()
      )
      const controlFrameworksInPolicyB = policyB.getIn(
        ['containedByControlFramework', 'items'],
        List()
      )
      controlFrameworksInPolicyA.forEach(cf => {
        if (controlFrameworksInPolicyB.includes(cf)) {
          return (
            policyB.get('alertingLevelNumeric') -
            policyA.get('alertingLevelNumeric')
          )
        }
      })
    }
  })

  return sortedByPolicy
}

export const getInterfaceCount = (queryTypes, interfaceName, countsByType) => {
  const possibleTypes = queryTypes.getIn(
    [interfaceName, 'possibleTypes'],
    List()
  )

  const casedKeys = Object.keys(countsByType)
  const countsByTypeLower = {}
  casedKeys.forEach(key => {
    countsByTypeLower[key.toLowerCase()] = countsByType[key]
  })

  const totalCount = possibleTypes.reduce((total, possibletype) => {
    const type = possibletype.get('name').toLowerCase()
    const count = countsByTypeLower[type] || 0
    return total + count
  }, 0)

  return totalCount
}

export const hasFinishedEvaluatingFrameworks = (
  appliedControlFrameworks,
  controlGroups, //ALL control frameworks
  swimlaneSrn
) => {
  const controlFrameworks = appliedControlFrameworks.filter(
    cf =>
      !cf.get('swimlaneSRNs') || //If swimalneSRNs is null, it is enabled globally
      (cf.get('swimlaneSRNs') || List()).includes(swimlaneSrn)
  )

  const controlFrameworksThatHaveEvaluated = controlFrameworks.filter(cf => {
    const fullCf = controlGroups.get(cf.get('srn')) || Map()
    const lastRunSwimlanes = fullCf.get('lastRunSwimlanes') || List()
    return lastRunSwimlanes.includes(swimlaneSrn)
  })

  return (
    controlFrameworks.size !== 0 &&
    controlFrameworks.size === controlFrameworksThatHaveEvaluated.size
  )
}

export const getSearchLimit = (reduxLimit, prevLimit) => {
  if (
    !prevLimit ||
    (reduxLimit !== prevLimit && reduxLimit !== DEFAULT_SEARCH_LIMIT)
  ) {
    return reduxLimit
  }
  return prevLimit
}

export const hadResourceDeleted = ticket => {
  const resource = ticket.resource || {}
  if (ticket.resourceSRN) {
    if (_.isEmpty(resource)) {
      return true
    }
  }
  return false
}

export const isEnableAllObjectiveSwimlaneDisabled = (
  objectives,
  controlFrameworks
) => {
  const controlFrameworkSrns = objectives
    .map(objective =>
      objective
        .getIn(['appliedControlFrameworks', 'items'], List())
        .map(item => item.get('srn'))
    )
    .flatten()

  const disabledControlFrameworks = controlFrameworkSrns.filter(srn => {
    return !(
      controlFrameworks.getIn([srn, 'enabled']) &&
      controlFrameworks.getIn([srn, 'swimlaneSRNs']) === null
    )
  })

  return disabledControlFrameworks.isEmpty()
}

export const getSupportedFilterFields = (filterFieldType, types) => {
  const fields = (types.get(filterFieldType) || Map()).get(
    'inputFields',
    List()
  )

  return fields.reduce((supportedFilters, currentDef) => {
    const fieldName = currentDef.get('name')
    const typeName = currentDef.getIn(['type', 'name'])
    if (typeName) {
      const childFields = (types.get(typeName) || Map())
        .get('inputFields', List())
        .map(def => def.get('name'))
        .filter(field => !supportedFilters.includes(field))
      return supportedFilters.merge(childFields)
    }
    return supportedFilters.push(fieldName)
  }, List())
}
