/**
 *
 * App
 *
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { StyleRoot } from 'radium'
import { compose } from 'redux'

import Login from 'containers/Login'
import AppLayout from 'containers/AppLayout'
import DisabledUserPage from 'containers/DisabledUserPage/Loadable'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import Loading from 'containers/Loading'
import userProfileData from 'containers/UserProfileData'
import themeManager from 'containers/ThemeManager'
import PageLoader from 'components/PageLoader'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { useAuth0 } from 'react-auth0-wrapper'

import OrgSelector, { Events } from 'auth/current-org'

function PrivateRoute({ component: Component, authed, location, ...rest }) {
  if (!authed) {
    const pathname = window.location.pathname
    const search = window.location.search
      ? decodeURIComponent(window.location.search)
      : ''
    localStorage.setItem('redirectOnLoginUrl', `${pathname}${search}`)
  }

  const render = props => {
    if (authed === true) {
      // check to make sure user has selected their org
      if (window.aat) {
        if (OrgSelector.userIsDisabled()) {
          return <Redirect to={{ pathname: '/DisabledUser' }} />
        } else if (OrgSelector.getCurrentOrg() === null) {
          // none selected - if we can set as default, make default
          if (OrgSelector.canSetDefaultOrg()) {
            OrgSelector.setDefaultOrg({ handleEvent: false })
          } else {
            // need to prompt user to make a selection
            return (
              <Redirect
                to={{
                  pathname: '/Login/ChooseOrganization',
                  state: { from: props.location },
                }}
              />
            )
          }
        }
        if (OrgSelector.hasInvalidOrg()) {
          OrgSelector.clearCurrentOrg()
        }
        return <Component {...props} />
      } else {
        // still loading token - just show loading thing real quick
        return <PageLoader />
      }
    } else {
      return (
        <Redirect
          to={{ pathname: '/Login', state: { from: props.location } }}
        />
      )
    }
  }

  return <Route {...rest} render={render} />
}

PrivateRoute.propTypes = {
  authed: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  component: PropTypes.func.isRequired,
  theme: themeShape,
  location: PropTypes.object,
}

export const App = () => {
  const { isAuthenticated, loading } = useAuth0()

  // register handlers for events when the org is changed
  useEffect(() => {
    OrgSelector.registerEventHandler(Events.CLEAR, () => {
      window.location = '/Login/ChooseOrganization'
    })
    OrgSelector.registerEventHandler(Events.SET, () => {
      window.location = '/'
    })
  }, [])

  const AuthedLogin = props => (
    <Login authenticated={isAuthenticated} {...props} />
  )

  if (!window.config || loading) {
    return <PageLoader />
  }

  return (
    <StyleRoot>
      <div
        style={{
          overflow: 'auto',
          position: 'absolute',
          backfaceVisibility: 'hidden',
          width: '100%',
          height: '100%',
        }}
      >
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/App" />} />
          <Route path="/Login*" render={AuthedLogin} />
          <PrivateRoute
            authed={isAuthenticated}
            path="/App"
            component={AppLayout}
          />
          <Route path="/Loading" component={Loading} />
          <Route path="/DisabledUser" component={DisabledUserPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </StyleRoot>
  )
}

export default compose(userProfileData, themeManager, themeable)(App)
