import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import {
  getFields,
  getSearchCard,
  getBaseSonraiSearchData,
  getSelection,
  hasSonraiSearch,
} from 'query-builder'

import { TopTitle, CardBody } from 'components/Card'
import DataTable from 'components/DataTable'
import WidgetCard from 'components/WidgetCard'

import { exists } from 'utils/sonraiUtils'

export default class TableWidgetQueryResult extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // we keep the rows in state so we don't have to do the transform in getRows
      // on each render, otherwise a new object get passed down to DataTable and
      // cause it to re-process it's data
      rows: this.getRows(props.data),
    }
  }

  styles = {
    title: {
      fontSize: '22px',
      fontWeight: '300',
      cursor: 'pointer',
      marginRight: '0px',
    },
    subtitle: {
      fontSize: '14px',
      fontWeight: '300',
      color: '#888888',
    },
    card: {
      minHeight: 0, //Required to allow tables to autosize without overflowing
    },
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ rows: this.getRows(this.props.data) })
    }
  }

  getRows = data => {
    const subData = this.getData(data)

    //The following is to get a deep clone of the original data,
    //which has been made immutable by Apollo Query using Object.preventExtensions()
    //AGGrid temporarily adds a property to the row items to track changes between renders
    //which results in an error because it cannot modify the original objects
    let rows = _.cloneDeep(subData) || []

    return rows
  }

  getData = data => {
    if (hasSonraiSearch(this.props.options, 'table')) {
      return getBaseSonraiSearchData(data)
    } else {
      const fields = getFields(
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.table
      ).toJS()

      if (this.props.shouldFlatten()) {
        if (_.isObject(data)) {
          const key = _.first(_.keys(data))
          return _.get(data, [key, 'items'], [])
        }
        return data
      } else {
        const searchCard = getSearchCard(this.props.resultLayout, 'table')
        const path = getSelection(fields, searchCard, 'items')

        return _.get(data, path)
      }
    }
  }

  onClickFlattenedNodeView = (allData, clickedRowData) => {
    if (!hasSonraiSearch(this.props.options, 'table')) {
      const dataKey = Object.keys(allData)[0]
      const dataItems = allData[dataKey].items

      if (!dataItems || dataItems.length === 0) {
        return
      }

      const rootDataSample = dataItems[0]
      const rootDataTypeKey = Object.keys(rootDataSample).find(propertyKey =>
        propertyKey.includes('__typename')
      )
      const rootDataType = rootDataSample[rootDataTypeKey]
      const rowRootDataSrn = clickedRowData[`${rootDataType}_srn`]
      if (rowRootDataSrn) {
        this.props.onClickNodeView(rowRootDataSrn, rootDataType)
      }
    }
  }

  render() {
    // const rows = this.state.rows

    let flatten = false
    if (
      !_.isEmpty(this.props.data) &&
      this.props.shouldFlatten() &&
      this.props.networkStatus !== 1
    ) {
      flatten = true
    }

    //Required to prevent error on refetching
    //See: https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
    const doRefetch = () => {
      this.props.refetch()
    }

    return (
      <WidgetCard
        searchId={this.props.searchId}
        disableToolbar={this.props.disableToolbar}
        allowDelete={this.props.allowDelete}
        allowUpdate={this.props.allowUpdate}
        createdBy={this.props.widget && this.props.widget.createdBy}
        onEdit={this.props.onEdit}
        error={this.props.error}
        onRemove={this.props.onRemove}
        toggleStatic={this.props.toggleStatic}
        static={this.props.static}
        refetch={doRefetch}
        networkStatus={this.props.networkStatus}
        title={this.props.title}
        filtered={this.props.filtered}
        pivot={this.props.filtered ? this.props.pivot : undefined}
        description={_.get(this.props.resultLayout, [
          'widgetOptions',
          'description',
        ])}
      >
        <TopTitle>{this.props.renderHeader(this.state.rows.length)}</TopTitle>
        <CardBody style={this.styles.card}>
          {!_.isEmpty(this.props.data) && exists(this.state.rows) && (
            <DataTable
              customColumnConfig={this.props.customColumnConfig}
              set="SolutionCard"
              id={this.props.widget.srn}
              userProfile={this.props.userProfile}
              data={this.state.rows}
              flatten={flatten}
              // setUserProfileTables={this.props.setUserProfileTables}
              onLayoutChange={this.props.setWidgetOptions}
              onClickNodeView={this.props.onClickNodeView}
              onClickFlattenedNodeView={this.onClickFlattenedNodeView.bind(
                null,
                this.props.data
              )}
              onSingleClickNodeView={this.props.onSingleClickNodeView}
              options={this.props.options}
              exportFileName={this.props.title}
            />
          )}
        </CardBody>
      </WidgetCard>
    )
  }
}

TableWidgetQueryResult.propTypes = {
  // setUserProfileTables: PropTypes.func,
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  customColumnConfig: PropTypes.object,
  data: PropTypes.array,
  disableToolbar: PropTypes.bool,
  error: PropTypes.bool,
  filtered: PropTypes.bool,
  networkStatus: PropTypes.any,
  onClickNodeView: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onSingleClickNodeView: PropTypes.func,
  options: PropTypes.object,
  pivot: PropTypes.object,
  refetch: PropTypes.func.isRequired,
  renderHeader: PropTypes.func.isRequired,
  resultLayout: PropTypes.shape({
    indexedSearches: PropTypes.objectOf(PropTypes.string),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }),
  savedSearches: ImmutablePropTypes.map,
  searchId: PropTypes.string,
  setWidgetOptions: PropTypes.func,
  shouldFlatten: PropTypes.func,
  srn: PropTypes.string,
  static: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  toggleStatic: PropTypes.func,
  userProfile: PropTypes.object,
  widget: PropTypes.object,
}
