import React from 'react'
import PropTypes from 'prop-types'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
export const ToolbarItem = ({
  permissionName,
  iconName,
  onClick,
  children,
  disabled,
  spin,
  active,
}) => {
  const styles = {
    toolbarButton: {
      paddingTop: '0.1em',
      paddingBottom: '0.1em',
      paddingRight: '0.5em',
      paddingLeft: '0.5em',
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: '1em',
      cursor: disabled ? '' : 'pointer',
    },
    iconStyle: {
      marginRight: '0.3em',
      fontSize: '1em',
    },
  }

  if (permissionName) {
    return (
      <WithPermission permissionName={permissionName}>
        <TextLink
          onClick={onClick}
          disabled={disabled}
          style={styles.toolbarButton}
          invert={active}
        >
          {iconName && (
            <Icon fa name={iconName} style={styles.iconStyle} spin={spin} />
          )}
          {children}
        </TextLink>
      </WithPermission>
    )
  }
  return (
    <TextLink
      onClick={onClick}
      disabled={disabled}
      style={styles.toolbarButton}
      invert={active}
    >
      {iconName && (
        <Icon fa name={iconName} style={styles.iconStyle} spin={spin} />
      )}
      {children}
    </TextLink>
  )
}

ToolbarItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  spin: PropTypes.bool,
  permissionName: PropTypes.string,
}
export default ToolbarItem
