import React from 'react'
import PropTypes from 'prop-types'
import ContainerDimensions from 'react-container-dimensions'
import EmptyWidgetResults from 'components/EmptyWidgetResults'
import ApexChart from 'react-apexcharts'

import Scrollable from 'components/Scrollable'
import BorderlessButton from 'components/BorderlessButton'
import ChartLegendEntry from 'components/ChartLegendEntry'

export class SunburstPieChart extends React.Component {
  styles = {
    container: {
      height: '100%',
      width: '100%',
    },
    title: {
      fontWeight: 400,
      marginBottom: '0.5em',
    },
  }

  onHoverLegend = e => {
    let series = this.apexChartInstance.series
    series.toggleSeriesOnHover(e, e.target)
  }

  getOptions = width => {
    return {
      width: width,
      height: width,
      grid: {
        padding: {
          top: 0,
          right: -5,
          bottom: -5,
          left: -5,
        },
      },
      chart: {
        offsetY: 0,
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
        },
        events: {
          beforeMount: apexChartInstance => {
            this.apexChartInstance = apexChartInstance
          },
          dataPointSelection: (e, chart, options) => {
            if (
              (this.props.onClickPoint &&
                this.props.yLabels[options.dataPointIndex] !== 'null') ||
              this.props.yLabels[options.dataPointIndex] !== '-'
            ) {
              this.props.onClickPoint({
                values: this.props.yValues,
                labels: this.props.yLabels,
                displayLabels: this.props.yValueDisplayLabels,
                index: options.dataPointIndex,
                sectionTitle: this.props.title,
              })
            }
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: this.props.yValueDisplayLabels || this.props.yLabels || [],
      },
      colors: this.props.colors,
      labels: this.props.yValueDisplayLabels || this.props.yLabels || [],
      legend: {
        show: false,
      },
      states: {
        active: {
          filter: 'lighten',
        },
      },
    }
  }
  renderScrollableLegend = height => {
    return (
      <Scrollable
        noShadow
        style={{
          height: `${height}px`,
        }}
      >
        <div style={this.styles.title}>{this.props.title}</div>
        <div>
          {(this.props.yValueDisplayLabels || this.props.yLabels || []).map(
            (label, index) => (
              <BorderlessButton
                key={index}
                style={{
                  display: 'block',
                }}
                onClick={() => {
                  this.props.onClickPoint({
                    values: this.props.yValues,
                    labels: this.props.yLabels,
                    displayLabels: this.props.yValueDisplayLabels,
                    index: index,
                    sectionTitle: this.props.title,
                  })
                }}
              >
                <ChartLegendEntry
                  title={`${label} (${this.props.yValues[index]})`}
                  color={this.props.colors[index]}
                />
              </BorderlessButton>
            )
          )}
        </div>
      </Scrollable>
    )
  }
  render() {
    //Note: Using height as the width on the pie charts so that their boundries are always square and aligned left
    //This gives more room for the legend
    return (
      <div style={this.styles.container}>
        <ContainerDimensions>
          {({ height }) => (
            <div
              style={{
                height: height,
                minHeight: height,
                maxHeight: height,
                display: 'grid',
                gridTemplateColumns: `${height}px 1fr`,
                gridTemplateAreas: '"pie legend"',
              }}
            >
              <div style={{ gridArea: 'pie' }}>
                {this.props.yValues.length === 0 ? (
                  <EmptyWidgetResults />
                ) : (
                  <ApexChart
                    options={this.getOptions(height)}
                    series={this.props.yValues}
                    type="donut"
                    height={height}
                    width={height}
                  />
                )}
              </div>
              <div style={{ gridArea: 'legend' }}>
                {this.renderScrollableLegend(height)}
              </div>
            </div>
          )}
        </ContainerDimensions>
      </div>
    )
  }
}

SunburstPieChart.propTypes = {
  yLabels: PropTypes.arrayOf(PropTypes.string),
  yValues: PropTypes.arrayOf(PropTypes.number),
  yValueDisplayLabels: PropTypes.arrayOf(PropTypes.string),
  onClickPoint: PropTypes.func,
  colors: PropTypes.array,
  title: PropTypes.node,
}

export default SunburstPieChart
