import cookie from 'cookie'
import jwt from 'jsonwebtoken'
import _ from 'lodash'

// the users current org is stored in a cookie
export const COOKIE_NAME = 'sonrai.currentOrg'

// this is the claim on the access_token that contains allowed orgs
export const ORGS_CLAIM = `https://sonraisecurity.com/orgs`

/**
 * Setup event handlers: Functions around app can subscribe to changes on to
 * the org. The events will emit across tabs using this BroadcastChannel
 */
const BC_CHANNEL_NAME = 'sonrai_current_org_channel'
export const Events = {
  CLEAR: `${BC_CHANNEL_NAME}/CLEAR`,
  SET: `${BC_CHANNEL_NAME}/SET`,
}

var orgChangeChannel // this is the BroadcastChannel
var channelEventHandlers // { [Event.CLEAR]: [callback...], ... }
initBroadcastChannel()

/**
 * ~~~ BEGIN PUBLIC INTERFACE ~~~
 */
export default {
  /**
   * export an object with everything if callers prefer not to just import
   * individual methods
   */
  Events,
  clearCurrentOrg,
  canSetDefaultOrg,
  setDefaultOrg,
  userIsDisabled,
  getCurrentOrg,
  setCurrentOrg,
  getAllowedOrgs,
  hasInvalidOrg,
  registerEventHandler,
  unregisterEventHandler,
}

/**
 * clear the currently selected org
 * @param passedParams - see comments below for values
 */
export function clearCurrentOrg(passedParams) {
  const params = {
    ...{
      // handleEvent: should we call the registered callbacks (default true)
      handleEvent: true,
    },
    ...passedParams,
  }
  document.cookie = `${COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  if (params.handleEvent) {
    orgChangeChannel.postMessage(Events.CLEAR)
    handleEvent(Events.CLEAR)
  }
}

/**
 * determine whether a default org can be set.. i.e. user ony has one allowed
 * org so we can use it by default
 *
 * @param token {string} users access token, default window.aat
 * @returns {boolean} true if there is only one org allowed, false otherwise
 */
export function canSetDefaultOrg(token = window.aat) {
  validateToken(token)
  const allowedOrgs = getAllowedOrgsFromToken(token)
  return allowedOrgs.length === 1
}

/**
 * selects the default org if there is only one allowed. If there is not only
 * one allowed, this will throw
 *
 * @param token {string} users access token, default window.aat
 */
export function setDefaultOrg(params, token = window.aat) {
  if (!canSetDefaultOrg(token)) {
    throw new Error(`IllegalArgumentError: not allowed to set default org`)
  }
  const allowedOrgs = getAllowedOrgsFromToken(token)
  setCurrentOrg(allowedOrgs[0], params, token)
}

/**
 * returns true if the user is disabled - i.e. they have no orgs assigned
 *
 * @param token {string} the users access token
 */
export function userIsDisabled(token = window.aat) {
  validateToken(token)
  const allowedOrgs = getAllowedOrgsFromToken(token)
  return allowedOrgs.length === 0
}

/**
 * get the org the user has currently selected to use
 *
 * @returns {string} user's current org
 */
export function getCurrentOrg() {
  const cookies = cookie.parse(document.cookie)
  return cookies[COOKIE_NAME] || null
}

/**
 * select the current org for the user to use
 *
 * @param org {string} org to select
 * @param token {string} users' current access token. default window.aat
 */
export function setCurrentOrg(org, passedParams, token = window.aat) {
  const params = {
    ...{
      // handleEvent: should we call the registered callbacks (default true)
      handleEvent: true,
      // noValidate: set the org to whatver is passed but skip validatition
      noValidate: false,
    },
    ...passedParams,
  }
  if (!params.noValidate) {
    validateOrgIsAllowed(org, token) // throws if invalid
  }
  document.cookie = `${COOKIE_NAME}=${org}; path=/;`
  if (params.handleEvent) {
    orgChangeChannel.postMessage(Events.SET)
    handleEvent(Events.SET)
  }
}

/**
 * get all the orgs the user could assume based on their token claim
 */
export function getAllowedOrgs(token = window.aat) {
  validateToken(token)
  return getAllowedOrgsFromToken(token)
}

/**
 * somehow a users token was set but
 */
export function hasInvalidOrg(token = window.aat) {
  const currentOrg = getCurrentOrg()
  if (currentOrg == null) {
    return false
  }
  const allowedOrgs = getAllowedOrgs(token)
  if (!allowedOrgs) {
    // eslint-disable-next-line no-console
    console.debug('could not get allowed orgs from token ' + token)
    return false
  }
  return !allowedOrgs.includes(currentOrg)
}

/**
 * Register a function to run if the event happens.
 *
 * @param event {Event} one of the events that will run callback when. It will
 * throw if an invalid event is passed
 * @param callback {func} function to run when the event happens
 */
export function registerEventHandler(event, callback) {
  if (undefined === channelEventHandlers[event]) {
    throw new Error(`IllegalArgumentError: invalid event ${event}`)
  }
  channelEventHandlers[event].push(callback)
}

/**
 * Unreigster a function so it wont run when the event happens
 *
 * @param event {Event} event that the function will not run when it happens.
 * It will throw if an invalid event is passed
 * @param callback {func} callback that wont run when the event fires
 */
export function unregisterEventHandler(event, callback) {
  if (undefined === channelEventHandlers[event]) {
    throw new Error(`IllegalArgumentError: invalid event ${event}`)
  }
  channelEventHandlers[event] = channelEventHandlers[event].filter(
    handler => handler != callback
  )
}

/**
 * ~~~ BEGIN PRIVATE INTERFACE ~~~
 */

function getAllowedOrgsFromToken(token) {
  const { [ORGS_CLAIM]: allowedOrgs } = jwt.decode(token)
  return allowedOrgs
}

function validateToken(token) {
  if (!token) {
    throw new Error('IllegalArgumentError: no token argument was supplied')
  }
  const allowedOrgs = getAllowedOrgsFromToken(token)
  if (!allowedOrgs) {
    throw new Error(
      `IllegalArgumentError: token did not contain claim '${ORGS_CLAIM}'`
    )
  }

  if (!_.isArray(allowedOrgs)) {
    throw new Error(
      `IllegalArgumentError: token claim '${ORGS_CLAIM}' had invalid value ${allowedOrgs}`
    )
  }
}

function validateOrgIsAllowed(org, token) {
  validateToken(token) // throws if invalid
  if (!org) {
    throw new Error('IllegalArgumentError: no org was supplied')
  }

  const allowedOrgs = getAllowedOrgsFromToken(token)
  if (!allowedOrgs.includes(org)) {
    throw new Error(
      `IllegalOrgError: cannot assign to org ${org}, allowed orgs are ${allowedOrgs}`
    )
  }
}

function handleEvent(event) {
  if (channelEventHandlers[event] != undefined) {
    // run all the event handlers for the event
    channelEventHandlers[event].forEach(handler => handler())
  } else {
    throw new Error(
      `IllegalArgumentError: invalid current-org event for handler ${event}`
    )
  }
}

function initBroadcastChannel() {
  var BroadcastChannel = window.BroadcastChannel
  if (undefined === BroadcastChannel) {
    /**
     * polyfill for Safari and IE - if users have mutliple tabs open in those
     * browsers then I hope the polyfill works right. Don't steal this code
     * and use it to send sensitive data cause it'll end hangin out in
     * localstorage and those pentesters will yell at us again
     */
    BroadcastChannel = class BroadcastChannelPolyfill {
      constructor(messageChannel) {
        this.messageChannel = messageChannel
        this.postMessage = this.postMessage.bind(this)
        this.close = this.close.bind(this)
        this.handleMessage = this.handleMessage.bind(this)
        this.currentMessage = localStorage.getItem(this.messageChannel)

        window.addEventListener('storage', this.handleMessage)
      }

      addMessageId(message) {
        // just prefix the message with some random number so we dont process same
        // message two times
        return Math.floor(Math.random() * 1000000) + '_' + message
      }

      getMessageContent(message) {
        // remove the message id
        var x = message.split('_')
        x.shift()
        return x.join('_')
      }

      handleMessage() {
        const message = localStorage.getItem(this.messageChannel)
        if (message && message != this.currentMessage && this.onmessage) {
          this.onmessage({ data: this.getMessageContent(message) })
          this.currentMessage = message
        }
      }

      postMessage(message) {
        localStorage.setItem(this.messageChannel, this.addMessageId(message))
      }

      close() {
        window.removeEventListener('storage', this.handleMessage)
      }
    }
  } // end polyfill

  orgChangeChannel = new BroadcastChannel(BC_CHANNEL_NAME)
  orgChangeChannel.onmessage = event => handleEvent(event.data)
  channelEventHandlers = Object.values(Events).reduce(
    (a, c) => ({ ...a, [c]: [] }),
    {}
  )
}
