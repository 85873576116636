import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from './messages'

const styles = {
  //The following styles are to match with the empty table widget text
  noResults: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '13px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    marginBottom: 0,
  },
}

export class EmptyWidgetResults extends React.PureComponent {
  render() {
    return (
      <p style={styles.noResults}>
        <FormattedMessage {...messages.noResults} />
      </p>
    )
  }
}

export default EmptyWidgetResults
