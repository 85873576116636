import gql from 'graphql-tag'

export const FETCH_ALL_ESCALATIONS_QUERY = gql`
  query fetchAllEscalations {
    EscalationSchemes {
      items {
        srn
        description
        title
        rules {
          srn
          actionType
          triggerAfter
          escalateToBot {
            botSrn
          }
        }
        filters {
          srn
          ticketKey
        }
      }
    }
  }
`

export const SUBMIT_AUTO_REMEDIATE_QUERY = gql`
  mutation autoRemediate($input: AutoRemediateRequestInput) {
    AutoRemediate(input: $input) {
      success
      error
    }
  }
`
