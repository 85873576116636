/**
 *
 * PolicyVersionWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import _ from 'lodash'
import Card, { TopTitle, CardBody } from 'components/Card'
import PolicyEntryInfo from './PolicyEntryInfo'
import InfoCard from 'components/InfoCard'

const styles = {
  entryContainer: {
    display: 'grid',
    gridTemplateColumns: '30% 1fr',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  entryList: {
    overflow: 'auto',
  },
  permissionsTitle: {
    fontSize: '1.3em',
    padding: '0.3em 1em 1em 0',
  },
  policyEntryTitle: {
    fontSize: '1.3em',
    marginBottom: '0.5em',
  },
  tableContainer: {
    padding: '1em',
    backgroundColor: '#fff',
  },
  contentsWrapper: {
    backgroundColor: 'rgb(249, 251, 255)',
    padding: '1em',
    border: '1px solid #eee',
    overflow: 'auto',
  },
}

class PolicyVersionWidget extends React.Component {
  state = {
    selectedPolicyIndex: 0,
    selectedSubKey: 'allows',
  }

  getSelectedPolicyEntry = () => {
    return _.get(
      this.props.hasPolicyEntry,
      ['items', this.state.selectedPolicyIndex],
      {}
    )
  }

  getSelectedSubKey = () => {
    return this.state.selectedSubKey || 'allows'
  }

  getTableContents = () => {
    return _.get(
      this.getSelectedPolicyEntry(),
      ['hasPermissionList', 'items', 0, this.getSelectedSubKey(), 'items'],
      []
    )
  }

  setContent = (policyIndex, subkey) => {
    this.setState({
      selectedPolicyIndex: policyIndex,
      selectedSubKey: subkey,
    })

    if (this.props.recalculateGrid) {
      setTimeout(this.props.recalculateGrid, 100)
    }
  }

  renderPolicyEntryList = () => {
    return this.props.hasPolicyEntry.items.map((policyEntry, index) => {
      return (
        <PolicyEntryInfo
          key={policyEntry.name}
          policyEntry={policyEntry}
          index={index}
          setContent={this.setContent}
          active={index === this.state.selectedPolicyIndex}
          activeSubkey={this.getSelectedSubKey()}
          allowsLabel={`Allows (${_.get(
            policyEntry,
            ['hasPermissionList', 'items', 0, 'allows', 'items', 'length'],
            0
          )})`}
          deniesLabel={`Denies (${_.get(
            policyEntry,
            ['hasPermissionList', 'items', 0, 'denies', 'items', 'length'],
            0
          )})`}
        />
      )
    })
  }

  renderPermissionsList = permissions => {
    let x = []
    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i]

      if (_.get(permission, ['hasServiceType', 'items', 'length'], 0) > 0) {
        for (let j = 0; j < permission.hasServiceType.items.length; j++) {
          x.push({
            name: permission.name,
            serviceType: permission.hasServiceType.items[j].name,
          })
        }
      } else {
        x.push({
          name: permission.name,
          serviceType: '-',
        })
      }
    }

    return x.map((cell, i) => (
      <tr key={i}>
        <td>{cell.name}</td>
        <td> {cell.serviceType} </td>
      </tr>
    ))
  }

  render() {
    if (this.props.loading) {
      return <Card loading={true} title="Policy Entries" />
    }

    if (
      !this.props.hasPolicyEntry ||
      this.props.hasPolicyEntry.items.length === 0
    ) {
      return <InfoCard title="No versions" />
    }

    return (
      <Card>
        <TopTitle>
          <div style={styles.policyEntryTitle}>Policy Entries</div>
        </TopTitle>
        <CardBody
          style={{
            alignItems: 'start',
            justifyContent: 'start',
            height: 'auto',
            overflow: 'hidden',
          }}
        >
          <div style={styles.entryContainer}>
            <div style={styles.entryList}>{this.renderPolicyEntryList()}</div>
            <div style={styles.contentsWrapper}>
              <div id="permissions-list-title" style={styles.permissionsTitle}>
                {this.state.selectedSubKey === 'allows' ? 'Allows' : 'Denies'}
              </div>
              <div style={styles.tableContainer}>
                <Table>
                  <thead>
                    <tr>
                      <th>Permission</th>
                      <th> Service Type </th>
                    </tr>
                  </thead>
                  <tbody id="permissions-list">
                    {!_.isEmpty(this.getTableContents()) &&
                      this.renderPermissionsList(this.getTableContents())}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

PolicyVersionWidget.propTypes = {
  loading: PropTypes.bool,
  hasPolicyEntry: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        conditions: PropTypes.string,
        resourceFilter: PropTypes.string,
        allows: PropTypes.shape({
          items: PropTypes.array,
        }),
        denies: PropTypes.shape({
          items: PropTypes.array,
        }),
      })
    ),
  }),
  recalculateGrid: PropTypes.func,
}

export default PolicyVersionWidget
