/*
 *
 * Access Activity reducer
 *
 */

import { fromJS } from 'immutable'
import {
  GET_CAN_ASSUME_ROLES,
  SET_CAN_ASSUME_ROLES,
  ERR_CAN_ASSUME_ROLES,
  GET_CAN_BE_ASSUMED_BY,
  ERR_CAN_BE_ASSUMED_BY,
  SET_CAN_BE_ASSUMED_BY,
  GET_GROUP_MEMBERSHIPS,
  SET_GROUP_MEMBERSHIPS,
  ERR_GROUP_MEMBERSHIPS,
  GET_DIRECT_ATTACHED_POLICIES,
  SET_DIRECT_ATTACHED_POLICIES,
  ERR_DIRECT_ATTACHED_POLICIES,
  GET_SCPS,
  SET_SCPS,
  ERR_SCPS,
  GET_INSTANCE_PROFILES,
  SET_INSTANCE_PROFILES,
  ERR_INSTANCE_PROFILES,
  GET_PERMISSION_INFO,
  SET_PERMISSION_INFO,
} from './constants'
import { handleActions } from 'redux-actions'

export const initialState = fromJS({
  canAssumeRoles: {
    data: null,
    error: false,
    isLoading: false,
  },
  canBeAssumedBy: {
    data: null,
    error: false,
    isLoading: false,
  },
  groupMemberships: {
    data: null,
    error: false,
    isLoading: false,
  },
  directAttachedPolicies: {
    data: null,
    error: false,
    isLoading: false,
  },
  scps: {
    data: null,
    error: false,
    isLoading: false,
  },
  instanceProfiles: {
    data: null,
    error: false,
    isLoading: false,
  },
  permissions: {
    data: {},
    error: false,
    isLoading: false,
  },
})

const accessReducer = handleActions(
  {
    [GET_CAN_ASSUME_ROLES]: state =>
      state
        .setIn(['canAssumeRoles', 'error'], false)
        .setIn(['canAssumeRoles', 'isLoading'], true),
    [ERR_CAN_ASSUME_ROLES]: state =>
      state
        .setIn(['canAssumeRoles', 'isLoading'], false)
        .setIn(['canAssumeRoles', 'error'], true),
    [SET_CAN_ASSUME_ROLES]: (state, { payload }) =>
      state
        .setIn(['canAssumeRoles', 'isLoading'], false)
        .setIn(['canAssumeRoles', 'data'], payload.roles),

    [GET_CAN_BE_ASSUMED_BY]: state =>
      state
        .setIn(['canBeAssumedBy', 'error'], false)
        .setIn(['canBeAssumedBy', 'isLoading'], true),
    [ERR_CAN_BE_ASSUMED_BY]: state =>
      state
        .setIn(['canBeAssumedBy', 'isLoading'], false)
        .setIn(['canBeAssumedBy', 'error'], true),
    [SET_CAN_BE_ASSUMED_BY]: (state, { payload }) =>
      state
        .setIn(['canBeAssumedBy', 'isLoading'], false)
        .setIn(['canBeAssumedBy', 'data'], payload.actors),

    [GET_GROUP_MEMBERSHIPS]: state =>
      state
        .setIn(['groupMemberships', 'error'], false)
        .setIn(['groupMemberships', 'isLoading'], true),
    [ERR_GROUP_MEMBERSHIPS]: state =>
      state
        .setIn(['groupMemberships', 'isLoading'], false)
        .setIn(['groupMemberships', 'error'], true),
    [SET_GROUP_MEMBERSHIPS]: (state, { payload }) =>
      state
        .setIn(['groupMemberships', 'isLoading'], false)
        .setIn(['groupMemberships', 'data'], payload.groups),

    [GET_DIRECT_ATTACHED_POLICIES]: state =>
      state
        .setIn(['directAttachedPolicies', 'error'], false)
        .setIn(['directAttachedPolicies', 'isLoading'], true),
    [ERR_DIRECT_ATTACHED_POLICIES]: state =>
      state
        .setIn(['directAttachedPolicies', 'isLoading'], false)
        .setIn(['directAttachedPolicies', 'error'], true),
    [SET_DIRECT_ATTACHED_POLICIES]: (state, { payload }) =>
      state
        .setIn(['directAttachedPolicies', 'isLoading'], false)
        .setIn(['directAttachedPolicies', 'data'], payload.policies),

    [GET_SCPS]: state =>
      state.setIn(['scps', 'error'], false).setIn(['scps', 'isLoading'], true),
    [ERR_SCPS]: state =>
      state.setIn(['scps', 'isLoading'], false).setIn(['scps', 'error'], true),
    [SET_SCPS]: (state, { payload }) =>
      state
        .setIn(['scps', 'isLoading'], false)
        .setIn(['scps', 'data'], payload.scps),

    [GET_INSTANCE_PROFILES]: state =>
      state
        .setIn(['instanceProfiles', 'error'], false)
        .setIn(['instanceProfiles', 'isLoading'], true),
    [SET_INSTANCE_PROFILES]: (state, { payload }) =>
      state
        .setIn(['instanceProfiles', 'isLoading'], false)
        .setIn(['instanceProfiles', 'data'], payload.profiles),
    [ERR_INSTANCE_PROFILES]: state =>
      state
        .setIn(['instanceProfiles', 'isLoading'], false)
        .setIn(['instanceProfiles', 'error'], true),
    [GET_PERMISSION_INFO]: state =>
      state
        .setIn(['permissions', 'error'], false)
        .setIn(['permissions', 'isLoading'], true),

    [SET_PERMISSION_INFO]: (state, { payload }) =>
      state
        .setIn(['permissions', 'isLoading'], false)
        .setIn(['permissions', 'data', payload.srn], fromJS(payload)),
  },
  initialState
)

export default accessReducer
