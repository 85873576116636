/**
 *
 * ThemeManager
 *
 */

import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getDisplayName } from 'utils/hocHelpers'
import injectReducer from 'utils/injectReducer'

import { ThemeContext } from './ThemeContext'
import themeReducer from './reducer'
import sagas from './sagas'
import injectSaga from 'utils/injectSaga'
import { loadTheme } from './actions'
import { selectTheme, selectTypeColors } from './selectors'

const themeManager = WrappedComponent => {
  return class extends React.Component {
    displayName = `ThemeManager(${getDisplayName(WrappedComponent)})`

    render() {
      return (
        <ThemeContext.Provider
          value={{
            theme: this.props.theme.toJS(),
            typeColors: this.props.typeColors.toJS(),
          }}
        >
          <WrappedComponent {...this.props} />
        </ThemeContext.Provider>
      )
    }
  }
}

const withReducer = injectReducer({
  key: 'theme',
  reducer: themeReducer,
})

const mapStateToProps = createStructuredSelector({
  theme: selectTheme,
  typeColors: selectTypeColors,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadTheme,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withSaga = injectSaga({ key: 'themeSaga', saga: sagas })

export default compose(
  withConnect,
  withReducer,
  withSaga,
  themeManager
)
