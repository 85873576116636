import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  ASSIGN_TO_ME,
  ASSIGN_TO_ME_ERROR,
  ASSIGN_TO_ME_SUCCESS,
  ACCEPT_RISK_TICKET,
  ACCEPT_RISK_TICKET_ERROR,
  ACCEPT_RISK_TICKET_SUCCESS,
  CLOSE_TICKET,
  CLOSE_TICKET_ERROR,
  CLOSE_TICKET_SUCCESS,
  FETCH_TICKET_DETAILS,
  FETCH_TICKET_DETAILS_SUCCESS,
  FETCH_TICKET_DETAILS_ERROR,
  REOPEN_TICKET,
  REOPEN_TICKET_ERROR,
  REOPEN_TICKET_SUCCESS,
  SNOOZE_TICKET,
  SNOOZE_TICKET_ERROR,
  SNOOZE_TICKET_SUCCESS,
  FETCH_TICKET_COMMENTS,
  FETCH_TICKET_COMMENTS_SUCCESS,
  FETCH_TICKET_COMMENTS_ERROR,
  ADD_COMMENT,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_ERROR,
  FETCH_SIMILAR_TICKETS,
  FETCH_SIMILAR_TICKETS_ERROR,
  FETCH_SIMILAR_TICKETS_SUCCESS,
  CHANGE_SIMILAR_TICKETS_FILTERS,
  REASSIGN_TO_USER,
  REASSIGN_TO_USER_SUCCESS,
  REASSIGN_TO_USER_ERROR,
  FETCH_TICKET_HISTORY,
  FETCH_TICKET_HISTORY_SUCCESS,
  FETCH_TICKET_HISTORY_ERROR,
  FETCH_ACTIVITY_TICKET_ACTIONS,
  FETCH_ACTIVITY_TICKET_ACTIONS_SUCCESS,
  FETCH_ACTIVITY_TICKET_ACTIONS_ERROR,
  FETCH_POLICY_EVIDENCE_LEGACY,
  FETCH_POLICY_EVIDENCE_LEGACY_ERROR,
  FETCH_POLICY_EVIDENCE_LEGACY_SUCCESS,
  FETCH_POLICY_PATH,
  FETCH_POLICY_PATH_SUCCESS,
  FETCH_POLICY_PATH_ERROR,
  BULK_ACTION_COMPLETE,
  BULK_ACTION_START,
} from './constants'

export const initialState = fromJS({
  assignToMe: {
    error: false,
    loading: false,
  },
  ticketDetails: {
    data: null,
    error: false,
    loading: false,
  },
  acceptRiskTicketStatus: {
    loading: false,
    error: false,
  },
  closeTicketStatus: {
    loading: false,
    error: false,
  },
  reopenTicketStatus: {
    loading: false,
    error: false,
  },
  snoozeTicketStatus: {
    loading: false,
    error: false,
  },
  reassignToUserStatus: {
    loading: false,
    error: false,
  },
  ticketComments: {
    data: null,
    error: false,
    loading: false,
  },
  addCommentError: false,
  similarTickets: {
    data: [],
    filters: {},
    error: false,
    loading: false,
  },
  ticketHistory: {
    data: null,
    error: false,
    loading: false,
  },
  activityTicketActions: {
    loading: false,
    error: false,
    data: [],
  },
  policyEvidenceLegacy: {
    loading: false,
    error: false,
    data: null,
  },
  policyPath: {
    loading: false,
    error: false,
    data: null,
  },
  bulkActionWorking: false,
})

const ticketDetailsReducer = handleActions(
  {
    [ASSIGN_TO_ME]: state =>
      state
        .setIn(['assignToMe', 'loading'], true)
        .setIn(['assignToMe', 'error'], false),
    [ASSIGN_TO_ME_ERROR]: state =>
      state
        .setIn(['assignToMe', 'loading'], false)
        .setIn(['assignToMe', 'error'], true),
    [ASSIGN_TO_ME_SUCCESS]: state =>
      state
        .setIn(['assignToMe', 'loading'], false)
        .setIn(['assignToMe', 'error'], false),
    [ACCEPT_RISK_TICKET]: state =>
      state
        .setIn(['acceptRiskTicketStatus', 'loading'], true)
        .setIn(['acceptRiskTicketStatus', 'error'], false),
    [ACCEPT_RISK_TICKET_ERROR]: state =>
      state
        .setIn(['acceptRiskTicketStatus', 'loading'], false)
        .setIn(['acceptRiskTicketStatus', 'error'], true),
    [ACCEPT_RISK_TICKET_SUCCESS]: state =>
      state
        .setIn(['acceptRiskTicketStatus', 'loading'], false)
        .setIn(['acceptRiskTicketStatus', 'error'], false),
    [CLOSE_TICKET]: state =>
      state
        .setIn(['closeTicketStatus', 'loading'], true)
        .setIn(['closeTicketStatus', 'error'], false),
    [CLOSE_TICKET_ERROR]: state =>
      state
        .setIn(['closeTicketStatus', 'loading'], false)
        .setIn(['closeTicketStatus', 'error'], true),
    [CLOSE_TICKET_SUCCESS]: state => {
      return state
        .setIn(['closeTicketStatus', 'loading'], false)
        .setIn(['closeTicketStatus', 'error'], false)
    },

    [FETCH_TICKET_DETAILS]: state =>
      state
        .setIn(['ticketDetails', 'data'], null)
        .setIn(['ticketDetails', 'loading'], true)
        .setIn(['ticketDetails', 'error'], false),
    [FETCH_TICKET_DETAILS_ERROR]: state =>
      state
        .setIn(['ticketDetails', 'loading'], false)
        .setIn(['ticketDetails', 'error'], true)
        .setIn(['ticketDetails', 'data'], null),
    [FETCH_TICKET_DETAILS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['ticketDetails', 'loading'], false)
        .setIn(['ticketDetails', 'error'], false)
        .setIn(['ticketDetails', 'data'], fromJS(payload.data)),
    [FETCH_POLICY_PATH]: state =>
      state
        .setIn(['policyPath', 'loading'], true)
        .setIn(['policyPath', 'error'], false),
    [FETCH_POLICY_PATH_ERROR]: state =>
      state
        .setIn(['policyPath', 'loading'], false)
        .setIn(['policyPath', 'error'], true),
    [FETCH_POLICY_PATH_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['policyPath', 'loading'], false)
        .setIn(['policyPath', 'error'], false)
        .setIn(['policyPath', 'data'], fromJS(payload.data)),
    [REOPEN_TICKET]: state =>
      state
        .setIn(['reopenTicketStatus', 'loading'], true)
        .setIn(['reopenTicketStatus', 'error'], false),
    [REOPEN_TICKET_ERROR]: state =>
      state
        .setIn(['reopenTicketStatus', 'loading'], false)
        .setIn(['reopenTicketStatus', 'error'], true),
    [REOPEN_TICKET_SUCCESS]: state =>
      state
        .setIn(['reopenTicketStatus', 'loading'], false)
        .setIn(['reopenTicketStatus', 'error'], false),
    [SNOOZE_TICKET]: state =>
      state
        .setIn(['snoozeTicketStatus', 'loading'], true)
        .setIn(['snoozeTicketStatus', 'error'], false),
    [SNOOZE_TICKET_ERROR]: state =>
      state
        .setIn(['snoozeTicketStatus', 'loading'], false)
        .setIn(['snoozeTicketStatus', 'error'], true),
    [SNOOZE_TICKET_SUCCESS]: state =>
      state
        .setIn(['snoozeTicketStatus', 'loading'], false)
        .setIn(['snoozeTicketStatus', 'error'], false),
    [REASSIGN_TO_USER]: state =>
      state
        .setIn(['reassignToUserStatus', 'loading'], true)
        .setIn(['reassignToUserStatus', 'error'], false)
        .setIn(['ticketDetails', 'loading'], true),
    [REASSIGN_TO_USER_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['reassignToUserStatus', 'loading'], false)
        .setIn(['reassignToUserStatus', 'error'], false)
        .setIn(['ticketDetails', 'loading'], false)
        .setIn(['ticketDetails', 'data'], fromJS(payload)),
    [REASSIGN_TO_USER_ERROR]: state =>
      state
        .setIn(['reassignToUserStatus', 'loading'], false)
        .setIn(['ticketDetails', 'loading'], false)
        .setIn(['reassignToUserStatus', 'error'], true),
    [FETCH_TICKET_COMMENTS]: state =>
      state
        .setIn(['ticketComments', 'data'], null)
        .setIn(['ticketComments', 'error'], false)
        .setIn(['ticketComments', 'loading'], true),
    [FETCH_TICKET_COMMENTS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['ticketComments', 'data'], fromJS(payload))
        .setIn(['ticketComments', 'loading'], false),
    [FETCH_TICKET_COMMENTS_ERROR]: state =>
      state
        .setIn(['ticketComments', 'data'], null)
        .setIn(['ticketComments', 'error'], true)
        .setIn(['ticketComments', 'loading'], false),
    [ADD_COMMENT]: state => state.setIn(['ticketComments', 'loading'], true),
    [ADD_COMMENT_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['ticketComments', 'loading'], false)
        .updateIn(['ticketComments', 'data'], comments =>
          comments.push(fromJS(payload))
        ),
    [ADD_COMMENT_ERROR]: state => state.set('addCommentError', true),
    [FETCH_SIMILAR_TICKETS]: state =>
      state
        .setIn(['similarTickets', 'loading'], true)
        .setIn(['similarTickets', 'error'], false),
    [FETCH_SIMILAR_TICKETS_ERROR]: state =>
      state
        .setIn(['similarTickets', 'loading'], false)
        .setIn(['similarTickets', 'error'], true),
    [FETCH_SIMILAR_TICKETS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['similarTickets', 'loading'], false)
        .setIn(['similarTickets', 'error'], false)
        .setIn(['similarTickets', 'data'], fromJS(payload.data)),
    [CHANGE_SIMILAR_TICKETS_FILTERS]: (state, { payload }) =>
      state
        .setIn(['similarTickets', 'filters'], fromJS(payload.filter))
        .setIn(['similarTickets', 'loading'], true),
    [FETCH_TICKET_HISTORY]: state =>
      state.setIn(['ticketHistory', 'loading'], true),
    [FETCH_TICKET_HISTORY_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['ticketHistory', 'loading'], false)
        .setIn(['ticketHistory', 'error'], false)
        .setIn(['ticketHistory', 'data'], fromJS(payload)),
    [FETCH_TICKET_HISTORY_ERROR]: state => {
      return state
        .setIn(['ticketHistory', 'loading'], false)
        .setIn(['ticketHistory', 'error'], true)
        .setIn(['ticketHistory', 'data'], null)
    },
    [ADD_COMMENT_ERROR]: state => state.set('addCommentError', true),
    [FETCH_ACTIVITY_TICKET_ACTIONS]: state =>
      state
        .setIn(['activityTicketActions', 'loading'], true)
        .setIn(['activityTicketActions', 'error'], false),
    [FETCH_ACTIVITY_TICKET_ACTIONS_ERROR]: state =>
      state
        .setIn(['activityTicketActions', 'loading'], false)
        .setIn(['activityTicketActions', 'error'], true),
    [FETCH_ACTIVITY_TICKET_ACTIONS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['activityTicketActions', 'loading'], false)
        .setIn(['activityTicketActions', 'error'], false)
        .setIn(['activityTicketActions', 'data'], fromJS(payload.data)),

    [FETCH_POLICY_EVIDENCE_LEGACY]: state =>
      state
        .setIn(['policyEvidenceLegacy', 'loading'], true)
        .setIn(['policyEvidenceLegacy', 'error'], false),
    [FETCH_POLICY_EVIDENCE_LEGACY_ERROR]: state =>
      state
        .setIn(['policyEvidenceLegacy', 'loading'], false)
        .setIn(['policyEvidenceLegacy', 'error'], true),
    [FETCH_POLICY_EVIDENCE_LEGACY_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['policyEvidenceLegacy', 'loading'], false)
        .setIn(['policyEvidenceLegacy', 'error'], false)
        .setIn(['policyEvidenceLegacy', 'data'], fromJS(payload.data)),
    [BULK_ACTION_START]: state => state.set('bulkActionWorking', true),
    [BULK_ACTION_COMPLETE]: state => state.set('bulkActionWorking', false),
  },
  initialState
)

export default ticketDetailsReducer
