import { fromJS, Map } from 'immutable'

import {
  FETCH_QUICK_SEARCH,
  SET_QUICK_SEARCH_RESULTS,
  QUICK_SEARCH_LOADING,
  CLEAR_QUICK_SEARCH_RESULTS,
  RESULT_LIMIT_EXCEEDED,
  SET_QUICK_SEARCH_ERROR,
} from './constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  resultLimitExceeded: false,
  quickSearchLoading: false,
  error: null,
  quickSearchResults: {},
})

const quicksearchReducer = handleActions(
  {
    [SET_QUICK_SEARCH_ERROR]: (state, { payload }) =>
      state.set('error', payload),
    [SET_QUICK_SEARCH_RESULTS]: (state, { payload }) =>
      state.setIn(
        ['quickSearchResults', payload.type],
        fromJS(payload.results)
      ),
    [CLEAR_QUICK_SEARCH_RESULTS]: state =>
      state
        .set('quickSearchResults', Map())
        .set('resultLimitExceeded', false)
        .set('error', null),
    [RESULT_LIMIT_EXCEEDED]: state => state.set('resultLimitExceeded', true),
    [FETCH_QUICK_SEARCH]: state =>
      state
        .set('resultLimitExceeded', false)
        .set('quickSearchResults', Map())
        .set('error', null),
    [QUICK_SEARCH_LOADING]: (state, { payload }) => {
      return state.set('quickSearchLoading', payload)
    },
  },
  initialState
)

export default quicksearchReducer
