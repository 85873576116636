import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'

import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import AwsVaultSecret from './AwsVaultSecret'
const SECRET_TYPES = {
  VAULT_AWS: 'HashiCorpVaultAWS',
}

export class SecretNodeViewCardLayout extends React.Component {
  getLayouts = () => {
    return {
      graph: {
        lg: { w: 8, h: 3 },
        md: { w: 7, h: 3 },
        sm: { w: 6, h: 3 },
        xs: { w: 4, h: 3 },
      },
    }
  }

  getGridContent = () => {
    return []
  }

  render() {
    if (this.props.nodeData.type === SECRET_TYPES.VAULT_AWS) {
      return <AwsVaultSecret {...this.props} />
    }

    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          layouts={this.getLayouts()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
          renderDetailsTab={false}
        />
      </Fragment>
    )
  }
}

SecretNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  setUserProfileTables: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserProfileTables,
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  injectIntl
)(SecretNodeViewCardLayout)
