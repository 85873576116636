import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import { REDUX_KEY } from './reducers'
import { defaultInitialNodeState } from './reducers'

export const selectNodeData = (state, props) => {
  const nodeData = state.getIn(
    [REDUX_KEY, 'nodes', props.nodeId],
    fromJS({ ...defaultInitialNodeState, default: true })
  )
  return nodeData
}

export const makeSelectNodeDataIsLoading = () =>
  createSelector(
    selectNodeData,
    nodeData => nodeData.get('loading')
  )

export const makeSelectNodeData = () =>
  createSelector(
    selectNodeData,
    nodeData => {
      const nodeDataData = nodeData.get('data')
      return nodeDataData.toJS()
    }
  )

export const makeSelectNodeDataError = () =>
  createSelector(
    selectNodeData,
    nodeData => nodeData.get('error')
  )

export const makeSelectUpdateFriendlyName = () =>
  createSelector(
    selectNodeData,
    nodeData => nodeData.get('updateFriendlyName').toJS()
  )

export const makeSelectCrmData = () =>
  createSelector(
    selectNodeData,
    nodeData => nodeData.get('crmData').toJS()
  )

export const makeSelectKeyVault = () =>
  createSelector(
    selectNodeData,
    nodeData => nodeData.get('keyVault')
  )

export const makeSelectResourceGroup = () =>
  createSelector(
    selectNodeData,
    nodeData => nodeData.get('resourceGroup').toJS()
  )

export const makeSelectSwimlanes = () =>
  createSelector(
    selectNodeData,
    nodeData => nodeData.get('swimlanes').toJS()
  )

export const makeSelectTags = () =>
  createSelector(
    selectNodeData,
    nodeData => nodeData.get('tags').toJS()
  )
