import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Input } from 'reactstrap'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
// import BorderCard from 'components/BorderCard'
import Button from 'components/Button'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import { Map } from 'immutable'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import InlineEditableText from 'components/InlineEditableText'
import { Alert } from 'reactstrap'
import BorderlessButton from 'components/BorderlessButton'

export class KeyVaultConfig extends ImmutablePureComponent {
  styles = {
    addCredForm: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: 'auto auto auto',
      gridColumnGap: '1em',
      gridRowGap: '1em',
      gridTemplateAreas: '". ." ". ." ". ." ". submit"',
    },
    addButtonWrapper: {
      gridArea: 'submit',
    },
    existingContainer: {
      marginBottom: '1em',
      maxHeight: '400px',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    cardContainer: {
      // backgroundColor: this.props.theme.neutralLight,
      marginBottom: '10px',
    },
    rowContainer: {
      display: 'grid',
      gridTemplateColumns: '115px 1fr',
      whiteSpace: 'nowrap',
    },
    title: { fontWeight: '400', marginTop: '8px', textAlign: 'right' },
    content: {
      display: 'inline-block',
      marginLeft: '5px',
      textOverflow: 'ellipsis',
      overflowX: 'hidden',
      backgroundColor: this.props.theme.highlight,
      borderRadius: '5px',
    },
    name: {
      paddingLeft: '0.3em',
      width: '100%',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      alignItems: 'center',
    },
  }

  state = {
    containerName: '',
    path: '',
    reset: false,
    editingNames: Map(),
    editingPaths: Map(),
  }

  componentDidUpdate() {
    if (!this.props.isAdding && this.state.reset) {
      this.setState({
        containerName: '',
        path: '',
        reset: false,
      })
    }
  }

  setEditingName = (id, name) => {
    this.setState(prevState => {
      return { editingNames: prevState.editingNames.set(id, name) }
    })
  }

  setEditingPath = (id, path) => {
    this.setState(prevState => {
      return {
        editingPaths: prevState.editingPaths.set(id, path),
      }
    })
  }

  setContainerName = e => {
    this.setState({
      containerName: e.target.value,
    })
  }

  setPath = e => {
    this.setState({
      path: e.target.value.replace(/ /g, ''),
    })
  }

  addVaultCreds = () => {
    this.setState({ reset: true })

    this.props.addAzureKeyVaultCreds({
      srn: this.props.srn,
      name: this.state.containerName,
      path: this.state.path,
    })
  }

  removeVaultCreds = id => {
    this.props.removeAzureKeyVaultCreds({
      id: id,
      srn: this.props.srn,
    })
  }

  updateName = (id, name, path) => {
    this.setEditingName(id, name)
    this.props.updateAzureKeyVaultCreds({
      srn: this.props.srn,
      id: id,
      containerName: name,
      containerPrefix: path.replace(/ /g, ''),
    })
  }

  updatePath = (id, path, name) => {
    this.setEditingPath(id, path)
    this.props.updateAzureKeyVaultCreds({
      srn: this.props.srn,
      id: id,
      containerName: name,
      containerPrefix: path.replace(/ /g, ''),
    })
  }

  dismissError = () => {
    this.props.setAzureKeyVaultError({ error: null })
  }

  render() {
    const keyVaultCreds = this.props.keyVaultCreds.toJS()

    const submitDisabled = !this.state.containerName || !this.state.path

    return (
      <div>
        {this.props.error && (
          <Alert
            style={{
              width: '100%',
              textAlign: 'centers',
              display: 'grid',
              gridTemplateColumns: '1fr auto',
            }}
            color="danger"
          >
            <div>{this.props.error}</div>
            <BorderlessButton onClick={this.dismissError}>
              <Icon fa name={'times'} transform={'shrink-2'} />
            </BorderlessButton>
          </Alert>
        )}
        {keyVaultCreds.length > 0 ? (
          <div style={this.styles.existingContainer}>
            <div style={{ margin: '5px' }}>
              {keyVaultCreds.map(creds => {
                const deleting = !!this.props.isDeleting.get(creds.id)
                const updating = !!this.props.isUpdating.get(creds.id)

                const nameValue =
                  updating && this.state.editingNames.get(creds.id)
                    ? this.state.editingNames.get(creds.id)
                    : creds.blob.containerName
                const pathValue =
                  updating && this.state.editingPaths.get(creds.id)
                    ? this.state.editingPaths.get(creds.id)
                    : creds.blob.containerPrefix

                if (
                  creds.blob.containerName ===
                  this.state.editingNames.get(creds.id)
                ) {
                  this.setState(prevState => {
                    return {
                      editingNames: prevState.editingNames.delete(creds.id),
                    }
                  })
                }

                if (
                  creds.blob.containerPrefix ===
                  this.state.editingPaths.get(creds.id)
                ) {
                  this.setState(prevState => {
                    return {
                      editingPaths: prevState.editingPaths.delete(creds.id),
                    }
                  })
                }

                return (
                  <div
                    key={`div-${creds.id}`}
                    // style={{
                    //   display: 'grid',
                    //   gridTemplateColumns: '640px auto',
                    // }}
                  >
                    <div
                      key={`card-${creds.id}`}
                      style={this.styles.cardContainer}
                    >
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr auto',
                        }}
                      >
                        <div style={this.styles.rowContainer}>
                          <div style={this.styles.title}>KeyVault Name:</div>
                          <div
                            style={{
                              ...this.styles.content,
                            }}
                          >
                            <InlineEditableText
                              activeNode
                              value={nameValue}
                              onSubmit={name => {
                                if (creds.blob.containerName !== name) {
                                  this.updateName(creds.id, name, pathValue)
                                }
                              }}
                              style={this.styles.name}
                              saving={updating || deleting}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={this.styles.rowContainer}>
                        <div style={this.styles.title}>Secret Name:</div>
                        <div
                          style={{
                            ...this.styles.content,
                          }}
                        >
                          <InlineEditableText
                            activeNode
                            value={pathValue}
                            onSubmit={path => {
                              if (creds.blob.containerPrefix !== path) {
                                this.updatePath(creds.id, path, nameValue)
                              }
                            }}
                            style={this.styles.name}
                            saving={updating || deleting}
                            trim={true}
                          />
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={() => {
                        this.removeVaultCreds(creds.id)
                      }}
                      style={{ marginLeft: '5px', float: 'right' }}
                      disabled={deleting}
                      color={`danger`}
                    >
                      <div style={{ float: 'left' }}>Delete</div>
                      {!deleting ? (
                        <Icon
                          style={{ float: 'right', marginLeft: '10px' }}
                          transform={'shrink-4'}
                          fa
                          name={'trash-alt'}
                        />
                      ) : (
                        <Icon
                          style={{ float: 'right', marginLeft: '10px' }}
                          transform={'shrink-4'}
                          fa
                          spin
                          name={'sync'}
                        />
                      )}
                    </Button>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div style={this.styles.addCredForm}>
            <div style={this.styles.title}>Configure New Credentials:</div>
            <div>&nbsp;</div>
            <FormLabel required for="containerName">
              KeyVault Name
            </FormLabel>
            <Input
              name="containerName"
              value={this.state.containerName}
              onChange={this.setContainerName}
              disabled={this.props.isAdding}
            />
            <FormLabel required for="path">
              Secret Name
            </FormLabel>
            <Input
              name="path"
              value={this.state.path}
              onChange={this.setPath}
              disabled={this.props.isAdding}
            />

            <div style={this.styles.addButtonWrapper}>
              <Button
                color="primary"
                onClick={this.addVaultCreds}
                disabled={this.props.isAdding || submitDisabled}
              >
                {this.props.isAdding
                  ? 'Adding Credentials..'
                  : 'Add Credentials'}
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

KeyVaultConfig.propTypes = {
  isAdding: PropTypes.bool,
  srn: PropTypes.string,
  theme: themeShape,
  removeAzureKeyVaultCreds: PropTypes.func,
  keyVaultCreds: ImmutablePropTypes.list,
  isDeleting: ImmutablePropTypes.map,
  setAzureKeyVaultError: PropTypes.func,
  error: PropTypes.string,
}

export default themeable(KeyVaultConfig)
