import React from 'react'
import PropTypes from 'prop-types'
import makeAnimated from 'react-select/animated'
import CreatableSelect from 'react-select/creatable'
import BorderlessButton from 'components/BorderlessButton'
import IHelp from 'containers/IHelp'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Icon from 'components/Icon'
export class NodeSearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      placeholder: 'Search All Nodes...',
      colourStyles: {
        multiValue: styles => ({
          ...styles,
          backgroundColor: 'rgba(27, 117, 188, 0.45)',
        }),
        multiValueLabel: styles => ({
          ...styles,
          color: '#FFFFFF',
          fontWeight: '400',
          fontSize: '1rem',
        }),
        multiValueRemove: styles => ({
          ...styles,
          color: '#FFFFFF',
          opacity: 1,
          ':hover': {
            backgroundColor: this.props.theme.red,
            color: '#FFFFFF',
          },
        }),
      },
    }

    this.styles = {
      iconContainer: {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
      },

      filterIcon: {
        marginRight: '0.5em',
      },
    }
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      const isNewVal = newValue[newValue.length - 1]
      const value = { label: isNewVal.label, value: isNewVal.value }
      const existingSearches =
        JSON.parse(localStorage.getItem('searches')) || []
      const list = [...existingSearches, value]

      if (list.length > 99) {
        list.shift()
      }
      localStorage.setItem('searches', JSON.stringify(list))
    }

    this.props.setTerms(newValue)
  }

  onMenuOpen = () => {
    this.setState({ placeholder: 'Recent Searches...' })
  }

  onMenuClose = () => {
    this.setState({ placeholder: 'Search Nodes...' })
  }

  render() {
    const localStorageValues =
      JSON.parse(localStorage.getItem('searches')) || []
    const selectOptions =
      localStorageValues.length > 3
        ? localStorageValues
        : [
            { label: 'Cloud', value: 'Cloud' },
            { label: 'Service', value: 'Service' },
            { label: 'Action', value: 'Action' },
          ]

    return (
      <div style={{ ...this.styles.iconContainer, ...this.props.style }}>
        <BorderlessButton
          style={this.styles.filterIcon}
          onClick={this.props.toggleFilterPanel}
          title="Global Filter"
        >
          <Icon
            fa
            name={this.props.filterActive ? 'filter-solid' : 'filter'}
            color={this.props.filterActive && this.props.theme.emphasis}
          />
        </BorderlessButton>
        <div style={{ width: '50vw', maxWidth: '500px' }}>
          <CreatableSelect
            isMulti
            components={makeAnimated()}
            onChange={this.handleChange}
            options={selectOptions}
            styles={this.state.colourStyles}
            onMenuOpen={this.onMenuOpen}
            onMenuClose={this.onMenuClose}
            placeholder={this.state.placeholder}
          />
        </div>
        <div style={{ marginLeft: '7px' }}>
          <IHelp
            help
            helpKey="explorer"
            position="right"
            iconSize="1.4rem"
            id="explorer-help"
          />
        </div>
      </div>
    )
  }
}

NodeSearch.defaultProps = {
  style: {},
}

NodeSearch.propTypes = {
  setTerms: PropTypes.func.isRequired,
  style: PropTypes.object,
  theme: themeShape,
  filterActive: PropTypes.bool,
  toggleFilterPanel: PropTypes.func,
}

export default themeable(NodeSearch)
