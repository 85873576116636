import { createSelector } from 'reselect'
import { Map, fromJS } from 'immutable'

const selectUserProfileDataDomain = state =>
  state !== undefined ? state.get('userProfileData', Map()) : fromJS({})

export const selectUserProfile = createSelector(
  selectUserProfileDataDomain,
  sonraiData => sonraiData.get('userProfile') || Map()
)

export const selectSMOpen = createSelector(
  selectUserProfileDataDomain,
  sonraiData => sonraiData.get('swimlaneManagerOpen')
)

export const selectSonraiUserSrn = createSelector(
  selectUserProfileDataDomain,
  sonraiData => sonraiData.get('sonraiUserSrn')
)

export const selectUserProfileError = createSelector(
  selectUserProfileDataDomain,
  sonraiData => sonraiData.get('error')
)
