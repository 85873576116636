import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { PlainCheckbox } from 'components/Checkbox'
import ToolbarDivider from './ToolbarDivider'

export const InactiveToolbar = ({
  children,
  style,
  selectedItems,
  totalItems,
  showCheckbox,
  toggleSelectAll,
}) => {
  const styles = {
    toolbar: {
      height: '35px',
      padding: '0 0.5em',
      display: 'flex',
      alignItems: 'center',
    },
  }

  return (
    <div className="flip-in-hor-bottom" style={{ ...styles.toolbar, ...style }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {showCheckbox && toggleSelectAll && (
          <Fragment>
            <PlainCheckbox
              checked={selectedItems.length > 0}
              semiChecked={
                selectedItems.length > 0 &&
                selectedItems.length !== totalItems.length
              }
              onChange={toggleSelectAll}
            />
            &nbsp;Select All
            <ToolbarDivider />
          </Fragment>
        )}
      </div>

      {React.Children.map(children, child => {
        if (!child) {
          return null
        }

        return React.cloneElement(child, {
          disabled: true,
        })
      })}
    </div>
  )
}

InactiveToolbar.defaultProps = {
  style: {},
  selectedItems: [],
  totalItems: [],
  showCheckbox: true,
}

InactiveToolbar.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  selectedItems: PropTypes.array,
  totalItems: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  toggleSelectAll: PropTypes.func,
  showCheckbox: PropTypes.bool,
}
export default InactiveToolbar
