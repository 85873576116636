import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

import styles from './styles'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class BorderlessButton extends React.PureComponent {
  getColor = () => {
    if (this.props.disabled) {
      return this.props.theme.neutralMedium
    }

    if (this.props.color === 'primary') {
      return this.props.theme.primary
    }

    if (this.props.color === 'secondary') {
      return this.props.theme.secondary
    }
  }

  render() {
    const {
      children,
      style,
      theme, //eslint-disable-line no-unused-vars
      className,
      typeColors, //eslint-disable-line no-unused-vars
      ...pprops
    } = this.props

    if (this.props.to) {
      return (
        <Link to={this.props.to}>
          <Button
            style={{
              ...styles.noBorderButton,
              ...{ color: this.getColor() },
              ...style,
            }}
            className={`borderless-button ${className}`}
            {...pprops}
          >
            {this.props.children}
          </Button>
        </Link>
      )
    }

    return (
      <Button
        style={{
          ...styles.noBorderButton,
          ...{ color: this.getColor() },
          ...style,
        }}
        className={`borderless-button ${className}`}
        {...pprops}
      >
        {children}
      </Button>
    )
  }
}

BorderlessButton.defaultProps = {
  className: '',
}

BorderlessButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  theme: themeShape,
  typeColors: PropTypes.object,
  to: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.any,
  }),
  style: PropTypes.object,
}

export default themeable(BorderlessButton)
