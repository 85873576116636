import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'

export class Scrollable extends Component {
  constructor(props) {
    super(props)

    this.scrollbarRef = React.createRef()

    this.state = {
      shadowTopOpacity: 0,
      shadowLeftOpacity: 0,
      shadowRightOpacity: 0,
      shadowBottomOpacity: 0,
    }

    this.styles = {
      container: {
        ...props.style,
        position: 'relative',
      },
      shadowTop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 10,
        background:
          'radial-gradient(ellipse at top, rgba(200,200,200,0.8) 0%, rgba(0, 0, 0, 0) 70%)',
      },
      shadowBottom: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 10,
        background:
          'radial-gradient(ellipse at bottom, rgba(200,200,200,0.8) 0%, rgba(0, 0, 0, 0) 70%)',
      },
      shadowLeft: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: 10,
        background:
          'radial-gradient(ellipse at 0%, rgba(200,200,200,0.8) 0%, rgba(0, 0, 0, 0) 80%)',
      },
      shadowRight: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: 10,
        background:
          'radial-gradient(ellipse at 100%, rgba(200,200,200,0.8) 0%, rgba(0, 0, 0, 0) 80%)',
      },
    }
  }

  handleMouseScroll = e => {
    if (e.deltaY !== 0) {
      const currentLeft = this.scrollbarRef.current.getScrollLeft()
      this.scrollbarRef.current.scrollLeft(e.deltaY + currentLeft)
      e.preventDefault()
    }
  }

  handleUpdate = values => {
    const {
      scrollTop,
      scrollHeight,
      scrollLeft,
      scrollWidth,
      clientHeight,
      clientWidth,
    } = values
    const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20)
    const bottomScrollTop = scrollHeight - clientHeight
    const shadowBottomOpacity =
      (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20))

    const shadowLeftOpacity = (1 / 20) * Math.min(scrollLeft, 20)
    const rightScrollStart = scrollWidth - clientWidth
    const shadowRightOpacity =
      (1 / 20) *
      (rightScrollStart - Math.max(scrollLeft, rightScrollStart - 20))

    this.setState({
      shadowTopOpacity,
      shadowBottomOpacity,
      shadowRightOpacity,
      shadowLeftOpacity,
    })
  }

  render() {
    const { horizontalScrollOnly, noShadow, ...props } = this.props
    return (
      <div
        style={this.styles.container}
        onWheel={horizontalScrollOnly ? this.handleMouseScroll : undefined}
      >
        {!noShadow && (
          <Fragment>
            <div
              style={{
                opacity: horizontalScrollOnly ? 0 : this.state.shadowTopOpacity,
                ...this.styles.shadowTop,
              }}
            />
            <div
              style={{
                opacity: horizontalScrollOnly
                  ? 0
                  : this.state.shadowBottomOpacity,
                ...this.styles.shadowBottom,
              }}
            />
            <div
              style={{
                opacity: this.state.shadowLeftOpacity,
                ...this.styles.shadowLeft,
              }}
            />
            <div
              style={{
                opacity: this.state.shadowRightOpacity,
                ...this.styles.shadowRight,
              }}
            />
          </Fragment>
        )}

        <Scrollbars
          renderTrackVertical={
            horizontalScrollOnly ? () => <span /> : undefined
          }
          ref={this.scrollbarRef}
          onUpdate={this.handleUpdate}
          {...props}
        />
      </div>
    )
  }
}

Scrollable.propTypes = {
  horizontalScrollOnly: PropTypes.bool,
  style: PropTypes.object,
  noShadow: PropTypes.bool,
}

export default Scrollable
