import React from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

const CodeBlock = ({ theme, children }) => {
  const style = {
    wrapper: {
      padding: '0.7em',
      fontFamily: 'monospace',
      backgroundColor: theme.neutralLight,
      whiteSpace: 'pre',
      borderRadius: '3px',
    },
  }

  return <div style={style.wrapper}>{children}</div>
}

CodeBlock.propTypes = {
  children: PropTypes.node,
  theme: themeShape,
}

export default themeable(CodeBlock)
