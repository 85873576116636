import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import BorderlessButton from 'components/BorderlessButton'
import { TopTitle } from 'components/Card'
import Card from 'components/Card'
import DataTable from 'components/DataTable'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'
import NodeViewMiniPopout from '../NodeViewMiniPopout'
import { getNameFromSrn } from 'utils/sonraiUtils'

class PerformedActions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      miniNodeViewNodeId: '',
      miniNodeViewOpen: false,
    }

    this.styles = {
      header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      title: {
        fontSize: '22px',
        fontWeight: '300',
        marginRight: '0px',
      },
      buttonContainer: {
        display: 'flex',
        alignItem: 'center',
        position: 'absolute',
        top: '10px',
        right: '10px',
      },
      button: {
        borderRadius: '25px',
        margin: '0.5rem',
        padding: '0.5rem',
      },
      buttonText: {
        fontSize: '0.85rem',
      },
      content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '15px',
      },
    }
  }

  openMiniNodeView = nodeId => {
    this.setState({
      miniNodeViewNodeId: nodeId,
      miniNodeViewOpen: true,
    })
  }

  closeMiniNodeView = () => {
    this.setState({
      miniNodeViewNodeId: '',
      miniNodeViewOpen: false,
    })
  }

  getData = data => {
    if (data) {
      return data.map(item => ({
        srn: item.srn,
        eventName: item.eventName || '-',
        actionClassification: item.hasActionClassificationValue || '-',
        createdDate: item.createdDate || '-',
        ..._.omit(item, [
          'srn',
          'eventName',
          'hasActionClassificationValue',
          'createdDate',
        ]),
      }))
    }

    return []
  }

  renderPerformedLink = params => {
    if (typeof params.value === 'string') {
      if (params.value.startsWith('srn:')) {
        return (
          <TextLink
            color="primary"
            onClick={() => {
              this.openMiniNodeView(params.value)
            }}
          >
            {getNameFromSrn(params.value)}
          </TextLink>
        )
      } else {
        return params.value
      }
    }

    if (Array.isArray(params.value)) {
      if (params.value.length > 1) {
        return <span style={{ fontStyle: 'italic' }}>(Multiple)</span>
      } else if (params.value.length === 0) {
        return '-'
      } else {
        const srn = params.value[0]
        return (
          <TextLink
            color="primary"
            onClick={() => {
              this.openMiniNodeView(srn)
            }}
          >
            {getNameFromSrn(srn)}
          </TextLink>
        )
      }
    }

    return params.value || '-'
  }

  render() {
    const {
      performedActionFields,
      togglePerformedActionFields,
      isLoading,
    } = this.props
    return (
      <Card>
        <div style={this.styles.header}>
          <TopTitle style={this.styles.title}> Actions </TopTitle>
          <div style={this.styles.buttonContainer}>
            <BorderlessButton
              onClick={() => togglePerformedActionFields('performedByValue')}
              style={{
                ...this.styles.button,
                ...{
                  background: performedActionFields.includes('performedByValue')
                    ? this.props.theme.secondary
                    : this.props.theme.neutralLight,
                },
              }}
            >
              <span
                style={{
                  ...this.styles.buttonText,
                  ...{
                    color: performedActionFields.includes('performedByValue')
                      ? '#FFF'
                      : this.props.theme.neutralMedium,
                  },
                }}
              >
                Actions this resource performed
              </span>
            </BorderlessButton>
            <BorderlessButton
              onClick={() => togglePerformedActionFields('performedOnValue')}
              style={{
                ...this.styles.button,
                ...{
                  background: performedActionFields.includes('performedOnValue')
                    ? this.props.theme.secondary
                    : this.props.theme.neutralLight,
                },
              }}
            >
              <span
                style={{
                  ...this.styles.buttonText,
                  ...{
                    color: performedActionFields.includes('performedOnValue')
                      ? '#FFF'
                      : this.props.theme.neutralMedium,
                  },
                }}
              >
                Actions performed on this resource
              </span>
            </BorderlessButton>
          </div>
        </div>
        <div style={this.styles.content}>
          {isLoading ? (
            <SquareLoadingAnimation />
          ) : (
            <DataTable
              onClickNodeView={this.props.onClickNodeView}
              hiddenColumns={['srn']}
              data={this.getData(this.props.data)}
              customColumnConfig={{
                performedOnValue: {
                  cellRendererFramework: this.renderPerformedLink,
                },
                performedByValue: {
                  cellRendererFramework: this.renderPerformedLink,
                },
              }}
            />
          )}
          <NodeViewMiniPopout
            handleClose={this.closeMiniNodeView}
            open={this.state.miniNodeViewOpen}
            nodeId={this.state.miniNodeViewNodeId}
          />
        </div>
      </Card>
    )
  }
}

PerformedActions.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  performedActionFields: ImmutablePropTypes.iterable,
  togglePerformedActionFields: PropTypes.func,
  onClickNodeView: PropTypes.func,
  theme: themeShape,
}

export default themeable(PerformedActions)
