import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Map, List, isImmutable } from 'immutable'
import _ from 'lodash'

import BarLoadingAnimation from 'components/BarLoadingAnimation'
import TicketNotFound from './components/TicketNotFound'
import TicketDetailsHeader from './TicketDetailsHeader'
import TicketDetailsTabs from './TicketDetailsTabs'
import TicketDetailsEscalation from './TicketDetailsEscalation'
import { fetchTicketDetails } from 'containers/TicketDetailsData/actions'
import {
  selectTicketDetails,
  selectActionStatus,
} from 'containers/TicketDetailsData/selectors'
import { selectPolicies } from 'containers/ControlFrameworkData/selectors'
import { REMEDIATION_TYPES } from 'appConstants'

import RemediationModal from './RemediationModal'

class Ticket extends React.Component {
  constructor(props, context) {
    super(props, context)

    if (props.ticketSrn) {
      props.fetchTicketDetails({ srn: props.ticketSrn })
    }

    this.style = {
      content: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '0.5fr auto',
        gridTemplateAreas: '"header" "body"',
        gridGap: '1rem',
        overflow: 'auto',
        width: '100%',
      },
      body: {
        display: 'grid',
        gridTemplateColumns: '1.5fr 0.5fr',
        gridTemplateAreas: 'tabs escalation',
        gridGap: '1rem',
      },
      loadingContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }

    this.state = {
      showRemediationModal: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.ticketDetails.get('data') &&
      prevProps.ticketDetails.getIn(['data', 'status']) !==
        this.props.ticketDetails.getIn(['data', 'status'])
    ) {
      this.props.fetchTicketDetails({ srn: this.props.ticketSrn })
    }

    if (
      prevProps.actionStatus.get('loading') &&
      !this.props.actionStatus.get('loading')
    ) {
      // we could take this out if all the action endpoints return the updated ticket obj -- for ex: snooze ticket returns bool
      this.props.fetchTicketDetails({ srn: this.props.ticketSrn })
    }
  }

  showRemediationModal = () => {
    this.setState({
      showRemediationModal: true,
    })
  }

  hideRemediationModal = () => {
    this.setState({
      showRemediationModal: false,
    })
  }

  hasRemediation = () => {
    const ticket = this.props.ticketDetails.get('data') || Map()
    const ticketPolicy = this.props.policies.get(ticket.get('ticketKey'))

    if (!ticketPolicy) {
      return false
    }

    const isUpdatePolicy =
      ticketPolicy.get('remediationType') === REMEDIATION_TYPES.UPDATE_POLICY

    if (isUpdatePolicy) {
      const evidence = ticket.getIn(['evidence', 'policyEvidence'], '')
      const policyEvidence = isImmutable(evidence) ? evidence.toJS() : evidence

      let currentPol = _.get(policyEvidence, ['data', 0, 'currentPolicy'])
      const suggestedPol = _.get(policyEvidence, ['data', 0, 'policy'])

      if (currentPol && suggestedPol) {
        return true
      }
    }

    //TODO: replace this with a check for the remediation steps on the ticket itself (a new field)
    const hasManualRemediations =
      ticketPolicy.get('hasManualRemediations') &&
      !ticketPolicy.getIn(['hasManualRemediations', 'items'], List()).isEmpty()

    if (hasManualRemediations) {
      return true
    }

    return false
  }

  render() {
    if (this.props.ticketDetails.get('loading')) {
      return (
        <div style={this.style.loadingContainer}>
          <BarLoadingAnimation />
        </div>
      )
    }

    if (this.props.ticketDetails.get('error')) {
      return <TicketNotFound />
    }

    if (this.props.ticketDetails.get('data') == null) {
      return null
    }

    const ticket = this.props.ticketDetails.get('data') || Map()
    const ticketPolicy = this.props.policies.get(ticket.get('ticketKey'))
    const canBeRemediated = this.hasRemediation()

    return (
      <div style={this.style.content}>
        <TicketDetailsHeader ticket={ticket} />
        <div style={this.style.body}>
          <TicketDetailsTabs
            ticket={ticket}
            showRemediationModal={
              canBeRemediated ? this.showRemediationModal : undefined
            }
          />
          <TicketDetailsEscalation ticket={ticket} />
        </div>
        {this.state.showRemediationModal && (
          <RemediationModal
            ticket={ticket}
            toggle={this.hideRemediationModal}
            policy={ticketPolicy}
          />
        )}
      </div>
    )
  }
}

Ticket.propTypes = {
  fetchTicketDetails: PropTypes.func,
  policies: ImmutablePropTypes.map.isRequired,
  ticketDetails: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: ImmutablePropTypes.map,
  }),
  ticketSrn: PropTypes.string,
  actionStatus: ImmutablePropTypes.map,
}

const mapStateToProps = createStructuredSelector({
  ticketDetails: selectTicketDetails,
  actionStatus: selectActionStatus,
  policies: selectPolicies,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTicketDetails,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(Ticket)
