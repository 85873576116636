import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectActionNodeViewCardLayoutDomain = state =>
  state.get('actionNodeViewCardLayout', Map())

export const selectActionContent = createSelector(
  selectActionNodeViewCardLayoutDomain,
  state => state.get('actionContent') || Map()
)

export const selectIsLoading = createSelector(
  selectActionNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
