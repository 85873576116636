/**
 *
 * Tab
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import { Dropdown, DropdownToggle, Fade } from 'reactstrap'

import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import DropdownMenu from 'components/StyledReactstrapDropdownMenu'

class Tab extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      moreButton: {
        padding: '0 0.3em 0 0.7em',
        marginRight: '-3px',
        border: 0,
        display: 'inline',
      },
      link: {
        display: 'block',
        width: '100%',
        marginBottom: '0.5em',
        paddingTop: '0.25em',
        paddingBottom: '0.25em',
        paddingLeft: '1em',
        paddingRight: '1em',
        cursor: 'pointer',
        ':hover': { backgroundColor: 'rgba(116, 180, 223, 0.4)' },
        textAlign: 'left',
      },
      tab: {
        padding: props.small ? '5px' : '10px',
        fontSize: props.small ? '16px' : '18px',
      },
    }

    this.state = {
      hover: false,
      dropdownExpanded: false,
    }
  }

  toggleActionMenu = event => {
    event.stopPropagation() //Required to prevent navigation when clicking the options dropdown

    this.setState(oldState => {
      return {
        dropdownExpanded: !oldState.dropdownExpanded,
      }
    })
  }

  render() {
    return (
      <div
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        style={this.styles.tab}
      >
        <span style={{ textAlign: 'center' }}>{this.props.text}</span>
        {this.props.actions &&
          (this.state.hover || this.state.dropdownExpanded) && (
            <Dropdown
              isOpen={this.state.dropdownExpanded}
              toggle={this.toggleActionMenu}
              style={{ display: 'inline-block', transition: 'width ease' }}
            >
              <DropdownToggle style={this.styles.moreButton}>
                <Fade
                  in={this.state.hover || this.state.dropdownExpanded}
                  style={{ display: 'inline-block' }}
                >
                  <Icon fa name="ellipsis-v" />
                </Fade>
              </DropdownToggle>

              <DropdownMenu style={{ borderRadius: '2px' }}>
                {this.props.actions.map(({ text, link }) => (
                  <BorderlessButton
                    key={text}
                    onClick={link}
                    style={this.styles.link}
                  >
                    {text}
                  </BorderlessButton>
                ))}
              </DropdownMenu>
            </Dropdown>
          )}
      </div>
    )
  }
}

Tab = Radium(Tab) // eslint-disable-line

Tab.propTypes = {
  text: PropTypes.node,
  actions: PropTypes.array,
  small: PropTypes.bool,
}

export default Tab
