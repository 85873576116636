import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectDataStoreNodeViewCardLayoutDomain = state =>
  state.get('dataStoreNodeViewCardLayout') || Map()

export const selectDataStoreContent = createSelector(
  selectDataStoreNodeViewCardLayoutDomain,
  state => state.get('dataStoreContent') || Map()
)
