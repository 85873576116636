/**
 *
 * Select
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import SelectLib from 'react-select'

class Select extends React.Component {
  findOption = (options, findThis) => {
    for (let option of options) {
      if (option.value !== undefined) {
        if (JSON.stringify(option.value) == findThis) {
          return option.label
        }
      } else if (option.options !== undefined) {
        const maybeFound = this.deepFindLabel(option.options, findThis)
        if (maybeFound) {
          return maybeFound
        }
      }
    }
    return ''
  }

  render() {
    let finalValue = this.props.value
    if (typeof this.props.value !== 'object') {
      finalValue = this.props.options.find(
        opt => opt.value === this.props.value
      )
    }

    return (
      <SelectLib
        menuPlacement="auto"
        isDisabled={this.props.disabled}
        {...this.props}
        value={finalValue}
      />
    )
  }
}

Select.propTypes = {
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
}

export default Select
