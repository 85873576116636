import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import themeable from 'containers/ThemeManager/Themeable'
import UserWidget from 'components/UserWidget'
import color from 'color'
import moment from 'moment'
import CloudBadge from 'components/CloudBadge'
import TextLink from 'components/TextLink'
import { Map, List } from 'immutable'
import qs from 'query-string'
import _ from 'lodash'
import Error from 'components/Error'

const getColor = ({ ticket, theme }) => {
  const time = moment(ticket.getIn(['nextEscalation', 'escalationTime'], null))
  const twentyFour = moment(
    ticket.getIn(['nextEscalation', 'escalationTime'], null)
  ).add(1, 'days')
  const fourtyEight = moment(
    ticket.getIn(['nextEscalation', 'escalationTime'], null)
  ).add(2, 'days')
  if (time.isBefore(twentyFour)) {
    return theme.warn // oranage
  } else if (time.isAfter(twentyFour) && time.isBefore(fourtyEight)) {
    return theme.info // yellow
  } else {
    return 'rgb(171, 171, 171)' // grey & black
  }
}

class Escalation extends Component {
  styles = {
    container: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      gridTemplateAreas: '"title" "details"',
      border: `2px solid ${getColor(this.props)}`,
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: color(getColor(this.props)).lighten(0.4),
      padding: '0.25rem',
      borderBottom: `4px solid ${getColor(this.props)}`,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0.5rem',
    },
    time: {
      display: 'flex',
    },
    timeText: {
      fontSize: '1.5rem',
      fontWeight: '400',
    },
    nextAssigne: {
      display: 'flex',
      alignItems: 'center',
    },
  }

  getBotEscalation = rule => {
    const srn = rule.getIn(['escalateToBot', 'botSrn'], null)
    const bots = this.props.bots.get('data') || List()
    const bot = bots.find(bot => bot.get('srn') === srn) || Map()
    if (!srn || bot.isEmpty()) {
      return '-'
    }
    return (
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        title={`Bot - ${bot.get('title')}`}
      >
        <CloudBadge
          style={{ fontSize: '0.95rem', margin: '0rem 0.5rem 0rem 0rem' }}
          type={(bot.get('cloud') || '').toLowerCase()}
        />
        <TextLink
          newTab
          to={{
            pathname: '/App/BotManagement/BotDetails',
            search: qs.stringify({
              botId: bot.get('srn'),
            }),
          }}
          style={{
            fontSize: '0.9rem',
            width: '185px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {' '}
          {bot.get('title')}{' '}
        </TextLink>
      </div>
    )
  }

  getTime = () => {
    const time = this.props.ticket.getIn(
      ['nextEscalation', 'escalationTime'],
      null
    )
    return time ? `${moment(time).fromNow()}` : '-'
  }

  renderRules = () => {
    const rules = this.props.ticket.getIn(['nextEscalation', 'rule'], List())
    const bots = this.props.ticket
      .getIn(['nextEscalation', 'rule'], List())
      .filter(rule => rule.get('actionType') === 'ESCALATE_TO_BOT')
    if (!bots.isEmpty()) {
      return (
        <div style={{ ...this.styles.nextAssigne, ...{ flexWrap: 'wrap' } }}>
          <span
            style={{
              fontSize: '0.85rem',
              margin: '0rem 0.5rem 0rem 0rem',
            }}
          >
            Will escalate to
          </span>
          {rules.map((rule, index) => {
            return (
              <div
                key={index}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {this.getBotEscalation(rule)}
                {index !== rules.size - 1 && ', '}
              </div>
            )
          })}
        </div>
      )
    } else {
      return (
        <div style={this.styles.nextAssigne}>
          <span
            style={{
              fontSize: '0.85rem',
              margin: '0rem 0.5rem 0rem 0rem',
            }}
          >
            Will escalate to
          </span>
          {rules.map((rule, index) => {
            return <div key={index}> {this.getNextAssigne(rule)}</div>
          })}
        </div>
      )
    }
  }

  getNextAssigne = rule => {
    const actionType = rule.get('actionType')

    if (!actionType) {
      return '-'
    }

    switch (actionType) {
      case 'ASSIGN_USER':
        return <UserWidget srn={rule.getIn(['assignUser', 'userSrn'])} table />
      case 'ASSIGN_ROLE':
        return (
          <div style={{ margin: '0rem 0rem 0rem -0.25rem', fontWeight: '400' }}>
            {_.startCase(
              _.toLower(rule.getIn(['assignRole', 'ActionRoleType'], null))
            ) || '-'}
          </div>
        )
      default:
        return '-'
    }
  }

  render() {
    return (
      <div style={this.styles.container}>
        {this.props.hasError ? (
          <div
            style={{
              paddingTop: '1rem',
            }}
          >
            <Error
              description={'Something went wrong fetching next escalation.'}
            />
          </div>
        ) : (
          <div>
            <div style={this.styles.title}>
              <span style={{ fontSize: '0.85rem' }}> Next Escalation</span>
            </div>
            <div style={this.styles.details}>
              <div style={this.styles.time}>
                <div style={this.styles.timeText}> {this.getTime()} </div>
              </div>
              {this.renderRules()}
            </div>
          </div>
        )}
      </div>
    )
  }
}

Escalation.propTypes = {
  hasError: PropTypes.bool,
  ticket: ImmutablePropTypes.map.isRequired,
  bots: ImmutablePropTypes.map.isRequired,
}

export default themeable(Escalation)
