import React from 'react'
import PropTypes from 'prop-types'
import TextLink from 'components/TextLink'
import { getNodeViewPushParams, getNameFromSrn } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import ReactTooltip from 'react-tooltip'
import Hoverable from 'components/Hoverable'
import uuid from 'uuid/v4'
import CopyToClipboard from 'components/CopyToClipboard'
import { truncateMiddle } from 'utils/sonraiUtils'

class NodeViewSrn extends React.PureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      textStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '30px',
      },
    }

    this.uuid = uuid()
  }

  formatSrn = value => {
    const name = truncateMiddle(
      value.name || value.friendlyName || getNameFromSrn(value.srn || value),
      this.props.start || 40,
      this.props.end || 19
    )
    if (name.length === value.srn ? value.srn.length : value.length) {
      return truncateMiddle(value.srn || value) || '-'
    }
    return name || '-'
  }

  render() {
    const { value } = this.props
    return (
      <div>
        <Hoverable
          hoverStyle={{ cursor: 'default' }}
          renderContent={({ hovered }) => (
            <div style={this.styles.textStyle}>
              <TextLink
                to={getNodeViewPushParams(
                  value.srn || value,
                  getTypeFromSrn(value.srn || value)
                )}
              >
                <span data-tip data-for={this.uuid}>
                  {this.formatSrn(value)}
                </span>
              </TextLink>
              {hovered ? (
                <div style={{ marginBottom: '5px' }}>
                  <CopyToClipboard value={value.srn || value} />
                </div>
              ) : null}
            </div>
          )}
        />
        <ReactTooltip id={this.uuid} place="top" effect="solid">
          {value.srn || value}
        </ReactTooltip>
      </div>
    )
  }
}

NodeViewSrn.propTypes = {
  value: PropTypes.string,
  start: PropTypes.number,
  end: PropTypes.number,
}

export default NodeViewSrn
