/*
 *
 * ThemeManager actions
 *
 */

import { LOAD_THEME, GENERATE_TYPE_COLORS, SET_TYPE_COLORS } from './constants'
import { createAction } from 'redux-actions'

export const loadTheme = createAction(LOAD_THEME)
export const generateTypeColors = createAction(GENERATE_TYPE_COLORS)
export const setTypeColors = createAction(SET_TYPE_COLORS)
