/*
 * ImageNodeViewCardLayout Messages
 *
 * This contains all the text for the ImageNodeViewCardLayout component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.containers.ImageNodeViewCardLayout.title',
    defaultMessage: 'Data Details',
  },
  activeLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.activeLabel',
    defaultMessage: 'Active',
  },
  typeLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.typeLabel',
    defaultMessage: 'Type',
  },
  accountLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  group: {
    id: 'app.containers.ImageNodeViewCardLayout.group',
    defaultMessage: 'Group',
  },
  lastModifiedLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.lastModifiedLabel',
    defaultMessage: 'Last Modified',
  },
  createdLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.createdLabel',
    defaultMessage: 'Created',
  },
  srnLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.srnLabel',
    defaultMessage: 'srn',
  },
  service: {
    id: 'app.containers.ImageNodeViewCardLayout.service',
    defaultMessage: 'Service',
  },
  descriptionLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.descriptionLabel',
    defaultMessage: 'Description',
  },
  sizeLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.size',
    defaultMessage: 'Size',
  },
  bytes: {
    id: 'app.containers.ImageNodeViewCardLayout.bytes',
    defaultMessage: 'Bytes',
  },
  publicRead: {
    id: 'app.containers.ImageNodeViewCardLayout.publicRead',
    defaultMessage: 'Public Read',
  },
  publicWrite: {
    id: 'app.containers.ImageNodeViewCardLayout.publicWrite',
    defaultMessage: 'Public Write',
  },
  webisteEnabled: {
    id: 'app.containers.ImageNodeViewCardLayout.webisteEnabled',
    defaultMessage: 'Website Enabled',
  },
  auditEnabled: {
    id: 'app.containers.ImageNodeViewCardLayout.auditEnabled',
    defaultMessage: 'Audit Enabled',
  },
  containerLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.containerLabel',
    defaultMessage: 'Container',
  },
  stateLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.stateLabel',
    defaultMessage: 'State',
  },
  architectureLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.architectureLabel',
    defaultMessage: 'Architecture',
  },
  platformLabel: {
    id: 'app.containers.ImageNodeViewCardLayout.platformLabel',
    defaultMessage: 'Platform',
  },
  none: {
    id: 'app.containers.ImageNodeViewCardLayout.none',
    defaultMessage: 'None',
  },
  dataItem: {
    id: 'app.containers.ImageNodeViewCardLayout.dataItem',
    defaultMessage: 'This Data item is not part of a container',
  },
  record: {
    id: 'app.containers.ImageNodeViewCardLayout.record',
    defaultMessage: 'Record',
  },
  performed: {
    id: 'app.containers.ImageNodeViewCardLayout.performed',
    defaultMessage: 'Performed',
  },
  members: {
    id: 'app.containers.ImageNodeViewCardLayout.performed',
    defaultMessage: 'Members',
  },
  groupsPolicies: {
    id: 'app.containers.ImageNodeViewCardLayout.groupsPolicies',
    defaultMessage: 'Groups & Policies',
  },
  label: {
    id: 'app.containers.ImageNodeViewCardLayout.label',
    defaultMessage: 'Label',
  },
  Image: {
    id: 'app.containers.ImageNodeViewCardLayout.Image',
    defaultMessage: 'Image',
  },
  public: {
    id: 'app.containers.ImageNodeViewCardLayout.public',
    defaultMessage: 'Public',
  },
  usedIn: {
    id: 'app.containers.ImageNodeViewCardLayout.usedIn',
    defaultMessage: 'Used In',
  },
})
