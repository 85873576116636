/**
 *
 * BigStatWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Query } from 'react-apollo'
import _ from 'lodash'
import gql from 'graphql-tag'
import Color from 'color'
import Card, { BottomTitle, CardBody } from 'components/Card'
import TextLink from 'components/TextLink'
import WidgetCard from 'components/WidgetCard'
import { BOUNDARY_OPTIONS } from 'appConstants'
import { queryHasPivotFilter } from 'query-builder'
import {
  getFields,
  getSonraiSearchQuery,
  getSearchCard,
  getSonraiSearchData,
  getSelection,
  hasSonraiSearch,
} from 'query-builder'
import BigStat from './BigStat'
import { getSearchIdForSonraiSearches } from 'utils/sonraiUtils'
import { widgetSearchUsesFirstCard } from 'utils/widgetUtils'
import { Map } from 'immutable'

export class BigStatWidget extends React.Component {
  getQuery = () => {
    if (hasSonraiSearch(this.props.options, 'bigcountsearch')) {
      return getSonraiSearchQuery(
        this.props.options.sonraiSearches.bigcountsearch
      )
    } else {
      const fields = getFields(
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.bigcountsearch
      )

      if (fields.isEmpty()) {
        return {}
      }

      const rootField = fields.find(
        statement => !statement.get('parentId'),
        null,
        Map()
      )

      const queryBuilder = this.props.getQueryBuilder(fields)
      queryBuilder.countsOnly()

      if (this.searchUsesFirstCard()) {
        //Performance optimization: skip aggregations if the query is reading the count from the first card
        queryBuilder.enableFlattenMode()
      }

      return queryBuilder.buildPivotableSource(
        rootField.get('id'),
        'bigstatquery'
      )
    }
  }

  searchUsesFirstCard = () => {
    const searchCardId = getSearchCard(
      this.props.resultLayout,
      'bigcountsearch'
    ).id

    return widgetSearchUsesFirstCard(
      this.props.resultLayout.indexedSearches.bigcountsearch,
      this.props.savedSearches,
      searchCardId
    )
  }

  getSearchName = () => {
    return hasSonraiSearch(this.props.options, 'bigcountsearch')
      ? this.props.options.sonraiSearches.bigcountsearch
      : this.props.savedSearches.getIn([
          this.props.resultLayout.indexedSearches.bigcountsearch,
          'name',
        ])
  }

  getNumber = data => {
    if (_.isEmpty(data)) {
      return ''
    }

    if (!_.isEmpty(this.props.options.sonraiSearches.bigcountsearch)) {
      return this.getNumberFromSonraiSearch(data)
    } else {
      return this.getNumberFromSavedSearch(data)
    }
  }

  getNumberFromSavedSearch = data => {
    const fields = getFields(
      this.props.savedSearches,
      this.props.resultLayout.indexedSearches.bigcountsearch
    ).toJS()

    const searchCard = getSearchCard(this.props.resultLayout, 'bigcountsearch')
    const selection = getSelection(
      fields,
      searchCard,
      'count',
      this.searchUsesFirstCard() ? 'items' : 'aggregation'
    )

    if (!selection) {
      return 'DATA ERROR'
    }

    const number = _.get(data, [...selection])

    if (typeof number === 'string' || typeof number === 'number') {
      return number
    } else {
      return 'DATA ERROR'
    }
  }

  getNumberFromSonraiSearch = data => {
    let num = getSonraiSearchData(data).count
    if (num) {
      return num
    } else {
      return 0
    }
  }

  getBorderStyle = count => {
    if (count === '' || count === null) {
      return {}
    }

    if (typeof count === `object`) {
      count = count.for
    }

    const thresholds = _.get(this.props.resultLayout, [
      'widgetOptions',
      'thresholds',
    ])
    if (!thresholds) {
      return
    } else {
      let style = {}

      thresholds.forEach(threshold => {
        let isMatch = false

        if (threshold.type === 'between') {
          if (count >= threshold.start && count <= threshold.end) {
            isMatch = true
          }
        } else {
          const sign = threshold.operatorSign
          switch (sign) {
            case BOUNDARY_OPTIONS.GREATER:
              if (count > threshold.operatorVal) {
                isMatch = true
              }
              break
            case BOUNDARY_OPTIONS.LESS:
              if (count < threshold.operatorVal) {
                isMatch = true
              }
              break
            case BOUNDARY_OPTIONS.EQUAL:
              if (count === threshold.operatorVal) {
                isMatch = true
              }
              break
          }
        }

        if (isMatch) {
          const shadowColor = Color(threshold.color).alpha(0.5).rgb().string()

          const borderColor = Color(threshold.color).alpha(0.7).rgb().string()

          style = {
            borderRadius: '3px',
            border: `1px solid ${borderColor}`,
            boxShadow: `0 2px 5px 3px ${shadowColor}`,
          }
        }
      })
      return style
    }
  }

  onClickSearch = () => {
    this.props.onClickSearch({
      savedSearchId: this.props.resultLayout.indexedSearches.bigcountsearch,
      sonraiSearchName: this.props.options.sonraiSearches.bigcountsearch,
      searchTitle: this.props.title,
    })
  }

  getTitleFontSize = title => {
    const width = this.props.layout.w
    const numChars = title.length

    let fontSize = 16

    let textToWidthRatio = numChars / width

    if (textToWidthRatio > 4.6) {
      fontSize = 13
    }

    return {
      fontSize: `${fontSize}px`,
    }
  }

  getSearchId = () => {
    const { options, sonraiSearches, resultLayout } = this.props
    let searchObj = Map({ uiSearch: null, advancedSearch: null })
    if (hasSonraiSearch(options, 'bigcountsearch')) {
      const searches = getSearchIdForSonraiSearches(options, sonraiSearches)
      searchObj = searchObj.set('advancedSearch', searches)
    } else {
      searchObj = searchObj.set(
        'uiSearch',
        resultLayout.indexedSearches.bigcountsearch
      )
    }
    return searchObj
  }

  render() {
    if (this.props.data === undefined) {
      const searchId = this.getSearchId()
      const query = this.getQuery()
      if (!query.gqlStatement) {
        return (
          <WidgetCard
            searchId={searchId}
            loading={true}
            title={this.props.title}
            allowDelete={this.props.allowDelete}
            allowUpdate={this.props.allowUpdate}
            onRemove={this.props.onRemove}
            onEdit={this.props.onEdit}
          />
        )
      }

      const filtered = queryHasPivotFilter(query.gqlStatement)

      return (
        <Query
          query={gql`
            ${query.gqlStatement}
          `}
          variables={query.variables}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          key={JSON.stringify(query)}
          context={{
            queryName: `bigCountWidget_${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ error, data, refetch, networkStatus }) => {
            const number = this.getNumber(data)
            const borderStyle = this.getBorderStyle(number)

            return (
              <WidgetCard
                createdBy={this.props.widget && this.props.widget.createdBy}
                searchId={searchId}
                style={borderStyle}
                disableToolbar={this.props.disableToolbar}
                allowDelete={this.props.allowDelete}
                allowUpdate={this.props.allowUpdate}
                onRemove={this.props.onRemove}
                onEdit={this.props.onEdit}
                toggleStatic={this.props.toggleStatic}
                static={this.props.static}
                refetch={refetch}
                networkStatus={networkStatus}
                title={
                  <TextLink
                    title={this.getSearchName()}
                    onClick={this.onClickSearch}
                  >
                    {this.props.title}
                  </TextLink>
                }
                filtered={filtered}
                pivot={filtered ? this.props.pivot : undefined}
                error={error}
                description={_.get(this.props.resultLayout, [
                  'widgetOptions',
                  'description',
                ])}
              >
                <CardBody style={{ overflowX: 'auto' }}>
                  <BigStat
                    title={this.getSearchName()}
                    data={number}
                    onClick={this.onClickSearch}
                  />
                </CardBody>
                <BottomTitle style={this.getTitleFontSize(this.props.title)}>
                  <TextLink
                    title={this.getSearchName()}
                    onClick={this.onClickSearch}
                  >
                    {this.props.title}
                  </TextLink>
                </BottomTitle>
              </WidgetCard>
            )
          }}
        </Query>
      )
    } else {
      return (
        <Card style={this.getBorderStyle(this.props.data)}>
          <CardBody>
            <BigStat data={this.props.data} onClick={this.onClickSearch} />
          </CardBody>
          <BottomTitle style={this.getTitleFontSize(this.props.title)}>
            <TextLink onClick={this.onClickSearch}>{this.props.title}</TextLink>
          </BottomTitle>
        </Card>
      )
    }
  }
}

BigStatWidget.defaultProps = {
  layout: {
    w: 10,
  },
  savedSearches: Map(),
}

BigStatWidget.propTypes = {
  layout: PropTypes.shape({
    w: PropTypes.number,
  }),
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  title: PropTypes.node.isRequired,
  onRemove: PropTypes.func,
  toggleStatic: PropTypes.func,
  savedSearches: ImmutablePropTypes.map.isRequired,
  static: PropTypes.bool,
  options: PropTypes.object,
  pivot: ImmutablePropTypes.map,
  resultLayout: PropTypes.object,
  getQueryBuilder: PropTypes.func,
  onClickSearch: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onEdit: PropTypes.func,
  sonraiSearches: ImmutablePropTypes.iterable,
  widget: PropTypes.object,
}

export default BigStatWidget
