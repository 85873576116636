import { createAction } from 'redux-actions'
import {
  ASSIGN_TICKET_ESCALTION_ERROR,
  FETCH_TICKET_TEMPLATES,
  FETCH_TICKET_TEMPLATES_ERROR,
  FETCH_TICKET_TEMPLATES_SUCCESS,
  FETCH_TICKET_TEMPLATE_FIELD_SELECT,
  FETCH_TICKET_TEMPLATE_FIELD_SELECT_ERROR,
  FETCH_TICKET_TEMPLATE_FIELD_SELECT_SUCCESS,
  SAVE_CUSTOM_TICKET,
  SAVE_CUSTOM_TICKET_ERROR,
  SAVE_CUSTOM_TICKET_SUCCES,
  UPDATE_CUSTOM_TICKET,
  UPDATE_CUSTOM_TICKET_ERROR,
  UPDATE_CUSTOM_TICKET_SUCCES,
  SET_TICKET_TYPE,
  SET_SELECTED_TICKET_TEMPLATE,
  CLEAR_STATE,
  SET_TICKET_TEMPLATE_FIELD_VALUE,
} from './constants'

export const assignTicketEscalationError = createAction(
  ASSIGN_TICKET_ESCALTION_ERROR
)

export const fetchTicketTemplates = createAction(FETCH_TICKET_TEMPLATES)
export const fetchTicketTemplatesError = createAction(
  FETCH_TICKET_TEMPLATES_ERROR
)
export const fetchTicketTemplatesSucces = createAction(
  FETCH_TICKET_TEMPLATES_SUCCESS
)

export const fetchTicketTemplateFieldSelect = createAction(
  FETCH_TICKET_TEMPLATE_FIELD_SELECT
)
export const fetchTicketTemplateFieldSelectError = createAction(
  FETCH_TICKET_TEMPLATE_FIELD_SELECT_ERROR
)
export const fetchTicketTemplateFieldSelectSuccess = createAction(
  FETCH_TICKET_TEMPLATE_FIELD_SELECT_SUCCESS
)

export const saveCustomTicket = createAction(SAVE_CUSTOM_TICKET)
export const saveCustomTicketError = createAction(SAVE_CUSTOM_TICKET_ERROR)
export const saveCustomTicketSucces = createAction(SAVE_CUSTOM_TICKET_SUCCES)

export const updateCustomTicket = createAction(UPDATE_CUSTOM_TICKET)
export const updateCustomTicketError = createAction(UPDATE_CUSTOM_TICKET_ERROR)
export const updateCustomTicketSuccess = createAction(
  UPDATE_CUSTOM_TICKET_SUCCES
)

export const setTicketType = createAction(SET_TICKET_TYPE)
export const setSelectedTicketTemplate = createAction(
  SET_SELECTED_TICKET_TEMPLATE
)
export const clearState = createAction(CLEAR_STATE)

export const setTicketTemplateFieldValue = createAction(
  SET_TICKET_TEMPLATE_FIELD_VALUE
)
