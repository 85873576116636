/**
 *
 * WidgetResultExplorer
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import qs from 'query-string'
import { fromJS, List, Map } from 'immutable'
import { push } from 'connected-react-router'
import _ from 'lodash'

import { getNodeViewPushParams } from 'utils/sonraiUtils'
import TextLink from 'components/TextLink'
import CenterContent from 'components/CenterContent'
import TimeoutLoader from 'components/TimeoutLoader'
import NodeSolutionCenter from 'containers/NodeSolutionCenter'
import BorderedCard from 'components/BorderedCard'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import Icon from 'components/Icon'
import { clearData } from 'containers/NodeSolutionCenter/actions'
import SlideoutPanel from 'components/SlideoutPanel'
import Error from 'components/Error'
import {
  fetchSavedSearchWidgetResults,
  fetchAdvancedSearchWidgetResults,
  fetchCustomSearchWidgetResults,
  setSonraiSearchName,
  setSavedSearchId,
  setFilter,
  setSavedSearchFilterCardId,
  setTitle,
  setAdvancedQuery,
} from './actions'
import {
  selectResults,
  selectSonraiSearchName,
  selectSavedSearchId,
  selectLoading,
  selectError,
  selectTitle,
  selectFilter,
} from './selectors'
import widgetResultExplorerReducer from './reducer'
import sagas from './sagas'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import DataTable from 'components/DataTable'
import { FormattedMessage } from 'react-intl'
import messages from './messages.js'
import { SEARCH_VIEWS } from 'containers/Search/constants'
import { selectSavedSearches } from 'containers/SonraiData/selectors'

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateAreas: "'results'",
    overflow: 'hidden',
    height: '100%',
  },
  filterText: {
    marginRight: '0.4rem',
    marginLeft: '0.4rem',
    fontWeight: '500',
    color: 'rgb(85, 85, 85)',
  },
  noResultsText: {
    margin: '0.5rem',
    color: '#A8A8A8',
    fontSize: '1.2rem',
  },
  noResults: {
    height: '100%',
    width: '100%',
    padding: '10em',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textCont: {
    marginLeft: '0.3rem',
    marginTop: '0.25rem',
  },
  closeIcon: { fontSize: '1.1rem', marginTop: '0.1rem' },
  filterCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '0.75rem',
  },
  filterIcon: { color: 'rgb(228, 198, 71)', fontSize: '1.2rem' },
  containerWithPanel: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateAreas: "'results details'",
    overflow: 'hidden',
    height: '100%',
  },
  closeIconCont: { marginTop: '0.15rem' },
  resultsColumn: {
    gridArea: 'results',
    height: '100%',
    padding: '1em',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridRowGap: '0.5em',
  },
  resultsCard: {
    height: '100%',
    overflow: 'auto',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  viewName: {
    fontSize: '1.5em',
  },
  panelColumn: {
    gridArea: 'details',
    paddingLeft: '1em',
  },
}

export class WidgetResultExplorer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showNodeViewPanel: false,
      nodeId: null,
      nodeType: null,
    }

    const params = _.get(this.props.location, 'state')
    if (params) {
      this.props.setTitle(params.searchTitle)
      this.props.setSavedSearchId(params.savedSearchId)
      this.props.setSonraiSearchName(params.sonraiSearchName)
      this.props.setFilter(params.filter)
      this.props.setSavedSearchFilterCardId(params.savedSearchFilterCardId)
      this.props.setAdvancedQuery(params.advancedQuery)
    }
  }

  componentDidMount() {
    const params = _.get(this.props.location, 'state')
    if (params) {
      if (params.savedSearchId) {
        this.props.fetchSavedSearchWidgetResults({
          searchId: params.savedSearchId,
          filter: params.filter,
          filterCardId: params.savedSearchFilterCardId,
        })
      } else if (params.sonraiSearchName) {
        if (params.advancedQuery) {
          this.props.fetchCustomSearchWidgetResults({
            query: params.advancedQuery,
          })
        } else {
          this.props.fetchAdvancedSearchWidgetResults({
            searchName: params.sonraiSearchName,
          })
        }
      }
    }
  }

  goToSelectedNodeView = () => {
    return getNodeViewPushParams(this.state.nodeId, this.state.nodeType)
  }

  onClickNodeView = (nodeId, nodeType) => {
    if (this.state.nodeId) {
      if (this.state.nodeId !== nodeId) {
        this.props.clearData()
      }
    }
    this.setState({ showNodeViewPanel: true, nodeType, nodeId })
  }

  onClickFlattenedNodeView = row => {
    const srn = fromJS(row).find((value, key) => key.includes('_srn'))
    const type = getTypeFromSrn(srn)

    if (!srn) {
      return
    }

    this.setState({ showNodeViewPanel: true, nodeType: type, nodeId: srn })
  }

  renderPanel() {
    return (
      <SlideoutPanel
        styles={styles.panelColumn}
        visible={this.state.showNodeViewPanel}
        toggleVisible={() => this.setState({ showNodeViewPanel: false })}
        actions={
          <TextLink color="primary" to={this.goToSelectedNodeView} newTab>
            Go to full node view
          </TextLink>
        }
      >
        {this.state.showNodeViewPanel && (
          <NodeSolutionCenter
            type={this.state.nodeType}
            nodeId={this.state.nodeId}
            horizontal
          />
        )}
      </SlideoutPanel>
    )
  }

  getDataFromGraphiqlSearchResults = () => {
    //Regions widget uses a custom search when clicking a region
    //And it does not use ExecuteSavedQuery
    //So for this case, we will default to use this.props.results as the root node
    const results = this.props.results
      .getIn(['ExecuteSavedQuery', 'Query'], this.props.results)
      .first(Map())

    return results.get('items') || results.get('group') || List()
  }

  getDataFromSavedSearchResults = () => {
    return this.props.results.first(Map()).get('items', List())
  }

  getData = () => {
    const isSavedSearch = !!this.props.savedSearchId
    let tableData = isSavedSearch
      ? this.getDataFromSavedSearchResults()
      : this.getDataFromGraphiqlSearchResults()

    if (!isSavedSearch && this.props.filter && !this.props.filter.isEmpty()) {
      tableData = tableData.filter(
        item =>
          item.get(this.props.filter.get('label')) ===
          this.props.filter.get('value')
      )
    }

    return tableData
  }

  clearFilter = () => {
    this.props.setFilter({})

    if (this.props.savedSearchId) {
      //If its a saved search, rerun the search without any extra filters
      this.props.fetchSavedSearchWidgetResults({
        searchId: this.props.savedSearchId,
      })
    }
  }

  renderSearchNav() {
    if (this.props.savedSearchId) {
      return (
        <TextLink
          className="backing-search-link"
          color="secondary"
          to={{
            pathname: '/App/Search/',
            search: qs.stringify({
              searchId: this.props.savedSearchId,
              view: SEARCH_VIEWS.RESULTS,
            }),
          }}
        >
          View Search
        </TextLink>
      )
    } else if (this.props.sonraiSearchName) {
      const pathname = '/App/GraphExplorer/'
      let querySource = this.props.results.getIn([
        'SavedQueries',
        'items',
        0,
        'query',
      ])

      return (
        <TextLink
          className="backing-search-link"
          color="secondary"
          to={{
            pathname: pathname,
            state: {
              searchName: this.props.sonraiSearchName,
              preloadQuery: querySource,
            },
          }}
        >
          View Search
        </TextLink>
      )
    }

    return null
  }

  renderResults() {
    return (
      <DataTable
        id={this.props.savedSearchId}
        set="ResultExplorer"
        data={this.getData().toJS()}
        onSingleClickNodeView={this.onClickNodeView}
        onSingleClickFlattenedNodeView={this.onClickFlattenedNodeView}
        flatten={true}
      />
    )
  }

  renderContent = () => {
    if (this.props.loading) {
      return (
        <CenterContent style={{ height: '100%' }}>
          <TimeoutLoader
            firstMessage="Working on your query..."
            finalMessage="Sorry, this search timed out."
          />
        </CenterContent>
      )
    }

    if (this.props.error) {
      return (
        <Error
          description="There was an error loading the results"
          errorMessage={this.props.error}
        />
      )
    }

    return this.renderResults()
  }

  renderTitle = () => {
    return (
      <span className="widget-results-explorer-title" style={styles.viewName}>
        <FormattedMessage
          {...messages.exploringResults}
          values={{
            title: this.props.title,
            searchName: this.props.savedSearchId
              ? this.props.savedSearches
                  .get(this.props.savedSearchId, Map())
                  .get('name')
              : this.props.sonraiSearchName,
          }}
        />
      </span>
    )
  }

  render() {
    return (
      <div
        style={
          this.state.showNodeViewPanel
            ? styles.containerWithPanel
            : styles.container
        }
      >
        <div style={styles.resultsColumn}>
          <div style={styles.header}>
            {this.renderTitle()}
            {this.renderSearchNav()}
            {this.props.filter && !this.props.filter.isEmpty() && (
              <div style={styles.filterCont}>
                <Icon fa name="filter-solid" style={styles.filterIcon} />
                <div style={styles.textCont}>
                  <FormattedMessage {...messages.filteredBy} />
                  <span style={styles.filterText}>
                    {this.props.filter.get('label')}
                  </span>
                  <FormattedMessage {...messages.equalTo} />
                  <span style={styles.filterText}>
                    {typeof this.props.filter.get('value') === 'string'
                      ? `"${this.props.filter.get('value')}"`
                      : String(this.props.filter.get('value'))}
                  </span>
                </div>
                <TextLink
                  color="primary"
                  onClick={this.clearFilter}
                  style={{ fontSize: '0.9em', margin: '0 0.3em' }}
                >
                  (Clear Filter)
                </TextLink>
              </div>
            )}
          </div>
          <BorderedCard style={styles.resultsCard}>
            {this.renderContent()}
          </BorderedCard>
        </div>

        {this.renderPanel()}
      </div>
    )
  }
}

WidgetResultExplorer.propTypes = {
  clearData: PropTypes.func.isRequired,
  error: PropTypes.string,
  fetchAdvancedSearchWidgetResults: PropTypes.func.isRequired,
  fetchSavedSearchWidgetResults: PropTypes.func.isRequired,
  fetchCustomSearchWidgetResults: PropTypes.func.isRequired,
  filter: ImmutablePropTypes.contains({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  loading: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      savedSearchId: PropTypes.string,
      sonraiSearchName: PropTypes.string,
      searchTitle: PropTypes.string,
      savedSearchFilterCardId: PropTypes.string,
      filter: PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
      }),
    }),
  }),
  push: PropTypes.func.isRequired,
  results: ImmutablePropTypes.iterable.isRequired,
  savedSearchId: PropTypes.string,
  savedSearches: ImmutablePropTypes.iterable.isRequired,
  setAdvancedQuery: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setSavedSearchId: PropTypes.func,
  setSavedSearchFilterCardId: PropTypes.func.isRequired,
  setSonraiSearchName: PropTypes.func,
  setTitle: PropTypes.func,
  sonraiSearchName: PropTypes.string,
  title: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  error: selectError,
  loading: selectLoading,
  results: selectResults,
  savedSearchId: selectSavedSearchId,
  savedSearches: selectSavedSearches,
  sonraiSearchName: selectSonraiSearchName,
  filter: selectFilter,
  title: selectTitle,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearData,
      fetchSavedSearchWidgetResults,
      fetchAdvancedSearchWidgetResults,
      fetchCustomSearchWidgetResults,
      setAdvancedQuery,
      setTitle,
      setSonraiSearchName,
      setSavedSearchId,
      setFilter,
      setSavedSearchFilterCardId,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({
  key: 'widgetResultExplorer',
  reducer: widgetResultExplorerReducer,
})
const withSaga = injectSaga({ key: 'widgetResultExplorer', saga: sagas })

export default compose(withConnect, withReducer, withSaga)(WidgetResultExplorer)
