/**
 *
 * AppLayout
 *
 */

import React, { Fragment, useEffect, useState } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { injectIntl, intlShape } from 'react-intl'
import AppLayoutErrorBoundry from './AppLayoutErrorBoundry'
import sonraiData from 'containers/SonraiData'
import controlFrameworkData from 'containers/ControlFrameworkData'
import platformSettingsData from 'containers/PlatformSettingsData'
import ticketDetailsData from 'containers/TicketDetailsData'
import escalationData from 'containers/EscalationData'
import dataClassifierData from 'containers/DataClassifierData'
import changeDetectionManager from 'containers/ChangeDetectionManager'
import featureFlags from 'containers/FeatureFlags'
import { getClient } from 'apolloClient'
import { ApolloProvider } from 'react-apollo'
import { Alert } from 'reactstrap'

import Error from 'components/Error'
import SolutionCenter from 'containers/SolutionCenter/Loadable'
import GraphExplorer from 'containers/GraphExplorer/Loadable'
import GlobalNavBar from 'containers/GlobalNavBar'
import QuickSearch from 'containers/QuickSearch'
import Search from 'containers/Search/Loadable'
import GlobalHeaderBar from 'containers/GlobalHeaderBar'
import NodeSolutionCenter from 'containers/NodeSolutionCenter/Loadable'
import NotFoundPage from 'containers/NotFoundPage'
import WidgetResultExplorer from 'containers/WidgetResultExplorer'
import ControlCenter from 'containers/ControlCenter/Loadable'
import Explorer from 'containers/Explorer'
import ControlGroup from 'containers/ControlGroup'
import Deploy from 'containers/Deploy/Loadable'
import PageLoader from 'components/PageLoader'
import LoadingAnim from 'components/LoadingAnim'
import HelpCenter from 'containers/HelpCenter/Loadable'
import PolicyEdit from 'containers/PolicyEdit/Loadable'
import NewAccountState from 'containers/NewAccountState'
import SecurityCenter from 'containers/SecurityCenter/Loadable'
import UserManagement from 'containers/UserManagement/Loadable'
import BotManagement from 'containers/BotManagement/Loadable'
import BotDetails from 'containers/BotDetails/Loadable'
import RoleManagementPage from 'containers/RoleManagementPage/Loadable'
import RoleDetails from 'containers/RoleDetails/Loadable'
import Tickets from 'containers/Tickets/Loadable'
import TicketDetails from 'containers/TicketDetails/Loadable'
import UserProfileView from 'containers/UserProfileView/Loadable'
import SwimlaneManager from 'containers/SwimlaneManager/Loadable'
import SwimlaneDetails from 'containers/SwimlaneDetails/Loadable'
import SearchManager from 'containers/SearchManager/Loadable'
import MonitoredResources from 'containers/MonitoredResources/Loadable'
import UserActivity from 'containers/UserActivity/Loadable'
import PlatformSettings from 'containers/PlatformSettings/Loadable'
import SlackApplicationAuthorization from 'containers/SlackApplicationAuthorization/Loadable'
import Escalation from 'containers/Escalation/Loadable'
import EscalationDetails from 'containers/EscalationDetails/Loadable'
import SwimlaneCheckup from 'containers/SwimlaneCheckup/Loadable'
import SwimlaneCheckupDetails from 'containers/SwimlaneCheckupDetails/Loadable'
import TicketTemplatesPage from 'containers/TicketTemplatesPage/Loadable'
import TicketTemplateDetailsPage from 'containers/TicketTemplateDetailsPage/Loadable'
import DataClassifierManagement from 'containers/DataClassifierManagement/Loadable'
import ObjectivesManager from 'containers/ObjectivesManager/Loadable'

import { clearCurrentOrg } from 'auth/current-org'
import { SUPPORT_EMAIL } from 'appConstants'
import TextLink from 'components/TextLink'
import permissionChecker from 'containers/PermissionChecker'
import {
  selectQueryTypes,
  selectHasCollectors,
  selectCheckCollectorsLoading,
  selectSavedSearchesLoading,
  selectSonraiConfigLoading,
} from 'containers/SonraiData/selectors'
import {
  selectUserProfile,
  selectUserProfileError,
} from 'containers/UserProfileData/selectors'
import {
  fetchControlGroups,
  fetchPolicies,
} from 'containers/ControlFrameworkData/actions'

import {
  startUpdateDataPoll,
  stopUpdateDataPoll,
  runInspectionQuery,
  checkIfHasCollectors,
  fetchSonraiUsers,
  fetchSonraiConfig,
  getAllRoles,
} from 'containers/SonraiData/actions'
import { loadUser } from 'containers/UserProfileData/actions'

import AcceptLicenseModal from './AcceptLicenseModal'
import { selectUserHasAcceptedLicense } from './selectors'
import messages from './messages'
import styles from './styles'
import { useAuth0 } from 'react-auth0-wrapper'
import useSonraiUserPermissions from 'auth/hooks/sonrai-users-permissions'
import { userHasPermission } from 'auth/permissions'
import { Provider as StyletronProvider } from 'styletron-react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { selectGlobalError } from 'containers/SonraiData/selectors'
import { setGlobalError } from 'containers/SonraiData/actions'

export const AppLayout = props => {
  const { initialTokenLoad, user, logout } = useAuth0()
  const permissions = useSonraiUserPermissions(user)
  useEffect(() => {
    initialTokenLoad
      .then(token => {
        props.loadUser(token)
        if (props.queryTypes.isEmpty()) {
          props.runInspectionQuery()
        }
        props.fetchSonraiUsers()
        props.fetchSonraiConfig()
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('failed to get token from sdk', error)
      })
    return () => {
      props.stopUpdateDataPoll()
    }
  }, [])

  // We need to make sure we only allow user into app after we check if
  // collectors are created - but only if user has ability to create collectors
  const [hasCheckedCollectors, setHasCheckedCollectors] = useState(false)
  useEffect(() => {
    if (!permissions.get('loading')) {
      props.getAllRoles({
        skipAssignments: !userHasPermission({
          permissions,
          permissionName: 'edit.roleassignments',
        }),
      })

      const canViewCollectors = userHasPermission({
        permissions,
        permissionName: 'edit.collectors',
      })
      if (canViewCollectors) {
        props.checkIfHasCollectors()
      }
      setHasCheckedCollectors(true)
      props.startUpdateDataPoll({ permissions })
      props.fetchControlGroups()
      props.fetchPolicies()
    }
  }, [permissions])

  const RedirctStartPage = () => {
    const canViewCollectors = userHasPermission({
      permissions,
      permissionName: 'edit.collectors',
    })

    if (props.hasCollectors || !canViewCollectors) {
      return <Redirect to={{ pathname: '/App/SecurityCenter' }} />
    } else {
      return <Redirect to={{ pathname: '/App/Start' }} />
    }
  }

  const getAppPage = () => {
    const loadingQueryTypes = props.queryTypes.isEmpty()

    const loadingSearches = props.savedSearchesLoading
    const loadingSonraiConfig = props.sonraiConfigLoading
    if (loadingQueryTypes || loadingSearches || loadingSonraiConfig) {
      return <LoadingAnim />
    }

    return (
      <Switch>
        <Route exact path={props.match.path} render={RedirctStartPage} />
        <Route
          exact
          path={`${props.match.path}/SolutionCenter`}
          component={SolutionCenter}
        />
        <Route
          path={`${props.match.path}/SolutionCenter/Node`}
          component={NodeSolutionCenter}
        />
        <Route
          exact
          path={`${props.match.path}/Start`}
          component={NewAccountState}
        />
        <Route
          exact
          path={`${props.match.path}/GraphExplorer`}
          component={GraphExplorer}
        />
        <Route
          path={`${props.match.path}/WidgetResultExplorer*`}
          component={WidgetResultExplorer}
        />
        <Route
          path={`${props.match.path}/QuickSearch*`}
          component={QuickSearch}
        />
        <Route
          path={`${props.match.path}/SearchManager*`}
          component={SearchManager}
        />
        <Route path={`${props.match.path}/Search*`} component={Search} />
        <Route exact path={`${props.match.path}/Deploy`} component={Deploy} />
        <Route
          exact
          path={`${props.match.path}/ControlCenter`}
          component={ControlCenter}
        />
        <Route
          exact
          path={`${props.match.path}/ControlCenter/ListPolicy`}
          component={ControlCenter}
        />
        <Route
          exact
          path={`${props.match.path}/Explorer`}
          component={Explorer}
        />
        <Route
          exact
          path={`${props.match.path}/ControlCenter/ControlGroup*`}
          component={ControlGroup}
        />
        <Route
          exact
          path={`${props.match.path}/ControlCenter/Policy*`}
          component={PolicyEdit}
        />
        <Route
          exact
          path={`${props.match.path}/HelpCenter`}
          component={HelpCenter}
        />
        <Route
          exact
          path={`${props.match.path}/SecurityCenter`}
          component={SecurityCenter}
        />
        <Route
          exact
          path={`${props.match.path}/SwimlaneManagement`}
          component={SwimlaneManager}
        />
        <Route
          exact
          path={`${props.match.path}/SwimlaneDetails*`}
          component={SwimlaneDetails}
        />
        <Route
          exact
          path={`${props.match.path}/SwimlaneCheckup`}
          component={SwimlaneCheckup}
        />
        <Route
          exact
          path={`${props.match.path}/SwimlaneCheckupDetails*`}
          component={SwimlaneCheckupDetails}
        />
        <Route
          exact
          path={`${props.match.path}/UserManagement`}
          component={UserManagement}
        />
        <Route
          exact
          path={`${props.match.path}/BotManagement`}
          component={BotManagement}
        />
        <Route
          exact
          path={`${props.match.path}/BotManagement/BotDetails*`}
          component={BotDetails}
        />
        <Route
          exact
          path={`${props.match.path}/RoleManagement`}
          component={RoleManagementPage}
        />
        <Route
          exact
          path={`${props.match.path}/PlatformSettings`}
          component={PlatformSettings}
        />
        <Route
          exact
          path={`${props.match.path}/RoleManagement/RoleDetails*`}
          component={RoleDetails}
        />
        <Route exact path={`${props.match.path}/Tickets`} component={Tickets} />
        <Route
          exact
          path={`${props.match.path}/TicketDetails*`}
          component={TicketDetails}
        />
        <Route
          exact
          path={`${props.match.path}/UserManagement/User`}
          component={UserProfileView}
        />
        <Route
          exact
          path={`${props.match.path}/UserManagement/UserActivity`}
          component={UserActivity}
        />
        <Route
          exact
          path={`${props.match.path}/MonitoredResources`}
          component={MonitoredResources}
        />
        <Route
          exact
          path={`${props.match.path}/Escalation`}
          component={Escalation}
        />
        <Route
          exact
          path={`${props.match.path}/EscalationDetails*`}
          component={EscalationDetails}
        />
        <Route
          exact
          path={`${props.match.path}/DoSlackAuth`}
          component={SlackApplicationAuthorization}
        />
        <Route
          exact
          path={`${props.match.path}/TicketTemplates`}
          component={TicketTemplatesPage}
        />
        <Route
          exact
          path={`${props.match.path}/TicketTemplateDetails`}
          component={TicketTemplateDetailsPage}
        />
        <Route
          exact
          path={`${props.match.path}/DataClassifierManagement`}
          component={DataClassifierManagement}
        />
        <Route
          exact
          path={`${props.match.path}/ObjectivesManager`}
          component={ObjectivesManager}
        />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    )
  }

  const navigateToOverview = () => props.history.push('/App/SolutionCenter')

  const dismissGlobalError = () => {
    props.setGlobalError(null)
  }

  const doLogout = () => {
    clearCurrentOrg()
    logout()
  }

  const loadingPermissions = props.permissions.get('loading')

  const loadingUserProfile =
    props.userProfile && !!props.userProfile.get('loading')
  const loadingCollector = props.hasCollectorsLoading

  if (props.userProfileError) {
    return (
      <Error
        description={
          <div>
            <p>
              There was an error connecting to the server. You can try{' '}
              <TextLink onClick={doLogout} color="primary">
                logging out
              </TextLink>{' '}
              and logging back in.{' '}
            </p>
            <p>
              If the error persists, you can{' '}
              <TextLink
                color="primary"
                href={`mailto:${SUPPORT_EMAIL}`}
                target="_blank"
              >
                contact support
              </TextLink>
            </p>
          </div>
        }
        errorMessage={props.userProfileError}
      />
    )
  }

  if (
    loadingPermissions ||
    !user ||
    loadingUserProfile ||
    loadingCollector ||
    !hasCheckedCollectors
  ) {
    return (
      <div>
        <div
          id="appErrorBanner"
          style={{ position: 'absolute', width: '100%', height: '35px' }}
        >
          {props.globalError && (
            <Alert color="danger" toggle={dismissGlobalError}>
              {props.globalError}
            </Alert>
          )}
        </div>
        <PageLoader />
      </div>
    )
  }

  const client = getClient()

  const engine = new Styletron()
  // TODO: init urql provider here maybe

  return (
    <ApolloProvider client={client}>
      <StyletronProvider value={engine}>
        <Fragment>
          {!props.userHasAcceptedLicense && <AcceptLicenseModal />}
          {/* dont pass children to helmet */}
          <Helmet
            title={props.intl.formatMessage(messages.appTitle)}
            meta={[
              {
                name: 'description',
                content: 'Cloud Security App',
              },
            ]}
          />
          <div id="appContainer" style={styles.container}>
            {props.userHasAcceptedLicense ? (
              <div id="appContentContainer" style={styles.contentContainer}>
                <AppLayoutErrorBoundry>{getAppPage()}</AppLayoutErrorBoundry>
              </div>
            ) : (
              <div id="appContentContainer" style={styles.contentContainer}>
                <AppLayoutErrorBoundry>
                  <LoadingAnim />
                </AppLayoutErrorBoundry>
              </div>
            )}
            <div id="appHeaderBarContainer" style={styles.headerContainer}>
              {<GlobalHeaderBar match={props.match} />}
            </div>
            <div id="appNavBarContainer" style={styles.navContainer}>
              <GlobalNavBar
                navigateToOverview={navigateToOverview}
                match={props.match}
                location={props.location}
              />
            </div>
            <div id="appErrorBanner" style={styles.globalerrorContainer}>
              {false && props.globalError && (
                <Alert color="danger" toggle={dismissGlobalError}>
                  {props.globalError}
                </Alert>
              )}
            </div>
          </div>
        </Fragment>
      </StyletronProvider>
    </ApolloProvider>
  )
}

AppLayout.propTypes = {
  checkIfHasCollectors: PropTypes.func.isRequired,
  fetchPolicies: PropTypes.func.isRequired,
  fetchControlGroups: PropTypes.func.isRequired,
  hasCollectors: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  globalError: PropTypes.string,
  intl: intlShape,
  location: PropTypes.object,
  match: PropTypes.object,
  permissions: ImmutablePropTypes.map,
  runInspectionQuery: PropTypes.func,
  setGlobalError: PropTypes.func.isRequired,
  startUpdateDataPoll: PropTypes.func.isRequired,
  stopUpdateDataPoll: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  queryTypes: ImmutablePropTypes.iterable,
  userHasAcceptedLicense: PropTypes.bool,
  userProfile: ImmutablePropTypes.map.isRequired,
  userProfileError: PropTypes.string,
  hasCollectorsLoading: PropTypes.bool,
  fetchSonraiUsers: PropTypes.func,
  getAllRoles: PropTypes.func,
  savedSearchesLoading: PropTypes.bool, //eslint-disable-line react/no-unused-prop-types
  fetchSonraiConfig: PropTypes.func,
  sonraiConfigLoading: PropTypes.bool, //eslint-disable-line react/no-unused-prop-types
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      checkIfHasCollectors,
      runInspectionQuery,
      startUpdateDataPoll,
      stopUpdateDataPoll,
      loadUser,
      setGlobalError,
      fetchSonraiUsers,
      getAllRoles,
      fetchControlGroups,
      fetchPolicies,
      fetchSonraiConfig,
    },
    dispatch
  )
}

const mapStateToProps = createStructuredSelector({
  hasCollectors: selectHasCollectors,
  hasCollectorsLoading: selectCheckCollectorsLoading,
  globalError: selectGlobalError,
  userProfile: selectUserProfile,
  userProfileError: selectUserProfileError,
  queryTypes: selectQueryTypes,
  userHasAcceptedLicense: selectUserHasAcceptedLicense,
  savedSearchesLoading: selectSavedSearchesLoading,
  sonraiConfigLoading: selectSonraiConfigLoading,
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withConnect,
  sonraiData,
  injectIntl,
  featureFlags,
  controlFrameworkData,
  platformSettingsData,
  escalationData,
  dataClassifierData,
  changeDetectionManager,
  permissionChecker,
  ticketDetailsData
)(AppLayout)
