/**
 *
 * TableLoader
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

const styles = {
  centerContentsWrapper: {
    gridTemplateColumns: '100%',
    justifyItems: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'grid',
  },
}

export class TableLoader extends React.PureComponent {
  render() {
    return (
      <div style={{ ...styles.centerContentsWrapper, ...this.props.style }}>
        <SquareLoadingAnimation />
      </div>
    )
  }
}

TableLoader.propTypes = {
  style: PropTypes.object,
}

export default TableLoader
