import React from 'react'
import { WithPermission } from './WithPermission'
import permissionChecker from './index'

/**
 * this react component will only render its children if the currently logged
 * in user does not have the permission passed by the prop 'permissionName'
 */
export function WithoutPermission(props) {
  return <WithPermission {...{ ...props, invertCheck: true }} />
}

export default permissionChecker(WithoutPermission)
