import React from 'react'
import PropTypes from 'prop-types'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'

const styles = {
  popover: {
    maxWidth: '300px',
  },
}

export class Tooltip extends React.PureComponent {
  render() {
    return (
      <Popover
        position={this.props.position}
        trigger={this.props.trigger || 'hover'}
      >
        <PopoverAnchor>{this.props.anchor}</PopoverAnchor>
        <PopoverBody>
          <div style={{ ...styles.popover, ...this.props.styles }}>
            {this.props.tooltipContent}
          </div>
        </PopoverBody>
      </Popover>
    )
  }
}

Tooltip.propTypes = {
  anchor: PropTypes.node,
  position: PropTypes.string,
  styles: PropTypes.object,
  tooltipContent: PropTypes.node,
  trigger: PropTypes.string,
}

export default Tooltip
