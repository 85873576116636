import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import IHelp from 'containers/IHelp'
import messages from 'containers/Access/messages'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import _ from 'lodash'

class DirectPolicyWidget extends React.Component {
  styles = {
    title: {
      fontSize: '14px',
      fontWeight: '300',
      borderBottom: '1px solid #eee',
      marginRight: '0px',
    },
    body: {
      alignItems: 'start',
      paddingTop: '5px',
      height: 'calc(100% - 10px)',
      background: 'none',
    },
  }

  formatData = () => {
    const transitionNullVals = vals => {
      if (vals) {
        vals.forEach(val => {
          if (val && !_.isEmpty(val)) {
            _.keys(val).forEach(key => {
              if (!val[key]) {
                val[key] = '-'
              }
            })
          }
        })
      }
      return vals || []
    }
    const data = this.props.policies.get('data').map(policy => {
      let guy = {
        name: policy.name,
        data: policy,
      }
      if (policy.rbac) {
        guy.resourcePath = policy.rbac.resourcePath
        guy.srn = policy.srn
        if (policy.rbac.resourcePathEnd === this.props.account) {
          guy.data.isRoot = true
          guy.resourcePathDisplay = `(Root) ${guy.resourcePath}`
        } else {
          guy.resourcePathDisplay = `${guy.resourcePath}`
        }
        guy.resourceType = _.startCase(policy.rbac.resourceType)
      } else {
        guy.resourcePathDisplay = ''
        guy.resourcePath = null
        guy.resourceType = null
        guy.srn = policy.srn
      }
      guy.showPolicy = true
      guy.policyType = policy.type
      return guy
    })

    return transitionNullVals(data)
  }

  getConfig = () => {
    return {
      data: {
        hide: true,
      },
      srn: {
        hide: true,
      },
      isRoot: {
        hide: true,
      },
      showPolicy: {
        width: 120,
        minWidth: 120,
        pinned: 'right',
        headerName: '',
        enableRowGroup: false,
        menuTabs: [],
        suppressMenu: true,
        cellRendererFramework: params => {
          return (
            <TextLink
              color="primary"
              style={{
                color: this.props.theme.primary,
              }}
              onClick={() => {
                this.props.onClick(params.data.data)
              }}
            >
              Show Policy
            </TextLink>
          )
        },
      },
      resourcePath: {
        hide: true,
      },
      resourcePathDisplay: {
        headerName: 'Resource Path',
      },
    }
  }

  handleDoubleClick = row => {
    if (row.srn) {
      this.props.push(getNodeViewPushParams(row.srn, getTypeFromSrn(row.srn)))
    }
  }

  render() {
    return (
      <BorderedCard style={{ height: '100%' }}>
        <div style={this.styles.title}>
          <DynamicFormattedMessage {...messages.directAttachedPolicies} />
          &nbsp;&nbsp;
          <IHelp
            id="directattached"
            info={true}
            infoMsg="Role-based access permission and policies directly attached to the identity"
            iconSize="20px"
            position="right"
          />
        </div>
        <div style={this.styles.body}>
          {this.props.policies.get('isLoading') ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SquareLoadingAnimation />
            </div>
          ) : (
            <DataTable
              customColumnConfig={this.getConfig()}
              data={this.formatData()}
              onDoubleClickRow={this.handleDoubleClick}
            />
          )}
        </div>
      </BorderedCard>
    )
  }
}

DirectPolicyWidget.propTypes = {
  policies: ImmutablePropTypes.map,
  onClick: PropTypes.func,
  push: PropTypes.func,
  theme: themeShape,
  account: PropTypes.string,
}

export default themeable(DirectPolicyWidget)
