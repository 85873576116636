import {
  FETCH_SAVED_SEARCH_WIDGET_RESULTS,
  FETCH_ADVANCED_SEARCH_WIDGET_RESULTS,
  FETCH_CUSTOM_SEARCH_WIDGET_RESULTS,
  SET_WIDGET_RESULTS,
  SET_TITLE,
  SET_SONRAI_SEARCH_NAME,
  SET_SAVED_SEARCH_ID,
  SET_WIDGET_RESULTS_ERROR,
  SET_FILTER,
  SET_SAVED_SEARCH_FILTER_CARD_ID,
  SET_ADVANCED_QUERY,
} from './constants'
import { createAction } from 'redux-actions'

export const fetchSavedSearchWidgetResults = createAction(
  FETCH_SAVED_SEARCH_WIDGET_RESULTS
)
export const fetchAdvancedSearchWidgetResults = createAction(
  FETCH_ADVANCED_SEARCH_WIDGET_RESULTS
)
export const fetchCustomSearchWidgetResults = createAction(
  FETCH_CUSTOM_SEARCH_WIDGET_RESULTS
)
export const setWidgetResults = createAction(SET_WIDGET_RESULTS)
export const setTitle = createAction(SET_TITLE)
export const setSonraiSearchName = createAction(SET_SONRAI_SEARCH_NAME)
export const setSavedSearchId = createAction(SET_SAVED_SEARCH_ID)
export const setWidgetResultsError = createAction(SET_WIDGET_RESULTS_ERROR)
export const setFilter = createAction(SET_FILTER)
export const setSavedSearchFilterCardId = createAction(
  SET_SAVED_SEARCH_FILTER_CARD_ID
)
export const setAdvancedQuery = createAction(SET_ADVANCED_QUERY)
