import Color from 'color'
import { getIconMapping } from 'utils/sonraiUtils'

export const getIcon = (type = '') => {
  return getIconMapping(type).unicode
}

export const getColor = (type = '', theme, typeColors) => {
  type = type.toLowerCase()

  if (type && typeColors && typeColors[type]) {
    return typeColors[type]
  }

  if (!type) {
    return '#000000'
  }

  if (type === 'rollup') {
    return '#000000'
  }

  if (
    type.includes('type') ||
    type.includes('classification') ||
    (type.includes('group') && type !== 'group')
  ) {
    return theme.secondary
  }

  if (
    type.includes('platform') ||
    type === 'parameter' ||
    type === 'property' ||
    type.includes('variable')
  ) {
    return Color(theme.primary)
      .darken(0.5)
      .hex()
  }

  if (
    type === 'region' ||
    type === 'availabilityzone' ||
    type === 'location' ||
    type === 'subdivision' ||
    type === 'country' ||
    type === 'city'
  ) {
    return Color(theme.emphasis)
      .red(250)
      .hex()
  }

  return theme.primary
}
