import {
  GET_COMPUTE_CONTENT,
  SET_COMPUTE_CONTENT,
  GET_INBOUND_PUBLIC_FILTERS,
  SET_INBOUND_PUBLIC_FILTERS,
  GET_INBOUND_PRIVATE_FILTERS,
  SET_INBOUND_PRIVATE_FILTERS,
  GET_INBOUND_PUBLIC_PATH,
  SET_INBOUND_PUBLIC_PATH,
  GET_INBOUND_PRIVATE_PATH,
  SET_INBOUND_PRIVATE_PATH,
  GET_OUTBOUND_PUBLIC_FILTERS,
  SET_OUTBOUND_PUBLIC_FILTERS,
  GET_OUTBOUND_PRIVATE_FILTERS,
  SET_OUTBOUND_PRIVATE_FILTERS,
  GET_OUTBOUND_PUBLIC_PATH,
  SET_OUTBOUND_PUBLIC_PATH,
  GET_OUTBOUND_PRIVATE_PATH,
  SET_OUTBOUND_PRIVATE_PATH,
  GET_INDIRECT_INBOUND_PUBLIC_FILTERS,
  SET_INDIRECT_INBOUND_PUBLIC_FILTERS,
  GET_INDIRECT_INBOUND_PRIVATE_FILTERS,
  SET_INDIRECT_INBOUND_PRIVATE_FILTERS,
  GET_INDIRECT_INBOUND_PUBLIC_PATH,
  SET_INDIRECT_INBOUND_PUBLIC_PATH,
  GET_INDIRECT_INBOUND_PRIVATE_PATH,
  SET_INDIRECT_INBOUND_PRIVATE_PATH,
  GET_COMPUTE_SECURITY_GROUPS,
  SET_COMPUTE_SECURITY_GROUPS,
  ERR_COMPUTE_SECURITY_GROUPS,
} from './constants'
import { createAction } from 'redux-actions'

export const getComputeContent = createAction(GET_COMPUTE_CONTENT)
export const setComputeContent = createAction(SET_COMPUTE_CONTENT)
export const getInboundPublicFilters = createAction(GET_INBOUND_PUBLIC_FILTERS)
export const setInboundPublicFilters = createAction(SET_INBOUND_PUBLIC_FILTERS)

export const getInboundPrivateFilters = createAction(
  GET_INBOUND_PRIVATE_FILTERS
)
export const setInboundPrivateFilters = createAction(
  SET_INBOUND_PRIVATE_FILTERS
)

export const getInboundPublicPath = createAction(GET_INBOUND_PUBLIC_PATH)
export const setInboundPublicPath = createAction(SET_INBOUND_PUBLIC_PATH)
export const getInboundPrivatePath = createAction(GET_INBOUND_PRIVATE_PATH)
export const setInboundPrivatePath = createAction(SET_INBOUND_PRIVATE_PATH)

export const getOutboundPublicFilters = createAction(
  GET_OUTBOUND_PUBLIC_FILTERS
)
export const setOutboundPublicFilters = createAction(
  SET_OUTBOUND_PUBLIC_FILTERS
)

export const getOutboundPrivateFilters = createAction(
  GET_OUTBOUND_PRIVATE_FILTERS
)
export const setOutboundPrivateFilters = createAction(
  SET_OUTBOUND_PRIVATE_FILTERS
)

export const getOutboundPublicPath = createAction(GET_OUTBOUND_PUBLIC_PATH)
export const setOutboundPublicPath = createAction(SET_OUTBOUND_PUBLIC_PATH)
export const getOutboundPrivatePath = createAction(GET_OUTBOUND_PRIVATE_PATH)
export const setOutboundPrivatePath = createAction(SET_OUTBOUND_PRIVATE_PATH)

export const getIndirectInboundPublicFilters = createAction(
  GET_INDIRECT_INBOUND_PUBLIC_FILTERS
)
export const setIndirectInboundPublicFilters = createAction(
  SET_INDIRECT_INBOUND_PUBLIC_FILTERS
)

export const getIndirectInboundPrivateFilters = createAction(
  GET_INDIRECT_INBOUND_PRIVATE_FILTERS
)
export const setIndirectInboundPrivateFilters = createAction(
  SET_INDIRECT_INBOUND_PRIVATE_FILTERS
)

export const getIndirectInboundPublicPath = createAction(
  GET_INDIRECT_INBOUND_PUBLIC_PATH
)
export const setIndirectInboundPublicPath = createAction(
  SET_INDIRECT_INBOUND_PUBLIC_PATH
)
export const getIndirectInboundPrivatePath = createAction(
  GET_INDIRECT_INBOUND_PRIVATE_PATH
)
export const setIndirectInboundPrivatePath = createAction(
  SET_INDIRECT_INBOUND_PRIVATE_PATH
)

export const getComputeSecurityGroups = createAction(
  GET_COMPUTE_SECURITY_GROUPS
)
export const setComputesecurityGroups = createAction(
  SET_COMPUTE_SECURITY_GROUPS
)
export const errComputeSecurityGroups = createAction(
  ERR_COMPUTE_SECURITY_GROUPS
)
