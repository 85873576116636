import {
  ADD_FIELD,
  ADD_RESULT_VIEW,
  CHANGE_ARGUMENT,
  CHANGE_FIELD_NAME,
  REMOVE_ARGUMENT,
  REMOVE_FIELD,
  REMOVE_RESULT_VIEW,
  RESET_QUERY,
  START_QUERY,
  LOAD_SAVED_QUERY,
  UPDATE_RESULT_VIEW,
  SET_DEFAULT_VIEW,
  ADD_ARGUMENT,
  MOVE_ARGUMENT,
  LOAD_AND_CONVERT_SAVED_QUERY,
  CHANGE_CHILD_OPERATOR,
  ADD_PROPERTY,
  REMOVE_PROPERTY,
  TOGGLE_DEFAULT_PROPERTY,
  RESET_DEFAULT_PROPERTIES,
  ADD_COMMENT,
  REMOVE_COMMENT,
  SET_QUERY_PROPERTY_ORDER,
  SET_QUERY_COLUMN_ALIAS,
  REMOVE_QUERY_COLUMN_ALIAS,
  ADD_EFFECTIVE_PERMISSION_INCLUDE_GROUP,
  ADD_EFFECTIVE_PERMISSION_EXCLUDE_GROUP,
  REMOVE_EFFECTIVE_PERMISSION_GROUP,
  ADD_EFFECTIVE_PERMISSION,
  REMOVE_EFFECTIVE_PERMISSION,
  SET_EFFECTIVE_PERMISSION_PROPERTY_ORDER,
  ADD_EP_DISPLAY_COLUMN,
  REMOVE_EP_DISPLAY_COLUMN,
  SET_IS_DISTINCT_EP,
  REMOVE_EFFECTIVE_PERMISSION_BLOCK,
  SET_EP_DISPLAY_COLUMN_ALIAS,
  REMOVE_EP_COLUMN_ALIAS,
  UPDATE_EFFECTIVE_PERMISSION,
  INSERT_FIELD,
  SET_SEARCH_LIMIT,
  CLEAR_SEARCH_LIMIT,
} from './constants'
import { createAction } from 'redux-actions'

export const addField = createAction(ADD_FIELD)
export const addResultView = createAction(ADD_RESULT_VIEW)
export const changeArgument = createAction(CHANGE_ARGUMENT)
export const changeFieldName = createAction(CHANGE_FIELD_NAME)
export const removeArgument = createAction(REMOVE_ARGUMENT)
export const removeField = createAction(REMOVE_FIELD)
export const removeResultView = createAction(REMOVE_RESULT_VIEW)
export const startQuery = createAction(START_QUERY)
export const loadSavedQuery = createAction(LOAD_SAVED_QUERY)
export const resetQuery = createAction(RESET_QUERY)
export const updateResultView = createAction(UPDATE_RESULT_VIEW)

export const setDefaultView = createAction(SET_DEFAULT_VIEW)
export const addArgument = createAction(ADD_ARGUMENT)
export const moveArgument = createAction(MOVE_ARGUMENT)
export const loadAndConvertSavedQuery = createAction(
  LOAD_AND_CONVERT_SAVED_QUERY
)
export const changeChildOperator = createAction(CHANGE_CHILD_OPERATOR)

export const addProperty = createAction(ADD_PROPERTY)
export const removeProperty = createAction(REMOVE_PROPERTY)
export const toggleDefaultProperty = createAction(TOGGLE_DEFAULT_PROPERTY)
export const resetDefaultProperties = createAction(RESET_DEFAULT_PROPERTIES)

export const addComment = createAction(ADD_COMMENT)
export const removeComment = createAction(REMOVE_COMMENT)
export const setQueryPropertyOrder = createAction(SET_QUERY_PROPERTY_ORDER)
export const setQueryColumnAlias = createAction(SET_QUERY_COLUMN_ALIAS)
export const removeQueryColumnAlias = createAction(REMOVE_QUERY_COLUMN_ALIAS)
export const addEffectivePermissionIncludeGroup = createAction(
  ADD_EFFECTIVE_PERMISSION_INCLUDE_GROUP
)
export const setEffectivePermissionPropertyOrder = createAction(
  SET_EFFECTIVE_PERMISSION_PROPERTY_ORDER
)
export const addEffectivePermissionExcludeGroup = createAction(
  ADD_EFFECTIVE_PERMISSION_EXCLUDE_GROUP
)
export const removeEffectivePermissionGroup = createAction(
  REMOVE_EFFECTIVE_PERMISSION_GROUP
)
export const addEffectivePermission = createAction(ADD_EFFECTIVE_PERMISSION)
export const removeEffectivePermission = createAction(
  REMOVE_EFFECTIVE_PERMISSION
)
export const addEPDisplayColumn = createAction(ADD_EP_DISPLAY_COLUMN)
export const removeEPDisplayColumn = createAction(REMOVE_EP_DISPLAY_COLUMN)
export const setIsDistinctEP = createAction(SET_IS_DISTINCT_EP)
export const setEPDisplayColumnAlias = createAction(SET_EP_DISPLAY_COLUMN_ALIAS)
export const removeEPColumnAlias = createAction(REMOVE_EP_COLUMN_ALIAS)
export const removeEffectivePermissionBlock = createAction(
  REMOVE_EFFECTIVE_PERMISSION_BLOCK
)
export const updateEffectivePermission = createAction(
  UPDATE_EFFECTIVE_PERMISSION
)
export const insertField = createAction(INSERT_FIELD)
export const setSearchLimit = createAction(SET_SEARCH_LIMIT)
export const clearSearchLimit = createAction(CLEAR_SEARCH_LIMIT)
