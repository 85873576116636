import React from 'react'
import PropTypes from 'prop-types'
import LoadingAnim from 'components/LoadingAnim'

const styles = {
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
class Loading extends React.PureComponent {
  render() {
    return (
      <div style={styles.wrapper}>
        <LoadingAnim error={this.props.error} inverse />
      </div>
    )
  }
}

Loading.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default Loading
