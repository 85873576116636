import { takeLatest, put, call, select } from 'redux-saga/effects'
import { configureAxios } from 'axiosConfig'
import jwt from 'jsonwebtoken'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'
import { List, fromJS } from 'immutable'
import _ from 'lodash'

import {
  LOAD_USER,
  SET_USER_PROFILE_TABLES,
  SAVE_USER_PROFILE,
  FETCH_USER_PROFILE,
  UNHIDE_SOLUTION_CARDS,
  HIDE_SOLUTION_CARD,
  ACCEPT_LICENSE,
  SET_SECURITY_CENTER_WELCOME_MODAL,
} from './constants'
import {
  setUserProfile,
  setSonraiUserSrn,
  setUserProfileError,
} from './actions'
import { selectUserProfile } from './selectors'

const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation saveUserProfile($profile: Blob) {
    UpdateSonraiCurrentUsers(input: [{ profile: $profile }]) {
      items {
        srn
        profile
      }
    }
  }
`

async function updateUserProfile({ profile }) {
  const client = await getClient()
  const result = await client.mutate({
    mutation: UPDATE_USER_PROFILE_MUTATION,
    variables: {
      profile,
    },
    forceFetch: true,
    fetchPolicy: 'no-cache',
  })
  return result.data.UpdateSonraiCurrentUsers.items[0].profile
}

function* saveUserProfile(prof, srn) {
  try {
    let profile, newSRN

    if (prof.payload) {
      profile = prof.payload
      newSRN = profile.srn
    } else {
      profile = prof.profile
      newSRN = srn || prof.srn
    }
    const client = getClient()

    yield client.mutate({
      mutation: UPDATE_USER_PROFILE_MUTATION,
      variables: {
        profile,
      },
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })

    yield put(setUserProfile(profile, newSRN))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error saving user profile', e)
  }
}

function* setSecurityCenterWelcomeModal() {
  const profile = yield select(selectUserProfile)
  const updatedProfile = {
    ...profile.toJS(),
    hasSeenSecurityCenterWelcomeModal: true,
  }

  yield put(setUserProfile(updatedProfile)) //optimistic update

  try {
    const newProfile = yield updateUserProfile({ profile: updatedProfile })
    yield put(setUserProfile(newProfile))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating User Profile', e)
  }
}

function* setUserProfileTables(action) {
  const profile = yield select(selectUserProfile)

  const oldConfig = profile.getIn(
    ['tables', action.payload.set, 'widgets'],
    List()
  )
  const index = oldConfig.findIndex(
    tableConf => tableConf.get('id') === action.payload.id
  )

  const tableConfig = fromJS({
    id: action.payload.id,
    grid: action.payload.grid,
  })
  const newConfig =
    index > -1 ? oldConfig.set(index, tableConfig) : oldConfig.push(tableConfig)
  const updatedProfile = profile
    .setIn(['tables', action.payload.set, 'widgets'], newConfig)
    .toJS()

  yield put(setUserProfile(updatedProfile)) //optimistic update

  try {
    const newProfile = yield updateUserProfile({ profile: updatedProfile })
    yield put(setUserProfile(newProfile))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating User Profile', e)
  }
}

function* getSonraiUser(srn) {
  const FETCH_SONRAI_USER_SRN_QUERY = `
      query sonrai_user($srn: String) {
        SonraiUsers(where: { srn: { value: $srn } }) {
          items {
            srn
          }
        }
      }
    `
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${FETCH_SONRAI_USER_SRN_QUERY}
      `,
      forceFetch: true,
      fetchPolicy: 'no-cache',
      variables: {
        srn: srn,
      },
    })

    yield put(
      setSonraiUserSrn(
        _.get(results, ['data', 'SonraiUsers', 'items', 0, 'srn'], null)
      )
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching sonrai user', e)
  }
}

function* fetchUserProfile(id) {
  try {
    const client = getClient()

    const results = yield client.query({
      query: gql`
        query currentUser {
          SonraiCurrentUsers {
            items {
              profile
              srn
            }
          }
        }
      `,
      forceFetch: true,
      fetchPolicy: 'no-cache',
      variables: {
        userId: id,
      },
    })

    const user = _.get(
      results,
      ['data', 'SonraiCurrentUsers', 'items', 0],
      null
    )
    if (user === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    let { profile, srn } = user

    // if profile has not been init yet, do that now
    if (!profile || profile.profile === null) {
      profile = {
        srn: srn,
        hasAcceptedLegal: false,
      }

      yield call(saveUserProfile, profile, srn)
    }

    yield call(getSonraiUser, srn)
    return { profile, srn }
  } catch (e) {
    yield put(setUserProfileError(`Error fetching User Profile: ${e.message}`))

    //eslint-disable-next-line no-console
    console.error('error fetching user profile', e)
  }
}

function* loadUser({ payload }) {
  const accessToken = payload
  if (accessToken) {
    configureAxios(accessToken)

    try {
      const { sub } = jwt.decode(accessToken)

      const userProfile = yield call(fetchUserProfile, sub)
      let srn = userProfile.srn
      let profile = userProfile.profile
      yield put(setUserProfile(profile, srn))
    } catch (e) {
      //eslint-disable-next-line no-console
      console.error('Error loading the user info', e)
    }
  }
}

function* unhideSolutionCardUserProfile(action) {
  const cardsToUnhide = action.payload
  const profile = yield select(selectUserProfile)

  const newHiddenCards = profile
    .get('hiddenSolutionCards', List())
    .filter(cardSrn => !cardsToUnhide.includes(cardSrn))

  const updatedProfile = profile
    .set('hiddenSolutionCards', newHiddenCards)
    .toJS()

  yield put(setUserProfile(updatedProfile)) //Optimistic update

  try {
    const newProfile = yield updateUserProfile({ profile: updatedProfile })
    yield put(setUserProfile(newProfile))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Unable to unhide solution cards ${e}`)
  }
}

function* hideSolutionCardUserProfile(action) {
  const cardId = action.payload
  const profile = yield select(selectUserProfile)

  try {
    const oldConfig = profile.get('hiddenSolutionCards', List())
    if (oldConfig.includes(cardId)) {
      return
    }

    const updatedProfile = profile
      .set('hiddenSolutionCards', oldConfig.push(cardId))
      .toJS()

    yield put(setUserProfile(updatedProfile)) //for optimistic updating

    const newProfile = yield updateUserProfile({ profile: updatedProfile })
    yield put(setUserProfile(newProfile))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Failed to hide solution card ${e}`)
  }
}

function* acceptLicense() {
  const profile = yield select(selectUserProfile)
  try {
    const updatedProfile = profile
      .set('hasAcceptedLegal', true)
      .set('acceptedLegalOn', new Date())
      .toJS()

    yield put(setUserProfile(updatedProfile)) //for optimistic updating
    const newProfile = yield updateUserProfile({ profile: updatedProfile })
    yield put(setUserProfile(newProfile))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Failed to accept legal ${e}`)
  }
}

function* userProfileDataSaga() {
  yield takeLatest(SET_USER_PROFILE_TABLES, setUserProfileTables)
  yield takeLatest(LOAD_USER, loadUser)
  yield takeLatest(SAVE_USER_PROFILE, saveUserProfile)
  yield takeLatest(FETCH_USER_PROFILE, fetchUserProfile)
  yield takeLatest(UNHIDE_SOLUTION_CARDS, unhideSolutionCardUserProfile)
  yield takeLatest(HIDE_SOLUTION_CARD, hideSolutionCardUserProfile)
  yield takeLatest(ACCEPT_LICENSE, acceptLicense)
  yield takeLatest(
    SET_SECURITY_CENTER_WELCOME_MODAL,
    setSecurityCenterWelcomeModal
  )
}

export default userProfileDataSaga
