import { createSelector } from 'reselect'
import { DOMAIN } from './constants'
import { initialState } from './reducers'
import { Map } from 'immutable'

export const selectDomain = state => state.get(DOMAIN) || initialState

export const selectChangeDetectionOptions = createSelector(
  selectDomain,
  state => state.get('changeDectionOptions')
)

export const selectTicketList = createSelector(selectDomain, state =>
  state.get('ticketList')
)

export const selectTicketListFilter = createSelector(
  selectDomain,
  state => state.get('ticketListFilter') || Map()
)
