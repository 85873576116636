import {
  all,
  put,
  takeLatest,
  take,
  race,
  call,
  select,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'
import {
  FETCH_SAVED_SEARCHES_QUERY,
  FETCH_SONRAI_SEARCHES_QUERY,
  FETCH_ACCOUNTS_QUERY,
  FETCH_TAGS_QUERY,
  FETCH_DATA_CONTAINERS_QUERY,
  CHECK_FOR_CLOUD_ACCOUNTS_QUERY,
  MONITORED_RESOURCES_COUNT_QUERY,
  FETCH_SUBSCRIPTIONS_QUERY,
} from 'static-queries'

import {
  GET_ALL_BOTS,
  CREATE_BOT_MUTATION,
  GET_FILTERED_BOTS,
} from 'containers/BotManagement/static-queries'

import { setSwimlaneBotError } from 'containers/SwimlaneDetails/actions'

import {
  setRelations,
  setTypes,
  setSavedSearches,
  setAllowedRootQueries,
  setSonraiSearches,
  fetchAccountsSuccess,
  fetchTagsSuccess,
  fetchDataContainersSuccess,
  setQueryNames,
  setGraphQLSchema,
  setHasCollectors,
  setMonitoredResourcesCount,
  fetchSwimlanesSuccess,
  fetchSubscriptionsSuccess,
  fetchSonraiUsersSuccess,
  updateSonraiUserSuccess,
  setAllRoles,
  errAllRoles,
  updateUserRolesSuccess,
  setGlobalError,
  setBots,
  validateSourceUrlSuccess,
  createBotSuccess,
  handleDeleteBotsSuccess,
  updateBotAssignmentsSuccess,
  removeSwimlaneSuccess,
  setExemptedIdentities,
  setExemptedIdentitiesError,
  addExemptedIdentitiesSuccess,
  deleteExemptedIdentitiesSuccess,
  setObjectives,
  fetchObjectivesError,
  fetchObjectives as fetchObjectivesAction,
  enableObjectiveSuccess,
  enableObjectiveError,
  setSonraiConfig,
  enableAllObjectivesSuccess,
  enableAllObjectivesError,
  enableAllObjectivesForSwimlaneSuccess,
  enableAllObjectivesForSwimlaneError,
} from './actions'

import { generateTypeColors } from 'containers/ThemeManager/actions'
import { fetchControlGroups } from 'containers/ControlFrameworkData/actions'
import {
  RUN_INSPECTION_QUERY,
  FETCH_SAVED_SEARCHES,
  FETCH_SAVED_SEARCHES_DETAILS,
  FETCH_SONRAI_SEARCHES,
  FETCH_ACCOUNTS,
  FETCH_TAGS,
  FETCH_DATA_CONTAINERS,
  START_UPDATE_DATA_POLL,
  STOP_UPDATE_DATA_POLL,
  CHECK_IF_HAS_COLLECTORS,
  GET_MONITORED_RESOURCES_COUNT,
  FETCH_SWIMLANES,
  FETCH_SUBSCRIPTIONS,
  FETCH_SONRAI_USERS,
  UPDATE_SONRAI_USER,
  GET_ALL_ROLES,
  UPDATE_USER_ROLES,
  GET_BOTS,
  VALIDATE_SOURCE_URL,
  HANDLE_DELETE_BOTS,
  UPDATE_BOT_ASSIGNMENT,
  REMOVE_SWIMLANE,
  FETCH_GRAPHQL_SCHEMA,
  GET_EXEMPTED_IDENTITIES,
  ADD_EXEMPTED_IDENTITIES,
  DELETE_EXEMPTED_IDENTITIES,
  FETCH_OBJECTIVES,
  ENABLE_OBJECTIVE,
  ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE,
  FETCH_SONRAI_CONFIG,
  ENABLE_ALL_OBJECTIVES,
} from './constants'
import {
  selectBots,
  selectSavedSearches,
  selectPermissions,
  selectObjectives,
} from './selectors'
import { getClient } from 'apolloClient'
import { INSPECTION_QUERY } from 'query-builder'
import gql from 'graphql-tag'
import { List, fromJS, Map } from 'immutable'
import { getIntrospectionQuery } from 'graphql'
import { POLL_FREQUENCY } from 'appConstants'
import _ from 'lodash'

function* runInspectionQuery() {
  try {
    const client = getClient()

    var data = null

    // try to fetch the data multiple times, retry if there is timeout
    const MAX_INTROSPECTION_ATTEMPTS = 5
    for (var attempt = 0; attempt < MAX_INTROSPECTION_ATTEMPTS; attempt++) {
      try {
        const result = yield client.query({
          query: INSPECTION_QUERY,
          fetchPolicy: 'no-cache',
          context: {
            queryName: 'pivoted-inspection-query',
          },
        })
        data = result.data
        break
      } catch (e) {
        // check error to make sure we did timeout
        const networkErrorMessage = _.get(
          e,
          ['networkError', 'result', 'errors', 0, 'message'],
          ''
        )
        const networkStatusCode = _.get(e, ['networkError', 'statusCode'], -1)

        const isTimeoutStatus = networkStatusCode === 504
        const isTimeoutMessage = networkErrorMessage.startsWith(
          'The request timed out after '
        )
        if (!(isTimeoutStatus && isTimeoutMessage)) {
          throw e // if it's some other error, throw up
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            `retrying introspection query fetch attempt ${
              attempt + 1
            }/${MAX_INTROSPECTION_ATTEMPTS}`
          )
        }
      }
    }

    if (data === null) {
      throw new Error(
        `could not fetch after ${MAX_INTROSPECTION_ATTEMPTS} attempts due to timeout`
      )
    }

    if (data.Queries) {
      let queries = ['AnalyticsResults'].concat(data.Queries)
      queries.sort()
      yield put(setAllowedRootQueries(queries))
    }

    if (data.Relations) {
      const relations = data.Relations
      yield put(setRelations(relations))
    }

    if (data.PivotedIntrospection) {
      yield put(setTypes(data.PivotedIntrospection.types))
      yield put(generateTypeColors())
    }

    if (data.__type) {
      const queryNames = data.__type.fields.reduce((types, nextType) => ({
        ...types,
        [nextType.type.name]: nextType,
      }))

      yield put(setQueryNames(queryNames))
    }
  } catch (e) {
    if (Object.values(e)[2] === 'Network error: Failed to fetch') {
      yield put(setGlobalError(e.message))
    }
    //eslint-disable-next-line no-console
    console.error('Error running introspection query', e)
  }
}

function* getGraphqlSchema() {
  try {
    const client = getClient()

    const schema_response = yield client.query({
      query: gql`
        ${getIntrospectionQuery()}
      `,
      fetchPolicy: 'no-cache',
      context: {
        queryName: 'graphiql-inspection-query',
      },
    })
    if (schema_response) {
      const schema_data = schema_response.data

      if (schema_data) {
        yield put(setGraphQLSchema(schema_data))
      }
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error fetching the graphql schema', e)
  }
}

function* getSonraiConfig() {
  try {
    const client = getClient()
    const result = yield client.query({
      query: gql`
        query getSonraiUIConfig {
          SonraiUiConfigs {
            items {
              config
            }
          }
        }
      `,
    })

    if (result.data.SonraiUiConfigs.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }
    const sonraiConfig = _.get(
      _.first(result.data.SonraiUiConfigs.items),
      ['config'],
      {}
    )

    const config = _.merge(window.config, sonraiConfig)
    window.config = config

    yield put(setSonraiConfig())
  } catch (e) {
    yield put(setSonraiConfig())
    yield put(setGlobalError(`Error fetching Sonrai UI Config: ${e.message}`))

    //eslint-disable-next-line no-console
    console.error('Failed to load Sonrai UI Config', e)
  }
}

export function* fetchSavedSearches(action) {
  try {
    const client = getClient(action.poll)

    let includeDetails = true
    const isFirstPoll = (yield select(selectSavedSearches)).isEmpty()
    if (isFirstPoll && action.forceIncludeDetails != true) {
      // we can save ourselves some time on initial render by not fetching the
      // relationships for the seaches. They're only needed on the Manage Searches page
      const location = yield select(state => {
        return state.getIn(['router', 'location', 'pathname'], '')
      })
      if (!location.startsWith('/App/SearchManager')) {
        includeDetails = false
      }
    }

    const results = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        ${FETCH_SAVED_SEARCHES_QUERY}
      `,
      variables: { includeDetails },
    })

    if (results.data.Searches.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const immutableResults = fromJS(results.data)
    const resultsMappedById = Map(
      immutableResults
        .getIn(['Searches', 'items'], List())
        .map(search => List([search.get('sid'), search]))
    )

    yield put(
      setSavedSearches({
        results: resultsMappedById,
        withDetails: includeDetails,
      })
    )

    if (!includeDetails) {
      // if we didn't load details this time, we can preemtively load it now
      yield call(fetchSavedSearchesDetails)
    }
  } catch (e) {
    yield put(setGlobalError(`Error fetching Saved Searches: ${e.message}`))
    //eslint-disable-next-line no-console
    console.error('error fetching saved searches', e)
  }
}

function* fetchSavedSearchesDetails() {
  yield call(fetchSavedSearches, { forceIncludeDetails: true })
}

function* getSavedSonraiSeaches(action) {
  try {
    const client = getClient(action.poll)
    const result = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        ${FETCH_SONRAI_SEARCHES_QUERY}
      `,
    })

    if (result.data.SavedQueries.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setSonraiSearches(result.data.SavedQueries.items))
  } catch (e) {
    yield put(setGlobalError(`Error fetching Sonrai Searches: ${e.message}`))

    //eslint-disable-next-line no-console
    console.error('Failed to load Sonrai saved searches', e)
  }
}

function* getAccounts(action) {
  try {
    const client = getClient(action.poll)
    const results = yield client.query({
      query: gql`
        ${FETCH_ACCOUNTS_QUERY}
      `,
    })

    if (results.data.Accounts.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const immutableResults = fromJS(results.data).getIn(
      ['Accounts', 'items'],
      List()
    )

    yield put(fetchAccountsSuccess(immutableResults))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching accounts', e)
  }
}

function* getAzureSubscriptions() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${FETCH_SUBSCRIPTIONS_QUERY}
      `,
    })

    if (results.data.Subscriptions.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const immutableResults = fromJS(results.data).getIn(
      ['Subscriptions', 'items'],
      List()
    )

    yield put(fetchSubscriptionsSuccess(immutableResults))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching subscriptions', e)
  }
}

function* getTags() {
  try {
    const client = getClient()

    const results = yield client.query({
      query: gql`
        ${FETCH_TAGS_QUERY}
      `,
    })

    const immutableResults = fromJS(
      results.data.Tags.group
        // filter to ensure we don't end up with null/undef. in list of tags
        .filter(tagGroup => (tagGroup.items || []).length > 0)
        .map(groupedResult => groupedResult.items[0])
    )

    yield put(fetchTagsSuccess(immutableResults))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching tags', e)
  }
}

function* getDataContainers() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${FETCH_DATA_CONTAINERS_QUERY}
      `,
    })

    if (results.data.DataContainers.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const immutableResults = fromJS(results.data).getIn(
      ['DataContainers', 'items'],
      List()
    )

    yield put(fetchDataContainersSuccess(immutableResults))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching data containers', e)
  }
}

export function* checkIfHasCollectors() {
  try {
    const client = getClient()
    const results = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        ${CHECK_FOR_CLOUD_ACCOUNTS_QUERY}
      `,
    })

    const count = results.data.PlatformAccounts.count

    yield put(setHasCollectors(count > 0))
  } catch (err) {
    yield put(setGlobalError(`Error verifying org collectors: ${err.message}`))

    //eslint-disable-next-line no-console
    console.error(err)
    yield put(setHasCollectors(true))
  }
}

export function* getMonitoredResourcesCount() {
  try {
    const client = getClient()
    const results = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        ${MONITORED_RESOURCES_COUNT_QUERY}
      `,
    })

    const count = results.data.Resources.count

    yield put(setMonitoredResourcesCount(count))
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error(err)
  }
}

function* getSonraiUsers() {
  const SONRAI_USERS_QUERY = `
    query getSonraiUsers {
      SonraiUsers {
        items {
          srn
          email
          name
          isActive
          lastLogin
          resourceId
          roleAssignments {
            items {
              srn
              scope
              resourceId
              role {
                items {
                  srn
                  resourceId
                  name
                  description
                  permissions
                }
              }
            }
          }
          orgs
          avatarUrl
          lastLogin
        }
      }
    }
  `
  try {
    const client = getClient()
    const results = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        ${SONRAI_USERS_QUERY}
      `,
    })

    yield put(
      fetchSonraiUsersSuccess({
        users: _.get(results, ['data', 'SonraiUsers', 'items'], []),
      })
    )
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error(err)
  }
}

function* updateSonraiUser(action) {
  const UPDATE_SONRAI_USER_QUERY = `
    ${
      action.payload.me
        ? `mutation updateSonraiGuy($updateGuy: [SonraiCurrentUserUpdater!]!) {
          UpdateSonraiCurrentUsers(input: $updateGuy) {`
        : `mutation updateSonraiGuy($updateGuy: [SonraiUserUpdater!]!) {
          UpdateSonraiUsers(input: $updateGuy) {`
    }
        items {
          srn
          email
          name
          isActive
          lastLogin
          resourceId
          roleAssignments {
            items {
              srn
              scope
              resourceId
              role {
                items {
                  srn
                  resourceId
                  name
                  description
                  permissions
                }
              }
            }
          }
          orgs
          avatarUrl
          lastLogin
        }
      }
    }
  `
  const srnMaybe = action.payload.me ? {} : { srn: action.payload.srn }
  const variables = {
    ...srnMaybe,
    ...action.payload.variables,
  }

  try {
    const client = getClient()
    const results = yield client.mutate({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      mutation: gql`
        ${UPDATE_SONRAI_USER_QUERY}
      `,
      variables: {
        updateGuy: variables,
      },
    })

    const updatedGuy = _.get(
      results,
      [
        'data',
        action.payload.me ? `UpdateSonraiCurrentUsers` : `UpdateSonraiUsers`,
        'items',
        0,
      ],
      null
    )
    if (updatedGuy) {
      yield put(
        updateSonraiUserSuccess({ srn: action.payload.srn, guy: updatedGuy })
      )
    }
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error(err)
  }
}

function* getBotAssignments(contentSrn) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        query getBotAssignemntsOnSwimlane {
          BotAssignments(
            where: {
              contentSrn: {
                value: "${contentSrn}"
              }
            }
          ) {
            items {
              srn
              bot {
                srn
                title
                description
                cloud
                url
              }
            }
          }
        }
      `,
    })
    if (results.errors) {
      yield put(setSwimlaneBotError('Error fetching Bots for Swimlane'))
      return []
    } else {
      let nullBoiz = 0
      const botties = _.get(
        results,
        ['data', 'BotAssignments', 'items'],
        []
      ).map(
        //temp fix to prevent null bots ruining EVERYTHING
        item => {
          if (!item) {
            nullBoiz++
            return null
          } else {
            return {
              ...item.bot,
              botAssignmentSrn: item.srn,
            }
          }
        }
      )
      if (nullBoiz > 0) {
        yield put(setSwimlaneBotError('Error fetching Bots for Swimlane'))
      }
      return botties
    }
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error(err)
  }
}

function* getSwimlanes(action) {
  try {
    const client = getClient(action.poll)
    const results = yield client.query({
      query: gql`
        query fetchSwimlanes {
          Swimlanes {
            items {
              description
              label
              title
              srn
              defaultImportance
              createdBy
              sid
              preventionEnabled
              lastModified
              createdDate
              name
              accounts
              names
              resourceIds
              tags
              resourceId
            }
          }
        }
      `,
    })

    if (results.data.Swimlanes.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }
    const bots = yield all(
      results.data.Swimlanes.items.map(swimlane =>
        call(getBotAssignments, swimlane.srn)
      )
    )
    const updatedSwimlanes = results.data.Swimlanes.items.map(
      (swimlane, index) => {
        return { ...swimlane, bots: bots[index] }
      }
    )
    const immutableResults = fromJS(updatedSwimlanes)

    const mappedByTitle = Map(
      immutableResults.map(swimlane => List([swimlane.get('title'), swimlane]))
    )

    yield put(fetchSwimlanesSuccess(mappedByTitle))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching swimlanes', e)
  }
}

function* getAllRoles(action) {
  if (action.payload.skipAssignments) {
    return yield call(getRolesWithoutAssignments)
  }

  try {
    const client = getClient()
    const result = yield client.query({
      query: gql`
        query get_all_roles {
          SonraiRoles {
            items {
              name
              srn
              permissions
              expandedPermissions
              description
              resourceId
              roleAssignments {
                items {
                  scope
                  srn
                  user {
                    items {
                      srn
                      name
                      email
                      resourceId
                    }
                  }
                }
              }
            }
          }
        }
      `,
    })

    const roles = _.get(result, ['data', 'SonraiRoles', 'items'], null)
    if (roles == null) {
      throw 'the response did not return SonraiRoles.items'
    }

    const data = roles
      .map(role => {
        // try to get a list of unique users in the role
        const assignments = _.get(role, ['roleAssignments', 'items'], null)
        if (!assignments) {
          // eslint-disable-next-line no-console
          console.error(
            'role ' + role.srn + ' did not have roleAssignments.items'
          )
          return null
        }
        const users = assignments
          .map(assignment => {
            const user = _.get(assignment, ['user', 'items', 0], null)
            if (!user) {
              // eslint-disable-next-line no-console
              console.error(
                'role ' +
                  role.srn +
                  ' assigment ' +
                  assignment.srn +
                  ' did not have field user.items'
              )
              return null
            }
            return user
          })
          .filter(user => user != null)
        const uniqueUserNames = _.uniq(users.map(user => user.name))
        return {
          // omit description and put in after so the columns are in right order
          ..._.omit(role, ['description']),
          users: uniqueUserNames.length,
          description: role.description,
        }
      })
      .filter(role => role != null)

    yield put(setAllRoles({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('There was an error fetching all roles ', e)
    yield put(errAllRoles())
  }
}

function* getRolesWithoutAssignments() {
  try {
    const client = getClient()
    const result = yield client.query({
      query: gql`
        query get_all_roles {
          SonraiRoles {
            items {
              name
              srn
              permissions
              expandedPermissions
              description
              resourceId
            }
          }
        }
      `,
    })

    const roles = _.get(result, ['data', 'SonraiRoles', 'items'], null)
    if (roles == null) {
      throw 'the response did not return SonraiRoles.items'
    }

    //Fill in blank roleAssignments
    const data = roles.map(role => {
      role.roleAssignments = { items: [] }
      return role
    })

    yield put(setAllRoles({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('There was an error fetching the roles ', e)
    yield put(errAllRoles())
  }
}

function* updateRoles(action) {
  const DELETE_USER_ROLES_MUTATION = `
    mutation deleteRoleAssignment($deleteMe: [SonraiRoleAssignmentDeleter!]!) {
      DeleteSonraiRoleAssignments(input: $deleteMe) {
        items {
          srn
          deleted
        }
      }
    }
  `
  const CREATE_ROLE_ASSIGNMENT_MUTATION = `
    mutation assignRole($assignment: [SonraiRoleAssignmentCreator!]!) {
      CreateSonraiRoleAssignments(input: $assignment) {
        items {
          srn
          resourceId
          role {
            items {
              srn
              name
              description
              permissions
            }
          }
          scope
        }
      }
    }
  `
  const removed = []
  const added = []

  try {
    const client = getClient()
    if (action.payload.remove) {
      const response = yield client.mutate({
        mutation: gql`
          ${DELETE_USER_ROLES_MUTATION}
        `,
        variables: {
          deleteMe: action.payload.remove.map(srn => ({ srn: srn })),
        },
      })

      _.get(
        response,
        ['data', 'DeleteSonraiRoleAssignments', 'items'],
        []
      ).forEach(item => {
        if (item.deleted) {
          removed.push(item.srn)
        }
      })
    }

    if (action.payload.add) {
      const response = yield client.mutate({
        mutation: gql`
          ${CREATE_ROLE_ASSIGNMENT_MUTATION}
        `,
        variables: {
          assignment: action.payload.add.map(addMe => ({
            userSrn: action.payload.srn,
            roleSrn: addMe.roleSrn,
            scope: addMe.scope,
          })),
        },
      })

      _.get(
        response,
        ['data', 'CreateSonraiRoleAssignments', 'items'],
        []
      ).forEach(item => {
        added.push(item)
      })
    }

    yield put(
      updateUserRolesSuccess({
        userSrn: action.payload.srn,
        deletedSrns: removed,
        addedRoles: added,
      })
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error saving user profile', e)
  }
}

// BOT time

function* getAllBots(action) {
  const fields = action.payload.filters
  let filters = {}
  _.keys(fields).forEach(field => {
    if (fields[field]) {
      filters[field] = { value: fields[field].value }
    }
  })
  const client = getClient()
  let result

  try {
    if (!_.isEmpty(filters)) {
      result = yield client.query({
        query: GET_FILTERED_BOTS,
        variables: { filters },
      })
    } else {
      result = yield client.query({
        query: GET_ALL_BOTS,
      })
    }

    if (!result.data.Bots) {
      throw new Error('Bots query returned null')
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Error getting filtered bots ${e}`)
  }

  const bots = result.data.Bots.items

  yield put(setBots(bots))
}

function* removeBot(srn) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation deleteBot($srn: String!) {
          DeleteBot(input: { srn: $srn })
        }
      `,
      variables: { srn },
    })
    return { srn, success: result.data.DeleteBot }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Error deleting bot ${e}`)
  }
}

function* deleteBots(action) {
  const { bots } = action.payload
  const results = yield all(bots.map(bot => call(removeBot, bot)))
  const currentBots = yield select(selectBots)
  const confirmedDeleted = results
    .filter(item => item.success === true)
    .map(item => item.srn)
  const updatedBots = currentBots
    .get('data')
    .toJS()
    .filter(bot => !confirmedDeleted.includes(bot.srn))
  yield put(handleDeleteBotsSuccess(updatedBots))
}

function* createBot(url) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: CREATE_BOT_MUTATION,
      variables: { url },
    })
    if (result.data.CreateBot) {
      return result.data.CreateBot
    } else {
      return {}
    }
  } catch (e) {
    return {}
  }
}

function* updateBotAssignments(action) {
  const CREATE_BOT_ASSIGNMENT_MUTATION = `
    mutation createAssignmentForSwimlane($input: [CreateBotAssignmentInput!]!) {
      CreateBotAssignments(input: $input) {
        items {
          srn
          bot {
            srn
            title
            description
            cloud
            url
          } 
        }
      }
    }
  `

  const DELETE_BOT_ASSIGNMENT_MUTATION = `
    mutation deleteBotAssignment($input: [RemoveBotAssignmentInput!]!) {
      RemoveBotAssignments(input: $input) {
        items
      }
    }
  `

  try {
    const client = getClient()

    if (action.payload.add) {
      const result = yield client.mutate({
        mutation: gql`
          ${CREATE_BOT_ASSIGNMENT_MUTATION}
        `,
        variables: {
          input: action.payload.add,
        },
      })

      if (result.errors) {
        yield put(
          setSwimlaneBotError(
            _.get(result, ['errors', 0, 'message'], 'Error assigning bot')
          )
        )
      }
    }

    if (action.payload.remove) {
      const result = yield client.mutate({
        mutation: gql`
          ${DELETE_BOT_ASSIGNMENT_MUTATION}
        `,
        variables: {
          input: action.payload.remove.map(srn => ({ srn: srn })),
        },
      })

      if (result.errors) {
        yield put(
          setSwimlaneBotError(
            _.get(result, ['errors', 0, 'message'], 'Error assigning bot')
          )
        )
      }
    }

    const bots = yield call(getBotAssignments, action.payload.swimlaneSrn)

    yield put(
      updateBotAssignmentsSuccess({
        swimlaneSrn: action.payload.swimlaneSrn,
        bots: bots,
      })
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error updating bot assignments', e)
  }
}

function* validateSourceUrl(action) {
  const { url } = action.payload
  // //some query here to give us a boolean val of valid or not
  // const isValid = url.includes('github') // this is palceholder - delete after real query
  // yield put(validateSourceUrlSuccess(isValid))
  const bot = yield call(createBot, url)
  const isValid = !_.isEmpty(bot)
  yield put(validateSourceUrlSuccess(isValid))
  if (isValid) {
    yield put(createBotSuccess(bot))
  }
}

function* deleteSwimlane(action) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation deleteSwimlane($srn: ID!) {
          DeleteSwimlane(srn: $srn)
        }
      `,
      variables: { srn: action.payload.srn },
    })

    if (result.errors && result.errors.length > 0) {
      result.errors.map(err => {
        throw new Error(err.message)
      })
    }

    yield put(removeSwimlaneSuccess(action.payload.title))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error deleting swimlanes', e)
  }
}

function* runAllDataFetcher() {
  const permissions = yield select(selectPermissions)
  const permissionKeys = _.keys(permissions.get('data'))

  let pollsSet = Map({
    getSavedSonraiSeaches: call(getSavedSonraiSeaches, { poll: true }),
    fetchSavedSearches: call(fetchSavedSearches, { poll: true }),
  })

  permissionKeys.forEach(permission => {
    if (permission === 'edit.swimlanes' || permission === 'assign.bots') {
      pollsSet = pollsSet.set(
        'getSwimlanes',
        call(getSwimlanes, { poll: true })
      )
    }

    if (permission === 'view.data') {
      pollsSet = pollsSet.set('getAccounts', call(getAccounts, { poll: true }))
      pollsSet = pollsSet.set(
        'getSwimlanes',
        call(getSwimlanes, { poll: true })
      )
      pollsSet = pollsSet.set(
        'getAzureSubscriptions',
        call(getAzureSubscriptions, { poll: true })
      )
    }
  })

  const polls = pollsSet.toList().toJS()

  while (true) {
    yield all(polls)
    yield call(delay, POLL_FREQUENCY)
  }
}

function* getExemptedIdentities() {
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
        query getExemptedIdentities {
          BotsExemptedIdentities {
            items {
              resourceId
              cloud
            }
          }
        }
      `,
    })

    const ids = _.get(
      response,
      ['data', 'BotsExemptedIdentities', 'items'],
      null
    )

    if (response.errors || !ids) {
      const message = _.get(
        response,
        ['errors', 0, 'message'],
        'Error retrieving Exempted Identities'
      )
      yield put(
        setExemptedIdentitiesError({
          error: message,
        })
      )
    } else {
      yield put(setExemptedIdentities({ identities: ids }))
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* addExemptedIdentities(action) {
  try {
    setTimeout(() => {}, 2500)
    const client = getClient()
    const response = yield client.mutate({
      mutation: gql`
        mutation createExemptItem($input: [BotsExemptedIdentityCreator!]!) {
          CreateBotsExemptedIdentities(input: $input) {
            items {
              resourceId
              cloud
            }
          }
        }
      `,
      variables: {
        input: action.payload.resourceIds.map(id => ({
          resourceId: id,
          cloud: action.payload.cloudType,
        })),
      },
    })

    const ids = _.get(
      response,
      ['data', 'CreateBotsExemptedIdentities', 'items'],
      null
    )

    if (response.errors || !ids) {
      const message = _.get(
        response,
        ['errors', 0, 'message'],
        'Error adding Exempted Identities'
      )
      yield put(
        setExemptedIdentitiesError({
          error: message,
        })
      )
    } else {
      yield put(addExemptedIdentitiesSuccess({ identities: ids }))
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* deleteExemptedIdentities(action) {
  try {
    setTimeout(() => {}, 2500)
    const client = getClient()
    const response = yield client.mutate({
      mutation: gql`
        mutation deleteExemptedIdentity(
          $input: [BotsExemptedIdentityDeleter!]!
        ) {
          DeleteBotsExemptedIdentities(input: $input) {
            items {
              resourceId
            }
          }
        }
      `,
      variables: {
        input: action.payload.resourceIds.map(id => ({
          resourceId: id,
        })),
      },
    })

    const id = _.get(
      response,
      ['data', 'DeleteBotsExemptedIdentities', 'items', 0, 'resourceId'],
      null
    )

    if (response.errors || !id) {
      const message = _.get(
        response,
        ['errors', 0, 'message'],
        'Error deleting Exempted Identities'
      )
      yield put(
        setExemptedIdentitiesError({
          error: message,
        })
      )
    } else {
      yield put(deleteExemptedIdentitiesSuccess({ resourceId: id }))
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

export function* fetchObjectives() {
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
        query getObjectives {
          SonraiObjectives {
            items {
              srn
              resourceId
              name
              description
              createdBy
              definedControlFrameworks {
                controlFrameworkSrn
                controlFrameworkOrder
              }
              appliedControlFrameworks {
                items {
                  srn
                  swimlaneSRNs
                  contains {
                    items {
                      srn
                    }
                  }
                }
              }
            }
          }
        }
      `,
    })

    const objectives = _.get(
      response,
      ['data', 'SonraiObjectives', 'items'],
      null
    )

    if (response.errors || !objectives) {
      throw new Error(`Error fetching objectives: ' ${response.errors}`)
    } else {
      yield put(setObjectives(objectives))
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
    yield put(fetchObjectivesError(e.message))
  }
}

function* enableObjective({ payload }) {
  try {
    const client = getClient()
    const response = yield client.mutate({
      mutation: gql`
        mutation enableObjective($input: [SonraiObjectiveAssignmentCreator!]!) {
          CreateSonraiObjectiveAssignment(input: $input) {
            items {
              controlFrameworkSrn
              swimlaneSrn
            }
          }
        }
      `,
      variables: {
        input: [
          {
            objectiveSrn: payload.objectiveSrn,
            swimlaneSrn: payload.swimlaneSrn,
            enableEscalationSchemes: payload.enableEscalationSchemes,
          },
        ],
      },
    })

    if (response.errors) {
      throw new Error(response.errors)
    } else {
      yield put(
        enableObjectiveSuccess({
          objectiveSrn: payload.objectiveSrn,
          swimlaneSrn: payload.swimlaneSrn,
          enableEscalationSchemes: payload.enableEscalationSchemes,
        })
      )
      yield put(fetchObjectivesAction())
      yield put(fetchControlGroups())
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
    yield put(enableObjectiveError())
  }
}
function* enableAllObjectives({ payload }) {
  try {
    const objectives = yield select(selectObjectives)

    const client = getClient()
    const enableResults = yield all([
      ...objectives.toJS().map(obj =>
        client.mutate({
          mutation: gql`
            mutation enableObjective(
              $input: [SonraiObjectiveAssignmentCreator!]!
            ) {
              CreateSonraiObjectiveAssignment(input: $input) {
                items {
                  controlFrameworkSrn
                  swimlaneSrn
                }
              }
            }
          `,
          variables: {
            input: {
              objectiveSrn: obj.srn,
              swimlaneSrn: null,
              enableEscalationSchemes: payload.enableEscalationSchemes,
            },
          },
        })
      ),
    ])

    for (let index in enableResults) {
      if (enableResults[index].errors) {
        throw new Error(enableResults[index].errors)
      }
    }

    yield put(enableAllObjectivesSuccess())
    yield put(fetchObjectivesAction())
    yield put(fetchControlGroups())
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
    yield put(enableAllObjectivesError())
  }
}

function* enableAllObjectivesForSwimlane({ payload }) {
  try {
    const swimlaneSrn = payload.swimlaneSrn
    const objectives = yield select(selectObjectives)

    const client = getClient()
    const enableResults = yield all([
      ...objectives.toJS().map(obj =>
        client.mutate({
          mutation: gql`
            mutation enableObjective(
              $input: [SonraiObjectiveAssignmentCreator!]!
            ) {
              CreateSonraiObjectiveAssignment(input: $input) {
                items {
                  controlFrameworkSrn
                  swimlaneSrn
                }
              }
            }
          `,
          variables: {
            input: {
              objectiveSrn: obj.srn,
              swimlaneSrn: swimlaneSrn,
              enableEscalationSchemes: payload.enableEscalationSchemes,
            },
          },
        })
      ),
    ])

    for (let index in enableResults) {
      if (enableResults[index].errors) {
        throw new Error(enableResults[index].errors)
      }
    }

    yield put(enableAllObjectivesForSwimlaneSuccess())
    yield put(fetchObjectivesAction())
    yield put(fetchControlGroups())
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
    yield put(enableAllObjectivesForSwimlaneError())
  }
}

function* sonraiDataSaga() {
  yield all([
    takeLatest(RUN_INSPECTION_QUERY, runInspectionQuery),
    takeLatest(FETCH_SONRAI_CONFIG, getSonraiConfig),
    takeLatest(FETCH_SAVED_SEARCHES, fetchSavedSearches),
    takeLatest(FETCH_SAVED_SEARCHES_DETAILS, fetchSavedSearchesDetails),
    takeLatest(FETCH_SONRAI_SEARCHES, getSavedSonraiSeaches),
    takeLatest(FETCH_ACCOUNTS, getAccounts),
    takeLatest(FETCH_SUBSCRIPTIONS, getAzureSubscriptions),
    takeLatest(FETCH_TAGS, getTags),
    takeLatest(FETCH_DATA_CONTAINERS, getDataContainers),
    takeLatest(CHECK_IF_HAS_COLLECTORS, checkIfHasCollectors),
    takeLatest(GET_MONITORED_RESOURCES_COUNT, getMonitoredResourcesCount),
    takeLatest(FETCH_SWIMLANES, getSwimlanes),
    takeLatest(FETCH_SONRAI_USERS, getSonraiUsers),
    takeLatest(UPDATE_SONRAI_USER, updateSonraiUser),
    takeLatest(GET_ALL_ROLES, getAllRoles),
    takeLatest(UPDATE_USER_ROLES, updateRoles),
    takeLatest(GET_BOTS, getAllBots),
    takeLatest(VALIDATE_SOURCE_URL, validateSourceUrl),
    takeLatest(HANDLE_DELETE_BOTS, deleteBots),
    takeLatest(UPDATE_BOT_ASSIGNMENT, updateBotAssignments),
    takeLatest(REMOVE_SWIMLANE, deleteSwimlane),
    takeLatest(FETCH_GRAPHQL_SCHEMA, getGraphqlSchema),
    takeLatest(GET_EXEMPTED_IDENTITIES, getExemptedIdentities),
    takeLatest(ADD_EXEMPTED_IDENTITIES, addExemptedIdentities),
    takeLatest(DELETE_EXEMPTED_IDENTITIES, deleteExemptedIdentities),
    takeLatest(FETCH_OBJECTIVES, fetchObjectives),
    takeLatest(ENABLE_OBJECTIVE, enableObjective),
    takeLatest(ENABLE_ALL_OBJECTIVES, enableAllObjectives),
    takeLatest(
      ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE,
      enableAllObjectivesForSwimlane
    ),
  ])

  while (true) {
    yield take(START_UPDATE_DATA_POLL)
    yield race([call(runAllDataFetcher), take(STOP_UPDATE_DATA_POLL)])
  }
}

export default sonraiDataSaga
