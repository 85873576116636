import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles'

class CardBody extends React.PureComponent {
  render() {
    return (
      <div
        style={{ ...styles.body, ...this.props.style }}
        className="scrollable"
      >
        {this.props.children}
      </div>
    )
  }
}

CardBody.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
}

export default CardBody
