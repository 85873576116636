import React, { Component } from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import IHelp from 'containers/IHelp'
import TextLink from 'components/TextLink'

class SavedSearchDisplay extends Component {
  styles = {
    container: { display: 'flex', alignItems: 'center' },
    tooltipContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '0.5rem',
      minHeight: '50px',
      minWidth: '200px',
    },
    description: {
      color: this.props.theme.neutralDark,
      fontStyle: 'italic',
      padding: '0.25rem 0rem 0rem 0rem',
      fontSize: '0.9rem',
    },
    title: { fontWeight: '500px', fontSize: '0.95rem' },
  }
  renderIHelp = () => (
    <IHelp
      id="savedSearchDisplay"
      help
      customContent={() => (
        <div style={this.styles.tooltipContainer}>
          <div style={this.styles.title}>Description</div>
          <div style={this.styles.description}>
            {this.props.search.description}
          </div>
        </div>
      )}
      iconSize="20px"
      position="bottom"
    />
  )

  render() {
    if (!this.props.search) {
      return '-'
    }

    const { description, name } = this.props.search
    if (description) {
      if (this.props.position === 'right') {
        return (
          <div style={this.styles.container}>
            <TextLink to={this.props.to}>{name}</TextLink>
            <div style={{ marginLeft: '0.5rem' }}>{this.renderIHelp()} </div>
          </div>
        )
      } else {
        return (
          <div style={this.styles.container}>
            <div style={{ marginRight: '0.5rem' }}>{this.renderIHelp()} </div>
            <TextLink to={this.props.to}>{name}</TextLink>
          </div>
        )
      }
    }
    return (
      <div style={this.styles.container}>
        <TextLink to={this.props.to}>{name}</TextLink>
      </div>
    )
  }
}

SavedSearchDisplay.defaultProps = {
  position: 'left',
}

SavedSearchDisplay.propTypes = {
  theme: themeShape,
  search: PropTypes.object.isRequired,
  to: PropTypes.object,
}

export default themeable(SavedSearchDisplay)
