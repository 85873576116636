import { createSelector } from 'reselect'
import { Map } from 'immutable'

export const selectTicketListFilterPanelDomain = state =>
  state.get('ticketListFilterPanel') || Map()

export const selectTicketListFilterPanel = createSelector(
  selectTicketListFilterPanelDomain,
  state => state.get('ticketListFilters') || Map()
)
