import React from 'react'
import PropTypes from 'prop-types'
import { DropdownToggle } from 'reactstrap'

class InlineDropdownAnchor extends React.Component {
  styles = {
    label: {
      fontWeight: '500',
      color: '#555',
      display: 'inline',
      cursor: 'pointer',
    },
  }

  render() {
    return (
      <DropdownToggle
        className="inline-dropdown-control"
        tag="span"
        data-toggle="dropdown"
        caret
        style={this.styles.label}
      >
        {this.props.selectedOption ? this.props.selectedOption.label : ''}{' '}
      </DropdownToggle>
    )
  }
}

InlineDropdownAnchor.propTypes = {
  selectedOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  }),
}

export default InlineDropdownAnchor
