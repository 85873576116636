import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles'

class NodeViewDetailLabel extends React.PureComponent {
  render() {
    return <div style={styles.key}>{this.props.children}</div>
  }
}

NodeViewDetailLabel.propTypes = {
  children: PropTypes.node,
}

export default NodeViewDetailLabel
