import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from 'reactstrap'
import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'

import messages from './messages'

class AddDecryptionKeyModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      privateKey: '',
    }
  }

  onKeyValueChange = event => {
    this.setState({ privateKey: event.target.value })
  }

  onSave = () => {
    if (this.state.privateKey) {
      this.props.setPrivateKey({ key: this.state.privateKey })
      this.props.close()
    }
  }

  render() {
    return (
      <Modal size="lg" isOpen={this.props.open} style={{ width: '550px' }}>
        <ModalHeader>
          <DynamicFormattedMessage {...messages.addPrivateKeyModalTitle} />
        </ModalHeader>
        <ModalBody>
          <b>
            <DynamicFormattedMessage
              {...messages.addPrivateKeyBoldInstructionsPart1Bold}
            />
          </b>{' '}
          <DynamicFormattedMessage
            {...messages.addPrivateKeyInstructionsPart2NotBold}
          />{' '}
          <b>
            <DynamicFormattedMessage
              {...messages.addPrivateKeyInstructionsPart3Bold}
            />
          </b>{' '}
          <DynamicFormattedMessage
            {...messages.addPrivateKeyInstructionsPart4NotBold}
          />
          <div style={{ marginTop: '8px' }}>
            <Input
              type="textarea"
              onChange={this.onKeyValueChange}
              style={{
                fontFamily: 'monospace',
                fontSize: '12px',
                height: '400px',
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <span style={{ marginRight: '8px' }}>
            <Button color="primary" onClick={this.onSave}>
              Add
            </Button>
          </span>
          <span>
            <Button color="primary" outline onClick={this.props.close}>
              Cancel
            </Button>
          </span>
        </ModalFooter>
      </Modal>
    )
  }
}

AddDecryptionKeyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  setPrivateKey: PropTypes.func.isRequired,
}

export default AddDecryptionKeyModal
