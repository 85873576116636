import { defineMessages } from 'react-intl'

export default defineMessages({
  selectCard: {
    id: 'app.components.CombinedSearches.selectCard',
    defaultMessage: 'Select a search card...',
  },
  searchCardLabel: {
    id: 'app.components.CombinedSearches.searchCardLabel',
    defaultMessage: 'Search Card',
  },
  selectSearch: {
    id: 'app.components.CombinedSearches.selectSearch',
    defaultMessage: 'Select a search...',
  },
})
