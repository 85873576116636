export const SET_CHANGE_DETECTION_DATA =
  'app/ChangeDetectionManager/SET_CHANGE_DETECTION_DATA'

export const ADD_CHANGE_DETECTION_PROPERTIES =
  'app/ChangeDetectionManager/ADD_CHANGE_DETECTION_PROPERTY'

export const ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS =
  'app/ChangeDetectionManager/ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS'

export const REMOVE_CHANGE_DETECTION_PROPERTIES =
  'app/ChangeDetectionManager/REMOVE_CHANGE_DETECTION_PROPERTIES'

export const REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS =
  'app/ChangeDetectionManager/REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS'

export const UPDATE_CHANGE_DETECTION_PROPERTY =
  'app/ChangeDetectionManager/UPDATE_CHANGE_DETECTION_PROPERTY'

export const UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS =
  'app/ChangeDetectionManager/UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS'
