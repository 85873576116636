import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectActiontypeNodeViewCardLayoutDomain = state =>
  state.get('actiontypeNodeViewCardLayout', Map())

export const selectActiontypeContent = createSelector(
  selectActiontypeNodeViewCardLayoutDomain,
  state => state.get('actiontypeContent', Map())
)

export const selectIsLoading = createSelector(
  selectActiontypeNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
