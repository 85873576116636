/*
 *
 * SecurityCenter constants
 *
 */

export const SET_SELECTED_RESOURCE_ID =
  'app/SecurityCenter/SET_SELECTED_RESOURCE_ID'
export const SET_SELECTED_RESOURCE = 'app/SecurityCenter/SET_SELECTED_RESOURCE'
export const CLEAR_SELECTED_RESOURCE_ID =
  'app/SecurityCenter/CLEAR_SELECTED_RESOURCE_ID'
export const SET_FILTER = 'app/SecurityCenter/SET_FILTER'
export const SET_SELECTED_SWIMLANE_NAME =
  'app/SecurityCenter/SET_SELECTED_SWIMLANE_NAME'
export const DISABLE_RESOURCE_MONITORING =
  'app/SecurityCenter/DISABLE_RESOURCE_MONITORING'
export const ENABLE_RESOURCE_MONITORING =
  'app/SecurityCenter/ENABLE_RESOURCE_MONITORING'
export const FETCH_ROLLUP_DATA = 'app/SecurityCenter/FETCH_ROLLUP_DATA'
export const SET_CATEGORY_ROLLUP_DATA =
  'app/SecurityCenter/SET_CATEGORY_ROLLUP_DATA'
export const SET_TIMELINE_ROLLUP_DATA =
  'app/SecurityCenter/SET_TIMELINE_ROLLUP_DATA'
export const SET_RELATIVE_DATE_FILTER =
  'app/SecurityCenter/SET_RELATIVE_DATE_FILTER'
export const SET_CUSTOM_DATE_FILTER =
  'app/SecurityCenter/SET_CUSTOM_DATE_FILTER'
export const SET_VIS_FILTERS = 'app/SecurityCenter/SET_VIS_FILTERS'
export const SET_SELECTED_CATEGORY = 'app/SecurityCenter/SET_SELECTED_CATEGORY'
export const SET_SELECTED_SEVERITY = 'app/SecurityCenter/SET_SELECTED_SEVERITY'
export const FETCH_CATEGORY_ROLLUP_DATA =
  'app/SecurityCenter/FETCH_CATEGORY_ROLLUP_DATA'

export const BULK_DISABLE_MONITORING =
  'app/SecurityCenter/BULK_DISABLE_MONITORING'
export const BULK_DISABLE_MONITORING_SUCCESS =
  'app/SecurityCenter/BULK_DISABLE_MONITORING_SUCCESS'

export const VIEWS = {
  VIS: 'vis',
  RESOURCES: 'resources',
}

export const TOGGLE_SC_INFO_MODAL = 'app/SecurityCenter/TOGGLE_SC_INFO_MODAL'

export const SET_STATUS_FILTER = 'app/SecurityCenter/SET_STATUS_FILTER'
