import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'
import ImmutablePropTypes from 'react-immutable-proptypes'

import WidgetCardContent from './WidgetCardContent'

class WidgetCard extends React.Component {
  constructor(props) {
    super(props)
    this.uuid = uuid()
  }

  render() {
    const { children, ...props } = this.props
    return (
      <Fragment>
        <WidgetCardContent
          {...props}
          loading={this.props.loading || this.props.networkStatus === 1}
          uuid={this.uuid}
        >
          {children}
        </WidgetCardContent>
      </Fragment>
    )
  }
}

WidgetCard.defaultProps = {
  style: {},
  containerStyle: {},
  headerStyle: {},
  allowDelete: false,
  allowUpdate: false,
}

WidgetCard.propTypes = {
  // Passed through to sub component / i am nice developr ur welcome
  // eslint-disable-next-line react/no-unused-prop-types
  searchId: ImmutablePropTypes.contains({
    uiSearch: PropTypes.string,
    advancedSearch: PropTypes.array,
  }),
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  children: PropTypes.node,
  containerStyle: PropTypes.object,
  disableToolbar: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  filtered: PropTypes.bool,
  loading: PropTypes.bool,
  networkStatus: PropTypes.number,
  onClickSearch: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  refetch: PropTypes.func,
  static: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.node,
  toggleStatic: PropTypes.func,
}

export default WidgetCard
