/*
 * NotFoundPage Messages
 *
 * This contains all the text for the NotFoundPage component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  NotFound: {
    id: 'app.components.NotFoundPage.NotFound',
    defaultMessage: 'Oops! You missed the mark on that one',
  },
})
