import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'

import { getTypeFromSrn } from 'utils/graphDataUtils'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import reducer from './reducers'
import sagas from './sagas'

import { getDataStoreContent } from './actions'
import { selectDataStoreContent } from './selectors'

import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'

import messages from './messages'

const TYPES_WITH_ANATOMY = [
  'Aurora',
  'MariaDB',
  'MySQL',
  'MSSQL',
  'Oracle',
  'PostgreSQL',
  'Redshift',
  'SQLServer',
  'DynamoDB',
  'S3',
]

export class DataStoreNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)
    this.props.getDataStoreContent({
      srn: this.props.nodeId,
    })
  }

  renderTablesAndColumnsView = () => {
    const tableRows = this.props.dataStoreContent.getIn([
      'data',
      'contains',
      'items',
    ])

    return (
      <div
        style={{
          height: 'calc(100vh - 400px)',
          minHeight: '800px',
        }}
      >
        <DataTable
          autosize={false}
          data={tableRows.toJS()}
          onDoubleClickRow={row => {
            const srn = row.srn
            const type = getTypeFromSrn(srn)
            const nav = getNodeViewPushParams(srn, type)
            this.props.push(nav)
          }}
        />
      </div>
    )
  }

  renderDBAnatomy = () => {
    if (this.props.dataStoreContent.get('loading')) {
      return <div>loading ...</div>
    }

    if (this.props.dataStoreContent.get('error')) {
      return <div>error loading content...</div>
    }

    if (!this.props.dataStoreContent.get('data').isEmpty()) {
      return this.renderTablesAndColumnsView()
    }

    return null
  }

  getColumns = () => {
    return {
      xs: [
        {
          widthPercent: 100,
          items: ['contents'],
          heights: {
            graph: 3,
          },
        },
      ],
    }
  }

  getLayouts = () => {
    return {
      contents: {
        lg: { w: 8, h: 3 },
        md: { w: 7, h: 3 },
        sm: { w: 6, h: 3 },
        xs: { w: 4, h: 3 },
      },
    }
  }

  getGridContent = () => {
    return [
      <div key="contents">
        <BorderedCard>
          <SectionHeader>
            <DynamicFormattedMessage {...messages.contents} />
          </SectionHeader>
          {this.renderDBAnatomy()}
        </BorderedCard>
      </div>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          nodeData={this.props.nodeData}
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          gridContent={this.getGridContent()}
          layouts={this.getLayouts()}
          columns={this.getColumns()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
          renderDetailsTabs={TYPES_WITH_ANATOMY.includes(
            this.props.nodeData.type
          )}
        />
      </Fragment>
    )
  }
}

DataStoreNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  nodeData: PropTypes.object,
  nodeId: PropTypes.string.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),

  // props from redux store
  dataStoreContent: ImmutablePropTypes.map,

  // bound action creators
  push: PropTypes.func,
  getDataStoreContent: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  dataStoreContent: selectDataStoreContent,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push,
      getDataStoreContent,
    },
    dispatch
  )

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({
  key: 'dataStoreNodeViewCardLayout',
  reducer,
})

const withSaga = injectSaga({
  key: 'dataStoreNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withReducer,
  withSaga,
  withConnect
)(DataStoreNodeViewCardLayout)
