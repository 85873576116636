import { defineMessages } from 'react-intl'

export default defineMessages({
  error: {
    id: 'app.components.MissingDataNode.error',
    defaultMessage: 'The resource you are looking for no longer exists',
  },
  contact: {
    id: 'app.components.MissingDataNode.contact',
    defaultMessage: 'For more information, you can contact support at',
  },
})
