/*
 *
 * QuickSearch actions
 *
 */

import {
  FETCH_QUICK_SEARCH,
  SET_QUICK_SEARCH_RESULTS,
  QUICK_SEARCH_LOADING,
  CLEAR_QUICK_SEARCH_RESULTS,
  RESULT_LIMIT_EXCEEDED,
  SET_QUICK_SEARCH_ERROR,
} from './constants'
import { createAction } from 'redux-actions'

export const fetchQuickSearch = createAction(FETCH_QUICK_SEARCH)
export const setQuickSearchResults = createAction(SET_QUICK_SEARCH_RESULTS)
export const clearQuickSearchResults = createAction(CLEAR_QUICK_SEARCH_RESULTS)
export const quickSearchLoading = createAction(QUICK_SEARCH_LOADING)
export const resultLimitExceeded = createAction(RESULT_LIMIT_EXCEEDED)
export const setQuickSearchError = createAction(SET_QUICK_SEARCH_ERROR)
