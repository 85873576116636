export default {
  loginContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 250px 1fr',
    height: '100%',
    textAlign: 'center',
  },

  footerLink: {
    fontFamily: 'comfortaa',
    fontSize: '14px',
    color: '#FFFFFF',
    fontWeight: '100',
    float: 'left',
    marginBottom: '2em',
    marginLeft: '2em',
    marginTop: '2em',
  },
  copyright: {
    fontFamily: 'comfortaa',
    fontSize: '14px',
    color: '#FFFFFF',
    fontWeight: '100',
    float: 'right',
    marginBottom: '2em',
    marginLeft: '1em',
    marginRight: '2em',
    marginTop: '2em',
  },
}
