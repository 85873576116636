import gql from 'graphql-tag'
import { fromJS } from 'immutable'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { getClient } from 'apolloClient'
import { useAuth0 } from 'react-auth0-wrapper'

const SONRAI_USERS_QUERY = gql`
  query currentUser {
    SonraiCurrentUsers {
      items {
        profile
        srn
        roleAssignments {
          items {
            scope
            role {
              items {
                expandedPermissions
              }
            }
          }
        }
      }
    }
  }
`

/**
 * This function takes the results of the query SONRAI_USERS_QUERY and
 * transforms the results into a structure like this:
 *
 * {
 *   [permissionp[0]]: {
 *     scopes: {
 *       [scope[0].value]: {
 *          ...scope[0]
 *       },
 *       [scope[1].value]: {
 *          ...[scope[1]]
 *       }
 *     }
 *   },
 *   [permission[1]]: {
 *     ...etc.
 *   },
 *  ...etc.
 * }
 */
async function fetchUserPermissions() {
  const client = await getClient()
  const result = await client.query({ query: SONRAI_USERS_QUERY })

  // try to get the user from the response, throw if not returned
  const currentUserPath = ['data', 'SonraiCurrentUsers', 'items', 0]
  const currentUser = _.get(result, currentUserPath, null)
  if (!currentUser) {
    throw new Error(`Result did not return ${currentUserPath.join('.')}`)
  }

  if (
    !currentUser.roleAssignments ||
    !_.isArray(currentUser.roleAssignments.items)
  ) {
    throw new Error(`current user roleAssignments is not an array`)
  }

  // this will hold the final results
  const allPermissions = {}

  currentUser.roleAssignments.items.forEach(ass => {
    const assPermissions = desturctureAssignment(ass)
    mergeRoleAssignments(allPermissions, assPermissions)
  })

  return allPermissions
}

/**
 * Take the role assignment and turn it into the structure for the same
 * structure as for fetchUserPermissions
 */
function desturctureAssignment(roleAssignment) {
  const scope = roleAssignment.scope
  const permissions = roleAssignment.role.items.reduce((permList, role) => {
    const { expandedPermissions } = role
    for (const perm of expandedPermissions) {
      if (!permList.includes(perm)) {
        permList.push(perm)
      }
    }
    return permList
  }, [])

  // turn into object
  return permissions.reduce((accumulator, perm) => {
    return {
      ...accumulator,
      [perm]: {
        [scope]: {},
      },
    }
  }, {})
}

/**
 * merge the permission iformation for two role assignments
 */
function mergeRoleAssignments(to, from) {
  for (let perm of Object.keys(from)) {
    if (!to[perm]) {
      to[perm] = { scopes: {} }
    }
    const scope = from[perm]
    if (!to[perm].scopes[scope]) {
      Object.assign(to[perm].scopes, scope)
    }
  }
}

let initialState = fromJS({
  data: null,
  error: null,
  loading: true,
})

// placeholder for results
const results = { permissions: null }

export function useSonraiUserPermissions() {
  const { initialTokenLoad } = useAuth0()
  const [state, setState] = useState(initialState)

  useEffect(() => {
    async function fetchData() {
      await initialTokenLoad
      try {
        // only set this promise one time, so we don't fetch many times
        if (!results.permissions) {
          results.permissions = fetchUserPermissions()
        }

        const permissionsData = await results.permissions
        const newState = state
          .set('loading', false)
          .set('data', permissionsData)
        setState(newState)

        // make the initial state for the hook the state with the loaded data
        // so it doesn't get fetched again when people use the hook
        initialState = newState
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('There was an error fetching the user permissions', e)
        setState(state.set('loading', false).set('error', true))
      }
    }

    // only fetch the data if we have not already fetched it
    if (state.get('loading')) {
      fetchData()
    }
    return () => {}
  }, [])
  return state
}

export default useSonraiUserPermissions
