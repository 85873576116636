/**
 *
 * Explorer
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Radium from 'radium'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
import qs from 'query-string'

import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import SplitterLayout from 'components/SplitterLayout'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Transition } from 'react-spring/renderprops.cjs'
import BorderedCard from 'components/BorderedCard'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import injectSaga from 'utils/injectSaga'
import injectReducer from 'utils/injectReducer'
import TableWidget from 'components/TableWidget'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { exists } from 'utils/sonraiUtils'
import GraphVis from 'components/GraphVis'
import LoadingAnim from 'components/LoadingAnim'
import { LAYERS } from 'utils/styleUtils'
import { selectQueryTypes } from 'containers/SonraiData/selectors'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import { getAccountFromSrn } from 'utils/sonraiUtils'
import ContextMenu from 'components/ContextMenu'
import {
  selectSourceNodes,
  selectIsLoading,
  selectNodes,
  selectSelectedNode,
  selectHiddenNodes,
  selectExpandedNodeTypes,
  selectIsLoadingNodes,
} from './selectors'
import reducer from './reducer'
import sagas from './sagas'
import {
  getSourceNode,
  getMultiSourceNodes,
  selectNode,
  clearGraph,
  setRelations,
  setSourceNodes,
  removeNode,
  handleExpand,
  handleHide,
  handleShow,
  loadNodes,
  handleUpdate,
} from './actions'
import NodePreview from './NodePreview'
import NodeSearch from './NodeSearch'
import { EXPLORER_VIEW_TYPES } from 'appConstants'
import { fromJS, List } from 'immutable'
import FilterPanel from './FilterPanel'

export class Explorer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      viewType: EXPLORER_VIEW_TYPES.EXPLORER,
      terms: [],
      filterActive: true,
      isDragging: false,
      resizing: false,
    }

    this.colourStyles = {
      multiValue: styles => ({
        ...styles,
        backgroundColor: props.theme.primary,
      }),
      multiValueLabel: styles => ({
        ...styles,
        color: props.theme.light,
        fontWeight: '400',
        fontSize: '1rem',
      }),
      multiValueRemove: styles => ({
        ...styles,
        color: props.theme.light,
        opacity: 1,
        ':hover': {
          backgroundColor: props.theme.red,
          color: props.theme.light,
        },
      }),
    }

    this.styles = {
      container: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
      },
      iconContainer: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
      },
      showTableIcon: {
        position: 'absolute',
        top: '0',
        right: '0',
        backgroundColor: props.theme.light,
        padding: '1em',
        borderRadius: '50%',
        zIndex: LAYERS.TOOLBAR,
      },
      filterPanel: {
        width: '25%',
        margin: '1em 0em 1em 1em',
      },
      searchContainer: {
        position: 'absolute',
        top: '1rem',
        left: '1rem',
        zIndex: LAYERS.TOOLBAR,
        right: '1em',
      },
      iconCenteredContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      graphContainer: {
        height: '100%',
        position: 'relative',
      },
      nodePreviewContainer: {
        position: 'absolute',
        backgroundColor: props.theme.light,
        top: '5rem',
        right: '1.5rem',
        padding: '1rem',
        width: '30%',
        boxShadow: `rgba(176, 176, 176, 0.2) 0px 0px 5px 2.5px`,
        maxHeight: '80%',
        height: '500px',
      },
      tableContainer: { width: '100%', height: '100%', padding: '0.5rem' },
      loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      },
      split: {
        width: '100%',
        height: '100%',
      },
      nodePreviewMessage: {
        top: '5rem',
        boxShadow: `rgba(176, 176, 176, 0.5) 0px 0px 5px 2.5px`,
        borderRadius: '0.2rem',
        right: '2rem',
        color: '#888888',
        position: 'absolute',
        backgroundColor: props.theme.light,
        padding: '1rem',
      },
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.props.loadNodes(this.props.data)
    } else if (this.props.history) {
      const incNodes = _.get(this.props, ['location', 'state', 'nodes'], null)
      const params = qs.parse(this.props.history.location.search)
      if (incNodes) {
        this.props.getMultiSourceNodes({ ids: incNodes.map(n => n.item.srn) })
      } else if (params) {
        this.props.getSourceNode(params)
      }
    }

    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    this.props.clearGraph()
    window.removeEventListener('resize', this.updateDimensions)

    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer)
    }
  }

  updateDimensions = () => {
    this.setState({
      resizing: true,
    })

    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.setState({
        resizing: false,
      })
    }, 500)
  }

  getNodes = () => {
    const nodes = this.props.nodes
    const hiddenNodes = this.props.hiddenNodes
    const nodesToDisplay = nodes
      .toList()
      .toJS()
      .map(node => ({
        item: node,
      }))

    if (!hiddenNodes.isEmpty()) {
      return _.uniqBy(nodesToDisplay, node => node.item.srn).filter(node => {
        if (!hiddenNodes.map(x => x.get('srn')).includes(node.item.srn)) {
          return node
        }
      })
    }

    return _.uniqBy(nodesToDisplay, node => node.item.srn)
  }

  getEdges = () => {
    const allRelations = []
    this.props.nodes
      .filter(node => node.has('relations'))
      .forEach(node => {
        node.get('relations', List()).forEach(relationType => {
          allRelations.push(...relationType.toJS())
        })
      })
    allRelations.push(..._.get(this.props, ['location', 'state', 'edges'], []))
    return _.uniqBy(allRelations, value => {
      const itemId = value.item.srn
      const attachedToId = exists(value.parent)
        ? value.parent
        : this.props.sourceNodes.toJS()[0]

      return value.relation.direction === 'OUT'
        ? [itemId, attachedToId].join()
        : [attachedToId, itemId].join()
    })
  }

  getLabel = relation => {
    let name = relation.item.srn.split('/')
    let label = `${relation.item.__typename}: ${relation.item.friendlyName ||
      relation.item.name ||
      name[name.length - 1]} `
    return label
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      const isNewVal = newValue[newValue.length - 1]
      const value = { label: isNewVal.label, value: isNewVal.value }
      const existingSearches =
        JSON.parse(localStorage.getItem('searches')) || []
      const list = [...existingSearches, value]
      if (list.length > 99) {
        list.shift()
      }
      localStorage.setItem('searches', JSON.stringify(list))
    }
    this.setState({ terms: newValue })
  }

  handleSelect = event => {
    this.setState({
      context: undefined,
      coords: undefined,
    })
    if (event && event.nodes) {
      if (event.nodes.length === 0) {
        this.setState({
          x: undefined,
          y: undefined,
        })
        this.props.selectNode({ node: null })
        return
      }

      const nodeId = event.nodes[0]
      const nodeConfig = this.props.nodes.get(nodeId)

      if (!nodeConfig) {
        return
      }
      this.props.selectNode({ node: nodeId })
    }
  }

  setTerms = value => {
    this.setState({
      terms: value,
    })
  }

  toggleViewType = () => {
    this.setState(prevState => ({
      viewType:
        prevState.viewType == EXPLORER_VIEW_TYPES.EXPLORER
          ? EXPLORER_VIEW_TYPES.TABLE
          : EXPLORER_VIEW_TYPES.EXPLORER,
    }))
  }

  onDoubleClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  handleRemoveNode = () => {
    this.setState({
      coords: undefined,
    })
    const { nodes, selectedNode } = this.props
    const node = fromJS(nodes.get(selectedNode))
    if (node) {
      this.props.removeNode(node)
    }
  }

  renderContextMenu = () => {
    return (
      <ContextMenu
        coords={[this.state.coords.y, this.state.coords.x]}
        actions={[
          {
            text: 'Remove Node',
            action: this.handleRemoveNode,
          },
        ]}
      />
    )
  }

  handleContext = (context, coords) => {
    this.props.selectNode({ node: context })
    this.setState({ coords })
  }

  handleDoubleClick = () => {
    if (this.state.viewType == EXPLORER_VIEW_TYPES.TABLE) {
      this.setState({ viewType: EXPLORER_VIEW_TYPES.EXPLORER })
    }
  }

  isMatch = label => {
    const { terms } = this.state
    if (!_.isEmpty(terms)) {
      const searches = terms.map(term => term.label.toLowerCase())

      const values = searches.map(search =>
        label.toLowerCase().includes(search)
      )
      if (!values.includes(true)) {
        return true
      }
    }
  }

  getTableWidgetData = () => {
    const nodes = !_.isEmpty(this.state.terms)
      ? _.uniqBy(Object.values(this.props.nodes.toJS()), val => val.srn).filter(
          node => {
            const name = node.srn.split('/')
            const label = `${node.__typename}: ${node.friendlyName ||
              node.name ||
              name[name.length - 1]} `
            return !this.isMatch(label)
          }
        )
      : _.uniqBy(Object.values(this.props.nodes.toJS()), val => val.srn)
    return nodes.map(x => ({
      srn: x.srn,
      label: x.label,
      type: x.type || x.__typename,
      name: x.name,
      account: getAccountFromSrn(x.srn),
    }))
  }

  handleExpand = selected => {
    const arrOfExpandableNodeTypes = selected.map(typename => ({
      listOfSrnsToExpand: this.props.nodes
        .map(node =>
          fromJS({
            srn: node.get('srn'),
            __typename: node.get('__typename'),
          })
        )
        .toList()
        .filter(node => node.get('__typename') === typename)
        .map(node => node.get('srn'))
        .toJS(),
      typename,
    }))

    if (!_.isEmpty(arrOfExpandableNodeTypes)) {
      this.props.handleExpand(arrOfExpandableNodeTypes)
    }
  }

  handleHide = typename => {
    const listOfSrnsToHide = this.props.nodes
      .map(node =>
        fromJS({
          srn: node.get('srn'),
          __typename: node.get('__typename'),
        })
      )
      .toList()
      .filter(node => node.get('__typename') == typename)
      .map(node => node.get('srn'))
    if (!listOfSrnsToHide.isEmpty()) {
      this.props.handleHide({ listOfSrnsToHide, typename })
    }
  }

  handleShow = typename => {
    this.props.handleShow(typename)
  }

  getEntityTypes = () =>
    fromJS(
      _.uniq(
        this.props.nodes
          .map(node => node.get('__typename'))
          .toList()
          .toJS()
      )
    )

  renderGraph = () => {
    if (
      this.state.isDragging ||
      this.state.resizing ||
      this.props.isLoadingNodes
    ) {
      return (
        <div style={this.styles.loading}>
          <SquareLoadingAnimation />
        </div>
      )
    }

    return (
      <div style={{ padding: '1em', height: '100%' }}>
        <BorderedCard style={this.styles.graphContainer}>
          <GraphVis
            onContext={this.handleContext}
            selectedNode={this.props.selectedNode}
            key={this.state.viewType}
            sourceNodes={this.props.sourceNodes}
            terms={this.state.terms}
            edges={this.getEdges()}
            nodes={this.getNodes()}
            onClick={this.handleSelect}
            onDoubleClick={this.handleDoubleClick}
          />

          {this.state.coords &&
            exists(this.props.selectedNode) &&
            !this.props.sourceNodes.includes(this.props.selectedNode) &&
            this.renderContextMenu()}

          {exists(this.props.selectedNode) &&
            this.state.viewType !== EXPLORER_VIEW_TYPES.TABLE && (
              <div style={this.styles.nodePreviewContainer}>
                <NodePreview
                  sourceNodes={this.props.sourceNodes}
                  key={this.props.selectedNode}
                  colourStyles={this.colourStyles}
                />
              </div>
            )}

          <Transition
            from={{ opacity: '0' }}
            enter={{ opacity: '1' }}
            leave={{ opacity: '0' }}
            items={
              exists(this.props.selectedNode) &&
              this.state.viewType === EXPLORER_VIEW_TYPES.TABLE
            }
          >
            {item =>
              item &&
              (styles => (
                <div
                  style={{
                    ...this.styles.nodePreviewMessage,
                    ...styles,
                  }}
                >
                  Node Previews are disabled in split view.
                </div>
              ))
            }
          </Transition>

          <NodeSearch
            toggleFilterPanel={this.toggleFilterPanel}
            filterActive={this.state.filterActive}
            style={this.styles.searchContainer}
            setTerms={this.setTerms}
          />

          {this.state.viewType === EXPLORER_VIEW_TYPES.EXPLORER && (
            <div style={this.styles.showTableIcon}>
              <BorderlessButton
                title="Show items as a table"
                onClick={this.toggleViewType}
                color="primary"
              >
                <Icon fa name="table" />
                &nbsp; Show Table
              </BorderlessButton>
            </div>
          )}
        </BorderedCard>
      </div>
    )
  }

  toggleFilterPanel = () => {
    this.setState(oldState => ({ filterActive: !oldState.filterActive }))
    this.updateDimensions()
  }

  renderTable = () => {
    return (
      <div style={this.styles.split}>
        <BorderedCard style={this.styles.split}>
          <TableWidget
            selectNode={this.props.selectNode}
            selectedNode={this.props.selectedNode}
            disableToolbar
            onClickNodeView={this.onDoubleClickNodeView}
            data={this.getTableWidgetData()}
            title="All Nodes"
          />

          <div style={this.styles.iconContainer}>
            <BorderlessButton
              style={{ margin: '0.75rem 1rem' }}
              onClick={this.toggleViewType}
            >
              <Icon fa name="times" />
            </BorderlessButton>
          </div>
        </BorderedCard>
      </div>
    )
  }

  render() {
    if (this.props.isLoading || this.props.queryTypes.isEmpty()) {
      return <LoadingAnim />
    }

    const entityTypes = this.getEntityTypes() || List()
    return (
      <div style={this.styles.container}>
        {this.state.filterActive && (
          <FilterPanel
            sourceNodes={this.props.sourceNodes}
            nodes={this.props.nodes}
            handleShow={this.handleShow}
            expandedNodeTypes={this.props.expandedNodeTypes}
            hiddenNodes={this.props.hiddenNodes}
            handleHide={this.handleHide}
            handleExpand={this.handleExpand}
            data={entityTypes}
            toggleFilterPanel={this.toggleFilterPanel}
            style={this.styles.filterPanel}
            handleUpdate={this.props.handleUpdate}
          />
        )}

        <div style={{ width: this.state.filterActive ? '75%' : '100%' }}>
          <SplitterLayout
            onDragStart={() => this.setState({ isDragging: true })}
            onDragEnd={() => this.setState({ isDragging: false })}
          >
            {this.renderGraph()}

            {this.state.viewType === EXPLORER_VIEW_TYPES.TABLE &&
              this.renderTable()}
          </SplitterLayout>
        </div>
      </div>
    )
  }
}

Explorer.propTypes = {
  theme: themeShape,
  isLoading: PropTypes.bool,
  sourceNodes: PropTypes.object,
  nodes: PropTypes.object,
  getSourceNode: PropTypes.func,
  getMultiSourceNodes: PropTypes.func,
  history: PropTypes.object,
  clearGraph: PropTypes.func,
  selectNode: PropTypes.func,
  selectedNode: PropTypes.string,
  data: PropTypes.object,
  push: PropTypes.func,
  loadNodes: PropTypes.func,
  queryTypes: ImmutablePropTypes.iterable,
  removeNode: PropTypes.func,
  handleExpand: PropTypes.func,
  expandedNodeTypes: ImmutablePropTypes.iterable,
  hiddenNodes: ImmutablePropTypes.iterable,
  handleHide: PropTypes.func,
  handleShow: PropTypes.func,
  isLoadingNodes: PropTypes.bool,
  handleUpdate: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  sourceNodes: selectSourceNodes,
  isLoading: selectIsLoading,
  nodes: selectNodes,
  selectedNode: selectSelectedNode,
  queryTypes: selectQueryTypes,
  hiddenNodes: selectHiddenNodes,
  expandedNodeTypes: selectExpandedNodeTypes,
  isLoadingNodes: selectIsLoadingNodes,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setRelations,
      setSourceNodes,
      getSourceNode,
      getMultiSourceNodes,
      selectNode,
      clearGraph,
      removeNode,
      handleExpand,
      handleHide,
      handleShow,
      push,
      loadNodes,
      handleUpdate,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)
const withReducer = injectReducer({ key: 'explorer', reducer })
const withSaga = injectSaga({ key: 'explorer', saga: sagas })

export default compose(
  withReducer,
  withConnect,
  withSaga,
  themeable,
  Radium
)(Explorer)
