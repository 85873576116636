/**
 *
 * Select
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import _ from 'lodash'
import { isImmutable } from 'immutable'

class SelectBar extends React.Component {
  getValue = originalValue => {
    //The Select component from react-select requires `value` to be:
    // - one of the objects from `options` (an object like {label, value})
    // - A list of objects from `options`, if multiselect
    // - null or undefined
    //In order to make this component a bit easier to use, we will automatically convert a plain `value` prop to the {label, value} format.
    let rawValue = originalValue
    if (isImmutable(originalValue)) {
      rawValue = originalValue.toJS()
    }

    if (this.props.isMulti && Array.isArray(rawValue)) {
      return rawValue.map(this.getValue)
    }

    if (rawValue && typeof rawValue === 'object') {
      //`null` is considered an object so we need to also make sure rawValue is truthy

      //Check if we've just been passed in an object with {label, value}:
      if (rawValue.label !== undefined && rawValue.value !== undefined) {
        //If we have a `value` of {label, value} we can just pass it straight through to react-select!
        return rawValue
      }
    }

    //To create option groups, you can nest options inside other options. So we should flatten these first
    //(Note: assumes only one level of nesting)
    const orginialOptions = isImmutable(this.props.options)
      ? this.props.options.toJS()
      : this.props.options
    const options = []
    orginialOptions.forEach(option => {
      if (!option.options && option.value !== undefined) {
        options.push(option)
      } else if (option.options) {
        const { options: childOpts, ...currentOpt } = option
        options.push(currentOpt)
        options.push.apply(options, childOpts)
      }
    })

    //At this point, we've probably only been given the `value` part of some option.
    //So search though the options and find one with a matching value
    //If none, fall back to using the `rawValue`, which could be null or undefined
    return options.find(opt => _.isEqual(opt.value, rawValue)) || rawValue
  }

  render() {
    const options = isImmutable(this.props.options)
      ? this.props.options.toJS()
      : this.props.options
    const finalValue = this.getValue(this.props.value)
    const orderedOptions = this.props.autoSort
      ? _.sortBy(options, opt =>
          opt.label && typeof opt.label === 'string'
            ? opt.label.toLowerCase()
            : opt
        )
      : options
    return (
      <Select
        menuPlacement="auto"
        isDisabled={this.props.disabled}
        {...this.props}
        options={orderedOptions}
        value={finalValue}
      />
    )
  }
}

SelectBar.defaultProps = {
  options: [],
  autoSort: true,
}

SelectBar.propTypes = {
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.node,
    })
  ),
  autoSort: PropTypes.bool,
  onChange: PropTypes.func,
  //Value can either be a plain value (number, string, etc), or it can be an object like {label: "One", value: 1}.
  //If isMulti is true, it can also be an array of these
  value: PropTypes.any,
}

export default SelectBar
