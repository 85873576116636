import PropTypes from 'prop-types'

export const PopoverAnchor = ({ children }) => {
  return children
}

PopoverAnchor.propTypes = {
  children: PropTypes.node,
}

export default PopoverAnchor
