import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CopyToClipboard from 'components/CopyToClipboard'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Hoverable from 'components/Hoverable'
import TextLink from 'components/TextLink'
import NodeViewMiniPopout from 'containers/NodeViewMiniPopout'

import messages from './messages'

const styles = {
  clipboardButton: {
    float: 'right',
    position: 'relative',
    top: '-8px',
  },
  encrypted: {
    color: 'black',
  },
  unencrypted: {
    color: 'red',
  },
}

export default class NodeViewDetailBlockStorage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      miniNodeViewOpen: false,
      miniNodeViewNodeId: '',
    }
  }

  closeMiniNodeView = () => {
    this.setState({
      miniNodeViewOpen: false,
      miniNodeViewNodeId: '',
    })
  }

  openMiniNodeView = nodeId => {
    this.setState({
      miniNodeViewOpen: true,
      miniNodeViewNodeId: nodeId,
    })
  }

  render() {
    return (
      <div>
        {this.props.value.length > 0 ? (
          this.props.value.map((blockStorage, index) => (
            <Hoverable
              key={`blockStorage-${index}`}
              renderContent={({ hovered }) => (
                <div>
                  <TextLink
                    color="primary"
                    onClick={() => this.openMiniNodeView(blockStorage.srn)}
                  >
                    {blockStorage.name}
                  </TextLink>{' '}
                  (
                  {blockStorage.encryptionEnabled ? (
                    <span style={styles.encrypted}>
                      <DynamicFormattedMessage
                        {...messages.blockStorageEncrypted}
                      />
                    </span>
                  ) : (
                    <span style={styles.unencrypted}>
                      <DynamicFormattedMessage
                        {...messages.blockStorageUnencrypted}
                      />
                    </span>
                  )}
                  {hovered ? (
                    <div style={styles.clipboardButton}>
                      <CopyToClipboard value={blockStorage.name} />
                    </div>
                  ) : (
                    <div style={styles.clipboardButton} />
                  )}
                  )
                </div>
              )}
            />
          ))
        ) : (
          <div>-</div>
        )}
        <NodeViewMiniPopout
          open={this.state.miniNodeViewOpen}
          nodeId={this.state.miniNodeViewNodeId}
          handleClose={this.closeMiniNodeView}
        />
      </div>
    )
  }
}

NodeViewDetailBlockStorage.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
}
