/*
 * PublicKeyNodeViewCardLayout Messages
 *
 * This contains all the text for the PublicKeyNodeViewCardLayout component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.title',
    defaultMessage: 'Data Details',
  },
  activeLabel: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.activeLabel',
    defaultMessage: 'Active',
  },
  typeLabel: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.typeLabel',
    defaultMessage: 'Type',
  },
  accountLabel: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  group: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.group',
    defaultMessage: 'Group',
  },
  lastModifiedLabel: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.lastModifiedLabel',
    defaultMessage: 'Last Modified',
  },
  createdLabel: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.createdLabel',
    defaultMessage: 'Created',
  },
  srnLabel: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.srnLabel',
    defaultMessage: 'srn',
  },
  service: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.service',
    defaultMessage: 'Service',
  },
  descriptionLabel: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.description',
    defaultMessage: 'Description',
  },
  sizeLabel: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.size',
    defaultMessage: 'Size',
  },
  bytes: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.bytes',
    defaultMessage: 'Bytes',
  },
  publicRead: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.publicRead',
    defaultMessage: 'Public Read',
  },
  publicWrite: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.publicWrite',
    defaultMessage: 'Public Write',
  },
  webisteEnabled: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.webisteEnabled',
    defaultMessage: 'Website Enabled',
  },
  auditEnabled: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.auditEnabled',
    defaultMessage: 'Audit Enabled',
  },
  containerLabel: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.containerLabel',
    defaultMessage: 'Container',
  },
  none: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.none',
    defaultMessage: 'None',
  },
  dataItem: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.dataItem',
    defaultMessage: 'This Data item is not part of a container',
  },
  record: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.record',
    defaultMessage: 'Record',
  },
  performed: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.performed',
    defaultMessage: 'Performed (Last 7 Days)',
  },
  info: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.info',
    defaultMessage: 'Info',
  },
  members: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.performed',
    defaultMessage: 'Members',
  },
  groupsPolicies: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.groupsPolicies',
    defaultMessage: 'Groups & Policies',
  },
  label: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.label',
    defaultMessage: 'Label',
  },
  PublicKey: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.PublicKey',
    defaultMessage: 'PublicKey',
  },
  public: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.public',
    defaultMessage: 'Public',
  },
  usedIn: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.usedIn',
    defaultMessage: 'Used In:',
  },
  Version: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.version',
    defaultMessage: 'Version',
  },
  noPolicies: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.noPolicies',
    defaultMessage: 'This policy has no versions',
  },
  Fingerprint: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.Fingerprint',
    defaultMessage: 'Fingerprint',
  },
  topTitleText: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.topTitleText',
    defaultMessage: 'Used to Access Compute: ',
  },
  lastLaunched: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.lastLaunched',
    defaultMessage: 'Last Launched: ',
  },
  architecture: {
    id: 'app.containers.PublicKeyNodeViewCardLayout.architecture',
    defaultMessage: 'Architecture: ',
  },
})
