/*
 *
 * ControlFrameworkData reducer
 *
 */
import { fromJS, Map, List } from 'immutable'

import {
  SET_CONTROL_GROUPS,
  FETCH_CONTROL_GROUPS,
  SET_POLICIES,
  CREATE_POLICY,
  CREATE_POLICY_SUCCESS,
  UPDATE_CONTROL_GROUP,
  UPDATE_CONTROL_GROUP_SUCCESS,
  CREATE_CONTROL_GROUP_SUCCESS,
  DELETE_CONTROL_GROUP,
  DELETE_CONTROL_GROUP_SUCCESS,
  DELETE_POLICY_SUCCESS,
  UPDATE_POLICY_SUCCESS,
  ADD_POLICY_SUCCESS,
  REMOVE_POLICY_SUCCESS,
  SET_POLICY_ERROR,
  DELETE_POLICY,
  REMOVE_POLICY,
  UPDATE_POLICY,
  TOGGLE_ENABLED_ON_SWIMLANES,
  TOGGLE_ENABLED_ON_SWIMLANES_SUCCESS,
  SET_FRAMEWORK_ENABLED,
  SET_FRAMEWORK_ENABLED_SUCCESS,
  CLONE_FRAMEWORK,
  CLONE_FRAMEWORK_SUCCESS,
  FETCH_POLICIES,
  TOGGLE_SWIMLANE_ON_CFS,
  TOGGLE_SWIMLANE_ON_CFS_SUCCESS,
  TOGGLE_SWIMLANE_ON_CFS_ERROR,
} from './constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  controlGroups: {},
  loadedControlGroups: false,
  fetchingControlGroups: false,
  policies: {},
  loadedControlPolicies: false,
  policyError: null,
  fetchingPolicies: false,
  updatingPolicies: Map(),
  updatingCFs: Map(),
  creatingPolicy: false,
  deletingCFs: Map(),
  togglingSwimlaneOnCfs: Map(),
  togglingSwimlaneError: Map(),
})

const controlFrameworkDataReducer = handleActions(
  {
    [ADD_POLICY_SUCCESS]: (state, { payload }) =>
      state.setIn(['controlGroups', payload.srn], fromJS(payload)),
    [DELETE_CONTROL_GROUP]: (state, { payload }) =>
      state.setIn(['deletingCFs', payload], true),
    [DELETE_CONTROL_GROUP_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['controlGroups', payload])
        .deleteIn(['deletingCFs', payload])
        .deleteIn(['updatingCFs', payload]),
    [SET_POLICY_ERROR]: (state, { payload }) =>
      state
        .set('policyError', payload.msg)
        .deleteIn(['updatingPolicies'], payload.srn),

    [CLONE_FRAMEWORK]: (state, { payload }) =>
      state.setIn(['updatingCFs', payload], true),
    [CLONE_FRAMEWORK_SUCCESS]: (state, { payload }) =>
      state.deleteIn(['updatingCFs', payload]),
    [SET_FRAMEWORK_ENABLED]: (state, { payload }) =>
      state.setIn(['updatingCFs', payload.srn], true),
    [SET_FRAMEWORK_ENABLED_SUCCESS]: (state, { payload }) =>
      state.deleteIn(['updatingCFs', payload]),
    [DELETE_POLICY]: (state, { payload }) =>
      state.setIn(['updatingPolicies', payload], true),
    [UPDATE_POLICY]: (state, { payload }) =>
      state.setIn(['updatingPolicies', payload.srn], true),
    [DELETE_POLICY_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['policies', payload])
        .deleteIn(['updatingPolicies', payload]),
    [REMOVE_POLICY]: (state, { payload }) =>
      state.setIn(['updatingPolicies', payload.policyId], true),
    [REMOVE_POLICY_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['controlGroups', payload.cf.srn], fromJS(payload.cf))
        .deleteIn(['updatingPolicies', payload.policyId]),
    [FETCH_CONTROL_GROUPS]: state => state.set('fetchingControlGroups', true),
    [SET_CONTROL_GROUPS]: (state, { payload }) =>
      state
        .set(
          'controlGroups',
          Map(
            fromJS(payload).map(controlGroup => [
              controlGroup.get('srn'),
              controlGroup,
            ])
          )
        )
        .set('fetchingControlGroups', false)
        .set('loadedControlGroups', true),
    [FETCH_POLICIES]: state => state.set('fetchingPolicies', true),
    [SET_POLICIES]: (state, { payload }) => {
      return state
        .set(
          'policies',
          Map(fromJS(payload).map(policy => [policy.get('srn'), policy]))
        )
        .set('loadedControlPolicies', true)
        .set('fetchingPolicies', false)
    },
    [CREATE_POLICY]: state => state.set('creatingPolicy', true),
    [CREATE_POLICY_SUCCESS]: (state, { payload }) => {
      const policy = fromJS(payload)
      if (
        policy.getIn(
          ['containedByControlFramework', 'items', '0', 'srn'],
          false
        )
      ) {
        return state
          .set('creatingPolicy', false)
          .setIn(['policies', payload.srn], policy)
          .updateIn(
            [
              'controlGroups',
              fromJS(payload).getIn([
                'containedByControlFramework',
                'items',
                '0',
                'srn',
              ]),
              'contains',
              'items',
            ],
            (attachedPolicies = List()) =>
              attachedPolicies.push(fromJS({ srn: payload.srn }))
          )
      } else {
        return state
          .setIn(['policies', payload.srn], policy)
          .set('creatingPolicy', false)
      }
    },
    [UPDATE_CONTROL_GROUP]: (state, { payload }) =>
      state.setIn(['updatingCFs', payload.srn], true),
    [UPDATE_CONTROL_GROUP_SUCCESS]: (state, { payload }) => {
      return state
        .mergeIn(['controlGroups', payload.srn], fromJS(payload))
        .deleteIn(['updatingCFs', payload.srn])
    },
    [UPDATE_POLICY_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['policies', payload.srn], fromJS(payload))
        .deleteIn(['updatingPolicies', payload.srn]),
    [CREATE_CONTROL_GROUP_SUCCESS]: (state, { payload }) => {
      return state.setIn(['controlGroups', payload.srn], fromJS(payload))
    },
    [TOGGLE_ENABLED_ON_SWIMLANES]: (state, { payload }) =>
      state.setIn(['updatingCFs', payload.srn], true),
    [TOGGLE_ENABLED_ON_SWIMLANES_SUCCESS]: (state, { payload }) => {
      return state
        .deleteIn(['updatingCFs', payload.srn])
        .setIn(['controlGroups', payload.srn], fromJS(payload.cf))
    },
    [TOGGLE_SWIMLANE_ON_CFS]: (state, { payload }) =>
      state.setIn(['togglingSwimlaneOnCfs', payload.srn], true),
    [TOGGLE_SWIMLANE_ON_CFS_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['togglingSwimlaneOnCfs', payload.srn])
        .deleteIn(['togglingSwimlaneError', payload.srn]),
    [TOGGLE_SWIMLANE_ON_CFS_ERROR]: (state, { payload }) =>
      state
        .setIn(['togglingSwimlaneError', payload.srn], payload.error)
        .deleteIn(['togglingSwimlaneOnCfs', payload.srn]),
  },
  initialState
)

export default controlFrameworkDataReducer
