import React, { Component, Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import { Dropdown, DropdownToggle } from 'reactstrap'
import styled from 'styled-components'
import CreateUpdateTicketModal from 'containers/CreateUpdateTicketModal'
import permissionChecker from 'containers/PermissionChecker'
import themeable from 'containers/ThemeManager/Themeable'
import TicketAutoRemediateWizard from 'containers/TicketAutoRemediateWizard'
import BorderlessButton from 'components/BorderlessButton'
import Button from 'components/Button'
import DropdownMenu from 'components/StyledReactstrapDropdownMenu'
import Icon from 'components/Icon'
import { hadResourceDeleted } from 'utils/sonraiUtils'
import SnoozeTicketModal from './SnoozeTicketModal'
import ReassignToUserModal from './ReassignToUserModal'

const TICKET_ACTIONS = {
  EDIT: {
    permissionName: 'edit.tickets',
    displayOnlyForTypes: ['Custom'],
    hideForStatuses: [],
    label: 'Edit Ticket',
  },
  SNOOZE: {
    permissionName: 'snooze.tickets',
    hideForStatuses: ['SNOOZED'],
    label: 'Snooze...',
  },
  ACCEPT_RISK: {
    permissionName: 'acceptrisk.tickets',
    hideForStatuses: ['RISK_ACCEPTED'],
    label: 'Accept Risk',
  },
  REOPEN: {
    permissionName: 'transition.tickets',
    hideForStatuses: ['NEW'],
    label: 'Reopen Ticket',
  },
  RESOLVE: {
    permissionName: 'transition.tickets',
    hideForStatuses: ['CLOSED'],
    label: 'Mark as Resolved',
  },
  REASSIGN: {
    permissionName: 'edit.tickets',
    hideForStatuses: [],
    label: 'Reassign to User...',
  },
  REMEDIATE: {
    permissionName: 'execute.bots',
    hideForStatuses: ['CLOSED', 'RISK_ACCEPTED', 'SNOOZED'],
    label: 'Auto Remediate...',
  },
}

const LoadingIconContainer = styled.span`
  padding-right: 2px;
  color: transparent;
  &.loading-true {
    color: gray;
  }
`

export class TicketDetailsHeaderActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      action: this.defaultTicketAction(),
      autoRemediateWizardOpen: false,
      isOpen: false,
      snoozeTicketModalOpen: false,
      reassignToUserModalOpen: false,
    }
  }

  style = {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    activeButton: {
      margin: '0rem 0.5rem 0rem 0rem',
      padding: '0.35rem',
      width: '160px', // maybe this is ok?
    },
    link: {
      padding: '0.65rem',
    },
    dropdownToggle: {
      border: '1px solid lightgrey',
      fontSize: '0.9rem',
      padding: '0.5rem 0.5rem',
    },
  }

  componentDidUpdate() {
    if (
      (TICKET_ACTIONS[this.state.action].hideForStatuses ?? []).includes(
        this.props.ticket.status
      )
    ) {
      this.setState({ action: this.defaultTicketAction() })
    }
  }

  defaultTicketAction = () => {
    switch (this.props.ticket.status) {
      case 'SNOOZED':
      case 'RISK_ACCEPTED':
      case 'CLOSED':
        return 'REOPEN'
      case 'NEW':
      default:
        return 'RESOLVE'
    }
  }

  handleChangeAction = action => {
    this.handleSelectAction(action)
    this.setState({ isOpen: false })
  }

  getActions = () => {
    const actions = Object.keys(TICKET_ACTIONS)
      .filter(action => {
        return this.props.userHasPermission({
          permissionName: TICKET_ACTIONS[action].permissionName,
          swimlanes: this.props.ticket.swimlaneSRNs,
        })
      })
      .filter(action => {
        return TICKET_ACTIONS[action].displayOnlyForTypes
          ? TICKET_ACTIONS[action].displayOnlyForTypes.includes(
              this.props.ticket.ticketType
            )
          : true // default display for all types
      })
      .filter(action => {
        return !(TICKET_ACTIONS[action].hideForStatuses ?? []).includes(
          this.props.ticket.status
        )
      })
      .map((action, index) => (
        <BorderlessButton
          onClick={() => this.handleChangeAction(action)}
          key={index}
          style={this.style.link}
          disabled={
            action === this.state.action ||
            (action === 'REMEDIATE' && hadResourceDeleted(this.props.ticket))
          }
        >
          {TICKET_ACTIONS[action].label}
        </BorderlessButton>
      ))
    return actions
  }

  toggleDropdown = () => {
    this.setState(oldState => ({
      isOpen: !oldState.isOpen,
    }))
  }

  handleSelectAction = action => {
    // handle all the logic of firing the corresponding action
    switch (action) {
      case 'RESOLVE':
        this.props.closeTicket(this.props.ticket)
        break
      case 'ACCEPT_RISK':
        this.props.acceptRiskTicket(this.props.ticket)
        break
      case 'SNOOZE':
        this.setState({ snoozeTicketModalOpen: true })
        break
      case 'REOPEN':
        this.props.reopenTicket(this.props.ticket)
        break
      case 'REASSIGN':
        this.setState({ reassignToUserModalOpen: true })
        break
      case 'REMEDIATE':
        this.setState({ autoRemediateWizardOpen: true })
        break
      case 'EDIT':
        this.setState({ updateTicketModalOpen: true })
        break
      default:
        // eslint-disable-next-line no-console
        console.error(`Unkonown action `, action)
    }
  }

  render() {
    const actions = this.getActions()
    if (actions.length === 0) {
      // user has permission to to no sactions :(
      return null
    }

    return (
      <Fragment>
        <div style={this.style.container}>
          <LoadingIconContainer
            className={`loading-${this.props.actionStatus.get('loading')}`}
          >
            <Icon fa name="sync" spin />
          </LoadingIconContainer>
          <Button
            color="primary"
            style={this.style.activeButton}
            disabled={this.props.actionStatus.get('loading')}
            onClick={() => this.handleSelectAction(this.state.action)}
          >
            {TICKET_ACTIONS[this.state.action].label}
          </Button>
          <Dropdown isOpen={this.state.isOpen} toggle={this.toggleDropdown}>
            <DropdownToggle style={this.style.dropdownToggle}>
              <Icon fa name="caret-down" />
            </DropdownToggle>
            <DropdownMenu>{this.getActions()}</DropdownMenu>
          </Dropdown>
        </div>
        <SnoozeTicketModal
          close={() => this.setState({ snoozeTicketModalOpen: false })}
          isOpen={this.state.snoozeTicketModalOpen}
          ticket={this.props.ticket}
          snoozeTicket={this.props.snoozeTicket}
        />
        <ReassignToUserModal
          users={this.props.users}
          close={() => this.setState({ reassignToUserModalOpen: false })}
          isOpen={this.state.reassignToUserModalOpen}
          ticket={this.props.ticket}
          reassignToUser={this.props.reassignToUser}
          reassignToUserStatus={this.props.reassignToUserStatus}
          swimlanes={this.props.swimlanes}
        />
        <TicketAutoRemediateWizard
          close={() => this.setState({ autoRemediateWizardOpen: false })}
          isOpen={this.state.autoRemediateWizardOpen}
          ticket={this.props.ticket}
        />
        <CreateUpdateTicketModal
          isOpen={this.state.updateTicketModalOpen}
          toggle={() => this.setState({ updateTicketModalOpen: false })}
          ticket={this.props.ticket}
          resource={this.props.ticket.resource}
          update
        />
      </Fragment>
    )
  }
}

TicketDetailsHeaderActions.propTypes = {
  acceptRiskTicket: PropTypes.func.isRequired,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  actionStatus: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }).isRequired,
  closeTicket: PropTypes.func.isRequired,
  reopenTicket: PropTypes.func.isRequired,
  snoozeTicket: PropTypes.func.isRequired,
  reassignToUser: PropTypes.func.isRequired,
  reassignToUserStatus: ImmutablePropTypes.map,
  ticket: PropTypes.shape({
    resource: PropTypes.object,
    srn: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    ticketType: PropTypes.string.isRequired,
    swimlaneSRNs: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  userHasPermission: PropTypes.func,
  users: ImmutablePropTypes.iterable.isRequired,
}

export default themeable(permissionChecker(TicketDetailsHeaderActions))
