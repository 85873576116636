/*
 * GlobalNavBar Messages
 *
 * This contains all the text for the GlobalNavBar component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  solutionCenter: {
    id: 'app.containers.GlobalNavBar.solutionCenter',
    defaultMessage: 'Solution Center',
  },
  graphExplorer: {
    id: 'app.containers.GlobalNavBar.graphExplorer',
    defaultMessage: 'Search',
  },
  search: {
    id: 'app.containers.GlobalNavBar.search',
    defaultMessage: 'Search',
  },
  reports: {
    id: 'app.containers.GlobalNavBar.reports',
    defaultMessage: 'Reports',
  },
  explorer: {
    id: 'app.containers.GlobalNavBar.explorer',
    defaultMessage: 'Explorer',
  },
  sc: {
    id: 'app.containers.GlobalNavBar.sc',
    defaultMessage: 'Security Center',
  },
  monitoredResources: {
    id: 'app.containers.GlobalNavBar.monitoredResources',
    defaultMessage: 'Monitored Resources',
  },
  bots: {
    id: 'app.containers.GlobalNavBar.bots',
    defaultMessage: 'Manage Bots',
  },
  oc: {
    id: 'app.containers.GlobalNavBar.oc',
    defaultMessage: 'Ops Center',
  },
  alerts: {
    id: 'app.containers.GlobalNavBar.alerts',
    defaultMessage: 'Alerts',
  },
  config: {
    id: 'app.containers.GlobalNavBar.config',
    defaultMessage: 'Config',
  },
  logout: {
    id: 'app.containers.GlobalNavBar.logout',
    defaultMessage: 'Log Out',
  },
  controlCenter: {
    id: 'app.containers.GlobalNavBar.controlCenter',
    defaultMessage: 'Manage Control Frameworks',
  },
  swimlanes: {
    id: 'app.containers.GlobalNavBar.swimlanes',
    defaultMessage: 'Manage Swimlanes',
  },
  userManagement: {
    id: 'app.containers.GlobalNavBar.userManagement',
    defaultMessage: 'Manage Users',
  },
  roleManagement: {
    id: 'app.containers.GlobalNavBar.roleManagement',
    defaultMessage: 'Manage Roles',
  },
  collectors: {
    id: 'app.containers.GlobalNavBar.collectors',
    defaultMessage: 'Configure Collectors',
  },
  customDataClassifiers: {
    id: 'app.containers.GlobalNavBar.customDataClassifiers',
    defaultMessage: 'Manage Custom Data Classifiers',
  },
  escalation: {
    id: 'app.containers.GlobalNavBar.escalations',
    defaultMessage: 'Configure Escalation Paths',
  },
  platformSettings: {
    id: 'app.containers.GlobalNavBar.platformSettings',
    defaultMessage: 'Platform Settings',
  },
  tickets: {
    id: 'app.containers.GlobalNavBar.tickets',
    defaultMessage: 'Tickets',
  },
  swimlaneCheckup: {
    id: 'app.containers.GlobalNavBar.swimlaneCheckup',
    defaultMessage: 'Swimlane Checkup',
  },
  objectiveManager: {
    id: 'app.containers.GlobalNavBar.objectiveManager',
    defaultMessage: 'Manage Objectives',
  },
})
