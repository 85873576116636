import React from 'react'
import PropTypes from 'prop-types'

export class Hoverable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      base: {
        cursor: this.props.disabled ? 'default' : 'pointer',
        transition: 'all 0.1s ease-out',
      },
    }

    this.state = {
      hovered: false,
    }
  }

  startHover = () => {
    this.setState({
      hovered: true,
    })
  }

  endHover = () => {
    this.setState({
      hovered: false,
    })
  }

  render() {
    const { children, style, hoverStyle, renderContent, ...pprops } = this.props

    const finalStyle = {
      ...this.styles.base,
      ...style,
      ...(this.state.hovered ? hoverStyle : {}),
    }

    return (
      <div
        onMouseEnter={this.startHover}
        onMouseLeave={this.endHover}
        style={finalStyle}
        {...pprops}
      >
        {children}
        {renderContent &&
          renderContent({
            hovered: this.state.hovered,
            endHover: this.endHover,
          })}
      </div>
    )
  }
}

Hoverable.defaultProps = {
  hoverStyle: {},
  style: {},
}

Hoverable.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hoverStyle: PropTypes.object,
  style: PropTypes.object,
  renderContent: PropTypes.func,
}

export default Hoverable
