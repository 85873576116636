import React from 'react'
import PropTypes from 'prop-types'

export class InlineEdit extends React.Component {
  constructor(props) {
    super(props)

    this.node = React.createRef()
  }

  styles = {
    editingContainer: {
      position: 'relative',
      height: '42px',
      display: 'block',
    },
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick = e => {
    if (!this.node.current.contains(e.target)) {
      this.props.onSubmit()
    }
  }

  render() {
    return (
      <div ref={this.node} style={this.styles.editingContainer}>
        <div>{this.props.children}</div>
      </div>
    )
  }
}

InlineEdit.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
}

export default InlineEdit
