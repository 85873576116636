import React from 'react'
import PropTypes from 'prop-types'
import MarkdownDisplay from 'components/MarkdownDisplay'

const ExpandRenderer = ({ data, colName }) => {
  return (
    <div style={{ padding: '10px', whiteSpace: 'normal' }}>
      <MarkdownDisplay content={data[colName]} />
    </div>
  )
}

ExpandRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  colName: PropTypes.string.isRequired,
}

export default ExpandRenderer
