import React from 'react'
import { compose } from 'redux'

import { getDisplayName } from 'utils/hocHelpers'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import userProfileDataSaga from './sagas'
import userProfileDataReducer from './reducer'

const userProfileData = WrappedComponent => {
  return class extends React.Component {
    displayName = `UserProfileData(${getDisplayName(WrappedComponent)})`

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const withSaga = injectSaga({
  key: 'userProfileData',
  saga: userProfileDataSaga,
})
const withReducer = injectReducer({
  key: 'userProfileData',
  reducer: userProfileDataReducer,
})

export default compose(
  withSaga,
  withReducer,
  userProfileData
)
