/**
 *
 * PlatformSettingsData
 *
 */

import React from 'react'
import { compose } from 'redux'

import { getDisplayName } from 'utils/hocHelpers'
import platformSettingsReducers from './reducers'
import platformSettingsDataSagas from './sagas'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'

const platformSettingsData = WrappedComponent => {
  return class extends React.Component {
    displayName = `platformSettingsData(${getDisplayName(WrappedComponent)})`
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const withReducer = injectReducer({
  key: 'platformSettingsData',
  reducer: platformSettingsReducers,
})
const withSaga = injectSaga({
  key: 'platformSettingsData',
  saga: platformSettingsDataSagas,
})

export default compose(withReducer, withSaga, platformSettingsData)
