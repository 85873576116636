import { defineMessages } from 'react-intl'

export default defineMessages({
  blockStorageEncrypted: {
    id: 'app.container.NodeView.blockStorageEncrypted',
    defaultMessage: 'Encrypted',
  },
  blockStorageUnencrypted: {
    id: 'app.container.NodeView.blockStorageUnencrypted',
    defaultMessage: 'Unencrypted',
  },
  classificationsNone: {
    id: 'app.container.NodeView.classificationsNone',
    defaultMessage: 'This resource has no classifications.',
  },
  classificationFound: {
    id: 'app.container.NodeView.classificationFound',
    defaultMessage: 'Data was found with the following classifications:',
  },
  classificationsNoneFound: {
    id: 'app.container.NodeView.classificationsNoneFound',
    defaultMessage: 'No data data was found with classifiers',
  },
  classificationsConfigJob: {
    id: 'app.container.NodeView.classificationsNone',
    defaultMessage: 'Click here to configure it.',
  },
})
