/*
 *
 * Access Activity reducer
 *
 */

import { fromJS } from 'immutable'
import moment from 'moment'
import _ from 'lodash'
import {
  SET_CRITICAL_RESOURCE_MONITOR_DATE,
  SET_PROPERTY_WIDGET_DATA,
  GET_PROPERTY_WIDGET_DATA,
  GET_DID_ACCESS_DATA,
  SET_DID_ACCESS_DATA,
  GET_CAN_ACCESS_DATA,
  SET_CAN_ACCESS_DATA,
  GET_ACCESSED_USING_DATA,
  SET_ACCESSED_USING_DATA,
  GET_ACTIVITY_WIDGET_DATA,
  SET_ACTIVITY_WIDGET_DATA,
  GET_SELECTED_ROW_ACTIONS,
  SET_SELECTED_ROW_ACTIONS,
  TOGGLE_ACTIONS_MODAL,
  GET_CAN_ACCESS_PATH,
  SET_CAN_ACCESS_PATH,
  GET_EP_PATHS,
  ERR_EP_PATHS,
  SET_LAST_CAN_ACCESS_JOB_TIMESTAMP,
} from './constants'
import { handleActions } from 'redux-actions'
import { exists } from 'utils/sonraiUtils'

export const initialState = fromJS({
  activityWidget: {
    data: null,
    isLoading: false,
  },
  accessedUsing: {
    data: null,
    isLoading: false,
  },
  canAccess: {
    data: null,
    isLoading: false,
  },
  didAccess: {
    data: null,
    isLoading: false,
  },
  propertyWidget: {
    data: null,
    isLoading: false,
  },
  lastCanAccessJobTimestamp: null,
  selectedRowActions: null,
  selectedRowActionsLoading: false,
  actionsModal: false,
  canAccessPaths: {},
  canAccessPathLoading: true,
  canAccessPathsError: false,
}).set('date', {
  fromDate: moment().subtract(1, 'days').startOf('day'),
  toDate: moment().endOf('day'),
})

const accessActivityReducer = handleActions(
  {
    [SET_CRITICAL_RESOURCE_MONITOR_DATE]: (state, { payload }) => {
      return state.set('date', payload)
    },
    [GET_PROPERTY_WIDGET_DATA]: state =>
      state.setIn(['propertyWidget', 'isLoading'], true),
    [SET_PROPERTY_WIDGET_DATA]: (state, { payload }) => {
      return state
        .setIn(['propertyWidget', 'data'], fromJS(payload))
        .setIn(['propertyWidget', 'isLoading'], false)
    },
    [GET_DID_ACCESS_DATA]: state =>
      state.setIn(['didAccess', 'isLoading'], true),
    [SET_DID_ACCESS_DATA]: (state, { payload }) => {
      return state
        .setIn(['didAccess', 'data'], fromJS(payload))
        .setIn(['didAccess', 'isLoading'], false)
    },
    [GET_CAN_ACCESS_DATA]: state =>
      state.setIn(['canAccess', 'isLoading'], true),
    [SET_CAN_ACCESS_DATA]: (state, { payload }) => {
      return state
        .setIn(['canAccess', 'data'], fromJS(payload))
        .setIn(['canAccess', 'isLoading'], false)
    },
    [GET_ACCESSED_USING_DATA]: state =>
      state.setIn(['accessedUsing', 'isLoading'], true),
    [SET_ACCESSED_USING_DATA]: (state, { payload }) => {
      return state
        .setIn(['accessedUsing', 'data'], fromJS(payload))
        .setIn(['accessedUsing', 'isLoading'], false)
    },
    [GET_ACTIVITY_WIDGET_DATA]: state =>
      state.setIn(['activityWidget', 'isLoading'], true),
    [SET_ACTIVITY_WIDGET_DATA]: (state, { payload }) => {
      return state
        .setIn(['activityWidget', 'data'], fromJS(payload))
        .setIn(['activityWidget', 'isLoading'], false)
    },
    [TOGGLE_ACTIONS_MODAL]: state =>
      state.set('actionsModal', !state.get('actionsModal')),
    [GET_SELECTED_ROW_ACTIONS]: state =>
      state.set('actionsModal', true).set('selectedRowActionsLoading', true),
    [SET_SELECTED_ROW_ACTIONS]: (state, { payload }) => {
      return state
        .set('selectedRowActions', fromJS(payload))
        .set('selectedRowActionsLoading', false)
    },
    [GET_CAN_ACCESS_PATH]: state =>
      state.set('canAccessPathLoading', true).set('canAccessPathError', false),
    [GET_EP_PATHS]: state =>
      state.set('canAccessPathLoading', true).set('canAccessPathError', false),
    [ERR_EP_PATHS]: state =>
      state.set('canAccessPathLoading', false).set('canAccessPathError', true),
    [SET_CAN_ACCESS_PATH]: (state, { payload }) => {
      return state
        .set('canAccessPathLoading', false)
        .set('canAccessPathError', false)
        .setIn(
          [
            'canAccessPaths',
            payload.srn,
            payload.actionClassification,
            payload.fromDate,
            payload.conditions,
          ],
          fromJS(payload.paths)
        )
    },
    [SET_LAST_CAN_ACCESS_JOB_TIMESTAMP]: (state, { payload }) => {
      return state.set('lastCanAccessJobTimestamp', payload)
    },
  },
  initialState
)

export default accessActivityReducer
