/**
 *
 * SquareLoadingAnimation
 *
 */

import React from 'react'

class SquareLoadingAnimation extends React.PureComponent {
  render() {
    return (
      // Color is defined by the css classes in global-styles.css
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube" />
        <div className="sk-cube2 sk-cube" />
        <div className="sk-cube4 sk-cube" />
        <div className="sk-cube3 sk-cube" />
      </div>
    )
  }
}

SquareLoadingAnimation.propTypes = {}

export default SquareLoadingAnimation
