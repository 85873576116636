import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import { CLOUD_TYPES } from 'appConstants'

const CloudBadge = props => {
  if (props.type === CLOUD_TYPES.AWS) {
    return <Icon fa name="amazon" color="#ff9900" style={props.style} />
  }

  if (props.type === CLOUD_TYPES.AZURE) {
    return <Icon fa name="windows" color="#4188CF" style={props.style} />
  }

  if (props.type === CLOUD_TYPES.SONRAI) {
    return <Icon png type="sonrailogo" style={props.style} />
  }

  if (props.type === CLOUD_TYPES.GCP) {
    return <Icon png type="gcplogo" style={props.style} />
  }

  if (props.type === CLOUD_TYPES.GSUITE) {
    return <Icon png type="gcplogo" style={props.style} />
  }

  if (props.type === CLOUD_TYPES.HASHICORP) {
    return <Icon png type="hashicorplogo" style={props.style} />
  }

  if (props.type === CLOUD_TYPES.KUBERNETES) {
    return <Icon png type="kuberneteslogo" style={props.style} />
  }

  return null
}

CloudBadge.propTypes = {
  style: PropTypes.object,
  type: PropTypes.oneOf([
    CLOUD_TYPES.AWS,
    CLOUD_TYPES.AZURE,
    CLOUD_TYPES.GCP,
    CLOUD_TYPES.SONRAI,
    CLOUD_TYPES.HASHICORP,
    CLOUD_TYPES.KUBERNETES,
    CLOUD_TYPES.GSUITE,
    '',
  ]),
}

export default CloudBadge
