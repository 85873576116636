import React from 'react'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import Icon from 'components/Icon'
import PropTypes from 'prop-types'

export class SearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.styles = {
      iconWrapper: {
        marginRight: 0,
      },
      icon: {
        backgroundColor: '#fff',
      },
    }
  }

  render() {
    const { quickFilter, style, ...rest } = this.props

    return (
      <div style={style}>
        <InputGroup>
          <InputGroupAddon addonType="prepend" style={this.styles.iconWrapper}>
            <InputGroupText style={this.styles.icon}>
              <Icon name={quickFilter ? 'filter' : 'search'} fa size="1x" />
            </InputGroupText>
          </InputGroupAddon>
          <Input {...rest} />
        </InputGroup>
      </div>
    )
  }
}

SearchInput.propTypes = {
  style: PropTypes.object,
  quickFilter: PropTypes.bool,
}

export default SearchInput
