import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropdownMenu } from 'reactstrap'
import _ from 'lodash'

/**
 * as of reactstrap 8, if we pass the style property to DropdownMenu it will
 * break the application, so we can pass it using modifiers, but its cubmersome
 * to type out the whole modifiers object, so we can use this instead
 */
export class StyledReactstrapDropdownMenu extends Component {
  render() {
    const props = _.omit(this.props, ['style', 'modifiers'])

    let modifiers = this.props.modifiers || {}
    props.modifiers = modifiers

    if (this.props.style) {
      modifiers.setStyle = {
        enabled: true,
        order: 900,
        fn: data => ({
          ...data,
          styles: {
            ...data.styles,
            ...this.props.style,
          },
        }),
      }
    }
    return <DropdownMenu {...props} />
  }
}

StyledReactstrapDropdownMenu.propTypes = {
  style: PropTypes.object,
  modifiers: PropTypes.object,
}

export default StyledReactstrapDropdownMenu
