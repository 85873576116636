/**
 *
 * GlobalHeaderBar
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { Switch, Route } from 'react-router-dom'
import { push } from 'connected-react-router'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'

import messages from 'containers/AppLayout/messages'
import HeaderSearchBar from 'components/HeaderSearchBar'
import UserMenu from 'containers/UserMenu'
import { FormattedMessage } from 'react-intl'
import qs from 'query-string'

export class GlobalHeaderBar extends React.PureComponent {
  styles = {
    base: {
      background: '#fff',
      boxShadow: '0 0 4px 0 rgba(0,0,0,0.50)',
      display: 'grid',
      gridTemplateAreas: '"title search user"',
      gridTemplateColumns: 'auto 1fr 60px',
      textAlign: 'center',
      height: '100%',
    },
    title: {
      fontSize: '20px',
      color: '#777777',
      fontWeight: '400',
      fontFamily: 'Roboto',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '16px',
      gridArea: 'title',
    },
    search: {
      gridArea: 'search',
      height: '100%',
    },
    userIconContainer: {
      gridArea: 'user',
      paddingTop: '10px',
      paddingLeft: '5px',
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
    }
  }

  setSearchValue = val => this.setState({ searchValue: val })

  doQuickSearch = query => {
    if (query !== '') {
      this.props.push({
        pathname: '/App/QuickSearch',
        search: qs.stringify({
          query,
        }),
      })
      this.setState({ searchValue: '' })
    }
  }

  render() {
    return (
      <div id="ghb-Container" className="slide-in-top" style={this.styles.base}>
        <div id="ghb-title" style={this.styles.title}>
          <Switch>
            <Route
              path={`${this.props.match.path}/SolutionCenter/Node`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(messages.nodeView)}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.nodeView} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/SolutionCenter`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.solutionCenter
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.solutionCenter} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/Start`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(messages.setup)}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.setup} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/GraphExplorer`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.graphExplorer
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.graphExplorer} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/SearchManager*`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(messages.search)}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.search} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/Search*`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(messages.search)}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.search} />
                </Fragment>
              )}
            />

            <Route
              path={`${this.props.match.path}/WidgetResultExplorer*`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.widgetResults
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.widgetResults} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/QuickSearch`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.quickSearch
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.quickSearch} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/Reports`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(messages.reports)}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.reports} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/Config`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(messages.config)}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.config} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/ControlCenter*`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.controlCenter
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.controlCenter} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/Explorer`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(messages.explorer)}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.explorer} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/Alert*`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(messages.alert)}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.alert} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/Deploy`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(messages.deploy)}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.deploy} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/HelpCenter`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.helpCenter
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.helpCenter} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/SecurityCenter`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.securityCenter
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.securityCenter} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/Escalation`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.escalation
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.escalation} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/TicketDetails`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.ticketDetails
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.ticketDetails} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/Tickets`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(messages.tickets)}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.tickets} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/MonitoredResources`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.monitoredResources
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.monitoredResources} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/BotManagement`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.botManagement
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.botManagement} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/SwimlaneCheckupDetails*`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.swimlaneCheckupDetails
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.swimlaneCheckupDetails} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/SwimlaneCheckup`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.swimlaneCheckup
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.swimlaneCheckup} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/BotManagement/BotDetails*`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.botDetails
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.botDetails} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/RoleManagement`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.roleManagement
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.roleManagement} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/RoleManagement/RoleDetails*`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.roleDetails
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.roleDetails} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/UserManagement`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.userManagement
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.userManagement} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/UserManagement/User*`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.userDetails
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.userDetails} />
                </Fragment>
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/ObjectivesManager`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.objectivesManager
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.objectivesManager} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/SwimlaneManagement`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.swimlanes
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.swimlanes} />
                </Fragment>
              )}
            />
            <Route
              path={`${this.props.match.path}/PlatformSettings`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>
                      {`${this.props.intl.formatMessage(
                        messages.appTitle
                      )} - ${this.props.intl.formatMessage(
                        messages.platformSettings
                      )}`}
                    </title>
                  </Helmet>
                  <FormattedMessage {...messages.platformSettings} />
                </Fragment>
              )}
            />
            <Route render={() => <span />} />
          </Switch>
        </div>
        <div id="ghb-search" style={this.styles.search}>
          <HeaderSearchBar
            executeSearch={this.doQuickSearch}
            searchValue={this.state.searchValue}
            setSearchValue={this.setSearchValue}
          />
        </div>
        <div id="ghb-user-icon" style={this.styles.userIconContainer}>
          <UserMenu />
        </div>
      </div>
    )
  }
}

GlobalHeaderBar.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.object,
  push: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect, injectIntl)(GlobalHeaderBar)
