import React, { Component } from 'react'
import PropTypes from 'prop-types'
import fail from 'assets/sadcloud.png'
import { withRouter } from 'react-router'

import { SUPPORT_EMAIL } from 'appConstants'
import TextLink from 'components/TextLink'
export class AppLayoutErrorBoundry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorMessage: '',
      errorSource: '',
      componentStack: '',
      errorStack: '',
    }

    this.styles = {
      errorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '20vh',
        color: '#666666',
      },
      failimg: {
        marginBottom: '20px',
        width: '200px',
      },
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      errorMessage: error.message,
      errorStack: error.stack,
      componentStack: info.componentStack,
      errorSource: error.source ? error.source.body : '',
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.clearError()
    }
  }

  refresh = () => {
    location.reload()
  }

  clearError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
      errorSource: '',
      componentStack: '',
      errorStack: '',
    })
  }

  logError = () => {
    // eslint-disable-next-line no-console
    console.log({ json: JSON.stringify(this.state), ...this.state })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <div style={this.styles.errorWrapper}>
            <img id="graphqlerror" src={fail} style={this.styles.failimg} />
            <p style={{ textAlign: 'center' }}>
              An Error Occured -{' '}
              <TextLink color="primary" onClick={this.clearError}>
                Try again?
              </TextLink>
            </p>
            <p style={{ fontSize: '0.9em' }}>
              If the error persists, you can try{' '}
              <TextLink onClick={this.refresh} color="secondary">
                refreshing the page
              </TextLink>{' '}
              or{' '}
              <TextLink
                color="secondary"
                href={`mailto:${SUPPORT_EMAIL}`}
                target="_blank"
              >
                contact support
              </TextLink>
            </p>
            {localStorage.getItem('sonraiDebugMode') && (
              <div>
                <small>
                  <button onClick={this.logError}>click to log</button>
                </small>
                <p>
                  <em>{this.state.errorMessage}</em>
                </p>
                <small style={{ paddingTop: '3px' }}>
                  <pre>{this.state.errorSource}</pre>
                  <pre>{this.state.errorStack}</pre>
                  <pre>{this.state.componentStack}</pre>
                </small>
              </div>
            )}
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

AppLayoutErrorBoundry.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
}

export default withRouter(AppLayoutErrorBoundry)
