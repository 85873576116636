/**
 *
 * CombinedSearches
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import SelectBar from 'components/SelectBar'
import { injectIntl, intlShape } from 'react-intl'

import FormLabel from 'components/FormLabel'
import { exists } from 'utils/sonraiUtils'

import messages from './messages'

class CombinedSearches extends React.PureComponent {
  combineSearchSonraiSearches = (saved, sonrai) => {
    if (this.props.onlySonrai) {
      return sonrai
    } else if (this.props.onlySaved) {
      return saved.toList()
    } else {
      return saved.toList().concat(sonrai)
    }
  }

  setSonraiSearch = value => {
    if (!this.props.onlySaved && this.props.setSonraiSearch) {
      this.props.setSonraiSearch(value)
    }
  }

  setSavedSearch = value => {
    if (!this.props.onlySonrai && this.props.selectSavedSearch) {
      this.props.selectSavedSearch(value)

      if (this.props.selectSearchField) {
        this.props.selectSearchField(null)
      }
    }
  }

  handleSelect = value => {
    if (this.props.onSelect) {
      this.props.onSelect(value)
    }

    if (value === null) {
      if (this.props.selectedSearchId) {
        this.setSavedSearch(null)
      } else {
        this.setSonraiSearch(null)
      }
    } else {
      switch (value.type) {
        case 'Search':
          this.setSavedSearch(value)
          this.setSonraiSearch(null)
          break
        case 'Savedquery':
          this.setSonraiSearch(value)
          this.setSavedSearch(null)

          break
      }
    }
  }

  getOptions = () =>
    this.combineSearchSonraiSearches(
      this.props.savedSearches,
      this.props.savedSonraiSearches
    )
      .toJS()
      .map(search => ({
        value: search.sid ? search.sid : search.name,
        label: search.name,
        type: search.__typename,
        srn: search.srn,
      }))

  render() {
    return (
      <Fragment>
        <SelectBar
          isClearable
          name="widgetQuery"
          value={this.props.selectedSearchId || this.props.selectedSonraiValue}
          options={this.getOptions()}
          onChange={this.handleSelect}
          placeholder={this.props.intl.formatMessage(messages.selectSearch)}
          isDisabled={this.props.loading}
        />

        {this.props.selectSearchField && this.props.selectedSearchId && (
          <Fragment>
            <FormLabel
              for="searchCard"
              label={
                this.props.searchFieldLabel ||
                this.props.intl.formatMessage(messages.searchCardLabel)
              }
              required={
                this.props.searchFieldRequired &&
                exists(this.props.selectedSearchId)
              }
            />
            <SelectBar
              isClearable
              name="searchCard"
              value={this.props.selectedFieldId}
              options={this.props.searchCards.toJS().map(field => ({
                value: field.id,
                label: field.name,
              }))}
              onChange={this.props.selectSearchField}
              isDisabled={this.props.searchFieldDisabled || this.props.loading}
              placeholder={this.props.intl.formatMessage(messages.selectCard)}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

CombinedSearches.defaultProps = {
  onlySaved: false,
  onlySonrai: false,
  searchFieldRequired: false,
}

CombinedSearches.propTypes = {
  intl: intlShape,
  onlySaved: PropTypes.bool,
  onlySonrai: PropTypes.bool,
  onSelect: PropTypes.func,
  savedSearches: ImmutablePropTypes.iterable,
  savedSonraiSearches: ImmutablePropTypes.iterable,
  searchCards: ImmutablePropTypes.iterable,
  searchFieldDisabled: PropTypes.bool,
  searchFieldRequired: PropTypes.bool,
  loading: PropTypes.bool,
  searchFieldLabel: PropTypes.string,
  selectSavedSearch: PropTypes.func,
  selectSearchField: PropTypes.func,
  selectedFieldId: PropTypes.string,
  selectedSearchId: PropTypes.string,
  selectedSonraiValue: PropTypes.string,
  setSonraiSearch: PropTypes.func,
}

export default injectIntl(CombinedSearches)
