/**
 *
 * IdentityNodeViewCardLayout
 *
 */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { List } from 'immutable'
import { injectIntl } from 'react-intl'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import TableWidget from 'components/TableWidget'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'

import messages from './messages'
import reducer from './reducer'
import sagas from './sagas'
import { getIdentityContent } from './actions'
import { selectIdentityContent, selectIsLoading } from './selectors'

export class IdentityNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getIdentityContent(props.nodeData.srn)
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getMemberOf = () => {
    let rows = []
    this.props.identityContent
      .getIn(['isMemberOf', 'items'], List())
      .toJS()
      .forEach(group => {
        const { hasAttached } = group
        const groupActive = group.active
        const groupName = group.name
        hasAttached.items.forEach(policy => {
          const { active, name, srn } = policy
          rows.push({
            groupActive,
            groupName,
            policyActive: active,
            policyName: name,
            srn,
          })
        })
      })

    this.props.identityContent
      .getIn(['hasAttached', 'items'], List())
      .toJS()
      .forEach(policy => {
        const { name, active, srn } = policy
        rows.push({
          groupName: 'Direct Attached',
          policyActive: active,
          policyName: name,
          srn,
        })
      })
    return rows
  }

  getPerformed = () => {
    const identityContent = this.props.identityContent
      .getIn(['performed', 'group'], List())
      .toJS()

    return identityContent.map(action => {
      return { action: action.key.eventName, count: identityContent.length }
    })
  }

  getColumns = () => {
    const numGroups = this.getMemberOf().length
    const numPerformed = this.getPerformed().length

    return {
      xs: [
        {
          widthPercent: 100,
          items: ['groups', 'performed', 'graph'],
          heights: {
            groups: numGroups > 0 ? 2 : 1,
            performed: numPerformed > 0 ? 2 : 1,
            graph: 3,
          },
        },
      ],
    }
  }

  getGridContent = () => {
    return [
      <div key="groups">
        <TableWidget
          nodeViewType="Identity"
          setUserProfileTables={this.props.setUserProfileTables}
          userProfile={this.props.userProfile}
          data={this.getMemberOf()}
          title={this.props.intl.formatMessage(messages.groupsPolicies)}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.isLoading}
        />
      </div>,
      <div key="performed">
        <TableWidget
          nodeViewType="Identity"
          setUserProfileTables={this.props.setUserProfileTables}
          userProfile={this.props.userProfile}
          data={this.getPerformed()}
          title={this.props.intl.formatMessage(messages.performed)}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.isLoading}
        />
      </div>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          columns={this.getColumns()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
          renderDetailsTab={false}
        />
      </Fragment>
    )
  }
}

IdentityNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  isLoading: PropTypes.bool,
  identityContent: ImmutablePropTypes.map.isRequired,
  getIdentityContent: PropTypes.func.isRequired,
  setUserProfileTables: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile,
  isLoading: selectIsLoading,
  identityContent: selectIdentityContent,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserProfileTables,
      getIdentityContent,
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({
  key: 'identityNodeViewCardLayout',
  reducer,
})

const withSaga = injectSaga({
  key: 'identityNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(IdentityNodeViewCardLayout)
