import { fromJS } from 'immutable'
import {
  FETCH_SAVED_SEARCH_WIDGET_RESULTS,
  FETCH_ADVANCED_SEARCH_WIDGET_RESULTS,
  FETCH_CUSTOM_SEARCH_WIDGET_RESULTS,
  SET_WIDGET_RESULTS,
  SET_TITLE,
  SET_SONRAI_SEARCH_NAME,
  SET_SAVED_SEARCH_ID,
  SET_WIDGET_RESULTS_ERROR,
  SET_FILTER,
  SET_SAVED_SEARCH_FILTER_CARD_ID,
} from './constants'
import { createNestedOrderMap } from 'utils/sonraiUtils'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  results: {},
  title: '',
  error: null,
  loading: true,
  sonraiSearchName: null,
  savedSearchId: null,
  filter: {},
  savedSearchFilterCardId: null,
})

const widgetResultExplorerReducer = handleActions(
  {
    [FETCH_SAVED_SEARCH_WIDGET_RESULTS]: state =>
      state.set('loading', true).set('error', null),
    [FETCH_ADVANCED_SEARCH_WIDGET_RESULTS]: state =>
      state.set('loading', true).set('error', null),
    [FETCH_CUSTOM_SEARCH_WIDGET_RESULTS]: state =>
      state.set('loading', true).set('error', null),
    [SET_WIDGET_RESULTS]: (state, { payload }) =>
      state
        .set('results', createNestedOrderMap(payload))
        .set('error', null)
        .set('loading', false),
    [SET_WIDGET_RESULTS_ERROR]: (state, { payload }) =>
      state.set('error', payload).set('loading', false),
    [SET_TITLE]: (state, { payload }) => state.set('title', payload),
    [SET_SONRAI_SEARCH_NAME]: (state, { payload }) =>
      state.set('sonraiSearchName', payload),
    [SET_SAVED_SEARCH_ID]: (state, { payload }) =>
      state.set('savedSearchId', payload),
    [SET_FILTER]: (state, { payload }) => state.set('filter', fromJS(payload)),
    [SET_SAVED_SEARCH_FILTER_CARD_ID]: (state, { payload }) =>
      state.set('savedSearchFilterCardId', payload),
  },
  initialState
)

export default widgetResultExplorerReducer
