import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { fromJS } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import EnableChangeDetection from './EnableChangeDetection'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import ManageChangeDetection from './ManageChangeDetection'
import { DEFAULT_ACTION_CLASSIFICATIONS_TO_ENABLE } from 'appConstants'
class ChangeDetectionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.style = {
      propertyContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      },
      enableChangeDetectionText: {
        width: '100%',
        textAlign: 'center',
        padding: '0.25rem',
        color: '#888',
        fontSize: '0.9rem',
        fontWeight: '400',
      },
      enableButton: {
        marginLeft: '1rem',
      },
      table: {
        height: '50vh',
        width: '100%',
        minWidth: '500px',
      },
      button: {
        backgroundColor: `${this.props.theme.primary}`,
        fontSize: '0.9rem',
        color: '#fff',
        padding: '0.5rem 1rem',
      },
      loadingContainer: {
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }
    this.modalRef = React.createRef()
  }

  handleEscPress = ({ key }) => {
    if (key && key === 'Escape') {
      this.props.close()
    }
  }

  componentDidMount() {
    document.addEventListener('keypress', this.handleEscPress)
    document.addEventListener('keydown', this.handleEscPress)
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleEscPress)
    document.removeEventListener('keydown', this.handleEscPress)
  }

  optionSelectionChanged = ({ api }) => {
    const { changeDetectionOptions } = this.props
    const selectedKeyNames = api.getSelectedRows().map(row => row.keyName)
    const selectedChangeDetectionOptions = changeDetectionOptions
      .toJS()
      .filter(option => selectedKeyNames.includes(option.keyName))

    this.setState({
      selectedChangeDetectionOptions,
    })
  }

  propertySelectionChanged = ({ api }) => {
    const { changeDetectionProperties } = this.props
    const selectedProperties = api.getSelectedRows()

    const selectedChangeDetectionProperties = changeDetectionProperties
      .toJS()
      .filter(option =>
        selectedProperties.map(x => x.actualKeyName).includes(option.keyName)
      )
      .filter(option =>
        selectedProperties.map(x => x.alertLevel).includes(option.alertLevel)
      )

    this.setState({
      selectedChangeDetectionProperties,
    })
  }

  handleUpdateChangeDetectionProperty = params => {
    this.props.updateChangeDetectionProperty(params)
  }

  handleEnableChangeDetectionProperties = () => {
    const { resourceSrn, addChangeDetectionProperties } = this.props
    const { selectedChangeDetectionOptions } = this.state

    selectedChangeDetectionOptions.forEach(option => {
      option.alertLevel = 5
      if (!_.isEmpty(option.actionClassification)) {
        option.actionClassification = DEFAULT_ACTION_CLASSIFICATIONS_TO_ENABLE
      }
    })

    addChangeDetectionProperties({
      properties: fromJS(selectedChangeDetectionOptions),
      resourceSrn,
    })
  }

  handleRemoveChangeDetectionProperties = () => {
    const {
      resourceSrn,
      removeChangeDetectionProperties,
      changeDetectionProperties,
    } = this.props
    const { selectedChangeDetectionProperties } = this.state
    let indexes = []
    selectedChangeDetectionProperties.forEach(property => {
      const index = changeDetectionProperties.findIndex(
        x =>
          x.get('keyName') === property.keyName &&
          x.get('alertLevel') === property.alertLevel
      )

      if (index !== -1) {
        indexes.push(index)
      }
    })

    removeChangeDetectionProperties({
      properties: fromJS(selectedChangeDetectionProperties),
      resourceSrn,
      indexes,
    })
    this.setState({ selectedChangeDetectionProperties: undefined })
  }

  getModalBody = () => {
    const {
      isLoading,
      changeDetectionProperties,
      changeDetectionOptions,
    } = this.props

    if (isLoading) {
      return (
        <div style={this.style.loadingContainer}>
          <SquareLoadingAnimation />
        </div>
      )
    }

    if (!changeDetectionProperties.isEmpty()) {
      return (
        <ManageChangeDetection
          handleRemoveChangeDetectionProperties={
            this.handleRemoveChangeDetectionProperties
          }
          selected={this.state.selectedChangeDetectionProperties}
          resourceSrn={this.props.resourceSrn}
          changeDetectionOptions={this.props.changeDetectionOptions}
          changeDetectionProperties={this.props.changeDetectionProperties}
          addChangeDetectionProperties={this.props.addChangeDetectionProperties}
          propertySelectionChanged={this.propertySelectionChanged}
          data={changeDetectionProperties.toJS().map(property => ({
            actionClassification: property.actionClassification,
            keyName: property.keyName,
            alertLevel: property.alertLevel,
          }))}
          updateChangeDetectionProperty={
            this.handleUpdateChangeDetectionProperty
          }
        />
      )
    } else {
      return (
        <EnableChangeDetection
          data={changeDetectionOptions.toJS().map(option => ({
            keyName: option.keyName,
          }))}
          selectionChanged={this.optionSelectionChanged}
        />
      )
    }
  }

  render() {
    const { isOpen, toggle } = this.props
    const isActive = !this.props.changeDetectionProperties.isEmpty()

    return (
      <Modal
        toggle={this.props.toggle}
        innerRef={this.modalRef}
        isOpen={isOpen}
        style={{ minWidth: '700px' }}
      >
        <ModalHeader toggle={toggle}>
          {isActive
            ? 'Manage Resource Change Detection'
            : 'Enable Resource Change Detection'}
        </ModalHeader>
        <ModalBody>{this.getModalBody()}</ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={toggle}>
            Close
          </TextLink>
          {!isActive && (
            <Button
              color="primary"
              onClick={this.handleEnableChangeDetectionProperties}
              disabled={_.isEmpty(this.state.selectedChangeDetectionOptions)}
            >
              Enable
            </Button>
          )}
        </ModalFooter>
      </Modal>
    )
  }
}

ChangeDetectionModal.propTypes = {
  theme: themeShape,
  toggle: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  addChangeDetectionProperties: PropTypes.func.isRequired,
  resourceSrn: PropTypes.string.isRequired,
  changeDetectionOptions: ImmutablePropTypes.iterable,
  changeDetectionProperties: ImmutablePropTypes.iterable,
  removeChangeDetectionProperties: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  updateChangeDetectionProperty: PropTypes.func.isRequired,
}

export default themeable(ChangeDetectionModal)
