import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import gql from 'graphql-tag'
import { getClient } from 'apolloClient'
import {
  setPerformedActions,
  setRolesAssumed,
  setRolesAssumedBy,
  setActiveFrom,
  setAccessedUsing,
  setSelectedRowActions,
} from './actions'
import {
  GET_PERFORMED_ACTIONS,
  GET_ROLES_ASSUMED,
  GET_ROLES_ASSUMED_BY,
  GET_ACTIVE_FROM,
  GET_ACCESSED_USING,
  GET_SELECTED_ROW_ACTIONS,
} from './constants'
import { selectPerformedActionFields } from './selectors'
import {
  GET_ACTIVE_FROM_ACTIONS,
  GET_ACCESSED_USING_ACTIONS,
  GET_WAS_ACCESSED_USING_ACTIONS,
} from 'static-queries'
import { DEFAULT_DISPLAY_COLUMNS } from 'query-builder'
import _ from 'lodash'

import {
  GET_ACCESSED_FROM_ACTIONS,
  GET_ACTIVITY_WIDGET,
  GET_ACCESSED_USING_WIDGET_CRITICAL_RESOURCE_SRN,
} from './queries'

function* getPerformedActions(action) {
  const performedActionFields = yield select(selectPerformedActionFields)

  const {
    payload: { srn, fromDate, toDate },
  } = action

  let fields = ''
  if (performedActionFields.includes('performedByValue')) {
    fields = `performedByValue: { value: "${srn}" }`
  }

  if (performedActionFields.includes('performedOnValue')) {
    fields = `performedOnValue: { value:  "${srn}" }`
  }

  if (
    performedActionFields.includes('performedOnValue') &&
    performedActionFields.includes('performedByValue')
  ) {
    fields = ` or: [ { performedOnValue: { value:  "${srn}" } } { performedByValue: { value:  "${srn}" } } ]`
  }

  const formattedFromDate = fromDate.format('YYYY-MM-DD')
  const formattedToDate = toDate.format('YYYY-MM-DD')

  try {
    const client = yield getClient()
    const response = yield client.query({
      query: gql`
      query getPerformedActionsBetweenDates(
        $fromDate: DateTime
        $toDate: DateTime
      ) {
        Actions(
          where: {
            createdDate: {
              and: [
                { op: GTE, value: $fromDate }
                { op: LTE, value: $toDate }
              ]
            }
           ${fields}
          }
        ) {
          count
          items(limit: 500) {
            ${DEFAULT_DISPLAY_COLUMNS.action.join(`\n
            `)}
          }
        }
      }
      `,
      variables: { toDate: formattedToDate, fromDate: formattedFromDate },
    })
    yield put(setPerformedActions(response.data.Actions.items))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting performed actions', e)
  }
}

function* getRolesAssumed(action) {
  const {
    payload: { srn, fromDate, toDate },
  } = action

  const formattedFromDate = fromDate.utc().format()
  const formattedToDate = toDate.utc().format()

  try {
    const client = yield getClient()
    const response = yield client.query({
      query: gql`
        query getRolesAssumed(
          $srn: String!
          $fromDate: DateTime
          $toDate: DateTime
        ) {
          Actions(
            where: {
              performedByValue: { value: $srn }
              eventName: { value: "AssumeRole" }
              createdDate: {
                and: [
                  { op: GTE, value: $fromDate }
                  { op: LTE, value: $toDate }
                ]
              }
              succeeded: { value: true }
            }
          ) {
            items {
              srn
              performedOnValue
              createdDate
              performedOn {
                items {
                  srn
                  ... on Role {
                    name
                    friendlyName
                  }
                }
              }
            }
          }
        }
      `,
      variables: { srn, toDate: formattedToDate, fromDate: formattedFromDate },
    })

    yield put(
      setRolesAssumed(_.get(response, ['data', 'Actions', 'items'], []))
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting roles assumed', e)
  }
}

function* getRolesAssumedBy(action) {
  const {
    payload: { srn, fromDate, toDate },
  } = action

  const formattedFromDate = fromDate.utc().format()
  const formattedToDate = toDate.utc().format()

  try {
    const client = yield getClient()
    const response = yield client.query({
      query: gql`
        query getRolesAssumedBy(
          $srn: String!
          $fromDate: DateTime
          $toDate: DateTime
        ) {
          Actions(
            where: {
              performedOnValue: { value: $srn }
              createdDate: {
                and: [
                  { op: GTE, value: $fromDate }
                  { op: LTE, value: $toDate }
                ]
              }
              eventName: { value: "AssumeRole" }
              succeeded: { value: true }
            }
          ) {
            items(limit: 1000) {
              srn
              performedOnValue
              performedByValue
              createdDate
              performedBy {
                items {
                  srn
                  name
                }
              }
            }
          }
        }
      `,
      variables: { srn, toDate: formattedToDate, fromDate: formattedFromDate },
    })

    yield put(
      setRolesAssumedBy(_.get(response, ['data', 'Actions', 'items'], []))
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting roles assumed', e)
  }
}

function* getActiveFrom(action) {
  const { srn, fromDate, toDate, actionableByMode } = action.payload

  const formattedFromDate = fromDate.utc().format()
  const formattedToDate = toDate.utc().format()

  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
        ${GET_ACTIVITY_WIDGET}
      `,
      variables: {
        srn,
        toDate: formattedToDate,
        fromDate: formattedFromDate,
        keyName: actionableByMode ? 'accessedFrom' : 'activeFrom',
      },
    })
    yield put(setActiveFrom(response.data.AlertLogs.items))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting activity regions data', e)
  }
}

function* getAccessedUsing(action) {
  const {
    payload: { srn, fromDate, toDate, actionableByMode },
  } = action
  const formattedFromDate = fromDate.utc().format()
  const formattedToDate = toDate.utc().format()

  try {
    const client = yield getClient()
    const response = yield client.query({
      query: gql`
        ${GET_ACCESSED_USING_WIDGET_CRITICAL_RESOURCE_SRN}
      `,
      variables: {
        srn,
        toDate: formattedToDate,
        fromDate: formattedFromDate,
        keyName: actionableByMode ? 'accessedFrom' : 'activeFrom',
      },
    })
    const items = !_.isEmpty(response.data.AlertLogs.items)
      ? response.data.AlertLogs.items
      : []
    yield put(setAccessedUsing(items))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting user agents', e)
  }
}

function* getActiveFromActions(data, fromDate, toDate) {
  const { criticalResourceSRN, regionSet } = data
  const countryValue = regionSet[0]
  try {
    const client = getClient()
    let response = yield client.query({
      query: gql`
        ${GET_ACTIVE_FROM_ACTIONS}
      `,
      variables: {
        fromDate,
        toDate,
        criticalResourceSRN,
        countryValue,
      },
    })

    const actions = _.get(response, ['data', 'Actions', 'items'], [])
    yield put(
      setSelectedRowActions(_.sortBy(actions, ['createdDate']).reverse())
    )
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting actions for selected node ', err)
  }
}

function* getAccessedFromActions(data, fromDate, toDate) {
  const { criticalResourceSRN, regionSet } = data
  const countryValue = regionSet[0]
  try {
    const client = getClient()
    let response = yield client.query({
      query: gql`
        ${GET_ACCESSED_FROM_ACTIONS}
      `,
      variables: {
        fromDate,
        toDate,
        criticalResourceSRN,
        countryValue,
      },
    })
    const actions = _.get(response, ['data', 'Actions', 'items'], [])
    yield put(
      setSelectedRowActions(_.sortBy(actions, ['createdDate']).reverse())
    )
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting actions for selected node ', err)
  }
}

function* getAccessedUsingActions(data, fromDate, toDate) {
  const { criticalResourceSRN, userAgentSet } = data
  const userValue = userAgentSet[0]
  try {
    const client = getClient()
    let response = yield client.query({
      query: gql`
        ${GET_WAS_ACCESSED_USING_ACTIONS}
      `,
      variables: {
        fromDate,
        toDate,
        criticalResourceSRN,
        userValue,
      },
    })
    const actions = _.get(response, ['data', 'Actions', 'items'], [])
    yield put(
      setSelectedRowActions(_.sortBy(actions, ['createdDate']).reverse())
    )
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting actions for selected node ', err)
  }
}

function* getActiveUsingActions(data, fromDate, toDate) {
  const { criticalResourceSRN, userAgentSet } = data
  const userValue = userAgentSet[0]
  try {
    const client = getClient()
    let response = yield client.query({
      query: gql`
        ${GET_ACCESSED_USING_ACTIONS}
      `,
      variables: {
        fromDate,
        toDate,
        criticalResourceSRN,
        userValue,
      },
    })
    const actions = _.get(response, ['data', 'Actions', 'items'], [])
    yield put(
      setSelectedRowActions(_.sortBy(actions, ['createdDate']).reverse())
    )
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting actions for selected node ', err)
  }
}

function* getActions(action) {
  const {
    data,
    date: { fromDate, toDate },
    path,
  } = action.payload

  const formattedFromDate = fromDate.format()
  const formattedToDate = toDate.format()

  switch (path) {
    case 'activeFrom':
      yield call(getActiveFromActions, data, formattedFromDate, formattedToDate)
      break
    case 'accessedFrom':
      yield call(
        getAccessedFromActions,
        data,
        formattedFromDate,
        formattedToDate
      )
      break
    case 'accessedUsing':
      yield call(
        getAccessedUsingActions,
        data,
        formattedFromDate,
        formattedToDate
      )
      break
    case 'activeUsing':
      yield call(
        getActiveUsingActions,
        data,
        formattedFromDate,
        formattedToDate
      )
      break
    default:
      return
  }
}

function* activitySaga() {
  yield all([
    takeLatest(GET_PERFORMED_ACTIONS, getPerformedActions),
    takeLatest(GET_ROLES_ASSUMED, getRolesAssumed),
    takeLatest(GET_ROLES_ASSUMED_BY, getRolesAssumedBy),
    takeLatest(GET_ACTIVE_FROM, getActiveFrom),
    takeLatest(GET_ACCESSED_USING, getAccessedUsing),
    takeLatest(GET_SELECTED_ROW_ACTIONS, getActions),
  ])
}

export default activitySaga
