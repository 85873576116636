import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { fromJS } from 'immutable'

import Tag from 'components/Tag'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import _ from 'lodash'
import FormLabel from 'components/FormLabel'
import { bulkTag } from 'containers/TicketDetailsData/actions'

export class AddTagsModal extends React.Component {
  styles = {
    inputWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 80px',
      gridTemplateRows: 'auto',
      gridColumnGap: '0.5em',
      gridRowGap: '0.5em',
      padding: '1em 0.5em',
    },
    inputLabel: {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
    },
  }

  state = {
    tags: [],
    suggestions: [],
    key: '',
    value: '',
  }

  handleChange = ({ key, value }) => {
    const { tags } = this.state
    const keys = tags.map(tag => tag.key)
    if (keys.includes(key)) {
      let updatedState = [...tags]
      updatedState.splice(keys.indexOf(key), 1)
      this.setState({
        tags: updatedState,
      })
    } else {
      this.setState(oldState => ({
        tags: [...oldState.tags, { key, value }],
        key: '',
        value: '',
      }))
    }
  }

  onCancel = () => {
    this.setState({ tags: [] })
    this.props.close()
  }

  onSave = () => {
    this.props.bulkTag({
      resourceSrns: this.props.resourceSrns,
      tags: this.state.tags,
    })

    this.props.close()
  }

  onChangeKey = event => {
    this.setState({
      key: event.target.value,
    })
  }

  onChangeValue = event => {
    this.setState({
      value: event.target.value,
    })
  }

  render() {
    return (
      <Modal isOpen={true}>
        <ModalHeader>Add Tag(s)</ModalHeader>
        <ModalBody>
          <div style={this.styles.inputWrapper}>
            <div>
              <FormLabel required style={this.styles.inputLabel}>
                Key
              </FormLabel>
              <Input value={this.state.key} onChange={this.onChangeKey} />
            </div>
            <div>
              <FormLabel required style={this.styles.inputLabel}>
                Value
              </FormLabel>
              <Input onChange={this.onChangeValue} value={this.state.value} />
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
              <Button
                disabled={
                  this.state.key.trim() === '' || this.state.value.trim() === ''
                }
                style={{ width: '100%' }}
                onClick={() =>
                  this.handleChange({
                    key: this.state.key,
                    value: this.state.value,
                  })
                }
                color="primary"
              >
                Add
              </Button>
            </div>
          </div>

          {!_.isEmpty(this.state.tags) &&
            this.state.tags.map((tag, i) => (
              <Tag
                key={i}
                tag={fromJS(tag)}
                removeTag={() => this.handleChange(tag)}
              />
            ))}
        </ModalBody>
        <ModalFooter>
          <TextLink color="secondary" onClick={this.onCancel}>
            Cancel
          </TextLink>
          <Button
            onClick={this.onSave}
            color="primary"
            disabled={_.isEmpty(this.state.tags)}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

AddTagsModal.propTypes = {
  bulkTag: PropTypes.func.isRequired,
  close: PropTypes.func,
  resourceSrns: PropTypes.array.isRequired,
}
const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      bulkTag,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(AddTagsModal)
