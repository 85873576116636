/**
 *
 * EncryptionKeyViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { push } from 'connected-react-router'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { List, Map } from 'immutable'
import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import { TopTitle } from 'components/Card'
import PolicyVersionWidget from 'components/PolicyVersionWidget'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import TextLink from 'components/TextLink'
import reducer from './reducer'
import sagas from './sagas'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { getEncryptionKeyContent } from './actions'
import { selectEncryptionKeyContent, selectIsLoading } from './selectors'

export class EncryptionKeyNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getEncryptionKeyContent({ srn: props.nodeData.srn })

    this.styles = {
      title: {
        fontSize: '22px',
        fontWeight: '300',
        marginRight: '0px',
      },
      prevNext: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      modalHeader: {
        fontSize: '17px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '60vw',
        color: props.theme.secondary,
      },
      modalFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    }

    this.state = {
      selectedTabIndex: 0,
      selectedPolicyIndex: 0,
      isOpen: false,
    }
  }

  setTabIndex = index => {
    this.setState({
      selectedTabIndex: index,
    })
  }

  getVersions = () =>
    this.props.encryptionKeyContent
      .getIn(
        [
          'hasAttached',
          'items',
          this.state.selectedPolicyIndex,
          'hasPolicyVersion',
          'items',
        ],
        List()
      )
      .toJS()

  getSelectedVersion = () => {
    return this.getVersions()[this.state.selectedTabIndex] || null
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  onClickPolicy = srn => {
    this.props.push(getNodeViewPushParams(srn, getTypeFromSrn(srn)))
  }

  getProtected = () => {
    const data = this.props.encryptionKeyContent
      .getIn(['protects', 'items'], List())
      .toJS()

    return data.map(item => ({
      protects: item.friendlyName || item.name || '',
      type: item.label || '',
      srn: item.srn,
    }))
  }

  getAlias = () => {
    return this.props.encryptionKeyContent
      .getIn(['hasAlias', 'items'], List())
      .toJS()
  }

  getLayouts = () => {
    return {
      alias: {
        lg: { w: 4, h: 2 },
        md: { w: 4, h: 2 },
        sm: { w: 4, h: 2 },
        xs: { w: 4, h: 2 },
      },
      protects: {
        lg: { w: 4, h: 2 },
        md: { w: 4, h: 2 },
        sm: { w: 4, h: 2 },
        xs: { w: 4, h: 2 },
      },
      policy: {
        lg: { w: 8, h: 3 },
        md: { w: 8, h: 3 },
        sm: { w: 8, h: 3 },
        xs: { w: 8, h: 3 },
      },
      graph: {
        lg: { w: 7, h: 3 },
        md: { w: 7, h: 3 },
        sm: { w: 7, h: 3 },
        xs: { w: 7, h: 3 },
      },
    }
  }

  getCols = () => {
    return {
      lg: 8,
      md: 8,
      sm: 8,
      xs: 1,
    }
  }

  handleNextPolicy = currentIndex => {
    this.setState({ selectedPolicyIndex: currentIndex + 1 })
  }

  handlePrevPolicy = currentIndex => {
    this.setState({ selectedPolicyIndex: currentIndex - 1 })
  }

  renderPolicyNav = () => {
    const { selectedPolicyIndex } = this.state
    const isFirst = selectedPolicyIndex === 0
    const isLast =
      selectedPolicyIndex ===
      this.props.encryptionKeyContent.getIn(['hasAttached', 'items'], List())
        .size -
        1
    return (
      <div style={this.styles.prevNext}>
        <TextLink
          disabled={isFirst}
          color="primary"
          style={{ cursor: 'pointer', userSelect: 'none' }}
          onClick={() =>
            this.setState(oldState => ({
              selectedPolicyIndex: oldState.selectedPolicyIndex - 1,
            }))
          }
        >
          Prev
        </TextLink>
        <div style={{ margin: '0rem 0.5rem' }}>
          {this.state.selectedPolicyIndex + 1} of{' '}
          {
            this.props.encryptionKeyContent.getIn(
              ['hasAttached', 'items'],
              List()
            ).size
          }
        </div>
        <TextLink
          disabled={isLast}
          color="primary"
          style={{ cursor: 'pointer', userSelect: 'none' }}
          onClick={() =>
            this.setState(oldState => ({
              selectedPolicyIndex: oldState.selectedPolicyIndex + 1,
            }))
          }
        >
          Next
        </TextLink>
      </div>
    )
  }

  togglePolicyEntriesModal = () =>
    this.setState(oldState => ({ isOpen: !oldState.isOpen }))

  renderPolicyEntriesModal = () => {
    const version = this.getSelectedVersion()
    const root =
      this.props.encryptionKeyContent.getIn([
        'hasAttached',
        'items',
        this.state.selectedPolicyIndex,
      ]) || Map()

    const title =
      root.get('friendlyName') || root.get('name') || root.get('srn') || ''
    return (
      <Modal
        toggle={this.togglePolicyEntriesModal}
        isOpen={this.state.isOpen}
        style={{ minWidth: '80vw' }}
      >
        <ModalHeader toggle={this.togglePolicyEntriesModal}>
          <div
            style={
              title === root.get('srn')
                ? { ...this.styles.modalHeader, ...{ fontSize: '15px' } }
                : this.styles.modalHeader
            }
          >
            <TextLink
              onClick={() =>
                this.onClickPolicy(
                  this.props.encryptionKeyContent.getIn([
                    'hasAttached',
                    'items',
                    this.state.selectedPolicyIndex,
                    'srn',
                  ])
                )
              }
              title={title}
            >
              {title}
            </TextLink>
          </div>
        </ModalHeader>
        <ModalBody style={{ height: '60vh', overflow: 'auto' }}>
          <PolicyVersionWidget loading={this.props.isLoading} {...version} />
        </ModalBody>
        <ModalFooter style={this.styles.modalFooter}>
          <TextLink color="primary" onClick={this.togglePolicyEntriesModal}>
            Close
          </TextLink>
          {this.renderPolicyNav()}
        </ModalFooter>
      </Modal>
    )
  }

  getPolicies = () => {
    const policies = this.props.encryptionKeyContent
      .getIn(['hasAttached', 'items'], List())
      .toJS()
      .map(item => ({
        name: item.name || item.srn || '',
        srn: item.srn || '',
        showPolicy: '',
        account: item.account || '',
        cloudType: item.cloudType || '',
        createdDate: item.createdDate || '',
        createdBy: item.createdBy || '',
      }))
    return policies
  }

  handlePolicyModal = srn => {
    const index = this.props.encryptionKeyContent
      .getIn(['hasAttached', 'items'], List())
      .findIndex(item => item.get('srn') === srn)

    if (index !== -1) {
      this.setState({
        selectedPolicyIndex: index,
        isOpen: true,
      })
    }
  }

  getGridContent = () => {
    return [
      <div key="protects">
        <BorderedCard
          style={{
            height: '100%',
          }}
        >
          <TopTitle style={this.styles.title}>Protects</TopTitle>
          <DataTable
            style={{ height: '90%' }}
            data={this.getProtected()}
            onClickNodeView={this.onClickNodeView}
            hiddenColumns={['srn']}
          />
        </BorderedCard>
      </div>,
      <div key="alias">
        <BorderedCard
          style={{
            height: '100%',
          }}
        >
          <TopTitle style={this.styles.title}>Alias</TopTitle>
          <DataTable style={{ height: '90%' }} data={this.getAlias()} />
        </BorderedCard>
      </div>,
      <div key="policy">
        <BorderedCard style={{ height: '100%' }}>
          <TopTitle style={this.styles.title}> Polices </TopTitle>
          <DataTable
            style={{ height: '90%' }}
            hiddenColumns={['srn']}
            onDoubleClickRow={({ srn }) => this.onClickPolicy(srn)}
            data={this.getPolicies()}
            customColumnConfig={{
              name: {
                maxWidth: 250,
              },
              showPolicy: {
                width: 200,
                minWidth: 160,
                pinned: 'right',
                headerName: '',
                enableRowGroup: false,
                menuTabs: [],
                suppressMenu: true,
                cellRendererFramework: ({ data: { srn } }) => {
                  return (
                    <TextLink
                      color="primary"
                      style={{
                        color: this.props.theme.primary,
                      }}
                      onClick={() => this.handlePolicyModal(srn)}
                    >
                      Show Policy Entries
                    </TextLink>
                  )
                },
              },
            }}
          />
        </BorderedCard>
      </div>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />
        {!this.props.isLoading && this.renderPolicyEntriesModal()}
        <NodeView
          {...this.props}
          layouts={this.getLayouts()}
          cols={this.getCols()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
        />
      </Fragment>
    )
  }
}

EncryptionKeyNodeViewCardLayout.propTypes = {
  theme: themeShape,
  isLoading: PropTypes.bool,
  encryptionKeyContent: ImmutablePropTypes.map.isRequired,
  getEncryptionKeyContent: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  userProfile: selectUserProfile,
  encryptionKeyContent: selectEncryptionKeyContent,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEncryptionKeyContent,
      setUserProfileTables,
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({
  key: 'encryptionKeyNodeViewCardLayout',
  reducer,
})

const withSaga = injectSaga({
  key: 'encryptionKeyNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(themeable(EncryptionKeyNodeViewCardLayout))
