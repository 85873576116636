import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
const Handle = Slider.Handle

class ImportanceWidget extends Component {
  styles = {
    slider: {
      width: '168px',
      margin: '0.5rem 0',
    },
  }

  handleChange = props => {
    const { value, dragging, ...restProps } = props

    //Convert dragging to a string to remove a warning in console
    //See: https://github.com/react-component/slider/issues/502
    return (
      <Handle value={value} dragging={String(dragging)} {...restProps}>
        <span
          style={{
            fontSize: '0.75rem',
            fontWeight: '400',
            color: `rgb(136, 136, 136)`,
          }}
        >
          {value}
        </span>
      </Handle>
    )
  }

  setImportance = (nodeId, importance) => {
    if (this.props.importance !== importance) {
      this.props.setImportance({ srn: nodeId, importance: importance })
    }
  }

  render() {
    const { nodeId } = this.props

    return (
      <div style={this.styles.slider}>
        <Slider
          trackStyle={{
            backgroundColor: `rgb(39, 110, 241)`,
          }}
          handleStyle={{
            width: '30px',
            height: '30px',
            display: 'flex',
            background: `rgb(255, 255, 255)`,
            border: `0.5px solid rgb(136, 136, 136)`,
            alignItems: `center`,
            justifyContent: `center`,
            marginTop: '-13px',
          }}
          onAfterChange={importance => {
            this.setImportance(nodeId, importance)
          }}
          min={1}
          max={10}
          defaultValue={
            typeof this.props.importance !== undefined &&
            this.props.importance !== 0
              ? this.props.importance
              : 1
          }
          handle={this.handleChange}
          disabled={this.props.disabled}
        />
      </div>
    )
  }
}

ImportanceWidget.propTypes = {
  importance: PropTypes.number,
  setImportance: PropTypes.func.isRequired,
  nodeId: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ImportanceWidget
