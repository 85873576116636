import {
  GET_DATA_CONTAINER_CONTENT,
  SET_DATA_CONTAINER_CONTENT,
  GET_ACTIONS_PERFORMED,
  SET_ACTIONS_PERFORMED,
} from './constants'
import { createAction } from 'redux-actions'

export const getDataContainerContent = createAction(GET_DATA_CONTAINER_CONTENT)
export const setDataContainerContent = createAction(SET_DATA_CONTAINER_CONTENT)

export const getActionsPerformed = createAction(GET_ACTIONS_PERFORMED)
export const setActionsPerformed = createAction(SET_ACTIONS_PERFORMED)
