import { createAction } from 'redux-actions'
import {
  NEXT_STEP,
  PREV_STEP,
  RESET_STATE,
  SELECT_BOT,
  FETCH_ALL_ESCALATIONS,
  FETCH_ALL_ESCALLATIONS_ERROR,
  FETCH_ALL_ESCALLATIONS_SUCCESS,
  SUBMIT_AUTO_REMEDIATE,
  SUBMIT_AUTO_REMEDIATE_ERROR,
  SUBMIT_AUTO_REMEDIATE_NOT_ACCEPTED,
  SUBMIT_AUTO_REMEDIATE_NO_COLLECTOR_MATCHED,
  SUBMIT_AUTO_REMEDIATE_SUCCESS,
} from './constants'

export const nextStep = createAction(NEXT_STEP)
export const prevStep = createAction(PREV_STEP)
export const resetState = createAction(RESET_STATE)
export const selectBot = createAction(SELECT_BOT)

export const fetchAllEscalations = createAction(FETCH_ALL_ESCALATIONS)
export const fetchAllEscalationsError = createAction(
  FETCH_ALL_ESCALLATIONS_ERROR
)
export const fetchAllEscalationsSucccess = createAction(
  FETCH_ALL_ESCALLATIONS_SUCCESS
)

export const submitAutoRemediate = createAction(SUBMIT_AUTO_REMEDIATE)
export const submitAutoRemediateSuccess = createAction(
  SUBMIT_AUTO_REMEDIATE_SUCCESS
)
export const submitAutoRemediateNotAccepted = createAction(
  SUBMIT_AUTO_REMEDIATE_NOT_ACCEPTED
)
export const submitAutoRemediateNoCollectorMatched = createAction(
  SUBMIT_AUTO_REMEDIATE_NO_COLLECTOR_MATCHED
)
export const submitAutoRemediateError = createAction(
  SUBMIT_AUTO_REMEDIATE_ERROR
)
