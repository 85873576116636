import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List, fromJS } from 'immutable'
import { FlatPathWidget } from 'components/PathWidget'
import _ from 'lodash'

class ShowPath extends Component {
  styles = {
    titleName: {
      fontWeight: 500,
    },
  }

  getPath = () => {
    if (!this.props.resourceId || _.isEmpty(this.props.accessPaths)) {
      return List()
    }
    return fromJS([this.props.accessPaths])
  }

  render() {
    return (
      <div style={{ height: '400px', overflow: 'hidden' }}>
        <FlatPathWidget
          key={`${this.props.resourceId}-flatpath`}
          data={this.getPath() || List()}
          resourceId={this.props.resourceId}
          loading={!this.props.resourceId || !this.props.accessPaths}
          onDoubleClick={this.props.onDoubleClick}
          emphasisNodes={[this.props.resourceId, this.props.sourceId]}
        />
      </div>
    )
  }
}

ShowPath.propTypes = {
  accessPaths: PropTypes.array,
  resourceId: PropTypes.string,
  sourceId: PropTypes.string.isRequired,
  onDoubleClick: PropTypes.func,
}

export default ShowPath
