export default {
  noBorderButton: {
    border: 'none',
    margin: '0',
    padding: '0',
    backgroundColor: 'inherit',
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    ':focus': {
      border: 'none',
      backgroundColor: 'inherit',
      boxShadow: 'none',
      outline: 'none',
    },
    ':active': {
      border: 'none',
      backgroundColor: 'inherit',
      boxShadow: 'none',
      outline: 'none',
    },
  },
}
