import React, { useEffect } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { isImmutable } from 'immutable'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'

import reducer from './reducers'
import sagas from './sagas'

import TextLink from 'components/TextLink'
import {
  nextStep,
  prevStep,
  resetState,
  selectBot,
  fetchAllEscalations,
  submitAutoRemediate,
} from './actions'
import { getBots } from 'containers/SonraiData/actions'

import { selectBots } from 'containers/SonraiData/selectors'
import {
  selectAllEscallations,
  selectStep,
  selectSelectedBot,
  selectSubmitAutoRemediateStatus,
} from './selectors'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import styled from 'styled-components'
import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

import messages from './messages'

const ModalHeaderContainer = styled.div`
  width: 100%;
`

export const ModalFootControls = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  & .next-button-container {
    text-align: right;
  }
`

function StepSwitch(props) {
  switch (props.step) {
    case 1:
      return <Step1 {...props} />
    case 2:
      return <Step2 {...props} />
    case 3:
      return <Step3 {...props} />
    default:
      // eslint-disable-next-line no-console
      console.error('no wisard step for step ' + props.step)
      return null
  }
}

StepSwitch.propTypes = {
  step: PropTypes.number.isRequired,
}

function isNextDisabled(props) {
  switch (props.step) {
    case 1:
      return props.selectedBot == null
    case 3:
      return props.submitAutoRemediateStatus.get('loading')
    default:
      return false
  }
}

function isPrevDisabled(props) {
  return props.step === 0
}

function getNextButtonText(props) {
  switch (props.step) {
    case 1:
      return messages.next
    case 2:
      return messages.autoremediate
    case 3:
      return messages.ok
    default:
      return messages.next
  }
}

function nextButtonOnClick(props) {
  switch (props.step) {
    case 1:
      props.nextStep()
      return
    case 2:
      props.submitAutoRemediate({
        ticket: isImmutable(props.ticket) ? props.ticket.toJS() : props.ticket,
        bot: isImmutable(props.selectedBot)
          ? props.selectedBot.toJS()
          : props.selectedBot,
      })
      props.nextStep()
      return
    case 3:
      props.close()
      props.resetState()
      return
  }
}

export function TicketAutoRemediateWizard(props) {
  useEffect(() => {
    if (props.bots.get('data').isEmpty() && !props.bots.get('loading')) {
      props.getBots({ filters: {} })
    }
    if (
      props.allEscallations.get('data') === null &&
      !props.allEscallations.get('loading')
    ) {
      props.fetchAllEscalations()
    }
    return () => {
      props.resetState()
    }
  }, [])
  return (
    <Modal
      size="lg"
      isOpen={props.isOpen}
      toggle={() => {
        props.close()
        props.resetState()
      }}
    >
      <ModalHeaderContainer>
        <ModalHeader
          toggle={() => {
            props.close()
            props.resetState()
          }}
        >
          <DynamicFormattedMessage {...messages.modalTitle} />
        </ModalHeader>
      </ModalHeaderContainer>
      <ModalBody>
        <StepSwitch {...props} />
      </ModalBody>
      <ModalFooter>
        <ModalFootControls>
          <div className="back-button-container">
            {props.step == 2 && (
              <Button
                outline
                color="primary"
                disabled={isPrevDisabled(props)}
                onClick={props.prevStep}
              >
                <DynamicFormattedMessage {...messages.back} />
              </Button>
            )}
          </div>
          <div className="next-button-container">
            <TextLink
              color="secondary"
              onClick={() => {
                props.close()
                props.resetState()
              }}
              style={{ marginRight: '0.5em' }}
            >
              Cancel
            </TextLink>
            <Button
              color="primary"
              disabled={isNextDisabled(props)}
              onClick={() => nextButtonOnClick(props)}
            >
              <DynamicFormattedMessage {...getNextButtonText(props)} />
            </Button>
          </div>
        </ModalFootControls>
      </ModalFooter>
    </Modal>
  )
}

TicketAutoRemediateWizard.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  ticket: ImmutablePropTypes.map,

  // ~ bound action creators ~
  getBots: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  fetchAllEscalations: PropTypes.func.isRequired,
  submitAutoRemediate: PropTypes.func.isRequired,

  // ~ from redux ~
  allEscallations: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  }).isRequired,
  bots: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    data: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  }).isRequired,
  step: PropTypes.number.isRequired,
  submitAutoRemediateStatus: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }),
}

const mapStateToProps = createStructuredSelector({
  allEscallations: selectAllEscallations,
  bots: selectBots,
  selectedBot: selectSelectedBot,
  step: selectStep,
  submitAutoRemediateStatus: selectSubmitAutoRemediateStatus,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBots,
      nextStep,
      prevStep,
      resetState,
      selectBot,
      fetchAllEscalations,
      submitAutoRemediate,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'ticketAutoRemediateWizard', reducer })
const withSaga = injectSaga({
  key: 'ticketAutoRemediateWizard',
  saga: sagas,
  mode: DAEMON,
})

export default compose(
  withReducer,
  withSaga,
  withConnect
)(TicketAutoRemediateWizard)
