import React from 'react'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SubmitFormBaseComponent, {
  DefaultFieldControlContainer,
} from './SubmitFormBaseComponent'
import { PlainCheckbox } from 'components/Checkbox'
import messages from '../messages'

export default class SubmitFormIncludeSamplesSection extends SubmitFormBaseComponent {
  renderTitleText = () => {
    return (
      <DynamicFormattedMessage {...messages.submitFormIncludeSamplesLabel} />
    )
  }

  renderHelpContent = () => {
    return (
      <DynamicFormattedMessage
        {...messages.submitFormIncludeSamplesInstructions}
      />
    )
  }

  setIncludeSamples = newVal => {
    this.props.setFormFields({ includeSamples: newVal })
  }

  renderFieldControls = () => {
    return (
      <DefaultFieldControlContainer>
        <div>
          <PlainCheckbox
            checked={this.props.formValues.includeSamples}
            onChange={this.setIncludeSamples}
          />
          Samples
        </div>
      </DefaultFieldControlContainer>
    )
  }
}
