import {
  RUN_QUERY,
  QUERY_ERROR_HAPPEN,
  SET_QUERY_RESULTS,
  SAVE_SEARCH_WITH_CONCURRENT_CHECK,
  SET_SEARCH_MODIFIED_CONCURRENTLY,
  SAVE_SEARCH,
  SAVE_SEARCH_SUCCESS,
  SAVE_SEARCH_ERROR,
  SET_SEARCH_NAME,
  LOAD_SEARCH_IN_ADVANCED,
  SET_MONITORING_ON_RESOURCES,
  SET_MONITORING_ON_RESOURCES_SUCCESS,
  SET_MONITORING_ON_RESOURCES_ERROR,
  SET_MONITORING_RESOURCES_EXCEEDED,
  SET_IMPORTANCE_MULTI,
  SET_IMPORTANCE_MULTI_SUCCESS,
  SET_IMPORTANCE_MULTI_MODAL,
  ADD_TAGS_ON_SELECTED_RESOURCES,
  ADD_TAGS_ON_SELECTED_RESOURCES_SUCCESS,
  TOGGLE_COMMENTS,
  CLEAR_QUERY_ERROR,
  CLEAR_SAVE_SEARCH_ERROR,
  RESET_EDITOR,
  SAVE_SEARCH_DESCRIPTION,
  SAVE_SEARCH_DESCRIPTION_SUCCESS,
  GET_NODETYPE_COUNTS,
  SET_NODETYPE_COUNTS,
} from './constants'
import { createAction } from 'redux-actions'

export const runQuery = createAction(RUN_QUERY)
export const queryErrorHappen = createAction(QUERY_ERROR_HAPPEN)
export const clearQueryError = createAction(CLEAR_QUERY_ERROR)
export const setQueryResults = createAction(SET_QUERY_RESULTS)
export const saveSearchWithConcurrentCheck = createAction(
  SAVE_SEARCH_WITH_CONCURRENT_CHECK
)
export const setSearchModifiedConcurrently = createAction(
  SET_SEARCH_MODIFIED_CONCURRENTLY
)
export const saveSearch = createAction(SAVE_SEARCH)
export const saveSearchSuccess = createAction(SAVE_SEARCH_SUCCESS)
export const setSaveSearchError = createAction(SAVE_SEARCH_ERROR)
export const clearSaveSearchError = createAction(CLEAR_SAVE_SEARCH_ERROR)
export const loadSearchInAdvanced = createAction(LOAD_SEARCH_IN_ADVANCED)
export const setSearchName = createAction(SET_SEARCH_NAME)
export const setMonitoringOnResources = createAction(
  SET_MONITORING_ON_RESOURCES
)
export const setMonitoringOnResourcesSuccess = createAction(
  SET_MONITORING_ON_RESOURCES_SUCCESS
)
export const setMonitoringOnResourcesError = createAction(
  SET_MONITORING_ON_RESOURCES_ERROR
)
export const setMonitoringResourcesExceeded = createAction(
  SET_MONITORING_RESOURCES_EXCEEDED
)
export const setImportanceMulti = createAction(SET_IMPORTANCE_MULTI)
export const setImportanceMultiSuccess = createAction(
  SET_IMPORTANCE_MULTI_SUCCESS
)
export const setImportanceMultiModal = createAction(SET_IMPORTANCE_MULTI_MODAL)

export const addTagsOnSelectedResources = createAction(
  ADD_TAGS_ON_SELECTED_RESOURCES
)
export const addTagsOnSelectedResourcesSuccess = createAction(
  ADD_TAGS_ON_SELECTED_RESOURCES_SUCCESS
)
export const toggleComments = createAction(TOGGLE_COMMENTS)

export const resetEditor = createAction(RESET_EDITOR)

export const saveSearchDescription = createAction(SAVE_SEARCH_DESCRIPTION)
export const saveSearchDescriptionSuccess = createAction(
  SAVE_SEARCH_DESCRIPTION_SUCCESS
)

export const getNodeTypeCounts = createAction(GET_NODETYPE_COUNTS)
export const setNodeTypeCounts = createAction(SET_NODETYPE_COUNTS)
