import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import { exists } from 'utils/sonraiUtils'
import { List } from 'immutable'
import Icon from 'components/Icon'
import Popup from 'reactjs-popup'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import SelectBar from 'components/SelectBar'
import { getFriendlyActionName, getFriendlyKeyName } from 'utils/widgetUtils'
import MultiSelect, { components } from 'react-select'
import _ from 'lodash'

class EditProperty extends Component {
  state = {
    popup: false,
    newAlertLevel: null,
    newActionClassification: null,
  }

  style = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    button: {
      background: '#FFFFFF',
      borderRadius: '2px',
      paddingTop: '0.1em',
      paddingBottom: '0.1em',
      paddingRight: '0.5em',
      paddingLeft: '0.5em',
      marginRight: '0.5em',
      height: '2em',
    },
  }

  isDisabled = () => {
    const { newAlertLevel, newActionClassification } = this.state
    const { alertLevel, actionClassification } = this.props.selected
    if (exists(actionClassification)) {
      const oldState = { alertLevel, actionClassification }
      if (
        (exists(newAlertLevel) || exists(newActionClassification)) &&
        !_.isEqual(oldState, this.state)
      ) {
        return false
      }
    }
    if (exists(newAlertLevel)) {
      if (newAlertLevel !== alertLevel) {
        return false
      }
    }
    return true
  }

  hasActionClassifications = () => {
    const { keyName } = this.state
    const { changeDetectionOptions } = this.props
    if (keyName) {
      const option = changeDetectionOptions.find(
        x => x.get('keyName') === keyName
      )
      if (
        option &&
        option.get('keyType') === 'PATH' &&
        option.get('actionClassification') &&
        !option.get('actionClassification').isEmpty()
      ) {
        return true
      }
    }
    return false
  }

  getRemoveProperty = (oldList, newList, oldProperty) => {
    let removingActionClassifications = []
    if (oldList && !_.isEmpty(newList)) {
      oldList.forEach(action => {
        if (!newList.map(x => x.value).includes(action)) {
          removingActionClassifications.push(action)
        }
      })
    }

    delete oldProperty.actionClassification
    const x = {
      ...oldProperty,
      actionClassification: removingActionClassifications,
    }
    if (_.isEmpty(x.actionClassification)) {
      return null
    } else {
      return x
    }
  }

  handleUpdate = () => {
    const { newAlertLevel, newActionClassification } = this.state
    const {
      resourceSrn,
      selected,
      updateChangeDetectionProperty,
      changeDetectionProperties,
    } = this.props
    const oldList = selected.actionClassification
    const keyName = selected.keyName
    const keyType = selected.actionClassification === null ? 'PROPERTY' : 'PATH'
    const alertLevel = newAlertLevel || selected.alertLevel
    const oldProperty = selected

    const newProperty = {
      keyName,
      keyType,
      alertLevel,
      actionClassification:
        exists(newActionClassification) && !_.isEmpty(newActionClassification)
          ? newActionClassification.map(x => x.value)
          : selected.actionClassification,
      __typename: 'Cdconfig',
    }

    const toRemove = this.getRemoveProperty(
      oldList,
      newActionClassification,
      oldProperty
    )

    const index = changeDetectionProperties.findIndex(
      x =>
        x.get('keyName') === selected.keyName &&
        x.get('alertLevel') === selected.alertLevel
    )

    if (index !== -1) {
      this.setState({ popup: false })
      updateChangeDetectionProperty({
        toRemove,
        resourceSrn,
        newProperty,
        oldProperty,
        index,
      })
    }
  }

  handleSelectAllActions = () => {
    if (!this.props.changeDetectionOptions.isEmpty()) {
      const newActionClassification = this.props.changeDetectionOptions
        .find(x => x.get('keyName') == this.props.selected.keyName)
        .get('actionClassification')
        .toJS()
        .map(y => ({
          label: getFriendlyActionName(y, this.props.selected.keyName),
          value: y,
        }))

      this.setState({ newActionClassification })
    }
  }

  getContents = () => {
    const { keyName, alertLevel, actionClassification } = this.props.selected
    const { changeDetectionOptions, changeDetectionProperties } = this.props
    const alertLevelList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const changeDetectionPropertiesWithKeyName = changeDetectionProperties.filter(
      x => x.get('keyName') === keyName
    )

    const propertyIndexes = changeDetectionPropertiesWithKeyName.map(x =>
      changeDetectionProperties.indexOf(x)
    )

    const optionIndex = changeDetectionOptions.findIndex(
      x => x.get('keyName') === keyName
    )
    const actionClassificationsUsed = List().merge(
      ...propertyIndexes.map(index =>
        changeDetectionProperties.getIn([index, 'actionClassification'])
      )
    )

    const actionClassificationOptions = changeDetectionOptions
      .getIn([optionIndex, 'actionClassification'])
      .toJS()
      .map(option => {
        return {
          label: getFriendlyActionName(option, keyName),
          value: option,
        }
      })

    actionClassificationOptions.forEach(option => {
      if (actionClassificationsUsed.includes(option.value)) {
        option.isDisabled = true
      } else {
        option.isDisabled = false
      }
    })

    return (
      <div style={this.style.container}>
        <div style={{ fontWeight: '400' }}>
          Edit {getFriendlyKeyName(keyName)}
        </div>

        <div style={{ fontSize: '0.9rem', margin: '0.5rem 0rem' }}>
          Alert Level
        </div>
        <div style={{ width: '95%' }}>
          <SelectBar
            styles={{
              option: provided => ({
                ...provided,
                fontSize: '0.85rem',
                fontWeight: '400',
              }),
              singleValue: provided => ({
                ...provided,
                fontSize: '0.85rem',
                fontWeight: '400',
              }),
            }}
            onChange={params => this.setState({ newAlertLevel: params.value })}
            options={alertLevelList.map(level => ({
              label: level,
              value: level,
            }))}
            value={
              exists(this.state.newAlertLevel)
                ? this.state.newAlertLevel
                : alertLevel
            }
          />
        </div>

        {exists(actionClassification) && !_.isEmpty(actionClassification) && (
          <div
            style={{ width: '95%', display: 'flex', flexDirection: 'column' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '1rem 0rem 0.5rem 0rem',
              }}
            >
              <div style={{ fontSize: '0.9rem' }}>Action Classification(s)</div>
              <TextLink
                onClick={() => this.handleSelectAllActions()}
                style={{
                  fontSize: '0.9rem',
                  cursor: 'pointer',
                  color: this.props.theme.secondary,
                }}
              >
                Select All
              </TextLink>
            </div>
            <MultiSelect
              isMulti
              components={{
                Option: props => {
                  if (props.isDisabled) {
                    return (
                      <div>
                        <components.Option {...props}>
                          <div>
                            {props.value}
                            <span
                              style={{
                                fontSize: '0.75rem',
                                marginLeft: '0.25rem',
                              }}
                            >
                              (Already Enabled)
                            </span>
                          </div>
                        </components.Option>
                      </div>
                    )
                  } else {
                    return <components.Option {...props} />
                  }
                },
              }}
              value={
                exists(this.state.newActionClassification)
                  ? this.state.newActionClassification
                  : actionClassification
                      .sort((a, b) => a.localeCompare(b))
                      .map(action => ({
                        label: getFriendlyActionName(action, keyName),
                        value: action,
                      }))
              }
              onChange={params =>
                this.setState({
                  newActionClassification: params,
                })
              }
              options={actionClassificationOptions.sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              styles={{
                option: provided => ({
                  ...provided,
                  fontSize: '0.85rem',
                  fontWeight: '400',
                }),
                multiValue: provided => {
                  return {
                    ...provided,
                    fontSize: '0.85rem',
                    fontWeight: '400',
                  }
                },
              }}
            />
          </div>
        )}
        <div
          style={{
            marginTop: '0.75rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextLink
              onClick={() => this.setState({ popup: false })}
              style={{
                fontSize: '0.9rem',
                marginRight: '0.5rem',
                cursor: 'pointer',
              }}
            >
              Cancel
            </TextLink>
            <Button
              onClick={this.handleUpdate}
              disabled={this.isDisabled()}
              style={{
                padding: '0.25rem',
                minWidth: '65px',
                fontSize: '0.8rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: this.props.theme.primary,
                borderColor: this.props.theme.primary,
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Popup
          open={this.state.popup}
          onOpen={() => this.setState({ popup: true })}
          onClose={() => this.setState({ popup: false })}
          modal
          trigger={
            <Button
              style={{
                ...this.style.button,
                ...{
                  border: 'none',
                  fontSize: '1rem',
                  color: this.props.theme.primary,
                },
              }}
            >
              <Icon
                fa
                name={'pencil-alt'}
                style={{
                  margin: `0rem 0.35rem 0rem 0rem`,
                  color: this.props.theme.primary,
                  fontSize: '0.9rem',
                }}
              />
              Edit
            </Button>
          }
          position="right top"
          closeOnDocumentClick
          contentStyle={{
            width: '20rem',
            minHeight: '200px',
            padding: '0.75rem',
            boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 3px -1px`,
            display: 'flex',
            zIndex: 100,
          }}
        >
          {this.getContents()}
        </Popup>
      </div>
    )
  }
}

EditProperty.propTypes = {
  theme: themeShape,
  changeDetectionOptions: ImmutablePropTypes.iterable.isRequired,
  resourceSrn: PropTypes.string.isRequired,
  changeDetectionProperties: ImmutablePropTypes.iterable.isRequired,
  updateChangeDetectionProperty: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
}

export default themeable(EditProperty)
