/**
 *
 * AuditNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { push } from 'connected-react-router'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { List } from 'immutable'
import color from 'color'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import TableWidget from 'components/TableWidget'
import NodeViewHeader from 'containers/NodeViewHeader'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import reducer from './reducer'
import sagas from './sagas'
import { getAuditContent } from './actions'
import { selectAuditContent, selectIsLoading } from './selectors'

export class AuditNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getAuditContent(props.nodeData.srn)

    this.styles = {
      button: {
        height: '1.85rem',
        fontSize: '0.85rem',
        border: `1px solid ${props.theme.primary}`,
        padding: '0.25rem 0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: color(props.theme.neutralDark).darken(0.6),
      },
    }
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getAudits = () => {
    return this.props.auditContent.getIn(['audits', 'items'], List()).toJS()
  }

  getGridContent = () => {
    return [
      <div key="table">
        <TableWidget
          nodeViewType="Audit"
          setUserProfileTables={this.props.setUserProfileTables}
          userProfile={this.props.userProfile}
          data={this.getAudits()}
          title="Audits Resources"
          onClickNodeView={this.onClickNodeView}
          loading={this.props.isLoading}
        />
      </div>,
    ]
  }

  getColumns = () => {
    return {
      xs: [
        {
          widthPercent: 100,
          items: ['table', 'graph'],
          heights: {
            table: 3,
            graph: 3,
          },
        },
      ],
    }
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          columns={this.getColumns()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
        />
      </Fragment>
    )
  }
}

AuditNodeViewCardLayout.propTypes = {
  auditContent: ImmutablePropTypes.map.isRequired,
  getAuditContent: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  userProfile: ImmutablePropTypes.map.isRequired,
  setUserProfileTables: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  userProfile: selectUserProfile,
  auditContent: selectAuditContent,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAuditContent,
      setUserProfileTables,
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({ key: 'auditNodeViewCardLayout', reducer })

const withSaga = injectSaga({
  key: 'auditNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl,
  themeable
)(AuditNodeViewCardLayout)
