import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import AddProperty from './AddProperty'
import DataTable from 'components/DataTable'
import { getFriendlyKeyName } from 'utils/widgetUtils'
import { exists } from 'utils/sonraiUtils'
import _ from 'lodash'
import EditProperty from './EditProperty'
import { getFriendlyActionName } from 'utils/widgetUtils'
import { ToolbarItem } from 'components/ActionToolbar'
class ManageChangeDetection extends Component {
  style = {
    table: {
      height: '50vh',
      width: '100%',
      minWidth: '500px',
    },
    propertyContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    button: {
      backgroundColor: `${this.props.theme.primary}`,
      fontSize: '0.9rem',
      color: '#fff',
      padding: '0.5rem 1rem',
    },
  }
  render() {
    const {
      data,
      selected,
      propertySelectionChanged,
      resourceSrn,
      changeDetectionOptions,
      changeDetectionProperties,
      addChangeDetectionProperties,
      handleRemoveChangeDetectionProperties,
      updateChangeDetectionProperty,
    } = this.props
    const orderedData = data
      .map(row => ({
        ...row,
        actualKeyName: row.keyName,
        keyName: getFriendlyKeyName(row.keyName),
      }))
      .sort((a, b) => (!a.keyName ? false : a.keyName.localeCompare(b.keyName)))

    return (
      <div>
        <div style={this.style.propertyContainer}>
          {exists(selected) && selected.length === 1 ? (
            <EditProperty
              resourceSrn={resourceSrn}
              changeDetectionOptions={changeDetectionOptions}
              addChangeDetectionProperties={addChangeDetectionProperties}
              changeDetectionProperties={changeDetectionProperties}
              theme={this.props.theme}
              updateChangeDetectionProperty={updateChangeDetectionProperty}
              selected={selected[0]}
            />
          ) : (
            <AddProperty
              resourceSrn={resourceSrn}
              changeDetectionOptions={changeDetectionOptions}
              addChangeDetectionProperties={addChangeDetectionProperties}
              changeDetectionProperties={changeDetectionProperties}
              theme={this.props.theme}
            />
          )}
        </div>
        <div style={this.style.table}>
          <DataTable
            actions={
              <ToolbarItem
                permissionName="edit.crmsettings"
                onClick={handleRemoveChangeDetectionProperties}
                disabled={
                  !exists(selected) || (exists(selected) && _.isEmpty(selected))
                }
                iconName="trash"
              >
                Disable
              </ToolbarItem>
            }
            style={{ width: '100%', minWidth: '600px' }}
            data={orderedData}
            rowHeight
            customColumnConfig={{
              keyName: {
                width: 280,
                pinned: 'left',
              },
              actualKeyName: {
                hide: true,
              },
              actionClassification: {
                valueFormatter: params => {
                  return exists(params.value)
                    ? params.value.map(action =>
                        getFriendlyActionName(action, params.data.keyName)
                      )
                    : '-'
                },
              },
            }}
            multiRowSelect
            hasCheckBoxes
            selectionChanged={propertySelectionChanged}
            autosize={false}
          />
        </div>
      </div>
    )
  }
}

ManageChangeDetection.propTypes = {
  theme: themeShape,
  data: PropTypes.array.isRequired,
  selected: PropTypes.array,
  resourceSrn: PropTypes.string.isRequired,
  changeDetectionOptions: ImmutablePropTypes.iterable.isRequired,
  changeDetectionProperties: ImmutablePropTypes.iterable.isRequired,
  addChangeDetectionProperties: PropTypes.func.isRequired,
  updateChangeDetectionProperty: PropTypes.func.isRequired,
  handleRemoveChangeDetectionProperties: PropTypes.func.isRequired,
  propertySelectionChanged: PropTypes.func.isRequired,
}

export default themeable(ManageChangeDetection)
