/**
 *
 * IconCount
 *
 */

import React from 'react'
import PropTypes from 'prop-types'

class IconCount extends React.PureComponent {
  render() {
    return (
      <span
        className="fa-layers-counter"
        title={this.props.title}
        style={{
          backgroundColor: this.props.color,
          fontSize: '55px',
          fontWeight: 'bold',
          right: '-0.2em',
          top: '-0.15em',
          ...this.props.style,
        }}
      >
        {this.props.text}
      </span>
    )
  }
}

IconCount.defaultProps = {
  style: {},
  color: 'red',
}

IconCount.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
}

export default IconCount
