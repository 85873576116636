import React from 'react'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export const ToolbarDivider = ({ theme }) => {
  const styles = {
    container: {
      height: '1.5em',
      borderRight: `1px solid ${theme.neutralMedium}`,
      margin: 'auto 0.7em',
    },
  }

  return <div style={styles.container} />
}

ToolbarDivider.propTypes = {
  theme: themeShape,
}
export default themeable(ToolbarDivider)
