import { all, put, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import gql from 'graphql-tag'

import { getClient } from 'apolloClient'

import {
  FETCH_CUSTOM_CLASSIFIERS,
  DELETE_CUSTOM_CLASSIFIERS,
  UPDATE_CUSTOM_CLASSIFIERS,
  CREATE_CUSTOM_CLASSIFIER,
} from './constants'
import {
  FETCH_CUSTOM_CLASSIFIERS_QUERY,
  DELETE_CUSTOM_CLASSIFIERSS_MUTATION,
  UPDATE_CUSTOM_CLASSIFIERSS_MUTATION,
  CREATE_CUSTOM_CLASSIFIERS_MUTATION,
} from './static-queries'
import {
  setCustomClassifiers,
  setCustomClassifiersError,
  deleteCustomClassifierSuccess,
  deleteCustomClassifierError,
  updateCustomClassifiersSuccess,
  updateCustomClassifiersError,
  createCustomClassifierSuccess,
  createCustomClassifierError,
} from './actions'

function* fetchCustomClassifiers() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${FETCH_CUSTOM_CLASSIFIERS_QUERY}
      `,
      variables: {
        // leave blank to grab em all
        srnList: [],
      },
    })

    const classifiers = _.get(
      results,
      ['data', 'GetCustomClassifiers', 'items'],
      false
    )

    if (classifiers) {
      yield put(setCustomClassifiers({ classifiers }))
    } else {
      const error = _.get(
        results,
        ['errors', 0, 'message'],
        'Error fetching Custom Classifiers'
      )
      yield put(setCustomClassifiersError({ error }))
      throw new Error(error)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching Custom Classifiers', e)
  }
}

function* createCustomClassifier({ payload }) {
  try {
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        ${CREATE_CUSTOM_CLASSIFIERS_MUTATION}
      `,
      variables: {
        input: payload.create,
      },
    })

    const classifier = _.get(
      results,
      ['data', 'CreateCustomClassifiers', 'items', 0],
      false
    )

    if (classifier) {
      yield put(createCustomClassifierSuccess({ classifier }))
    } else {
      const error = _.get(
        results,
        ['errors', 0, 'message'],
        'Error creating Custom Classifier'
      )
      yield put(createCustomClassifierError({ error }))
      throw new Error(error)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error creating Custom Classifier', e)
  }
}

function* deleteCustomClassifiers({ payload }) {
  try {
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        ${DELETE_CUSTOM_CLASSIFIERSS_MUTATION}
      `,
      variables: {
        srnList: payload.srnList,
      },
    })

    const deleted = _.get(
      results,
      ['data', 'DeleteCustomClassifiers', 'items'],
      false
    )

    if (deleted) {
      yield put(
        deleteCustomClassifierSuccess({ delete: deleted.map(d => d.srn) })
      )
    } else {
      const error = _.get(
        results,
        ['errors', 0, 'message'],
        'Error deleting Custom Classifiers'
      )
      yield put(
        deleteCustomClassifierError({ error, srnList: payload.srnList })
      )
      throw new Error(error)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error deleting Custom Classifiers', e)
  }
}

function* updateCustomClassifiers({ payload }) {
  try {
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        ${UPDATE_CUSTOM_CLASSIFIERSS_MUTATION}
      `,
      variables: {
        srnList: payload.srnList,
        input: payload.updated,
      },
    })

    const updated = _.get(
      results,
      ['data', 'UpdateCustomClassifiers', 'items', 0],
      false
    )

    if (updated) {
      yield put(
        updateCustomClassifiersSuccess({ updated, srnList: payload.srnList })
      )
    } else {
      const error = _.get(
        results,
        ['errors', 0, 'message'],
        'Error updating Custom Classifiers'
      )
      yield put(
        updateCustomClassifiersError({ error, srnList: payload.srnList })
      )
      throw new Error(error)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating Custom Classifiers', e)
  }
}

function* controlFrameworkDataSaga() {
  yield all([
    takeLatest(FETCH_CUSTOM_CLASSIFIERS, fetchCustomClassifiers),
    takeLatest(DELETE_CUSTOM_CLASSIFIERS, deleteCustomClassifiers),
    takeLatest(UPDATE_CUSTOM_CLASSIFIERS, updateCustomClassifiers),
    takeLatest(CREATE_CUSTOM_CLASSIFIER, createCustomClassifier),
  ])
}

export default controlFrameworkDataSaga
