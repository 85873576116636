import {
  SET_CRITICAL_RESOURCE_MONITOR_DATE,
  GET_PROPERTY_WIDGET_DATA,
  SET_PROPERTY_WIDGET_DATA,
  GET_DID_ACCESS_DATA,
  SET_DID_ACCESS_DATA,
  GET_CAN_ACCESS_DATA,
  SET_CAN_ACCESS_DATA,
  GET_ACCESSED_USING_DATA,
  SET_ACCESSED_USING_DATA,
  GET_ACTIVITY_WIDGET_DATA,
  SET_ACTIVITY_WIDGET_DATA,
  GET_SELECTED_ROW_ACTIONS,
  SET_SELECTED_ROW_ACTIONS,
  TOGGLE_ACTIONS_MODAL,
  GET_CAN_ACCESS_PATH,
  SET_CAN_ACCESS_PATH,
  GET_EP_PATHS,
  ERR_EP_PATHS,
  SET_LAST_CAN_ACCESS_JOB_TIMESTAMP,
} from './constants'
import { createAction } from 'redux-actions'

export const setCriticalResourceMonitorDate = createAction(
  SET_CRITICAL_RESOURCE_MONITOR_DATE
)

export const getPropertyWidgetData = createAction(GET_PROPERTY_WIDGET_DATA)

export const setPropertyWidgetData = createAction(SET_PROPERTY_WIDGET_DATA)

export const getDidAccessData = createAction(GET_DID_ACCESS_DATA)
export const setDidAccessData = createAction(SET_DID_ACCESS_DATA)

export const getCanAccessData = createAction(GET_CAN_ACCESS_DATA)
export const setCanAccessData = createAction(SET_CAN_ACCESS_DATA)

export const getAccessedUsingData = createAction(GET_ACCESSED_USING_DATA)
export const setAccessedUsingData = createAction(SET_ACCESSED_USING_DATA)

export const getActivityWidgetData = createAction(GET_ACTIVITY_WIDGET_DATA)
export const setActivityWidgetData = createAction(SET_ACTIVITY_WIDGET_DATA)

export const getSelectedRowActions = createAction(GET_SELECTED_ROW_ACTIONS)
export const setSelectedRowActions = createAction(SET_SELECTED_ROW_ACTIONS)
export const toggleActionsModal = createAction(TOGGLE_ACTIONS_MODAL)

export const getCanAccessPath = createAction(GET_CAN_ACCESS_PATH)
export const setCanAccessPath = createAction(SET_CAN_ACCESS_PATH)

export const getEPPaths = createAction(GET_EP_PATHS)
export const errEPPaths = createAction(ERR_EP_PATHS)

export const setLastCanAccessJobTimestamp = createAction(
  SET_LAST_CAN_ACCESS_JOB_TIMESTAMP
)
