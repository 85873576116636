/*
 * DataNodeViewCardLayout Messages
 *
 * This contains all the text for the DataNodeViewCardLayout component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.containers.DataNodeViewCardLayout.title',
    defaultMessage: 'Data Details',
  },
  activeLabel: {
    id: 'app.containers.DataNodeViewCardLayout.activeLabel',
    defaultMessage: 'Active',
  },
  typeLabel: {
    id: 'app.containers.DataNodeViewCardLayout.typeLabel',
    defaultMessage: 'Type',
  },
  accountLabel: {
    id: 'app.containers.DataNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id: 'app.containers.DataNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  lastModifiedLabel: {
    id: 'app.containers.DataNodeViewCardLayout.lastModifiedLabel',
    defaultMessage: 'Last Modified',
  },
  createdLabel: {
    id: 'app.containers.DataNodeViewCardLayout.createdLabel',
    defaultMessage: 'Created',
  },
  srnLabel: {
    id: 'app.containers.DataNodeViewCardLayout.srnLabel',
    defaultMessage: 'srn',
  },
  description: {
    id: 'app.containers.DataNodeViewCardLayout.description',
    defaultMessage: 'Description',
  },
  size: {
    id: 'app.containers.DataNodeViewCardLayout.size',
    defaultMessage: 'Size',
  },
  bytes: {
    id: 'app.containers.DataNodeViewCardLayout.bytes',
    defaultMessage: 'Bytes',
  },
  publicRead: {
    id: 'app.containers.DataNodeViewCardLayout.publicRead',
    defaultMessage: 'Public Read',
  },
  publicWrite: {
    id: 'app.containers.DataNodeViewCardLayout.publicWrite',
    defaultMessage: 'Public Write',
  },
  websiteEnabled: {
    id: 'app.containers.DataNodeViewCardLayout.websiteEnabled',
    defaultMessage: 'Website Enabled',
  },
  auditEnabled: {
    id: 'app.containers.DataNodeViewCardLayout.auditEnabled',
    defaultMessage: 'Audit Enabled',
  },
  containerLabel: {
    id: 'app.containers.DataNodeViewCardLayout.containerLabel',
    defaultMessage: 'Container',
  },
  none: {
    id: 'app.containers.DataNodeViewCardLayout.none',
    defaultMessage: 'None',
  },
  dataItem: {
    id: 'app.containers.DataNodeViewCardLayout.dataItem',
    defaultMessage: 'This Data item is not part of a container',
  },
})
