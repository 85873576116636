export const GET_SECRET_STORE_CONTENT =
  'app/SecretStoreNodeViewCardLayout/GET_SECRET_STORE_CONTENT'
export const SET_SECRET_STORE_CONTENT =
  'app/SecretStoreNodeViewCardLayout/SET_SECRET_STORE_CONTENT'
export const GET_VAULT_STORE_CONTENT =
  'app/SecretStoreNodeViewCardLayout/GET_VAULT_STORE_CONTENT'

export const SECRET_STORE_TYPES = {
  VAULT: 'HashiCorpVault',
  VAULT_AWS: 'HashiCorpVaultAWS',
}
