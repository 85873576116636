import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import ProfileIcon from 'components/ProfileIcon'
import TextLink from 'components/TextLink'
import qs from 'query-string'
import moment from 'moment'
import MarkdownDisplay from 'components/MarkdownDisplay'

class Comment extends Component {
  styles = {
    container: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: '1fr',
      gridGap: '0.15rem',
      gridTemplateAreas: '"profile-icon content"',
      padding: '0.5rem',
      borderBottom: !this.props.isLast && '1px solid #ABABAB',
      marginTop: !this.props.isFirst && '0.5rem',
    },
    content: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: ' 32px 1fr',
      gridGap: '0.25rem',
      gridTemplateAreas: '"header" "body"',
      gridArea: 'content',
    },
    body: {
      gridArea: 'body',
      paddingBottom: '0.5rem',
    },
    header: {
      gridArea: 'header',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
    profileIcon: {
      marginTop: '2px',
      gridArea: 'profile-icon',
    },
    timestamp: {
      color: '#ABABAB',
      fontWeight: '400',
      fontSize: '0.85rem',
    },
  }

  render() {
    return (
      <div style={this.styles.container}>
        <div style={this.styles.content}>
          <div style={this.styles.header}>
            <div>
              <TextLink
                to={{
                  pathname: `/App/UserManagement/User`,
                  search: qs.stringify({
                    srn: this.props.user.get('srn'),
                  }),
                }}
                color="primary"
              >
                {this.props.user.get('name')}
              </TextLink>
            </div>

            <div style={this.styles.timestamp}>
              {moment(this.props.comment.get('createdDate')).fromNow()}
            </div>
          </div>
          <div style={this.styles.body}>
            <MarkdownDisplay content={this.props.comment.get('body')} />
          </div>
        </div>
        <div style={this.styles.profileIcon}>
          {' '}
          <ProfileIcon img={this.props.user.get('avatarUrl')} />
        </div>
      </div>
    )
  }
}

Comment.propTypes = {
  comment: ImmutablePropTypes.iterable.isRequired,
  user: ImmutablePropTypes.iterable.isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

export default Comment
