import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import { Input } from 'reactstrap'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TemplateFieldCheckbox from './TemplateFieldCheckbox'
import TemplateFieldSelect from './TemplateFieldSelect'

import messages from '../messages'

export default function TemplateField(props) {
  switch (props.fieldDefinition.type) {
    case 'text':
      return (
        <Input
          name={props.fieldDefinition.name}
          value={(props.fieldState ?? Map()).get('value')}
          onChange={event => {
            props.setTicketTemplateFieldValue({
              value: event.target.value,
              field: props.fieldDefinition,
            })
          }}
        />
      )
    case 'select':
      return <TemplateFieldSelect {...props} />
    case 'checkbox':
      return <TemplateFieldCheckbox {...props} />
    default:
      return (
        <div style={{ color: 'red' }}>
          <DynamicFormattedMessage {...messages.invalidTemplate} />
        </div>
      )
  }
}

TemplateField.propTypes = {
  fetchTicketTemplateFieldSelect: PropTypes.func.isRequired,
  setTicketTemplateFieldValue: PropTypes.func.isRequired,
  fieldDefinition: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      required: PropTypes.bool,
      type: PropTypes.string.isRequired,
      select: PropTypes.shape({
        labelFields: PropTypes.arrayOf(PropTypes.string),
        valueField: PropTypes.string,
        search: PropTypes.string,
        path: PropTypes.arrayOf(PropTypes.string),
      }),
    })
  ),
  fieldState: ImmutablePropTypes.contains({
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    select: ImmutablePropTypes.contains({
      loading: PropTypes.bool,
      error: PropTypes.bool,
      data: ImmutablePropTypes.listOf(PropTypes.map),
    }),
  }),
}
