import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import uuid from 'uuid/v4'
import { selectSwimlanes } from 'containers/SonraiData/selectors'
import {
  getSwimlaneNameFromSrn,
  getSwimlaneSrnFromResourceId,
} from 'utils/sonraiUtils'
import CopyToClipboard from 'components/CopyToClipboard'
import TextLink from 'components/TextLink'
import Hoverable from 'components/Hoverable'
import qs from 'query-string'

class SwimlaneDisplay extends React.PureComponent {
  constructor(props) {
    super(props)
    this.uuid = uuid()

    this.styles = {
      textStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      clipboardButton: {
        marginBottom: '5px',
        width: '32px',
        height: '32px',
      },
    }
  }

  getSwimlaneTooltip = () => {
    if (this.props.hasTooltip) {
      return (
        <ReactTooltip
          id={this.uuid}
          place="bottom"
          effect="solid"
          clickable={true}
        >
          {this.props.srn ||
            getSwimlaneSrnFromResourceId(this.props.resourceId)}
        </ReactTooltip>
      )
    }
  }

  render() {
    const { resourceId, swimlanes, hasLink, hasCopy } = this.props
    const srn = this.props.srn || getSwimlaneSrnFromResourceId(resourceId)
    const name = getSwimlaneNameFromSrn(srn, swimlanes)

    if (hasLink && srn) {
      return (
        <div style={{ display: 'inline-block' }}>
          <Hoverable
            hoverStyle={{ cursor: 'default' }}
            renderContent={({ hovered }) => (
              <div style={this.styles.textStyle}>
                <TextLink
                  to={{
                    pathname: '/App/SwimlaneDetails',
                    search: qs.stringify({
                      srn,
                    }),
                  }}
                >
                  {name}
                </TextLink>
                {hasCopy &&
                  (hovered ? (
                    <div style={this.styles.clipboardButton}>
                      <CopyToClipboard value={name} />
                    </div>
                  ) : (
                    <div style={this.styles.clipboardButton} />
                  ))}
              </div>
            )}
          />
          {this.getSwimlaneTooltip()}
        </div>
      )
    }

    return (
      <div>
        <Hoverable
          hoverStyle={{ cursor: 'default' }}
          renderContent={({ hovered }) => (
            <div style={this.styles.textStyle}>
              {name}
              {hasCopy &&
                (hovered ? (
                  <div style={this.styles.clipboardButton}>
                    <CopyToClipboard value={srn} />
                  </div>
                ) : (
                  <div style={this.styles.clipboardButton} />
                ))}
            </div>
          )}
        />
        {this.getSwimlaneTooltip()}
      </div>
    )
  }
}

SwimlaneDisplay.defaultProps = {
  static: true,
}

SwimlaneDisplay.propTypes = {
  srn: PropTypes.string.isRequired,
  resourceId: PropTypes.string,
  hasLink: PropTypes.bool,
  hasCopy: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
}

const mapStateToProps = createStructuredSelector({
  swimlanes: selectSwimlanes,
})

const withConnect = connect(mapStateToProps)

export default withConnect(SwimlaneDisplay)
