import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { fromJS, isImmutable, List, Map } from 'immutable'
import _ from 'lodash'

import EmptyText from 'components/EmptyText'
import BarLoadingAnimation from 'components/BarLoadingAnimation'
import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import PathWidget from 'components/PathWidget'
import PolicySearchConditions from 'components/PolicySearchConditions'

import { flattenData } from 'utils/sonraiUtils'

import messages from '../messages'

function getPolicyEvidence(props) {
  const { ticket } = props
  let policyEvidence = props.useLegacyPolicyEvidence
    ? props.policyEvidenceLegacy.get('data') ?? Map()
    : ticket.getIn(['evidence', 'policyEvidence'], null)

  if (policyEvidence == null) {
    // eslint-disable-next-line no-console
    console.warn(`ticket ${ticket.get('srn')} did not have policyEvidence`)
    return null
  }

  if (_.isString(policyEvidence)) {
    try {
      return fromJS(JSON.parse(policyEvidence))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Could not parse policy evidence ${policyEvidence}`, e)
      return null
    }
  }

  return policyEvidence
}

/**
 * Flatten the root item and then try to reposition the EP columns
 * after the fied they were defined on.
 *
 * Note: this'll break if the EP fields were defined on more than one
 * field until we fix CRC-1101
 */
const flattenRootItem = rootItem => {
  if (!rootItem) {
    return
  }

  const flattenedRootItems = flattenData({
    items: [rootItem.toJS()],
  })
  try {
    const actualItem = rootItem.toJS()

    // find which field has the effective permissions
    let epField = null
    if (actualItem.hasEffectivePermissions) {
      epField = actualItem
    } else {
      let children = Object.values(actualItem)
        .filter(value => _.isObject(value))
        .filter(value => value.items)
        .map(value => value.items)

      while (children.length > 0 && epField == null) {
        const temp = []
        for (let child of children) {
          if (child.hasEffectivePermissions) {
            epField = child
            break
          } else {
            let innerChildren = Object.values(child)
              .filter(value => _.isObject(value))
              .filter(value => value.items)
              .map(value => value.items)
            innerChildren.forEach(ic => temp.push(ic))
          }
        }
        children = temp
      }
    }

    if (!epField) {
      return flattenedRootItems
    }

    const epParentType = epField.__typename
    if (!epParentType) {
      //eslint-disable-next-line no-console
      console.warn('epField  did not have typename ', epField)
      return flattenedRootItems
    }

    // want to insert the EP keys right after the parent keys
    const row1 = flattenedRootItems[0]
    if (!row1) {
      return flattenedRootItems // if there's no rows don't do anything
    }

    const allKeys = Object.keys(row1)
    const parentKeys = allKeys
      .filter(k => k.startsWith(epParentType))
      .filter(k => !k.endsWith('__typename'))
    const lastParentKey = _.last(parentKeys)
    const epKeys = allKeys.filter(k => k.startsWith('EffectivePermission'))

    // now insert them
    const keys = allKeys.filter(k => !k.startsWith('EffectivePermission'))
    const insertAtBase = keys.indexOf(lastParentKey)
    for (var i = 0; i < epKeys.length; i++) {
      keys.splice(insertAtBase + i, 0, epKeys[i])
    }

    // now we have a list of the fields in correct order make choose fields
    return flattenedRootItems.map(item => _.pick(item, keys))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('an error happened tryng to reposition the ep columns', e)
    return flattenedRootItems
  }
}

const getAnalyticResultColumns = data => {
  //The analytics data result can have any properties with any shape of values.
  //We will consdier any non-string result to be something that is worth viewing as JSON.
  //Find the non-primitive values from a sample row, and add to the list of jsonCols
  const sampleRow = _.get(data, [0])
  const objectColumns = []

  if (sampleRow) {
    Object.keys(sampleRow).forEach(key => {
      const value = sampleRow[key]
      if (typeof value === 'object' && value !== null) {
        objectColumns.push(key)
      }
    })
  }

  return objectColumns
}

export default function TicketPolicyEvidence(props) {
  if (props.useLegacyPolicyEvidence) {
    if (props.policyEvidenceLegacy.get('loading')) {
      return <BarLoadingAnimation />
    }

    if (props.policyEvidenceLegacy.get('error')) {
      return (
        <DynamicFormattedMessage {...messages.poilcyEvidenceLegacyLoadError} />
      )
    }
  }

  let policyEvidence = getPolicyEvidence(props)

  let hasSubValues = (policyEvidence || Map()).find(
    prop => isImmutable(prop) && !(prop.get('items') || List()).isEmpty()
  )
  const search = props.policy.getIn(['contains', 'items', 0], Map())

  const searchWithFields = props.searches.get(search.get('sid'), Map())

  const advancedSearch = props.advancedSearches.find(
    s => s.get('srn') === search.get('srn'),
    Map()
  )

  let data = flattenRootItem(policyEvidence)
  let isAnalyticQuery = false

  if (advancedSearch) {
    if (advancedSearch.get('query', '').includes('AnalyticsResults')) {
      isAnalyticQuery = true
      //It's using the special analytics type query, in which case we do not want to flatten the data.
      data = policyEvidence.get('data', List()).toJS()
    }
  } else {
    const searchFields = searchWithFields.getIn(['query', 'fields'], Map())
    const hasAnalyticField = searchFields.find(
      f => f.getIn(['definition', 'type', 'name']) === 'AnalyticsResults'
    )

    //AnalyticsResults dont have any relations so they will be the only card in a search. So we are safe to not flatten the results
    if (hasAnalyticField) {
      isAnalyticQuery = true
      data = policyEvidence.get('data', List()).toJS()
    }
  }

  return (
    <div>
      {search.isEmpty() ? (
        <EmptyText>
          Some details could not be displayed because this policy does not have
          a search attached.
        </EmptyText>
      ) : (
        <Fragment>
          <div>
            <PolicySearchConditions
              search={searchWithFields}
              allTriggerProps={false}
              results={policyEvidence}
              alertTime={props.ticket.get('lastSeen')}
            />
          </div>
          {hasSubValues && (
            <PathWidget
              style={{
                height: '300px',
                border: '1px solid #eee',
                margin: '1em 0',
                padding: '0.5em',
              }}
              data={policyEvidence}
            />
          )}
        </Fragment>
      )}

      <div
        style={{
          padding: '0.5em',
          margin: '1em 0',
        }}
      >
        <DataTable
          fitAllRows
          pageSize={3}
          data={data}
          exportFileName={props.policy.get('title')}
          jsonCols={isAnalyticQuery ? getAnalyticResultColumns(data) : []}
        />
      </div>
    </div>
  )
}

TicketPolicyEvidence.propTypes = {
  policy: ImmutablePropTypes.map,
  searches: ImmutablePropTypes.map,
  advancedSearches: ImmutablePropTypes.list,
  ticket: ImmutablePropTypes.contains({
    srn: PropTypes.string.isRequired,
    evidence: ImmutablePropTypes.contains({}),
  }),
  policyEvidenceLegacy: ImmutablePropTypes.map,
  useLegacyPolicyEvidence: PropTypes.bool,
  policyPath: ImmutablePropTypes.map,
}
