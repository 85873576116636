import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import { List } from 'immutable'
import _ from 'lodash'
import { getClient } from 'apolloClient'

import {
  assignTicketEscalationError,
  fetchTicketTemplatesError,
  fetchTicketTemplatesSucces,
  fetchTicketTemplateFieldSelectError,
  fetchTicketTemplateFieldSelectSuccess,
  saveCustomTicketError,
  saveCustomTicketSucces,
  updateCustomTicketError,
  updateCustomTicketSuccess,
} from './actions'

import {
  SAVE_CUSTOM_TICKET,
  FETCH_TICKET_TEMPLATES,
  FETCH_TICKET_TEMPLATE_FIELD_SELECT,
  UPDATE_CUSTOM_TICKET,
} from './constants'

import {
  ASSIGN_TICKET_ESCALATION_MUTATION,
  REMOVE_TICKET_ESCALTION_MUTATION,
  FETCH_TICKET_TEMPLATES_QUERY,
  CREATE_TICKET_MUTATION,
  UPDATE_TICKET_MUTATION,
} from './queries'

import { getEscalations } from 'containers/EscalationData/actions'
import { selectEscalations } from 'containers/EscalationData/selectors'

import { getSelectOptionsObjects } from './utils'

function* fetchTicketTemplates() {
  try {
    const client = yield getClient()
    const result = yield client.query({ query: FETCH_TICKET_TEMPLATES_QUERY })
    const path = ['data', 'TicketTemplates', 'items']
    const data = _.get(result, path, null)
    if (data == null) {
      throw new Error(`no data at path ${path.join('/')}`)
    }
    yield put(fetchTicketTemplatesSucces({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`an error happened fetching ticket templates`, e)
    yield put(fetchTicketTemplatesError())
  }
}

function* fetchTicketTemplateSelect({ payload: { field } }) {
  try {
    const client = yield getClient()
    const result = yield client.query({
      query: gql`
        ${field.select.search}
      `,
    })
    if (result.data === null) {
      throw new Error('no data')
    }
    const data = getSelectOptionsObjects(result, field)
    yield put(fetchTicketTemplateFieldSelectSuccess({ field, data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `error happen fetching options field ${JSON.stringify(field)})`,
      e
    )
    yield put(fetchTicketTemplateFieldSelectError({ field }))
  }
}

/**
 * Assign the ticketto the escaltion scheme
 *
 * @param {String} ticket ticketSrn of ticket
 * @param {String} escalationSchemeSrn srn of escalation schema
 */
function* assignTicketEscalationSchema({ escalationSchemeSrn, ticketSrn }) {
  try {
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: ASSIGN_TICKET_ESCALATION_MUTATION,
      variables: { escalationSchemeSrn, ticketSrn },
    })
    const path = ['data', 'CreateEscalationFilter', 'srn']
    const data = _.get(result, path, null)
    if (data === null) {
      throw new Error(`no data for path ${path.join('/')}`)
    }
    yield put(getEscalations())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`an error occurred assigning escalation to ticket`, e)
    yield put(assignTicketEscalationError())
  }
}

/**
 * remove the ticket from the escalation schema
 *
 * @param {String} ticket ticketSrn of ticket
 * @param {Immutable.Map} escalationScheme escalation scheme ticket is assigned to
 */
function* removeCurrentEscalation({ escalationScheme, ticketSrn }) {
  const currentFilter = escalationScheme.get('filters').find(filter => {
    return filter.get('ticketKey') === ticketSrn
  })
  if (!currentFilter) {
    // eslint-disable-next-line no-console
    console.warn(
      `cannot remove escalationScheme from ticket ${ticketSrn}`,
      escalationScheme
    )
    return
  }

  try {
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: REMOVE_TICKET_ESCALTION_MUTATION,
      variables: { filterSrn: currentFilter.get('srn') },
    })
    const path = ['data', 'DeleteEscalationFilter', 'srn']
    const data = _.get(result, path, null)
    if (data === null) {
      throw new Error(`no result at path ${path.join('/')}`)
    }
    yield put(getEscalations())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`an error occurred removing escalation to ticket`, e)
    yield put(assignTicketEscalationError())
  }
}

function* saveCustomTicket({ payload }) {
  try {
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: CREATE_TICKET_MUTATION,
      variables: { ticket: payload.ticket },
    })
    const path = ['data', 'CreateTicket']
    const newTicket = _.get(result, path, null)
    if (newTicket === null) {
      throw new Error(`no data for path ${path}`)
    }

    if (payload.escalationSchemeSrn) {
      yield assignTicketEscalationSchema({
        escalationSchemeSrn: payload.escalationSchemeSrn,
        ticketSrn: newTicket.srn,
      })
    }
    yield put(saveCustomTicketSucces({ data: newTicket }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happen saving custom ticket: ', e)
    yield put(saveCustomTicketError())
  }
}

function* updateCustomTicket({ payload }) {
  try {
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: UPDATE_TICKET_MUTATION,
      variables: { ticket: payload.ticket },
    })
    const path = ['data', 'UpdateTicket']
    const data = _.get(result, path, null)
    if (data === null) {
      throw new Error(`no data for path ${path}`)
    }

    // find currently assigned escalation
    let currentEscalation = undefined
    const escalations = yield select(selectEscalations)
    if (!escalations.isEmpty()) {
      // find previously assigned escalation
      currentEscalation = escalations.valueSeq().find(escalation => {
        return (
          undefined !==
          (escalation.get('filters') ?? List()).find(filter => {
            return filter.get('ticketKey') === payload.ticket.ticketSrn
          })
        )
      })
    }

    if (payload.escalationSchemeSrn) {
      let alreadyAssigned = false // is ticket already assigned to scheme
      if (currentEscalation) {
        if (currentEscalation.get('srn') !== payload.escalationSchemeSrn) {
          // we're assigned to a different escalation, so remove assignment
          yield removeCurrentEscalation({
            escalationScheme: currentEscalation,
            ticketSrn: payload.ticket.ticketSrn,
          })
        } else {
          // already assigned to this escalation, no need to reassign
          alreadyAssigned = true
        }
      }
      if (!alreadyAssigned) {
        // assign an escaltion scheme to the ticket
        yield assignTicketEscalationSchema({
          escalationSchemeSrn: payload.escalationSchemeSrn,
          ticketSrn: payload.ticket.ticketSrn,
        })
      }
    }
    // handle no escalation assginment:
    else {
      if (currentEscalation) {
        // remove current escalation
        yield removeCurrentEscalation({
          escalationScheme: currentEscalation,
          ticketSrn: payload.ticket.ticketSrn,
        })
      }
    }
    yield put(updateCustomTicketSuccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happen saving custom ticket: ', e)
    yield put(updateCustomTicketError())
  }
}

export default function* sagas() {
  yield all([
    takeLatest(SAVE_CUSTOM_TICKET, saveCustomTicket),
    takeLatest(FETCH_TICKET_TEMPLATES, fetchTicketTemplates),
    takeEvery(FETCH_TICKET_TEMPLATE_FIELD_SELECT, fetchTicketTemplateSelect),
    takeLatest(UPDATE_CUSTOM_TICKET, updateCustomTicket),
  ])
}
