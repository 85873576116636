/**
 *
 * DateProperty
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class DateProperty extends React.Component {
  getValue = () => {
    return this.props.value
      ? moment(this.props.value).format('MMMM Do YYYY, h:mm:ss a')
      : '-'
  }

  render() {
    return (
      <Fragment>
        <div>{this.props.label}</div>
        <div>{this.getValue()}</div>
      </Fragment>
    )
  }
}

DateProperty.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

export default DateProperty
