/**
 *
 * SonraiPage
 *
 */

import React from 'react'
import PropTypes from 'prop-types'

import logo from 'assets/logo.png'
import vidsrcWEBM from 'assets/clouds1.webm'
import poster from 'assets/poster.png'

import styles from './styles'

class SonraiPage extends React.PureComponent {
  render() {
    return (
      <div id="wrapper" style={styles.wrapper}>
        {this.props.videoBackground && (
          <video
            style={styles.center}
            muted
            autoPlay
            poster={poster}
            id="bgvid"
          >
            <source src={vidsrcWEBM} type="video/webm" />
          </video>
        )}

        <div
          id="gradient"
          style={Object.assign({}, styles.center, styles.gradient)}
        />

        <div id="header" style={styles.header}>
          <img src={logo} style={styles.logo} alt="Logo" />
        </div>

        {this.props.children}
      </div>
    )
  }
}

SonraiPage.propTypes = {
  children: PropTypes.node,
  videoBackground: PropTypes.bool,
}

export default SonraiPage
export SonraiPageContent from './SonraiPageContent'
export SonraiPageFooter from './SonraiPageFooter'
