import { defineMessages } from 'react-intl'

export default defineMessages({
  policyTitleLabel: {
    id: 'app.containers.PolicyEdit.policyTitleLabel',
    defaultMessage: 'Title',
  },
  policyDescriptionLabel: {
    id: 'app.containers.PolicyEdit.policyDescriptionLabel',
    defaultMessage: 'Description',
  },
  policySearchLabel: {
    id: 'app.containers.PolicyEdit.policySearchLabel',
    defaultMessage: 'Search',
  },
  policyAlertingLevelLabel: {
    id: 'app.containers.PolicyEdit.policyAlertingLevelLabel',
    defaultMessage: 'Alert Level',
  },
  policyLastTriggeredLabel: {
    id: 'app.containers.PolicyEdit.policyLastTriggeredLabel',
    defaultMessage: 'Last Run',
  },
  policyFindingsLabel: {
    id: 'app.containers.PolicyEdit.policyFindingsLabel',
    defaultMessage: 'Findings',
  },
  cancelButton: {
    id: 'app.containers.PolicyEdit.cancelButton',
    defaultMessage: 'Cancel',
  },
  createButton: {
    id: 'app.containers.PolicyEdit.createButton',
    defaultMessage: 'Create',
  },
  addButton: {
    id: 'app.containers.PolicyEdit.addButton',
    defaultMessage: 'Add',
  },
  saveEditButton: {
    id: 'app.containers.PolicyEdit.saveEditButton',
    defaultMessage: 'Save',
  },
  policyLabel: {
    id: 'app.containers.PolicyEdit.policyLabel',
    defaultMessage: 'Policy',
  },
  evalTypeLabel: {
    id: 'app.containers.PolicyEdit.evalTypeLabel',
    defaultMessage: 'Trigger',
  },
  remTypeLabel: {
    id: 'app.containers.PolicyEdit.remTypeLabel',
    defaultMessage: 'Remediation Type',
  },
  evalTypeCountLabel: {
    id: 'app.containers.PolicyEdit.evalTypeCountLabel',
    defaultMessage: 'Count',
  },
  evalOpGTLabel: {
    id: 'app.containers.PolicyEdit.evalOpGTLabel',
    defaultMessage: 'Greater Than',
  },
  evalOpLTLabel: {
    id: 'app.containers.PolicyEdit.evalOpLTLabel',
    defaultMessage: 'Less Than',
  },
  evalOpEQLabel: {
    id: 'app.containers.PolicyEdit.evalOpEQLabel',
    defaultMessage: 'Equal To',
  },
  editPolicyHeader: {
    id: 'app.containers.PolicyEdit.editPolicyHeader',
    defaultMessage: 'Edit Policy',
  },
})
