/**
 *
 * NodeViewLayout
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Responsive } from 'react-grid-layout'
import SizeMe from 'components/SizeMe'
const ResponsiveGridLayout = SizeMe(Responsive)

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

const styles = {
  grid: {
    overflow: 'auto',
    padding: '0.5em',
  },
}

export class NodeViewBody extends React.Component {
  render() {
    return (
      <div style={styles.grid}>
        <ResponsiveGridLayout
          breakpoints={{ lg: 1390, md: 1153, sm: 916, xs: 679 }}
          className="layout"
          cols={this.props.cols}
          rowHeight={180}
          margin={[15, 15]}
          measureBeforeMount={true}
          useCSSTransforms={false}
          layouts={this.props.layouts}
          isDraggable={false}
          isResizable={false}
        >
          {this.props.children}
        </ResponsiveGridLayout>
      </div>
    )
  }
}

const layoutShape = PropTypes.shape({
  i: PropTypes.string,
  w: PropTypes.number,
  h: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  static: PropTypes.bool,
})

NodeViewBody.defaultProps = {
  cols: { lg: 8, md: 7, sm: 6, xs: 4 },
}

NodeViewBody.propTypes = {
  children: PropTypes.node,
  layouts: PropTypes.shape({
    lg: PropTypes.arrayOf(layoutShape),
    md: PropTypes.arrayOf(layoutShape),
    sm: PropTypes.arrayOf(layoutShape),
    xs: PropTypes.arrayOf(layoutShape),
  }),
  cols: PropTypes.object,
}

export default NodeViewBody
