import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import uuid from 'uuid/v4'
import {
  selectAccountsBySrn,
  selectAccountsByAccountId,
} from 'containers/SonraiData/selectors'
import CloudBadge from 'components/CloudBadge'
import {
  getAccountIdFromAccountSrn,
  getNodeViewPushParams,
  getAccountFromSrn,
} from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import CopyToClipboard from 'components/CopyToClipboard'
import TextLink from 'components/TextLink'
import Hoverable from 'components/Hoverable'

class CloudAccount extends React.PureComponent {
  constructor(props) {
    super(props)
    this.uuid = uuid()

    this.styles = {
      textStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      clipboardButton: {
        marginBottom: '5px',
        width: '32px',
        height: '32px',
      },
    }
  }

  getCloudType = () => {
    const { accountSrn, accountId, accountsByAccountId } = this.props

    let srn = accountSrn

    if (!srn) {
      srn = accountId ? accountsByAccountId.getIn([accountId, 'srn']) : ''
    }

    if (srn && srn.includes(':')) {
      return srn.split(':')[1]
    }

    return ''
  }

  getDisplayName = () => {
    const {
      accountSrn,
      accountId,
      accountsByAccountId,
      accountsBySrn,
      hasAccountNumber,
    } = this.props
    const account = accountSrn
      ? accountsBySrn.get(accountSrn)
      : accountsByAccountId.get(accountId)

    if (account) {
      const friendlyName = account.get('friendlyName')
      const name = account.get('name')
      const id = getAccountIdFromAccountSrn(accountSrn)
      const display = friendlyName || name || id || this.props.accountId
      if (hasAccountNumber && display !== id) {
        return `${display} (${id})`
      }
      return display
    }

    return this.props.accountId || '-'
  }

  getContent = () => {
    return (
      <div
        data-tip
        data-for={this.uuid}
        style={{ display: 'inline-block', ...this.props.style }}
      >
        <CloudBadge
          type={this.getCloudType()}
          style={{ marginRight: '0.3em', fontSize: '1.2em' }}
        />
        {this.getDisplayName()}
      </div>
    )
  }

  getAccountTooltip = () => {
    if (this.props.hasTooltip) {
      const { accountSrn, accountId, accountsByAccountId } = this.props
      const nodeId = accountSrn || accountsByAccountId.getIn([accountId, 'srn'])
      return (
        <ReactTooltip
          id={this.uuid}
          place="bottom"
          effect="solid"
          clickable={true}
        >
          {getAccountFromSrn(nodeId)}
        </ReactTooltip>
      )
    }
  }

  render() {
    const { accountSrn, accountId, accountsByAccountId } = this.props
    const nodeId = accountSrn || accountsByAccountId.getIn([accountId, 'srn'])

    if (this.props.hasLink && nodeId) {
      return (
        <div style={{ display: 'inline-block' }}>
          <Hoverable
            hoverStyle={{ cursor: 'default' }}
            renderContent={({ hovered }) => (
              <div style={this.styles.textStyle}>
                <TextLink
                  to={getNodeViewPushParams(nodeId, getTypeFromSrn(nodeId))}
                >
                  {this.getContent()}
                </TextLink>
                {this.props.hasCopy &&
                  (hovered ? (
                    <div style={this.styles.clipboardButton}>
                      <CopyToClipboard value={nodeId} />
                    </div>
                  ) : (
                    <div style={this.styles.clipboardButton} />
                  ))}
              </div>
            )}
          />
          {this.getAccountTooltip()}
        </div>
      )
    }

    if (this.props.static) {
      return this.getContent()
    }

    return (
      <div>
        <Hoverable
          hoverStyle={{ cursor: 'default' }}
          renderContent={({ hovered }) => (
            <div style={this.styles.textStyle}>
              {this.getContent()}
              {this.props.hasCopy &&
                (hovered ? (
                  <div style={this.styles.clipboardButton}>
                    <CopyToClipboard value={nodeId} />
                  </div>
                ) : (
                  <div style={this.styles.clipboardButton} />
                ))}
            </div>
          )}
        />
        {this.getAccountTooltip()}
      </div>
    )
  }
}

CloudAccount.defaultProps = {
  static: true,
}

CloudAccount.propTypes = {
  accountSrn: PropTypes.string,
  static: PropTypes.bool,
  hasLink: PropTypes.bool,
  accountsBySrn: ImmutablePropTypes.map.isRequired,
  accountsByAccountId: ImmutablePropTypes.map.isRequired,
  style: PropTypes.object,
  accountId: PropTypes.string,
  hasCopy: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  hasAccountNumber: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  accountsBySrn: selectAccountsBySrn,
  accountsByAccountId: selectAccountsByAccountId,
})

const withConnect = connect(mapStateToProps)

export default withConnect(CloudAccount)
