import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CopyToClipboard from 'components/CopyToClipboard'
import Hoverable from 'components/Hoverable'
import TextLink from 'components/TextLink'
import NodeViewMiniPopout from 'containers/NodeViewMiniPopout'

const styles = {
  clipboardButton: {
    float: 'right',
    position: 'relative',
    top: '-8px',
  },
}

export default class NodeViewDetailPublicKey extends Component {
  constructor(props) {
    super(props)
    this.state = {
      miniNodeViewOpen: false,
      miniNodeViewNodeId: '',
    }
  }

  closeMiniNodeView = () => {
    this.setState({
      miniNodeViewOpen: false,
      miniNodeViewNodeId: '',
    })
  }

  openMiniNodeView = nodeId => {
    this.setState({
      miniNodeViewOpen: true,
      miniNodeViewNodeId: nodeId,
    })
  }

  render() {
    return (
      <div>
        {this.props.value.length > 0 ? (
          this.props.value.map((publicKey, index) => (
            <Hoverable
              key={`publicKey=${index}`}
              renderContent={({ hovered }) => (
                <div>
                  <TextLink
                    color="primary"
                    onClick={() => this.openMiniNodeView(publicKey.srn)}
                  >
                    {publicKey.name}
                    {hovered ? (
                      <div style={styles.clipboardButton}>
                        <CopyToClipboard value={publicKey.name} />
                      </div>
                    ) : (
                      <div style={styles.clipboardButton} />
                    )}
                  </TextLink>
                </div>
              )}
            />
          ))
        ) : (
          <div>-</div>
        )}
        <NodeViewMiniPopout
          open={this.state.miniNodeViewOpen}
          nodeId={this.state.miniNodeViewNodeId}
          handleClose={this.closeMiniNodeView}
        />
      </div>
    )
  }
}

NodeViewDetailPublicKey.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
}
