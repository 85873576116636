import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectGroupNodeViewCardLayoutDomain = state =>
  state.get('groupNodeViewCardLayout', Map())

export const selectGroupContent = createSelector(
  selectGroupNodeViewCardLayoutDomain,
  state => state.get('groupContent') || Map()
)

export const selectIsLoading = createSelector(
  selectGroupNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
