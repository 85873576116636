/**
 *
 * Checkbox
 *
 */

import React from 'react'
import PropTypes from 'prop-types'

import Hoverable from 'components/Hoverable'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

import './checkbox.css'
class Checkbox extends React.PureComponent {
  render() {
    const styles = {
      base: {
        height: '22px',
        width: '22px',
        overflow: 'visible',
      },
      wrapper: {
        cursor: 'pointer',
        position: 'relative',
        top: '-13px',
        left: '-13px',
        width: '48px',
        height: '48px',
        borderRadius: '50%',
        transition: 'background 0.2s ease',
      },
      circle: {
        background: 'rgba(34, 50, 84, 0.03)',
      },
      svg: {
        width: '22px',
        height: '22px',
        position: 'relative',
        top: '12px',
        left: '12px',
        fill: 'none',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        stroke: this.props.checked
          ? this.props.theme.primary
          : this.props.theme.secondary,
        strokeWidth: 1.5,
      },
    }

    return (
      <div style={styles.base}>
        <Hoverable style={styles.wrapper} hoverStyle={styles.circle}>
          <div onClick={() => this.props.onChange(this.props.value)}>
            <input
              checked={this.props.checked}
              type="checkbox"
              style={{ display: 'none' }}
              onChange={() => {}}
            />
            <label>
              <svg
                style={styles.svg}
                viewBox="0 0 18 18"
                transform={`scale(0.8)`}
              >
                <path
                  className={
                    this.props.checked
                      ? 'checkbox-animate-in'
                      : 'checkbox-check-hidden'
                  }
                  d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
                />
                <polyline
                  className={
                    this.props.checked
                      ? 'checkbox-animate-away'
                      : 'checkbox-box-visible'
                  }
                  points="1 9 7 14 15 4"
                />
              </svg>
            </label>
          </div>
        </Hoverable>
      </div>
    )
  }
}

Checkbox.defaultProps = {
  scale: 1,
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  theme: themeShape,
  value: PropTypes.bool,
}

export default themeable(Checkbox)
export { default as PlainCheckbox } from './PlainCheckbox'
