import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import BorderedCard from 'components/BorderedCard'
import styles from './styles'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

export class Card extends React.PureComponent {
  state = {
    hover: false,
  }

  onHover = () => {
    this.setState({
      hover: true,
    })
  }

  onLeave = () => {
    this.setState({
      hover: false,
    })
  }

  getCard = () => {
    if (this.props.loading) {
      let toolbar = null
      React.Children.forEach(this.props.children, child => {
        if (child && child.type.name === 'Toolbar') {
          toolbar = child
        }
      })
      return (
        <span className="card-content-loading" style={styles.loading}>
          {toolbar}
          <SquareLoadingAnimation />
          <span style={styles.loadingTitle}>{this.props.title}</span>
        </span>
      )
    }

    return React.Children.map(this.props.children, child => {
      if (child && typeof child.type === 'function') {
        return React.cloneElement(child, { hovered: this.state.hover })
      } else {
        return child
      }
    })
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{ ...styles.container, ...this.props.style }}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onLeave}
      >
        <BorderedCard
          style={{ ...styles.borderedCard, ...this.props.containerStyle }}
        >
          {this.getCard()}
        </BorderedCard>
      </div>
    )
  }
}

Card.defaultProps = {
  style: {},
  containerStyle: {},
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  containerStyle: PropTypes.object,
  style: PropTypes.object,
  loading: PropTypes.bool,
  title: PropTypes.node,
}

export default compose(injectIntl)(Card)

export { default as TopTitle } from './TopTitle' // eslint-disable-line
export { default as BottomTitle } from './BottomTitle' // eslint-disable-line
export { default as CardBody } from './CardBody' // eslint-disable-line
export { default as Toolbar } from './Toolbar' // eslint-disable-line
