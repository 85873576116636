import _ from 'lodash'

let authDB
export function getAuthDB() {
  return authDB
}

export function setAuthDB(_it_) {
  authDB = _it_
}

export const MAX_STORAGE_SIZE = 1_000_000 // ~1MB

export function cleanupOldAuthDBs() {
  const start = new Date().getTime()
  const authDB = getAuthDB()
  if (!authDB) {
    return
  }

  const allDBs = authDB.getAllDBs()

  const storageSize = {}
  for (let db of allDBs) {
    const key = authDB.makeLocalStorageKey(db.tab)
    const dbRaw = localStorage.getItem(key)
    storageSize[key] = dbRaw.length
  }

  let totalSize = Object.values(storageSize).reduce(
    (acc, curr) => acc + curr,
    0
  )

  let cleanupCount = 0
  if (totalSize > MAX_STORAGE_SIZE) {
    const dbsOldestToNewest = _.sortBy(allDBs, 'time')
    while (totalSize > MAX_STORAGE_SIZE) {
      cleanupCount++
      const db = dbsOldestToNewest.shift()
      const key = authDB.makeLocalStorageKey(db.tab)
      localStorage.removeItem(key)
      totalSize -= storageSize[key]
    }
  }

  const end = new Date().getTime()
  // eslint-disable-next-line no-console
  console.debug(`cleaned up ${cleanupCount} old DBs in ${end - start}ms`)
}

export default function startCleanup(authDB) {
  setAuthDB(authDB)
  return setInterval(() => {
    cleanupOldAuthDBs()
  }, 30_000)
}
