import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

/**
 * Direct selector to the Access Activity state domain
 */
const selectAccessActivityDomain = state => state.get('accessActivity')

/**
 * Other specific selectors
 */

/**
 * Default selector used by Access Activity
 */

export const selectAccessActivity = createSelector(
  selectAccessActivityDomain,
  state => state || Map()
)

export const selectCriticalResourceMonitorDate = createSelector(
  selectAccessActivity,
  state => state.get('date')
)

export const selectPropertyWidget = createSelector(
  selectAccessActivity,
  state => state.get('propertyWidget') || Map()
)

export const selectDidAccessData = createSelector(
  selectAccessActivity,
  state => state.get('didAccess') || Map()
)
export const selectCanAccessData = createSelector(
  selectAccessActivity,
  state => state.get('canAccess') || Map()
)
export const selectAccessedUsingData = createSelector(
  selectAccessActivity,
  state => state.get('accessedUsing') || Map()
)
export const selectActivityWidget = createSelector(
  selectAccessActivity,
  state => state.get('activityWidget') || Map()
)

export const selectSelectedRowActions = createSelector(
  selectAccessActivity,
  state => {
    return state.get('selectedRowActions') || List()
  }
)

export const selectActionsModal = createSelector(
  selectAccessActivity,
  state => {
    return state.get('actionsModal')
  }
)

export const selectSelectedRowActionsLoading = createSelector(
  selectAccessActivity,
  state => {
    return state.get('selectedRowActionsLoading')
  }
)

export const selectAccessPaths = createSelector(
  selectCanAccessData,
  canAccessData => {
    return canAccessData.get('data') || List()
  }
)

export const selectCanAccessPaths = createSelector(
  selectAccessActivity,
  state => {
    return state.get('canAccessPaths')
  }
)

export const selectCanAccessPathsLoading = createSelector(
  selectAccessActivity,
  state => {
    return state.get('canAccessPathLoading')
  }
)

export const selectCanAccessPathsError = createSelector(
  selectAccessActivity,
  state => {
    return state.get('canAccessPathError')
  }
)

export const selectLastCanAccessJobTimestamp = createSelector(
  selectAccessActivity,
  state => {
    return state.get('lastCanAccessJobTimestamp')
  }
)

export default selectAccessActivity
export { selectAccessActivityDomain }
