import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ImmutablePropTypes from 'react-immutable-proptypes'
import DataTable from 'components/DataTable'
import { TopTitle, CardBody } from 'components/Card'
import Card from 'components/Card'
import WidgetPopup from 'components/WidgetPopup'
import moment from 'moment'
import { exists } from 'utils/sonraiUtils'
import TextLink from 'components/TextLink'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

export class AccessedUsingWidget extends Component {
  styles = {
    title: {
      fontSize: '22px',
      fontWeight: '300',
      marginRight: '0px',
    },
    card: {
      minHeight: 0,
    },
  }

  getData = accessedUsingData => {
    if (
      exists(accessedUsingData) &&
      exists(accessedUsingData.data) &&
      !_.isEmpty(accessedUsingData.data)
    ) {
      const rows = accessedUsingData.data.map(x => {
        const row = {
          timestamp: x.timestamp,
          userAgentSet: x.userAgentSet,
          showActions: '',
        }
        return row
      })

      return rows || []
    }
    return []
  }

  getHeader = () => {
    const { isLocked, title } = this.props
    const keyName = 'accessedUsing'

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="accessedusingdget-title" style={this.styles.title}>
            {title}
          </span>
          {this.props.isMonitored && (
            <WidgetPopup
              data={{
                keyName,
                keyType: 'PATH',
              }}
              title={this.props.title}
              resourceSrn={this.props.srn}
              isLocked={isLocked}
              crmTickets={this.props.crmTickets}
            />
          )}
        </div>
      </div>
    )
  }

  handleActionsModal = ({ userAgentSet, timestamp }) => {
    const { srn } = this.props
    this.props.getSelectedRowActions({
      data: {
        userAgentSet,
        criticalResourceSRN: srn,
      },
      path: this.props.path,
      date: {
        fromDate: moment.utc(timestamp).utc().startOf('day'),
        toDate: moment.utc(timestamp).utc().endOf('day'),
      },
    })
  }

  getContent = () => {
    const accessedUsingData = this.getData(this.props.accessedUsingData)
    const { isLoading } = this.props
    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    }

    if (!this.props.isMonitored) {
      return this.props.renderCRMOverlay()
    } else {
      return (
        <DataTable
          sortColumns
          onRowCountChange={this.props.recalculateGrid}
          style={{ height: accessedUsingData.length > 0 ? '100%' : '60px' }}
          data={accessedUsingData}
          autosize={false}
          sortColumnByType="AccessedUsing"
          customColumnConfig={{
            timestamp: {
              headerName: 'Day',
              valueFormatter: ({ value }) => moment.utc(value).format('ll'),
            },
            userAgentSet: {
              flex: 1,
              minWidth: 200,
            },
            showActions: {
              cellStyle: { padding: '0 7px' },
              width: 120,
              minWidth: 120,
              aggFunc: null,
              pinned: 'right',
              headerName: '',
              enableRowGroup: false,
              menuTabs: [],
              suppressMenu: true,
              cellRendererFramework: params => {
                if (!params.data) {
                  return null
                }
                return (
                  <TextLink
                    color="primary"
                    style={{ color: this.props.theme.primary }}
                    onClick={() => this.handleActionsModal(params.data)}
                  >
                    Show Actions
                  </TextLink>
                )
              },
            },
          }}
        />
      )
    }
  }

  render() {
    return (
      <Card title={this.props.title}>
        <TopTitle>{this.getHeader()}</TopTitle>
        <CardBody
          style={{
            display: 'grid',
            gridTemplateRows: '1fr',
            height: 'auto',
            overflow: 'hidden',
            justifyContent: 'inherit',
            alignItems: 'inherit',
          }}
        >
          {this.getContent()}
        </CardBody>
      </Card>
    )
  }
}

AccessedUsingWidget.defaultProps = {
  // eslint-disable-next-line react/display-name
  renderCRMOverlay: () => {
    return (
      <div>
        <div
          style={{
            textAlign: 'center',
            fontSize: '0.9rem',
            marginBottom: '0.5rem',
          }}
        >
          Results from this widget are only available if the resource is
          monitored.
        </div>
      </div>
    )
  },
}

AccessedUsingWidget.propTypes = {
  theme: themeShape,
  title: PropTypes.string,
  isLocked: PropTypes.bool,
  recalculateGrid: PropTypes.func,
  srn: PropTypes.string,
  crmTickets: ImmutablePropTypes.list.isRequired,
  accessedUsingData: PropTypes.object,
  isLoading: PropTypes.bool,
  getSelectedRowActions: PropTypes.func,
  path: PropTypes.string,
  isMonitored: PropTypes.bool,
  renderCRMOverlay: PropTypes.func,
}

export default themeable(AccessedUsingWidget)
