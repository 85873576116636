/*
 *
 * RapSheet constants
 *
 */

export const SET_SELECTED_RESOURCE_ALERT_HISTORY =
  'app/RapSheet/SET_SELECTED_RESOURCE_ALERT_HISTORY'
export const SET_DEDUPED_ALERTS = 'app/RapSheet/SET_DEDUPED_ALERTS'

export const GET_ACTIVE_ALERT_ACTIONS = 'app/RapSheet/GET_ACTIVE_ALERT_ACTIONS'
export const SET_ACTIVE_ALERT_ACTIONS = 'app/RapSheet/SET_ACTIVE_ALERT_ACTIONS'
export const SET_FILTER = 'app/RapSheet/SET_FILTER'
export const REMOVE_FILTER = 'app/RapSheet/REMOVE_FILTER'
export const RESET_RESOURCE = 'app/RapSheet/RESET_RESOURCE'
export const FETCH_CATEGORY_ROLLUP_DATA =
  'app/RapSheet/FETCH_CATEGORY_ROLLUP_DATA'
export const SET_CATEGORY_ROLLUP_DATA = 'app/RapSheet/SET_CATEGORY_ROLLUP_DATA'
export const FETCH_FILTERED_ALERTS = 'app/RapSheet/FETCH_FILTERED_ALERTS'
export const TOGGLE_SUNBURST_VIEW = 'app/RapSheet/TOGGLE_SUNBURST_VIEW'
export const SET_RESOURCES = 'app/RapSheet/SET_RESOURCES'
export const SET_RESOURCES_LOADING = 'app/RapSheet/SET_RESOURCES_LOADING'
export const UPDATE_RAPSHEET_RESOURCE_MONITOR =
  'app/RapSheet/UPDATE_RAPSHEET_RESOURCE_MONITOR'
