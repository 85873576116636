/**
 *
 * DataNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { injectIntl, FormattedMessage } from 'react-intl'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'

import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import { exists } from 'utils/sonraiUtils'
import { NODE_VIEW_TYPES } from 'appConstants'
import { NodeViewDetailString, NodeViewDetailLabel } from 'components/NodeView'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'

import InfoCard, {
  Property,
  DateProperty,
  BoolProperty,
} from 'components/InfoCard'

import messages from './messages'
import reducer from './reducer'
import sagas from './sagas'
import { getDataContent } from './actions'
import { selectDataContent } from './selectors'

export class DataNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getDataContent(props.nodeData.srn)
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }
  getContainer = () =>
    this.props.dataContent
      .getIn(['isIn', 'items'], List())
      .find(item => item.get('label') === 'DataContainer', null, Map())
      .toJS()

  getColumns = () => {
    return {
      md: [
        {
          widthPercent: 50,
          items: ['container'],
        },
        {
          widthPercent: 50,
          items: ['graph'],
          heights: {
            graph: 3,
          },
        },
      ],
      xs: [
        {
          widthPercent: 100,
          items: ['container', 'graph'],
          heights: {
            graph: 3,
          },
        },
      ],
    }
  }

  onClickContainer = () => {
    const containerData = this.getContainer()
    this.onClickNodeView(containerData.srn, NODE_VIEW_TYPES.CONTAINER)
  }

  renderContainerCard = containerData => {
    if (!containerData || _.isEmpty(containerData)) {
      return (
        <InfoCard
          title={`${this.props.intl.formatMessage(
            messages.containerLabel
          )}: ${this.props.intl.formatMessage(messages.none)}`}
        >
          <p>
            <FormattedMessage {...messages.dataItem} />
          </p>
        </InfoCard>
      )
    }

    return (
      <InfoCard
        title={`${this.props.intl.formatMessage(messages.containerLabel)}: ${
          containerData.name
        }`}
        onClick={this.onClickContainer}
      >
        <Property
          label={this.props.intl.formatMessage(messages.srnLabel)}
          value={containerData.srn}
        />
        <DateProperty
          label={this.props.intl.formatMessage(messages.createdLabel)}
          value={containerData.createdDate}
        />
        <DateProperty
          label={this.props.intl.formatMessage(messages.lastModifiedLabel)}
          value={containerData.modifiedDate}
        />
        <BoolProperty
          label={this.props.intl.formatMessage(messages.activeLabel)}
          value={containerData.active}
        />
        <BoolProperty
          label={this.props.intl.formatMessage(messages.publicRead)}
          value={containerData.publicRead}
        />
        <BoolProperty
          label={this.props.intl.formatMessage(messages.publicWrite)}
          value={containerData.publicWrite}
        />
        <BoolProperty
          label={this.props.intl.formatMessage(messages.websiteEnabled)}
          value={containerData.websiteEnabled}
        />
        <BoolProperty
          label={this.props.intl.formatMessage(messages.auditEnabled)}
          value={containerData.auditEnabled}
        />
      </InfoCard>
    )
  }

  getHeaderDetails = () => {
    const node = this.props.nodeData

    return [
      <Fragment key="content-size">
        <NodeViewDetailLabel>
          {this.props.intl.formatMessage(messages.size)}
        </NodeViewDetailLabel>
        <NodeViewDetailString
          value={
            exists(node.size)
              ? `${node.size} ${this.props.intl.formatMessage(messages.bytes)}`
              : '-'
          }
        />
      </Fragment>,
    ]
  }

  getGridContent = () => {
    const containerData = this.getContainer()

    return [
      <div key="container">{this.renderContainerCard(containerData)}</div>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          additionalHeaderDetails={this.getHeaderDetails()}
          columns={this.getColumns()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
        />
      </Fragment>
    )
  }
}

DataNodeViewCardLayout.propTypes = {
  getDataContent: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  dataContent: ImmutablePropTypes.map.isRequired,
  setUserProfileTables: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.func.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile,
  dataContent: selectDataContent,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDataContent,
      setUserProfileTables,
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({ key: 'dataNodeViewCardLayout', reducer })

const withSaga = injectSaga({
  key: 'dataNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(DataNodeViewCardLayout)
