import React from 'react'
import PropTypes from 'prop-types'

export const CenterContent = ({ children, horizontalOnly, style }) => {
  const styles = {
    container: {
      display: 'grid',
      justifyItems: 'center',
      alignItems: horizontalOnly ? 'top' : 'center',
      textAlign: 'center',
    },
  }

  return <div style={{ ...styles.container, ...style }}>{children}</div>
}

CenterContent.defaultProps = {
  style: {},
}

CenterContent.propTypes = {
  children: PropTypes.node,
  horizontalOnly: PropTypes.bool,
  style: PropTypes.object,
}

export default CenterContent
