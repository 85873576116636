import React from 'react'
import PropTypes from 'prop-types'
import { default as ReactSplitterLayout } from 'react-splitter-layout'
import 'react-splitter-layout/lib/index.css'
import './gridStyles.css'
import './styles.css'

export class SplitterLayout extends React.Component {
  render() {
    const { children, grid, ...pprops } = this.props
    return (
      <ReactSplitterLayout
        customClassName={grid ? 'splitter-layout-grid' : undefined}
        {...pprops}
      >
        {children}
      </ReactSplitterLayout>
    )
  }
}

SplitterLayout.propTypes = {
  grid: false,
}

SplitterLayout.propTypes = {
  children: PropTypes.node,
}

export default SplitterLayout
