export const FETCH_POLICY = 'app/PolicyEdit/FETCH_POLICY'
export const SET_POLICY = 'app/PolicyEdit/SET_POLICY'

export const POLICY_EVAL_TYPES = {
  COUNT: 'count',
}

export const POLICY_OP_TYPES = {
  GT: 'gt',
  LT: 'lt',
  EQ: 'eq',
  GE: 'ge',
  ALL: 'all',
  LE: 'le',
  ANY: 'any',
}
