/**
 *
 * NodeViewDetail
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { HAS_COPY } from 'appConstants'
import CopyToClipboard from 'components/CopyToClipboard'
import Hoverable from 'components/Hoverable'

class NodeViewDetail extends React.PureComponent {
  styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '30px',
    },
    textStyle: {
      width: '370px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    clipboardButton: {
      marginBottom: '5px',
      width: '30px',
    },
  }

  render() {
    const { value, fieldName } = this.props
    const hasCopy = HAS_COPY.includes(fieldName)

    if (hasCopy) {
      const realValue =
        typeof value === 'object'
          ? value.name || value.friendlyName || value.srn || '-'
          : value
      return (
        <div style={{ width: '370px' }}>
          <Hoverable
            hoverStyle={{ cursor: 'default' }}
            renderContent={({ hovered }) => (
              <div style={this.styles.container}>
                <div style={this.styles.textStyle}>{realValue}</div>
                {hovered ? (
                  <div style={this.styles.clipboardButton}>
                    <CopyToClipboard value={realValue} />
                  </div>
                ) : (
                  <div style={this.styles.clipboardButton} />
                )}
              </div>
            )}
          />
        </div>
      )
    }
    return <div>{value || '-'}</div>
  }
}

NodeViewDetail.propTypes = {
  value: PropTypes.string,
  fieldName: PropTypes.string,
}

export default NodeViewDetail
