import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import TicketPolicyEvidence from './TicketPolicyEvidence'
import TicketCRMEvidence from './TicketCRMEvidence'

export default function TicketEvidence(props) {
  switch (props.ticket.get('ticketType')) {
    case 'Policy':
      return <TicketPolicyEvidence {...props} />
    case 'Activity':
      return <TicketCRMEvidence {...props} />
    case 'Access':
      return <TicketCRMEvidence {...props} />
    case 'Property':
      return <TicketCRMEvidence {...props} />
    default:
      return null
  }
}

TicketEvidence.propTypes = {
  ticket: ImmutablePropTypes.contains({
    ticketType: PropTypes.string.isRequired,
  }).isRequired,
}
