import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { List } from 'immutable'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getNodeViewPushParams, metadataToObj } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import TableWidget from 'components/TableWidget'

import reducer from './reducer'
import sagas from './sagas'
import { getSecretStoreContent, getVaultStoreContent } from './actions'
import { selectSecretStoreContent, selectIsLoading } from './selectors'
import { SECRET_STORE_TYPES } from './constants'

export class SecretStoreNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    if (this.props.nodeData.type === SECRET_STORE_TYPES.VAULT) {
      props.getVaultStoreContent(props.nodeData.srn)
    } else {
      props.getSecretStoreContent(props.nodeData.srn)
    }
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getContainsCount = () =>
    this.props.secretStoreContent.getIn(['contains', 'count'])

  getContents = () => {
    const baseContent = this.props.secretStoreContent
      .getIn(['contains', 'items'], List())
      .toJS()

    if (this.props.nodeData.type === SECRET_STORE_TYPES.VAULT_AWS) {
      return baseContent.map(({ metadata, ...secret }) => {
        const mappedMetadata = metadataToObj(metadata)
        return {
          ...secret,
          roleCredentialType: mappedMetadata['role.credential_type'],
        }
      })
    }

    return baseContent
  }

  getLayouts = () => {
    const hasContents = this.getContents().length > 0

    return {
      contents: {
        lg: { w: 8, h: hasContents ? 3 : 1 },
        md: { w: 7, h: hasContents ? 3 : 1 },
        sm: { w: 6, h: hasContents ? 3 : 1 },
        xs: { w: 4, h: hasContents ? 3 : 1 },
      },
      graph: {
        lg: { w: 8, h: 3 },
        md: { w: 7, h: 3 },
        sm: { w: 6, h: 3 },
        xs: { w: 4, h: 3 },
      },
    }
  }

  isTopLevel = () => {
    return this.props.nodeData.type === SECRET_STORE_TYPES.VAULT
  }

  getGridContent = () => {
    const title = this.isTopLevel() ? 'Contains Stores' : 'Contains Secrets'

    return [
      <div key="contents">
        <TableWidget
          nodeViewType="SecretStore"
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
          data={this.getContents()}
          title={title}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.isLoading}
        />
      </div>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          layouts={this.getLayouts()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
        />
      </Fragment>
    )
  }
}

SecretStoreNodeViewCardLayout.propTypes = {
  getSecretStoreContent: PropTypes.func.isRequired,
  getVaultStoreContent: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  isLoading: PropTypes.bool,
  secretStoreContent: ImmutablePropTypes.map.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  setUserProfileTables: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile,
  secretStoreContent: selectSecretStoreContent,
  isLoading: selectIsLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserProfileTables,
      push,
      getSecretStoreContent,
      getVaultStoreContent,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({
  key: 'secretStoreNodeViewCardLayout',
  reducer,
})

const withSaga = injectSaga({
  key: 'secretStoreNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(SecretStoreNodeViewCardLayout)
