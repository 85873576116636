import _ from 'lodash'
import { Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'

class ConfigValueHolder {
  /**
   * @param configName name of field on config.json holding the value
   * @param defaultValue value if not set in config.json
   */
  constructor(configName, defaultValue) {
    this.configName = configName
    this.defaultValue = defaultValue
    this.value = undefined
  }

  /**
   * Compute value either from the config.json, but if it's not set
   * then we can use the default
   */
  getValue = () => {
    let value = window.config[this.configName]
    if (_.isUndefined(this.value)) {
      value = this.defaultValue
    }
    return value
  }
}

const MS_PER_SECOND = 1000

// number of milliseconds to wait before attempting the first retry
const INITIAL_DELAY = 'graphqlClientRetryInitialDelay'

// maximum number of milliseconds that the link should wait for any retry
const MAX_DELAY = 'graphqlClientRetryMaxDelay'

// whether delays between attempts should be randomized
const JITTER = 'graphqlClientRetryDelayJitter'

//  max number of times to try a single operation before giving up
const MAX_ATTEMPTS = 'graphqlClientRetryMaxAttempts'

// other keys that could be in config.json
export const RETRY_ON_SHUTDOWN = 'graphClientRetryOnShutdown'
export const RETRY_ON_NETWORK_FAILURE = 'graphClientOnNetworkFailure'

const config = {
  [INITIAL_DELAY]: new ConfigValueHolder(INITIAL_DELAY, 5 * MS_PER_SECOND),
  [MAX_DELAY]: new ConfigValueHolder(MAX_DELAY, 30 * MS_PER_SECOND),
  [JITTER]: new ConfigValueHolder(JITTER, true),
  [MAX_ATTEMPTS]: new ConfigValueHolder(MAX_ATTEMPTS, 12),
}

/**
 * Shutdown retry link - this apollo error handling link will retry the request
 * if it recieves the "shutting-down" response from GraphQL Server:
 *  - HTTP Status = 503
 *  - special message in body
 *
 * The retry behaviour can be configured using values from config.json. It takes
 * the same configuration options as the apollo-retry handling, but with
 * different names (see above for config names)
 */
export const retryOnShutdown = args => {
  const { operation, networkError, forward } = args
  if (undefined !== networkError && shouldRetryOnShutdown(networkError)) {
    const delay = config[INITIAL_DELAY].getValue()
    return new Observable(subscriber => {
      setTimeout(() => {
        subscriber.next()
        subscriber.complete()
      }, delay)
    }).flatMap(() => {
      return forward(operation)
    })
  }
}
export const retryOnShutdownLink = onError(retryOnShutdown)

const SPECIAL_MESSAGE =
  'Unable to finish processing request. Server is shutting down.'

export const shouldRetryOnShutdown = networkError => {
  const message = _.get(networkError, ['result', 'message'], null)
  return 503 === networkError.statusCode && SPECIAL_MESSAGE === message
}

/**
 * This is the apollo-link to handle network failures
 */
export function makeRetryOnFailedLink() {
  return new RetryLink({
    delay: {
      initial: config[INITIAL_DELAY].getValue(),
      max: config[MAX_DELAY].getValue(),
      jitter: config[JITTER].getValue(),
    },
    attempts: {
      max: config[MAX_ATTEMPTS].getValue(),
    },
  })
}
