import { createSelector } from 'reselect'
import { initialState } from './reducers'

const selectCreateUpdateTicketModalDomain = state =>
  state.get('createTicketModal') || initialState

export const selectTicketType = createSelector(
  selectCreateUpdateTicketModalDomain,
  state => state.get('ticketType')
)

export const selectSelectedTicketTemplate = createSelector(
  selectCreateUpdateTicketModalDomain,
  state => state.get('selectedTicketTemplate')
)

export const selectTicketTemplates = createSelector(
  selectCreateUpdateTicketModalDomain,
  state => state.get('ticketTemplates')
)

export const selectTicketTemplateFields = createSelector(
  selectCreateUpdateTicketModalDomain,
  state => state.get('ticketTemplateFields')
)

export const selectCreateTicketStatus = createSelector(
  selectCreateUpdateTicketModalDomain,
  state => state.get('createTicketStatus')
)
