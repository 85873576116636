import { all, put, takeLatest, select, call } from 'redux-saga/effects'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'
import { List } from 'immutable'
import _ from 'lodash'

import { mapPoliciesToControlFrameworks } from 'utils/sonraiUtils'
import {
  selectPolicies,
  selectControlFrameworksForUser,
  selectControlGroups,
} from 'containers/ControlFrameworkData/selectors'
import { TICKET_STATUS_CHANGE_SUCCESS } from 'containers/TicketDetailsData/constants'
import { performTicketFetch } from 'containers/TicketDetailsData/sagas'
import { TICKET_STATUS } from 'appConstants'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { getNameFromSrn, getCloudFromSrn } from 'utils/sonraiUtils'
import { FETCH_CATEGORY_ROLLUP_DATA, FETCH_FILTERED_ALERTS } from './constants'
import { selectStatus } from 'containers/SecurityCenter/selectors'
import {
  setSelectedResourceAlertHistory,
  setCategoryRollupData,
  setResources,
  setResourcesLoading,
} from './actions'
import { selectSelectedResourceAlerts } from './selectors'

const getStatusString = status => {
  const defValue = `status: { op: IN_LIST, values: ["${TICKET_STATUS.NEW}", "${TICKET_STATUS.IN_PROGRESS}"] }`
  if (!status || status === 'OPEN') {
    return defValue
  }
  return `status: { op: EQ, value: "${status}" }`
}

function* fetchCategoryRollupData({ payload }) {
  const { category, swimlaneSrn, visFilters } = payload
  const status = visFilters.get('status')
  const filters = {}
  //category = ticketType (could be Access, Activity, Property, Custom OR a control framework srn in which case we will switch the type to "Policy")
  if (category) {
    if (category.startsWith('srn')) {
      if (category.includes('ControlFramework')) {
        const controlFrameworks = yield select(selectControlGroups)
        const policyIds = controlFrameworks
          .getIn([category, 'contains', 'items'], List())
          .toJS()
          .map(pol => pol.srn)
        filters.ticketType = 'Policy'
        filters.ticketKey = policyIds
      }
    } else {
      filters.ticketType = category
    }
  }
  if (swimlaneSrn) {
    filters.swimlaneSrns = swimlaneSrn
  }
  const displayStatus = getStatusString(status)
  try {
    const client = getClient()
    const categoryRollups = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
        query getTicketRollUpsForSunburst {
          TicketRollups(
            where: {
              ${
                payload.resourceId
                  ? `resourceSRN: { op: EQ value:  "${payload.resourceId}"}`
                  : 'resourceSRN: { value: "ALL" }'
              }
              ${
                filters.ticketType
                  ? `ticketType: { op: EQ value: "${filters.ticketType}"  }`
                  : `ticketType: { op: IN_LIST values: ["Policy", "Access", "Activity", "Property"] }`
              }
              ${
                filters.swimlaneSrns
                  ? `swimlaneSrns: { op: IN_LIST values: ["${filters.swimlaneSrns}"] }`
                  : 'swimlaneSrns: { value: "ALL" }'
              }
              ${
                filters.ticketKey
                  ? `ticketKey: {op: IN_LIST values: [${filters.ticketKey
                      .map(srn => `"${srn}"`)
                      .join(', ')}]}`
                  : `ticketKey: { op: NEQ, value: "ALL" }   `
              }
              ${displayStatus}
              severityCategory: { op: NEQ, value: "ALL" }
              actionClassification: { value: "ALL" }
              resourceType: { op: NEQ value: "ALL" }
              resourceLabel: { op: NEQ value: "ALL" }
              orgName: { value: "ALL" }
              account: { value: "ALL" }
            }
          ) {
            items(limit: -1) {
              resourceSRN
              ticketType
              ticketKey
              severityCategory
              count
              resourceType
              resourceLabel
              actionClassification
              swimlaneSrns
              status
              riskScore
              riskScoreNumeric
            }
          }
        }
      `,
      variables: {
        ...filters,
        resourceSRN: payload.resourceId,
      },
    })
    if (categoryRollups.data.TicketRollups.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }
    //COR-1549 Filtering is ignored on ticketType.
    //We do not want both Policy and Framework tickets because that results in redundancy in the sunburst multi view
    //So filter them out here
    const data = categoryRollups.data.TicketRollups.items.filter(
      rollup => rollup.ticketType !== 'Framework'
    )
    const policies = yield select(selectPolicies)
    const controlGroups = yield select(selectControlFrameworksForUser)

    // if not filtering by specific key, do not duplicate policies across control frameworks
    const dataWithControlFrameworks = (filters.ticketKey
      ? mapPoliciesToControlFrameworks(data, policies, controlGroups)
      : data
    ).map(ticketRollup => {
      //sunburstVis expects all properties to be a single value
      //We can do this because AlertRollups from this query will be granular and only have one swimlane on them
      return {
        ...ticketRollup,
        swimlaneSrn: _.get(ticketRollup, ['swimlaneSRNs', 0]),
      }
    })
    yield put(setCategoryRollupData(dataWithControlFrameworks))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error loading cateogry rollup data', e)
  }
}

function* fetchResourcesForAlerts(alerts) {
  let highestMatchingSeveritiesBySrn = {}
  let lastAlertDateBySrn = {}
  const uniqueSrns = new Set() //eslint-disable-line no-restricted-globals

  alerts.forEach(alert => {
    const srn = alert.resourceSRN
    if (srn) {
      uniqueSrns.add(srn)
    }

    if (
      !highestMatchingSeveritiesBySrn[srn] ||
      alert.severityNumeric > highestMatchingSeveritiesBySrn[srn]
    ) {
      highestMatchingSeveritiesBySrn[srn] = alert.severityNumeric
      lastAlertDateBySrn[srn] = alert.createdDate
    }
  })

  const client = getClient()

  const entities = yield client.query({
    forceFetch: true,
    fetchPolicy: 'no-cache',
    query: gql`
      query getEntitiesBySRN($srnList: [String]) {
        Entities(where: { srn: { op: IN_LIST, values: $srnList } }) {
          items {
            srn
            tagSet
            ... on Resource {
              account
              active
              createdDate
              cloudType
              country
              friendlyName
              name
              region
              resourceId
              importance
              label
              swimlaneSRNs
            }
          }
        }
      }
    `,
    variables: {
      srnList: Array.from(uniqueSrns),
    },
  })

  if (entities.data.Entities.items === null) {
    throw new Error('Bad formatting of response from server: items is null')
  }

  const visResources = {}
  entities.data.Entities.items.forEach(entity => {
    entity.lastAlertDate = lastAlertDateBySrn[entity.srn]
    entity.highestAlertSeverity = highestMatchingSeveritiesBySrn[entity.srn]

    if (!entity.name) {
      entity.name = getNameFromSrn(entity.srn)
    }

    if (!entity.cloudType) {
      entity.cloudType = getCloudFromSrn(entity.srn)
    }

    if (!entity.label) {
      entity.label = getTypeFromSrn(entity.srn)
    }

    if (entity.active === undefined || entity.active === null) {
      entity.active = true
    }

    visResources[entity.srn] = entity
  })
  return visResources
}

const getStatus = status => {
  const defValue = {
    op: 'IN_LIST',
    values: [TICKET_STATUS.NEW, TICKET_STATUS.IN_PROGRESS],
  }
  if (!status || status === 'OPEN') {
    return defValue
  }

  return { op: 'EQ', value: `${status}` }
}

function* fetchFilteredAlerts({ payload }) {
  if (payload.fetchResources) {
    yield put(setResourcesLoading(true))
  }

  const filters = payload

  /*==== Prepare the variables === */

  let variables = {}

  //Make sure we always include the category selected from the heatmap, if we haven't gone down into something more specific
  if (filters.category && !filters.ticketKey) {
    if (filters.category.startsWith('srn')) {
      if (filters.category.includes('ControlFramework')) {
        filters.ticketType = 'Framework'
        filters.ticketKey = filters.category
      }
    } else {
      filters.ticketType = filters.category
    }
  }

  if (filters.ticketType && filters.ticketType !== 'ALL') {
    if (filters.ticketType === 'Framework') {
      variables.ticketType = 'Policy'
    } else {
      variables.ticketType = filters.ticketType
    }
  }

  if (filters.ticketKey) {
    if (
      filters.ticketKey.startsWith('srn') &&
      filters.ticketKey.includes('ControlFramework')
    ) {
      //Fetching for a control framework.
      //Tickets don't have the ControlFramework on them,
      //so we instead have to get the list of policiesIds in the CF
      const controlFrameworks = yield select(selectControlGroups)

      const policyIds = controlFrameworks
        .getIn([filters.ticketKey, 'contains', 'items'], List())
        .toJS()
        .map(pol => pol.srn)

      if (policyIds.length > 0) {
        variables.ticketType = 'Policy'
        variables.ticketKey = policyIds
      } else {
        //Framework has no policies so it can't possibly have results.
        console.warn('Selected framework does not have any configured policies') //eslint-disable-line no-console
        yield put(setSelectedResourceAlertHistory([]))
        yield put(setResourcesLoading(false))
        yield put(setResources({}))
        return
      }
    } else {
      variables.ticketKey = filters.ticketKey
    }
  }

  if (filters.subKeyName) {
    variables.actionClassification = filters.subKeyName
  }

  if (filters.severityCategory && filters.severityCategory !== 'ALL') {
    variables.severityCategory = filters.severityCategory
  }

  if (filters.resourceLabel) {
    variables.resourceLabel = filters.resourceLabel
  }

  if (filters.resourceSrn) {
    variables.resourceSRN = filters.resourceSrn
  }

  if (filters.swimlaneSrn || filters.swimlane) {
    variables.swimlaneSrn = filters.swimlaneSrn
  }

  if (filters.status) {
    variables.status = filters.status
  }

  const ticketFilter = {}
  if (variables.swimlaneSrn) {
    ticketFilter.swimlaneSrns = { value: variables.swimlaneSrn }
  }

  if (variables.actionClassification) {
    ticketFilter.actionClassification = {
      value: variables.actionClassification,
    }
  }

  if (variables.resourceSRN) {
    ticketFilter.resourceSRN = { value: variables.resourceSRN }
  }

  if (variables.resourceLabel) {
    ticketFilter.resourceLabel = { value: variables.resourceLabel }
  }

  if (variables.severityCategory) {
    ticketFilter.severityCategory = { value: variables.severityCategory }
  }

  if (variables.ticketType) {
    ticketFilter.ticketType = { value: variables.ticketType }
  }

  if (variables.status !== 'ALL') {
    ticketFilter.status = getStatus(variables.status)
  }

  if (variables.ticketKey) {
    if (Array.isArray(variables.ticketKey)) {
      ticketFilter.ticketKey = {
        values: variables.ticketKey,
        op: 'IN_LIST',
      }
    } else {
      ticketFilter.ticketKey = { value: variables.ticketKey }
    }
  }

  /**
   * TODO need to support AND for this
   * // if (variables.maxSeverity ) {
   * //   severityNumeric: {}
   * // }
   * // ${
   * //   exists(variables.maxSeverity) && exists(variables.minSeverity)
   * //     ? 'severity: {and: [{op: GTE, value: $minSeverity}, {op: LTE, value: $maxSeverity}]}'
   * //     : ''
   * // }
   */

  const selection = `
    items(
      limit: 100,
      orderBy: [ { severityNumeric: { order: DESC } } ]
    ) {
      srn
      resourceId
      createdDate
      createdBy
      orgName
      assignedTo
      transitionedBy
      transitionDate
      isSnoozed
      snoozedUntil
      ticketType
      ticketKey
      description
      title
      status
      account
      firstSeen
      lastSeen
      flag
      evidence {
        conditions
        path
        count
        fieldType
        boolValue
        intValue
        longValue
        value
        prevBoolValue
        prevIntValue
        prevLongValue
        prevValue
        resourceSet
        conditions
        path
        regionSet
        userAgentSet
      }
      resourceSRN
      severityNumeric
      severityCategory
      swimlaneSRNs
      actionClassification
      resourceLabel
      resourceType
    }
    `
  try {
    const client = getClient()
    const result = yield client.query({
      forceFetch: true,
      fetchPolicy: 'network-only',
      query: gql`
        query getRapSheetTickets($ticketFilter: TicketsFilter) {
          Tickets(where: $ticketFilter) {
            ${selection}
          }
        }
      `,
      variables: { ticketFilter },
    })
    if (result.data.Tickets.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const tickets = result.data.Tickets.items
    const visResources = yield call(fetchResourcesForAlerts, tickets)
    tickets.forEach(ticket => {
      const { resourceSRN } = ticket
      const resource = visResources[resourceSRN]
      if (resource) {
        ticket.resource = resource
      } else {
        ticket.resource = {}
      }
    })

    if (payload.ticketSrn != null) {
      const ticket = tickets.find(ticket => ticket.srn === payload.ticketSrn)
      if (undefined == ticket) {
        tickets.push(yield performTicketFetch({ srn: payload.ticketSrn }))
      }
    }

    yield put(setSelectedResourceAlertHistory(tickets))
    if (payload.fetchResources) {
      yield put(setResources(visResources))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error loading CRM alerts data', e)
  }
}

function* handleTicketStatusChanged({ payload }) {
  const { srn, newStatus } = payload
  const statusFilter = yield select(selectStatus)

  const tickets = yield select(selectSelectedResourceAlerts)

  const wasClosed =
    newStatus === TICKET_STATUS.CLOSED ||
    newStatus === TICKET_STATUS.SNOOZED ||
    newStatus === TICKET_STATUS.RISK_ACCEPTED

  const showOpen =
    statusFilter === 'OPEN' || statusFilter === 'NEW' || !statusFilter

  if (
    (showOpen && wasClosed) ||
    (statusFilter === 'CLOSED' && newStatus === TICKET_STATUS.NEW)
  ) {
    //Remove this ticket from the visible list of tickets
    yield put(
      setSelectedResourceAlertHistory(
        tickets.filterNot(t => t.get('srn') === srn).toJS()
      )
    )
  }
}

function* rapsheetSaga() {
  yield all([
    takeLatest(FETCH_CATEGORY_ROLLUP_DATA, fetchCategoryRollupData),
    takeLatest(FETCH_FILTERED_ALERTS, fetchFilteredAlerts),
    takeLatest(TICKET_STATUS_CHANGE_SUCCESS, handleTicketStatusChanged),
  ])
}

export default rapsheetSaga
