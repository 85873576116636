/**
 *
 * Property
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

class Property extends React.Component {
  render() {
    return (
      <Fragment>
        <div>{this.props.label}</div>
        <div>{this.props.value}</div>
      </Fragment>
    )
  }
}

Property.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

export default Property
