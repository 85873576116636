import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

const selectDataContainerNodeViewCardLayoutDomain = state =>
  state.get('dataContainerNodeViewCardLayout') || Map()

export const selectDataContainerContent = createSelector(
  selectDataContainerNodeViewCardLayoutDomain,
  state => state.get('dataContainerContent') || Map()
)

export const selectIsLoading = createSelector(
  selectDataContainerNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)

export const selectActionsPerformed = createSelector(
  selectDataContainerNodeViewCardLayoutDomain,
  state => state.get('actionsPerformed') || List()
)

export const selectIsLoadingActions = createSelector(
  selectDataContainerNodeViewCardLayoutDomain,
  state => state.get('loadingActions')
)
