import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'
import NodeViewDetailMetadataBody from './NodeViewDetailMetadataBody'
import TextLink from 'components/TextLink'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import metadataMap from 'metadataMapping.json'
import { metadataToObj } from 'utils/sonraiUtils'
import './styles.css'

export class NodeViewDetailMetadata extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      filterText: '',
    }

    this.styles = {
      label: {
        fontStyle: 'italic',
      },
      modalBody: {
        maxHeight: '80vh',
        overflow: 'auto',
      },
      key: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 400,
        opacity: 0.8,
      },
      value: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    }
  }

  toggle = () => {
    this.setState({
      isOpen: false,
    })
  }

  open = () => {
    this.setState({
      isOpen: true,
    })
  }

  setFilterText = ({ target: { value } }) =>
    this.setState({ filterText: value })

  isMatch = key => {
    let keyText = key.toLowerCase()
    let filterText = this.state.filterText.toLowerCase()
    if (filterText !== '') {
      if (keyText.includes(filterText)) {
        return {
          backgroundColor: this.props.theme.highlight,
        }
      }
      return {
        color: 'rgba(171, 171, 171, 0.51)',
      }
    }
    return {}
  }

  doesMetadataMappingFilterMatch = item => {
    const { type, label, cloudType } = this.props.nodeData
    // make sure the config has a 'filter' field and it is an array
    if (!item.filter) {
      // without filter, ignore it. This might be undesired behaviour, like if we
      // want a "global" config, it this could return true instead.
      return false
    }
    const { filter } = item
    if (!_.isObject(filter)) {
      // eslint-disable-next-line no-console
      console.warn(
        'invalid metadataMapping config, expected filter to be an object: ' +
          JSON.stringify(filter)
      )
      return false
    }

    // make sure at least one field is in the filter
    if (!filter.cloudType && !filter.type && !filter.label) {
      // eslint-disable-next-line no-console
      console.warn(
        'filter must have at least one field "cloudType", "type" or "label"'
      )
      return false
    }

    // if cloudType doesnt match, return false
    if (filter.cloudType) {
      if (!_.isArray(filter.cloudType)) {
        // eslint-disable-next-line no-console
        console.warn(
          'invalid metadataMapping config, expected filter.cloudtype to be an array: ' +
            JSON.stringify(filter)
        )
        return false
      }

      if (!filter.cloudType.includes(cloudType)) {
        return false
      }
    }

    // if label doesnt match, return false
    if (filter.label) {
      if (!_.isArray(filter.label)) {
        // eslint-disable-next-line no-console
        console.warn(
          'invalid metadataMapping config, expected filter.type to be an array: ' +
            JSON.stringify(filter)
        )
        return false
      }

      if (!filter.label.includes(label)) {
        return false
      }
    }

    // if label doesn't match, return false
    if (filter.type) {
      if (!_.isArray(filter.type)) {
        // eslint-disable-next-line no-console
        console.warn(
          'invalid metadataMapping config, expected filter.type to be an array: ' +
            JSON.stringify(filter)
        )
        return false
      }

      if (!filter.type.includes(type)) {
        return false
      }
    }

    // have passed every opportunity to filter out this mapping, it must match
    return true
  }

  getMetadataKeyPreviews = values => {
    const metadataObject = metadataToObj(values)
    let keysToDisplay = metadataMap
      .filter(item => {
        // make sure there is actually a keys field
        if (!item.keys) {
          // eslint-disable-next-line no-console
          console.warn(
            'metadata mapping is invalid, has no "keys": ' +
              JSON.stringify(item)
          )
          return false
        }

        // filter has to match
        return this.doesMetadataMappingFilterMatch(item)
      })
      .reduce(
        (allKeys, currentMapping) => [...allKeys, ...currentMapping.keys],
        []
      )
    keysToDisplay = _.uniq(keysToDisplay)

    if (keysToDisplay.length > 0) {
      const currentValues = values.map(item => _.first(item.split(':')))
      return (
        <div className="node-view-metadata-detail">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto auto',
              gridColumnGap: '0.3em',
            }}
          >
            {currentValues
              .filter(item => keysToDisplay.includes(item))
              .map((item, i) => {
                return [
                  <div
                    key={'key-' + i}
                    className="metadata-preview-key"
                    style={this.styles.key}
                    data-tip
                    data-for={'metadata-key-tooltip-' + i}
                  >
                    {item}:{' '}
                  </div>,
                  <div
                    key={'val-' + i}
                    className="metadata-preview-value"
                    style={this.styles.value}
                    data-tip
                    data-for={'metadata-value-tooltip-' + i}
                  >
                    {metadataObject[item]}
                  </div>,
                ]
              })}
          </div>
          <span>
            {currentValues
              .filter(item => keysToDisplay.includes(item))
              .map((item, i) => {
                // this class has a width on it to try to make it not go off
                // the edge of the page
                return [
                  <ReactTooltip
                    className="metadata-tooltip"
                    key={'metadata-key-tooltip-' + i}
                    id={'metadata-key-tooltip-' + i}
                  >
                    {item}
                  </ReactTooltip>,
                  <ReactTooltip
                    className="metadata-tooltip"
                    key={'metadata-value-tooltip-' + i}
                    id={'metadata-value-tooltip-' + i}
                  >
                    {metadataObject[item]}
                  </ReactTooltip>,
                ]
              })}
          </span>
        </div>
      )
    } else {
      return (
        <span className="metadata-preview-empty">
          {' '}
          {values.length} entries{' '}
        </span>
      )
    }
  }

  render() {
    if (Array.isArray(this.props.value) && this.props.value.length > 0) {
      return (
        <div>
          {this.getMetadataKeyPreviews(this.props.value)}
          <TextLink color="primary" onClick={this.open}>
            (See More)
          </TextLink>
          <Modal isOpen={this.state.isOpen} toggle={this.toggle} size="lg">
            <ModalHeader>Metadata</ModalHeader>
            <ModalBody style={this.styles.modalBody}>
              <NodeViewDetailMetadataBody
                value={this.props.value}
                nodeData={this.props.nodeData}
              />
            </ModalBody>
            <ModalFooter>
              <TextLink color="primary" onClick={this.toggle}>
                Close
              </TextLink>
            </ModalFooter>
          </Modal>
        </div>
      )
    }

    return <div>-</div>
  }
}

NodeViewDetailMetadata.propTypes = {
  theme: themeShape,
  value: PropTypes.array,
  nodeData: PropTypes.object,
}

export default themeable(NodeViewDetailMetadata)
