import React from 'react'
import PropTypes from 'prop-types'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import styles from './styles'

export class BorderedCard extends React.Component {
  render() {
    return (
      <div
        className={this.props.className}
        style={
          this.props.loading
            ? {
                ...styles.wrapper,
                ...this.props.style,
                ...this.props.loadingStyle,
              }
            : { ...styles.wrapper, ...this.props.style }
        }
      >
        {this.props.loading ? (
          <div style={{ height: 'auto', width: 'auto' }}>
            <span style={styles.loading}>
              <SquareLoadingAnimation />
            </span>
            {this.props.title && <span> {this.props.title} </span>}
          </div>
        ) : (
          this.props.children
        )}
      </div>
    )
  }
}

BorderedCard.defaultProps = {
  className: '',
  style: {},
  loadingStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

BorderedCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingStyle: PropTypes.object,
  style: PropTypes.object,
  title: PropTypes.string,
}

export default BorderedCard
