/**
 *
 * ControlGroup
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import qs from 'query-string'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { Alert as StrapAlert } from 'reactstrap'
import { Map, List } from 'immutable'
import { FormattedMessage } from 'react-intl'
import { SONRAI_ORG_NAME } from 'appConstants'
import SavedSearchDisplay from 'components/SavedSearchDisplay'
import Button from 'components/Button'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import TextLink from 'components/TextLink'
import DataTable from 'components/DataTable'
import TableLoader from 'components/TableLoader'
import EmptySection from 'components/EmptySection'
import permissionChecker from 'containers/PermissionChecker'
import Scrollable from 'components/Scrollable'
import MarkdownDisplay from 'components/MarkdownDisplay'
import IconLayer from 'components/IconLayer'
import Icon from 'components/Icon'
import BorderlessButton from 'components/BorderlessButton'
import injectReducer from 'utils/injectReducer'
import LoadingAnim from 'components/LoadingAnim'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import BorderedCard from 'components/BorderedCard'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import DropdownActions, {
  DropdownAnchor,
  DropdownMenu,
  DropdownItem,
} from 'components/DropdownActions'
import Select from 'react-select'
import {
  deletePolicy,
  removePolicy,
  createPolicy,
  setPolicyError,
  toggleEnabledOnSwimlanes,
  cloneFramework,
  deleteControlGroup,
  setFrameworkEnabled,
} from 'containers/ControlFrameworkData/actions'
import {
  selectPolicyError,
  selectUpdatingPolicies,
  selectUpdatingCFs,
  selectDeletingCFs,
  selectControlGroups,
  selectLoadedPolicies,
} from 'containers/ControlFrameworkData/selectors'
import {
  selectSavedSearches,
  selectSonraiUsers,
  selectSwimlanes,
  selectSwimlanesLoading,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'

import {
  selectControlGroup,
  selectControlGroupId,
  selectIndexedSonraiSearches,
  selectPoliciesForControlGroup,
  selectCloning,
} from './selectors'
import reducer from './reducer'
import { setCloning } from './actions'
import AddPolicyModal from './AddPolicyModal'
import EditControlGroupModal from './EditControlGroupModal'
import messages from './messages'
import { getNameForHelmet } from 'utils/sonraiUtils'
import { Helmet } from 'react-helmet'
import DescriptionRenderer from './DescriptionRenderer'
import PolicyActions from './PolicyActions'
import CreatedByBadge from 'components/CreatedByBadge'

import { displayAsEnabled } from 'containers/ControlCenter/utils'

const SORT_FIELDS = {
  TITLE: 'title',
  LEVEL: 'level',
}

const SORT_DIRS = {
  ASC: 'asc',
  DESC: 'desc',
}

export class ControlGroup extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      container: {
        padding: '1em',
        height: '100%',
        overflow: 'auto',
      },
      createdByContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      policiesContainer: {
        gridArea: 'policies',
        padding: '1em',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      },
      swimlaneContainer: {
        gridArea: 'swimlanes',
        padding: '1em',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        marginBottom: '1em',
      },
      titleContainer: {
        gridArea: 'title',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridTemplateAreas: '"cfTitle buttonZone" "description description"',
        gridRowGap: '1em',
        gridTemplateRows: '50px auto',
        height: '150px',
      },
      navContextContainer: {
        height: '24px',
      },
      pageTitle: {
        gridArea: 'cfTitle',
        color: props.theme.neutralDark,
      },
      title: {
        fontSize: '1.3em',
        color: props.theme.neutralDark,
      },
      searchIconWrapper: {
        marginRight: 0,
      },
      searchIcon: {
        backgroundColor: '#fff',
      },
      policies: {
        padding: '1em 0',
      },
      emptyNotice: {
        color: '#eee',
        maxHeight: '60vh',
      },
      loader: {
        color: '#eee',
        maxHeight: '60vh',
      },
      columnHeader: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: '1em',
        alignItems: 'center',
      },
      headerContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
      },
      sortContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridColumnGap: '0.3em',
        fontSize: '0.9em',
      },
      policyTypeBody: {
        margin: '1em',
      },
      noPolicies: {
        color: props.theme.neutralMedium,
        textAlign: 'center',
      },
      notEnabledModalMessage: {
        color: '#77777',
        fontStyle: 'italic',
        padding: '4px 0px',
      },
    }

    this.state = {
      policyFilter: '',
      showCreatePolicyModal: false,
      showEditControlGroupModal: false,
      showSwimlaneModal: false,
      sortBy:
        localStorage.getItem('controlFrameworkPolicySort') || SORT_FIELDS.PASS,
      policySortDirection:
        localStorage.getItem('controlFrameworkPolicySortDirection') ||
        SORT_DIRS.ASC,
      errorMsg: null,
      swimlaneOptions: null,
      showConfirmDeleteSwimlaneModal: false,
      confirmSwimlaneDelete: null,
    }
  }

  setError = msg => {
    this.setState({
      errorMsg: msg,
      showCreatePolicyModal: false,
      showEditControlGroupModal: false,
      showSwimlaneModal: false,
    })
    this.props.setPolicyError({ msg: null, srn: '' })
    setTimeout(() => {
      this.setState({ errorMsg: null })
    }, 6000)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.policyError && !prevState.errorMsg) {
      this.setError(this.props.policyError)
    }
    if (
      prevProps.deletingCFs.get(prevProps.controlGroup.get('srn')) &&
      !this.props.deletingCFs.get(this.props.controlGroup.get('srn'))
    ) {
      this.props.push({
        pathname: '/App/ControlCenter',
      })
    }
  }

  setPolicyFilter = e => {
    this.setState({
      policyFilter: e.target.value,
    })
  }

  getFilteredPolicies = () => {
    const sortProp = this.state.sortBy

    let sortedPolicies = this.props.policies.toList()
    switch (sortProp) {
      case SORT_FIELDS.TITLE:
        sortedPolicies = sortedPolicies.sortBy(pol =>
          pol.get('title').toLowerCase()
        )
        break
      case SORT_FIELDS.LEVEL:
        sortedPolicies = sortedPolicies.sortBy(pol =>
          pol.get('alertingLevelNumeric')
        )
        break
    }

    if (this.state.policySortDirection === SORT_DIRS.DESC) {
      sortedPolicies = sortedPolicies.reverse()
    }

    if (!this.state.policyFilter) {
      return sortedPolicies
    }

    return sortedPolicies.filter(
      policy =>
        policy
          .get('title')
          .toLowerCase()
          .includes(this.state.policyFilter.toLowerCase()) ||
        policy
          .get('description')
          .toLowerCase()
          .includes(this.state.policyFilter.toLowerCase())
    )
  }

  toggleCreatePolicyModal = () => {
    this.setState(currentState => ({
      showCreatePolicyModal: !currentState.showCreatePolicyModal,
    }))
  }

  toggleEditControlGroupModal = () => {
    this.setState(currentState => ({
      showEditControlGroupModal: !currentState.showEditControlGroupModal,
    }))
  }

  toggleSwimlaneModal = () => {
    this.setState(currentState => ({
      showSwimlaneModal: !currentState.showSwimlaneModal,
    }))
  }

  changeSort = e => {
    this.setState({
      sortBy: e.value,
    })

    localStorage.setItem('controlFrameworkPolicySort', e.value)
  }

  removePolicy = policyId => {
    this.props.removePolicy({
      policyId,
      controlGroupId: this.props.controlGroup.get('srn'),
    })

    if (this.policyGridApi) {
      this.policyGridApi.showLoadingOverlay()
    }
  }

  clonePolicy = policyId => {
    this.props.setCloning(policyId)

    const policy = this.props.policies.get(policyId)

    this.props.createPolicy({
      title: `${policy.get('title')} (Copy)`,
      description: policy.get('description'),
      searchId: policy.getIn(['contains', 'items', 0, 'srn']),
      controlGroupId: this.props.controlGroup.get('srn'),
      evalCriteria: policy.get('evalCriteria'),
      alertingLevelNumeric: policy.get('alertingLevelNumeric'),
    })

    if (this.policyGridApi) {
      this.policyGridApi.showLoadingOverlay()
    }
  }

  deletePolicy = policyId => {
    this.props.deletePolicy(policyId)
    if (this.policyGridApi) {
      this.policyGridApi.showLoadingOverlay()
    }
  }

  navigateToPolicy = row => {
    this.props.push({
      pathname: '/App/ControlCenter/Policy',
      search: qs.stringify({
        policyId: row.srn,
      }),
    })
  }

  getSearchNavParams = search => {
    if (search !== undefined) {
      if (search.__typename === 'Savedquery') {
        return {
          pathname: '/App/GraphExplorer',
          state: {
            searchName: search.name,
          },
        }
      } else {
        return {
          pathname: '/App/Search',
          search: qs.stringify({
            searchId: search.sid,
          }),
        }
      }
    } else {
      return undefined
    }
  }

  toggleDescription = e => {
    e.node.setExpanded(!e.node.expanded)
  }

  onPolicyTableLoad = ({ api }) => {
    this.policyGridApi = api
  }

  onSwimlaneTableLoad = ({ api }) => {
    api.sizeColumnsToFit()
  }

  getSwimlaneData = () => {
    const listOfSwombos = this.props.swimlanes.toList()
    const data = (this.props.controlGroup.get('swimlaneSRNs')
      ? this.props.controlGroup.get('swimlaneSRNs').toJS()
      : []
    ).map(swimSrn => {
      const thisSwombo = listOfSwombos.find(swo => swo.get('srn') === swimSrn)
      if (!thisSwombo) {
        return {
          title: 'Unavailable Swimlane',
          description: 'You do not have permission to view this swimlane',
          actions: 'not_allowed_swimmy',
        }
      }
      return {
        title: thisSwombo.get('title'),
        description: thisSwombo.get('description'),
        actions: thisSwombo.get('srn'),
      }
    })

    return data
  }

  renderSwimlanes = canEdit => {
    if (this.props.swimlanesLoading) {
      return <LoadingAnim />
    }

    return (
      <div style={this.styles.policies}>
        <DataTable
          fitAllRows
          onLoad={this.onSwimlaneTableLoad}
          customColumnConfig={{
            title: {
              flex: 1,
              minWidth: 200,
            },
            description: {
              flex: 1.5,
              minWidth: 200,
            },
            actions: {
              pinned: 'right',
              hide: !canEdit,
              width: 150,
              suppressSizeToFit: true,
              cellRendererFramework: ({ data }) => {
                if (!data) {
                  return null
                }

                const canAssignSwimmy = this.props.userHasPermission({
                  permissionName: 'assign.integrations',
                  swimlanes: [data.actions],
                })
                return (
                  <BorderlessButton
                    color="primary"
                    title="Disable on Swimlane"
                    onClick={
                      canAssignSwimmy
                        ? () => {
                            this.openConfirmDisableSwimlaneModal(data)
                          }
                        : undefined
                    }
                    disabled={
                      !canAssignSwimmy ||
                      this.props.updatingCFs.get(
                        this.props.controlGroup.get('srn')
                      )
                    }
                    style={{ marginRight: '1em' }}
                  >
                    <Icon fa name="times" />
                    &nbsp; Remove
                  </BorderlessButton>
                )
              },
            },
          }}
          data={this.getSwimlaneData()}
          overlayNoRowsTemplate={`<span>Enabled for all Swimlanes</span>`}
        />
      </div>
    )
  }

  renderPolicies = () => {
    if (!this.props.policiesLoaded) {
      return <TableLoader style={this.styles.loader} />
    }

    if (this.props.policies.isEmpty()) {
      return (
        <EmptySection
          style={this.styles.emptyNotice}
          icon={
            <IconLayer>
              <Icon fa name="clipboard" />
              <Icon fa name="question-solid" transform="shrink-10 down-1" />
            </IconLayer>
          }
        >
          <div>No Policies</div>
        </EmptySection>
      )
    }

    const activePolicies = this.getFilteredPolicies()

    const tableData = activePolicies
      .map(policy => {
        let search
        const polsearch = policy.getIn(['contains', 'items', 0])
        if (polsearch !== undefined) {
          const sonraiSearch = this.props.sonraiSearches.get(
            polsearch.get('name'),
            Map()
          )

          const savedSearch = this.props.savedSearches.find(
            s => s.get('srn') === polsearch.get('srn')
          )

          search = !sonraiSearch.isEmpty() ? sonraiSearch : savedSearch
        }

        return {
          title: policy.get('title'),
          alertingLevel: policy.get('alertingLevelNumeric'),
          description: policy.get('description'),
          search: search,
          createdBy: policy.get('createdBy'),
          remediationType:
            policy.get('remediationType') &&
            _.startCase(_.toLower(policy.get('remediationType'))),
          createdByUser: this.props.sonraiUsers.get(
            policy.get('createdBy'),
            Map()
          ),
          actions: '',
          srn: policy.get('srn'),
        }
      })
      .toJS()

    const canEdit = this.props.userHasPermission({
      permissionName: 'assign.integrations',
    })

    return (
      <div style={this.styles.policies}>
        <DataTable
          fitAllRows
          onDoubleClickRow={this.navigateToPolicy}
          onLoad={this.onPolicyTableLoad}
          onSingleClick={this.onSingleClick}
          customGridProps={{
            groupUseEntireRow: true,
            overlayLoadingTemplate:
              '<span class="ag-overlay-loading-center">Working...</span>',
            masterDetail: true,
            detailCellRenderer: 'descriptionRenderer',
            frameworkComponents: { descriptionRenderer: DescriptionRenderer },
            detailCellRendererParams: {
              detailGridOptions: {
                columnDefs: [{ field: 'description' }],
                defaultColDef: { flex: 1, minWidth: 100 },
              },
              getDetailRowData: function (params) {
                params.successCallback([
                  { description: params.data.description },
                ])
              },
            },
          }}
          data={tableData}
          customColumnConfig={{
            srn: {
              hide: true,
            },
            createdByUser: {
              hide: true,
            },
            createdBy: {
              minWidth: 200,
              width: 200,
              valueGetter: params => {
                if (!params.data) {
                  return ''
                }

                return params.data.createdByUser.name || 'Sonrai'
              },
              cellRendererFramework: ({ data }) => {
                if (!data) {
                  return null
                }
                return <CreatedByBadge table createdBy={data.createdBy} />
              },
            },
            description: {
              minWidth: 500,
              width: 500,
              tooltip: params => {
                if (
                  !params.value ||
                  typeof params.value !== 'string' ||
                  params.value.trim().length === 0
                ) {
                  return ''
                }

                if (params.value.length > 50) {
                  return params.value.slice(0, 50) + '...'
                }

                return params.value
              },
              cellRendererFramework: params => {
                if (!params.data) {
                  return
                }

                if (
                  !params.data.description ||
                  typeof params.data.description !== 'string' ||
                  params.data.description.trim().length === 0
                ) {
                  return ''
                }

                return (
                  <span>
                    {params.data.description &&
                    params.data.description.length > 50
                      ? params.data.description.slice(0, 50) + '...'
                      : params.data.description}
                    <TextLink
                      color="primary"
                      onClick={() => this.toggleDescription(params)}
                      style={{ marginLeft: '0.3em' }}
                    >
                      (Toggle Expand)
                    </TextLink>
                  </span>
                )
              },
            },
            search: {
              minWidth: 300,
              flex: 1,
              cellRendererFramework: params => {
                if (!params.data) {
                  return
                }
                return (
                  <SavedSearchDisplay
                    search={params.data.search}
                    to={this.getSearchNavParams(params.data.search)}
                  />
                )
              },
            },
            actions: {
              hide: !canEdit,
              cellStyle: { padding: '0 7px' },
              width: 125,
              minWidth: 125,
              aggFunc: null,
              pinned: 'right',
              headerName: '',
              enableRowGroup: false,
              singleClickEdit: true,
              menuTabs: [],
              suppressMenu: true,
              cellRendererFramework: params => {
                if (!params.data) {
                  return null
                }
                const policy = this.props.policies.get(params.data.srn) || Map()

                return (
                  <PolicyActions
                    policy={policy}
                    removePolicy={this.removePolicy}
                    clonePolicy={this.clonePolicy}
                    deletePolicy={this.deletePolicy}
                  />
                )
              },
            },
          }}
        />
      </div>
    )
  }

  handleAddEnableSwimlanes = () => {
    if (this.state.swimlaneOptions) {
      this.toggleEnabledSwimlanes({
        add: this.state.swimlaneOptions.map(option => option.value),
      })
    }
    this.setState({ swimlaneOptions: null })
    this.toggleSwimlaneModal()
  }

  openConfirmDisableSwimlaneModal = data => {
    this.setState({
      showConfirmDeleteSwimlaneModal: true,
      confirmSwimlaneDelete: data,
    })
  }

  closeConfirmDisableSwimlaneModal = () => {
    this.setState({
      showConfirmDeleteSwimlaneModal: false,
      confirmSwimlaneDelete: null,
    })
  }

  handleDisableSwimlane = () => {
    if (!this.props.updatingCFs.get(this.props.controlGroup.get('srn'))) {
      this.toggleEnabledSwimlanes({
        remove: [this.state.confirmSwimlaneDelete.actions],
      })
    }
    this.closeConfirmDisableSwimlaneModal()
  }

  toggleEnabledSwimlanes = params => {
    this.props.toggleEnabledOnSwimlanes({
      srn: this.props.controlGroup.get('srn'),
      add: params.add ? params.add : null,
      remove: params.remove ? params.remove : null,
    })
  }

  enableGlobally = () => {
    this.props.setFrameworkEnabled({
      enabled: true,
      srn: this.props.controlGroup.get('srn'),
      redirect:
        this.props.controlGroup.getIn([
          'ownedByOrganization',
          'items',
          0,
          'name',
        ]) === SONRAI_ORG_NAME,
    })
  }

  disableGlobally = () => {
    this.props.setFrameworkEnabled({
      enabled: false,
      srn: this.props.controlGroup.get('srn'),
    })
  }

  getSwimlaneOptions = () =>
    this.props.swimlanes
      .map(swim => ({
        value: swim.get('srn'),
        label: swim.get('title'),
      }))
      .filter(
        item =>
          !(this.props.controlGroup.get('swimlaneSRNs')
            ? this.props.controlGroup.get('swimlaneSRNs')
            : []
          ).includes(item.value)
      )
      .toList()
      .toJS()

  handleSwimlaneOptionChange = params => {
    this.setState({ swimlaneOptions: params })
  }

  handleCloneFramework = () => {
    this.props.cloneFramework(this.props.controlGroup.get('srn'))
  }

  handleDeleteFramework = () => {
    this.props.deleteControlGroup(this.props.controlGroup.get('srn'))
  }

  handleToggleEnabled = () => {
    this.props.setFrameworkEnabled({
      srn: this.props.controlGroup.get('srn'),
      enabled: !this.props.controlGroup.get('enabled'),
    })
  }

  getMenuItems = canEdit => {
    const items = []
    if (canEdit) {
      items.push(
        <DropdownItem
          key="enable-cf"
          closeOnClick
          onClick={this.handleToggleEnabled}
        >
          {this.props.controlGroup.get('enabled') ? 'Disable ' : 'Enable '}
          Framework
        </DropdownItem>
      )

      items.push(
        <DropdownItem
          key="edit-cf"
          closeOnClick
          onClick={this.toggleEditControlGroupModal}
        >
          Edit Framework
        </DropdownItem>
      )
    }

    items.push(
      <DropdownItem
        key="clone-cf"
        closeOnClick
        onClick={this.handleCloneFramework}
      >
        Clone Framework
      </DropdownItem>
    )
    if (canEdit) {
      items.push(
        <DropdownItem
          key="delete-cf"
          closeOnClick
          onClick={this.handleDeleteFramework}
        >
          Delete Framework
        </DropdownItem>
      )
    }

    return items
  }

  togglePolicySortDir = () => {
    this.setState(currentState => {
      const newDirection =
        currentState.policySortDirection === SORT_DIRS.ASC
          ? SORT_DIRS.DESC
          : SORT_DIRS.ASC

      localStorage.setItem('controlFrameworkPolicySortDirection', newDirection)
      return {
        policySortDirection: newDirection,
      }
    })
  }

  renderGlobalButton = () => {
    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.controlframeworks',
      resourceId: this.props.controlGroup.get('resourceId'),
    })

    const swimlanesCantAssign = (
      this.props.controlGroup.get('swimlaneSRNs') || List()
    ).filter(swimlane => {
      return !this.props.userHasPermission({
        permissionName: 'assign.integrations',
        swimlanes: [swimlane],
      })
    })
    const canAssign =
      swimlanesCantAssign.size == 0 &&
      this.props.userHasPermission({
        permissionName: 'assign.integrations',
      })

    const canClone = this.props.userHasPermission({
      permissionName: 'edit.controlframeworks',
    })

    const isEnabled = displayAsEnabled(this.props.controlGroup)
    const isSonrai =
      this.props.controlGroup.getIn([
        'ownedByOrganization',
        'items',
        0,
        'name',
      ]) === SONRAI_ORG_NAME

    const canEnable = canAssign || canEdit || (canClone && isSonrai)

    const numSwimlanes = this.props.controlGroup.get('swimlaneSRNs')
      ? this.props.controlGroup.get('swimlaneSRNs').size
      : 0

    if (numSwimlanes === 0 && isEnabled) {
      return (
        <div>
          <span
            style={{
              fontStyle: 'italic',
              color: this.props.theme.success,
              marginRight: '1em',
            }}
          >
            Enabled Globally
          </span>
          <Button
            outline
            color="danger"
            title="Disable"
            onClick={this.disableGlobally}
            disabled={
              !canEdit ||
              this.props.updatingCFs.get(this.props.controlGroup.get('srn'))
            }
            style={{ marginRight: '1em' }}
          >
            <FormattedMessage {...messages.disableButton} />
          </Button>
        </div>
      )
    } else if (numSwimlanes > 0 && isEnabled) {
      return (
        <div>
          <span
            style={{
              fontStyle: 'italic',
              color: this.props.theme.success,
              marginRight: '1em',
            }}
          >
            Enabled on {numSwimlanes} swimlanes
          </span>
          <Button
            outline
            color="danger"
            title="Disable Globally"
            onClick={this.disableGlobally}
            disabled={
              (!canEdit && !canAssign) ||
              this.props.updatingCFs.get(this.props.controlGroup.get('srn'))
            }
            style={{ marginRight: '1em' }}
          >
            <FormattedMessage {...messages.disableGloballyButton} />
          </Button>
        </div>
      )
    } else {
      return (
        <Button
          color="primary"
          title="Enable"
          onClick={this.enableGlobally}
          disabled={
            !canEnable ||
            this.props.updatingCFs.get(this.props.controlGroup.get('srn'))
          }
          style={{ marginRight: '1em' }}
        >
          <FormattedMessage {...messages.enableButton} />
        </Button>
      )
    }
  }

  render() {
    if (this.props.controlGroup.isEmpty() || this.props.sonraiUsersLoading) {
      return <LoadingAnim />
    }

    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.controlframeworks',
      resourceId: this.props.controlGroup.get('resourceId'),
    })

    const canEnableOnSwimlane = this.props.userHasPermission({
      permissionName: 'assign.integrations',
    })

    const name = getNameForHelmet(this.props.controlGroup.toJS())
    let container = { ...this.styles.container }

    if (this.state.errorMsg) {
      container.gridTemplateRows = 'auto 100px 35px 1fr'
      container.gridTemplateAreas = '"title" "details" "banner" "policies"'
    }

    const updating =
      this.props.updatingCFs.get(this.props.controlGroup.get('srn')) ||
      this.props.deletingCFs.get(this.props.controlGroup.get('srn'))

    const parentCF = this.props.allControlFrameworks.get(
      this.props.controlGroup.getIn(['cloneOf', 'items', 0, 'srn'], '')
    )

    return (
      <div style={container}>
        {this.state.showCreatePolicyModal && (
          <AddPolicyModal
            toggle={this.toggleCreatePolicyModal}
            controlGroup={this.props.controlGroup}
          />
        )}
        {this.state.showEditControlGroupModal && (
          <EditControlGroupModal
            toggle={this.toggleEditControlGroupModal}
            controlGroup={this.props.controlGroup}
          />
        )}
        <div style={this.styles.navContextContainer}>
          <Breadcrumb>
            <BreadcrumbItem>
              <TextLink color="primary" to={{ pathname: '/App/ControlCenter' }}>
                All Control Frameworks
              </TextLink>
            </BreadcrumbItem>
            <BreadcrumbItem>Control Framework Details</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div style={this.styles.titleContainer}>
          <div style={this.styles.pageTitle}>
            <div style={{ fontSize: '1.5em', fontWeight: '400' }}>
              {this.props.controlGroup.get('title')}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={this.styles.createdByContainer}>
                <div
                  style={{
                    marginRight: '0.5rem',
                    fontSize: '0.9em',
                  }}
                >
                  {' '}
                  Created By:{' '}
                </div>
                <CreatedByBadge
                  createdBy={this.props.controlGroup.get('createdBy')}
                />
              </div>
              {parentCF && (
                <Fragment>
                  <div style={{ marginRight: '0.5em', marginLeft: '0.5em' }}>
                    &nbsp;|&nbsp;
                  </div>
                  <TextLink
                    color="primary"
                    to={{
                      pathname: '/App/ControlCenter/ControlGroup',
                      search: qs.stringify({
                        controlGroupId: parentCF.get('srn'),
                      }),
                    }}
                  >
                    View Parent Framework
                  </TextLink>
                </Fragment>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gridArea: 'buttonZone',
            }}
          >
            {this.renderGlobalButton()}
            <Button
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginLeft: '1em',
              }}
              outline
              color="primary"
              to={{
                pathname: '/App/SecurityCenter',
                search: qs.stringify({
                  view: 'resources',
                  category: this.props.controlGroup.get('srn'),
                }),
              }}
            >
              <Icon name="securityCenterLink" style={{ fontSize: '25px' }} />{' '}
              <span>Open in Security Center</span>
            </Button>
            <DropdownActions disabled={updating}>
              <DropdownAnchor borderless>
                <div
                  style={{
                    marginLeft: '1em',
                  }}
                >
                  {updating ? (
                    <Icon transform="grow-4" spin fa name="sync" />
                  ) : (
                    <Icon transform="grow-4" fa name="cog-solid" />
                  )}
                </div>
              </DropdownAnchor>
              <DropdownMenu>{this.getMenuItems(canEdit)}</DropdownMenu>
            </DropdownActions>
          </div>
          <Scrollable style={{ height: '100%', gridArea: 'description' }}>
            <MarkdownDisplay
              content={this.props.controlGroup.get('description')}
            />
          </Scrollable>
        </div>
        <BorderedCard style={this.styles.swimlaneContainer}>
          <div style={this.styles.columnHeader}>
            <span style={this.styles.title}>Swimlanes</span>
            <BorderlessButton
              color="primary"
              title="Enable on Swimlanes"
              onClick={
                canEnableOnSwimlane ? this.toggleSwimlaneModal : undefined
              }
              disabled={
                !canEnableOnSwimlane ||
                this.props.updatingCFs.get(this.props.controlGroup.get('srn'))
              }
              style={{ marginRight: '1em' }}
            >
              {this.props.updatingCFs.get(
                this.props.controlGroup.get('srn')
              ) ? (
                <Icon fa spin name="sync" style={{ marginRight: '0.3em' }} />
              ) : (
                <Icon fa name="plus" style={{ marginRight: '0.3em' }} />
              )}
              <FormattedMessage {...messages.enableOnSwimlaneButton} />
            </BorderlessButton>
          </div>
          {this.renderSwimlanes(canEnableOnSwimlane)}
        </BorderedCard>

        <BorderedCard style={this.styles.policiesContainer}>
          <div style={this.styles.columnHeader}>
            <span style={this.styles.title}>Policies</span>
            <BorderlessButton
              color="primary"
              title="Create Policy"
              onClick={canEdit ? this.toggleCreatePolicyModal : undefined}
              disabled={!canEdit}
            >
              <Icon fa name="plus" style={{ marginRight: '0.3em' }} />
              <FormattedMessage {...messages.addPolicyButton} />
            </BorderlessButton>
          </div>
          {this.renderPolicies()}
        </BorderedCard>
        {this.state.errorMsg && (
          <div style={{ gridArea: 'banner' }}>
            <StrapAlert style={{ textAlign: 'center' }} color="danger">
              {this.state.errorMsg}
            </StrapAlert>
          </div>
        )}
        <Helmet title={`Sonrai - ${name}`} />
        {this.state.showSwimlaneModal && (
          <Modal
            isOpen={this.state.showSwimlaneModal}
            toggle={this.toggleSwimlaneModal}
          >
            <ModalHeader>
              <FormattedMessage {...messages.enableSwimlaneTitle} />
            </ModalHeader>
            <ModalBody>
              <Select
                isMulti
                options={this.getSwimlaneOptions()}
                onChange={this.handleSwimlaneOptionChange}
                value={this.state.swimlaneOptions}
              />
              <div style={this.styles.notEnabledModalMessage}>
                {true !== this.props.controlGroup.get('enabled') && (
                  <FormattedMessage {...messages.notEnabledModalMessage} />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <TextLink color="secondary" onClick={this.toggleSwimlaneModal}>
                <FormattedMessage {...messages.cancelButton} />
              </TextLink>
              <Button color="primary" onClick={this.handleAddEnableSwimlanes}>
                <FormattedMessage {...messages.enableSwimlanes} />
              </Button>
            </ModalFooter>
          </Modal>
        )}

        {this.state.showConfirmDeleteSwimlaneModal && (
          <Modal
            isOpen={this.state.showConfirmDeleteSwimlaneModal}
            toggle={this.closeConfirmDisableSwimlaneModal}
          >
            <ModalHeader>{`Disable ${this.state.confirmSwimlaneDelete.title} on Control Framework?`}</ModalHeader>
            <ModalBody>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <TextLink
                  color="secondary"
                  onClick={this.closeConfirmDisableSwimlaneModal}
                >
                  <FormattedMessage {...messages.cancelButton} />
                </TextLink>
                <Button
                  style={{ marginLeft: '15px' }}
                  color="danger"
                  onClick={this.handleDisableSwimlane}
                >
                  <FormattedMessage {...messages.disableSwimlane} />
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    )
  }
}

ControlGroup.propTypes = {
  cloning: PropTypes.string,
  controlGroup: ImmutablePropTypes.map.isRequired,
  controlGroupId: PropTypes.string,
  createPolicy: PropTypes.func,
  deletePolicy: PropTypes.func,
  setPolicyError: PropTypes.func,
  policyError: PropTypes.string,
  policies: ImmutablePropTypes.iterable.isRequired,
  policiesLoaded: PropTypes.bool,
  push: PropTypes.func,
  removePolicy: PropTypes.func.isRequired,
  savedSearches: ImmutablePropTypes.iterable.isRequired,
  setCloning: PropTypes.func.isRequired,
  sonraiSearches: ImmutablePropTypes.iterable.isRequired,
  sonraiUsers: ImmutablePropTypes.map,
  updatingPolicies: ImmutablePropTypes.map,
  swimlanes: ImmutablePropTypes.map,
  updatingCFs: ImmutablePropTypes.map,
  deletingCFs: ImmutablePropTypes.map,
  allControlFrameworks: ImmutablePropTypes.map,
  userHasPermission: PropTypes.func.isRequired,
  toggleEnabledOnSwimlanes: PropTypes.func,
  cloneFramework: PropTypes.func,
  deleteControlGroup: PropTypes.func,
  setFrameworkEnabled: PropTypes.func,
  swimlanesLoading: PropTypes.bool,
  sonraiUsersLoading: PropTypes.bool,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  cloning: selectCloning,
  controlGroup: selectControlGroup,
  policies: selectPoliciesForControlGroup,
  policiesLoaded: selectLoadedPolicies,
  controlGroupId: selectControlGroupId,
  savedSearches: selectSavedSearches,
  sonraiUsers: selectSonraiUsers,
  policyError: selectPolicyError,
  sonraiSearches: selectIndexedSonraiSearches,
  updatingPolicies: selectUpdatingPolicies,
  swimlanes: selectSwimlanes,
  updatingCFs: selectUpdatingCFs,
  deletingCFs: selectDeletingCFs,
  swimlanesLoading: selectSwimlanesLoading,
  allControlFrameworks: selectControlGroups,
  sonraiUsersLoading: selectSonraiUsersLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPolicy,
      deletePolicy,
      setPolicyError,
      push,
      removePolicy,
      setCloning,
      cloneFramework,
      toggleEnabledOnSwimlanes,
      deleteControlGroup,
      setFrameworkEnabled,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'controlGroup', reducer })

export default compose(
  withReducer,
  withConnect,
  themeable,
  permissionChecker
)(ControlGroup)
