import React from 'react'
import PropTypes from 'prop-types'
import { Badge as RSBadge } from 'reactstrap'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class Badge extends React.PureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      base: {
        fontSize: '0.9rem',
        fontWeight: '500',
        letterSpacing: '1.2px',
        color: props.theme.light,
        backgroundColor: props.theme.primary,
        borderRadius: '0.8rem',
        padding: '.35em .5em',
        ...props.style,
      },
    }
  }

  render() {
    const { style, ...pprops } = this.props //eslint-disable-line no-unused-vars

    return <RSBadge style={this.styles.base} {...pprops} />
  }
}

Badge.defaultProps = {
  style: {},
}

Badge.propTypes = {
  style: PropTypes.object,
  theme: themeShape,
}

export default themeable(Badge)
