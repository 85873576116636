import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles'

class SonraiPageFooter extends React.PureComponent {
  render() {
    return (
      <div id="footer" style={styles.footer}>
        {this.props.children}
      </div>
    )
  }
}

SonraiPageFooter.propTypes = {
  children: PropTypes.node,
}

export default SonraiPageFooter
