import { put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import {
  COMPUTE_NODE_VIEW_QUERY,
  COMPUTE_INBOUND_PUBLIC_FILTERS_NODE_VIEW_QUERY,
  COMPUTE_INBOUND_PRIVATE_FILTERS_NODE_VIEW_QUERY,
  COMPUTE_INBOUND_PUBLIC_PATHS_NODE_VIEW_QUERY,
  COMPUTE_INBOUND_PRIVATE_PATHS_NODE_VIEW_QUERY,
  COMPUTE_OUTBOUND_PUBLIC_FILTERS_NODE_VIEW_QUERY,
  COMPUTE_OUTBOUND_PRIVATE_FILTERS_NODE_VIEW_QUERY,
  COMPUTE_OUTBOUND_PUBLIC_PATHS_NODE_VIEW_QUERY,
  COMPUTE_OUTBOUND_PRIVATE_PATHS_NODE_VIEW_QUERY,
  COMPUTE_INDIRECT_INBOUND_PUBLIC_FILTERS_NODE_VIEW_QUERY,
  COMPUTE_INDIRECT_INBOUND_PRIVATE_FILTERS_NODE_VIEW_QUERY,
  COMPUTE_INDIRECT_INBOUND_PUBLIC_PATHS_NODE_VIEW_QUERY,
  COMPUTE_INDIRECT_INBOUND_PRIVATE_PATHS_NODE_VIEW_QUERY,
  COMPUTE_SECURITY_GROUP_QUERY,
} from 'static-queries'

import {
  GET_COMPUTE_CONTENT,
  GET_INBOUND_PUBLIC_FILTERS,
  GET_INBOUND_PRIVATE_FILTERS,
  GET_INBOUND_PUBLIC_PATH,
  GET_INBOUND_PRIVATE_PATH,
  GET_OUTBOUND_PUBLIC_FILTERS,
  GET_OUTBOUND_PRIVATE_FILTERS,
  GET_OUTBOUND_PUBLIC_PATH,
  GET_OUTBOUND_PRIVATE_PATH,
  GET_INDIRECT_INBOUND_PUBLIC_FILTERS,
  GET_INDIRECT_INBOUND_PRIVATE_FILTERS,
  GET_INDIRECT_INBOUND_PUBLIC_PATH,
  GET_INDIRECT_INBOUND_PRIVATE_PATH,
  GET_COMPUTE_SECURITY_GROUPS,
} from './constants'
import {
  setComputeContent,
  setInboundPublicFilters,
  setInboundPrivateFilters,
  setInboundPublicPath,
  setInboundPrivatePath,
  setOutboundPublicFilters,
  setOutboundPrivateFilters,
  setOutboundPublicPath,
  setOutboundPrivatePath,
  setIndirectInboundPublicFilters,
  setIndirectInboundPrivateFilters,
  setIndirectInboundPublicPath,
  setIndirectInboundPrivatePath,
  setComputesecurityGroups,
  errComputeSecurityGroups
} from './actions'

function* getComputeContent(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Compute.items
    if (content === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setComputeContent(_.get(content, 0)))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getInboundPublicFilters(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_INBOUND_PUBLIC_FILTERS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setInboundPublicFilters(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getInboundPrivateFilters(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_INBOUND_PRIVATE_FILTERS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setInboundPrivateFilters(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getInboundPublicPath(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_INBOUND_PUBLIC_PATHS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setInboundPublicPath(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getInboundPrivatePath(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_INBOUND_PRIVATE_PATHS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setInboundPrivatePath(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getOutboundPublicFilters(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_OUTBOUND_PUBLIC_FILTERS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setOutboundPublicFilters(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getOutboundPrivateFilters(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_OUTBOUND_PRIVATE_FILTERS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setOutboundPrivateFilters(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getOutboundPublicPath(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_OUTBOUND_PUBLIC_PATHS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setOutboundPublicPath(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getOutboundPrivatePath(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_OUTBOUND_PRIVATE_PATHS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setOutboundPrivatePath(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getIndirectInboundPublicFilters(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_INDIRECT_INBOUND_PUBLIC_FILTERS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setIndirectInboundPublicFilters(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getIndirectInboundPrivateFilters(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_INDIRECT_INBOUND_PRIVATE_FILTERS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setIndirectInboundPrivateFilters(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getIndirectInboundPublicPath(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_INDIRECT_INBOUND_PUBLIC_PATHS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })
    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setIndirectInboundPublicPath(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getIndirectInboundPrivatePath(action) {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_INDIRECT_INBOUND_PRIVATE_PATHS_NODE_VIEW_QUERY}
      `,
      variables: {
        srn: action.payload,
      },
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })

    const content = results.data.Compute
    if (content.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setIndirectInboundPrivatePath(content))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getComputeSecurityGroups(action) {
  try {
    const client = yield getClient()
    const results = yield client.query({
      query: gql`
        ${COMPUTE_SECURITY_GROUP_QUERY}
      `,
      variables: { srn: action.payload },
    })
    const compute = _.get(results, ['data', 'Compute', 'items', 0])
    if (compute.items === null) {
      throw new Error('did not receive data.Compute.items[0]')
    }

    const { cloudType } = compute
    if (!cloudType) {
      throw new Error(`Compute ${action.payload} does not have cloudType`)
    }

    const securityGroups = []

    switch (cloudType) {
      // if it's 'aws', the security groups are in includedPolicy { items { /* here */} }
      case 'aws': {
        const networkPolcies = _.get(compute, ['includedInPolicy', 'items'])
        if (!networkPolcies) {
          throw new Error(
            `aws Compute ${
              action.payload
            } did recieve key includedInpolicy.items`
          )
        }
        networkPolcies.forEach(it => securityGroups.push(it))
        break
      }

      /*
       * if it's 'aws', the security groups are in
       * isAttachedToInterface {
       *   includedPolicy {
       *     items {
       *       # here
       *     }
       *   }
       * }
       */
      case 'azure': {
        const interfaces = _.get(compute, ['isAttachedToInterface', 'items'])
        if (!interfaces) {
          throw new Error(
            `aws Compute ${
              action.payload
            } did recieve key isAttachedToInterface.items`
          )
        }

        const allNetworkPolcies = interfaces.flatMap(interfaze => {
          const networkPolcies = _.get(interfaze, ['includedInPolicy', 'items'])
          if (!networkPolcies) {
            throw new Error(
              `aws Compute ${action.payload} interface ${
                interfaze.srn
              } did recieve key includedInpolicy.items`
            )
          }
          return networkPolcies
        })

        _.uniqBy(allNetworkPolcies, ({ srn }) => srn).forEach(it =>
          securityGroups.push(it)
        )
        break
      }

      default:
        // eslint-disable-next-line no-console
        console.warn(
          'Security groups currently not supported for cloudType: ' + cloudType
        )
    }
    yield put(setComputesecurityGroups(securityGroups))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      'There was a problem fetching security groups for resource: ',
      e
    )
    yield put(errComputeSecurityGroups())
  }
}

function* computeNodeSaga() {
  yield takeLatest(GET_COMPUTE_CONTENT, getComputeContent)
  yield takeLatest(GET_INBOUND_PUBLIC_FILTERS, getInboundPublicFilters)
  yield takeLatest(GET_INBOUND_PRIVATE_FILTERS, getInboundPrivateFilters)
  yield takeLatest(GET_INBOUND_PUBLIC_PATH, getInboundPublicPath)
  yield takeLatest(GET_INBOUND_PRIVATE_PATH, getInboundPrivatePath)
  yield takeLatest(GET_OUTBOUND_PUBLIC_FILTERS, getOutboundPublicFilters)
  yield takeLatest(GET_OUTBOUND_PRIVATE_FILTERS, getOutboundPrivateFilters)
  yield takeLatest(GET_OUTBOUND_PUBLIC_PATH, getOutboundPublicPath)
  yield takeLatest(GET_OUTBOUND_PRIVATE_PATH, getOutboundPrivatePath)

  yield takeLatest(
    GET_INDIRECT_INBOUND_PUBLIC_FILTERS,
    getIndirectInboundPublicFilters
  )
  yield takeLatest(
    GET_INDIRECT_INBOUND_PRIVATE_FILTERS,
    getIndirectInboundPrivateFilters
  )
  yield takeLatest(
    GET_INDIRECT_INBOUND_PUBLIC_PATH,
    getIndirectInboundPublicPath
  )
  yield takeLatest(
    GET_INDIRECT_INBOUND_PRIVATE_PATH,
    getIndirectInboundPrivatePath
  )
  yield takeLatest(GET_COMPUTE_SECURITY_GROUPS, getComputeSecurityGroups)
}

export default computeNodeSaga
