import {
  all,
  put,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects'
import _ from 'lodash'
import gql from 'graphql-tag'
import { push } from 'connected-react-router'
import qs from 'query-string'
import {
  policyProps,
  controlFrameworkProps,
  FETCH_CONTROL_FRAMEWORKS_QUERY,
  FETCH_POLICIES_QUERY,
  FETCH_POLICY_SEARCH_NAME_QUERY,
} from 'static-queries'

import { selectControlGroups } from './selectors'
import { getCurrentOrg } from 'auth/current-org'
import { SONRAI_ORG_NAME } from 'appConstants'
import { getClient } from 'apolloClient'
import {
  setPolicies,
  createPolicySuccess,
  updateControlGroupSuccess,
  setControlGroups,
  createControlGroupSuccess,
  deleteControlGroupSuccess,
  deletePolicySuccess,
  updatePolicySuccess,
  addPolicySuccess,
  removePolicySuccess,
  cloneFrameworkSuccess,
  setFrameworkEnabledSuccess,
  fetchControlGroups,
  setPolicyError,
  toggleEnabledOnSwimlanesSuccess,
  toggleSwimlaneOnCFsSuccess,
  toggleSwimlaneOnCFsError,
} from './actions'

import {
  FETCH_POLICIES,
  CREATE_POLICY,
  FETCH_CONTROL_GROUPS,
  UPDATE_CONTROL_GROUP,
  CREATE_CONTROL_GROUP,
  DELETE_CONTROL_GROUP,
  DELETE_POLICY,
  UPDATE_POLICY,
  ADD_POLICY,
  REMOVE_POLICY,
  CLONE_FRAMEWORK,
  SET_FRAMEWORK_ENABLED,
  TOGGLE_ENABLED_ON_SWIMLANES,
  TOGGLE_SWIMLANE_ON_CFS,
} from './constants'

export function* getControlGroups() {
  try {
    const client = getClient()
    const result = yield client.query({
      forceFetch: true,
      fetchPolicy: 'network-only',
      query: gql`
        ${FETCH_CONTROL_FRAMEWORKS_QUERY}
      `,
    })

    const controlGroups = _.get(result, ['data', 'ControlFrameworks', 'items'])

    if (!controlGroups) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(setControlGroups(controlGroups))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching control groups', e)
  }
}

function* addControlGroup(action) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation addControlFramework(
          $controlFramework: ControlframeworkCreator!
        ) {
          CreateControlframework(value: $controlFramework) {
            ${controlFrameworkProps}
          }
        }
      `,
      variables: {
        controlFramework: {
          title: action.payload.title,
          description: action.payload.description,
          shortDescription: action.payload.friendlyName,
          enabled: true,
        },
      },
    })

    const createdControlGroup = result.data.CreateControlframework

    yield put(createControlGroupSuccess(createdControlGroup))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error creating a control group', e)
  }
}

function* updateControlGroup(action) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`mutation updateControlFramework(
        $controlFramework: ControlframeworkUpdater!
      ) {
        UpdateControlframework (srn: "${action.payload.srn}", value: $controlFramework) {
          ${controlFrameworkProps}
        }
      }
      `,
      variables: {
        controlFramework: {
          title: action.payload.title,
          shortDescription: action.payload.friendlyName,
          description: action.payload.description,
        },
      },
    })

    const updatedControlGroup = result.data.UpdateControlframework
    yield put(updateControlGroupSuccess(updatedControlGroup))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating control group', e)
  }
}

export function* deleteControlGroup(action) {
  try {
    const client = getClient()
    yield client.mutate({
      mutation: gql`mutation deleteControlFramework {
        DeleteControlframework (srn: "${action.payload}")
      }
      `,
    })
    yield put(deleteControlGroupSuccess(action.payload))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error deleting control group', e)
  }
}

export function* deletePolicy(action) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`mutation deletePolicy {
        DeleteControlpolicy (srn: "${action.payload}")
      }
      `,
    })

    if (result.errors) {
      yield put(
        setPolicyError({
          msg: _.get(result.errors, [0, 'message'], 'Error Deleting Policy'),
          srn: action.payload,
        })
      )
    } else {
      yield put(deletePolicySuccess(action.payload))
      yield put(fetchControlGroups())
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error deleting policy', e)
  }
}

export function* getPolicies() {
  try {
    const client = getClient()
    const result = yield client.query({
      forceFetch: true,
      fetchPolicy: 'network-only',
      query: gql`
        ${FETCH_POLICIES_QUERY}
      `,
    })

    if (result.data.ControlPolicies.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const policies = result.data.ControlPolicies.items.map(pol => {
      if (typeof pol.evalCriteria === 'string') {
        try {
          pol.evalCriteria = JSON.parse(pol.evalCriteria)
        } catch (e) {
          pol.evalCriteria = {}
        }
      }

      return pol
    })

    yield put(setPolicies(policies))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching policies', e)
  }
}

function* createPolicy(action) {
  // const bots = action.payload.bots.map(bot => bot.value) --- an arr of srns
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation addControlPolicy($controlPolicy: ControlpolicyCreator!) {
          CreateControlpolicy(value: $controlPolicy) {
            ${policyProps}
          }
        }
      `,
      variables: {
        controlPolicy: {
          alertingLevelNumeric: action.payload.alertingLevelNumeric,
          title: action.payload.title,
          description: action.payload.description,
          evalCriteria: JSON.stringify(action.payload.evalCriteria),
          contains: {
            add: action.payload.searchId,
          },
          containedByControlFramework: {
            add: action.payload.controlGroupId,
          },
          //bots
        },
      },
    })
    if (result.errors) {
      yield put(
        setPolicyError({
          msg: _.get(result.errors, [0, 'message'], 'Error Creating Policy'),
          srn: '',
        })
      )
    } else {
      const createdPolicy = result.data.CreateControlpolicy
      createdPolicy.evalCriteria = JSON.parse(createdPolicy.evalCriteria)
      yield put(createPolicySuccess(createdPolicy))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error creating a policy', e)
  }
}

function* addPolicy(action) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`mutation addControlPolicy {
        UpdateControlframework (srn: "${action.payload.controlGroupId}",
          value: {
            contains: {
              add: "${action.payload.policyId}"
            }
        }) {
          ${controlFrameworkProps}
        }
      }
      `,
    })

    if (result.errors) {
      yield put(
        setPolicyError({
          msg: _.get(result.errors, [0, 'message'], 'Error Adding Policy'),
          srn: '',
        })
      )
    } else {
      yield put(
        addPolicySuccess(_.get(result, ['data', 'UpdateControlframework']))
      )
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error creating a policy', e)
  }
}

function* removePolicy(action) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`mutation removeControlPolicy {
        UpdateControlframework (srn: "${action.payload.controlGroupId}",
          value: {
            contains: {
              remove: "${action.payload.policyId}"
            }
        }) {
          ${controlFrameworkProps}
        }
      }
      `,
    })

    if (result.errors) {
      yield put(
        setPolicyError({
          msg: _.get(result.errors, [0, 'message'], 'Error Adding Policy'),
          srn: action.payload.policyId,
        })
      )
    } else {
      yield put(
        removePolicySuccess({
          cf: _.get(result, ['data', 'UpdateControlframework']),
          policyId: action.payload.policyId,
          srn: action.payload.controlGroupId,
        })
      )
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error creating a policy', e)
  }
}

function* updatePolicy(action) {
  try {
    const client = getClient()

    const oldPolicySearch = yield client.query({
      forceFetch: true,
      fetchPolicy: 'network-only',
      query: gql`
        ${FETCH_POLICY_SEARCH_NAME_QUERY}
      `,
      variables: {
        policySrn: action.payload.srn,
      },
    })

    const oldSearchId = _.get(oldPolicySearch, [
      'data',
      'ControlPolicies',
      'items',
      0,
      'contains',
      'items',
      0,
      'srn',
    ])

    const result = yield client.mutate({
      mutation: gql`mutation updateControlPolicy($controlPolicy: ControlpolicyUpdater!) {
        UpdateControlpolicy (srn: "${action.payload.srn}", value: $controlPolicy) {
          ${policyProps}
        }
      }
      `,
      variables: {
        controlPolicy: {
          title: action.payload.title,
          description: action.payload.description,
          remediationType: action.payload.remediationType,
          alertingLevelNumeric: action.payload.alertingLevelNumeric,
          evalCriteria: JSON.stringify(action.payload.evalCriteria),
          contains:
            action.payload.searchId && oldSearchId !== action.payload.searchId
              ? {
                  remove: oldSearchId,
                  add: action.payload.searchId,
                }
              : undefined,
        },
      },
    })

    if (result.errors) {
      yield put(
        setPolicyError({
          msg: _.get(result.errors, [0, 'message'], 'Error Adding Policy'),
          srn: action.payload.policyId,
        })
      )
    } else {
      const updatedPolicy = _.get(result, ['data', 'UpdateControlpolicy'])
      if (updatedPolicy) {
        updatedPolicy.evalCriteria = JSON.parse(updatedPolicy.evalCriteria)
      }
      yield put(updatePolicySuccess(updatedPolicy))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating control policy', e)
  }
}

function* cloneAndEnableFramework(action) {
  try {
    const client = getClient()
    const cloneResult = yield client.mutate({
      mutation: gql`
        mutation cloneControlFramework {
          CloneControlframework(
            srn: "${action.payload}"
          ) {
            srn
          }
        }
      `,
    })

    const newSrn = cloneResult.data.CloneControlframework.srn

    //The clone mutation doesn't update the enabled property, so manually enable the new CF
    const result = yield client.mutate({
      mutation: gql`mutation updateControlFramework(
        $controlFramework: ControlframeworkUpdater!
      ) {
        UpdateControlframework (srn: "${newSrn}", value: $controlFramework) {
          ${controlFrameworkProps}
        }
      }
      `,
      variables: {
        controlFramework: {
          enabled: true,
        },
      },
    })

    const createdControlGroup = result.data.UpdateControlframework

    yield put(createControlGroupSuccess(createdControlGroup))
    yield put(cloneFrameworkSuccess(createdControlGroup.srn))
    yield put(cloneFrameworkSuccess(action.payload))
    yield put(setFrameworkEnabledSuccess(createdControlGroup.srn))
    yield put(updateControlGroupSuccess(createdControlGroup))
    return createdControlGroup.srn
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error cloning a control group', e)
  }
}

function* cloneAndEnableFrameworkOnSwimlane({ payload }) {
  try {
    const client = getClient()
    const cloneResult = yield client.mutate({
      mutation: gql`
        mutation cloneControlFramework {
          CloneControlframework(
            srn: "${payload.srn}"
          ) {
            srn
          }
        }
      `,
    })

    if (!cloneResult.data.CloneControlframework || cloneResult.errors) {
      throw new Error('Toggle Enabled mutation error')
    }

    const newSrn = cloneResult.data.CloneControlframework.srn

    //The clone mutation doesn't update the enabled property, so manually enable the new CF
    const result = yield client.mutate({
      mutation: gql`mutation updateControlFramework(
        $controlFramework: ControlframeworkUpdater!, $srn: ID!
      ) {
        UpdateControlframework (srn: $srn, value: $controlFramework) {
          ${controlFrameworkProps}
        }
      }
      `,
      variables: {
        srn: newSrn,
        controlFramework: {
          enabled: true,
          swimlaneSRNs: {
            add: payload.add ? payload.add : [],
            remove: payload.remove ? payload.remove : [],
          },
        },
      },
    })

    const createdControlGroup = result.data.UpdateControlframework
    if (!createdControlGroup || result.errors) {
      throw new Error('Toggle Enabled mutation error')
    }

    if (payload.redirect) {
      yield put(
        push({
          pathname: '/App/ControlCenter/ControlGroup',
          search: qs.stringify({
            controlGroupId: newSrn,
          }),
        })
      )
    }

    yield put(
      toggleEnabledOnSwimlanesSuccess({
        srn: payload.srn,
        cf: createdControlGroup,
      })
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error enabling a control group with swimanes', e)
  }
}

function* toggleSwimlaneOnCFs({ payload }) {
  try {
    const client = getClient()

    const addResults = yield all([
      ...payload.add.map(cfSrn =>
        client.mutate({
          mutation: gql`
            mutation assignControlFramework(
              $controlFrameworkSrn: String!,
              $add: [String],
              $remove: [String]
            ) {
              AssignControlFrameworksToSwimlanes(input: [
                {
                  controlFrameworkSrn: $controlFrameworkSrn,
                  swimlaneSrns: {
                    add: $add,
                    remove: $remove,
                  },
                }
              ]) {
                ${controlFrameworkProps}
              }
            }
          `,
          variables: {
            controlFrameworkSrn: cfSrn,
            add: [payload.srn],
            remove: [],
          },
        })
      ),
    ])

    const removeResults = yield all([
      ...payload.remove.map(cfSrn =>
        client.mutate({
          mutation: gql`
            mutation assignControlFramework(
              $controlFrameworkSrn: String!,
              $add: [String],
              $remove: [String]
            ) {
              AssignControlFrameworksToSwimlanes(input: [
                {
                  controlFrameworkSrn: $controlFrameworkSrn,
                  swimlaneSrns: {
                    add: $add,
                    remove: $remove,
                  },
                }
              ]) {
                ${controlFrameworkProps}
              }
            }
          `,
          variables: {
            controlFrameworkSrn: cfSrn,
            add: [],
            remove: [payload.srn],
          },
        })
      ),
    ])

    if (addResults && addResults.length > 0) {
      for (let addResult of addResults) {
        const cf = _.get(
          addResult,
          ['data', 'AssignControlFrameworksToSwimlanes', 0],
          null
        )
        if (cf) {
          yield put(toggleEnabledOnSwimlanesSuccess({ srn: cf.srn, cf }))
        } else if (addResult.errors) {
          throw new Error(
            _.get(
              addResult,
              ['errors', 0, 'message'],
              'Toggle Enabled mutation error'
            )
          )
        }
      }
    }

    if (removeResults && removeResults.length > 0) {
      for (let removeResult of removeResults) {
        const cf = _.get(
          removeResult,
          ['data', 'AssignControlFrameworksToSwimlanes', 0],
          null
        )

        if (cf) {
          yield put(toggleEnabledOnSwimlanesSuccess({ srn: cf.srn, cf }))
        } else if (removeResult.errors) {
          throw new Error(
            _.get(
              removeResult,
              ['errors', 0, 'message'],
              'Toggle Enabled mutation error'
            )
          )
        }
      }
    }

    yield put(toggleSwimlaneOnCFsSuccess({ srn: payload.srn }))
  } catch (e) {
    yield put(toggleSwimlaneOnCFsError({ srn: payload.srn, error: e.message }))
    // eslint-disable-next-line no-console
    console.error('Error enabling swimlanes on a control group', e)
  }
}

function* toggleEnabledOnSwimlane({ payload }) {
  const frameworks = yield select(selectControlGroups)
  const cf = frameworks.get(payload.srn)
  const org = cf.getIn(['ownedByOrganization', 'items', 0, 'name'])

  if (org === SONRAI_ORG_NAME && getCurrentOrg() !== SONRAI_ORG_NAME) {
    //We cannot modify CFs that are owned by sonrai, so to enable them we actually have to clone them first.
    yield call(cloneAndEnableFrameworkOnSwimlane, {
      payload,
    })

    return
  }

  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation assignControlFramework(
          $controlFrameworkSrn: String!,
          $add: [String],
          $remove: [String]
        ) {
          AssignControlFrameworksToSwimlanes(input: [
            {
              controlFrameworkSrn: $controlFrameworkSrn,
              swimlaneSrns: {
                add: $add,
                remove: $remove,
              },
            }
          ]) {
            ${controlFrameworkProps}
          }
        }
      `,
      variables: {
        controlFrameworkSrn: payload.srn,
        add: payload.add ? payload.add : [],
        remove: payload.remove ? payload.remove : [],
      },
    })

    const stuff = _.get(
      result,
      ['data', 'AssignControlFrameworksToSwimlanes', 0],
      null
    )
    if (stuff) {
      yield put(
        toggleEnabledOnSwimlanesSuccess({ srn: payload.srn, cf: stuff })
      )
    } else if (result.errors) {
      throw new Error('Toggle Enabled mutation error')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error enabling swimlanes on a control group', e)
  }
}

export function* setFrameworkEnabled(action) {
  const frameworks = yield select(selectControlGroups)
  const cf = frameworks.get(action.payload.srn)
  const org = cf.getIn(['ownedByOrganization', 'items', 0, 'name'])

  if (org === SONRAI_ORG_NAME && getCurrentOrg() !== SONRAI_ORG_NAME) {
    //We cannot modify CFs that are owned by sonrai, so to enable them we actually have to clone them first.
    const newSrn = yield call(cloneAndEnableFramework, {
      payload: action.payload.srn,
    })

    if (action.payload.redirect) {
      yield put(
        push({
          pathname: '/App/ControlCenter/ControlGroup',
          search: qs.stringify({
            controlGroupId: newSrn,
          }),
        })
      )
    }

    return
  }

  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation assignControlFramework(
          $controlFrameworkSrn: String!,
          $enabled: Boolean,
        ) {
          AssignControlFrameworksToSwimlanes(input: [
            {
              controlFrameworkSrn: $controlFrameworkSrn,
              enabled: $enabled
            }
          ]) {
            ${controlFrameworkProps}
          }
        }
      `,
      variables: {
        controlFrameworkSrn: action.payload.srn,
        enabled: action.payload.enabled,
      },
    })

    const updatedControlGroup =
      result.data.AssignControlFrameworksToSwimlanes[0]

    yield put(setFrameworkEnabledSuccess(action.payload.srn))
    yield put(updateControlGroupSuccess(updatedControlGroup))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating control group', e)
  }
}

function* controlFrameworkDataSaga() {
  yield all([
    takeLatest(FETCH_POLICIES, getPolicies),
    takeLatest(CREATE_POLICY, createPolicy),
    takeLatest(FETCH_CONTROL_GROUPS, getControlGroups),
    takeLatest(UPDATE_CONTROL_GROUP, updateControlGroup),
    takeLatest(CREATE_CONTROL_GROUP, addControlGroup),
    takeEvery(DELETE_CONTROL_GROUP, deleteControlGroup),
    takeEvery(DELETE_POLICY, deletePolicy),
    takeLatest(UPDATE_POLICY, updatePolicy),
    takeLatest(ADD_POLICY, addPolicy),
    takeEvery(REMOVE_POLICY, removePolicy),
    takeLatest(CLONE_FRAMEWORK, cloneAndEnableFramework),
    takeEvery(SET_FRAMEWORK_ENABLED, setFrameworkEnabled),
    takeEvery(TOGGLE_ENABLED_ON_SWIMLANES, toggleEnabledOnSwimlane),
    takeEvery(TOGGLE_SWIMLANE_ON_CFS, toggleSwimlaneOnCFs),
  ])
}

export default controlFrameworkDataSaga
