import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { isImmutable } from 'immutable'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import SectionHeader from 'components/SectionHeader'
import {
  changeSimilarTicketsFilters,
  fetchSimilarTickets,
} from 'containers/TicketDetailsData/actions'
import CenterContent from 'components/CenterContent'
import { selectSimilarTickets } from 'containers/TicketDetailsData/selectors'
import {
  selectSonraiUsers,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'

import TicketList from 'containers/Tickets/components/TicketList'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

import messages from '../messages'

/**
 * Transform to state from:
 * { severityCategory: high }
 * to
 * { severityCategory: { value: high } }
 */
function filterToReduxState(filter) {
  if (isImmutable(filter)) {
    return filterToReduxState(filter.toJS())
  }
  return _.mapValues(filter, value => ({ value }))
}

/**
 * Transform to state from:
 * { severityCategory: { value: high } }
 * to
 * { severityCategory: high }
 */
function filterFromReduxState(filter) {
  if (isImmutable(filter)) {
    return filterFromReduxState(filter.toJS())
  }
  return _.mapValues(filter, ({ value }) => value)
}

export class SimilarTicketsTab extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.fetchTickets()
    this.state = {
      filter: filterFromReduxState(props.similarTickets.get('filter')),
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filter != this.state.filter) {
      this.props.changeSimilarTicketsFilters({
        ticket: this.props.ticket.toJS(),
        filter: filterToReduxState(this.state.filter),
      })
    }
  }

  fetchTickets = () => {
    this.props.fetchSimilarTickets({ ticket: this.props.ticket.toJS() })
  }

  handleFilterChange = value => {
    this.setState({ filter: value })
  }

  render() {
    if (this.props.similarTickets.get('loading')) {
      return (
        <CenterContent>
          <SquareLoadingAnimation />
        </CenterContent>
      )
    }

    if (this.props.similarTickets.get('error')) {
      return (
        <CenterContent>
          <h5>
            <DynamicFormattedMessage {...messages.fetchSimilarTicketsError} />
          </h5>
        </CenterContent>
      )
    }

    return (
      <div>
        <SectionHeader small>Similar Tickets</SectionHeader>
        <TicketList
          ticketListFilters={this.state.filter}
          handleTicketListFilters={this.handleFilterChange}
          ticketList={this.props.similarTickets}
          noModal
          refreshData={this.fetchTickets}
          {...this.props}
        />
      </div>
    )
  }
}

SimilarTicketsTab.propTypes = {
  // ~ passed props ~
  policies: ImmutablePropTypes.map,
  ticket: ImmutablePropTypes.map.isRequired,

  // ~ bound action creators ~
  changeSimilarTicketsFilters: PropTypes.func.isRequired,
  fetchSimilarTickets: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,

  // ~ props from redux ~
  similarTickets: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    filter: ImmutablePropTypes.map,
  }),
  users: selectSonraiUsers,
  usersLoading: selectSonraiUsersLoading,
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { changeSimilarTicketsFilters, fetchSimilarTickets, push },
    dispatch
  )
}

const mapStateToProps = createStructuredSelector({
  similarTickets: selectSimilarTickets,
  users: selectSonraiUsers,
  usersLoading: selectSonraiUsersLoading,
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)(SimilarTicketsTab)
