import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { Map } from 'immutable'
import SonraiBadge from 'components/SonraiBadge'
import { SONRAI_ORG_NAME } from 'appConstants'
import {
  selectSonraiUsers,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'
import ProfileIcon from 'components/ProfileIcon'
import TextLink from 'components/TextLink'
import qs from 'query-string'
import { TextLineShimmer } from 'components/ShimmerLoader'
import { stripTags } from 'utils/sonraiUtils'

class UserWidget extends React.Component {
  styles = {
    container: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      alignItems: 'center',
    },
  }

  getPushLink = srn => {
    return {
      pathname: `/App/UserManagement/User`,
      search: qs.stringify({
        srn: srn,
      }),
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div style={this.styles.container}>
          <ProfileIcon width={'30px'} height={'30px'} />
          <div style={this.styles.name}>
            <TextLineShimmer />
          </div>
        </div>
      )
    }

    if (!this.props.srn || typeof this.props.srn !== 'string') {
      return '-'
    }

    if (
      this.props.srn.includes('SonraiComponent') ||
      !this.props.srn.startsWith('srn:') ||
      this.props.srn.includes(SONRAI_ORG_NAME)
    ) {
      return <SonraiBadge />
    }

    const user = this.props.users.get(this.props.srn) || Map()

    if (user.isEmpty()) {
      return (
        <span
          style={{ fontWeight: '400', fontStyle: 'italic' }}
        >{`Unavailable - ${this.props.srn}`}</span>
      )
    }

    return (
      <div style={this.styles.container}>
        <ProfileIcon
          width={this.props.table ? '20px' : '30px'}
          height={this.props.table ? '20px' : '30px'}
          img={user.get('avatarUrl')}
        />
        <div style={{ fontSize: this.props.table ? '0.9rem' : '1.2rem' }}>
          {this.props.noLink ? (
            stripTags(user.get('name'))
          ) : (
            <TextLink to={this.getPushLink(user.get('srn'))}>
              {stripTags(user.get('name'))}
            </TextLink>
          )}
        </div>
      </div>
    )
  }
}

UserWidget.propTypes = {
  users: ImmutablePropTypes.map,
  loading: PropTypes.bool,
  srn: PropTypes.string,
  table: PropTypes.bool,
  noLink: PropTypes.bool,
}
const mapStateToProps = createStructuredSelector({
  users: selectSonraiUsers,
  loading: selectSonraiUsersLoading,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(UserWidget)
