import { createSelector } from 'reselect'
import { List, Map } from 'immutable'
import _ from 'lodash'
import qs from 'query-string'

export const selectQueryStringTicketSrn = (state, props) => {
  return qs.parse(_.get(props, ['location', 'search'], '')).ticketSrn
}

const selectRapSheetDomain = state => state.get('rapSheet') || Map()

export const selectResourcesLoading = createSelector(selectRapSheetDomain, sc =>
  sc.get('resourcesLoading')
)

export const selectResources = createSelector(
  selectRapSheetDomain,
  sc => sc.get('resources') || Map()
)

export const selectAlertListLoading = createSelector(selectRapSheetDomain, sc =>
  sc.get('alertListLoading')
)

export const selectSelectedResourceAlerts = createSelector(
  selectRapSheetDomain,
  sc => sc.get('selectedResourceAlerts') || List()
)

export const selectCategoryRollupData = createSelector(
  selectRapSheetDomain,
  sc => sc.get('categoryRollupData') || List()
)

export const selectCategoryRollupLoading = createSelector(
  selectRapSheetDomain,
  sc => sc.get('categoryRollupLoading')
)

export const selectIsSunburstViewMulti = createSelector(
  selectRapSheetDomain,
  sc => sc.get('isSunburstViewMulti')
)
