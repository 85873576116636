export const FETCH_CUSTOM_CLASSIFIERS =
  'app/DataClassifierData/FETCH_CUSTOM_CLASSIFIERS'
export const SET_CUSTOM_CLASSIFIERS =
  'app/DataClassifierData/SET_CUSTOM_CLASSIFIERS'
export const SET_CUSTOM_CLASSIFIERS_ERROR =
  'app/DataClassifierData/SET_CUSTOM_CLASSIFIERS_ERROR'

export const CREATE_CUSTOM_CLASSIFIER =
  'app/DataClassifierData/CREATE_CUSTOM_CLASSIFIER'
export const CREATE_CUSTOM_CLASSIFIER_SUCCESS =
  'app/DataClassifierData/CREATE_CUSTOM_CLASSIFIER_SUCCESS'
export const CREATE_CUSTOM_CLASSIFIER_ERROR =
  'app/DataClassifierData/CREATE_CUSTOM_CLASSIFIER_ERROR'

export const UPDATE_CUSTOM_CLASSIFIERS =
  'app/DataClassifierData/UPDATE_CUSTOM_CLASSIFIERS'
export const UPDATE_CUSTOM_CLASSIFIERS_SUCCESS =
  'app/DataClassifierData/UPDATE_CUSTOM_CLASSIFIERS_SUCCESS'
export const UPDATE_CUSTOM_CLASSIFIERS_ERROR =
  'app/DataClassifierData/UPDATE_CUSTOM_CLASSIFIERS_ERROR'

export const DELETE_CUSTOM_CLASSIFIERS =
  'app/DataClassifierData/DELETE_CUSTOM_CLASSIFIERS'
export const DELETE_CUSTOM_CLASSIFIERS_SUCCESS =
  'app/DataClassifierData/DELETE_CUSTOM_CLASSIFIERS_SUCCESS'
export const DELETE_CUSTOM_CLASSIFIERS_ERROR =
  'app/DataClassifierData/DELETE_CUSTOM_CLASSIFIERS_ERROR'
