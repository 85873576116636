import React from 'react'

export const ChartLegendEntry = ({ style, color, title }) => {
  const wrapperStyle = {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    marginBottom: '5px',
    ...style,
  }

  const legendTitleStyle = {
    fontSize: '12px',
  }

  const styledTitle = (
    <div style={wrapperStyle}>
      <span
        style={{
          height: '15px',
          width: '15px',
          borderRadius: '50%',
          display: 'inline-block',
          marginRight: '0.3em',
          backgroundColor: color,
        }}
      />
      <span style={legendTitleStyle}>{title}</span>
    </div>
  )

  return styledTitle
}

export default ChartLegendEntry
