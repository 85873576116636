import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import {
  SET_DATA_CONTAINER_CONTENT,
  GET_DATA_CONTAINER_CONTENT,
  SET_ACTIONS_PERFORMED,
  GET_ACTIONS_PERFORMED,
} from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  actionsPerformed: [],
  loadingActions: false,
  dataContainerContent: {},
  isLoading: false,
})

const dataContainerNodeViewCardLayoutReducer = handleActions(
  {
    [GET_ACTIONS_PERFORMED]: state => state.set('loadingActions', true),
    [SET_ACTIONS_PERFORMED]: (state, { payload }) => {
      return state
        .set('actionsPerformed', fromJS(payload))
        .set('loadingActions', false)
    },
    [GET_DATA_CONTAINER_CONTENT]: state => state.set('isLoading', true),
    [SET_DATA_CONTAINER_CONTENT]: (state, { payload }) => {
      return state
        .set('dataContainerContent', fromJS(payload))
        .set('isLoading', false)
    },
    [CLEAR_DATA]: state => state.set('dataContainerContent', Map()),
  },
  initialState
)

export default dataContainerNodeViewCardLayoutReducer
