/*
 *
 * SonraiData reducer
 *
 */
import { fromJS, Map, merge, List } from 'immutable'

import {
  SET_TYPES,
  SET_RELATIONS,
  SET_SAVED_SEARCHES,
  SET_ALLOWED_ROOT_QUERIES,
  SET_SAVED_SEARCH,
  SET_SONRAI_SEARCHES,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SONRAI_SEARCHES,
  FETCH_TAGS_SUCCESS,
  LOAD_TAG,
  SET_PIVOT,
  FETCH_DATA_CONTAINERS_SUCCESS,
  SET_QUERY_NAMES,
  SET_GRAPHQL_SCHEMA,
  REMOVE_SAVED_SEARCH,
  SET_HAS_COLLECTORS,
  FETCH_SAVED_SEARCHES,
  START_UPDATE_DATA_POLL,
  SET_MONITORED_RESOURCES_COUNT,
  FETCH_SWIMLANES,
  FETCH_SWIMLANES_SUCCESS,
  ADD_SWIMLANE,
  REMOVE_SWIMLANE_SUCCESS,
  UPDATE_SWIMLANE,
  SET_GLOBAL_ERROR,
  CHECK_IF_HAS_COLLECTORS,
  FETCH_SONRAI_USERS,
  FETCH_SONRAI_USERS_SUCCESS,
  UPDATE_SONRAI_USER,
  UPDATE_SONRAI_USER_SUCCESS,
  GET_ALL_ROLES,
  SET_ALL_ROLES,
  ERR_ALL_ROLES,
  UPDATE_USER_ROLES,
  UPDATE_USER_ROLES_SUCCESS,
  GET_BOTS,
  SET_BOTS,
  CREATE_BOT,
  CREATE_BOT_SUCCESS,
  VALIDATE_SOURCE_URL,
  VALIDATE_SOURCE_URL_SUCCESS,
  CLEAR_SOURCE_URL_VALIDATION,
  HANDLE_DELETE_BOTS,
  HANDLE_DELETE_BOTS_SUCCESS,
  UPDATE_BOT_ASSIGNMENT,
  UPDATE_BOT_ASSIGNMENT_SUCCESS,
  GET_EXEMPTED_IDENTITIES,
  SET_EXEMPTED_IDENTITIES,
  SET_EXEMPTED_IDENTITIES_ERROR,
  DELETE_EXEMPTED_IDENTITIES,
  DELETE_EXEMPTED_IDENTITIES_SUCCESS,
  ADD_EXEMPTED_IDENTITIES,
  ADD_EXEMPTED_IDENTITIES_SUCCESS,
  SET_OBJECTIVES,
  FETCH_OBJECTIVES,
  FETCH_OBJECTIVES_ERROR,
  ENABLE_OBJECTIVE,
  ENABLE_ALL_OBJECTIVES,
  ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE,
  ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_SUCCESS,
  ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_ERROR,
  ENABLE_ALL_OBJECTIVES_SUCCESS,
  ENABLE_ALL_OBJECTIVES_ERROR,
  ENABLE_OBJECTIVE_SUCCESS,
  ENABLE_OBJECTIVE_ERROR,
  FETCH_SONRAI_CONFIG,
  SET_SONRAI_CONFIG,
} from './constants'
import { handleActions } from 'redux-actions'
import { exists } from 'utils/sonraiUtils'

const initialState = fromJS({
  allowedRootQueries: [],
  queryTypes: {},
  queryRelations: {},
  queryNames: {},
  globalError: null,
  savedSearches: {},
  savedSearchesLoading: false,
  savedSearchesDetailsLoaded: false,
  sonraiSearches: {},
  sonraiSearchesLoading: false,
  swimlanes: undefined,
  swimlanesLoading: false,
  accounts: [],
  subscriptions: [],
  tags: [],
  pivot: {},
  dataContainers: [],
  schema: undefined,
  objectives: [],
  objectivesError: null,
  objectivesLoading: false,
  enablingObjectives: List(),
  enablingAllObjectives: false,
  enablingAllObjectivesForSwimlane: null,
  hasCollectors: null,
  monitoredResourcesCount: null,
  checkCollectorsLoading: false,
  sonraiUsers: null,
  sonraiUsersLoading: false,
  updatingSonraiUser: Map(),
  allRoles: {
    loading: false,
    error: false,
    data: [],
  },
  updatingUserRoles: false,
  bots: { data: [], isLoading: false },
  isUpdatingBotAssignment: false,
  isCreatingBot: false,
  sourceUrlValidation: { isValid: undefined, isValidating: false },
  permissions: List(),
  exemptedIdentities: null,
  exemptedIdentitiesError: null,
  exemptedIdentitiesLoading: false,
  exemptedIdentitiesDeleting: Map(),
  exemptedIdentitiesAdding: false,
  sonraiConfigLoading: false,
})

const sonraiDataReducer = handleActions(
  {
    [ADD_SWIMLANE]: (state, { payload }) => {
      const newSwimlane = fromJS(payload)

      return state.setIn(['swimlanes', newSwimlane.get('title')], newSwimlane)
    },
    [FETCH_SAVED_SEARCHES]: state => state.set('savedSearchesLoading', true),
    [FETCH_ACCOUNTS_SUCCESS]: (state, { payload }) =>
      state.set('accounts', payload),
    [FETCH_SUBSCRIPTIONS_SUCCESS]: (state, { payload }) =>
      state.set('subscriptions', payload),
    [FETCH_DATA_CONTAINERS_SUCCESS]: (state, { payload }) =>
      state.set('dataContainers', payload),
    [FETCH_SWIMLANES]: state => state.set('swimlanesLoading', true),
    [FETCH_SWIMLANES_SUCCESS]: (state, { payload }) =>
      state.set('swimlanes', payload).set('swimlanesLoading', false),
    [FETCH_TAGS_SUCCESS]: (state, { payload }) => state.set('tags', payload),
    [LOAD_TAG]: (state, { payload }) => {
      if (state.get('tags').includes(payload)) {
        return state
      }

      return state.update('tags', oldTags => oldTags.push(payload))
    },
    [SET_GLOBAL_ERROR]: (state, { payload }) =>
      state.set('globalError', payload),
    [SET_GRAPHQL_SCHEMA]: (state, { payload }) => state.set('schema', payload),
    [SET_ALLOWED_ROOT_QUERIES]: (state, { payload }) =>
      state.set('allowedRootQueries', fromJS(payload)),
    [CHECK_IF_HAS_COLLECTORS]: state =>
      state.set('checkCollectorsLoading', true),
    [SET_HAS_COLLECTORS]: (state, { payload }) =>
      state.set('checkCollectorsLoading', false).set('hasCollectors', payload),
    [SET_MONITORED_RESOURCES_COUNT]: (state, { payload }) =>
      state.set('monitoredResourcesCount', payload),
    [SET_SAVED_SEARCHES]: (state, { payload }) =>
      state
        .set('savedSearches', fromJS(payload.results))
        .set('savedSearchesDetailsLoaded', payload.withDetails)
        .set('savedSearchesLoading', false),
    [FETCH_SONRAI_SEARCHES]: state => state.set('sonraiSearchesLoading', true),
    [SET_SONRAI_SEARCHES]: (state, { payload }) =>
      state
        .set('sonraiSearches', fromJS(payload))
        .set('sonraiSearchesLoading', false),
    [SET_TYPES]: (state, { payload }) =>
      state.set('queryTypes', fromJS(payload)),
    [SET_RELATIONS]: (state, { payload }) =>
      state.set('queryRelations', fromJS(payload)),
    [SET_QUERY_NAMES]: (state, { payload }) =>
      state.set('queryNames', fromJS(payload)),
    [REMOVE_SAVED_SEARCH]: (state, { payload }) =>
      state.deleteIn(['savedSearches', payload]),
    [REMOVE_SWIMLANE_SUCCESS]: (state, { payload }) =>
      state.deleteIn(['swimlanes', payload]),
    [SET_SAVED_SEARCH]: (state, { payload }) =>
      state.setIn(['savedSearches', payload.sid], fromJS(payload)),
    [SET_PIVOT]: (state, { payload }) => {
      if (!exists(payload.value)) {
        return state.deleteIn(['pivot', payload.type])
      } else {
        return state.setIn(['pivot', payload.type], fromJS(payload.value))
      }
    },
    [START_UPDATE_DATA_POLL]: (state, { payload: { permissions } }) =>
      state
        .set('savedSearchesLoading', true)
        .set('swimlanesLoading', true)
        .set('permissions', fromJS(permissions)),
    [UPDATE_SWIMLANE]: (state, { payload }) => {
      let newState = state
      const newSwimlane = payload.swimlane
      const oldSwimlane = state
        .get('swimlanes', Map())
        .find(swimlane => swimlane.get('srn') === payload.srn, null, Map())

      if (oldSwimlane.get('title') !== newSwimlane.title) {
        newState = newState.deleteIn(['swimlanes', oldSwimlane.get('title')])
      }

      return newState.setIn(
        ['swimlanes', newSwimlane.title],
        fromJS(newSwimlane).set('srn', payload.srn)
      )
    },
    [UPDATE_SONRAI_USER]: (state, { payload }) =>
      state.setIn(['updatingSonraiUser', payload.srn], true),
    [UPDATE_SONRAI_USER_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['sonraiUsers', payload.srn], fromJS(payload.guy))
        .deleteIn(['updatingSonraiUser', payload.srn]),
    [FETCH_SONRAI_USERS]: state => state.set('sonraiUsersLoading', true),
    [FETCH_SONRAI_USERS_SUCCESS]: (state, { payload }) => {
      let userObj = {}
      payload.users.forEach(user => {
        userObj[user.srn] = user
      })
      return state
        .set('sonraiUsers', fromJS(userObj))
        .set('sonraiUsersLoading', false)
    },
    [GET_ALL_ROLES]: state =>
      state
        .setIn(['allRoles', 'error'], false)
        .setIn(['allRoles', 'loading'], true),
    [SET_ALL_ROLES]: (state, { payload }) =>
      state
        .setIn(['allRoles', 'error'], false)
        .setIn(['allRoles', 'loading'], false)
        .setIn(['allRoles', 'data'], fromJS(payload.data)),
    [ERR_ALL_ROLES]: state =>
      state
        .setIn(['allRoles', 'error'], true)
        .setIn(['allRoles', 'loading'], false),
    [UPDATE_USER_ROLES]: state => state.set('updatingUserRoles', true),
    [UPDATE_USER_ROLES_SUCCESS]: (state, { payload }) => {
      const newRoleAssignments = merge(
        state
          .getIn(['sonraiUsers', payload.userSrn, 'roleAssignments', 'items'])
          .filter(item => !payload.deletedSrns.includes(item.get('srn'))),
        fromJS(payload.addedRoles)
      )
      return state
        .setIn(
          ['sonraiUsers', payload.userSrn, 'roleAssignments', 'items'],
          newRoleAssignments
        )
        .set('updatingUserRoles', false)
    },
    [GET_BOTS]: state => state.setIn(['bots', 'isLoading'], true),
    [SET_BOTS]: (state, { payload }) =>
      state
        .setIn(['bots', 'data'], fromJS(payload))
        .setIn(['bots', 'isLoading'], false),
    [CREATE_BOT]: state => state.set('isCreatingBot', true),
    [CREATE_BOT_SUCCESS]: (state, { payload }) =>
      state
        .updateIn(['bots', 'data'], data => data.push(fromJS(payload))) // payoad = new bot obj
        .set('isCreatingBot', false),
    [GET_EXEMPTED_IDENTITIES]: state =>
      state.set('exemptedIdentitiesLoading', true),
    [SET_EXEMPTED_IDENTITIES]: (state, { payload }) =>
      state
        .set('exemptedIdentitiesLoading', false)
        .set('exemptedIdentitiesError', null)
        .set('exemptedIdentities', fromJS(payload.identities)),
    [SET_EXEMPTED_IDENTITIES_ERROR]: (state, { payload }) =>
      state
        .set('exemptedIdentitiesLoading', false)
        .set('exemptedIdentitiesAdding', false)
        .set('exemptedIdentitiesDeleting', Map())
        .set('exemptedIdentitiesError', payload.error),
    [ADD_EXEMPTED_IDENTITIES]: state =>
      state.set('exemptedIdentitiesAdding', true),
    [ADD_EXEMPTED_IDENTITIES_SUCCESS]: (state, { payload }) =>
      state
        .set('exemptedIdentitiesAdding', false)
        .set(
          'exemptedIdentities',
          state.get('exemptedIdentities').merge(fromJS(payload.identities))
        )
        .set('exemptedIdentitiesError', null),
    [DELETE_EXEMPTED_IDENTITIES]: (state, { payload }) =>
      state.setIn(['exemptedIdentitiesDeleting', payload.resourceIds[0]], true),
    [DELETE_EXEMPTED_IDENTITIES_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['exemptedIdentitiesDeleting', payload.resourceId])
        .set(
          'exemptedIdentities',
          state
            .get('exemptedIdentities')
            .filter(id => id.get('resourceId') !== payload.resourceId)
        )
        .set('exemptedIdentitiesError', null),
    [VALIDATE_SOURCE_URL]: state =>
      state.setIn(['sourceUrlValidation', 'isValidating'], true),
    [VALIDATE_SOURCE_URL_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['sourceUrlValidation', 'isValid'], payload)
        .setIn(['sourceUrlValidation', 'isValidating'], false),
    [CLEAR_SOURCE_URL_VALIDATION]: state =>
      state.setIn(['sourceUrlValidation', 'isValid'], undefined),
    [HANDLE_DELETE_BOTS]: state => state.setIn(['bots', 'isLoading'], true),
    [HANDLE_DELETE_BOTS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['bots', 'data'], fromJS(payload))
        .setIn(['bots', 'isLoading'], false),
    [UPDATE_BOT_ASSIGNMENT]: state =>
      state.set('isUpdatingBotAssignment', true),
    [UPDATE_BOT_ASSIGNMENT_SUCCESS]: (state, { payload }) => {
      const swombo = state
        .get('swimlanes')
        .find(lane => lane.get('srn') === payload.swimlaneSrn)
      return state
        .set('isUpdatingBotAssignment', false)
        .setIn(['swimlanes', swombo.get('title'), 'bots'], fromJS(payload.bots))
    },
    [SET_OBJECTIVES]: (state, { payload }) =>
      state.set('objectives', fromJS(payload)).set('objectivesLoading', false),
    [FETCH_OBJECTIVES]: state =>
      state.set('objectivesLoading', true).set('objectivesError', null),
    [FETCH_OBJECTIVES_ERROR]: (state, { payload }) =>
      state.set('objectivesLoading', false).set('objectivesError', payload),
    [ENABLE_OBJECTIVE]: (state, { payload }) =>
      state.set(
        'enablingObjectives',
        state.get('enablingObjectives').push(fromJS(payload))
      ),
    [ENABLE_ALL_OBJECTIVES]: state => state.set('enablingAllObjectives', true),
    [ENABLE_ALL_OBJECTIVES_SUCCESS]: state =>
      state.set('enablingAllObjectives', false),
    [ENABLE_ALL_OBJECTIVES_ERROR]: state =>
      state.set('enablingAllObjectives', false),
    [ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE]: (state, { payload }) =>
      state.set('enablingAllObjectivesForSwimlane', payload.swimlaneSrn),
    [ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_ERROR]: state =>
      state.set('enablingAllObjectivesForSwimlane', null),
    [ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_SUCCESS]: state =>
      state.set('enablingAllObjectivesForSwimlane', null),
    [ENABLE_OBJECTIVE_ERROR]: state => state.set('enablingObjectives', List()),
    [ENABLE_OBJECTIVE_SUCCESS]: (state, { payload }) =>
      state.set(
        'enablingObjectives',
        state
          .get('enablingObjectives')
          .filter(
            obj =>
              obj.get('swimlaneSrn') !== payload.swimlaneSrn &&
              obj.get('objectiveSrn') !== payload.objectiveSrn
          )
      ),
    [FETCH_SONRAI_CONFIG]: state => state.set('sonraiConfigLoading', true),
    [SET_SONRAI_CONFIG]: state => state.set('sonraiConfigLoading', false),
  },
  initialState
)

export default sonraiDataReducer
