/*
 *
 * QuickSearch constants
 *
 */

export const QUICK_SEARCH_LOADING = 'app/QuickSearch/QUICK_SEARCH_LOADING'
export const FETCH_QUICK_SEARCH = 'app/QuickSearch/FETCH_QUICK_SEARCH'
export const SET_QUICK_SEARCH_RESULTS =
  'app/QuickSearch/SET_QUICK_SEARCH_RESULTS'
export const CLEAR_QUICK_SEARCH_RESULTS =
  'app/QuickSearch/CLEAR_QUICK_SEARCH_RESULTS'
export const RESULT_LIMIT_EXCEEDED = 'app/QuickSearch/RESULT_LIMIT_EXCEEDED'
export const SET_QUICK_SEARCH_ERROR = 'app/QuickSearch/QUICK_SEARCH_ERROR'
