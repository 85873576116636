import React, { useState } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { assignTickets } from 'containers/TicketDetailsData/actions'
import { selectSonraiUsers } from 'containers/SonraiData/selectors'
import Button from 'components/Button'
import Select from 'components/Select'
import TextLink from 'components/TextLink'
import UserWidget from 'components/UserWidget'

const ReassignToUserModal = props => {
  const [user, setUser] = useState(null)

  const onSubmit = () => {
    props.assignTickets({
      ticketSrns: props.ticketSrns,
      userSrn: user,
    })
    props.close()
  }

  const options = props.users
    .toList()
    .toJS()
    .map(item => ({
      label: item.name || item.srn,
      value: item.srn,
    }))

  return (
    <Modal isOpen={true}>
      <ModalHeader>Select user</ModalHeader>
      <ModalBody>
        <Select
          options={options}
          onChange={val => setUser(val.value)}
          formatOptionLabel={option => (
            <UserWidget srn={option.value} table noLink />
          )}
        />
      </ModalBody>
      <ModalFooter>
        <TextLink color="secondary" onClick={props.close}>
          Cancel
        </TextLink>
        <Button color="primary" disabled={user == null} onClick={onSubmit}>
          Reassign
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ReassignToUserModal.propTypes = {
  close: PropTypes.func.isRequired,
  users: ImmutablePropTypes.iterable.isRequired,
  assignTickets: PropTypes.func.isRequired,
  ticketSrns: PropTypes.array.isRequired,
}

const mapStateToProps = createStructuredSelector({
  users: selectSonraiUsers,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      assignTickets,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(ReassignToUserModal)
