import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'

import permissionChecker from 'containers/PermissionChecker'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import CloudAccount from 'components/CloudAccount'
import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import Popover, { PopoverAnchor, PopoverBody } from 'components/Popover'
import SelectBar from 'components/SelectBar'
import TextLink from 'components/TextLink'

import { addResourceToSwimlane } from './actions'

import { selectSwimlanes } from 'containers/SonraiData/selectors'

import { getAccountFromSrn } from 'utils/sonraiUtils'

import messages from './messages'

export class AddSwimlaneForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      open: false,
      suggestions: [],
      autoValue: '',
      errorKey: false,
      clearMe: false,
    }

    this.styles = {
      keyInput: {},
      addTagButton: {
        padding: '0rem 0.7rem',
        borderRadius: '1rem',
        border: `1px dashed ${props.theme.neutralMedium}`,
        backgroundColor: 'transparent',
        height: '20px',
        lineHeight: '20px',
        fontSize: '0.9em',
        margin: '0.5rem',
      },
      inputWrapper: {
        width: '100%',
        paddingBottom: '0.5em',
      },
      inputLabel: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
      },
      actionButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      addIcon: { fontSize: '17px', top: '-2px', position: 'relative' },
    }
  }

  handleAddSL = () => {
    this.props.addResourceToSwimlane({
      srn: this.props.srn,
      swimlaneSrn: this.state.value,
      resourceId: this.props.resourceId,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.savingSL && !this.props.savingSL) {
      this.toggleAddSL()
    }
  }

  onChange = swim => {
    this.setState({
      value: swim.value,
    })
  }

  toggleAddSL = () => {
    this.setState(oldState => ({
      open: !oldState.open,
    }))
  }

  renderPopover = () => {
    if (!this.props.resourceId) {
      return (
        <div style={{ padding: '1em', maxWidth: '400px' }}>
          This resource has no ResourceID and cannot be added to a swimlane
          directly.
        </div>
      )
    }

    const alreadyIn = []
    const options = []
    const account = getAccountFromSrn(this.props.srn)

    if (this.props.currentSwimlanes) {
      this.props.currentSwimlanes.forEach(swombo => {
        alreadyIn.push(swombo.srn)
      })
    }

    if (this.props.swimlanes) {
      this.props.swimlanes.forEach(lane => {
        const canEdit = this.props.userHasPermission({
          permissionName: 'edit.swimlanes',
          resourceId: lane.get('resourceId'),
        })
        if (canEdit && !alreadyIn.includes(lane.get('srn'))) {
          if (!lane.get('accounts') || lane.get('accounts').isEmpty()) {
            options.push({ label: lane.get('title'), value: lane.get('srn') })
          } else if (lane.get('accounts').includes(account)) {
            options.push({ label: lane.get('title'), value: lane.get('srn') })
          }
        }
      })
    }

    if (options.length === 0) {
      return (
        <div style={{ padding: '1em', maxWidth: '400px' }}>
          <p>There are no more valid swimlanes for this resource.</p>
          <p>
            In order to add this resource to another swimlane, the swimlane must
            either apply to all accounts, or be applied to the{' '}
            <CloudAccount accountId={account} /> account
          </p>
        </div>
      )
    }

    return (
      <div style={{ padding: '0.5em', maxWidth: '400px' }}>
        <p>
          Valid swimlanes either apply to all accounts, or are applied to the{' '}
          <CloudAccount accountId={account} /> account
        </p>
        <div style={this.styles.inputWrapper}>
          <SelectBar
            name="data-container-encryption"
            onChange={this.onChange}
            options={options}
            disabled={this.props.savingSL}
          />
        </div>
        <div style={this.styles.actionButtons}>
          <TextLink onClick={this.toggleAddSL} color="secondary">
            <DynamicFormattedMessage {...messages.cancel} />
          </TextLink>
          <Button
            style={{ marginLeft: '0.5em' }}
            onClick={this.handleAddSL}
            color="primary"
            disabled={!this.state.value || this.props.savingSL}
          >
            {this.props.savingSL ? (
              <Icon style={this.styles.addIcon} fa name="sync" spin />
            ) : (
              <DynamicFormattedMessage {...messages.add} />
            )}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Popover isOpen={this.state.open}>
        <PopoverAnchor>
          <Button style={this.styles.addTagButton} onClick={this.toggleAddSL}>
            <Icon fa name="plus" style={this.styles.addIcon} />{' '}
            <DynamicFormattedMessage {...messages.addToSwimlane} />
          </Button>
        </PopoverAnchor>
        <PopoverBody>{this.renderPopover()}</PopoverBody>
      </Popover>
    )
  }
}

AddSwimlaneForm.propTypes = {
  theme: themeShape,

  // passed from permissionChecker
  userHasPermission: PropTypes.func.isRequired,

  // ~ passed props ~
  currentSwimlanes: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  resourceId: PropTypes.string.isRequired,
  savingSL: PropTypes.bool,
  srn: PropTypes.string.isRequired,

  // ~ props from redux store ~
  swimlanes: ImmutablePropTypes.map,

  // ~ bound action creators ~
  addResourceToSwimlane: PropTypes.func.isRequired,
}

const makeStateToProps = createStructuredSelector({
  swimlanes: selectSwimlanes,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addResourceToSwimlane,
    },
    dispatch
  )
}

const withConnect = connect(makeStateToProps, mapDispatchToProps)

export default compose(
  permissionChecker,
  withConnect,
  themeable
)(AddSwimlaneForm)
