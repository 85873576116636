import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import { getTypeFromSrn } from 'utils/graphDataUtils'

import AGGridDataTable from './AGGridDataTable'

export class ConfigurableTable extends React.Component {
  componentDidUpdate(oldProps) {
    if (
      this.props.onRowCountChange &&
      oldProps.data.length !== this.props.data.length
    ) {
      setTimeout(function () {
        this.props.onRowCountChange()
      }, 500)
    }
  }

  componentWillUnmount() {
    this.api = null
    this.columnApi = null
  }

  getGridState = () => {
    if (!this.api || !this.columnApi) {
      return {}
    }

    return {
      columnGroupState: this.columnApi.getColumnGroupState(),
      columnState: this.columnApi.getColumnState(),
      sortModel: this.api.getSortModel(),
      filterModel: this.api.getFilterModel(),
      options: {},
    }
  }

  saveColumnsState = () => {
    const { options } = this.props
    const gridState = this.getGridState()

    if (this.props.onLayoutChange) {
      if (!options.gridState || !_.isEqual(gridState, options.gridState)) {
        const newOptions = {
          ...options,
          gridState,
        }

        this.props.onLayoutChange(newOptions)
      }
    } else {
      //This will trigger rerender when hiding/showing columns on tables without passed-down gridState
      this.setState({
        options: gridState,
      })
    }

    if (!_.isEmpty(gridState) && this.props.setUserProfileTables) {
      this.props.setUserProfileTables(
        gridState,
        this.props.set,
        this.props.id,
        this.props.userProfile.toJS()
      )
    }
  }

  handleRowDoubleClick = event => {
    if (!event.data) {
      return
    }

    if (this.props.onDoubleClickRow) {
      this.props.onDoubleClickRow(event.data)
    }

    if (this.props.onClickNodeView) {
      if (event.data.__typename) {
        this.props.onClickNodeView(event.data.srn, event.data.__typename)
        return
      } else if (event.data.srn) {
        this.props.onClickNodeView(
          event.data.srn,
          getTypeFromSrn(event.data.srn)
        )
        return
      }
    }

    if (this.props.flatten && this.props.onClickFlattenedNodeView) {
      this.props.onClickFlattenedNodeView(event.data)
    }
  }

  handleRowSingleClick = event => {
    if (!event.data) {
      return
    }

    if (event.data.srn && this.props.onSingleClickNodeView) {
      this.props.onSingleClickNodeView(
        event.data.srn,
        getTypeFromSrn(event.data.srn) || event.data.__typename
      )
    }

    if (this.props.flatten && this.props.onSingleClickFlattenedNodeView) {
      this.props.onSingleClickFlattenedNodeView(event.data)
    }
  }

  loadColumnState = gState => {
    if (!this.columnApi || !this.api) {
      return
    }

    if (gState.columnState) {
      const cols = this.columnApi.getAllColumns() || []
      const gridColumnIds = cols.map(col => col.colId)
      const optionColumnIds = gState.columnState.map(col => col.colId)

      //If somehow the saved gridstate got out of sync and has extra columns
      //This will avoid a warning in the console
      const validColumnsFromOptions = gState.columnState.filter(col =>
        gridColumnIds.includes(col.colId)
      )

      //If somehow the saved gridState got out of sync, and left out a column that is present
      //We want to still include those columns
      const extraColumns = this.getGridState().columnState.filter(
        col => !optionColumnIds.includes(col.colId)
      )

      this.columnApi.setColumnState(
        validColumnsFromOptions.concat(extraColumns)
      )
    }

    if (gState.columnGroupstate) {
      this.columnApi.setColumnGroupState(gState.columnGroupState)
    }

    if (gState.sortModel) {
      this.api.setSortModel([
        ...this.getGridState().sortModel.filter(
          sortModelConfig =>
            !gState.sortModel.find(col => col.colId === sortModelConfig.colId)
        ),
        ...gState.sortModel,
      ])
    }

    if (gState.filterModel) {
      this.api.setFilterModel(gState.filterModel)
    }
  }

  onLoad = ({ api, columnApi }) => {
    const { options } = this.props
    this.api = api
    this.columnApi = columnApi

    const userPreferenceState = this.getUserPreferenceGridState()
    if (!_.isEmpty(userPreferenceState)) {
      this.loadColumnState(userPreferenceState)
    } else if (options.gridState) {
      this.loadColumnState(options.gridState)
    }

    this.api.addGlobalListener((type, event) => {
      if (
        type === 'columnVisible' ||
        type === 'columnPinned' ||
        type === 'columnResized' ||
        type === 'columnMoved' ||
        type === 'sortChanged' ||
        type === 'filterChanged' ||
        type === 'filterModified' ||
        type === 'displayedColumnsChanged' ||
        type === 'columnValueChanged'
      ) {
        this.saveColumnsState()
      } else if (type === 'rowDoubleClicked') {
        this.handleRowDoubleClick(event)
      } else if (type === 'rowClicked') {
        this.handleRowSingleClick(event)
      }
    })

    if (this.props.onLoad) {
      this.props.onLoad({ api, columnApi })
    }
  }

  getUserPreferenceGridState = () => {
    if (this.props.userProfile) {
      const { id, set } = this.props
      const widgets = this.props.userProfile.getIn(
        ['tables', set, 'widgets'],
        List()
      )
      const match = widgets.find(widget => widget.get('id') == id)

      if (match) {
        return match.toJS().grid
      } else {
        return {}
      }
    }
  }

  getColumnName = (col, sampleItem) => {
    let colName = col
    if (
      typeof sampleItem === 'object' &&
      sampleItem !== null &&
      sampleItem.items === undefined &&
      sampleItem.count !== undefined
    ) {
      colName = `${this.getRelationHeaderName(
        col,
        sampleItem['__typename']
      )} Count`
    }

    if (col.toLowerCase().startsWith('qf')) {
      if (!_.isEmpty(this.props.customColumnHeaders)) {
        colName = _.get(
          this.props.customColumnHeaders.find(
            column => column.fieldName === col
          ),
          'alias'
        )
      } else {
        if (col.split('_').length > 0) {
          colName = _.last(col.split('_'))
        }
      }
    }

    return colName
  }

  getRelationHeaderName = (edgeName = '', edgeTypeName = '') => {
    const mainTypeName = edgeTypeName.replace(/EdgeRelation$/, '')
    const typeRelations =
      this.props.relations.find(
        relation => relation.get('type') === mainTypeName
      ) || Map()

    const childRelation =
      typeRelations
        .get('relations', List())
        .find(relation => relation.get('reverse') == edgeName) || Map()

    const parentRelation =
      typeRelations
        .get('relations', List())
        .find(relation => relation.get('name') == edgeName) || Map()

    if (!childRelation.isEmpty() && parentRelation.isEmpty()) {
      return `${edgeName} ${childRelation.get('from')}`
    }

    if (!parentRelation.isEmpty() && childRelation.isEmpty()) {
      return `${edgeName} ${parentRelation.get('to')}`
    }

    return edgeName
  }

  render() {
    return (
      <AGGridDataTable
        supressSelectAll={this.props.supressSelectAll}
        checkboxSelectedRows={this.props.checkboxSelectedRows}
        groupBy={this.props.groupBy}
        columnWidth={this.props.columnWidth}
        gridState={this.props.options.gridState}
        selectNode={this.props.selectNode}
        suppressDragLeaveHidesColumns={this.props.suppressDragLeaveHidesColumns}
        selectedNode={this.props.selectedNode}
        onLoad={this.onLoad}
        flatten={this.props.flatten}
        data={this.props.data}
        style={this.props.style}
        getColumnName={this.getColumnName}
        multiRowSelect={this.props.multiRowSelect}
        selectionChanged={this.props.selectionChanged}
        rowSelected={this.props.rowSelected}
        hasCheckBoxes={this.props.hasCheckBoxes}
        onCellEditingStopped={this.props.onCellEditingStopped}
        customColumnConfig={this.props.customColumnConfig}
        customGridProps={this.props.customGridProps}
        crm={this.props.crm}
        actions={this.props.actions}
        bulkActions={this.props.bulkActions}
        bulkActionWorking={this.props.bulkActionWorking}
        sortColumns={this.props.sortColumns}
        rowHeight={this.props.rowHeight}
        autosize={this.props.autosize}
        sortColumnByType={this.props.sortColumnByType}
        exportFileName={this.props.exportFileName}
        pageSize={this.props.pageSize}
        hiddenColumns={this.props.hiddenColumns}
        overlayNoRowsTemplate={this.props.overlayNoRowsTemplate}
        noTableActions={this.props.noTableActions}
        defaultColDef={this.props.defaultColDef}
        disableSearchBar={this.props.disableSearchBar}
        customFilter={this.props.customFilter}
        fitAllRows={this.props.fitAllRows}
        expandCollapseColumn={this.props.expandCollapseColumn}
        jsonCols={this.props.jsonCols}
      />
    )
  }
}

ConfigurableTable.defaultProps = {
  options: {},
  relations: List(),
}

ConfigurableTable.propTypes = {
  actions: PropTypes.node,
  autosize: PropTypes.bool,
  bulkActions: PropTypes.arrayOf(PropTypes.node),
  bulkActionWorking: PropTypes.bool,
  checkboxSelectedRows: PropTypes.array,
  customColumnConfig: PropTypes.object,
  customGridProps: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  exportFileName: PropTypes.string,
  expandCollapseColumn: PropTypes.string,
  flatten: PropTypes.bool,
  fitAllRows: PropTypes.bool,
  id: PropTypes.string,
  jsonCols: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.object,
  onLayoutChange: PropTypes.func,
  onClickNodeView: PropTypes.func,
  onClickFlattenedNodeView: PropTypes.func,
  onRowCountChange: PropTypes.func,
  onSingleClickNodeView: PropTypes.func,
  onSingleClickFlattenedNodeView: PropTypes.func,
  relations: ImmutablePropTypes.list,
  setUserProfileTables: PropTypes.func,
  set: PropTypes.string,
  style: PropTypes.object,
  userProfile: PropTypes.object,
  selectedNode: PropTypes.string,
  selectNode: PropTypes.func,
  selectionChanged: PropTypes.func,
  sortColumns: PropTypes.bool,
  multiRowSelect: PropTypes.bool,
  columnWidth: PropTypes.number,
  hasCheckBoxes: PropTypes.bool,
  rowSelected: PropTypes.func,
  onCellEditingStopped: PropTypes.func,
  onLoad: PropTypes.func,
  crm: PropTypes.bool,
  rowHeight: PropTypes.bool,
  sortColumnByType: PropTypes.string,
  groupBy: PropTypes.string,
  onDoubleClickRow: PropTypes.func,
  pageSize: PropTypes.number,
  customColumnHeaders: PropTypes.array,
  hiddenColumns: PropTypes.array,
  noTableActions: PropTypes.bool,
  overlayNoRowsTemplate: PropTypes.string,
  defaultColDef: PropTypes.object,
  suppressDragLeaveHidesColumns: PropTypes.bool,
  disableSearchBar: PropTypes.bool,
  customFilter: PropTypes.func,
  supressSelectAll: PropTypes.bool,
}

export default ConfigurableTable
