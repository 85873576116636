import { createSelector } from 'reselect'
import { Map } from 'immutable'

export const selectWidgetResultExplorerDomain = state =>
  state.get('widgetResultExplorer', Map())

export const selectResults = createSelector(
  selectWidgetResultExplorerDomain,
  sonraiData => sonraiData.get('results') || Map()
)

export const selectTitle = createSelector(
  selectWidgetResultExplorerDomain,
  sonraiData => sonraiData.get('title')
)

export const selectSonraiSearchName = createSelector(
  selectWidgetResultExplorerDomain,
  sonraiData => sonraiData.get('sonraiSearchName')
)

export const selectSavedSearchId = createSelector(
  selectWidgetResultExplorerDomain,
  sonraiData => sonraiData.get('savedSearchId')
)

export const selectLoading = createSelector(
  selectWidgetResultExplorerDomain,
  sonraiData => sonraiData.get('loading')
)

export const selectError = createSelector(
  selectWidgetResultExplorerDomain,
  sonraiData => sonraiData.get('error')
)

export const selectFilter = createSelector(
  selectWidgetResultExplorerDomain,
  sonraiData => sonraiData.get('filter')
)

export const selectSavedSearchFilterCardId = createSelector(
  selectWidgetResultExplorerDomain,
  sonraiData => sonraiData.get('savedSearchFilterCardId')
)
