import { createAction } from 'redux-actions'
import {
  GET_CAN_ASSUME_ROLES,
  ERR_CAN_ASSUME_ROLES,
  SET_CAN_ASSUME_ROLES,
  GET_GROUP_MEMBERSHIPS,
  ERR_GROUP_MEMBERSHIPS,
  SET_GROUP_MEMBERSHIPS,
  GET_CAN_BE_ASSUMED_BY,
  ERR_CAN_BE_ASSUMED_BY,
  SET_CAN_BE_ASSUMED_BY,
  GET_DIRECT_ATTACHED_POLICIES,
  ERR_DIRECT_ATTACHED_POLICIES,
  SET_DIRECT_ATTACHED_POLICIES,
  GET_SCPS,
  ERR_SCPS,
  SET_SCPS,
  GET_INSTANCE_PROFILES,
  SET_INSTANCE_PROFILES,
  ERR_INSTANCE_PROFILES,
  GET_PERMISSION_INFO,
  SET_PERMISSION_INFO,
} from './constants'

export const getCanAssumeRoles = createAction(GET_CAN_ASSUME_ROLES)
export const setCanAssumeRoles = createAction(SET_CAN_ASSUME_ROLES)
export const errorCanAssumeRoles = createAction(ERR_CAN_ASSUME_ROLES)

export const getCanBeAssumedBy = createAction(GET_CAN_BE_ASSUMED_BY)
export const setCanBeAssumedBy = createAction(SET_CAN_BE_ASSUMED_BY)
export const errorCanBeAssumedBy = createAction(ERR_CAN_BE_ASSUMED_BY)

export const getGroupMemberships = createAction(GET_GROUP_MEMBERSHIPS)
export const setGroupMemberships = createAction(SET_GROUP_MEMBERSHIPS)
export const errorGroupMemberships = createAction(ERR_GROUP_MEMBERSHIPS)

export const getDirectAttachedPolicies = createAction(
  GET_DIRECT_ATTACHED_POLICIES
)
export const setDirectAttachedPolicies = createAction(
  SET_DIRECT_ATTACHED_POLICIES
)
export const errorDirectAttachedPolicies = createAction(
  ERR_DIRECT_ATTACHED_POLICIES
)

export const getSCPs = createAction(GET_SCPS)
export const setSCPs = createAction(SET_SCPS)
export const errorSCPs = createAction(ERR_SCPS)

export const getInstanceProfiles = createAction(GET_INSTANCE_PROFILES)
export const setInstanceProfiles = createAction(SET_INSTANCE_PROFILES)
export const errorInstanceProfiles = createAction(ERR_INSTANCE_PROFILES)

export const getPermissionInfo = createAction(GET_PERMISSION_INFO)
export const setPermissionInfo = createAction(SET_PERMISSION_INFO)
