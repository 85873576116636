import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectSecretStoreNodeViewCardLayoutDomain = state =>
  state.get('secretStoreNodeViewCardLayout') || Map()

export const selectSecretStoreContent = createSelector(
  selectSecretStoreNodeViewCardLayoutDomain,
  state => state.get('secretStoreContent') || Map()
)

export const selectIsLoading = createSelector(
  selectSecretStoreNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
