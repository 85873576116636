/*
 * IdentityNodeViewCardLayout Messages
 *
 * This contains all the text for the IdentityNodeViewCardLayout component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.containers.IdentityNodeViewCardLayout.title',
    defaultMessage: 'Data Details',
  },
  activeLabel: {
    id: 'app.containers.IdentityNodeViewCardLayout.activeLabel',
    defaultMessage: 'Active',
  },
  typeLabel: {
    id: 'app.containers.IdentityNodeViewCardLayout.typeLabel',
    defaultMessage: 'Type',
  },
  accountLabel: {
    id: 'app.containers.IdentityNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id: 'app.containers.IdentityNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  group: {
    id: 'app.containers.IdentityNodeViewCardLayout.group',
    defaultMessage: 'Group',
  },
  lastModifiedLabel: {
    id: 'app.containers.IdentityNodeViewCardLayout.lastModifiedLabel',
    defaultMessage: 'Last Modified',
  },
  createdLabel: {
    id: 'app.containers.IdentityNodeViewCardLayout.createdLabel',
    defaultMessage: 'Created',
  },
  srnLabel: {
    id: 'app.containers.IdentityNodeViewCardLayout.srnLabel',
    defaultMessage: 'srn',
  },
  service: {
    id: 'app.containers.IdentityNodeViewCardLayout.service',
    defaultMessage: 'Service',
  },
  descriptionLabel: {
    id: 'app.containers.IdentityNodeViewCardLayout.description',
    defaultMessage: 'Description',
  },
  sizeLabel: {
    id: 'app.containers.IdentityNodeViewCardLayout.size',
    defaultMessage: 'Size',
  },
  bytes: {
    id: 'app.containers.IdentityNodeViewCardLayout.bytes',
    defaultMessage: 'Bytes',
  },
  publicRead: {
    id: 'app.containers.IdentityNodeViewCardLayout.publicRead',
    defaultMessage: 'Public Read',
  },
  publicWrite: {
    id: 'app.containers.IdentityNodeViewCardLayout.publicWrite',
    defaultMessage: 'Public Write',
  },
  webisteEnabled: {
    id: 'app.containers.IdentityNodeViewCardLayout.webisteEnabled',
    defaultMessage: 'Website Enabled',
  },
  auditEnabled: {
    id: 'app.containers.IdentityNodeViewCardLayout.auditEnabled',
    defaultMessage: 'Audit Enabled',
  },
  containerLabel: {
    id: 'app.containers.IdentityNodeViewCardLayout.containerLabel',
    defaultMessage: 'Container',
  },
  none: {
    id: 'app.containers.IdentityNodeViewCardLayout.none',
    defaultMessage: 'None',
  },
  dataItem: {
    id: 'app.containers.IdentityNodeViewCardLayout.dataItem',
    defaultMessage: 'This Data item is not part of a container',
  },
  record: {
    id: 'app.containers.IdentityNodeViewCardLayout.record',
    defaultMessage: 'Record',
  },
  performed: {
    id: 'app.containers.IdentityNodeViewCardLayout.performed',
    defaultMessage: 'Performed (Last 24 Hours)',
  },
  members: {
    id: 'app.containers.IdentityNodeViewCardLayout.performed',
    defaultMessage: 'Members',
  },
  groupsPolicies: {
    id: 'app.containers.IdentityNodeViewCardLayout.groupsPolicies',
    defaultMessage: 'Groups & Policies',
  },
  label: {
    id: 'app.containers.IdentityNodeViewCardLayout.label',
    defaultMessage: 'Label',
  },
  Identity: {
    id: 'app.containers.IdentityNodeViewCardLayout.Identity',
    defaultMessage: 'Identity',
  },
})
