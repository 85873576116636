import { put, takeLatest, select } from 'redux-saga/effects'
import { List, isImmutable } from 'immutable'
import { setTypeColors } from './actions'
import { selectQueryTypes } from 'containers/SonraiData/selectors'
import { shiftColorToMatch, colorForString } from 'utils/sonraiUtils'

import { selectTheme } from './selectors'
import { GENERATE_TYPE_COLORS } from './constants'

const isNodeType = type => {
  if (!isImmutable(type)) {
    return false
  }

  if (type.get('implements') && !type.get('implements').isEmpty()) {
    return true
  }

  const typeName = type.get('name')
  if (
    typeName === 'Identity' ||
    typeName === 'Protection' ||
    typeName === 'Data' ||
    typeName === 'Infrastructure' ||
    typeName === 'Actor'
  ) {
    return true
  }

  return false
}

function* generateTypeColors() {
  const theme = yield select(selectTheme)
  const allTypes = yield select(selectQueryTypes)
  const nodeTypes = allTypes
    .filter(isNodeType)
    .toList()
    .sortBy(thing => thing.get('name', ''))

  let colors = {}

  nodeTypes.map(definition => {
    const nodetype = definition.get('name', '').toLowerCase()
    const impl = definition.get('implements') || List()
    let baseColor = theme.get('secondary')

    //Figure out the color category this nodetype should be in
    if (impl.includes('Protection') || nodetype === 'protection') {
      baseColor = theme.get('protection')
    } else if (impl.includes('Data') || nodetype === 'data') {
      baseColor = theme.get('data')
    } else if (
      impl.includes('Identity') ||
      nodetype === 'identity' ||
      nodetype === 'actor'
    ) {
      baseColor = theme.get('identity')
    } else if (
      impl.includes('Infrastructure') ||
      nodetype === 'infrastructure'
    ) {
      baseColor = theme.get('infrastructure')
    }

    //Generate a unique(ish) color within the matching color category, based on the nodetype string value
    const color = shiftColorToMatch(colorForString(nodetype), baseColor)
    colors[nodetype] = color

    //Also add color definition for subtypes of node types. EG "S3" should be colored like a Data node
    const typeProperty = (definition.get('fields') || List()).find(
      field => field.get('name') === 'type'
    )

    if (typeProperty) {
      const typeDef = allTypes.get(definition.get('name', '') + 'Type')

      if (typeDef) {
        const enumValues = typeDef.get('enumValues') || List()
        enumValues.forEach(enumDef => {
          const label = (enumDef.get('name') || '').toLowerCase()
          if (!colors[label]) {
            //Some enum types are named the same as nodetypes and would overwrite the node types, so we skip those
            const enumColor = shiftColorToMatch(
              colorForString(label),
              baseColor
            )

            colors[label] = enumColor
          }
        })
      }
    }
  })

  yield put(setTypeColors(colors))
}
function* themeSaga() {
  yield takeLatest(GENERATE_TYPE_COLORS, generateTypeColors)
}

export default themeSaga
