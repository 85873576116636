/**
 *
 * Tag
 *
 */

import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import Color from 'color'

import Icon from 'components/Icon'
import BorderlessButton from 'components/BorderlessButton'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class Tag extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      removeButton: {
        marginLeft: '0.5em',
        backgroundColor: 'auto',
      },
      tag: {
        display: 'inline-grid',
        margin: '0.5rem',
        alignContent: 'center',
        maxWidth: '100%',
        overflow: 'hidden',
        gridTemplateColumns: 'auto 1fr',
      },
      key: {
        padding: '0rem 0.7rem',
        borderTopLeftRadius: '1rem',
        borderBottomLeftRadius: '1rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        backgroundColor: Color(props.theme.secondary)
          .blacken(0.5)
          .hex(),
        color: props.theme.light,
        height: this.props.tagHeight || '30px',
        lineHeight: this.props.tagHeight || '30px',
      },
      val: {
        paddingLeft: '0.5rem',
        paddingRight: this.props.removeTag ? '0.6rem' : '0.5rem',
        borderTopRightRadius: '1.2rem',
        borderBottomRightRadius: '1.2rem',
        backgroundColor: props.invert
          ? props.theme.light
          : props.theme.neutralLight,
        color: props.theme.dark,
        height: this.props.tagHeight || '30px',
        lineHeight: this.props.tagHeight || '30px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      tagText: {
        fontSize: (this.props.style && this.props.style.fontSize) || '0.9rem',
        maxWidth: '10rem',
        width: 'auto',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    }
  }

  render() {
    return (
      <div
        style={{ ...this.styles.tag, ...this.props.style }}
        key={decodeURIComponent(this.props.tag.get('key'))}
        title={`${this.props.tag.get('key')}:${this.props.tag.get('value')}`}
      >
        <div style={this.styles.key}>
          <span style={this.styles.tagText}>
            {decodeURIComponent(this.props.tag.get('key'))}
          </span>
        </div>
        <div style={this.styles.val}>
          <span style={this.styles.tagText}>
            {this.props.tag.get('value')}{' '}
          </span>
          {this.props.removeTag && (
            <BorderlessButton
              style={{ ...this.styles.removeButton, ...this.styles.tagText }}
              onClick={this.props.removeTag}
            >
              <Icon fa name="times" transform="shrink-4" />
            </BorderlessButton>
          )}
        </div>
      </div>
    )
  }
}

Tag.propTypes = {
  removeTag: PropTypes.func,
  tag: ImmutablePropTypes.contains({
    srn: PropTypes.string,
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  tagHeight: PropTypes.string,
  theme: themeShape,
  style: PropTypes.object,
  invert: PropTypes.bool,
}

export default themeable(Tag)
