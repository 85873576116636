/**
 *
 * GenericNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import { NodeViewDetailString, NodeViewDetailLabel } from 'components/NodeView'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import JSONDisplay from 'components/JSONDisplay'
import EmptyWidgetResults from 'components/EmptyWidgetResults'
import TextLink from 'components/TextLink'
export class GenericNodeViewCardLayout extends React.Component {
  state = {
    isOpen: false,
  }
  getColumns = () => {
    return {
      xs: [
        {
          widthPercent: 100,
          items: ['graph'],
          heights: {
            graph: 3,
          },
        },
      ],
    }
  }

  toggleJSONModal = () =>
    this.setState(oldState => ({ isOpen: !oldState.isOpen }))

  getHeaderContent = () => {
    const { version, policyJSON, label } = this.props.nodeData
    if (label === 'PolicyVersion') {
      return [
        <Fragment key="content-version">
          <NodeViewDetailLabel>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Version
              {policyJSON && (
                <TextLink
                  onClick={this.toggleJSONModal}
                  color="primary"
                  style={{
                    margin: '0.1rem 1rem 0rem 0.5rem',
                    fontSize: '0.9rem',
                  }}
                >
                  (View Policy JSON)
                </TextLink>
              )}
            </div>{' '}
          </NodeViewDetailLabel>
          <NodeViewDetailString value={version} />
        </Fragment>,
      ]
    }
  }

  render() {
    const { friendlyName, name, policyJSON } = this.props.nodeData
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          nodeData={this.props.nodeData}
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          gridContent={[]}
          columns={this.getColumns()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
          renderDetailsTab={false}
          additionalHeaderDetails={this.getHeaderContent()}
        />
        <Modal isOpen={this.state.isOpen} toggle={this.toggleJSONModal}>
          <ModalHeader toggle={this.toggleJSONModal}>
            {friendlyName || name || 'Policy'} JSON
          </ModalHeader>
          <ModalBody>
            {policyJSON ? (
              <JSONDisplay content={JSON.parse(policyJSON)} />
            ) : (
              <EmptyWidgetResults />
            )}
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

GenericNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  nodeData: PropTypes.object,
  nodeId: PropTypes.string.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

export default GenericNodeViewCardLayout
