/*
 *
 * NodeSolutionCenter constants
 *
 */

export const DEFAULT_RAPSHEET_ALERTS_DAYS = 1

export const GET_DATA = 'app/NodeSolutionCenter/GET_DATA'

export const SET_SWIMLANE_DATA_LOADING =
  'app/NodeSolutionCenter/SET_SWIMLANE_DATA_LOADING'

export const GET_SWIMLANE_DATA_SUCCESS =
  'app/NodeSolutionCenter/GET_SWIMLANE_DATA_SUCCESS'

export const GET_SWIMLANE_DATA_FAILURE =
  'app/NodeSolutionCenter/GET_SWIMLANE_DATA_FAILURE'

export const SET_SWIMLANE_DATA_FAILURE =
  'app/NodeSolutionCenter/SET_SWIMLANE_DATA_FAILURE'

export const GET_DATA_SUCCESS = 'app/NodeSolutionCenter/GET_DATA_SUCCESS'

export const GET_CRM_DATA_SUCCESS =
  'app/NodeSolutionCenter/GET_CRM_DATA_SUCCESS'

export const REMAINING_TAG_SEARCH =
  'app/NodeSolutionCenter/REMAINING_TAG_SEARCH'

export const CLEAR_DATA = 'app/NodeSolutionCenter/CLEAR_DATA'

export const GET_TAG_OPTIONS = 'app/NodeSolutionCenter/GET_TAG_OPTIONS'

export const GET_TAG_OPTIONS_SUCCESS =
  'app/NodeSolutionCenter/GET_TAG_OPTIONS_SUCCESS'

export const SKIP_PROPERTIES = [
  'observedDate',
  'loadId',
  'createdBy',
  'content',
  'relations',
  'relationsToParent',
  'sonraiConfig',
  'taggedBy',
]

// CRM

export const SET_RESOURCE_MONITORING =
  'app/NodeSolutionCenter/SET_RESOURCE_MONITORING'
export const TOGGLE_RESOURCE_MONITORING =
  'app/ChangeDetectionManager/TOGGLE_RESOURCE_MONITORING'

export const GET_CRM_TICKETS = 'app/NodeSolutionCenter/GET_CRM_TICKETS'
export const SET_CRM_TICKETS = 'app/NodeSolutionCenter/SET_CRM_TICKETS'
export const GET_TICKET_COUNT = 'app/NodeSolutionCenter/GET_TICKET_COUNT'
export const SET_TICKET_COUNT = 'app/NodeSolutionCenter/SET_TICKET_COUNT'

export const FETCH_RESOURCE_GROUPS =
  'app/NodeSolutionCenter/FETCH_RESOURCE_GROUPS'
export const FETCH_RESOURCE_GROUPS_SUCCESS =
  'app/NodeSolutionCenter/FETCH_RESOURCE_GROUPS_SUCCESS'
export const SET_SHOULD_UPDATE_NODE_SWIMLANES =
  'app/NodeSolutionCenter/SET_SHOULD_UPDATE_NODE_SWIMLANES'
export const RELOAD_SWIMLANES = 'app/NodeSolutionCenter/RELOAD_SWIMLANES'
export const FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR =
  'app/NodeSolutionCenter/FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR'
export const FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR_SUCCESS =
  'app/NodeSolutionCenter/FETCH_EFFECTIVE_PERMISSION_FOR_ACTOR_SUCCESS'
export const FETCH_UBER_POLICY = 'app/NodeSolutionCenter/FETCH_UBER_POLICY'
export const FETCH_UBER_POLICY_ERROR =
  'app/NodeSolutionCenter/FETCH_UBER_POLICY_ERROR'
export const FETCH_UBER_POLICY_SUCCESS =
  'app/NodeSolutionCenter/FETCH_UBER_POLICY_SUCCESS'
