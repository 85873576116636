import React from 'react'
import PropTypes from 'prop-types'
import { Fade, Dropdown, DropdownToggle } from 'reactstrap'

import Icon from 'components/Icon'
import DropdownMenu from 'components/StyledReactstrapDropdownMenu'
import styles from './styles'

export class Toolbar extends React.Component {
  state = {
    dropdownExpanded: false,
  }

  toggleDropdown = () => {
    this.setState(currentState => ({
      dropdownExpanded: !currentState.dropdownExpanded,
    }))
  }

  renderLeftToolbar = () => {
    return (
      <div style={styles.leftHeader}>
        {this.props.leftToolbarButtons.map((button, index) => {
          return (
            <Fade
              style={{
                backgroundColor: this.props.hovered
                  ? 'rgba(255,255,255,0.8)'
                  : '',
              }}
              key={`toolbarbutton-${index}`}
              in={this.props.hovered}
            >
              {button}
            </Fade>
          )
        })}
      </div>
    )
  }

  renderRightToolbar = () => {
    return (
      <div style={styles.rightHeader}>
        {this.props.rightToolbarButtons.map((button, index) => {
          return (
            <Fade
              style={{
                backgroundColor: this.props.hovered
                  ? 'rgba(255,255,255,0.8)'
                  : '',
              }}
              key={`righttoolbar-${index}`}
              in={this.props.hovered}
            >
              {button}
            </Fade>
          )
        })}

        {this.props.statusButtons}
        {this.renderDropdown()}
      </div>
    )
  }

  renderDropdown = () => {
    if (this.props.dropdownActions.length > 0) {
      return (
        <Dropdown
          isOpen={this.state.dropdownExpanded}
          toggle={this.toggleDropdown}
        >
          <DropdownToggle style={styles.moreButton}>
            <Icon fa name="ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu
            style={{ border: 'none' }}
            onClick={this.toggleDropdown}
          >
            {this.props.dropdownActions}
          </DropdownMenu>
        </Dropdown>
      )
    }
  }

  render() {
    return (
      <div
        style={this.props.style}
      >
        {this.renderLeftToolbar()}
        {this.renderRightToolbar()}
      </div>
    )
  }
}

Toolbar.defaultProps = {
  style: {},
  leftToolbarButtons: [],
  rightToolbarButtons: [],
  dropdownActions: [],
  statusButtons: [],
}

Toolbar.propTypes = {
  hovered: PropTypes.bool,
  leftToolbarButtons: PropTypes.array,
  rightToolbarButtons: PropTypes.array,
  dropdownActions: PropTypes.array,
  style: PropTypes.object,
  statusButtons: PropTypes.node,
}

export default Toolbar
