import { createSelector } from 'reselect'
import { Map, List } from 'immutable'
import { GLOBAL_SWIMLANE_NAME } from 'appConstants'

export const selectSonraiDataDomain = state =>
  state.get('sonraiData', Map()) || Map()

export const selectQueryTypes = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('queryTypes') || Map()
)

export const selectSavedSearches = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('savedSearches') || Map()
)

export const selectSavedSearchesDetailsLoaded = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('savedSearchesDetailsLoaded')
)

export const selectPermissions = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('permissions') || List()
)

export const selectGraphQLSchema = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('schema') || {}
)

export const selectSonraiSearches = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('sonraiSearches') || List()
)

export const selectQueryRelations = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('queryRelations') || List()
)

export const selectQueryNames = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('queryNames') || List()
)

export const selectAllowedRootQueries = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('allowedRootQueries') || List()
)

export const selectSonraiConfigLoading = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('sonraiConfigLoading')
)

export const selectAccounts = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('accounts') || List()
)

export const selectAccountsBySrn = createSelector(
  selectAccounts,
  accountsList =>
    Map(accountsList.map(account => List([account.get('srn'), account])))
)

export const selectAccountsByAccountId = createSelector(
  selectAccounts,
  accountsList =>
    Map(accountsList.map(account => List([account.get('account'), account])))
)

export const selectSubscriptions = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('subscriptions') || List()
)

export const selectPivot = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('pivot') || Map()
)

export const selectTags = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('tags') || List()
)

export const selectDataContainers = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('dataContainers') || List()
)

export const selectHasCollectors = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('hasCollectors')
)

export const selectCheckCollectorsLoading = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('checkCollectorsLoading')
)

export const selectSavedSearchesLoading = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('savedSearchesLoading')
)

export const selectMonitoredResourcesCount = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('monitoredResourcesCount')
)

export const selectSwimlanes = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('swimlanes') || Map()
)

export const selectSwimlanesBySrn = createSelector(selectSwimlanes, swimlanes =>
  swimlanes.reduce(
    (swimlanesMap, swimlane) => swimlanesMap.set(swimlane.get('srn'), swimlane),
    Map()
  )
)

export const selectSwimlanesWithoutGlobal = createSelector(
  selectSwimlanes,
  swimlanes =>
    swimlanes
      ? swimlanes.filterNot(
          swimlane => swimlane.get('title') === GLOBAL_SWIMLANE_NAME
        )
      : List()
)

export const selectSwimlanesLoading = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('swimlanesLoading')
)

export const selectGlobalError = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('globalError')
)

export const selectSonraiUsers = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('sonraiUsers') || Map()
)

export const selectSonraiUsersLoading = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('sonraiUsersLoading')
)

export const selectUpdatingSonraiUsers = createSelector(
  selectSonraiDataDomain,
  sonraiData => sonraiData.get('updatingSonraiUser') || Map()
)

export const selectAllRoles = createSelector(selectSonraiDataDomain, state => {
  // this logic is in place in regards to SAR-1943 - removing the edit.manualremediations permission until a user can actually use it
  const data = state.getIn(['allRoles', 'data'], List())
  const roleIndex = data.findIndex(item =>
    item.get('expandedPermissions').includes('edit.manualremediations')
  )
  const permissionIndex = data
    .getIn([roleIndex, 'expandedPermissions'], List())
    .findIndex(item => item === 'edit.manualremediations')

  if (roleIndex !== -1 && permissionIndex !== -1) {
    return state
      .get('allRoles')
      .deleteIn(['data', roleIndex, 'expandedPermissions', permissionIndex])
  }
  //once a user can, we can update Action & Desc. accordingly and just return state.get('allRoles') here
  return state.get('allRoles')
})

export const selectUnassigningRoles = createSelector(
  selectSonraiDataDomain,
  data => data.get('unassigningRole')
)

export const selectUpdatingUserRoles = createSelector(
  selectSonraiDataDomain,
  data => data.get('updatingUserRoles')
)

export const selectBots = createSelector(selectSonraiDataDomain, state =>
  state.get('bots')
)

export const selectBotsMap = createSelector(selectBots, bots =>
  bots
    ? Map(bots.get('data', List()).map(bot => List([bot.get('srn'), bot])))
    : Map()
)

export const selectIsCreatingBot = createSelector(
  selectSonraiDataDomain,
  state => state.get('isCreatingBot')
)

export const selectSourceUrlValidation = createSelector(
  selectSonraiDataDomain,
  state => state.get('sourceUrlValidation')
)

export const selectIsUpdatingBotAssignment = createSelector(
  selectSonraiDataDomain,
  state => state.get('isUpdatingBotAssignment')
)

export const selectExemptedIdentities = createSelector(
  selectSonraiDataDomain,
  state => state.get('exemptedIdentities') || List()
)

export const selectExemptedIdentitiesError = createSelector(
  selectSonraiDataDomain,
  state => state.get('exemptedIdentitiesError')
)

export const selectAddRemoveCertIdsError = createSelector(
  selectSonraiDataDomain,
  state => state.get('addRemoveCertIDsError')
)

export const selectExemptedIdentitiesLoading = createSelector(
  selectSonraiDataDomain,
  state => state.get('exemptedIdentitiesLoading')
)

export const selectExemptedIdentitiesAdding = createSelector(
  selectSonraiDataDomain,
  state => state.get('exemptedIdentitiesAdding')
)

export const selectExemptedIdentitiesDeleting = createSelector(
  selectSonraiDataDomain,
  state => state.get('exemptedIdentitiesDeleting') || Map()
)

export const selectSonraiSearchesLoading = createSelector(
  selectSonraiDataDomain,
  state => state.get('sonraiSearchesLoading')
)

export const selectObjectivesLoading = createSelector(
  selectSonraiDataDomain,
  state => state.get('objectivesLoading')
)

export const selectObjectives = createSelector(
  selectSonraiDataDomain,
  state => state.get('objectives') || List()
)

export const selectObjectivesError = createSelector(
  selectSonraiDataDomain,
  state => state.get('objectivesError')
)

export const selectEnablingObjectives = createSelector(
  selectSonraiDataDomain,
  state => state.get('enablingObjectives')
)

export const selectEnablingAllObjectives = createSelector(
  selectSonraiDataDomain,
  state => state.get('enablingAllObjectives')
)

export const selectEnablingAllObjectivesForSwimlane = createSelector(
  selectSonraiDataDomain,
  state => state.get('enablingAllObjectivesForSwimlane')
)
