import { put, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import gql from 'graphql-tag'
import { getClient } from 'apolloClient'
import { GET_DATA_STORE_CONTENT } from './constants'
import { errDataStoreContent, setDataStoreContent } from './actions'

const DATA_STORE_CONTENTS_QUERY = gql`
  query dataStoreContents($srn: String!) {
    DataStores(where: { srn: { value: $srn } }) {
      items {
        srn
        type
        classificationSet
        contains(where: { label: { value: "DataContainer" } }) {
          items {
            label
            name
            ... on Datacontainer {
              type
              serviceType
            }
            createdDate
            srn
          }
        }
      }
    }
  }
`

function* getDataStoreContent(action) {
  const { srn } = action.payload
  const client = yield getClient()
  try {
    const result = yield client.query({
      variables: { srn },
      query: DATA_STORE_CONTENTS_QUERY,
    })
    const path = ['data', 'DataStores', 'items', 0]
    const content = _.get(result, path, null)
    if (content === null) {
      throw new Error(`Result did not return data at path: ${path}`)
    }
    yield put(setDataStoreContent({ content }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`an error happened fetching dataStoreContent`, e)
    yield put(errDataStoreContent())
  }
}

function* dataStoreNodeSaga() {
  yield takeLatest(GET_DATA_STORE_CONTENT, getDataStoreContent)
}

export default dataStoreNodeSaga
