import React from 'react'
import PropTypes from 'prop-types'
import UserWidget from 'components/UserWidget'

const CreatedByBadge = ({ createdBy, table }) => {
  return <UserWidget srn={createdBy} table={table} />
}

CreatedByBadge.propTypes = {
  createdBy: PropTypes.string,
  table: PropTypes.bool,
}

export default CreatedByBadge
