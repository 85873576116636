/**
 *
 * TabBar
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Tabs, TabList, Tab } from 'react-tabtab'
import { getTheme } from './theme'
import * as customStyle from 'react-tabtab/lib/themes/material-design'

import TabContent from './Tab'
import themeable from 'containers/ThemeManager/Themeable'
class TabBar extends React.Component {
  style = {
    ...customStyle,
    ...getTheme(this.props.theme, this.props.small),
  }

  render() {
    const nCards = this.props.tabs
    const tabTemplate = nCards.map(tab => {
      const closable = this.props.tabs.length > 1 && tab.closable
      return (
        <Tab key={tab.id} closable={closable}>
          <TabContent
            text={tab.text}
            actions={tab.actions}
            small={this.props.small}
          />
        </Tab>
      )
    })

    return (
      <Tabs
        onTabEdit={this.props.handleEdit}
        onTabChange={this.props.changeTab}
        activeIndex={parseInt(this.props.activeTab)}
        customStyle={this.style}
        onTabSequenceChange={this.props.handleTabSequenceChange}
        showModalButton={
          this.props.showModal !== undefined ? this.props.showModal : false
        }
        showArrowButton={this.props.showArrow}
      >
        <TabList>{tabTemplate}</TabList>
      </Tabs>
    )
  }
}

TabBar.propTypes = {
  changeTab: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      actions: PropTypes.array,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ).isRequired,
  theme: PropTypes.shape({
    primary: PropTypes.string,
    dark: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  handleTabSequenceChange: PropTypes.func,
  showModal: PropTypes.bool,
  showArrow: PropTypes.bool,
  small: PropTypes.bool,
}

export default themeable(TabBar)
