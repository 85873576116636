import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

import BorderedCard from 'components/BorderedCard'
import { TopTitle, CardBody } from 'components/Card'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'

import { getNodeViewPushParams } from 'utils/sonraiUtils'

import messages from './messages'

const styles = {
  card: {
    height: '100%',
  },
  title: {
    fontSize: '14px',
    fontWeight: '300',
    borderBottom: '1px solid #eee',
    marginRight: '0px',
  },
  body: {
    alignItems: 'start',
    paddingTop: '5px',
    height: 'calc(100% - 10px)',
    background: 'none',
  },
  list: {
    fontSize: '12px',
    width: '100%',
  },
  listItem: {
    cursor: 'pointer',
    paddingBottom: '1px',
  },
}

/**
 * this component just shows a list of links in a bordered card. The linkswill
 * navigate to the node views
 */
export class ListCard extends React.Component {
  handleLinkClick = item => {
    if (this.props.handleLinkClick) {
      this.props.handleLinkClick(item)
    } else {
      const { srn, label } = item
      this.props.push(getNodeViewPushParams(srn, label))
    }
  }

  /**
   * users could pass their own custom link text generator, otherwise just use
   * the `name` property
   */
  getLinkText = item => {
    if (this.props.getItemText) {
      return this.props.getItemText(item)
    } else {
      if (item.name) {
        return item.name
      } else if (item.srn) {
        return item.srn
      } else {
        return ''
      }
    }
  }

  /**
   * this renders a TextLink inside an anchor tag b/c if you left-click it open
   * a modal, but if you right click you get the link menu that says "open in
   * new tab"
   */
  renderListItem = (item, index) => {
    const linkParams = getNodeViewPushParams(item.srn, item.label)
    return (
      <li
        className="access-activity-list-card-link"
        onClick={() => this.handleLinkClick(item)}
        key={`listCardItem_${index}`}
      >
        <a
          href={`${linkParams.pathName}?${linkParams.search}`}
          style={{ fontWeight: 300 }}
          onClick={
            // prevent actually navigating the page, we want a react-router nav
            e => {
              e.preventDefault()
            }
          }
        >
          <TextLink color={'primary'}>{this.getLinkText(item)}</TextLink>
        </a>
      </li>
    )
  }

  renderCardBody = () => {
    // show the loading spinner if fetching not complete
    if (this.props.isLoading) {
      return (
        <span>
          <SquareLoadingAnimation />
        </span>
      )
    }

    // show error if error fetching data
    if (this.props.error) {
      return (
        <span style={styles.list}>
          <FormattedMessage {...messages.error} />
        </span>
      )
    }

    // if the data is null but we made it this far in method, our properties
    // are some invalid state so log a warning and show the error
    if (!this.props.data) {
      // eslint-disable-next-line
      console.warn('ListCard component did not recieve data as props')
      return (
        <span style={styles.list}>
          <FormattedMessage {...messages.error} />
        </span>
      )
    }

    // if no data, display "None"
    if (this.props.data.length === 0) {
      return (
        <span style={styles.list}>
          <FormattedMessage {...messages.nodata} />
        </span>
      )
    }

    // sort the items in alphabetical order based on what the text is
    const sortedData = _.sortBy(this.props.data, item =>
      this.getLinkText(item).toUpperCase()
    )

    // return our list
    return <ul style={styles.list}>{sortedData.map(this.renderListItem)}</ul>
  }

  render() {
    return (
      <BorderedCard style={styles.card}>
        <TopTitle style={styles.title}>{this.props.title}</TopTitle>
        <CardBody style={styles.body}>{this.renderCardBody()}</CardBody>
      </BorderedCard>
    )
  }
}

ListCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.bool,
  getItemText: PropTypes.func,
  handleLinkClick: PropTypes.func,
  isLoading: PropTypes.bool,
  push: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
}

export default ListCard
