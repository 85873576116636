import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { exists } from 'utils/sonraiUtils'
import Icon from 'components/Icon'
import Hoverable from 'components/Hoverable'
import Popover, { PopoverAnchor, PopoverBody } from 'components/Popover'
import BorderlessButton from 'components/BorderlessButton'
import SearchInput from 'components/SearchInput'
import { CRM_ALERT_INDICATOR } from './constants'
import { PlainCheckbox } from 'components/Checkbox'
import TextLink from 'components/TextLink'
import _ from 'lodash'
const styles = {
  option: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    padding: '0.5rem',
    margin: '0.5rem 0rem',
    alignSelf: 'center',
  },
  hoveredOption: {
    backgroundColor: '#EEE',
  },
  anchor: {
    fontSize: '0.9em',
  },
  column: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    padding: '0.75rem',
    margin: '0.25rem 0rem',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mainContainer: { width: '100%', height: '100%' },
  tabContainer: {
    minWidth: '200px',
    minHeight: '250px',
    overflow: 'auto',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 10px 3px rgba(200,200,200,1)',
    opacity: 1,
  },
  tabHeader: {
    height: '30px',
    display: 'flex',
    fontSize: '12px',
  },
  tab: {
    backgroundColor: '#EEE',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
  },
  optionsContainer: {
    flex: 1,
    maxHeight: '255px',
    overflow: 'auto',
    fontSize: '13px',
    fontWeight: 400,
    width: '250px',
  },
  filterInput: {
    padding: '0.3em',
  },
}

export class TableActions extends React.Component {
  state = {
    main: true,
    column: false,
    open: false,
    filter: '',
  }

  setFilter = e => {
    this.setState({
      filter: e.target.value,
    })
  }

  getEmptyColumns = () => {
    const { rows } = this.props
    const columnNames = _.keys(_.first(rows))
    let emptyRows = []
    columnNames.forEach(column => {
      rows.forEach(row => {
        if (row[column] === '-' || !exists(row[column])) {
          emptyRows.push(column)
        }
      })
    })
    const countOfEmptyRows = emptyRows.reduce((obj, x) => {
      obj[x] = ++obj[x] || 1
      return obj
    }, {})
    const columnsToHide = _.keys(countOfEmptyRows).filter(key => {
      if (countOfEmptyRows[key] === rows.length) {
        return true
      }
    })

    return columnsToHide
  }

  hideEmptyColumns = () => {
    const { rows } = this.props
    if (rows && !_.isEmpty(rows)) {
      let columnsToHide = this.getEmptyColumns()
      if (columnsToHide && !_.isEmpty(columnsToHide)) {
        this.props.columnApi.setColumnsVisible(columnsToHide, false)
      }
    }
  }

  toggleMenu = () => {
    this.setState(currentState => ({
      open: !currentState.open,
    }))
  }

  autoSize = () => {
    const columns = this.props.columnApi
      .getAllGridColumns()
      .filter(col => col.colId !== CRM_ALERT_INDICATOR)
    this.props.columnApi.autoSizeColumns(columns)
  }

  toggleMainTab = e => {
    if (!this.state.main) {
      if (this.state.column) {
        this.setState({
          column: false,
        })
      }
      this.setState({
        main: true,
      })
    }

    e.stopPropagation()
  }

  toggleColumnTab = e => {
    if (!this.state.column) {
      if (this.state.main) {
        this.setState({
          main: false,
        })
      }
      this.setState({
        column: true,
      })
    }
    e.stopPropagation()
  }

  handleColumnSelect = column => {
    this.props.columnApi.setColumnVisible(column.colId, column.hide)
  }

  handleSelectAllChange = checked => {
    const columns = this.props.columnApi
      .getAllGridColumns()
      .map(col => col.colId)

    if (checked) {
      this.props.columnApi.setColumnsVisible(columns, true)
    } else {
      this.props.columnApi.setColumnsVisible(columns, false)
    }
  }

  getOptions = () => {
    if (this.state.main) {
      const options = [
        {
          name: 'Hide Empty Columns',
          action: () => {
            this.hideEmptyColumns()
            this.toggleMenu()
          },
        },
        {
          name: 'Expand All',
          action: () => {
            this.props.api.expandAll()
            this.toggleMenu()
          },
        },
        {
          name: 'Collapse All',
          action: () => {
            this.props.api.collapseAll()
            this.toggleMenu()
          },
        },
        {
          name: 'Auto Size All',
          action: () => {
            this.autoSize()
            this.toggleMenu()
          },
        },
        {
          name: 'Reset Columns',
          action: () => {
            this.props.columnApi.resetColumnState()
            this.toggleMenu()
          },
        },
        {
          name: 'Export CSV',
          action: () => {
            this.props.api.exportDataAsCsv({
              fileName: this.props.exportFileName
                ? `${this.props.exportFileName} - ${moment().format(
                    'YYYY-M-D H:mm'
                  )}`
                : undefined,
              processCellCallback: ({ column: { colId }, value }) => {
                if (typeof value === 'string') {
                  if (
                    value.startsWith('=') ||
                    value.startsWith('+') ||
                    value.startsWith('-') ||
                    value.startsWith('@')
                  ) {
                    return `'${value}'`
                  }
                }
                if (
                  colId === 'resource' &&
                  value &&
                  typeof value === 'object'
                ) {
                  return value.friendlyName || value.name || value.srn
                }

                return value
              },
            })
            this.toggleMenu()
          },
        },
      ]
      return options.map(option => (
        <Hoverable
          hoverStyle={styles.hoveredOption}
          key={option.name}
          onClick={option.action}
          style={styles.option}
        >
          {option.name}
        </Hoverable>
      ))
    } else {
      let columns = this.props.columnApi
        .getColumnState()
        .map(x => ({
          colId: x.colId,
          hide: x.hide,
        }))
        .filter(col => col.colId !== CRM_ALERT_INDICATOR)

      if (this.state.filter) {
        columns = columns.filter(col =>
          col.colId.toLowerCase().includes(this.state.filter.toLowerCase())
        )
      }

      columns.sort((a, b) => a.colId.localeCompare(b.colId))

      const hasSelectedAll = !_.isEmpty(columns)
        ? columns.filter(col => !col.hide).length === columns.length
        : false
      return (
        <div>
          <div style={styles.filterInput}>
            <SearchInput value={this.state.filter} onChange={this.setFilter} />
          </div>
          <div
            style={{
              ...styles.column,
              ...{ borderBottom: '1px solid #e0e0e0' },
            }}
          >
            <span> Select All</span>
            <PlainCheckbox
              checked={hasSelectedAll}
              onChange={this.handleSelectAllChange}
            />
          </div>
          {columns.map(column => (
            <div key={column.colId} style={styles.column}>
              <span> {column.colId} </span>
              <PlainCheckbox
                checked={!column.hide}
                onChange={() => this.handleColumnSelect(column)}
              />
            </div>
          ))}
        </div>
      )
    }
  }

  render() {
    return (
      <Popover borderless>
        <PopoverAnchor>
          <TextLink
            color="secondary"
            style={styles.anchor}
            invert={this.props.active}
          >
            Table Actions
          </TextLink>
        </PopoverAnchor>
        <PopoverBody>
          <div style={styles.tabHeader}>
            <BorderlessButton
              title="Actions"
              onClick={this.toggleMainTab}
              style={{
                ...styles.tab,
                ...{
                  borderBottom: this.state.main ? `2px solid #1B75BC` : 'none',
                },
              }}
            >
              <Icon
                fa
                name="menu"
                color={this.state.main ? '#1B75BC' : '#C8C8C8'}
              />
            </BorderlessButton>
            <BorderlessButton
              title="Hide/Show columns"
              onClick={this.toggleColumnTab}
              style={{
                ...styles.tab,
                ...{
                  borderBottom: this.state.column
                    ? `2px solid #1B75BC`
                    : 'none',
                },
              }}
            >
              <Icon
                fa
                name="column"
                color={this.state.column ? '#1B75BC' : '#C8C8C8'}
              />
            </BorderlessButton>
          </div>
          <div style={styles.optionsContainer}>{this.getOptions()}</div>
        </PopoverBody>
      </Popover>
    )
  }
}

TableActions.propTypes = {
  active: PropTypes.bool,
  api: PropTypes.object,
  columnApi: PropTypes.object,
  exportFileName: PropTypes.string,
  rows: PropTypes.array,
}

export default TableActions
