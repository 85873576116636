import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Radium from 'radium'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import qs from 'query-string'
import TextLink from 'components/TextLink'
import { SEARCH_VIEWS } from 'containers/Search/constants'
import errorWidget from 'assets/sadcloud.png'
import BorderlessButton from 'components/BorderlessButton'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Card, { Toolbar, BottomTitle, CardBody } from 'components/Card'
import Icon from 'components/Icon'
import IHelp from 'containers/IHelp'
import messages from './messages.js'
import styles from './styles'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import CreatedByBadge from 'components/CreatedByBadge'

export class WidgetCardContent extends React.Component {
  //Required to prevent the onClick event accidentially getting passed to refetch as an argument
  refetch = () => {
    this.props.refetch()
  }

  getFilterTitle = pivot => {
    if (pivot !== undefined) {
      let message = pivot.getIn(['relativeDate', 'label'], null)
      if (message !== null) {
        return message
      } else {
        return 'No Data'
      }
    }
  }

  getDropdownActions = () => {
    const actions = []
    // UI search
    if (this.props.searchId && this.props.searchId.get('uiSearch')) {
      actions.push(
        <BorderlessButton
          key="viewSearch"
          style={{
            ...styles.link,
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <TextLink
            style={{ color: 'inherit' }}
            to={{
              pathname: '/App/Search/',
              search: qs.stringify({
                searchId: this.props.searchId.get('uiSearch'),
                view: SEARCH_VIEWS.RESULTS,
              }),
            }}
          >
            View Search
          </TextLink>
        </BorderlessButton>
      )
      // else must be a reg string which is an Advanced search name
    }
    if (this.props.searchId && this.props.searchId.get('advancedSearch')) {
      const searches = this.props.searchId.get('advancedSearch')
      let index = 0
      if (Object.keys(searches).length > 1) {
        index = 1
      }
      for (let name in searches) {
        actions.push(
          <BorderlessButton
            key={`viewSearch${index}`}
            style={{
              ...styles.link,
              border: 'none',
              boxShadow: 'none',
            }}
          >
            <TextLink
              style={{ color: 'inherit' }}
              to={{
                pathname: '/App/GraphExplorer/',
                state: {
                  searchName: name,
                  preloadQuery: searches[name],
                },
              }}
            >
              {`View Search${index > 0 ? ` ${index}` : ''}`}
            </TextLink>
          </BorderlessButton>
        )
        index++
      }
    }
    if (this.props.onEdit && this.props.allowUpdate) {
      actions.push(
        <BorderlessButton
          key="edit"
          onClick={this.props.onEdit}
          style={{
            ...styles.link,
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <FormattedMessage {...messages.edit} />
        </BorderlessButton>
      )
    }

    if (this.props.onClickSearch) {
      actions.push(
        <BorderlessButton
          key="viewsearch"
          onClick={this.props.onClickSearch}
          style={{
            ...styles.link,
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <FormattedMessage {...messages.viewSearch} />
        </BorderlessButton>
      )
    }

    if (this.props.toggleStatic && this.props.allowUpdate) {
      actions.push(
        <BorderlessButton
          key="pin"
          onClick={this.props.toggleStatic}
          style={{
            ...styles.link,
            border: 'none',
            boxShadow: 'none',
          }}
        >
          {this.props.static ? (
            <FormattedMessage {...messages.unpin} />
          ) : (
            <FormattedMessage {...messages.pin} />
          )}
        </BorderlessButton>
      )
    }

    if (this.props.onRemove && this.props.allowDelete) {
      actions.push(
        <BorderlessButton
          key="delete"
          onClick={this.props.onRemove}
          style={{
            ...styles.link,
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <FormattedMessage {...messages.delete} />
        </BorderlessButton>
      )
    }
    actions.push(
      <BorderlessButton
        disabled
        key="createdBy"
        style={{
          ...styles.link,
          border: 'none',
          boxShadow: 'none',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span style={{ margin: '0rem 0.5rem 0rem 0rem' }}>Created By: </span>
        <CreatedByBadge table createdBy={this.props.createdBy} />
      </BorderlessButton>
    )

    return actions
  }

  getMoveToolbar = () => {
    const { uuid } = this.props

    const hoverStyle = { ':hover': { color: this.props.theme.primary } }

    const buttons = []

    if (this.props.allowUpdate) {
      buttons.push(
        <span
          key={`dragHandle-${uuid}`}
          className="drag-handle"
          style={this.props.static ? styles.hidden : hoverStyle}
        >
          <Icon fa name="arrows" style={styles.toolbarButton} />
        </span>
      )
    }

    if (
      this.props.toggleStatic &&
      this.props.allowUpdate &&
      this.props.static
    ) {
      buttons.push(
        <span
          key={`pinButton-${uuid}`}
          onClick={this.props.toggleStatic}
          style={{
            ...styles.pinButton,
            color: this.props.theme.emphasis,
            ...hoverStyle,
          }}
        >
          <Icon fa name="thumbtack-solid" style={styles.toolbarButton} />
        </span>
      )
    }

    return buttons
  }

  getToolbar = () => {
    const { uuid } = this.props
    const hoverStyle = { ':hover': { color: this.props.theme.primary } }

    const buttons = []
    if (
      this.props.refetch &&
      this.props.networkStatus !== 4 &&
      this.props.networkStatus !== 6
    ) {
      buttons.push(
        <span
          key={`refreshButton-${uuid}`}
          onClick={this.refetch}
          style={hoverStyle}
        >
          <Icon
            fa
            name="refresh"
            style={{ ...styles.toolbarButton, float: 'right' }}
          />
        </span>
      )
    }
    return buttons
  }

  getStatusIndicators = () => {
    const { uuid } = this.props

    if (this.props.disableToolbar) {
      return []
    }

    const buttons = []
    const hoverStyle = { ':hover': { color: this.props.theme.primary } }

    if (
      this.props.refetch &&
      (this.props.networkStatus === 4 || this.props.networkStatus === 6)
    ) {
      buttons.push(
        <span
          key={`refreshButton-${this.props.uuid}`}
          onClick={this.refetch}
          style={hoverStyle}
        >
          <Icon
            fa
            name="refresh"
            style={{ ...styles.toolbarButton, float: 'right' }}
            spin
          />
        </span>
      )
    }

    if (this.props.description) {
      buttons.push(
        <span key={`help-${uuid}`} style={hoverStyle}>
          <IHelp
            id={`help-${uuid}`}
            info={true}
            infoMsg={this.props.description}
            iconSize="20px"
            position="right"
          />
        </span>
      )
    }

    if (this.props.filtered) {
      buttons.push(
        <div
          key={`filterButton-${this.props.uuid}`}
          title={this.getFilterTitle(this.props.pivot)}
        >
          <Icon
            fa
            name="filter-solid"
            style={{
              ...styles.filterIcon,
              ...{
                color: this.props.theme.emphasis,
              },
            }}
            title={this.props.intl.formatMessage(messages.filterApplied)}
          />
        </div>
      )
    }

    return buttons
  }

  renderContent = () => {
    if (this.props.error) {
      const errStr =
        typeof this.props.error === 'string'
          ? this.props.error
          : this.props.error.toString()

      return (
        <Fragment>
          <CardBody style={{ overflow: 'auto', height: 'auto' }}>
            <div style={styles.error}>
              <img src={errorWidget} style={styles.errorImg} />
              <p style={{ marginBottom: 0 }}>
                {errStr.includes('Received status code 504') ? (
                  'Timed out'
                ) : (
                  <FormattedMessage {...messages.error} />
                )}
              </p>
            </div>
          </CardBody>

          <BottomTitle>{this.props.title}</BottomTitle>
        </Fragment>
      )
    }

    if (this.props.loading) {
      return (
        <Fragment>
          <CardBody style={{ overflow: 'auto', height: 'auto' }}>
            <span className="card-content-loading" style={styles.loading}>
              <SquareLoadingAnimation />
            </span>
          </CardBody>
          <BottomTitle>{this.props.title}</BottomTitle>
        </Fragment>
      )
    }

    return this.props.children
  }

  render() {
    return (
      <Card
        title={this.props.title}
        loading={this.props.loading || this.props.networkStatus === 1}
        className="react-grid-item react-draggable cssTransforms react-resizable"
        containerStyle={this.props.containerStyle}
        style={this.props.style}
      >
        {this.renderContent()}
        {!this.props.disableToolbar && (
          <Toolbar
            style={this.props.headerStyle}
            leftToolbarButtons={this.getMoveToolbar()}
            rightToolbarButtons={this.getToolbar()}
            dropdownActions={this.getDropdownActions()}
            statusButtons={this.getStatusIndicators()}
          />
        )}
      </Card>
    )
  }
}

WidgetCardContent.propTypes = {
  searchId: ImmutablePropTypes.contains({
    uiSearch: PropTypes.string,
    advancedSearch: PropTypes.array,
  }),
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  children: PropTypes.node,
  containerStyle: PropTypes.object,
  description: PropTypes.node,
  disableToolbar: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  filtered: PropTypes.bool,
  headerStyle: PropTypes.object,
  intl: intlShape,
  loading: PropTypes.bool,
  networkStatus: PropTypes.number,
  onClickSearch: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  refetch: PropTypes.func,
  static: PropTypes.bool,
  style: PropTypes.object,
  theme: themeShape,
  title: PropTypes.node,
  toggleStatic: PropTypes.func,
  uuid: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
}

export default injectIntl(themeable(Radium(WidgetCardContent)))
