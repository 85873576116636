import _ from 'lodash'
import { isImmutable, List } from 'immutable'

import {
  AUTH_MODE_USERNAME_KV,
  AUTH_MODE_USERNAME_SECRETMGR,
  CLASSIFICATION_TYPE_DB,
  CLASSIFICATION_TYPE_BUCKET,
} from './constants'

// these things can have a classification job submitted for them
const supportedConfigs = [
  // AWS Bucket
  {
    label: 'DataContainer',
    cloudType: 'aws',
    type: 'Bucket',
    __type: CLASSIFICATION_TYPE_BUCKET,
  },

  // Azure Blob Storage
  {
    label: 'DataContainer',
    cloudType: 'azure',
    type: 'Container',
    __type: CLASSIFICATION_TYPE_BUCKET,
  },

  // Azure MS SQL
  {
    label: 'DataContainer',
    cloudType: 'azure',
    type: 'Database',
    serviceType: 'Microsoft.Sql',
    __type: CLASSIFICATION_TYPE_DB,
    __auth: AUTH_MODE_USERNAME_KV,
  },

  // Azure Postgres
  {
    label: 'DataContainer',
    cloudType: 'azure',
    __parent: {
      label: 'DataStore',
      type: 'PostgreSQL',
    },
    __type: CLASSIFICATION_TYPE_DB,
    __auth: AUTH_MODE_USERNAME_KV,
  },

  // Azure MySQL
  {
    label: 'DataContainer',
    cloudType: 'azure',
    __parent: {
      label: 'DataStore',
      type: 'MySQL',
    },
    __type: CLASSIFICATION_TYPE_DB,
    __auth: AUTH_MODE_USERNAME_KV,
  },

  // AWS MySQL
  {
    label: 'DataContainer',
    cloudType: 'aws',
    type: 'Database',
    serviceType: 'rds',
    __parent: {
      label: 'DataStore',
      type: 'MySQL',
    },
    __type: CLASSIFICATION_TYPE_DB,
    __auth: AUTH_MODE_USERNAME_SECRETMGR,
  },

  // AWS Aurora
  {
    label: 'DataContainer',
    cloudType: 'aws',
    type: 'Database',
    serviceType: 'rds',
    __parent: {
      label: 'DataStore',
      type: 'Aurora',
    },
    __type: CLASSIFICATION_TYPE_DB,
    __auth: AUTH_MODE_USERNAME_SECRETMGR,
  },

  // AWS Redshift
  // {
  //   label: 'DataStore',
  //   cloudType: 'aws',
  //   type: 'Redshift',
  //   __type: CLASSIFICATION_TYPE_DB,
  //   __auth: AUTH_MODE_USERNAME_SECRETMGR,
  // },

  // AWS PostgreSQL
  {
    label: 'DataContainer',
    cloudType: 'aws',
    type: 'Database',
    serviceType: 'rds',
    __parent: {
      label: 'DataStore',
      type: 'PostgreSQL',
    },
    __type: CLASSIFICATION_TYPE_DB,
    __auth: AUTH_MODE_USERNAME_SECRETMGR,
  },

  // AWS Dynamo
  {
    label: 'DataContainer',
    cloudType: 'aws',
    type: 'Table',
    serviceType: 'dynamodb',
    __type: CLASSIFICATION_TYPE_DB,
  },
]

// these things can get classifications on them, but cannot have classification
// job submitted for themselves. They'll get classifications from their parent
const hasOnlyClassifications = [
  { label: 'DataObject' },
  { label: 'DataContainer', type: 'Schema' },
  { label: 'DataContainer', type: 'Table' },
  { label: 'DataContainer', type: 'Column' },
]

/**
 * returns a function that will do the util func only if the argument passed
 * is valid
 */
const withValidNodeData = utilFunc => {
  const utilFuncWithValidation = nodeData => {
    if (typeof nodeData !== 'object') {
      throw new Error('IllegalArgument: nodeData must be an object')
    }
    return utilFunc(nodeData)
  }

  return nodeData => {
    if (isImmutable(nodeData)) {
      return utilFuncWithValidation(nodeData.fromJS())
    }
    return utilFuncWithValidation(nodeData)
  }
}

/**
 * Can the thing have classificationSet on it possibly
 */
export const hasClassifications = withValidNodeData(nodeData => {
  return (
    findMatchingconfig(nodeData, [
      ...hasOnlyClassifications,
      ...supportedConfigs,
    ]) !== null
  )
})

/**
 * is Data Classification supported for this resource
 *
 * @param nodeData {Object} the resource (can be immutable)
 * @returns true if supported, false otherwise
 */
export const isClassificationSupported = withValidNodeData(nodeData => {
  return findMatchingconfig(nodeData, supportedConfigs) !== null
})

export const isDB = withValidNodeData(nodeData => {
  const config = findMatchingconfig(nodeData, supportedConfigs)
  if (!config) {
    return false
  }
  return CLASSIFICATION_TYPE_DB === config.__type
})

export const getAuthMode = withValidNodeData(nodeData => {
  const config = findMatchingconfig(nodeData, supportedConfigs)
  if (!config) {
    return null
  }
  if (config.__auth) {
    return config.__auth
  }
  return null
})

const findMatchingconfig = (nodeData, configs) => {
  // Iterate over map of supported configs and see if the thing matches all
  // fields. It's not the fastest way to do it but it'll be easier to maintain
  // this way
  for (let config of configs) {
    if (doesConfigMatch(nodeData, config)) {
      return config
    }
  }
  return null
}

function doesConfigMatch(nodeData, config) {
  const configKeys = Object.keys(config).filter(key => !key.startsWith('__'))
  const fieldsFromNodeData = _.pick(nodeData, configKeys)
  // do a deep equals w/ the picked object
  const propsMatch = _.isEqual(_.pick(config, configKeys), fieldsFromNodeData)

  let parentMatches = true
  if (config.__parent) {
    const parents = getParents(nodeData)
    parentMatches =
      undefined !==
      parents.find(parent => doesConfigMatch(parent, config.__parent))
  }

  return propsMatch && parentMatches
}

function getParents(nodeData) {
  return [
    // get containers
    ..._.get(nodeData, ['isIn', 'items'], []),
    // sometimes isIn aliased to dataStores
    ..._.get(nodeData, ['dataStores', 'items'], []),
  ]
}

export const isBase64Encoded = sample => {
  return sample.match(
    // https://stackoverflow.com/questions/8571501/how-to-check-whether-a-string-is-base64-encoded-or-not
    /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/
  )
}

export const isValidPEM = keyData => {
  const lines = keyData.split('\n')
  if (keyData.length < 3) {
    // eslint-disable-next-line no-console
    console.debug('Key does not have at least 3 lines:\n' + keyData)
    return false
  }

  const firstLine = lines[0]
  const headerSegments = firstLine.match(/(-----BEGIN )([A-Z ]+)(-----)/)

  if (!headerSegments) {
    // eslint-disable-next-line no-console
    console.debug('Key first line did not match the special regex:\n' + keyData)
    return false
  }

  if ('-----BEGIN ' !== headerSegments[1] || '-----' !== headerSegments[3]) {
    // eslint-disable-next-line no-console
    console.debug('Key first line had invalid format:\n' + keyData)
    return false
  }

  const lastLine = lines[lines.length - 1]
  const footerSegments = lastLine.match(/(-----END )([A-Z ]+)(-----)/)

  if (!footerSegments) {
    // eslint-disable-next-line no-console
    console.debug('Key last line did not match the special regex:\n' + keyData)
    return false
  }

  if ('-----END ' !== footerSegments[1] || '-----' !== footerSegments[3]) {
    // eslint-disable-next-line no-console
    console.debug('Key last line had invalid format:\n' + keyData)
    return false
  }

  if (headerSegments[2] != footerSegments[2]) {
    // eslint-disable-next-line no-console
    console.debug('Key type in header does not match footer:\n' + keyData)
    return false
  }

  // check to make sure all the lines are base64 encoded strings
  for (let i = 1; i < lines.length - 1; i++) {
    if (!isBase64Encoded(lines[i])) {
      //eslint-disable-next-line no-console
      console.debug(
        'line ' + i + ' of the key is not valid base64 encoding:\n' + keyData
      )
      return false
    }
  }

  return true
}

/**
 * Sanitize our inputs to make sure users don't try to put in some weird
 * value into an input that only accepts a positive integer
 *
 * @param event {Event} onChange event from an input
 * @param callback {Event} what to do with the positive integer
 * @returns undefined
 */
export const doIfValidInt = (event, callback) => {
  const { value } = event.target
  const invalidValue = value.includes('.') || value.includes('-')
  if (invalidValue) {
    return // that's boog fam
  }
  const asNumber = Number(value)
  if (asNumber || '' === value) {
    callback(asNumber)
  }
}

export const formatDataForDataAnatomy = data => {
  const tableRows = data
    .getIn(['contains', 'items'], List())
    .flatMap(schema => {
      const tables = schema.getIn(['contains', 'items'], [])
      if (tables.size === 0) {
        // if schema has no table show blank columns
        return [
          {
            schema: { name: schema.get('name'), srn: schema.get('srn') },
            table: '',
            column: '',
          },
        ]
      }
      // make a row for each table in the schema
      return tables.flatMap(table => {
        const columns = table.getIn(['contains', 'items'], [])
        if (columns.size === 0) {
          // if the table has no columns, make a blank column
          return [
            {
              schema: { name: schema.get('name'), srn: schema.get('srn') },
              table: { name: table.get('name'), srn: table.get('srn') },
              column: '',
            },
          ]
        } else {
          // make column for each table
          return columns.map(column => ({
            schema: { name: schema.get('name'), srn: schema.get('srn') },
            table: { name: table.get('name'), srn: table.get('srn') },
            column: { name: column.get('name'), srn: column.get('srn') },
          }))
        }
      })
    })
  return tableRows
}
