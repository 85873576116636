/**
 *
 * IconDivider
 *
 */

import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'

const styles = {
  wrapper: {
    display: 'flex',
  },
  line: {
    width: '100%',
    margin: '1em 0.3em',
  },
}

class IconDivider extends React.PureComponent {
  render() {
    let lineStyle = { ...styles.line }
    lineStyle.borderBottom = `1px solid ${this.props.color}`

    return (
      <div style={styles.wrapper}>
        <div style={lineStyle} />
        {this.props.iconName ? (
          <Icon
            fa
            name={this.props.iconName}
            color={this.props.color}
            transform="shrink-8"
          />
        ) : (
          this.props.children
        )}

        <div style={lineStyle} />
      </div>
    )
  }
}

IconDivider.defaultProps = {
  color: '#eeeeee',
}

IconDivider.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  iconName: PropTypes.string,
}

export default IconDivider
