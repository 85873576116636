import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import TextLink from 'components/TextLink'
import Button from 'components/Button'
import Select from 'components/Select'
import FormLabel from 'components/FormLabel'
import { snoozeTickets } from 'containers/TicketDetailsData/actions'

const SNOOZE_UNTIL_OPTIONS = {
  DAYS_7: {
    label: '7 Days',
    value: [7, 'days'],
  },
  DAYS_14: {
    label: '14 Days',
    value: [14, 'days'],
  },
  DAYS_30: {
    label: '30 Days',
    value: [30, 'days'],
  },
  DAYS_90: {
    label: '90 days',
    value: [90, 'days'],
  },
  DAYS_180: {
    label: '180 days',
    value: [180, 'days'],
  },
  DAYS_1YEAR: {
    label: 'One year',
    value: [1, 'year'],
  },
}

function formatOptionValue({ value }) {
  return moment()
    .add(...value)
    .format('YYYY-MM-DD')
}

export const SnoozeTicketModal = props => {
  const [snoozeUntil, setSnoozeUntil] = useState(null)

  const onSubmit = () => {
    props.snoozeTickets({
      ticketSrns: props.ticketSrns,
      snoozedUntil: formatOptionValue(snoozeUntil),
    })
    props.close()
  }

  return (
    <Modal isOpen={true}>
      <ModalHeader>Snooze Tickets</ModalHeader>
      <ModalBody>
        <FormLabel required>Snooze Interval</FormLabel>
        <Select
          options={Object.values(SNOOZE_UNTIL_OPTIONS)}
          onChange={val => setSnoozeUntil(val)}
        />
      </ModalBody>
      <ModalFooter>
        <TextLink color="secondary" onClick={props.close}>
          Cancel
        </TextLink>
        <Button
          color="primary"
          disabled={snoozeUntil == null}
          onClick={onSubmit}
        >
          Snooze
        </Button>
      </ModalFooter>
    </Modal>
  )
}

SnoozeTicketModal.propTypes = {
  close: PropTypes.func.isRequired,
  snoozeTickets: PropTypes.func.isRequired,
  ticketSrns: PropTypes.array,
}

const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      snoozeTickets,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(SnoozeTicketModal)
