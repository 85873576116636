import React from 'react'
import PropTypes from 'prop-types'
import CloudAccount from 'components/CloudAccount'
import { getAccountFromSrn } from 'utils/sonraiUtils'
class NodeViewDetailAccount extends React.PureComponent {
  hasLink = () => {
    const { __typename, srn } = this.props.nodeData
    if (__typename === 'Account') {
      if (getAccountFromSrn(srn) === this.props.accountId) {
        return false
      }
    }
    return true
  }
  render() {
    return (
      <div>
        <CloudAccount
          hasCopy
          hasTooltip
          static={false}
          hasLink={this.hasLink()}
          accountId={this.props.accountId}
        />
      </div>
    )
  }
}

NodeViewDetailAccount.propTypes = {
  accountId: PropTypes.string.isRequired,
  nodeData: PropTypes.object,
}

export default NodeViewDetailAccount
