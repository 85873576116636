import { defineMessages } from 'react-intl'

export default defineMessages({
  actionsTitle: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.actionsTitle',
    defaultMessage: 'Actions (Last 7 Days)',
  },
  subnetsTitle: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.subnetsTitle',
    defaultMessage: 'Subnets',
  },
  nodeViewType: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.nodeViewType',
    defaultMessage: 'Compute',
  },
  blockStorageTitle: {
    id:
      'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.blockStorageTitle',
    defaultMessage: 'Block Storage',
  },
  networksTitle: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.networksTitle',
    defaultMessage: 'Networks',
  },
  accountLabel: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id:
      'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  launchTimeLabel: {
    id:
      'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.launchTimeLabel',
    defaultMessage: 'Launch Time',
  },
  typeLabel: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.typeLabel',
    defaultMessage: 'Type',
  },
  stateLabel: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.stateLabel',
    defaultMessage: 'State',
  },
  basedOnLabel: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.basedOnLabel',
    defaultMessage: 'Based On',
  },
  activeLabel: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.activeLabel',
    defaultMessage: 'Active',
  },
  ebsOptimizedLabel: {
    id:
      'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.ebsOptimizedLabel',
    defaultMessage: 'EBS Optimized',
  },
  encryptionEnabledLabel: {
    id:
      'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.encryptionEnabledLabel',
    defaultMessage: 'Encryption Enabled',
  },
  architectureLabel: {
    id:
      'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.architectureLabel',
    defaultMessage: 'Architecture',
  },
  timezoneLabel: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.timezoneLabel',
    defaultMessage: 'Timezone',
  },
  isPublicLabel: {
    id: 'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.isPublicLabel',
    defaultMessage: 'Public',
  },
  networkConnection: {
    id:
      'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.networkConnection',
    defaultMessage: 'Network Connections',
  },
  securityGroups: {
    id:
      'app.container.NodeViewLayouts.ComputeNodeViewCardLayout.securityGroups',
    defaultMessage: 'Security Groups',
  },
})
