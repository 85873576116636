import { all, takeLatest, put, select } from 'redux-saga/effects'
import { List, Map } from 'immutable'
import _ from 'lodash'
import moment from 'moment'
import { QueryBuilder } from 'query-builder'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'

import { modifyFieldsForSRN } from './utils'
import { metadataToObj, shiftDateArgs } from 'utils/sonraiUtils'
import { selectSonraiUserSrn } from 'containers/UserProfileData/selectors'

import {
  assignToMeError,
  assignToMeSuccess,
  acceptRiskTicketError,
  acceptRiskTicketSuccess,
  closeTicketError,
  closeTicketSuccess,
  fetchTicketDetailsError,
  fetchTicketDetailsSuccess,
  reopenTicketError,
  reopenTicketSucces,
  snoozeTicketError,
  snoozeTicketSuccess,
  fetchTicketCommentsSuccess,
  fetchTicketCommentsError,
  addCommentSuccess,
  addCommentError,
  fetchSimilarTicketsError,
  fetchSimilarTicketsSuccess,
  reassignToUserSuccess,
  reassignToUserError,
  fetchTicketHistorySuccess,
  fetchTicketHistoryError,
  fetchActivityTicketActionsError,
  fetchActivityTicketActionsSuccess,
  fetchPolicyEvidenceLegacyError,
  fetchPolicyEvidenceLegacySuccess,
  fetchPolicyPathSuccess,
  fetchPolicyPathError,
  ticketStatusChangeSuccess,
  closeTicketsSuccess,
  bulkActionComplete,
  bulkActionStart,
} from './actions'

import {
  ASSIGN_TO_ME,
  ACCEPT_RISK_TICKET,
  CLOSE_TICKET,
  FETCH_TICKET_DETAILS,
  REOPEN_TICKET,
  SNOOZE_TICKET,
  FETCH_TICKET_COMMENTS,
  ADD_COMMENT,
  FETCH_SIMILAR_TICKETS,
  CHANGE_SIMILAR_TICKETS_FILTERS,
  REASSIGN_TO_USER,
  FETCH_TICKET_HISTORY,
  FETCH_ACTIVITY_TICKET_ACTIONS,
  FETCH_POLICY_EVIDENCE_LEGACY,
  FETCH_POLICY_PATH,
  CLOSE_TICKETS,
  ASSIGN_TICKETS,
  ACCEPT_RISK_TICKETS,
  SNOOZE_TICKETS,
  BULK_COMMENT,
  BULK_TAG,
} from './constants'

import {
  ACCEPT_RISK_MUTATION,
  CLOSE_TICKET_MUTATION,
  FETCH_TICKET_DETAILS_QUERY,
  REOPEN_TICKET_MUTATION,
  SNOOZE_TICKET_MUTATION,
  FETCH_TICKET_COMMENTS_QUERY,
  ADD_TICKET_COMMENT_MUTATION,
  FETCH_SIMILAR_TICKETS_QUERY,
  REASSIGN_TICKET_MUTATION,
  FETCH_TICKET_HISTORY_QUERY,
  ACTIONS_QUERY,
  TICKET_BOT_ACTIONS_QUERY,
} from './queries'

import {
  selectSavedSearches,
  selectQueryTypes,
} from 'containers/SonraiData/selectors'

import { selectPolicies } from 'containers/ControlFrameworkData/selectors'
import { selectTicketDetails, selectSimilarTickets } from './selectors'

export async function performTicketFetch({ srn }) {
  const client = await getClient()
  const result = await client.query({
    query: FETCH_TICKET_DETAILS_QUERY,
    variables: { srn },
  })

  const path = ['data', 'Tickets', 'items', 0]
  const ticket = _.get(result, path, null)
  if (ticket == null) {
    throw new Error(`No ticket could be found at path ${path}`)
  }
  return ticket
}

function* fetchResourceOnTicket(srn) {
  try {
    const client = getClient()
    const result = yield client.query({
      query: gql`
        query getResourceOnTicket($srn: String!) {
          Entities(where: { srn: { value: $srn } }) {
            items {
              srn
              tagSet
              ... on Resource {
                account
                active
                createdDate
                cloudType
                country
                friendlyName
                name
                region
                resourceId
                importance
                label
                swimlaneSRNs
                __typename
              }
            }
          }
        }
      `,
      variables: { srn },
    })
    const path = ['data', 'Entities', 'items', 0]
    return _.get(result, path)
  } catch (e) {
    // eslint-disable-next-line
    console.error('An error happened fetching the resource on ticket', e)
  }
}

//TODO: Remove policyPath fetch as it is no longer used. Remove from actions/reducer etc.
function* fetchPolicyPath({ payload }) {
  try {
    const srn = payload.resourceSrn

    const searchId = payload.searchId

    if (!searchId) {
      return
    }

    const searches = yield select(selectSavedSearches)
    const search = searches.get(searchId, Map())

    if (search.isEmpty()) {
      return
    }

    const queryTypes = yield select(selectQueryTypes)

    let fields = search.getIn(['query', 'fields'])
    const rootIndex = fields
      .find(field => !field.get('parentId'), null, Map())
      .get('id')
    fields = modifyFieldsForSRN(srn, rootIndex, fields)

    fields = fields.map(field =>
      field
        .updateIn(['arguments', 'and'], List(), andArgs =>
          shiftDateArgs(andArgs, payload.timestamp)
        )
        .updateIn(['arguments', 'or'], List(), orArgs =>
          shiftDateArgs(orArgs, payload.timestamp)
        )
    )

    const queryBuilder = new QueryBuilder({
      query: fields,
      types: queryTypes,
    })
    queryBuilder.enableFlattenMode()
    queryBuilder.skipCounts()

    const queryConfig = queryBuilder.buildPivotableSource(rootIndex)

    const client = getClient()
    const response = yield client.query({
      query: gql`
        ${queryConfig.gqlStatement}
      `,
      variables: queryConfig.variables,
    })

    yield put(fetchPolicyPathSuccess(response))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error fetching data', e)
    yield put(fetchPolicyPathError())
  }
}

function* fetchNextEscalationOnTicket(srn) {
  try {
    const client = getClient()
    const result = yield client.query({
      query: gql`
        query getNextEscalation($srn: String!) {
          GetNextEscalation(input: { ticktSrn: $srn }) {
            escalationTime
            rule {
              actionType
              assignUser {
                userSrn
              }
              assignRole {
                ActionRoleType
              }
              escalateToBot {
                botSrn
              }
            }
          }
        }
      `,
      variables: { srn },
    })
    const path = ['data', 'GetNextEscalation']
    if (_.isArray(result.errors) && result.errors.length > 0) {
      throw new Error(JSON.stringify(result.errors))
    }
    return _.get(result, path)
  } catch (e) {
    return null
    // eslint-disable-next-line
    console.error('An error happened fetching the next escalation on ticket', e)
  }
}

function* fetchTicketDetails(action) {
  try {
    const ticket = yield performTicketFetch(action.payload)
    const resource = yield fetchResourceOnTicket(ticket.resourceSRN)
    const nextEscalation = yield fetchNextEscalationOnTicket(ticket.srn)
    ticket.resource = resource || {}
    ticket.nextEscalation = nextEscalation
    yield put(fetchTicketDetailsSuccess({ data: ticket }))
  } catch (e) {
    // eslint-disable-next-line
    console.error('An error happened in fetchTicketDetails: ', e)
    yield put(fetchTicketDetailsError())
  }
}

function* acceptRiskTicket(action) {
  try {
    const { srn } = action.payload
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: ACCEPT_RISK_MUTATION,
      variables: { srn },
    })

    if (_.isArray(result.errors) && result.errors.length > 0) {
      throw new Error(JSON.stringify(result.errors))
    }

    const path = ['data', 'AcceptRiskTickets', 'successCount']
    const data = _.get(result, path, null)
    if (data === null) {
      // check if we got a valid response, if not, it's a chance error happen
      throw new Error(`no result at path ${path}`)
    }
    yield yield all([
      updateTicketStatus(srn, 'RISK_ACCEPTED'),
      put(acceptRiskTicketSuccess()),
    ])
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An errror occurred closing ticket', e)
    yield put(acceptRiskTicketError())
  }
}

function* closeTicket(action) {
  try {
    const { srn } = action.payload
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: CLOSE_TICKET_MUTATION,
      variables: { srn },
    })

    if (_.isArray(result.errors) && result.errors.length > 0) {
      throw new Error(JSON.stringify(result.errors))
    }

    const path = ['data', 'CloseTickets', 'successCount']
    const data = _.get(result, path, null)
    if (data === null) {
      // check if we got a valid response, if not, it's a chance error happen
      throw new Error(`no result at path ${path}`)
    }
    yield yield all([
      updateTicketStatus(srn, 'CLOSED'),
      put(closeTicketSuccess()),
    ])
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An errror occurred closing ticket', e)
    yield put(closeTicketError())
  }
}

function* reopenTicket(action) {
  try {
    const { srn } = action.payload
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: REOPEN_TICKET_MUTATION,
      variables: { srn },
    })

    if (_.isArray(result.errors) && result.errors.length > 0) {
      throw new Error(JSON.stringify(result.errors))
    }

    const path = ['data', 'ReopenTickets', 'successCount']
    const data = _.get(result, path, null)
    if (data === null) {
      // check if we got a valid response, if not, it's a chance error happen
      throw new Error(`no result at path ${path}`)
    }
    yield all([updateTicketStatus(srn, 'NEW'), put(reopenTicketSucces())])
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An errror occurred closing ticket', e)
    yield put(reopenTicketError())
  }
}

function* snoozeTicket(action) {
  try {
    const { srn, snoozedUntil } = action.payload
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: SNOOZE_TICKET_MUTATION,
      variables: { srn, snoozedUntil },
    })

    if (_.isArray(result.errors) && result.errors.length > 0) {
      throw new Error(JSON.stringify(result.errors))
    }

    const path = ['data', 'SnoozeTickets', 'successCount']
    const data = _.get(result, path, null)
    if (data === null) {
      // check if we got a valid response, if not, it's a chance error happen
      throw new Error(`no result at path ${path}`)
    }
    yield all([updateTicketStatus(srn, 'SNOOZED'), put(snoozeTicketSuccess())])
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error ocurred snoozing ticket', e)
    yield put(snoozeTicketError())
  }
}

function* assignToMe({ payload }) {
  try {
    const { ticketSrn, userSrn } = payload
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: REASSIGN_TICKET_MUTATION,
      variables: { ticketSrn, userSrn },
    })

    if (_.isArray(result.errors) && result.errors.length > 0) {
      throw new Error(JSON.stringify(result.errors))
    }

    const path = ['data', 'AssignTicket']
    const data = _.get(result, path, null)

    if (data === null) {
      // check if we got a valid response, if not, it's a chance error happen
      throw new Error(`no result at path ${path}`)
    }
    yield put(assignToMeSuccess(data))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error ocurred reassigning ticket', e)
    yield put(assignToMeError())
  }
}

function* reassignTicketToUser({ payload }) {
  try {
    const { ticketSrn, userSrn } = payload
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: REASSIGN_TICKET_MUTATION,
      variables: { ticketSrn, userSrn },
    })

    if (_.isArray(result.errors) && result.errors.length > 0) {
      throw new Error(JSON.stringify(result.errors))
    }

    const path = ['data', 'AssignTicket']
    const data = _.get(result, path, null)

    if (data === null) {
      // check if we got a valid response, if not, it's a chance error happen
      throw new Error(`no result at path ${path}`)
    }
    yield put(reassignToUserSuccess(data))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error ocurred reassigning ticket', e)
    yield put(reassignToUserError())
  }
}

function* updateTicketStatus(srn, newStatus) {
  const ticketDetails = (yield select(selectTicketDetails)).get('data', null)
  if (
    ticketDetails == null ||
    !(ticketDetails instanceof Map) ||
    srn !== ticketDetails.get('srn')
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      `could not update internal ticket (${srn}) details state after state change: ${ticketDetails}`
    )
    return
  }

  yield put(
    fetchTicketDetailsSuccess({ data: ticketDetails.set('status', newStatus) })
  )

  yield put(ticketStatusChangeSuccess({ srn, newStatus }))
}

function* getTicketComments(action) {
  try {
    const { ticketSrn } = action.payload
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: FETCH_TICKET_COMMENTS_QUERY,
      variables: { ticketSrn },
    })

    const path = ['data', 'TicketComments', 'items']
    const data = _.get(result, path, null)
    if (data === null) {
      // check if we got a valid response, if not, it's a chance error happen
      throw new Error(`no result at path ${path}`)
    }

    yield put(fetchTicketCommentsSuccess(data))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error ocurred getting ticket comments', e)
    yield put(fetchTicketCommentsError())
  }
}

function* addTicketComment(action) {
  try {
    const { ticketSrn, body, createdBy } = action.payload
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: ADD_TICKET_COMMENT_MUTATION,
      variables: { ticketSrn, body, createdBy },
    })

    const path = ['data', 'CreateTicketComment']
    const data = _.get(result, path, null)
    if (data === null) {
      // check if we got a valid response, if not, it's a chance error happen
      throw new Error(`no result at path ${path}`)
    }

    yield put(addCommentSuccess(data))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error ocurred creating a comment', e)
    yield put(addCommentError())
  }
}

function* fetchSimilarTickets(action) {
  const { ticket } = action.payload
  try {
    // we need to define how to fetch similar tickets:
    const ticketFilter = {}

    // if it's a policy ticket, same policy + swimlanes
    if (ticket.ticketType === 'Policy') {
      ticketFilter.ticketType === { value: ticket.ticketKey }
      ticketFilter.ticketKey = { value: ticket.ticketKey }
      ticketFilter.swimlaneSrns = {
        op: 'IN_LIST',
        values: [...ticket.swimlaneSRNs],
      }
    }

    // if it's a CRM ticket, same ticketType, ticketKey, swimlanes and resource
    else if (['Access', 'Activity', 'Property'].includes(ticket.ticketType)) {
      ticketFilter.ticketType === { value: ticket.ticketKey }
      ticketFilter.ticketKey = { value: ticket.ticketKey }
      ticketFilter.swimlaneSrns = {
        op: 'IN_LIST',
        values: [...ticket.swimlaneSRNs],
      }
      ticketFilter.resourceSRN = { value: ticket.resourceSRN }
    }

    // if it's a custom ticket, look for other custom tickets for the same resourceSRN
    else if (['Custom'].includes(ticket.ticketType)) {
      ticketFilter.ticketKey = { value: ticket.ticketKey }
      ticketFilter.swimlaneSrns = {
        op: 'IN_LIST',
        values: [...ticket.swimlaneSRNs],
      }
      ticketFilter.resourceSRN = { value: ticket.resourceSRN }
    }

    // otherwise, who knows how to fetch simliar tickets! this must be a case
    // we don't handle yet, so throw error
    else {
      // eslint-disable-next-line no-console
      throw new Error(`Unknown type of ticket: ${ticket.ticketKey}`)
    }

    ticketFilter.srn = { op: 'NEQ', value: ticket.srn }

    const filters = (yield select(selectSimilarTickets)).get('filters')
    const client = yield getClient()
    const result = yield client.query({
      query: FETCH_SIMILAR_TICKETS_QUERY,
      variables: {
        ticketFilter: {
          ...filters.toJS(),
          ...ticketFilter,
        },
      },
    })

    const path = ['data', 'Tickets', 'items']
    const data = _.get(result, path, null)
    if (data == null) {
      throw new Error(`no data at path ${path}`)
    }
    yield put(fetchSimilarTicketsSuccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error happen loading similar tickets', e)
    yield put(fetchSimilarTicketsError())
  }
}

function* getTicketHistory({ payload }) {
  const { ticketSrn } = payload
  try {
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: FETCH_TICKET_HISTORY_QUERY,
      variables: { ticketSrn },
    })

    const path = ['data', 'TicketHistoryLogs', 'items']
    const data = _.get(result, path, null)
    if (data === null) {
      // check if we got a valid response, if not, it's a chance error happen
      throw new Error(`no result at path ${path}`)
    }

    const botActions = yield client.mutate({
      mutation: TICKET_BOT_ACTIONS_QUERY,
      variables: { metadataFilter: `data.ticket.srn:${ticketSrn}` },
    })

    const actionData = _.get(botActions, ['data', 'Actions', 'items'], null)
    if (actionData === null) {
      throw new Error(`Error fetching Bot Actions`)
    }

    const combinedData = data
      .map(d => {
        d.succeeded = true
        return d
      })
      .concat(
        actionData.map(action => {
          const metadata = metadataToObj(action.metadata)
          let message = action.eventName

          if (action.eventName === 'ApplyRemediation') {
            if (action.succeeded) {
              message = 'Auto Remediation successfully executed'
            } else {
              message = 'Auto Remediation failed to execute'
            }
          }

          return {
            srn: action.srn,
            message: message,
            actionType: action.eventName,
            createdDate: action.createdDate,
            actorSrn: metadata.botId,
            resourceId: action.resourceId,
            succeeded: action.succeeded,
          }
        })
      )

    yield put(fetchTicketHistorySuccess(combinedData))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error fetching ticket history', e)
    yield put(fetchTicketHistoryError())
  }
}

function* fetchActivityTicketActions(action) {
  try {
    const client = getClient()
    const { ticket } = action.payload
    let actionFilter = {}

    switch (ticket.ticketKey) {
      case 'accessed':
        Object.assign(actionFilter, {
          performedByValue: { value: ticket.resourceSRN },
          performedOnValue: {
            value: _.first(ticket.evidence.resourceSet) || '',
          },
          hasActionClassificationValue: { value: ticket.actionClassification },
        })
        break
      case 'accessedBy':
        Object.assign(actionFilter, {
          performedOnValue: { value: ticket.resourceSRN },
          performedByValue: { value: ticket.evidence.resourceSet[0] },
          hasActionClassificationValue: { value: ticket.actionClassification },
        })
        break
      case 'activeFrom':
        Object.assign(actionFilter, {
          performedByValue: { value: ticket.resourceSRN },
          performedAtCountryValue: { value: ticket.evidence.regionSet[0] },
        })
        break
      case 'accessedFrom':
        Object.assign(actionFilter, {
          performedOnValue: { value: ticket.resourceSRN },
          performedAtCountryValue: { value: ticket.evidence.regionSet[0] },
        })
        break
      case 'accessedUsing':
        Object.assign(actionFilter, {
          performedByValue: { value: ticket.resourceSRN },
          userAgent: { value: ticket.evidence.userAgentSet[0] },
        })
        break
    }

    const { firstSeen, lastSeen } = ticket
    actionFilter.createdDate = { and: [] }
    if (firstSeen) {
      actionFilter.createdDate.and.push({ op: 'GTE', value: firstSeen })
    }

    if (lastSeen) {
      actionFilter.createdDate.and.push({ op: 'LTE', value: lastSeen })
    }

    const result = yield client.query({
      query: ACTIONS_QUERY,
      variables: { actionFilter },
    })

    if (result.data.Actions.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    yield put(
      fetchActivityTicketActionsSuccess({ data: result.data.Actions.items })
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error loading alerts for security center', e)
    yield put(fetchActivityTicketActionsError())
  }
}

function* fetchPolicyEvidenceLegacy(action) {
  try {
    const { ticket } = action.payload

    const policies = yield select(selectPolicies)

    const policyForTicket = policies.get(ticket.ticketKey)
    if (!policyForTicket) {
      throw new Error('Could not find policy with srn', ticket.ticketKey)
    }

    const searchOnPolicy = policyForTicket.getIn(['contains', 'items', 0])
    if (!searchOnPolicy) {
      throw new Error(
        'Could not get search from policy: ' +
          JSON.stringify(policyForTicket.toJS())
      )
    }

    // we'l try to build the search for the policy and then run it
    let search
    const variables = {}
    let isUISearch = true

    // There are two types of searches that can be attached to a policy
    // need to handle both case
    switch (searchOnPolicy.get('__typename')) {
      // if it type Search, means it's a UI search and must invoke query-builder
      case 'Search':
        {
          const savedSearches = yield select(selectSavedSearches)
          const searchForPolicy = savedSearches.get(searchOnPolicy.get('sid'))
          if (!searchForPolicy) {
            throw new Error(
              'could not find search with sid ' + searchOnPolicy.get('sid')
            )
          }
          const queryTypes = yield select(selectQueryTypes)
          let fields = searchForPolicy.getIn(['query', 'fields'])

          const rootIndex = fields
            .find(statement => !statement.get('parentId'))
            .get('id')
          fields = modifyFieldsForSRN(ticket.resourceSRN, rootIndex, fields)

          const timestamp = moment(ticket.lastSeen).unix()
          fields = fields.map(field =>
            field
              .updateIn(['arguments', 'and'], List(), andArgs =>
                shiftDateArgs(andArgs, timestamp)
              )
              .updateIn(['arguments', 'or'], List(), orArgs =>
                shiftDateArgs(orArgs, timestamp)
              )
          )

          const queryBuilder = new QueryBuilder({
            query: fields,
            types: queryTypes,
            resultViews: {},
            swimlanes: [],
          })
          queryBuilder.addDefaultResultViews()
          queryBuilder.enableFlattenMode()
          queryBuilder.skipCount = false

          const {
            gqlStatement: policyGQLSource,
          } = queryBuilder.buildPivotableSource(
            rootIndex,
            'fetchPolicyEvidenceLegacy'
          )
          search = policyGQLSource
        }
        break
      // its a saved query, it means it's an advanced search and we can execute by name
      case 'Savedquery':
        {
          search = `query fetchPolicyEvidenceLegacy($name: String) {
            ExecuteSavedQuery {
              Query(name: $name)
            }
          }`
          variables.name = searchOnPolicy.get('name')
          isUISearch = false
        }
        break
      default:
        throw new Error(
          `Could not determine how to find search of type: ${searchOnPolicy.get(
            '__typename'
          )}`
        )
    }

    const client = yield getClient()
    const result = yield client.query({
      query: gql`
        ${search}
      `,
      variables,
    })

    if (!result.data) {
      throw new Error('Invalid response, no data')
    }

    let path
    if (isUISearch) {
      path = ['data', Object.keys(result.data)[0], 'items', 0]
    } else {
      path = [
        'data',
        'ExecuteSavedQuery',
        'Query',
        // eslint-disable-next-line no-undef
        Object.keys(result.data?.ExecuteSavedQuery?.Query ?? {})[0],
        'items',
        0,
      ]
    }
    const data = _.get(result, path, null)

    if (data === null) {
      throw new Error(`no results at path ${path.join('/')}`)
    }

    yield put(fetchPolicyEvidenceLegacySuccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened fetchPolicyEvidenceLegacy', e)
    yield put(fetchPolicyEvidenceLegacyError())
  }
}

function* closeTickets(action) {
  const { payload } = action

  yield put(bulkActionStart())
  try {
    const client = getClient()
    const response = yield client.mutate({
      mutation: gql`
        mutation close_tickets($srns: [String]) {
          CloseTickets(input: { srns: $srns }) {
            successCount
            failureCount
          }
        }
      `,
      variables: {
        srns: payload,
      },
    })
    if (response.data.CloseTickets.successCount > 0) {
      yield put(closeTicketsSuccess(payload))
    }
    yield put(bulkActionComplete())
  } catch (error) {
    // eslint-disable-next-line
    console.error('Unable to close tickets: ', error)
    yield put(bulkActionComplete())
  }
}

function* assignTickets({ payload }) {
  yield put(bulkActionStart())

  try {
    const client = getClient()

    yield all(
      payload.ticketSrns.map(ticketSrn =>
        client.mutate({
          mutation: gql`
            mutation reassignToUser($ticketSrn: String!, $userSrn: String!) {
              AssignTicket(
                input: { ticketSrn: $ticketSrn, userSrn: $userSrn }
              ) {
                srn
              }
            }
          `,
          variables: {
            userSrn: payload.userSrn,
            ticketSrn: ticketSrn,
          },
        })
      )
    )

    yield put(bulkActionComplete())
  } catch (error) {
    // eslint-disable-next-line
    console.error('Unable to reassign tickets: ', error)
    yield put(bulkActionComplete())
  }
}

function* acceptRiskForTickets({ payload }) {
  yield put(bulkActionStart())

  const { ticketSrns, comment } = payload
  try {
    const client = getClient()

    //First, bulk accept the risk
    const response = yield client.mutate({
      mutation: gql`
        mutation acceptRiskOnTickets($srns: [String]) {
          AcceptRiskTickets(input: { srns: $srns }) {
            successCount
            failureCount
          }
        }
      `,
      variables: {
        srns: ticketSrns,
      },
    })

    if (response.data.AcceptRiskTickets.successCount <= 0) {
      yield put(bulkActionComplete())
      return
    }

    //Now, add the comment on all the tickets
    const currentUser = yield select(selectSonraiUserSrn)
    yield all(
      payload.ticketSrns.map(ticketSrn =>
        client.mutate({
          mutation: ADD_TICKET_COMMENT_MUTATION,
          variables: {
            ticketSrn: ticketSrn,
            body: comment,
            createdBy: currentUser,
          },
        })
      )
    )

    yield put(bulkActionComplete())
  } catch (error) {
    // eslint-disable-next-line
    console.error('Unable to accept risk on tickets: ', error)
    yield put(bulkActionComplete())
  }
}

function* snoozeTickets(action) {
  const { payload } = action

  yield put(bulkActionStart())
  try {
    const client = getClient()
    yield client.mutate({
      mutation: gql`
        mutation snoozeTickets($srns: [String], $snoozedUntil: DateTime) {
          SnoozeTickets(input: { srns: $srns }, snoozedUntil: $snoozedUntil) {
            successCount
            failureCount
          }
        }
      `,
      variables: {
        srns: payload.ticketSrns,
        snoozedUntil: payload.snoozedUntil,
      },
    })

    yield put(bulkActionComplete())
  } catch (error) {
    // eslint-disable-next-line
    console.error('Unable to close tickets: ', error)
    yield put(bulkActionComplete())
  }
}

function* bulkComment({ payload }) {
  yield put(bulkActionStart())

  const { ticketSrns, comment } = payload
  try {
    const client = getClient()
    //Now, add the comment on all the tickets
    const currentUser = yield select(selectSonraiUserSrn)
    yield all(
      ticketSrns.map(ticketSrn =>
        client.mutate({
          mutation: ADD_TICKET_COMMENT_MUTATION,
          variables: {
            ticketSrn: ticketSrn,
            body: comment,
            createdBy: currentUser,
          },
        })
      )
    )

    yield put(bulkActionComplete())
  } catch (error) {
    // eslint-disable-next-line
    console.error('Unable to bulk comment on tickets: ', error)
    yield put(bulkActionComplete())
  }
}

function* bulkTag({ payload }) {
  yield put(bulkActionStart())

  const { resourceSrns, tags } = payload
  try {
    const client = getClient()
    yield all(
      tags.map(tag => {
        return client.mutate({
          mutation: gql`
          mutation addTagsWithNoDuplicates {
            AddTag(
              value: {
                key: "${tag.key}"
                value: "${tag.value}"
                tagsEntity: {
                  add: [${resourceSrns.map(resource => `"${resource}"`)}]
                }
              }) {
              srn
              key
              value
            }
          }`,
        })
      })
    )

    yield put(bulkActionComplete())
  } catch (error) {
    // eslint-disable-next-line
    console.error('Unable to bulk add tags on ticket resources: ', error)
    yield put(bulkActionComplete())
  }
}

function* ticketDetailsSaga() {
  yield all([
    takeLatest(ACCEPT_RISK_TICKET, acceptRiskTicket),
    takeLatest(ASSIGN_TO_ME, assignToMe),
    takeLatest(CLOSE_TICKET, closeTicket),
    takeLatest(FETCH_TICKET_DETAILS, fetchTicketDetails),
    takeLatest(REOPEN_TICKET, reopenTicket),
    takeLatest(SNOOZE_TICKET, snoozeTicket),
    takeLatest(REASSIGN_TO_USER, reassignTicketToUser),
    takeLatest(FETCH_TICKET_COMMENTS, getTicketComments),
    takeLatest(ADD_COMMENT, addTicketComment),
    takeLatest(FETCH_SIMILAR_TICKETS, fetchSimilarTickets),
    takeLatest(CHANGE_SIMILAR_TICKETS_FILTERS, fetchSimilarTickets),
    takeLatest(FETCH_TICKET_HISTORY, getTicketHistory),
    takeLatest(FETCH_ACTIVITY_TICKET_ACTIONS, fetchActivityTicketActions),
    takeLatest(FETCH_POLICY_EVIDENCE_LEGACY, fetchPolicyEvidenceLegacy),
    takeLatest(FETCH_POLICY_PATH, fetchPolicyPath),
    takeLatest(CLOSE_TICKETS, closeTickets),
    takeLatest(ASSIGN_TICKETS, assignTickets),
    takeLatest(ACCEPT_RISK_TICKETS, acceptRiskForTickets),
    takeLatest(SNOOZE_TICKETS, snoozeTickets),
    takeLatest(BULK_COMMENT, bulkComment),
    takeLatest(BULK_TAG, bulkTag),
  ])
}

export default ticketDetailsSaga
