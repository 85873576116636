import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { fromJS, List, OrderedSet } from 'immutable'
import _ from 'lodash'

import PathWidget from './PathWidget'

class FlatPathWidget extends React.Component {
  styles = {
    emptyPath: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '400',
    },
  }

  processNodes = () => {
    let nodeIds = OrderedSet()
    let edges = List()

    this.props.data.forEach(path => {
      path.forEach((srn, index) => {
        nodeIds = nodeIds.add(srn)
        edges = edges.push(
          fromJS({
            item: {
              srn: srn,
            },
            parent: index === 0 ? null : path.get(index - 1),
            relation: {
              direction: 'OUT',
            },
          })
        )
      })
    })

    const uniqueEdges = _.uniqBy(edges.toJS(), value => {
      const itemId = value.item.srn
      const attachedToId = value.parent

      return `${itemId}${attachedToId}`
    })

    return {
      nodes: nodeIds
        .toList()
        .toJS()
        .map(nodeId => ({ item: { srn: nodeId } })),
      edges: uniqueEdges,
    }
  }

  render() {
    const processed = this.processNodes()
    if (
      !this.props.loading &&
      !this.props.error &&
      _.isEmpty(processed.nodes) &&
      _.isEmpty(processed.edges)
    ) {
      return <div style={this.styles.emptyPath}>No Path to show</div>
    }

    return (
      <PathWidget
        key={`${this.props.resourceId}-pathwidget`}
        error={this.props.error}
        loading={this.props.loading}
        nodes={processed.nodes}
        edges={processed.edges}
        onDoubleClick={this.props.onDoubleClick}
        emphasisNodes={this.props.emphasisNodes}
        resourceId={this.props.resourceId}
      />
    )
  }
}

FlatPathWidget.propTypes = {
  data: ImmutablePropTypes.listOf(ImmutablePropTypes.list).isRequired,
  emphasisNodes: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool,
  loading: PropTypes.bool,
  onDoubleClick: PropTypes.func,
  resourceId: PropTypes.string,
}

export default FlatPathWidget
