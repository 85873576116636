/**
 *
 * ComputeNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, isImmutable } from 'immutable'
import _ from 'lodash'
import { Responsive } from 'react-grid-layout'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { exists } from 'utils/sonraiUtils'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import { NodeViewDetailString, NodeViewDetailLabel } from 'components/NodeView'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import ListCard from 'containers/AccessActivity/ListCard'
import NodeViewMiniPopout from 'containers/NodeViewMiniPopout'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SizeMe from 'components/SizeMe'

import NetworkConnectionsWidget from './NetworkConnectionsWidget'

import messages from './messages'
import reducer from './reducer'
import sagas from './sagas'
import {
  getComputeContent,
  getInboundPublicFilters,
  getInboundPrivateFilters,
  getInboundPublicPath,
  getInboundPrivatePath,
  getOutboundPublicFilters,
  getOutboundPrivateFilters,
  getOutboundPublicPath,
  getOutboundPrivatePath,
  getIndirectInboundPublicFilters,
  getIndirectInboundPrivateFilters,
  getIndirectInboundPublicPath,
  getIndirectInboundPrivatePath,
  getComputeSecurityGroups,
} from './actions'
import {
  selectIsLoading,
  selectComputeContent,
  selectLoadingInboundPublicFilters,
  selectInboundPublicFilters,
  selectInboundPrivateFilters,
  selectLoadingInboundPrivateFilters,
  selectLoadingInboundPublicPath,
  selectInboundPublicPath,
  selectInboundPrivatePath,
  selectLoadingInboundPrivatePath,
  selectLoadingOutboundPublicFilters,
  selectOutboundPublicFilters,
  selectOutboundPrivateFilters,
  selectLoadingOutboundPrivateFilters,
  selectLoadingOutboundPublicPath,
  selectOutboundPublicPath,
  selectOutboundPrivatePath,
  selectLoadingOutboundPrivatePath,
  selectLoadingIndirectInboundPublicFilters,
  selectIndirectInboundPublicFilters,
  selectIndirectInboundPrivateFilters,
  selectLoadingIndirectInboundPrivateFilters,
  selectLoadingIndirectInboundPublicPath,
  selectIndirectInboundPublicPath,
  selectIndirectInboundPrivatePath,
  selectLoadingIndirectInboundPrivatePath,
  selectSecurityGroups,
  selectLoadingSecurityGroups,
  selectErrorSecurityGroups,
} from './selectors'

import AccessWidget from './AccessWidget'

const ResponsiveGridLayout = SizeMe(Responsive)

export class ComputeNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getComputeContent(props.nodeData.srn)
    props.getInboundPublicFilters(props.nodeData.srn)
    props.getInboundPrivateFilters(props.nodeData.srn)
    props.getInboundPublicPath(props.nodeData.srn)
    props.getInboundPrivatePath(props.nodeData.srn)
    props.getOutboundPublicFilters(props.nodeData.srn)
    props.getOutboundPrivateFilters(props.nodeData.srn)
    props.getOutboundPublicPath(props.nodeData.srn)
    props.getOutboundPrivatePath(props.nodeData.srn)
    props.getIndirectInboundPublicFilters(props.nodeData.srn)
    props.getIndirectInboundPrivateFilters(props.nodeData.srn)
    props.getIndirectInboundPublicPath(props.nodeData.srn)
    props.getIndirectInboundPrivatePath(props.nodeData.srn)
    props.getComputeSecurityGroups(props.nodeData.srn)

    this.styles = {
      accessTable: {
        display: 'grid',
        gridTemplateColumns: '30% 1fr 1fr',
        gridTemplateAreas: 'category public private',
        gridColumnGap: '1em',
      },
      pathWidget: {
        height: '300px',
      },
    }

    this.state = {
      miniNodeViewOpen: false,
      miniNodeViewNodeId: '',
    }
  }

  closeMiniModeView = () => {
    this.setState({
      miniNodeViewNodeId: '',
      miniNodeViewOpen: false,
    })
  }

  openMiniNodeView = nodeId => {
    this.setState({
      miniNodeViewNodeId: nodeId,
      miniNodeViewOpen: true,
    })
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getActivity = () =>
    this.props.computeContent
      ? this.props.computeContent
          .getIn(['isPartOfAction', 'items'], List())
          .toJS()
          .map(action => ({
            eventName: action.eventName,
            createdDate: action.createdDate,
            performedBy: _.get(action, ['performedBy', 'items', 0, 'name']),
            srn: action.srn,
          }))
      : []

  getSubnets = () =>
    this.props.computeContent
      ? this.props.computeContent
          .getIn(['isAttachedToSubnet', 'items'], List())
          .toJS()
          .map(subnet => ({ label: subnet.name, srn: subnet.srn }))
      : []

  getNetworks = () =>
    this.props.computeContent
      ? this.props.computeContent
          .getIn(['isAttachedToNetwork', 'items'], List())
          .toJS()
          .map(network => ({ label: network.name, srn: network.srn }))
      : []

  getBlockStorage = () =>
    this.props.computeContent
      ? this.props.computeContent
          .getIn(['isAttachedToBlockStorage', 'items'], List())
          .toJS()
          .map(block => ({ label: block.name, srn: block.srn }))
      : []

  getAttachedInterface = () =>
    this.props.computeContent
      ? this.props.computeContent
          .getIn(['isAttachedToInterface', 'items'], List())
          .toJS()
      : []

  getHeaderContent = () => {
    return [
      <Fragment key="content-basedOn">
        <NodeViewDetailLabel>
          {this.props.intl.formatMessage(messages.basedOnLabel)}
        </NodeViewDetailLabel>
        {exists(this.props.computeContent) &&
          !this.props.computeContent.isEmpty() && (
            <NodeViewDetailString
              value={this.props.computeContent.getIn([
                'basedOn',
                'items',
                0,
                'ImageName',
              ])}
            />
          )}
      </Fragment>,
    ]
  }

  getColumns = () => {
    return {
      lg: [
        {
          widthPercent: 33,
          items: [
            'actions',
            'subnets',
            'blocks',
            'networks',
            'interface',
            'graph',
          ],
          heights: { actions: 2, graph: 3, interface: 2 },
        },
        {
          widthPercent: 66,
          items: ['inbound', 'outbound', 'indirect'],
        },
      ],
      xs: [
        {
          widthPercent: 100,
          items: [
            'actions',
            'subnets',
            'blocks',
            'networks',
            'interface',
            'inbound',
            'outbound',
            'indirect',
            'graph',
          ],
          heights: { actions: 2, graph: 3, interface: 2 },
        },
      ],
    }
  }

  getGridContent = () => {
    const layout = [
      { x: 0, y: 0, w: 1, h: 2, i: 'securityGroups' },
      { x: 1, y: 0, w: 3, h: 2, i: 'networkConnections' },
      { x: 0, y: 2, w: 4, h: 4, i: 'inboundAccess' },
      { x: 0, y: 6, w: 4, h: 4, i: 'outboundAccess' },
      { x: 0, y: 10, w: 4, h: 4, i: 'indirectAccess' },
    ]

    return (
      <div label="Network" key="Network">
        <ResponsiveGridLayout
          className="layout"
          breakpoints={{ lg: 1390, md: 1250, sm: 916, xs: 679 }}
          cols={{ lg: 4, md: 4, sm: 4, xs: 4 }}
          margin={[15, 15]}
          rowHeight={64}
          measureBeforeMount={true}
          useCSSTransforms={false}
          isDraggable={false}
          isResizable={false}
          layouts={{
            lg: layout,
            md: layout,
            sm: layout,
            xs: layout,
          }}
        >
          <div key="securityGroups">
            <ListCard
              data={
                isImmutable(this.props.securityGroups)
                  ? this.props.securityGroups.toJS()
                  : this.props.securityGroups
              }
              error={this.props.errorSecurityGroups}
              isLoading={this.props.loadingSecurityGroups}
              handleLinkClick={target => this.openMiniNodeView(target.srn)}
              push={this.props.push}
              title={<DynamicFormattedMessage {...messages.securityGroups} />}
            />
          </div>
          <div key="inboundAccess">
            <AccessWidget
              key="inbound"
              title="Inbound Access"
              publicFilters={this.props.inboundPublicFilters}
              publicPath={this.props.inboundPublicPath}
              privateFilters={this.props.inboundPrivateFilters}
              privatePath={this.props.inboundPrivatePath}
              loadingPublicFilters={this.props.loadingInboundPublicFilters}
              loadingPrivateFilters={this.props.loadingInboundPrivateFilters}
              loadingPublicPath={this.props.loadingInboundPublicPath}
              loadingPrivatePath={this.props.loadingInboundPrivatePath}
            />
          </div>
          <div key="outboundAccess">
            <AccessWidget
              key="outbound"
              title="Outbound Access"
              publicFilters={this.props.outboundPublicFilters}
              publicPath={this.props.outboundPublicPath}
              privateFilters={this.props.outboundPrivateFilters}
              privatePath={this.props.outboundPrivatePath}
              loadingPublicFilters={this.props.loadingOutboundPublicFilters}
              loadingPrivateFilters={this.props.loadingOutboundPrivateFilters}
              loadingPublicPath={this.props.loadingOutboundPublicPath}
              loadingPrivatePath={this.props.loadingOutboundPrivatePath}
            />
          </div>
          <div key="indirectAccess">
            <AccessWidget
              key="indirect"
              title="Indirect Inbound Access"
              publicFilters={this.props.indirectInboundPublicFilters}
              publicPath={this.props.indirectInboundPublicPath}
              privateFilters={this.props.indirectInboundPrivateFilters}
              privatePath={this.props.indirectInboundPrivatePath}
              loadingPublicFilters={
                this.props.loadingIndirectInboundPublicFilters
              }
              loadingPrivateFilters={
                this.props.loadingIndirectInboundPrivateFilters
              }
              loadingPublicPath={this.props.loadingIndirectInboundPublicPath}
              loadingPrivatePath={this.props.loadingIndirectInboundPrivatePath}
            />
          </div>
          <div key="networkConnections">
            <NetworkConnectionsWidget
              isLoading={this.props.isLoading}
              handleClick={this.openMiniNodeView}
              computeContent={
                isImmutable(this.props.computeContent)
                  ? this.props.computeContent.toJS()
                  : this.props.computeContent
              }
            />
          </div>
        </ResponsiveGridLayout>
        <NodeViewMiniPopout
          handleClose={this.closeMiniModeView}
          nodeId={this.state.miniNodeViewNodeId}
          open={this.state.miniNodeViewOpen}
        />
      </div>
    )
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          columns={this.getColumns()}
          horizontal={this.props.horizontal}
          renderDetailsTab={false}
          tabs={[this.getGridContent()]}
        />
      </Fragment>
    )
  }
}

ComputeNodeViewCardLayout.propTypes = {
  computeContent: ImmutablePropTypes.map.isRequired,
  getComputeContent: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  isLoading: PropTypes.bool,
  getInboundPublicFilters: PropTypes.func.isRequired,
  getInboundPrivateFilters: PropTypes.func.isRequired,
  getInboundPublicPath: PropTypes.func.isRequired,
  getInboundPrivatePath: PropTypes.func.isRequired,
  getComputeSecurityGroups: PropTypes.func.isRequired,
  inboundPublicFilters: ImmutablePropTypes.map.isRequired,
  inboundPrivateFilters: ImmutablePropTypes.map.isRequired,
  inboundPublicPath: ImmutablePropTypes.map.isRequired,
  inboundPrivatePath: ImmutablePropTypes.map.isRequired,
  loadingInboundPublicFilters: PropTypes.bool,
  loadingInboundPrivateFilters: PropTypes.bool,
  loadingInboundPublicPath: PropTypes.bool,
  loadingInboundPrivatePath: PropTypes.bool,
  getOutboundPublicFilters: PropTypes.func.isRequired,
  getOutboundPrivateFilters: PropTypes.func.isRequired,
  getOutboundPublicPath: PropTypes.func.isRequired,
  getOutboundPrivatePath: PropTypes.func.isRequired,
  outboundPublicFilters: ImmutablePropTypes.map.isRequired,
  outboundPrivateFilters: ImmutablePropTypes.map.isRequired,
  outboundPublicPath: ImmutablePropTypes.map.isRequired,
  outboundPrivatePath: ImmutablePropTypes.map.isRequired,
  loadingOutboundPublicFilters: PropTypes.bool,
  loadingOutboundPrivateFilters: PropTypes.bool,
  loadingOutboundPublicPath: PropTypes.bool,
  loadingOutboundPrivatePath: PropTypes.bool,
  getIndirectInboundPublicFilters: PropTypes.func.isRequired,
  getIndirectInboundPrivateFilters: PropTypes.func.isRequired,
  getIndirectInboundPublicPath: PropTypes.func.isRequired,
  getIndirectInboundPrivatePath: PropTypes.func.isRequired,
  indirectInboundPublicFilters: ImmutablePropTypes.map.isRequired,
  indirectInboundPrivateFilters: ImmutablePropTypes.map.isRequired,
  indirectInboundPublicPath: ImmutablePropTypes.map.isRequired,
  indirectInboundPrivatePath: ImmutablePropTypes.map.isRequired,
  loadingIndirectInboundPublicFilters: PropTypes.bool,
  loadingIndirectInboundPrivateFilters: PropTypes.bool,
  loadingIndirectInboundPublicPath: PropTypes.bool,
  loadingIndirectInboundPrivatePath: PropTypes.bool,
  securityGroups: ImmutablePropTypes.map.isRequired,
  loadingSecurityGroups: PropTypes.bool,
  errorSecurityGroups: PropTypes.bool,

  setUserProfileTables: PropTypes.func,
  userProfile: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile,
  isLoading: selectIsLoading,
  computeContent: selectComputeContent,
  loadingInboundPublicFilters: selectLoadingInboundPublicFilters,
  inboundPublicFilters: selectInboundPublicFilters,
  loadingInboundPrivateFilters: selectLoadingInboundPrivateFilters,
  inboundPrivateFilters: selectInboundPrivateFilters,
  inboundPublicPath: selectInboundPublicPath,
  loadingInboundPublicPath: selectLoadingInboundPublicPath,
  inboundPrivatePath: selectInboundPrivatePath,
  loadingInboundPrivatePath: selectLoadingInboundPrivatePath,
  loadingOutboundPublicFilters: selectLoadingOutboundPublicFilters,
  outboundPublicFilters: selectOutboundPublicFilters,
  loadingOutboundPrivateFilters: selectLoadingOutboundPrivateFilters,
  outboundPrivateFilters: selectOutboundPrivateFilters,
  outboundPublicPath: selectOutboundPublicPath,
  loadingOutboundPublicPath: selectLoadingOutboundPublicPath,
  outboundPrivatePath: selectOutboundPrivatePath,
  loadingOutboundPrivatePath: selectLoadingOutboundPrivatePath,
  loadingIndirectInboundPublicFilters: selectLoadingIndirectInboundPublicFilters,
  indirectInboundPublicFilters: selectIndirectInboundPublicFilters,
  loadingIndirectInboundPrivateFilters: selectLoadingIndirectInboundPrivateFilters,
  indirectInboundPrivateFilters: selectIndirectInboundPrivateFilters,
  indirectInboundPublicPath: selectIndirectInboundPublicPath,
  loadingIndirectInboundPublicPath: selectLoadingIndirectInboundPublicPath,
  indirectInboundPrivatePath: selectIndirectInboundPrivatePath,
  loadingIndirectInboundPrivatePath: selectLoadingIndirectInboundPrivatePath,
  securityGroups: selectSecurityGroups,
  loadingSecurityGroups: selectLoadingSecurityGroups,
  errorSecurityGroups: selectErrorSecurityGroups,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserProfileTables,
      push,
      getComputeContent,
      getInboundPublicFilters,
      getInboundPrivateFilters,
      getInboundPublicPath,
      getInboundPrivatePath,
      getOutboundPublicFilters,
      getOutboundPrivateFilters,
      getOutboundPublicPath,
      getOutboundPrivatePath,
      getIndirectInboundPublicFilters,
      getIndirectInboundPrivateFilters,
      getIndirectInboundPublicPath,
      getIndirectInboundPrivatePath,
      getComputeSecurityGroups,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({ key: 'computeNodeViewCardLayout', reducer })

const withSaga = injectSaga({
  key: 'computeNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(ComputeNodeViewCardLayout)
