import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { assignToMe } from 'containers/TicketDetailsData/actions'

import { selectSonraiUserSrn } from 'containers/UserProfileData/selectors'
import { selectAssignToMe } from 'containers/TicketDetailsData/selectors'

import ToolTip from 'react-tooltip'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import TextLink from 'components/TextLink'

import messages from '../messages'

export function AssignToMe(props) {
  const color = props.status.get('loading') ? 'disabled' : 'primary'

  const handleClick = () => {
    props.assignToMe({
      userSrn: props.currentUserSrn,
      ticketSrn: props.ticket.get('srn'),
    })
  }

  return (
    <Fragment>
      <TextLink
        color={color}
        onClick={handleClick}
        disabled={props.ticket.get('assignedTo') === props.currentUserSrn}
      >
        <DynamicFormattedMessage {...messages.assignToMe} />{' '}
        {props.status.get('loading') && <Icon fa name="sync" spin size={12} />}
      </TextLink>
      {props.status.get('error') && (
        <Fragment>
          <span
            data-tip
            data-for="assign-to-me-error"
            style={{ cursor: 'pointer ' }}
          >
            <Icon fa name="exclamation-triangle-solid" color="red" size={12} />
          </span>
          <ToolTip id="assign-to-me-error" place="bottom" effect="solid">
            <DynamicFormattedMessage {...messages.assignToMeError} />
          </ToolTip>
        </Fragment>
      )}
    </Fragment>
  )
}

AssignToMe.propTypes = {
  // passed props
  ticket: ImmutablePropTypes.map,
  isAssignToMeDisabled: PropTypes.func.isRequired,

  // ~ bound action creators ~
  assignToMe: PropTypes.func.isRequired,

  // ~ props from redux ~
  currentUserSrn: PropTypes.string.isRequired,
  status: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }),
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      assignToMe,
    },
    dispatch
  )

const mapStatetoProps = createStructuredSelector({
  status: selectAssignToMe,
  currentUserSrn: selectSonraiUserSrn,
})

const withConnect = connect(mapStatetoProps, mapDispatchToProps)

export default compose(withConnect)(AssignToMe)
