/**
 *
 * SonraiData
 *
 */

import React from 'react'
import { compose } from 'redux'

import { getDisplayName } from 'utils/hocHelpers'
import sonraiDatareducer from './reducer'
import sagas from './sagas'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'

const sonraiData = WrappedComponent => {
  return class extends React.Component {
    displayName = `SonraiData(${getDisplayName(WrappedComponent)})`
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const withReducer = injectReducer({
  key: 'sonraiData',
  reducer: sonraiDatareducer,
})
const withSaga = injectSaga({ key: 'sonraiData', saga: sagas })

export default compose(withReducer, withSaga, sonraiData)
