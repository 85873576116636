export const GET_ACCESSED_FROM_ACTIONS = `  
query getActiveFromActions($criticalResourceSRN: String, $countryValue: String, $fromDate: DateTime, $toDate: DateTime) {
    Actions(
      where: {
        performedOnValue: { value: $criticalResourceSRN }
        performedAtCountryValues: { value: $countryValue }
        createdDate: {
          and: [
            { op: GT, value: $fromDate}
            { op: LTE, value: $toDate}
          ]
        }
      }
    ) {
      count
      items {
        srn
        eventName
        service
        createdDate
        performedByValue
      }
    }
  }`

export const GET_ACTIVITY_WIDGET = `
  query getActivityFromRegions($srn: String, $fromDate: DateTime, $toDate: DateTime, $keyName: String) {
      AlertLogs(
        where: { 
          and: { 
            criticalResourceSRN: { value: $srn }
            timestamp: { op: BETWEEN values: [$fromDate, $toDate] }
            keyName: { value: $keyName }
          }
        }
      )
    {
      items {
          count
          regionSet
          timestamp
      }
  }
}`

export const GET_ACCESSED_USING_WIDGET_CRITICAL_RESOURCE_SRN = `
  query getAccessedUsing($srn: String, $fromDate: DateTime, $toDate: DateTime, $keyName: String) {
      AlertLogs(
        where: { 
          and: { 
            criticalResourceSRN: { value: $srn }
            timestamp: { op: BETWEEN values: [$fromDate, $toDate] }
            keyName: { value: $keyName }
          }
        }
      ) {
        items { 
          criticalResourceSRN
          userAgentSet
          timestamp
        }
        }
      }`
