import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectImageNodeViewCardLayoutDomain = state =>
  state.get('imageNodeViewCardLayout', Map())

export const selectImageContent = createSelector(
  selectImageNodeViewCardLayoutDomain,
  state => state.get('imageContent') || Map()
)

export const selectIsLoading = createSelector(
  selectImageNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
