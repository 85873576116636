/**
 *
 * NavBarLink
 *
 */

import React from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class NavBarLink extends React.PureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      icon: {
        fontSize: '24px',
        color: '#FFFFFF',
        marginTop: '14px',
        marginLeft: '0px',
        ':hover': {
          color: props.theme.emphasis,
          textShadow: '0 2px 4px rgba(0,0,0,0.50)',
        },
        height: '28px',
        width: '28px',
      },
      tooltip: {
        position: 'relative',
        marginTop: '0.8em',
      },
      active: {
        color: props.theme.emphasis,
        textShadow: '0 2px 4px rgba(0,0,0,0.50)',
      },
    }
  }

  render() {
    const {
      active,
      name,
      to,
      fullname,
      fixed,
      onClick,
      png,
      type,
      style,
      fa,
    } = this.props

    const baseStyle = {
      ...this.styles.icon,
      ...style,
    }

    const activeStyle = {
      ...baseStyle,
      ...this.styles.active,
    }

    return (
      <li id={name} data-tip data-for={name}>
        <Link to={to} onClick={onClick}>
          <Icon
            style={active ? activeStyle : baseStyle}
            name={name}
            fixed={fixed}
            className="scale-in-center"
            fa={fa}
            png={png}
            color={active ? this.props.theme.emphasis : undefined}
            type={active ? `${type}-active` : `${type}`}
          />
        </Link>
        <ReactTooltip
          id={name}
          place="right"
          effect="solid"
          offset={{ left: 10, top: -5 }}
        >
          <span>{fullname}</span>
        </ReactTooltip>
      </li>
    )
  }
}

NavBarLink = Radium(NavBarLink) // eslint-disable-line

NavBarLink.propTypes = {
  active: PropTypes.bool,
  to: PropTypes.string,
  theme: themeShape,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  fixed: PropTypes.bool,
  fullname: PropTypes.node.isRequired,
  png: PropTypes.bool,
  type: PropTypes.string,
  fa: PropTypes.bool,
  style: PropTypes.object,
}

export default themeable(NavBarLink)
