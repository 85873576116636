import { handleActions } from 'redux-actions'
import { fromJS, isImmutable } from 'immutable'
import _ from 'lodash'

export const REDUX_KEY = 'nodeViewHeader'

import {
  ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS,
  ADD_KEYVAULT_CREDS,
  ADD_KEYVAULT_CREDS_SUCCESS,
  ADD_RESOURCE_TO_SWIMLANE,
  ADD_RESOURCE_TO_SWIMLANE_SUCCESS,
  ADD_TAG,
  ADD_TAG_SUCCESS,
  ERR_CRM_DATA,
  ERR_KEYVAULT_DATA,
  ERR_NODE_DATA,
  ERR_RESOURCE_GROUP,
  ERR_SWIMLANES,
  GET_KEYVAULT_DATA,
  GET_NODE_DATA,
  GET_RESOURCE_GROUP,
  REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS,
  REMOVE_KEYVAULT_CREDS,
  REMOVE_KEYVAULT_CREDS_SUCCESS,
  REMOVE_TAG,
  REMOVE_TAG_SUCCESS,
  SET_CRM_DATA,
  SET_KEYVAULT_DATA,
  SET_NODE_DATA,
  SET_RESOURCE_GROUP,
  SET_SWIMLANES,
  TOGGLE_RESOURCE_MONITORING_SUCCESS,
  UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS,
  UPDATE_FRIENDLY_NAME,
  UPDATE_FRIENDLY_NAME_SUCCESS,
  UPDATE_KEYVAULT_CREDS,
  UPDATE_KEYVAULT_CREDS_SUCCESS,
} from './constants'

export const defaultInitialNodeState = {
  loading: true,
  error: false,
  data: {},
  crmData: {
    error: false,
    monitored: null,
    importance: null,
    changeDetectionProperties: [],
    changeDetectionOptions: [],
  },
  keyVault: {
    loading: false,
    adding: false,
    deleting: {},
    updating: {},
    error: null,
    data: [],
  },
  resourceGroup: {
    error: false,
    loading: false,
    group: {},
    storageAccount: {},
  },
  swimlanes: {
    addingResourceToSL: false,
    loading: false,
    error: false,
    data: [],
  },
  tags: {
    loading: false,
    data: [],
    saving: false,
  },
  updateFriendlyName: {
    loading: false,
  },
}

const initialState = fromJS({
  nodes: {},
})

const nodeViewHeader = handleActions(
  {
    // Editting Cahnge detection properties
    [ADD_CHANGE_DETECTION_PROPERTIES_SUCCESS]: (state, { payload }) => {
      const path = [
        'nodes',
        payload.srn,
        'crmData',
        'changeDetectionProperties',
      ]
      const currentState = state.getIn(path)
      const newState = payload.properties.reduce(
        (nextState, property) => mergeCDProperty(nextState, property),
        currentState
      )
      return state.setIn(path, newState)
    },
    [REMOVE_CHANGE_DETECTION_PROPERTIES_SUCCESS]: (state, { payload }) => {
      const path = [
        'nodes',
        payload.srn,
        'crmData',
        'changeDetectionProperties',
      ]

      const propertiesToRemove = (isImmutable(payload.properties)
        ? payload.properties.toJS()
        : payload.properties
      ).map(prop => ({
        keyName: prop.keyName,
        alertLevel: prop.alertLevel,
        actionClassification: prop.actionClassification,
      }))

      const currentState = state.getIn(path)
      const newState = currentState.filter(existingProp => {
        const findMeIndex = propertiesToRemove.findIndex(
          prop =>
            prop.keyName === existingProp.get('keyName') &&
            prop.alertLevel === existingProp.get('alertLevel')
        )
        if (findMeIndex === -1) {
          return true
        } else if (
          !propertiesToRemove[findMeIndex].actionClassification ||
          (propertiesToRemove[findMeIndex].actionClassification.length ===
            existingProp.get('actionClassification').size &&
            existingProp
              .get('actionClassification')
              .every(val =>
                propertiesToRemove[findMeIndex].actionClassification.includes(
                  val
                )
              ))
        ) {
          return false
        } else {
          return true
        }
      })
      return state.setIn(path, newState)
    },
    [UPDATE_CHANGE_DETECTION_PROPERTY_SUCCESS]: (state, { payload }) => {
      const path = [
        'nodes',
        payload.srn,
        'crmData',
        'changeDetectionProperties',
      ]
      const currentState = state.getIn(path)
      return state.setIn(path, mergeCDProperty(currentState, payload.property))
    },

    // Editting KeyVault creds
    [ADD_KEYVAULT_CREDS]: (state, { payload }) =>
      state.setIn(['nodes', payload.srn, 'keyVault', 'adding'], true),
    [ADD_KEYVAULT_CREDS_SUCCESS]: (state, { payload }) => {
      const dataPath = ['nodes', payload.srn, 'keyVault', 'data']
      const currentData = state.getIn(dataPath)
      return state
        .setIn(['nodes', payload.srn, 'keyVault', 'adding'], false)
        .setIn(dataPath, currentData.push(fromJS(payload.newCreds)))
    },

    [REMOVE_KEYVAULT_CREDS]: (state, { payload }) =>
      state.setIn(
        ['nodes', payload.srn, 'keyVault', 'deleting', payload.id],
        true
      ),
    [REMOVE_KEYVAULT_CREDS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(
          ['nodes', payload.srn, 'keyVault', 'deleting', payload.id],
          false
        )
        .updateIn(['nodes', payload.srn, 'keyVault', 'data'], data =>
          data.filter(cred => cred.get('id') !== payload.id)
        ),

    [UPDATE_KEYVAULT_CREDS]: (state, { payload }) =>
      state.setIn(
        ['nodes', payload.srn, 'keyVault', 'updating', payload.id],
        true
      ),
    [UPDATE_KEYVAULT_CREDS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(
          ['nodes', payload.srn, 'keyVault', 'updating', payload.id],
          false
        )
        .updateIn(['nodes', payload.srn, 'keyVault', 'data'], data =>
          data.set(
            data.findIndex(cred => cred.get('id') === payload.id),
            fromJS(payload.updateCreds)
          )
        ),

    // Fetching KeyVault Data
    [GET_KEYVAULT_DATA]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'keyVault', 'loading'], true)
        .setIn(['nodes', payload.srn, 'keyVault', 'error'], false),
    [ERR_KEYVAULT_DATA]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'keyVault', 'loading'], false)
        .setIn(['nodes', payload.srn, 'keyVault', 'error'], true),
    [SET_KEYVAULT_DATA]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'keyVault', 'loading'], false)
        .setIn(['nodes', payload.srn, 'keyVault', 'error'], false)
        .setIn(
          ['nodes', payload.srn, 'keyVault', 'data'],
          fromJS(payload.data)
        ),

    // Updating swimlanes
    [ADD_RESOURCE_TO_SWIMLANE]: (state, { payload }) =>
      state.setIn(
        ['nodes', payload.srn, 'swimlanes', 'addingResourceToSL'],
        true
      ),
    [ADD_RESOURCE_TO_SWIMLANE_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'swimlanes', 'addingResourceToSL'], false)
        .updateIn(['nodes', payload.srn, 'data', 'swimlaneSRNs'], data => {
          return data.push(payload.swimlane.srn)
        }),
    // Updating tags
    [ADD_TAG]: (state, { payload }) =>
      state.setIn(['nodes', payload.srn, 'tags', 'loading'], true),
    [ADD_TAG_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'tags', 'loading'], false)
        .updateIn(['nodes', payload.srn, 'tags', 'data'], tags =>
          tags.push(payload.newTag)
        ),
    [REMOVE_TAG]: (state, { payload }) =>
      state.setIn(['nodes', payload.srn, 'tags', 'loading'], true),
    [REMOVE_TAG_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'tags', 'loading'], false)
        .updateIn(['nodes', payload.srn, 'tags', 'data'], tags => {
          const index = tags.findIndex(tag => payload.tagSrn === tag.get('srn'))
          return index >= 0 ? tags.remove(index) : tags // nothing to remove
        }),

    // Fetching Node View Data
    [GET_NODE_DATA]: (state, { payload }) =>
      state.setIn(['nodes', payload.srn], fromJS(defaultInitialNodeState)),
    [SET_NODE_DATA]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'loading'], false)
        .setIn(['nodes', payload.srn, 'error'], false)
        .setIn(['nodes', payload.srn, 'data'], fromJS(payload.data))
        .setIn(['nodes', payload.srn, 'tags', 'loading'], false)
        .setIn(
          ['nodes', payload.srn, 'tags', 'data'],
          fromJS(_.get(payload.data, ['hasTag', 'items']))
        ),
    [ERR_NODE_DATA]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'loading'], true)
        .setIn(['nodes', payload.srn, 'error'], true),

    // Fetching CRN Data
    [SET_CRM_DATA]: (state, { payload }) =>
      state.setIn(
        ['nodes', payload.srn, 'crmData'],
        fromJS({ ...payload, error: false })
      ),
    [ERR_CRM_DATA]: (state, { payload }) =>
      state.setIn(['nodes', payload.srn, 'crmData', 'error'], true),

    // Fetching Swimlane Data
    [ERR_SWIMLANES]: (state, { payload }) =>
      state.setIn(['nodes', payload.srn, 'swimlanes', 'error'], true),
    [SET_SWIMLANES]: (state, { payload }) =>
      state
        .setIn(
          ['nodes', payload.srn, 'swimlanes', 'data'],
          fromJS(payload.swimlanes)
        )
        .setIn(['nodes', payload.srn, 'swimlanes', 'error'], false),

    // Fetching Resource Group
    [GET_RESOURCE_GROUP]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'resourceGroup', 'loading'], true)
        .setIn(['nodes', payload.srn, 'resourceGroup', 'error'], false),
    [ERR_RESOURCE_GROUP]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'resourceGroup', 'loading'], false)
        .setIn(['nodes', payload.srn, 'resourceGroup', 'error'], true),
    [SET_RESOURCE_GROUP]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'resourceGroup', 'loading'], false)
        .setIn(['nodes', payload.srn, 'resourceGroup', 'error'], false)
        .setIn(['nodes', payload.srn, 'resourceGroup', 'group'], payload.group)
        .setIn(
          ['nodes', payload.srn, 'resourceGroup', 'storageAccount'],
          payload.storageAccount
        ),

    // Toggling resource Monitoring
    [TOGGLE_RESOURCE_MONITORING_SUCCESS]: (state, { payload }) =>
      state.setIn(
        ['nodes', payload.srn, 'crmData', 'monitored'],
        payload.isMonitored
      ),

    // Updating Friendly Name
    [UPDATE_FRIENDLY_NAME]: (state, { payload }) =>
      state.setIn(
        ['nodes', payload.srn, 'updateFriendlyName', 'loading'],
        true
      ),
    [UPDATE_FRIENDLY_NAME_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['nodes', payload.srn, 'updateFriendlyName', 'loading'], false)
        .setIn(['nodes', payload.srn, 'data', 'friendlyName'], payload.value),
  },
  initialState
)

/**
 * in the list of properties, find it, and then merge the new property with the
 * existing one. If cannot find the existing property, just add it to the list
 */
const mergeCDProperty = (currentProperties, newProperty) => {
  if (!isImmutable(newProperty)) {
    return mergeCDProperty(currentProperties, fromJS(newProperty))
  }

  // search for the index of the current property, if it exists
  const findKey = newProperty.get('keyName')
  const currentIndex = currentProperties.findIndex(
    property => property.get('keyName') == findKey
  )

  if (currentIndex < 0) {
    // if it can't be foudn, just add the new property
    return currentProperties.push(newProperty)
  } else {
    // otherwise assign new values to the old values
    const propToUpdate = currentProperties.get(currentIndex)
    let updatedProp = propToUpdate.mergeDeep(newProperty)
    const maxImortance = Math.max(
      newProperty.get('importance'),
      propToUpdate.get('importance')
    )
    if (_.isNumber(maxImortance) && maxImortance > 0) {
      updatedProp = updatedProp.set('importance', maxImortance)
    }
    return currentProperties.set(currentIndex, updatedProp)
  }
}

export default nodeViewHeader
