/**
 *
 * InlineTextDropdown
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import BorderlessButton from 'components/BorderlessButton'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

class InlineTextDropdown extends React.Component {
  state = {
    expanded: false,
  }

  styles = {
    container: {
      display: 'inline',
    },
    label: {
      fontWeight: '500',
      color: '#555',
      position: 'static',
      cursor: 'pointer',
    },
    option: {
      width: '100%',
      textAlign: 'left',
      padding: '0.5em 1.5em',
      borderBottom: '1px solid #eee',
    },
  }

  toggleDropdown = () => {
    this.setState(oldState => {
      return {
        expanded: !oldState.expanded,
      }
    })
  }

  clickOption = option => {
    this.props.onClick(option)
    this.toggleDropdown()
  }

  render() {
    const selectedOption = this.props.options.find(
      option => option.value === this.props.selectedValue
    )

    const defaultOption = this.props.options.find(option => option.default)

    return (
      <Dropdown
        style={this.styles.container}
        isOpen={this.state.expanded}
        toggle={this.toggleDropdown}
      >
        <DropdownToggle
          className="inline-dropdown-control"
          tag="span"
          data-toggle="dropdown"
          caret
          style={this.styles.label}
        >
          {selectedOption
            ? selectedOption.label
            : defaultOption
            ? defaultOption.label
            : ''}{' '}
        </DropdownToggle>

        <DropdownMenu>
          {this.props.options.map(option => (
            <BorderlessButton
              key={`${option.value}_${option.label}`}
              style={this.styles.option}
              onClick={this.clickOption.bind(null, option)}
            >
              {option.label}
            </BorderlessButton>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

InlineTextDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
      default: PropTypes.bool,
    })
  ),
  onClick: PropTypes.func.isRequired,
  selectedValue: PropTypes.any,
}

export default InlineTextDropdown
