import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import _ from 'lodash'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import moment from 'moment'
import { List } from 'immutable'
import { Responsive } from 'react-grid-layout'
import SizeMe from 'components/SizeMe'
import EffectivePermissionWidget from 'components/EffectivePermissionWidget'
const ResponsiveGridLayout = SizeMe(Responsive)
import TextLink from 'components/TextLink'
import IHelp from 'containers/IHelp'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import CanAccessWidget from 'components/CanAccessWidget'
import DirectPolicyWidget from 'components/DirectPolicyWidget'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import injectMultipleSagas from 'utils/injectMultipleSagas'
import injectMultipleReducers from 'utils/injectMultipleReducers'
import { srnIsCRMActionable } from 'utils/widgetUtils'
import { CLOUD_TYPES } from 'appConstants'
import ListCard from 'containers/AccessActivity/ListCard'
import NodeViewMiniPopout from 'containers/NodeViewMiniPopout'

import DataTable from 'components/DataTable'
import BorderedCard from 'components/BorderedCard'
import { TopTitle } from 'components/Card'
import messages from './messages'
import legacyReducer from 'containers/AccessActivity/reducers'
import reducer from './reducers'
import legacySaga from 'containers/AccessActivity/sagas'
import saga from './sagas'
import {
  getNodeViewPushParams,
  exists,
  getNameFromSrn,
  getAccountFromSrn,
  getCloudFromSrn,
} from 'utils/sonraiUtils'

import {
  setCriticalResourceMonitorDate,
  getCanAccessData,
  getCanAccessPath,
  setCanAccessPath,
  getEPPaths,
} from 'containers/AccessActivity/actions'
import { toggleResourceMonitoring } from 'containers/NodeSolutionCenter/actions'

import {
  getCanAssumeRoles,
  getCanBeAssumedBy,
  getGroupMemberships,
  getDirectAttachedPolicies,
  getSCPs,
  getInstanceProfiles,
  getPermissionInfo,
} from './actions'

import {
  selectCriticalResourceMonitorDate,
  selectCanAccessData,
  selectCanAccessPaths,
  selectCanAccessPathsLoading,
  selectCanAccessPathsError,
} from 'containers/AccessActivity/selectors'

import {
  selectCanAssumeRoles,
  selectCanBeAssumedBy,
  selectGroupMemberships,
  selectDirectAttachedPolicies,
  selectSCPs,
  selectInstanceProfiles,
  selectPermissions,
} from './selectors'

import { selectIsMonitored } from 'containers/NodeSolutionCenter/selectors'

import { selectChangeDetectionProperties } from 'containers/ChangeDetectionManager/selectors'

import { initialState } from 'containers/AccessActivity/reducers'
import ShowPathModal from 'containers/AccessActivity/ShowPathModal'

import 'react-dates/lib/css/_datepicker.css'
import 'containers/AccessActivity/styles.css' // TODO fix this

const style = {
  crmOverlay: {
    position: 'absolute',
    background: 'rgba(200,200,200,0.48)',
    opacity: '0.7',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.25rem',
    height: '100%',
  },
  marker: {
    width: '100%',
    height: '100%',
  },
}

const DATALAYOUT_TYPES = [
  'DataContainer',
  'DataObject',
  'DataStore',
  'Secret',
  'SecretStore',
  'Secret',
  'EncryptionKey',
]

class Access extends React.Component {
  constructor(props) {
    super(props)

    this.getAllData(props)

    this.state = {
      showPathId: null,
      showPathClassification: null,
      miniNodeViewNodeId: '',
      miniNodeViewOpen: false,
    }
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.date !== this.props.date ||
      oldProps.isMonitored !== this.props.isMonitored
    ) {
      this.getAllData(this.props)
    }

    if (
      !oldProps.permissions.getIn(['data', this.state.showPathId]) &&
      this.props.permissions.getIn(['data', this.state.showPathId])
    ) {
      this.setState(currState => ({
        showPathToItem: this.props.permissions
          .getIn(['data', currState.showPathId])
          .toJS(),
      }))
    }
  }

  componentWillUnmount() {
    this.props.setCriticalResourceMonitorDate(initialState.get('date'))
  }

  getAllData = () => {
    const type = getTypeFromSrn(this.props.nodeId)
    const date = exists(this.props.date)
      ? this.props.date
      : exists(this.props.alertTime)
      ? {
          fromDate: moment
            .utc(parseInt(this.props.alertTime))
            .subtract(1, 'days'),
          toDate: moment.utc(parseInt(this.props.alertTime)),
        }
      : {
          fromDate: moment().subtract(1, 'days'),
          toDate: moment(),
        }

    const fromDate = date.fromDate.format('X')
    const toDate = date.toDate.format('X')

    const srn = this.props.nodeId

    if ('User' === type || 'Compute' === type || 'Role' === type) {
      this.props.getCanAssumeRoles({ srn })
    }
    if ('Role' === type) {
      this.props.getCanBeAssumedBy({ srn })
    }

    if ('Role' === type || 'User' === type) {
      this.props.getGroupMemberships({ srn })
      this.props.getDirectAttachedPolicies({ srn })
    }

    if ('Role' === type || 'User' === type || DATALAYOUT_TYPES.includes(type)) {
      this.props.getDirectAttachedPolicies({ srn })
    }

    if ('Compute' === type) {
      this.props.getInstanceProfiles({ resource: this.props.nodeData })
    }
    this.props.getSCPs({ srn })

    this.props.getCanAccessData({
      srn,
      cloudType: this.props.nodeData.cloudType,
      resourceId: this.props.nodeData.resourceId,
      fromDate,
      toDate,
      keyName: srnIsCRMActionable(srn) ? 'actionableBy' : 'hasPermissionTo',
    })
  }

  navigateToNodeView = srn => {
    const nodeType = getTypeFromSrn(srn)
    this.props.push(getNodeViewPushParams(srn, nodeType))
  }

  onShowPath = (resourceId, classification, conditions) => {
    let fromDate
    let toDate
    if (exists(this.props.date)) {
      fromDate = this.props.date.fromDate.format('YYYY-MM-DD')
      toDate = this.props.date.toDate.format('YYYY-MM-DD')
    } else if (exists(this.props.alertTime)) {
      fromDate = moment
        .utc(parseInt(this.props.alertTime))
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
      toDate = moment.utc(parseInt(this.props.alertTime)).format('YYYY-MM-DD')
    } else {
      fromDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
      toDate = moment().format('YYYY-MM-DD')
    }

    if (
      !this.props.canAccessPaths.getIn([
        resourceId,
        classification,
        fromDate,
        conditions,
      ])
    ) {
      const actionableByMode = srnIsCRMActionable(this.props.nodeId)

      this.props.getCanAccessPath({
        resourceId: resourceId,
        actionClassification: classification,
        srn: this.props.nodeId,
        conditions: conditions,
        fromDate: fromDate,
        toDate: toDate,
        keyName: actionableByMode ? 'actionableBy' : 'hasPermissionTo',
      })
    }

    this.setState({
      showPathId: resourceId,
      showPathClassification: classification,
      showPathConditions: conditions,
    })
  }

  onShowPathFromList = ({ item, showPathClassification }) => {
    const { fromDate } = this.getDate()
    const { srn, path, conditions } = item

    this.props.setCanAccessPath({
      srn,
      actionClassification: showPathClassification,
      paths: [JSON.parse(path)],
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
      conditions: conditions,
    })

    this.setState({
      showPathId: srn,
      showPathClassification: showPathClassification,
      showPathConditions: conditions,
      showPathToItem: { ...item },
    })
  }

  onShowPathForEP = effectivePermission => {
    this.props.getPermissionInfo(effectivePermission.permissionSrn)
    const { fromDate } = this.getDate()

    const path = effectivePermission.identityChain.split(',')
    path.push(effectivePermission.policySrn)
    this.props.setCanAccessPath({
      srn: effectivePermission.permissionSrn,
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
      actionClassification: '',
      conditions: effectivePermission.policyEntryConditions,
      paths: [path],
    })

    this.setState({
      showPathId: effectivePermission.permissionSrn,
      showPathClassification: '',
      showPathConditions: effectivePermission.policyEntryConditions,
      showPathToItem: this.props.permissions.getIn([
        'data',
        effectivePermission.permissionSrn,
      ])
        ? this.props.permissions
            .getIn(['data', effectivePermission.permissionSrn])
            .toJS()
        : null,
    })
  }

  closeShowPath = () => {
    this.setState({
      showPathId: null,
      showPathClassification: null,
      showPathConditions: null,
      showPathToItem: null,
    })
  }

  onDoubleClickPathNode = ({ nodes }) => {
    if (!_.isEmpty(nodes)) {
      const srn = _.first(nodes)
      if (srn && srn.startsWith('srn:')) {
        const { origin } = window.location
        const { pathname, search } = getNodeViewPushParams(
          srn,
          getTypeFromSrn(srn)
        )
        if (pathname && search) {
          const url = `${origin}${pathname}?${search}`
          window.open(url, '_blank')
        }
      }
    }
  }

  openMiniNodeView = nodeId => {
    this.setState({
      miniNodeViewOpen: true,
      miniNodeViewNodeId: nodeId,
    })
  }

  closeMiniNodeView = () => {
    this.setState({
      miniNodeViewOpen: false,
      miniNodeViewNodeId: '',
    })
  }

  getModalTitle = () => {
    return 'Actions'
  }

  handleDatesChange = params => {
    const { startDate, endDate, value } = params
    if (startDate && endDate) {
      this.props.setCriticalResourceMonitorDate({
        fromDate: startDate,
        toDate: endDate,
      })
    }
    if (!startDate && !endDate && value) {
      this.props.setCriticalResourceMonitorDate({
        fromDate: moment().subtract(value, 'days'),
        toDate: moment(),
      })
    }
  }

  getDate = () => {
    const date = exists(this.props.date)
      ? this.props.date
      : exists(this.props.alertTime)
      ? {
          fromDate: moment
            .utc(parseInt(this.props.alertTime))
            .subtract(1, 'days'),
          toDate: moment.utc(parseInt(this.props.alertTime)),
        }
      : {
          fromDate: moment().subtract(1, 'days'),
          toDate: moment(),
        }

    return date
  }

  getCRMOverlay = () => {
    return (
      <div style={style.crmOverlay}>
        <div
          style={{
            textAlign: 'center',
            fontSize: '0.9rem',
            marginBottom: '0.5rem',
          }}
        >
          Results from this widget are only available if the resource is
          monitored.
        </div>
        <TextLink
          color="primary"
          onClick={() =>
            this.props.toggleResourceMonitoring({
              srn: this.props.nodeId,
              isMonitored: this.props.isMonitored,
            })
          }
        >
          Enable Monitoring
        </TextLink>
      </div>
    )
  }

  render() {
    const type = getTypeFromSrn(this.props.nodeId)

    const date = this.getDate()
    const { fromDate } = date

    const actionableByMode =
      srnIsCRMActionable(this.props.nodeId) ||
      (this.state.showPathClassification === 'Authentication' &&
        getTypeFromSrn(this.props.nodeId) === 'Role')

    const changeDetectionProperties = this.props.changeDetectionProperties
    const rowHeight = 64

    const layout2Col = []
    const layout1Col = []

    if ('User' === type) {
      ;[
        { x: 0, y: 0, w: 6, h: 5, i: 'directPolicies' },
        { x: 0, y: 5, w: 4, h: 5, i: 'canAssumeRole' },
        { x: 4, y: 5, w: 1, h: 5, i: 'groupMembership' },
        { x: 5, y: 5, w: 1, h: 5, i: 'scp' },
        { x: 0, y: 0, w: 0, h: 0, i: 'identitiesWithAccess' },
        { x: 0, y: 0, w: 0, h: 0, i: 'canBeAssumedBy' },
      ].forEach(it => layout2Col.push(it))
      ;[
        { x: 0, y: 0, w: 6, h: 4, i: 'directPolicies' },
        { x: 0, y: 4, w: 3, h: 3, i: 'groupMembership' },
        { x: 3, y: 4, w: 3, h: 3, i: 'scp' },
        { x: 0, y: 10, w: 6, h: 4, i: 'canAssumeRole' },
        { x: 0, y: 0, w: 0, h: 0, i: 'identitiesWithAccess' },
        { x: 0, y: 0, w: 0, h: 0, i: 'canBeAssumedBy' },
      ].forEach(it => layout1Col.push(it))
    }

    if ('Role' === type) {
      ;[
        { x: 0, y: 0, w: 6, h: 5, i: 'directPolicies' },
        { x: 0, y: 5, w: 4, h: 5, i: 'canAssumeRole' },
        { x: 4, y: 5, w: 1, h: 5, i: 'groupMembership' },
        { x: 5, y: 5, w: 1, h: 5, i: 'scp' },
        { x: 0, y: 10, w: 6, h: 5, i: 'canBeAssumedBy' },
        { x: 0, y: 0, w: 0, h: 0, i: 'identitiesWithAccess' },
      ].forEach(it => layout2Col.push(it))
      ;[
        { x: 0, y: 0, w: 6, h: 4, i: 'directPolicies' },
        { x: 0, y: 4, w: 3, h: 3, i: 'groupMembership' },
        { x: 3, y: 4, w: 3, h: 3, i: 'scp' },
        { x: 0, y: 10, w: 6, h: 4, i: 'canAssumeRole' },
        { x: 0, y: 14, w: 6, h: 4, i: 'canBeAssumedBy' },
        { x: 0, y: 0, w: 0, h: 0, i: 'identitiesWithAccess' },
      ].forEach(it => layout1Col.push(it))
    }

    if ('Compute' === type) {
      const layout = [
        { x: 0, y: 0, w: 0, h: 0, i: 'canAssumeRole' },
        { x: 0, y: 0, w: 0, h: 0, i: 'groupMembership' },
        { x: 0, y: 0, w: 0, h: 0, i: 'directPolicies' },
        { x: 0, y: 0, w: 0, h: 0, i: 'canBeAssumedBy' },
      ]

      if ('azure' === this.props.nodeData.cloudType) {
        layout.push({ x: 0, y: 0, w: 0, h: 0, i: 'scp' }) // azure don't have SCP
        // so make this full width:
        layout.push({ x: 3, y: 0, w: 6, h: 2, i: 'identitiesWithAccess' })
      } else {
        // otherwise they're side-by-each
        layout.push({ x: 0, y: 0, w: 3, h: 2, i: 'scp' })
        layout.push({ x: 3, y: 0, w: 3, h: 2, i: 'identitiesWithAccess' })
      }
      layout.forEach(it => layout2Col.push(it))
      layout.forEach(it => layout1Col.push(it))
    }

    if (DATALAYOUT_TYPES.includes(type)) {
      ;[
        { x: 0, y: 0, w: 0, h: 0, i: 'canAssumeRole' },
        { x: 0, y: 0, w: 0, h: 0, i: 'groupMembership' },
        { x: 0, y: 0, w: 3, h: 4, i: 'directPolicies' },
        { x: 3, y: 0, w: 3, h: 4, i: 'scp' },
        { x: 0, y: 0, w: 0, h: 0, i: 'identitiesWithAccess' },
        { x: 0, y: 0, w: 0, h: 0, i: 'canBeAssumedBy' },
      ].forEach(it => layout2Col.push(it))
      ;[
        { x: 0, y: 0, w: 0, h: 0, i: 'canAssumeRole' },
        { x: 0, y: 0, w: 0, h: 0, i: 'groupMembership' },
        { x: 0, y: 0, w: 3, h: 4, i: 'directPolicies' },
        { x: 3, y: 0, w: 3, h: 4, i: 'scp' },
        { x: 0, y: 0, w: 0, h: 0, i: 'identitiesWithAccess' },
        { x: 0, y: 0, w: 0, h: 0, i: 'canBeAssumedBy' },
      ].forEach(it => layout1Col.push(it))
    }

    const renderEffectivePermissions =
      'User' === type || 'Role' === type || 'Compute' === type

    if (renderEffectivePermissions) {
      ;[
        { x: 0, y: 17, w: 3, h: 7, i: 'usedPermissionViewer' },
        { x: 3, y: 17, w: 3, h: 7, i: 'permissionViewer' },
        { x: 0, y: 22, w: 6, h: 8, i: 'resourceViewer' },
      ].forEach(it => layout2Col.push(it))
      ;[
        { x: 0, y: 14, w: 3, h: 6, i: 'usedPermissionViewer' },
        { x: 3, y: 14, w: 3, h: 6, i: 'permissionViewer' },
        { x: 0, y: 18, w: 6, h: 8, i: 'resourceViewer' },
      ].forEach(it => layout1Col.push(it))
    } else {
      ;[
        { x: 0, y: 0, w: 0, h: 0, i: 'usedPermissionViewer' },
        { x: 0, y: 0, w: 0, h: 0, i: 'permissionViewer' },
        { x: 0, y: 10, w: 6, h: 8, i: 'resourceViewer' },
      ].forEach(it => {
        layout1Col.push(it)
        layout2Col.push(it)
      })
    }

    let data = this.props.canAssumeRoles.get('data')
      ? this.props.canAssumeRoles.get('data')
      : []

    data = data.map(item => ({
      ...item,
      account: getAccountFromSrn(item.srn),
    }))

    const cloud = getCloudFromSrn(this.props.nodeId)

    return (
      <div style={style.accessActivityContainer}>
        <ShowPathModal
          toggle={this.closeShowPath}
          isOpen={!!this.state.showPathId}
          resourceId={this.state.showPathId}
          sourceName={getNameFromSrn(this.props.nodeId)}
          onDoubleClick={this.onDoubleClickPathNode}
          sourceId={this.props.nodeId}
          classification={this.state.showPathClassification}
          actionableByMode={actionableByMode}
          pathEndItem={this.state.showPathToItem}
          canAccessPathsLoading={this.props.canAccessPathsLoading}
          canAccessPathsError={this.props.canAccessPathsError}
          paths={this.props.canAccessPaths.getIn(
            [
              this.state.showPathId,
              this.state.showPathClassification,
              fromDate.format('YYYY-MM-DD'),
              this.state.showPathConditions,
            ],
            List()
          )}
        />
        <NodeViewMiniPopout
          handleClose={this.closeMiniNodeView}
          open={this.state.miniNodeViewOpen}
          nodeId={this.state.miniNodeViewNodeId}
        />
        <ResponsiveGridLayout
          className="layout"
          breakpoints={{ lg: 1390, md: 1250, sm: 916, xs: 679 }}
          cols={{ lg: 6, md: 6, sm: 6, xs: 6 }}
          margin={[15, 15]}
          rowHeight={rowHeight}
          measureBeforeMount={true}
          useCSSTransforms={false}
          layouts={{
            lg: layout2Col,
            md: layout2Col,
            sm: layout1Col,
            xs: layout1Col,
          }}
          isDraggable={false}
          isResizable={false}
        >
          <div style={style.marker} key="canAssumeRole">
            {('User' === type || 'Compute' === type || 'Role' === type) && (
              <BorderedCard
                style={{ height: '100%', overflow: 'hidden' }}
                error={this.props.canAssumeRoles.get('error')}
                loading={this.props.canAssumeRoles.get('isLoading')}
              >
                <div style={{ height: '100%' }}>
                  <TopTitle>
                    <DynamicFormattedMessage {...messages.canAssumeRoles} />
                  </TopTitle>
                  <DataTable
                    autosize={false}
                    data={data}
                    customColumnConfig={{
                      name: {
                        flex: 1,
                      },
                      account: {
                        flex: 1,
                      },
                      srn: {
                        hide: true,
                      },
                      how: {
                        width: 120,
                        minWidth: 120,
                        aggFunc: null,
                        pinned: 'right',
                        headerName: '',
                        enableRowGroup: false,
                        menuTabs: [],
                        suppressMenu: true,
                        cellRendererFramework: params => {
                          if (!params.data) {
                            return null
                          }
                          return (
                            <TextLink
                              color="primary"
                              onClick={() =>
                                this.onShowPathFromList({
                                  item: {
                                    srn: params.data.srn,
                                    name: params.data.name,
                                    path: JSON.stringify(
                                      params.data.how
                                        .split(',')
                                        .splice(
                                          0,
                                          params.data.how.split(',').length - 1
                                        )
                                    ),
                                  },
                                  showPathClassification: 'Authentication',
                                })
                              }
                            >
                              Show Path
                            </TextLink>
                          )
                        },
                      },
                    }}
                    onDoubleClickRow={item => this.navigateToNodeView(item.srn)}
                  />
                </div>
              </BorderedCard>
            )}
          </div>
          <div style={style.marker} key="canBeAssumedBy">
            {'Role' === type && (
              <BorderedCard
                style={{ height: '100%', overflow: 'hidden' }}
                error={this.props.canBeAssumedBy.get('error')}
                loading={this.props.canBeAssumedBy.get('isLoading')}
              >
                <div style={{ height: '100%' }}>
                  <TopTitle>
                    <DynamicFormattedMessage {...messages.canBeAssumedBy} />
                  </TopTitle>
                  <DataTable
                    autosize={false}
                    customColumnConfig={{
                      account: {
                        flex: 1,
                      },
                      name: {
                        flex: 1,
                      },
                      srn: {
                        hide: true,
                      },
                      how: {
                        width: 120,
                        minWidth: 120,
                        aggFunc: null,
                        pinned: 'right',
                        headerName: '',
                        enableRowGroup: false,
                        menuTabs: [],
                        suppressMenu: true,
                        cellRendererFramework: params => {
                          if (!params.data) {
                            return null
                          }
                          return (
                            <TextLink
                              color="primary"
                              onClick={() =>
                                this.onShowPathFromList({
                                  item: {
                                    srn: params.data.srn,
                                    name: params.data.name,
                                    path: JSON.stringify(
                                      params.data.how
                                        .split(',')
                                        .splice(
                                          0,
                                          params.data.how.split(',').length - 1
                                        )
                                    ),
                                  },
                                  showPathClassification: 'Authentication',
                                })
                              }
                            >
                              Show Path
                            </TextLink>
                          )
                        },
                      },
                    }}
                    data={(this.props.canBeAssumedBy.get('data') || []).map(
                      item => ({
                        ...item,
                        resourceType: getTypeFromSrn(item.srn),
                        account: getAccountFromSrn(item.srn),
                      })
                    )}
                    onDoubleClickRow={item => this.navigateToNodeView(item.srn)}
                  />
                </div>
              </BorderedCard>
            )}
          </div>

          <div style={style.marker} key="groupMembership">
            {('Role' === type || 'User' === type) && (
              <ListCard
                data={this.props.groupMemberships.get('data')}
                error={this.props.groupMemberships.get('error')}
                handleLinkClick={item =>
                  this.onShowPathFromList({
                    item,
                    showPathClassification: 'Group Membership',
                  })
                }
                isLoading={this.props.groupMemberships.get('isLoading')}
                push={this.props.push}
                title={
                  <DynamicFormattedMessage {...messages.groupMemberships} />
                }
              />
            )}
          </div>

          <div style={style.marker} key="directPolicies">
            {('Role' === type ||
              'User' === type ||
              DATALAYOUT_TYPES.includes(type)) && (
              <DirectPolicyWidget
                policies={this.props.directAttachedPolicies}
                onClick={item =>
                  this.onShowPathFromList({
                    item,
                    showPathClassification: 'Direct Policies',
                  })
                }
                account={this.props.nodeData.account}
                push={this.props.push}
              />
            )}
          </div>
          <div style={style.marker} key="scp">
            <ListCard
              data={this.props.scps.get('data')}
              error={this.props.scps.get('error')}
              handleLinkClick={item =>
                this.onShowPathFromList({
                  item,
                  showPathClassification: 'SCP',
                })
              }
              isLoading={this.props.scps.get('isLoading')}
              push={this.props.push}
              title={
                <div>
                  <DynamicFormattedMessage {...messages.scps} />
                  &nbsp;&nbsp;
                  <IHelp
                    id="scps"
                    info={true}
                    infoMsg="AWS Service Control Policies, Azure Management Groups, GCP Organization Policy Service"
                    iconSize="20px"
                    position="right"
                  />
                </div>
              }
            />
          </div>
          <div style={style.marker} key="identitiesWithAccess">
            {'Compute' === type && (
              <ListCard
                data={this.props.instanceProfiles.get('data')}
                error={this.props.instanceProfiles.get('error')}
                handleLinkClick={item => this.openMiniNodeView(item.srn)}
                isLoading={this.props.instanceProfiles.get('isLoading')}
                push={this.props.push}
                title={
                  'aws' === this.props.nodeData.cloudType ? (
                    <DynamicFormattedMessage {...messages.instanceProfiles} />
                  ) : (
                    <DynamicFormattedMessage {...messages.servicePrincipals} />
                  )
                }
              />
            )}
          </div>
          <div style={style.marker} key="permissionViewer">
            {renderEffectivePermissions && (
              <EffectivePermissionWidget
                onShowPath={this.onShowPathForEP}
                srn={this.props.nodeId}
              />
            )}
          </div>
          <div style={style.marker} key="usedPermissionViewer">
            {renderEffectivePermissions && (
              <EffectivePermissionWidget
                srn={this.props.nodeId}
                onShowPath={this.onShowPathForEP}
                usedPermissions
              />
            )}
          </div>
          <div style={style.marker} key="resourceViewer">
            <CanAccessWidget
              crmTickets={this.props.crmTickets}
              usesEP={cloud === CLOUD_TYPES.GCP || cloud === CLOUD_TYPES.AZURE}
              renderCRMOverlay={() => this.getCRMOverlay()}
              isMonitored={this.props.isMonitored}
              push={this.props.push}
              srn={this.props.nodeId}
              isLocked={
                changeDetectionProperties
                  .map(x => x.get('keyName'))
                  .includes('hasPermissionTo') ||
                changeDetectionProperties
                  .map(x => x.get('keyName'))
                  .includes('actionableBy')
              }
              title={actionableByMode ? 'Who & What Can Access' : 'Can Access'}
              canAccessData={this.props.canAccessData.toJS()}
              isLoading={this.props.canAccessData.get('isLoading')}
              onShowPath={this.onShowPath}
              onShowPathFromList={(...args) => {
                this.onShowPathFromList(...args)
              }}
              actionableByMode={actionableByMode}
            />
          </div>
        </ResponsiveGridLayout>
      </div>
    )
  }
}

Access.propTypes = {
  alertsCanAccess: ImmutablePropTypes.list,
  alertTime: PropTypes.number,
  canAccessData: ImmutablePropTypes.iterable.isRequired,
  canAccessPaths: ImmutablePropTypes.map.isRequired,
  canAccessPathsLoading: PropTypes.bool,
  canAccessPathsError: PropTypes.bool,
  canAssumeRoles: ImmutablePropTypes.map,
  canBeAssumedBy: ImmutablePropTypes.map,
  changeDetectionProperties: ImmutablePropTypes.iterable,
  crmTickets: ImmutablePropTypes.list.isRequired,
  date: PropTypes.string,
  directAttachedPolicies: ImmutablePropTypes.map,
  getCanAccessPath: PropTypes.func.isRequired,
  getCanAssumeRoles: PropTypes.func.isRequired,
  getCanBeAssumedBy: PropTypes.func.isRequired,
  getCanAccessData: PropTypes.func.isRequired,
  getDirectAttachedPolicies: PropTypes.func.isRequired,
  getEPPaths: PropTypes.func.isRequired,
  getGroupMemberships: PropTypes.func.isRequired,
  getInstanceProfiles: PropTypes.func.isRequired,
  getPermissionInfo: PropTypes.func.isRequired,
  getSCPs: PropTypes.func.isRequired,
  groupMemberships: ImmutablePropTypes.map,
  instanceProfiles: ImmutablePropTypes.map,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  permissions: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.map,
  }),
  push: PropTypes.func.isRequired,
  scps: ImmutablePropTypes.map,
  setCanAccessPath: PropTypes.func.isRequired,
  setCriticalResourceMonitorDate: PropTypes.func.isRequired,
  toggleResourceMonitoring: PropTypes.func,
  isMonitored: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  canAccessPaths: selectCanAccessPaths,
  canAccessData: selectCanAccessData,
  canAssumeRoles: selectCanAssumeRoles,
  canBeAssumedBy: selectCanBeAssumedBy,
  canAccessPathsLoading: selectCanAccessPathsLoading,
  canAccessPathsError: selectCanAccessPathsError,
  changeDetectionProperties: selectChangeDetectionProperties,
  date: selectCriticalResourceMonitorDate,
  directAttachedPolicies: selectDirectAttachedPolicies,
  groupMemberships: selectGroupMemberships,
  scps: selectSCPs,
  instanceProfiles: selectInstanceProfiles,
  isMonitored: selectIsMonitored,
  permissions: selectPermissions,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      setCriticalResourceMonitorDate,
      getCanAccessData,
      getCanAccessPath,
      setCanAccessPath,
      getCanAssumeRoles,
      getGroupMemberships,
      getDirectAttachedPolicies,
      getSCPs,
      getCanBeAssumedBy,
      getEPPaths,
      getInstanceProfiles,
      toggleResourceMonitoring,
      getPermissionInfo,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectMultipleReducers({
  reducers: [
    {
      key: 'access',
      reducer,
    },
    {
      key: 'accessActivity',
      reducer: legacyReducer,
    },
  ],
})
const withSaga = injectMultipleSagas({
  sagas: [
    { key: 'access', saga: saga },
    { key: 'accessactivity', saga: legacySaga },
  ],
})

export default compose(withReducer, withSaga, withConnect)(Access)
