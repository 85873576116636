import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectPublickeyNodeViewCardLayoutDomain = state =>
  state.get('publickeyNodeViewCardLayout', Map())

export const selectPublickeyContent = createSelector(
  selectPublickeyNodeViewCardLayoutDomain,
  state => state.get('publickeyContent') || Map()
)

export const selectIsLoading = createSelector(
  selectPublickeyNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
