/**
 *
 * ProfileIcon
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Icon from 'components/Icon'
class ProfileIcon extends React.PureComponent {
  styles = {
    base: {},
    userIcon: {
      width: this.props.width ? this.props.width : '40px',
      height: this.props.height ? this.props.height : '40px',
      borderRadius: '20px',
      marginRight: '0.5em',
    },
    defaultIcon: {
      marginRight: '0.5em',
    },
  }
  render() {
    if (this.props.img) {
      return [
        <img
          key={this.props.img}
          id="ghb-profile-icon-pic"
          style={this.styles.userIcon}
          src={this.props.img}
          alt="profile photo"
          data-tip={this.props.name}
        />,
        <ReactTooltip
          effect="solid"
          key={`${this.props.name}-tooltip`}
          place="bottom"
        />,
      ]
    } else {
      return (
        <div style={{ ...this.styles.defaultIcon, ...this.props.style }}>
          <Icon
            fa
            name="user-circle"
            style={{ fontSize: this.props.width || '30px' }}
          />
        </div>
      )
    }
  }
}

ProfileIcon.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.object,
}

export default ProfileIcon
