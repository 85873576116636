export default {
  primary: '#1B75BC', //The first brand color
  secondary: '#74B4DF', //Supporting brand color, for links and iconography
  emphasis: '#E4C647', //Sparingly used to draw attention
  light: '#FFFFFF', //IE white, for use as text on dark backgrounds or as a background color
  dark: '#3E3E3E', //IE black, for text, borders, etc
  highlight: '#F9FBFF', //IE blueish white, used as the backgorund color when highlighting data rows or as headers etc
  neutralLight: '#ECEFF1', //a low contrast grey for backgrounds and borders
  neutralMedium: '#ABABAB', //A medium contrast grey for iconography, borders, etc
  neutralDark: '#888888', //A large contrast grey for secondary typography
  primaryAzure: '#737373',
  primaryGcp: '#4285F4',
  primaryAws: '#FF9900',
  chartColors: [
    '#3355B1',
    '#0099EE',
    '#3CAE58',
    '#ff802b',
    '#009688',
    '#fccf49',
    '#A030AC',
    '#d62222',
    '#69e5ab',
  ],
  red: '#DC3545',
  darkBlue: '#24445c',
  success: '#11871f',
  fail: '#bc1818',
  info: '#fccf49',
  warn: '#ff802b',
  explorerIncomingEdge: '#1679a0',
  bodyText: '#212529',
  background: '#FAFAFA',
  identity: '#1b75bc',
  data: '#3e3e3e',
  protection: '#7f2743',
  infrastructure: '#556b2f',
}
