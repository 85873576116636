import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles'
import { exists } from 'utils/sonraiUtils'

class NodeViewDetailCode extends React.PureComponent {
  render() {
    if (Array.isArray(this.props.value)) {
      return this.props.value.map((val, index) => (
        <div key={index} style={styles.codeBlock}>
          {val}
        </div>
      ))
    }

    return (
      <div style={exists(this.props.value) ? styles.codeBlock : {}}>
        {this.props.value || '-'}
      </div>
    )
  }
}

NodeViewDetailCode.propTypes = {
  value: PropTypes.string,
}

export default NodeViewDetailCode
