export const ADD_FIELD = 'app/SearchQuery/ADD_FIELD'
export const ADD_RESULT_VIEW = 'app/SearchQuery/ADD_RESULT_VIEW'
export const CHANGE_ARGUMENT = 'app/SearchQuery/CHANGE_ARGUMENT'
export const CHANGE_FIELD_NAME = 'app/SearchQuery/CHANGE_FIELD_NAME'
export const REMOVE_ARGUMENT = 'app/SearchQuery/REMOVE_ARGUMENT'
export const REMOVE_FIELD = 'app/SearchQuery/REMOVE_FIELD'
export const REMOVE_RESULT_VIEW = 'app/SearchQuery/REMOVE_RESULT_VIEW'
export const START_QUERY = 'app/SearchQuery/START_QUERY'
export const LOAD_SAVED_QUERY = 'app/SearchQuery/LOAD_SAVED_QUERY'
export const RESET_QUERY = 'app/SearchQuery/RESET_QUERY'
export const UPDATE_RESULT_VIEW = 'app/SearchQuery/UPDATE_RESULT_VIEW'
export const SET_DEFAULT_VIEW = 'app/SearchQuery/SET_DEFAULT_VIEW'
export const ADD_ARGUMENT = 'app/SearchQuery/ADD_ARGUMENT'
export const MOVE_ARGUMENT = 'app/SearchQuery/MOVE_ARGUMENT'
export const LOAD_AND_CONVERT_SAVED_QUERY =
  'app/SearchQuery/LOAD_AND_CONVERT_SAVED_QUERY'
export const CHANGE_CHILD_OPERATOR = 'app/SearchQuery/CHANGE_CHILD_OPERATOR'
export const ADD_PROPERTY = 'app/SearchQuery/ADD_PROPERTY'
export const REMOVE_PROPERTY = 'app/SearchQuery/REMOVE_PROPERTY'
export const TOGGLE_DEFAULT_PROPERTY = 'app/SearchQuery/TOGGLE_DEFAULT_PROPERTY'
export const RESET_DEFAULT_PROPERTIES =
  'app/SearchQuery/RESET_DEFAULT_PROPERTIES'

export const ADD_COMMENT = 'app/SearchQuery/ADD_COMMENT'
export const REMOVE_COMMENT = 'app/SearchQuery/REMOVE_COMMENT'
export const SET_QUERY_PROPERTY_ORDER =
  'app/SearchQuery/SET_QUERY_PROPERTY_ORDER'
export const SET_QUERY_COLUMN_ALIAS = 'app/SearchQuery/SET_QUERY_COLUMN_ALIAS'
export const REMOVE_QUERY_COLUMN_ALIAS =
  'app/SearchQuery/REMOVE_QUERY_COLUMN_ALIAS'
export const SET_EFFECTIVE_PERMISSION_PROPERTY_ORDER =
  'app/SearchQuery/SET_EFFECTIVE_PERMISSION_PROPERTY_ORDER'
export const ADD_EFFECTIVE_PERMISSION_INCLUDE_GROUP =
  'app/SearchQuery/ADD_EFFECTIVE_PERMISSION_INCLUDE_GROUP'
export const ADD_EFFECTIVE_PERMISSION_EXCLUDE_GROUP =
  'app/SearchQuery/ADD_EFFECTIVE_PERMISSION_EXCLUDE_GROUP'
export const REMOVE_EFFECTIVE_PERMISSION_GROUP =
  'app/SearchQuery/REMOVE_EFFECTIVE_PERMISSION_GROUP'
export const ADD_EFFECTIVE_PERMISSION =
  'app/SearchQuery/ADD_EFFECTIVE_PERMISSION'
export const REMOVE_EFFECTIVE_PERMISSION =
  'app/SearchQuery/REMOVE_EFFECTIVE_PERMISSION'
export const ADD_EP_DISPLAY_COLUMN = 'app/SearchQuery/ADD_EP_DISPLAY_COLUMN'
export const REMOVE_EP_DISPLAY_COLUMN =
  'app/SearchQuery/REMOVE_EP_DISPLAY_COLUMN'
export const SET_IS_DISTINCT_EP = 'app/SearchQuery/SET_IS_DISTINCT_EP'
export const SET_EP_DISPLAY_COLUMN_ALIAS =
  'app/SearchQuery/SET_EP_DISPLAY_COLUMN_ALIAS'
export const REMOVE_EFFECTIVE_PERMISSION_BLOCK =
  'app/SearchQuery/REMOVE_EFFECTIVE_PERMISSION_BLOCK'
export const UPDATE_EFFECTIVE_PERMISSION =
  'app/SearchQuery/UPDATE_EFFECTIVE_PERMISSION'
export const SET_EP_COLUMN_ALIAS = 'app/SearchQuery/SET_EP_COLUMN_ALIAS'
export const REMOVE_EP_COLUMN_ALIAS = 'app/SearchQuery/REMOVE_EP_COLUMN_ALIAS'
export const INSERT_FIELD = 'app/SearchQuery/INSERT_FIELD'
export const SET_SEARCH_LIMIT = 'app/SearchQuery/SET_SEARCH_LIMIT'
export const CLEAR_SEARCH_LIMIT = 'app/SearchQuery/CLEAR_SEARCH_LIMIT'
