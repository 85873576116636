import React from 'react'
import PropTypes from 'prop-types'
import BorderlessButton from 'components/BorderlessButton'

class DropdownItem extends React.Component {
  styles = {
    option: {
      width: '100%',
      textAlign: 'left',
      padding: '0.5em 1.5em',
      borderBottom: '1px solid #eee',
    },
  }

  onClick = e => {
    e.stopPropagation()
    if (this.props.closeOnClick) {
      this.props.toggle()
    }

    this.props.onClick()
  }

  render() {
    return (
      <BorderlessButton
        style={this.styles.option}
        onClick={this.onClick}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </BorderlessButton>
    )
  }
}

DropdownItem.propTypes = {
  children: PropTypes.node,
  closeOnClick: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  toggle: PropTypes.func,
}

export default DropdownItem
