import { createSelector } from 'reselect'
import { Map } from 'immutable'

export const nodeViewMiniPopoutDomain = state => 
  state.get('nodeViewMiniPopout') || Map()

export const selectData = createSelector(
  nodeViewMiniPopoutDomain,
  nodeViewMiniPopout => {
    return nodeViewMiniPopout.get('data')
  }
)