import React from 'react'
import PropTypes from 'prop-types'
import { DropdownToggle } from 'reactstrap'

class DropdownAnchor extends React.Component {
  render() {
    const styles = {
      label: {
        cursor: 'pointer',
        border: this.props.borderless ? 'none' : undefined,
      },
      borderless: {
        border: 'none',
      },
    }

    return (
      <DropdownToggle
        data-toggle="dropdown"
        style={{ ...styles.label, ...this.props.style }}
      >
        {this.props.children}
      </DropdownToggle>
    )
  }
}

DropdownAnchor.defaultprops = {
  style: {},
}

DropdownAnchor.propTypes = {
  borderless: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
}

export default DropdownAnchor
