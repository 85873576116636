import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

const Pill = ({ style = {}, children, theme }) => {
  return (
    <Badge
      style={{
        backgroundColor: theme.neutralLight,
        color: theme.neutralMedium,
        ...style,
      }}
      pill
    >
      {children}
    </Badge>
  )
}

Pill.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  theme: themeShape,
}

export default themeable(Pill)
