/**
 *
 * SonraiCountDisplay
 *
 */

import PropTypes from 'prop-types'
import { sonraiCountDisplay } from 'utils/sonraiUtils'

const SonraiCountDisplay = ({ count, hasSearchLimit }) => {
  return sonraiCountDisplay(count, hasSearchLimit)
}

SonraiCountDisplay.defaultProps = {
  hasSearchLimit: false,
}

SonraiCountDisplay.propTypes = {
  count: PropTypes.number.isRequired,
  hasSearchLimit: PropTypes.bool,
}

export default SonraiCountDisplay
