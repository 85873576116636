import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import {
  RESET_JOB_REQUEST_SUCCESS,
  SET_PLATFORM_ACCOUNT,
  SET_PRIVATE_KEY,
  SUBMIT_JOB,
  SUBMIT_JOB_SUCCESS,
  SUBMIT_JOB_ERROR,
} from './constants'

const initialState = fromJS({
  platformAccounts: {},
  privateKey: null,
  submitJob: {
    loading: false,
    success: false,
    error: false,
  },
})

const dataClassificationReducer = handleActions(
  {
    [SET_PLATFORM_ACCOUNT]: (state, { payload }) =>
      state.setIn(
        ['platformAccounts', payload.accountId],
        payload.platformAccount
      ),
    [SET_PRIVATE_KEY]: (state, { payload }) =>
      state.set('privateKey', payload.key),

    [RESET_JOB_REQUEST_SUCCESS]: state =>
      state
        .setIn(['submitJob', 'loading'], false)
        .setIn(['submitJob', 'error'], false)
        .setIn(['submitJob', 'success'], false),
    [SUBMIT_JOB]: state =>
      state
        .setIn(['submitJob', 'loading'], true)
        .setIn(['submitJob', 'error'], false)
        .setIn(['submitJob', 'success'], false),
    [SUBMIT_JOB_SUCCESS]: state =>
      state
        .setIn(['submitJob', 'loading'], false)
        .setIn(['submitJob', 'error'], false)
        .setIn(['submitJob', 'success'], true),
    [SUBMIT_JOB_ERROR]: state =>
      state
        .setIn(['submitJob', 'loading'], false)
        .setIn(['submitJob', 'error'], true)
        .setIn(['submitJob', 'success'], false),
  },
  initialState
)

export default dataClassificationReducer
