import gql from 'graphql-tag'
export const GET_ALL_BOTS = gql`
  query getBots {
    Bots {
      items {
        title
        description
        type
        isCustom
        url
        status
        cloud
        operation
        lastSync
        version
        srn
        resourceId
        blob
      }
    }
  }
`

export const GET_FILTERED_BOTS = gql`
  query getFilteredBots($filters: BotFilter!) {
    Bots(where: $filters) {
      items {
        title
        description
        type
        isCustom
        url
        status
        cloud
        operation
        lastSync
        version
        srn
        resourceId
        blob
      }
    }
  }
`

export const CREATE_BOT_MUTATION = gql`
  mutation createBot($url: String!) {
    CreateBot(input: { url: $url }) {
      title
      description
      type
      isCustom
      url
      status
      cloud
      operation
      lastSync
      version
      srn
      resourceId
    }
  }
`
