import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { Map } from 'immutable'
import { push } from 'connected-react-router'
import { createStructuredSelector } from 'reselect'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import moment from 'moment'
import BorderedCard from 'components/BorderedCard'
import TicketDetailsHeaderActions from './components/TicketDetailsHeaderActions'
import TicketDetailsHeaderProperties from './components/TicketDetailsHeaderProperties'
import ErrorChangeStatusModal from './components/ErrorChangeStatusModal'
import { selectPolicies } from 'containers/ControlFrameworkData/selectors'
import {
  selectSwimlanes,
  selectSonraiUsers,
} from 'containers/SonraiData/selectors'
import _ from 'lodash'
import { getFriendlyTicketName } from 'utils/widgetUtils'
import {
  acceptRiskTicket,
  closeTicket,
  reopenTicket,
  snoozeTicket,
  reassignToUser,
} from 'containers/TicketDetailsData/actions'
import {
  selectActionStatus,
  selectAcceptRiskTicketStatus,
  selectCloseTicketStatus,
  selectReopenTicketStatus,
  selectSnoozeTicketStatus,
  selectReassignToUserStatus,
} from 'containers/TicketDetailsData/selectors'

export class TicketDetailsHeader extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      actionErrorModalOpen: false,
    }
  }
  style = {
    container: {
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '0.5fr 1.5fr',
      gridGap: '0.5rem',
    },
    top: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '"title actions"',
    },
    title: {
      fontWeight: '400',
      fontSize: '1.35rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    snoozeLabel: {
      margin: '0rem 0.75rem 0rem 0rem',
      fontSize: '0.9rem',
      fontWeight: '500',
      letterSpacing: '1.2px',
      backgroundColor: '#fff',
      padding: '3px 5px 22px 6px',
      borderRadius: '0.8rem',
      color: this.props.theme.neutralMedium,
      textTransform: 'uppercase',
      border: `2px solid ${this.props.theme.neutralMedium}`,
      height: '26px',
    },
    resolvedLabel: {
      margin: '0rem 0.75rem 0rem 0rem',
      fontSize: '0.9rem',
      fontWeight: '500',
      letterSpacing: '1.2px',
      backgroundColor: '#fff',
      padding: '3px 5px 22px 6px',
      borderRadius: '0.8rem',
      color: this.props.theme.success,
      textTransform: 'uppercase',
      border: `2px solid ${this.props.theme.success}`,
      height: '26px',
    },
    secondaryContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    indicatorText: {
      fontWeight: '500',
      fontSize: '0.85rem',
      fontStyle: 'italic',
      color: this.props.theme.neutralMedium,
    },
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.actionStatus.get('error') == false &&
      this.props.actionStatus.get('error') == true
    ) {
      this.setState({ actionErrorModalOpen: true })
    }
  }

  renderSnoozeIndication = () => {
    const time = moment(this.props.ticket.get('snoozedUntil')).format('ll')

    return (
      <div style={this.style.secondaryContainer}>
        <div style={this.style.snoozeLabel}> Snoozed</div>
        <div style={this.style.indicatorText}> Snoozed until {time}</div>
      </div>
    )
  }

  renderResolvedIndication = () => {
    const status = _.startCase(
      _.lowerCase(this.props.ticket.get('status') || '')
    )
    const time = moment(this.props.ticket.get('transitionDate')).format('ll')
    return (
      <div style={this.style.secondaryContainer}>
        <div style={this.style.resolvedLabel}> Resolved </div>
        <div style={this.style.indicatorText}>
          Resolved as {status} on {time}
        </div>
      </div>
    )
  }

  render() {
    const title = getFriendlyTicketName(this.props.ticket, this.props.policies)
    const isSnoozed = !!this.props.ticket.get('snoozedUntil')
    const isResolved =
      !isSnoozed &&
      (this.props.ticket.get('status') === 'CLOSED' ||
        this.props.ticket.get('status') === 'RISK_ACCEPTED')
    return (
      <BorderedCard style={this.style.container}>
        {isSnoozed && this.renderSnoozeIndication()}
        {isResolved && this.renderResolvedIndication()}
        <div style={this.style.top}>
          <div title={title} style={this.style.title}>
            {title}
          </div>
          <TicketDetailsHeaderActions
            actionStatus={this.props.actionStatus}
            ticket={this.props.ticket.toJS()}
            acceptRiskTicket={this.props.acceptRiskTicket}
            closeTicket={this.props.closeTicket}
            snoozeTicket={this.props.snoozeTicket}
            reopenTicket={this.props.reopenTicket}
            reassignToUser={this.props.reassignToUser}
            users={this.props.users}
            swimlanes={this.props.swimlanes}
          />
        </div>
        <TicketDetailsHeaderProperties
          ticket={this.props.ticket.toJS()}
          swimlanes={this.props.swimlanes}
        />
        <ErrorChangeStatusModal
          isOpen={this.state.actionErrorModalOpen}
          close={() => this.setState({ actionErrorModalOpen: false })}
          acceptRiskTicketStatus={this.props.acceptRiskTicketStatus}
          closeTicketStatus={this.props.closeTicketStatus}
          reopenTicketStatus={this.props.reopenTicketStatus}
          snoozeTicketStatus={this.props.snoozeTicketStatus}
          reassignToUserStatus={this.props.reassignToUserStatus}
        />
      </BorderedCard>
    )
  }
}

TicketDetailsHeader.propTypes = {
  // ~ bound action creators ~
  acceptRiskTicket: PropTypes.func.isRequired,
  closeTicket: PropTypes.func.isRequired,
  snoozeTicket: PropTypes.func.isRequired,
  reopenTicket: PropTypes.func.isRequired,
  reassignToUser: PropTypes.func.isRequired,

  // ~ props from redux store ~
  actionStatus: ImmutablePropTypes.map,
  acceptRiskTicketStatus: ImmutablePropTypes.map,
  closeTicketStatus: ImmutablePropTypes.map,
  snoozeTicketStatus: ImmutablePropTypes.map,
  reassignToUserStatus: ImmutablePropTypes.map,
  reopenTicketStatus: ImmutablePropTypes.map,
  policies: ImmutablePropTypes.iterable.isRequired,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  users: ImmutablePropTypes.iterable.isRequired,

  // ~ props from parent
  ticket: ImmutablePropTypes.iterable.isRequired,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  actionStatus: selectActionStatus,
  acceptRiskTicketStatus: selectAcceptRiskTicketStatus,
  closeTicketStatus: selectCloseTicketStatus,
  reopenTicketStatus: selectReopenTicketStatus,
  snoozeTicketStatus: selectSnoozeTicketStatus,
  reassignToUserStatus: selectReassignToUserStatus,
  policies: selectPolicies,
  swimlanes: selectSwimlanes,
  users: selectSonraiUsers,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push,
      acceptRiskTicket,
      closeTicket,
      snoozeTicket,
      reopenTicket,
      reassignToUser,
    },
    dispatch
  )

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(themeable(TicketDetailsHeader))
