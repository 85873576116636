import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Set } from 'immutable'
import CodeMirror from 'codemirror'
import 'codemirror/addon/runmode/runmode'
import 'codemirror/mode/javascript/javascript'
import { Table } from 'reactstrap'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import CopyToClipBoard from 'components/CopyToClipboard'
import Icon from 'components/Icon'
import SearchInput from 'components/SearchInput'
import {
  metadataToObj,
  metdataNestedObject,
  unescapeJSONObject,
} from 'utils/sonraiUtils'
// display mode, either key-value or json
export const DISPKV = 'DISPKV'
export const DISPJSON = 'DISPJSON'

// settings for the position of the search box in this component
export const SEARCH_NONE = 'search/none'
export const SEARCH_INLINE = 'search/inline'
export const SEARCH_HEADER = 'search/header'

const styles = {
  displayModeButton: {
    background: 'none',
    border: 'none',
    color: '#9a9a9a',
  },
  jsonDisplayContainer: {
    border: '1px solid #eee',
    padding: '10px',
  },
  jsonDisplayControlsContainer: {
    padding: '0px 0px 8px 0px',
    textAlign: 'right',
  },
  jsonDisplayText: {
    fontFamily: '"Lucida Console", Monaco, monospace',
    fontSize: '11.5px',
  },
  jsonDisplayStyles: {
    filterMatch: {
      fontWeight: 'bold',
      backgroundColor: '#ffc1075e',
    },
    property: {
      color: 'black',
    },
    string: {
      color: '#007ad9',
    },
  },
  tableCellCopyButtonContainer: {
    height: '12px',
    width: '24px',
    cursor: 'pointer',
    // make it go in the RHS of cell
    float: 'right',
    // it looks nicer if it's moved a little bit
    position: 'relative',
    right: '8px',
    top: '-4px',
  },
  tableCellText: {
    display: 'inline-block',
    width: 'calc(100% - 24px)',
    wordBreak: 'break-all',
  },
}

class NodeViewDetailMetadataBody extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      cellsShowCopy: Set(),
      displayMode: DISPKV,
      filterText: '',
      search: false,
    }
  }

  setFilterText = ({ target: { value } }) =>
    this.setState({ filterText: value })

  /**
   * this method actualy returns the style to display in a table cell
   * if the text matches the filterText
   */
  isMatch = key => {
    let keyText = key.toLowerCase()
    let filterText = this.state.filterText.toLowerCase()
    if (filterText !== '') {
      if (keyText.includes(filterText)) {
        return {
          backgroundColor: this.props.theme.highlight,
        }
      }
      return {
        color: 'rgba(171, 171, 171, 0.51)',
      }
    }
    return {}
  }

  changeDisplayMode = displayMode => {
    this.setState({ displayMode })
  }

  toggleSearchBar = () => {
    this.setState(oldState => ({ search: !oldState.search }))
  }

  onMouseEnterTableCell = cellId => {
    // I made it a Set here in case we want to show the copy on multiple
    // cells at some point
    this.setState({ cellsShowCopy: Set().add(cellId) })
  }

  onMouseLeaveMetadataTable = () => {
    this.setState({ cellsShowCopy: Set() })
  }

  /**
   * this renders the bit of the body if the display mode is for json
   */
  renderJSONBody = () => {
    // create JSON for the body
    const medatadataObj = metdataNestedObject(this.props.value)
    const unescapedMedatadaObj = unescapeJSONObject(medatadataObj)
    const json = JSON.stringify(unescapedMedatadaObj, null, 2)

    // we're going to be creating elements for the json display after codemirror
    // parses the text using this function
    const elements = []
    let index = 0
    const pushElement = (token, classNames) => {
      // decide which classes the cell should have
      const tokenStyles = (classNames || '')
        .split(' ')
        .map(className => {
          return styles.jsonDisplayStyles[className.trim()]
        })
        .filter(style => style)
        .reduce((allStyles, style) => {
          return {
            ...allStyles,
            ...style,
          }
        }, {})

      // if the text matches the search text, add an additional class
      let filterText = this.state.filterText.toLowerCase()
      if (
        token &&
        filterText !== '' &&
        token.toLowerCase().includes(filterText)
      ) {
        Object.assign(tokenStyles, styles.jsonDisplayStyles.filterMatch)
      }

      // put the elemnt in the list of elements
      elements.push(
        <span className={classNames} style={tokenStyles} key={++index}>
          {token}
        </span>
      )
    }

    // parse and tokenize the JSON
    CodeMirror.runMode(
      json,
      {
        name: 'text/javascript',
        json: 'true',
      },
      // for each token, create an element
      (token, style) => {
        pushElement(token, style)
      }
    )
    return (
      <div>
        <div
          className="json-display-control-container"
          style={styles.jsonDisplayControlsContainer}
        >
          <div
            style={{
              display: 'inline-block',
              fontSize: '12px',
              marginLeft: '4px',
              position: 'relative',
              top: '-2px',
            }}
          >
            <button
              style={styles.displayModeButton}
              onClick={this.toggleSearchBar}
            >
              <Icon fa name="search" />
            </button>
            {/* button to change the display mode */}
            <button
              style={styles.displayModeButton}
              onClick={() => this.changeDisplayMode(DISPKV)}
            >
              <Icon fa name="table" />
            </button>
            <CopyToClipBoard
              value={json}
              style={{
                ...styles.displayModeButton,
                marginTop: 'none',
              }}
            />
          </div>
        </div>
        <div
          className="json-display-content"
          style={styles.jsonDisplayContainer}
        >
          <pre style={styles.jsonDisplayText}>{elements}</pre>
        </div>
      </div>
    )
  }

  /**
   * this method renders the table view
   */
  renderTableBody = () => {
    const metaObject = metadataToObj(this.props.value)
    const keysToDisplay = Object.keys(metaObject)
    return (
      <Table style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ border: 'none' }}>Key</th>
            <th style={{ border: 'none' }}>Value</th>
            <th
              style={{
                border: 'none',
                padding: '0px 0px 8px 0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  marginLeft: '4px',
                  position: 'relative',
                  justifyContent: 'flex-end',
                  top: '-2px',
                }}
              >
                <button
                  style={styles.displayModeButton}
                  onClick={this.toggleSearchBar}
                >
                  <Icon fa name="search" />
                </button>
                {/* button to change display mode */}
                <button
                  style={{
                    ...styles.displayModeButton,
                    margin: '2px 2px 0px 0px ',
                    fontSize: '14px',
                  }}
                  onClick={() => this.changeDisplayMode(DISPJSON)}
                >
                  &#123; JSON View &#125;
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody onMouseLeave={this.onMouseLeaveMetadataTable}>
          {keysToDisplay.map(key => {
            // cells have a hoverover function (show copy icon), these are the
            // keys to keep track of which cell the mouse is on top of
            const keyId = `k_${key}`
            const valueId = `v_${key}`
            return (
              <tr style={{ borderTop: 'none' }} key={metaObject[key]}>
                {/* the cell with the key in it: */}
                <td
                  style={this.isMatch(key)}
                  onMouseEnter={() => this.onMouseEnterTableCell(keyId)}
                >
                  <div style={styles.tableCellText}>{key}</div>
                  <span style={styles.tableCellCopyButtonContainer}>
                    {this.state.cellsShowCopy.includes(keyId) && (
                      <CopyToClipBoard
                        value={key}
                        style={{
                          ...styles.displayModeButton,
                          marginTop: 'none',
                        }}
                      />
                    )}
                  </span>
                </td>

                {/* the cell with the value in it: */}
                <td
                  colSpan="2"
                  style={this.isMatch(metaObject[key])}
                  onMouseEnter={() => this.onMouseEnterTableCell(valueId)}
                >
                  <div style={styles.tableCellText}>{metaObject[key]}</div>
                  <span style={styles.tableCellCopyButtonContainer}>
                    {this.state.cellsShowCopy.includes(valueId) && (
                      <CopyToClipBoard
                        value={metaObject[key]}
                        style={{
                          ...styles.displayModeButton,
                          marginTop: 'none',
                        }}
                      />
                    )}
                  </span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  /**
   * the component has two display modes, a table vie wnad a JSON view
   */
  renderBody = () => {
    switch (this.state.displayMode) {
      case DISPJSON:
        return this.renderJSONBody()
      case DISPKV:
      default:
        return this.renderTableBody()
    }
  }

  render() {
    return (
      <div>
        {this.state.search && (
          <div style={{ paddingBottom: '6px' }}>
            <SearchInput
              placeholder="Search..."
              value={this.state.filterText}
              onChange={this.setFilterText}
            />
          </div>
        )}
        {this.renderBody()}
      </div>
    )
  }
}

NodeViewDetailMetadataBody.defaultProps = {
  search: SEARCH_HEADER,
}

NodeViewDetailMetadataBody.propTypes = {
  theme: themeShape,
  value: PropTypes.string,
}

export default themeable(NodeViewDetailMetadataBody)
