import React from 'react'
import PropTypes from 'prop-types'
import { DropdownMenu as RSDropdownMenu } from 'reactstrap'

export class DropdownMenu extends React.Component {
  render() {
    return (
      <RSDropdownMenu>
        {React.Children.map(this.props.children, child =>
          React.cloneElement(child, {
            toggle: this.props.toggle,
          })
        )}
      </RSDropdownMenu>
    )
  }
}

DropdownMenu.propTypes = {
  children: PropTypes.node,
  toggle: PropTypes.func,
}

export default DropdownMenu
