import { createAction } from 'redux-actions'
import {
  RESET_JOB_REQUEST_SUCCESS,
  SET_PLATFORM_ACCOUNT,
  SET_PRIVATE_KEY,
  SUBMIT_JOB,
  SUBMIT_JOB_SUCCESS,
  SUBMIT_JOB_ERROR,
} from './constants'

export const resetJobRequestSuccess = createAction(RESET_JOB_REQUEST_SUCCESS)

export const setPlatformAccount = createAction(SET_PLATFORM_ACCOUNT)

export const setPrivateKey = createAction(SET_PRIVATE_KEY)

export const submitJob = createAction(SUBMIT_JOB)
export const submitJobSuccess = createAction(SUBMIT_JOB_SUCCESS)
export const submitJobError = createAction(SUBMIT_JOB_ERROR)
