/**
 *
 * ActionTypeNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { List } from 'immutable'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import TableWidget from 'components/TableWidget'
import { NodeViewDetailString, NodeViewDetailLabel } from 'components/NodeView'

import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'

import messages from './messages'
import reducer from './reducer'
import sagas from './sagas'
import { getActiontypeContent } from './actions'
import { selectActiontypeContent, selectIsLoading } from './selectors'

export class ActionTypeNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getActiontypeContent(props.nodeData.srn)
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getIdentitiesExecuted = () => {
    return this.props.actiontypeContent
      .getIn(['isPartOfAction', 'items'], List())
      .toJS()
      .map(events => {
        return {
          eventName: events.eventName,
          performedBy: _.get(events, ['performedBy', 'items', 0, 'name']),
          srn: _.get(events, ['performedBy', 'items', 0, 'srn']),
        }
      })
  }

  getIdentitiesWithPermissions = () => {
    return this.props.actiontypeContent
      .getIn(
        [
          'isPartOfPermission',
          'items',
          '0',
          'isAllowedBy',
          'items',
          '0',
          'isPartOfPolicyEntry',
          'items',
          '0',
          'isPartOfPolicyVersion',
          'items',
          '0',
          'isPartOfPolicy',
          'items',
          '0',
          'isAttachedTo',
          'items',
        ],
        List()
      )
      .toJS()
      .map(identities => {
        return {
          Identity: identities.IdentityName,
          Type: identities.__typename,
          srn: identities.srn,
        }
      })
  }

  getClassificationType = () => {
    return this.props.actiontypeContent.getIn([
      'hasClassification',
      'items',
      0,
      'ClassificationType',
    ])
  }

  getColumns = () => {
    const numIdentitiesEx = this.getIdentitiesExecuted().length
    const numIdentitiesPerms = this.getIdentitiesWithPermissions().length

    return {
      md: [
        {
          widthPercent: 50,
          items: ['identitiesExecuted', 'identitiesWithPermissions'],
          heights: {
            identitiesExecuted: numIdentitiesEx > 0 ? 2 : 1,
            identitiesWithPermissions: numIdentitiesPerms > 0 ? 2 : 1,
          },
        },
        {
          widthPercent: 50,
          items: ['graph'],
          heights: {
            graph: 3,
          },
        },
      ],
      xs: [
        {
          widthPercent: 100,
          items: ['identitiesExecuted', 'identitiesWithPermissions', 'graph'],
          heights: {
            identitiesExecuted: numIdentitiesEx > 0 ? 2 : 1,
            identitiesWithPermissions: numIdentitiesPerms > 0 ? 2 : 1,
            graph: 3,
          },
        },
      ],
    }
  }

  getGridContent = () => {
    return [
      <div key="identitiesExecuted">
        <TableWidget
          nodeViewType="Actiontype"
          setUserProfileTables={this.props.setUserProfileTables}
          userProfile={this.props.userProfile}
          data={this.getIdentitiesExecuted()}
          title={this.props.intl.formatMessage(messages.executedIdsTitle)}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.isLoading}
        />
      </div>,
      <div key="identitiesWithPermissions">
        <TableWidget
          nodeViewType="Actiontype"
          setUserProfileTables={this.props.setUserProfileTables}
          userProfile={this.props.userProfile}
          data={this.getIdentitiesWithPermissions()}
          title={this.props.intl.formatMessage(messages.permissionIdsTitle)}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.isLoading}
        />
      </div>,
    ]
  }

  getHeaderContent = () => {
    return [
      <Fragment key="content-classification">
        <NodeViewDetailLabel>
          {this.props.intl.formatMessage(messages.classificationLabel)}
        </NodeViewDetailLabel>
        <NodeViewDetailString value={this.getClassificationType()} />
      </Fragment>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          additionalHeaderDetails={this.getHeaderContent()}
          columns={this.getColumns()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
        />
      </Fragment>
    )
  }
}

ActionTypeNodeViewCardLayout.propTypes = {
  actiontypeContent: ImmutablePropTypes.map.isRequired,
  horizontal: PropTypes.bool,
  isLoading: PropTypes.bool,
  getActiontypeContent: PropTypes.func.isReequired,
  setUserProfileTables: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile,
  isLoading: selectIsLoading,
  actiontypeContent: selectActiontypeContent,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getActiontypeContent,
      setUserProfileTables,
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({
  key: 'actiontypeNodeViewCardLayout',
  reducer,
})

const withSaga = injectSaga({
  key: 'actiontypeNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(ActionTypeNodeViewCardLayout)
