import React, { Component } from 'react'
import PropTypes from 'prop-types'

import BorderedCard from 'components/BorderedCard'
import { TopTitle, CardBody } from 'components/Card'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'

import messages from './messages'

const styles = {
  card: {
    width: '100%',
    height: '100%',
  },
  cardBody: {
    justifyContent: 'right',
  },
  title: {
    fontSize: '14px',
    fontWeight: '300',
    borderBottom: '1px solid #eee',
    marginRight: '0px',
  },
  rowDataCell: {
    display: 'inline',
    fontSize: '12px',
  },
  rowArrowCell: {
    display: 'inline',
    fontSize: '8px',
    padding: '10px',
    position: 'relative',
    top: '-2px',
  },
}

class NetworkConnectionsWidget extends Component {
  /**
   * This method will take the nested data structure from the query:
   *
   * isAttachedToInterface { # interface
   *   name
   *   items {
   *     name
   *     isAttachedToSubnet { # subnet
   *       items {
   *         isPartOf { items { name } } # vpc
   *       }
   *     }
   *   }
   * }
   *
   * and turn it into a list of:
   * [
   *   [ interface, subnet, vpc ],
   *   [ ... ],
   *   ...
   * ]
   */
  getRowData = () => {
    return this.props.computeContent.isAttachedToInterface.items.flatMap(
      interfaze => {
        // get a list of [subnet, vpc]
        const subnetsWithVPCs = interfaze.isAttachedToSubnet.items.flatMap(
          subnet => {
            return subnet.isPartOf.items.map(vpc => [subnet, vpc])
          }
        )

        return subnetsWithVPCs.map(subnetWithVPCs => {
          return [interfaze, ...subnetWithVPCs]
        })
      }
    )
  }

  renderRow = (rowData, i) => {
    const [interfaze, subnet, vpc] = rowData
    return (
      <div key={`rowData-${i}`}>
        <div style={styles.rowDataCell}>
          <TextLink
            color="primary"
            onClick={() => this.props.handleClick(interfaze.srn)}
          >
            {interfaze.name}
          </TextLink>
        </div>

        <div style={styles.rowArrowCell}>
          <Icon fa name="arrowRight" />
        </div>
        <div style={styles.rowDataCell}>
          <TextLink
            color="primary"
            onClick={() => this.props.handleClick(subnet.srn)}
          >
            {subnet.name}
          </TextLink>
        </div>
        <div style={styles.rowArrowCell}>
          <Icon fa name="arrowRight" />
        </div>
        <div style={styles.rowDataCell}>
          <TextLink
            color="primary"
            onClick={() => this.props.handleClick(vpc.srn)}
          >
            {vpc.name}
          </TextLink>
        </div>
      </div>
    )
  }

  renderCardBody = () => {
    if (this.props.isLoading) {
      return <SquareLoadingAnimation />
    }
    const rowData = this.getRowData()
    return <div>{rowData.map(this.renderRow)}</div>
  }

  render() {
    return (
      <BorderedCard style={styles.card}>
        <TopTitle style={styles.title}>
          <DynamicFormattedMessage {...messages.networkConnection} />
        </TopTitle>
        <CardBody style={styles.cardBody}>{this.renderCardBody()}</CardBody>
      </BorderedCard>
    )
  }
}

NetworkConnectionsWidget.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  computeContent: PropTypes.object,
}

export default NetworkConnectionsWidget
