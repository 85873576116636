import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import CopyToClipBoard from 'components/CopyToClipboard'
import JsonView from 'components/JsonView'

import messages from './messages'

const TombstoneInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 132px auto;
  margin-bottom: 16px;
  row-gap: 4px;

  & .label {
    font-weight: 500;
  }
`

const PolicyContainer = styled.div`
  border: 1px solid #dddddd;
  margin-top: 8px;
  padding: 8px;
  position: relative;

  & button {
    margin: 0 !important;
  }
`

export default function Step2(props) {
  const [policyJSON, setPolicyJSON] = useState()

  useEffect(() => {
    if (!props.selectedBot) {
      props.prevStep()
    }
  }, [props.selectedBot])
  if (!props.selectedBot) {
    return null
  }

  return (
    <div>
      <p>
        <DynamicFormattedMessage {...messages.step2Instructions} />
      </p>
      <TombstoneInfoContainer>
        <div className="label">
          <DynamicFormattedMessage {...messages.botTitleLabel} />
        </div>
        <div>{props.selectedBot.title}</div>
        <div className="label">
          <DynamicFormattedMessage {...messages.botTypeLabel} />
        </div>
        <div>{props.selectedBot.type}</div>
        <div className="label">
          <DynamicFormattedMessage {...messages.botDescriptionLabel} />
        </div>
        <div>{props.selectedBot.description}</div>
      </TombstoneInfoContainer>
      <div>
        {props.selectedBot.blob !== null && (
          <Fragment>
            <DynamicFormattedMessage {...messages.step2PolicyLabel} />
            <PolicyContainer>
              <CopyToClipBoard
                style={{ margin: 0, position: 'absolute', top: 0, right: 0 }}
                value={policyJSON}
              />
              <JsonView
                value={props.selectedBot.blob}
                onStringify={setPolicyJSON}
              />
            </PolicyContainer>
          </Fragment>
        )}
      </div>
    </div>
  )
}

Step2.propTypes = {
  prevStep: PropTypes.func.isRequired,
  selectedBot: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    blob: PropTypes.any,
  }),
}
