import React from 'react'
import PropTypes from 'prop-types'
import CenterContent from 'components/CenterContent'
import Icon from 'components/Icon'

export const EmptySection = ({ children, icon, style }) => {
  const styles = {
    text: {
      fontSize: '1.5em',
      fontWeight: 'bold',
      color: '#aaa',
    },
  }

  return (
    <CenterContent style={{ ...styles.text, ...style }}>
      <div>
        {icon && (typeof icon === 'string' ? <Icon fa name={icon} /> : icon)}
        <div>{children}</div>
      </div>
    </CenterContent>
  )
}

EmptySection.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
}

export default EmptySection
