import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectIdentityNodeViewCardLayoutDomain = state =>
  state.get('identityNodeViewCardLayout', Map())

export const selectIdentityContent = createSelector(
  selectIdentityNodeViewCardLayoutDomain,
  state => state.get('identityContent') || Map()
)

export const selectIsLoading = createSelector(
  selectIdentityNodeViewCardLayoutDomain,
  state => state.get('isLoading')
)
