import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import qs from 'query-string'
import {
  getNameFromSrn,
  getNodeViewPushParams,
  getSwimlaneNameFromSrn,
} from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'

import messages from '../messages'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Badge from 'components/Badge'
import CloudAccount from 'components/CloudAccount'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'

class TicketDetailsHeaderProperties extends Component {
  style = {
    container: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto auto 1fr',
    },

    property: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0rem 1rem',
    },
    label: {
      fontSize: '1rem',
      fontWeight: '400',
      margin: '0rem 0rem 0.5rem 0rem',
    },
    value: {
      margin: '0.5rem 0rem 0rem 0rem',
    },
    accountLink: {
      fontSize: '0.9rem',
    },
  }

  getAlertLevel = severityCategory => {
    if (!severityCategory) {
      return null
    }
    const getBackgroundColor = severity => {
      switch (severity) {
        case 'HIGH':
          return this.props.theme.fail
        case 'MEDIUM':
          return this.props.theme.warn
        case 'LOW':
          return this.props.theme.info
      }
    }
    return (
      <Badge
        style={{
          padding: '0.5rem 1.5rem',
          backgroundColor: getBackgroundColor(severityCategory),
        }}
      >
        {_.startCase(severityCategory.toLowerCase())}
      </Badge>
    )
  }

  getResourceLink = resource => {
    if (Object.keys(resource).length === 0) {
      // is it an empty object, e.g. no resource for ticket
      return <DynamicFormattedMessage {...messages.na} />
    }
    const { name, friendlyName, srn } = resource
    const displayName = name || friendlyName || getNameFromSrn(srn)
    return (
      <TextLink
        style={{ fontSize: '0.9rem' }}
        color="primary"
        to={getNodeViewPushParams(srn, getTypeFromSrn(srn))}
        newTab
      >
        {displayName}
      </TextLink>
    )
  }

  getAccount = ticket => {
    if (ticket.account) {
      return (
        <div style={this.style.accountLink}>
          <TextLink color="primary">
            <CloudAccount hasLink accountSrn={ticket.account} />
          </TextLink>
        </div>
      )
    }
    const { resource } = ticket
    if (!resource) {
      return <DynamicFormattedMessage {...messages.na} />
    }
    return (
      <div style={this.style.accountLink}>
        <TextLink color="primary">
          <CloudAccount hasLink accountId={resource.account} />
        </TextLink>
      </div>
    )
  }

  getSwimlanes = ticket => {
    let swimlaneSRNs = []
    if (ticket.resource && Object.keys(ticket.resource).length > 0) {
      swimlaneSRNs = ticket.resource.swimlaneSRNs ?? []
    } else if (ticket.swimlaneSRNs) {
      swimlaneSRNs = ticket.swimlaneSRNs
    }
    return (
      <div>
        {swimlaneSRNs.map((srn, index) => {
          const name = getSwimlaneNameFromSrn(srn, this.props.swimlanes)
          const path = {
            pathname: '/App/SwimlaneDetails',
            search: qs.stringify({
              srn,
            }),
          }
          return (
            <Badge
              key={index}
              style={
                index !== 0
                  ? {
                      padding: '0.5rem 1.5rem',
                      margin: '0.25rem',
                      backgroundColor: this.props.theme.neutralMedium,
                    }
                  : {
                      padding: '0.5rem 1.5rem',
                      margin: '0.25rem',
                      backgroundColor: this.props.theme.neutralMedium,
                    }
              }
            >
              <TextLink style={{ color: '#fff' }} newTab to={path}>
                {name || srn}
              </TextLink>
            </Badge>
          )
        })}
      </div>
    )
  }

  render() {
    const { ticket } = this.props
    return (
      <div style={this.style.container}>
        <div style={this.style.property}>
          <div style={this.style.label}> Severity </div>
          <div>{this.getAlertLevel(ticket.severityCategory)}</div>
        </div>
        <div style={this.style.property}>
          <div style={this.style.label}> Resource </div>
          <div>{this.getResourceLink(ticket.resource)}</div>
        </div>
        <div style={this.style.property}>
          <div style={this.style.label}>Account</div>
          <div>{this.getAccount(ticket)}</div>
        </div>
        <div style={this.style.property}>
          <div style={this.style.label}> Type </div>
          <div>{ticket.ticketType || '-'}</div>
        </div>
        <div style={this.style.property}>
          <div style={this.style.label}> Swimlane(s) </div>
          <div>{this.getSwimlanes(ticket)}</div>
        </div>
      </div>
    )
  }
}

TicketDetailsHeaderProperties.propTypes = {
  theme: themeShape,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  ticket: PropTypes.object.isRequired,
}

export default themeable(TicketDetailsHeaderProperties)
