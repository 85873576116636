import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectDataClassificationDomain = state =>
  state.get('dataClassification') || Map()

export const selectPrivateKey = createSelector(
  selectDataClassificationDomain,
  dcDomain => dcDomain.get('privateKey')
)

export const selectSubmitJob = createSelector(
  selectDataClassificationDomain,
  dcDomain => dcDomain.get('submitJob')
)

export const selectPlatformAccounts = createSelector(
  selectDataClassificationDomain,
  dcDomain => dcDomain.get('platformAccounts')
)
