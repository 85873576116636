import { defineMessages } from 'react-intl'

export default defineMessages({
  dataLoadError: {
    id: 'app.container.NodeViewMiniPopout.dataLoadError',
    defaultMessage: 'An error ocurred loading the data. Click here to close.',
  },
  generalProperties: {
    id: 'app.container.NodeViewMiniPopout.generalProperties',
    defaultMessage: 'Details',
  },
  gotoFullNodeView: {
    id: 'app.container.NodeViewMiniPopout.gotoFullNodeView',
    defaultMessage: 'Go to full node view',
  },
})
