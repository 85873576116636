import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import JSEncrypt from 'jsencrypt'

import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'
import TextLink from 'components/TextLink'
import AddDecryptionKeyModal from './AddDecryptionKeyModal'

import { isBase64Encoded, isClassificationSupported } from './utils'
import messages from './messages'

class ClassificationDataView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addPrivateKeyModalOpen: false,
      decrypter: null,
    }
  }

  componentDidMount() {
    if (this.state.decrypter == null && this.props.privateKey) {
      const decrypter = new JSEncrypt()
      decrypter.setPrivateKey(this.props.privateKey)
      this.setState({ decrypter })
    }
  }

  componentDidUpdate(prevProps) {
    // if we got a new private key, create a new instance of decrypter
    if (
      this.props.privateKey &&
      this.props.privateKey !== prevProps.privateKey
    ) {
      const decrypter = new JSEncrypt()
      decrypter.setPrivateKey(this.props.privateKey)
      this.setState({ decrypter })
    }
  }

  openAddPrivateKeyModal = () => {
    this.setState({ addPrivateKeyModalOpen: true })
  }

  closeAddPrivateKeyModal = () => {
    this.setState({ addPrivateKeyModalOpen: false })
  }

  /**
   * Classifications look like:
   * <classifier>.<sampleType (sample/count)>=<sample>
   * eg: EMAIL.sample=chafferty@eprime.com
   */
  desconstructSample = classifcation => {
    const segments = classifcation.split('=')
    const [classifier, sampleType] = segments.shift().split('.')
    const sample = segments.join('=')
    return { classifier, sampleType, sample }
  }

  getTableRows = () => {
    return this.getSamples()
      .filter(({ sampleType }) => 'sample' === sampleType)
      .map(value => _.pick(value, ['classifier', 'sample']))
  }

  getTableRowsForDataContainer = () => {
    return this.getSamplesForDataContainer()
      .filter(({ sampleType }) => 'sample' === sampleType)
      .toJS()
  }

  getSamples = () => {
    const { classificationSet } = this.props.nodeData
    if (!classificationSet) {
      // shouldn't happen, but good to be safe in case proptypes ignored
      return []
    }

    return classificationSet.map(this.desconstructSample)
  }

  isSampleEncoded = sample => {
    // 100 is abritrary, but we know our base64 strings are more than 100 and
    // most names or other samples are < 100 chars
    if (sample && sample.length > 100) {
      if (isBase64Encoded(sample)) {
        return true
      }
    }
    return false
  }

  /**
   * TODO - sometime down the line we SHOULD push for some identifier to be
   * added to data classificaton samples that show if they are encoded or not
   * so the method isSampleEncoded can be reimplemeneed
   *
   * also this method is O(n) for the rows in the table which is annoying
   * that we have to just scan it. What if there were a lot of rows of samples
   * with really long email addresses, we'd regex match them all and it would
   * take all month for this method to run.
   */
  isSamplesBase64Encoded = tableRows => {
    const base64 = tableRows.find(({ sample }) => this.isSampleEncoded(sample))
    if (base64) {
      return true
    }
    return false
  }

  decryptSample = sample => {
    if (!this.isSampleEncoded(sample)) {
      return sample
    }
    try {
      const decryptedSample = this.state.decrypter.decrypt(sample)
      const deConstructedSample = this.desconstructSample(decryptedSample)
      if (deConstructedSample.sample) {
        return deConstructedSample.sample
      }
      // for some unknown reason the deconstructed value isn't in the right
      // format so just return whatever was encrypted
      return decryptedSample
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('There was an error decrypting sample ' + sample, e)
      return 'Unable to decrypt sample.'
    }
  }

  decryptSamples = tableRows => {
    return tableRows.map(row => ({
      ...row,
      sample: this.decryptSample(row.sample),
    }))
  }

  shouldRenderCountsOnlyView = () => {
    const allSamples = this.getSamples()

    if (allSamples.length === 0) {
      return false
    }

    const nonSampleSample = allSamples.find(
      ({ sampleType }) => 'sample' === sampleType
    )

    return nonSampleSample === undefined
  }

  renderSamplesTable = (tableRows, samplesAreEncrypted) => {
    return (
      <div
        style={{
          height: 'calc(100vh - 400px)',
          minHeight: '800px',
        }}
      >
        <DataTable
          data={tableRows}
          customColumnConfig={{
            sample: {
              headerName: `Samples ${samplesAreEncrypted ? '(Encrypted)' : ''}`,
            },
            classifier: {
              cellRendererFramework: params => {
                if (params.value) {
                  if (!messages[`dataTableClassifierName_${params.value}`]) {
                    return params.value
                  }
                  return (
                    <DynamicFormattedMessage
                      {...messages[`dataTableClassifierName_${params.value}`]}
                    />
                  )
                } else {
                  return <span />
                }
              },
            },
          }}
        />
      </div>
    )
  }

  renderCountsOnlyView = () => {
    const countSamples = this.getSamples().filter(
      ({ sampleType }) => 'count' === sampleType
    )

    const lis = countSamples.map(({ sample, classifier }) => {
      return (
        <li key={'classifier-count-' + classifier}>
          <span>
            <DynamicFormattedMessage
              defaultMessage={classifier}
              {...messages[`dataTableClassifierName_${classifier}`]}
            />
          </span>
          <span> - {sample}</span>
        </li>
      )
    })

    return (
      <div>
        <ul>{lis}</ul>
      </div>
    )
  }

  render() {
    let tableRows = null
    let samplesAreEncrypted = false
    let renderCountsOnlyView = this.shouldRenderCountsOnlyView()

    // if the user has samples, init data for table
    if (!renderCountsOnlyView) {
      tableRows = this.getTableRows()
      samplesAreEncrypted = this.isSamplesBase64Encoded(tableRows)
      if (samplesAreEncrypted && this.props.privateKey) {
        tableRows = this.decryptSamples(tableRows)
      }
    }

    return (
      <div>
        <AddDecryptionKeyModal
          open={this.state.addPrivateKeyModalOpen}
          close={this.closeAddPrivateKeyModal}
          setPrivateKey={this.props.setPrivateKey}
        />
        <div style={{ display: 'grid', gridTemplateColumns: 'auto 225px' }}>
          <div
            className="classification-results-heading-content"
            style={{ marginBottom: '12px' }}
          >
            <SectionHeader>
              <DynamicFormattedMessage
                {...messages.dataClassificationTableSectionHeading}
              />
            </SectionHeader>
            <DynamicFormattedMessage
              {...(renderCountsOnlyView
                ? messages.dataClassificationNoSamplesText
                : messages.dataClassificationTableHeadingText)}
            />
          </div>
          <div className="classification-results-heading-content">
            <div
              style={{
                textAlign: 'right',
                paddingTop: '8px',
              }}
            >
              {isClassificationSupported(this.props.nodeData) && (
                <TextLink color="primary" onClick={this.props.openFormView}>
                  <DynamicFormattedMessage
                    {...messages.scheduleAnotherClassification}
                  />
                </TextLink>
              )}
            </div>
            {!renderCountsOnlyView && samplesAreEncrypted && (
              <div
                style={{
                  textAlign: 'right',
                  paddingTop: '8px',
                }}
              >
                <TextLink color="primary" onClick={this.openAddPrivateKeyModal}>
                  <DynamicFormattedMessage
                    {...messages.suggestUnencryptionLinkMessage}
                  />
                </TextLink>
              </div>
            )}
          </div>
        </div>
        {!renderCountsOnlyView &&
          this.renderSamplesTable(tableRows, samplesAreEncrypted)}
        {renderCountsOnlyView && this.renderCountsOnlyView()}
      </div>
    )
  }
}

ClassificationDataView.propTypes = {
  dataContainerContent: ImmutablePropTypes.contains({
    contains: ImmutablePropTypes.contains({
      srn: PropTypes.string,
      items: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
          classificationSet: ImmutablePropTypes.listOf(PropTypes.string),
        })
      ),
    }),
  }),
  nodeData: PropTypes.shape({
    classificationSet: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.string.isRequired,
    __typename: PropTypes.string.isRequired,
  }).isRequired,
  openFormView: PropTypes.func.isRequired,
  privateKey: PropTypes.string,
  setPrivateKey: PropTypes.func.isRequired,
}

export default ClassificationDataView
