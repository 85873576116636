/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import img from 'assets/iamghost.png'
import messages from './messages'

export default class NotFound extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ marginTop: '5em', fontSize: '18px', fontWeight: '500' }}>
          <FormattedMessage {...messages.NotFound} />
        </div>
        <div>
          <img
            src={img}
            style={{ width: '400px', height: '300px', marginLeft: '5em' }}
          />
        </div>
      </div>
    )
  }
}
