import {
  FETCH_CUSTOM_CLASSIFIERS,
  SET_CUSTOM_CLASSIFIERS,
  SET_CUSTOM_CLASSIFIERS_ERROR,
  CREATE_CUSTOM_CLASSIFIER,
  CREATE_CUSTOM_CLASSIFIER_SUCCESS,
  CREATE_CUSTOM_CLASSIFIER_ERROR,
  UPDATE_CUSTOM_CLASSIFIERS,
  UPDATE_CUSTOM_CLASSIFIERS_SUCCESS,
  UPDATE_CUSTOM_CLASSIFIERS_ERROR,
  DELETE_CUSTOM_CLASSIFIERS,
  DELETE_CUSTOM_CLASSIFIERS_SUCCESS,
  DELETE_CUSTOM_CLASSIFIERS_ERROR,
} from './constants'
import { createAction } from 'redux-actions'

export const fetchCustomClassifiers = createAction(FETCH_CUSTOM_CLASSIFIERS)
export const setCustomClassifiers = createAction(SET_CUSTOM_CLASSIFIERS)
export const setCustomClassifiersError = createAction(
  SET_CUSTOM_CLASSIFIERS_ERROR
)

export const createCustomClassifier = createAction(CREATE_CUSTOM_CLASSIFIER)
export const createCustomClassifierSuccess = createAction(
  CREATE_CUSTOM_CLASSIFIER_SUCCESS
)
export const createCustomClassifierError = createAction(
  CREATE_CUSTOM_CLASSIFIER_ERROR
)

export const updateCustomClassifiers = createAction(UPDATE_CUSTOM_CLASSIFIERS)
export const updateCustomClassifiersSuccess = createAction(
  UPDATE_CUSTOM_CLASSIFIERS_SUCCESS
)
export const updateCustomClassifiersError = createAction(
  UPDATE_CUSTOM_CLASSIFIERS_ERROR
)

export const deleteCustomClassifier = createAction(DELETE_CUSTOM_CLASSIFIERS)
export const deleteCustomClassifierSuccess = createAction(
  DELETE_CUSTOM_CLASSIFIERS_SUCCESS
)
export const deleteCustomClassifierError = createAction(
  DELETE_CUSTOM_CLASSIFIERS_ERROR
)
