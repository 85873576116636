import React from 'react'
import Button from 'components/Button'
import Icon from 'components/Icon'
import CenterContent from 'components/CenterContent'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import BorderedCard from 'components/BorderedCard'
import TextLink from 'components/TextLink'
import Expandable from 'components/Expandable'
import { SUPPORT_EMAIL } from 'appConstants'

export class NewAccountState extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      titleContainer: {
        marginTop: '3em',
      },
      welcome: {
        marginTop: 0,
      },
      card: {
        margin: '3em',
      },
      mainText: {
        fontSize: '1.2em',
      },
      paragraph: {
        maxWidth: '800px',
      },
      tip: {
        color: props.theme.neutralDark,
        maxWidth: '700px',
        margin: '1em auto',
      },
      mainButtonWrapper: {
        marginTop: '2em',
      },
      details: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridColumnGap: '5em',
        padding: '3em',
      },
      detailsIcon: {
        marginBottom: '2em',
      },
    }
  }
  render() {
    return (
      <div>
        <CenterContent>
          <div style={this.styles.titleContainer}>
            <h3 style={this.styles.welcome}>
              Welcome to <strong>Sonrai Security</strong>
            </h3>
            <h3 style={this.styles.welcome}>
              Your Cloud Data Control (CDC) account is ready to go!
            </h3>
          </div>
        </CenterContent>
        <BorderedCard style={this.styles.card}>
          <CenterContent>
            <p style={this.styles.mainText}>
              The next step is to <strong>deploy a collector</strong> into your
              cloud and add your cloud accounts to it for monitoring
            </p>
            <div>
              <Expandable>
                <p>
                  Once the collector deploy is complete and you’ve added
                  accounts, it will automatically begin collecting information
                  from your cloud accounts and you should see updates within
                  about 15 minutes.
                </p>
                <p>
                  CDC intelligence collection is divided into multiple
                  components: an initial discovery pass collects high level
                  resource information from the account and a background
                  discovery process collects lower level details from those
                  resources. At the same time, it will subscribe to and report
                  current audit activity, while another background component
                  gathers the last 7 days of audit history.
                </p>

                <p style={this.styles.tip}>
                  <Icon fa name="lightbulb" color={this.props.theme.emphasis} />
                  &nbsp; Many users begin by adding small test, sandbox or
                  development accounts to familiarize themselves with the CDC
                  service and then add more complex, production accounts over
                  time as desired.
                </p>
              </Expandable>
            </div>

            <p style={this.styles.mainButtonWrapper}>
              <Button
                color="primary"
                to={{
                  pathname: '/App/Deploy',
                }}
              >
                Configure Collectors
              </Button>
            </p>
          </CenterContent>
        </BorderedCard>

        <div style={this.styles.details}>
          <CenterContent horizontalOnly>
            <p style={this.styles.detailsIcon}>
              <Icon
                fa
                name="clipboard-list-check"
                color={this.props.theme.neutralMedium}
                size="3x"
              />
            </p>

            <p>
              <strong>Installation instructions</strong> for supported cloud
              platforms are available in the{' '}
              <TextLink to={{ pathname: '/App/HelpCenter' }}>
                Knowledge Base
              </TextLink>
              , which provide walkthroughs for deploying collectors.
            </p>
          </CenterContent>

          <CenterContent horizontalOnly>
            <p style={this.styles.detailsIcon}>
              <Icon
                fa
                name="user-headset"
                color={this.props.theme.neutralMedium}
                size="3x"
              />
            </p>
            <p>
              If you have questions at any time during the setup & configuration
              process or would like to schedule assistance to get your
              collectors deployed and configured, reach out to us via email at{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
            </p>
          </CenterContent>

          <CenterContent horizontalOnly>
            <p style={this.styles.detailsIcon}>
              <Icon
                fa
                name="map-signs"
                color={this.props.theme.neutralMedium}
                size="3x"
              />
            </p>
            <p>
              For a quick overview of other areas of the CDC Service, take a
              look at the <strong>Getting Started Guide</strong> in the{' '}
              <TextLink to={{ pathname: '/App/HelpCenter' }}>
                Knowledge Base
              </TextLink>
              .
            </p>
          </CenterContent>
        </div>
      </div>
    )
  }
}

NewAccountState.propTypes = {
  theme: themeShape,
}

export default themeable(NewAccountState)
