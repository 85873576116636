import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import qs from 'query-string'

import TextLink from 'components/TextLink'
import { getTicketId } from 'utils/sonraiUtils'
import Ticket from 'containers/TicketDetails/Ticket'

const TicketDetails = ({
  allTickets,
  ticketSrn,
  closeDetails,
  setActiveTicketSrn,
}) => {
  if (!ticketSrn) {
    return null
  }

  const currIndex = allTickets.findIndex(t => t.srn === ticketSrn)
  if (currIndex < 0) {
    return null
  }

  const isFirst = currIndex === 0
  const isLast = currIndex === allTickets.length - 1

  const prevTicket = () => {
    if (!isFirst) {
      setActiveTicketSrn(allTickets[currIndex - 1].srn)
    }
  }

  const nextTicket = () => {
    if (!isLast) {
      setActiveTicketSrn(allTickets[currIndex + 1].srn)
    }
  }

  return (
    <Modal
      isOpen={true}
      toggle={closeDetails}
      size="lg"
      style={{
        width: '85vw',
        maxWidth: '85vw',
      }}
    >
      <ModalHeader toggle={closeDetails}>
        Ticket #{getTicketId(allTickets[currIndex].resourceId)}{' '}
        <TextLink
          to={{
            pathname: '/App/TicketDetails',
            search: qs.stringify({
              srn: ticketSrn,
            }),
          }}
        >
          (Go to full Ticket Details)
        </TextLink>
      </ModalHeader>
      <ModalBody
        style={{
          maxHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <Ticket ticketSrn={ticketSrn} key={ticketSrn} />
      </ModalBody>
      <ModalFooter>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <TextLink disabled={isFirst} color="primary" onClick={prevTicket}>
            Prev
          </TextLink>
          <div style={{ margin: '0rem 0.5rem' }}>
            {currIndex + 1} of {allTickets.length} Tickets
          </div>
          <TextLink disabled={isLast} color="primary" onClick={nextTicket}>
            Next
          </TextLink>
        </div>
      </ModalFooter>
    </Modal>
  )
}

TicketDetails.propTypes = {
  allTickets: PropTypes.array.isRequired,
  closeDetails: PropTypes.func,
  ticketSrn: PropTypes.string.isRequired,
  setActiveTicketSrn: PropTypes.func.isRequired,
}

export default TicketDetails
