import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectSubscriptions } from 'containers/SonraiData/selectors'
import { Map } from 'immutable'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import TextLink from 'components/TextLink'
import Hoverable from 'components/Hoverable'
import CopyToClipboard from 'components/CopyToClipboard'
import ReactTooltip from 'react-tooltip'
import uuid from 'uuid/v4'

class Subscription extends React.PureComponent {
  constructor(props) {
    super(props)
    this.tooltipId = uuid()
  }

  styles = {
    clipboardButton: {
      marginBottom: '5px',
      width: '32px',
    },
    textStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '30px',
      marginTop: '-3px',
    },
  }

  getTooltop = srn => {
    return (
      <ReactTooltip
        id={this.tooltipId}
        place="bottom"
        effect="solid"
        clickable={true}
      >
        <span>{srn}</span>
      </ReactTooltip>
    )
  }

  render() {
    const subscription = this.props.subscriptions.find(
      sub => sub.get('srn').includes(this.props.subscriptionId),
      Map()
    )
    let name = this.props.subscriptionId

    if (subscription) {
      name = subscription.get('friendlyName') || subscription.get('name') || '-'
    }

    return (
      <div style={this.styles.container}>
        <div>
          {subscription ? (
            <Hoverable
              hoverStyle={{ cursor: 'default' }}
              renderContent={({ hovered }) => (
                <div style={this.styles.textStyle}>
                  <div data-tip data-for={this.tooltipId}>
                    <TextLink
                      to={getNodeViewPushParams(
                        subscription.get('srn'),
                        getTypeFromSrn(subscription.get('srn'))
                      )}
                    >
                      {name}
                    </TextLink>
                  </div>
                  {hovered ? (
                    <div style={this.styles.clipboardButton}>
                      <CopyToClipboard value={this.props.subscriptionId} />
                    </div>
                  ) : (
                    <div style={this.styles.clipboardButton} />
                  )}
                </div>
              )}
            />
          ) : (
            `${this.props.subscriptionId}`
          )}
          {this.getTooltop(this.props.subscriptionId)}
        </div>
      </div>
    )
  }
}

Subscription.propTypes = {
  subscriptions: ImmutablePropTypes.iterable,
  subscriptionId: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  subscriptions: selectSubscriptions,
})

const withConnect = connect(mapStateToProps)

export default withConnect(Subscription)
