import React from 'react'
import PropTypes from 'prop-types'

import TextLink from 'components/TextLink'
import Icon from 'components/Icon'

export const ToolbarItem = ({
  iconName,
  onClick,
  children,
  disabled,
  spin,
  color,
}) => {
  const styles = {
    toolbarButton: {
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: '1em',
    },
    iconStyle: {
      marginRight: '0.3em',
      fontSize: '1em',
      color: color ? color : undefined,
    },
  }

  return (
    <TextLink
      color={color ? color : 'primary'}
      style={styles.toolbarButton}
      onClick={onClick}
      disabled={disabled}
    >
      {iconName && (
        <Icon fa name={iconName} style={styles.iconStyle} spin={spin} />
      )}
      {children}
    </TextLink>
  )
}

ToolbarItem.propTypes = {
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  spin: PropTypes.bool,
  color: PropTypes.string,
}
export default ToolbarItem
