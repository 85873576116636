import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

import Hoverable from 'components/Hoverable'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import InlineEdit from 'components/InlineEdit'
import CopyToClipboard from 'components/CopyToClipboard'
import ReactTooltip from 'react-tooltip'

export class InlineEditableText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      text: this.props.value || '',
    }
    this.container = React.createRef()
    this.input = React.createRef()

    this.styles = {
      container: {
        width: '100%',
        maxWidth: '800px',
      },
      display: {
        cursor: 'text',
        height: '42px',
        padding: '5px 5px',
        fontSize: '1rem',
        width: '100%',
        display: 'flex',
        overflowY: 'hidden',
      },
      inputStyle: {
        fontWeight: 300,
        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
        maxWidth: '800px',
        ...props.style,
        ...props.inputStyle,
      },
      hoverStyle: {
        borderRadius: '3px',
        backgroundColor: props.theme.neutralLight,
      },
      copy: {
        marginTop: '-3px',
      },
      stopBeingBig: {
        maxWidth: '800px',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        flex: '1 0 86%',
        paddingLeft: '1px',
      },
      inactiveText: {
        marginLeft: '0.5rem',
        fontSize: '0.9rem',
        fontWeight: '500',
        letterSpacing: '1.2px',
        color: props.theme.red,
        backgroundColor: '#FFF',
        padding: '3px 5px',
        borderRadius: '0.8rem',
        textTransform: 'uppercase',
        height: '26px',
        alignSelf: 'center',
      },
      error: {
        fontSize: '0.8rem',
        fontWeight: '400',
        margin: '0.25rem 0rem 0.5rem 0.25rem',
        color: props.theme.red,
      },
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value) {
      this.setState({ text: this.props.value })
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick = e => {
    if (this.props.isNodeView) {
      if (!this.container.current.contains(e.target)) {
        this.hideErrorMessage()
      }
    }
  }

  hideErrorMessage = () => this.setState({ error: false })

  onChange = e => {
    const text = this.props.trim
      ? e.target.value.replace(/ /g, '')
      : e.target.value
    this.setState({
      text: text,
    })
  }

  onKeyDown = e => {
    if (e.key === 'Escape') {
      this.cancelEditing()
    }
  }

  setEditing = () => {
    if (this.props.isNodeView) {
      if (this.props.canEdit) {
        this.setState({
          editing: true,
        })
      } else {
        this.setState({ error: true })
      }
    } else {
      this.setState({
        editing: true,
      })
    }
  }

  cancelEditing = () => {
    this.setState({
      editing: false,
      text: this.props.value,
    })
  }

  onSubmit = () => {
    this.setState({
      editing: false,
    })
    if (this.state.text !== this.props.value) {
      this.props.onSubmit(this.state.text)
    }
  }

  getTooltip = text => {
    return (
      <ReactTooltip id={'buh'} place="bottom" effect="solid" clickable={true}>
        {text}
      </ReactTooltip>
    )
  }

  getEditInputStyle = () => {
    if (this.props.isNodeView) {
      return {
        ...this.styles.inputStyle,
        ...{
          width:
            this.input && this.input.current && this.input.current.offsetWidth
              ? `${this.input.current.offsetWidth}px`
              : 'auto',
        },
      }
    }
    return this.styles.inputStyle
  }

  render() {
    if (this.state.editing || this.props.saving) {
      return (
        <InlineEdit onSubmit={this.onSubmit}>
          <Input
            style={this.getEditInputStyle()}
            type="text"
            value={this.state.text}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onKeyPress={e => e.key === 'Enter' && this.onSubmit()}
            placeholder={this.props.placeholder}
            autoFocus
            disabled={this.props.saving}
          />
        </InlineEdit>
      )
    }

    if (this.props.activeNode === false) {
      return (
        <div style={{ display: 'flex', ...this.styles.container }}>
          <Hoverable
            style={this.styles.staticHov}
            hoverStyle={this.styles.hoverStyle}
            renderContent={({ hovered }) => (
              <div
                style={{ ...this.styles.display, ...this.props.style }}
                onClick={this.setEditing}
              >
                <div style={this.styles.stopBeingBig}>{this.props.value} </div>

                {hovered ? (
                  <div style={this.styles.copy}>
                    <CopyToClipboard value={this.state.text} />
                  </div>
                ) : null}
              </div>
            )}
          />
          <div style={this.styles.inactiveText}> Inactive </div>
        </div>
      )
    }

    return (
      <div ref={this.container} style={this.styles.container}>
        <Hoverable
          style={this.styles.staticHov}
          hoverStyle={this.styles.hoverStyle}
          renderContent={({ hovered }) => (
            <div
              ref={this.input}
              style={{ ...this.styles.display, ...this.props.style }}
              onClick={this.setEditing}
            >
              <div style={this.styles.stopBeingBig}>
                {!this.props.value && this.props.placeholder
                  ? this.props.placeholder
                  : this.props.value}{' '}
              </div>
              {hovered ? (
                <div style={this.styles.copy}>
                  <CopyToClipboard
                    value={this.state.text}
                    tooltip={'Copy Name to Clipboard'}
                  />
                  {this.getTooltip(this.state.text)}
                </div>
              ) : null}
            </div>
          )}
        />
        {this.state.error && (
          <div style={this.styles.error}>Cannot edit this node type</div>
        )}
      </div>
    )
  }
}

InlineEditableText.defaultProps = {
  style: {},
  inputStyle: {},
  canEdit: true,
}

InlineEditableText.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  theme: themeShape,
  onSubmit: PropTypes.func,
  saving: PropTypes.bool,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  activeNode: PropTypes.bool,
  trim: PropTypes.bool,
  canEdit: PropTypes.bool,
  isNodeView: PropTypes.bool,
}

export default themeable(InlineEditableText)
