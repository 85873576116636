export const RULE_PROPS = `
  srn
  triggerAfter
  actionType
  assignUser {
    userSrn
  }
  assignRole {
    roleType: ActionRoleType
  }
  escalateToBot {
    botSrn
  }
`

export const FILTER_PROPS = `
  srn
  createdDate
  createdBy
  ticketType
  ticketKey
  allKeys
  allTypes
  controlFrameworkSrn
`

export const ASSIGNMENT_PROPS = `
  srn
  swimlaneSRN
  resourceId
`

export const GET_ESCALATIONS_QUERY = `
  query getEscalations {
    EscalationSchemes {
      items {
        srn
        title
        description
        createdBy
        createdDate
        resourceId
        assignments {
          ${ASSIGNMENT_PROPS}
        }
        filters {
          ${FILTER_PROPS}
        }
        rules {
          ${RULE_PROPS}
        }
      }
    }
  }
`

export const GET_FILTER_OPTIONS_QUERY = `
  query getOptions {
    getChangeDetectionOptions(resourceType: Data) {
      keyType
      keyName
      actionClassification
    }
  }
`

export const CREATE_ESCALATION_MUTATION = `
  mutation createEscalation($input: CreateEscalationSchemeRequestInput) {
    CreateEscalationScheme(input: $input) {
      srn
      title
      description
      createdBy
      createdDate
    }
  }
`

export const CREATE_ESCALATION_ASSSIGNMENT_MUTATION = `
  mutation createEscalationAssignment($input: CreateEscalationSchemeAssignmentRequestInput) {
    CreateEscalationSchemeAssignment(input: $input) {
      ${ASSIGNMENT_PROPS}
    }
  }
`

export const DELETE_ESCALATION_ASSIGNMNET_MUTATION = `
  mutation deleteEscalationAssignment($input: EscalationDeleteRequestInput) {
    DeleteEscalationSchemeAssignment(input: $input) {
      srn
    }
  }
`

export const CREATE_ESCALATION_RULE_MUTATION = `
  mutation createEscalationRule($input: CreateEscalationRuleRequestInput) {
    CreateEscalationRule(input: $input) {
      ${RULE_PROPS}
    }
  }
`

export const DELETE_ESCALATION_RULE_MUTATION = `
  mutation deleteEscalationRule($input: EscalationDeleteRequestInput) {
    DeleteEscalationRule(input: $input) {
      srn
    }
  }
`

export const CREATE_ESCALATION_FILTER_MUTATION = `
  mutation createEscalationFilter($input: CreateEscalationFilterRequestInput) {
    CreateEscalationFilter(input: $input) {
      ${FILTER_PROPS}
    }
  }
`

export const DELETE_ESCALATION_FILTER_MUTATION = `
  mutation deleteEscalationFilter($input: EscalationDeleteRequestInput) {
    DeleteEscalationFilter(input: $input) {
      srn
    }
  }
`

export const DELETE_ESCALATION_MUTATION = `
  mutation deleteEscalation($input: EscalationDeleteRequestInput) {
    DeleteEscalationScheme(input: $input) {
      srn
    }
  }
`

export const UPDATE_ESCALATION_MUTATION = `
  mutation updateEscalationDetails($input: UpdateEscalationSchemeRequestInput) {
    UpdateEscalationScheme(input: $input) {
      srn
      title
      description
    }
  }
`

export const FETCH_TICKETS_BY_SRNS = `
query fetchTicketsBySrns ($srns: [String]) {
  Tickets(where: {
    srn: { op: IN_LIST values: $srns }
  }) {
    items {
      srn
      swimlaneSRNs
      title
    }
  }
}
`
