//Modified from react-graph-vis: https://github.com/crubier/react-graph-vis

import React from 'react'
import defaultsDeep from 'lodash/fp/defaultsDeep'
import isEqual from 'lodash/isEqual'
import differenceWith from 'lodash/differenceWith'
import vis from 'visjs-network'
import PropTypes from 'prop-types'

import './styles.css'
class VisNetwork extends React.Component {
  constructor(props) {
    super(props)

    this.container = React.createRef()
  }

  componentDidMount() {
    this.edges = new vis.DataSet()
    this.edges.add(this.props.graph.edges)
    this.nodes = new vis.DataSet()
    this.nodes.add(this.props.graph.nodes)
    this.updateGraph()
  }

  shouldComponentUpdate(nextProps) {
    let nodesChange = !isEqual(this.props.graph.nodes, nextProps.graph.nodes)
    let edgesChange = !isEqual(this.props.graph.edges, nextProps.graph.edges)
    let optionsChange = !isEqual(this.props.options, nextProps.options)
    let eventsChange = !isEqual(this.props.events, nextProps.events)

    if (nodesChange) {
      const idIsEqual = (n1, n2) => n1.id === n2.id
      const nodesRemoved = differenceWith(
        this.props.graph.nodes,
        nextProps.graph.nodes,
        idIsEqual
      )
      const nodesAdded = differenceWith(
        nextProps.graph.nodes,
        this.props.graph.nodes,
        idIsEqual
      )
      const nodesChanged = differenceWith(
        differenceWith(nextProps.graph.nodes, this.props.graph.nodes, isEqual),
        nodesAdded
      )
      this.patchNodes({ nodesRemoved, nodesAdded, nodesChanged })
    }

    if (edgesChange) {
      const edgesRemoved = differenceWith(
        this.props.graph.edges,
        nextProps.graph.edges,
        isEqual
      )
      const edgesAdded = differenceWith(
        nextProps.graph.edges,
        this.props.graph.edges,
        isEqual
      )
      const edgesChanged = differenceWith(
        differenceWith(nextProps.graph.edges, this.props.graph.edges, isEqual),
        edgesAdded
      )
      this.patchEdges({ edgesRemoved, edgesAdded, edgesChanged })
    }

    if (optionsChange) {
      this.Network.setOptions(nextProps.options)
    }

    if (eventsChange) {
      let events = this.props.events || {}
      for (let eventName of Object.keys(events))
        this.Network.off(eventName, events[eventName])

      events = nextProps.events || {}
      for (let eventName of Object.keys(events))
        this.Network.on(eventName, events[eventName])
    }

    return (
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width
    )
  }

  componentDidUpdate() {
    this.updateGraph()
  }

  patchEdges({ edgesRemoved, edgesAdded, edgesChanged }) {
    this.edges.remove(edgesRemoved)
    this.edges.add(edgesAdded)
    this.edges.update(edgesChanged)
  }

  patchNodes({ nodesRemoved, nodesAdded, nodesChanged }) {
    this.nodes.remove(nodesRemoved)
    this.nodes.add(nodesAdded)
    this.nodes.update(nodesChanged)
  }

  updateGraph = () => {
    let container = this.container.current

    if (!container) {
      return
    }

    let defaultOptions = {
      physics: {
        stabilization: false,
      },
      autoResize: false,
      edges: {
        smooth: false,
        color: '#000000',
        width: 0.5,
        arrows: {
          to: {
            enabled: true,
            scaleFactor: 0.5,
          },
        },
      },
    }

    // merge user provied options with our default ones
    let options = defaultsDeep(defaultOptions, this.props.options)

    this.Network = new vis.Network(
      container,
      Object.assign({}, this.props.graph, {
        edges: this.edges,
        nodes: this.nodes,
      }),
      options
    )

    if (this.props.getNetwork) {
      this.props.getNetwork(this.Network)
    }

    if (this.props.getNodes) {
      this.props.getNodes(this.nodes)
    }

    if (this.props.getEdges) {
      this.props.getEdges(this.edges)
    }

    // Add user provied events to network
    let events = this.props.events || {}
    for (let eventName of Object.keys(events)) {
      this.Network.on(eventName, events[eventName])
    }
  }

  render() {
    return (
      <div
        ref={this.container}
        style={{
          width: this.props.width,
          height: this.props.height,
        }}
      />
    )
  }
}

VisNetwork.defaultProps = {
  graph: {},
}
VisNetwork.propTypes = {
  events: PropTypes.object,
  graph: PropTypes.object,
  getNetwork: PropTypes.func,
  getNodes: PropTypes.func,
  getEdges: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.object,
}

export default VisNetwork
