/*
 * UserNodeViewCardLayout Messages
 *
 * This contains all the text for the UserNodeViewCardLayout component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  createSC: {
    id: 'app.containers.UserNodeViewCardLayout.createSC',
    defaultMessage: 'Create New Solution Card',
  },
  addWidget: {
    id: 'app.containers.UserNodeViewCardLayout.addWidget',
    defaultMessage: 'Add Widget',
  },
  saveCard: {
    id: 'app.containers.UserNodeViewCardLayout.saveCard',
    defaultMessage: 'Save Solution Card',
  },
  shareCard: {
    id: 'app.containers.UserNodeViewCardLayout.shareCard',
    defaultMessage: 'Share Solution Card',
  },
  dupeCard: {
    id: 'app.containers.UserNodeViewCardLayout.dupeCard',
    defaultMessage: 'Duplicate Solution Card',
  },
  exportCard: {
    id: 'app.containers.UserNodeViewCardLayout.exportCard',
    defaultMessage: 'Export as PDF',
  },
  passwordLastUsed: {
    id: 'app.containers.UserNodeViewCardLayout.exportCard',
    defaultMessage: 'Password Last Used',
  },
  activeLabel: {
    id: 'app.containers.UserNodeViewCardLayout.activeLabel',
    defaultMessage: 'Active',
  },
  typeLabel: {
    id: 'app.containers.UserNodeViewCardLayout.typeLabel',
    defaultMessage: 'Type',
  },
  accountLabel: {
    id: 'app.containers.UserNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id: 'app.containers.UserNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  group: {
    id: 'app.containers.UserNodeViewCardLayout.group',
    defaultMessage: 'Group',
  },
  lastModifiedLabel: {
    id: 'app.containers.UserNodeViewCardLayout.lastModifiedLabel',
    defaultMessage: 'Last Modified',
  },
  createdLabel: {
    id: 'app.containers.UserNodeViewCardLayout.createdLabel',
    defaultMessage: 'Created',
  },
  performed: {
    id: 'app.containers.UserNodeViewCardLayout.performed',
    defaultMessage: 'Performed',
  },
  groupsPolicies: {
    id: 'app.containers.UserNodeViewCardLayout.groupsPolicies',
    defaultMessage: 'Groups & Policies',
  },
  user: {
    id: 'app.containers.UserNodeViewCardLayout.user',
    defaultMessage: 'User',
  },
  MFADevices: {
    id: 'app.containers.UserNodeViewCardLayout.MFADevices',
    defaultMessage: 'MFA Devices',
  },
  owns: {
    id: 'app.containers.UserNodeViewCardLayout.owns',
    defaultMessage: 'Owns',
  },
  statusDays1: {
    id: 'app.containers.UserNodeViewCardLayout.statusDays1',
    defaultMessage: 'within the past 24 hours',
  },
  statusDays7: {
    id: 'app.containers.UserNodeViewCardLayout.statusDays7',
    defaultMessage: 'within the past 7 days',
  },
  statusDays30: {
    id: 'app.containers.UserNodeViewCardLayout.statusDays30',
    defaultMessage: 'within the past 30 days',
  },
  statusDays90: {
    id: 'app.containers.UserNodeViewCardLayout.statusDays90',
    defaultMessage: 'within the past 90 days',
  },
  attachedPolicies: {
    id: 'app.containers.UserNodeViewCardLayout.attachedPolicies',
    defaultMessage: 'Attached Policies',
  },
})
