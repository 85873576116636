/**
 *
 * ImageNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { injectIntl } from 'react-intl'
import { List } from 'immutable'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import TableWidget from 'components/TableWidget'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'

import messages from './messages'
import reducer from './reducer'
import sagas from './sagas'
import { getImageContent } from './actions'
import { selectImageContent, selectIsLoading } from './selectors'

export class ImageNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getImageContent(props.nodeData.srn)
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getComputes = () =>
    this.props.imageContent.getIn(['baseOf', 'items'], List()).toJS()

  getUsedIn = () => {
    return this.getComputes().map(compute => {
      const { launchTime, state, srn } = compute
      return {
        computeName: compute.ComputeName,
        launchTime,
        state,
        srn,
        networks: compute.isAttachedToNetwork.items
          .map(network => network.name)
          .join('\n'),
        subnets: compute.isAttachedToSubnet.items
          .map(subnet => subnet.name)
          .join('\n'),
        blockstorages: compute.isAttachedToBlockStorage.items
          .map(store => store.BlockStorageName)
          .join('\n'),
      }
    })
  }

  getColumns = () => {
    const numUsedIn = this.getUsedIn().length
    return {
      md: [
        {
          widthPercent: 50,
          items: ['computes'],
          heights: {
            computes: numUsedIn > 0 ? 2 : 1,
          },
        },
        {
          widthPercent: 50,
          items: ['graph'],
          heights: {
            graph: 3,
          },
        },
      ],
      xs: [
        {
          widthPercent: 100,
          items: ['computes', 'graph'],
          heights: {
            computes: numUsedIn > 0 ? 2 : 1,
            graph: 3,
          },
        },
      ],
    }
  }

  getGridContent = () => {
    return [
      <div key="computes">
        <TableWidget
          nodeViewType="Image"
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
          data={this.getUsedIn()}
          title={this.props.intl.formatMessage(messages.usedIn)}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.isLoading}
        />
      </div>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          columns={this.getColumns()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
        />
      </Fragment>
    )
  }
}

ImageNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  imageContent: ImmutablePropTypes.map.isRequired,
  getImageContent: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  userProfile: ImmutablePropTypes.map.isRequired,
  setUserProfileTables: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  userProfile: selectUserProfile,
  imageContent: selectImageContent,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getImageContent,
      setUserProfileTables,
      push,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({
  key: 'imageNodeViewCardLayout',
  reducer,
})

const withSaga = injectSaga({
  key: 'imageNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(ImageNodeViewCardLayout)
