import { all, put, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import gql from 'graphql-tag'
import { getClient } from 'apolloClient'

import { submitJobSuccess, submitJobError } from './actions'
import { SUBMIT_JOB } from './constants'

function* submitJob(action) {
  const client = yield getClient()
  try {
    yield new Promise(resolve => {
      setTimeout(() => resolve(), 1000)
    })

    const result = yield client.mutate({
      variables: {
        ..._.pick(action.payload, ['jobInfo', 'targetSrn']),
      },
      mutation: gql`
        mutation createDataClassificationJob(
          $targetSrn: String!
          $jobInfo: DataClassificationJobInfo!
        ) {
          CreateDataClassificationConfig(
            input: { targetSrn: $targetSrn, enabled: true, jobInfo: $jobInfo }
          ) {
            id
          }
        }
      `,
    })

    const path = ['data', 'CreateDataClassificationConfig', 'id']
    const id = _.get(result, path)
    if (!id) {
      throw 'The result did not return an id at ' + path.join('/')
    }
    yield put(submitJobSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('An error happened submitting job classification: ', e)
    yield put(submitJobError())
  }
}

function* dataClassificationSaga() {
  yield all([takeLatest(SUBMIT_JOB, submitJob)])
}

export default dataClassificationSaga
