import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import qs from 'query-string'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import TextLink from 'components/TextLink'
import DataTable from 'components/DataTable'
import { fetchTicketHistory } from 'containers/TicketDetailsData/actions'
import { selectTicketHistory } from 'containers/TicketDetailsData/selectors'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import Error from 'components/Error'
import UserWidget from 'components/UserWidget'
import BotWidget from 'components/BotWidget'
import SectionHeader from 'components/SectionHeader'

class TicketHistoryTab extends Component {
  constructor(props) {
    super(props)

    props.fetchTicketHistory({
      ticketSrn: props.ticket.get('srn'),
    })

    this.styles = {
      container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridGap: '1rem',
      },
      secondaryContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      content: {
        height: '350px',
      },
    }
  }

  formatEvent = ({ actionType }) => {
    if ('TICKET_BOT_EXECUTED' === actionType) {
      return 'Ticket Bot Submitted'
    } else {
      return _.startCase(_.lowerCase(actionType || '-'))
    }
  }

  getData = () => {
    const data = this.props.ticketHistory.get('data') || List()
    return data
      .sortBy(hist => hist.get('createdDate'))
      .reverse()
      .toJS()
      .map(item => {
        return {
          message: item.message,
          event: this.formatEvent(item),
          performedBy: item.actorSrn,
          succeeded: item.succeeded,
          date: item.createdDate,
          srn: item.srn,
        }
      })
  }

  render() {
    if (this.props.ticketHistory.get('error')) {
      return (
        <div style={this.styles.secondaryContainer}>
          <Error description={'Something went wrong...'} />
        </div>
      )
    }

    if (this.props.ticketHistory.get('loading')) {
      return (
        <div style={this.styles.secondaryContainer}>
          <SquareLoadingAnimation />
        </div>
      )
    }
    return (
      <div style={this.styles.container}>
        <SectionHeader small> Ticket History </SectionHeader>
        <DataTable
          fitAllRows
          data={this.getData()}
          autosize={false}
          customColumnConfig={{
            srn: {
              hide: true,
            },
            message: {
              flex: 1,
              minWidth: 200,
              cellRendererFramework: ({ data, value }) => {
                if (!data || !value) {
                  return null
                }

                if (data.performedBy && data.performedBy.includes(':bot/')) {
                  //Make the message clickable to view the full action details
                  return (
                    <TextLink
                      to={{
                        pathname: '/App/SolutionCenter/Node/action',
                        search: qs.stringify({
                          nodeId: data.srn,
                        }),
                      }}
                    >
                      {value}
                    </TextLink>
                  )
                }

                return value
              },
            },
            performedBy: {
              cellRendererFramework: ({ data, value }) => {
                if (!data || !value) {
                  return null
                }

                if (value.includes(':bot/')) {
                  //If the actor is actually a bot srn from an action:
                  return <BotWidget srn={value} table />
                }

                return <UserWidget srn={value} table />
              },
            },
          }}
        />
      </div>
    )
  }
}

TicketHistoryTab.propTypes = {
  fetchTicketHistory: PropTypes.func.isRequired,
  ticket: ImmutablePropTypes.iterable.isRequired,
  ticketHistory: ImmutablePropTypes.iterable.isRequired,
}

const mapStateToProps = createStructuredSelector({
  ticketHistory: selectTicketHistory,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTicketHistory,
    },
    dispatch
  )

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(TicketHistoryTab)
