import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import {
  SET_SECRET_STORE_CONTENT,
  GET_SECRET_STORE_CONTENT,
  GET_VAULT_STORE_CONTENT,
} from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  secretStoreContent: {},
  isLoading: false,
})

const secretStoreNodeViewCardLayoutReducer = handleActions(
  {
    [GET_SECRET_STORE_CONTENT]: state => state.set('isLoading', true),
    [GET_VAULT_STORE_CONTENT]: state => state.set('isLoading', true),
    [SET_SECRET_STORE_CONTENT]: (state, { payload }) => {
      return state
        .set('secretStoreContent', fromJS(payload))
        .set('isLoading', false)
    },
    [CLEAR_DATA]: state => state.set('secretStoreContent', Map()),
  },
  initialState
)

export default secretStoreNodeViewCardLayoutReducer
