import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Map, fromJS } from 'immutable'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import JSONDisplay from 'components/JSONDisplay'
import { flattenData, getNodeViewPushParams } from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { exists, stripTags } from 'utils/sonraiUtils'
import TableActions from './TableActions'
import getColumnProps from './getColumnProps'
import BulkActionToolbar from 'components/BulkActionToolbar'
import ActionToolbar from 'components/ActionToolbar'
import SearchInput from 'components/SearchInput'
import TextLink from 'components/TextLink'
import './styles.css'
import { CRM_ALERT_INDICATOR } from './constants'
import { BlockShimmer } from 'components/ShimmerLoader'
import ShowPathModal from 'containers/AccessActivity/ShowPathModal'

import ExpandRenderer from './ExpandRenderer'

const styles = {
  tableContainer: {
    gridArea: 'table',
    overflow: 'hidden', //Required to make tables stay in widget card. See https://www.ag-grid.com/javascript-grid-responsiveness/
  },
  mainContainer: {
    display: 'grid',
    position: 'relative',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: '"header" "table"',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  headerContainer: {
    height: '45px',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  selectAllButton: {
    fontSize: '0.9rem',
    padding: '0.45rem',
    margin: '0rem 0rem 0rem 0.75rem',
    // border: '1px solid #74B4DF',
  },
  loadingOverlay: {
    opacity: 0.7,
    backgroundColor: '#eee',
    position: 'absolute',
    borderRadius: '4px',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
}

export class AGGridDataTable extends React.Component {
  containerRef = React.createRef()
  state = {
    loaded: false,
    quickFilter: '',
    isIdentityChainModalOpen: false,
    pathData: Map(),
    sourceName: null,
    json: null,
  }

  componentWillUnmount() {
    this.api = null
    this.columnApi = null
  }

  constructor(props) {
    super(props)
    this.state = {
      rows: this.getRows(props.data),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ rows: this.getRows(this.props.data) })
    }
  }

  hasGrouping = () => {
    const columnApi = this.getColumnApi()
    if (columnApi) {
      const groupColumns = columnApi.getRowGroupColumns()
      if (groupColumns && groupColumns.length > 0) {
        return true
      }
    }
    return false
  }

  getCleanData = data => {
    if (_.isEmpty(data)) {
      return []
    }

    let newData = _.cloneDeep(data)

    if (newData[Object.keys(newData)[0]].items) {
      newData = newData[Object.keys(newData)[0]].items
    }

    return newData || []
  }

  getRows = data => {
    if (data && !_.isArray(data)) {
      const key = _.first(_.keys(data))
      data = _.get(data, [key, 'items'], [])
    }
    const strippedData = (data || []).map(row => {
      let safeRow = { ...row }
      Object.keys(row).forEach(col => {
        if (typeof safeRow[col] === 'string') {
          safeRow[col] = stripTags(safeRow[col])
        }
      })
      return safeRow
    })
    if (this.props.flatten) {
      const cleanData = this.getCleanData(strippedData)
      return flattenData(cleanData)
    } else {
      return strippedData
    }
  }

  groupHeaderName = () => {
    if (this.hasGrouping()) {
      return `Grouped By ${
        this.getColumnApi().getRowGroupColumns()[0].colDef.headerName
      }`
    } else {
      return 'Grouped'
    }
  }

  headerValueGetter = params => {
    if (
      params.location === 'header' &&
      this.hasGrouping() &&
      params.column.aggFunc
    ) {
      return `${params.colDef.headerName} (${params.column.aggFunc})`
    } else {
      return `${params.colDef.headerName}`
    }
  }
  groupedBy = values => {
    const types = values.map(x => typeof x)
    if (!types.includes('undefined') && !types.includes('object')) {
      return _.uniq(values).join(', ')
    }
  }

  onGridReady = params => {
    this.api = params.api
    this.columnApi = params.columnApi

    this.api.setPopupParent(document.querySelector('body'))
    const allCols = this.columnApi.getAllGridColumns() || []

    if (this.props.autosize) {
      const columnsStateWidthOverrides = new Set() // eslint-disable-line no-restricted-globals
      if (this.props.gridState.columnState) {
        this.props.gridState.columnState.forEach(columnState => {
          if (columnState.width) {
            columnsStateWidthOverrides.add(columnState.colId)
          }
        })
      }
      this.columnApi.autoSizeColumns(
        allCols.filter(
          col =>
            !columnsStateWidthOverrides.has(col.colId) &&
            !col.colId.includes('Set')
        )
      )
    }

    if (this.props.groupBy) {
      this.columnApi.setRowGroupColumns([this.props.groupBy])
    }

    this.setState({
      loaded: true,
    })

    if (this.props.onLoad) {
      this.props.onLoad({
        api: params.api,
        columnApi: params.columnApi,
      })
    }
  }

  filterOutCell = (cell, rows) => {
    const filterModel = this.getApi().getFilterModel() || {}

    const filterState = _.get(filterModel, [cell.colId, 'values'], {})
    const columnIdValues = _.isEmpty(filterState)
      ? _.uniq(rows.map(row => row[cell.colId]))
      : filterState
    const filteredColumnIdValues = columnIdValues.filter(
      id => id !== cell.value
    )

    this.getApi().setFilterModel({
      [cell.colId]: {
        filterType: 'set',
        values: filteredColumnIdValues,
      },
    })
  }

  filterInCell = cell =>
    this.getApi().setFilterModel({
      [cell.colId]: {
        filterType: 'set',
        values: [`${cell.value}`],
      },
    })

  getContextMenuItems = (items, rows) => {
    const standard = [...items.defaultItems]
    const exportIndex = standard.indexOf('export')
    if (exportIndex > -1) {
      standard.splice(exportIndex, 1)
    }

    const toolPanelIndex = standard.indexOf('toolPanel')
    if (toolPanelIndex > -1) {
      standard.splice(toolPanelIndex, 1)
    }

    const cell = {
      colId: items.column.colId,
      value: items.value,
    }

    const contextMenuItems = [
      ...standard,
      {
        name: 'Cell Filters',
        subMenu: [
          {
            name: `Exclude ${cell.value}`,
            action: () => this.filterOutCell(cell, rows),
          },
          {
            name: `Show only ${cell.value}`,
            action: () => this.filterInCell(cell),
          },
        ],
      },
    ]

    return contextMenuItems
  }

  countUnique = values => {
    const uniqueValues = new Set(values) //eslint-disable-line no-restricted-globals
    const count = uniqueValues.size
    return count === 1 ? values[0] : count
  }

  getMainMenuItems = ({ column, columnApi, defaultItems }) => {
    const itemsToExclude = [
      'resetColumns',
      'toolPanel',
      'autoSizeAll',
      'expandAll',
      'contractAll',
    ]

    const items = defaultItems.filter(x => !itemsToExclude.includes(x))

    const groupedColumns = columnApi.getRowGroupColumns()
    const isGrouped = groupedColumns.find(col => col.colId === column.colId)
    if (isGrouped) {
      items.push('rowUnGroup')
    } else if (!_.get(column, ['colDef', 'enableRowGroup'])) {
      items.push('rowGroup')
    }

    if (_.get(column, ['colDef', 'showRowGroup'])) {
      //expand/collapse only works on autogrouped column
      items.push('expandAll')
      items.push('contractAll')
    } else {
      //hide column function doesn't work on the AutoGroup column
      items.push({
        name: 'Hide Column',
        action: () => {
          columnApi.setColumnVisible(column.colId, false)
        },
      })
    }

    return items
  }

  getApi = () => {
    return this.api
  }

  getColumnApi = () => {
    return this.columnApi
  }

  onDoubleClickPathNode = ({ nodes }) => {
    if (nodes.length > 0) {
      const srn = nodes[0]

      if (srn.startsWith('srn:')) {
        this.props.push(getNodeViewPushParams(srn, getTypeFromSrn(srn)))
      }
    }
  }

  toggleIsIdentityChainModalOpen = () => {
    this.setState(prevState => {
      return { isIdentityChainModalOpen: !prevState.isIdentityChainModalOpen }
    })
  }

  setJSON = value => {
    this.setState({
      json: value,
    })
  }

  showEPPath = data => {
    if (data.EffectivePermission_identityChain) {
      const chain = data.EffectivePermission_identityChain.split(',')
      chain.push(data.EffectivePermission_policySrn)
      const name = data[Object.keys(data).find(col => col.includes('name'))]
      this.setState({
        epPathData: fromJS([chain]),
        epSourceName: name,
        isIdentityChainModalOpen: true,
      })
    }
  }

  getColumns = rows => {
    if (rows !== undefined && rows.length > 0) {
      const sampleRow = rows[0]
      let columns = Object.keys(sampleRow)

      let cols = columns.map(col => {
        const sampleValue = sampleRow[col]
        let columnConfig = getColumnProps({
          field: col,
          sampleValue,
          getColumnName: this.props.getColumnName,
          customColumnConfig: this.props.customColumnConfig,
        })

        if (this.props.crm && col === 'highestAlertSeverity') {
          columnConfig.sort = 'desc'
          columnConfig.hide = true
        }

        if (
          this.props.hiddenColumns &&
          this.props.hiddenColumns.includes(col)
        ) {
          columnConfig.hide = true
        }

        // if flex or maxWidth not set, make default max column width
        const { flex, maxWidth } =
          (this.props.customColumnConfig || {})[col] || {}
        if (!flex && !maxWidth) {
          columnConfig.maxWidth = 900
        }

        if (col === this.props.expandCollapseColumn) {
          columnConfig.flex = 1
          columnConfig.minWidth = 400
          columnConfig.tooltip = params => {
            if (
              !params.value ||
              typeof params.value !== 'string' ||
              params.value.trim().length === 0
            ) {
              return ''
            }

            if (params.value.length > 40) {
              return params.value.slice(0, 40) + '...'
            }

            return params.value
          }
          columnConfig.cellRendererFramework = params => {
            if (!params.data) {
              return
            }

            if (
              !params.value ||
              typeof params.value !== 'string' ||
              params.value.trim().length === 0
            ) {
              return ''
            }

            return (
              <span>
                {params.value.length > 40
                  ? params.value.slice(0, 40) + '...'
                  : params.value}
                <TextLink
                  color="primary"
                  onClick={() => {
                    params.node.setExpanded(!params.node.expanded)
                  }}
                  style={{ marginLeft: '0.3em' }}
                >
                  (Toggle Expand)
                </TextLink>
              </span>
            )
          }
        }

        //For AnalyticsResults.data, default to show it as JSON modal
        if (col === 'data' || this.props.jsonCols.includes(col)) {
          columnConfig.pinned = 'right'
          columnConfig.minWidth = 200
          columnConfig.cellRendererFramework = params => {
            if (params.value && typeof params.value === 'object') {
              return (
                <TextLink
                  color="primary"
                  onClick={() => this.setJSON(params.value)}
                >
                  (Show JSON Values)
                </TextLink>
              )
            } else {
              return params.value
            }
          }
        }

        return columnConfig
      })

      // set default column aggregation functions
      cols.forEach(columnConfig => {
        // if it can be aggregated, set the agg func to count unique
        if (columnConfig.aggFunc) {
          columnConfig.aggFunc = 'countUnique'
          if (this.props.groupBy) {
            columnConfig.aggFunc = 'groupBy'
          }
        }
      })

      cols = cols.map(columnConfig => {
        return (
          <AgGridColumn
            key={columnConfig.field}
            headerValueGetter={this.headerValueGetter}
            {...columnConfig}
          />
        )
      })

      if (
        columns.includes('EffectivePermission_identityChain') &&
        columns.includes('EffectivePermission_policySrn')
      ) {
        cols.push(
          <AgGridColumn
            key="epPath"
            minWidth={120}
            pinned="right"
            aggFunc={null}
            enableRowGroup={false}
            menuTabs={[]}
            suppressMenu={true}
            cellRendererFramework={params => {
              if (
                !params.data ||
                !params.data.EffectivePermission_identityChain ||
                !params.data.EffectivePermission_policySrn
              ) {
                return ''
              }

              return (
                <TextLink
                  color="primary"
                  onClick={() => this.showEPPath(params.data)}
                >
                  Show Path
                </TextLink>
              )
            }}
          />
        )
      }

      if (this.props.crm) {
        cols.push(
          <AgGridColumn
            width={10}
            key={CRM_ALERT_INDICATOR}
            colId={CRM_ALERT_INDICATOR}
            field="highestAlertSeverity"
            headerName=""
            sort="desc"
            cellClass={this.getIndicatorColClass}
            pinned="left"
            suppressColumnsToolPanel={true}
            lockPosition={true}
            lockVisible={true}
            lockPinned={true}
            sortable={true}
            resizable={false}
            suppressAutoSize={true}
            checkboxSelection={false}
            filter={false}
            valueFormatter={() => ''}
            suppressMenu={true}
            suppressSizeToFit={true}
            suppressMovable={true}
            maxWidth={10}
            minWidth={10}
          />
        )
      }

      return cols
    }
  }

  setRowStyle = row => {
    if (this.props.selectedNode && row.data.srn == this.props.selectedNode) {
      return { background: '#F9FBFF' }
    }
    return
  }

  handleRowSelection = obj => {
    if (this.props.selectedNode !== undefined) {
      this.props.selectNode({ node: obj.data.srn })
    }
  }

  handleChecked = obj => {
    if (this.props.rowSelected) {
      this.props.rowSelected(obj)
    }
  }

  isFirstColumn = params => {
    if (!this.props.multiRowSelect && !this.props.hasCheckBoxes) {
      return false
    }

    if (params.column.rowGroupActive) {
      return false
    }

    const displayedColumns = params.columnApi.getAllDisplayedColumns()

    if (
      this.props.crm &&
      displayedColumns[0].colId === CRM_ALERT_INDICATOR &&
      displayedColumns.length > 1
    ) {
      return displayedColumns[1] === params.column
    }

    if (displayedColumns.length > 0) {
      return displayedColumns[0] === params.column
    }

    return false
  }

  hasHeaderCheckboxSelection = params => {
    if (
      this.isFirstColumn(params) &&
      !this.props.crm &&
      !this.props.supressSelectAll
    ) {
      return true
    }

    return false
  }

  handleSearchBarChange = ({ target: { value } }) => {
    this.setState({ quickFilter: value })
    this.setQuickFilter(value)
  }

  /**
   * Debounce setting the quick filter so the app doesn't hang filtering
   * big big table while user is trying to type
   */
  setQuickFilterNOW = value => this.api.setQuickFilter(value)
  setQuickFilter = _.debounce(this.setQuickFilterNOW, 150, {
    leading: false,
    trailing: true,
  })

  renderSearchBar = rows => {
    if (this.state.loaded) {
      if (this.props.customFilter && !this.props.disableSearchBar) {
        return (
          <div style={{ display: 'flex' }}>
            <SearchInput
              placeholder="Quick Filter..."
              style={{ margin: '0.25rem 0rem ', width: '100%' }}
              value={this.state.quickFilter}
              onChange={this.handleSearchBarChange}
            />
            {this.props.customFilter()}
          </div>
        )
      }

      if (
        !this.props.customFilter &&
        !this.props.disableSearchBar &&
        rows.length > 0
      ) {
        return (
          <SearchInput
            placeholder="Quick Filter..."
            style={{ margin: '0.25rem 0rem' }}
            value={this.state.quickFilter}
            onChange={this.handleSearchBarChange}
          />
        )
      }
    } else {
      return (
        <div style={{ height: '45px' }}>
          <BlockShimmer />
        </div>
      )
    }
  }

  renderToolbar = rows => {
    if (this.props.noTableActions) {
      return (
        <Fragment>
          {this.renderSearchBar(rows)} <span />
        </Fragment>
      )
    }

    if (
      (this.props.bulkActionWorking ||
        this.props.checkboxSelectedRows.length !== 0) &&
      this.props.bulkActions
    ) {
      return (
        <BulkActionToolbar
          showCheckbox={false}
          selectedItems={this.props.checkboxSelectedRows}
          totalItems={rows}
          working={this.props.bulkActionWorking}
        >
          {this.props.bulkActions}
        </BulkActionToolbar>
      )
    }

    if (this.props.actions) {
      return (
        <Fragment>
          {this.renderSearchBar(rows)}
          <ActionToolbar>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                gridColumnGap: '1em',
                width: '100%',
              }}
            >
              <div>{this.props.actions}</div>
              {this.state.loaded &&
              rows.length > 0 &&
              this.containerRef.current ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TableActions
                    rows={rows}
                    containerRef={this.containerRef.current}
                    api={this.getApi()}
                    columnApi={this.getColumnApi()}
                    exportFileName={this.props.exportFileName}
                  />
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          </ActionToolbar>
        </Fragment>
      )
    }

    if (this.state.loaded && rows.length > 0 && this.containerRef.current) {
      return (
        <Fragment>
          {this.renderSearchBar(rows)}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row-reverse',
              position: 'relative',
              zIndex: 1,
              margin: this.props.bulkActions
                ? '0px 0px -5px 10px'
                : '0px 0px 0px 10px',
            }}
          >
            <TableActions
              rows={rows}
              containerRef={this.containerRef.current}
              api={this.getApi()}
              columnApi={this.getColumnApi()}
              exportFileName={this.props.exportFileName}
            />
          </div>
        </Fragment>
      )
    }

    return (
      <Fragment>
        {this.renderSearchBar(rows)}
        <span
          style={{
            marginBottom: this.props.bulkActions ? 0 : '-5px',
            height: this.props.bulkActions ? '35px' : 'auto',
          }}
        >
          &nbsp;
        </span>
      </Fragment>
    )
  }

  getIndicatorColClass = params => {
    if (!params.data) return ['alertLevel-0']

    const highestAlertSeverity = params.data.highestAlertSeverity
    if (!exists(highestAlertSeverity)) return ['alertLevel-0']

    const approxSeverity = Math.floor(highestAlertSeverity / 10)

    if (approxSeverity >= 0 && approxSeverity <= 1) {
      return ['alertLevel-1']
    }

    if (approxSeverity >= 1 && approxSeverity <= 10) {
      return [`alertLevel-${approxSeverity}`]
    }

    if (approxSeverity > 10) {
      return ['alertLevel-10']
    }

    return ['alertLevel-0']
  }

  processCellForClipboard = ({ value }) => {
    if (value) {
      if (typeof value === 'object') {
        const { name, srn } = value
        return name || srn
      }
      return value
    }
  }

  getRowSelection = () => {
    if (this.props.multiRowSelect || this.props.hasCheckBoxes) {
      return 'multiple'
    }

    return undefined
  }

  getHeightOfVisibleRows = rows => {
    const headerHeight = 35
    const rowHeight = 50
    const tablePadding = 15
    const pageSize = this.props.pageSize
    const hasPagination = rows.length > pageSize
    const paginationFooterHeight = 55
    const toolbarHeight =
      this.props.disableSearchBar && this.props.noTableActions ? 0 : 35

    const aggridStuff =
      headerHeight + toolbarHeight + tablePadding + toolbarHeight

    if (hasPagination) {
      return aggridStuff + rowHeight * pageSize + paginationFooterHeight
    }

    return rowHeight * rows.length + aggridStuff
  }

  getExpandCollapseColConfig = () => {
    if (!this.props.expandCollapseColumn) {
      return {}
    }

    return {
      groupUseEntireRow: true,
      masterDetail: true,
      detailCellRenderer: 'expandRenderer',
      frameworkComponents: {
        expandRenderer: params => (
          <ExpandRenderer
            data={params.data}
            colName={this.props.expandCollapseColumn}
          />
        ),
      },
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [{ field: this.props.expandCollapseColumn }],
          defaultColDef: { flex: 1 },
        },
        getDetailRowData: function (params) {
          params.successCallback([
            {
              [this.props.expandCollapseColumn]:
                params.data[this.props.expandCollapseColumn],
            },
          ])
        },
      },
    }
  }

  render() {
    const rows = this.state.rows

    const onRowSelected = this.props.hasCheckBoxes
      ? this.handleChecked
      : this.handleRowSelection

    const pageSize = this.props.pageSize
    const hasPagination = rows.length > pageSize

    //Have to put a blank space when not showing table actions because the table only checks its size on initial render
    //Without it, it was always overflowing its container when the actions loaded in

    return (
      <div
        ref={this.containerRef}
        style={
          this.props.fitAllRows
            ? {
                ...styles.mainContainer,
                height: `${this.getHeightOfVisibleRows(rows)}px`,
              }
            : styles.mainContainer
        }
      >
        {this.props.disableSearchBar && this.props.noTableActions ? (
          <div />
        ) : (
          <div style={styles.headerContainer}>{this.renderToolbar(rows)}</div>
        )}

        <div
          style={{ ...styles.tableContainer, ...this.props.style }}
          className="ag-theme-material"
        >
          {!this.state.loaded && <div style={styles.loadingOverlay} />}
          <AgGridReact
            aggFuncs={{
              countUnique: this.countUnique,
              groupedBy: this.groupedBy,
            }}
            processCellForClipboard={this.processCellForClipboard}
            headerHeight={35}
            onGridReady={this.onGridReady}
            rowSelection={this.getRowSelection()}
            enableCellTextSelection={true}
            suppressCopyRowsToClipboard={true}
            onRowSelected={onRowSelected}
            getRowStyle={row => this.setRowStyle(row)}
            sortable
            filter
            resizable
            enableBrowserTooltips
            suppressDragLeaveHidesColumns={
              this.props.suppressDragLeaveHidesColumns
            }
            defaultColDef={{
              // only pass func if to checkboxSelection if checkboxes available,
              // to save render time on tables w/out checkboxes. if func passed
              // ag-grid will always render checkbox but hide, otherwise it wont
              // render at all = faster
              checkboxSelection:
                (this.props.hasCheckBoxes || this.props.multiRowSelect) &&
                this.isFirstColumn,
              headerCheckboxSelection: this.hasHeaderCheckboxSelection,
              headerCheckboxSelectionFilteredOnly: true,
              width: this.props.columnWidth,
              sortable: true,
              ...this.props.defaultColDef,
            }}
            rowData={rows}
            getContextMenuItems={items => this.getContextMenuItems(items, rows)}
            suppressAggFuncInHeader={true}
            autoGroupColumnDef={{
              headerValueGetter: this.groupHeaderName,
              resizable: true,
            }}
            suppressMenuHide
            getMainMenuItems={this.getMainMenuItems}
            onSelectionChanged={this.props.selectionChanged}
            suppressRowClickSelection={this.props.hasCheckBoxes}
            pagination={hasPagination}
            paginationPageSize={pageSize}
            suppressMakeColumnVisibleAfterUnGroup
            {...this.getExpandCollapseColConfig()}
            {...this.props.customGridProps}
            cacheQuickFilter={rows.length > 25}
            overlayNoRowsTemplate={this.props.overlayNoRowsTemplate}
          >
            {this.getColumns(rows)}
          </AgGridReact>
        </div>

        {this.state.epPathData && this.state.isIdentityChainModalOpen && (
          <ShowPathModal
            resourceId={this.state.epPathData.getIn([
              0,
              this.state.epPathData.get(0).size - 1,
            ])}
            sourceName={this.state.epSourceName}
            onDoubleClick={this.onDoubleClickPathNode}
            leaveItBeFFS
            paths={this.state.epPathData}
            isOpen={this.state.isIdentityChainModalOpen}
            toggle={this.toggleIsIdentityChainModalOpen}
            skipLoading
          />
        )}
        {this.state.json && (
          <Modal isOpen={true} toggle={() => this.setJSON(null)} size="lg">
            <ModalHeader toggle={() => this.setJSON(null)}>
              JSON details
            </ModalHeader>
            <ModalBody>
              <JSONDisplay content={this.state.json} />
            </ModalBody>
          </Modal>
        )}
      </div>
    )
  }
}

AGGridDataTable.defaultProps = {
  autosize: true,
  checkboxSelectedRows: [],
  flatten: false,
  jsonCols: [],
  style: {},
  sortColumns: true,
  pageSize: 25,
  gridState: {},
}

AGGridDataTable.propTypes = {
  actions: PropTypes.node,
  bulkActions: PropTypes.node,
  bulkActionWorking: PropTypes.bool,
  checkboxSelectedRows: PropTypes.array,
  autosize: PropTypes.bool,
  customColumnConfig: PropTypes.object,
  customGridProps: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  exportFileName: PropTypes.string,
  expandCollapseColumn: PropTypes.string,
  fitAllRows: PropTypes.bool,
  getColumnName: PropTypes.func,
  gridState: PropTypes.shape({
    columnGroupState: PropTypes.array,
    columnState: PropTypes.array,
    sortModel: PropTypes.array,
    filterModel: PropTypes.array,
    options: PropTypes.object,
  }),
  flatten: PropTypes.bool,
  jsonCols: PropTypes.arrayOf(PropTypes.string),
  onLoad: PropTypes.func,
  rowSelected: PropTypes.func,
  style: PropTypes.object,
  selectedNode: PropTypes.string,
  selectNode: PropTypes.func,
  selectionChanged: PropTypes.func,
  sortColumns: PropTypes.bool,
  multiRowSelect: PropTypes.bool,
  noTableActions: PropTypes.bool,
  columnWidth: PropTypes.number,
  hasCheckBoxes: PropTypes.bool,
  onCellEditingStopped: PropTypes.func,
  sortColumnByType: PropTypes.string,
  crm: PropTypes.bool,
  groupBy: PropTypes.string,
  onDoubleClickRow: PropTypes.func,
  pageSize: PropTypes.number,
  hiddenColumns: PropTypes.array,
  overlayNoRowsTemplate: PropTypes.string,
  defaultColDef: PropTypes.object,
  suppressDragLeaveHidesColumns: PropTypes.bool,
  disableSearchBar: PropTypes.bool,
  customFilter: PropTypes.func,
  push: PropTypes.func,
  supressSelectAll: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(AGGridDataTable)
