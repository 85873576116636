import _ from 'lodash'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import gql from 'graphql-tag'
import { getClient } from 'apolloClient'
import {
  GET_RESOURCE_CAN_ASSUME_ROLES,
  GET_IDENTITY_GROUP_MEMBERSHIPS,
  GET_IDENTITY_DIRECT_ATTACHED_POLICIES,
  GET_ROLE_CAN_BE_ASSUMED_BY,
} from 'static-queries'
import {
  metadataToObj,
  getCloudFromSrn,
  getWhateverFromSrn,
} from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import {
  setCanAssumeRoles,
  errorCanAssumeRoles,
  setCanBeAssumedBy,
  errorCanBeAssumedBy,
  setGroupMemberships,
  errorGroupMemberships,
  setDirectAttachedPolicies,
  errorDirectAttachedPolicies,
  setSCPs,
  errorSCPs,
  setInstanceProfiles,
  errorInstanceProfiles,
  setPermissionInfo,
} from './actions'
import {
  GET_CAN_ASSUME_ROLES,
  GET_CAN_BE_ASSUMED_BY,
  GET_GROUP_MEMBERSHIPS,
  GET_DIRECT_ATTACHED_POLICIES,
  GET_SCPS,
  GET_INSTANCE_PROFILES,
  GET_PERMISSION_INFO,
} from './constants'

function* getAssumeRoles({ action, query, success, error }) {
  try {
    const client = yield getClient()
    const response = yield client.query({
      query: gql`
        ${query}
      `,
      variables: { criticalResourceSrn: action.payload.srn },
    })

    const alertLogs = _.get(response, ['data', 'AlertLogs', 'items'])
    if (alertLogs === undefined) {
      throw 'Error: response did not return any alertLogs'
    }

    const roles = alertLogs
      .flatMap(alertLog => {
        // set path on each resource, and then return so we'll be filtering
        // resources
        const { path, conditions, resourceSetFull } = alertLog
        resourceSetFull.forEach(resource => {
          resource.path = path
          resource.conditions = conditions
        })
        return resourceSetFull
      })
      .filter(resource => 'Role' === resource.label)
    yield put(success({ roles }))
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting can assume roles for selected node ', err)
    yield put(error())
  }
}

/**
 * Get all the roles a user can assume
 */
function* getCanAssumeRoles(action) {
  const GET_CAN_ASSUME_ROLES_QUERY = `
    query getCanAssumeRoles($srn: String!) {
      Actors(where: {
        srn: {value: $srn}
      }) {
        items {
          srn
          canAssumeRole {
            items {
              name: roleName
              srn: roleSrn
              how
            }
          }
        }
      }
    }
  `
  try {
    const client = yield getClient()
    const response = yield client.query({
      query: gql`
        ${GET_CAN_ASSUME_ROLES_QUERY}
      `,
      variables: { srn: action.payload.srn },
    })

    const roles = _.get(response, [
      'data',
      'Actors',
      'items',
      0,
      'canAssumeRole',
      'items',
    ])

    if (!roles) {
      yield put(errorCanAssumeRoles())
    } else {
      yield put(setCanAssumeRoles({ roles: roles }))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error getting canBeAssumedBy ', e)
    yield put(errorCanAssumeRoles())
  }
}

/**
 * Get all the things that can assume some resource (probably resource is
 * a role)
 */
function* getCanBeAssumedBy(action) {
  const GET_CAN_BE_ASSUMED_BY_QUERY = `
    query getCanGetAssumedBy($srn: String!) {
      Roles(where: {
        srn: {value: $srn}
      }) {
        items {
          roleAssumableBy {
            items {
              srn: actorSrn
              name: actorName
              how
            }
          }
        }
      }
    }
  `
  try {
    const client = yield getClient()
    const response = yield client.query({
      query: gql`
        ${GET_CAN_BE_ASSUMED_BY_QUERY}
      `,
      variables: { srn: action.payload.srn },
    })

    const actors = _.get(response, [
      'data',
      'Roles',
      'items',
      0,
      'roleAssumableBy',
      'items',
    ])

    if (!actors) {
      yield put(errorCanBeAssumedBy())
    } else {
      yield put(setCanBeAssumedBy({ actors: actors }))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error getting canAssumedBeBy ', e)
    yield put(errorCanBeAssumedBy())
  }
}

function* getGroupMemberships(action) {
  try {
    const client = yield getClient()
    const response = yield client.query({
      query: gql`
        ${GET_IDENTITY_GROUP_MEMBERSHIPS}
      `,
      variables: { srn: action.payload.srn },
    })

    const identity = _.get(response, ['data', 'Identities', 'items', [0]])
    if (identity === undefined) {
      throw 'Error: response did not return an identity item'
    }

    const groups = _.get(identity, ['isMemberOf', 'items'])
    if (groups === undefined) {
      throw 'Error: response did not return a list of groups'
    }

    groups.forEach(group => {
      group.path = JSON.stringify([action.payload.srn])
      group.conditions = '[]'
    })
    yield put(setGroupMemberships({ groups }))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error getting group memberships for selected node ', err)
    yield put(errorGroupMemberships())
  }
}

const GET_GCP_IDENTITY_DIRECT_ATTACHED_POLICIES = `
query GET_GCP_IDENTITY_DIRECT_ATTACHED_POLICIES($srn: String!){ 
  Users (where: {
    srn: {value: $srn}
  }) {
    items {
      isPartOfPolicyEntry {
            items {
              hasPermissionList {
                items {
                  Name: name
                }
              }
              isPartOfPolicyVersion {
                items {
                  isPartOfPolicy {
                    items {
                      srn
                      account
                      cloudType
                      type
                      label
                      metadata
                      __typename
                     isAttachedTo {
                        items {
                          ResourcePath: name
                          ResourceSRN: srn
                          ...on Account {
                            ResourceType: type
                          }
                          ...on Organizationalunit {
                            ResourceType: type
                          }
                          ...on Datacontainer {
                            ResourceType: type
                          }
                          ...on Datastore {
                            ResourceType: type
                          }
                           
                        }
                      }
                    }
                  }
                }
              }
            }
           }
      isMemberOf {
        items {
          Group: name
          GroupSRN: srn
          isPartOfPolicyEntry {
            items {
              hasPermissionList {
                items {
                  Name: name
                }
              }
              isPartOfPolicyVersion {
                items {
                  isPartOfPolicy {
                    items {
                      srn
                      account
                      cloudType
                      label
                      metadata
                      __typename
                      isAttachedTo {
                        items {
                          ResourcePath: name
                          ResourceSRN: srn
                          ...on Account {
                            ResourceType: type
                          }
                          ...on Organizationalunit {
                            ResourceType: type
                          }
                          ...on Datacontainer {
                            ResourceType: type
                          }
                          ...on Datastore {
                            ResourceType: type
                          }
                           
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
 }
`

function* getPolicyVersionJSON(policy) {
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
      query getPolicyVersionsData {
        Policies(
          where: { srn: { value: "${policy.srn}"}}
        ) {
          items {
            srn
            hasPolicyVersion(where:{active: {op: EQ value:true}}){
              count
              items{
                type
                policyJSON
              }
            }
          }
        }
      }
      `,
    })

    const json = _.get(response, [
      'data',
      'Policies',
      'items',
      '0',
      'hasPolicyVersion',
      'items',
      '0',
      'policyJSON',
    ])
    policy.json = json
    return policy
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error(`Unable to get policy version json  ${err}`)
  }
}

function* getDirectAttachedPolicies(action) {
  const { srn } = action.payload
  const isGCPNode = srn && srn.includes('gcp')
  const isUser = getTypeFromSrn(srn) === 'User'
  if (isGCPNode && isUser) {
    try {
      const client = yield getClient()
      let response = yield client.query({
        query: gql`
          ${GET_GCP_IDENTITY_DIRECT_ATTACHED_POLICIES}
        `,
        variables: { srn: action.payload.srn },
      })

      const isPartOfPolicyEntry =
        _.get(response, [
          'data',
          'Users',
          'items',
          '0',
          'isPartOfPolicyEntry',
          'items',
        ]) || []

      const isMemberOf =
        _.get(response, [
          'data',
          'Users',
          'items',
          '0',
          'isMemberOf',
          'items',
        ]) || []

      const gcpUserPolicies = isPartOfPolicyEntry
        .map(entry => {
          let policy = _.get(entry, [
            'isPartOfPolicyVersion',
            'items',
            '0',
            'isPartOfPolicy',
            'items',
            '0',
          ])

          const attached = _.get(entry, [
            'isPartOfPolicyVersion',
            'items',
            '0',
            'isPartOfPolicy',
            'items',
            '0',
            'isAttachedTo',
            'items',
            '0',
          ])

          if (!policy || !attached) {
            return null
          }

          return {
            name: _.get(entry, ['hasPermissionList', 'items', '0', 'Name']),
            srn: policy.srn,
            account: policy.account,
            cloudType: policy.cloudType,
            label: policy.label,
            type: policy.type,
            metadata: policy.metadata,
            __typename: policy.__typename,
            path: JSON.stringify([policy.srn]),
            conditions: '[]',
            rbac: {
              name: _.get(attached, ['ResourcePath']),
              resourcePath: _.get(attached, ['ResourcePath']),
              resourcePathEnd: _.get(attached, ['ResourcePath']),
              resourceType: _.get(attached, ['ResourceType']),
              srn: _.get(attached, ['ResourceSRN']),
            },
          }
        })
        .filter(entry => !!entry)

      let gcpMemberPolicies = []
      isMemberOf.forEach(entry => {
        const group = entry.Group
        entry.isPartOfPolicyEntry.items.forEach(entri => {
          const policy = _.get(entri, [
            'isPartOfPolicyVersion',
            'items',
            '0',
            'isPartOfPolicy',
            'items',
            '0',
          ])
          const attached = _.get(entri, [
            'isPartOfPolicyVersion',
            'items',
            '0',
            'isPartOfPolicy',
            'items',
            '0',
            'isAttachedTo',
            'items',
            '0',
          ])

          if (!policy || !attached) {
            return
          }

          gcpMemberPolicies.push({
            name: _.get(entri, ['hasPermissionList', 'items', '0', 'Name']),
            srn: policy.srn,
            account: policy.account,
            cloudType: policy.cloudType,
            label: policy.label,
            metadata: policy.metadata,
            __typename: policy.__typename,
            path: JSON.stringify([policy.srn]),
            conditions: '[]',
            group,
            rbac: {
              name: _.get(attached, ['ResourcePath']),
              resourcePath: _.get(attached, ['ResourcePath']),
              resourcePathEnd: _.get(attached, ['ResourcePath']),
              resourceType: _.get(attached, ['ResourceType']),
              srn: _.get(attached, ['ResourceSRN']),
            },
          })
        })
      })

      let allPolicies = [...gcpUserPolicies, ...gcpMemberPolicies]

      yield put(setDirectAttachedPolicies({ policies: allPolicies }))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'Error getting direct attached policies for selected GCP node ',
        error
      )
      yield put(errorDirectAttachedPolicies())
    }
  } else {
    try {
      const client = yield getClient()
      const response = yield client.query({
        query: gql`
          ${GET_IDENTITY_DIRECT_ATTACHED_POLICIES}
        `,
        variables: { srn: action.payload.srn },
      })

      const identity = _.get(response, ['data', 'Resources', 'items', [0]])
      if (identity === undefined) {
        throw 'Error: response did not return an resource item'
      }

      const policies = _.get(identity, ['hasAttached', 'items'])
      if (policies === undefined) {
        throw 'Error: response did not return a list of policies'
      }

      for (let policy of policies) {
        policy.path = JSON.stringify([policy.srn])
        policy.conditions = '[]'
        // if meta data value scope is here and is for azure, try and find some additional info
        if (
          policy.metadata &&
          getCloudFromSrn(action.payload.srn) === 'azure'
        ) {
          // if not one of these types, the type should be named "direct"
          const chainTypes = [
            'managementGroups',
            'subscriptions',
            'resourceGroups',
            'storageAccounts',
          ]

          const scope = policy.metadata.find(meta =>
            meta.includes('roleAssignment.properties.scope:')
          )

          if (scope) {
            const scopeVal = scope.substring(scope.indexOf(':') + 1)
            const splits = scopeVal.split('/').splice(1)
            let pathChain = []
            if (splits.length > 2) {
              let index = 0
              for (const split of splits) {
                if (
                  chainTypes.includes(split) &&
                  splits[index + 1] &&
                  index < splits.length - 2
                ) {
                  const pathResource = yield client.query({
                    query: gql`
                      query getResource($val: String!) {
                        Resources(
                          where: { resourceId: { op: ENDS_WITH, value: $val } }
                        ) {
                          items {
                            name
                            srn
                          }
                        }
                      }
                    `,
                    variables: { val: splits[index + 1] },
                  })
                  const pathResourceVal = _.get(
                    pathResource,
                    ['data', 'Resources', 'items', 0],
                    false
                  )
                  if (pathResourceVal && pathResourceVal.name) {
                    pathChain.push(pathResourceVal.name)
                  } else {
                    pathChain.push(splits[index + 1])
                  }
                }
                index++
              }
            }

            const type = chainTypes.includes(splits[splits.length - 2])
              ? splits[splits.length - 2]
              : 'directResource'
            let path = splits[splits.length - 1]
            const response = yield client.query({
              query: gql`
                query getResource($val: String!) {
                  Resources(
                    where: { resourceId: { op: ENDS_WITH, value: $val } }
                  ) {
                    items {
                      name
                      resourceId
                      srn
                    }
                  }
                }
              `,
              variables: { val: path },
            })

            const resource = _.get(
              response,
              ['data', 'Resources', 'items', 0],
              false
            )
            const pathEnd = path
            if (resource.name) {
              path = resource.name
            }

            if (pathChain.length > 0) {
              path = `${pathChain.reduce((totalPath, thisPath) => {
                if (!totalPath) {
                  return `${thisPath}`
                } else {
                  return `${totalPath} / ${thisPath}`
                }
              })} / ${path}`
            }

            policy.rbac = {
              name: resource.name,
              resourcePath: path,
              resourcePathEnd: pathEnd,
              resourceType: type,
              srn: resource.srn,
            }
          }
        }
      }

      yield all(policies.map(policy => call(getPolicyVersionJSON, policy))) // adds the new policyJSON field

      yield put(setDirectAttachedPolicies({ policies }))
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        'Error getting direct attached policies for selected node ',
        err
      )
      yield put(errorDirectAttachedPolicies())
    }
  }
}

const DIRECT_ATTACHED_POLICIES_FRAGMENT = `
fragment directAttachedPoliciesFields on Resource {
  srn
  name
  label
  hasAttached {
    items {
      srn
      cloudType
      account
      name
      metadata
    }
  }
}
`

export function* getSCPs(action) {
  const client = yield getClient()
  try {
    const lookupUserResponse = yield client.query({
      query: gql`
        query get_user($srn: String) {
          Resources(where: { srn: { value: $srn } }) {
            items @skip(if: false) {
              srn
              name
              account
              cloudType
            }
          }
        }
      `,
      variables: {
        srn: action.payload.srn,
      },
    })
    const resource = _.get(lookupUserResponse, [
      'data',
      'Resources',
      'items',
      0,
    ])

    if (resource === undefined) {
      throw 'Resource was not returned when queried for'
    }

    const { cloudType, account: accountNumber } = resource
    const accountSrn = `srn:${cloudType}:account::${accountNumber}/Account/${accountNumber}`

    const scpLookupResponse = yield client.query({
      variables: { account: accountNumber, accountSrn },
      query: gql`
        ${DIRECT_ATTACHED_POLICIES_FRAGMENT}
        query get_account_scps($account: String, $accountSrn: String) {
          Accounts(where: { srn: { value: $accountSrn } }) {
            items {
              ...directAttachedPoliciesFields
              organizedBy {
                items {
                  ...directAttachedPoliciesFields
                  organizedBy {
                    items {
                      ...directAttachedPoliciesFields
                      organizedBy {
                        items {
                          ...directAttachedPoliciesFields
                          organizedBy {
                            items {
                              ...directAttachedPoliciesFields
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          Users(
            where: {
              srn: {
                and: [
                  { op: CONTAINS, value: "Root" }
                  { op: CONTAINS, value: $account }
                ]
              }
            }
          ) {
            items {
              ...directAttachedPoliciesFields
            }
          }
        }
      `,
    })

    const account = _.get(
      scpLookupResponse,
      ['data', 'Accounts', 'items', 0],
      {}
    )
    const accountSCPs = _.get(account, ['hasAttached', 'items'], [])
    accountSCPs.forEach(scp => {
      scp.path = JSON.stringify([account.srn])
      scp.conditions = '[]'
    })

    const rootUser = _.get(scpLookupResponse, ['data', 'Users', 'items', 0])
    const rootUserSCPs = _.get(rootUser, ['hasAttached', 'items'], [])
    rootUserSCPs.forEach(scp => {
      scp.path = JSON.stringify([action.payload.srn, rootUser.srn])
      scp.conditions = '[]'
    })

    const visitedOUs = []
    const parentOUScpPolicies = getSCPsfromParentOrgUnit({
      resource: account,
      visitedOUs,
      path: [action.payload.srn, account.srn],
    })

    // parent OUs can be in a big tree! but we can't fetch all the way up the tree at once, so
    // we need to figure out if there are more, and them keep fetching until we get them all
    const ous = _.get(account, ['organizedBy', 'items'])

    // find if there's any OUs in the current result we didnt fetch the parents for
    let ousWithoutParents = []
    findOUsWithoutFetchedParents({
      orgUnits: ous,
      currentList: ousWithoutParents, // it'll populate this list,
      path: [action.payload.srn, account.srn],
    })

    while (ousWithoutParents.length > 0) {
      // if there were some OUs without parents, fetch the parents
      const parentOUs = yield fetchMoreParentOUs({
        fromChildren: ousWithoutParents.map(ou => ou.srn),
        excludeList: visitedOUs,
      })

      // for each newly fetched OU, find the SCP policies
      for (let parentOU of parentOUs) {
        const path = ousWithoutParents
          .filter(ou => ou.srn === parentOU.srn)
          .map(ou => ou.path)[0]

        parentOU.path = path

        const moreParentOUScpPolicies = getSCPsfromParentOrgUnit({
          resource: parentOU,
          visitedOUs,
          path,
        })

        // add them to our list of SCP policies
        moreParentOUScpPolicies.forEach(scp => parentOUScpPolicies.push(scp))
      }

      // now see if there are even MORE ous we didn't fetch the parents for
      ousWithoutParents.length = 0
      findOUsWithoutFetchedParents({
        orgUnits: parentOUs,
        currentList: ousWithoutParents,
        path: ['?'],
      })
    }

    const allSCPPolicies = _.uniqBy(
      [...accountSCPs, ...rootUserSCPs, ...parentOUScpPolicies],
      policy => policy.srn
    )

    yield put(setSCPs({ scps: allSCPPolicies }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error getting SCPs for selected node', e)
    yield put(errorSCPs())
  }
}

/**
 * Each account can be part of an OrganizationalUnit (OU), and those organizational
 * units can in turn be owned by other OUs, creating a tree of OUs with accounts at
 * the botttom.
 *
 * This method traverses up the tree and pulls the direct attached policies off the
 * OUs and returns them.
 */
const getSCPsfromParentOrgUnit = ({ resource, visitedOUs, path }) => {
  const allScpPolicies = []
  const orgUnits = _.get(resource, ['organizedBy', 'items'])

  // if we can't tell what the OU is orgnaized by, just return, perhaps the data
  // not available
  if (orgUnits === undefined) {
    return allScpPolicies
  }

  // check each OU the thing is orgnaized by
  for (let orgUnit of orgUnits) {
    path.push(orgUnit.srn)

    // if we've seen the OU before, don't re-add the OU's policies
    if (!_.includes(visitedOUs, orgUnit.srn)) {
      // keep track of the OUs we've seen
      visitedOUs.push(orgUnit.srn)

      // find the direct attached SCP policies, and then add them to our result
      const scpPolicies = _.get(orgUnit, ['hasAttached', 'items'])
      if (scpPolicies === undefined) {
        throw (
          'Could not get scp Policies from resource: ' + JSON.stringify(orgUnit)
        )
      }

      for (let scpPolicy of scpPolicies) {
        allScpPolicies.push({
          ...scpPolicy,
          conditions: '[]',
          path: JSON.stringify(path),
        })
      }

      // now get the SCP policies from the parent OU and also add those to the result
      const parentScpPolicies = getSCPsfromParentOrgUnit({
        resource: orgUnit,
        visitedOUs,
        path,
      })
      for (let parentScpPolicy of Object.values(parentScpPolicies)) {
        allScpPolicies.push({
          ...parentScpPolicy,
        })
      }
    }

    path.pop()
  }

  // now we found all the SCP policies up the tree!
  return allScpPolicies
}

/**
 * traverse the object containig OUs, find ones that don't have an organizedBy key,
 * it means we didn't fetch the parent OUs
 */
const findOUsWithoutFetchedParents = ({ orgUnits, currentList, path }) => {
  const parents = []
  for (let orgUnit of orgUnits) {
    if (!orgUnit.path) {
      orgUnit.path = [...path]
    }
    if (!orgUnit.path.includes(orgUnit.srn)) {
      orgUnit.path.push(orgUnit.srn)
    }

    if (orgUnit.organizedBy === undefined) {
      currentList.push(orgUnit)
    } else {
      orgUnit.organizedBy.items.forEach(item => {
        item.path = orgUnit.path
        parents.push(item)
      })
    }
  }
  if (parents.length > 0) {
    findOUsWithoutFetchedParents({ orgUnits: parents, currentList, path })
  }
}

/**
 * There's no limit to how deeply you can nest OUs, and we don't have a way in graphql
 * to say, "Just keep going up the tree until there's no more", so we can just keep
 * querying for more and more until we get to the top.
 *
 * This starts at the children, gets all the parents, but excluding parents we maybe
 * have seen before
 *
 * @param fromChildren list of children SRNs to fetch from
 * @param excludeList list of parentSRNs not to fetch
 */
const fetchMoreParentOUs = async ({ fromChildren, excludeList }) => {
  const client = await getClient()
  const response = await client.query({
    variables: { fromChildren, excludeList },
    query: gql`
      ${DIRECT_ATTACHED_POLICIES_FRAGMENT}
      query getParentOUs($fromChildren: [String], $excludeList: [String]) {
        OrganizationalUnits(
          where: {
            srn: {
              and: [
                { op: IN_LIST, values: $fromChildren }
                { op: NOT_IN_LIST, values: $excludeList }
              ]
            }
          }
        ) {
          items {
            ...directAttachedPoliciesFields
            organizedBy(
              where: { srn: { op: NOT_IN_LIST, values: $excludeList } }
            ) {
              items {
                ...directAttachedPoliciesFields
                organizedBy(
                  where: { srn: { op: NOT_IN_LIST, values: $excludeList } }
                ) {
                  items {
                    ...directAttachedPoliciesFields
                    organizedBy(
                      where: { srn: { op: NOT_IN_LIST, values: $excludeList } }
                    ) {
                      items {
                        ...directAttachedPoliciesFields
                        organizedBy(
                          where: {
                            srn: { op: NOT_IN_LIST, values: $excludeList }
                          }
                        ) {
                          items {
                            ...directAttachedPoliciesFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  })

  const parentOUs = _.get(response, ['data', 'OrganizationalUnits', 'items'])
  if (parentOUs === undefined) {
    throw 'could not get list of parent OUs from response'
  }

  return parentOUs
}

function* getInstanceProfileForAWS(action) {
  const { resource } = action.payload
  const { srn } = resource

  const client = yield getClient()
  const result = yield client.query({
    query: gql`
      query getInstanceProfileForCompute($srn: String) {
        Compute(where: { srn: { value: $srn } }) {
          items {
            hasAttached {
              items {
                name
                srn
              }
            }
          }
        }
      }
    `,
    variables: { srn },
  })

  const roles = _.get(result, [
    'data',
    'Compute',
    'items',
    0,
    'hasAttached',
    'items',
  ])
  if (!roles) {
    throw new Error('Could not get attached roles from response')
  }
  yield put(setInstanceProfiles({ profiles: roles }))
}

function* getServicePrincipalsForAzure(action) {
  const { resource } = action.payload
  const client = yield getClient()
  const result = yield client.query({
    variables: { srn: resource.srn },
    query: gql`
      query getComputeServicePrincipals($srn: String) {
        Compute(where: { srn: { value: $srn } }) {
          items {
            canBeAccessedBy(where: { label: { value: "IdentityProvider" } }) {
              items {
                srn
                name
                friendlyName
              }
            }
          }
        }
      }
    `,
  })

  const path = ['data', 'Compute', 'items', 0, 'canBeAccessedBy', 'items']
  const identityProviders = _.get(result, path)
  if (!identityProviders) {
    throw new Error(`Could not retrive ${path.join('.')} from result`)
  }

  yield put(setInstanceProfiles({ profiles: identityProviders }))
}

/**
 * for AWS computes, the instance profile's Role ARN
 */
function* getInstanceProfiles(action) {
  try {
    const { resource } = action.payload
    if (!resource) {
      throw new Error("action paylaod did not have field 'resource'")
    }
    const { srn, cloudType } = resource
    switch (cloudType) {
      case 'aws':
        yield getInstanceProfileForAWS(action)
        break
      case 'azure':
        yield getServicePrincipalsForAzure(action)
        break
      default:
        // eslint-disable-next-line no-console
        console.warn(
          `no way to get instance profiles for resource ${srn} cloudType ${cloudType}`
        )
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('There was an error loading instance profiles', e)
    yield put(errorInstanceProfiles())
  }
}

function* getPermission(action) {
  try {
    const client = yield getClient()
    const result = yield client.query({
      variables: {
        srn: action.payload,
      },
      query: gql`
        query permissionInfo($srn: String) {
          Permissions(where: { srn: { value: $srn } }) {
            items {
              cloudType
              label
              name
              srn
              metadata
            }
          }
        }
      `,
    })

    const permission = _.get(result, ['data', 'Permissions', 'items', 0])

    if (!permission) {
      throw new Error('No permission returned')
    }
    yield put(setPermissionInfo(permission))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('There was an error fetching the permission info', e)
  }
}

function* accessSaga() {
  yield all([
    takeLatest(GET_CAN_ASSUME_ROLES, getCanAssumeRoles),
    takeLatest(GET_CAN_BE_ASSUMED_BY, getCanBeAssumedBy),
    takeLatest(GET_GROUP_MEMBERSHIPS, getGroupMemberships),
    takeLatest(GET_DIRECT_ATTACHED_POLICIES, getDirectAttachedPolicies),
    takeLatest(GET_SCPS, getSCPs),
    takeLatest(GET_INSTANCE_PROFILES, getInstanceProfiles),
    takeLatest(GET_PERMISSION_INFO, getPermission),
  ])
}

export default accessSaga
