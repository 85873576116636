import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { exists } from 'utils/sonraiUtils'
import { POLICY_EVAL_TYPES, POLICY_OP_TYPES } from './constants'
import Icon from 'components/Icon'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import { updatePolicy } from 'containers/ControlFrameworkData/actions'
import {
  selectSonraiSearches,
  selectSavedSearches,
} from 'containers/SonraiData/selectors'
import { Map } from 'immutable'

import messages from './messages'
import { selectSaving } from './selectors'
import PolicyEditor from './PolicyEditor'

export class EditPolicyModal extends React.Component {
  constructor(props) {
    super(props)

    const evalCriteria = props.policy.get('evalCriteria') || Map()
    const firstCriteriaKey =
      evalCriteria.size > 0 ? evalCriteria.keySeq().first() : null

    const search = props.policy.getIn(['contains', 'items', 0], Map())

    this.state = {
      title: props.policy.get('title'),
      policyDescription: props.policy.get('description'),
      selectedSearchSrn: search.get('srn'),
      selectedSearchValue:
        search.get('__typename') === 'Savedquery'
          ? search.get('name')
          : search.get('sid'),
      policyEvalType: firstCriteriaKey
        ? props.policy.getIn(['evalCriteria', firstCriteriaKey, 0])
        : POLICY_EVAL_TYPES.COUNT,
      policyRemediationType: props.policy.get('remediationType'),
      policyEvalTarget: firstCriteriaKey
        ? props.policy.getIn(['evalCriteria', firstCriteriaKey, 1])
        : 0,
      policyEvalOp: firstCriteriaKey || POLICY_OP_TYPES.GT,
      alertingLevelNumeric: props.policy.get('alertingLevelNumeric') || 5,
    }
  }

  componentDidUpdate(oldProps) {
    if (
      (oldProps.saving && !this.props.saving) ||
      (oldProps.policy && oldProps.policy !== this.props.policy)
    ) {
      this.props.toggle()
    }
  }

  setPolicyDescription = value => {
    this.setState({
      policyDescription: value,
    })
  }

  setPolicyTitle = e => {
    this.setState({
      title: e.target.value,
    })
  }

  setPolicyEvalOp = e => {
    this.setState({
      policyEvalOp: e.target.value,
    })
  }

  setPolicyRemediationType = opt => {
    this.setState({
      policyRemediationType: opt.value,
    })
  }

  setPolicyEvalTarget = e => {
    const parsed = parseInt(e.target.value)
    this.setState({
      policyEvalTarget: isNaN(parsed) ? e.target.value : parsed,
    })
  }

  setPolicyEvalType = e => {
    this.setState({
      policyEvalType: e.target.value,
    })
  }

  handleSave = () => {
    const evalCriteria = {
      [this.state.policyEvalOp]: [
        this.state.policyEvalType,
        this.state.policyEvalTarget,
      ],
    }

    this.props.updatePolicy({
      title: this.state.title,
      description: this.state.policyDescription,
      searchId: this.state.selectedSearchSrn,
      srn: this.props.policy.get('srn'),
      evalCriteria: evalCriteria,
      alertingLevelNumeric: this.state.alertingLevelNumeric,
      remediationType: this.state.policyRemediationType,
    })
  }

  setSelectedSearch = value => {
    if (!value) {
      this.setState({
        selectedSearchValue: null,
        selectedSearchSrn: null,
      })
    } else {
      this.setState({
        selectedSearchValue: value.value,
        selectedSearchSrn: value.srn,
      })
    }
  }

  render() {
    const isOpen = exists(this.props.isOpen) ? this.props.isOpen : true
    return (
      <Modal isOpen={isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          <FormattedMessage {...messages.editPolicyHeader} />
        </ModalHeader>
        <ModalBody>
          <PolicyEditor
            onAlertingLevelNumericChange={alertingLevelNumeric =>
              this.setState({ alertingLevelNumeric })
            }
            alertingLevelNumeric={this.state.alertingLevelNumeric}
            setPolicyEvalTarget={this.setPolicyEvalTarget}
            setPolicyTitle={this.setPolicyTitle}
            setPolicyDescription={this.setPolicyDescription}
            setPolicyEvalType={this.setPolicyEvalType}
            setPolicyEvalOp={this.setPolicyEvalOp}
            setSelectedSearch={this.setSelectedSearch}
            policyEvalTarget={this.state.policyEvalTarget}
            policyEvalOp={this.state.policyEvalOp}
            policyEvalType={this.state.policyEvalType}
            description={this.state.policyDescription}
            title={this.state.title}
            sonraiSearches={this.props.sonraiSearches}
            selectedSearchValue={this.state.selectedSearchValue}
            savedSearches={this.props.savedSearches}
            policyRemediationType={this.state.policyRemediationType}
            setPolicyRemediationType={this.setPolicyRemediationType}
          />
        </ModalBody>
        <ModalFooter>
          <TextLink color="secondary" onClick={this.props.toggle}>
            <FormattedMessage {...messages.cancelButton} />
          </TextLink>
          <Button
            color="primary"
            onClick={this.handleSave}
            disabled={
              !this.state.title ||
              !this.state.selectedSearchSrn ||
              this.props.saving
            }
          >
            {this.props.saving ? (
              <Icon fa spin name="sync" />
            ) : (
              <FormattedMessage {...messages.saveEditButton} />
            )}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

EditPolicyModal.propTypes = {
  policy: ImmutablePropTypes.map.isRequired,
  saving: PropTypes.bool,
  savedSearches: ImmutablePropTypes.iterable.isRequired,
  sonraiSearches: ImmutablePropTypes.iterable.isRequired,
  toggle: PropTypes.func.isRequired,
  updatePolicy: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  saving: selectSaving,
  savedSearches: selectSavedSearches,
  sonraiSearches: selectSonraiSearches,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePolicy,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(EditPolicyModal)
