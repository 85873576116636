import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Input } from 'reactstrap'
import Select from 'react-select'
import MarkdownEditor from 'components/MarkdownEditor'
import CombinedSearches from 'components/CombinedSearches'
import FormLabel from 'components/FormLabel'
import { POLICY_EVAL_TYPES, POLICY_OP_TYPES } from './constants'
import messages from './messages'
import Slider from 'rc-slider'
import _ from 'lodash'
import 'rc-slider/assets/index.css'
const Handle = Slider.Handle

const REMEDIATION_TYPES = [
  'DELETE_ACCESS_KEY',
  'DELETE_IDENTITY',
  'UPDATE_POLICY',
]
export class PolicyEdit extends React.Component {
  state = {
    showEdit: false,
  }

  styles = {
    triggerConfig: {
      display: 'grid',
      gridTemplateColumns: '30% 1fr 30%',
      gridColumnGap: '1em',
    },
    slider: {
      width: '168px',
      margin: '0.5rem 0',
    },
  }

  toggleEdit = () => {
    this.setState(currentState => ({
      showEdit: !currentState.showEdit,
    }))
  }

  handleChange = props => {
    const { value, dragging, ...restProps } = props
    //Convert dragging to a string to remove a warning in console
    //See: https://github.com/react-component/slider/issues/502
    return (
      <Handle value={value} dragging={String(dragging)} {...restProps}>
        <span
          style={{
            fontSize: '0.75rem',
            fontWeight: '400',
            color: `rgb(136, 136, 136)`,
          }}
        >
          {value}
        </span>
      </Handle>
    )
  }

  render() {
    return (
      <div>
        <div style={{ fontSize: '1.2rem' }}> Details </div>
        <div style={{ paddingLeft: '1rem', marginTop: '-0.5rem' }}>
          <FormLabel
            for="policyTitle"
            required
            label={<FormattedMessage {...messages.policyTitleLabel} />}
          />
          <Input
            type="text"
            name="policyTitle"
            id="policyTitle"
            value={this.props.title}
            onChange={this.props.setPolicyTitle}
            disabled={this.props.loading}
          />
          <FormLabel
            for="policyDescription"
            label={<FormattedMessage {...messages.policyDescriptionLabel} />}
          />

          <MarkdownEditor
            value={this.props.description}
            onChange={this.props.setPolicyDescription}
          />
          <div>
            <FormLabel
              for="remType"
              label={<FormattedMessage {...messages.remTypeLabel} />}
              // required={true}
            />
            <Select
              isClearable
              options={REMEDIATION_TYPES.map(value => ({
                label: _.startCase(_.toLower(value)),
                value,
              }))}
              value={
                this.props.policyRemediationType
                  ? {
                      label: _.startCase(
                        _.toLower(this.props.policyRemediationType)
                      ),
                      value: this.props.policyRemediationType,
                    }
                  : this.props.policyRemediationType
              }
              onChange={this.props.setPolicyRemediationType}
              disabled={this.props.loading}
            />
          </div>
        </div>

        <div style={{ fontSize: '1.2rem', marginTop: '1rem' }}>Alerting</div>

        <div style={{ paddingLeft: '1rem', marginTop: '-0.5rem' }}>
          <FormLabel
            for="search"
            label={<FormattedMessage {...messages.policySearchLabel} />}
            required={true}
          />
          <CombinedSearches
            savedSonraiSearches={this.props.sonraiSearches}
            savedSearches={this.props.savedSearches}
            selectedSearchId={this.props.selectedSearchValue}
            onSelect={this.props.setSelectedSearch}
            loading={this.props.loading}
          />
          <FormLabel
            for="alertingLevel"
            label={<FormattedMessage {...messages.policyAlertingLevelLabel} />}
            required={true}
          />
          <div style={this.styles.slider}>
            <Slider
              trackStyle={{
                backgroundColor: `rgb(39, 110, 241)`,
              }}
              handleStyle={{
                width: '30px',
                height: '30px',
                display: 'flex',
                background: `rgb(255, 255, 255)`,
                border: `0.5px solid rgb(136, 136, 136)`,
                alignItems: `center`,
                justifyContent: `center`,
                marginTop: '-13px',
              }}
              onAfterChange={this.props.onAlertingLevelNumericChange}
              min={1}
              max={10}
              defaultValue={this.props.alertingLevelNumeric || 5}
              handle={this.handleChange}
              disabled={this.props.loading}
            />
          </div>
          <FormLabel
            for="evalType"
            label={<FormattedMessage {...messages.evalTypeLabel} />}
            required={true}
          />
          <div style={this.styles.triggerConfig}>
            <Input
              type="select"
              name="evalType"
              id="evalType"
              value={this.props.policyEvalType}
              onChange={this.props.setPolicyEvalType}
              disabled={this.props.loading}
            >
              <option value={POLICY_EVAL_TYPES.COUNT}>
                {this.props.intl.formatMessage(messages.evalTypeCountLabel)}
              </option>
            </Input>
            <Input
              type="select"
              name="evalOp"
              id="evalOp"
              value={this.props.policyEvalOp}
              onChange={this.props.setPolicyEvalOp}
              disabled={this.props.loading}
            >
              <option value={POLICY_OP_TYPES.GT}>
                {this.props.intl.formatMessage(messages.evalOpGTLabel)}
              </option>
              <option value={POLICY_OP_TYPES.LT}>
                {this.props.intl.formatMessage(messages.evalOpLTLabel)}
              </option>
              <option value={POLICY_OP_TYPES.EQ}>
                {this.props.intl.formatMessage(messages.evalOpEQLabel)}
              </option>
            </Input>
            <Input
              type="number"
              name="policyEvalTarget"
              id="policyEvalTarget"
              value={this.props.policyEvalTarget}
              onChange={this.props.setPolicyEvalTarget}
              disabled={this.props.loading}
            />
          </div>
        </div>
      </div>
    )
  }
}

PolicyEdit.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  setPolicyEvalTarget: PropTypes.func,
  setPolicyTitle: PropTypes.func,
  setPolicyDescription: PropTypes.func,
  setPolicyEvalType: PropTypes.func,
  setPolicyEvalOp: PropTypes.func,
  setSelectedSearch: PropTypes.func,
  policyEvalTarget: PropTypes.number,
  policyEvalOp: PropTypes.string,
  policyEvalType: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  savedSearches: ImmutablePropTypes.map,
  sonraiSearches: ImmutablePropTypes.list,
  selectedSearchValue: PropTypes.string,
  onAlertingLevelNumericChange: PropTypes.func,
  alertingLevelNumeric: PropTypes.number,
  loading: PropTypes.bool,
  policyRemediationType: PropTypes.string,
  setPolicyRemediationType: PropTypes.func,
}

export default injectIntl(PolicyEdit)
