import { Map, List } from 'immutable'
import { getFieldsForType, getFullType } from 'query-builder'
import {
  getGenericNodeQuery,
  getGenericNodeQueryFieldsOnly,
} from 'static-queries'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getCurrentOrg } from 'auth/current-org'

export const getTypeFromSrn = srn => {
  if (srn) {
    if (srn.startsWith('srn:kubernetes')) {
      const srnWithoutPreamble = srn.split('::')
      if (srnWithoutPreamble.length > 1) {
        srn = srnWithoutPreamble[1]
      }
    }

    if (srn.includes('srn:sonrai::')) {
      const parts = srn.split('::')
      const typeAndIdentifier = parts[1]
      const idParts = typeAndIdentifier.split('/')
      return idParts[0]
    } else {
      const parts = srn.split('/')
      if (parts[1]) {
        return parts[1]
      } else {
        return ''
      }
    }
  }

  return ''
}

export const getCloudTypeFromSRN = srn => {
  if (srn) {
    const parts = srn.split(':')
    if (parts.length > 1) {
      return parts[1]
    }
  }
}

// If query-builder is not up to date - use this
// export const getFieldsForType = (type, types) => {
//   const acceptableTypes = ['SCALAR', 'LIST']
//   const objectType = type.charAt(0).toUpperCase() + type.slice(1)
//   const nodeDefinition = types.get(objectType, Map())

//   const fields = nodeDefinition
//     .get('fields', List())
//     .filter(field => acceptableTypes.includes(field.getIn(['type', 'kind'])))

//   return fields
// }

export const getNodeInfoQuery = (nodeId, queryNames, queryTypes) => {
  const config = getNodeInfo(nodeId, queryNames, queryTypes)

  const nodeProperties = getAllFields(nodeId, queryTypes)
  if (
    nodeProperties
      .find(property => property.get('name') === 'isIn', null, Map())
      .getIn(['type', 'name']) === 'ResourceEdgeRelation'
  ) {
    return gql`
      ${getGenericNodeQuery(config.nodeType, config.fields)}
    `
  } else {
    return gql`
      ${getGenericNodeQueryFieldsOnly(config.nodeType, config.fields)}
    `
  }
}

export const getGraphEndpoint = () => {
  const config = window.config || {}
  let orgName = getCurrentOrg()

  if (config.graph_host_template !== undefined) {
    if (config.graph_host_template.includes('__ORGNAME__')) {
      if (orgName) {
        return config.graph_host_template.replace('__ORGNAME__', orgName)
      } else {
        //eslint-disable-next-line no-console
        console.error(
          'Adam, there was an error while building graphQL endpoint: No org_name is present in localstorage or redux'
        )
        //eslint-disable-next-line no-console
        console.log(
          'per the error above, we dont know what to do so setting the host to: ',
          config.graph_host_template
        )
        return config.graph_host_template
      }
    } else {
      return config.graph_host_template
    }
  } else {
    //TODO: use users profile value from redux, not localstorage...
    let domain = config.environment_domain

    if (process.env.NODE_ENV !== 'production') {
      if (config.local_dev_graph_host) {
        return config.local_dev_graph_host
      }

      if (!orgName) {
        orgName = 'dev'
      }

      if (!domain) {
        domain = 's.sonraisecurity.com'
      }
    }

    if (orgName === undefined) {
      //eslint-disable-next-line no-console
      console.log(
        'Error while building graphQL endpoint: No org_name is present in localstorage. '
      )
      return setTimeout(getGraphEndpoint, 1000)
    }

    //eslint-disable-next-line no-console
    console.warn(
      'No graph_host_template found in config.json. Building graphql endpoint from default template "https://${orgName}-graphql-server.${domain}/"'
    )

    return `https://${orgName}.${domain}/`
  }
}

export const getAllFields = (nodeId, types) => {
  const type = getTypeFromSrn(nodeId).toLowerCase()
  const nodeType = getFullType(type).replace('EdgeRelation', '')

  const definition = types.get(nodeType, Map())
  const fields = definition.get('fields', List())
  return fields
}

export const getNodeInfo = (nodeId, queryNames, types) => {
  const type = getTypeFromSrn(nodeId).toLowerCase() || ''
  const nodeType = getFullType(type)

  const queryName = queryNames.get(nodeType, Map()).get('name', 'Resources')

  const SKIP_PROPERTIES = [
    'observedDate',
    'loadId',
    'createdBy',
    'content',
    'relations',
    'taggedBy',
    'relationsToParent',
  ]

  let fields = getFieldsForType(type, types)
    .map(field => field.get('name'))
    .filter(field => !SKIP_PROPERTIES.includes(field))

  let finalNodeType = queryName
  if (!nodeType) {
    finalNodeType = 'Resources'
  }

  return {
    nodeType: finalNodeType,
    fields: fields,
    nodeId: nodeId,
  }
}

export const getSwimlaneFields = (nodeId, queryNames, types) => {
  const type = getTypeFromSrn(nodeId).toLowerCase() || ''
  const nodeType = getFullType(type)

  // check if has swimlane prop
  const checkMeForSwimmi =
    types.get(type.charAt(0).toUpperCase() + type.slice(1)) || Map()

  let fields = List()
  const queryName = queryNames.get(nodeType, Map()).get('name', 'Resources')
  if (
    checkMeForSwimmi.get('fields').filter(field => {
      return field.get('name') === 'swimlane'
    }).size > 0
  ) {
    const swimmy = `swimlane {
    items {
      title
      defaultImportance
      srn
    }
  }
  `
    fields = fields.push(swimmy)
  } else if (queryName === 'Identities') {
    const swimmy = `...on Resource {
    swimlane {
      items {
        srn
        title
      }
    }
  }
  `
    fields = fields.push(swimmy)
  }
  return fields
}

export const formatUpdateSwimlaneTags = arr => {
  const { add, remove } = arr
  let updateAdd = add.map(tag => {
    if (hasBeenStarred(tag)) {
      return tag
    }
    return `*${tag}*`
  })
  let updateRemove = remove.map(tag => {
    if (hasBeenStarred(tag)) {
      return tag
    }
    return `*${tag}*`
  })
  return { add: updateAdd, remove: updateRemove }
}

export const formatCreateSwimlaneTags = tags => {
  if (!_.isEmpty(tags)) {
    return tags.map(tag => {
      if (!hasBeenStarred(tag)) {
        return `*${tag}*`
      }
      return tag
    })
  }
  return tags
}

const hasBeenStarred = tag => {
  if (tag) {
    const arr = tag.split('')
    if (_.first(arr) === '*' || _.last(arr) === '*') {
      return true
    }
  }
  return false
}

// search nested relationships for label type
export const dudeWheresMyType = (dude, relation, type) => {
  if (!dude || !relation || !type) {
    return []
  }
  if (dude.items && dude.items.length > 0) {
    for (let item of dude.items) {
      if (item.label && item.label === type) {
        return item
      } else if (item[relation]) {
        return dudeWheresMyType(item[relation], relation, type)
      } else {
        return []
      }
    }
  } else {
    return []
  }
}

//field - immutable map
export const getTypeFromDefinition = field => {
  return field
    .getIn(['definition', 'type', 'name'], '')
    .replace('EdgeRelation', '')
}
