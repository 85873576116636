import React, { useState } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { fromJS, List, Map } from 'immutable'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Button from 'components/Button'
import Select from 'components/Select'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'
import AssignToMe from './AssignToMe'
import messages from '../messages'
import { getCurrentOrg } from 'auth/current-org'
import UserWidget from 'components/UserWidget'

export default function ReassignToUserModal(props) {
  const [user, setUser] = useState(null)

  const clearAndClose = () => {
    setUser(null)
    props.close()
  }

  const onSubmit = () => {
    props.reassignToUser({
      ticketSrn: props.ticket.srn,
      userSrn: user,
    })
    clearAndClose()
  }

  //pass in immutable user
  const getRoleAssignmentsByScope = user => {
    const roleAssignments = user.getIn(['roleAssignments', 'items'], List())
    const roleAssignmentsByScope = {}
    roleAssignments.forEach(roleAssignment => {
      roleAssignmentsByScope[
        roleAssignment.get('scope')
      ] = roleAssignment.toJS()
    })
    return fromJS(roleAssignmentsByScope)
  }

  //pass in roleAssignmentsByScope obj
  //pass in a scope (resourceId or adminScope)
  const hasPermission = (roleAssignmentsByScope, scope) => {
    const adminPerm = '*'
    const passingPerm = 'view.data' // needs this to pass
    if (roleAssignmentsByScope.get(scope)) {
      const permissions = roleAssignmentsByScope.getIn(
        [scope, 'role', 'items', '0', 'permissions'],
        List()
      )

      if (
        permissions.includes(adminPerm) ||
        permissions.includes(passingPerm)
      ) {
        return true
      }
    } else {
      return false
    }
  }

  const getTicketSwimlaneResourceIds = ticket => {
    const adminScope = `/org/${getCurrentOrg()}/*`
    const srns = (ticket.swimlaneSRNs || []).map(swimlaneSrn => {
      const swimlane =
        props.swimlanes.find(swimlane => swimlane.get('srn') === swimlaneSrn) ||
        Map()
      return swimlane.get('resourceId')
    })
    srns.push(adminScope) // add the admin scope
    return srns
  }

  const getUserOptions = () => {
    //This is a list of all the swimlane resource IDs that the ticket is attached to -- not sure if we are suppose to use the ticket RESOURCE's swimlanes for this?

    const users = (props.users || List()).toList()
    let options = []
    users.forEach(user => {
      const roleAssignmentsByScope = getRoleAssignmentsByScope(user)
      const ticketSwimlaneResourceIds = getTicketSwimlaneResourceIds(
        props.ticket
      )
      //checking the tickets swimlanes against the users scopes and permissions
      ticketSwimlaneResourceIds.forEach(resourceId => {
        if (hasPermission(roleAssignmentsByScope, resourceId)) {
          options.push(user.toJS())
        }
      })
    })

    return options
  }

  const options = getUserOptions().map(item => ({
    label: item.name || item.srn,
    value: item.srn,
  }))

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>
        <DynamicFormattedMessage {...messages.reassignModalHeaderText} />
      </ModalHeader>
      <ModalBody>
        <Select
          options={options}
          onChange={val => setUser(val.value)}
          formatOptionLabel={option => (
            <UserWidget srn={option.value} table noLink />
          )}
        />
      </ModalBody>
      <ModalFooter>
        <div style={{ position: 'absolute', left: '20px' }}>
          <AssignToMe ticket={fromJS(props.ticket)} />
        </div>
        <TextLink color="secondary" onClick={clearAndClose}>
          <DynamicFormattedMessage {...messages.snoozeModalCancelText} />
        </TextLink>
        <Button color="primary" disabled={user == null} onClick={onSubmit}>
          <DynamicFormattedMessage {...messages.reassignModalOKText} />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ReassignToUserModal.propTypes = {
  users: ImmutablePropTypes.iterable.isRequired,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  reassignToUser: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    srn: PropTypes.string.isRequired,
    swimlaneSRNs: PropTypes.array.isRequired,
    assignedTo: PropTypes.string,
  }),
}
