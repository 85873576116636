import React from 'react'
import PropTypes from 'prop-types'
import BorderlessButton from 'components/BorderlessButton'

class DropdownItem extends React.Component {
  styles = {
    option: {
      width: '100%',
      textAlign: 'left',
      padding: '0.5em 1.5em',
      borderBottom: '1px solid #eee',
    },
  }

  clickOption = () => {
    this.props.onClick({
      label: this.props.label,
      value: this.props.value,
    })
  }

  render() {
    return (
      <BorderlessButton
        style={this.styles.option}
        onClick={this.clickOption}
        disabled={this.props.disabled}
      >
        {this.props.label}
      </BorderlessButton>
    )
  }
}

DropdownItem.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  defaultValue: PropTypes.bool, //Used in Dropdown
  onClick: PropTypes.func,
}

export default DropdownItem
