import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import IconDivider from 'components/IconDivider'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'

export class Expandable extends React.Component {
  constructor(props) {
    super(props)
    this.styles = {
      expanded: {
        height: 'auto',
        overflow: 'visible',
      },
      content: {
        wordBreak: 'break-word',
        wordWrap: 'break-word', //Needed for Firefox
        overflow: 'hidden',
        position: 'relative',
      },
      expandButton: {
        fontSize: '0.8em',
      },
    }

    this.state = {
      expanded: false,
      contentOverflows: true,
    }

    this.containerRef = React.createRef()
  }

  componentDidMount() {
    const element = this.containerRef.current
    if (element) {
      this.setState({
        contentOverflows: element.scrollHeight > element.clientHeight,
      })
    }
  }

  componentDidUpdate() {
    if (this.props.autoUpdate) {
      const element = this.containerRef.current
      if (element) {
        if (element.scrollHeight <= Number.parseInt(this.props.height)) {
          if (this.state.contentOverflows) {
            this.setState({ contentOverflows: false })
          }
        } else {
          if (!this.state.contentOverflows) {
            this.setState({ contentOverflows: true })
          }
        }
      }
    }
  }

  toggleExpand = () => {
    this.setState(oldState => ({
      expand: !oldState.expand,
    }))
  }

  render() {
    return (
      <Fragment>
        <div
          ref={this.containerRef}
          style={{
            ...(this.props.scale && !this.state.expand
              ? { maxHeight: this.props.height }
              : { height: this.props.height }),
            ...this.styles.content,
            ...(this.state.expand ? this.styles.expanded : {}),
            ...this.props.style,
          }}
        >
          {this.props.children}
        </div>

        {this.state.contentOverflows && (
          <IconDivider color="#eee">
            <BorderlessButton
              onClick={this.toggleExpand}
              style={this.styles.expandButton}
            >
              {this.state.expand ? (
                <Fragment>
                  <Icon name="caret-up" fa /> Show Less
                </Fragment>
              ) : (
                <Fragment>
                  <Icon name="caret-down" fa /> Show More
                </Fragment>
              )}
            </BorderlessButton>
          </IconDivider>
        )}
      </Fragment>
    )
  }
}

Expandable.defaultProps = {
  height: '1.7em',
  style: {},
}

Expandable.propTypes = {
  children: PropTypes.node,
  height: PropTypes.string,
  style: PropTypes.object,
  autoUpdate: PropTypes.bool,
  scale: PropTypes.bool,
}

export default Expandable
