import React from 'react'
import { Map, List } from 'immutable'
import moment from 'moment'
import { getMultiValuesFromString } from 'utils/sonraiUtils'

const styles = {
  regex: {
    fontFamily: 'monospace',
    fontStyle: 'normal',
    backgroundColor: '#eee',
    display: 'inline-block',
    whiteSpace: 'pre',
  },
  propName: {
    fontFamily: 'monospace',
    fontStyle: 'normal',
  },
  nodeValue: {
    fontFamily: 'monospace',
    fontStyle: 'normal',
  },
}

const GLOBAL_ACCOUNT_ID = 'Global'
const MICROSOFT_ACCOUNT_ID = 'f8cdef31-a31e-4b4a-93e4-5f571e91255a'

export const filterOutStandardSearchArgs = args => {
  const modifiedArgs = args.map(arg => {
    const config = arg.first(Map())
    const propName = arg.keySeq().first()

    const configValue = config.get('value')
    const op = config.get('op')

    if (
      propName === 'active' &&
      (configValue === true || configValue === 'true')
    ) {
      return null
    }

    if (propName === 'tagSet') {
      if (List.isList(configValue)) {
        return arg.setIn(
          [propName, 'value'],
          configValue.filter(
            value =>
              typeof value !== 'string' ||
              !value.toLowerCase().includes('controlexception')
          )
        )
      } else if (typeof configValue === 'string') {
        return configValue.toLowerCase().includes('controlexception')
          ? null
          : arg
      }
    }

    if (propName === 'account' && op === 'NEQ') {
      if (List.isList(configValue)) {
        const filteredAccounts = configValue.filter(
          accountId =>
            accountId !== GLOBAL_ACCOUNT_ID &&
            accountId !== MICROSOFT_ACCOUNT_ID
        )

        if (filteredAccounts.isEmpty()) {
          return null
        }

        return arg.setIn([propName, 'value'], filteredAccounts)
      }

      const csv = getMultiValuesFromString(configValue)
      const nonGlobal = csv.filter(
        val => val !== GLOBAL_ACCOUNT_ID && val !== MICROSOFT_ACCOUNT_ID
      )

      if (nonGlobal.length > 0) {
        return arg.setIn([propName, 'value'], nonGlobal.join(','))
      } else {
        return null
      }
    }

    return arg
  })

  return modifiedArgs.filter(arg => !!arg)
}

export const compareDateField = (
  config,
  nodeValue,
  propName,
  alertTime,
  nodeType
) => {
  const result = {
    field: propName,
    message: null,
    triggerCondition: null,
    nodeType,
  }

  if (typeof config.get('dateOffset') === 'number') {
    if (config.get('dateOffset') < 0) {
      result.triggerCondition = `Field "${propName}" is older than ${Math.abs(
        config.get('dateOffset')
      )} days when the policy runs `

      const offsetDate = moment(alertTime).subtract(
        config.get('dateOffset'),
        'days'
      )

      if (nodeValue && moment(nodeValue).isBefore(offsetDate)) {
        result.message = (
          <span>
            Field <span style={styles.propName}>{propName}</span> with value{' '}
            <span style={styles.nodeValue}>
              {moment(nodeValue).format('lll')}
            </span>{' '}
            was older than {Math.abs(config.get('dateOffset'))} days when the
            policy ran
          </span>
        )
      }
    } else {
      const offsetDate = moment(alertTime).add(config.get('dateOffset'), 'days')

      result.triggerCondition = `Field "${propName}" is newer than ${config.get(
        'dateOffset'
      )} days when the policy runs `

      if (nodeValue && moment(nodeValue).isBefore(offsetDate)) {
        result.message = (
          <span>
            Field <span style={styles.propName}>{propName}</span> with value{' '}
            <span style={styles.nodeValue}>
              {moment(nodeValue).format('lll')}
            </span>{' '}
            was newer than {config.get('dateOffset')} days when the policy ran
          </span>
        )
      }
    }
  } else if (Map.isMap(config.get('dateOffset'))) {
    const offsetArg = config.get('dateOffset')
    const alertDate = moment(alertTime)
    const nodeDate = moment(nodeValue)

    if (offsetArg.get('tense') === 'future') {
      const compareToDate = alertDate.add(
        offsetArg.get('number'),
        offsetArg.get('measure')
      )

      if (offsetArg.get('type') === 'GT') {
        result.triggerCondition = `Field "${propName}" is more than ${offsetArg.get(
          'number'
        )} ${offsetArg.get('measure')} in the future from when the policy runs `

        if (nodeValue && nodeDate.isAfter(compareToDate)) {
          result.message = (
            <span>
              Field <span style={styles.propName}>{propName}</span> with value{' '}
              <span style={styles.nodeValue}>
                {moment(nodeValue).format('lll')}
              </span>{' '}
              was more than {offsetArg.get('number')} {offsetArg.get('measure')}{' '}
              in the future from when the policy ran
            </span>
          )
        }
      }

      if (offsetArg.get('type') === 'LT') {
        result.triggerCondition = `Field "${propName}" is less than ${offsetArg.get(
          'number'
        )} ${offsetArg.get('measure')} in the future from when the policy runs `

        if (nodeValue && nodeDate.isBefore(compareToDate)) {
          result.message = (
            <span>
              Field <span style={styles.propName}>{propName}</span>with value{' '}
              <span style={styles.nodeValue}>
                {moment(nodeValue).format('lll')}
              </span>{' '}
              was less than {offsetArg.get('number')} {offsetArg.get('measure')}{' '}
              in the future from when the policy ran
            </span>
          )
        }
      }
    } else {
      const compareToDate = alertDate.subtract(
        offsetArg.get('number'),
        offsetArg.get('measure')
      )

      if (offsetArg.get('type') === 'GT') {
        result.triggerCondition = `Field "${propName}" is less than ${offsetArg.get(
          'number'
        )} ${offsetArg.get('measure')} in the past from when the policy runs `

        if (nodeValue && nodeDate.isAfter(compareToDate)) {
          result.message = (
            <span>
              Field <span style={styles.propName}>{propName}</span> with value
              <span style={styles.nodeValue}>
                {moment(nodeValue).format('lll')}
              </span>{' '}
              was less than {offsetArg.get('number')} {offsetArg.get('measure')}{' '}
              in the past from when the policy ran
            </span>
          )
        }
      }

      if (offsetArg.get('type') === 'LT') {
        result.triggerCondition = `Field "${propName}" is more than ${offsetArg.get(
          'number'
        )} ${offsetArg.get('measure')} in the past from when the policy runs `

        if (nodeValue && nodeDate.isBefore(compareToDate)) {
          result.message = (
            <span>
              Field <span style={styles.propName}>{propName}</span> with value{' '}
              <span style={styles.nodeValue}>
                {moment(nodeValue).format('lll')}
              </span>{' '}
              was more than {offsetArg.get('number')} {offsetArg.get('measure')}{' '}
              in the past from when the policy ran
            </span>
          )
        }
      }
    }
  }

  return result
}

export const getPropOfInterest = (
  config,
  propName,
  nodeValue,
  alertTime,
  nodeType
) => {
  let configValue = config.get('value')
  const op = config.get('op')
  let configValues = []

  if (typeof configValue === 'string') {
    configValues = config.get('noCsv')
      ? [configValue]
      : getMultiValuesFromString(configValue)
  } else if (
    List.isList(configValue) &&
    configValue.filter(val => typeof val === 'string').size === configValue.size
  ) {
    configValues = configValue.toJS()
  }

  //Handle caseSensitive
  if (
    !config.get('caseSensitive') &&
    (op === 'NOT_CONTAINS' ||
      op === 'CONTAINS' ||
      op === 'EQ' ||
      op === 'NEQ' ||
      op === 'ENDS_WITH' ||
      op === 'STARTS_WITH')
  ) {
    configValues = configValues.map(configValue => configValue.toLowerCase())

    if (typeof nodeValue === 'string') {
      nodeValue = nodeValue.toLowerCase()
    } else if (List.isList(nodeValue)) {
      nodeValue = nodeValue.map(val =>
        typeof val === 'string' ? val.toLowerCase() : val
      )
    }
  }

  //Handle numeric comparisons
  if (op === 'LTE' || op === 'GTE' || op === 'LT' || op === 'GT') {
    if (typeof configValue === 'string' && typeof nodeValue === 'number') {
      const numeric = parseInt(configValue, 10)

      if (!isNaN(numeric)) {
        configValue = numeric
      }
    }
  }

  const joinedNodeVal = List.isList(nodeValue)
    ? nodeValue.join(', ')
    : nodeValue

  const result = {
    field: propName,
    message: null,
    triggerCondition: null,
    nodeType,
  }

  if (op === 'REGEX') {
    const withoutEscapes = configValue
      .replace(/\\\\\\/g, '\\')
      .replace(/\\\\/g, '\\')
    const regex = new RegExp(withoutEscapes)

    result.triggerCondition = (
      <span>
        Field &quot;{propName}&quot; matches the regex{' '}
        <span style={styles.regex}>{configValue}</span>
      </span>
    )

    if (regex.test(nodeValue)) {
      result.message = (
        <span>
          Field &quot;{propName}&quot; with value &quot;
          <span style={styles.nodeValue}>{nodeValue}</span>&quot; matched the
          regex <span style={styles.regex}>{withoutEscapes}</span>
        </span>
      )
    }
  } else if (op === 'NOT_CONTAINS') {
    result.triggerCondition = (
      <span>
        Field <span style={styles.propName}>{propName}</span>
        {' does not contain'}{' '}
        {configValues.length === 1
          ? `"${configValues[0]}"`
          : `one of [${configValues.join(', ')}]`}
      </span>
    )

    if (
      (typeof nodeValue === 'string' || List.isList(nodeValue)) &&
      configValues.filter(
        configValue =>
          !nodeValue.includes(configValue) &&
          !joinedNodeVal.includes(configValue)
      ).length > 0
    ) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value{' '}
          &quot;
          <span style={styles.nodeValue}>
            {List.isList(nodeValue) ? `[${joinedNodeVal}]` : nodeValue}
          </span>
          &quot;
          {' did not contain'}{' '}
          {configValues.length === 1
            ? `"${configValues[0]}"`
            : `one of [${configValues.join(', ')}]`}
        </span>
      )
    } else if (nodeValue === null) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> was empty, and
          did not contain
          {configValues.length === 1
            ? `"${configValues[0]}"`
            : `one of [${configValues.join(', ')}]`}
        </span>
      )
    }
  } else if (op === 'CONTAINS') {
    result.triggerCondition = (
      <span>
        Field <span style={styles.propName}>{propName}</span> contains{' '}
        {configValues.length === 1
          ? `"${configValues[0]}"`
          : `one of [${configValues.join(', ')}]`}
      </span>
    )

    if (
      (typeof nodeValue === 'string' || List.isList(nodeValue)) &&
      configValues.filter(
        configValue =>
          nodeValue.includes(configValue) || joinedNodeVal.includes(configValue)
      ).length > 0
    ) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value{' '}
          &quot;
          <span style={styles.nodeValue}>
            {List.isList(nodeValue) ? `[${joinedNodeVal}]` : nodeValue}
          </span>
          &quot; contained{' '}
          {configValues.length === 1
            ? `"${configValues[0]}"`
            : `one of [${configValues.join(', ')}]`}
        </span>
      )
    }
  } else if (op === 'EQ') {
    if (config.get('dateOffset')) {
      return compareDateField(config, nodeValue, propName, alertTime, nodeType)
    }

    if (configValues.length !== 0) {
      result.triggerCondition = (
        <span>
          Field <span style={styles.propName}>{propName}</span> equals{' '}
          {configValues.map(str => `"${str}"`).join(' OR ')}
        </span>
      )
    } else {
      result.triggerCondition = (
        <span>
          Field <span style={styles.propName}>{propName}</span> equals &quot;
          {configValue}&quot;
        </span>
      )
    }

    if (List.isList(nodeValue)) {
      nodeValue.forEach(val => {
        if (
          configValues.length !== 0 &&
          configValues.filter(configValue => val === configValue).length > 0
        ) {
          result.message = (
            <span>
              Field <span style={styles.propName}>{propName}</span> with value
              <span style={styles.nodeValue}>
                [{nodeValue.toJS().join(', ')}]
              </span>{' '}
              contained one of{' '}
              {configValues.map(str => `"${str}"`).join(' OR ')}
            </span>
          )
        } else if (configValue === val) {
          result.message = (
            <span>
              Field <span style={styles.propName}>{propName}</span> with value
              <span style={styles.nodeValue}>
                [{nodeValue.toJS().join(', ')}]
              </span>{' '}
              contained &quot;
              {typeof configValue === 'boolean'
                ? configValue.toString()
                : configValue}
              &quot;
            </span>
          )
        } else if (typeof configValue === 'string' && typeof val === 'number') {
          //Handle a special case where we get the search comparison value as a string but should treat it as a number
          const numeric = parseInt(configValue, 10)

          if (!isNaN(numeric) && numeric === val) {
            result.message = (
              <span>
                Field <span style={styles.propName}>{propName}</span> with value
                <span style={styles.nodeValue}>
                  [{nodeValue.toJS().join(', ')}]
                </span>{' '}
                contained &quot;
                {configValue}&quot;
              </span>
            )
          }
        }
      })
    } else if (configValues.length === 1 && configValues[0] === nodeValue) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> equaled &quot;
          {configValues[0]}&quot;
        </span>
      )
    } else if (
      configValues.length !== 0 &&
      configValues.filter(configValue => nodeValue === configValue).length > 0
    ) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; equaled
          one of {configValues.map(str => `"${str}"`).join(' OR ')}
        </span>
      )
    } else if (configValue === nodeValue) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> equaled &quot;
          {typeof configValue === 'boolean'
            ? configValue.toString()
            : configValue}
          &quot;
        </span>
      )
    } else if (
      typeof configValue === 'string' &&
      typeof nodeValue === 'number'
    ) {
      //Handle a special case where we get the search comparison value as a string but should treat it as a number
      const numeric = parseInt(configValue, 10)

      if (!isNaN(numeric) && numeric === nodeValue) {
        result.message = (
          <span>
            Field <span style={styles.propName}>{propName}</span> equaled &quot;
            {configValue}&quot;
          </span>
        )
      }
    }
  } else if (op === 'NEQ') {
    if (configValues.length > 0) {
      result.triggerCondition = (
        <span>
          Field <span style={styles.propName}>{propName}</span> does not equal{' '}
          {configValues.map(val => `"${val}"`).join(' OR ')}
        </span>
      )
    } else if (configValues.length === 0 && configValue !== nodeValue) {
      result.triggerCondition = (
        <span>
          Field <span style={styles.propName}>{propName}</span> does not equal{' '}
          &quot;{configValue}&quot;
        </span>
      )
    }

    if (configValues.length === 1 && nodeValue !== configValues[0]) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; did not
          equal &quot;{configValues[0]}&quot;
        </span>
      )
    } else if (configValues.filter(val => nodeValue !== val).length > 0) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; did not
          equal any of {configValues.map(str => `"${str}"`).join(' OR ')}
        </span>
      )
    } else if (configValues.length === 0 && configValue !== nodeValue) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; did not
          equal &quot;{configValue}&quot;
        </span>
      )
    }
  } else if (op === 'ENDS_WITH') {
    result.triggerCondition = `Field "${propName}" ends with (${configValues.join(
      ' OR '
    )})`

    if (
      configValues.filter(
        configValue =>
          typeof nodeValue === 'string' && nodeValue.endsWith(configValue)
      ).length > 0
    ) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; ended
          with ({configValues.join(' OR ')})
        </span>
      )
    }
  } else if (op === 'STARTS_WITH') {
    result.triggerCondition = `Field "${propName}" starts with (${configValues.join(
      ' OR '
    )})`

    if (
      configValues.filter(
        configValue =>
          typeof nodeValue === 'string' && nodeValue.startsWith(configValue)
      ).length > 0
    ) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; started
          with ({configValues.join(' OR ')})
        </span>
      )
    }
  } else if (op === 'IN_LIST' && List.isList(configValue)) {
    result.triggerCondition = `"${propName}" value is in the list [${config
      .get('value')
      .join()}]`

    if (configValue.includes(nodeValue)) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; was in
          the list [{config.get('value').join()}]
        </span>
      )
    }
  } else if (op === 'NOT_IN_LIST' && List.isList(configValue)) {
    result.triggerCondition = `"${propName}" value is not in the list [${config
      .get('value')
      .join()}]`

    if (!configValue.includes(nodeValue)) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; was not
          in the list [{config.get('value').join()}]
        </span>
      )
    }
  } else if (op === 'BETWEEN' && config.get('values')) {
    const firstDate = config.get('values').get(0)
    const secondDate = config.get('values').get(1)

    result.triggerCondition = `"${propName}" date is between ${firstDate} and ${secondDate}`
    const nodeDate = moment(nodeValue)

    if (nodeDate.isBetween(firstDate, secondDate)) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          <span style={styles.nodeValue}>{nodeDate.format('lll')}</span> was
          between {firstDate} and {secondDate}
        </span>
      )
    }
  } else if (op === 'NOT_BETWEEN' && Array.isArray(configValue)) {
    const firstDate = configValue.get(0)
    const secondDate = configValue.get(1)
    result.triggerCondition = `"${propName}" date is not between ${firstDate} and ${secondDate}`

    const nodeDate = moment(nodeDate)

    if (!nodeDate.isBetween(firstDate, secondDate)) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          <span style={styles.nodeValue}>{nodeDate.format('lll')}</span> was not
          between {firstDate} and {secondDate}
        </span>
      )
    }
  } else if (op === 'LTE') {
    result.triggerCondition = `Field "${propName}" is less than or equal to "${configValue}"`

    if (configValue >= nodeValue) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; was less
          than or equal to &quot;
          {configValue}
          &quot;
        </span>
      )
    }
  } else if (op === 'GTE') {
    result.triggerCondition = `Field "${propName}" is greater than or equal to "${configValue}"`

    if (configValue <= nodeValue) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; was
          greater than or equal to &quot;
          {configValue}
          &quot;
        </span>
      )
    }
  } else if (op === 'GT') {
    result.triggerCondition = `Field "${propName}" is greater than "${configValue}"`

    if (configValue < nodeValue) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; was
          greater than &quot;
          {configValue}
          &quot;
        </span>
      )
    }
  } else if (op === 'LT') {
    result.triggerCondition = `Field "${propName}" is less than "${configValue}"`

    if (configValue > nodeValue) {
      result.message = (
        <span>
          Field <span style={styles.propName}>{propName}</span> with value
          &quot;<span style={styles.nodeValue}>{nodeValue}</span>&quot; was less
          than &quot;
          {configValue}
          &quot;
        </span>
      )
    }
  } else {
    //eslint-disable-next-line no-console
    console.warn(
      `Unknown operator given to propsOfInterestUtils.getPropOfInterest: "${op}"`
    )
  }

  return result
}

export const getFieldsOfInterest = ({
  args,
  alertTime,
  node = Map(),
  allTriggerProps,
  nodeType,
}) => {
  let propsOfInterest = []

  args.forEach(value => {
    const config = value.first(Map())
    const propName = value.keySeq().first()
    let nodeValue = node.get(propName)

    const result = getPropOfInterest(
      config,
      propName,
      nodeValue,
      alertTime,
      nodeType
    )
    if (allTriggerProps || result.message) {
      propsOfInterest.push(result)
    }
  })

  return propsOfInterest
}

export const getRelationOfInterest = (config, propName, nodeRelations) => {
  let value = config.get('value')
  const op = config.get('op')
  const typename = nodeRelations
    .get('__typename', '')
    .replace('EdgeRelation', '')

  const result = {
    field: propName,
  }

  const countValue = nodeRelations.has('count')
    ? nodeRelations.get('count')
    : nodeRelations.has('items')
    ? nodeRelations.get('items').size
    : undefined

  if (op === 'EQ' && value === 0) {
    result.triggerCondition = `There are no ${propName} relations ${
      typename ? `to a ${typename}` : ''
    }`

    if (countValue === 0) {
      result.message = `There were no ${propName} relations ${
        typename ? `to a ${typename}` : ''
      }`
    }
  } else if (op === 'GT' && value === 0) {
    result.triggerCondition = `There is at least one ${propName} relation ${
      typename ? `to a ${typename}` : ''
    }`

    if (countValue > 0) {
      result.message = `There was at least one ${propName} relation ${
        typename ? `to a ${typename}` : ''
      }`
    }
  } else if (op === 'GT' && value !== 0) {
    result.triggerCondition = `There are more than ${value} ${propName} relations ${
      typename ? `to a ${typename}` : ''
    }`

    if (countValue > value) {
      result.message = `There were more than ${value} ${propName} relations ${
        typename ? `to a ${typename}` : ''
      }`
    }
  } else if (op === 'LT') {
    if (countValue < value) {
      result.triggerCondition = `There are less than ${value} ${propName} relations ${
        typename ? `to a ${typename}` : ''
      }`

      result.message = `There was less than ${value} ${propName} relations ${
        typename ? `to a ${typename}` : ''
      }`
    }
  }

  return result
}

export const getRelationshipsOfInterest = ({
  args,
  node = Map(),
  allTriggerProps,
}) => {
  let relationsOfInterest = []

  args.forEach((value, propName) => {
    const config = value.first(Map())

    let nodeValue = node.get(propName) || Map()
    const result = getRelationOfInterest(config, propName, nodeValue)
    if (allTriggerProps || result.message) {
      relationsOfInterest.push(result)
    }
  })

  return relationsOfInterest
}

export const getFieldsOfInterestForCard = ({
  rootSearchField,
  results,
  alertTime,
  allTriggerProps,
}) => {
  const rootResultArgs = rootSearchField.get('arguments')

  const nodeArgs = rootResultArgs
    .get('and', List())
    .concat(rootResultArgs.get('or', List()))
  const importantArgs = filterOutStandardSearchArgs(nodeArgs)

  const props = getFieldsOfInterest({
    args: importantArgs,
    alertTime: alertTime,
    node: results,
    allTriggerProps,
    nodeType: rootSearchField.getIn(['definition', 'name']),
  })

  return props
}

const convertEPToArgs = effectivePermissionsMap => {
  return effectivePermissionsMap
    .toList()
    .map(epList => epList.map(perm => perm.get('arg')))
}

export const getEffectivePermissionsOfInterestForCard = ({
  rootSearchField,
  results,
  alertTime,
  allTriggerProps,
}) => {
  if (!results) {
    return []
  }

  const effectivePermissions =
    rootSearchField.get('effectivePermissions') || Map()

  const includeGroups = convertEPToArgs(
    effectivePermissions.get('include', Map())
  )

  const includedConditions = includeGroups
    .map(group => {
      const matchingPermissions = results.map(result => {
        const props = getFieldsOfInterest({
          args: group,
          alertTime: alertTime,
          node: result,
          allTriggerProps,
          nodeType: rootSearchField.getIn(['definition', 'name']),
        })

        return props.filter(poi => !!poi.message)
      })

      return matchingPermissions.first(List())
    })
    .toJS()
    .filter(groupResults => {
      return groupResults.length > 0
    })

  return includedConditions
}

export const getRelationsOfInterestForCard = ({
  rootSearchField,
  results,
  allTriggerProps,
}) => {
  const rootResultArgs = rootSearchField.get('arguments')

  let propsOfInterest = []

  const relationshipArgs = rootResultArgs.delete('and').delete('or')
  if (!relationshipArgs.isEmpty()) {
    const relationsOfInterest = getRelationshipsOfInterest({
      args: relationshipArgs,
      node: results,
      allTriggerProps,
    })

    propsOfInterest = propsOfInterest.concat(relationsOfInterest)
  }

  return propsOfInterest
}

export const getFieldsOfInterestForSearch = (
  search,
  results,
  alertTime,
  allTriggerProps
) => {
  const rootItem = results.first(Map()).getIn(['items', 0], Map())
  const fields = search.getIn(['query', 'fields'])

  const rootSearchField = fields.find(
    field => !field.get('parentId'),
    null,
    Map()
  )
  const rootResultArgs = rootSearchField.get('arguments')

  const nodeArgs = rootResultArgs
    .get('and', List())
    .concat(rootResultArgs.get('or', List()))
  const importantArgs = filterOutStandardSearchArgs(nodeArgs)

  const props = getFieldsOfInterest({
    args: importantArgs,
    alertTime: alertTime,
    node: rootItem,
    allTriggerProps,
    nodeType: rootSearchField.getIn(['definition', 'name']),
  })

  let propsOfInterest = props

  const relationshipArgs = rootResultArgs.delete('and').delete('or')
  if (!relationshipArgs.isEmpty()) {
    const relationsOfInterest = getRelationshipsOfInterest({
      args: relationshipArgs,
      node: rootItem,
      allTriggerProps,
    })

    propsOfInterest = propsOfInterest.concat(relationsOfInterest)
  }

  return propsOfInterest
}
