/**
 *
 * IHelp
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Icon from 'components/Icon'
import messages from './messages'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import MarkdownDisplay from 'components/MarkdownDisplay'
import Tooltip from 'components/Tooltip'
import BorderlessButton from 'components/BorderlessButton'

export class IHelp extends React.PureComponent {
  styles = {
    base: {
      transition: 'all 0.4s ease',
      color: this.props.theme.neutralMedium,
      ':hover': {
        color: this.props.theme.primary,
      },
    },
    icon: {
      display: 'inline-block',
      cursor: 'pointer',
      fontSize: this.props.iconSize || '22px',
    },
  }

  getIcon = () => {
    if (this.props.help) {
      return (
        <Icon
          fa
          name="help"
          style={this.styles.icon}
          color={this.props.theme[this.props.color]}
        />
      )
    } else if (this.props.info) {
      return (
        <Icon
          fa
          name="info-circle"
          style={this.styles.icon}
          color={this.props.theme[this.props.color]}
        />
      )
    }
  }

  getContent = () => {
    if (this.props.customContent) {
      return this.props.customContent()
    }
    if (this.props.help && messages[this.props.helpKey]) {
      return <FormattedMessage {...messages[this.props.helpKey]} />
    } else if (this.props.info && typeof this.props.infoMsg === 'string') {
      return <MarkdownDisplay content={this.props.infoMsg} />
    } else if (this.props.info) {
      return this.props.infoMsg
    } else {
      return this.props.helpKey || 'No help key or info message provided'
    }
  }

  render() {
    return (
      <Tooltip
        styles={
          this.props.width
            ? {
                maxWidth: this.props.width,
                width: this.props.width,
                ...this.props.style,
              }
            : { ...this.props.style }
        }
        trigger={this.props.info ? 'click' : 'hover'}
        anchor={
          <BorderlessButton id={this.props.id}>
            {this.getIcon()}
          </BorderlessButton>
        }
        tooltipContent={this.getContent()}
        position={this.props.position}
      />
    )
  }
}

IHelp.propTypes = {
  customContent: PropTypes.func,
  id: PropTypes.string.isRequired,
  theme: themeShape,
  style: PropTypes.object,
  helpKey: PropTypes.string,
  infoMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  help: PropTypes.bool,
  info: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  position: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  width: PropTypes.string,
  iconSize: PropTypes.string,
}

export default themeable(IHelp)
