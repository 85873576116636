/**
 * the auth modes aren't used in actions, they're constants for howto display
 * the authentication modes on the form to submit the job:
 */
// user must supply username and azure keyvault secret & path:
export const AUTH_MODE_USERNAME_KV = `AUTH_MODE_USERNAME_KV`
// user must supply username and aws secret manager & path:
export const AUTH_MODE_USERNAME_SECRETMGR = `AUTH_MODE_USERNAME_SECRETMGR`

export const CLASSIFICATION_TYPE_DB = 'DB'
export const CLASSIFICATION_TYPE_BUCKET = 'Bucket'

// the rest of the constants are probbaly for redux actions:

export const RESET_JOB_REQUEST_SUCCESS = `app/containers/DataClassification/RESET_JOB_REQUEST_SUCCESS`

export const SET_PRIVATE_KEY = `app/containers/DataClassification/SET_PRIVATE_KEY`

export const SET_PLATFORM_ACCOUNT = `app/containers/DataClassification/SET_PLATFORM_ACCOUNT`

export const SUBMIT_JOB = `app/containers/DataClassification/SUBMIT_JOB`
export const SUBMIT_JOB_SUCCESS = `app/containers/DataClassification/SUBMIT_JOB_SUCCESS`
export const SUBMIT_JOB_ERROR = `app/containers/DataClassification/SUBMIT_JOB_ERROR`
