import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { DEFAULT_ACTION_CLASSIFICATIONS_TO_ENABLE } from 'appConstants'
import _ from 'lodash'
import color from 'color'
import { fromJS } from 'immutable'
import qs from 'query-string'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { getDefaultChangeDetectionPropertiesByType } from 'utils/sonraiUtils'
import BorderlessButton from 'components/BorderlessButton'
import ChangeDetectionModal from 'components/ChangeDetectionModal'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'

import {
  addChangeDetectionProperties,
  removeChangeDetectionProperties,
  toggleResourceMonitoring,
  updateChangeDetectionProperty,
} from './actions'

import { makeSelectCrmData } from './selectors'

import messages from './messages'

export class ChangeDetectionActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      driftDetectionModal: false,
    }

    this.styles = {
      container: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: props.direction === 'horizontal' ? '' : 'column',
        marginLeft: '4px',
        marginBottom: '4px',
      },
      button: {
        height: '1.85rem',
        fontSize: '0.85rem',
        border: `1px solid ${this.props.theme.primary}`,
        padding: '0.25rem 0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: props.direction === 'horizontal' ? '5px' : '',
        color: color(props.theme.neutralDark).darken(0.6),
      },
      toggle: {
        marginTop: '1rem',
      },
    }
  }

  handleAddChangeDetectionProperties = payload => {
    this.props.addChangeDetectionProperties({
      ...payload,
      onNodeView: this.props.onNodeView,
      srn: this.props.nodeId,
    })
  }

  handleDriftDetectionToggle = () => {
    this.setState(oldState => ({
      driftDetectionModal: !oldState.driftDetectionModal,
    }))
  }

  handleCloseDriftDetectionToggle = () => {
    this.setState({
      driftDetectionModal: false,
    })
  }

  handleDefaultChangeDetection = () => {
    const { __typename } = this.props.nodeData
    const { changeDetectionOptions } = this.props.crmData
    const defaultProperties = getDefaultChangeDetectionPropertiesByType(
      __typename
    )
    const selected = changeDetectionOptions.filter(option =>
      defaultProperties.includes(option.keyName.toLowerCase())
    )

    selected.forEach(option => {
      option.alertLevel = 5
      if (!_.isEmpty(option.actionClassification)) {
        option.actionClassification = DEFAULT_ACTION_CLASSIFICATIONS_TO_ENABLE
      }
    })

    this.handleAddChangeDetectionProperties({
      properties: fromJS(selected),
      resourceSrn: this.props.nodeId,
    })
  }

  handleMonitorChange = () => {
    const { monitored, changeDetectionProperties } = this.props.crmData
    const isMonitored = monitored
    this.props.push({
      search: qs.stringify({
        ...qs.parse(this.props.location.search),
        showAccessActivity: !isMonitored,
      }),
      state: this.props.location.state,
    })

    this.props.toggleResourceMonitoring({
      srn: this.props.nodeId,
      isMonitored: isMonitored,
      onNodeView: this.props.onNodeView,
    })

    if (!isMonitored && _.isEmpty(changeDetectionProperties)) {
      this.handleDefaultChangeDetection()
    }
  }

  handleRemoveChangeDetectionProperties = payload => {
    this.props.removeChangeDetectionProperties({
      ...payload,
      onNodeView: this.props.onNodeView,
      srn: this.props.nodeId,
    })
  }

  handleUpdateChangeDetectionProperty = payload => {
    this.props.updateChangeDetectionProperty({
      ...payload,
      onNodeView: this.props.onNodeView,
      srn: this.props.nodeId,
    })
  }

  render() {
    const isMonitored = this.props.crmData.monitored

    const {
      changeDetectionProperties,
      changeDetectionOptions,
    } = this.props.crmData
    const hasChangeDetectionEnabled = changeDetectionProperties.length > 0

    const buttomMessage = isMonitored
      ? messages.disableMonitoring
      : messages.enableMonitoring
    return [
      <div key="monitoring-button" style={this.styles.container}>
        <BorderlessButton
          onClick={this.handleMonitorChange}
          style={this.styles.button}
        >
          <div style={{ paddingRight: '0.5rem' }}>
            <Icon
              fa
              name="clock"
              style={{
                fontSize: '0.95rem',
                color: isMonitored
                  ? this.props.theme.red
                  : this.props.theme.primary,
              }}
            />
          </div>
          <DynamicFormattedMessage {...buttomMessage} />
        </BorderlessButton>
      </div>,
      <div key="config-button" style={this.styles.container}>
        {isMonitored && (
          <BorderlessButton
            onClick={this.handleDriftDetectionToggle}
            style={this.styles.button}
          >
            <div style={{ padding: '0rem 0.5rem 0rem 0.25rem' }}>
              <Icon
                fa
                name={hasChangeDetectionEnabled ? 'lock' : 'lock-open'}
                style={{
                  fontSize: '0.95rem',
                  color: hasChangeDetectionEnabled
                    ? this.props.theme.emphasis
                    : '#888',
                }}
              />
            </div>
            {hasChangeDetectionEnabled
              ? 'Manage Change Detection'
              : 'Enable Change Detection'}
          </BorderlessButton>
        )}
        <ChangeDetectionModal
          removeChangeDetectionProperties={
            this.handleRemoveChangeDetectionProperties
          }
          resourceSrn={this.props.nodeId}
          isOpen={this.state.driftDetectionModal}
          toggle={this.handleDriftDetectionToggle}
          close={this.handleCloseDriftDetectionToggle}
          changeDetectionProperties={fromJS(changeDetectionProperties)}
          changeDetectionOptions={fromJS(changeDetectionOptions)}
          addChangeDetectionProperties={this.handleAddChangeDetectionProperties}
          isLoading={this.props.isLoading}
          updateChangeDetectionProperty={
            this.handleUpdateChangeDetectionProperty
          }
        />
      </div>,
    ]
  }
}

ChangeDetectionActions.propTypes = {
  theme: themeShape,
  isLoading: PropTypes.bool,
  // ~ passed props ~
  direction: PropTypes.string,
  nodeData: PropTypes.object,
  nodeId: PropTypes.string.isRequired,
  onNodeView: PropTypes.bool, // see comment in NodeViewHeader

  // ~ bound action creators ~
  addChangeDetectionProperties: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  removeChangeDetectionProperties: PropTypes.func.isRequired,
  toggleResourceMonitoring: PropTypes.func.isRequired,
  updateChangeDetectionProperty: PropTypes.func.isRequired,

  // ~ props from redux store ~
  crmData: PropTypes.shape({
    error: PropTypes.bool,
    monitored: PropTypes.bool,
    importance: PropTypes.number,
    // TODO - change this to shape below?
    changeDetectionProperties: PropTypes.arrayOf(PropTypes.object),
    changeDetectionOptions: PropTypes.arrayOf(PropTypes.object),
  }),
  location: PropTypes.object.isRequired,
}

const makeMapStateToProps = () => {
  const selectCrmData = makeSelectCrmData()
  return createStructuredSelector({
    crmData: selectCrmData,
  })
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addChangeDetectionProperties,
      push,
      removeChangeDetectionProperties,
      toggleResourceMonitoring,
      updateChangeDetectionProperty,
    },
    dispatch
  )
}

const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  withRouter,
  themeable
)(ChangeDetectionActions)
