import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

const RectangleShimmer = ({ width = 50, height = 30, ...props }) => {
  return (
    <span
      style={{ display: 'block', width: `${width}px`, height: `${height}px` }}
    >
      <ContentLoader
        height={height}
        width={width}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="0" rx="3" ry="3" width={width} height={height} />
      </ContentLoader>
    </span>
  )
}

RectangleShimmer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default RectangleShimmer
