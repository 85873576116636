import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

export const selectDataClassifierDataDomain = state =>
  state.get('dataClassifierData', Map())

export const selectFetchingClassifiers = createSelector(
  selectDataClassifierDataDomain,
  state => state.get('fetchingClassifiers')
)

export const selectCustomClassifiers = createSelector(
  selectDataClassifierDataDomain,
  state => state.get('customClassifiers') || List()
)

export const selectDeletingCustomClassifiers = createSelector(
  selectDataClassifierDataDomain,
  state => state.get('deletingCustomClassifiers') || Map()
)

export const selectDeletingCustomClassifiersError = createSelector(
  selectDataClassifierDataDomain,
  state => state.get('deletingCustomClassifiersError')
)

export const selectUpdatingCustomClassifiers = createSelector(
  selectDataClassifierDataDomain,
  state => state.get('updatingCustomClassifiers')
)

export const selectCreatingClassifier = createSelector(
  selectDataClassifierDataDomain,
  state => state.get('creatingClassifier')
)

export const selectCreatingClassifierError = createSelector(
  selectDataClassifierDataDomain,
  state => state.get('creatingClassifierError')
)
