export const LOAD_USER = 'app/UserProfileData/LOAD_USER'
export const FETCH_USER_PROFILE = 'app/UserProfileData/FETCH_USER_PROFILE'
export const SET_USER_PROFILE = 'app/UserProfileData/SET_USER_PROFILE'
export const SAVE_USER_PROFILE = 'app/UserProfileData/SAVE_USER_PROFILE'
export const HIDE_SOLUTION_CARD = 'app/UserProfileData/HIDE_SOLUTION_CARD'
export const UNHIDE_SOLUTION_CARDS = 'app/UserProfileData/UNHIDE_SOLUTION_CARDS'
export const SET_USER_PROFILE_TABLES =
  'app/UserProfileData/SET_USER_PROFILE_TABLES'
export const ACCEPT_LICENSE = 'app/UserProfileData/ACCEPT_LICENSE'
export const SET_SECURITY_CENTER_WELCOME_MODAL =
  'app/UserProfileData/SET_SECURITY_CENTER_WELCOME_MODAL'
export const TOGGLE_SWIMLANE_MANAGER =
  'app/UserProfileData/TOGGLE_SWIMLANE_MANAGER'
export const SET_SONRAI_USER_SRN = 'app/UserProfileData/SET_SONRAI_USER_SRN'
export const SET_USER_PROFILE_ERROR =
  'app/UserProfileData/SET_USER_PROFILE_ERROR'
