import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import {
  GET_ROLE_CONTENT,
  SET_ROLE_CONTENT,
  GET_ACTIONS_PERFORMED,
  SET_ACTIONS_PERFORMED,
} from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  roleContent: {},
  isLoading: false,
  actionsPerformed: [],
  loadingActions: false,
})

const roleNodeViewCardLayoutReducer = handleActions(
  {
    [GET_ROLE_CONTENT]: state => state.set('isLoading', true),
    [SET_ROLE_CONTENT]: (state, { payload }) => {
      return state.set('roleContent', fromJS(payload)).set('isLoading', false)
    },
    [GET_ACTIONS_PERFORMED]: state => state.set('loadingActions', true),
    [SET_ACTIONS_PERFORMED]: (state, { payload }) => {
      return state
        .set('actionsPerformed', fromJS(payload))
        .set('loadingActions', false)
    },
    [CLEAR_DATA]: state => state.set('roleContent', Map()),
  },
  initialState
)

export default roleNodeViewCardLayoutReducer
