import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { List } from 'immutable'

import TextLink from 'components/TextLink'
import Button from 'components/Button'
import EditPolicyModal from 'containers/PolicyEdit/EditPolicyModal'
import permissionChecker from 'containers/PermissionChecker'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import { getCurrentOrg } from 'auth/current-org'
import { SONRAI_ORG_NAME } from 'appConstants'

export class PolicyActions extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      button: {
        margin: '0 0.3em',
      },
    }

    this.state = {
      editPolicy: false,
      deleteModalOpen: false,
    }
  }

  toggleDeleteModal = () => {
    this.setState(oldState => ({
      deleteModalOpen: !oldState.deleteModalOpen,
    }))
  }

  closeDeleteModal = () => {
    this.setState({ deleteModalOpen: false })
  }

  handleEditPolicy = () => {
    this.setState(oldState => ({ editPolicy: !oldState.editPolicy }))
  }

  handleDeletePolicy = () => {
    this.closeDeleteModal()
    this.props.deletePolicy(this.props.policy.get('srn'))
  }

  handleRemovePolicy = () => {
    this.props.removePolicy(this.props.policy.get('srn'))
  }

  handleClonePolicy = () => {
    this.props.clonePolicy(this.props.policy.get('srn'))
  }

  getDeleteModal = () => {
    const usedInSrns = this.props.policy
      .getIn(['containedByControlFramework', 'items'], List())
      .map(relation => relation.get('srn'))

    return (
      <Modal
        toggle={this.toggleDeleteModal}
        isOpen={this.state.deleteModalOpen}
      >
        <ModalHeader>Confirm Policy Deletion</ModalHeader>
        <ModalBody>
          {usedInSrns.size <= 1 ? (
            'This will delete this policy from the system. This cannot be undone.'
          ) : (
            <div>
              This policy is used in {usedInSrns.size} frameworks. Deleting it
              will remove it from all frameworks. This cannot be undone.
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <TextLink onClick={this.closeDeleteModal}>Cancel</TextLink>
          <Button color="danger" onClick={this.handleDeletePolicy}>
            Continue Delete
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  render() {
    const isSonrai =
      this.props.policy.getIn(
        ['ownedByOrganization', 'items', 0, 'sid'],
        false
      ) === SONRAI_ORG_NAME
    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.controlframeworks',
      resourceId: this.props.policy.get('resourceId'),
    })
    const canDelete = this.props.userHasPermission({
      permissionName: 'edit.controlframeworks',
      resourceId: this.props.policy.get('resourceId'),
    })

    const currentOrg = getCurrentOrg()

    if (
      (isSonrai && currentOrg !== SONRAI_ORG_NAME) ||
      (!canEdit && !canDelete)
    ) {
      return (
        <div>
          <span
            style={{ fontSize: '0.9em' }}
            title={
              isSonrai
                ? 'Cannot delete or edit a Sonrai Policy'
                : 'Insufficient permissions to edit or delete'
            }
          >
            <Icon
              fa
              name="lock"
              style={{ margin: '0rem 0.5rem 0rem 0rem', fontSize: '1.1rem' }}
              color={this.props.theme.neutralMedium}
            />
          </span>

          <BorderlessButton
            color="secondary"
            title="Clone Policy"
            onClick={this.handleClonePolicy}
            style={this.styles.button}
          >
            <Icon fa name="copy" />
          </BorderlessButton>

          <BorderlessButton
            color="secondary"
            onClick={this.handleRemovePolicy}
            title="Remove from control framework"
            style={{ ...this.styles.button, ...{ marginRight: '1rem' } }}
          >
            <Icon fa name="times" />
          </BorderlessButton>
        </div>
      )
    }

    return (
      <Fragment>
        {this.getDeleteModal()}
        <EditPolicyModal
          isOpen={this.state.editPolicy}
          policy={this.props.policy}
          toggle={this.handleEditPolicy}
        />
        <div>
          {!canEdit ? (
            <span
              style={{ fontSize: '0.9em' }}
              title="Insufficient Permissions to Edit"
            >
              <Icon fa name="lock" color={this.props.theme.neutralMedium} />
            </span>
          ) : (
            <BorderlessButton
              color="secondary"
              onClick={this.handleEditPolicy}
              title="Edit Policy"
              style={this.styles.button}
            >
              <Icon fa name="pencil-alt" />
            </BorderlessButton>
          )}

          {!canDelete ? (
            <span
              style={{ fontSize: '0.9em' }}
              title="Insufficient Permissions to Delete"
            >
              <Icon fa name="lock" color={this.props.theme.neutralMedium} />
            </span>
          ) : (
            <BorderlessButton
              color="secondary"
              onClick={this.toggleDeleteModal}
              title="Delete Policy"
              style={this.styles.button}
            >
              <Icon fa name="trash" />
            </BorderlessButton>
          )}

          <BorderlessButton
            color="secondary"
            title="Clone Policy"
            onClick={this.handleClonePolicy}
            style={this.styles.button}
          >
            <Icon fa name="copy" />
          </BorderlessButton>

          <BorderlessButton
            color="secondary"
            onClick={this.handleRemovePolicy}
            title="Remove from control framework"
            style={this.styles.button}
          >
            <Icon fa name="times" />
          </BorderlessButton>
        </div>
      </Fragment>
    )
  }
}

PolicyActions.propTypes = {
  userHasPermission: PropTypes.func.isRequired,
  clonePolicy: PropTypes.func.isRequired,
  removePolicy: PropTypes.func,
  deletePolicy: PropTypes.func,
  policy: ImmutablePropTypes.contains({
    srn: PropTypes.string,
    resourceId: PropTypes.string,
    ownedByOrganization: ImmutablePropTypes.contains({
      items: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
          sid: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  theme: themeShape,
}

export default permissionChecker(themeable(PolicyActions))
