import _ from 'lodash'
import color from 'color'

import { getAlertLevelColorHML } from 'utils/widgetUtils'
import {
  colorForString as colorForStringLib,
  colorForEntityString,
  exists,
} from 'utils/sonraiUtils'
import { SEVERITY_CATEGORIES } from 'appConstants'

const ALERT_TYPES = {
  POLICY: 'Policy',
  CRM: 'CRM',
  FRAMEWORK: 'Framework',
  ACCESS: 'Access',
  PROPERTY: 'Property',
  ACTIVITY: 'Activity',
}

const formatMap = map => {
  Object.keys(map).forEach(
    key =>
      (!exists(map[key]) || key === 'null' || key === 'undefined') &&
      delete map[key]
  )
  return map
}

export const sliceBySeverity = (tickets, options) => {
  const groupingMap = formatMap(
    _.groupBy(tickets, ticket => ticket.severityCategory)
  )
  return _.keys(groupingMap).map(key => {
    const childrenValues = groupingMap[key]

    return {
      name: getTitleForSeverity(key),
      hex: getAlertLevelColorHML(key),
      children: groupByTicketType(childrenValues, {
        ...options,
        severity: key,
      }),
      keyName: 'severityCategory',
      keyValue: key,
      value: childrenValues.reduce((sum, ticket) => (sum += ticket.count), 0),
    }
  })
}

const groupByTicketType = (tickets, options) => {
  const groupingMap = formatMap(_.groupBy(tickets, ticket => ticket.ticketType))

  return _.keys(groupingMap).map(ticketType => {
    const childrenValues = groupingMap[ticketType]

    return {
      name: getTitleForAlertType(ticketType),
      hex: getColorForAlertType(ticketType),
      children: groupByTicketKey(childrenValues, options),
      value: childrenValues.reduce((sum, alert) => (sum += alert.count), 0),
      keyName: 'ticketType',
      keyValue: ticketType,
    }
  })
}

const groupByTicketKey = (tickets, options) => {
  const groupingMap = formatMap(_.groupBy(tickets, ticket => ticket.ticketKey))

  return _.keys(groupingMap).map(ticketKey => {
    const childrenValues = groupingMap[ticketKey]
    const sample = childrenValues[0]

    let name = ticketKey
    const { controlPolicies, controlGroups } = options
    if (controlPolicies && controlGroups) {
      name =
        controlGroups.getIn([ticketKey, 'title']) ||
        controlPolicies.getIn([ticketKey, 'title']) ||
        ticketKey
    }

    const sliceColor = colorForStringLib(name)
    return {
      name,
      hex: options.colorBySeverity
        ? shiftColorForSeverity(sliceColor, options.severity)
        : sliceColor,
      children: groupByLabel(childrenValues, options),
      value: childrenValues.reduce((sum, alert) => (sum += alert.count), 0),
      keyName:
        sample.ticketType === 'Framework' && sample.ticketKey.startsWith('srn')
          ? 'controlFrameworkId'
          : 'ticketKey',
      keyValue: ticketKey,
    }
  })
}

const groupByLabel = (alerts, options) => {
  const groupingMap = formatMap(_.groupBy(alerts, alert => alert.resourceLabel))

  return _.keys(groupingMap).map(keyName => {
    const childrenValues = groupingMap[keyName]

    return {
      name: keyName,
      hex: colorForEntityString(keyName, options.typeColors),
      children: groupBySwimlane(childrenValues, options),
      value: childrenValues.reduce((sum, alert) => (sum += alert.count), 0),
      keyName: 'resourceLabel',
      keyValue: keyName,
    }
  })
}

const groupBySwimlane = (alerts, options) => {
  const groupingMap = formatMap(
    _.groupBy(alerts, alert => {
      return _.first(alert.swimlaneSrns)
    })
  )

  return _.keys(groupingMap).map(key => {
    const childrenValues = groupingMap[key]
    return {
      name: options.swimlanes.getIn([key, 'title']),
      hex: colorForStringLib(key),
      keyName: 'swimlaneSrns',
      keyValue: key,
      value: childrenValues.reduce((sum, alert) => (sum += alert.count), 0),
    }
  })
}

const shiftColorForSeverity = (originalColor, HMLSeverity) => {
  const baseColor = color(getAlertLevelColorHML(HMLSeverity))
  const baseHue = baseColor.hue()

  return color(originalColor).hue(baseHue).hex()
}

const getColorForAlertType = type => {
  switch (type) {
    case ALERT_TYPES.POLICY:
    case ALERT_TYPES.FRAMEWORK:
      return '#231E36'
    case ALERT_TYPES.CRM:
    case ALERT_TYPES.ACCESS:
    case ALERT_TYPES.PROPERTY:
    case ALERT_TYPES.ACTIVITY:
      return '#806bd6'
    default:
      return colorForStringLib(type)
  }
}

const getTitleForAlertType = type => {
  switch (type) {
    case ALERT_TYPES.POLICY:
    case ALERT_TYPES.FRAMEWORK:
      return 'Control Framework'
    case ALERT_TYPES.CRM:
    case ALERT_TYPES.ACCESS:
    case ALERT_TYPES.PROPERTY:
    case ALERT_TYPES.ACTIVITY:
      return 'Critical Resource Monitor'
    default:
      return type
  }
}

export const getTitleForSeverity = severity => {
  switch (severity) {
    case SEVERITY_CATEGORIES.HIGH:
      return 'High Severity'
    case SEVERITY_CATEGORIES.MEDIUM:
      return 'Medium Severity'
    case SEVERITY_CATEGORIES.LOW:
      return 'Low Severity'
    default:
      return severity
  }
}
