import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import {
  SET_TICKET_LIST_FILTER_PANEL,
  CLEAR_TICKET_LIST_FILTER,
} from './constants'
import _ from 'lodash'

const initialState = fromJS({
  ticketListFilters: {},
})

const ticketListFilterPanelReducer = handleActions(
  {
    [SET_TICKET_LIST_FILTER_PANEL]: (state, { payload }) => {
      const newState = _.keys(payload).reduce((nextState, type) => {
        const currentState = nextState.get('ticketListFilters')
        const value = payload[type]
        const updatedState =
          _.isEmpty(value) && currentState.get(type)
            ? currentState.delete(type)
            : currentState.set(type, fromJS(value))
        return nextState.set('ticketListFilters', updatedState)
      }, state)
      return newState
    },

    [CLEAR_TICKET_LIST_FILTER]: () => initialState,
  },
  initialState
)

export default ticketListFilterPanelReducer
