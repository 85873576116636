import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-spring/renderprops.cjs'

import Card from 'components/Card'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'

export class SlideoutPanel extends React.PureComponent {
  styles = {
    container: {
      width: '100%',
      position: 'relative',
      overflow: 'auto',
    },
    card: {
      padding: '0',
      minWidth: '0px',
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
      transition: 'all 2s',
    },
    scrollWrapper: {
      maxHeight: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: '0 0 1em 1em',
    },
    actionsContainer: {
      padding: '0.5em 1em',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
  }

  render() {
    return (
      <Transition
        from={{ left: '101%' }}
        enter={{ left: '0%' }}
        leave={{ left: '101%' }}
        items={this.props.visible}
      >
        {show =>
          show &&
          (styles => (
            <div
              style={{
                ...this.styles.container,
                ...styles,
                ...this.props.style,
              }}
            >
              <Card containerStyle={this.styles.card}>
                <div style={this.styles.actionsContainer}>
                  <div>{this.props.actions}</div>
                  <BorderlessButton
                    onClick={this.props.toggleVisible}
                    style={this.styles.closeButton}
                  >
                    <Icon fa name="times" />
                  </BorderlessButton>
                </div>
                <div style={this.styles.scrollWrapper}>
                  {this.props.children}
                </div>
              </Card>
            </div>
          ))
        }
      </Transition>
    )
  }
}

SlideoutPanel.defaultProps = {
  style: {},
}

SlideoutPanel.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  visible: PropTypes.bool,
  toggleVisible: PropTypes.func,
  style: PropTypes.object,
}

export default SlideoutPanel
