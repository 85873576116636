import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import DataTable from 'components/DataTable'
import TextLink from 'components/TextLink'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

class UnderlyingActionsModal extends Component {
  getData = data => {
    if (data) {
      return data
    }
    return []
  }
  render() {
    const {
      isOpen,
      toggle,
      selectedRowActions,
      selectedRowActionsLoading,
    } = this.props

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        style={{
          width: '85vw',
          maxWidth: '85vw',
        }}
      >
        <ModalHeader toggle={toggle}>{this.props.title}</ModalHeader>
        <ModalBody
          style={{
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
          }}
        >
          {!selectedRowActionsLoading ? (
            <DataTable
              hiddenColumns={['srn']}
              sortColumns
              style={{
                height: selectedRowActions.length > 0 ? '60vh' : '60px',
              }}
              onClickNodeView={this.props.onClickNodeView}
              data={selectedRowActions}
            />
          ) : (
            <SquareLoadingAnimation />
          )}
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={toggle}>
            Close
          </TextLink>
        </ModalFooter>
      </Modal>
    )
  }
}

UnderlyingActionsModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedRowActions: ImmutablePropTypes.iterable,
  selectedRowActionsLoading: PropTypes.bool,
  onClickNodeView: PropTypes.func,
  title: PropTypes.string,
}

export default UnderlyingActionsModal
