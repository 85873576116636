export const SET_RELATIONS = 'app/SonraiData/SET_RELATIONS'
export const SET_TYPES = 'app/SonraiData/SET_TYPES'
export const RUN_INSPECTION_QUERY = 'app/SonraiData/RUN_INSPECTION_QUERY'
export const FETCH_SAVED_SEARCHES = 'app/SonraiData/FETCH_SAVED_SEARCHES'
export const FETCH_SAVED_SEARCHES_DETAILS =
  'app/SonraiData/FETCH_SAVED_SEARCHES_DETAILS,'
export const SET_SAVED_SEARCHES = 'app/SonraiData/SET_SAVED_SEARCHES'
export const SET_ALLOWED_ROOT_QUERIES =
  'app/SonraiData/SET_ALLOWED_ROOT_QUERIES'
export const SET_SAVED_SEARCH = 'all/SonraiData/SET_SAVED_SEARCH'
export const FETCH_SONRAI_SEARCHES = 'app/SonraiData/FETCH_SONRAI_SEARCHES'
export const SET_SONRAI_SEARCHES = 'app/SonraiData/SET_SONRAI_SEARCHES'
export const FETCH_ACCOUNTS = 'app/SonraiData/FETCH_ACCOUNTS'
export const FETCH_ACCOUNTS_SUCCESS = 'app/SonraiData/FETCH_ACCOUNTS_SUCCESS'
export const FETCH_SUBSCRIPTIONS = 'app/SonraiData/FETCH_SUBSCRIPTIONS'
export const FETCH_SUBSCRIPTIONS_SUCCESS =
  'app/SonraiData/FETCH_SUBSCRIPTIONS_SUCCESS'
export const FETCH_TAGS = 'app/SonraiData/FETCH_TAGS'
export const FETCH_TAGS_SUCCESS = 'app/SonraiData/FETCH_TAGS_SUCCESS'
export const LOAD_TAG = 'app/SonraiData/LOAD_TAG'
export const SET_PIVOT = 'app/SolutionCenter/SET_PIVOT'
export const FETCH_DATA_CONTAINERS = 'app/SonraiData/FETCH_DATA_CONTAINERS'
export const FETCH_DATA_CONTAINERS_SUCCESS =
  'app/SonraiData/FETCH_DATA_CONTAINERS_SUCCESS'
export const START_UPDATE_DATA_POLL = 'app/SonraiData/START_UPDATE_DATA_POLL'
export const STOP_UPDATE_DATA_POLL = 'app/SonraiData/STOP_UPDATE_DATA_POLL'
export const SET_QUERY_NAMES = 'app/SonraiData/SET_QUERY_NAMES'
export const SET_GRAPHQL_SCHEMA = 'app/SonraiData/SET_GRAPHQL_SCHEMA'
export const FETCH_GRAPHQL_SCHEMA = 'app/SonraiData/FETCH_GRAPHQL_SCHEMA'
export const REMOVE_SAVED_SEARCH = 'app/SonraiData/REMOVE_SAVED_SEARCH'
export const CHECK_IF_HAS_COLLECTORS = 'app/SonraiData/CHECK_IF_HAS_COLLECTORS'
export const SET_HAS_COLLECTORS = 'app/SonraiData/SET_HAS_COLLECTORS'
export const GET_MONITORED_RESOURCES_COUNT =
  'app/SonraiData/GET_MONITORED_RESOURCES_COUNT'
export const SET_MONITORED_RESOURCES_COUNT =
  'app/SonraiData/SET_MONITORED_RESOURCES_COUNT'
export const FETCH_SWIMLANES = 'app/SonraiData/FETCH_SWIMLANES'
export const FETCH_SWIMLANES_SUCCESS = 'app/SonraiData/FETCH_SWIMLANES_SUCCESS'
export const ADD_SWIMLANE = 'app/SonraiData/ADD_SWIMLANE'
export const REMOVE_SWIMLANE = 'app/SonraiData/REMOVE_SWIMLANE'
export const REMOVE_SWIMLANE_SUCCESS = 'app/SonraiData/REMOVE_SWIMLANE_SUCCESS'
export const UPDATE_SWIMLANE = 'app/SonraiData/UPDATE_SWIMLANE'
export const SET_GLOBAL_ERROR = 'app/SonraiData/SET_GLOBAL_ERROR'
export const FETCH_SONRAI_USERS = 'app/SonraiData/FETCH_SONRAI_USERS'
export const FETCH_SONRAI_USERS_SUCCESS =
  'app/SonraiData/FETCH_SONRAI_USERS_SUCCESS'
export const UPDATE_SONRAI_USER = 'app/SonraiData/UPDATE_SONRAI_USER'
export const UPDATE_SONRAI_USER_SUCCESS =
  'app/SonraiData/UPDATE_SONRAI_USER_SUCCESS'
export const GET_ALL_ROLES = 'app/SonraiData/GET_ALL_ROLES'
export const SET_ALL_ROLES = 'app/SonraiData/SET_ALL_ROLES'
export const ERR_ALL_ROLES = 'app/SonraiData/ERR_ALL_ROLES'
export const UPDATE_USER_ROLES = 'app/SonraiData/UPDATE_USER_ROLES'
export const UPDATE_USER_ROLES_SUCCESS =
  'app/SonraiData/UPDATE_USER_ROLES_SUCCESS'

export const GET_BOTS = 'app/BotManagement/GET_BOTS'
export const SET_BOTS = 'app/BotManagement/SET_BOTS'
export const CREATE_BOT = 'app/BotManagement/CREATE_BOT'
export const CREATE_BOT_SUCCESS = 'app/BotManagement/CREATE_BOT_SUCCESS'
export const UPDATE_BOT_ASSIGNMENT = 'app/BotManagement/UPDATE_BOT_ASSIGNMENT'
export const UPDATE_BOT_ASSIGNMENT_SUCCESS =
  'app/BotManagement/UPDATE_BOT_ASSIGNMENT_SUCCESS'

export const GET_EXEMPTED_IDENTITIES =
  'app/BotManagement/GET_EXEMPTED_IDENTITIES'
export const SET_EXEMPTED_IDENTITIES =
  'app/BotManagement/SET_EXEMPTED_IDENTITIES'
export const SET_EXEMPTED_IDENTITIES_ERROR =
  'app/BotManagement/SET_EXEMPTED_IDENTITIES_ERROR'

export const ADD_EXEMPTED_IDENTITIES =
  'app/BotManagement/ADD_EXEMPTED_IDENTITIES'
export const ADD_EXEMPTED_IDENTITIES_SUCCESS =
  'app/BotManagement/ADD_EXEMPTED_IDENTITIES_SUCCESS'

export const DELETE_EXEMPTED_IDENTITIES =
  'app/BotManagement/DELETE_EXEMPTED_IDENTITIES'
export const DELETE_EXEMPTED_IDENTITIES_SUCCESS =
  'app/BotManagement/DELETE_EXEMPTED_IDENTITIES_SUCCESS'

export const VALIDATE_SOURCE_URL = 'app/BotManagement/VALIDATE_SOURCE_URL'
export const VALIDATE_SOURCE_URL_SUCCESS =
  'app/BotManagement/VALIDATE_SOURCE_URL_SUCCESS'
export const CLEAR_SOURCE_URL_VALIDATION =
  'app/BotManagement/CLEAR_SOURCE_URL_VALIDATION'

export const HANDLE_DELETE_BOTS = 'app/BotManagement/HANDLE_DELETE_BOTS'
export const HANDLE_DELETE_BOTS_SUCCESS =
  'app/BotManagement/HANDLE_DELETE_BOTS_SUCCESS'

export const FETCH_OBJECTIVES = 'app/SonraiData/FETCH_OBJECTIVES'
export const FETCH_OBJECTIVES_ERROR = 'app/SonraiData/FETCH_OBJECTIVES_ERROR'
export const SET_OBJECTIVES = 'app/SonraiData/SET_OBJECTIVES'

export const ENABLE_OBJECTIVE = 'app/SonraiData/ENABLE_OBJECTIVE'
export const ENABLE_OBJECTIVE_SUCCESS =
  'app/SonraiData/ENABLE_OBJECTIVE_SUCCESS'
export const ENABLE_OBJECTIVE_ERROR = 'app/SonraiData/ENABLE_OBJECTIVE_ERROR'

export const ENABLE_ALL_OBJECTIVES = 'app/SonraiData/ENABLE_ALL_OBJECTIVES'
export const ENABLE_ALL_OBJECTIVES_SUCCESS =
  'app/SonraiData/ENABLE_ALL_OBJECTIVES_SUCCESS'
export const ENABLE_ALL_OBJECTIVES_ERROR =
  'app/SonraiData/ENABLE_ALL_OBJECTIVES_ERROR'

export const ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE =
  'app/SonraiData/ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE'
export const ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_SUCCESS =
  'app/SonraiData/ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_SUCCESS'
export const ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_ERROR =
  'app/SonraiData/ENABLE_ALL_OBJECTIVES_FOR_SWIMLANE_ERROR'

export const FETCH_SONRAI_CONFIG = 'app/SonraiData/FETCH_SONRAI_CONFIG'
export const SET_SONRAI_CONFIG = 'app/SonraiData/SET_SONRAI_CONFIG'
