import { all, put, select, takeLatest } from 'redux-saga/effects'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'
import { List, Map } from 'immutable'
import _ from 'lodash'

import {
  setEscalations,
  setEscalationsError,
  fetchEscalationOptionsSuccess,
  fetchEscalationOptionsError,
  createEscalationSuccess,
  createEscalationError,
  deleteEscalationError,
  deleteEscalationSuccess,
  updateEscalationError,
  updateEscalationSuccess,
  deleteEscalation as deleteEscalationAction,
  updateEscalationSwimlaneCustomTicketError,
} from './actions'
import {
  GET_ESCALATIONS,
  FETCH_ESCALATION_FILTER_OPTIONS,
  CREATE_ESCALATION,
  UPDATE_ESCALATION,
  DELETE_ESCALATION,
} from './constants'
import {
  FETCH_TICKETS_BY_SRNS,
  GET_ESCALATIONS_QUERY,
  GET_FILTER_OPTIONS_QUERY,
  CREATE_ESCALATION_MUTATION,
  CREATE_ESCALATION_ASSSIGNMENT_MUTATION,
  DELETE_ESCALATION_ASSIGNMNET_MUTATION,
  CREATE_ESCALATION_RULE_MUTATION,
  DELETE_ESCALATION_RULE_MUTATION,
  CREATE_ESCALATION_FILTER_MUTATION,
  DELETE_ESCALATION_FILTER_MUTATION,
  DELETE_ESCALATION_MUTATION,
  UPDATE_ESCALATION_MUTATION,
} from './static-queries'
import { selectEscalations } from 'containers/EscalationData/selectors'

function* createEscalation({ payload }) {
  let escalationSrn = null
  try {
    const client = getClient()
    // first create the escalation itself
    const escalationResult = yield client.query({
      query: gql`
        ${CREATE_ESCALATION_MUTATION}
      `,
      variables: {
        input: {
          title: payload.title,
          description: payload.description,
        },
      },
    })

    if (escalationResult.errors) {
      throw new Error(
        _.get(
          escalationResult,
          ['errors', 0, 'message'],
          'Error Creating Escalation'
        )
      )
    } else {
      // now create swimlane assignments
      const escalation = _.get(escalationResult, [
        'data',
        'CreateEscalationScheme',
      ])
      escalationSrn = escalation.srn
      const assResults = yield all([
        ...payload.swimlanes.map(assignment =>
          client.mutate({
            mutation: gql`
              ${CREATE_ESCALATION_ASSSIGNMENT_MUTATION}
            `,
            variables: {
              input: {
                swimlaneSRN: assignment.get('swimlaneSRN'),
                schemeSRN: escalation.srn,
              },
            },
          })
        ),
      ])
      const assignments = []
      for (const assign of assResults) {
        if (assign.errors) {
          throw new Error(
            _.get(
              assign,
              ['errors', 0, 'message'],
              'Error Creating Escalation Assignment'
            )
          )
        }
        if (
          _.get(assign, ['data', 'CreateEscalationSchemeAssignment'], false)
        ) {
          assignments.push(
            _.get(assign, ['data', 'CreateEscalationSchemeAssignment'])
          )
        }
      }
      // create filters
      const filterResults = yield all([
        ...payload.filters.map(filter =>
          client.mutate({
            mutation: gql`
              ${CREATE_ESCALATION_FILTER_MUTATION}
            `,
            variables: {
              input: {
                schemeSrn: escalation.srn,
                ticketType: filter.get('ticketType'),
                ticketKey: filter.get('controlFrameworkSrn')
                  ? null
                  : filter.getIn(['ticketKey', 'value']),
                controlFrameworkSrn: filter.get('controlFrameworkSrn'),
                allKeys: filter.get('allKeys'),
                allTypes: filter.get('allTypes'),
              },
            },
          })
        ),
      ])
      const filters = []
      for (const filter of filterResults) {
        if (filter.errors) {
          throw new Error(
            _.get(
              filter,
              ['errors', 0, 'message'],
              'Error Creating Escalation Filter'
            )
          )
        }
        if (_.get(filter, ['data', 'CreateEscalationFilter'], false)) {
          filters.push(_.get(filter, ['data', 'CreateEscalationFilter']))
        }
      }
      // create rules
      const ruleResults = yield all([
        ...payload.rules.map(rule => {
          let typeVar = {}
          switch (rule.get('actionType')) {
            case 'ESCALATE_TO_BOT':
              typeVar.escalateToBot = rule.get('escalateToBot').toJS()
              break
            case 'ASSIGN_USER':
              typeVar.assignUser = rule.get('assignUser').toJS()
              break
            case 'ASSIGN_ROLE':
              typeVar.assignRole = rule.get('assignRole').toJS()
              break
          }
          return client.mutate({
            mutation: gql`
              ${CREATE_ESCALATION_RULE_MUTATION}
            `,
            variables: {
              input: {
                schemeSrn: escalation.srn,
                triggerAfter: rule.get('triggerAfter'),
                actionType: rule.get('actionType'),
                ...typeVar,
              },
            },
          })
        }),
      ])
      const rules = []
      for (const rule of ruleResults) {
        if (rule.errors) {
          throw new Error(
            _.get(
              rule,
              ['errors', 0, 'message'],
              'Error Creating Escalation Rule'
            )
          )
        }
        if (_.get(rule, ['data', 'CreateEscalationRule'], false)) {
          rules.push(_.get(rule, ['data', 'CreateEscalationRule']))
        }
      }

      if (assignments.length > 0) {
        escalation.assignments = [...assignments]
      }
      if (filters.length > 0) {
        escalation.filters = [...filters]
      }
      if (rules.length > 0) {
        escalation.rules = [...rules]
      }

      yield put(createEscalationSuccess({ escalation: escalation }))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    // if there is an error creating a swimlane assignment, filter, or rule
    // then just delete the escalation itself to clean up the problem
    if (escalationSrn) {
      yield put(deleteEscalationAction(escalationSrn))
    }
    yield put(createEscalationError({ error: e.message }))
  }
}

function* getFilterOptions() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${GET_FILTER_OPTIONS_QUERY}
      `,
    })

    const options = _.get(results, ['data', 'getChangeDetectionOptions'], false)

    if (results.errors || !options) {
      yield put(
        fetchEscalationOptionsError(
          _.get(
            results,
            ['errors', 0, 'message'],
            'Error fetching Filter Options'
          )
        )
      )
    } else if (options) {
      yield put(fetchEscalationOptionsSuccess({ options: options }))
    } else {
      throw new Error('Error fetching Filter Options')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

function* getEscalations() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${GET_ESCALATIONS_QUERY}
      `,
    })

    const items = _.get(results, ['data', 'EscalationSchemes', 'items'], false)

    if (results.errors || !items) {
      throw new Error(
        _.get(results, ['errors', 0, 'message'], 'Error fetching Escalations')
      )
    } else if (items) {
      yield put(setEscalations({ escalations: items }))
    } else {
      throw new Error('Error fetching Escalations')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    yield put(setEscalationsError({ error: 'Error fetching Escalations' }))
  }
}

function* deleteEscalation({ payload }) {
  try {
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        ${DELETE_ESCALATION_MUTATION}
      `,
      variables: {
        input: { srn: payload },
      },
    })

    const deleted = _.get(
      results,
      ['data', 'DeleteEscalationScheme', 'srn'],
      false
    )

    if (results.errors || !deleted) {
      throw new Error(
        _.get(results, ['errors', 0, 'message'], 'Error deleting Escalations')
      )
    } else if (deleted) {
      yield put(deleteEscalationSuccess(deleted))
    } else {
      throw new Error('Error deleting Escalations')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    yield put(deleteEscalationError({ error: e.message }))
  }
}

function* updateEscalation({ payload }) {
  try {
    const client = getClient()
    let updatedEscalation = {
      srn: payload.schemeSrn,
      details: null,
      assignments: null,
      filters: null,
      rules: null,
    }

    // DETAIL UPDATES
    if (payload.details) {
      const detailResults = yield client.mutate({
        mutation: gql`
          ${UPDATE_ESCALATION_MUTATION}
        `,
        variables: {
          input: { schemeSrn: payload.schemeSrn, ...payload.details },
        },
      })

      const newDetails = _.get(
        detailResults,
        ['data', 'UpdateEscalationScheme'],
        false
      )

      if (!newDetails || detailResults.errors) {
        throw new Error(
          _.get(
            detailResults,
            ['errors', 0, 'message'],
            'Error Updating Escalation Details'
          )
        )
      } else {
        updatedEscalation.details = newDetails
      }
    }
    // SWIMLANE UPDATES
    if (payload.swimlanes) {
      /**
       * the code below checks if the escalation has custom tickets assigned to
       * it as filters. If it does, it tries to determine if any of the swimlanes
       * the user is removing would make it so there's a mismatch between the
       * custom ticket's swimlanes and the escalations swimlanes. If it finds that,
       * it will set an error state and return
       */
      if (
        _.isArray(payload.swimlanes.remove) &&
        payload.swimlanes.remove.length > 0
      ) {
        // find current value of escalation
        const currEscalation = (yield select(selectEscalations) ?? Map()).get(
          payload.schemeSrn
        )

        if (currEscalation != null /* this could happen if invalid payload */) {
          // find filters for custom tickets
          const customTicketFilters = (
            currEscalation.get('filters') ?? List()
          ).filter(filter => filter.get('ticketType') === 'Custom')

          if (customTicketFilters.size > 0) {
            // load the custom tickets
            const customTicketSRNs = customTicketFilters
              .map(filter => filter.get('ticketKey'))
              .toJS()
            const response = yield client.query({
              query: gql`
                ${FETCH_TICKETS_BY_SRNS}
              `,
              variables: { srns: customTicketSRNs },
            })
            const path = ['data', 'Tickets', 'items']
            const data = _.get(response, path, null)
            if (data != null /* hopefully this doesn't happen */) {
              // get list of all the current swimlanes
              const currentSwimlanes = (
                currEscalation.get('assignments') ?? List()
              )
                .filter(ass => ass.get('swimlaneSRN'))
                .map(ass => ass.get('swimlaneSRN'))
                .toJS()

              // find the SRNs of the swimlanes that being removed
              const removeSwimlaneSRNs = payload.swimlanes.remove
                .map(assignmentSRN =>
                  (currEscalation.get('assignments') ?? List()).find(
                    assignment => assignment.get('srn') === assignmentSRN
                  )
                )
                .map(assignment => assignment.get('swimlaneSRN'))

              // this is list of swimlane assignments after the edit completes
              const swimlanesAfterEdit = [
                ...payload.swimlanes.add,
                ...currentSwimlanes,
              ].filter(srn => !removeSwimlaneSRNs.includes(srn))

              // find custom tickets that will become invalid and keep track of
              // their swimlanes:
              const requiredSwimlanes = {}
              const invalidCustomTickets = data
                .filter(ticket => ticket.swimlaneSRNs)
                .filter(ticket => {
                  const removedIntersection = _.intersection(
                    ticket.swimlaneSRNs,
                    removeSwimlaneSRNs
                  )
                  if (removedIntersection.length > 0) {
                    requiredSwimlanes[ticket.srn] = removedIntersection
                    return true
                  }
                })
                .filter(ticket => {
                  return (
                    _.intersection(ticket.swimlaneSRNs, swimlanesAfterEdit)
                      .length === 0
                  )
                })

              // if some will become invalid, set error and don't continue:
              if (invalidCustomTickets.length > 0) {
                yield put(
                  updateEscalationSwimlaneCustomTicketError({
                    tickets: invalidCustomTickets,
                    requiredSwimlanes: requiredSwimlanes,
                  })
                )
                return
              }
            } else {
              throw new Error(
                'Could not load custom tickets to verify swimlane config'
              )
            }
          }
        }
      }

      // ADD SWIMLANES
      const addAssignResults = yield all([
        ...payload.swimlanes.add.map(swimlaneSRN =>
          client.mutate({
            mutation: gql`
              ${CREATE_ESCALATION_ASSSIGNMENT_MUTATION}
            `,
            variables: {
              input: {
                swimlaneSRN: swimlaneSRN,
                schemeSRN: payload.schemeSrn,
              },
            },
          })
        ),
      ])
      const added = []
      for (const add of addAssignResults) {
        if (add.errors) {
          throw new Error(
            _.get(
              add,
              ['errors', 0, 'message'],
              'Error Creating Escalation Assignment'
            )
          )
        }
        if (_.get(add, ['data', 'CreateEscalationSchemeAssignment'], false)) {
          added.push(_.get(add, ['data', 'CreateEscalationSchemeAssignment']))
        }
      }
      // REMOVE SWIMLANES
      const removeAssignResults = yield all([
        ...payload.swimlanes.remove.map(assignmentSrn =>
          client.mutate({
            mutation: gql`
              ${DELETE_ESCALATION_ASSIGNMNET_MUTATION}
            `,
            variables: {
              input: {
                srn: assignmentSrn,
              },
            },
          })
        ),
      ])
      const removed = []
      for (const remove of removeAssignResults) {
        if (remove.errors) {
          throw new Error(
            _.get(
              remove,
              ['errors', 0, 'message'],
              'Error Deleting Escalation Assignment'
            )
          )
        }
        if (
          _.get(remove, ['data', 'DeleteEscalationSchemeAssignment'], false)
        ) {
          removed.push(
            _.get(remove, ['data', 'DeleteEscalationSchemeAssignment'])
          )
        }
      }
      updatedEscalation.swimlanes = { added, removed }
    }

    // FILTER UPDATES
    if (payload.filters) {
      // ADD FILTERS
      const addFilterResults = yield all([
        ...payload.filters.add.map(filter =>
          client.mutate({
            mutation: gql`
              ${CREATE_ESCALATION_FILTER_MUTATION}
            `,
            variables: {
              input: {
                schemeSrn: payload.schemeSrn,
                ticketType: filter.ticketType,
                ticketKey: filter.controlFrameworkSrn ? null : filter.ticketKey,
                controlFrameworkSrn: filter.controlFrameworkSrn,
                allTypes: filter.allTypes,
                allKeys: filter.allKeys,
              },
            },
          })
        ),
      ])
      const added = []
      for (const add of addFilterResults) {
        if (add.errors) {
          throw new Error(
            _.get(
              add,
              ['errors', 0, 'message'],
              'Error Creating Escalation Filter'
            )
          )
        }
        if (_.get(add, ['data', 'CreateEscalationFilter'], false)) {
          added.push(_.get(add, ['data', 'CreateEscalationFilter']))
        }
      }
      // REMOVE FILTERS
      const removeFilterResults = yield all([
        ...payload.filters.remove.map(filterSrn =>
          client.mutate({
            mutation: gql`
              ${DELETE_ESCALATION_FILTER_MUTATION}
            `,
            variables: {
              input: {
                srn: filterSrn,
              },
            },
          })
        ),
      ])
      const removed = []
      for (const remove of removeFilterResults) {
        if (remove.errors) {
          throw new Error(
            _.get(
              remove,
              ['errors', 0, 'message'],
              'Error Deleting Escalation Filter'
            )
          )
        }
        if (_.get(remove, ['data', 'DeleteEscalationFilter'], false)) {
          removed.push(_.get(remove, ['data', 'DeleteEscalationFilter']))
        }
      }
      updatedEscalation.filters = { added, removed }
    }

    // RULES UPDATES
    if (payload.rules) {
      // ADD RULES
      const addRuleResults = yield all([
        ...payload.rules.add.map(rule => {
          let typeVar = {}
          switch (rule.actionType) {
            case 'ESCALATE_TO_BOT':
              typeVar.escalateToBot = rule.escalateToBot
              break
            case 'ASSIGN_USER':
              typeVar.assignUser = rule.assignUser
              break
            case 'ASSIGN_ROLE':
              typeVar.assignRole = rule.assignRole
              break
          }
          return client.mutate({
            mutation: gql`
              ${CREATE_ESCALATION_RULE_MUTATION}
            `,
            variables: {
              input: {
                schemeSrn: payload.schemeSrn,
                triggerAfter: rule.triggerAfter,
                actionType: rule.actionType,
                ...typeVar,
              },
            },
          })
        }),
      ])
      const added = []
      for (const add of addRuleResults) {
        if (add.errors) {
          throw new Error(
            _.get(
              add,
              ['errors', 0, 'message'],
              'Error Creating Escalation Rule'
            )
          )
        }
        if (_.get(add, ['data', 'CreateEscalationRule'], false)) {
          added.push(_.get(add, ['data', 'CreateEscalationRule']))
        }
      }
      // REMOVE RULES
      const removeRuleResults = yield all([
        ...payload.rules.remove.map(ruleSrn =>
          client.mutate({
            mutation: gql`
              ${DELETE_ESCALATION_RULE_MUTATION}
            `,
            variables: {
              input: {
                srn: ruleSrn,
              },
            },
          })
        ),
      ])
      const removed = []
      for (const remove of removeRuleResults) {
        if (remove.errors) {
          throw new Error(
            _.get(
              remove,
              ['errors', 0, 'message'],
              'Error Deleting Escalation Rule'
            )
          )
        }
        if (_.get(remove, ['data', 'DeleteEscalationRule'], false)) {
          removed.push(_.get(remove, ['data', 'DeleteEscalationRule']))
        }
      }
      updatedEscalation.rules = { added, removed }
    }

    yield put(updateEscalationSuccess({ escalation: updatedEscalation }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    yield put(updateEscalationError({ error: e.message }))
  }
}

function* escalationDataSagas() {
  yield all([
    takeLatest(GET_ESCALATIONS, getEscalations),
    takeLatest(FETCH_ESCALATION_FILTER_OPTIONS, getFilterOptions),
    takeLatest(CREATE_ESCALATION, createEscalation),
    takeLatest(DELETE_ESCALATION, deleteEscalation),
    takeLatest(UPDATE_ESCALATION, updateEscalation),
  ])
}

export default escalationDataSagas
