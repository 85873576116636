import React, { Component } from 'react'
import Icon from 'components/Icon'
import Popup from 'reactjs-popup'
import PropTypes from 'prop-types'
import { getCrmPropertySubtitle, getMappedBoolLabel } from 'utils/widgetUtils'

class ChangeDetectionPopup extends Component {
  styles = {
    button: {
      border: 'none',
      padding: '0rem 0.5rem',
    },
    contentStyle: {
      minWidth: '18rem',
      padding: '0.75rem',
      boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 3px -1px`,
      display: 'flex',
    },
    container: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '0.4fr 1fr 1fr',
    },
    title: {
      fontWeight: '500',
      fontSize: '1rem',
      margin: '0px',
      padding: '0px',
    },
    subTitle: {
      fontSize: '14px',
      color: '#888',
      padding: '0px',
      margin: '0rem 0rem 0.15rem 0rem',
    },
    propertiesContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px 5px 15px',
      backgroundColor: '#74B4DF',
      borderRadius: '20px',
      margin: '0rem 1rem 1rem 0rem',
    },
  }

  getContent = () => {
    const {
      data: { keyName, currentValue },
    } = this.props
    return (
      <div style={this.styles.container}>
        <div>
          <span style={this.styles.title}>
            {this.props.title || getMappedBoolLabel(keyName, currentValue)}
          </span>
        </div>
        <div style={this.styles.subTitle}>
          {getCrmPropertySubtitle(this.props.resourceSrn, keyName)}
        </div>
        <div>
          <div style={{ fontSize: '0.85rem' }}>
            Current State:
            <span style={{ fontWeight: '500', marginLeft: '0.25rem' }}>
              {this.props.isLocked ? 'Locked' : 'Unlocked'}
            </span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      data: { content },
    } = this.props

    if (!content) {
      return (
        <div style={{ display: 'block' }}>
          <Popup
            on={['hover']}
            trigger={
              <button style={{ ...this.styles.button, ...this.props.style }}>
                <Icon
                  fa
                  name={this.props.isLocked ? 'lock' : 'lock-open'}
                  style={{ fontSize: '1rem' }}
                  color={
                    !this.props.color
                      ? this.props.isLocked
                        ? '#E4C647'
                        : '#888'
                      : '#FFF'
                  }
                />
              </button>
            }
            position="right top"
            closeOnDocumentClick
            contentStyle={this.styles.contentStyle}
          >
            {this.getContent()}
          </Popup>
        </div>
      )
    }

    return (
      <div style={{ display: 'block' }}>
        <Popup
          on={['hover']}
          trigger={
            <div style={this.styles.propertiesContainer}>
              {content}
              <div
                style={{
                  border: 'none',
                  padding: '0rem 0.5rem',
                }}
              >
                <Icon
                  fa
                  name={this.props.isLocked ? 'lock' : 'lock-open'}
                  style={{ fontSize: '1rem' }}
                  color={
                    !this.props.color
                      ? this.props.isLocked
                        ? '#E4C647'
                        : '#888'
                      : '#FFF'
                  }
                />
              </div>
            </div>
          }
          position="right top"
          closeOnDocumentClick
          contentStyle={this.styles.contentStyle}
        >
          {this.getContent()}
        </Popup>
      </div>
    )
  }
}

ChangeDetectionPopup.propTypes = {
  isLocked: PropTypes.bool.isRequired,
  style: PropTypes.object,
  color: PropTypes.string,
  resourceSrn: PropTypes.string,
  data: PropTypes.shape({
    keyName: PropTypes.string,
    currentValue: PropTypes.any,
    content: PropTypes.node,
  }),
  title: PropTypes.string,
}

export default ChangeDetectionPopup
