import { defineMessages } from 'react-intl'

export default defineMessages({
  alt: {
    id: 'app.components.CopyToClipboard.alt',
    defaultMessage: 'Copy to Clipboard',
  },
  copySuccess: {
    id: 'app.components.CopyToClipboard.copySuccess',
    defaultMessage: 'Copied!',
  },
})
