import React from 'react'
import PropTypes from 'prop-types'
import MarkdownDisplay from 'components/MarkdownDisplay'

const DescriptionRenderer = props => {
  return (
    <div style={{ padding: '10px' }}>
      <MarkdownDisplay content={props.data.description} />
    </div>
  )
}

DescriptionRenderer.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
  }),
}

export default DescriptionRenderer
