/**
 *
 * PolicyNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { List } from 'immutable'
import _ from 'lodash'
import TextLink from 'components/TextLink'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { NodeViewDetailString, NodeViewDetailLabel } from 'components/NodeView'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import { injectIntl } from 'react-intl'
import JSONDisplay from 'components/JSONDisplay'
import EmptyWidgetResults from 'components/EmptyWidgetResults'
import PolicyVersionWidget from 'components/PolicyVersionWidget'
import BorderedCard from 'components/BorderedCard'

import messages from './messages'
import reducer from './reducer'
import sagas from './sagas'
import { getPolicyContent } from './actions'
import { selectPolicyContent, selectIsLoading } from './selectors'

const styles = {
  descTitle: {
    fontSize: '1.3em',
  },
  niceline: {
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },
}

export class PolicyNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    props.getPolicyContent(props.nodeData.srn)

    this.state = {
      selectedTabIndex: 0,
      isOpen: false,
    }
  }

  getVersions = () =>
    this.props.policyContent.getIn(['hasPolicyVersion', 'items'], List()).toJS()

  setTabIndex = index => {
    this.setState({
      selectedTabIndex: index,
    })
  }

  getTabs = () =>
    this.getVersions().map(version => ({
      id: version.version,
      text: version.version,
    }))

  getSelectedVersion = () => {
    return this.getVersions()[this.state.selectedTabIndex] || null
  }

  toggleJSONModal = () =>
    this.setState(oldState => ({ isOpen: !oldState.isOpen }))

  getGridContent = () => {
    const version = this.getSelectedVersion()
    return [
      <div key="description">{this.renderDesc()}</div>,
      <PolicyVersionWidget
        key="policy"
        loading={this.props.isLoading}
        {...version}
      />,
    ]
  }

  getPolicyJSON = () => {
    if (!this.props.isLoading) {
      return _.get(this.getVersions(), ['0', 'policyJSON'], null)
    }
    return null
  }

  getColumns = () => {
    return {
      xs: [
        {
          widthPercent: 100,
          items: ['description', 'policy', 'graph'],
          heights: {
            graph: 3,
          },
        },
      ],
    }
  }

  renderDesc = () => {
    if (this.props.policyContent.get('hasPolicyVersion')) {
      const desc = this.props.policyContent.getIn([
        'hasPolicyVersion',
        'items',
        0,
        'hasPolicyEntry',
        'items',
        0,
        'hasPermissionList',
        'items',
        0,
        'description',
      ])
      return (
        <BorderedCard>
          <div>
            <div style={styles.descTitle}>Permission List Description</div>
            <hr style={styles.niceline} />
            <div>{desc}</div>
          </div>
        </BorderedCard>
      )
    } else {
      return null
    }
  }
  getHeaderContent = () => {
    const version = !_.isEmpty(this.getSelectedVersion())
      ? this.getSelectedVersion().version
      : null

    return [
      <Fragment key="content-version">
        <NodeViewDetailLabel>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {this.props.intl.formatMessage(messages.Version)}
            {this.getPolicyJSON() && (
              <TextLink
                onClick={this.toggleJSONModal}
                color="primary"
                style={{
                  margin: '0.1rem 1rem 0rem 0.5rem',
                  fontSize: '0.9rem',
                }}
              >
                (View Policy JSON)
              </TextLink>
            )}
          </div>
        </NodeViewDetailLabel>
        <NodeViewDetailString
          value={this.getSelectedVersion() !== null && version}
        />
      </Fragment>,
    ]
  }

  render() {
    const json = this.getPolicyJSON()
    const { friendlyName, name } = this.props.nodeData
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          additionalHeaderDetails={this.getHeaderContent()}
          columns={this.getColumns()}
          gridContent={this.getGridContent()}
          nodeData={this.props.nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
        />
        <Modal isOpen={this.state.isOpen} toggle={this.toggleJSONModal}>
          <ModalHeader toggle={this.toggleJSONModal}>
            {friendlyName || name || 'Policy'} JSON
          </ModalHeader>
          <ModalBody>
            {json ? (
              <JSONDisplay content={JSON.parse(json)} />
            ) : (
              <EmptyWidgetResults />
            )}
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

PolicyNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  isLoading: PropTypes.bool,
  policyContent: ImmutablePropTypes.map.isRequired,
  getPolicyContent: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object.isRequired,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  policyContent: selectPolicyContent,
  isLoading: selectIsLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPolicyContent,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'policyNodeViewCardLayout', reducer })

const withSaga = injectSaga({
  key: 'policyNodeViewCardLayout',
  saga: sagas,
})

export default compose(
  withConnect,
  withReducer,
  withSaga,
  injectIntl
)(PolicyNodeViewCardLayout)
