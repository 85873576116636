import React from 'react'
import PropTypes from 'prop-types'

class IconLayer extends React.Component {
  render() {
    return (
      <span className="fa-layers fa-lg" style={this.props.style}>
        {this.props.children}
      </span>
    )
  }
}

IconLayer.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

export default IconLayer
