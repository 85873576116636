import React from 'react'
import PropTypes from 'prop-types'
import ReactMde, { commands as mdeCommands } from 'react-mde'
import MarkdownDisplay from 'components/MarkdownDisplay'

export class MarkdownEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      markdown: props.value || '',
      selectedTab: 'write',
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (!this.props.value) {
        this.handleClear()
      }
    }
  }

  handleChange = value => {
    this.setState({ markdown: value })

    if (this.props.onKeyStroke) {
      this.props.onKeyStroke(value)
    }
  }

  handleClear = () => this.setState({ markdown: '' })

  render() {
    //Remove the image command from the button b ar
    const commands = mdeCommands.getDefaultCommands()
    commands[1] = {
      commands: [
        mdeCommands.linkCommand,
        mdeCommands.quoteCommand,
        mdeCommands.codeCommand,
      ],
    }

    return (
      <div
        className="container"
        style={{ padding: '0px', marginTop: '4px' }}
        onBlur={() => this.props.onChange(this.state.markdown)}
      >
        <ReactMde
          commands={commands}
          value={this.state.markdown}
          readOnly={this.props.disabled}
          onChange={this.handleChange}
          selectedTab={this.state.selectedTab}
          onTabChange={tab => this.setState({ selectedTab: tab })}
          generateMarkdownPreview={markdown =>
            Promise.resolve(<MarkdownDisplay content={markdown} />)
          }
        />
      </div>
    )
  }
}

MarkdownEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyStroke: PropTypes.func,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default MarkdownEditor
