import React from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { injectIntl } from 'react-intl'
import injectReducer from 'utils/injectReducer'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Input } from 'reactstrap'

import Icon from 'components/Icon'
import { List, isImmutable, Map } from 'immutable'
import Select from 'components/SelectBar'
import SelectBar from 'components/SelectBar'
import Button from 'components/Button'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import TextLink from 'components/TextLink'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { selectSwimlanesBySrn } from 'containers/SonraiData/selectors'
import { selectPolicies } from 'containers/ControlFrameworkData/selectors'
import { selectChangeDetectionOptions } from 'containers/Tickets/selectors'

import reducer from './reducer'
import { selectTicketListFilterPanel } from './selectors'
import { setTicketListFilterPanel, clearTicketListFilter } from './actions'

export class TicketListFilterPanel extends React.Component {
  state = {}

  styles = {
    filterList: {
      gridArea: 'filterList',
      paddingTop: '0.75em',
      paddingLeft: '1em',
      fontFamily: 'Roboto-Italic',
      fontSize: '14px',
      color: '#777777',
    },
    sort: {
      marginRight: '0.5em',
    },
    filter: {
      marginLeft: '0.5em',
    },
    filterTitle: {
      marginBottom: '0.5em',
      display: 'block',
    },
    filterHeader: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.25rem 0.5rem 0.25rem 0.5rem',
    },
    filterPanelFooter: {
      width: '100%',
      margin: '1rem 0rem 0rem 0rem',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  }

  handleSeverityChange = severityCategory => this.setState({ severityCategory })

  handleTicketTypeChange = ticketType => this.setState({ ticketType })

  handleTicketKeyChange = selectedOptions => {
    this.setState({
      ticketKey:
        selectedOptions && !_.isEmpty(selectedOptions)
          ? selectedOptions.map(opt => opt.value)
          : [],
    })
  }

  handleSwimlaneSrnsChange = selectedOptions => {
    this.setState({
      swimlaneSrns:
        selectedOptions && !_.isEmpty(selectedOptions)
          ? selectedOptions.map(opt => opt.value)
          : [],
    })
  }

  handleResourceSrnChange = e => this.setState({ resourceSRN: e.target.value })

  getTicketKeyOptions = () => {
    const policyOptions = this.props.policies
      .sortBy(policy => policy.get('title'))
      .toList()
      .toJS()
      .map(pol => ({ label: pol.title, value: pol.srn }))

    const propertyOptions = this.props.changeDetectionOptions
      .get('data')
      .toJS()
      .filter(option => option.keyType === 'PROPERTY')
      .map(option => ({
        label: `Property Changed: ${_.snakeCase(option.keyName)
          .replace(/_/g, ' ')
          .split(' ')
          .map(_.capitalize)
          .join(' ')}`,
        value: option.keyName,
      }))

    const activityOptions = this.props.changeDetectionOptions
      .get('data')
      .toJS()
      .filter(option => option.keyType !== 'PROPERTY')
      .filter(
        option => !['actionableBy', 'hasPermissionTo'].includes(option.keyName)
      )
      .map(option => ({
        label: `Activity: ${_.snakeCase(option.keyName)
          .replace(/_/g, ' ')
          .split(' ')
          .map(_.capitalize)
          .join(' ')}`,
        value: option.keyName,
      }))

    const accessOptions = [
      { label: 'Actionable By', value: 'actionableBy' },
      { label: 'Has Permission To', value: 'hasPermissionto' },
    ]

    switch ((this.state.ticketType ?? {}).value) {
      case 'Activity':
        return activityOptions
      case 'Access':
        return accessOptions
      case 'Property':
        return propertyOptions
      case 'Policy':
        return policyOptions
      default:
        return [
          ...accessOptions,
          ...activityOptions,
          ...propertyOptions,
          ...policyOptions,
        ]
    }
  }

  getValue = type => {
    const singleValSelects = ['severityCategory', 'ticketType']
    let value
    if (
      this.state[type] === null &&
      this.props.ticketListFilters.get(type) &&
      singleValSelects.includes(type)
    ) {
      value = null
    } else {
      value = this.state[type]
        ? this.state[type]
        : this.props.ticketListFilters.get(type)
    }

    return isImmutable(value) ? value.toJS() : value
  }

  applyFilters = () => {
    const {
      severityCategory,
      ticketType,
      ticketKey,
      swimlaneSrns,
      resourceSRN,
    } = this.state
    const filters = {}
    if (
      severityCategory ||
      (severityCategory === null &&
        this.props.ticketListFilters.has('severityCategory'))
    ) {
      filters.severityCategory = severityCategory ? severityCategory.value : {}
    }

    if (
      ticketType ||
      (ticketType === null && this.props.ticketListFilters.has('ticketType'))
    ) {
      filters.ticketType = ticketType ? ticketType.value : {}
    }

    if (ticketKey) {
      filters.ticketKey = ticketKey
    }
    if (swimlaneSrns) {
      filters.swimlaneSrns = swimlaneSrns
    }

    if (resourceSRN) {
      filters.resourceSRN = resourceSRN
    }

    if (!_.isEmpty(filters)) {
      this.props.setTicketListFilterPanel(filters)
    } else {
      this.props.clearTicketListFilter()
    }

    this.setState({})
    this.togglePanel()
  }

  togglePanel = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  }

  handleClear = () => {
    this.props.clearTicketListFilter()
    this.setState({})
    this.togglePanel()
  }

  getSwimlaneLabel = srn => {
    const swimlane = this.props.swimlanes.get(srn) || Map()
    return swimlane.get('title') || srn
  }

  getTicketKeyLabel = ticketKey => {
    if (ticketKey.startsWith('srn')) {
      const policy = this.props.policies.get(ticketKey) || Map()
      return policy.get('title') || ticketKey
    }
    return ticketKey
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '400px',
        }}
      >
        <Popover isOpen={this.state.isOpen} onToggle={this.togglePanel}>
          <PopoverAnchor>
            {this.props.ticketListFilters.isEmpty() ? (
              <Icon style={this.styles.filter} fa name="filter" />
            ) : (
              <Icon
                style={{
                  ...this.styles.filter,
                  color: this.props.theme.emphasis,
                }}
                fa
                name="filter-solid"
              />
            )}
          </PopoverAnchor>
          <PopoverBody style={{ width: '500px' }}>
            <div style={{ width: '500px' }}>
              <div style={this.styles.filterHeader}>
                <div
                  style={{
                    fontSize: '1.15rem',
                    fontWeight: '400',
                  }}
                >
                  Filters
                </div>
                <TextLink
                  onClick={this.handleClear}
                  color="primary"
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  Clear All Filters
                </TextLink>
              </div>

              <div style={{ margin: '0.5em' }}>
                <SelectBar
                  autoSort={false}
                  onChange={this.handleSeverityChange}
                  placeholder={`Severity Category`}
                  value={this.getValue('severityCategory')}
                  isClearable
                  options={[
                    { label: 'Low Severity', value: 'LOW' },
                    { label: 'Medium Severity', value: 'MEDIUM' },
                    { label: 'High Severity', value: 'HIGH' },
                  ]}
                />
              </div>
              <div style={{ margin: '1em 0.5em 0.5em 0.5em' }}>
                <Select
                  onChange={this.handleTicketTypeChange}
                  placeholder={`Ticket Type`}
                  value={this.getValue('ticketType')}
                  isClearable
                  options={[
                    { label: 'Access', value: 'Access' },
                    { label: 'Activity', value: 'Activity' },
                    { label: 'Custom', value: 'Custom' },
                    { label: 'Policy', value: 'Policy' },
                    { label: 'Property', value: 'Property' },
                  ]}
                />
              </div>
              <div style={{ margin: '1em 0.5em 0.5em 0.5em' }}>
                <Select
                  onChange={this.handleTicketKeyChange}
                  placeholder={`Ticket Key`}
                  value={
                    !_.isEmpty(this.getValue('ticketKey'))
                      ? this.getValue('ticketKey').map(value => ({
                          label: this.getTicketKeyLabel(value),
                          value,
                        }))
                      : []
                  }
                  isClearable
                  isMulti
                  options={this.getTicketKeyOptions()}
                />
              </div>
              <div style={{ margin: '1em 0.5em 0.5em 0.5em' }}>
                <Select
                  onChange={this.handleSwimlaneSrnsChange}
                  placeholder={`Swimlanes`}
                  value={
                    !_.isEmpty(this.getValue('swimlaneSrns'))
                      ? this.getValue('swimlaneSrns').map(value => ({
                          label: this.getSwimlaneLabel(value),
                          value,
                        }))
                      : []
                  }
                  isClearable
                  isMulti
                  options={(this.props.swimlanes.toList() || List())
                    .toJS()
                    .map(item => ({ label: item.title, value: item.srn }))}
                />
              </div>
              <div style={{ margin: '1em 0.5em 0.5em 0.5em' }}>
                <Input
                  onChange={this.handleResourceSrnChange}
                  placeholder={`Affected Resource SRN`}
                  value={this.getValue('resourceSRN')}
                />
              </div>
            </div>
            <div style={this.styles.filterPanelFooter}>
              <TextLink
                onClick={this.togglePanel}
                color="primary"
                style={{
                  cursor: 'pointer',
                  paddingRight: '1rem',
                }}
              >
                Cancel
              </TextLink>
              <Button color="primary" onClick={this.applyFilters}>
                Apply
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      </div>
    )
  }
}

TicketListFilterPanel.propTypes = {
  ticketListFilters: ImmutablePropTypes.iterable.isRequired,
  changeDetectionOptions: ImmutablePropTypes.iterable.isRequired,
  swimlanes: ImmutablePropTypes.map.isRequired,
  policies: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
  setTicketListFilterPanel: PropTypes.func.isRequired,
  clearTicketListFilter: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  changeDetectionOptions: selectChangeDetectionOptions,
  ticketListFilters: selectTicketListFilterPanel,
  policies: selectPolicies,
  swimlanes: selectSwimlanesBySrn,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTicketListFilterPanel,
      clearTicketListFilter,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'ticketListFilterPanel', reducer })

export default compose(
  withConnect,
  injectIntl,
  withReducer
)(themeable(TicketListFilterPanel))
