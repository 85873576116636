import { createSelector } from 'reselect'
import { Map, List } from 'immutable'
import { getCurrentOrg } from 'auth/current-org'

const selectUser = (state, props) => {
  return props.userData || new Map()
}

const selectAppMeta = createSelector(
  selectUser,
  user => {
    return user.get('https://sonraisecurity.com/appMeta', Map())
  }
)

const selectEnvKey = createSelector(
  selectUser,
  user => {
    return user.get('https://sonraisecurity.com/env')
  }
)

const selectEnvConfig = createSelector(
  [selectAppMeta, selectEnvKey],
  (appMeta, envKey) => {
    return appMeta.get(envKey, Map())
  }
)

const selectCurrentOrgConfig = createSelector(
  [selectEnvConfig],
  config => {
    const currentOrg = getCurrentOrg()
    return config
      .get('orgs', List())
      .find(orgConf => orgConf.get('name') === currentOrg, null, Map())
  }
)

export const selectFeatureFlagConfig = createSelector(
  selectCurrentOrgConfig,
  config => config.get('feature_flags', Map())
)
