import React from 'react'
import PropTypes from 'prop-types'
import Color from 'color'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class Checkbox extends React.PureComponent {
  renderCheckbox = () => {
    const styles = {
      svg: {
        width: '22px',
        height: '22px',
        position: 'relative',
      },
    }
    if (this.props.checked && this.props.semiChecked) {
      return (
        <svg style={styles.svg} viewBox="0 0 22 22">
          <path
            style={{
              fill: this.props.agGridTheme
                ? 'rgb(253,60,120)'
                : Color(this.props.theme.secondary).lighten(0.2),
            }}
            d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
          />
          <polyline
            style={{
              fill: 'none',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              stroke: this.props.agGridTheme ? '#222' : this.props.theme.light,
              strokeWidth: 2,
            }}
            points="5 9 13 9"
          />
        </svg>
      )
    }

    if (this.props.checked) {
      return (
        <svg style={styles.svg} viewBox="0 0 18 18" transform={`scale(0.8)`}>
          <path
            style={{
              fill: this.props.agGridTheme
                ? 'rgb(253,60,120)'
                : this.props.theme.primary,
            }}
            d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
          />
          <polyline
            style={{
              fill: 'none',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              stroke: this.props.theme.light,
              strokeWidth: 2,
            }}
            points="4 9 7 12 14 5"
          />
        </svg>
      )
    }

    return (
      <svg
        style={{
          ...styles.svg,
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          stroke: this.props.agGridTheme ? '#222' : this.props.theme.secondary,
          strokeWidth: 1.5,
        }}
        viewBox="0 0 18 18"
        transform={`scale(0.8)`}
      >
        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
      </svg>
    )
  }

  render() {
    const styles = {
      base: {
        overflow: 'visible',
        display: 'inline-block',
      },
    }

    return (
      <div style={styles.base}>
        <div onClick={() => this.props.onChange(!this.props.checked)}>
          <input
            checked={this.props.checked}
            type="checkbox"
            style={{ display: 'none' }}
          />
          {this.renderCheckbox()}
        </div>
      </div>
    )
  }
}

Checkbox.propTypes = {
  agGridTheme: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  theme: themeShape,
  semiChecked: PropTypes.bool,
}

export default themeable(Checkbox)
