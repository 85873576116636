import { defineMessages } from 'react-intl'

export default defineMessages({
  filteredBy: {
    id: 'app.container.WidgetResultExplorer.filteredBy',
    defaultMessage: 'Filter:',
  },
  equalTo: {
    id: 'app.container.WidgetResultExplorer.equalTo',
    defaultMessage: 'equals:',
  },
  emptyWidgetSearch: {
    id: 'app.container.WidgetResultExplorer.emptyWidgetSearch',
    defaultMessage: 'No Widget Search was found.',
  },
  exploringResults: {
    id: 'app.container.WidgetResultExplorer.exploringResults',
    defaultMessage:
      'Exploring results for widget "{title}" with search "{searchName}"',
  },
})
