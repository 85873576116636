import React, { Component } from 'react'
import CloudBadge from 'components/CloudBadge'

export default class SonraiBadge extends Component {
  render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CloudBadge type="sonrai" />
        <span style={{ margin: '0rem 0rem 0rem 0.5rem' }}> Sonrai </span>
      </div>
    )
  }
}
