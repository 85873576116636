import React, { Component } from 'react'
import sizeMe from 'react-sizeme'

export default (ComposedComponent, options = {}) => {
  const defaultOptions = {
    monitorWidth: true,
    monitorHeight: true,
    refreshRate: 16,
    refreshMode: 'debounce',
  }

  const sizeMeHOC = sizeMe({ ...defaultOptions, ...options })

  return sizeMeHOC(
    class extends Component {
      displayName = 'sizeMe'

      render() {
        const { width, height } = this.props.size

        return (
          <ComposedComponent
            measureBeforeMount={true}
            {...this.props}
            width={width}
            height={height}
            {...this.state}
          />
        )
      }
    }
  )
}
