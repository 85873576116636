import { defineMessages } from 'react-intl'

export default defineMessages({
  delete: {
    id: 'app.components.WidgetCard.delete',
    defaultMessage: 'Delete',
  },
  edit: {
    id: 'app.components.WidgetCard.edit',
    defaultMessage: 'Edit',
  },
  openInWindow: {
    id: 'app.components.WidgetCard.openInWindow',
    defaultMessage: 'Open in Window',
  },
  viewSearch: {
    id: 'app.components.WidgetCard.viewSearch',
    defaultMessage: 'View Search',
  },
  pin: {
    id: 'app.components.WidgetCard.pin',
    defaultMessage: 'Pin Widget',
  },
  unpin: {
    id: 'app.components.WidgetCard.unpin',
    defaultMessage: 'Unpin Widget',
  },
  filterApplied: {
    id: 'app.components.WidgetCard.filterApplied',
    defaultMessage: 'Filter Applied',
  },
  error: {
    id: 'app.components.WidgetCard.error',
    defaultMessage: 'There was an error loading your widget.',
  },
})
