export const INTEGRATION_CONFIG_PROPS = `
srn
createdDate
createdBy
type
status
slack {
  channels
  actionTypes
}
jira {
  ProjectKey
  PutLabels
}
assignment {
  srn
  SwimlaneSRN
  orgName
}
`

export const INTEGRATION_PROPS = `
srn
resourceId
orgName
title
description
createdDate
createdBy
type
configs {
  ${INTEGRATION_CONFIG_PROPS}
}
`

export const UPDATE_INTEGRATION_MUTATION = `
  mutation updateIntegration($input: UpdateIntegrationRequestInput) {
    UpdateIntegration(input: $input) {
      ${INTEGRATION_PROPS}
    }
  }
`

export const UPDATE_INTEGRATION_CONFIG_MUTATION = `
  mutation updateConfig($input: UpdateIntegrationConfigRequestInput) {
    UpdateIntegrationConfiguration(input: $input) {
      ${INTEGRATION_CONFIG_PROPS}
    }
  }
`

export const CREATE_INTEGRATION_MUTATION = `
  mutation createIntegration($input: CreateIntegrationRequestInput) {
    CreateIntegration(input: $input) {
      ${INTEGRATION_PROPS}
    }
  }
`

export const DELETE_INTEGRATION_MUTATION = `
  mutation deleteIntegration($input: DeleteRequestInput) {
    DeleteIntegration(input: $input) {
      srn
    }
  }
`

export const GET_INTEGRATIONS_QUERY = `
  query getIntegrations {
    Integrations {
      items {
        ${INTEGRATION_PROPS}
      }
    }
  }
`
export const CREATE_CONFIGURATION_MUTATION = `
  mutation createIntegrationConfiguration(
    $input: CreateIntegrationConfigRequestInput
  ) {
    CreateIntegrationConfiguration(input: $input) {
      srn
    }
  }
`

export const CREATE_ASSIGNMENT_MUTATION = `
  mutation assignConfiguration($input: IntegrationAssignmentRequestInput) {
    CreateIntegrationAssignment(input: $input) {
      srn
    }
  }
`

export const DELETE_CONFIG_MUTATION = `
  mutation deleteConfig($input: DeleteRequestInput) {
    DeleteIntegrationConfiguration(input: $input) {
      srn
    }
  }
`
