/*
 * GroupNodeViewCardLayout Messages
 *
 * This contains all the text for the GroupNodeViewCardLayout component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.containers.GroupNodeViewCardLayout.title',
    defaultMessage: 'Data Details',
  },
  activeLabel: {
    id: 'app.containers.GroupNodeViewCardLayout.activeLabel',
    defaultMessage: 'Active',
  },
  typeLabel: {
    id: 'app.containers.GroupNodeViewCardLayout.typeLabel',
    defaultMessage: 'Type',
  },
  accountLabel: {
    id: 'app.containers.GroupNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id: 'app.containers.GroupNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  group: {
    id: 'app.containers.GroupNodeViewCardLayout.group',
    defaultMessage: 'Group',
  },
  lastModifiedLabel: {
    id: 'app.containers.GroupNodeViewCardLayout.lastModifiedLabel',
    defaultMessage: 'Last Modified',
  },
  createdLabel: {
    id: 'app.containers.GroupNodeViewCardLayout.createdLabel',
    defaultMessage: 'Created',
  },
  srnLabel: {
    id: 'app.containers.GroupNodeViewCardLayout.srnLabel',
    defaultMessage: 'srn',
  },
  service: {
    id: 'app.containers.GroupNodeViewCardLayout.service',
    defaultMessage: 'Service',
  },
  descriptionLabel: {
    id: 'app.containers.GroupNodeViewCardLayout.description',
    defaultMessage: 'Description',
  },
  sizeLabel: {
    id: 'app.containers.GroupNodeViewCardLayout.size',
    defaultMessage: 'Size',
  },
  bytes: {
    id: 'app.containers.GroupNodeViewCardLayout.bytes',
    defaultMessage: 'Bytes',
  },
  publicRead: {
    id: 'app.containers.GroupNodeViewCardLayout.publicRead',
    defaultMessage: 'Public Read',
  },
  publicWrite: {
    id: 'app.containers.GroupNodeViewCardLayout.publicWrite',
    defaultMessage: 'Public Write',
  },
  webisteEnabled: {
    id: 'app.containers.GroupNodeViewCardLayout.webisteEnabled',
    defaultMessage: 'Website Enabled',
  },
  auditEnabled: {
    id: 'app.containers.GroupNodeViewCardLayout.auditEnabled',
    defaultMessage: 'Audit Enabled',
  },
  containerLabel: {
    id: 'app.containers.GroupNodeViewCardLayout.containerLabel',
    defaultMessage: 'Container',
  },
  none: {
    id: 'app.containers.GroupNodeViewCardLayout.none',
    defaultMessage: 'None',
  },
  dataItem: {
    id: 'app.containers.GroupNodeViewCardLayout.dataItem',
    defaultMessage: 'This Data item is not part of a container',
  },
  record: {
    id: 'app.containers.GroupNodeViewCardLayout.record',
    defaultMessage: 'Record',
  },
  performed: {
    id: 'app.containers.GroupNodeViewCardLayout.performed',
    defaultMessage: 'Performed',
  },
  members: {
    id: 'app.containers.GroupNodeViewCardLayout.performed',
    defaultMessage: 'Members',
  },
  groupsPolicies: {
    id: 'app.containers.GroupNodeViewCardLayout.groupsPolicies',
    defaultMessage: 'Groups & Policies',
  },
})
