/*
 *
 * Explorer constants
 *
 */

export const GET_SOURCE_NODE = 'app/Explorer/GET_SOURCE_NODE'
export const GET_MULTI_SOURCE_NODES = 'app/Explorer/GET_MULTI_SOURCE_NODES'
export const SET_SOURCE_NODES = 'app/Explorer/SET_SOURCE_NODES'
export const GET_RELATIONS = 'app/Explorer/GET_RELATIONS'
export const SET_RELATIONS = 'app/Explorer/SET_RELATIONS'
export const REMOVE_RELATIONS = 'app/Explorer/REMOVE_RELATIONS'
export const SET_FILTER = 'app/Explorer/SET_FILTER'
export const REMOVE_FILTER = 'app/Explorer/REMOVE_FILTER'
export const SELECT_NODE = 'app/Explorer/SELECT_NODE'
export const SET_AVAILABLE_FILTERS = 'app/Explorer/SET_AVAILABLE_FILTERS'
export const CLEAR_GRAPH = 'app/Explorer/CLEAR_GRAPH'
export const GET_NODE_PREVIEW = 'app/Explorer/GET_NODE_PREVIEW'
export const SET_NODE_PREVIEW = 'app/Explorer/SET_NODE_PREVIEW'
export const LOAD_ALL_CHILDREN = 'app/Explorer/LOAD_ALL_CHILDREN'
export const CLEAR_FILTERS = 'app/Explorer/CLEAR_FILTERS'
export const REMOVE_NODE = 'app/Explorer/REMOVE_NODE'
export const GET_NODE_PREVIEW_RELATIONS =
  'app/Explorer/GET_NODE_PREVIEW_RELATIONS'
export const SET_NODE_PREVIEW_RELATIONS =
  'app/Explorer/SET_NODE_PREVIEW_RELATIONS'

export const HANDLE_EXPAND = 'app/Explorer/HANDLE_EXPAND'
export const HANDLE_HIDE = 'app/Explorer/HANDLE_HIDE'
export const HANDLE_SHOW = 'app/Explorer/HANDLE_SHOW'
export const HANDLE_UPDATE = 'app/Explorer/HANDLE_UPDATE'
export const LOAD_NODES = 'app/Explorer/LOAD_NODES'
