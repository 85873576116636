import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import {
  SET_COMPUTE_CONTENT,
  GET_COMPUTE_CONTENT,
  GET_INBOUND_PUBLIC_FILTERS,
  SET_INBOUND_PUBLIC_FILTERS,
  GET_INBOUND_PRIVATE_FILTERS,
  SET_INBOUND_PRIVATE_FILTERS,
  GET_INBOUND_PUBLIC_PATH,
  SET_INBOUND_PUBLIC_PATH,
  GET_INBOUND_PRIVATE_PATH,
  SET_INBOUND_PRIVATE_PATH,
  GET_OUTBOUND_PUBLIC_FILTERS,
  SET_OUTBOUND_PUBLIC_FILTERS,
  GET_OUTBOUND_PRIVATE_FILTERS,
  SET_OUTBOUND_PRIVATE_FILTERS,
  GET_OUTBOUND_PUBLIC_PATH,
  SET_OUTBOUND_PUBLIC_PATH,
  GET_OUTBOUND_PRIVATE_PATH,
  SET_OUTBOUND_PRIVATE_PATH,
  GET_INDIRECT_INBOUND_PUBLIC_FILTERS,
  SET_INDIRECT_INBOUND_PUBLIC_FILTERS,
  GET_INDIRECT_INBOUND_PRIVATE_FILTERS,
  SET_INDIRECT_INBOUND_PRIVATE_FILTERS,
  GET_INDIRECT_INBOUND_PUBLIC_PATH,
  SET_INDIRECT_INBOUND_PUBLIC_PATH,
  GET_INDIRECT_INBOUND_PRIVATE_PATH,
  SET_INDIRECT_INBOUND_PRIVATE_PATH,
  GET_COMPUTE_SECURITY_GROUPS,
  SET_COMPUTE_SECURITY_GROUPS,
  ERR_COMPUTE_SECURITY_GROUPS,
} from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  computeContent: {},
  isLoading: false,
  inboundPublicFilters: {},
  loadingInboundPublicFilters: false,
  inboundPrivateFilters: {},
  loadingInboundPrivateFilters: false,
  inboundPublicPath: {},
  loadingInboundPublicPath: false,
  inboundPrivatePath: {},
  loadingInboundPrivatePath: false,
  outboundPublicFilters: {},
  loadingOutboundPublicFilters: false,
  outboundPrivateFilters: {},
  loadingOutboundPrivateFilters: false,
  outboundPublicPath: {},
  loadingOutboundPublicPath: false,
  outboundPrivatePath: {},
  loadingOutboundPrivatePath: false,
  indirectInboundPublicFilters: {},
  loadingIndirectInboundPublicFilters: false,
  indirectInboundPrivateFilters: {},
  loadingIndirectInboundPrivateFilters: false,
  indirectInboundPublicPath: {},
  loadingIndirectInboundPublicPath: false,
  indirectInboundPrivatePath: {},
  loadingIndirectInboundPrivatePath: false,
  securityGroups: {},
  loadingSecurityGroups: false,
  errorSecurityGroups: false,
})

const computeNodeViewCardLayoutReducer = handleActions(
  {
    [GET_COMPUTE_CONTENT]: state => state.set('isLoading', true),
    [SET_COMPUTE_CONTENT]: (state, { payload }) => {
      return state
        .set('computeContent', fromJS(payload))
        .set('isLoading', false)
    },
    [GET_INBOUND_PUBLIC_FILTERS]: state =>
      state.set('loadingInboundPublicFilters', true),
    [SET_INBOUND_PUBLIC_FILTERS]: (state, { payload }) => {
      return state
        .set('inboundPublicFilters', fromJS(payload))
        .set('loadingInboundPublicFilters', false)
    },
    [GET_INBOUND_PRIVATE_FILTERS]: state =>
      state.set('loadingInboundPrivateFilters', true),
    [SET_INBOUND_PRIVATE_FILTERS]: (state, { payload }) => {
      return state
        .set('inboundPrivateFilters', fromJS(payload))
        .set('loadingInboundPrivateFilters', false)
    },
    [GET_INBOUND_PUBLIC_PATH]: state =>
      state.set('loadingInboundPublicPath', true),
    [SET_INBOUND_PUBLIC_PATH]: (state, { payload }) => {
      return state
        .set('inboundPublicPath', fromJS(payload))
        .set('loadingInboundPublicPath', false)
    },
    [GET_INBOUND_PRIVATE_PATH]: state =>
      state.set('loadingInboundPrivatePath', true),
    [SET_INBOUND_PRIVATE_PATH]: (state, { payload }) => {
      return state
        .set('inboundPrivatePath', fromJS(payload))
        .set('loadingInboundPrivatePath', false)
    },
    [GET_OUTBOUND_PUBLIC_FILTERS]: state =>
      state.set('loadingOutboundPublicFilters', true),
    [SET_OUTBOUND_PUBLIC_FILTERS]: (state, { payload }) => {
      return state
        .set('outboundPublicFilters', fromJS(payload))
        .set('loadingOutboundPublicFilters', false)
    },
    [GET_OUTBOUND_PRIVATE_FILTERS]: state =>
      state.set('loadingOutboundPrivateFilters', true),
    [SET_OUTBOUND_PRIVATE_FILTERS]: (state, { payload }) => {
      return state
        .set('outboundPrivateFilters', fromJS(payload))
        .set('loadingOutboundPrivateFilters', false)
    },
    [GET_OUTBOUND_PUBLIC_PATH]: state =>
      state.set('loadingOutboundPublicPath', true),
    [SET_OUTBOUND_PUBLIC_PATH]: (state, { payload }) => {
      return state
        .set('outboundPublicPath', fromJS(payload))
        .set('loadingOutboundPublicPath', false)
    },
    [GET_OUTBOUND_PRIVATE_PATH]: state =>
      state.set('loadingOutboundPrivatePath', true),
    [SET_OUTBOUND_PRIVATE_PATH]: (state, { payload }) => {
      return state
        .set('outboundPrivatePath', fromJS(payload))
        .set('loadingOutboundPrivatePath', false)
    },
    [GET_INDIRECT_INBOUND_PUBLIC_FILTERS]: state =>
      state.set('loadingIndirectInboundPublicFilters', true),
    [SET_INDIRECT_INBOUND_PUBLIC_FILTERS]: (state, { payload }) => {
      return state
        .set('indirectInboundPublicFilters', fromJS(payload))
        .set('loadingIndirectInboundPublicFilters', false)
    },
    [GET_INDIRECT_INBOUND_PRIVATE_FILTERS]: state =>
      state.set('loadingIndirectInboundPrivateFilters', true),
    [SET_INDIRECT_INBOUND_PRIVATE_FILTERS]: (state, { payload }) => {
      return state
        .set('indirectInboundPrivateFilters', fromJS(payload))
        .set('loadingIndirectInboundPrivateFilters', false)
    },
    [GET_INDIRECT_INBOUND_PUBLIC_PATH]: state =>
      state.set('loadingIndirectInboundPublicPath', true),
    [SET_INDIRECT_INBOUND_PUBLIC_PATH]: (state, { payload }) => {
      return state
        .set('indirectInboundPublicPath', fromJS(payload))
        .set('loadingIndirectInboundPublicPath', false)
    },
    [GET_INDIRECT_INBOUND_PRIVATE_PATH]: state =>
      state.set('loadingIndirectInboundPrivatePath', true),
    [SET_INDIRECT_INBOUND_PRIVATE_PATH]: (state, { payload }) => {
      return state
        .set('indirectInboundPrivatePath', fromJS(payload))
        .set('loadingIndirectInboundPrivatePath', false)
    },
    [GET_COMPUTE_SECURITY_GROUPS]: state =>
      state
        .set('loadingSecurityGroups', true)
        .set('errorSecurityGroups', false),
    [SET_COMPUTE_SECURITY_GROUPS]: (state, { payload }) =>
      state
        .set('loadingSecurityGroups', false)
        .set('errorSecurityGroups', false)
        .set('securityGroups', fromJS(payload)),
    [ERR_COMPUTE_SECURITY_GROUPS]: state =>
      state
        .set('loadingSecurityGroups', false)
        .set('errorSecurityGroups', true),
    [CLEAR_DATA]: () => initialState,
  },
  initialState
)

export default computeNodeViewCardLayoutReducer
