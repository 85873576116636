import React from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export const ToolbarDivider = ({ theme, active }) => {
  const styles = {
    container: {
      height: '1.5em',
      borderRight: active
        ? `1px solid ${theme.light}`
        : `1px solid ${theme.neutralMedium}`,
      margin: 'auto 0.7em',
    },
  }

  return <div style={styles.container} />
}

ToolbarDivider.propTypes = {
  active: PropTypes.bool,
  theme: themeShape,
}
export default themeable(ToolbarDivider)
