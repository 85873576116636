export const FETCH_SAVED_SEARCH_WIDGET_RESULTS =
  'app/WidgetResultExplorer/FETCH_SAVED_SEARCH_WIDGET_RESULTS'
export const FETCH_ADVANCED_SEARCH_WIDGET_RESULTS =
  'app/WidgetResultExplorer/FETCH_ADVANCED_SEARCH_WIDGET_RESULTS'
export const SET_WIDGET_RESULTS = 'app/WidgetResultExplorer/SET_WIDGET_RESULTS'
export const SET_TITLE = 'app/WidgetResultExplorer/SET_TITLE'
export const SET_SONRAI_SEARCH_NAME =
  'app/WidgetResultExplorer/SET_SONRAI_SEARCH_NAME'
export const SET_SAVED_SEARCH_ID =
  'app/WidgetResultExplorer/SET_SAVED_SEARCH_ID'
export const SET_SAVED_SEARCH_FILTER_CARD_ID =
  'app/WidgetResultExplorer/SET_SAVED_SEARCH_FILTER_CARD_ID'
export const SET_WIDGET_RESULTS_ERROR =
  'app.WidgetResultExplorer/SET_WIDGET_RESULTS_ERROR'
export const SET_FILTER = 'app.WidgetResultExplorer/SET_FILTER'
export const SET_ADVANCED_QUERY = 'app.WidgetResultExplorer/SET_ADVANCED_QUERY'
export const FETCH_CUSTOM_SEARCH_WIDGET_RESULTS =
  'app/WidgetResultsExplorer/FETCH_CUSTOM_SEARCH_WIDGET_RESULTS'
