import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Set, fromJS } from 'immutable'

import { getTitleForSeverity } from 'containers/RapSheet/processData.js'
import { getAlertLevelColorHML } from 'utils/widgetUtils'
import themeable from 'containers/ThemeManager/Themeable'
import { colorForString, colorForEntityString } from 'utils/sonraiUtils'
import SunburstPieChart from './SunburstPieChart'
class SunburstMultiView extends Component {
  //TODO: move this logic to another component in Rapsheet to keep components/SunburstVis more generic
  styles = {
    container: {
      gridArea: 'sunburst',
      paddingRight: '0.5em',
      marginRight: '0.5em',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      gridRowGap: '0.5em',
      gridTemplateAreas:
        '"severitychart typechart swimlanechart" "catchart namechart ."',
    },
  }

  dedupeDataBySwimlane = () => {
    const noSwimlane = fromJS(this.props.data).map(alertLog =>
      alertLog.delete('swimlaneSRNs').delete('swimlaneSrn')
    )

    return new Set(noSwimlane).toList().toJS()
  }

  getAlertCounts = (data, property) => {
    const groupMap = _.groupBy(data, property)
    const keys = _.keys(groupMap)
    const countMap = {}
    keys.forEach(key => {
      countMap[key] = groupMap[key].reduce(
        (sum, alert) => (sum += alert.count),
        0
      )
    })

    return countMap
  }

  getSeverityPieChartData = data => {
    const countMap = this.getAlertCounts(data, 'severityCategory')
    const keys = _.keys(countMap)

    const sortedKeys = _.orderBy(keys, key => countMap[key], 'desc')

    let colors = []
    let slices = []
    let labels = []
    let displayLabels = []

    sortedKeys.forEach(key => {
      colors.push(getAlertLevelColorHML(key))
      slices.push(countMap[key])
      labels.push(key)
      displayLabels.push(getTitleForSeverity(key))
    })

    return { labels, slices, colors, displayLabels }
  }

  getResourceLabelPieChartData = data => {
    const countMap = this.getAlertCounts(data, 'resourceLabel')
    const keys = _.keys(countMap)
    const sortedKeys = _.orderBy(keys, key => countMap[key], 'desc')

    let colors = []
    let slices = []
    let labels = []

    sortedKeys.forEach(key => {
      colors.push(colorForEntityString(key, this.props.typeColors))
      slices.push(countMap[key])
      labels.push(key)
    })

    return { labels, slices, colors }
  }

  getTicketTypePieChartData = data => {
    const nameMap = {}
    const groupMap = _.groupBy(data, alert => {
      if (alert.ticketType === 'Policy') {
        const controlFrameworkId = this.props.policies.getIn([
          alert.ticketKey,
          'containedByControlFramework',
          'items',
          0,
          'srn',
        ])

        const controlFrameworkName = this.props.controlFrameworks.getIn([
          controlFrameworkId,
          'title',
        ])
        if (controlFrameworkName) {
          nameMap[controlFrameworkId] = controlFrameworkName
          return controlFrameworkId
        }
      }

      return alert.ticketType
    })

    const keys = _.keys(groupMap)
    const countMap = {}
    keys.forEach(key => {
      countMap[key] = groupMap[key].reduce(
        (sum, alert) => (sum += alert.count),
        0
      )
    })

    const sortedKeys = _.orderBy(keys, key => countMap[key], 'desc')

    let colors = []
    let slices = []
    let labels = []
    let displayLabels = []

    sortedKeys.forEach(key => {
      colors.push(colorForString(key))
      slices.push(countMap[key])
      labels.push(key)
      displayLabels.push(nameMap[key] || key)
    })

    return { labels, slices, colors, displayLabels }
  }

  getKeyNamePieChartData = data => {
    const nameMap = {}

    const countMap = this.getAlertCounts(data, entry => {
      if (entry.ticketType === 'Policy') {
        nameMap[entry.ticketKey] = this.props.policies.getIn(
          [entry.ticketKey, 'title'],
          entry.ticketKey
        )
      }

      return entry.ticketKey
    })
    const keys = _.keys(countMap)
    const sortedKeys = _.orderBy(keys, key => countMap[key], 'desc')

    let colors = []
    let slices = []
    let labels = []
    let displayLabels = []

    sortedKeys.forEach(key => {
      colors.push(colorForString(key))
      slices.push(countMap[key])
      labels.push(key)
      displayLabels.push(nameMap[key] || key)
    })

    return { labels, slices, colors, displayLabels }
  }

  getSwimlanePieChartData = data => {
    const groupMap = _.groupBy(
      data.flatMap(ticket => {
        return ticket.swimlaneSrns.map(swimlaneSrn => {
          return { ...ticket, swimlaneSrn }
        })
      }),
      alert => alert.swimlaneSrn
    )
    const keys = _.keys(groupMap)
    const countMap = {}
    keys.forEach(key => {
      countMap[key] = groupMap[key].reduce(
        (sum, alert) => (sum += alert.count),
        0
      )
    })

    const sortedKeys = _.orderBy(keys, key => countMap[key], 'desc')

    let colors = []
    let slices = []
    let labels = []
    let displayLabels = []

    sortedKeys.forEach(key => {
      colors.push(colorForString(key))
      slices.push(countMap[key])
      labels.push(key)
      displayLabels.push(this.props.swimlanes.getIn([key, 'title']) || 'ALL')
    })
    return { labels, slices, colors, displayLabels }
  }

  handleClick = (
    { index, labels, values, displayLabels, sectionTitle },
    key
  ) => {
    const label = _.get(labels, index)
    const value = _.get(values, index)
    const displayLabel = _.get(displayLabels, index) || label

    this.props.onClickSlice({
      key,
      label,
      displayLabel,
      value,
      index,
      sectionTitle,
    })
  }

  render() {
    const swimlanePieChartData = this.getSwimlanePieChartData(this.props.data)

    const deduped = this.dedupeDataBySwimlane()
    const severityPieChartData = this.getSeverityPieChartData(deduped)
    const resourceLabelPieChartData = this.getResourceLabelPieChartData(deduped)
    const ticketTypePieChartData = this.getTicketTypePieChartData(deduped)
    const keyNamePieChartData = this.getKeyNamePieChartData(deduped)

    return (
      <div style={this.styles.container}>
        <div
          style={{
            gridArea: 'severitychart',
          }}
        >
          <SunburstPieChart
            onClickPoint={params =>
              this.handleClick(params, 'severityCategory')
            }
            yValues={severityPieChartData.slices}
            yLabels={severityPieChartData.labels}
            yValueDisplayLabels={severityPieChartData.displayLabels}
            colors={severityPieChartData.colors}
            title="Severity Category"
          />
        </div>
        <div
          style={{
            gridArea: 'typechart',
          }}
        >
          <SunburstPieChart
            onClickPoint={params => this.handleClick(params, 'resourceLabel')}
            yValues={resourceLabelPieChartData.slices}
            yLabels={resourceLabelPieChartData.labels}
            colors={resourceLabelPieChartData.colors}
            title="Resource Label"
          />
        </div>
        <div
          style={{
            gridArea: 'catchart',
          }}
        >
          <SunburstPieChart
            onClickPoint={params => this.handleClick(params, 'ticketKey')}
            yValues={ticketTypePieChartData.slices}
            yValueDisplayLabels={ticketTypePieChartData.displayLabels}
            yLabels={ticketTypePieChartData.labels}
            colors={ticketTypePieChartData.colors}
            title="Ticket Type"
          />
        </div>
        <div
          style={{
            gridArea: 'namechart',
          }}
        >
          <SunburstPieChart
            onClickPoint={params => this.handleClick(params, 'ticketKey')}
            yValues={keyNamePieChartData.slices}
            yValueDisplayLabels={keyNamePieChartData.displayLabels}
            yLabels={keyNamePieChartData.labels}
            colors={keyNamePieChartData.colors}
            title="Policy or Activity Type"
          />
        </div>
        <div
          style={{
            gridArea: 'swimlanechart',
          }}
        >
          <SunburstPieChart
            onClickPoint={params => this.handleClick(params, 'swimlane')}
            yValues={swimlanePieChartData.slices}
            yLabels={swimlanePieChartData.labels}
            yValueDisplayLabels={swimlanePieChartData.displayLabels}
            colors={swimlanePieChartData.colors}
            title="Swimlanes"
          />
        </div>
      </div>
    )
  }
}

SunburstMultiView.propTypes = {
  data: PropTypes.array.isRequired,
  onClickSlice: PropTypes.func.isRequired,
  typeColors: PropTypes.object,
  swimlanes: ImmutablePropTypes.map.isRequired,
  policies: ImmutablePropTypes.map.isRequired,
  controlFrameworks: ImmutablePropTypes.map.isRequired,
}

export default themeable(SunburstMultiView)
