import { fromJS, Map } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  GET_ESCALATIONS,
  SET_ESCALATIONS,
  SET_ESCALATIONS_ERROR,
  CREATE_ESCALATION,
  CREATE_ESCALATION_SUCCESS,
  CREATE_ESCALATION_ERROR,
  DELETE_ESCALATION,
  DELETE_ESCALATION_SUCCESS,
  // DELETE_ESCALATION_ERROR,
  FETCH_ESCALATION_FILTER_OPTIONS,
  FETCH_ESCALATION_FILTER_OPTIONS_SUCCESS,
  FETCH_ESCALATION_FILTER_OPTIONS_ERROR,
  UPDATE_ESCALATION,
  UPDATE_ESCALATION_SUCCESS,
  UPDATE_ESCALATION_ERROR,
  UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR,
  CLEAR_UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR,
} from './constants'

const initialState = fromJS({
  escalations: null,
  escalationsLoading: false,
  escalationError: null,
  creatingEscalationError: null,
  escalationFilterOptions: null,
  escalationFilterOptionsLoading: false,
  escalationFilterOptionsError: null,
  creatingEscalation: false,
  updatingEscalation: false,
  updatingEscalationError: null,
  updatingEscalationInvalidSwimlaneForTickets: null,
  deletingEscalations: Map(),
})

const escalationDataReducers = handleActions(
  {
    [GET_ESCALATIONS]: state =>
      state.set('escalationsLoading', true).set('escalationsError', null),
    [SET_ESCALATIONS]: (state, { payload }) => {
      const keyEscalations = {}
      payload.escalations.forEach(esc => (keyEscalations[esc.srn] = esc))
      return state
        .set('escalationsLoading', false)
        .set('escalations', fromJS(keyEscalations))
        .set('escalationsError', null)
    },
    [SET_ESCALATIONS_ERROR]: (state, { payload }) =>
      state
        .set('escalationsLoading', false)
        .set('escalations', null)
        .set('escalationsError', payload.error),
    [CREATE_ESCALATION]: state => state.set('creatingEscalation', true),
    [CREATE_ESCALATION_SUCCESS]: (state, { payload }) =>
      state
        .set('creatingEscalation', false)
        .setIn(
          ['escalations', payload.escalation.srn],
          fromJS(payload.escalation)
        ),
    [CREATE_ESCALATION_ERROR]: (state, { payload }) =>
      state
        .set('creatingEscalation', false)
        .set('creatingEscalationError', payload.error),
    [DELETE_ESCALATION]: (state, { payload }) =>
      state
        .setIn(['deletingEscalations', payload], true)
        .setIn(['deletingEscalationError', payload], null),
    [DELETE_ESCALATION_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['deletingEscalations', payload], false)
        .setIn(['deletingEscalationError', payload], null)
        .set(
          'escalations',
          state.get('escalations').filter(esc => esc.get('srn') !== payload)
        ),
    [UPDATE_ESCALATION]: state =>
      state
        .set('updatingEscalation', true)
        .set('updatingEscalationError', null)
        .set('updatingEscalationInvalidSwimlaneForTickets', null),
    [UPDATE_ESCALATION_SUCCESS]: (state, { payload }) => {
      let oldEscalation = state.getIn(['escalations', payload.escalation.srn])
      if (payload.escalation.details) {
        oldEscalation = oldEscalation
          .set('title', payload.escalation.details.title)
          .set('description', payload.escalation.details.description)
      }

      if (payload.escalation.swimlanes) {
        payload.escalation.swimlanes.added.forEach(add => {
          if (oldEscalation.get('assignments')) {
            oldEscalation = oldEscalation.set(
              'assignments',
              oldEscalation.get('assignments').push(fromJS(add))
            )
          } else {
            oldEscalation = oldEscalation.set('assignments', fromJS([add]))
          }
        })
        payload.escalation.swimlanes.removed.forEach(remove => {
          oldEscalation = oldEscalation.set(
            'assignments',
            oldEscalation
              .get('assignments')
              .filter(ass => ass.get('srn') !== remove.srn)
          )
        })
      }
      if (payload.escalation.filters) {
        payload.escalation.filters.added.forEach(add => {
          if (oldEscalation.get('filters')) {
            oldEscalation = oldEscalation.set(
              'filters',
              oldEscalation.get('filters').push(fromJS(add))
            )
          } else {
            oldEscalation = oldEscalation.set('filters', fromJS([add]))
          }
        })
        payload.escalation.filters.removed.forEach(remove => {
          oldEscalation = oldEscalation.set(
            'filters',
            oldEscalation
              .get('filters')
              .filter(ass => ass.get('srn') !== remove.srn)
          )
        })
      }
      if (payload.escalation.rules) {
        payload.escalation.rules.added.forEach(add => {
          if (oldEscalation.get('rules')) {
            oldEscalation = oldEscalation.set(
              'rules',
              oldEscalation.get('rules').push(fromJS(add))
            )
          } else {
            oldEscalation = oldEscalation.set('rules', fromJS([add]))
          }
        })
        payload.escalation.rules.removed.forEach(remove => {
          oldEscalation = oldEscalation.set(
            'rules',
            oldEscalation
              .get('rules')
              .filter(ass => ass.get('srn') !== remove.srn)
          )
        })
      }
      return state
        .set('updatingEscalation', false)
        .setIn(['escalations', payload.escalation.srn], oldEscalation)
        .set('updatingEscalationError', null)
    },
    [UPDATE_ESCALATION_ERROR]: (state, { payload }) =>
      state
        .set('updatingEscalation', false)
        .set('updatingEscalationError', payload.error),
    [UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR]: (
      state,
      { payload }
    ) =>
      state
        .set('updatingEscalationInvalidSwimlaneForTickets', fromJS(payload))
        .set('updatingEscalation', false),
    [CLEAR_UPDATE_ESCALATION_SWIMLANE_EDIT_CUSTOM_TICKET_ERROR]: state =>
      state.set('updatingEscalationInvalidSwimlaneForTickets', null),
    [FETCH_ESCALATION_FILTER_OPTIONS]: state =>
      state.set('escalationFilterOptionsLoading', true),
    [FETCH_ESCALATION_FILTER_OPTIONS_SUCCESS]: (state, { payload }) =>
      state
        .set('escalationFilterOptionsLoading', false)
        .set('escalationFilterOptions', fromJS(payload.options)),
    [FETCH_ESCALATION_FILTER_OPTIONS_ERROR]: (state, { payload }) =>
      state
        .set('escalationFilterOptionsLoading', false)
        .set('escalationFilterOptions', null)
        .set('escalationFilterOptionsError', payload.error),
  },
  initialState
)

export default escalationDataReducers
