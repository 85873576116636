import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { Modal as DataClassificationModal } from 'containers/DataClassification'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'

import dataClassificationMessages from 'containers/DataClassification/messages'
import messages from './messages'

import permissionChecker from 'containers/PermissionChecker'
import { isClassificationSupported } from 'containers/DataClassification/utils'

export class NodeViewDetailsClassification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  /**
   * Classifications look like:
   * <classifier>.<sampleType (sample/count)>=<sample>
   * eg: EMAIL.sample=chafferty@eprime.com
   */
  desconstructSample = classifcation => {
    const segments = classifcation.split('=')
    const [classifier, sampleType] = segments.shift().split('.')
    const sample = segments.join('=')
    return { classifier, sampleType, sample }
  }

  renderBodyForEmpty = () => {
    const classificationSupported = isClassificationSupported(
      this.props.nodeData
    )
    const hasPermission = this.props.userHasPermission({
      permissionName: 'edit.dataclassificationconfig',
      swimlanes: [...this.props.nodeData.swimlaneSRNs],
    })
    return (
      <Fragment>
        <div key="message">
          <DynamicFormattedMessage {...messages.classificationsNone} />
        </div>
        {hasPermission && classificationSupported && (
          <TextLink key="link" color="primary" onClick={this.openModal}>
            <div>
              <DynamicFormattedMessage {...messages.classificationsConfigJob} />
            </div>
          </TextLink>
        )}
      </Fragment>
    )
  }

  renderBodyForSamples = () => {
    if (this.props.value.length == 0) {
      return <DynamicFormattedMessage {...messages.classificationsNoneFound} />
    }

    /**
     * Create an object like of classifiers plus count of values found:
     * {
     *   EMAIL: 2,
     *   FULLNAME: 3,
     * }
     */
    const classifierCounts = this.props.value
      .map(this.desconstructSample)
      .filter(({ sampleType }) => 'count' === sampleType)
      .reduce((acc, cur) => {
        const { classifier, sample } = cur
        if (undefined === acc[classifier]) {
          acc[classifier] = 0
        }
        return { ...acc, [classifier]: acc[classifier] + Number(sample) }
      }, {})

    const lis = Object.keys(classifierCounts)
      .sort()
      .map(key => {
        return (
          <li key={'classifier-count-' + key}>
            <span>
              {dataClassificationMessages[`dataTableClassifierName_${key}`] ? (
                <DynamicFormattedMessage
                  {...dataClassificationMessages[
                    `dataTableClassifierName_${key}`
                  ]}
                />
              ) : (
                <span>{key}</span>
              )}
            </span>
            <span> ({classifierCounts[key]})</span>
          </li>
        )
      })

    return (
      <div>
        <ul>{lis}</ul>
      </div>
    )
  }

  render() {
    return (
      <div>
        <DataClassificationModal
          open={this.state.modalOpen}
          close={this.closeModal}
          nodeData={this.props.nodeData}
        />
        {this.props.value
          ? this.renderBodyForSamples()
          : this.renderBodyForEmpty()}
      </div>
    )
  }
}

NodeViewDetailsClassification.propTypes = {
  nodeData: PropTypes.object.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  userHasPermission: PropTypes.func.isRequired,
}

export default permissionChecker(NodeViewDetailsClassification)
