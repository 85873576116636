import React, { Component } from 'react'
import BorderedCard from 'components/BorderedCard'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import Icon from 'components/Icon'
import BorderlessButton from 'components/BorderlessButton'
import TextLink from 'components/TextLink'
import Tooltip from 'components/Tooltip'
import { getIconMapping } from 'utils/sonraiUtils'
import { getColor } from 'components/GraphVis/nodeIcons'

class FilterPanel extends Component {
  state = {
    filter: '',
    selected: [],
  }

  style = {
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    title: { fontSize: '1.2rem' },
    filterBar: { margin: '1rem 0rem' },
    toggleFilterPanel: {
      top: '1rem',
      right: '1rem',
      position: 'absolute',
      cursor: 'pointer',
    },
    button: {
      padding: '0.25rem',
      fontSize: '0.8rem',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: '140px',
    },
    entityTitle: { fontSize: '0.95rem', marginLeft: '1rem' },
    entityContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '0.85rem 0.4rem',
      justifyContent: 'space-between',
      margin: '0.25rem',
    },
    count: { color: '#c8c8c8' },
    filterBarContainer: {
      padding: '0.8rem 0.4rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: '1px solid lightgrey',
    },
    tooltip: {
      fontWeight: '400',
      fontSize: '14px',
      overflow: 'hidden',
    },
  }

  isHidden = type => {
    const types = this.props.hiddenNodes.map(x => x.get('__typename'))
    return types.includes(type)
  }

  handleCollapse = () => {
    const { selected } = this.state
    const { expandedNodeTypes, nodes } = this.props
    const labels = selected.filter(x => expandedNodeTypes.toJS().includes(x))
    const nodeTypesToCollapse = nodes.filter(node => {
      if (labels.includes(node.get('label'))) {
        return node
      }
    })
    const parents = _.keys(nodeTypesToCollapse.toJS())
    let update = []
    let updatedNodes = {}
    nodes.forEach(node => {
      if (node.get('parents') || node.get('isSourceNode')) {
        if (
          !parents.includes(node.getIn(['parents', '0'])) ||
          node.get('isSourceNode')
        ) {
          update.push(node.toJS())
        }
      }
    })

    update.forEach(node => {
      updatedNodes[node.srn] = node
    })

    this.setState({
      selected: [],
    })
    this.props.handleUpdate(updatedNodes)
  }

  handleExpand = () => {
    const { selected } = this.state

    const totalNumberOfEntites = _.sum(
      selected.map(x => this.getCount(x, true))
    )

    if (totalNumberOfEntites >= 10) {
      this.setState({
        warningModal: true,
      })
    } else {
      this.setState({
        selected: [],
      })
      this.props.handleExpand(selected)
    }
  }

  getCount = (type, int) => {
    const nodes = this.props.nodes
      .toList()
      .filter(node => node.get('__typename') == type)
    if (int) {
      return nodes.size
    }
    return nodes.size < 100 ? `${nodes.size}` : `100+`
  }

  handleChecked = type => {
    if (this.state.selected.includes(type)) {
      this.setState(oldState => {
        const newArr = [...oldState.selected]
        const index = newArr.indexOf(type)
        newArr.splice(index, 1)
        return { selected: newArr }
      })
    } else {
      this.setState(oldState => {
        const newArr = [...oldState.selected]
        newArr.push(type)
        return {
          selected: newArr,
        }
      })
    }
  }

  renderEntityTypes = () => {
    if (!this.props.data.isEmpty()) {
      const entityTypes =
        this.state.filter !== ''
          ? this.props.data.filter(type =>
              type.toLowerCase().includes(this.state.filter)
            )
          : this.props.data

      if (!entityTypes.isEmpty()) {
        return entityTypes.map((type, i) => {
          const isHidden = this.isHidden(type)
          const onClick = isHidden
            ? () => this.props.handleShow(type)
            : () => this.props.handleHide(type)
          const count = this.getCount(type)
          const iconName = getIconMapping(type).faIcon

          return (
            <div
              key={i}
              style={{
                ...this.style.entityContainer,
                ...{
                  borderBottom:
                    i !== entityTypes.size - 1 && '1px solid lightgrey',
                },
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  onChange={() => this.handleChecked(type)}
                  checked={
                    this.state.selected && this.state.selected.includes(type)
                  }
                  scale={0.8}
                />
                <div style={this.style.entityTitle}>
                  <Icon
                    fa
                    name={iconName}
                    color={getColor(
                      type,
                      this.props.theme,
                      this.props.typeColors
                    )}
                    transform="shrink-3"
                    style={{ marginRight: '0.5em' }}
                  />
                  {type} <span style={this.style.count}> ({count}) </span>
                </div>
              </div>
              <BorderlessButton
                onClick={onClick}
                style={{
                  fontSize: '0.85rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Tooltip
                  anchor={
                    <Icon
                      fa
                      name={isHidden ? 'eye' : 'eye-slash'}
                      style={{ fontSize: '0.9rem', marginLeft: '1rem' }}
                    />
                  }
                  tooltipContent={
                    <div style={this.style.tooltip}>
                      {isHidden ? `Show ${type}` : `Hide ${type}`}
                    </div>
                  }
                  position="right"
                />
              </BorderlessButton>
            </div>
          )
        })
      }
    }
  }
  isCollapseDisabled = () => {
    const { selected } = this.state
    const { expandedNodeTypes } = this.props
    if (!expandedNodeTypes || expandedNodeTypes.isEmpty()) {
      return true
    }

    return !!_.isEmpty(
      selected.filter(x => expandedNodeTypes.toJS().includes(x))
    )
  }

  isResetDisabled = () => {
    const { hiddenNodes, expandedNodeTypes } = this.props

    return !!hiddenNodes.isEmpty() && !!expandedNodeTypes.isEmpty()
  }

  handleReset = () => {
    const { sourceNodes, nodes } = this.props
    let update = []
    let updateNodes = {}
    sourceNodes.forEach(sourceNode => {
      nodes.forEach(node => {
        if (
          node.get('isSourceNode') ||
          node.get('parents').includes(sourceNode)
        ) {
          update.push(node.toJS())
        }
      })
    })

    update.forEach(node => {
      updateNodes[node.srn] = node
    })

    this.setState({
      selected: [],
    })

    this.props.handleUpdate(updateNodes)
  }

  getFilterBar = () => {
    const { selected } = this.state
    return (
      <div style={this.style.filterBarContainer}>
        <BorderlessButton
          disabled={this.isResetDisabled()}
          onClick={this.handleReset}
          style={{ fontSize: '0.85rem', marginRight: '1rem' }}
        >
          <Icon
            fa
            name="undo"
            style={{ fontSize: '0.9rem', marginRight: '0.5rem' }}
          />
          Reset
        </BorderlessButton>
        <BorderlessButton
          disabled={_.isEmpty(selected)}
          onClick={this.handleExpand}
          style={{ fontSize: '0.85rem', marginRight: '1rem' }}
        >
          <Icon
            fa
            name="expand-explorer"
            style={{ fontSize: '0.9rem', marginRight: '0.5rem' }}
          />
          Expand
        </BorderlessButton>
        <BorderlessButton
          disabled={this.isCollapseDisabled()}
          onClick={this.handleCollapse}
          style={{ fontSize: '0.85rem', marginRight: '1rem' }}
        >
          <Icon
            fa
            name="collapse"
            style={{ fontSize: '0.9rem', marginRight: '0.5rem' }}
          />
          Collapse
        </BorderlessButton>
      </div>
    )
  }

  handleFilter = e => {
    this.setState({
      filter: e.target.value,
    })
  }

  handleModalConfirm = () => {
    this.setState({ warningModal: false })
    this.props.handleExpand(this.state.selected)
  }

  handleModalCancel = () => {
    this.setState({ warningModal: false, selected: [] })
  }

  renderWarningModal = () => (
    <Modal isOpen={this.state.warningModal}>
      <ModalHeader toggle={this.handleModalCancel}>
        Confirm Delayed Physics
      </ModalHeader>
      <ModalBody>
        By expanding the relations of more than 10 entites at a time, the graph
        may suffer poor performance.
      </ModalBody>
      <ModalFooter>
        <TextLink color="secondary" onClick={this.handleModalCancel}>
          Cancel
        </TextLink>
        <Button color="primary" onClick={this.handleModalConfirm}>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  )

  render() {
    return (
      <BorderedCard style={this.props.style}>
        <div style={this.style.container}>
          <div
            onClick={this.props.toggleFilterPanel}
            style={this.style.toggleFilterPanel}
          >
            <Icon fa name="times" />
          </div>
          {this.renderWarningModal()}
          <div style={this.style.title}> Global Filters </div>
          <InputGroup style={this.style.filterBar}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon name="search" fa size="1x" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={this.state.filter}
              onChange={this.handleFilter}
              placeholder="Filter..."
            />
          </InputGroup>
          <div> {this.getFilterBar()} </div>
          <div style={{ overflow: 'auto' }}>{this.renderEntityTypes()}</div>
        </div>
      </BorderedCard>
    )
  }
}
FilterPanel.propTypes = {
  toggleFilterPanel: PropTypes.func,
  style: PropTypes.object,
  expandedNodeTypes: ImmutablePropTypes.iterable,
  handleExpand: PropTypes.func,
  hiddenNodes: ImmutablePropTypes.iterable,
  sourceNodes: ImmutablePropTypes.iterable,
  handleHide: PropTypes.func,
  handleShow: PropTypes.func,
  handleUpdate: PropTypes.func,
  data: ImmutablePropTypes.iterable,
  nodes: ImmutablePropTypes.iterable,
  theme: themeShape,
  typeColors: PropTypes.object,
}

export default themeable(FilterPanel)
