import React, { useEffect, useState } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import Checkbox from 'components/Checkbox'

export default function TemplateFieldCheckbox(props) {
  const [isChecked, setIsChecked] = useState(false)
  useEffect(() => {
    if (!props.fieldState) {
      return
    }
    const value = props.fieldState.get('value')

    // if the field is required make sure the value is explicitly set false
    if (props.fieldDefinition.required) {
      props.setTicketTemplateFieldValue({
        value: value ?? false,
        field: props.fieldDefinition,
      })
    }
  }, [])

  // try to set the value from the property
  useEffect(() => {
    if (!props.fieldState) {
      return
    }
    const value = props.fieldState.get('value')

    // is this a dumb way to check this? I don't even know anymore
    if (value === 'false' || value === false) {
      setIsChecked(false)
    }
    if (value === 'true' || value === true) {
      setIsChecked(true)
    }
  }, [props.fieldState])

  return (
    <Checkbox
      checked={isChecked}
      onChange={() => {
        props.setTicketTemplateFieldValue({
          value: !isChecked,
          field: props.fieldDefinition,
        })
      }}
    />
  )
}

TemplateFieldCheckbox.propTypes = {
  setTicketTemplateFieldValue: PropTypes.func.isRequired,
  fieldDefinition: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      required: PropTypes.bool,
      type: PropTypes.string.isRequired,
      select: PropTypes.shape({
        labelFields: PropTypes.arrayOf(PropTypes.string),
        valueField: PropTypes.string,
        search: PropTypes.string,
        path: PropTypes.arrayOf(PropTypes.string),
      }),
    })
  ),
  fieldState: ImmutablePropTypes.contains({
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    select: ImmutablePropTypes.contains({
      loading: PropTypes.bool,
      error: PropTypes.bool,
      data: ImmutablePropTypes.listOf(PropTypes.map),
    }),
  }),
}
