/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import '@babel/polyfill'

// Import all the third party stuff
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import createHistory from 'history/createBrowserHistory'
import Helmet from 'react-helmet'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { ModuleRegistry, AllModules } from '@ag-grid-enterprise/all-modules'
import { Auth0Provider } from './react-auth0-wrapper'
import { Route } from 'react-router-dom'

// Import stylesheets
import 'sanitize.css/sanitize.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/light-bootstrap-dashboard.css'
import 'react-dates/lib/css/_datepicker.css'
import 'graphiql/graphiql.css'
import '@fortawesome/fontawesome-pro/css/light.min.css'
import 'primereact/resources/themes/nova-light/theme.css'
import 'primereact/resources/primereact.min.css'
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css'
import 'vis/dist/vis-network.min.css'
import './global-styles.css'
import 'react-virtualized/styles.css'
import 'react-popper-tooltip/dist/styles.css'
import 'react-mde/lib/styles/css/react-mde-editor.css'
import 'react-mde/lib/styles/css/react-mde-suggestions.css'
import 'react-mde/lib/styles/css/react-mde-toolbar.css'
import 'react-mde/lib/styles/css/react-mde.css'

// Initialize datepickers
import 'react-dates/initialize'

//Initialize licenses
LicenseManager.setLicenseKey(
  'Sonrai_Security_Cloud_Risk_Control_Single_Application_4_Devs_1_Deployment_License_27_January_2021_[v2]_MTYxMTcwNTYwMDAwMA==4ba4a99cecd45891d1a22bec445fb0e9'
)
ModuleRegistry.registerModules(AllModules)

// Import root app
import App from 'containers/App'

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider'

// Load the favicon, the manifest json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicons/favicon.ico'
import '!file-loader?name=[name].[ext]!./images/favicons/icon-72x72.png'
import '!file-loader?name=[name].[ext]!./images/favicons/icon-96x96.png'
import '!file-loader?name=[name].[ext]!./images/favicons/icon-128x128.png'
import '!file-loader?name=[name].[ext]!./images/favicons/icon-144x144.png'
import '!file-loader?name=[name].[ext]!./images/favicons/icon-152x152.png'
import '!file-loader?name=[name].[ext]!./images/favicons/icon-192x192.png'
import '!file-loader?name=[name].[ext]!./images/favicons/icon-384x384.png'
import '!file-loader?name=[name].[ext]!./images/favicons/icon-512x512.png'
import '!file-loader?name=[name].[ext]!./images/favicons/favicon-32x32.png'
import '!file-loader?name=[name].[ext]!./images/favicons/favicon-16x16.png'
import '!file-loader?name=[name].[ext]!./images/favicons/apple-touch-icon.png'
import '!file-loader?name=[name].[ext]!./images/favicons/safari-pinned-tab.svg'
import '!file-loader?name=[name].[ext]!./images/favicons/browserconfig.xml'
import 'file-loader?name=[name].[ext]!./.htaccess'

/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './configureStore'

import framekiller from './framekiller'

framekiller()

// Import i18n messages
import { translationMessages } from './i18n'

if (window.config === undefined) {
  const xhttp = new XMLHttpRequest()
  xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      // Typical action to be performed when the document is ready:
      const text = xhttp.responseText
      try {
        window.config = JSON.parse(
          text.substring(text.indexOf('{'), text.length)
        )
        console.log('version: ', window.config.version) //eslint-disable-line no-console
      } catch (error) {
        //eslint-disable-next-line no-console
        console.error(
          'Unable to load config.json. Please contact us for assistance'
        )
      }
    }
  }

  xhttp.open('GET', '/config.json', false)
  xhttp.send()
}

// Create redux store with history
const initialState = {}
const history = createHistory()
const store = configureStore(initialState, history)
const MOUNT_NODE = document.getElementById('app')

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

if (process.env.NODE_ENV === 'production') {
  //Disable React dev tools in production
  if (
    typeof window === 'object' &&
    typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object'
  ) {
    for (let [key, value] of Object.entries(
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__
    )) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] =
        typeof value == 'function' ? () => {} : null
    }
  }
}

//CSP notice:
//The script-src 'unsafe-eval' is required in development mode for hot reloading.
const render = messages => {
  ReactDOM.render(
    <Fragment>
      <Helmet>
        {process.env.NODE_ENV !== 'production' && (
          <meta
            httpEquiv="Content-Security-Policy"
            content={`
              default-src 'self' ;
              connect-src 'self' localhost:8080 sonrai-kb.s3.amazonaws.com *.sonraisecurity.com dev-sonraisecurity.auth0.com stage-sonraisecurity.auth0.com; 
              font-src 'self' data:;
              frame-src 'self' login.s.sonraisecurity.com sonraisecurity.auth0.com dev-sonraisecurity.auth0.com stage-sonraisecurity.auth0.com; 
              img-src 'self' *.googleusercontent.com sonrai-logos.s3.amazonaws.com s.gravatar.com secure.gravatar.com *.wp.com data:; 
              script-src 'self' cdn.auth0.com secure.gravatar.com 'unsafe-eval'; 
              style-src 'self' 'unsafe-inline'
            `}
          />
        )}
      </Helmet>

      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <ConnectedRouter history={history}>
            <Auth0Provider
              domain={window.config.idpHost}
              client_id={window.config.idpClientId}
              redirect_uri={`${window.location.origin}/Login`}
              onRedirectCallback={onRedirectCallback}
            >
              <Route component={App} />
            </Auth0Provider>
          </ConnectedRouter>
        </LanguageProvider>
      </Provider>
    </Fragment>,
    MOUNT_NODE
  )
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}
