import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { getDisplayName } from 'utils/hocHelpers'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { fromJS } from 'immutable'
import { userHasPermission } from 'auth/permissions'
import { selectQueryTypes } from 'containers/SonraiData/selectors'
import { useAuth0 } from 'react-auth0-wrapper'
import useSonraiUserPermissions from 'auth/hooks/sonrai-users-permissions'
import { getCurrentOrg } from 'auth/current-org'
import useSwimlanesForPermissionCheck from './useSwimlanesForPermissionCheck'

const resourceIdGeneratorArgs = userData => {
  return {
    id: userData.get('sub'),
    username: userData.get('name'),
    org: getCurrentOrg(),
  }
}

const permissionChecker = WrappedComponent => {
  const comp = props => {
    const { user } = useAuth0()

    const { isSrn, getResourceId } = useSwimlanesForPermissionCheck()
    const permissions = useSonraiUserPermissions(user)
    const doUserHasPermission = ({ permissionName, resourceId, swimlanes }) => {
      // resourceId can either be the string literal or a function used to generate
      // it from the userData transformed into arguments
      if (_.isFunction(resourceId)) {
        resourceId = resourceId(resourceIdGeneratorArgs(fromJS(user)))
      }

      // swimlanes can be either a list of swimlane SRNs or resourceIds but if they're
      // srns, chang them to resourceIds
      if (swimlanes) {
        swimlanes = swimlanes
          .map(swimlane => {
            if (isSrn(swimlane)) {
              return getResourceId(swimlane)
            } else {
              return swimlane
            }
          })
          .filter(_.identity)
      }

      return userHasPermission({
        permissionName,
        permissions,
        resourceId,
        swimlanes,
      })
    }

    return (
      <WrappedComponent
        userData={fromJS(user)}
        permissions={fromJS(permissions)}
        userHasPermission={doUserHasPermission}
        {...props}
      />
    )
  }

  comp.displayName = `PermissionChecker(${getDisplayName(WrappedComponent)})`
  comp.propTypes = {
    queryTypes: ImmutablePropTypes.map.isRequired,
    userInfo: PropTypes.object,
  }
  return comp
}

const mapStateToProps = createStructuredSelector({
  queryTypes: selectQueryTypes,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect, permissionChecker)
