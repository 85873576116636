/**
 *
 * RoleNodeViewCardLayout
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { List } from 'immutable'
import { exists } from 'utils/sonraiUtils'

import { getNodeViewPushParams, getPrincipalType } from 'utils/sonraiUtils'
import { setUserProfileTables } from 'containers/UserProfileData/actions'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import NodeViewHeader from 'containers/NodeViewHeader'
import { DAEMON } from 'utils/constants'
import TableWidget from 'components/TableWidget'
import Dropdown, {
  DropdownMenu,
  InlineDropdownAnchor,
  DropdownItem,
} from 'components/Dropdown'
import messages from 'containers/NodeViewLayouts/UserNodeViewCardLayout/messages'
import {
  selectRoleContent,
  selectIsLoading,
  selectActionsPerformed,
  selectIsLoadingActions,
} from './selectors'
import reducer from './reducer'
import { getRoleContent, getActionsPerformed } from './actions'
import sagas from './sagas'

const styles = {
  title: {
    fontSize: '22px',
    fontWeight: '300',
  },
}

export class RoleNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: 1,
    }

    props.getActionsPerformed({
      srn: this.props.nodeData.srn,
      range: this.state.filter.value,
    })

    props.getRoleContent(this.props.nodeData.srn)
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  getKeysOwned = () => {
    let rows = []
    this.props.roleContent
      .getIn(['isOwnerOf', 'group'], List())
      .toJS()
      .forEach(item => {
        const key = item.key.label || '-'
        item.items.forEach(groupName => {
          rows.push({
            type: key,
            name: groupName.name || '-',
            srn: groupName.srn,
          })
        })
      })
    return rows
  }

  getAttachedPolicies = () => {
    let rows = []
    this.props.roleContent
      .getIn(['hasAttached', 'items'], List())
      .toJS()
      .forEach(policy => {
        const { name, active, srn } = policy
        rows.push({
          policyActive: active,
          policyName: name,
          srn,
        })
      })

    return rows
  }

  getPerformed = () => {
    const { actionsPerformed } = this.props
    if (!actionsPerformed.isEmpty()) {
      const data = actionsPerformed.map(action => {
        return {
          eventName: action.get('eventName') || '-',
          cityPerformedAt:
            action.get('performedAtCityValue') ||
            (exists(action.get('performedAtCityValues')) &&
              action.get('performedAtCityValues').join(', ')) ||
            '-',
          srn: action.get('srn'),
        }
      })
      return data.toJS()
    } else {
      return []
    }
  }

  getPrincipals = () => {
    const principals = []
    if (this.props.roleContent) {
      this.props.roleContent
        .getIn(['hasAttached', 'items'], [])
        .forEach(policy => {
          policy.getIn(['hasPolicyVersion', 'items'], []).forEach(version => {
            version.getIn(['hasPolicyEntry', 'items'], []).forEach(entry => {
              entry.getIn(['hasPrincipal', 'items'], []).forEach(principal => {
                principals.push({
                  account: principal.get('principalAccount'),
                  name: principal.get('principalName'),
                  srn: principal.get('principalSRN'),
                  type: getPrincipalType(principal.get('principalSRN')),
                })
              })
            })
          })
        })
    }
    return principals
  }

  handleRangeChange = val => {
    this.setState({
      filter: val.value,
    })
    this.props.getActionsPerformed({
      srn: this.props.nodeData.srn,
      range: val.value,
    })
  }

  getLayouts = () => {
    const numAttachedPolicies = this.getAttachedPolicies().length
    const numOwned = this.getKeysOwned().length
    const numPerformed = this.getPerformed().length

    return {
      policies: {
        md: { w: 8, h: numAttachedPolicies > 0 ? 2 : 1 },
        sm: { w: 6, h: numAttachedPolicies > 0 ? 2 : 1 },
        xs: { w: 4, h: numAttachedPolicies > 0 ? 2 : 1 },
      },
      owns: {
        md: { w: 3, h: numOwned > 0 ? 2 : 1 },
        sm: { w: 5, h: numOwned > 0 ? 2 : 1 },
        xs: { w: 3, h: numOwned > 0 ? 2 : 1 },
      },
      performed: {
        md: { w: 4, h: numPerformed > 0 ? 2 : 1 },
        sm: { w: 6, h: numPerformed > 0 ? 2 : 1 },
        xs: { w: 4, h: numPerformed > 0 ? 2 : 1 },
      },
      graph: {
        lg: { w: 4, h: 3 },
        md: { w: 3, h: 3 },
        sm: { w: 6, h: 3 },
        xs: { w: 4, h: 3 },
      },
    }
  }

  getGridContent = () => {
    return [
      <div key={'policies'}>
        <TableWidget
          nodeViewType={'User'}
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
          data={this.getAttachedPolicies()}
          title={this.props.intl.formatMessage(messages.attachedPolicies)}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.loadingRoleContent}
        />
      </div>,
      <div key={'owns'}>
        <TableWidget
          nodeViewType={'User'}
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
          data={this.getKeysOwned()}
          title={this.props.intl.formatMessage(messages.owns)}
          onClickNodeView={this.onClickNodeView}
          loading={this.props.loadingRoleContent}
        />
      </div>,
      <div key={'performed'}>
        <TableWidget
          nodeViewType={'User'}
          userProfile={this.props.userProfile}
          setUserProfileTables={this.props.setUserProfileTables}
          data={this.getPerformed()}
          title={
            <div>
              <span style={styles.title}>
                <FormattedMessage {...messages.performed} />{' '}
              </span>

              <Dropdown
                selectedValue={this.state.filter}
                onClick={filter => this.handleRangeChange(filter)}
              >
                <InlineDropdownAnchor />
                <DropdownMenu>
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays1)}
                    value={1}
                  />
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays7)}
                    value={7}
                  />
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays30)}
                    value={30}
                  />
                  <DropdownItem
                    label={this.props.intl.formatMessage(messages.statusDays90)}
                    value={90}
                  />
                </DropdownMenu>
              </Dropdown>
            </div>
          }
          onClickNodeView={this.onClickNodeView}
          loading={this.props.loadingActions}
        />
      </div>,
    ]
  }

  render() {
    let nodeData = {
      ...this.props.nodeData,
      servicePrincipals: this.getPrincipals(),
    }

    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />

        <NodeView
          {...this.props}
          layouts={this.getLayouts()}
          gridContent={this.getGridContent()}
          nodeData={nodeData}
          propsOfInterest={this.props.propsOfInterest}
          horizontal={this.props.horizontal}
          renderDetailsTab={false}
        />
      </Fragment>
    )
  }
}

RoleNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  setUserProfileTables: PropTypes.func,
  roleContent: ImmutablePropTypes.map.isRequired,
  getRoleContent: PropTypes.func.isRequired,
  loadingRoleContent: PropTypes.bool,
  userProfile: PropTypes.object,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  getActionsPerformed: PropTypes.func,
  loadingActions: PropTypes.bool,
  loading: PropTypes.bool,
  actionsPerformed: PropTypes.object,
  nodeId: PropTypes.string.isRequired,
  nodeData: PropTypes.object,
  push: PropTypes.func,
  propsOfInterest: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      message: PropTypes.node,
    })
  ),
}

const mapStateToProps = createStructuredSelector({
  actionsPerformed: selectActionsPerformed,
  loadingActions: selectIsLoadingActions,
  userProfile: selectUserProfile,
  roleContent: selectRoleContent,
  loadingRoleContent: selectIsLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getActionsPerformed,
      setUserProfileTables,
      push,
      getRoleContent,
    },
    dispatch
  )
}

const withReducer = injectReducer({ key: 'roleNodeViewCardLayout', reducer })

const withSaga = injectSaga({
  key: 'roleNodeViewCardLayout',
  saga: sagas,
  mode: DAEMON,
})

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  injectIntl,
  withSaga,
  withReducer
)(RoleNodeViewCardLayout)
