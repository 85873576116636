/*
 * ControlGroup Messages
 *
 * This contains all the text for the Alerts component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  createPolicyHeader: {
    id: 'app.containers.ControlGroup.createPolicyHeader',
    defaultMessage: 'Add Policy',
  },
  policyTitleLabel: {
    id: 'app.containers.ControlGroup.policyTitleLabel',
    defaultMessage: 'Title',
  },
  policyDescriptionLabel: {
    id: 'app.containers.ControlGroup.policyDescriptionLabel',
    defaultMessage: 'Description',
  },
  policySearchLabel: {
    id: 'app.containers.ControlGroup.policySearchLabel',
    defaultMessage: 'Search',
  },
  policyLastTriggeredLabel: {
    id: 'app.containers.ControlGroup.policyLastTriggeredLabel',
    defaultMessage: 'Last Run',
  },
  policyFindingsLabel: {
    id: 'app.containers.ControlGroup.policyFindingsLabel',
    defaultMessage: 'Findings',
  },
  addPolicyButton: {
    id: 'app.containers.ControlGroup.addPolicyButton',
    defaultMessage: 'Add Policy',
  },
  enableOnSwimlaneButton: {
    id: 'app.containers.ControlGroup.enableOnSwimlaneButton',
    defaultMessage: 'Apply to specific Swimlane',
  },
  enableGloballyButton: {
    id: 'app.containers.ControlGroup.enableGloballyButton',
    defaultMessage: 'Enable Globally',
  },
  enableButton: {
    id: 'app.containers.ControlGroup.enableButton',
    defaultMessage: 'Enable',
  },
  disableButton: {
    id: 'app.containers.ControlGroup.disableButton',
    defaultMessage: 'Disable',
  },
  disableGloballyButton: {
    id: 'app.containers.ControlGroup.disableGloballyButton',
    defaultMessage: 'Disable Globally',
  },
  cancelButton: {
    id: 'app.containers.ControlGroup.cancelButton',
    defaultMessage: 'Cancel',
  },
  createButton: {
    id: 'app.containers.ControlGroup.createButton',
    defaultMessage: 'Create',
  },
  enableSwimlanes: {
    id: 'app.containers.ControlGroup.enableSwimlanes',
    defaultMessage: 'Enable',
  },
  disableSwimlane: {
    id: 'app.containers.ControlGroup.disableSwimlane',
    defaultMessage: 'Disable',
  },
  enableSwimlaneTitle: {
    id: 'app.containers.ControlGroup.enableSwimlaneTitle',
    defaultMessage: 'Select Swimlanes to Enable on Control Framework',
  },
  notEnabledModalMessage: {
    id: 'app.containers.ControlGroup.notEnabledModalMessage',
    defaultMessage:
      'This Control Framework is not enabled. Tickets wil not be created for your swimlane unless it is enabled.',
  },
  addButton: {
    id: 'app.containers.ControlGroup.addButton',
    defaultMessage: 'Add',
  },
  saveEditButton: {
    id: 'app.containers.ControlGroup.saveEditButton',
    defaultMessage: 'Save',
  },
  policyLabel: {
    id: 'app.containers.ControlGroup.policyLabel',
    defaultMessage: 'Policy',
  },
  editCGHeader: {
    id: 'app.containers.ControlGroup.createCGHeader',
    defaultMessage: 'Edit Control Framework',
  },
  cGTitleLabel: {
    id: 'app.containers.ControlGroup.cGTitleLabel',
    defaultMessage: 'Title',
  },
  cGDescriptionLabel: {
    id: 'app.containers.ControlGroup.cGDescriptionLabel',
    defaultMessage: 'Description',
  },
  cGFriendlyNameLabel: {
    id: 'app.containers.ControlGroup.cGFriendlyNameLabel',
    defaultMessage: 'Short Name',
  },
  editActionLabel: {
    id: 'app.containers.ControlGroup.editActionLabel',
    defaultMessage: 'Edit',
  },
  deleteActionLabel: {
    id: 'app.containers.ControlGroup.deleteActionLabel',
    defaultMessage: 'Delete Policy',
  },
  removeActionLabel: {
    id: 'app.containers.ControlGroup.removeActionLabel',
    defaultMessage: 'Remove Policy from Control Framework',
  },
  evalTypeLabel: {
    id: 'app.containers.ControlGroup.evalTypeLabel',
    defaultMessage: 'Trigger',
  },
  evalTypeCountLabel: {
    id: 'app.containers.ControlGroup.evalTypeCountLabel',
    defaultMessage: 'Count',
  },
  evalOpGTLabel: {
    id: 'app.containers.ControlGroup.evalOpGTLabel',
    defaultMessage: 'Greater Than',
  },
  evalOpLTLabel: {
    id: 'app.containers.ControlGroup.evalOpLTLabel',
    defaultMessage: 'Less Than',
  },
  evalOpEQLabel: {
    id: 'app.containers.ControlGroup.evalOpEQLabel',
    defaultMessage: 'Equal To',
  },
  newPolicyTabLabel: {
    id: 'app.containers.ControlGroup.newPolicyTabLabel',
    defaultMessage: 'Create New Policy',
  },
  addPolicyTabLabel: {
    id: 'app.containers.ControlGroup.addPolicyTabLabel',
    defaultMessage: 'Add Existing Policy',
  },
  editPolicyHeader: {
    id: 'app.containers.ControlGroup.editPolicyHeader',
    defaultMessage: 'Edit Policy',
  },
})
