import React from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import { Table } from 'reactstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Icon from 'components/Icon'
import TextLink from 'components/TextLink'
import PathWidget from 'components/PathWidget'
import { exists, flattenData, getNameFromSrn } from 'utils/sonraiUtils'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class FilterAndPath extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showPath: false,
    }

    this.styles = {
      pathWidget: {
        height: '300px',
        marginBottom: '1em',
        border: `1px solid ${props.theme.neutralLight}`,
      },
      tableStyle: {
        fontSize: '12px',
      },
      title: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        padding: '1em 0',
        alignItems: 'center',
      },
      expandPath: {
        fontSize: '0.9em',
      },
      disabledSection: {
        color: props.theme.neutralMedium,
        display: 'grid',
        gridTemplateColumns: '220px 1fr',
      },
    }
  }

  togglePath = () => {
    this.setState(currentState => ({
      showPath: !currentState.showPath,
    }))

    if (this.props.onToggle) {
      this.props.onToggle()
    }
  }

  renderFilterTable = () => {
    const flattened = flattenData(this.props.filters.toJS())

    let filters = []

    flattened.forEach(row => {
      const portRangeMax = row['Portrange_maxPort']
      const portRangeMin = row['Portrange_minPort']

      let port

      if (
        portRangeMin === portRangeMax ||
        !exists(portRangeMin) ||
        portRangeMin === '-'
      ) {
        port = portRangeMax
      } else if (!exists(portRangeMax) || portRangeMax === '-') {
        port = portRangeMin
      } else {
        port = `${portRangeMin} - ${portRangeMax}`
      }

      const cidr =
        row['Cidr_name'] === '-'
          ? getNameFromSrn(row['Cidr_srn'])
          : row['Cidr_name']
      const protocol =
        row['Protocol_name'] === '-'
          ? getNameFromSrn(row['Protocol_srn'])
          : row['Protocol_name']

      filters.push({
        protocol,
        cidr,
        port,
      })
    })

    return (
      <Table style={this.styles.tableStyle}>
        <thead>
          <tr>
            <th>Protocol</th>
            <th>CIDR</th>
            <th>Port</th>
          </tr>
        </thead>
        <tbody>
          {filters.map((row, index) => (
            <tr key={index}>
              <td>{row.protocol}</td>
              <td>{row.cidr}</td>
              <td>{row.port}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  renderFilters = () => {
    if (this.props.filters.get('items', List()).size === 0) {
      return (
        <div style={this.styles.disabledSection}>
          <span>{this.props.filterLabel}:</span> <span>None</span>
        </div>
      )
    }

    return (
      <div>
        <div style={this.styles.title}>
          <div>{this.props.filterLabel}</div>
        </div>
        {this.renderFilterTable()}
      </div>
    )
  }

  renderPath = () => {
    if (this.props.path.get('items', List()).size === 0) {
      return (
        <div style={this.styles.disabledSection}>
          <span>{this.props.pathLabel}:</span> <span>None</span>
        </div>
      )
    }

    return (
      <div>
        <div style={this.styles.title}>
          <div>{this.props.pathLabel}</div>
          <TextLink
            color="primary"
            onClick={this.togglePath}
            style={this.styles.expandPath}
          >
            {this.state.showPath ? (
              <span>
                Hide Path <Icon fa name="caret-down" />
              </span>
            ) : (
              <span>
                Show Path <Icon fa name="caret-left" />
              </span>
            )}
          </TextLink>
        </div>

        {this.props.path.get('items', List()).size > 0 && this.state.showPath && (
          <div style={this.styles.pathWidget}>
            <PathWidget
              data={this.props.path.get('items', List()).first() || Map()}
              loading={this.props.loadingPath}
              onDoubleClick={this.props.onDoubleClick}
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderFilters()}

        {this.renderPath()}
      </div>
    )
  }
}

FilterAndPath.propTypes = {
  path: ImmutablePropTypes.map.isRequired,
  filters: ImmutablePropTypes.map.isRequired,
  filterLabel: PropTypes.node,
  pathLabel: PropTypes.node,
  loadingPath: PropTypes.bool,
  onDoubleClick: PropTypes.func,
  theme: themeShape,
  onToggle: PropTypes.func,
}
export default themeable(FilterAndPath)
