import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { push } from 'connected-react-router'
import { isImmutable } from 'immutable'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { getNodeViewPushParams } from 'utils/sonraiUtils'

import NodeViewHeader from 'containers/NodeViewHeader'

import BorderedCard from 'components/BorderedCard'
import Icon from 'components/Icon'
import LoadingAnim from 'components/LoadingAnim'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import NodeViewDetails from 'components/NodeView/NodeViewDetails'
import TextLink from 'components/TextLink'

import {
  selectQueryNames,
  selectQueryTypes,
} from 'containers/SonraiData/selectors'
import { selectFields } from 'containers/NodeSolutionCenter/selectors'
import { selectData } from './selectors'

import { getData } from './actions'

import messages from './messages'
import reducer from './reducers'
import sagas from './sagas'

const styles = {
  openContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 8,
    overflowY: 'scroll',
    paddingBottom: '16px',
  },
  closedContainer: {
    position: 'fixed',
    width: 0,
    height: 0,
    left: -999999,
  },
  overlay: {
    cursor: 'pointer',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'black',
    transition: 'opacity 0.25s',
  },
  openOverlay: {
    position: 'fixed',
    opacity: 0.6,
  },
  closedOverlay: {
    position: 'absolute',
    opacity: 0,
  },
  content: {
    paddingTop: '90px',
    paddingBottom: '90px',
    height: '100%',
    width: '1024px',
    zIndex: 8.1,
    transition: 'right 0.25s',
    margin: 'auto',
  },
  openContent: {
    right: 0,
  },
  closedContent: {
    right: -1024,
  },
  card: {
    background: '#fcfcfc',
    width: '100%',
    minHeight: '100%',
    marginBottom: '16px',
  },
  controls: {
    marginLeft: '12px',
    marginRight: '12px',
  },
  closeIconContainer: {
    border: 'none',
    display: 'inline',
    float: 'right',
    position: 'relative',
    right: '6px',
    top: '2px',
    color: 'rgb(0, 90, 160)',
    cursor: 'pointer',
  },
  nodeDetails: {
    position: 'relative',
    left: '1px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 1px 4px 3px -1px',
    margin: '12px',
    padding: '10px',
    border: '1px solid #eee',
    background: 'white',
  },
  nodeDetialsTitle: {
    fontSize: '1.2em',
    color: 'rgb(51, 51, 51)',
  },
  nodeHeader: {},
  error: {
    textAlign: 'center',
    marginTop: '30px',
    padding: '12px',
  },
}

export class MiniNodeViewPopout extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open && this.props.nodeId) {
      this.props.getData({
        srn: this.props.nodeId,
        queryNames: this.props.queryNames,
        types: this.props.types,
      })
    }
  }

  goToSelectedNodeView = () => {
    const type = getTypeFromSrn(this.props.nodeId)
    this.props.push(getNodeViewPushParams(this.props.nodeId, type))
  }

  renderLoadingBody = () => {
    return <LoadingAnim />
  }

  renderErrorBody = () => {
    return (
      <div style={styles.error}>
        <p style={{ cursor: 'pointer' }} onClick={this.props.handleClose}>
          <DynamicFormattedMessage {...messages.dataLoadError} />
        </p>
      </div>
    )
  }

  renderBodyWithData = () => {
    const type = getTypeFromSrn(this.props.nodeId)
    const linkParams = getNodeViewPushParams(this.props.nodeId, type)

    return (
      <div>
        <div style={styles.controls}>
          <div style={{ display: 'inline' }}>
            <a
              href={`${linkParams.pathName}?${linkParams.search}`}
              onClick={e => e.preventDefault()}
            >
              <TextLink color="primary" onClick={this.goToSelectedNodeView}>
                <DynamicFormattedMessage {...messages.gotoFullNodeView} />
              </TextLink>
            </a>
          </div>
          <div
            style={styles.closeIconContainer}
            onClick={this.props.handleClose}
          >
            <Icon fa name="times" style={styles.closeIcon} />
          </div>
        </div>
        <div style={styles.nodeHeader}>
          <NodeViewHeader nodeId={this.props.nodeId} />
        </div>
        <div style={styles.nodeDetails}>
          <div style={styles.nodeDetialsTitle}>
            <DynamicFormattedMessage {...messages.generalProperties} />
          </div>
          <NodeViewDetails
            fields={
              isImmutable(this.props.fields)
                ? this.props.fields.toJS()
                : this.props.fields
            }
            nodeData={this.props.data.get('data').toJS()}
          />
        </div>
      </div>
    )
  }

  renderCardBody = () => {
    if (this.props.data.get('loading')) {
      return this.renderLoadingBody()
    }
    if (this.props.data.get('error')) {
      return this.renderErrorBody()
    }
    return this.renderBodyWithData()
  }

  render() {
    const containerStyle = this.props.open
      ? styles.openContainer
      : styles.closedContainer

    const overlayStyle = this.props.open
      ? styles.openOverlay
      : styles.closedOverlay

    const contentStyle = this.props.open
      ? styles.openContent
      : styles.closedContent

    return (
      <div style={containerStyle}>
        <div
          style={{ ...styles.overlay, ...overlayStyle }}
          onClick={this.props.handleClose}
        />
        <div
          style={{
            ...styles.content,
            ...contentStyle,
          }}
        >
          <BorderedCard style={styles.card}>
            {this.props.open && this.renderCardBody()}
          </BorderedCard>
        </div>
      </div>
    )
  }
}

MiniNodeViewPopout.propTypes = {
  // passed props
  handleClose: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  open: PropTypes.bool,

  // props mapped from state
  data: ImmutablePropTypes.map.isRequired,
  fields: ImmutablePropTypes.list,
  queryNames: ImmutablePropTypes.iterable.isRequired,
  types: ImmutablePropTypes.map,

  // bound action creators
  getData: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  data: selectData,
  fields: selectFields,
  queryNames: selectQueryNames,
  types: selectQueryTypes,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getData, push }, dispatch)
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({ key: 'nodeViewMiniPopout', reducer })

const withSaga = injectSaga({
  key: 'nodeViewMiniPopout',
  saga: sagas,
})

export default compose(
  withConnect,
  withSaga,
  withReducer
)(MiniNodeViewPopout)
