import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

export const selectEscalationsDataDomain = state =>
  state.get('escalationData', Map())

export const selectEscalations = createSelector(
  selectEscalationsDataDomain,
  state => state.get('escalations') || Map()
)

export const selectCreatingEscalation = createSelector(
  selectEscalationsDataDomain,
  state => state.get('creatingEscalation')
)

export const selectCreatingEscalationError = createSelector(
  selectEscalationsDataDomain,
  state => state.get('creatingEscalationError')
)

export const selectDeletingEscalations = createSelector(
  selectEscalationsDataDomain,
  state => state.get('deletingEscalations') || Map()
)

export const selectUpdatingEscalation = createSelector(
  selectEscalationsDataDomain,
  state => state.get('updatingEscalation')
)

export const selectUpdatingEscalationError = createSelector(
  selectEscalationsDataDomain,
  state => state.get('updatingEscalationError')
)

export const selectEscalationsLoading = createSelector(
  selectEscalationsDataDomain,
  state => state.get('escalationsLoading')
)

export const selectEscalationFilterOptions = createSelector(
  selectEscalationsDataDomain,
  state => state.get('escalationFilterOptions') || List()
)

export const selectEscalationFilterOptionsLoading = createSelector(
  selectEscalationsDataDomain,
  state => state.get('escalationFilterOptionsLoading')
)

export const selectUpdatingEscalationInvalidSwimlaneForTickets = createSelector(
  selectEscalationsDataDomain,
  state => state.get('updatingEscalationInvalidSwimlaneForTickets')
)
