import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import { SET_POLICY_CONTENT, GET_POLICY_CONTENT } from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  policyContent: {},
  isLoading: false,
})

const policyNodeViewCardLayoutReducer = handleActions(
  {
    [GET_POLICY_CONTENT]: state => state.set('isLoading', true),
    [SET_POLICY_CONTENT]: (state, { payload }) => {
      return state.set('policyContent', fromJS(payload)).set('isLoading', false)
    },
    [CLEAR_DATA]: state => state.set('policyContent', Map()),
  },
  initialState
)

export default policyNodeViewCardLayoutReducer
