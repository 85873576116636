import React from 'react'

import { Input } from 'reactstrap'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SubmitFormBaseComponent, {
  DefaultFieldControlContainer,
} from './SubmitFormBaseComponent'

import messages from '../messages'
import { doIfValidInt } from '../utils'

const MIN_VALUE = 1
const MAX_VALUE = 1000

export default class SubmitFormOutputLimitSection extends SubmitFormBaseComponent {
  renderTitleText = () => {
    return <DynamicFormattedMessage {...messages.submitFormOutputLimitLabel} />
  }

  renderHelpContent = () => {
    return (
      <DynamicFormattedMessage
        {...messages.submitFormOutputLimitInstructions}
      />
    )
  }

  setValue = event => {
    doIfValidInt(event, asNumber => {
      this.props.setFormFields({ [this.props.fieldKey]: asNumber })
    })
  }

  validateValue = ({ target: { value } }) => {
    if (!value || value > MAX_VALUE || value < MIN_VALUE) {
      const error = `Must be a 1umber between ${MIN_VALUE} and ${MAX_VALUE}`
      this.props.setFormErrors({ [this.props.fieldKey]: error })
    }
  }

  renderFieldControls = () => {
    return (
      <DefaultFieldControlContainer>
        <div>
          <Input
            placeholder="Limit"
            name={this.props.fieldKey}
            onChange={this.setValue}
            onBlur={this.validateValue}
            value={this.props.formValues[this.props.fieldKey]}
          />
        </div>
      </DefaultFieldControlContainer>
    )
  }
}
