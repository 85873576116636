import React from 'react'
import { compose } from 'redux'
import injectSaga from 'utils/injectSaga'
import injectReducer from 'utils/injectReducer'
import { getDisplayName } from 'utils/hocHelpers'
import saga from './sagas'
import reducer from './reducers'

const changeDetectionManager = WrappedComponent => {
  return class extends React.Component {
    displayName = `ChangeDetectionManager(${getDisplayName(WrappedComponent)})`
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const withReducer = injectReducer({ key: 'changeDetectionManager', reducer })
const withSaga = injectSaga({ key: 'changeDetectionManager', saga: saga })

export default compose(
  withReducer,
  withSaga,
  changeDetectionManager
)
