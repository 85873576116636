import React from 'react'
import { compose } from 'redux'

import { DAEMON } from 'utils/constants'
import { getDisplayName } from 'utils/hocHelpers'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import reducer from './reducer'
import sagas from './sagas'

const ticketDetailsData = WrappedComponent => {
  return class extends React.Component {
    displayName = `TicketDetailsData(${getDisplayName(WrappedComponent)})`
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const withReducer = injectReducer({ key: 'ticketDetails', reducer })
const withSaga = injectSaga({
  key: 'ticketDetails',
  saga: sagas,
  mode: DAEMON,
})

export default compose(withReducer, withSaga, ticketDetailsData)
