import React, { Fragment } from 'react'
import { Set } from 'immutable'

import Select from 'react-select'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SubmitFormBaseComponent, {
  DefaultFieldControlContainer,
  RequiredSpan,
} from './SubmitFormBaseComponent'

import { TitleShimmer } from 'components/ShimmerLoader'
import TextLink from 'components/TextLink'

import messages from '../messages'

class SubmitFormClassifiersSection extends SubmitFormBaseComponent {
  constructor(props) {
    super(props)
    this.state = {
      phoneNumberSelected: false,
      customSelected: false,
    }
    this.styles = {
      ...this.styles,
      phoneNumberControl: {
        paddingTop: '8px',
      },
      phoneNumberControlShow: {
        maxHeight: '20em',
        overflow: 'hidden',
        transition: 'max-height 0.5s',
      },
      phoneNumberControlHide: {
        maxHeight: '0px',
        overflow: 'hidden',
        transition: 'max-height 0.5s',
      },
      customControlShow: {
        maxHeight: '20em',
        transition: 'max-height 0.5s',
      },
      customControlHide: {
        maxHeight: '0px',
        overflow: 'hidden',
        transition: 'max-height 0.5s',
      },
    }
  }

  renderTitleText = () => {
    return <DynamicFormattedMessage {...messages.submitFormClassifiersLabel} />
  }

  renderHelpContent = () => {
    return (
      <span>
        <DynamicFormattedMessage
          {...messages.submitFormClassifiersInstruction}
        />
      </span>
    )
  }

  getAllClassifierOptions = () => {
    return [
      { label: 'Addresses', value: 'ADDRESS' },
      { label: 'AWS Access Key', value: 'AWSACCESSKEY' },
      { label: 'AWS Secret Key', value: 'AWSSECRETKEY' },
      { label: 'Azure Storage Account Key', value: 'AZURESTORAGEACCOUNTKEY' },
      { label: 'Azure Client Secret', value: 'AZURECLIENTSECRET' },
      { label: 'Azure Access Signature', value: 'AZURESAS' },
      { label: 'Bank Account Number', value: 'BANKACCOUNTNUMBER' },
      { label: 'Credit Card Numbers', value: 'CREDITCARD' },
      { label: 'Custom Classifier', value: 'CUSTOM' },
      { label: 'Date of Births', value: 'DOB' },
      { label: 'Drivers License', value: 'DRIVERSLICENSE' },
      { label: 'Emails', value: 'EMAIL' },
      { label: 'Encryption Key', value: 'ENCRYPTIONKEY' },
      { label: 'Full Names', value: 'FULLNAME' },
      { label: 'GCP API Key', value: 'GCPAPIKEY' },
      { label: 'GCP Service Account Key', value: 'GCPSERVICEACCOUNTKEY' },
      { label: 'Passport Number', value: 'PASSPORTNUMBER' },
      { label: 'Phone Numbers', value: 'PHONENUMBER' },
      { label: 'Postal Codes', value: 'POSTCODE' },
      { label: 'Tax Number', value: 'TAXNUMBER' },
      { label: 'Zip Codes', value: 'ZIPCODE' },
      { label: 'Social Insurance Numbers', value: 'SIN' },
      { label: 'Social Security Numbers', value: 'SSN' },
    ]
  }

  setClassifiers = values => {
    const allValue = (values || []).find(({ value }) => 'All' === value)
    if (allValue) {
      values = this.getAllClassifierOptions()
    }

    const valuesWithoutPhoneNumber = (values || []).find(
      ({ value }) => 'PHONENUMBER' === value
    )
    const customValue = (values || []).find(({ value }) => 'CUSTOM' === value)
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.props.setFormFields({ [this.props.fieldKey]: new Set(values) })
    this.setState({
      phoneNumberSelected: undefined != valuesWithoutPhoneNumber,
    })
    this.setState({
      customSelected: undefined != customValue,
    })

    if (!customValue) {
      this.props.setFormFields({ customClassifiers: new Set() })
    }
  }

  setCustomClassifiers = values => {
    this.props.setFormFields({ customClassifiers: new Set(values) })
  }

  buildCustomOptions = () => {
    if (
      this.props.customClassifiers &&
      !this.props.customClassifiers.isEmpty()
    ) {
      return this.props.customClassifiers
        .map(cc => ({
          value: cc.get('srn'),
          label: cc.get('atomName'),
        }))
        .toJS()
    } else {
      return []
    }
  }

  setPhoneNumberClassifiers = event => {
    const { value } = event.target
    const currentState = this.props.formValues.phoneNumbers
    // const formErrors = _.omit(this.state.formErrors, 'classifiers')
    if (currentState.includes(value)) {
      this.props.setFormFields({ phoneNumbers: currentState.remove(value) })
    } else {
      this.props.setFormFields({ phoneNumbers: currentState.add(value) })
    }
  }

  renderFieldControls = () => {
    return (
      <Fragment>
        <DefaultFieldControlContainer>
          <Select
            isMulti
            onChange={this.setClassifiers}
            value={this.props.formValues[this.props.fieldKey].toJS()}
            options={[
              { label: 'All', value: 'All' },
              ...this.getAllClassifierOptions(),
            ]}
          />
        </DefaultFieldControlContainer>
        <div
          className="field-control"
          style={{
            ...this.styles.fieldControl,
            ...this.styles.phoneNumberControl,
            ...(this.state.customSelected
              ? this.styles.customControlShow
              : this.styles.customControlHide),
          }}
        >
          <div
            style={{
              marginBottom: '4px',
              ...(this.props.formErrors[this.props.fieldKey] &&
                this.styles.formHeadingErrors),
            }}
          >
            <DynamicFormattedMessage
              {...messages.submitFormCustomClassifierLabel}
            />
            <RequiredSpan />
          </div>
          <div>
            {this.props.loadingCustomClassifiers ? (
              <TitleShimmer width={200} />
            ) : (
              <Fragment>
                <Select
                  isMulti
                  onChange={this.setCustomClassifiers}
                  value={this.props.formValues['customClassifiers'].toJS()}
                  options={this.buildCustomOptions()}
                />
                <TextLink
                  color="primary"
                  to={{ pathname: '/App/DataClassifierManagement' }}
                  newTab
                >
                  <DynamicFormattedMessage
                    {...messages.manageCustomDataClassifiers}
                  />
                </TextLink>
              </Fragment>
            )}
          </div>
        </div>
        <div
          className="field-control"
          style={{
            ...this.styles.fieldControl,
            ...this.styles.phoneNumberControl,
            ...(this.state.phoneNumberSelected
              ? this.styles.phoneNumberControlShow
              : this.styles.phoneNumberControlHide),
          }}
        >
          <div
            style={{
              marginBottom: '4px',
              ...(this.props.formErrors[this.props.fieldKey] &&
                this.styles.formHeadingErrors),
            }}
          >
            <DynamicFormattedMessage {...messages.submitFormPhoneNumberLabel} />
            <RequiredSpan />
          </div>
          <div>
            <label style={{ paddingLeft: '8px' }}>
              {' '}
              <input
                type="checkbox"
                name="outputMode"
                value="PHONENUMBER_CANADA"
                onChange={this.setPhoneNumberClassifiers}
                checked={
                  this.props.formValues.phoneNumbers.includes(
                    'PHONENUMBER_CANADA'
                  ) ||
                  this.props.formValues.phoneNumbers.includes('PHONENUMBER')
                }
              />{' '}
              Canada
            </label>
          </div>
          <div>
            <label style={{ paddingLeft: '8px' }}>
              {' '}
              <input
                type="checkbox"
                name="outputMode"
                value="PHONENUMBER_USA"
                checked={
                  this.props.formValues.phoneNumbers.includes(
                    'PHONENUMBER_USA'
                  ) ||
                  this.props.formValues.phoneNumbers.includes('PHONENUMBER')
                }
                onChange={this.setPhoneNumberClassifiers}
              />{' '}
              USA
            </label>
          </div>
          <div>
            <label style={{ paddingLeft: '8px' }}>
              {' '}
              <input
                type="checkbox"
                name="outputMode"
                value="PHONENUMBER_UK"
                checked={
                  this.props.formValues.phoneNumbers.includes(
                    'PHONENUMBER_UK'
                  ) ||
                  this.props.formValues.phoneNumbers.includes('PHONENUMBER')
                }
                onChange={this.setPhoneNumberClassifiers}
              />{' '}
              UK
            </label>
          </div>
          <div>
            <label style={{ paddingLeft: '8px' }}>
              {' '}
              <input
                type="checkbox"
                name="outputMode"
                value="PHONENUMBER"
                checked={this.props.formValues.phoneNumbers.includes(
                  'PHONENUMBER'
                )}
                onChange={this.setPhoneNumberClassifiers}
              />{' '}
              <DynamicFormattedMessage
                {...messages.submitFormPhoneNumberAllCountries}
              />
            </label>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default SubmitFormClassifiersSection
