import { all, put, takeLatest, call } from 'redux-saga/effects'

import {
  setIntegrations,
  setIntegrationsError,
  createIntegrationSuccess,
  createIntegrationError,
  updateIntegrationSuccess,
  updateIntegrationError,
  deleteIntegrationSuccess,
  deleteIntegrationError,
  assignIntegrationSuccess,
  assignIntegrationError,
  deleteIntegrationAssignmentSuccess,
  deleteIntegrationAssignmentError,
  updateIntegrationConfigSuccess,
  updateIntegrationConfigError,
} from './actions'
import {
  GET_INTEGRATIONS,
  CREATE_INTEGRATION,
  DELETE_INTEGRATION,
  UPDATE_INTEGRATION,
  ASSIGN_INTEGRATION,
  DELETE_INTEGRATION_ASSIGNMENT,
  UPDATE_INTEGRATION_CONFIG,
} from './constants'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'
import _ from 'lodash'
import {
  CREATE_INTEGRATION_MUTATION,
  GET_INTEGRATIONS_QUERY,
  DELETE_INTEGRATION_MUTATION,
  CREATE_CONFIGURATION_MUTATION,
  CREATE_ASSIGNMENT_MUTATION,
  DELETE_CONFIG_MUTATION,
  UPDATE_INTEGRATION_MUTATION,
  UPDATE_INTEGRATION_CONFIG_MUTATION,
} from './static-queries'

function* getIntegrations() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${GET_INTEGRATIONS_QUERY}
      `,
    })
    const items = _.get(results, ['data', 'Integrations', 'items'], false)

    if (results.errors || !items) {
      yield put(
        setIntegrationsError(
          _.get(
            results,
            ['errors', 0, 'message'],
            'Error fetching Integrations'
          )
        )
      )
    } else if (items) {
      yield put(setIntegrations(items))
    } else {
      throw new Error('Error fetching Integrations')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

function* createIntegration({ payload }) {
  try {
    const variables = {
      type: payload.type,
      title: payload.title,
      description: payload.description,
    }

    if (payload.type === 'SLACK') {
      variables.slack = payload.slack
    }

    if (payload.type === 'SERVICE_NOW') {
      variables.serviceNow = payload.serviceNow
    }

    if (payload.type === 'JIRA') {
      variables.jira = payload.jira
    }

    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        ${CREATE_INTEGRATION_MUTATION}
      `,
      variables: {
        input: variables,
      },
    })

    const newIntegration = _.get(results, ['data', 'CreateIntegration'], false)

    if (results.errors) {
      yield put(
        createIntegrationError(
          _.get(results.errors, [0, 'message'], 'Error fetching Integrations')
        )
      )
    } else if (newIntegration) {
      yield put(createIntegrationSuccess(newIntegration))
    } else {
      throw new Error('Error fetching Integrations')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error creating Integration.')
  }
}

function* deleteIntegration({ payload }) {
  try {
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        ${DELETE_INTEGRATION_MUTATION}
      `,
      variables: {
        input: {
          srn: payload.srn,
        },
      },
    })

    const deletedIntegration = _.get(
      results,
      ['data', 'DeleteIntegration'],
      false
    )

    if (results.errors) {
      yield put(
        deleteIntegrationError({
          error: _.get(
            results.errors,
            [0, 'message'],
            'Error deleting Integration'
          ),
          srn: payload.srn,
        })
      )
    } else if (deletedIntegration) {
      yield put(deleteIntegrationSuccess({ srn: payload.srn }))
    } else {
      throw new Error('Error deleting Integration.')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error deleting Integration.')
  }
}

function* updateIntegration({ payload }) {
  try {
    const client = getClient()

    const variables = {
      srn: payload.srn,
    }

    if (payload.title) {
      variables.title = payload.title
    }

    if (payload.description) {
      variables.description = payload.description
    }

    if (payload.slack) {
      variables.slack = payload.slack
    }

    if (payload.serviceNow) {
      variables.serviceNow = payload.serviceNow
    }

    if (payload.jira) {
      variables.jira = payload.jira
    }

    const results = yield client.mutate({
      mutation: gql`
        ${UPDATE_INTEGRATION_MUTATION}
      `,
      variables: {
        input: variables,
      },
    })
    const updatedIntegration = _.get(
      results,
      ['data', 'UpdateIntegration'],
      false
    )
    if (results.errors || !updatedIntegration) {
      yield put(
        updateIntegrationError({
          error: _.get(
            results.errors,
            [0, 'message'],
            'Error fetching Integrations'
          ),
          srn: payload.srn,
        })
      )
    } else {
      yield put(
        updateIntegrationSuccess({
          srn: payload.srn,
          updated: updatedIntegration,
        })
      )
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating Integration Token')
    yield put(
      updateIntegrationError({
        error: e.message,
        srn: payload.srn,
      })
    )
  }
}

function* assignIntegration({ payload }) {
  // swimlaneSRN, integration SRN, type,
  // if slack or jira give the channel list/actiontypes or put label
  try {
    const client = getClient()

    let variables = {
      IntegrationSRN: payload.integrationSrn,
    }

    if (payload.type === 'SLACK') {
      variables.slack = payload.slack
    }

    if (payload.type === 'SERVICE_NOW') {
      variables.serviceNow = payload.serviceNow
    }

    if (payload.type === 'JIRA') {
      variables.jira = payload.jira
    }
    // create a configuration first
    const configResults = yield client.mutate({
      mutation: gql`
        ${CREATE_CONFIGURATION_MUTATION}
      `,
      variables: {
        input: variables,
      },
    })

    const configSrn = _.get(
      configResults,
      ['data', 'CreateIntegrationConfiguration', 'srn'],
      false
    )

    if (configSrn) {
      // if there were no issues creating the config then we will automatically assign it the swimlane for now
      const assignResults = yield client.mutate({
        mutation: gql`
          ${CREATE_ASSIGNMENT_MUTATION}
        `,
        variables: {
          input: {
            SwimlaneSRN: payload.swimlaneSrn,
            configSRN: configSrn,
          },
        },
      })

      const assignment = _.get(assignResults, [
        'data',
        'CreateIntegrationAssignment',
      ])
      if (assignment) {
        yield call(getIntegrations)
        yield put(
          assignIntegrationSuccess({ swimlaneSrn: payload.swimlaneSrn })
        )
      } else {
        const errors = _.get(
          assignResults,
          ['errors', 0, 'message'],
          'Error assigning Integration.'
        )
        throw new Error(errors)
      }
    } else {
      const errors = _.get(
        configResults,
        ['errors', 0, 'message'],
        'Error assigning Integration.'
      )
      throw new Error(errors)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error assigning Integration to Swimlane', e)
    yield put(
      assignIntegrationError({
        error: e.message,
        swimlaneSrn: payload.swimlaneSrn,
      })
    )
  }
}

function* deleteIntegrationAssignment({ payload }) {
  try {
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        ${DELETE_CONFIG_MUTATION}
      `,
      variables: {
        input: { srn: payload.assignmentSrn },
      },
    })

    if (!results.errors) {
      // yield call(getIntegrations)
      yield put(
        deleteIntegrationAssignmentSuccess({
          assignmentSrn: payload.assignmentSrn,
        })
      )
    } else {
      throw new Error('Error deleting Integration Assignment')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error deleting Integration Assignment')
    yield put(deleteIntegrationAssignmentError(e.message))
  }
}

function* updateIntegrationConfig({ payload }) {
  try {
    const variables = { srn: payload.srn }
    if (payload.type === 'JIRA') {
      variables.jira = payload.jira
    } else if (payload.type === 'SLACK') {
      variables.slack = payload.slack
    }

    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        ${UPDATE_INTEGRATION_CONFIG_MUTATION}
      `,
      variables: {
        input: variables,
      },
    })

    const config = _.get(results, [
      'data',
      'UpdateIntegrationConfiguration',
      payload.type.toLowerCase(),
    ])

    if (!config.errors) {
      // const channels = _.get(results, [''])
      yield put(
        updateIntegrationConfigSuccess({
          configSrn: payload.srn,
          config,
          type: payload.type,
        })
      )
    } else {
      throw new Error('Error deleting Integration Assignment')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error deleting Integration Assignment')
    yield put(
      updateIntegrationConfigError({ error: e.message, srn: payload.srn })
    )
  }
}

function* platformSettingsDataSagas() {
  yield all([
    takeLatest(GET_INTEGRATIONS, getIntegrations),
    takeLatest(CREATE_INTEGRATION, createIntegration),
    takeLatest(DELETE_INTEGRATION, deleteIntegration),
    takeLatest(UPDATE_INTEGRATION, updateIntegration),
    takeLatest(ASSIGN_INTEGRATION, assignIntegration),
    takeLatest(DELETE_INTEGRATION_ASSIGNMENT, deleteIntegrationAssignment),
    takeLatest(UPDATE_INTEGRATION_CONFIG, updateIntegrationConfig),
  ])
}

export default platformSettingsDataSagas
