import React from 'react'
import ContentLoader from 'react-content-loader'

const SquareShimmer = ({ size = 50, ...props }) => {
  return (
    <span style={{ display: 'block', width: `${size}px`, height: `${size}px` }}>
      <ContentLoader
        height={size}
        width={size}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="0" rx="3" ry="3" width={size} height={size} />
      </ContentLoader>
    </span>
  )
}

export default SquareShimmer
