import { fromJS } from 'immutable'
import moment from 'moment'
import { getFriendlyTicketName } from 'utils/widgetUtils'

export function formatDateTime(value) {
  if (!value) {
    return ''
  }
  return moment(value).format('ll')
}

export function formatDateWithTime(value) {
  if (!value) {
    return ''
  }
  return moment(value).format('lll')
}

export function formatTicketTitle(policies, row) {
  return getFriendlyTicketName(fromJS(row), policies)
}

export function formatTicketType(row) {
  switch (row.ticketType) {
    case 'Policy':
      return 'Control Policy'
    case 'Custom':
      return 'Custom'
    default:
      return 'Critical Asset Monitor'
  }
}
