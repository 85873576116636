import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Set } from 'immutable'
import _ from 'lodash'
import { ModalFooter } from 'reactstrap'

import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import TextLink from 'components/TextLink'

import AuthenticationSection from './Form/SubmitFormAuthenticationSection'
import ClassifiersSection from './Form/SubmitFormClassifiersSection'
import ScanModeSection from './Form/SubmitFormScanModeSection'
import ColumnSampleSize from './Form/SubmitFormColumnSamplesSizeSection'
import OutputLimitSection from './Form/SubmitFormOutputLimitSection'
import MaxSamplesPerClassifier from './Form/SubmitFormMaxSamplesPerClassifierSection'
import OutputModeSection from './Form/SubmitFormOutputModeSection'
import IncludeSamplesSection from './Form/SubmitFormIncludeSamplesSection'
import EncryptionSection from './Form/SubmitFormEncryptionSection'

import messages from './messages'
import { isValidPEM } from './utils'

/**
 * Form to submit a new classification
 */
class SubmitForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      classifiers: new Set(),
      customClassifiers: new Set(),
      authentication: {
        username: '',
        kvName: '',
        kvPath: '',
      },
      scanMode: 'QUICK_SCAN',
      outputMode: 'FINGERPRINT',
      outputLimit: 1000,
      columnSampleSize: 1000,
      encryptionEnabled: false,
      maxSamplesPerClassifier: 10,
      includeSamples: true,
      phoneNumbers: new Set(),
      publicKey: null,
      formErrors: {},
    }
  }

  setEncryptionEnabled = event => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const formErrors = _.omit(this.state.formErrors, 'encryptionEnabled')
    this.setState({ formErrors, encryptionEnabled: event.target.checked })
  }

  setPublicKey = event => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const formErrors = _.omit(this.state.formErrors, [
      'encryptionEnabled',
      'publicKey',
    ])
    this.setState({ formErrors, publicKey: (event.target.value || '').trim() })
  }

  validateFormValues = () => {
    const errors = {}
    if (this.state.classifiers.size === 0) {
      errors.classifiers = 'At least one classifier must be selected'
    }

    const phoneNumberClassifier = this.state.classifiers.find(classifier => {
      return 'PHONENUMBER' === classifier.value
    })
    if (phoneNumberClassifier && this.state.phoneNumbers.size === 0) {
      errors.classifiers =
        'At least one selection must be made of phone numbers'
    }

    const customClassifier = this.state.classifiers.find(classifier => {
      return 'CUSTOM' === classifier.value
    })
    if (customClassifier && this.state.customClassifiers.size === 0) {
      errors.classifiers =
        'At least one selection must be made of custom classifiers'
    }

    if (this.state.scanMode == null) {
      errors.scanMode = 'A scan mode must be selected'
    }

    if (this.state.outputMode == null) {
      errors.outputMode = 'An output mode must be selected'
    }

    if (this.props.isForDatabase) {
      if (
        !this.state.outputLimit ||
        this.state.outputLimit > 1000 ||
        this.state.outputLimit < 1
      ) {
        errors.outputLimit = 'Must be a number between 1 and 1000'
      }

      if (
        !this.state.columnSampleSize ||
        this.state.columnSampleSize > 3000 ||
        this.state.columnSampleSize < 300
      ) {
        errors.outputLimit = 'Must be a number between 300 and 3000'
      }

      if (
        !this.state.maxSamplesPerClassifier ||
        this.state.maxSamplesPerClassifier > 100 ||
        this.state.maxSamplesPerClassifier < 1
      ) {
        errors.maxSamplesPerClassifier = 'Must be a number between 1 and 100'
      }
    }

    if (this.state.encryptionEnabled) {
      if (!this.state.publicKey) {
        errors.encryptionEnabled =
          'Public key must be present if encyrption is enabeld'
      } else if (!isValidPEM(this.state.publicKey)) {
        errors.encryptionEnabled =
          'Public key is invalid format must be pem format'
        errors.publicKey = 'Public key is invalid format must be pem format'
      }
    }

    if (this.props.authenticationMode) {
      const invalid =
        !this.state.authentication.username ||
        !this.state.authentication.kvName ||
        !this.state.authentication.kvPath
      if (invalid) {
        errors.authentication = 'authentication details must be supplied'
      }
    }

    return errors
  }

  onSubmit = () => {
    const formErrors = this.validateFormValues()
    if (Object.keys(formErrors).length === 0) {
      const fields = this.props.isForDatabase
        ? [
            'encryptionEnabled',
            'outputLimit',
            'includeSamples',
            'columnSampleSize',
            'maxSamplesPerClassifier',
          ]
        : ['encryptionEnabled', 'scanMode', 'outputMode', 'includeSamples']

      let formValues = _.pick(this.state, fields)

      // only send private key if user actually has enabled encryption
      if (this.state.encryptionEnabled) {
        formValues.publicKey = this.state.publicKey
      }

      if (this.props.authenticationMode) {
        formValues.dbUserName = this.state.authentication.username
        formValues.keyvaultName = this.state.authentication.kvName
        formValues.keyvaultPath = this.state.authentication.kvPath
      }

      formValues.classifiers = this.state.classifiers
        .toJS()
        .map(({ value }) => value)

      formValues.customClassifierSrns = this.state.customClassifiers
        .toJS()
        .map(({ value }) => value)

      // if the user only selects phone numbers from one country, don't want to
      // submit the 'PHONENUMBER' classifier, only want to submit for the
      // country the user wants
      if (this.state.classifiers.find(cl => cl.value === 'PHONENUMBER')) {
        if (!this.state.phoneNumbers.includes('PHONENUMBER')) {
          _.pull(formValues.classifiers, 'PHONENUMBER')
          this.state.phoneNumbers.forEach(countryPhoneNumbers => {
            formValues.classifiers.push(countryPhoneNumbers)
          })
        }
      }

      formValues.classifiers = formValues.classifiers.filter(
        cl => cl !== 'CUSTOM'
      )

      this.props.onSubmit(formValues)
    } else {
      this.setState({ formErrors })
    }
  }

  setFormFields = values => {
    const errorResetFields = Object.keys(values)
    if (errorResetFields.includes('phoneNumbers')) {
      errorResetFields.push('classifiers') // phone number is classifier
    }

    if (errorResetFields.includes('publicKey')) {
      errorResetFields.push('encryptionEnabled') // publicKey same part as encryptionEnabled
    }

    // eslint-disable-next-line react/no-access-state-in-setstate
    const formErrors = _.omit(this.state.formErrors, errorResetFields)
    this.setState({ formErrors, ...values })
  }

  /**
   * call to set there is an error on the form
   *
   * @param errors {Object} Errors will be an object with form like:
   * { [fieldName]: 'error message', ... }
   */
  setFormErrors = errors => {
    const formErrors = this.state.formErrors
    this.setState({ formErrors: Object.assign({}, formErrors, errors) })
  }

  render() {
    const sectionProps = {
      authenticationMode: this.props.authenticationMode,
      formErrors: this.state.formErrors,
      formValues: _.pick(this.state, [
        'authentication',
        'classifiers',
        'customClassifiers',
        'phoneNumbers',
        'scanMode',
        'outputMode',
        'includeSamples',
        'publicKey',
        'encryptionEnabled',
        'outputLimit',
        'maxSamplesPerClassifier',
        'columnSampleSize',
      ]),
      setFormFields: this.setFormFields,
      setFormErrors: this.setFormErrors,
    }

    return (
      <div className="dataclassification-submit-form">
        <ClassifiersSection
          customClassifiers={this.props.customClassifiers}
          loadingCustomClassifiers={this.props.loadingCustomClassifiers}
          fieldKey="classifiers"
          required
          {...sectionProps}
        />
        {this.props.isForDatabase ? (
          <ColumnSampleSize
            fieldKey="columnSampleSize"
            required
            {...sectionProps}
          />
        ) : (
          <ScanModeSection fieldKey="scanMode" required {...sectionProps} />
        )}

        {this.props.isForDatabase ? (
          <OutputLimitSection
            fieldKey="outputLimit"
            required
            {...sectionProps}
          />
        ) : (
          <OutputModeSection fieldKey="outputMode" required {...sectionProps} />
        )}

        {this.props.isForDatabase && (
          <MaxSamplesPerClassifier
            fieldKey="maxSamplesPerClassifier"
            required
            {...sectionProps}
          />
        )}
        <IncludeSamplesSection fieldKey="includeSamples" {...sectionProps} />
        <EncryptionSection fieldKey="encryptionEnabled" {...sectionProps} />
        {this.props.authenticationMode && (
          <AuthenticationSection
            fieldKey="authentication"
            required
            {...sectionProps}
          />
        )}

        {this.props.submitStatus.get('error') && (
          <div
            style={{
              color: 'red',
              position: 'relative',
              top: '55px',
              fontWeight: 400,
            }}
          >
            There was a problem submitting the job
          </div>
        )}

        <ModalFooter
          className="submitform-controls"
          style={{ marginTop: '1em' }}
        >
          <TextLink color="primary" onClick={this.props.onCancel}>
            <DynamicFormattedMessage {...messages.submitFormCancel} />
          </TextLink>

          <Button
            color="primary"
            onClick={this.onSubmit.bind(this)}
            disabled={
              Object.keys(this.state.formErrors).length > 0 ||
              this.props.submitStatus.get('loading')
            }
          >
            <DynamicFormattedMessage {...messages.submitFormSubmit} />
            <span>
              {' '}
              {this.props.submitStatus.get('loading') && (
                <Icon fa name="sync" spin={true} />
              )}
            </span>
          </Button>
        </ModalFooter>
      </div>
    )
  }
}

SubmitForm.defaultProps = {
  isForDatabase: false,
}

SubmitForm.propTypes = {
  authenticationMode: PropTypes.string,
  isForDatabase: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitStatus: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
  }).isRequired,
  customClassifiers: ImmutablePropTypes.list,
  loadingCustomClassifiers: PropTypes.bool,
}

export default SubmitForm
