import { all, put, takeLatest, select, call } from 'redux-saga/effects'

import { resultsLimitExceeded } from 'utils/sonraiUtils'
import {
  quickSearchLoading,
  setQuickSearchResults,
  resultLimitExceeded,
  setQuickSearchError,
} from './actions'
import { FETCH_QUICK_SEARCH } from './constants'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'
import { selectQueryTypes } from 'containers/SonraiData/selectors'

function* getResultsForType(searchTerm, type) {
  const client = getClient()
  const results = yield client.query({
    query: gql`
      query getQuickSearch${type}Results(
        $searchParam: String!
        $limit: Int = 500
      ) {
        QuickSearch(searchTerm: $searchParam) {
          ${type}(limit: $limit) {
            active
            account
            createdDate
            createdBy
            name
            modifiedDate
            resourceId
            sid
            observedDate
            loadId
            label
            srn
          }
        }
      }
    `,
    variables: {
      searchParam: searchTerm,
    },
  })

  const formattedResults = { items: results.data.QuickSearch[type] }

  if (resultsLimitExceeded(formattedResults)) {
    yield put(resultLimitExceeded())
  }

  yield put(setQuickSearchResults({ results: formattedResults, type }))
}

function* getIdentities(searchTerm) {
  yield call(getResultsForType, searchTerm, 'Identities')
}

function* getData(searchTerm) {
  yield call(getResultsForType, searchTerm, 'Data')
}

function* getInfrastructure(searchTerm) {
  yield call(getResultsForType, searchTerm, 'Infrastructure')
}

function* getProtections(searchTerm) {
  const queryTypes = yield select(selectQueryTypes)

  // Before doing query we try to check Protections is actually a field on
  // QuickSearchResultSet, if it's not it just means the GraphQL server doesn't
  // support it yet. It will support it soon, but doing this check makes
  // front-end not coupled to graphql-server

  const quickSearchGraphQLType = queryTypes.get('QuickSearchResultSet')
  if (!quickSearchGraphQLType) {
    // eslint-disable-next-line no-console
    console.error('Could not find GraphQL type QuickSearch')
    yield put(setQuickSearchResults({ results: [], type: 'Protection' }))
    return
  }

  const protectionsField = quickSearchGraphQLType
    .get('fields')
    .find(field => 'Protection' === field.get('name'))
  if (!protectionsField) {
    // this is OK, it probably means Protection isn't implemented yet
    // (it is forthcoming)

    // eslint-disable-next-line no-console
    console.warn('No Protections on QuickSearchResultSet')
    yield put(setQuickSearchResults({ results: [], type: 'Protection' }))
    return
  }

  yield call(getResultsForType, searchTerm, 'Protection')
}

function* getQuickSearchResults(action) {
  try {
    yield put(quickSearchLoading(true))

    yield all([
      call(getIdentities, action.payload),
      call(getData, action.payload),
      call(getInfrastructure, action.payload),
      call(getProtections, action.payload),
    ])

    yield put(quickSearchLoading(false))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error(`Failed to run quick search for: ${action.payload}`, e)

    yield put(
      setQuickSearchError(
        e.message || 'Error when fetching Quick Search results'
      )
    )
  }
}

function* quickSearchSaga() {
  yield all([takeLatest(FETCH_QUICK_SEARCH, getQuickSearchResults)])
}

export default quickSearchSaga
