import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Sunburst, Hint } from 'react-vis'
import _ from 'lodash'
import color from 'color'
import SunburstMultiView from './SunburstMultiView'
import Scrollable from 'components/Scrollable'
import EmptyWidgetResults from 'components/EmptyWidgetResults'
import sizeMe from 'components/SizeMe'
import BounceLoadingAnimation from 'components/BounceLoadingAnimation'
import ChartTooltip from 'components/ChartTooltip'
import BorderlessButton from 'components/BorderlessButton'
import SunburstBreadcrumb from './SunburstBreadcrumb'
import Pill from 'components/Badge/Pill'
import './styles.css'
import SunburstLegend from './SunburstLegend'

const BREADCRUMBS_HEIGHT = 80
const LEGEND_WIDTH = 400
export class SunburstVis extends React.Component {
  constructor(props) {
    super(props)

    const originalData = this.updateData(props.dataFormatter(props.data), false)

    this.originalData = originalData
    const preFiltered = this.getPreselectedPath(originalData)
    let selectedSlice =
      preFiltered.length > 0
        ? preFiltered[preFiltered.length - 1]
        : originalData

    if (
      preFiltered.length > 1 &&
      (!selectedSlice.children || selectedSlice.children.length === 0)
    ) {
      selectedSlice = { children: [selectedSlice] }
    }

    this.state = {
      clickedPath: preFiltered,
      hoverPath: [],
      hoveredNode: null,
      hoveredTooltip: null,
      data: selectedSlice,
      legendFilters: [],
      pieBreadcrumbs: {},
    }
  }

  componentDidUpdate(oldProps) {
    if (!_.isEqual(oldProps.data, this.props.data)) {
      this.originalData = this.updateData(
        this.props.dataFormatter(this.props.data),
        false
      )

      const preFiltered = this.getPreselectedPath(this.originalData)

      let selectedSlice =
        preFiltered.length > 0
          ? preFiltered[preFiltered.length - 1]
          : this.originalData

      if (
        preFiltered.length > 1 &&
        (!selectedSlice.children || selectedSlice.children.length === 0)
      ) {
        selectedSlice = { children: [selectedSlice] }
      }

      this.setState({
        clickedPath: preFiltered,
        hoverPath: [],
        data: selectedSlice,
        legendFilters: [],
      })
    }

    if (oldProps.isSunburstViewMulti !== this.props.isSunburstViewMulti) {
      this.reset()
    }
  }

  getFilteredData = data => {
    if (this.state.legendFilters.length === 0) {
      return data
    } else {
      const filteredData = data.filter(
        rollup =>
          !!this.state.legendFilters.find(
            filter => rollup[filter.keyName] === filter.keyValue
          )
      )

      return filteredData
    }
  }

  updateData = (data, keyPath) => {
    if (!data.id) {
      data.id = `${data.name}${data.keyName}${data.keyValue}`
    }

    if (data.children) {
      data.children.map(child => this.updateData(child, keyPath))
    }

    data.style = {
      ...data.style,
      fillOpacity: keyPath && !keyPath[data.id] ? 0.2 : 1,
    }

    return data
  }

  getHoverKeyPath = node => {
    if (!node.parent) {
      return []
    }

    return [
      {
        id: (node.data && node.data.id) || node.id,
        name: (node.data && node.data.name) || node.name,
        hex: (node.data && node.data.hex) || node.hex,
        keyName: (node.data && node.data.keyName) || node.keyName,
        keyValue: (node.data && node.data.keyValue) || node.keyValue,
      },
    ].concat(this.getHoverKeyPath(node.parent))
  }

  reset = () => {
    this.setState({
      data: this.updateData(this.originalData),
      clickedPath: [],
      hoverPath: [],
      hoveredNode: null,
    })

    if (this.props.onClickSlice) {
      const root = { ...this.originalData }
      delete root.children

      this.props.onClickSlice({
        path: [root],
        node: root,
      })
    }
  }

  handleClick = clickedSlice => {
    const slicePath = this.findSlicePath(clickedSlice.id, [this.originalData])

    if (!slicePath) {
      return
    }

    if (slicePath && slicePath.length > 1) {
      slicePath.shift() //remove the fake "all/root" node from the array
    }

    const isOuterRing =
      !clickedSlice.children || clickedSlice.children.length === 0

    const visData = isOuterRing
      ? { children: [slicePath[slicePath.length - 1]] }
      : slicePath[slicePath.length - 1]

    this.setState({
      clickedPath: slicePath,
      hoverPath: [],
      data: this.updateData(visData, false),
      legendFilters: [], //TODO: make clicking breadcrumbs play nice with legend filtering
    })

    if (this.props.onClickSlice) {
      this.props.onClickSlice({
        path: slicePath,
        node: visData,
      })
    }
  }

  findSlicePath = (sliceId, items) => {
    for (let i = 0; i < items.length; i++) {
      const slice = items[i]
      if (slice.id === sliceId) {
        return [slice]
      } else if (_.isArray(slice.children)) {
        const found = this.findSlicePath(sliceId, slice.children)

        if (found) {
          found.unshift(slice)
          return found
        }
      }
    }
  }

  showingOutermostValue = () => {
    return (
      this.state.data.children.length === 1 &&
      !this.state.data.children[0].children
    )
  }

  onHover = node => {
    this.setState({
      hoveredNode: node,
    })

    if (this.showingOutermostValue()) {
      return
    }

    const path = this.getHoverKeyPath(node).reverse()
    const pathAsMap = path.reduce((res, row) => {
      res[row.id] = true
      return res
    }, {})

    this.setState(currentState => ({
      hoverPath: path,
      data: this.updateData(currentState.data, pathAsMap),
      hoveredNode: node,
    }))
  }

  onHoverLeave = () => {
    this.setState(currentState => ({
      hoverPath: [],
      data: this.updateData(currentState.data, false),
      hoveredNode: null,
      hoveredTooltip: null,
    }))
  }

  onTooltipHover = node => {
    this.setState({
      hoveredTooltip: node,
    })
  }

  onTooltipEndHover = () => {
    this.setState(currentState => ({
      hoverPath: [],
      data: this.updateData(currentState.data, false),
      hoveredNode: null,
    }))
  }

  getPreselectedPath = startingData => {
    if (!this.props.preFilters || this.props.preFilters.isEmpty()) {
      return []
    }

    let foundRepeatedValue = false
    let usedKeys = {}

    const checkChildren = (data, filters) => {
      if (foundRepeatedValue) return []

      let path = []
      if (data.keyName && data.keyValue && filters.get(data.keyName)) {
        if (filters.get(data.keyName) === data.keyValue) {
          if (usedKeys[data.keyName]) {
            foundRepeatedValue = true
            return []
          }

          path.push(data)
          usedKeys[data.keyName] = true
        }

        if (filters.get(data.keyName) !== data.keyValue) {
          return []
        }
      }

      if (data.children) {
        data.children.forEach(child => {
          const childResult = checkChildren(child, filters)
          if (childResult.length > 0) {
            //One of the children have a match!
            if (data.keyName && data.keyValue && filters.get(data.keyName)) {
              //If there is a filter applicable for this particular slice
              //we only want to use the child paths if the current slice matches the filters
              if (filters.get(data.keyName) === data.keyValue) {
                if (path.length === 0) {
                  path.push(data)
                }

                path = path.concat(childResult)
              }
            } else {
              if (path.length === 0) {
                path.push(data)
              }

              path = path.concat(childResult)
            }
          }
        })
      }

      return path
    }

    const finalPath = checkChildren(startingData, this.props.preFilters)

    if (foundRepeatedValue) {
      //In the case that matching filters are found in multiple approaches, do no preselection
      return []
    }

    if (finalPath.length > 0 && !finalPath[0].name) {
      //remove the "root" node
      finalPath.shift()
    }

    return finalPath
  }

  buildValue = hoveredCell => {
    const { radius, angle, angle0 } = hoveredCell
    const truedAngle = (angle + angle0) / 2

    const defaultLocation = {
      x: radius * Math.cos(truedAngle),
      y: radius * Math.sin(truedAngle),
    }

    if (this.state.hoveredTooltip) {
      defaultLocation.x = defaultLocation.x * -1
      defaultLocation.y = defaultLocation.y * -1
    }

    return defaultLocation
  }

  renderBreadcrumb = slice => {
    return (
      <SunburstBreadcrumb
        onClick={() => this.handleClick(slice)}
        color={slice.hex}
      >
        {slice.name}
      </SunburstBreadcrumb>
    )
  }

  renderHoverBreadcrumb = slice => {
    const colorContrastToWhite = color(slice.hex).contrast(color('#fff'))
    return (
      <span style={{ padding: '0 0.3em' }}>
        &gt;{' '}
        <span
          style={{
            color:
              colorContrastToWhite < 4.5
                ? color(slice.hex).darken(0.3).hex()
                : slice.hex,
          }}
        >
          {slice.name}
        </span>
      </span>
    )
  }

  renderBreadcrumbs = () => {
    return (
      <div
        style={{
          gridArea: 'breadcrumbs',
          overflow: 'hidden',
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          gridColumnGap: '1em',
          gridTemplateAreas: '"breadcrumbContent switchView"',
        }}
      >
        {this.props.isSunburstViewMulti
          ? this.renderMultiviewBreadcrumbs()
          : this.renderSunburstBreadcrumbs()}
        <div
          style={{
            gridArea: 'switchView',
          }}
        >
          <BorderlessButton
            color="primary"
            style={{
              marginBottom: '5px',
              marginRight: '1em',
              cursor: 'pointer',
            }}
            onClick={() => this.props.toggleSunburstView()}
          >
            {this.props.isSunburstViewMulti
              ? 'See Combined View'
              : 'See Multi View'}
          </BorderlessButton>
        </div>
      </div>
    )
  }

  renderSunburstBreadcrumbs = () => {
    return (
      <Scrollable horizontalScrollOnly style={{ height: '40px' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            whiteSpace: 'nowrap',
          }}
        >
          <div style={{ paddingRight: '20px' }}>
            <SunburstBreadcrumb color="#aaa" onClick={this.reset}>
              All
            </SunburstBreadcrumb>
            {this.state.clickedPath.map(this.renderBreadcrumb)}
          </div>

          <div style={{ height: '30px', lineHeight: '30px' }}>
            {this.state.hoverPath.map(this.renderHoverBreadcrumb)}
          </div>
        </div>
      </Scrollable>
    )
  }

  renderMultiviewBreadcrumbs = () => {
    return (
      <div
        style={{
          gridArea: 'breadcrumbContent',
          display: 'flex',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        {_.keys(this.state.pieBreadcrumbs || {}).map(key => (
          <Pill key={key} style={{ fontSize: '0.9rem' }}>{`${_.startCase(
            key
          )}: ${this.state.pieBreadcrumbs[key]}`}</Pill>
        ))}

        {!_.isEmpty(this.state.pieBreadcrumbs) && (
          <BorderlessButton
            style={{ marginLeft: '10px', fontSize: '0.9rem' }}
            onClick={this.resetPie}
            color="secondary"
          >
            Clear
          </BorderlessButton>
        )}
      </div>
    )
  }

  onClickLegend = filters => {
    if (filters.length === 0) {
      this.setState({
        data: this.updateData(this.originalData),
        legendFilters: filters,
      })
    } else {
      const filteredData = this.props.data.filter(rollup => {
        return !!filters.find(
          filter => rollup[filter.keyName] === filter.keyValue
        )
      })

      this.setState({
        data: this.updateData(this.props.dataFormatter(filteredData)),
        legendFilters: filters,
      })
    }

    if (this.props.onChangeLegendFilter) {
      this.props.onChangeLegendFilter(filters)
    }
  }

  resetPie = () => {
    this.props.onClickPie({})
    this.setState({
      pieBreadcrumbs: {},
    })
  }

  onClickPieSlice = params => {
    this.props.onClickPie(params)

    let { sectionTitle, displayLabel } = params

    this.setState(currState => {
      const pieVariables = {
        ...currState.pieBreadcrumbs,
      }

      if (pieVariables[sectionTitle] === displayLabel) {
        //If slice was already selected, we're DEselecting it!
        delete pieVariables[sectionTitle]
      } else {
        pieVariables[sectionTitle] = displayLabel
      }

      return {
        pieBreadcrumbs: pieVariables,
      }
    })
  }

  renderTooltip = () => {
    const centerBasedCoords = this.buildValue(this.state.hoveredNode)

    return (
      <Hint
        value={centerBasedCoords}
        style={{ right: `500px !important`, bottom: `200px !important` }}
        align={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ChartTooltip
          colorShadow
          onHover={() => this.onTooltipHover(this.state.hoveredNode)}
          onEndHover={this.onTooltipEndHover}
          color={this.state.hoveredNode.hex}
          title={this.state.hoveredNode.name}
          label="Count"
          value={
            this.state.hoveredNode.value || this.state.hoveredNode.childcount
          }
        />
      </Hint>
    )
  }

  render() {
    if (this.props.loading) {
      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <BounceLoadingAnimation />
        </div>
      )
    }

    const { data } = this.state

    const hasNoChildren = !data.children || data.children.length === 0
    const isNotPrefiltered = this.state.clickedPath.length === 0

    if (!data || (isNotPrefiltered && hasNoChildren)) {
      return (
        <div
          style={{
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <EmptyWidgetResults />
        </div>
      )
    }

    return (
      <div
        style={{
          overflow: 'hidden',
          height: '100%',
          display: 'grid',
          gridTemplateRows: `${
            this.props.isSunburstViewMulti ? 40 : BREADCRUMBS_HEIGHT
          }px 1fr`,
          gridTemplateAreas: '"breadcrumbs" "sunburst"',
        }}
      >
        {this.renderBreadcrumbs()}
        {!this.props.isSunburstViewMulti ? (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `1fr ${LEGEND_WIDTH}px`,
              height: `${this.props.height - BREADCRUMBS_HEIGHT}px`,
              overflow: 'hidden',
            }}
          >
            <Sunburst
              animation
              className="center-sunburst"
              hideRootNode
              onValueMouseOver={this.onHover}
              onValueMouseOut={this.onHoverLeave}
              onValueClick={this.handleClick}
              style={{
                stroke: '#fff',
                strokeWidth: '1',
                overflow: 'hidden',
                height: `${this.props.height - BREADCRUMBS_HEIGHT}px`,
                width: `${this.props.width - LEGEND_WIDTH}px`,
                gridArea: 'sunburst',
              }}
              colorType="literal"
              getSize={d => d.value}
              getColor={d => d.hex}
              data={data}
              height={this.props.height - BREADCRUMBS_HEIGHT}
              width={this.props.width - LEGEND_WIDTH}
            >
              {this.state.hoveredNode && this.renderTooltip()}
            </Sunburst>
            <SunburstLegend
              isSunburstViewMulti={this.props.isSunburstViewMulti}
              formattedData={this.props.dataFormatter(this.props.data)}
              legendFilters={this.state.legendFilters}
              onClickLegend={this.onClickLegend}
            />
          </div>
        ) : (
          <SunburstMultiView
            data={this.props.data}
            onClickSlice={this.onClickPieSlice}
            swimlanes={this.props.swimlanes}
            controlFrameworks={this.props.controlFrameworks}
            policies={this.props.policies}
          />
        )}
      </div>
    )
  }
}

SunburstVis.defaultProps = {
  height: 350,
  width: 300,
}

SunburstVis.propTypes = {
  data: PropTypes.array,
  dataFormatter: PropTypes.func.isRequired,
  height: PropTypes.number,
  onChangeLegendFilter: PropTypes.func,
  onClickSlice: PropTypes.func,
  onClickPie: PropTypes.func,
  width: PropTypes.number,
  loading: PropTypes.bool,
  preFilters: ImmutablePropTypes.map,
  swimlanes: ImmutablePropTypes.map,
  toggleSunburstView: PropTypes.func,
  isSunburstViewMulti: PropTypes.bool,
  policies: ImmutablePropTypes.map.isRequired,
  controlFrameworks: ImmutablePropTypes.map.isRequired,
}

export default sizeMe(SunburstVis)
