import uuid from 'uuid/v4'
import _ from 'lodash'
import moment from 'moment'
import jwt from 'jsonwebtoken'
import startCleanup from './auth-db-cleanup'

const LS_PREFIX = 'authDB_'
export const UIEXPCLAIM = 'https://sonraisecurity.com/uiexp'

const EventTypes = {
  TOKEN_FETCH: 't',
  LOGOUT: 'l',
}

export const TokenFetchType = {
  INITIAL: 'i',
  REFETCH: 'r',
}

export const LogoutEventType = {
  EXPIRE_TIMEOUT: 'e',
  UNAUTH_RESP: 'u',
}

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS Z'
export function formatDateTime(value) {
  if (!value) {
    return ''
  }
  return moment(value).format(DATE_FORMAT)
}

export function parseDateTime(value) {
  return moment(value, DATE_FORMAT).valueOf()
}

export function isTelemetryEnabled(user) {
  const env = user['https://sonraisecurity.com/env']
  const meta = user['https://sonraisecurity.com/userMeta']
  if (meta && meta[env]) {
    const envMeta = meta[env]
    return envMeta.sessionTelemetryEnabled || false
  }
}

export default class AuthDB {
  constructor(user) {
    // this is the auth0 ID token as an object:
    // e.g. { name: "albert1@sonraisecurity.com", sub: "auth0|728...", ...etc }
    this.user = user
    this.enabled = isTelemetryEnabled(user)

    // each time the user navigates to the page, their tab gets an ID
    this.tab = uuid()

    this.localStorageKey = this.makeLocalStorageKey(this.tab)
    this.events = []
    this.db = {
      tab: this.tab,
      at: new Date().getTime(),
      active: true,
      events: this.events,
    }

    window.onbeforeunload = () => {
      try {
        this.db.active = false
        this.flushDB()
      } catch {
        // eslint-disable-next-line no-empty
      }
      return null
    }

    startCleanup(this)
  }

  /**
   * Flush the events to localstorage
   */
  flushDB() {
    if (this.enabled) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.db))
    }
  }

  /**
   * when a new token is fetched, call this method
   */
  tokenFetchEvent(token, type) {
    try {
      const event = this.makeEvent(EventTypes.TOKEN_FETCH, type, token)

      // eslint-disable-next-line no-console
      console.debug(
        `[TOKEN_FETCH]: tab=${this.tab} eventType=${event.type} keyId=${
          event.kid
        } iat=${event.iat}`
      )
      this.events.push(event)
      this.flushDB()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error happen tokenFetchEvent', e)
    }
  }

  logoutEvent(type, token = window.aat) {
    try {
      const event = this.makeEvent(EventTypes.LOGOUT, type, token)
      // eslint-disable-next-line no-console
      console.debug(
        `[LOGOUT]: tab=${this.tab} eventType=${event.type} keyId=${
          event.kid
        } iat=${event.iat}`
      )
      this.events.push(event)
      this.db.active = false
      this.flushDB()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error happen logoutEvent', e)
    }
  }

  makeEvent(evt, type, token) {
    const time = new Date().getTime()
    let tokenClaims
    try {
      tokenClaims = jwt.decode(token)
    } catch (e) {
      tokenClaims = {}
    }
    const event = {
      evt,
      type,
      at: time,
      env: this.user['https://sonraisecurity.com/env'],
      sub: this.user.sub,
      exp: tokenClaims.exp,
      iat: tokenClaims.iat,
      uiexp: tokenClaims[UIEXPCLAIM],
    }
    return event
  }

  getAllDBs() {
    const allDBs = Object.keys(localStorage)
      .filter(key => key.startsWith(LS_PREFIX))
      .map(key => {
        try {
          return JSON.parse(localStorage.getItem(key))
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('invalid content in DB: ' + key)
          return null
        }
      })
      .filter(db => db)
    return allDBs
  }

  clearAll() {
    for (let db of this.getAllDBs()) {
      this.clearDB(db)
    }
  }

  clearDB(db) {
    const { tab } = db
    if (tab) {
      localStorage.removeItem(this.makeLocalStorageKey(tab))
    }
  }

  /**
   * user could call this from the console window to get their session logs
   */
  getSessionLogs(filter = {}) {
    let allEvents = []
    for (let db of this.getAllDBs()) {
      const { tab, active, at, events } = db
      if (events) {
        for (let event of events) {
          const prettyEvent = {
            tab,
            active,
            tabOpen: formatDateTime(at),
            ...this.getPrettyEvent(event),
          }
          if (this.filterEvent(prettyEvent, filter)) {
            allEvents.push(prettyEvent)
          }
        }
      }
    }

    allEvents.sort((a, b) => {
      return parseDateTime(b.time) - parseDateTime(a.time)
    })
    return allEvents
  }

  filterEvent(event, filter = {}) {
    for (let key of Object.keys(filter)) {
      if (event[key] != filter[key]) {
        return false
      }
    }
    return true
  }

  /**
   * get a more human readable version of the event stored in the DB
   */
  getPrettyEvent(event) {
    const prettyEvent = {
      time: formatDateTime(event.at),
      sub: event.sub,
      env: event.env,
      iat: formatDateTime(event.iat * 1000),
      exp: formatDateTime(event.exp * 1000),
      uiexp: formatDateTime(event.uiexp * 1000),
    }

    if (event.evt === 't') {
      prettyEvent.eventType = 'TOKEN_FETCH'
      if (event.type === 'i') {
        prettyEvent.type = 'INITIAL'
      }
      if (event.type === 'r') {
        prettyEvent.type = 'REFETCH'
      }
    }

    if (event.evt === 'l') {
      prettyEvent.eventType = 'LOGOUT'
      if (event.type === 'e') {
        prettyEvent.type = 'EXPIRE_TIMEOUT'
      }
      if (event.type === 'u') {
        prettyEvent.type = 'UNAUTH_RESP'
      }
    }
    return prettyEvent
  }

  /**
   * get all the session logs formatted as a CSV
   */
  sessionLogsCSV(filter, logs = this.getSessionLogs(filter)) {
    const fields = [
      'time',
      'tab',
      'active',
      'tabOpen',
      'eventType',
      'type',
      'sub',
      'env',
      'iat',
      'exp',
      'uiexp',
    ]
    const lines = [fields.join(',')]
    for (let log of logs) {
      lines.push(Object.values(_.pick(log, fields, undefined)).join(','))
    }
    return lines.join('\n')
  }

  makeLocalStorageKey(tab) {
    return LS_PREFIX + tab
  }
}
