import { defineMessages } from 'react-intl'

export default defineMessages({
  servicesTitle: {
    id: 'app.container.NodeViewLayouts.AccountNodeViewCardLayout.servicesTitle',
    defaultMessage: 'Services',
  },
  accountLabel: {
    id: 'app.container.NodeViewLayouts.AccountNodeViewCardLayout.accountLabel',
    defaultMessage: 'Account',
  },
  createdOnLabel: {
    id:
      'app.container.NodeViewLayouts.AccountNodeViewCardLayout.createdOnLabel',
    defaultMessage: 'Created On',
  },
  activeLabel: {
    id: 'app.container.NodeViewLayouts.AccountNodeViewCardLayout.activeLabel',
    defaultMessage: 'Active',
  },
})
