import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Select from 'react-select'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'

import messages from './messages'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`
const ModalContainer = styled.div`
  position: absolute;
  height: 300px;
  width: 300px;
  background: white;
  top: calc(50% - 185px);
  left: calc(50% - 150px);
  box-shadow: rgb(17, 17, 24) 0px 0px 40px 4px;
  border-radius: 5px;
`

const TopPartContainer = styled.div`
  height: 121px;
  background: rgba(241, 241, 241, 0.8);
  font-size: 22px;
  text-align: center;
  border-radius: 5px;
`

const ControlContainer = styled.div`
  font-size: 12px;
  padding: 20px;
`

const BigButton = styled.button`
  border: none;
  cursor: pointer;
  color: white;
  font-size: 15px;
  font-weight: 400;
  background-color: green;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px;
  position: absolute;
  bottom: 0;
  height: 80px;
  width: 100%;
  text-align: center;

  transition: font-size 0.1s, padding 0.1s;
  &:hover {
    padding: 19px;
    font-size: 16px;
  }
  &:focus {
    text-decoration: underline;
  }
`

export default class ChooseOrgModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      value: null,
    }
  }

  onConfirm = () => {
    if (this.state.value) {
      this.props.onConfirm(this.state.value)
    } else {
      this.setState({ error: true })
    }
  }

  onButtonKeyUp = event => {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.onConfirm()
    }
  }

  setValue = ({ value }) => this.setState({ value, error: false })

  render() {
    const options = this.props.allowedOrgs.map(org => {
      return { label: org, value: org }
    })

    return (
      <ModalOverlay>
        <ModalContainer>
          <TopPartContainer>
            <img
              style={{ height: '58px', width: 'auto', marginTop: '10px' }}
              src="https://sonrai-logos.s3.amazonaws.com/productLogo.png"
            />
            <div>
              <DynamicFormattedMessage {...messages.title} />
            </div>
          </TopPartContainer>
          <ControlContainer>
            <Select options={options} onChange={this.setValue} />
          </ControlContainer>
          <BigButton onKeyUp={this.onButtonKeyUp} onClick={this.onConfirm}>
            <DynamicFormattedMessage {...messages.buttonText} />
            <span
              style={{
                marginLeft: '4px',
                fontSize: '12px',
                position: 'relative',
              }}
            >
              <Icon fa name="angle-right" />
            </span>
          </BigButton>
        </ModalContainer>
      </ModalOverlay>
    )
  }
}

ChooseOrgModal.propTypes = {
  allowedOrgs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onConfirm: PropTypes.func.isRequired,
}
