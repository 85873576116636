import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown as RSDropdown } from 'reactstrap'
import { isPlainObject } from 'lodash'

import DropdownMenu from './DropdownMenu'
import DropdownItem from './DropdownItem'
export class Dropdown extends React.Component {
  state = {
    dropdownExpanded: false,
  }

  styles = {
    container: {
      display: 'inline',
    },
  }

  toggleDropdown = () => {
    if (!this.props.disabled) {
      this.setState(currentState => ({
        dropdownExpanded: !currentState.dropdownExpanded,
      }))
    } else {
      this.setState(() => ({
        dropdownExpanded: false,
      }))
    }
  }

  render() {
    let selectedOption
    let defaultOption

    const color = this.props.disabled ? '#888888' : '#000000'

    const checkForValues = child => {
      if (child.props.value === this.props.selectedValue) {
        selectedOption = {
          value: child.props.value,
          label: child.props.label,
        }
      } else if (child.props.defaultValue) {
        defaultOption = {
          value: child.props.value,
          label: child.props.label,
        }
      }
    }

    React.Children.forEach(this.props.children, child => {
      if (child.type === DropdownMenu) {
        React.Children.forEach(child.props.children, checkForValues)
      } else if (child.type === DropdownItem) {
        checkForValues(child)
      }
    })
    return (
      <RSDropdown
        style={this.styles.container}
        isOpen={this.state.dropdownExpanded}
        toggle={this.toggleDropdown}
      >
        {React.Children.map(this.props.children, child => {
          const style = isPlainObject(child.props.style)
            ? child.props.style
            : {}
          return React.cloneElement(child, {
            dropdownExpanded: this.state.dropdownExpanded,
            toggle: this.toggleDropdown,
            selectedOption: selectedOption || defaultOption,
            onClick: this.props.onClick,
            style: { ...style, color: color },
          })
        })}
      </RSDropdown>
    )
  }
}

Dropdown.defaultProps = {
  onClick: () => {},
}

Dropdown.propTypes = {
  children: PropTypes.node,
  selectedValue: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Dropdown
export InlineDropdownAnchor from './InlineDropdownAnchor'
export InlineTextDropdown from './InlineTextDropdown'
export DropdownMenu from './DropdownMenu'
export DropdownItem from './DropdownItem'
export DropdownAnchor from './DropdownAnchor'
