import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import sizeMe from 'components/SizeMe'
import TicketTable from 'components/TicketTable'
import ImmutablePropTypes from 'react-immutable-proptypes'

export class RapSheetAlerts extends React.Component {
  componentDidMount() {
    this.setAlertsCount()
  }

  componentDidUpdate(prevProps) {
    this.setAlertsCount(prevProps)
  }

  /**
   * if the parent wishes to be notified when the number of items in this table
   * changes, they can pass this setAlertCounts function
   */
  setAlertsCount(prevProps) {
    if (!prevProps || prevProps.data.length != this.props.data.length) {
      if (this.props.setAlertCounts) {
        this.props.setAlertCounts(this.props.data.length)
      }
    }
  }

  getHeightForPage = (data, pageSize) => {
    return data.length > 0 ? pageSize * 50 + 160 : 250
  }

  render() {
    const { data } = this.props

    let maxHeight = this.props.pageSize
      ? this.getHeightForPage(data, this.props.pageSize)
      : this.props.height

    if (this.props.pageSize && data.length <= this.props.pageSize) {
      maxHeight = this.getHeightForPage(data, data.length)
    }

    return (
      <div
        style={{
          height: `${maxHeight}px`,
          paddingRight: '10px', // move the table slightly left of the scrollbar on windows
        }}
      >
        <TicketTable
          columnConfig={{
            objective: {
              width: 300,
              pinned: 'right',
            },
          }}
          exportFileName={`Security Center Tickets`}
          tickets={data}
          refreshData={this.props.refreshData}
          objectives={this.props.objectives}
        />
      </div>
    )
  }
}

RapSheetAlerts.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  pageSize: PropTypes.number,
  refreshData: PropTypes.func,
  setAlertCounts: PropTypes.func,
  objectives: ImmutablePropTypes.iterable.isRequired,
}

export default compose(sizeMe)(RapSheetAlerts)
