import { createSelector } from 'reselect'

/**
 * Direct selector to the explorer state domain
 */
const selectExplorerDomain = state => state.get('explorer')

export const selectSourceNodes = createSelector(
  selectExplorerDomain,
  state => state.get('sourceNodes')
)

export const selectIsLoading = createSelector(
  selectExplorerDomain,
  state => state.get('isLoading')
)

export const selectAvailableFilters = createSelector(
  selectExplorerDomain,
  state => state.get('availableFilters')
)

export const selectNodes = createSelector(
  selectExplorerDomain,
  state => state.get('nodes')
)

export const selectSelectedNode = createSelector(
  selectExplorerDomain,
  state => state.get('selectedNode')
)

export const selectNodePreview = createSelector(
  selectExplorerDomain,
  state => state.get('nodePreview')
)
export const selectNodePreviewRelations = createSelector(
  selectExplorerDomain,
  state => state.get('nodePreviewRelations') || Map()
)

export const selectIsLoadingNodePreview = createSelector(
  selectExplorerDomain,
  state => state.get('isLoadingNodePreview')
)

export const selectHiddenNodes = createSelector(
  selectExplorerDomain,
  state => state.get('hiddenNodes')
)

export const selectExpandedNodeTypes = createSelector(
  selectExplorerDomain,
  state => state.get('expandedNodeTypes')
)

export const selectIsLoadingNodes = createSelector(
  selectExplorerDomain,
  state => state.get('isLoadingNodes')
)
/**
 * Other specific selectors
 */

/**
 * Default selector used by Explorer
 */

const makeSelectExplorer = () =>
  createSelector(
    selectExplorerDomain,
    substate => substate.toJS()
  )

export default makeSelectExplorer
export { selectExplorerDomain }
