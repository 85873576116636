import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import permissionChecker from './index'

/**
 * This react component will only render its children if the currently
 * logged in user has the permission passed by prop 'permissionName'
 */
export function WithPermission(props) {
  let renderIt = false
  if (props.multiPermissions) {
    props.multiPermissions.forEach(permission => {
      if (
        props.userHasPermission({
          permissionName: permission.permissionName,
          swimlanes: permission.swimlanes,
          resourceId: permission.resourceId,
        })
      ) {
        renderIt = true
      }
    })
  } else {
    renderIt = props.userHasPermission({
      permissionName: props.permissionName,
      swimlanes: props.swimlanes,
      resourceId: props.resourceId,
    })
  }

  if (props.invertCheck) {
    renderIt = !renderIt
  }
  if (renderIt) {
    return props.children
  } else {
    return null
  }
}

PropTypes.defaultProps = {
  invertCheck: false,
}

PropTypes.propTypes = {
  permissionName: PropTypes.string,
  resourceId: PropTypes.oneOfType([
    PropTypes.string,
    // if func, it's a function that can be used to generate the resourceId
    // from the args defined in 'resourceIdGeneratorArgs'
    PropTypes.func,
  ]),
  swimlanes: PropTypes.arrayOf(PropTypes.string),

  // if you have multiple permissions, passs it this bad boy
  multiPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      permissionName: PropTypes.string,
      resourceId: PropTypes.string,
      swimlanes: PropTypes.arrayOf(PropTypes.string),
    })
  ),

  // this is the contents of the auth0 id_token
  userData: ImmutablePropTypes.map,

  // userHasPermission populated from permissionChecker hoc
  userHasPermission: PropTypes.func.isRequired,

  // whether we should invert the permission check, and only render the
  // children if user doesn't have permission. Instead of passing this try
  // using WithoutPermission component instead
  invertCheck: PropTypes.bool,
}

export default permissionChecker(WithPermission)
