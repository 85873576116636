import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import MarkdownEditor from 'components/MarkdownEditor'
import FormLabel from 'components/FormLabel'
import { acceptRiskTickets } from 'containers/TicketDetailsData/actions'
import Button from 'components/Button'
import TextLink from 'components/TextLink'

const AcceptRiskModal = props => {
  const [comment, setComment] = useState('')

  const onSubmit = () => {
    props.acceptRiskTickets({
      ticketSrns: props.ticketSrns,
      comment: comment,
    })
    props.close()
  }

  return (
    <Modal isOpen={true}>
      <ModalHeader>Accept Risk for selected tickets</ModalHeader>
      <ModalBody>
        <FormLabel required>Comment</FormLabel>
        <MarkdownEditor
          value={comment}
          onChange={newcomment => setComment(newcomment)}
          onKeyStroke={newcomment => setComment(newcomment)}
        />
      </ModalBody>
      <ModalFooter>
        <TextLink color="secondary" onClick={props.close}>
          Cancel
        </TextLink>
        <Button color="primary" disabled={!comment} onClick={onSubmit}>
          Accept Risk
        </Button>
      </ModalFooter>
    </Modal>
  )
}

AcceptRiskModal.propTypes = {
  close: PropTypes.func.isRequired,
  acceptRiskTickets: PropTypes.func.isRequired,
  ticketSrns: PropTypes.array.isRequired,
}

const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      acceptRiskTickets,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(AcceptRiskModal)
