import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import _ from 'lodash'
import {
  setPropertyWidgetData,
  setDidAccessData,
  setCanAccessData,
  setAccessedUsingData,
  setActivityWidgetData,
  setSelectedRowActions,
  setCanAccessPath,
  errEPPaths,
  setLastCanAccessJobTimestamp,
} from './actions'

import { selectLastCanAccessJobTimestamp } from './selectors'
import {
  GET_PROPERTY_WIDGET_DATA,
  GET_DID_ACCESS_DATA,
  GET_CAN_ACCESS_DATA,
  GET_ACCESSED_USING_DATA,
  GET_ACTIVITY_WIDGET_DATA,
  GET_SELECTED_ROW_ACTIONS,
  GET_CAN_ACCESS_PATH,
  GET_EP_PATHS,
} from './constants'
import {
  GET_PROPERTIES_WIDGET,
  GET_DID_ACCESS_WIDGET,
  GET_CAN_ACCESS_WIDGET,
  GET_CAN_ACCESS_PATHS,
  GET_ACCESSED_USING_WIDGET_RESOURCE_SET,
  GET_ACCESSED_USING_WIDGET_CRITICAL_RESOURCE_SRN,
  GET_ACTIVITY_WIDGET,
  GET_ACCESSED_ACTIONS,
  GET_ACCESSED_BY_ACTIONS,
  GET_ACTIVE_FROM_ACTIONS,
  GET_ACCESSED_FROM_ACTIONS,
  GET_ACCESSED_USING_ACTIONS,
  GET_WAS_ACCESSED_USING_ACTIONS,
} from 'static-queries'
import gql from 'graphql-tag'
import { getClient } from 'apolloClient'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { srnIsCRMActionable } from 'utils/widgetUtils'
import {
  selectQueryTypes,
  selectSwimlanes,
} from 'containers/SonraiData/selectors'

import moment from 'moment'
function* getPropertyWidget(action) {
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
        ${GET_PROPERTIES_WIDGET}
      `,
      variables: {
        srn: action.payload,
      },
    })
    const properties = response.data.getMonitoredProperties
    yield put(setPropertyWidgetData(properties))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting property data', e)
  }
}

function* getDidAccess(action) {
  const { srn, fromDate, toDate, keyName } = action.payload
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
        ${GET_DID_ACCESS_WIDGET}
      `,
      variables: {
        srn,
        toDate: parseInt(toDate),
        fromDate: parseInt(fromDate),
        keyName,
      },
    })
    yield put(setDidAccessData(response.data.AlertLogs.items))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting did access data', e)
  }
}

const CAN_ACCESS_FROM_EP_GCP = gql`
  query getCanAccessFromEPGCP($resourceId: String) {
    Actors(
      where: {
        and: [{ and: [{ active: { op: EQ, value: true } }] }, {}, {}]
        hasEffectivePermissions: {
          includes: [
            {
              gcpResource: { op: EQ, value: $resourceId, caseSensitive: false }
            }
          ]
        }
      }
    ) {
      items {
        hasEffectivePermissions(
          distinct: [
            permission
            policySrn
            permissionActionClassifications
            policyEntryConditions
            identityChain
          ]
          where: {
            includes: [
              {
                gcpResource: {
                  op: EQ
                  value: $resourceId
                  caseSensitive: false
                }
              }
            ]
          }
        ) {
          items {
            permission
            policySrn
            permissionActionClassifications
            policyEntryConditions
            identityChain
          }
        }
        Who: name
        Where: account
        Service: serviceType
        highestAlertSeverity
        srn
        ... on Resource {
          swimlane {
            items {
              title
              defaultImportance
            }
          }
        }
        importance
        tagSet
      }
    }
  }
`

const CAN_ACCESS_FROM_EP = gql`
  query getCanAccessFromEP($srn: String) {
    Actors(
      where: {
        and: [{ and: [{ active: { op: EQ, value: true } }] }, {}, {}]
        hasEffectivePermissions: {
          includes: [
            { azureResource: { op: EQ, value: $srn, caseSensitive: false } }
          ]
        }
      }
    ) {
      items {
        hasEffectivePermissions(
          distinct: [
            permission
            policySrn
            permissionActionClassifications
            policyEntryConditions
            identityChain
          ]
          where: {
            includes: [
              { azureResource: { op: EQ, value: $srn, caseSensitive: false } }
            ]
          }
        ) {
          items {
            permission
            policySrn
            permissionActionClassifications
            policyEntryConditions
            identityChain
          }
        }
        Who: name
        Where: account
        Service: serviceType
        highestAlertSeverity
        srn
        ... on Resource {
          swimlane {
            items {
              title
              defaultImportance
            }
          }
        }
        importance
        tagSet
      }
    }
  }
`

function* getTypesForAzureData(action) {
  const { srn, resourceId } = action.payload
  const type = getTypeFromSrn(srn)
  if (!type) {
    // eslint-disable-next-line no-console
    console.warn('could not get the type from the srn: ' + srn)
    return
  }
  const queryTypes = yield select(selectQueryTypes)
  const possibleDataTypes = queryTypes.getIn(['Data', 'possibleTypes'], null)
  if (possibleDataTypes === null) {
    // eslint-disable-next-line no-console
    console.warn('could not get Data.possibleTypes from queryTypes')
    return
  }

  const lowercaseType = type.toLowerCase()
  const dataType = possibleDataTypes.find(
    pt => lowercaseType === pt.get('name').toLowerCase()
  )

  if (!dataType) {
    // this is an OK scenario, it just means the thing is Azure but not data
    return
  }

  const client = yield getClient()

  let response
  const isGCPNode = srn && srn.includes('gcp')
  if (isGCPNode && resourceId) {
    response = yield client.query({
      query: CAN_ACCESS_FROM_EP_GCP,
      variables: { resourceId },
    })
  } else {
    response = yield client.query({
      query: CAN_ACCESS_FROM_EP,
      variables: { srn: resourceId }, // <-- not a typo
    })
  }

  const results = []

  const path = ['data', 'Actors', 'items']
  const actors = _.get(response, path, null)
  if (path === null) {
    throw 'The response did not have ' + path.join('/')
  }

  const allSwimlanes = yield select(selectSwimlanes)
  const swimlanesSrnToName = {}
  if (!allSwimlanes) {
    // eslint-disable-next-line no-console
    console.warn('no swimlanes')
  } else {
    for (let swimlane of allSwimlanes) {
      swimlanesSrnToName[swimlane.srn] = swimlane.name
    }
  }

  for (let actor of actors) {
    const {
      Who: who,
      Where: where,
      Service,
      tagSet: Tags,
      swimlane,
      importance,
      srn,
    } = actor
    const eps = _.get(actor, ['hasEffectivePermissions', 'items'], null)
    if (eps === null) {
      // eslint-disable-next-line no-console
      console.warn('actor has null eps ', actor)
    }

    const alreadyfoundHows = []
    for (let ep of eps) {
      const {
        policyEntryConditions: Conditions,
        permissionActionClassifications: How,
        identityChain,
        policySrn,
      } = ep

      const path = identityChain.split(',')
      path.push(policySrn)

      if (!alreadyfoundHows.includes(How)) {
        results.push({
          srn,
          isSpecialAzureData: true,
          who,
          How,
          where,
          Service,
          action: path,
          Conditions: Conditions != '' ? Conditions : '[]',
          Swimlanes: _.get(swimlane, ['items'], []).map(sw => sw.title),
          Importance: importance
            ? importance
            : _.get(swimlane, ['items'], []).reduce(
                (acc, curr) => Math.max(acc, curr.defaultImportance),
                0
              ),
          Tags,
        })
        alreadyfoundHows.push(How)
      }
    }
  }

  return results
}

function* getCanAccess(action) {
  const { srn, cloudType, fromDate, toDate, keyName } = action.payload
  try {
    if ('azure' === cloudType || 'gcp' === cloudType) {
      const azureDataCanAccess = yield getTypesForAzureData(action)
      if (azureDataCanAccess) {
        yield put(setCanAccessData(azureDataCanAccess))
        return
      }
    }

    const fromDateSeven = moment.unix(toDate).subtract(7, 'days')
    let momentFromDate = moment.unix(fromDate)

    if (fromDateSeven.isBefore(momentFromDate)) {
      momentFromDate = fromDateSeven
    }

    const client = getClient()

    const lastRunTimestampResponse = yield client.query({
      query: gql`
        query getMostRecentCanAccessAlertLog(
          $srn: String
          $fromDate: DateTime
          $toDate: DateTime
          $keyName: String
        ) {
          AlertLogs(
            where: {
              timestamp: { op: BETWEEN, values: [$fromDate, $toDate] }
              criticalResourceSRN: { value: $srn }
              keyName: { value: $keyName }
            }
          ) {
            items(limit: 1, orderBy: [{ timestamp: { order: DESC } }]) {
              timestamp
            }
          }
        }
      `,
      variables: {
        srn,
        toDate: moment.unix(toDate).toISOString(),
        fromDate: momentFromDate.toISOString(),
        keyName,
      },
    })

    if (!lastRunTimestampResponse.data) {
      throw new Error(
        `Error fetching last Can Access data: ${lastRunTimestampResponse.errors}`
      )
    }

    if (lastRunTimestampResponse.data.AlertLogs.items.length === 0) {
      yield put(setCanAccessData([]))
      return
    }

    const timestamp = lastRunTimestampResponse.data.AlertLogs.items[0].timestamp
    const lastTimestamp = moment(timestamp).toISOString()

    yield put(setLastCanAccessJobTimestamp(lastTimestamp))

    const response = yield client.query({
      query: gql`
        ${GET_CAN_ACCESS_WIDGET}
      `,
      variables: {
        srn,
        toDate: lastTimestamp,
        fromDate: lastTimestamp,
        keyName,
      },
    })

    const parsedData = response.data.AlertLogs.items.map(crmData => {
      if (typeof crmData.path === 'string') {
        crmData.path = JSON.parse(crmData.path)
      }

      return crmData
    })

    yield put(setCanAccessData(parsedData))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting can access data', e)
  }
}

function* getCanAccessPaths(action) {
  let {
    srn,
    fromDate,
    toDate,
    keyName,
    resourceId,
    actionClassification,
    conditions,
  } = action.payload

  const lastRunTimestamp = yield select(selectLastCanAccessJobTimestamp)
  if (lastRunTimestamp) {
    fromDate = lastRunTimestamp
    toDate = lastRunTimestamp
  }

  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
        ${GET_CAN_ACCESS_PATHS}
      `,
      variables: {
        srn,
        conditions,
        fromDate,
        toDate,
        keyName,
        resourceId,
        actionClassification,
      },
    })

    const parsedData = response.data.AlertLogs.items.map(crmData => {
      let path = crmData.path
      if (typeof crmData.path === 'string') {
        path = JSON.parse(crmData.path)
      }

      return path
    })

    yield put(
      setCanAccessPath({
        srn: resourceId,
        actionClassification: actionClassification,
        paths: parsedData,
        fromDate: action.payload.fromDate,
        conditions,
      })
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting can access paths', e)
  }
}

function* getAccessedUsing(action) {
  const { srn, fromDate, toDate } = action.payload
  if (srnIsCRMActionable(srn)) {
    try {
      const client = getClient()
      const response = yield client.query({
        query: gql`
          ${GET_ACCESSED_USING_WIDGET_RESOURCE_SET}
        `,
        variables: {
          srn,
          fromDate: parseInt(fromDate),
          toDate: parseInt(toDate),
        },
      })
      yield put(setAccessedUsingData(response.data.AlertLogs.items))
    } catch (e) {
      //eslint-disable-next-line no-console
      console.error('Error getting accessed using data', e)
    }
  } else {
    try {
      const client = getClient()
      const response = yield client.query({
        query: gql`
          ${GET_ACCESSED_USING_WIDGET_CRITICAL_RESOURCE_SRN}
        `,
        variables: {
          srn,
          fromDate: parseInt(fromDate),
          toDate: parseInt(toDate),
        },
      })
      yield put(setAccessedUsingData(response.data.AlertLogs.items))
    } catch (e) {
      //eslint-disable-next-line no-console
      console.error('Error getting accessed using data', e)
    }
  }
}

function* getActivityWidget(action) {
  const { srn, fromDate, toDate, keyName } = action.payload
  try {
    const client = getClient()
    const response = yield client.query({
      query: gql`
        ${GET_ACTIVITY_WIDGET}
      `,
      variables: {
        srn,
        toDate: parseInt(toDate),
        fromDate: parseInt(fromDate),
        keyName,
      },
    })
    yield put(setActivityWidgetData(response.data.AlertLogs.items))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error getting activity regions data', e)
  }
}

function* getActions(action) {
  const { data, date, path } = action.payload
  const dates = {
    fromDate: date.fromDate
      ? moment.utc(moment(date.fromDate).format('MMM D YYYY')).format('X')
      : moment.utc(moment().format('MMM D YYYY')).format('X'),
    toDate:
      moment
        .utc(date || moment().format('MMM D YYYY'))
        .add(1, 'days')
        .format('X') - 1,
  }

  switch (path) {
    case 'accessed':
      yield call(getAccessedActions, data, dates)
      break
    case 'accessedBy':
      yield call(getAccessedByActions, data, dates)
      break
    case 'activeFrom':
      yield call(getActiveFromActions, data, dates)
      break
    case 'accessedFrom':
      yield call(getAccessedFromActions, data, dates)
      break
    case 'accessedUsing':
      yield call(getAccessedUsingActions, data, dates)
      break
    case 'wasAccessedUsing':
      yield call(getWasAccessedUsingActions, data, dates)
      break
    default:
      return
  }
}

function* getAccessedActions(data, dates) {
  const { fromDate, toDate } = dates
  const { criticalResourceSRN, actionClassification, resourceSet } = data
  const performedOnValue = resourceSet[0]
  try {
    const client = getClient()
    let response = yield client.query({
      query: gql`
        ${GET_ACCESSED_ACTIONS}
      `,
      variables: {
        fromDate: parseInt(fromDate),
        toDate: parseInt(toDate),
        criticalResourceSRN,
        actionClassification,
        performedOnValue,
      },
    })
    yield put(setSelectedRowActions(response.data.Actions.items))
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting actions for selected node ', err)
  }
}

function* getAccessedByActions(data, dates) {
  const { fromDate, toDate } = dates
  const { criticalResourceSRN, actionClassification, resourceSet } = data
  const performedByValue = resourceSet[0]
  try {
    const client = getClient()
    let response = yield client.query({
      query: gql`
        ${GET_ACCESSED_BY_ACTIONS}
      `,
      variables: {
        fromDate: parseInt(fromDate),
        toDate: parseInt(toDate),
        criticalResourceSRN,
        actionClassification,
        performedByValue,
      },
    })
    yield put(setSelectedRowActions(response.data.Actions.items))
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting actions for selected node ', err)
  }
}

function* getActiveFromActions(data, dates) {
  const { fromDate, toDate } = dates
  const { criticalResourceSRN, regionSet } = data
  const countryValue = regionSet[0]
  try {
    const client = getClient()
    let response = yield client.query({
      query: gql`
        ${GET_ACTIVE_FROM_ACTIONS}
      `,
      variables: {
        fromDate: parseInt(fromDate),
        toDate: parseInt(toDate),
        criticalResourceSRN,
        countryValue,
      },
    })
    yield put(setSelectedRowActions(response.data.Actions.items))
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting actions for selected node ', err)
  }
}

function* getAccessedFromActions(data, dates) {
  const { fromDate, toDate } = dates
  const { criticalResourceSRN, regionSet } = data
  const countryValue = regionSet[0]
  try {
    const client = getClient()
    let response = yield client.query({
      query: gql`
        ${GET_ACCESSED_FROM_ACTIONS}
      `,
      variables: {
        fromDate: parseInt(fromDate),
        toDate: parseInt(toDate),
        criticalResourceSRN,
        countryValue,
      },
    })
    yield put(setSelectedRowActions(response.data.Actions.items))
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting actions for selected node ', err)
  }
}

function* getAccessedUsingActions(data, dates) {
  const { fromDate, toDate } = dates
  const { criticalResourceSRN, userAgentSet } = data
  const userValue = userAgentSet[0]
  try {
    const client = getClient()
    let response = yield client.query({
      query: gql`
        ${GET_ACCESSED_USING_ACTIONS}
      `,
      variables: {
        fromDate: parseInt(fromDate),
        toDate: parseInt(toDate),
        criticalResourceSRN,
        userValue,
      },
    })
    yield put(setSelectedRowActions(response.data.Actions.items))
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting actions for selected node ', err)
  }
}

function* getWasAccessedUsingActions(data, dates) {
  const { fromDate, toDate } = dates
  const { criticalResourceSRN, userAgentSet } = data
  const userValue = userAgentSet[0]
  try {
    const client = getClient()
    let response = yield client.query({
      query: gql`
        ${GET_WAS_ACCESSED_USING_ACTIONS}
      `,
      variables: {
        fromDate: parseInt(fromDate),
        toDate: parseInt(toDate),
        criticalResourceSRN,
        userValue,
      },
    })
    yield put(setSelectedRowActions(response.data.Actions.items))
  } catch (err) {
    //eslint-disable-next-line no-console
    console.error('Error getting actions for selected node ', err)
  }
}

function* getEffectivePermissionIdentityChains(action) {
  const client = yield getClient()
  const { epFilter: epFilterArg, srn } = action.payload
  const epFilter = _.mapValues(epFilterArg, arg => ({ value: arg }))
  try {
    const result = yield client.query({
      variables: {
        epFilter,
        srn,
        permissionSrn: epFilterArg.permissionSrn,
      },
      query: gql`
        query effectivePermChains(
          $srn: String
          $permissionSrn: String
          $epFilter: EffectivePermissionFilterType
        ) {
          Permissions(where: { srn: { value: $permissionSrn } }) {
            items {
              cloudType
              label
              name
              srn
              metadata
            }
          }
          Users(where: { srn: { value: $srn } }) {
            items {
              hasEffectivePermissions(where: { includes: [$epFilter] }) {
                items {
                  identityChain
                  permission
                  permissionSrn
                  permissionCloudType
                  permissionActionClassifications
                  permissionListLoadId
                  permissionServiceType
                  policySrn
                  policyType
                  policyVersionId
                  policyEntryCloudType
                  policyEntryLoadId
                  policyEntryPrincipals
                  policyEntryResourceFilter
                  policyEntryConditions
                  resourceAccount
                  resourceFriendlyName
                  resourceId
                  resourceLabel
                  resourceLoadId
                  resourceSrn
                  resourceCloud
                  restriction
                  activityCount
                  identityChainDepth
                  lastUsedDate
                }
              }
            }
          }
        }
      `,
    })

    const permission = _.get(result, ['data', 'Permissions', 'items', 0])
    const permissionSRN = (permission || {}).srn

    if (undefined === permissionSRN) {
      throw 'The result did not return permission SRN'
    }

    const eps = _.get(result, [
      'data',
      'Users',
      'items',
      0,
      'hasEffectivePermissions',
      'items',
    ])
    if (undefined === eps) {
      throw 'Rhe result did not return effective permissions'
    }

    const paths = _.uniq(
      eps.map(({ identityChain, policySrn }) => `${identityChain},${policySrn}`)
    ).map(it => it.split(','))

    const { actionClassification, fromDate, conditions } = action.payload

    action.payload.pathDataCallback({
      srn: permissionSRN,
      permission,
    })
    yield put(
      setCanAccessPath({
        srn: permissionSRN,
        fromDate,
        actionClassification,
        conditions,
        paths,
      })
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `There was error fetching effective permission identity chain: ${e}`
    )
    yield put(errEPPaths())
  }
}

function* accessActivitySaga() {
  yield all([
    takeLatest(GET_PROPERTY_WIDGET_DATA, getPropertyWidget),
    takeLatest(GET_DID_ACCESS_DATA, getDidAccess),
    takeLatest(GET_CAN_ACCESS_DATA, getCanAccess),
    takeLatest(GET_ACCESSED_USING_DATA, getAccessedUsing),
    takeLatest(GET_ACTIVITY_WIDGET_DATA, getActivityWidget),
    takeLatest(GET_SELECTED_ROW_ACTIONS, getActions),
    takeLatest(GET_CAN_ACCESS_PATH, getCanAccessPaths),
    takeLatest(GET_EP_PATHS, getEffectivePermissionIdentityChains),
  ])
}

export default accessActivitySaga
