import gql from 'graphql-tag'

export const FETCH_TICKET_TEMPLATES_QUERY = gql`
  query fetchTicketTemplates {
    TicketTemplates {
      items(limit: 1000, orderBy: [{ title: { order: ASC } }]) {
        srn
        title
        description
        fields {
          name
          required
          type
          multi
          select {
            search
            path
            valueField
            labelFields
          }
        }
        __typename
      }
    }
  }
`

export const ASSIGN_TICKET_ESCALATION_MUTATION = gql`
  mutation assignCustomTicketEscalationScheme(
    $escalationSchemeSrn: String
    $ticketSrn: String
  ) {
    CreateEscalationFilter(
      input: {
        schemeSrn: $escalationSchemeSrn
        ticketKey: $ticketSrn
        ticketType: "Custom"
      }
    ) {
      srn
    }
  }
`

export const REMOVE_TICKET_ESCALTION_MUTATION = gql`
  mutation removeTicketEscalationAssignment($filterSrn: String) {
    DeleteEscalationFilter(input: { srn: $filterSrn }) {
      srn
    }
  }
`

export const CREATE_TICKET_MUTATION = gql`
  mutation createTicket($ticket: CreateTicketRequestInput) {
    CreateTicket(input: $ticket) {
      srn
    }
  }
`

export const UPDATE_TICKET_MUTATION = gql`
  mutation updateTicket($ticket: UpdateTicketInput) {
    UpdateTicket(input: $ticket) {
      srn
    }
  }
`
