import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import _ from 'lodash'
import {
  FETCH_CD_OPTIONS,
  FETCH_CD_OPTIONS_ERROR,
  FETCH_CD_OPTIONS_SUCCESS,
  FETCH_TICKET_LIST,
  FETCH_TICKET_LIST_ERROR,
  FETCH_TICKET_LIST_SUCCESS,
  SET_TICKET_LIST_FILTER,
  CLEAR_TICKET_LIST_FILTER,
} from './constants'

export const initialState = fromJS({
  changeDectionOptions: {
    data: [],
    error: false,
    loading: true,
  },
  ticketList: {
    data: {},
    error: false,
    loading: true,
  },
  ticketListFilter: {},
})

export default handleActions(
  {
    [FETCH_CD_OPTIONS]: state =>
      state
        .setIn(['changeDectionOptions', 'error'], false)
        .setIn(['changeDectionOptions', 'loading'], true),
    [FETCH_CD_OPTIONS_ERROR]: state =>
      state
        .setIn(['changeDectionOptions', 'error'], true)
        .setIn(['changeDectionOptions', 'loading'], false),
    [FETCH_CD_OPTIONS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['changeDectionOptions', 'error'], false)
        .setIn(['changeDectionOptions', 'loading'], false)
        .setIn(['changeDectionOptions', 'data'], fromJS(payload.data)),
    [FETCH_TICKET_LIST]: state =>
      state
        .setIn(['ticketList', 'loading'], true)
        .setIn(['ticketList', 'error'], false),
    [FETCH_TICKET_LIST_ERROR]: state =>
      state
        .setIn(['ticketList', 'loading'], false)
        .setIn(['ticketList', 'error'], true),
    [FETCH_TICKET_LIST_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['ticketList', 'loading'], false)
        .setIn(['ticketList', 'error'], false)
        .setIn(['ticketList', 'data'], fromJS(payload.data)),
    [SET_TICKET_LIST_FILTER]: (state, { payload }) => {
      const currentTicketFilterState = state.get('ticketListFilter')
      const updated = _.keys(payload).reduce((nextState, type) => {
        const value = payload[type]
        return value === undefined
          ? nextState.delete(type)
          : nextState.set(type, fromJS(value))
      }, currentTicketFilterState)

      return state.set('ticketListFilter', updated)
    },
    [CLEAR_TICKET_LIST_FILTER]: state => state.set('ticketListFilter', Map()),
  },
  initialState
)
