export const GET_PERFORMED_ACTIONS = 'app/Activity/GET_PERFORMED_ACTIONS'
export const SET_PERFORMED_ACTIONS = 'app/Activity/SET_PERFORMED_ACTIONS'
export const SET_ACTIVITY_DATE = 'app/Activity/SET_ACTIVITY_DATE'
export const TOGGLE_PERFORMED_ACTION_FIELDS =
  'app/Activity/TOGGLE_PERFORMED_ACTION_FIELDS'
export const SET_PERFORMED_ACTION_FIELDS =
  'app/Activity/SET_PERFORMED_ACTION_FIELDS'
export const CLEAR_ACTIVITY = 'app/Activity/CLEAR_ACTIVITY'
export const GET_ROLES_ASSUMED = 'app/Activity/GET_ROLES_ASSUMED'
export const SET_ROLES_ASSUMED = 'app/Activity/SET_ROLES_ASSUMED'
export const GET_ROLES_ASSUMED_BY = 'app/Activity/GET_ROLES_ASSUMED_BY'
export const SET_ROLES_ASSUMED_BY = 'app/Activity/SET_ROLES_ASSUMED_BY'
export const GET_ACTIVE_FROM = 'app/Activity/GET_ACTIVE_FROM'
export const SET_ACTIVE_FROM = 'app/Activity/SET_ACTIVE_FROM'
export const GET_ACCESSED_USING = 'app/Activity/GET_ACCESSED_USING'
export const SET_ACCESSED_USING = 'app/Activity/SET_ACCESSED_USING'

export const GET_SELECTED_ROW_ACTIONS = 'app/Activity/GET_SELECTED_ROW_ACTIONS'
export const SET_SELECTED_ROW_ACTIONS = 'app/Activity/SET_SELECTED_ROW_ACTIONS'
export const TOGGLE_ACTIONS_MODAL = 'app/Activity /TOGGLE_ACTIONS_MODAL'
