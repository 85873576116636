import { createSelector } from 'reselect'
import { initialState } from './reducers'

export const selectDomain = state =>
  state.get('ticketAutoRemediateWizard', initialState)

export const selectAllEscallations = createSelector(selectDomain, state =>
  state.get('allEscalations')
)

export const selectStep = createSelector(selectDomain, state =>
  state.get('step')
)

export const selectSelectedBot = createSelector(selectDomain, state =>
  state.get('selectedBot')
)

export const selectSubmitAutoRemediateStatus = createSelector(
  selectDomain,
  state => state.get('submitAutoRemediateStatus')
)
