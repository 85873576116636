import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { List } from 'immutable'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import JSONDisplay from 'components/JSONDisplay'
import BarLoadingAnimation from 'components/BarLoadingAnimation'
import Button from 'components/Button'
import {
  fetchEffectivePermissionsForActor,
  fetchUberPolicy,
} from 'containers/NodeSolutionCenter/actions'
import {
  getNodeViewPushParams,
  getNameFromSrn,
  getCloudFromSrn,
} from 'utils/sonraiUtils'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import CenterContent from 'components/CenterContent'
import TimeoutLoader from 'components/TimeoutLoader'
import {
  selectEffectivePermissions,
  selectUsedEffectivePermissions,
  selectEffectivePermissionsLoading,
  selectUsedEffectivePermissionsLoading,
  selectUberPolicy,
} from 'containers/NodeSolutionCenter/selectors'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Card from 'components/Card'
import { TopTitle, CardBody } from 'components/Card'
import DataTable from 'components/DataTable'
import TextLink from 'components/TextLink'

class EffectivePermissionWidget extends React.Component {
  styles = {
    card: {
      minHeight: 0,
    },
  }

  state = {
    showUberPolicy: false,
  }

  componentDidMount() {
    this.props.fetchEffectivePermissionsForActor({
      srn: this.props.srn,
      usedPermissions: this.props.usedPermissions,
    })

    if (this.hasUberPolicy()) {
      this.props.fetchUberPolicy(this.props.srn)
    }
  }

  getEPData = () => {
    if (this.props.usedPermissions) {
      return (this.props.usedEffectivePermissions || List()).toJS().map(row => {
        const newCols = {
          permission: row.permission,
          policy: row.policySrn,
          resourceFilter: row.policyEntryResourceFilter,
          conditions: row.policyEntryConditions,
          identityChain: row.identityChain,
        }

        if (this.props.onShowPath) {
          newCols.showPath = row
        }

        return newCols
      })
    } else {
      return (this.props.effectivePermissions || List()).toJS().map(row => {
        const newCols = {
          permission: row.permission,
          policy: row.policySrn,
          resourceFilter: row.policyEntryResourceFilter,
          conditions: row.policyEntryConditions,
          identityChain: row.identityChain,
        }

        if (this.props.onShowPath) {
          newCols.showPath = row
        }

        return newCols
      })
    }
  }

  onShowPath = perm => {
    if (this.props.onShowPath) {
      this.props.onShowPath(perm)
    }
  }

  getRowRows = () => {
    return '<div>No IAM or RBAC Permissions</div>'
  }

  openUberPolicy = () => {
    this.setState({
      showUberPolicy: true,
    })
  }

  closeUberPolicy = () => {
    this.setState({
      showUberPolicy: false,
    })
  }

  hasUberPolicy = () => {
    if (this.props.usedPermissions) {
      return false
    }

    if (!getCloudFromSrn(this.props.srn) === 'aws') {
      return false
    }

    const type = getTypeFromSrn(this.props.srn).toLowerCase()
    if (type !== 'user' && type !== 'group' && type !== 'role') {
      return false
    }

    return true
  }

  render() {
    const permissionData = this.getEPData()
    const isLoading = this.props.usedPermissions
      ? this.props.loadingUsed
      : this.props.loading

    return (
      <Card>
        <TopTitle>{`${
          this.props.usedPermissions ? 'Used' : 'Unused'
        } Permissions`}</TopTitle>
        <CardBody style={this.styles.card}>
          {isLoading ? (
            <CenterContent style={{ height: '100%' }}>
              <TimeoutLoader
                firstMessage="Working on your query..."
                finalMessage="Unfortunately, your query has timed out. Please try again later."
              />
            </CenterContent>
          ) : (
            <Fragment>
              <div
                style={{
                  display: 'grid',
                  gridTemplateRows: '1fr auto',
                  height: '100%',
                  width: '100%',
                }}
              >
                <div>
                  <DataTable
                    style={{
                      height: permissionData.length > 0 ? '100%' : '60px',
                    }}
                    sortColumns={true}
                    data={permissionData}
                    overlayNoRowsTemplate={this.getRowRows()}
                    customColumnConfig={{
                      showPath: {
                        width: 120,
                        minWidth: 120,
                        pinned: 'right',
                        headerName: '',
                        enableRowGroup: false,
                        menuTabs: [],
                        suppressMenu: true,
                        cellRendererFramework: params => {
                          return (
                            <TextLink
                              color="primary"
                              style={{
                                color: this.props.theme.primary,
                              }}
                              onClick={() => this.onShowPath(params.value)}
                            >
                              Show Path
                            </TextLink>
                          )
                        },
                      },
                      policy: {
                        cellRendererFramework: params => {
                          return (
                            <TextLink
                              color="primary"
                              style={{
                                color: this.props.theme.primary,
                              }}
                              to={getNodeViewPushParams(
                                params.value,
                                getTypeFromSrn(params.value)
                              )}
                            >
                              {getNameFromSrn(params.value)}
                            </TextLink>
                          )
                        },
                      },
                    }}
                  />
                </div>
                <div>
                  {this.hasUberPolicy() && (
                    <Button color="primary" onClick={this.openUberPolicy}>
                      Optimal Least Privilege Policy
                    </Button>
                  )}
                </div>
              </div>
            </Fragment>
          )}
          <Modal
            isOpen={this.state.showUberPolicy}
            toggle={this.closeUberPolicy}
            size="lg"
          >
            <ModalHeader toggle={this.closeUberPolicy}>
              Optimal Least Privilege Policy
            </ModalHeader>
            <ModalBody>
              {this.props.uberPolicy.get('loading') ? (
                <BarLoadingAnimation />
              ) : (
                <JSONDisplay content={this.props.uberPolicy.get('data')} />
              )}
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    )
  }
}

EffectivePermissionWidget.defaultProps = {
  usedPermissions: false,
}

EffectivePermissionWidget.propTypes = {
  effectivePermissions: ImmutablePropTypes.list,
  fetchEffectivePermissionsForActor: PropTypes.func.isRequired,
  fetchUberPolicy: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingUsed: PropTypes.bool,
  onShowPath: PropTypes.func,
  srn: PropTypes.string.isRequired,
  theme: themeShape,
  uberPolicy: ImmutablePropTypes.contains({
    data: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.string,
  }),
  usedEffectivePermissions: ImmutablePropTypes.list,
  usedPermissions: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  loading: selectEffectivePermissionsLoading,
  loadingUsed: selectUsedEffectivePermissionsLoading,
  effectivePermissions: selectEffectivePermissions,
  usedEffectivePermissions: selectUsedEffectivePermissions,
  uberPolicy: selectUberPolicy,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchEffectivePermissionsForActor,
      fetchUberPolicy,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(themeable(EffectivePermissionWidget))
