import React, { useState } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import { PlainCheckbox } from 'components/Checkbox'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import { acceptLicense } from 'containers/UserProfileData/actions'
import { useAuth0 } from 'react-auth0-wrapper'

export const AcceptLicenseModal = props => {
  const { logout } = useAuth0()
  const [state, setState] = useState({
    checkedAccept: !!props.userInfo.get('hasAcceptedLegal'),
  })

  const handleAccept = checked => {
    setState({
      checkedAccept: checked,
    })
  }

  const handleSubmit = () => {
    if (state.checkedAccept) {
      props.acceptLicense()
    }
  }

  return (
    <Modal size="lg" isOpen={true} backdrop="static">
      <ModalHeader>License Agreement</ModalHeader>
      <ModalBody>
        <div
          style={{
            padding: '1em',
            marginTop: '-1em',
            textAlign: 'center',
          }}
        >
          {`By clicking "I Accept" below you agree to the terms and conditions of
      our license.`}
          <br />
          <br />
          <span style={{ margin: '0.5em' }}>
            <PlainCheckbox
              checked={state.checkedAccept}
              onChange={handleAccept}
            />
          </span>
          <label htmlFor="agreement">
            I accept the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={window.config.userAgreementUrl}
            >
              Sonrai License Agreement
            </a>{' '}
            and our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={window.config.openSourceLicenseUrl}
            >
              Open Source Licenses
            </a>
          </label>
        </div>
      </ModalBody>
      <ModalFooter>
        <TextLink color="secondary" onClick={logout}>
          Cancel
        </TextLink>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={!state.checkedAccept}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  )
}

AcceptLicenseModal.propTypes = {
  acceptLicense: PropTypes.func.isRequired,
  userInfo: ImmutablePropTypes.map.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      acceptLicense,
    },
    dispatch
  )
}

const mapStateToProps = createStructuredSelector({
  userInfo: selectUserProfile,
})

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(withConnect)(AcceptLicenseModal)
