import {
  FETCH_POLICIES,
  SET_POLICIES,
  CREATE_POLICY,
  CREATE_POLICY_SUCCESS,
  UPDATE_CONTROL_GROUP,
  UPDATE_CONTROL_GROUP_SUCCESS,
  FETCH_CONTROL_GROUPS,
  SET_CONTROL_GROUPS,
  CREATE_CONTROL_GROUP,
  CREATE_CONTROL_GROUP_SUCCESS,
  DELETE_CONTROL_GROUP,
  DELETE_CONTROL_GROUP_SUCCESS,
  DELETE_POLICY,
  DELETE_POLICY_SUCCESS,
  UPDATE_POLICY,
  UPDATE_POLICY_SUCCESS,
  ADD_POLICY,
  ADD_POLICY_SUCCESS,
  REMOVE_POLICY,
  REMOVE_POLICY_SUCCESS,
  CLONE_FRAMEWORK,
  CLONE_FRAMEWORK_SUCCESS,
  SET_FRAMEWORK_ENABLED,
  SET_FRAMEWORK_ENABLED_SUCCESS,
  SET_POLICY_ERROR,
  TOGGLE_ENABLED_ON_SWIMLANES,
  TOGGLE_ENABLED_ON_SWIMLANES_SUCCESS,
  TOGGLE_SWIMLANE_ON_CFS,
  TOGGLE_SWIMLANE_ON_CFS_SUCCESS,
  TOGGLE_SWIMLANE_ON_CFS_ERROR,
} from './constants'
import { createAction } from 'redux-actions'

export const fetchPolicies = createAction(FETCH_POLICIES)
export const setPolicies = createAction(SET_POLICIES)
export const createPolicy = createAction(CREATE_POLICY)
export const createPolicySuccess = createAction(CREATE_POLICY_SUCCESS)
export const setPolicyError = createAction(SET_POLICY_ERROR)
export const updateControlGroup = createAction(UPDATE_CONTROL_GROUP)
export const updateControlGroupSuccess = createAction(
  UPDATE_CONTROL_GROUP_SUCCESS
)
export const fetchControlGroups = createAction(FETCH_CONTROL_GROUPS)
export const setControlGroups = createAction(SET_CONTROL_GROUPS)
export const createControlGroup = createAction(CREATE_CONTROL_GROUP)
export const createControlGroupSuccess = createAction(
  CREATE_CONTROL_GROUP_SUCCESS
)
export const deleteControlGroup = createAction(DELETE_CONTROL_GROUP)
export const deleteControlGroupSuccess = createAction(
  DELETE_CONTROL_GROUP_SUCCESS
)

export const deletePolicy = createAction(DELETE_POLICY)
export const deletePolicySuccess = createAction(DELETE_POLICY_SUCCESS)
export const updatePolicy = createAction(UPDATE_POLICY)
export const updatePolicySuccess = createAction(UPDATE_POLICY_SUCCESS)
export const addPolicy = createAction(ADD_POLICY)
export const addPolicySuccess = createAction(ADD_POLICY_SUCCESS)
export const removePolicy = createAction(REMOVE_POLICY)
export const removePolicySuccess = createAction(REMOVE_POLICY_SUCCESS)
export const cloneFramework = createAction(CLONE_FRAMEWORK)
export const cloneFrameworkSuccess = createAction(CLONE_FRAMEWORK_SUCCESS)
export const setFrameworkEnabled = createAction(SET_FRAMEWORK_ENABLED)
export const setFrameworkEnabledSuccess = createAction(
  SET_FRAMEWORK_ENABLED_SUCCESS
)
export const toggleEnabledOnSwimlanes = createAction(
  TOGGLE_ENABLED_ON_SWIMLANES
)
export const toggleEnabledOnSwimlanesSuccess = createAction(
  TOGGLE_ENABLED_ON_SWIMLANES_SUCCESS
)

export const toggleSwimlaneOnCFs = createAction(TOGGLE_SWIMLANE_ON_CFS)
export const toggleSwimlaneOnCFsSuccess = createAction(
  TOGGLE_SWIMLANE_ON_CFS_SUCCESS
)
export const toggleSwimlaneOnCFsError = createAction(
  TOGGLE_SWIMLANE_ON_CFS_ERROR
)
