import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SubmitForm from './SubmitForm'

import { submitJob, resetJobRequestSuccess } from './actions'

import { selectSubmitJob } from './selectors'

import messages from './messages'
import { isDB, getAuthMode } from './utils'

export class DataClassificationModal extends Component {
  onSubmit = formValues => {
    this.props.submitJob({
      targetSrn: this.props.nodeData.srn,
      resourceAccount: this.props.nodeData.account,
      jobInfo: formValues,
    })
  }

  closeAndClear = () => {
    this.props.close()
    setTimeout(() => {
      // settimeout cause close is animated and we don't want the content to
      // flicker during the animation
      this.props.resetJobRequestSuccess()
    }, 500)
  }

  render() {
    return (
      <Modal isOpen={this.props.open} size="lg">
        <ModalHeader toggle={this.closeAndClear}>
          <DynamicFormattedMessage {...messages.submitFormHeader} />
        </ModalHeader>
        <ModalBody>
          {this.props.submitStatus.get('success') && (
            <div>
              <div>Job submitted Successfully</div>
              <div style={{ textAlign: 'right' }}>
                <Button color="primary" outline onClick={this.closeAndClear}>
                  Close
                </Button>
              </div>
            </div>
          )}
          {!this.props.submitStatus.get('success') && (
            <SubmitForm
              isForDatabase={isDB(this.props.nodeData)}
              authenticationMode={getAuthMode(this.props.nodeData)}
              onSubmit={this.onSubmit}
              submitStatus={this.props.submitStatus}
              onCancel={this.closeAndClear}
              customClassifiers={this.props.customClassifiers}
              loadingCustomClassifiers={this.props.loadingCustomClassifiers}
            />
          )}
        </ModalBody>
      </Modal>
    )
  }
}

DataClassificationModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  nodeData: PropTypes.shape({
    srn: PropTypes.string.isRequired,
    account: PropTypes.string.isRequired,
    cloudType: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    serviceType: PropTypes.string,
    type: PropTypes.string.isRequired,
    __typename: PropTypes.string.isRequired,
  }).isRequired,
  resetJobRequestSuccess: PropTypes.func.isRequired,
  submitJob: PropTypes.func.isRequired,
  submitStatus: ImmutablePropTypes.map.isRequired,
  customClassifiers: ImmutablePropTypes.list,
  loadingCustomClassifiers: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  submitStatus: selectSubmitJob,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ submitJob, resetJobRequestSuccess }, dispatch)
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(DataClassificationModal)
