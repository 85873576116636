import React from 'react'
import { injectIntl, intlShape } from 'react-intl'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'
import ReactTooltip from 'react-tooltip'

import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'

import messages from './messages'

export class CopyToClipboard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.buttonRef = React.createRef()
    this.uuid = uuid()
  }

  copyToClipboard = e => {
    e.stopPropagation()
    let el = document.createElement('textarea')
    el.value = this.props.value
    el.setAttribute('readonly', '')
    el.style = { position: 'absolute', left: '-9999px' }
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    ReactTooltip.show(this.buttonRef.current)
    setTimeout(() => {
      ReactTooltip.hide(this.buttonRef.current)
    }, 3000)
  }

  render() {
    const tooltip = this.props.tooltip
      ? this.props.tooltip
      : this.props.intl.formatMessage(messages.alt)
    return (
      <BorderlessButton
        innerRef={this.buttonRef}
        color="primary"
        onClick={this.copyToClipboard}
        style={{
          ...{
            height: '30px',
            width: '20px',
            marginTop: '15%',
            padding: 'auto 0.5em',
            background: 'white',
            borderRadius: '15px',
          },
          ...this.props.style,
        }}
        data-tip={this.props.intl.formatMessage(messages.copySuccess)}
        data-type="light"
        data-effect="solid"
        data-for={this.uuid}
        data-event="nothing"
        title={tooltip}
      >
        <Icon name="copy" fa transform="left-7 up-3" />
        <ReactTooltip id={this.uuid} />
      </BorderlessButton>
    )
  }
}

CopyToClipboard.defaultProps = {
  style: {},
}

CopyToClipboard.propTypes = {
  intl: intlShape,
  value: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.string,
}

export default injectIntl(CopyToClipboard)
