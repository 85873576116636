import { defineMessages } from 'react-intl'

export default defineMessages({
  assignmentControlAll: {
    id: 'app.containers.Tickets.assignmentControlAll',
    defaultMessage: 'All Assignees',
  },
  assignmentControlMyself: {
    id: 'app.containers.Tickets.assignmentControlMyself',
    defaultMessage: 'Assigned to Me',
  },
  assignmentControlUnassigned: {
    id: 'app.containers.Tickets.assignmentControlUnassigned',
    defaultMessage: 'Unassigned',
  },
  createdByCriticalAssetMonitor: {
    id: 'app.containers.Tickets.createdByCriticalAssetMonitor',
    defaultMessage: 'Critical Asset Monitor',
  },
  createdByPolicyEvaluation: {
    id: 'app.containers.Tickets.createdByPolicyEvaluation',
    defaultMessage: 'Policy Evaluation',
  },
  fetchErrorMessage: {
    id: 'app.containers.Tickets.fetchErrorMessage',
    defaultMessage: 'There was an error fetching tickets.',
  },
  pageTitle: {
    id: 'app.containers.Tickets.pageTitle',
    defaultMessage: 'Tickets',
  },
  statusControlOpen: {
    id: 'app.containers.Tickets.statusControlOpen',
    defaultMessage: 'Open',
  },
  statusControlAll: {
    id: 'app.containers.Tickets.statusControlAll',
    defaultMessage: 'All Statuses',
  },
  unassigned: {
    id: 'app.containers.Tickets.unassigned',
    defaultMessage: 'Unassigned',
  },
})
