import React from 'react'
import PropTypes from 'prop-types'

export const SectionHeader = ({ children, small, style }) => {
  const styles = {
    base: {
      marginTop: '0',
      fontSize: small ? '1.2em' : '1.5em',
      color: '#333',
    },
  }

  return <h3 style={{ ...styles.base, ...style }}>{children}</h3>
}

SectionHeader.defaultProps = {
  style: {},
}

SectionHeader.propTypes = {
  children: PropTypes.node,
  small: PropTypes.bool,
  style: PropTypes.object,
}

export default SectionHeader
