import { all, put, takeLatest, select } from 'redux-saga/effects'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import { fetchTicketDetailsSuccess } from 'containers/TicketDetailsData/actions'
import { selectTicketDetails } from 'containers/TicketDetailsData/selectors'
import {
  fetchAllEscalationsError,
  fetchAllEscalationsSucccess,
  submitAutoRemediateError,
  submitAutoRemediateNotAccepted,
  submitAutoRemediateSuccess,
  submitAutoRemediateNoCollectorMatched,
} from './actions'
import { FETCH_ALL_ESCALATIONS, SUBMIT_AUTO_REMEDIATE } from './constants'
import {
  FETCH_ALL_ESCALATIONS_QUERY,
  SUBMIT_AUTO_REMEDIATE_QUERY,
} from './queries'

function* fetchAllEscalations() {
  try {
    const client = yield getClient()
    const result = yield client.query({
      query: FETCH_ALL_ESCALATIONS_QUERY,
    })
    const path = ['data', 'EscalationSchemes', 'items']
    const data = _.get(result, path, null)
    if (data === null) {
      throw new Error(`No data at path ${path}`)
    }
    yield put(fetchAllEscalationsSucccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error ocurred fetching all escallations', e)
    yield put(fetchAllEscalationsError())
  }
}

function* submitAutoRemediate(action) {
  try {
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: SUBMIT_AUTO_REMEDIATE_QUERY,
      variables: {
        input: {
          ticktSrn: action.payload.ticket.srn,
          botSrn: action.payload.bot.srn,
        },
      },
    })

    const error = _.get(result, ['data', 'AutoRemediate', 'error'], null)
    if ('NOT_ACCEPTED' === error) {
      yield put(submitAutoRemediateNotAccepted())
      return
    } else if ('NO_COLLECTOR_MATCHED' === error) {
      yield put(submitAutoRemediateNoCollectorMatched())
      return
    } else if (error) {
      throw new Error('Other unknown error ' + error)
    }

    const success = _.get(result, ['data', 'AutoRemediate', 'success'], false)
    if (!success) {
      throw new Error('AutoRemediate was not successful')
    }
    const ticketDetails = yield select(selectTicketDetails)
    if (ticketDetails.get('data') && !ticketDetails.get('loading')) {
      const ticket = ticketDetails.get('data').toJS()
      ticket.status = 'SNOOZED'
      yield put(fetchTicketDetailsSuccess({ data: ticket }))
    }
    yield put(submitAutoRemediateSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error ocurred submitting autoremediate:', e)
    yield put(submitAutoRemediateError())
  }
}

function* ticketAutoRemediateWizardSaga() {
  yield all([
    takeLatest(FETCH_ALL_ESCALATIONS, fetchAllEscalations),
    takeLatest(SUBMIT_AUTO_REMEDIATE, submitAutoRemediate),
  ])
}

export default ticketAutoRemediateWizardSaga
