export const GET_COMPUTE_CONTENT =
  'app/ComputeNodeViewCardLayout/GET_COMPUTE_CONTENT'
export const SET_COMPUTE_CONTENT =
  'app/ComputeNodeViewCardLayout/SET_COMPUTE_CONTENT'
export const GET_INBOUND_PUBLIC_FILTERS =
  'app/ComputeNodeViewCardLayout/GET_INBOUND_PUBLIC_FILTERS'
export const SET_INBOUND_PUBLIC_FILTERS =
  'app/ComputeNodeViewCardLayout/SET_INBOUND_PUBLIC_FILTERS'
export const GET_INBOUND_PRIVATE_FILTERS =
  'app/ComputeNodeViewCardLayout/GET_INBOUND_PRIVATE_FILTERS'
export const SET_INBOUND_PRIVATE_FILTERS =
  'app/ComputeNodeViewCardLayout/SET_INBOUND_PRIVATE_FILTERS'
export const GET_INBOUND_PUBLIC_PATH =
  'app/ComputeNodeViewCardLayout/GET_INBOUND_PUBLIC_PATH'
export const SET_INBOUND_PUBLIC_PATH =
  'app/ComputeNodeViewCardLayout/SET_INBOUND_PUBLIC_PATH'
export const GET_INBOUND_PRIVATE_PATH =
  'app/ComputeNodeViewCardLayout/GET_INBOUND_PRIVATE_PATH'
export const SET_INBOUND_PRIVATE_PATH =
  'app/ComputeNodeViewCardLayout/SET_INBOUND_PRIVATE_PATH'

export const GET_OUTBOUND_PUBLIC_FILTERS =
  'app/ComputeNodeViewCardLayout/GET_OUTBOUND_PUBLIC_FILTERS'
export const SET_OUTBOUND_PUBLIC_FILTERS =
  'app/ComputeNodeViewCardLayout/SET_OUTBOUND_PUBLIC_FILTERS'
export const GET_OUTBOUND_PRIVATE_FILTERS =
  'app/ComputeNodeViewCardLayout/GET_OUTBOUND_PRIVATE_FILTERS'
export const SET_OUTBOUND_PRIVATE_FILTERS =
  'app/ComputeNodeViewCardLayout/SET_OUTBOUND_PRIVATE_FILTERS'
export const GET_OUTBOUND_PUBLIC_PATH =
  'app/ComputeNodeViewCardLayout/GET_OUTBOUND_PUBLIC_PATH'
export const SET_OUTBOUND_PUBLIC_PATH =
  'app/ComputeNodeViewCardLayout/SET_OUTBOUND_PUBLIC_PATH'
export const GET_OUTBOUND_PRIVATE_PATH =
  'app/ComputeNodeViewCardLayout/GET_OUTBOUND_PRIVATE_PATH'
export const SET_OUTBOUND_PRIVATE_PATH =
  'app/ComputeNodeViewCardLayout/SET_OUTBOUND_PRIVATE_PATH'

export const GET_INDIRECT_INBOUND_PUBLIC_FILTERS =
  'app/ComputeNodeViewCardLayout/GET_INDIRECT_INBOUND_PUBLIC_FILTERS'
export const SET_INDIRECT_INBOUND_PUBLIC_FILTERS =
  'app/ComputeNodeViewCardLayout/SET_INDIRECT_INBOUND_PUBLIC_FILTERS'
export const GET_INDIRECT_INBOUND_PRIVATE_FILTERS =
  'app/ComputeNodeViewCardLayout/GET_INDIRECT_INBOUND_PRIVATE_FILTERS'
export const SET_INDIRECT_INBOUND_PRIVATE_FILTERS =
  'app/ComputeNodeViewCardLayout/SET_INDIRECT_INBOUND_PRIVATE_FILTERS'
export const GET_INDIRECT_INBOUND_PUBLIC_PATH =
  'app/ComputeNodeViewCardLayout/GET_INDIRECT_INBOUND_PUBLIC_PATH'
export const SET_INDIRECT_INBOUND_PUBLIC_PATH =
  'app/ComputeNodeViewCardLayout/SET_INDIRECT_INBOUND_PUBLIC_PATH'
export const GET_INDIRECT_INBOUND_PRIVATE_PATH =
  'app/ComputeNodeViewCardLayout/GET_INDIRECT_INBOUND_PRIVATE_PATH'
export const SET_INDIRECT_INBOUND_PRIVATE_PATH =
  'app/ComputeNodeViewCardLayout/SET_INDIRECT_INBOUND_PRIVATE_PATH'

export const GET_COMPUTE_SECURITY_GROUPS =
  'app/ComputeNodeViewCardLayout/GET_COMPUTE_SECURITY_GROUPS'
export const SET_COMPUTE_SECURITY_GROUPS =
  'app/ComputeNodeViewCardLayout/SET_COMPUTE_SECURITY_GROUPS'
export const ERR_COMPUTE_SECURITY_GROUPS =
  'app/ComputeNodeViewCardLayout/ERR_COMPUTE_SECURITY_GROUPS'
