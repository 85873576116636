import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'app.components.ChooseOrgModal.title',
    defaultMessage: 'Choose Organization',
  },
  buttonText: {
    id: 'app.components.ChooseOrgModal.buttonText',
    defaultMessage: 'Confirm',
  },
})
