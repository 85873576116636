//AgGrid reads the props off its direct children when constructing columns
//rather than actially rendering the AgGridColumn component
//So we cannot provide a wrapper component to encapsulate the column builder functionality
//To reduce the size of the AGGridDataTable component, put some of the column logic here

import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { exists } from 'utils/sonraiUtils'

import CloudAccount from 'components/CloudAccount'

const defaultHiddenColumns = ['__typename']

const dateColumns = [
  'activationDate',
  'createdDate',
  'deletionDate',
  'expiryDate',
  'firstSeen',
  'grantedSessionDate',
  'lastAlertDate',
  'lastModified',
  'lastProcessed',
  'lastSeen',
  'lastUsed',
  'lastUsedDate',
  'launchTime',
  'modifiedDate',
  'passwordLastUsed',
  'sessionDate',
  'startDate',
  'timestamp',
  'lastSync',
  'date',
  'assumedOn',
  'EffectivePermission_lastUsedDate',
  'addedOn',
]

const effectivePermissionsFormatter = params => {
  if (!params.value) {
    return ''
  }

  if (!params.value.items && exists(params.value.count)) {
    return params.value.count
  }

  const items = params.value.items

  return JSON.stringify(items)
}

const dateTimeFormatter = params => {
  const paramValue = params.value

  if (!paramValue || paramValue === 'null' || paramValue === '-') {
    return '-'
  }

  if (paramValue.value) {
    //it is part of an grouped row result
    return paramValue.value
  }

  if (Number(paramValue)) {
    // oh shoot whaddup gangsta we got a epoch boi up in here
    return moment(Number.parseInt(paramValue)).format('LLL')
  }

  if (typeof paramValue !== 'string') {
    //It is the output of an aggregation
    return paramValue
  }

  return moment(paramValue).format('LLL')
}

const stringFormatter = params => {
  if (params.value === undefined) {
    return ''
  }

  return String(params.value)
}

const formatBoolean = ({ value }) => {
  let stringifiedVal =
    value !== null && value !== undefined ? value.toString() : ''
  return stringifiedVal
}

const countFormatter = params => {
  return params.value.count
}

const getFormatterForCol = (field, sampleValue) => {
  if (dateColumns.find(colName => field.includes(colName))) {
    return dateTimeFormatter
  } else if (field.includes('account')) {
    return stringFormatter
  } else if (field.includes('hasEffectivePermission')) {
    return effectivePermissionsFormatter
  } else {
    if (
      typeof sampleValue === 'object' &&
      sampleValue !== null &&
      sampleValue.count !== undefined
    ) {
      return countFormatter
    } else if (typeof sampleValue === 'boolean') {
      return formatBoolean
    } else {
      return undefined
    }
  }
}

const getAllowedAggregationFuncs = sampleValue => {
  if (
    typeof sampleValue === 'number' ||
    (typeof sampleValue === 'object' &&
      sampleValue !== null &&
      sampleValue.count !== undefined)
  ) {
    return ['sum', 'avg', 'countUnique', 'min', 'max', 'first', 'last', 'count']
  } else {
    return ['countUnique', 'first', 'last', 'count']
  }
}

const sortColumn = (a, b) => {
  if (a === '-' || a === '' || a === null) {
    return -1
  }

  if (b === '-' || b === '' || b === null) {
    return 1
  }

  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b, 'en', { sensitivity: 'base' })
  }

  if (typeof a === 'string') {
    //Handle values that have been grouped with uniqCount.
    //String "a" should be listed before numeric value "b"
    return -1
  }

  if (typeof b === 'string') {
    //Handle values that have been grouped with uniqCount
    //numeric "a" should be listed after string value "b"
    return 1
  }

  //Numeric or date values
  return a - b
}

const getToolTip = ({ value, valueFormatted }) => {
  if (exists(valueFormatted)) {
    return valueFormatted.toString()
  }
  if (exists(value)) {
    return value.toString()
  }
}

const isHiddenByDefault = field => {
  return !!defaultHiddenColumns.find(hiddenColName =>
    field.includes(hiddenColName)
  )
}

const getAdditionalConfig = field => {
  if (field === 'account') {
    return {
      //eslint-disable-next-line
      cellRendererFramework: params => {
        return <CloudAccount accountId={params.value} hasLink />
      },
      width: 180,
    }
  } else {
    return {}
  }
}

export default ({
  sampleValue,
  field,
  getColumnName,
  customColumnConfig = {},
}) => {
  const colName = getColumnName ? getColumnName(field, sampleValue) : field
  const customConfig = customColumnConfig[colName] || {}
  const additionalConfig = getAdditionalConfig(field, sampleValue)

  return {
    headerName: _.startCase(colName),
    field: field,
    comparator: sortColumn,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
    valueFormatter: getFormatterForCol(field, sampleValue),
    allowedAggFuncs: getAllowedAggregationFuncs(sampleValue),
    hide: isHiddenByDefault(field),
    tooltipValueGetter: getToolTip,
    sortable: true,
    filter: true,
    resizable: true,
    ...additionalConfig,
    ...customConfig,
  }
}
