import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import _ from 'lodash'

import BorderedCard from 'components/BorderedCard'

import ClassificationDataView from './ClassificationDataView'
import NoClassificationsView from './NoClassificationsView'
import SubmitFormModal from './SubmitFormModal'

import { fetchCustomClassifiers } from 'containers/DataClassifierData/actions'
import { setPrivateKey, submitJob } from './actions'

import { selectPrivateKey, selectSubmitJob } from './selectors'
import {
  selectCustomClassifiers,
  selectFetchingClassifiers,
} from 'containers/DataClassifierData/selectors'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer from './reducers'
import sagas from './sagas'

import permissionChecker from 'containers/PermissionChecker'

import { isClassificationSupported } from './utils'

const VIEW_NONE = 'VIEW_NONE'
const VIEW_DATA = 'VIEW_DATA'

class DataClassification extends Component {
  constructor(props) {
    super(props)

    // choose which view to show based on whether there is already
    // classification data
    let view = VIEW_NONE
    if (
      ['dataobject', 'datacontainer', 'datastore'].includes(
        this.props.nodeData.__typename.toLowerCase()
      )
    ) {
      if (props.nodeData && _.isArray(props.nodeData.classificationSet)) {
        view = VIEW_DATA
      }
    }

    if (
      !props.customClassifiers ||
      (props.customClassifiers.isEmpty() && !props.loadingCustomClassifiers)
    ) {
      props.fetchCustomClassifiers()
    }

    this.state = {
      view,
      submitModalOpen: false,
    }

    this.styles = {
      container: {
        padding: '8px',
      },
    }
  }

  closeFormView = () => {
    this.setState({ submitModalOpen: false })
  }

  openFormView = () => {
    this.setState({ submitModalOpen: true })
  }

  renderCardContents = () => {
    const classificationSupported = isClassificationSupported(
      this.props.nodeData
    )
    const hasPermission = this.props.userHasPermission({
      permissionName: 'edit.dataclassificationconfig',
      swimlanes: [...this.props.nodeData.swimlaneSRNs],
    })
    switch (this.state.view) {
      case VIEW_DATA:
        return (
          <ClassificationDataView
            dataContainerContent={this.props.dataContainerContent}
            nodeData={this.props.nodeData}
            openFormView={this.openFormView}
            setPrivateKey={this.props.setPrivateKey}
            privateKey={this.props.privateKey}
          />
        )
      case VIEW_NONE:
        return (
          <NoClassificationsView
            canSubmitJob={classificationSupported && hasPermission}
            openFormView={this.openFormView}
          />
        )
    }
  }

  render() {
    return (
      <div style={this.styles.container}>
        <SubmitFormModal
          open={this.state.submitModalOpen}
          close={this.closeFormView}
          nodeData={this.props.nodeData}
          customClassifiers={this.props.customClassifiers}
          loadingCustomClassifiers={this.props.loadingCustomClassifiers}
        />
        <BorderedCard>{this.renderCardContents()}</BorderedCard>
      </div>
    )
  }
}

DataClassification.propTypes = {
  // ~ passed props ~
  dataContainerContent: ImmutablePropTypes.contains({
    contains: ImmutablePropTypes.contains({
      items: ImmutablePropTypes.listOf(
        ImmutablePropTypes.contains({
          classificationSet: ImmutablePropTypes.listOf(PropTypes.string),
        })
      ),
    }),
  }),
  nodeData: PropTypes.shape({
    srn: PropTypes.string.isRequired,
    label: PropTypes.string,
    account: PropTypes.string.isRequired,
    classificationSet: PropTypes.arrayOf(PropTypes.string),
    swimlaneSRNs: PropTypes.arrayOf(PropTypes.string).isRequired,
    __typename: PropTypes.string.isRequired,
  }).isRequired,

  // ~ props from redux ~
  privateKey: PropTypes.string,

  // ~ bound action creators ~
  setPrivateKey: PropTypes.func.isRequired,

  // HOC misc.
  userHasPermission: PropTypes.func.isRequired,
  // custom classifiers stuff
  fetchCustomClassifiers: PropTypes.func.isRequired,
  customClassifiers: ImmutablePropTypes.list,
  loadingCustomClassifiers: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  privateKey: selectPrivateKey,
  submitJobStatus: selectSubmitJob,
  customClassifiers: selectCustomClassifiers,
  loadingCustomClassifiers: selectFetchingClassifiers,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setPrivateKey,
      submitJob,
      fetchCustomClassifiers,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'dataClassification', reducer })

const withSaga = injectSaga({
  key: 'dataClassification',
  saga: sagas,
  mode: DAEMON,
})

export default compose(
  permissionChecker,
  withReducer,
  withSaga,
  withConnect
)(DataClassification)

// connect a modal version of this page too
import DisconnectedModal from './SubmitFormModal'
export const Modal = compose(withReducer, withSaga)(DisconnectedModal)
