import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import styled from 'styled-components'
import BarLoadingAnimation from 'components/BarLoadingAnimation'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import BorderedCard from 'components/BorderedCard'
import TicketTable from 'components/TicketTable'
import messages from '../messages'
import TicketListFilterPanel from 'containers/TicketListFilterPanel'
// TODO test error message looks OK
const ErrorMessageContainer = styled.div``

const style = {
  secondaryContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

/**
 * main body of Ticket List, has error and loading state
 */
function TicketListBody(props) {
  if (props.ticketList.get('loading') || props.objectivesLoading) {
    return (
      <div style={style.secondaryContainer}>
        <BarLoadingAnimation />
      </div>
    )
  }

  if (props.ticketList.get('error')) {
    return (
      <div style={style.secondaryContainer}>
        <ErrorMessageContainer>
          <DynamicFormattedMessage {...messages.fetchErrorMessage} />
        </ErrorMessageContainer>
      </div>
    )
  }
  return (
    <TicketTable
      tickets={props.ticketList.get('data').toJS()}
      customFilter={() => <TicketListFilterPanel />}
      noModal={props.noModal}
      overlayNoRowsTemplate={props.overlayNoRowsTemplate}
      refreshData={props.refreshData}
      objectives={props.objectives}
    />
  )
}

TicketListBody.propTypes = {
  refreshData: PropTypes.func,
  ticketList: ImmutablePropTypes.contains({
    data: PropTypes.any,
    error: PropTypes.bool,
    loading: PropTypes.bool,
  }),
  noModal: PropTypes.bool,
  overlayNoRowsTemplate: PropTypes.node,
  objectives: ImmutablePropTypes.iterable.isRequired,
  objectivesLoading: PropTypes.bool.isRequired,
}

export default function TicketList(props) {
  return (
    <BorderedCard
      style={{
        height: 'calc(100vh - 180px)',
        minHeight: '300px',
      }}
    >
      <TicketListBody {...props} />
    </BorderedCard>
  )
}
