/**
 *
 * HeaderSearchBar
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import messages from './messages'

import Icon from 'components/Icon'
import IHelp from 'containers/IHelp'

export class HeaderSearchBar extends React.PureComponent {
  styles = {
    base: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    inputIcon: {
      backgroundColor: '#fff',
    },
    input: {
      borderRight: 0,
      borderLeft: 0,
    },
  }

  handleInput = e => {
    if (e.keyCode === 13) {
      this.props.executeSearch(e.target.value)
    }
  }

  handleValue = val => {
    this.props.setSearchValue(val)
  }

  render() {
    return (
      <div id="headerSearchWidget" style={this.styles.base}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={this.styles.inputIcon}>
              <Icon name="search" fa size="1x" color="#E3E3E3" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            className="global-search"
            key="global-search"
            type="search"
            placeholder={this.props.intl.formatMessage(messages.search)}
            onKeyUp={this.handleInput}
            onChange={e => this.handleValue(e.target.value)}
            style={this.styles.input}
            value={this.props.searchValue}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText style={this.styles.inputIcon}>
              <IHelp helpKey="quicksearch" id="help.quicksearch" help />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </div>
    )
  }
}

HeaderSearchBar.propTypes = {
  intl: intlShape,
  executeSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
}

export default injectIntl(HeaderSearchBar)
