import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

const TitleShimmer = ({ width = 80, ...props }) => {
  return (
    <span style={{ display: 'block', width: `${width}px`, height: '30px' }}>
      <ContentLoader
        height={28}
        width={width}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="0" rx="3" ry="3" width={width} height="28" />
      </ContentLoader>
    </span>
  )
}

TitleShimmer.propTypes = {
  width: PropTypes.number,
}

export default TitleShimmer
