import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'

import messages from '../messages'

export default function ErrorChangeStatusModal(props) {
  let errorMessageKey = ''
  if (props.acceptRiskTicketStatus.get('error')) {
    errorMessageKey = 'actionErrorAcceptRiskTicket'
  }

  if (props.closeTicketStatus.get('error')) {
    errorMessageKey = 'actionErrorCloseTicket'
  }

  if (props.snoozeTicketStatus.get('error')) {
    errorMessageKey = 'actionErrorSnoozeTicket'
  }

  if (props.reopenTicketStatus.get('error')) {
    errorMessageKey = 'actionErrorReopenTicket'
  }

  if (props.reassignToUserStatus.get('error')) {
    errorMessageKey = 'actionErrorReassign'
  }

  if (props.isOpen && '' === errorMessageKey) {
    // hopefully this doesn't happen:
    // eslint-disable-next-line no-console
    console.warn(
      `ErrorChangeStatusModal: could not generate error message`,
      this.props
    )
    props.close()
    return null
  }

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>
        <DynamicFormattedMessage {...messages.errorHeaderText} />
      </ModalHeader>
      <ModalBody>
        <DynamicFormattedMessage {...messages[errorMessageKey]} />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.close}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ErrorChangeStatusModal.propTypes = {
  // ~ passed from parent ~
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  acceptRiskTicketStatus: ImmutablePropTypes.contains({
    error: PropTypes.bool,
  }).isRequired,
  closeTicketStatus: ImmutablePropTypes.contains({
    error: PropTypes.bool,
  }).isRequired,
  reopenTicketStatus: ImmutablePropTypes.contains({
    error: PropTypes.bool,
  }),
  reassignToUserStatus: ImmutablePropTypes.contains({
    error: PropTypes.bool,
  }),
  snoozeTicketStatus: ImmutablePropTypes.contains({
    error: PropTypes.bool,
  }).isRequired,
}
