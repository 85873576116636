import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import TicketCommentsDisplay from './TicketCommentsDisplay'
import AddTicketComment from './AddTicketComment'
import {
  fetchTicketComments,
  addComment,
} from 'containers/TicketDetailsData/actions'
import {
  selectTicketComments,
  selectAddCommentError,
} from 'containers/TicketDetailsData/selectors'
import SectionHeader from 'components/SectionHeader'
import { selectSonraiUserSrn } from 'containers/UserProfileData/selectors'
import { selectSonraiUsers } from 'containers/SonraiData/selectors'

class TicketCommentsTab extends Component {
  constructor(props) {
    super(props)

    props.fetchTicketComments({ ticketSrn: props.ticket.get('srn') })
  }
  styles = {
    container: {
      padding: '0rem 0.5rem 0.5rem 0.5rem',
    },
  }

  render() {
    const count = (this.props.ticketComments.get('data') || List()).size
    return (
      <div style={this.styles.container}>
        {!this.props.ticketComments.get('loading') && (
          <SectionHeader small style={{ marginBottom: '2em' }}>
            Comments ({count})
          </SectionHeader>
        )}
        <TicketCommentsDisplay
          ticketComments={this.props.ticketComments}
          users={this.props.users}
        />
        <AddTicketComment
          ticketComments={this.props.ticketComments}
          addComment={this.props.addComment}
          addCommentError={this.props.addCommentError}
          currentUser={this.props.currentUser}
          ticketSrn={this.props.ticket.get('srn')}
        />
      </div>
    )
  }
}

TicketCommentsTab.propTypes = {
  fetchTicketComments: PropTypes.func.isRequired,
  ticket: ImmutablePropTypes.iterable.isRequired,
  ticketComments: ImmutablePropTypes.iterable.isRequired,
  users: ImmutablePropTypes.iterable.isRequired,
  addComment: PropTypes.func.isRequired,
  addCommentError: PropTypes.bool,
  currentUser: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  ticketComments: selectTicketComments,
  addCommentError: selectAddCommentError,
  currentUser: selectSonraiUserSrn,
  users: selectSonraiUsers,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTicketComments,
      addComment,
    },
    dispatch
  )

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(TicketCommentsTab)
