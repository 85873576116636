import PropTypes from 'prop-types'

export const PopoverBody = ({ children }) => {
  return children
}

PopoverBody.propTypes = {
  children: PropTypes.node,
}

export default PopoverBody
