import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import { exists } from 'utils/sonraiUtils'
import { fromJS, List } from 'immutable'
import Icon from 'components/Icon'
import Popup from 'reactjs-popup'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import SelectBar from 'components/SelectBar'
import { getFriendlyActionName, getFriendlyKeyName } from 'utils/widgetUtils'
import MultiSelect, { components } from 'react-select'
import _ from 'lodash'
class AddProperty extends Component {
  state = {
    popup: false,
    keyName: null,
    alertLevel: null,
    actionClassification: null,
  }

  style = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    button: {
      background: '#FFFFFF',
      borderRadius: '2px',
      paddingTop: '0.1em',
      paddingBottom: '0.1em',
      paddingRight: '0.5em',
      paddingLeft: '0.5em',
      marginRight: '0.5em',
      height: '2em',
    },
  }

  isDisabled = () => {
    const { keyName, alertLevel, actionClassification } = this.state
    if (this.hasActionClassifications()) {
      if (
        exists(keyName) &&
        exists(alertLevel) &&
        exists(actionClassification) &&
        !_.isEmpty(actionClassification)
      ) {
        return false
      }
    } else {
      if (exists(keyName) && exists(alertLevel)) {
        return false
      }
    }
    return true
  }

  hasActionClassifications = () => {
    const { keyName } = this.state
    const { changeDetectionOptions } = this.props
    if (keyName) {
      const option = changeDetectionOptions.find(
        x => x.get('keyName') === keyName
      )
      if (
        option &&
        option.get('keyType') === 'PATH' &&
        option.get('actionClassification') &&
        !option.get('actionClassification').isEmpty()
      ) {
        return true
      }
    }
    return false
  }

  handleAdd = () => {
    const { keyName, alertLevel, actionClassification } = this.state
    const {
      resourceSrn,
      addChangeDetectionProperties,
      changeDetectionOptions,
    } = this.props
    const option = changeDetectionOptions.find(x => x.get('keyName') == keyName)
    const properties = fromJS([
      {
        keyName: option.get('keyName'),
        keyType: option.get('keyType'),
        alertLevel: parseInt(alertLevel),
        actionClassification:
          actionClassification && actionClassification.map(x => x.value),
      },
    ])
    this.setState({
      keyName: null,
      alertLevel: null,
      actionClassification: null,
    })
    addChangeDetectionProperties({
      properties,
      resourceSrn,
    })
  }

  handleSelectAllActions = actionClassificationsUsed => {
    const option = this.props.changeDetectionOptions.find(
      x => x.get('keyName') == this.state.keyName
    )
    const actionClassification = option
      .get('actionClassification')
      .filter(option => !actionClassificationsUsed.includes(option))
      .toJS()
      .map(y => ({
        label: getFriendlyActionName(y, this.state.keyName),
        value: y,
      }))
    this.setState({ actionClassification })
  }

  getContents = () => {
    const { keyName } = this.state
    const { changeDetectionOptions, changeDetectionProperties } = this.props
    const alertLevelList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const changeDetectionPropertiesWithKeyName = changeDetectionProperties.filter(
      x => x.get('keyName') === keyName
    )

    const propertyIndexes = changeDetectionPropertiesWithKeyName.map(x =>
      changeDetectionProperties.indexOf(x)
    )

    const optionIndex = changeDetectionOptions.findIndex(
      x => x.get('keyName') === keyName
    )

    const actionClassificationsUsed = List().merge(
      ...propertyIndexes.map(index =>
        changeDetectionProperties.getIn([index, 'actionClassification'])
      )
    )

    const actionClassificationOptions = changeDetectionOptions
      .getIn([optionIndex, 'actionClassification'])
      .toJS()
      .map(option => {
        return {
          label: getFriendlyActionName(option, keyName),
          value: option,
        }
      })

    actionClassificationOptions.forEach(option => {
      if (actionClassificationsUsed.includes(option.value)) {
        option.isDisabled = true
      } else {
        option.isDisabled = false
      }
    })

    const keyNameOptions = changeDetectionOptions.filter(option => {
      if (option.get('keyType') === 'PROPERTY') {
        if (
          changeDetectionProperties
            .map(property => property.get('keyName'))
            .includes(option.get('keyName'))
        ) {
          return false
        }
      }
      return true
    })

    return (
      <div style={this.style.container}>
        <div style={{ fontWeight: '400' }}>Add New Property</div>
        <div style={{ fontSize: '0.9rem', margin: '0.5rem 0rem' }}>
          What would you like to monitor for changes?
        </div>
        <div style={{ width: '95%' }}>
          <SelectBar
            styles={{
              option: provided => ({
                ...provided,
                fontSize: '0.85rem',
                fontWeight: '400',
              }),
              singleValue: provided => ({
                ...provided,
                fontSize: '0.85rem',
                fontWeight: '400',
              }),
            }}
            onChange={params => this.setState({ keyName: params.value })}
            options={keyNameOptions
              .map(option => ({
                label: getFriendlyKeyName(option.get('keyName')),
                value: option.get('keyName'),
              }))
              .toJS()}
            value={this.state.keyName || 'Select...'}
          />
        </div>

        <div style={{ fontSize: '0.9rem', margin: '0.5rem 0rem' }}>
          When a change occurs, what level should the alert be?
        </div>
        <div style={{ width: '95%' }}>
          <SelectBar
            isDisabled={!exists(this.state.keyName)}
            styles={{
              option: provided => ({
                ...provided,
                fontSize: '0.85rem',
                fontWeight: '400',
              }),
              singleValue: provided => ({
                ...provided,
                fontSize: '0.85rem',
                fontWeight: '400',
              }),
            }}
            onChange={params => this.setState({ alertLevel: params.value })}
            options={alertLevelList.map(level => ({
              label: level,
              value: level,
            }))}
            value={this.state.alertLevel || 'Select...'}
          />
        </div>
        {this.hasActionClassifications() && (
          <div style={{ fontSize: '0.9rem', margin: '0.5rem 0rem 0rem 0rem' }}>
            Which actions types should change detection be restricted to?
          </div>
        )}
        {this.hasActionClassifications() && (
          <div
            style={{ width: '95%', display: 'flex', flexDirection: 'column' }}
          >
            <TextLink
              onClick={() =>
                this.handleSelectAllActions(actionClassificationsUsed)
              }
              style={{
                fontSize: '0.9rem',
                margin: '0rem 0.5rem 0.5rem 0rem',
                cursor: 'pointer',
                alignSelf: 'flex-end',
                color: this.props.theme.secondary,
              }}
            >
              Select All
            </TextLink>
            <MultiSelect
              isMulti
              components={{
                Option: props => {
                  if (props.isDisabled) {
                    return (
                      <div>
                        <components.Option {...props}>
                          <div>
                            {props.data.label}
                            <span
                              style={{
                                fontSize: '0.75rem',
                                marginLeft: '0.25rem',
                              }}
                            >
                              (Already Enabled)
                            </span>
                          </div>
                        </components.Option>
                      </div>
                    )
                  } else {
                    return <components.Option {...props} />
                  }
                },
              }}
              value={this.state.actionClassification || 'Select...'}
              onChange={params =>
                this.setState({
                  actionClassification: params,
                })
              }
              options={actionClassificationOptions.sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              styles={{
                option: provided => ({
                  ...provided,
                  fontSize: '0.85rem',
                  fontWeight: '400',
                }),
                multiValue: provided => {
                  return {
                    ...provided,
                    fontSize: '0.85rem',
                    fontWeight: '400',
                  }
                },
              }}
              isDisabled={!exists(this.state.alertLevel)}
            />
          </div>
        )}
        <div
          style={{
            marginTop: '0.75rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextLink
              onClick={() => this.setState({ popup: false })}
              style={{
                fontSize: '0.9rem',
                marginRight: '0.5rem',
                cursor: 'pointer',
              }}
            >
              Cancel
            </TextLink>
            <Button
              onClick={this.handleAdd}
              disabled={this.isDisabled()}
              style={{
                padding: '0.25rem',
                minWidth: '65px',
                fontSize: '0.8rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: this.props.theme.primary,
                borderColor: this.props.theme.primary,
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Popup
          open={this.state.popup}
          onOpen={() => this.setState({ popup: true })}
          onClose={() => this.setState({ popup: false })}
          modal
          trigger={
            <Button
              style={{
                ...this.style.button,
                ...{
                  border: 'none',
                  fontSize: '1rem',
                  color: this.props.theme.primary,
                },
              }}
            >
              <Icon
                fa
                name={'plus'}
                style={{
                  margin: `0rem 0.35rem 0rem 0rem`,
                  color: this.props.theme.primary,
                  fontSize: '0.9rem',
                }}
              />
              Add
            </Button>
          }
          position="right top"
          closeOnDocumentClick
          contentStyle={{
            width: '20rem',
            minHeight: '200px',
            padding: '0.75rem',
            boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 3px -1px`,
            display: 'flex',
            zIndex: 100,
          }}
        >
          {this.getContents()}
        </Popup>
      </div>
    )
  }
}

AddProperty.propTypes = {
  theme: themeShape,
  changeDetectionOptions: ImmutablePropTypes.iterable.isRequired,
  resourceSrn: PropTypes.string.isRequired,
  addChangeDetectionProperties: PropTypes.func.isRequired,
  changeDetectionProperties: ImmutablePropTypes.iterable.isRequired,
}

export default themeable(AddProperty)
