import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import { SET_ACCOUNT_CONTENT, GET_ACCOUNT_CONTENT } from './constants'
import { CLEAR_DATA } from 'containers/NodeSolutionCenter/constants'

const initialState = fromJS({
  accountContent: {},
  isLoading: false,
})

const accountNodeViewCardLayoutReducer = handleActions(
  {
    [GET_ACCOUNT_CONTENT]: state => state.set('isLoading', true),
    [SET_ACCOUNT_CONTENT]: (state, { payload }) => {
      return state
        .set('accountContent', fromJS(payload))
        .set('isLoading', false)
    },
    [CLEAR_DATA]: state => state.set('accountContent', Map()),
  },
  initialState
)

export default accountNodeViewCardLayoutReducer
