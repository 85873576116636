import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import UserWidget from 'components/UserWidget'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TicketStatusIcon from 'components/TicketStatusIcon'
import Pill from 'components/Badge/Pill'
import Tooltip from 'components/Tooltip'

import messages from './messages'

const cellRendererFrameworksShape = {
  value: PropTypes.any,
}

/**
 * Inject the parent component props into the child
 */
export const connectProps = (Component, props) => {
  const render = params => {
    return <Component {...{ ...props, ...params }} />
  }
  render.displayName = `connectedWithProps${Component.displayName}`
  return render
}

export function AssigneeColumn({ value }) {
  //If row is grouped, the value will be undefined. if it's not grouped and is just blank, it will be null
  if (value === undefined) {
    return null
  }

  if (!value) {
    return (
      <span style={{ fontStyle: 'italic', paddingRight: '1em' }}>
        <DynamicFormattedMessage {...messages.unassigned} />
      </span>
    )
  }

  return <UserWidget srn={value} table />
}

AssigneeColumn.propTypes = cellRendererFrameworksShape

AssigneeColumn.getQuickFilterText = ({ value }, props) => {
  if (props.usersLoading) {
    return null
  }

  if (!value || _.isEmpty(value)) {
    return null
  }

  const user = props.users.get(value)
  if (user) {
    return user.get('name')
  }
  return null
}

export function CreatedByColumn({ value }) {
  if ((value ?? '').startsWith('srn:')) {
    return <UserWidget srn={value} table />
  }

  switch (value) {
    case 'CrmPathFinderJob':
      return (
        <DynamicFormattedMessage {...messages.createdByCriticalAssetMonitor} />
      )
    case 'PolicyEngine':
      return <DynamicFormattedMessage {...messages.createdByPolicyEvaluation} />
    default:
      return value ?? ''
  }
}

/**
 * component to display ticket resource in table
 */
export const getDisplayValue = resource => {
  if (!resource) {
    return null
  }

  return resource.friendlyName || resource.name || resource.srn
}

export function ResourceColumn(args) {
  let { value, data } = args
  if (!data) {
    //Table is grouped...
    if (value) {
      //Table is grouped by resource!
      return value
    }

    //Table is grouped by another column, show nothing!
    return null
  }

  if (!value || _.isEmpty(value)) {
    if (!_.get(data, ['ticket'], null)) {
      //No ticket data, so we don't know why the resource is empty. Just show nothing.
      return null
    }
    if (_.get(data, ['ticket', 'ticketType'], '') === 'Custom') {
      //Custom tickets do not require resources, this is normal
      return 'N/A'
    }

    //If its not a custom ticket and the resource is missing, the resource has been deleted from the system
    return (
      <div
        title="This resource is no longer available as it was removed from the
        Sonrai platform."
      >
        <Tooltip
          tooltipContent={
            <span style={{ fontSize: '0.9rem' }}>
              This resource is no longer available as it was removed from the
              Sonrai platform.
            </span>
          }
          anchor={
            <Pill style={{ margin: '0 0.3em', fontSize: '1em' }}>
              &nbsp;Removed
            </Pill>
          }
        />
      </div>
    )
  }

  return value
}
ResourceColumn.propTypes = cellRendererFrameworksShape

ResourceColumn.getQuickFilterText = ({ value }) => {
  if (!value || _.isEmpty(value)) {
    return null
  }
  const text = value.friendlyName || value.name || value.srn
  return text
}

ResourceColumn.sortColumn = (a, b) => {
  const first = a.friendlyName || a.name || a.srn
  const second = b.friendlyName || b.name || b.srn
  return first.localeCompare(second)
}
/**
 * component to display ticket status in table
 */
export function StatusColumn({ value }) {
  if (!value || _.isEmpty(value)) {
    return null
  }

  return <TicketStatusIcon value={value} />
}
StatusColumn.propTypes = cellRendererFrameworksShape

/**
 * component to display swimlanes in table
 */
export function SwimlanesColumn({ value, swimlanes, swimlanesLoading }) {
  if (swimlanesLoading) {
    return null
  }

  if (!value || _.isEmpty(value)) {
    return null
  }

  const swimmies = swimlanes
    .valueSeq()
    .filter(swimlane => value && value.includes(swimlane.get('srn')))
    .toJS()
    .map((swombo, index) => (
      <Link
        key={`swombo-${index}`}
        to={`/App/SwimlaneDetails?srn=${swombo.srn}`}
      >
        <Pill style={{ margin: '0 0.3em', fontSize: '1em' }}>
          {swombo.title}
        </Pill>
      </Link>
    ))

  return <div>{swimmies}</div>
}
SwimlanesColumn.propTypes = {
  ...cellRendererFrameworksShape,
  swimlanes: ImmutablePropTypes.map.isRequired,
  swimlanesLoading: PropTypes.bool,
}

SwimlanesColumn.getQuickFilterText = (
  { value },
  { swimlanes, swimlanesLoading }
) => {
  if (swimlanesLoading) {
    return null
  }

  return swimlanes
    .valueSeq()
    .filter(swimlane => value.includes(swimlane.get('srn')))
    .toJS()
    .map(swombo => swombo.title)
    .join(' ')
}
