import React from 'react'
import PropTypes from 'prop-types'

export class SmolExpand extends React.Component {
  constructor(props) {
    super(props)
    this.styles = {
      expanded: {
        height: 'auto',
        overflow: 'visible',
      },
      content: {
        wordBreak: 'break-word',
        wordWrap: 'break-word', //Needed for Firefox
        overflow: 'hidden',
        position: 'relative',
      },
      expandButton: {
        fontSize: '0.8em',
      },
    }

    this.state = {
      contentOverflows: null,
    }

    this.containerRef = React.createRef()
  }

  componentDidMount() {
    const element = this.containerRef.current
    if (element) {
      this.setState({
        contentOverflows: element.scrollHeight > element.clientHeight,
      })
      this.props.setContentOverflow(element.scrollHeight > element.clientHeight)
    }
  }

  componentDidUpdate() {
    if (this.props.autoUpdate) {
      const element = this.containerRef.current
      if (element) {
        if (element.scrollHeight <= Number.parseInt(this.props.height)) {
          if (this.state.contentOverflows) {
            this.props.setContentOverflow(false)
            this.setState({ contentOverflows: false })
          }
        } else {
          if (!this.state.contentOverflows) {
            this.props.setContentOverflow(true)
            this.setState({ contentOverflows: true })
          }
        }
      }
    }
  }

  render() {
    return (
      <div
        ref={this.containerRef}
        style={{
          ...(this.props.scale && !this.props.expanded
            ? { maxHeight: this.props.height }
            : { height: this.props.height }),
          ...this.styles.content,
          ...(this.props.expanded ? this.styles.expanded : {}),
          ...this.props.style,
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

SmolExpand.defaultProps = {
  height: '1.7em',
  style: {},
}

SmolExpand.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  height: PropTypes.string,
  style: PropTypes.object,
  autoUpdate: PropTypes.bool,
  scale: PropTypes.bool,
  setContentOverflow: PropTypes.func,
}

export default SmolExpand
