import { isImmutable, fromJS } from 'immutable'
import { getCurrentOrg } from 'auth/current-org'

/**
 * Should we display the framework as if it is enabled:
 *
 * For supersonrai frameworks, we click "enable" and it actually clones the
 * framework, so for those ones we want to show them as "disabled" if they have
 * no clones.
 *
 * The special case is for supersonrai org users, when they log in they want to
 * see if it's enabled.
 *
 * Otherwise, we want to just show whether or not it's actually enabled.
 */
export function displayAsEnabled(controlFramework) {
  if (!isImmutable(controlFramework)) {
    // all the functions assume it is immutable
    return displayAsEnabled(fromJS(controlFramework))
  }

  if (isOwnedBySuperSonrai(controlFramework)) {
    if (isUserSuperSonrai()) {
      return isFrameworkEnabled(controlFramework)
    } else {
      return frameworkHasClone(controlFramework)
    }
  } else {
    return isFrameworkEnabled(controlFramework)
  }
}

/**
 * Get the property from the control framework. If it doesn't have it log warn
 */
function getPropertyOrWarn(controlFramework, property, defaultVal = null) {
  if (!controlFramework.get(property)) {
    // eslint-disable-next-line no-console
    console.warn(
      `controlFramework ${controlFramework.get(
        'srn'
      )} does not have ${property} property`
    )
    return defaultVal
  }
  return controlFramework.get(property)
}

/**
 * return true/false if the framework has been cloned
 */
function frameworkHasClone(controlFramework) {
  const clonedBy = getPropertyOrWarn(
    controlFramework,
    'clonedBy',
    fromJS({ items: [] })
  )
  return clonedBy.get('items').size > 0
}

/**
 * return true/false if the framework is enabled
 */
function isFrameworkEnabled(controlFramework) {
  return controlFramework.get('enabled')
}

/**
 * return true/false if the framework is owned by supersonrai
 */
function isOwnedBySuperSonrai(controlFramework) {
  const resourceId = getPropertyOrWarn(controlFramework, 'resourceId', '')
  return resourceId.match(/^\/org\/supersonrai\/.*/)
}

/**
 * return true/false if the current user is supersonrai
 */
function isUserSuperSonrai() {
  return getCurrentOrg() === 'supersonrai'
}
