/*
 *
 * Explorer actions
 *
 */

import {
  GET_SOURCE_NODE,
  SET_SOURCE_NODES,
  GET_MULTI_SOURCE_NODES,
  GET_RELATIONS,
  SET_RELATIONS,
  REMOVE_RELATIONS,
  SET_FILTER,
  REMOVE_FILTER,
  SELECT_NODE,
  SET_AVAILABLE_FILTERS,
  CLEAR_GRAPH,
  GET_NODE_PREVIEW,
  SET_NODE_PREVIEW,
  LOAD_ALL_CHILDREN,
  CLEAR_FILTERS,
  REMOVE_NODE,
  SET_NODE_PREVIEW_RELATIONS,
  GET_NODE_PREVIEW_RELATIONS,
  HANDLE_EXPAND,
  HANDLE_HIDE,
  HANDLE_SHOW,
  LOAD_NODES,
  HANDLE_UPDATE,
} from './constants'
import { createAction } from 'redux-actions'

export const getSourceNode = createAction(GET_SOURCE_NODE)
export const getMultiSourceNodes = createAction(GET_MULTI_SOURCE_NODES)
export const setSourceNodes = createAction(SET_SOURCE_NODES)
export const getRelations = createAction(GET_RELATIONS)
export const setRelations = createAction(SET_RELATIONS)
export const removeRelations = createAction(REMOVE_RELATIONS)
export const setFilter = createAction(SET_FILTER)
export const removeFilter = createAction(REMOVE_FILTER)
export const selectNode = createAction(SELECT_NODE)
export const setAvailableFilters = createAction(SET_AVAILABLE_FILTERS)
export const clearGraph = createAction(CLEAR_GRAPH)
export const getNodePreview = createAction(GET_NODE_PREVIEW)
export const setNodePreview = createAction(SET_NODE_PREVIEW)
export const loadAllChildren = createAction(LOAD_ALL_CHILDREN)
export const clearFilters = createAction(CLEAR_FILTERS)
export const removeNode = createAction(REMOVE_NODE)
export const getNodePreviewRelations = createAction(GET_NODE_PREVIEW_RELATIONS)
export const setNodePreviewRelations = createAction(SET_NODE_PREVIEW_RELATIONS)
export const handleExpand = createAction(HANDLE_EXPAND)
export const handleHide = createAction(HANDLE_HIDE)
export const handleShow = createAction(HANDLE_SHOW)
export const loadNodes = createAction(LOAD_NODES)
export const handleUpdate = createAction(HANDLE_UPDATE)
