import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import messages from 'globalTranslations'

class TicketStatusIcon extends React.Component {
  getIconInfo = value => {
    switch (value) {
      case 'NEW':
        return {
          iconName: 'exclamation-triangle-solid',
          iconColor: 'rgb(242, 157, 56)',
        }
      case 'SNOOZED':
        return {
          iconName: 'clock',
          iconColor: 'rgb(101, 157, 213)',
        }
      case 'RISK_ACCEPTED':
      case 'CLOSED':
        return {
          iconName: 'check-circle',
          iconColor: 'rgb(70, 155, 48)',
        }
    }
  }

  render() {
    const { value } = this.props
    if (!value) {
      return null
    }
    const { iconColor, iconName } = this.getIconInfo(value)
    return (
      <Fragment>
        {iconName && (
          <span
            style={{
              color: iconColor,
              paddingRight: '8px',
              position: 'relative',
              top: '-1px',
            }}
          >
            <Icon fa name={iconName} />
          </span>
        )}
        <DynamicFormattedMessage
          {...messages.tickets[`ticketStatus${this.props.value}`]}
        />
      </Fragment>
    )
  }
}

TicketStatusIcon.propTypes = {
  value: PropTypes.string.isRequired,
}

export default TicketStatusIcon
